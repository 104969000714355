import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  projectListHoablPageSuccess: ["response"],
  projectListHoablPageFailure: ["error"],
  projectListHoablPageRequest: ["data"],

  hoablPage1Success: ["response"],
  hoablPage1Failure: ["error"],
  hoablPage1Request: ["data"],

  updateHoablPage1Request: ["data"],
  updateHoablPage1Success: ["response"],
  updateHoablPage1Failure: ["error"],

  createHoablPage1Request: ["data"],
  createHoablPage1Success: ["response"],
  createHoablPage1Failure: ["error"],

  setHoablPage1State: ["key", "value"],
});

export const hoablPage1Types = Types;

export const hoablPage1Action = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  data: null,
  projectListWithPriorityReduxState: null,
  hoablPage1UpdateResponse: null,
  projectListHoablPage1: null,
  isSuccess: false,
  ishoablPage1Upadted: false,
  isGetPageFailure: false,
};

///projectList HoablPage1

export const projectListHoablPageRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const projectListHoablPageSuccess = (state: any, action: any) => {
  const response = action.response;

  return {
    ...state,
    error: null,
    projectListHoablPage1: response.data,
    isLoading: false,
    // projectListWithPriorityReduxState:null
  };
};

export const projectListHoablPageFailure = (state: any, action: any) => {
  const response = action.response;
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    ishoablPage1Upadted: false,
    isSuccess: false,
  };
};

///
export const hoablPage1Request = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const hoablPage1Success = (state: any, action: any) => {
  const response = action.response;
  return {
    ...state,
    error: null,
    hoablPage1: response.data,
    isLoading: false,
    // projectListWithPriorityReduxState:null
  };
};

export const hoablPage1Failure = (state: any, action: any) => {
  const response = action.response;
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    ishoablPage1Upadted: false,
    isSuccess: false,
    isGetPageFailure: true,
    hoablPage1UpdateResponse: action?.error?.message
      ? action?.error?.message
      : { message: "something went wrong, try again!." },
  };
};

export const createHoablPage1Request = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const createHoablPage1Success = (state: any, action: any) => {
  const response = action.response;
  return {
    ...state,
    error: null,
    hoablPage1UpdateResponse: action.response,
    isLoading: false,
    // projectListWithPriorityReduxState:null
  };
};

export const createHoablPage1Failure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    hoablPage1UpdateResponse: action.error,
  };
};

export const updateHoablPage1Request = (state: any) => {
  return {
    ...state,
    // isLoading: true,
  };
};

export const updateHoablPage1Success = (state: any, action: any) => {
  const response = action.response;
  return {
    ...state,
    error: null,
    hoablPage1UpdateResponse: action.response,
    isLoading: false,
    // projectListWithPriorityReduxState:null,
    isSuccess: true,
  };
};

export const updateHoablPage1Failure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    hoablPage1UpdateResponse: action.error,
  };
};

export const setHoablPage1State = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PROJECT_LIST_HOABL_PAGE_SUCCESS]: projectListHoablPageSuccess,
  [Types.PROJECT_LIST_HOABL_PAGE_FAILURE]: projectListHoablPageFailure,
  [Types.PROJECT_LIST_HOABL_PAGE_REQUEST]: projectListHoablPageRequest,

  [Types.HOABL_PAGE1_SUCCESS]: hoablPage1Success,
  [Types.HOABL_PAGE1_FAILURE]: hoablPage1Failure,
  [Types.HOABL_PAGE1_REQUEST]: hoablPage1Request,

  [Types.UPDATE_HOABL_PAGE1_SUCCESS]: updateHoablPage1Success,
  [Types.UPDATE_HOABL_PAGE1_FAILURE]: updateHoablPage1Failure,
  [Types.UPDATE_HOABL_PAGE1_REQUEST]: updateHoablPage1Request,

  [Types.CREATE_HOABL_PAGE1_SUCCESS]: createHoablPage1Success,
  [Types.CREATE_HOABL_PAGE1_FAILURE]: createHoablPage1Failure,
  [Types.CREATE_HOABL_PAGE1_REQUEST]: createHoablPage1Request,

  [Types.SET_HOABL_PAGE1_STATE]: setHoablPage1State,
});
