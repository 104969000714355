import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import UserActions from "redux-container/users/UsersRedux";
import { InviteActions } from "redux-container/users/InviteUserRedux";
import UserLogsActions from "redux-container/users/UserLogsRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";

export function* getUsers(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getUsers, action.params);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UserActions.getUsersListSuccess(response.data));
    } else {
      yield put(UserActions.getUsersListFailure(response.data));
    }
  } catch (error) {
    yield put(UserActions.getUsersListFailure(error));
  }
}

export function* getAllUsers(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAllUsers, action.params);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InviteActions.getUsersSuccess(response.data));
    } else {
      yield put(InviteActions.getUsersFailure(response.data));
    }
  } catch (error) {
    yield put(InviteActions.getUsersFailure(error));
  }
}

export function* updateUsers(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateAdmins, action.id, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InviteActions.updateInviteUsersSuccess(response.data));
    } else {
      yield put(InviteActions.updateInviteUsersFailure(response.data));
    }
  } catch (error) {
    yield put(InviteActions.updateInviteUsersFailure(error));
  }
}

export function* inviteUsers(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.inviteUsers, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InviteActions.inviteUsersSuccess(response.data));
    } else {
      yield put(InviteActions.inviteUsersFailure(response.data));
    }
  } catch (error) {
    yield put(InviteActions.inviteUsersFailure(error));
  }
}

export function* getUserModules(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getModules, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(RoleAndModuleActions.moduleSuccess(response.data));
    } else {
      yield put(RoleAndModuleActions.roleAndModuleFailure(response?.data));
    }
  } catch (error) {
    yield put(RoleAndModuleActions.roleAndModuleFailure(error));
  }
}

export function* getUserRoles(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getRoles, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(RoleAndModuleActions.roleSuccess(response.data));
    } else {
      yield put(RoleAndModuleActions.roleAndModuleFailure(response?.data));
    }
  } catch (error) {
    yield put(RoleAndModuleActions.roleAndModuleFailure(error));
  }
}

export function* deleteUser(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteUser, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InviteActions.deleteUserSuccess(response.data));
    } else {
      yield put(InviteActions.deleteUserFailure(response.data));
    }
  } catch (error) {
    yield put(InviteActions.deleteUserFailure(error));
  }
}

export function* updateUserStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateAdminStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InviteActions.updateUserStatusSuccess(response.data));
    } else {
      yield put(InviteActions.updateUserStatusFailure(response.data));
    }
  } catch (error) {
    yield put(InviteActions.updateUserStatusFailure(error));
  }
}

export function* getUserLogs(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAdminLogs, action.data);
    yield put(UserLogsActions.userLogsSuccess(response.data));
  } catch (error) {
    yield put(UserLogsActions.userLogsFailure(error));
  }
}

export function* updateAdminStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateAdminStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UserActions.changeUserStatusSuccess(response.data));
    } else {
      yield put(UserActions.changeUserStatusFailure(response.data));
    }
  } catch (error) {
    yield put(UserActions.changeUserStatusFailure(error));
  }
}

export function* deleteUsers(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteUsers, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UserActions.deleteUsersSuccess(response.data));
    } else {
      yield put(UserActions.deleteUsersFailure(response.data));
    }
  } catch (error) {
    yield put(UserActions.deleteUsersFailure(error));
  }
}

export function* getUserDashboard(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getUserDashboard, action.data);
    yield put(UserActions.getUserDashboardSuccess(response.data));
  } catch (error) {
    yield put(UserActions.getUserDashboardFailure(error));
  }
}

export function* logout(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.logout, action.data);
    yield put(UserActions.logoutSuccess(response.data));
  } catch (error) {
    yield put(UserActions.logoutFailure(error));
  }
}

export function* adminListForFilters(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAdminsForFilters, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UserActions.getAdminListForFiltersSuccess(response.data));
    } else {
      yield put(UserActions.getAdminListForFiltersFailure(response.data));
    }
  } catch (error) {
    yield put(UserActions.getAdminListForFiltersFailure(error));
  }
}
