import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import MapedFaqsBasedOnProject from "./mapped-project-faqs/MappedFaqsBasedOnProject";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteConstants } from "./../../../../routes/RouteConstants";
import { Box, Divider } from "@mui/material";
import CategoriesFaqsTable from "./categories-faqs-table.tsx/CategoriesFaqsTable";
import FaqCreationAndEdit from "./category-based-faq-list/FaqCreationAndEdit";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetProjectCategoriesAction } from "redux-container/project-management/ProjectCategoryRedux";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";
import { AllProjectFaqsAction } from "redux-container/project-management/ProjectAllFaqsRedux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { useFormikContext } from "formik";
import ProjectAllFaqsListAction from "redux-container/project-management/project-content/ProjectAllFaqsListRedux";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { FaqsTypeEnum } from "enumerations/FaqsTypes";

const actionDispatch = (dispatch: any) => {
  return {
    setProjectCategoriesState: (key: any, data: any) =>
      dispatch(GetProjectCategoriesAction.setProjectCategoriesState(key, data)),
    setProjectFaqState: (key: any, value: any) =>
      dispatch(ProjectFaqActions.setProjectFaqState(key, value)),
    allProjectFaqs: () =>
      dispatch(AllProjectFaqsAction.allFaqsProjectRequest()),
    setALLProjectFaqState: (key: any, value: any) =>
      dispatch(AllProjectFaqsAction.setALLProjectFaqState(key, value)),
    getProjectFaqs: (params: any) =>
      dispatch(
        ProjectAllFaqsListAction.getProjectAllFaqsListDataRequest(params)
      ),
  };
};
export default function Faqs() {
  const {
    setProjectCategoriesState,
    setProjectFaqState,
    allProjectFaqs,
    setALLProjectFaqState,
    getProjectFaqs,
  } = actionDispatch(useDispatch());
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );
  const pageCreationData = useSelector(
    (state: any) => state?.projectContentManagement
  );
  const allFaqs = useSelector(
    (state: any) => state?.projectAllfaqs?.allProjectFaqs
  );
  const isFaqAddOrRemoved = useSelector(
    (state: any) => state?.projectAllfaqs.isFaqAddOrRemoved
  );
  const projectContentAndFaqs = useSelector(
    (state: any) =>
      state?.projectContentManagement?.projectCreationData
        ?.projectContentsAndFaqs
  );
  const projectFaqs = useSelector(
    (state: any) => state?.projectFaqs?.projectFaqs
  );
  const projectCategories = useSelector(
    (state: any) => state?.projectCategories?.mappedCategoryArray
  );
  const projectAllFaqsList = useSelector(
    (state: any) => state?.projectAllFaqsList?.projectAllFaqsListData
  );
  const mappedprojectFaq = useSelector(
    (state: any) => state?.projectFaqs?.mappedFaqs
  );

  const location: any = useLocation();

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    touched,
    errors,
  } = useFormikContext<IInitinalValueProjectContent>();

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    const getCategoryParams = {
      typeOfFAQ: FaqsTypeEnum.ProjectFaq,
      shouldDisplayMappedFaqs: false,
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    getProjectFaqs(getCategoryParams);
    let mapProjectFaqs = projectAllFaqsList.filter((item: any) =>
      projectCategories?.includes(item?.categoryId)
    );
    const mappedProjectIds = mapProjectFaqs.map((item: any) => item.id);
    setFieldValue("mappedFaqs", mappedProjectIds);
  }, [projectAllFaqsList?.length, projectCategories?.length]);

  useEffect(() => {
    allProjectFaqs();
    const categoryArry: any = [];
    const mappedFaqs: any = [];

    pageCreationData?.projectCreationData?.projectContent?.frequentlyAskedQuestions?.forEach(
      (category: any) => {
        if (categoryArry.indexOf(category?.categoryId) === -1) {
          categoryArry?.push(category?.categoryId);
        }
      }
    );

    pageCreationData?.projectCreationData?.projectContentsAndFaqs?.forEach(
      (faq: any) => {
        if (mappedFaqs.indexOf(faq?.faqId) === -1) {
          mappedFaqs?.push(faq?.faqId);
        }
      }
    );

    if (location?.state?.isDraft) {
      location?.state?.projectContent?.frequentlyAskedQuestions?.forEach(
        (category: any) => {
          if (categoryArry.indexOf(category?.categoryId) === -1) {
            categoryArry?.push(category?.categoryId);
          }
        }
      );
    }

    const showingFaqs = allFaqs?.filter((allFaq: any) => {
      return mappedFaqs.indexOf(allFaq.id) !== -1;
    });
    if (!location?.state?.isNew || location?.state?.isDraft) {
      setALLProjectFaqState("showInMobileFaq", showingFaqs);
      setALLProjectFaqState("filterAllfaqBasedGivenArray", mappedFaqs);
      setProjectCategoriesState("mappedCategoryArray", categoryArry);
      setProjectFaqState("mappedFaqs", mappedFaqs);
      setALLProjectFaqState("allProjectFaqs", mappedFaqs);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.isNew) {
      setFieldValue("frequentlyAskedQuestions", allFaqs);
      setALLProjectFaqState("allProjectFaqs", []);
    }
  }, []);

  useEffect(() => {
    if (isFaqAddOrRemoved) {
      allProjectFaqs();
      setALLProjectFaqState("isFaqAddOrRemoved", false);
    }
  }, [isFaqAddOrRemoved]);

  useEffect(() => {
    if (
      values?.faqsToDisplayInDetails?.length > 0 &&
      values?.faqsToDisplayInDetails[0]?.faqId === undefined
    ) {
      const extractingMappedFaqId = projectContentAndFaqs?.map((item: any) => {
        if (item?.faqId) {
          return { faqId: item?.faqId, priority: item?.priority };
        }
      });
      setFieldValue("faqsToDisplayInDetails", extractingMappedFaqId);
    }
  }, [
    projectAllFaqsList?.length,
    projectCategories?.length,
    values?.faqsToDisplayInDetails?.length,
  ]);

  useEffect(() => {
    const extractingMappedFaqId = mappedprojectFaq?.map(
      (item: any, index: any) => {
        if (item) {
          return { faqId: item, priority: index };
        }
      }
    );
    setFieldValue("faqsToDisplayInDetails", extractingMappedFaqId);
  }, [mappedprojectFaq?.length]);

  const navigate: any = useNavigate();
  return (
    <Box>
      <Routes>
        <Route path="/" element={<CategoriesFaqsTable />} />
        <Route
          path={`${RouteConstants.projectFaqCreation}`}
          element={<FaqCreationAndEdit />}
        />
      </Routes>
      <Box textAlign="right" p={"1.5rem"}>
        {/* <ButtonComponent variant="contained">Save</ButtonComponent> */}
      </Box>
      <Divider />
      <Divider />
      <Box>
        <MapedFaqsBasedOnProject />
      </Box>
      <Divider />
    </Box>
  );
}
