import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
    chatManagementListSuccess: ["response"],
    chatManagementListFailure: ["error"],
    chatManagementListRequest: ["data"],

    setChatManagementListState: ["key", "value"],
});

export const chatMangementListTypes = Types;
export default Creators;

export const INITIAL_STATE: any = {
    chatMangementListData: [],
    error: null,
    isLoading: false,
    snackBarError: "",
    isSuccess: false,
    message: null,

};
export const chatManagementListRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
    };
};

export const chatManagementListSuccess = (state: any, action: any) => {
    const response = action.response;
    
    if (response) {
        return {
            ...state,
            error: null,
            chatMangementListData: response.data,
            isLoading: false,
        };
    };
};

export const chatManagementListFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
    };
};

export const setChatManagementListState = (state: any, action: any) => {
    const newState = {
        ...state,
    };
    newState[action.key] = action.value;
    return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.CHAT_MANAGEMENT_LIST_SUCCESS]: chatManagementListSuccess,
    [Types.CHAT_MANAGEMENT_LIST_FAILURE]: chatManagementListFailure,
    [Types.CHAT_MANAGEMENT_LIST_REQUEST]: chatManagementListRequest,

    [Types.SET_CHAT_MANAGEMENT_LIST_STATE]: setChatManagementListState,
});
