import {
  Stack,
  Typography,
  Avatar,
  Tooltip,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Divider,
  IconButton,
  Grid,
} from "@mui/material";
import MediaAssets from "assets";
import {
  ButtonComponent,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import React, { Fragment, useEffect, useState } from "react";
import styles from "./SimilarInvestments.module.css";
import ProjectListDialog from "../../../../pages/page-management/page-management-dashboard/investments/project-list-dialog/ProjectListDialog";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { initialState } from "./SimilarInvestmentsConstants";
import { Form, Formik, getIn } from "formik";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
// import projectListActions from "redux-container/project-management/ProjectListRedux";

// const actionDispatch = (dispatch : any) => {
//   return {
//     getProjectList: (params: any) =>
//       dispatch(projectListActions.projectListRequest(params)),
//   }
// }

export default function SimilarInvestments() {

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    errors,
    touched,
    isValid
  } = useFormikContext<IInitinalValueProjectContent>();

  const [showProjectListDialog, setshowProjectListDailog] = useState(false);
  const [mappedProjects, setMappedProjects] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isStartingPriceSort, setIsStartingPriceSort] = useState(true);
  const [isStartingAreaSort, setIsStartingAreaSort] = useState(true);
  const [priorityCount, setPriorityCount] = useState(0);
  const projectContentList = useSelector((state : any) => state.projectList?.data.data)
  const location : any = useLocation()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [deleteId, setDeleteId] = useState<any>()

  const handleProjectListDialogClose = () => {
    setshowProjectListDailog(false);
  };

  const tableHeadings = [
    { heading: "Project ID" },
    { heading: "Thumbnail" },
    { heading: "Launch Name" },
    { heading: "Location" },
    { heading: "Starting Price" },
    { heading: "Starting Area" },
    { heading: "Created On" },
    { heading: "Priority" },
    { heading: "Action" },
  ];
  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));
  const removeHandler = (id: any) => {
    let data = mappedProjects;
    const filteredData = data.filter((item: any) => item?.id !== id);
    setMappedProjects(filteredData);
  };
  const handleDeleteOkClick = () => {
    if(mappedProjects?.length === values?.numberOfSimilarInvestmentsToShow){
      setFieldValue('numberOfSimilarInvestmentsToShow', values?.numberOfSimilarInvestmentsToShow - 1)
    }
    let data = mappedProjects;
    const filteredData = data.filter((item: any) => item?.id !== deleteId);
    setMappedProjects(filteredData);
    setOpenConfirmation(false)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handlePriorityChange = (
    indexValue: any,
    list: any,
    priorityUpdatevalue: any,
    updatedItem: any
  ) => {
    if (indexValue) {
      list.splice(indexValue, 1);
      list.splice(indexValue + priorityUpdatevalue, 0, updatedItem);
      return list;
    }
  };

  // useEffect(() => {
  //   setFieldValue('similarInvestments', mappedProjects)
  // }, [mappedProjects])

  const initialProjectMaps = () => {
    values?.similarInvestmentsWithPriority?.map((item: any) => {
      projectContentList?.filter((data: any) => {
        if (item.id === data.id) {
          mappedProjects.push(data);
        }
      });
    });
  };

  useEffect(() => {
    // if(location?.state?.isNew === false){
      initialProjectMaps()
    // }
    const removeDuplicates = mappedProjects.filter((v : any,i : any,a : any)=>a.findIndex((v2 : any)=>(v2.id===v.id))===i)
    setMappedProjects(removeDuplicates)
  }, [])

  useEffect(() => {
    if(mappedProjects?.length > 0){
      const mappedProjectswithPriority = mappedProjects?.map((item : any, index : any) => ({id : item?.id, priority : index + 1}))
      setFieldValue('similarInvestmentsWithPriority', mappedProjectswithPriority)
    }else {
      setFieldValue('similarInvestmentsWithPriority', [])
    }
  }, [mappedProjects])

  return (
    <Fragment>
      <ConfirmationDialog
        shouldOpen={openConfirmation}
        content={"Are You Sure? Do You Want To Delete Item From Table?"}
        cancelHandler={() => setOpenConfirmation(false)}
        okText="Delete"
        okHandler={() => handleDeleteOkClick()}
      />

      <Container>
        <Stack justifyContent="start" spacing={2} mt={4} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Similar Investments
          </Typography>
          <SwitchComponent
            name={"isSimilarInvestmentActive"}
            checked={values?.isSimilarInvestmentActive}
            onChange={handleChange}
            disabled={isViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>
        <Grid container className="margin-bottom-30">
          <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
            <TextFieldComponent
              required
              type="text"
              label="Section Heading"
              placeholder="Enter"
              name={"similarInvestmentSectionHeading"}
              value={values?.similarInvestmentSectionHeading}
              onChange={handleChange}
              inputProps={{
                maxLength: 40,
              }}
              onBlur={handleBlur}
              fieldhelpertext={
                getIn(touched, `similarInvestmentSectionHeading`) &&
                getIn(errors, `similarInvestmentSectionHeading`)
              }
              error={Boolean(
                getIn(errors, `similarInvestmentSectionHeading`) &&
                  getIn(touched, `similarInvestmentSectionHeading`)
              )}
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
        <div className="margin-bottom-30">
          <LoadingButtonComponent
            onClick={() => setshowProjectListDailog(true)}
            className="btn btn-dark"
            style={{ minWidth: "235px" }}
            disabled={isViewMode}
          >
            {mappedProjects?.length > 0
              ? "Add More Investments"
              : "Map Projects"}
          </LoadingButtonComponent>
        </div>
        {showProjectListDialog && (
          <ProjectListDialog
            shouldOpen={showProjectListDialog}
            cancelHandler={handleProjectListDialogClose}
            mapProjectList={setMappedProjects}
            initialMapList={mappedProjects}
          />
        )}

        {mappedProjects?.length > 0 && (
          <>
            <Stack spacing={1} justifyContent={"end"}>
              <Typography color={"#17171c"}>
                Total items to be shown on Horizontal scroll on Project detail
                Page?
              </Typography>
              <PrioritySelector
                priority={values?.numberOfSimilarInvestmentsToShow}
                disableDecrement={
                  values?.numberOfSimilarInvestmentsToShow === 1
                }
                disableIncrement={
                  values?.numberOfSimilarInvestmentsToShow >=
                  mappedProjects?.length
                }
                incrementpriority={() =>
                  setFieldValue(
                    "numberOfSimilarInvestmentsToShow",
                    values?.numberOfSimilarInvestmentsToShow + 1
                  )
                }
                decrementpriority={() =>
                  setFieldValue(
                    "numberOfSimilarInvestmentsToShow",
                    values?.numberOfSimilarInvestmentsToShow - 1
                  )
                }
                isPriorityRequired={isViewMode}
                isCounter={true}
              />
            </Stack>

            <TableContainer
              component={Paper}
              className="table-container"
              style={{ maxHeight: "400px" }}
            >
              <Table>
                <TableHead>
                  <TableRow className="table-header-row">
                    {tableHeadings?.map((item, index) => (
                      <TableCell key={index} className="table-header-text">
                        {item.heading}

                        {/* {index === 4 && (
                                <IconButton
                                  onClick={() => {
                                    setIsStartingPriceSort(
                                      !isStartingPriceSort
                                    );
                                  }}
                                >
                                  {isStartingPriceSort ? (
                                    <ArrowDropDownIcon />
                                  ) : (
                                    <ArrowDropUpIcon />
                                  )}
                                </IconButton>
                              )}

                              {index === 5 && (
                                <IconButton
                                  onClick={() => {
                                    setIsStartingAreaSort(!isStartingAreaSort);
                                  }}
                                >
                                  {isStartingAreaSort ? (
                                    <ArrowDropDownIcon />
                                  ) : (
                                    <ArrowDropUpIcon />
                                  )}
                                </IconButton>
                              )} */}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mappedProjects
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((project: any, index: any) => (
                      <TableRow key={project?.id} className="table-data-row">
                        <TableCell className="table-data-text">
                          {project.id}
                        </TableCell>

                        <TableCell className="table-data-text">
                          {project?.projectCoverImages?.homePageMedia?.value
                            .url ? (
                            <img
                              src={
                                project?.projectCoverImages?.homePageMedia
                                  ?.value?.url
                              }
                              alt={
                                project?.projectCoverImages?.homePageMedia?.name
                              }
                              className={styles["project-list-image"]}
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>

                        <TableCell className="table-data-text">
                          {project?.launchName}
                        </TableCell>

                        <TableCell className="table-data-text">
                          {project?.address?.city},{project?.address?.state}
                        </TableCell>

                        <TableCell className="table-data-text">
                          {project?.priceStartingFrom}
                        </TableCell>

                        <TableCell className="table-data-text">
                          {project?.areaStartingFrom}
                        </TableCell>

                        <TableCell className="table-data-text">
                          <p>{project?.createdAt?.slice(0, 10)}</p>
                        </TableCell>

                        <TableCell className="table-data-text">
                          <PrioritySelector
                            priority={index + 1}
                            disableDecrement={
                              index + 1 === mappedProjects?.length
                            }
                            disableIncrement={index === 0}
                            incrementpriority={() => {
                              handlePriorityChange(
                                index,
                                mappedProjects,
                                -1,
                                project
                              );
                              setPriorityCount(priorityCount + 1);
                            }}
                            decrementpriority={() => {
                              if (index === 0) {
                                mappedProjects.splice(0, 1);
                                mappedProjects.splice(1, 0, project);
                                setPriorityCount(priorityCount + 1);
                              } else {
                                handlePriorityChange(
                                  index,
                                  mappedProjects,
                                  1,
                                  project
                                );
                                setPriorityCount(priorityCount - 1);
                              }
                            }}
                            isPriorityRequired={isViewMode}
                          />
                        </TableCell>

                        <TableCell className="table-data-text">
                          <Stack justifyContent={"start"} spacing={2}>
                            {mappedProjects?.length > 1 ? (
                              <Typography
                                sx={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpenConfirmation(true);
                                  setDeleteId(project?.id);
                                }}
                              >
                                Remove
                              </Typography>
                            ) : (
                              <Typography
                                color={"#CFD2CF"}
                                sx={{ cursor: "text" }}
                              >
                                Remove
                              </Typography>
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Divider sx={{ margin: "20px -56px" }} />

            {!isViewMode ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 50, 100]}
                component="div"
                count={mappedProjects?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}

            {/* <Divider sx={{ margin: "0 -56px" }} />

                  <Stack justifyContent={"end"} mt={3} mb={3}>
                    <LoadingButtonComponent onClick={() => handleOnGlobalSave(values)}>Save</LoadingButtonComponent>
                  </Stack> */}
          </>
        )}
      </Container>
    </Fragment>
  );
}
