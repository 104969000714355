export const updateCreationConstants = {
  displayTitleCharacterLimit: 80,
  subTitleCharacterLimit: 40,
  minimumLength: 0,
  detailedDescriptionCharacterLimit: 10000,
  briefDescriptionCharacterLimit: 130,
  briefDescriptionCharacterLimitWithImage: 65,
};

export const enum DescriptionTypeEnum {
  BriefDescription = "1001",
  DetailedDescription = "1002",
}

export const enum TypeOfUpdate {
  hoablType = "2001",
  projectType = "2002",
  factualType = "2003",
}

export const enum StatusEnum {
  Published = "1001",
  InActive = "1002",
}
export const updateCreationLabels: any = {
  date: "Date",
  displayTitle: "Display Title *",
  subTitle: "Sub Title",
  typeOfUpdate: "Type Of Update",
  mediaUpload3: "Upload Media 3",
  mediaUpload2: "Upload Media 2",
  mediaUpload1: "Upload Media 1",
  description1: "Description 1",
  description2: "Description 2",
  description3: "Description 3",
};
