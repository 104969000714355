import { Box, Grid, IconButton, Link, Typography } from "@mui/material";
import {
  ButtonComponent,
  FormFieldContainer,
  TextFieldComponent,
} from "components/ui-elements";
import { FieldArray, useFormikContext, getIn } from "formik";
import {
  IProjectFormInitialValues,
  ProjectFormInitialValues,
} from "../../ProjectFormInitialValues";
import { Fragment } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useLocation } from "react-router-dom";
import { handleChangeNoSpecialCharacter } from "utils/ValidationUtils";

const ReraDetails = () => {
  const { values, touched, errors, handleChange, handleBlur, setFieldValue } =
    useFormikContext<IProjectFormInitialValues>();

  const location: any = useLocation();

  return (
    <Box className="section-gutter">
      <Typography
        variant="h6"
        color={"common.black"}
        fontFamily={"Jost-Medium"}
      >
        Rera Details
      </Typography>
      <FormFieldContainer>
        <Grid container spacing={4} className="grid-container">
          <Grid item lg={5} xl={5.5}>
            <TextFieldComponent
              label={"Launch / Project Name *"}
              onChange={handleChange}
              name={`basicDetailsForm.reraDetails.projectName`}
              value={values?.basicDetailsForm?.reraDetails?.projectName}
              onBlur={handleBlur}
              error={Boolean(
                getIn(touched, `basicDetailsForm.reraDetails.projectName`) &&
                  getIn(errors, `basicDetailsForm.reraDetails.projectName`)
              )}
              fieldhelpertext={
                getIn(touched, `basicDetailsForm.reraDetails.projectName`) &&
                getIn(errors, `basicDetailsForm.reraDetails.projectName`)
              }
              sx={location?.state?.isViewMode && { pointerEvents: "none" }}
              inputProps={{
                maxLength: 40,
              }}
            />
          </Grid>
          <Grid item lg={5} xl={5.5}>
            <TextFieldComponent
              label={"Static Label 1 *"}
              value={
                ProjectFormInitialValues?.basicDetailsForm?.reraDetails
                  ?.staticLableOne
              }
              sx={{ pointerEvents: "none" }}
            />
          </Grid>
          <Grid item lg={12} xl={12}>
            <TextFieldComponent
              value={
                values?.basicDetailsForm?.reraDetails
                  ?.companyRegistrationAddress
              }
              name="basicDetailsForm.reraDetails.companyRegistrationAddress"
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ maxLength: 250 }}
              label={"Company Name & Register Address *"}
              error={Boolean(
                getIn(
                  touched,
                  `basicDetailsForm.reraDetails.companyRegistrationAddress`
                ) &&
                  getIn(
                    errors,
                    `basicDetailsForm.reraDetails.companyRegistrationAddress`
                  )
              )}
              fieldhelpertext={
                getIn(
                  touched,
                  `basicDetailsForm.reraDetails.companyRegistrationAddress`
                ) &&
                getIn(
                  errors,
                  `basicDetailsForm.reraDetails.companyRegistrationAddress`
                )
              }
              sx={location?.state?.isViewMode && { pointerEvents: "none" }}
            />
          </Grid>
          <Grid item lg={3} xl={4}>
            <TextFieldComponent
              label={"Static Label 2 *"}
              value={
                ProjectFormInitialValues?.basicDetailsForm?.reraDetails
                  ?.staticLableTwo
              }
              sx={{ pointerEvents: "none" }}
            />
          </Grid>
          <Grid item lg={7} xl={7}>
            <TextFieldComponent
              label={"GSTIN Number *"}
              value={values?.basicDetailsForm?.reraDetails?.gstinNumber}
              name="basicDetailsForm.reraDetails.gstinNumber"
              onChange={handleChangeNoSpecialCharacter(setFieldValue)}
              onBlur={handleBlur}
              inputProps={{ maxLength: 15 }}
              sx={location?.state?.isViewMode && { pointerEvents: "none" }}
              error={Boolean(
                errors.basicDetailsForm?.reraDetails?.gstinNumber &&
                  touched.basicDetailsForm?.reraDetails?.gstinNumber
              )}
              fieldhelpertext={
                errors.basicDetailsForm?.reraDetails?.gstinNumber &&
                touched.basicDetailsForm?.reraDetails?.gstinNumber &&
                errors.basicDetailsForm?.reraDetails?.gstinNumber
              }
            />
          </Grid>
          <Grid item lg={12} xl={12}>
            <TextFieldComponent
              name="basicDetailsForm.reraDetails.staticLableThree"
              label={"Static Label 3 *"}
              value={values?.basicDetailsForm?.reraDetails?.staticLableThree}
              onChange={(event: any) => {
                console.log("onchange");
                handleChange(event);
              }}
              onBlur={handleBlur}
              error={Boolean(
                errors.basicDetailsForm?.reraDetails?.staticLableThree &&
                  touched.basicDetailsForm?.reraDetails?.staticLableThree
              )}
              fieldhelpertext={
                errors.basicDetailsForm?.reraDetails?.staticLableThree &&
                touched.basicDetailsForm?.reraDetails?.staticLableThree &&
                errors.basicDetailsForm?.reraDetails?.staticLableThree
              }
            />
          </Grid>
          <Grid item lg={12} xl={12}>
            <FormFieldContainer>
              <FieldArray
                name="basicDetailsForm.reraDetails.phase"
                render={(arrayHelpers) => (
                  <>
                    <Grid container spacing={4} className="grid-container">
                      {values?.basicDetailsForm?.reraDetails?.phase.map(
                        (_value, index) => (
                          <Fragment key={index + 1}>
                            <Grid item lg={5.5} xl={5.5}>
                              <TextFieldComponent
                                required
                                label={"Phase Name"}
                                name={`basicDetailsForm.reraDetails.phase[${index}].phaseName`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ maxLength: 40 }}
                                value={
                                  values?.basicDetailsForm?.reraDetails?.phase[
                                    index
                                  ]?.phaseName
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    `basicDetailsForm.reraDetails.phase[${index}].phaseName`
                                  ) &&
                                    getIn(
                                      errors,
                                      `basicDetailsForm.reraDetails.phase[${index}].phaseName`
                                    )
                                )}
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `basicDetailsForm.reraDetails.phase[${index}].phaseName`
                                  ) &&
                                  getIn(
                                    errors,
                                    `basicDetailsForm.reraDetails.phase[${index}].phaseName`
                                  )
                                }
                                sx={
                                  location?.state?.isViewMode && {
                                    pointerEvents: "none",
                                  }
                                }
                              />
                            </Grid>
                            <Grid item lg={5.5} xl={5.5}>
                              <TextFieldComponent
                                required
                                label={"RERA Number"}
                                onChange={handleChangeNoSpecialCharacter(
                                  setFieldValue
                                )}
                                value={
                                  values?.basicDetailsForm?.reraDetails?.phase[
                                    index
                                  ]?.reraNumber
                                }
                                inputProps={{ maxLength: 40 }}
                                onBlur={handleBlur}
                                name={`basicDetailsForm.reraDetails.phase[${index}].reraNumber`}
                                error={Boolean(
                                  getIn(
                                    touched,
                                    `basicDetailsForm.reraDetails.phase[${index}].reraNumber`
                                  ) &&
                                    getIn(
                                      errors,
                                      `basicDetailsForm.reraDetails.phase[${index}].reraNumber`
                                    )
                                )}
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `basicDetailsForm.reraDetails.phase[${index}].reraNumber`
                                  ) &&
                                  getIn(
                                    errors,
                                    `basicDetailsForm.reraDetails.phase[${index}].reraNumber`
                                  )
                                }
                                sx={
                                  location?.state?.isViewMode && {
                                    pointerEvents: "none",
                                  }
                                }
                              />
                            </Grid>
                            {!location?.state?.isViewMode && (
                              <Grid item lg={1} xl={1}>
                                {values?.basicDetailsForm?.reraDetails?.phase
                                  ?.length > 1 ? (
                                  <IconButton
                                    aria-label="delete"
                                    data-testid="button-delete"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                                  </IconButton>
                                ) : null}
                              </Grid>
                            )}
                          </Fragment>
                        )
                      )}
                    </Grid>
                    {!location?.state?.isViewMode && (
                      <ButtonComponent
                        variant="text"
                        color="inherit"
                        onClick={() => {
                          arrayHelpers.push({
                            phaseName: "",
                            reraNumber: "",
                          });
                        }}
                      >
                        + Add More
                      </ButtonComponent>
                    )}
                  </>
                )}
              />
            </FormFieldContainer>
          </Grid>
          <Grid item lg={3} xl={4}>
            <TextFieldComponent
              label={"Static Label 4 *"}
              sx={{ pointerEvents: "none" }}
              value={
                ProjectFormInitialValues?.basicDetailsForm?.reraDetails
                  ?.staticLableFour
              }
            />
          </Grid>
          <Grid item lg={7} xl={7}>
            <TextFieldComponent
              required
              label={"Register Projects"}
              name="basicDetailsForm.reraDetails.registeredProjects"
              value={values?.basicDetailsForm?.reraDetails?.registeredProjects}
              onChange={handleChange}
              inputProps={{ maxLength: 150 }}
              sx={location?.state?.isViewMode && { pointerEvents: "none" }}
              onBlur={handleBlur}
              error={Boolean(
                getIn(
                  touched,
                  `basicDetailsForm.reraDetails.registeredProjects`
                ) &&
                  getIn(
                    errors,
                    `basicDetailsForm.reraDetails.registeredProjects`
                  )
              )}
              fieldhelpertext={
                getIn(
                  touched,
                  `basicDetailsForm.reraDetails.registeredProjects`
                ) &&
                getIn(errors, `basicDetailsForm.reraDetails.registeredProjects`)
              }
            />
          </Grid>
          <Grid item lg={3} xl={4}>
            <TextFieldComponent
              label={"Static Label 5 *"}
              value={
                ProjectFormInitialValues?.basicDetailsForm?.reraDetails
                  ?.staticLableFive
              }
              sx={{ pointerEvents: "none" }}
            />
          </Grid>
          <Grid item lg={7} xl={7}>
            <TextFieldComponent
              required
              label={"Website Name"}
              name="basicDetailsForm.reraDetails.websiteName"
              value={values?.basicDetailsForm?.reraDetails?.websiteName}
              onChange={handleChange}
              inputProps={{ maxLength: 150 }}
              sx={location?.state?.isViewMode && { pointerEvents: "none" }}
              onBlur={handleBlur}
              error={Boolean(
                getIn(touched, `basicDetailsForm.reraDetails.websiteName`) &&
                  getIn(errors, `basicDetailsForm.reraDetails.websiteName`)
              )}
              fieldhelpertext={
                getIn(touched, `basicDetailsForm.reraDetails.websiteName`) &&
                getIn(errors, `basicDetailsForm.reraDetails.websiteName`)
              }
            />
          </Grid>
        </Grid>
      </FormFieldContainer>
    </Box>
  );
};

export default ReraDetails;
