import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { ResetPasswordAction } from "redux-container/reset-password/ResetPasswordRedux";


export function* resetPassword(api: any, action: any): any {
    try {
        const response = yield call(api.resetPassword, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ResetPasswordAction.resetPasswordSuccess(response.data));
        } else {
            yield put(ResetPasswordAction.resetPasswordFailure(response.data));
        }
    } catch (error) {
        yield put(ResetPasswordAction.resetPasswordFailure(error));
    }
}