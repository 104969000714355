import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  projectListSuccess: ["response"],
  projectListFailure: ["error"],
  projectListRequest: ["data"], //Project

  updateProjectPriorityRequest: ["data"],
  updateProjectPrioritySuccess: ["response"],
  updateProjectPriorityFailure: ["error"],

  updateProjectsStatusRequest: ["data"],
  updateProjectsStatusSuccess: ["response"],
  updateProjectsStatusFailure: ["error"],

  deleteProjectsRequest: ["data"],
  deleteProjectsSuccess: ["response"],
  deleteProjectsFailure: ["error"],

  setProjectState: ["key", "value"],
});

export const projectListTypes = Types;

const projectListActions = Creators;
export default projectListActions;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  data: [],
  snackBarError: "",
  shouldOpenFlashDialog: false,
  message: "",
  isSuccess: false,
  totalCount: 0,
  isUpdated: false,
  isDeleted: false,
  tabValue: "1",
  page: 0,
  rowsPerPage: 5,
};

export const deleteProjectsRequest = (state: any) => {
  return {
    ...state,
    isloading: true,
  };
};

export const deleteProjectsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isDeleted: false,
    message: action?.error?.data?.message
      ? action?.error?.data?.message
      : "Failed to Delete",
    shouldOpenFlashDialog: true,
    isSuccess: false,
  };
};
export const deleteProjectsSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isDeleted: true,
    message: action?.response?.data?.message,
    shouldOpenFlashDialog: true,
    isSuccess: true,
  };
};
export const updateProjectsStatusRequest = (state: any) => {
  return {
    ...state,
    isloading: true,
  };
};

export const updateProjectsStatusSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isUpdated: true,
    message: action?.response?.data?.message,
    shouldOpenFlashDialog: true,
    isSuccess: true,
  };
};
export const updateProjectsStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isloading: false,
    message: action?.error?.data?.message
      ? action?.error?.data?.message
      : "Failed to Update",
    shouldOpenFlashDialog: true,
    isSuccess: false,

    // isSuccess:true,
  };
};
const updateProjectPriorityRequest = (state: any) => {
  return {
    ...state,
    isloading: true,
  };
};
const updateProjectPrioritySuccess = (state: any, action: any) => {
  return {
    ...state,
    isloading: false,
    isUpdated: true,
    // // shouldOpenFlashDialog: true,
    // isSuccess: true,
    // message: action?.response?.data?.message,
  };
};
const updateProjectPriorityFailure = (state: any, action: any) => {
  return {
    ...state,
    isloding: false,
    isUpdated: true,
    shouldOpenFlashDialog: true,
    isSuccess: false,
    message: action?.error?.data?.message
      ? action?.error?.data?.message
      : "Failed to Update",
  };
};

export const projectListRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const projectListSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      data: response,
      isLoading: false,
      massage: response.message,
      totalCount: response.totalCount ? response.totalCount : 0,
      isUpdated: false,
      isDeleted: false,
    };
  }
  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const projectListFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setProjectState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PROJECT_LIST_SUCCESS]: projectListSuccess,
  [Types.PROJECT_LIST_FAILURE]: projectListFailure,
  [Types.PROJECT_LIST_REQUEST]: projectListRequest,

  [Types.UPDATE_PROJECT_PRIORITY_REQUEST]: updateProjectPriorityRequest,
  [Types.UPDATE_PROJECT_PRIORITY_SUCCESS]: updateProjectPrioritySuccess,
  [Types.UPDATE_PROJECT_PRIORITY_FAILURE]: updateProjectPriorityFailure,

  [Types.UPDATE_PROJECTS_STATUS_REQUEST]: updateProjectsStatusRequest,
  [Types.UPDATE_PROJECTS_STATUS_SUCCESS]: updateProjectsStatusSuccess,
  [Types.UPDATE_PROJECTS_STATUS_FAILURE]: updateProjectsStatusFailure,

  [Types.DELETE_PROJECTS_REQUEST]: deleteProjectsRequest,
  [Types.DELETE_PROJECTS_SUCCESS]: deleteProjectsSuccess,
  [Types.DELETE_PROJECTS_FAILURE]: deleteProjectsFailure,

  [Types.SET_PROJECT_STATE]: setProjectState,
});
