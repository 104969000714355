import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  ButtonComponent,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { useFormikContext } from "formik";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";
import { RouteConstants } from "routes/RouteConstants";
import { AllProjectFaqsAction } from "redux-container/project-management/ProjectAllFaqsRedux";
import { useState } from "react";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import ProjectAllFaqsListAction from "redux-container/project-management/project-content/ProjectAllFaqsListRedux";
import { Constants } from "constants/Constants";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { FaqsTypeEnum } from "../../../../../enumerations/FaqsTypes";

const actionDispatch = (dispatch: any) => {
  return {
    setProjectFaqState: (key: any, value: any) =>
      dispatch(ProjectFaqActions.setProjectFaqState(key, value)),
    projectUpdateFaq: (id: any, data: any) =>
      dispatch(ProjectFaqActions.projectFaqUpdateRequest(id, data)),
    setALLProjectFaqState: (key: any, value: any) =>
      dispatch(AllProjectFaqsAction.setALLProjectFaqState(key, value)),
    projectFaqDelete: (data: any) =>
      dispatch(ProjectFaqActions.projectFaqDeleteRequest(data)),
    projectFaqsBulkUpdate: (data: any) =>
      dispatch(ProjectFaqActions.projectFaqBulkUpdateRequest(data)),
    getProjectFaqs: (params: any) =>
      dispatch(
        ProjectAllFaqsListAction.getProjectAllFaqsListDataRequest(params)
      ),
  };
};
export default function NewProjectFaqBox(props: any) {
  const {
    setProjectFaqState,
    projectUpdateFaq,
    setALLProjectFaqState,
    projectFaqDelete,
    projectFaqsBulkUpdate,
    getProjectFaqs,
  } = actionDispatch(useDispatch());
  const {
    fieldId,
    handleDelete,
    questionFieldValue,
    questionFieldName,
    answerFieldName,
    answerFieldValue,
    touchedQuestion,
    errorsQuestion,
    errorAnsfield,
    touchedAnsfield,
    isEditable,
    handleEditable,
  } = props;
  const { projectFaqs, mappedFaqs } = useSelector(
    (state: any) => state?.projectFaqs
  );
  const mappedFaqsBottomTableValues = useSelector(
    // by yuvraj
    (state: any) => state?.projectAllfaqs.allProjectFaqs
  );
  const {
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    isValid,
    dirty,
    errors,
  } = useFormikContext();
  const location: any = useLocation();
  const navigate: any = useNavigate();
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [openWarningDailog, setOpenWarningDialog] = useState(false);

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  const handleMarkStatus = () => {
    if (faqStatus.status === true) {
      const reqBody = {
        faqIds: [location.state.id],
        status: false,
        categoryId: location?.state?.categoryId,
      };
      const faqStatus = projectFaqs?.find(
        (faq: any) => faq?.id === location.state.id
      );

      projectFaqsBulkUpdate(reqBody);

      if (faqStatus.isMappedToggle === true) {
        const removeFalseFaqID = mappedFaqs.filter((id: any) => {
          return id !== location.state.id;
        });
        setALLProjectFaqState("isFaqAddOrRemoved", true);
        setProjectFaqState("mappedFaqs", removeFalseFaqID);
        setALLProjectFaqState("filterAllfaqBasedGivenArray", removeFalseFaqID);
      }
    } else {
      const reqBody = {
        faqIds: [location.state.id],
        status: true,
        categoryId: location?.state?.categoryId,
      };

      projectFaqsBulkUpdate(reqBody);
    }
  };

  const handleOpenFaqDialog = () => {
    setIsConfirmDeleteDialogOpen(true);
  };
  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };
  const handleClickAway = () => {
    setOpen(false);
  };
  const popOverComponent = (event: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
  };

  const faqStatus = projectFaqs?.find(
    (faq: any) => faq?.id === location?.state?.id
  );

  const deleteFaqsOnSelect = async () => {
    projectFaqDelete(faqStatus?.id);
    const faqStatusForDelete = await projectFaqs?.find(
      (faq: any) => faq?.id === faqStatus?.id
    );
    if (faqStatusForDelete.isMappedToggle === true) {
      const removeFalseFaqID = mappedFaqs.filter((id: any) => {
        return id !== faqStatusForDelete?.id;
      });
      setALLProjectFaqState("isFaqAddOrRemoved", true);
      setProjectFaqState("mappedFaqs", removeFalseFaqID);
      setALLProjectFaqState("filterAllfaqBasedGivenArray", removeFalseFaqID);
    }
    setIsConfirmDeleteDialogOpen(false);
  };

  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const handleMappedFaq = () => {
    if (
      mappedFaqsBottomTableValues?.length >= 2 &&
      faqStatus.isMappedToggle === false
    ) {
      setOpenWarningDialog(true);
    } else {
      const newMappedArray = projectFaqs.map((faq: any) => {
        if (faq.id === faqStatus.id) {
          if (faqStatus.isMappedToggle === false) {
            const newArray = mappedFaqs;
            newArray.push(faqStatus.id);
            setProjectFaqState("mappedFaqs", newArray);
            setALLProjectFaqState("isFaqAddOrRemoved", true);
            setALLProjectFaqState("filterAllfaqBasedGivenArray", newArray);
            return {
              ...faq,
              isMappedToggle: true,
            };
          }
          if (faqStatus.isMappedToggle === true) {
            const removeFalseFaqID = mappedFaqs.filter((id: any) => {
              return id !== faq.id;
            });
            setALLProjectFaqState("isFaqAddOrRemoved", true);
            setProjectFaqState("mappedFaqs", removeFalseFaqID);
            setALLProjectFaqState(
              "filterAllfaqBasedGivenArray",
              removeFalseFaqID
            );
            return {
              ...faq,
              isMappedToggle: false,
            };
          }
        } else {
          return { ...faq };
        }
      });
      setProjectFaqState("projectFaqs", newMappedArray);
    }
  };

  const handlePublishChanges = () => {
    const reqBody = {
      category: location?.state?.categoryId,
      faqQuestion: {
        question: questionFieldValue,
      },
      faqAnswer: {
        answer: answerFieldValue,
      },
    };
    projectUpdateFaq(faqStatus.id, reqBody);
    const getCategoryParams = {
      typeOfFAQ: FaqsTypeEnum.ProjectFaq,
      shouldDisplayMappedFaqs: false,
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    getProjectFaqs(getCategoryParams);

    const newMappedArray = projectFaqs.map((faq: any) => {
      if (faq.id === faqStatus.id) {
        if (faqStatus.isMappedToggle === true) {
          const newArray = mappedFaqs;
          newArray.push(faqStatus.id);
          setProjectFaqState("mappedFaqs", newArray);
          setALLProjectFaqState("isFaqAddOrRemoved", true);
          setALLProjectFaqState("filterAllfaqBasedGivenArray", newArray);
          return {
            ...faq,
            isMappedToggle: true,
          };
        }
      } else {
        return { ...faq };
      }
    });
    setProjectFaqState("projectFaqs", newMappedArray);
  };

  const handleCloseFlashMessageDialog = () => {
    setOpenWarningDialog(false);
  };
  return (
    <Box>
      <FlashMessageDialog
        shouldOpen={openWarningDailog}
        content={"We Cannot map more than two FAQs"}
        isSuccess={false}
        showWarning={true}
        cancelHandler={handleCloseFlashMessageDialog}
      />
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={2}>
          <Stack justifyContent={"flex-start"}>
            <Typography color={"common.black"} variant="h6">
              FAQ no.{fieldId + 1}
            </Typography>
            <IconButton
              disabled={location?.state?.isOnlyView && !isEditable}
              onClick={(event) => popOverComponent(event)}
            >
              {location?.state?.isFaqTable && (
                <SettingsOutlinedIcon fontSize="medium" />
              )}
            </IconButton>
          </Stack>
        </Grid>
        <Grid>
          {location.state.isFaqCreation &&
            location.state.isFaqTable &&
            (!location?.state?.isOnlyView || isEditable) && (
              <Stack spacing={2}>
                <ButtonComponent
                  onClick={() => {
                    navigate(RouteConstants?.projectCreation, {
                      state: { ...location.state },
                    });
                  }}
                >
                  Cancel
                </ButtonComponent>
                <LoadingButtonComponent
                  onClick={handlePublishChanges}
                  disabled={!isValid}
                >
                  Publish Changes
                </LoadingButtonComponent>
              </Stack>
            )}

          {location?.state?.isFaqCreation &&
            location?.state?.isFaqTable &&
            location?.state?.isOnlyView &&
            !isEditable && (
              <Stack spacing={2}>
                <ButtonComponent
                  onClick={() => {
                    navigate(
                      `${RouteConstants.projectCreation}/${RouteConstants.projectFaqCreation}`,
                      {
                        state: {
                          ...location.state,
                          categoryId: location?.state?.categoryId,
                          isFaqCreation: false,
                          isFaqTable: true,
                        },
                      }
                    );
                  }}
                >
                  Close
                </ButtonComponent>
                <ButtonComponent
                  variant="contained"
                  onClick={() => {
                    handleEditable();
                  }}
                >
                  Edit
                </ButtonComponent>
              </Stack>
            )}
        </Grid>
      </Grid>

      {location?.state?.isFaqTable && (
        <Box>
          <Box>
            <Typography color={"grey.A700"} variant="body1">
              Status:
              {faqStatus?.status ? "Published" : "Inactive"}
            </Typography>
          </Box>
          <Stack justifyContent="flex-start" spacing={1} mt={2} mb={2}>
            <SwitchComponent
              disabled={
                (location?.state?.isOnlyView && !isEditable) ||
                faqStatus?.status === false
              }
              checked={faqStatus?.isMappedToggle}
              onChange={handleMappedFaq}
              value={faqStatus?.isMappedToggle}
            />
            <Typography>Show on the project detailed section</Typography>
            <Tooltip
              title={
                "Toggle is to map this FAQ in project detailed page on the Customer App"
              }
              placement="top-start"
              arrow
            >
              <InfoOutlinedIcon
                fontSize="small"
                className="infooutlined-alignment"
              />
            </Tooltip>
          </Stack>
        </Box>
      )}
      {/* <Stack>

                <Typography color={"common.black"} variant="h6" >
                    FAQ No.{fieldId + 1}
                </Typography>
                <IconButton
                    size="small"
                    color="error"
                    aria-label="delete"
                    onClick={() => handleDelete(fieldId)}
                >
                    <DeleteOutlineOutlinedIcon fontSize="large" />
                </IconButton>
            </Stack> */}

      <TextFieldComponent
        label="Question"
        required
        onChange={handleChange}
        disabled={(location?.state?.isOnlyView && !isEditable) || isViewMode}
        placeholder="Enter"
        inputProps={{ maxLength: 150 }}
        value={questionFieldValue}
        name={questionFieldName}
        error={Boolean(touchedQuestion && errorsQuestion)}
        fieldhelpertext={
          touchedQuestion && errorsQuestion ? errorsQuestion : ""
        }
        onBlur={handleBlur}
      />

      <TextFieldComponent
        label="Answer"
        multiline
        rows={6}
        disabled={
          (location?.state?.isOnlyView &&
            location?.state?.isOnlyView &&
            !isEditable) ||
          isViewMode
        }
        required
        placeholder="Enter"
        inputProps={{ maxLength: 240 }}
        value={answerFieldValue}
        name={answerFieldName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(touchedAnsfield && errorAnsfield)}
        fieldhelpertext={touchedAnsfield && errorAnsfield ? errorAnsfield : ""}
      />

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Popper open={open} placement="right-start" anchorEl={anchor}>
          <Paper elevation={12}>
            <MenuList>
              <MenuItem sx={{ bgcolor: "white" }} onClick={handleMarkStatus}>
                {faqStatus?.status ? " Mark as Inactive" : "Mark as Published"}
              </MenuItem>
              <MenuItem sx={{ bgcolor: "white" }} onClick={handleOpenFaqDialog}>
                Delete
              </MenuItem>
            </MenuList>
          </Paper>
        </Popper>
      </ClickAwayListener>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        content={`Are you sure that you want to delete the  Faq(s)?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteFaqsOnSelect}
      />
    </Box>
  );
}
