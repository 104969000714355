import {
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box } from "@mui/system";
import styles from "./CustomerPaymentReceipts.module.css";
import {
  ButtonComponent,
  LoadingButtonComponent,
} from "components/ui-elements";
import { useDispatch, useSelector } from "react-redux";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import PDFViewDialog from "components/pdf-view-dialog/PDFViewDialog";
import CancelIcon from "@mui/icons-material/Cancel";
import { CustomerActions } from "redux-container/customer-management/CustomersListAndDetailsRedux";

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerDocument: async (params: any) => {
      dispatch(CustomerActions.getCustomerDocumentRequest(params));
    },
  };
};

const CustomerPaymentReceipts = (props: any) => {
  const { setShowAllPaymentReceipts } = props;
  const [openPdfDoc, setOpenPdfDoc] = useState(false);
  const { getCustomerDocument } = actionDispatch(useDispatch());
  const customerDocument: any = useSelector(
    (state: any) => state?.customerListAndDetails?.customerDocument
  );
  const [filePath, setFilePath] = useState("");
  const [isPdf, setIsPdf] = useState(false);
  const [URLofDocument, setURLofDocument] = useState("");
  const [showAllReceipts, setShowAllReceipts] = useState(false);
  const [documentType, setDocumentType] = useState("");

  const allPaymentcontent = useSelector(
    (state: any) =>
      state?.customerManagement?.bookingJourneyData?.data?.bookingJourney
        ?.paymentHistory
  );

  const allPaymentReceipts: any = useSelector(
    (state: any) =>
      state?.customerManagement?.bookingJourneyData?.data?.bookingJourney
        ?.paymentReceipts
  );

  const viewPaymentReceipt = async (path: string, documentType: any) => {
    setDocumentType(documentType);
    setFilePath(path);

    await getCustomerDocument(path);
    setOpenPdfDoc(true);
    if (path?.toLowerCase().includes(".pdf")) {
      setIsPdf(true);
    } else {
      setIsPdf(false);
    }
  };

  const isDocumentLoading: any = useSelector(
    (state: any) => state?.customerListAndDetails?.isLoading
  );

  function dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  const bookingJourneyContent = useSelector(
    (state: any) => state?.customerManagement?.bookingJourneyData?.data
  );

  React.useEffect(() => {
    setShowAllReceipts(false);
  }, []);

  React.useEffect(() => {
    if (!isDocumentLoading && customerDocument && openPdfDoc) {
      const blob = dataURItoBlob(customerDocument);
      const url = URL.createObjectURL(blob);
      setURLofDocument(url);
    }
  }, [customerDocument]);

  const handleCloseDialog = () => {
    setShowAllReceipts(false);
  };

  return (
    <Fragment>
      <PDFViewDialog
        shouldOpen={openPdfDoc}
        content={isPdf ? URLofDocument : customerDocument}
        cancelHandler={() => setOpenPdfDoc(false)}
        isLoading={isDocumentLoading}
    
        documentType={documentType}
        filePath={filePath}
      />
      <Stack textAlign={"start"} justifyContent={"start"} spacing={3}>
        <IconButton
          edge={"start"}
          onClick={() => setShowAllPaymentReceipts(false)}
        >
          <ChevronLeftIcon />
        </IconButton>

        <Typography variant={"h5"} component={"h5"}>
          All Receipts
        </Typography>
      </Stack>
      <Stack sx={{ marginLeft: "30px", marginTop: "20px" }}>
        <Button
          onClick={() => setShowAllReceipts(true)}
          disabled={allPaymentReceipts?.length > 0 ? false : true}
          sx={{
            textDecoration: "underline",
            marginBottom: -2,
            fontSize: "1.4rem",
          }}
          variant="text"
          color="primary"
        >
          Download Receipts
        </Button>
      </Stack>

      <Box className={styles["content-section"]}>
        {allPaymentcontent?.map((payment: any) => (
          <>
            <Stack mt={2}>
              <Stack
                flexDirection={"column"}
                spacing={3}
                textAlign={"start"}
                alignItems={"start"}
              >
                <Typography variant={"h6"} component={"h6"}>
                  Total Amount Paid:
                </Typography>
                <Typography
                  className={styles["payed-amount"]}
                  variant={"h6"}
                  component={"h3"}
                  color={"#676ac0"}
                >
                  ₹{payment?.paidAmount}
                </Typography>
              </Stack>

              <Stack
                flexDirection={"column"}
                spacing={3}
                textAlign={"end"}
                alignItems={"end"}
              >
                {payment?.paymentDate && (
                  <Typography
                    className={styles["payment-date"]}
                    variant={"h6"}
                    component={"h6"}
                  >
                    {formatDateTime(
                      payment?.paymentDate,
                      Constants.dateFormatDDMMMYY
                    )}
                  </Typography>
                )}
                {/* <ButtonComponent
                  sx={{ textDecoration: "underline", marginTop: "0.5rem" }}
                  variant="text"
                  color={"primary"}
                  onClick={() => viewPaymentReceipt(payment?.crmBookingId)}
                >
                  See Receipt
                </ButtonComponent> */}
              </Stack>
            </Stack>
            <Divider sx={{ marginTop: "10px" }} />
          </>
        ))}
      </Box>

      {showAllReceipts && (
        <Drawer
          anchor={"right"}
          open={showAllReceipts}
          onClose={handleCloseDialog}
          PaperProps={{
            sx: {
              width: "50%",
              padding: "20px 15px",
            },
          }}
        >
          <div>
            <Box>
              <Stack spacing={2} justifyContent={"space-between"} pl={2} pr={2}>
                <Typography className={styles["section-heading-name"]}>
                  Documents
                </Typography>

                <IconButton onClick={handleCloseDialog} color="error">
                  <CancelIcon className={styles["left-arrow"]} />
                </IconButton>
              </Stack>
            </Box>
            <Box mt={3}>
              {allPaymentReceipts?.map((item: any, index: number) => (
                <Paper
                  key={index}
                  sx={{ padding: "5px 25px", marginBottom: "10px" }}
                >
                  <Stack>
                    <Typography>{item?.name}</Typography>
                    <Button
                      onClick={() => {
                        viewPaymentReceipt(item?.path, item?.documentType);
                      }}
                      disabled={item?.path ? false : true}
                      sx={{
                        textDecoration: "underline",
                        marginTop: "0.5rem",
                      }}
                      variant="text"
                      color="primary"
                    >
                      View
                    </Button>
                  </Stack>
                </Paper>
              ))}
            </Box>
          </div>
        </Drawer>
      )}
    </Fragment>
  );
};

export default CustomerPaymentReceipts;
