import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemToLocalStorage,
} from "utils/LocalStorageUtils";

const { Types, Creators } = createActions({
  changePasswordSuccess: ["response"],
  changePasswordFailure: ["error"],
  changePasswordRequest: ["data"],

  setChangePasswordState: ["key", "value"],
});

export const ChangePasswordTypes = Types;

export const ChangePasswordActions = Creators;

export const INITIAL_STATE = {
  data: null,
  isLoading: false,
  dataRecived: false,
  error: null,
  isPasswordChangedSuccess: false,
};

export const changePasswordRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const changePasswordSuccess = (state: any, action: any) => {
  const user = getItemFromLocalStorage(Constants.loggedInUser);
  let userCopy = user;
  if (user && user.admin && user.admin.isResetPasswordCompleted === false) {
    userCopy.admin.isResetPasswordCompleted = true;
    removeItemFromLocalStorage(Constants.loggedInUser);
    setItemToLocalStorage(Constants.loggedInUser, userCopy);
  }
  return {
    ...state,
    data: action.response?.message,
    isLoading: false,
    dataRecived: true,
    error: null,
    isPasswordChangedSuccess: true,
  };
};

export const changePasswordFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    dataRecived: true,
    data: action.error?.message,
    error: action.response?.error,
  };
};

export const setChangePasswordState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [Types.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,
  [Types.CHANGE_PASSWORD_REQUEST]: changePasswordRequest,

  [Types.SET_CHANGE_PASSWORD_STATE]: setChangePasswordState,
});
