import { MediaUploadComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";

interface ITourismAroundMedia {
  index?: any;
  isViewModeProp?: any;
  currentlyOpenedIndex?: any;
  handleTourismAroundMedia: (imageIndex: number) => void;
}

function TourismAroundMedia({
  index,
  isViewModeProp,
  currentlyOpenedIndex,
  handleTourismAroundMedia,
}: ITourismAroundMedia) {
  const { values, handleChange, setFieldValue, errors, touched, handleBlur } =
    useFormikContext<IInitinalValueProjectContent>();

  const { imageDetails, isLoading, isSuccess, isDelete } = useSelector(
    (state: any) => state.fileUpload
  );
  const dispatch = useDispatch();

  const [tourismAroundMediaValue, setTourismAroundMediaValue] = useState<any[]>(
    []
  );
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  //upload Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const insightMediaImageUploadSuccess = `opportunityDoc.tourismAround.stories.${index}.media.value.url`;
      if (
        tourismAroundMediaValue[0] === insightMediaImageUploadSuccess &&
        index === currentlyOpenedIndex
      ) {
        setFieldValue(insightMediaImageUploadSuccess, imageDetails?.url);
        handleTourismAroundMedia(999);
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  //delete Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const insightMediaImageUploadDelete = `opportunityDoc.tourismAround.stories.${index}.media.value.url`;
      if (
        insightMediaImageUploadDelete === tourismAroundMediaValue[0] &&
        index === currentlyOpenedIndex
      ) {
        const empty = "";
        setFieldValue(insightMediaImageUploadDelete, empty);
      }
    }
    getAllInsightsCreationFunction();
  }, [isDelete]);

  const handleuploadImage = (event: any) => {
    const file = event?.target?.files[0];
    const MediaId = event?.target?.id;

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
        setTourismAroundMediaValue((prevState) => [...prevState, MediaId]);
      };
      handleTourismAroundMedia(index);
      reader.readAsArrayBuffer(file);
      const currentInfraStoryMediaName = `opportunityDoc.tourismAround.stories.${index}.media.name`;
      const currentInfraStoryMediaKey = `opportunityDoc.tourismAround.stories.${index}.media.key`;
      setFieldValue(currentInfraStoryMediaName, file?.name);
      setFieldValue(currentInfraStoryMediaKey, file?.name);
    }
  };

  const handleConfirmDelete = () => {
    let fileName =
      values?.opportunityDoc?.tourismAround?.stories[index]?.media?.name;
    let fileUrl = `opportunityDoc.tourismAround.stories.${index}.media.value.url`;
    setTourismAroundMediaValue((prevState) => [...prevState, fileUrl]);
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
  };

  return (
    <MediaUploadComponent
      ondrop={handleChange}
      loading={isLoading && index === currentlyOpenedIndex}
      name={`opportunityDoc.tourismAround.stories.${index}.media.value.url`}
      value={
        values?.opportunityDoc?.tourismAround?.stories[index]?.media?.value?.url
      }
      onChange={(event: any) => {
        handleuploadImage(event);
      }}
      previewimage={
        values?.opportunityDoc?.tourismAround?.stories[index]?.media?.value?.url
      }
      cancelimage={() => {
        handleTourismAroundMedia(index);
        handleConfirmDelete();
      }}
      onBlur={handleBlur}
      fieldhelpertext={
        getIn(
          touched,
          `opportunityDoc.tourismAround.stories.${index}.media.value.url`
        ) &&
        getIn(
          errors,
          `opportunityDoc.tourismAround.stories.${index}.media.value.url`
        )
      }
      disabled={isViewMode || isViewModeProp}
    />
  );
}

export default TourismAroundMedia;
