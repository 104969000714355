import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { InsightsCreationActions } from "redux-container/insights-creation/InsightsCreationRedux";

//creation Insights
export function* insightsCreation(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.postInsightsCreation, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InsightsCreationActions.insightsCreationSuccess(response.data));
    } else {
      yield put(InsightsCreationActions.insightsCreationFailure(response.data));
    }
  } catch (error) {
    yield put(InsightsCreationActions.insightsCreationFailure(error));
  }
}

// all Insights
export function* allInsightsCreation(api: any, action: any): any {
  try {
    const response = yield call(api.getAllInsightsCreation, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        InsightsCreationActions.getAllInsightsCreationSuccess(response.data)
      );
    } else {
      yield put(
        InsightsCreationActions.getAllInsightsCreationFailure(response.data)
      );
    }
  } catch (error) {
    yield put(InsightsCreationActions.getAllInsightsCreationFailure(error));
  }
}

//Get by id Insigths
export function* getByIdInsights(api: any, action: any): any {
  try {
    const response = yield call(api.getInsightsById, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InsightsCreationActions.getInsightsByIdSuccess(response.data));
    } else {
      yield put(InsightsCreationActions.getInsightsByIdFailure(response.data));
    }
  } catch (error) {
    yield put(InsightsCreationActions.getInsightsByIdFailure(error));
  }
}

//updated a creation Insights
export function* insightsCreationUpdate(api: any, action: any): any {
  try {
    const response = yield call(api.updateInsightsCreation, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        InsightsCreationActions.insightsCreationUpdateSuccess(response.data)
      );
    } else {
      yield put(
        InsightsCreationActions.insightsCreationUpdateFailure(response.data)
      );
    }
  } catch (error) {
    yield put(InsightsCreationActions.insightsCreationUpdateFailure(error));
  }
}

//delete a creation Insights
export function* insightsCreationDelete(api: any, action: any): any {
  try {
    const response = yield call(api.deleteInsightsCreation, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        InsightsCreationActions.insightsCreationDeleteSuccess(response.data)
      );
    } else {
      yield put(
        InsightsCreationActions.insightsCreationDeleteFailure(response.data)
      );
    }
  } catch (error) {
    yield put(InsightsCreationActions.insightsCreationDeleteFailure(error));
  }
}

//Update status of Creation Insights
export function* insightsCreationUpdateStatus(api: any, action: any): any {
  try {
    const response = yield call(api.updateInsightsStatuses, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        InsightsCreationActions.insightsCreationDeleteSuccess(response.data)
      );
    } else {
      yield put(
        InsightsCreationActions.insightsCreationDeleteFailure(response.data)
      );
    }
  } catch (error) {
    yield put(InsightsCreationActions.insightsCreationDeleteFailure(error));
  }
}
