import * as Yup from "yup";

export const appUpdatesValidationSchema = Yup.object({
  title: Yup.string().required("Title is mandatory").nullable(),
  description: Yup.string().required("Description is mandatory").nullable(),
  shouldNotifyAndroid: Yup.boolean(),
  shouldNotifyIOS: Yup.boolean(),
}).test("atLeastOneTrue", "", (values) => {
  const { shouldNotifyAndroid, shouldNotifyIOS } = values;

  if (!shouldNotifyAndroid && !shouldNotifyIOS) {
    return new Yup.ValidationError(
      "At least one of the boolean variables must be true",
      null,
      "atLeastOneTrue"
    );
  }
  return true;
});
