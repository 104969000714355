import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./InsightCreationMediaDialog.module.css";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldComponent from "../../../../components/ui-elements/text-field/TextFieldComponent";
import {
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import { Form, useFormikContext } from "formik";
import {
  LoadingButtonComponent,
  RadioButtonGroupComponent,
  StyledRadio,
} from "components/ui-elements";
import { InsightsCreationConstants } from "./InsightsCreationConstants";
import ImageUploadReplaceDragDrop from "components/ui-elements/image-uploader-dropdown/image-upload-replace/ImageUploadReplaceDragDrop";
import { useEffect, useState } from "react";
import { mediaType } from "./InsightsCreationInitialValue";
import { IInsightsCreation } from "./InsightsCreationInitialValue";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { isValidYoutubeUrl } from "utils/ValidationUtils";

interface IInsightCreationMediaDialog {
  shouldOpen: boolean;
  title: string;
  cancelHandler: (imageIndex: number) => void;
  mediaUploadSuccess?: () => void;
  imageOrVideoIndex: number;
  imageVideoToggle?: () => void;
  handleDialogSuccessFullyClose: () => void;
}

const InsightCreationMediaDialog = ({
  shouldOpen,
  cancelHandler,
  title,
  mediaUploadSuccess,
  imageOrVideoIndex,
  imageVideoToggle,
  handleDialogSuccessFullyClose,
}: IInsightCreationMediaDialog) => {
  const handleClose = () => {
    cancelHandler(999);
    setFieldValue(
      `insightsMedia[${imageOrVideoIndex}].media.value.isCancel`,
      true
    );
    const insightMediaImageUpload = `insightsMedia[${imageOrVideoIndex}].media.value.imageDialogUrl`;
    setFieldValue(insightMediaImageUpload, "");
    handleDialogSuccessFullyClose();
  };

  const handleCloseOnMediaUpload = () => {
    cancelHandler(999);
    handleDialogSuccessFullyClose();
    const insightMediaImageUpload = `insightsMedia[${imageOrVideoIndex}].media.value.url`;
    if (
      values?.insightsMedia[imageOrVideoIndex]?.media?.value?.imageDialogUrl !==
        "" &&
      values?.insightsMedia[imageOrVideoIndex]?.media?.value?.mediaType ===
        "IMAGE"
    ) {
      setFieldValue(
        `insightsMedia[${imageOrVideoIndex}].media.value.successFullyUploadedType`,
        "IMAGE"
      );
      setFieldValue(
        insightMediaImageUpload,
        values?.insightsMedia[imageOrVideoIndex]?.media?.value?.imageDialogUrl
      );
    }
  };
  const { values, handleChange, handleSubmit, setFieldValue, resetForm } =
    useFormikContext<IInsightsCreation>();

  const dispatch = useDispatch();
  const { imageDetails, isLoading, error, isSuccess, fileName, isDelete } =
    useSelector((state: any) => state.fileUpload);

  const HandleImageUpload = () => {
    // mediaUploadSuccess();
    setFieldValue(
      `insightsMedia[${imageOrVideoIndex}].media.value.isPreview`,
      true
    );
    setFieldValue(
      `insightsMedia[${imageOrVideoIndex}].media.value.isCancel`,
      false
    );
  };

  const handleVideoUpload = () => {
    cancelHandler(999);
    handleDialogSuccessFullyClose();
    const videoId = isValidYoutubeUrl(
      values?.insightsMedia[imageOrVideoIndex]?.media?.value?.videoDialogUrl
    );
    {
      let videoMergeUrl = `https://www.youtube.com/embed/${videoId}`;
      const fileNameOfMedia = `insightsMedia[${imageOrVideoIndex}].media.name`;
      const fileSizeOfMedia = `insightsMedia[${imageOrVideoIndex}].media.value.size`;
      const keyOfMedia = `insightsMedia[${imageOrVideoIndex}].media.key`;
      const IsActiveOfMedia = `insightsMedia[${imageOrVideoIndex}].media.isActive`;
      if (
        values?.insightsMedia[imageOrVideoIndex]?.media?.value?.mediaType ===
        "VIDEO"
      ) {
        setFieldValue(
          `insightsMedia[${imageOrVideoIndex}].media.value.successFullyUploadedType`,
          "VIDEO"
        );
        setFieldValue(
          `insightsMedia[${imageOrVideoIndex}].media.value.url`,
          videoMergeUrl
        );
        setFieldValue(keyOfMedia, videoMergeUrl);
        setFieldValue(IsActiveOfMedia, true);
        setFieldValue(fileNameOfMedia, videoMergeUrl);
        setFieldValue(fileSizeOfMedia, 0);
      }
    }
  };

  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      if (imageDetails) {
        const fileNameOfMediaPreview = `insightsMedia[${imageOrVideoIndex}].media.value.imageDialogUrl`;
        setFieldValue(fileNameOfMediaPreview, imageDetails?.url);
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  //delete
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const empty = "";
      const insightMediaImageUpload = `insightsMedia[${imageOrVideoIndex}].media.value.imageDialogUrl`;
      setFieldValue(insightMediaImageUpload, empty);
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isDelete", false));
  }, [isDelete]);

  const handleuploadImage = (file: any) => {
    // let file = event?.target?.files[0];
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
      };
      reader.readAsArrayBuffer(file);

      const fileNameOfMedia = `insightsMedia[${imageOrVideoIndex}].media.name`;
      const fileSizeOfMedia = `insightsMedia[${imageOrVideoIndex}].media.value.size`;
      const keyOfMedia = `insightsMedia[${imageOrVideoIndex}].media.key`;
      const IsActiveOfMedia = `insightsMedia[${imageOrVideoIndex}].media.isActive`;
      if (
        values?.insightsMedia[imageOrVideoIndex]?.media?.value?.mediaType ===
        "IMAGE"
      ) {
        setFieldValue(keyOfMedia, file?.name);
        setFieldValue(IsActiveOfMedia, true);
        setFieldValue(fileNameOfMedia, file?.name);
        setFieldValue(fileSizeOfMedia, file?.size);
      }
    }
    return;
  };

  const handleDialogClose = () => {
    dispatch(FileUploadActions.setUploadFileState("imageDetails", null));
  };

  return (
    <Dialog
      data-testid="main-dialog-box"
      fullWidth
      maxWidth={"lg"}
      open={shouldOpen}
      onClose={() => {
        handleClose();
        handleDialogClose();
      }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle data-testid="dialog-title" color="black">
        <Typography variant="h5" component="h5">
          {title}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={() => {
          handleClose();
          handleDialogClose();
          // imageVideoToggle(); //On close the the Dialog the
        }}
        className={styles["close-icon-btn-wrapper"]}
        aria-label="close-icon"
      >
        <CloseIcon className={styles["close-icon-btn"]} />
      </IconButton>

      <DialogContent style={{ height: "22rem" }}>
        <Form
          onSubmit={handleSubmit}
          noValidate
          className={styles["form-section"]}
        >
          <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <RadioButtonGroupComponent
                name={`insightsMedia[${imageOrVideoIndex}].mediaType`}
                value={
                  values?.insightsMedia[imageOrVideoIndex]?.media.value
                    .mediaType
                }
                onChange={handleChange}
                onClick={imageVideoToggle}
                data-testid="select-option"
                aria-label="insights-type-selection"
              >
                {mediaType.map((ImageOrVideo) => {
                  return (
                    <FormControlLabel
                      key={ImageOrVideo.id}
                      name={`insightsMedia[${imageOrVideoIndex}].media.value.mediaType`}
                      value={ImageOrVideo?.value}
                      control={<StyledRadio />}
                      label={ImageOrVideo.label}
                      onChange={handleChange}
                      checked={
                        values.insightsMedia[imageOrVideoIndex].media.value
                          .mediaType === ImageOrVideo.value
                      }
                    />
                  );
                })}
              </RadioButtonGroupComponent>
            </Grid>

            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Collapse
                in={
                  values.insightsMedia[imageOrVideoIndex]?.media?.value
                    ?.mediaType === mediaType[0].value
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={3.5} md={3.5} lg={3.5} xl={3.5}>
                    <ImageUploadReplaceDragDrop
                      uploadMediaButton={false}
                      index={imageOrVideoIndex}
                      currentlyOpenedIndex={imageOrVideoIndex}
                      name={`insightsMedia[${imageOrVideoIndex}].media.value.imageDialogUrl`}
                      dragAndDropButton={true}
                      editDeleteButton={false}
                      onDrop={handleChange}
                      onChange={(file: any) => handleuploadImage(file)}
                      previewimage={
                        !!values?.insightsMedia[imageOrVideoIndex]?.media?.value
                          ?.imageDialogUrl
                          ? values?.insightsMedia[imageOrVideoIndex]?.media
                              ?.value?.imageDialogUrl
                          : values.insightsMedia[imageOrVideoIndex].media.value
                              ?.url
                      }
                      fieldhelpertext={""}
                    />
                  </Grid>

                  <Grid item xs={8.5} md={8.5} lg={8.5} xl={8.5}>
                    <Stack
                      direction={"column"}
                      sx={{ height: "100%" }}
                      alignItems={"end"}
                    >
                      <TextFieldComponent
                        placeholder="Enter"
                        fullWidth
                        name={`insightsMedia[${imageOrVideoIndex}].media.mediaDescription`}
                        label={"Description"}
                        value={
                          values.insightsMedia[imageOrVideoIndex].media
                            .mediaDescription
                        }
                        onChange={handleChange}
                        inputProps={{
                          maxLength: InsightsCreationConstants.imageDescription,
                        }}
                      />

                      <LoadingButtonComponent
                        type="button"
                        // disabled={
                        //   !values.insightsMedia[imageOrVideoIndex].media.value
                        //     .imageDialogUrl
                        // }
                        loading={isLoading}
                        variant="contained"
                        disableElevation
                        onClick={() => {
                          handleCloseOnMediaUpload();
                          HandleImageUpload();
                          handleDialogClose();
                        }}
                      >
                        UPLOAD
                      </LoadingButtonComponent>
                    </Stack>
                  </Grid>
                </Grid>
              </Collapse>

              <Collapse
                in={
                  values.insightsMedia[imageOrVideoIndex]?.media?.value
                    .mediaType === mediaType[1].value
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={5} md={5} lg={5} xl={5}>
                    <TextFieldComponent
                      inputProps={{ "data-testid": "name-textField" }}
                      placeholder="Enter"
                      name={`insightsMedia[${imageOrVideoIndex}].media.value.videoDialogUrl`}
                      label={"Video URL"}
                      value={
                        values.insightsMedia[imageOrVideoIndex].media.value
                          .videoDialogUrl
                      }
                      required
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={7} md={7} lg={7} xl={7}>
                    <Stack
                      direction={"column"}
                      alignItems={"end"}
                      sx={{ height: "200%" }}
                    >
                      <TextFieldComponent
                        placeholder="Enter"
                        name={`insightsMedia[${imageOrVideoIndex}].media.mediaDescription`}
                        fullWidth
                        label={"Description"}
                        value={
                          values.insightsMedia[imageOrVideoIndex].media
                            .mediaDescription
                        }
                        onChange={handleChange}
                        inputProps={{
                          maxLength: InsightsCreationConstants.videoDescription,
                        }}
                      />
                      <LoadingButtonComponent
                        type="button"
                        disabled={
                          !values.insightsMedia[imageOrVideoIndex].media
                            .mediaDescription ||
                          (!values.insightsMedia[imageOrVideoIndex].media.value
                            .videoDialogUrl &&
                            true)
                        }
                        variant="contained"
                        disableElevation
                        onClick={() => {
                          HandleImageUpload();
                          handleVideoUpload();
                        }}
                      >
                        UPLOAD
                      </LoadingButtonComponent>
                    </Stack>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default InsightCreationMediaDialog;
