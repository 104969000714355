import { Divider, Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonComponent } from "components/ui-elements";
import { ReactNode } from "react";
import styles from "./CustomerRegistrationDetailsDialog.module.css";

export interface ICustomerRegistration{
    fieldTitle : string;
    value : string | number;
  };


interface ICustomerRegistrationDetailsDialog {
  size?: any;
  shouldOpen: boolean;
  title?: string;
  content: ICustomerRegistration[];
  cancelHandler: (e?: any) => any;
  cancelBtnText?: string;
}

const CustomerRegistrationDetailsDialog = ({
  size="xs",
  shouldOpen,
  title,
  content,
  cancelHandler,
  cancelBtnText = "OKAY",
}: ICustomerRegistrationDetailsDialog) => {

  const handleClose = () => {
    cancelHandler();
  };

  return (
    <Dialog
      maxWidth={size}
      fullWidth
      open={shouldOpen}
      disableEscapeKeyDown={true}
      sx={{textAlign:'center'}}
    >
    <Stack justifyContent={'center'}>
        <DialogTitle>
            <Typography fontWeight={600} variant={'h5'} component={'h6'}>
                {title}
            </Typography>
        </DialogTitle>
    </Stack>

      <DialogContent>
        {content?.map((item : any, index : any) =>(
            <div className={index !== 0 ? 'margin-top-20px' : ''}>
            <Typography variant={'h6'} component={'h6'}>
                {item?.fieldTitle}
            </Typography>

            <Typography fontWeight={600} variant={'h6'} component={'h6'}>
                {item?.value}
            </Typography>
            </div>
        ) )}
      </DialogContent>

        <Divider />
      <DialogActions className={styles["dialog-actions"]}>
        <ButtonComponent
            className={styles["okay-btn"]}
            variant="text"
            color="inherit"
            onClick={handleClose}
        >
            {cancelBtnText}
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerRegistrationDetailsDialog;
