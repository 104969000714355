import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  ButtonComponent,
  FormFieldContainer,
  LoadingButtonComponent,
  TextFieldComponent,
} from "../../../components/ui-elements";
import {
  ClickAwayListener,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import PageHeading from "../../../components/page-heading/PageHeading";
import ProjectListDialog from "./project-list-dialog/ProjectListDialog";
import {
  ProjectCreationConstants,
  ProjectCreationLabels,
} from "./ProjectCollectionCreationConstant";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { formatDateTime } from "utils/DateFormatterUtils";
import { RouteConstants } from "routes/RouteConstants";
import { UpdatesStatusTypeEnum } from "enumerations/StatusTypeEnum";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import ProjectCollectionActions from "../../../redux-container/project-management/project-collection/ProjectCollectionCreationRedux";
import ProjectCollectionListAction from "redux-container/project-management/project-collection/ProjectCollectionListRedux";
import { Constants } from "constants/Constants";
import moment from "moment";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import styles from "./ProjectCollectionCreation.module.css";
import projectListActions from "redux-container/project-management/ProjectListRedux";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
interface IProjectCollectionCreation {
  collectionName: string;
  priority?: number;
}

const actionDispatch = (dispatch: any) => {
  return {
    addProjectCollection: (data: any) =>
      dispatch(ProjectCollectionActions.addProjectCollectionRequest(data)),
    getProjectCollectionById: (id: any) =>
      dispatch(ProjectCollectionActions.getProjectCollectionByIdRequest(id)),
    setProjectCollectionState: (key: any, data: any) =>
      dispatch(ProjectCollectionActions.setProjectCollectionState(key, data)),
    deleteProjectCollectionById: (id: any) =>
      dispatch(ProjectCollectionActions.deleteProjectCollectionByIdRequest(id)),
    updateProjectCollectionById: (id: any, data: any) =>
      dispatch(
        ProjectCollectionActions.updateProjectCollectionByIdRequest(id, data)
      ),
    setProjectCollectionListState: (key: any, data: any) =>
      dispatch(
        ProjectCollectionListAction.setProjectCollectionListState(key, data)
      ),
    updateBulkProjectCollectionListStatus: (data: any) =>
      dispatch(
        ProjectCollectionListAction.changeProjectCollectionListStatusRequest(
          data
        )
      ),
    getProjectList: async (params: any) => {
      dispatch(projectListActions.projectListRequest(params));
    },
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
  };
};

const ProjectCollectionCreation = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  console.log("location", location);

  const error = useSelector(
    (state: any) => state?.projectCollectionCreation?.error
  );
  const message = useSelector(
    (state: any) => state?.projectCollectionCreation?.message
  );

  const isLoading = useSelector(
    (state: any) => state?.projectCollection?.isLoading
  );
  const isSuccess = useSelector(
    (state: any) => state?.projectCollectionCreation?.isSuccess
  );
  const collectionData = useSelector(
    (state: any) => state?.projectCollectionCreation?.projectCollectionData
  );
  const isAddedNewProject = useSelector(
    (state: any) => state?.projectCollectionCreation?.isAddedNewProject
  );
  const projectCollectionId = useSelector(
    (state: any) => state?.projectCollectionCreation?.projectCollectionId
  );
  const singleProjectCollection: any = useSelector(
    (state: any) => state?.projectCollectionCreation?.singleProjectCollection
  );

  const isSingleProjectCollection = useSelector(
    (state: any) => state?.projectCollectionCreation?.isSingleProjectCollection
  );
  const creationProjectCollection = useSelector(
    (state: any) => state?.projectCollectionCreation?.creationProjectCollection
  );

  const isCreationProjectCollection = useSelector(
    (state: any) =>
      state?.projectCollectionCreation?.isCreationProjectCollection
  );
  const dataRecivedProjectCollection = useSelector(
    (state: any) =>
      state?.projectCollectionCreation?.dataRecivedProjectCollection
  );
  const saveProjectCollection = useSelector(
    (state: any) => state?.projectCollectionCreation?.saveProjectCollection
  );
  const mappedprojectCollectionWithPriority = useSelector(
    (state: any) =>
      state?.projectCollectionCreation?.mappedprojectCollectionWithPriority
  );
  const projects = useSelector((state: any) => state.projectList?.data.data);
  const {
    addProjectCollection,
    getProjectCollectionById,
    setProjectCollectionState,
    updateProjectCollectionById,
    deleteProjectCollectionById,
    setProjectCollectionListState,
    updateBulkProjectCollectionListStatus,
    getProjectList,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());

  const [isEditMode, setIsEditMode] = useState(
    location?.state?.isEditMode ? location?.state?.isEditMode : false
  );
  const [isViewMode, setIsViewMode] = useState(
    location?.state?.isViewMode ? location?.state?.isViewMode : false
  );
  const [
    isAddProjectsToCollectionDialogOpen,
    setIsAddProjectsToCollectionDialogOpen,
  ] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [projectListWithPriority, setProjectListWithPriority] = useState([]);
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const [isRemoveHandle, setIsRemoveHandle] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [priority, setPriority] = useState(0);
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [isAllProjectList, setAllProjectList] = useState(false);
  const [openProjectRemoveDialog, setOpenProjectRemoveDialog] = useState(false);
  const [removeProjectById, setremoveProjectById] = useState(null);
  const [isSaveConfirmationDialogOpen, setIsSaveConfirmationDialogOpen] =
    useState(false);
  const [
    isSaveAndNewConfirmationDialogOpen,
    setIsSaveAndNewConfirmationDialogOpen,
  ] = useState(false);
  const [isEditConfirmationDialogOpen, setIsEditConfirmationDialogOpen] =
    useState(false);

  const [previousStates, setPreviousStates] = useState<any>({
    singleProjectCollection: singleProjectCollection,
    collectionName: location?.state?.projectCollection?.name,
  });
  const [modulesAudited, setModulesAudited] = useState<any>(null);

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);
  const modules = useSelector((state: any) => state?.rolesAndModules?.module);
  const auditedObject = useSelector(
    (state: any) => state?.projectCollectionCreation?.auditedObject
  );
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (singleProjectCollection && count === 0) {
      setPreviousStates((prev: any) => {
        return {
          ...prev,
          singleProjectCollection: singleProjectCollection,
          collectionName: location?.state?.projectCollection?.name,
        };
      });
      setCount((prev: number) => prev + 1);
    }
  }, [singleProjectCollection]);
  const breadcrumbRoutes: any = [
    {
      name: "Project Management",
      route: RouteConstants.projectManagementDashboard,
    },
    {
      name: "Project Collections",
      route: RouteConstants.projectCollections,
    },
    {
      name: location.state?.projectCollection
        ? `${(isEditMode === true && "Edit : ") || "View :"}
        ${location.state.projectCollection.name}`
        : "Create New",
      route: RouteConstants.projectCollectionCreation,
    },
  ];

  useEffect(() => {
    getProjectCollectionById(location?.state?.projectCollection?.id);
  }, []);

  useEffect(() => {
    if (isAddedNewProject) {
      getProjectCollectionById(location?.state?.projectCollection?.id);
      setProjectCollectionState("isAddedNewProject", false);
    }
  }, [isAddedNewProject]);

  useEffect(() => {
    if (isSingleProjectCollection) {
      setProjectListWithPriority(
        singleProjectCollection?.collectionsOrProjectContents
      );
      setProjectCollectionState("isSingleProjectCollection", false);
    } else if (creationProjectCollection) {
      setProjectListWithPriority(creationProjectCollection);
    }
  }, [singleProjectCollection, creationProjectCollection]);

  const initialValues: IProjectCollectionCreation = {
    collectionName: collectionData ? collectionData?.collectionName : "",
  };

  const validationSchema = Yup.object().shape({
    collectionName: Yup.string().required("Required"),
  });

  const tableHeadings = [
    { heading: "Project ID" },
    { heading: "Thumbnail" },
    { heading: "Name" },
    { heading: "Location" },
    { heading: "Starting Price" },
    { heading: "Starting Area" },
    { heading: "Created on" },
    { heading: "Priority" },
    { heading: "Actions" },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleCloseFlashMessageDialog = () => {
    if (isSuccess) {
      setProjectCollectionState("isSuccess", false);
      const moduleObject = modules?.find((element: any) => {
        return element?.name === "Project Management";
      });
      if (modulesAudited?.length) {
        const postAuditRequestbody = {
          ipAddress: ipAddress, //ipAddress
          moduleId: moduleObject?.id,
          modulesAudited: modulesAudited,
          status: location.state ? AuditStatus.EDITED : AuditStatus.CREATED,
          auditedObject: location.state ? location.state : auditedObject,
          route: RouteConstants.projectCollectionCreation,
        };
        postAuditLogRequest(postAuditRequestbody);
      }
      setProjectCollectionState("singleProjectCollection", null);
    }

    setProjectCollectionState("dataRecivedProjectCollection", false);
    setProjectCollectionState("message", null);
    setProjectCollectionState("error", null);
    setProjectCollectionListState("dataRecievedProjectCollectionList", false);
    setProjectCollectionListState("message", null);
    setProjectCollectionListState("error", null);
    if (saveProjectCollection === true) {
      navigate(RouteConstants.projectCollections);
    } else {
      navigate(RouteConstants?.projectCollectionCreation);
      setProjectCollectionState("creationProjectCollection", "");
    }
  };

  const handleGlobalSave = (values: any) => {
    let auditObject: any = {
      itemAffected: `Collection ${values?.collectionName} Created`,
      newValue: "",
      oldValue: "",
    };
    setModulesAudited([auditObject]);

    setIsSaveConfirmationDialogOpen(false);
    const listOfIds = (projectListWithPriority && projectListWithPriority)?.map(
      (project: any) => project.id
    );
    // const body: any = {
    //   name: values.collectionName,
    //   projectContents: listOfIds,
    //   createdBy: 22,
    // };
    const body: any = {
      name: values.collectionName,
      projectContents: listOfIds,
      projectsWithPriority: listOfIds?.map((item: any, index: number) => {
        return { id: item, priority: index + 1 };
      }),
    };
    addProjectCollection(body);
    setProjectCollectionState("saveProjectCollection", true);
    setShowFlashMessage(true);
  };

  const handleEditChange = () => {
    setIsSaveConfirmationDialogOpen(true);
  };

  const onEditProjectCollection = (values: any, resetForm: any) => {
    let presentValues: any = {
      singleProjectCollection: singleProjectCollection,
      // singleProjectCollection: creationProjectCollection,
      collectionName: values?.collectionName,
    };
    let auditObjectsArray: any[] = [];
    let auditObjectSingleProjectCollection: any = {
      itemAffected: `${ProjectCreationLabels.singleProjectCollection}`,
      newValue: presentValues?.singleProjectCollection
        ? presentValues?.singleProjectCollection
            ?.map((project: any) => project?.launchName)
            .join(", ")
        : "",
      oldValue: previousStates?.singleProjectCollection
        ? previousStates?.singleProjectCollection
            ?.map((project: any) => project?.launchName)
            .join(", ")
        : "",
    };
    if (
      auditObjectSingleProjectCollection?.newValue !==
      auditObjectSingleProjectCollection?.oldValue
    ) {
      auditObjectsArray.push(auditObjectSingleProjectCollection);
    }
    let auditObjectCollectionName: any = {
      itemAffected: `${ProjectCreationLabels.collectionName}`,
      newValue: presentValues?.collectionName
        ? presentValues?.collectionName
        : "",
      oldValue: previousStates?.collectionName
        ? previousStates?.collectionName
        : "",
    };
    if (
      auditObjectCollectionName?.newValue !==
      auditObjectCollectionName?.oldValue
    ) {
      auditObjectsArray.push(auditObjectCollectionName);
    }

    setModulesAudited(auditObjectsArray);

    setIsEditConfirmationDialogOpen(false);
    const listOfIds: any = [];
    singleProjectCollection?.forEach((project: any) =>
      listOfIds.push(project.id)
    );
    const reqbody: any = {
      name: values.collectionName,
      projectContents: listOfIds,
      projectsWithPriority: listOfIds?.map((item: any, index: number) => {
        return { id: item, priority: index + 1 };
      }),
    };
    updateProjectCollectionById(projectCollectionId.id, reqbody);
    setProjectCollectionState("saveProjectCollection", true);
  };

  const handleChangeCancel = () => {
    navigate(-1);
  };

  const handleSaveAndNew = () => {
    setIsSaveConfirmationDialogOpen(true);
  };

  const handleChangeSaveNew = (values: any, resetForm: any) => {
    setIsSaveAndNewConfirmationDialogOpen(false);
    const listOfIds = (projectListWithPriority && projectListWithPriority).map(
      (project: any) => project.id
    );
    const body: any = {
      name: values.collectionName,
      projectContents: listOfIds,
    };
    addProjectCollection(body);
    setProjectListWithPriority([]);
    setProjectCollectionState("saveProjectCollection", false);
    setShowFlashMessage(true);
    resetForm(body);
  };

  const handleProjectCollection = () => {
    navigate(RouteConstants.projectCollections);
  };

  const handleOpenStatusClick = (event: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    setIsViewMode(false);
  };

  const addProjectsToCollection = () => {
    setIsAddProjectsToCollectionDialogOpen(true);
    setAllProjectList(true);
  };

  const deleteProjectCollection = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const deleteProjectCollectionId = (id: number) => {
    deleteProjectCollectionById(id);
    setShowFlashMessage(true);
    setIsConfirmDeleteDialogOpen(false);
    setProjectCollectionState("saveProjectCollection", true);
  };

  const handleMarkStatus = () => {
    if (projectCollectionId?.status === UpdatesStatusTypeEnum.Published) {
      const reqBody1 = {
        collectionIds: [projectCollectionId.id],
        status: UpdatesStatusTypeEnum.Inactive,
      };
      updateBulkProjectCollectionListStatus(reqBody1);
    } else {
      const reqBody1 = {
        collectionIds: [projectCollectionId.id],
        status: UpdatesStatusTypeEnum.Published,
      };
      updateBulkProjectCollectionListStatus(reqBody1);
    }
    setProjectCollectionState("saveProjectCollection", true);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
    setIsEditConfirmationDialogOpen(false);
    setOpenProjectRemoveDialog(false);
    setIsSaveAndNewConfirmationDialogOpen(false);
    setIsSaveConfirmationDialogOpen(false);
  };

  const [isStatusChanged, setStatusChanged] = useState(false);

  const handlePriorityChangeIndex = (
    indexValue: number, // index value of that loop
    list: any, // main list which we are mapping
    priorityUpdatevalue: number, // giving priority value
    updatedItem: any // updated priority item of the list
  ) => {
    // if (indexValue) {
    list.splice(indexValue, 1);
    list.splice(indexValue + priorityUpdatevalue, 0, updatedItem);
    setStatusChanged(true);
    return list;
    // }
  };

  useEffect(() => {
    if (isStatusChanged) {
      setStatusChanged(false);
    }
  }, [isStatusChanged]);

  const handleCloseAddProjectsToCollectionDialog = () => {
    setIsAddProjectsToCollectionDialogOpen(false);
  };

  const removeHandler = (id: any) => {
    setIsRemoveHandle(false);
    setOpenProjectRemoveDialog(false);
    if (isCreationProjectCollection) {
      const deleteProject = creationProjectCollection?.filter(
        (project: any) => project?.id !== id
      );
      setProjectCollectionState("creationProjectCollection", deleteProject);
    } else {
      const deleteProjectcollection = singleProjectCollection?.filter(
        (project: any) => project?.id !== id
      );
      setProjectCollectionState(
        "singleProjectCollection",
        deleteProjectcollection
      );
    }
  };

  const apiCollectionName = () => {
    return {
      collectionName: location.state.projectCollection.name,
    };
  };

  const mappedProjectList = isCreationProjectCollection
    ? creationProjectCollection
    : singleProjectCollection;
  return (
    <Fragment>
      <Formik
        initialValues={
          location?.state?.projectCollection?.name.length > 0
            ? apiCollectionName()
            : initialValues
        }
        validationSchema={validationSchema}
        onSubmit={handleGlobalSave}
        enableReinitialize={true}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          resetForm,
          dirty,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <PageHeading title="Project Management" />
            <Breadcrumbs routes={breadcrumbRoutes}></Breadcrumbs>
            <Container className="main-container">
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack spacing={1}>
                  <IconButton
                    edge={"start"}
                    onClick={handleProjectCollection}
                    style={{ color: "black" }}
                  >
                    <ChevronLeftIcon />
                  </IconButton>

                  {isEditMode || isViewMode ? (
                    <Typography color={"palette.secondary.dark"} variant="h6">
                      {location?.state?.projectCollection.name?.length > 15
                        ? `${location?.state?.projectCollection.name?.slice(
                            0,
                            10
                          )}....${location?.state?.projectCollection.name?.slice(
                            -5
                          )}`
                        : location?.state?.projectCollection?.name}
                    </Typography>
                  ) : (
                    <Typography color={"palette.secondary.dark"} variant="h6">
                      Create New
                    </Typography>
                  )}
                  {isEditMode ? (
                    <IconButton>
                      <SettingsOutlinedIcon
                        onClick={(event) => handleOpenStatusClick(event)}
                      />
                    </IconButton>
                  ) : isViewMode ? (
                    <IconButton disabled>
                      <SettingsOutlinedIcon
                        onClick={(event) => handleOpenStatusClick(event)}
                      />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </Stack>

                {isViewMode ? (
                  <LoadingButtonComponent
                    onClick={handleEditClick}
                    color={"inherit"}
                  >
                    Edit
                  </LoadingButtonComponent>
                ) : isEditMode ? (
                  <Stack direction={"row"} columnGap={2}>
                    <Button
                      type="reset"
                      variant="contained"
                      onClick={handleChangeCancel}
                      disableElevation
                      className="btn btn-light-outline margin-right-15"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      // onClick={() => onEditProjectCollection(values, resetForm)}
                      onClick={() => {
                        setIsEditConfirmationDialogOpen(true);
                      }}
                      disableElevation
                      className="btn btn-dark"
                    >
                      Save changes
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction={"row"} columnGap={2}>
                    <Button
                      type="reset"
                      variant="contained"
                      onClick={handleChangeCancel}
                      disableElevation
                      className="btn btn-light-outline margin-right-15"
                    >
                      Cancel
                    </Button>
                    {creationProjectCollection?.length > 0 ? (
                      <>
                        <Button
                          // type="submit"
                          variant="contained"
                          onClick={() => {
                            setIsSaveAndNewConfirmationDialogOpen(true);
                            setProjectCollectionState(
                              "saveProjectCollection",
                              false
                            );
                            setShowFlashMessage(false);
                          }}
                          disableElevation
                          className="btn btn-dark-outline margin-right-15"
                          disabled={!isValid || !dirty}
                        >
                          Save & New
                        </Button>
                        <Button
                          // type="submit"
                          variant="contained"
                          onClick={() => {
                            handleSaveAndNew();
                          }}
                          disableElevation
                          className="btn btn-dark"
                          disabled={!isValid || !dirty}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          variant="contained"
                          disableElevation
                          className="btn btn-dark-outline margin-right-15"
                          disabled
                        >
                          Save & New
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          disableElevation
                          className="btn btn-dark"
                          disabled
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>

              {isViewMode && (
                <div>
                  <Stack justifyContent={"start"} spacing={2}>
                    <Typography>
                      <Typography component={"i"}>
                        {" "}
                        Created on:&nbsp;
                      </Typography>
                      {`${
                        moment(
                          location?.state?.projectCollection.createdAt
                        ).format("L") || ""
                      }
                    by `}
                      <Link>
                        {location?.state?.projectCollection?.collectionCreatedBy
                          ?.firstName || "User"}
                      </Link>
                    </Typography>
                    <Typography>
                      <Typography component={"i"}>
                        {" "}
                        Modified on:&nbsp;
                      </Typography>
                      {`${
                        moment(
                          location?.state?.projectCollection?.updatedAt
                        ).format("L") || ""
                      } by `}
                      <Link>
                        {location?.state?.projectCollection?.collectionUpdatedBy
                          ?.firstName || "User"}
                      </Link>
                    </Typography>
                    <ButtonComponent
                      sx={{ textDecoration: "underline" }}
                      variant="text"
                      color="inherit"
                    >
                      View Modified Content
                    </ButtonComponent>
                  </Stack>
                </div>
              )}

              <FormFieldContainer>
                <Grid container mt={"0.2rem"} rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} md={5} lg={5} xl={5}>
                    <TextFieldComponent
                      placeholder="Enter"
                      label="Collection Name"
                      name={`collectionName`}
                      value={values.collectionName}
                      inputProps={{
                        maxLength:
                          ProjectCreationConstants.collectionNameCharacterLimit,
                      }}
                      disabled={isViewMode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // error={collectionNameError !== "" ? true : false}
                      error={Boolean(
                        touched?.collectionName && errors?.collectionName
                      )}
                      fieldhelpertext={
                        errors.collectionName &&
                        touched.collectionName &&
                        errors.collectionName
                      }
                    />
                  </Grid>
                </Grid>

                {isEditMode ? (
                  <Button
                    variant="contained"
                    onClick={addProjectsToCollection}
                    // autoFocus
                    disableElevation
                    className="btn btn-dark margin-top-30"
                  >
                    Map Projects to Collection
                  </Button>
                ) : isViewMode ? (
                  ""
                ) : (
                  <Button
                    variant="contained"
                    onClick={addProjectsToCollection}
                    // autoFocus
                    disableElevation
                    className="btn btn-dark margin-top-30"
                  >
                    Map Projects to Collection
                  </Button>
                )}

                {creationProjectCollection?.length > 0 ||
                singleProjectCollection?.length > 0 ? (
                  <section className="margin-top-50">
                    <h4 className="heading">
                      Collections{" "}
                      {`(${
                        isCreationProjectCollection
                          ? creationProjectCollection?.length === undefined
                            ? 0
                            : creationProjectCollection?.length
                          : singleProjectCollection?.length
                      })`}
                    </h4>
                    <TableContainer
                      component={Paper}
                      className="table-container"
                      style={{
                        height: window.innerHeight - 575,
                        position: "relative",
                      }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow className="table-header-row">
                            {tableHeadings.map((item: any, index: number) => (
                              <TableCell
                                key={index}
                                className="table-header-text"
                                style={{ minWidth: 170 }}
                              >
                                {item.heading}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(
                            isCreationProjectCollection
                              ? creationProjectCollection?.length > 0
                              : singleProjectCollection?.length > 0
                          ) ? (
                            (isCreationProjectCollection
                              ? creationProjectCollection
                              : singleProjectCollection
                            )?.map((project: any, index: any) => {
                              return (
                                <TableRow
                                  key={project.id}
                                  className="table-data-row"
                                >
                                  <TableCell className="table-data-text">
                                    {project?.id}
                                  </TableCell>

                                  <TableCell
                                    className="table-data-text"
                                    style={{ minWidth: 120 }}
                                  >
                                    <img
                                      className={styles["project-image"]}
                                      src={
                                        project?.projectCoverImages
                                          ?.homePageMedia?.value?.url
                                      }
                                      alt={"image"}
                                    />
                                  </TableCell>

                                  <TableCell className="table-data-text">
                                    {project?.launchName}
                                  </TableCell>

                                  <TableCell className="table-data-text">
                                    <div> {project?.address?.city}</div>
                                    {project?.address?.state}
                                  </TableCell>

                                  <TableCell className="table-data-text">
                                    {`(₹) ${project?.priceStartingFrom || "-"}`}
                                  </TableCell>

                                  <TableCell className="table-data-text">
                                    {project?.areaStartingFrom || "-"}
                                  </TableCell>

                                  <TableCell className="table-data-text">
                                    <div>
                                      {
                                        project?.projectContentCreatedBy
                                          ?.firstName
                                      }
                                    </div>
                                    <div>
                                      {project?.createdAt
                                        ? formatDateTime(
                                            project?.createdAt,
                                            Constants.dateFormatDDmmYYYY
                                          )
                                        : ""}
                                    </div>
                                  </TableCell>
                                  <TableCell className="table-data-text">
                                    {isViewMode ? (
                                      <PrioritySelector
                                        priority={project.priority}
                                        incrementpriority={() => {}}
                                        decrementpriority={() => {}}
                                      />
                                    ) : (
                                      <PrioritySelector
                                        priority={index + 1}
                                        incrementpriority={() => {
                                          handlePriorityChangeIndex(
                                            index,
                                            isCreationProjectCollection
                                              ? creationProjectCollection
                                              : singleProjectCollection,
                                            1,
                                            project
                                          );
                                        }}
                                        decrementpriority={() => {
                                          // if (index === 0) {
                                          //   (isCreationProjectCollection
                                          //     ? creationProjectCollection
                                          //     : singleProjectCollection
                                          //   )?.splice(0, 1);
                                          //   (isCreationProjectCollection
                                          //     ? creationProjectCollection
                                          //     : singleProjectCollection
                                          //   )?.splice(1, 0, project);
                                          // } else {
                                          handlePriorityChangeIndex(
                                            index,
                                            isCreationProjectCollection
                                              ? creationProjectCollection
                                              : singleProjectCollection,
                                            -1,
                                            project
                                          );
                                          // }
                                        }}
                                        disableDecrement={index === 0}
                                        disableIncrement={
                                          isCreationProjectCollection
                                            ? index + 1 ===
                                              creationProjectCollection?.length
                                            : index + 1 ===
                                              singleProjectCollection.length
                                        }
                                      />
                                    )}
                                  </TableCell>
                                  {isEditMode ? (
                                    <TableCell className="table-data-text">
                                      <Stack
                                        justifyContent={"start"}
                                        spacing={2}
                                      >
                                        {singleProjectCollection?.length > 1 ? (
                                          <Typography
                                            sx={{
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              // removeHandler(project.id);
                                              setOpenProjectRemoveDialog(true);
                                              setremoveProjectById(project.id);
                                            }}
                                          >
                                            Remove
                                          </Typography>
                                        ) : (
                                          <Typography color={"#CFD2CF"}>
                                            Remove
                                          </Typography>
                                        )}
                                      </Stack>
                                    </TableCell>
                                  ) : isViewMode ? (
                                    <TableCell className="table-data-text">
                                      <Stack
                                        justifyContent={"start"}
                                        spacing={2}
                                      >
                                        <Typography
                                          sx={{
                                            textDecoration: "underline",
                                            pointerEvents: "none",
                                            opacity: "0.5",
                                          }}
                                        >
                                          Remove
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                  ) : (
                                    <TableCell className="table-data-text">
                                      <Stack
                                        justifyContent={"start"}
                                        spacing={2}
                                      >
                                        {creationProjectCollection?.length >
                                        1 ? (
                                          <Typography
                                            sx={{
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              // removeHandler(project.id)
                                              setOpenProjectRemoveDialog(true);
                                              setremoveProjectById(project.id);
                                            }}
                                          >
                                            Remove
                                          </Typography>
                                        ) : (
                                          <Typography color={"#CFD2CF"}>
                                            Remove
                                          </Typography>
                                        )}
                                      </Stack>
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })
                          ) : (
                            <div className="no-record">No Project Found</div>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      component="div"
                      count={
                        isCreationProjectCollection
                          ? creationProjectCollection?.length === undefined
                            ? 0
                            : creationProjectCollection?.length
                          : singleProjectCollection?.length
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </section>
                ) : (
                  ""
                )}
              </FormFieldContainer>

              {isAddProjectsToCollectionDialogOpen && (
                <ProjectListDialog
                  shouldOpen={isAddProjectsToCollectionDialogOpen}
                  cancelHandler={handleCloseAddProjectsToCollectionDialog}
                  initialMapList={singleProjectCollection?.map(
                    (item: any) => item?.id
                  )}
                  setProjectListWithPriority={setProjectListWithPriority}
                  projectListWithPriority={projectListWithPriority}
                  setAllProjectList={setAllProjectList}
                  isAllProjectList={isAllProjectList}
                  projectCollectionId={projectCollectionId}
                  isEditMode={isEditMode}
                />
              )}
              {openProjectRemoveDialog && (
                <ConfirmationDialog
                  shouldOpen={openProjectRemoveDialog}
                  title="Remove Mapped Project?"
                  content="This action will Remove Mapped Project. Are you sure you want to continue?"
                  okText="Remove"
                  cancelHandler={handleCloseConfirmDialog}
                  okHandler={() => removeHandler(removeProjectById)}
                />
              )}

              <ConfirmationDialog
                shouldOpen={isConfirmDeleteDialogOpen}
                title="Delete Project Collection ?"
                content="This Action will delete the Project Collection. Are You sure you want to continue ?"
                okText="Delete"
                cancelHandler={handleCloseConfirmDialog}
                okHandler={() =>
                  deleteProjectCollectionId(projectCollectionId?.id)
                }
              />
              <ConfirmationDialog
                shouldOpen={
                  isSaveAndNewConfirmationDialogOpen ||
                  isSaveConfirmationDialogOpen ||
                  isEditConfirmationDialogOpen
                }
                title="Save Project Collection ?"
                content="This Action will Save the Project Collection. Are You sure you want to continue ?"
                okText="Save"
                cancelHandler={handleCloseConfirmDialog}
                okHandler={() => {
                  if (isSaveAndNewConfirmationDialogOpen === true) {
                    handleChangeSaveNew(values, resetForm);
                    setFieldValue("collectionName", "");
                  } else if (isSaveConfirmationDialogOpen === true) {
                    handleGlobalSave(values);
                  } else if (isEditConfirmationDialogOpen === true) {
                    onEditProjectCollection(values, resetForm);
                  }
                }}
              />

              <FlashMessageDialog
                cancel={false}
                shouldOpen={dataRecivedProjectCollection}
                content={error?.length ? error : message}
                isSuccess={error === null ? true : false}
                cancelHandler={handleCloseFlashMessageDialog}
              />
            </Container>
          </Form>
        )}
      </Formik>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Popper open={open} placement="right-start" anchorEl={anchor}>
          <Paper elevation={12}>
            <MenuList>
              <MenuItem sx={{ bgcolor: "white" }} onClick={handleMarkStatus}>
                {`${
                  projectCollectionId?.status ===
                  UpdatesStatusTypeEnum.Published
                    ? "Mark as Inactive"
                    : "Mark as Published"
                }`}
              </MenuItem>
              <MenuItem
                sx={{ bgcolor: "white" }}
                onClick={deleteProjectCollection}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </Fragment>
  );
};
export default ProjectCollectionCreation;
