import { Box, useTheme } from "@mui/material";

interface ReactNodeProps {
  children: React.ReactNode;
}

const FormFieldContainer = (props: ReactNodeProps) => {
  const theme = useTheme();
  const { children } = props;
  return (
    <Box
      position={"relative"}
      mt={"1.5rem"}
      border={`1px solid ${theme.palette.grey[400]}`}
      borderRadius={"0.4rem"}
      component={"section"}
      padding={"1.5rem"}
      {...props}
    >
      {children}
    </Box>
  );
};

export default FormFieldContainer;
