export const enum DocumentTypeEnum {
  PAN_CARD = 200100,
  ADDRESS_PROOF = 200101,
  AFS = 200103,
  POWER_OF_ATTORNEY = 200104,
  DEED_OF_CONVEYANCE = 200105,
  SATBARA_UTARA = 200106,
  ALLOTMENT = 200107,
  CUSTOMER_GUIDELINES = 200108,
  UNVERIFIED_PAN_CARD = 200109,
  UNVERIFIED_ADDRESS_PROOF = 200110,
  PAYMENT = 200111,
}
export class ActionTypeEnumUtils {
  public static getEnumText(type: DocumentTypeEnum): string {
    switch (type) {
      case DocumentTypeEnum.PAN_CARD:
        return "Pan Card";
      case DocumentTypeEnum.ADDRESS_PROOF:
        return "Address Proof";
      case DocumentTypeEnum.AFS:
        return "Aggreement for Sale (AFS)";
      case DocumentTypeEnum.POWER_OF_ATTORNEY:
        return "Power of Attorney";
      case DocumentTypeEnum.DEED_OF_CONVEYANCE:
        return "Deed of Conveyance";
      case DocumentTypeEnum.SATBARA_UTARA:
        return "7/12 (Satbara Utara)";
      case DocumentTypeEnum.ALLOTMENT:
        return "Allotment letter";
      case DocumentTypeEnum.CUSTOMER_GUIDELINES:
        return "Customer Guidelines";
      case DocumentTypeEnum.UNVERIFIED_PAN_CARD:
        return "Unverified Pan Card";
      case DocumentTypeEnum.UNVERIFIED_ADDRESS_PROOF:
        return "Unverified Address Proof";
      case DocumentTypeEnum.PAYMENT:
        return "Payment receipt";
    }
  }

  public static getActionsEnums(): {
    label: string;
    value: DocumentTypeEnum;
  }[] {
    const options: { label: string; value: DocumentTypeEnum }[] = [
      {
        label: this.getEnumText(DocumentTypeEnum.PAN_CARD),
        value: DocumentTypeEnum.PAN_CARD,
      },
      {
        label: this.getEnumText(DocumentTypeEnum.ADDRESS_PROOF),
        value: DocumentTypeEnum.ADDRESS_PROOF,
      },
      {
        label: this.getEnumText(DocumentTypeEnum.AFS),
        value: DocumentTypeEnum.AFS,
      },
      {
        label: this.getEnumText(DocumentTypeEnum.POWER_OF_ATTORNEY),
        value: DocumentTypeEnum.POWER_OF_ATTORNEY,
      },
      {
        label: this.getEnumText(DocumentTypeEnum.DEED_OF_CONVEYANCE),
        value: DocumentTypeEnum.DEED_OF_CONVEYANCE,
      },
      {
        label: this.getEnumText(DocumentTypeEnum.ALLOTMENT),
        value: DocumentTypeEnum.ALLOTMENT,
      },
      {
        label: this.getEnumText(DocumentTypeEnum.CUSTOMER_GUIDELINES),
        value: DocumentTypeEnum.CUSTOMER_GUIDELINES,
      },
      {
        label: this.getEnumText(DocumentTypeEnum.UNVERIFIED_PAN_CARD),
        value: DocumentTypeEnum.UNVERIFIED_PAN_CARD,
      },
      {
        label: this.getEnumText(DocumentTypeEnum.UNVERIFIED_ADDRESS_PROOF),
        value: DocumentTypeEnum.UNVERIFIED_ADDRESS_PROOF,
      },
      {
        label: this.getEnumText(DocumentTypeEnum.PAYMENT),
        value: DocumentTypeEnum.PAYMENT,
      },
    ];
    return options;
  }
}
