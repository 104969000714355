import ImageUploadReplaceDragDrop from "components/ui-elements/image-uploader-dropdown/image-upload-replace/ImageUploadReplaceDragDrop";
import { useFormikContext } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { IInsightsCreation } from "./InsightsCreationInitialValue";
import { useDispatch, useSelector } from "react-redux";
import InsightCreationMediaDialog from "./InsightsCreationMediaDialog";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { useLocation } from "react-router-dom";
import { stripHtml } from "utils/ValidationUtils";

interface IInsightCreationMediaDescription {
  index: number;
  showImageUploadDialog: boolean;
  handleShowInsightsCreationMediaDialog: (imageIndex: number) => void;
  handleShowInsightsCreationMedia: (imageIndex: number) => void;
  deleteInsightsCreationImageOrVideo: () => void;
  currentlyOpenedIndex: number;
  handleImageSuccesFullyUploaded: () => void;
  isConfirmDeleteDialogOpen: boolean;
  handleCloseConfirmDialog: () => void;
  handleShowInsightsCreationCancel: () => void;
  isViewMode: boolean;
}

function InsightsCreationMediaDescription({
  index,
  handleShowInsightsCreationMediaDialog,
  handleShowInsightsCreationMedia,
  deleteInsightsCreationImageOrVideo,
  showImageUploadDialog,
  currentlyOpenedIndex,
  handleImageSuccesFullyUploaded,
  isConfirmDeleteDialogOpen,
  handleCloseConfirmDialog,
  handleShowInsightsCreationCancel,
  isViewMode,
}: IInsightCreationMediaDescription) {
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit, setFieldValue, resetForm } =
    useFormikContext<IInsightsCreation>();
  const {
    imageDetails,
    isLoading,
    error,
    isSuccess,
    isDelete,
    data,
    isDeleteConfirm,
    isVideoDelete,
    // fileName,
  } = useSelector((state: any) => state.fileUpload);
  const [diglogOpen, setDialogOpen] = useState(false);
  const location: any = useLocation();

  // const MediaUrlEditView =
  //   location?.state?.insightsMedia[index].media?.value?.url;;
  const MediaUrlCancel = values?.insightsMedia[index]?.media?.value?.isCancel;
  const MediaUrlPreview = values?.insightsMedia[index]?.media?.value?.isPreview;

  const handleDialogSuccessFullyOpen = () => {
    setDialogOpen(true);
    setFieldValue(`insightsMedia[${index}].media.value.isPreview`, false);
    setFieldValue(`insightsMedia[${index}].media.value.isCancel`, false);
  };

  const handleDialogSuccessFullyClose = () => {
    setDialogOpen(false);
  };

  const handleCloseFlashMessage = () => {
    dispatch(FileUploadActions.setUploadFileState("isDeleteConfirm", false));
    // dispatch(FileUploadActions.setUploadFileState("error", null));
  };

  //On Toggle the Image OR Video Clear the ImageDetails
  const handleToggleImageVideo = () => {
    dispatch(FileUploadActions.setUploadFileState("imageDetails", null));
    dispatch(FileUploadActions.setUploadFileState("isLoading", false));
  };
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      if (currentlyOpenedIndex == index) {
        const insightMediaImageUpload = `insightsMedia[${index}].media.value.url`;
        setFieldValue(insightMediaImageUpload, imageDetails?.url);
        if (values?.insightsMedia[index].media.value.mediaType !== "IMAGE") {
          const fileNameOfMedia = `insightsMedia[${index}].media.name`;
          setFieldValue(fileNameOfMedia, imageDetails?.url);
        }
      }
    }
    if (MediaUrlPreview && MediaUrlCancel) {
      getAllInsightsCreationFunction();
    }
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess, MediaUrlPreview, MediaUrlCancel]);

  const handleChangeImageVideo = (values: any, file: any, index: number) => {
    // let file = event.target.files[0];
    let imageUrl;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
      };
      reader.readAsArrayBuffer(file);
      // dispatch(FileUploadActions.setUploadFileState("fileName", file?.name));

      const fileNameOfMedia = `insightsMedia[${index}].media.name`;
      const fileSizeOfMedia = `insightsMedia[${index}].media.value.size`;
      const fileKeyOfMedia = `insightsMedia[${index}].media.key`;
      const IsActiveOfMedia = `insightsMedia[${index}].media.isActive`;

      if (values?.insightsMedia[index]?.media?.value?.mediaType === "IMAGE") {
        setFieldValue(fileNameOfMedia, file?.name);
        setFieldValue(fileKeyOfMedia, file?.name);
        setFieldValue(IsActiveOfMedia, true);
        setFieldValue(fileSizeOfMedia, file?.size);
      }
    } else {
      imageUrl = null;
    }
  };

  //delete
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      if (currentlyOpenedIndex == index) {
        const empty = "";
        const insightMediaImageUpload = `insightsMedia[${index}].media.value.url`;
        setFieldValue(insightMediaImageUpload, empty);
        const insightMediaImageMediaDescription = `insightsMedia[${index}].media.mediaDescription`;
        setFieldValue(insightMediaImageMediaDescription, empty);
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isDelete", false));
    // dispatch(FileUploadActions.setUploadFileState("fileName", ""));
  }, [isDelete]);

  //delete Video isVideoDelete
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      if (currentlyOpenedIndex == index) {
        const empty = "";
        const insightMediaImageUpload = `insightsMedia[${index}].media.value.url`;
        const insightMediaImageMediaDescription = `insightsMedia[${index}].media.mediaDescription`;
        setFieldValue(insightMediaImageMediaDescription, empty);
        setFieldValue(insightMediaImageUpload, empty);
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isVideoDelete", false));
  }, [isVideoDelete]);

  const handleConfirmDelete = (values: any) => {
    let fileName = values?.insightsMedia[currentlyOpenedIndex]?.media?.name;
    let mediaUrl =
      values?.insightsMedia[currentlyOpenedIndex].media?.value?.mediaType;
    if (mediaUrl === "IMAGE") {
      dispatch(FileUploadActions.fileDeleteRequest(fileName));
      dispatch(FileUploadActions.setUploadFileState("isDeleteConfirm", true));
      handleCloseConfirmDialog();
    } else {
      dispatch(FileUploadActions.setUploadFileState("isVideoDelete", true));
      handleCloseConfirmDialog();
    }
  };

  return (
    <Fragment>
      <ImageUploadReplaceDragDrop
        index={index}
        currentlyOpenedIndex={currentlyOpenedIndex}
        isViewMode={isViewMode}
        uploadMediaButton={true}
        dragAndDropButton={false}
        resetLeft={true}
        editDeleteButton={isViewMode ? false : true}
        onEditClick={() => {
          handleShowInsightsCreationMediaDialog(index);
          handleDialogSuccessFullyOpen();
        }}
        onNewClick={() => {
          handleShowInsightsCreationMediaDialog(index);
          handleDialogSuccessFullyOpen();
        }}
        onDrop={() => handleShowInsightsCreationMediaDialog(index)}
        name={`insightsMedia[${index}].media.value.url`}
        onChange={(file: any) => {
          handleChangeImageVideo(values, file, index);
          handleShowInsightsCreationMedia(index); //here i m setting the particular Index
        }}
        previewimage={values?.insightsMedia[index]?.media?.value?.url}
        cancelimage={() => {
          handleShowInsightsCreationMedia(index); //setting the particalar index
          handleDialogSuccessFullyClose();
          deleteInsightsCreationImageOrVideo();
        }}
        fieldhelpertext={
          stripHtml(values?.insightsMedia[index]?.description) &&
          !values?.insightsMedia[index]?.media?.value?.url &&
          "Required"
        }
      />
      {currentlyOpenedIndex == index && diglogOpen === true && (
        <InsightCreationMediaDialog
          imageOrVideoIndex={index}
          shouldOpen={showImageUploadDialog}
          title="Upload Media"
          imageVideoToggle={() => {
            handleToggleImageVideo();
            let empty = "";

            // const emptyInsightsMediaUrl = `insightsMedia[${index}].media.value.url`;
            // setFieldValue(emptyInsightsMediaUrl, empty);

            const emptyInsightsMediaDescrption = `insightsMedia[${index}].media.mediaDescription`;
            setFieldValue(emptyInsightsMediaDescrption, empty);
          }}
          cancelHandler={handleShowInsightsCreationCancel}
          // mediaUploadSuccess={handleImageSuccesFullyUploaded}
          handleDialogSuccessFullyClose={handleDialogSuccessFullyClose}
        />
      )}
      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Insights Creation Media?"
        content="This action will delete the Insights Creation Media. Are you sure you want to continue?"
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={() => handleConfirmDelete(values)}
        // okHandler={() => handleCloseConfirmDialog()}
      />
      {/* <FlashMessageDialog
        cancel={false}
        shouldOpen={isDeleteConfirm}
        content={data}
        isSuccess={error === null ? true : false}
        cancelHandler={handleCloseFlashMessage}
      /> */}
    </Fragment>
  );
}

export default InsightsCreationMediaDescription;
