import React, { Fragment, useEffect, useState } from "react";
import { TextFieldComponent } from "components/ui-elements";
import { IconButton, Stack, Autocomplete, Link, Box } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { getIn, useFormikContext } from "formik";
import { IProjectFormInitialValues } from "pages/payment-form-management/projects/ProjectFormInitialValues";
import OptionEditDialog from "./OptionEditDialog";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
// import Autocomplete from "@material-ui/lab/Autocomplete";

const category: string[] = [" "]; //Select an option or Create one

interface ICustomAutoComplete {
  name: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  validationPattern?: any;
  fieldValue: string[];
  disabled?: boolean;
}

const CustomAutoComplete = (props: ICustomAutoComplete) => {
  const {
    placeholder,
    name,
    label,
    required = false,
    validationPattern,
    fieldValue,
  } = props;

  let disabled = true;

  const { setFieldValue, handleBlur, touched, errors } =
    useFormikContext<IProjectFormInitialValues>();

  const [branch, setBranch] = useState(null);
  const [inputBranch, setInputBranch] = useState("");
  const [categories, setCategories] = useState(category.concat(fieldValue));
  const [openOptionEditDialog, setOpenOptionEditDialog] =
    useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [optionValue, setOptionValue] = useState("");
  const [updateIndex, setUpdateIndex] = useState<any>();

  useEffect(() => {
    if (categories?.length > 1) {
      setFieldValue(name, categories.slice(1, categories?.length));
    } else if (categories?.length === 1) {
      setFieldValue(name, []);
    }
  }, [categories]);

  const handleClickOpenCategoryEdit = (option: any, index: any) => {
    setOpenOptionEditDialog(true);
    setUpdateIndex(index);
    setOptionValue(option);
  };

  const handleCloseEditDialog = () => {
    setOpenOptionEditDialog(false);
    setOpenConfirmationDialog(false);
    setOptionValue("");
    setUpdateIndex(null);
  };

  const handleResetFieldValue = () => {
    setOptionValue("");
  };

  const handleUpdateOption = () => {
    categories[updateIndex] = optionValue;
    handleCloseEditDialog();
  };

  const handleChangeOptionValue = (e: any) => {
    setOptionValue(e.target.value.trimStart());
  };
  const handleDeleteOption = (selected: any) => () => {
    setSelectedOption(selected);
    setOpenConfirmationDialog(true);
  };
  const deleteOkHandler = () => {
    setCategories(categories.filter((x: any) => x !== selectedOption));
    setOpenConfirmationDialog(false);
  };

  return (
    <>
      <Autocomplete
        options={categories}
        disableCloseOnSelect
        size="medium"
        value={branch}
        // onChange={(event, value: any) => {
        //   setBranch(value);
        // }}
        inputValue={inputBranch}
        onInputChange={(event, value: any) => {
          if (validationPattern === 1) {
            setInputBranch(value?.trimStart()?.replace(/[^a-zA-Z0-9 ]/g, "")); // without special characters
          } else if (validationPattern === 2) {
            setInputBranch(value?.trimStart()?.replace(/[^a-zA-Z ]/g, "")); // only characters
          } else {
            setInputBranch(value?.trimStart());
          }
        }}
        noOptionsText={
          <div
            onClick={() => {
              setCategories([...categories, inputBranch]);
              setInputBranch("");
            }}
          >
            <Link
              sx={{
                color: "black",
                fontFamily: "Jost-Medium",
                cursor: "pointer",
              }}
            >
              Create
            </Link>{" "}
            <span style={{ color: "#676ac0" }}>{inputBranch}</span>
          </div>
        }
        autoHighlight
        getOptionLabel={(option) => option}
        getOptionDisabled={(option) => option === category[0]}
        renderOption={(props, option: any, indexState: any) => (
          <Fragment>
            <div>
              <Stack direction="row" justifyContent={"space-between"}>
                <Box
                  sx={{
                    width: `${option == categories[0] ? "100%" : "100%"}`,
                    padding: "10px",
                  }}
                >
                  {option}
                </Box>

                {/* {option !== categories[0] && option !== categories[0] && (
                  <div>
                    <IconButton
                      disabled={disabled}
                      onClick={() => {
                        handleClickOpenCategoryEdit(
                          option,
                          categories.indexOf(option)
                        );
                      }}
                    >
                      <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                    <IconButton
                      disabled={disabled}
                      size="small"
                      color="error"
                      onClick={handleDeleteOption(option)}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </div>
                )} */}
              </Stack>
            </div>
          </Fragment>
        )}
        renderInput={(params) => (
          <TextFieldComponent
            name={name}
            required={required}
            label={label}
            variant="outlined"
            onBlur={handleBlur}
            fieldhelpertext={getIn(touched, name) && getIn(errors, name)}
            error={Boolean(getIn(errors, name) && getIn(touched, name))}
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: disabled,
            }}
          />
        )}
      />
      <ConfirmationDialog
        shouldOpen={openConfirmationDialog}
        content={`Are you sure you want to Delete dropdown value?`}
        okText="Yes"
        cancelHandler={handleCloseEditDialog}
        okHandler={deleteOkHandler}
      />
      <OptionEditDialog
        titleText={label || placeholder}
        open={openOptionEditDialog}
        handleClose={handleCloseEditDialog}
        handleUpdateOption={handleUpdateOption}
        handleChangeOptionValue={handleChangeOptionValue}
        optionValue={optionValue}
        handleResetField={handleResetFieldValue}
      />
    </>
  );
};
export default CustomAutoComplete;
