import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
  ButtonComponent,
} from "components/ui-elements";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useFormikContext } from "formik";
import { ChangeEvent, FocusEvent } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { getIn } from "formik";
import { shouldNumberOnly } from "utils/ValidationUtils";
import { useLocation } from "react-router-dom";

const MastheadContainer = styled(FormFieldContainer)`
  padding: 0;
`;

const Masthead = ({ isEditMode }: any) => {
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  const location: any = useLocation();

  const handleNumberFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (shouldNumberOnly(event.target.value)) {
      setFieldValue(`${event.target.name}`, event.target.value);
    }
  };

  const handleTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${event.target.name}`, event.target.value);
  };

  return (
    <MastheadContainer>
      <Grid
        container
        p={"1rem"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item>
          <Stack columnGap={2} justifyContent={"flex-start"}>
            <Typography className="accordion-heading" margin={"0.5rem 0"}>
              Masthead
            </Typography>
            <Tooltip
              title={"This represent the ticker on Customer App"}
              arrow
              placement="top-start"
            >
              <InfoOutlinedIcon
                fontSize="small"
                className="infooutlined-alignment"
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Divider />

      <Grid
        container
        spacing={4}
        gridAutoRows={4}
        alignItems={"flex-start"}
        p={"1rem"}
      >
        <Grid item xs={4}>
          <Stack justifyContent={"flex-start"} spacing={2} mt={2}>
            <Typography variant="body1">
              Total amount of land transacted
            </Typography>
            <SwitchComponent
              name="mastheadSection.totalAmoutOfLandTransacted.shouldDisplay"
              checked={
                values?.mastheadSection?.totalAmoutOfLandTransacted
                  ?.shouldDisplay
              }
              onChange={handleChange}
              disabled={location?.state?.isViewMode && isEditMode}
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            placeholder="Enter"
            label="Display Name"
            required
            disabled={location?.state?.isViewMode && isEditMode}
            name="mastheadSection.totalAmoutOfLandTransacted.displayName"
            value={
              values?.mastheadSection?.totalAmoutOfLandTransacted?.displayName
            }
            error={Boolean(
              getIn(
                errors,
                "mastheadSection.totalAmoutOfLandTransacted.displayName"
              ) &&
                getIn(
                  touched,
                  "mastheadSection.totalAmoutOfLandTransacted.displayName"
                )
            )}
            fieldhelpertext={
              getIn(
                touched,
                "mastheadSection.totalAmoutOfLandTransacted.displayName"
              ) &&
              getIn(
                errors,
                "mastheadSection.totalAmoutOfLandTransacted.displayName"
              )
            }
            onChange={handleTextInput}
            onBlur={handleBlur}
            inputProps={{ maxLength: 45 }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            onChange={handleChange}
            name="mastheadSection.totalAmoutOfLandTransacted.value"
            value={values?.mastheadSection?.totalAmoutOfLandTransacted?.value}
            fieldhelpertext={
              getIn(
                touched,
                "mastheadSection.totalAmoutOfLandTransacted.value"
              ) &&
              getIn(errors, "mastheadSection.totalAmoutOfLandTransacted.value")
            }
            error={Boolean(
              getIn(
                errors,
                "mastheadSection.totalAmoutOfLandTransacted.value"
              ) &&
                getIn(
                  touched,
                  "mastheadSection.totalAmoutOfLandTransacted.value"
                )
            )}
            placeholder="Enter"
            label="Value"
            required
            disabled={location?.state?.isViewMode && isEditMode}
            onBlur={handleBlur}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Stack justifyContent={"flex-start"} spacing={2} mt={2}>
            <Typography variant="body1">
              Total sqft of land transacted
            </Typography>
            <SwitchComponent
              name="mastheadSection.totalSqftOfLandTransacted.shouldDisplay"
              checked={
                values?.mastheadSection?.totalSqftOfLandTransacted
                  ?.shouldDisplay
              }
              onChange={handleChange}
              disabled={location?.state?.isViewMode && isEditMode}
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            placeholder="Enter"
            label="Display Name"
            onChange={handleTextInput}
            disabled={location?.state?.isViewMode && isEditMode}
            onBlur={handleBlur}
            error={Boolean(
              getIn(
                errors,
                "mastheadSection.totalSqftOfLandTransacted.displayName"
              ) &&
                getIn(
                  touched,
                  "mastheadSection.totalSqftOfLandTransacted.displayName"
                )
            )}
            fieldhelpertext={
              getIn(
                touched,
                "mastheadSection.totalSqftOfLandTransacted.displayName"
              ) &&
              getIn(
                errors,
                "mastheadSection.totalSqftOfLandTransacted.displayName"
              )
            }
            value={
              values?.mastheadSection?.totalSqftOfLandTransacted?.displayName
            }
            name="mastheadSection.totalSqftOfLandTransacted.displayName"
            required
            inputProps={{ maxLength: 45 }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            label="Value"
            required
            placeholder="Enter (sqft)"
            onBlur={handleBlur}
            name="mastheadSection.totalSqftOfLandTransacted.value"
            value={values?.mastheadSection?.totalSqftOfLandTransacted?.value}
            disabled={location?.state?.isViewMode && isEditMode}
            onChange={handleChange}
            error={Boolean(
              getIn(
                errors,
                "mastheadSection.totalSqftOfLandTransacted.value"
              ) &&
                getIn(
                  touched,
                  "mastheadSection.totalSqftOfLandTransacted.value"
                )
            )}
            fieldhelpertext={
              getIn(
                touched,
                "mastheadSection.totalSqftOfLandTransacted.value"
              ) &&
              getIn(errors, "mastheadSection.totalSqftOfLandTransacted.value")
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">sq ft</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Stack justifyContent={"flex-start"} spacing={2} mt={2}>
            <Typography variant="body1">Gross weighted</Typography>

            <SwitchComponent
              name="mastheadSection.grossWeightedAvgAppreciation.shouldDisplay"
              checked={
                values?.mastheadSection?.grossWeightedAvgAppreciation
                  ?.shouldDisplay
              }
              onChange={handleChange}
              disabled={location?.state?.isViewMode && isEditMode}
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            placeholder="Enter"
            label="Display Name"
            required
            onChange={handleTextInput}
            disabled={location?.state?.isViewMode && isEditMode}
            onBlur={handleBlur}
            name="mastheadSection.grossWeightedAvgAppreciation.displayName"
            value={
              values?.mastheadSection?.grossWeightedAvgAppreciation?.displayName
            }
            fieldhelpertext={
              getIn(
                touched,
                "mastheadSection.grossWeightedAvgAppreciation.displayName"
              ) &&
              getIn(
                errors,
                "mastheadSection.grossWeightedAvgAppreciation.displayName"
              )
            }
            error={Boolean(
              getIn(
                errors,
                "mastheadSection.grossWeightedAvgAppreciation.displayName"
              ) &&
                getIn(
                  touched,
                  "mastheadSection.grossWeightedAvgAppreciation.displayName"
                )
            )}
            inputProps={{ maxLength: 45 }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            onChange={handleChange}
            onBlur={handleBlur}
            name="mastheadSection.grossWeightedAvgAppreciation.value"
            value={values?.mastheadSection?.grossWeightedAvgAppreciation?.value}
            disabled={location?.state?.isViewMode && isEditMode}
            // required
            fieldhelpertext={
              getIn(
                touched,
                "mastheadSection.grossWeightedAvgAppreciation.value"
              ) &&
              getIn(
                errors,
                "mastheadSection.grossWeightedAvgAppreciation.value"
              )
            }
            error={Boolean(
              getIn(
                errors,
                "mastheadSection.grossWeightedAvgAppreciation.value"
              ) &&
                getIn(
                  touched,
                  "mastheadSection.grossWeightedAvgAppreciation.value"
                )
            )}
            placeholder="Enter"
            label="Value"
          />
        </Grid>
        <Grid item xs={4}>
          <Stack justifyContent={"flex-start"} spacing={2} mt={2}>
            <Typography variant="body1">
              Total number of users who bought the land
            </Typography>

            <SwitchComponent
              name="mastheadSection.totalNumberOfUsersWhoBoughtTheLand.shouldDisplay"
              checked={
                values?.mastheadSection?.totalNumberOfUsersWhoBoughtTheLand
                  ?.shouldDisplay
              }
              onChange={handleChange}
              disabled={location?.state?.isViewMode && isEditMode}
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            placeholder="Enter"
            label="Display Name"
            required
            disabled={location?.state?.isViewMode && isEditMode}
            onBlur={handleBlur}
            onChange={handleTextInput}
            name="mastheadSection.totalNumberOfUsersWhoBoughtTheLand.displayName"
            value={
              values?.mastheadSection?.totalNumberOfUsersWhoBoughtTheLand
                ?.displayName
            }
            inputProps={{ maxLength: 45 }}
            fieldhelpertext={
              getIn(
                touched,
                "mastheadSection.totalNumberOfUsersWhoBoughtTheLand.displayName"
              ) &&
              getIn(
                errors,
                "mastheadSection.totalNumberOfUsersWhoBoughtTheLand.displayName"
              )
            }
            error={Boolean(
              getIn(
                errors,
                "mastheadSection.totalNumberOfUsersWhoBoughtTheLand.displayName"
              ) &&
                getIn(
                  touched,
                  "mastheadSection.totalNumberOfUsersWhoBoughtTheLand.displayName"
                )
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            placeholder="Enter"
            label="Value"
            required
            onBlur={handleBlur}
            disabled={location?.state?.isViewMode && isEditMode}
            onChange={handleChange}
            name="mastheadSection.totalNumberOfUsersWhoBoughtTheLand.value"
            value={
              values?.mastheadSection?.totalNumberOfUsersWhoBoughtTheLand?.value
            }
            fieldhelpertext={
              getIn(
                touched,
                "mastheadSection.totalNumberOfUsersWhoBoughtTheLand.value"
              ) &&
              getIn(
                errors,
                "mastheadSection.grossWetotalNumberOfUsersWhoBoughtTheLandightedAvgAppreciation.value"
              )
            }
            error={Boolean(
              getIn(
                errors,
                "mastheadSection.totalNumberOfUsersWhoBoughtTheLand.value"
              ) &&
                getIn(
                  touched,
                  "mastheadSection.totalNumberOfUsersWhoBoughtTheLand.value"
                )
            )}
          />
        </Grid>
      </Grid>
      <Divider />
      <Box p={"1rem"} textAlign={"right"}>
        {/* <ButtonComponent>Save</ButtonComponent> */}
      </Box>
    </MastheadContainer>
  );
};

export default Masthead;
