import { TopicEnums } from "enumerations/TopicEnumsForProjectsNewUser";
import * as Yup from "yup";
import { number } from "yup/lib/locale";
import { IProjectsNewUser } from "./projectsNewUserInitialValues";

const errorMessage = "Required";
export const validationSchemaProjectNewUser = Yup.object().shape({
  firstMessage: Yup.string().required("Required").nullable(),
  welcomeMessage: Yup.string().required("Required").nullable(),
  // inactiveMessage: Yup.string().required("Required").nullable(),
  // finalMessage: Yup.string().required("Required").nullable(),
  // allowTypingMessage: Yup.string().required("Required").nullable(),
  categories: Yup.array().of(
    Yup.object({
      text: Yup.string().required("Required").nullable(),
      action: Yup.number().required("Required").nullable(),
      //   actionType: Yup.number().required("Required").nullable(),
      message: Yup.string().required("Required").nullable(),
      //   options: Yup.array().of(
      //     Yup.object({
      //       text: Yup.string().required('Required').nullable(),
      //       action: Yup.number().required('Required').nullable(),
      //     //   actionType: Yup.number().required('Required').nullable(),
      //     })
      //   )
    })
  ),
});
export interface IProjectsNewUserValidation {
  smartKey?: string;
  welcomeMessage?: string;
  allowTypingMessage?: string;
  inactiveMessage?: string;
  firstMessage?: string;
  finalMessage?: string;
  categories?: {
    text?: string;
    action?: string;

    message?: string;

    options?: {
      text?: string;
      action?: string;
    }[];
  }[];
}

interface ICategory {
  text?: string | undefined;
  action?: string | undefined;
  message?: string | undefined;
  options?: {
    text?: string;
    action?: string;
  }[];
}
interface IOptions {
  text?: string;
  action?: string;
}

export const validateProjectNewUser = (values: any) => {
  let errors: IProjectsNewUserValidation = {};
  let categories: any[] = [];
  let options: any[];

  values?.categories?.map((categoryValue: any) => {
    let category: ICategory = {};

    if (!categoryValue?.text) {
      category.text = errorMessage;
    }
    if (!categoryValue.action) {
      category.action = errorMessage;
    }
    if (!categoryValue.message) {
      category.message = errorMessage;
    }
    if (categoryValue.action !== TopicEnums.Others) {
      options = [];
      categoryValue?.options?.map((optionValue: any, index: number) => {
        let option: IOptions = {};
        if (!optionValue.text) {
          option.text = errorMessage;
        }
        if (!optionValue.action) {
          option.action = errorMessage;
        }

        options?.push(option);
      });

      if (
        !options.every((value: any) => {
          return Object?.keys(value)?.length < 1;
        })
      ) {
        category.options = options;
      }
    }
    categories?.push(category);
  });

  if (
    !categories.every((value: any) => {
      return Object?.keys(value)?.length < 1;
    })
  ) {
    errors.categories = categories;
  }

  if (!values.firstMessage) {
    errors.firstMessage = errorMessage;
  }
  // if (!values.finalMessage) {
  //   errors.finalMessage = errorMessage;
  // }
  if (!values.welcomeMessage) {
    errors.welcomeMessage = errorMessage;
  }
  // if (!values.inactiveMessage) {
  //   errors.inactiveMessage = errorMessage;
  // }

  return errors;
};
