export const enum BookingStatusCodesEnums {
  BOOKED = 225360007,
  BOOKING_ACCEPTED = 225360003,
  BOOKING_IN_PROGRESS = 225360001,
  BOOKING_INITIATED = 225360000,
  BOOKING_REJECTED = 225360002,
  CANCELLATION_POST_REGISTRATION = 225360012,
  CANCELLATION_PRE_REGISTRATION = 225360004,
  HOLD = 225360014,
  INVESTMENT_DONE = 225360010,
  REGISTRATION_IN_PROGRESS = 225360008,
  REGISTRATION_TERMINATION = 225360011,
  RETAIN_IN_PROGRESS = 225360005,
  RETENTION_FAILED = 225360006,
  SOLD = 225360009,
  SOLD_PLOT_HANDOVER = 225360013,
}

export const getBookingStatusMessage = (type: any) => {
  switch (type) {
    case BookingStatusCodesEnums.BOOKED:
      return "Booked";
    case BookingStatusCodesEnums.BOOKING_ACCEPTED:
      return "Booking Accepted";
    case BookingStatusCodesEnums.BOOKING_IN_PROGRESS:
      return "Booking In Progress";
    case BookingStatusCodesEnums.BOOKING_INITIATED:
      return "Booking Initiated";
    case BookingStatusCodesEnums.BOOKING_REJECTED:
      return "Booking Rejected";
    case BookingStatusCodesEnums.CANCELLATION_POST_REGISTRATION:
      return "Cancellation Post Registration";
    case BookingStatusCodesEnums.CANCELLATION_PRE_REGISTRATION:
      return "Cancellation Pre Registration";
    case BookingStatusCodesEnums.HOLD:
      return "Hold";
    case BookingStatusCodesEnums.INVESTMENT_DONE:
      return "Investment Done";
    case BookingStatusCodesEnums.REGISTRATION_IN_PROGRESS:
      return "Registration In Progress";
    case BookingStatusCodesEnums.REGISTRATION_TERMINATION:
      return "Registration Termination";
    case BookingStatusCodesEnums.RETAIN_IN_PROGRESS:
      return "Retain In Progress";
    case BookingStatusCodesEnums.RETENTION_FAILED:
      return "Retain Failed";
    case BookingStatusCodesEnums.SOLD:
      return "Sold";
    case BookingStatusCodesEnums.SOLD_PLOT_HANDOVER:
      return "Sold Plot Handover";
  }
};
