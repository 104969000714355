import { PopperPlacementType } from "@mui/material";
import { RouteConstants } from "routes/RouteConstants";

export type PropperStateType = {
  anchorEl: null;
  isSettingOpen: boolean;
  placement: PopperPlacementType;
};
export interface IObjectProps {
  [key: string]: any;
}
export interface IOptionProps {
  id?: number;
  title: string;
}

export const newCreationPayload: IObjectProps = {
  status: "",
  urlDescription: {
    media: {
      name: "",
      key: "",
      value: {
        mediaType: "",
        size: 1,
        width: 2,
        height: 3,
        url: "",
      },
    },
  },
  phoneNumber: "",
};

export const urlCreationBreadcrumbRoutes = (isViewMode?: any) => {
  return [
    {
      name: "Url Management",
      route: RouteConstants.landingPageManagementDashboard,
    },
    {
      name: "Url List",
      route: RouteConstants.urlList,
    },
    {
      name: isViewMode === undefined ? "Create Url" : `Urls`,
      route: RouteConstants.landingPageUrlCreation,
    },
  ];
};
