import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import TestimonialsActions from "redux-container/marketing-management/testimonials/TestimonialsRedux";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";

export function* getTestimonials(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getTestimonials, action.params);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(TestimonialsActions.getTestimonialsSuccess(response.data));
    } else {
      yield put(TestimonialsActions.getTestimonialsFailure(response.data));
    }
  } catch (error) {
    yield put(TestimonialsActions.getTestimonialsFailure(error));
  }
}

export function* addTestimonial(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addTestimonial, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(TestimonialsActions.addTestimonialSuccess(response.data));
    } else {
      yield put(TestimonialsActions.addTestimonialFailure(response.data));
    }
  } catch (error) {
    yield put(TestimonialsActions.addTestimonialFailure(error));
  }
}

export function* updateTestimonial(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateTestimonial, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(TestimonialsActions.updateTestimonialSuccess(response.data));
    } else {
      yield put(TestimonialsActions.updateTestimonialFailure(response.data));
    }
  } catch (error) {
    yield put(TestimonialsActions.updateTestimonialFailure(error));
  }
}

export function* deleteTestimonial(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteTestimonial, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(TestimonialsActions.deleteTestimonialSuccess(response.data));
    } else {
      yield put(TestimonialsActions.deleteTestimonialFailure(response.data));
    }
  } catch (error) {
    yield put(TestimonialsActions.deleteTestimonialFailure(error));
  }
}

export function* changeTestimonialStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.changeTestimonialStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        TestimonialsActions.changeTestimonialStatusSuccess(response.data)
      );
    } else {
      yield put(
        TestimonialsActions.changeTestimonialStatusFailure(response.data)
      );
    }
  } catch (error) {
    yield put(TestimonialsActions.changeTestimonialStatusFailure(error));
  }
}

export function* updateTestimonialPriority(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.updateTestimonialPriority, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        TestimonialsActions.updateTestimonialPrioritySuccess(response.data)
      );
    } else {
      yield put(
        TestimonialsActions.updateTestimonialPriorityFailure(response.data)
      );
    }
  } catch (error) {
    yield put(TestimonialsActions.updateTestimonialPriorityFailure(error));
  }
}
