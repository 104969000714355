import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Paper from "@mui/material/Paper";
import { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Checkbox } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import { RouteConstants } from "../../../routes/RouteConstants";
import { formatDateTime } from "utils/DateFormatterUtils";
import { exportAsExcel } from "utils/ExportFileUtils";
import Loader from "components/loader/Loader";
import Retry from "components/retry/Retry";
import { Constants } from "constants/Constants";
import { TextFieldComponent } from "components/ui-elements";
import {
  ProjectCollectionStatusTypeEnum,
  ProjectCollectionStatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import {
  ProjectCollectionActionTypeEnum,
  ProjectCollectionActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import ProjectCollectionListAction from "redux-container/project-management/project-collection/ProjectCollectionListRedux";
import MediaAssets from "../../../assets";
import ProjectCollectionActions from "../../../redux-container/project-management/project-collection/ProjectCollectionCreationRedux";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";

const actionDispatch = (dispatch: any) => {
  return {
    getProjectCollectionList: (params: any) =>
      dispatch(
        ProjectCollectionListAction.getProjectCollectionListRequest(params)
      ),
    deleteProjectCollectionList: (data: any) =>
      dispatch(
        ProjectCollectionListAction.deleteProjectCollectionListRequest(data)
      ),
    changeProjectCollectionListStatus: (data: any) =>
      dispatch(
        ProjectCollectionListAction.changeProjectCollectionListStatusRequest(
          data
        )
      ),
    setProjectCollectionListState: (key: any, data: any) =>
      dispatch(
        ProjectCollectionListAction.setProjectCollectionListState(key, data)
      ),
    setProjectCollectionState: (key: any, data: any) =>
      dispatch(ProjectCollectionActions.setProjectCollectionState(key, data)),
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
  };
};

const ProjectCollectionList = () => {
  const {
    getProjectCollectionList,
    deleteProjectCollectionList,
    changeProjectCollectionListStatus,
    setProjectCollectionListState,
    setProjectCollectionState,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());

  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const projectCollectionListData = useSelector(
    (state: any) => state?.projectCollectionList?.projectCollection
  );

  const isLoading = useSelector(
    (state: any) => state.projectCollectionList.isLoading
  );
  const error = useSelector((state: any) => state.projectCollectionList.error);
  const isSuccess = useSelector(
    (state: any) => state.projectCollectionList.isSuccess
  );
  const message = useSelector(
    (state: any) => state.projectCollectionList.message
  );
  const totalCount = useSelector(
    (state: any) => state?.projectCollectionList?.totalCount
  );
  const { page, rowsPerPage } = useSelector((state: any) => {
    return {
      page: state?.projectCollectionList?.page,
      rowsPerPage: state?.projectCollectionList?.rowsPerPage,
    };
  });
  const actions =
    ProjectCollectionActionTypeEnumUtils.getProjectCollectionActionTypeEnum();
  const statuses =
    ProjectCollectionStatusTypeEnumUtils.getProjectCollectionStatusTypeEnums();
  const dataRecievedProjectCollectionList = useSelector(
    (state: any) =>
      state?.projectCollectionList?.dataRecievedProjectCollectionList
  );

  const [searchKey, setSearchKey] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortingPriority, setSortingPriority] = useState(0);
  const [selectedAction, setSelectedAction] = useState<any>(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [selectedProjectCollections, setSelectedProjectCollections] =
    useState<any>([]);

  const [modulesAudited, setModulesAudited] = useState<any>(null);
  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);
  const modules = useSelector((state: any) => state?.rolesAndModules?.module);

  const tableHeadings = [
    { heading: "Collection Name" },
    { heading: "No. of Projects" },
    { heading: "Created on" },
    { heading: "Modified on" },
    { heading: "Last Modified by" },
    { heading: "Status" },
    { heading: "Actions" },
  ];

  const breadcrumbRoutes: any = [
    {
      name: "Project Management",
      route: RouteConstants.projectManagementDashboard,
    },
    {
      name: "Project Collections",
      route: RouteConstants.projectCollections,
    },
  ];

  useEffect(() => {
    const params = {};
    getFilterDataFromDashboard();
    getProjectCollectionList(params);
  }, []);

  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setProjectCollectionListState("page", 0);
      setProjectCollectionListState("rowsPerPage", 10);
    }
    return () => {};
  }, []);

  useEffect(() => {
    const params = {
      index: page + 1,
      size: rowsPerPage,
      status: location?.state?.statusType
        ? location?.state?.statusType
        : selectedStatus,
      searchKey: searchKey,
    };
    loadData(params);
    clearSelected();
    setSelectedAction("");
    setSelectedProjectCollections([]);
  }, [dispatch, page, rowsPerPage, searchKey, selectedStatus]);

  useEffect(() => {
    if (isSuccess) {
      setProjectCollectionListState("success", false);
      const params = {
        index: page + 1,
        size: rowsPerPage,
        status: location?.state?.statusType
          ? location?.state?.statusType
          : selectedStatus,
        searchKey: searchKey,
      };
      loadData(params);
      clearSelected();
    }
  }, [isSuccess]);

  const loadData = (params: any) => {
    getProjectCollectionList(params);
  };

  const getFilterDataFromDashboard = () => {
    if (location && location.state && location.state.statusType) {
      setSelectedStatus(location.state.statusType as string);
    }
    navigate(location.pathname, { replace: true });
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setProjectCollectionListState("page", newPage);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = projectCollectionListData.map(
        (data: any) => data.id
      );
      setSelectedProjectCollections(newSelecteds);
      return;
    }
    setSelectedProjectCollections([]);
  };

  const handleSelectClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedProjectCollections.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedProjectCollections, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedProjectCollections.slice(1));
    } else if (selectedIndex === selectedProjectCollections.length - 1) {
      newSelected = newSelected.concat(selectedProjectCollections.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedProjectCollections.slice(0, selectedIndex),
        selectedProjectCollections.slice(selectedIndex + 1)
      );
    }
    setSelectedProjectCollections(newSelected);
  };

  const clearSelected = () => {
    setSelectedProjectCollections([]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProjectCollectionListState("rowsPerPage", Number(event.target.value));
    setProjectCollectionListState("page", 0);
  };

  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelectedOption = event.target.value;

    switch (Number(actionSelectedOption)) {
      case ProjectCollectionActionTypeEnum.Export:
        handleExportAsExcel(event);
        break;

      case ProjectCollectionActionTypeEnum.Delete:
        handleDeleteProjectCollection(event);
        break;
      case ProjectCollectionActionTypeEnum.MarkAsPublished:
        changeProjectCollectionStatuses(
          String(ProjectCollectionStatusTypeEnum.Published)
        );
        break;
      case ProjectCollectionActionTypeEnum.MarkAsInactive:
        changeProjectCollectionStatuses(
          String(ProjectCollectionStatusTypeEnum.Inactive)
        );
        break;
    }
  };

  const handleExportAsExcel = (event: any) => {
    if (selectedProjectCollections.length === 0) {
      alert("You have not selected any Collection");
      return;
    }
    let exportingProjectCollections: any = [];
    if (projectCollectionListData.length) {
      selectedProjectCollections.map((selectedCollection: any) => {
        let localSelectedItem = projectCollectionListData.find(
          (item: any) => item.id == selectedCollection
        );
        exportingProjectCollections.push({
          Collection_Name: localSelectedItem.name,
          No_Of_Projects: localSelectedItem?.collectionsOrProjectContents
            ?.length
            ? localSelectedItem?.collectionsOrProjectContents?.length
            : "0",
          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Modified_On: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Last_Modified_By: localSelectedItem?.collectionUpdatedBy?.firstName
            ? localSelectedItem?.collectionUpdatedBy.firstName
            : "----",
          Status: localSelectedItem.status
            ? ProjectCollectionStatusTypeEnumUtils.getEnumText(
                localSelectedItem.status
              )
            : "----",
        });
      });
    }

    // setSelectedAction(event.target.value as String);
    exportAsExcel(exportingProjectCollections, "ProjectCollection", "Sheet1");
    setSelectedProjectCollections([]);
  };

  const handleDeleteProjectCollection = (event: any) => {
    if (selectedProjectCollections.length === 0) {
      alert("You have not selected any Project collections");
      return;
    }
    // setSelectedAction(event.target.value as String);
    setIsConfirmDeleteDialogOpen(true);
  };

  const deleteSelectedProjectCollections = () => {
    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Collection ${item?.name} Deleted.(ID ${item?.id})`,
        newValue: "",
        oldValue: "",
        auditedObject: null,
        route: null,
        status: AuditStatus.DELETED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    setIsConfirmDeleteDialogOpen(false);
    const ProjectCollections = {
      collectionIds: selectedProjectCollections,
    };
    deleteProjectCollectionList(ProjectCollections);
    clearSelected();
  };

  const changeProjectCollectionStatuses = (status: string) => {
    let auditedItems = statusAuditedItems();

    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Collection Status`,
        newValue:
          ProjectCollectionStatusTypeEnum.Published === status
            ? "Published"
            : "Inactive",
        oldValue:
          ProjectCollectionStatusTypeEnum.Published === status
            ? "Inactive"
            : "Published",
        auditedObject: {
          projectCollection: { ...item, status: status },
          isEditMode: true,
        },
        route: RouteConstants?.projectCollectionCreation,
        status: AuditStatus.EDITED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    const data = {
      status: status,
      collectionIds: selectedProjectCollections,
    };

    if (selectedProjectCollections.length === 0) {
      alert("you have not selected any Project Collections");
      return;
    }
    changeProjectCollectionListStatus(data);
  };

  const clearFilters = () => {
    setSelectedStatus("");
    setSearchKey("");
  };

  const isSelected = (id: any) => selectedProjectCollections.indexOf(id) !== -1;

  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 0) {
      setProjectCollectionListState("page", 0);
    }
  };

  const resetState = () => {
    setProjectCollectionListState("message", null);
    setProjectCollectionListState("error", null);
    setProjectCollectionListState("dataRecievedProjectCollectionList", false);
  };

  const handleCloseFlashMessageDialog = () => {
    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Project Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: null,
      auditedObject: null,
      modulesAudited: modulesAudited,
      status: null,
    };
    postAuditLogRequest(postAuditRequestbody);

    // resetState();
    setProjectCollectionListState("message", null);
    setProjectCollectionListState("error", null);
    setProjectCollectionListState("dataRecievedProjectCollectionList", false);
    setProjectCollectionState("dataRecivedProjectCollection", false);
    setProjectCollectionState("error", null);
    setProjectCollectionState("message", null);
    clearSelected();
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
    if (page !== 0) {
      setProjectCollectionListState("page", 0);
    }
  };

  const handleCreate = () => {
    navigate(RouteConstants.projectCollectionCreation);
    setProjectCollectionState("singleProjectCollection", null);
  };

  const createProjectCollection = () => {
    setProjectCollectionState("isCreationProjectCollection", true);
    setProjectCollectionState("creationProjectCollection", null);
    navigate(RouteConstants.projectCollectionCreation);
  };

  const editProjectCollection = (projectCollection: any) => {
    const dataEdit = {
      isEditMode: true,
      projectCollection: projectCollection,
    };
    navigate(`${RouteConstants.projectCollectionCreation}`, {
      state: dataEdit,
    });
    setProjectCollectionState("mappedProjectCollectionList", []);
    setProjectCollectionState("isCreationProjectCollection", false);
  };

  const viewProjectCollection = (projectCollection: any) => {
    const dataToView = {
      isViewMode: true,
      projectCollection: projectCollection,
    };
    navigate(`${RouteConstants.projectCollectionCreation}`, {
      state: dataToView,
    });
    setProjectCollectionState("isCreationProjectCollection", false);
    setProjectCollectionState("mappedProjectCollectionList", []);
  };

  const statusAuditedItems = () => {
    let auditedItems: any[] = [];
    projectCollectionListData?.map((item: any) => {
      selectedProjectCollections?.map((itemId: number) => {
        if (itemId === item.id) {
          auditedItems.push(item);
        }
      });
    });
    return auditedItems;
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_project_collections}
        altText=""
        message="You have no collections yet. Create your first collection."
        btnTitle="CREATE COLLECTION"
        btnAction={createProjectCollection}
      />
    );
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Project Collections
            {`(${
              totalCount
                ? totalCount
                : projectCollectionListData?.length
                ? projectCollectionListData?.length
                : 0
            })`}
            {selectedProjectCollections.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedProjectCollections.length} Project Collection(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Action"
                onChange={handleActionChange}
              >
                {actions.map((action: any) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>
            <Button
              variant="contained"
              onClick={handleCreate}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create
            </Button>
          </div>
        </div>

        <Grid container spacing={1}>
          <Grid container spacing={2} md={8} lg={8} xl={8}>
            <Grid
              item
              md={1}
              lg={1}
              xl={1}
              className="margin-left-10 filter-heading-holder"
            >
              <span className="filter-heading">Filters:</span>
            </Grid>

            <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedStatus}
                  label="Status"
                  onChange={handleStatusChange}
                >
                  {statuses.map((status: any) => {
                    return (
                      <MenuItem value={status.value} key={status.value}>
                        {status.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            {selectedStatus !== "" ? (
              <Grid
                item
                md={0.5}
                lg={0.5}
                xl={0.5}
                className="filter-heading-holder"
                style={{ position: "relative" }}
              >
                <span
                  onClick={clearFilters}
                  className="clear-filter"
                  style={{ position: "absolute", top: 32 }}
                >
                  Clear
                </span>
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475, position: "relative" }}
        >
          <Table>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedProjectCollections.length > 0 &&
                      selectedProjectCollections.length <
                        projectCollectionListData.length
                    }
                    checked={
                      projectCollectionListData.length > 0 &&
                      selectedProjectCollections.length ===
                        projectCollectionListData.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all collections" }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                    {/* {index === 2 && (
                      <IconButton
                        onClick={() => {
                          setCreatedBySort(!isCreatedBySort);
                          if (sortingPriority === 0) {
                            setSortingKey(1);
                            setSortingPriority(1);
                          } else {
                            setSortingPriority(0);
                            setSortingKey(0);
                          }
                        }}
                      >
                        {isCreatedBySort ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropUpIcon />
                        )}
                      </IconButton>
                    )} */}
                    {/* {index === 3 && (
                      <IconButton
                        onClick={() => {
                          setUpdatedBySort(!isUpdatedBySort);
                          if (sortingPriority === 0) {
                            setSortingKey(0);
                            setSortingPriority(1);
                          } else {
                            setSortingPriority(0);
                            setSortingKey(0);
                          }
                        }}
                      >
                        {isUpdatedBySort ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropUpIcon />
                        )}
                      </IconButton>
                    )} */}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {projectCollectionListData &&
            projectCollectionListData.length > 0 ? (
              <TableBody>
                {projectCollectionListData.map((collection: any) => {
                  const isItemSelected = isSelected(collection.id);
                  return (
                    <TableRow
                      key={collection.id}
                      className="table-data-row"
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ minWidth: 50 }} padding="checkbox">
                        <Checkbox
                          className="active-checkbox"
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleSelectClick(event, collection.id)
                          }
                        />
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 50 }}
                      >
                        {collection?.name}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 50 }}
                      >
                        {collection?.collectionsOrProjectContents?.length
                          ? collection?.collectionsOrProjectContents?.length
                          : "----"}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150 }}
                      >
                        <div>
                          {collection &&
                          collection.collectionCreatedBy &&
                          collection.collectionCreatedBy.firstName
                            ? collection?.collectionCreatedBy.firstName
                            : null}
                        </div>
                        <div>
                          {collection.createdAt
                            ? formatDateTime(
                                collection?.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "----"}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 100 }}
                      >
                        <div>
                          {collection &&
                          collection.collectionUpdatedBy &&
                          collection.collectionUpdatedBy.firstName
                            ? collection?.collectionUpdatedBy.firstName
                            : null}
                        </div>
                        <div>
                          {collection.updatedAt
                            ? formatDateTime(
                                collection.updatedAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "----"}
                        </div>
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 100 }}
                      >
                        <div>
                          {collection &&
                          collection.collectionUpdatedBy &&
                          collection.collectionUpdatedBy.firstName
                            ? collection?.collectionUpdatedBy.firstName
                            : null}
                        </div>
                      </TableCell>

                      <TableCell className="table-data-text">
                        {collection.status
                          ? ProjectCollectionStatusTypeEnumUtils.getEnumText(
                              collection.status
                            )
                          : "----"}
                      </TableCell>

                      <TableCell style={{ minWidth: 100 }}>
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editProjectCollection(collection)}
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewProjectCollection(collection)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Project collection Records</div>
            )}
          </Table>
        </TableContainer>

        {!searchKey &&
        projectCollectionListData &&
        projectCollectionListData.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={totalCount ? totalCount : projectCollectionListData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <PageHeading title="Project Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder="Search Collections"
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Project Collection(s)?"
        content={`This action will delete ${selectedProjectCollections.length} selected Project Collection(s). Are you sure you want to continue?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteSelectedProjectCollections}
      />

      <FlashMessageDialog
        shouldOpen={dataRecievedProjectCollectionList}
        content={message}
        isSuccess={error === null ? true : false}
        cancelHandler={handleCloseFlashMessageDialog}
        cancel={false}
      />
      {/* {error ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null} */}
    </Fragment>
  );
};

export default ProjectCollectionList;
