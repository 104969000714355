import { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../routes/RouteConstants";
import { ButtonComponent } from "../../../components/ui-elements";
import styles from "./ProjectList.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Divider } from "@mui/material";
import { launchPhasesAction } from "redux-container/project-management/launch-phases/LaunchPhasesRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import projectListActions from "redux-container/project-management/ProjectListRedux";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import UserActions from "redux-container/users/UsersRedux";
import { ProjectListConstants } from "./ProjectListConstants";
import ProjectDraftsList from "redux-container/project-management/project-drafts/ProjectDraftsRedux";
import { priceFormatter } from "utils/AmountFormatter";

const actionDispatch = (dispatch: any) => {
  return {
    updateProjectsStatus: (toBeUpdatingIdies: any) => {
      dispatch(
        projectListActions.updateProjectsStatusRequest(toBeUpdatingIdies)
      );
    },
    getAllLaunchPhases: (params: any) =>
      dispatch(launchPhasesAction.getAllLaunchPhasesRequest(params)),
    setLaunchPhasesState: (key: any, data: any) =>
      dispatch(launchPhasesAction.setLaunchPhasesState(key, data)),
    verifyLaunchPhase: (params: any) =>
      dispatch(launchPhasesAction.verifyLaunchPhaseDataRequest(params)),
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),
    getProjectList: async (params: any) => {
      dispatch(projectListActions.projectListRequest(params));
    },
    getProjectDraftsList: (data: any) =>
      dispatch(ProjectDraftsList.getProjectDraftRequest(data)),
  };
};

const ProjectNewLaunchList = (props: any) => {
  const { searchKeyOfNewTable } = props;
  const newTableHeadings = [
    { heading: "Launch ID" },
    { heading: "CRM Launch Name" },
    { heading: "CRM Project Name" },
    { heading: "Starting Price" },
    { heading: "Starting Area(sqft)" },
    { heading: "Fetched on" },
    { heading: "Actions" },
  ];
  const {
    updateProjectsStatus,
    getAllLaunchPhases,
    setLaunchPhasesState,
    verifyLaunchPhase,
    getProjectDraftsList,
    getProjectList,
  } = actionDispatch(useDispatch());
  const navigate = useNavigate();
  const location: any = useLocation();

  const {
    projectDraftList,
    verifyLaunchPhaseError,
    totalCountOfNewProjects,
    publishedProjects,
    fetchedAllLaunchPhasesData,
    launchPhasesLoader,
    pageOfNewTable,
    rowsPerPageOfNewTable,
    verifyLauchPhaseInventoryCount,
    isVerifyLoading,
  } = useSelector((state: any) => {
    return {
      projectDraftList: state?.projectDrafts?.data,

      verifyLaunchPhaseError: state?.projectLaunchPhases.error,
      totalCountOfNewProjects:
        state?.projectLaunchPhases?.totalCountOfNewProjects,
      publishedProjects: state?.projectList?.data?.data,
      launchPhasesLoader: state.projectLaunchPhases?.isLoading,
      fetchedAllLaunchPhasesData:
        state.projectLaunchPhases?.fetchedAllLaunchPhasesData,
      pageOfNewTable: state?.projectLaunchPhases?.pageOfNewTable,
      rowsPerPageOfNewTable: state?.projectLaunchPhases?.rowsPerPageOfNewTable,
      verifyLauchPhaseInventoryCount:
        state?.projectLaunchPhases?.verifiedResponse?.inventoryBucketsCount,
      isVerifyLoading: state?.projectLaunchPhases.isLoading,
    };
  });

  const [projectCurrentId, setProjectCurrentId] = useState<any>();
  const [openVerifiedFlashMessage, setOpenVerifiedFlashMessage] =
    useState(false);
  const [errorText, setErrorText] = useState<any>("");
  const [showAnyErrorMessage, setShowAnyErrorMessage] = useState(false);
  const [crmProjId, setcrmProjId] = useState<any>();

  // Verify For Current Project Do we Have inventory Bucket. If No Inventory Bucket Show Error Popup(openVerifiedFlashMessage)
  const navigateToProjectCreation = (currentId: any) => {
    if (verifyLaunchPhaseError) {
      setOpenVerifiedFlashMessage(true);
      setErrorText(verifyLaunchPhaseError);
    } else if (
      verifyLauchPhaseInventoryCount !== undefined &&
      verifyLauchPhaseInventoryCount === 0 &&
      showAnyErrorMessage
    ) {
      setOpenVerifiedFlashMessage(true);
      setErrorText(ProjectListConstants.noInventoryWarning);
    } else {
      !isVerifyLoading &&
        !verifyLaunchPhaseError &&
        verifyLauchPhaseInventoryCount > 0 &&
        navigate(RouteConstants.projectCreation, {
          state: {
            isNew: true,
            ProjectListCreationId: currentId,
            isViewMode: false,
          },
        });
    }
  };

  const handleCreateContent = async (currentId: number) => {
    setShowAnyErrorMessage(true);
    setProjectCurrentId(currentId);

    await verifyLaunchPhase(currentId);
  };

  const handleVerifyLauchPhaseErrorClose = () => {
    // updateSelectedProjectsStatus("1005", projectCurrentId);
    setLaunchPhasesState("error", null);
    setShowAnyErrorMessage(false);
    setOpenVerifiedFlashMessage(false);
  };

  useEffect(() => {
    if (projectCurrentId) {
      navigateToProjectCreation(projectCurrentId);
    }
  }, [verifyLauchPhaseInventoryCount, isVerifyLoading]);

  useEffect(() => {
    getProjectDraftsList({});
    getProjectList({});
  }, []);

  useEffect(() => {
    const params = {
      size: rowsPerPageOfNewTable,
      index: pageOfNewTable + 1,
      searchKey: searchKeyOfNewTable,
    };
    getAllLaunchPhases(params);
  }, [searchKeyOfNewTable, rowsPerPageOfNewTable, pageOfNewTable]);

  const handleChangePageOfNewTable = (event: unknown, newPage: number) => {
    setLaunchPhasesState("pageOfNewTable", newPage);
  };

  const handleChangeRowsPerPageOfNewTable = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLaunchPhasesState("rowsPerPageOfNewTable", Number(event.target.value));
    setLaunchPhasesState("pageOfNewTable", 0);
  };

  //This action will redirect to that perticular drafted project content from new launch list.
  const handleDraftEditIconClick = (draftId: number, draft: any) => {
    navigate(RouteConstants.projectCreation, {
      state: {
        isNew: true,
        ProjectListCreationId: draftId,
        projectContent: draft,
        isViewMode: false,
        isDraft: true,
      },
    });
  };

  //This action will redirect to that perticular project content from new launch list.
  const handleEditIconClick = (publishedId: number) => {
    navigate(RouteConstants.projectCreation, {
      state: {
        isNew: false,
        ProjectListCreationId: publishedId,
        isViewMode: false,
        isEditMode: true,
      },
    });
  };

  // Extract crmLaunchPhaseId Project Draft to check is project created under this crmLaunchPhaseId
  const projectDraftIds = projectDraftList?.map(
    (draft: any) => draft?.crmLaunchPhaseId
  );

  // Extract crmLaunchPhaseId Project content to check is project created under this crmLaunchPhaseId
  const projectContentIds = publishedProjects?.map(
    (project: any) => project?.crmLaunchPhaseId
  );
  const updateSelectedProjectsStatus = async (status: string, ids: any) => {
    const projectsToBeUpdated = {
      contentIds: [].concat(ids),
      status: status,
    };

    updateProjectsStatus(projectsToBeUpdated);
  };
  console.log("fetchedAllLaunchPhasesData", fetchedAllLaunchPhasesData);

  return (
    <Fragment>
      <Grid container spacing={1}></Grid>

      {launchPhasesLoader ? (
        <Loader />
      ) : (
        <>
          {openVerifiedFlashMessage && showAnyErrorMessage && (
            <FlashMessageDialog
              shouldOpen={openVerifiedFlashMessage}
              content={errorText}
              isSuccess={false}
              showWarning={verifyLaunchPhaseError === errorText ? false : true}
              cancelHandler={handleVerifyLauchPhaseErrorClose}
            />
          )}
          <TableContainer
            component={Paper}
            style={{ height: window.innerHeight - 475 }}
            className="table-container"
          >
            <Table style={{ position: "relative", borderSpacing: "0 4px" }}>
              <TableHead>
                <TableRow className="table-header-row">
                  {newTableHeadings.map((item, index) => (
                    <TableCell key={index} className="table-header-text">
                      {item.heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {fetchedAllLaunchPhasesData?.length > 0 ? (
                <TableBody>
                  {fetchedAllLaunchPhasesData?.map((newTable: any) => (
                    <TableRow
                      hover
                      key={newTable.id}
                      className="table-data-row"
                    >
                      <TableCell className="table-data-text">
                        {`#ID-${newTable.id}`}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150, maxWidth: 170 }}
                      >
                        {newTable.launchName}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150, maxWidth: 170 }}
                      >
                        {newTable?.crmProjectName
                          ? newTable?.crmProjectName
                          : newTable["crmProject.name"]
                          ? newTable["crmProject.name"]
                          : "-"}
                      </TableCell>

                      <TableCell className="table-data-text">
                        {newTable?.priceStartingFrom
                          ? "₹" + priceFormatter(newTable.priceStartingFrom)
                          : "-"}
                      </TableCell>

                      <TableCell className="table-data-text">
                        {newTable?.areaStartingFrom
                          ? priceFormatter(newTable.areaStartingFrom)
                          : "-"}
                      </TableCell>

                      <TableCell className="table-data-text">
                        {newTable?.createdAt
                          ? formatDateTime(
                              newTable?.createdAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "-"}
                      </TableCell>

                      <TableCell>
                        <ButtonComponent
                          variant="text"
                          onClick={() => {
                            let isDraftedProject = projectDraftList?.find(
                              (draft: any) =>
                                draft?.crmLaunchPhaseId === newTable?.id
                            );
                            let isPublishedProject = publishedProjects?.find(
                              (pulished: any) =>
                                pulished?.crmLaunchPhaseId === newTable?.id
                            );
                            if (isDraftedProject) {
                              handleDraftEditIconClick(
                                isDraftedProject?.id,
                                isDraftedProject
                              );
                            } else if (isPublishedProject) {
                              handleEditIconClick(isPublishedProject?.id);
                            } else {
                              handleCreateContent(newTable.id);
                              setcrmProjId(newTable?.crmProjId);
                            }
                          }}
                          className={styles["create-content-link"]}
                        >
                          {projectDraftIds?.includes(newTable.id)
                            ? "Drafted"
                            : projectContentIds?.includes(newTable.id)
                            ? "Published"
                            : "Create Content >"}
                        </ButtonComponent>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <div className="no-record">No Projects Found</div>
              )}
            </Table>
          </TableContainer>

          <Divider
            style={{
              width: "100%",
            }}
          />

          {!searchKeyOfNewTable && (
            <TablePagination
              className={styles["table-pagination"]}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                totalCountOfNewProjects
                  ? totalCountOfNewProjects
                  : fetchedAllLaunchPhasesData?.length
              }
              rowsPerPage={rowsPerPageOfNewTable}
              page={pageOfNewTable}
              onPageChange={handleChangePageOfNewTable}
              onRowsPerPageChange={handleChangeRowsPerPageOfNewTable}
            />
          )}
        </>
      )}
    </Fragment>
  );
};

export default ProjectNewLaunchList;
