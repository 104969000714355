import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

type BlogsStateType = typeof INITIAL_STATE;

export interface IReduxSuccessActionProps {
  response?: {
    code: number;
    data: [];
    message: string;
  };
}

export interface IReduxFailureActionProps {
  error: {
    message: string;
  };
}

const { Types, Creators } = createActions({
  blogSuccess: ["response"],
  blogFailure: ["error"],
  blogRequest: ["data"],

  deleteBlogSuccess: ["response"],
  deleteBlogFailure: ["error"],
  deleteBlogRequest: ["data"],

  blogStatusSuccess: ["response"],
  blogStatusFailure: ["error"],
  blogStatusRequest: ["data"],

  blogPrioritySuccess: ["response"],
  blogPriorityFailure: ["error"],
  blogPriorityRequest: ["data"],

  setBlogState: ["key", "value"],
});

export const BlogTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  updates: [],
  drafts: [],
  dashboardData: null,
  pageSize: Constants.defaultPageSize,
  pageIndex: Constants.defaultPageIndex,
  totalPages: Constants.defaultTotalPages,
  totalCount: Constants.defaultTotalCount,
  shouldOpenFlashDialog: false,
  error: null,
  isLoading: false,
  isPriorityUpdated: false,
  snackBarError: "",
  message: "",
  isUpdated: false,
  page: 0,
  rowsPerPage: 10,
};

/* Get update */ export const blogRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const blogSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      updates: response.data,
      isLoading: false,
      // isUpdated: false,
      totalCount: response.totalCount,
      // message : response.message
    };
  }

  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const blogFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// blog delete
export const deleteBlogRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    message: "",
  };
};

export const deleteBlogSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      isDeleted: true,
      totalCount: response.totalCount,
      message: "Blog Successfully deleted!",
    };
  }
  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const deleteBlogFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: "Blog Not deleted!",
  };
};

// Updating Admin Blog status
export const blogStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const blogStatusSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      message: "Blog Status Updated!",
    };
  }

  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const blogStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: "Blog Status Not Updated!",
  };
};

// Update Admin updates status
export const blogPriorityRequest = (state: any) => {
  return {
    ...state,
    // isLoading: true,
    isUpdated: false,
  };
};

export const blogPrioritySuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      // isUpdated: true,
      isPriorityUpdated: true,
    };
  }
};

export const blogPriorityFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    // isUpdated: true,
  };
};

export const setBlogState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BLOG_SUCCESS]: blogSuccess,
  [Types.BLOG_FAILURE]: blogFailure,
  [Types.BLOG_REQUEST]: blogRequest,

  [Types.DELETE_BLOG_SUCCESS]: deleteBlogSuccess,
  [Types.DELETE_BLOG_FAILURE]: deleteBlogFailure,
  [Types.DELETE_BLOG_REQUEST]: deleteBlogRequest,

  [Types.BLOG_STATUS_REQUEST]: blogStatusRequest,
  [Types.BLOG_STATUS_SUCCESS]: blogStatusSuccess,
  [Types.BLOG_STATUS_FAILURE]: blogStatusFailure,

  [Types.BLOG_PRIORITY_REQUEST]: blogPriorityRequest,
  [Types.BLOG_PRIORITY_SUCCESS]: blogPrioritySuccess,
  [Types.BLOG_PRIORITY_FAILURE]: blogPriorityFailure,

  [Types.SET_BLOG_STATE]: setBlogState,
});
