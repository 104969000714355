export const enum TopicEnums {
  Portfolio = 115,
  PlotBookingStatus = 116,
  DevelopmentStatus = 117,
  ProjectDetails = 106,
  PaymentRelated = 118,
  Others = 105,
}
export class TopicEnumsUtils {
  public static getEnumText(type: TopicEnums): string {
    switch (type) {
      case TopicEnums.Portfolio:
        return "Portfolio";
      case TopicEnums.PlotBookingStatus:
        return "Plot Booking Status";
      case TopicEnums.DevelopmentStatus:
        return "Development Status";
      case TopicEnums.ProjectDetails:
        return "Project Details";
      case TopicEnums.PaymentRelated:
        return "Payment Related";

      case TopicEnums.Others:
        return "Others";
    }
  }

  public static getTopicEnums(): {
    label: string;
    value: TopicEnums;
  }[] {
    const options: { label: string; value: TopicEnums }[] = [
      {
        label: this.getEnumText(TopicEnums.Portfolio),
        value: TopicEnums.Portfolio,
      },
      {
        label: this.getEnumText(TopicEnums.PlotBookingStatus),
        value: TopicEnums.PlotBookingStatus,
      },
      {
        label: this.getEnumText(TopicEnums.DevelopmentStatus),
        value: TopicEnums.DevelopmentStatus,
      },
      {
        label: this.getEnumText(TopicEnums.ProjectDetails),
        value: TopicEnums.ProjectDetails,
      },
      {
        label: this.getEnumText(TopicEnums.PaymentRelated),
        value: TopicEnums.PaymentRelated,
      },

      {
        label: this.getEnumText(TopicEnums.Others),
        value: TopicEnums.Others,
      },
    ];
    return options;
  }
}
