import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Checkbox from "@mui/material/Checkbox";
import ConfirmationDialog from "../../../../components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MediaAssets from "../../../../assets";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NoDataRecord from "../../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SwitchComponent } from "../../../../components/ui-elements";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const PromotionsAndOffersList = () => {
  const navigate = useNavigate();

  const [selectedPromotionsAndOffers, setSelectedPromotionsAndOffers] =
    useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const breadcrumbRoutes: any = [
    {
      name: "Marketing Management",
      route: RouteConstants.marketingManagementDashboard,
    },
    {
      name: "Promotion & Offers",
      route: RouteConstants.promotionsAndOffersList,
    },
  ];

  const tableHeadings = [
    { heading: "Type" },
    { heading: "Title" },
    { heading: "Sub Title" },
    { heading: "Created By" },
    { heading: "Created On" },
    { heading: "Project" },
    { heading: "Status" },
    { heading: "Actions" },
  ];

  const promotionsAndOffers = [
    {
      id: 1,
      type: "Promotion",
      title: "Dont miss out",
      subtitle:
        "Buy more than 3 SKUs in Anjarle and get all the amenities for free!",
      createdBy: "User C",
      createdOn: "02-10-2022",
      project: "The RUSH",
      status: true,
    },
    {
      id: 2,
      type: "Offer",
      title: "Empower someone",
      subtitle:
        "Buy more than 2 SKUs in Dapoli and get all the amenities for free!",
      createdBy: "User C",
      createdOn: "02-10-2022",
      project: "Dapoli",
      status: true,
    },
  ];

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleActionChange = (event: SelectChangeEvent) => {
    setSelectedAction(event.target.value as string);
  };

  const handleTitleChange = (event: SelectChangeEvent) => {
    setSelectedTitle(event.target.value as string);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value as string);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = promotionsAndOffers.map((data) => data.title);
      setSelectedPromotionsAndOffers(newSelecteds);
      return;
    }
    setSelectedPromotionsAndOffers([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, title: string) => {
    const selectedIndex = selectedPromotionsAndOffers.indexOf(title);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPromotionsAndOffers, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPromotionsAndOffers.slice(1));
    } else if (selectedIndex === selectedPromotionsAndOffers.length - 1) {
      newSelected = newSelected.concat(
        selectedPromotionsAndOffers.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPromotionsAndOffers.slice(0, selectedIndex),
        selectedPromotionsAndOffers.slice(selectedIndex + 1)
      );
    }

    setSelectedPromotionsAndOffers(newSelected);
  };

  const isSelected = (title: string) =>
    selectedPromotionsAndOffers.indexOf(title) !== -1;

  const createPromotionsAndOffers = () => {
    navigate(RouteConstants.promotionAndOffersCreation);
  };

  const deletePromotionsAndOffers = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const editPromotionsAndOffers = () => { };

  const viewPromotionsAndOffers = () => { };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_promotion_offer}
        altText=""
        message="You haven't created any Promotion & Offer yet. Create your first Promotion & Offer."
        btnTitle="CREATE PROMOTION & OFFER"
        btnAction={createPromotionsAndOffers}
      />
    );
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Promotions & Offers {`(${promotionsAndOffers.length})`}
          </h4>

          <div>
            <FormControl className="action-dropdown">
              <InputLabel>Actions</InputLabel>
              <Select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
                IconComponent={() => (
                  <ExpandMoreRoundedIcon className="dropdown-icon" />
                )}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={createPromotionsAndOffers}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New
            </Button>
          </div>
        </div>

        <Grid container spacing={1}>
          <Grid container spacing={2} md={8} lg={8} xl={8}>
            <Grid
              item
              md={1}
              lg={1}
              xl={1}
              className="margin-left-10 filter-heading-holder"
            >
              <span className="filter-heading">Filters:</span>
            </Grid>

            <Grid item md={2} lg={2} xl={2}>
              <FormControl className="filter-dropdown">
                <InputLabel>Title</InputLabel>
                <Select
                  value={selectedTitle}
                  label="Title"
                  onChange={handleTitleChange}
                  IconComponent={() => (
                    <ExpandMoreRoundedIcon className="dropdown-icon" />
                  )}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={2} lg={2} xl={2}>
              <FormControl className="filter-dropdown">
                <InputLabel>Type</InputLabel>
                <Select
                  value={selectedType}
                  label="Type"
                  onChange={handleTypeChange}
                  IconComponent={() => (
                    <ExpandMoreRoundedIcon className="dropdown-icon" />
                  )}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer component={Paper} className="table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedPromotionsAndOffers.length > 0 &&
                      selectedPromotionsAndOffers.length <
                      promotionsAndOffers.length
                    }
                    checked={
                      promotionsAndOffers.length > 0 &&
                      selectedPromotionsAndOffers.length ===
                      promotionsAndOffers.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all promotions and offers",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {promotionsAndOffers.map((data) => {
                const isItemSelected = isSelected(data.title);

                return (
                  <TableRow
                    key={data.id}
                    className="table-data-row"
                    hover
                    onClick={(event) => handleClick(event, data.title)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        className="active-checkbox"
                        checked={isItemSelected}
                      />
                    </TableCell>

                    <TableCell className="table-data-text">
                      {data.type}
                    </TableCell>

                    <TableCell className="table-data-text">
                      {data.title}
                    </TableCell>

                    <TableCell className="table-data-text">
                      {data.subtitle}
                    </TableCell>

                    <TableCell className="table-data-text">
                      {data.createdBy}
                    </TableCell>

                    <TableCell className="table-data-text">
                      {data.createdOn}
                    </TableCell>

                    <TableCell className="table-data-text">
                      {data.project}
                    </TableCell>

                    <TableCell className="table-data-text">
                      <SwitchComponent />
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Edit" placement="top">
                        <IconButton
                          aria-label="edit"
                          onClick={editPromotionsAndOffers}
                        >
                          <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete" placement="top">
                        <IconButton
                          aria-label="delete"
                          onClick={deletePromotionsAndOffers}
                        >
                          <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="View" placement="top">
                        <IconButton
                          aria-label="view"
                          onClick={viewPromotionsAndOffers}
                        >
                          <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={promotionsAndOffers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <PageHeading title="Marketing Management" />

      <Container className="main-container">
        {promotionsAndOffers.length > 0 ? renderTable() : renderNoData()}
      </Container>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Promotions and Offers?"
        content="This action will delete the Promotion and Offer. Are you sure you want to continue?"
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
      />
    </Fragment>
  );
};

export default PromotionsAndOffersList;
