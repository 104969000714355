import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  Tooltip,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  IconButton,
  TablePagination,
  MenuItem,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { SwitchComponent, TextFieldComponent } from "components/ui-elements";
import { useFormikContext } from "formik";
import { isValidYoutubeUrl } from "utils/ValidationUtils";
import { useSelector } from "react-redux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";

const MediaGalleryTable = (props: any) => {
  const {
    setSingleMediaUpdateData,
    setOpenMediaUploadDialog,
    isViewMode,
    setIsViewMode,
    isEditMode,
    setIsEditMode,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(1);
  const { mediaUploadFor, setMediaUploadFor }: any = props;
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    resetForm,
    errors,
  } = useFormikContext<any>();

  const [filterType, setFilterType] = useState("");
  const [filterTypeData, setFilterTypeData] = useState<any>();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteKey, setDeleteKey] = useState<any>();
  const isProjectViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const tableHeadings = [
    { heading: "Media" },
    { heading: "Name" },
    // { heading: "Type" },
    { heading: "Status" },
    { heading: "Actions" },
  ];

  useEffect(() => {}, [page, rowsPerPage, count, filterType, filterTypeData]);

  let mediaList = (
    filterType
      ? props?.dataMap?.filter((item: any) => {
          return item?.isPageWidthEnabled === filterTypeData;
        })
      : props?.dataMap
  )?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleStatusChange = (index: any) => {
    if (mediaList[index]?.status === "1001") {
      mediaList[index].status = "1002";
    } else {
      mediaList[index].status = "1001";
    }
    setCount(count + 1);
  };

  const clearFilters = () => {
    setFilterType("");
    setFilterTypeData(undefined);
  };

  const handleDeleteOkClick = () => {
    const filteredData = props?.dataMap?.filter(
      (item: any) => item?.mediaContent?.key !== deleteKey
    );
    setFieldValue(`${props?.name}`, filteredData);
    setOpenConfirmation(false);
  };

  return (
    <div>
      {/* <Stack width={200} spacing={1} mt={1}>
        <Typography color={"#707070"} sx={{ marginBottom: "12px" }}>
          Filters:
        </Typography>
        <TextFieldComponent
          style={{ width: "200px" }}
          type="select"
          placeholder="Enter"
          name={"Type"}
          label={"Type"}
          onBlur={() => {}}
          value={filterType}
          onChange={(e) => {
            setFilterType(e.target.value);
            if (e.target.value === "pageWidth") {
              setFilterTypeData(true);
            } else {
              setFilterTypeData(false);
            }
          }}
          fieldhelpertext={""}
          select
          defaultValue={"select"}
          disabled={isProjectViewMode}
        >
          <MenuItem value={"pageWidth"}> Page Width </MenuItem>
          <MenuItem value={"noPageWidth"}> No Page Width </MenuItem>
        </TextFieldComponent>
        {filterType ? (
          <span onClick={clearFilters} className="clear-filter">
            Clear
          </span>
        ) : (
          ""
        )}
      </Stack> */}
      <ConfirmationDialog
        shouldOpen={openConfirmation}
        content={"Are You Sure? Do You Want To Delete Item From Table?"}
        cancelHandler={() => setOpenConfirmation(false)}
        okText="Delete"
        okHandler={() => handleDeleteOkClick()}
      />
      <TableContainer
        component={Paper}
        className="table-container"
        style={{ maxHeight: "400px" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow className="table-header-row">
              {tableHeadings?.map((item: any, index: any) => (
                <TableCell key={index} className="table-header-text">
                  {item?.heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {mediaList?.map((data: any, index: any) => {
              // const validateVideoUrl = isValidYoutubeUrl(data?.mediaContent?.value?.url);
              // const videoUrl = `https://www.youtube.com/embed/${validateVideoUrl}`;
              // const videoUrl = validateVideoUrl;
              return (
                <TableRow key={index} className="table-data-row">
                  {data?.mediaContent?.value?.mediaType === "video" ? (
                    <TableCell className="table-data-text">
                      <iframe
                        style={{
                          width: "140px",
                          height: "80px",
                          margin: "-15px 0",
                        }}
                        src={data?.mediaContent?.value?.url}
                        title={data?.name}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </TableCell>
                  ) : (
                    <TableCell className="table-data-text">
                      {data?.mediaContent?.value?.url ? (
                        <img
                          style={{
                            width: "140px",
                            height: "80px",
                            margin: "-15px 0",
                          }}
                          src={data?.mediaContent?.value?.url}
                          alt={data?.mediaContent?.value?.url}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  )}

                  <TableCell className="table-data-text">
                    {data?.name ? data?.name : "-"}
                  </TableCell>

                  {/* <TableCell className="table-data-text">
                      {data?.isPageWidthEnabled && data?.isPageWidthEnabled === true ? "Page Width" : "-"}
                    </TableCell> */}

                  <TableCell className="table-data-text">
                    <SwitchComponent
                      name="status"
                      checked={data?.status === "1001" ? true : false}
                      value={data?.status}
                      onChange={() => {
                        handleStatusChange(index);
                      }}
                    />
                  </TableCell>

                  <TableCell>
                    <Stack width={"70px"}>
                      <Tooltip title="Edit" placement="top">
                        <IconButton
                          disabled={isProjectViewMode}
                          aria-label="edit"
                          onClick={() => {
                            setOpenMediaUploadDialog(true);
                            setIsEditMode(true);
                            setIsViewMode(false);
                            setSingleMediaUpdateData(data);
                            props?.setMediaUploadFor(mediaUploadFor);
                          }}
                        >
                          <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="View" placement="top">
                        <IconButton
                          aria-label="view"
                          onClick={() => {
                            setOpenMediaUploadDialog(true);
                            setIsViewMode(true);
                            setIsEditMode(false);
                            setSingleMediaUpdateData(data);
                            props?.setMediaUploadFor(mediaUploadFor);
                          }}
                        >
                          <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                        </IconButton>
                      </Tooltip>
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpenConfirmation(true);
                          setDeleteKey(data?.mediaContent?.key);
                        }}
                      >
                        Remove
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={props?.dataMap?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default MediaGalleryTable;
