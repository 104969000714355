import { Avatar, Tooltip, TooltipProps } from "@mui/material";
import MediaAssets from "assets";

interface IinfoProps {
  placement?: TooltipProps["placement"];
  infoContent?: string | NonNullable<React.ReactNode>;
}

const InfoComponent = (props: IinfoProps) => {
  const {
    infoContent = "Toggle is to show/hide Notification on the Customer App.",
    placement = "top-start",
  } = props;
  return (
    <Tooltip arrow placement={placement} title={infoContent}>
      <Avatar
        sx={{
          width: "1rem",
          height: "1rem",
          cursor: "pointer",
          marginTop: "0.10rem",
          marginBottom: "0.10rem",
        }}
        src={MediaAssets.ic_info}
        variant="rounded"
      />
    </Tooltip>
  );
};

export default InfoComponent;
