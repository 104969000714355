import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import leadActions from "redux-container/lead-management/LeadRedux";

export function* getLeaddata(api: any, action: any): SagaIterator {
  console.log("freghhgh");

  try {
    const response = yield call(api.fetchLeads, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(leadActions.getLeadDataSuccess(response.data));
    } else {
      yield put(leadActions.getLeadDataFailure(response.data));
    }
  } catch (error) {
    yield put(leadActions.getLeadDataFailure(error));
  }
}
