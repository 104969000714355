import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import customerManagementActions from "redux-container/customer-management/CustomerManagementRedux"
import blockedCustomerListActions from "redux-container/customer-management/BlockedCustomerListRedux";

export function* customerFeedbackListData(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.getCustomerFeedbackList, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        customerManagementActions.customerFeedbackListSuccess(
          response.data
        )
      );
    } else {
      yield put(
        customerManagementActions.customerFeedbackListFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      customerManagementActions.customerFeedbackListFailure(error)
    );
  }
}

export function* getCustomerBookingJourney(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.getCustomerBookingJourney, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        customerManagementActions.getCustomerBookingJourneySuccess(
          response.data
        )
      );
    } else {
      yield put(
        customerManagementActions.getCustomerBookingJourneyFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      customerManagementActions.getCustomerBookingJourneyFailure(error)
    );
  }
}

//get Blocked Customer List

export function* blockedCustomerListSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getBlockedCustomerList, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(blockedCustomerListActions.blockedCustomerListSuccess(response.data));
    } else {
      yield put(blockedCustomerListActions.blockedCustomerListFailure(response.data));
    }
  } catch (error) {
    yield put(blockedCustomerListActions.blockedCustomerListSuccess(error));
  }
};

//PUT API: update Blocked customer list

export function* unblockedCustomersSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.unblockedCustomers, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(blockedCustomerListActions.unblockedCustomerSuccess(response.data));
    } else {
      yield put(blockedCustomerListActions.unblockedCustomerFailure(response.data));
    }
  } catch (error) {
    yield put(blockedCustomerListActions.unblockedCustomerSuccess(error));
  }
};
