import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import ProjectContentCreationAction from "redux-container/project-management/project-content/ProjectContentRedux";
import UpcommingProjectActions from "redux-container/project-management/upcomming-project/UpcommingProjectRedux";
export function* addUpCommingProjectContentCreationDataSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(
      api.addupCommingProjectContentCreationData,
      action.data
    );
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        UpcommingProjectActions.upCommingProjectContentCreationDataSuccess(
          response.data
        )
      );
    } else {
      yield put(
        UpcommingProjectActions.upCommingProjectContentCreationDataFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      UpcommingProjectActions.upCommingProjectContentCreationDataFailure(error)
    );
  }
}

export function* getUpcommingProjectSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getUpCommigProjects, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        UpcommingProjectActions.getUpCommingProjectContentCreationDataSuccess(
          response.data
        )
      );
    } else {
      yield put(
        UpcommingProjectActions.getUpCommingProjectContentCreationDataFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      UpcommingProjectActions.getUpCommingProjectContentCreationDataFailure(
        error
      )
    );
  }
}
export function* getUpcommingSingleProjectSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.getUpCommigSingleProject, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        UpcommingProjectActions.getSingleUpCommingProjectContentCreationDataSuccess(
          response.data
        )
      );
    } else {
      yield put(
        UpcommingProjectActions.getSingleUpCommingProjectContentCreationDataFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      UpcommingProjectActions.getSingleUpCommingProjectContentCreationDataFailure(
        error
      )
    );
  }
}

export function* updateStatusUpcommingSingleProjectSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.updateStatusUpcommingProject, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        UpcommingProjectActions.updateStatusUpCommingProjectContentCreationDataSuccess(
          response.data
        )
      );
    } else {
      yield put(
        UpcommingProjectActions.updateStatusUpCommingProjectContentCreationDataFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      UpcommingProjectActions.updateStatusUpCommingProjectContentCreationDataFailure(
        error
      )
    );
  }
}

export function* updateUpcommingSingleProjectSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(
      api.updateUpcommingProject,
      action.id,
      action.data
    );
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        UpcommingProjectActions.updateStatusUpCommingProjectContentCreationDataSuccess(
          response.data
        )
      );
    } else {
      yield put(
        UpcommingProjectActions.updateStatusUpCommingProjectContentCreationDataFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      UpcommingProjectActions.updateStatusUpCommingProjectContentCreationDataFailure(
        error
      )
    );
  }
}

export function* filterUpCommingProjects(api: any, action: any): SagaIterator {
  try {
    console.log("action data", action.data);

    const response = yield call(api.filterUpCommingProject, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        UpcommingProjectActions.filterUpCommingProjectContentCreationDataSuccess(
          response.data
        )
      );
    } else {
      yield put(
        UpcommingProjectActions.filterUpCommingProjectContentCreationDataFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      UpcommingProjectActions.filterUpCommingProjectContentCreationDataFailure(
        error
      )
    );
  }
}

// filterUpCommingProjects
