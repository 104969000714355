export const amountFormatter = (value: any) => {
  let amountValue = Number(value);
  let val: any = Math.abs(amountValue);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + " Cr";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + " Lakhs";
  }
  return val;
};

export const priceFormatter = (value: any) => {
  let amountValue = Number(value);
  let val: any = Math.abs(amountValue);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(0) + " Cr";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(0) + " L";
  } else if (val >= 1000) {
    val = (val / 1000).toFixed(0) + " K";
  }

  return val;
};
