import { Fragment } from "react";
import Container from "@mui/material/Container";
import PageHeading from "../../components/page-heading/PageHeading";
import { RouteConstants } from "../../routes/RouteConstants";
import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";

const PortalDashboard = () => {
  const breadcrumbRoutes: any = [
    {
      name: "Portal Dashboard",
      route: RouteConstants.portalDashboard,
    },
  ];

  return (
    <Fragment>
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <PageHeading title="Portal Dashboard" />
      <Container className="main-container">Portal dashboard</Container>
    </Fragment>
  );
};

export default PortalDashboard;
