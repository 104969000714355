import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import InsightsActions from "../../redux-container/insights/InsightsRedux";

export function* getInsights(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getInsights, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InsightsActions.getInsightsSuccess(response));
    } else {
      yield put(InsightsActions.getInsightsFailure(response));
    }
  } catch (error) {
    yield put(InsightsActions.getInsightsFailure(error));
  }
}

export function* updateInsightStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateInsightsStatus, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InsightsActions.updateInsightStatusSuccess(response));
    } else {
      yield put(InsightsActions.updateInsightStatusFailure(response));
    }
  } catch (error) {
    yield put(InsightsActions.updateInsightStatusFailure(error));
  }
}

export function* updateInsightPriority(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateInsightPriority, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InsightsActions.updateInsightPrioritySuccess(response));
    } else {
      yield put(InsightsActions.updateInsightPriorityFailure(response));
    }
  } catch (error) {
    yield put(InsightsActions.updateInsightPriorityFailure(error));
  }
}

export function* deleteSelectedInsights(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteSelectedInsights, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InsightsActions.deleteInsightsSuccess(response));
    } else {
      yield put(InsightsActions.deleteInsightsFailure(response));
    }
  } catch (error) {
    yield put(InsightsActions.deleteInsightsFailure(error));
  }
}
