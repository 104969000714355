import { RouteConstants } from "routes/RouteConstants";
import { DescriptionTypeEnum, TypeOfBlog } from "../BlogCreationConstants";

export const breadcrumbRoutes: any = (
  displayTitle: string,
  locationStateId: any,
  isEditMode: boolean,
  isDisabled: boolean
) => [
  {
    name: "Blog Management",
    route: RouteConstants.blogManagementDashboard,
  },
  {
    name: "Blogs",
    route: RouteConstants.blogs,
  },
  locationStateId && isEditMode && !isDisabled
    ? {
        name: `Edit - ${displayTitle}`,
        route: RouteConstants.blogCreation,
      }
    : isDisabled
    ? {
        name: `View - ${displayTitle}`,
        route: RouteConstants.blogCreation,
      }
    : {
        name: `Create New`,
        route: RouteConstants.blogCreation,
      },
];

export const descriptionSelection = [
  {
    id: 1,
    label: "Brief Description",
    value: DescriptionTypeEnum.BriefDescription,
  },
  {
    id: 2,
    label: "Detailed Description",
    value: DescriptionTypeEnum.DetailedDescription,
  },
];

export const getBlogTypeLabels = (value: string) => {
  switch (value) {
    case TypeOfBlog.hoablType:
      return "HoABL";
    case TypeOfBlog.projectType:
      return "Project";
    case TypeOfBlog.factualType:
      return "Factual";
    default:
      break;
  }
};
