import { createActions, createReducer } from "reduxsauce";

export const INITIAL_STATE = {
  isLoading: false,
  audits: [],
  message: "",
  isSuccess: false,
  shouldOpenFlashDialog: false,
  ipAdress: "",
  totalCount: 0,
  page: 0,
  rowsPerPage: 10,
};

const { Types, Creators } = createActions({
  postAuditLogRequest: ["data"],
  postAuditLogSuccess: ["response"],
  postAuditLogFailure: ["error"],

  getAuditLogRequest: ["data"],
  getAuditLogSuccess: ["response"],
  getAuditLogFailure: ["error"],
  //get

  setAuditLogState: ["key", "value"],
});
export const AuditLogTypes = Types;
export const AuditActions = Creators;

export const postAuditLogRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const postAuditLogSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    shouldOpenFlashDialog: true,
    message: action?.response?.data?.message,
  };
};

export const postAuditLogFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    message: action?.error?.data?.message,
  };
};
export const getAuditLogRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getAuditLogSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    shouldOpenFlashDialog: true,
    message: action?.response?.data?.message,
    audits: action?.response?.data?.data,
    totalCount: action?.response?.data?.totalCount,
  };
};

export const getAuditLogFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    message: action?.error?.data?.message,
    audits: [],
    totalCount: 0,
  };
};

export const setAuditLogState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_AUDIT_LOG_REQUEST]: postAuditLogRequest,
  [Types.POST_AUDIT_LOG_SUCCESS]: postAuditLogSuccess,
  [Types.POST_AUDIT_LOG_FAILURE]: postAuditLogFailure,

  [Types.GET_AUDIT_LOG_REQUEST]: getAuditLogRequest,
  [Types.GET_AUDIT_LOG_SUCCESS]: getAuditLogSuccess,
  [Types.GET_AUDIT_LOG_FAILURE]: getAuditLogFailure,

  //POST_AUDIT_LOG_
  //GET
  [Types.SET_AUDIT_LOG_STATE]: setAuditLogState,
});
