import { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MediaAssets from "../../../../assets";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NoDataRecord from "../../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../../routes/RouteConstants";
import { SelectChangeEvent } from "@mui/material/Select";
import SwitchComponent from "components/ui-elements/switch/SwitchComponent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import {
  Avatar,
  Card,
  CardActions,
  CardMedia,
  Checkbox,
  ClickAwayListener,
  Popper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import styles from "./InsightList.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useDispatch, useSelector } from "react-redux";
import InsightsActions from "../../../../redux-container/insights/InsightsRedux";
import { exportAsExcel } from "utils/ExportFileUtils";
import Retry from "components/retry/Retry";
import Loader from "components/loader/Loader";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import {
  AutocompleteComponent,
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Box } from "@mui/system";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import {
  ActionTypeEnumInsight,
  ActionTypeEnumUtilsInsight,
} from "enumerations/ActionTypeEnum";
import {
  StatusTypeEnumInsight,
  StatusTypeEnumUtilsInsight,
} from "enumerations/StatusTypeEnum";
import { getYoutubeVideoId } from "utils/ValidationUtils";
import UserActions from "redux-container/users/UsersRedux";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import axios from "axios";
import { AuditStatus } from "enumerations/AuditStatusTypes";

const CustomTableContainer = styled(Box)`
  &&.MuiTableContainer-root {
    overflow-x: none;
  }
  && .MuiOutlinedInput-root {
    margin-right: 55px;
  }
`;
export const BreadCrumbsWrapper = styled(Box)`
  && .MuiOutlinedInput-root {
    margin-right: 50px;
  }
  .MuiButton-root {
    /* margin-right: 6px; */
  }
`;
export const ReadmoreDialogWrapper = styled(Box)`
  && .MuiPaper-root {
    overflow-wrap: break-word;
    width: 200px;
    margin-right: 20px;
  }
`;

//table headings (heading objects with name,boolean key for whether it is sorting column or not,and sorting key for an API call )
const tableHeadings = [
  { heading: "Title" },
  { heading: "Media" },
  { heading: "Created On" },
  { heading: "Published On" },
  { heading: "Modified On" },
  { heading: "Last Modified By" },
  { heading: "Priority" },
  { heading: "Status" },
  { heading: "Actions" },
];

const actionDispatch = (dispatch: any) => {
  return {
    getInsights: async (params: any) => {
      dispatch(InsightsActions.getInsightsRequest(params));
    },
    deleteSelectedInsights: (newtoBeDeletingIds: any) => {
      dispatch(InsightsActions.deleteInsightsRequest(newtoBeDeletingIds));
    },
    updateStatusOfSelectedInsights: (toBeUpdatingIdies: any) => {
      dispatch(InsightsActions.updateInsightStatusRequest(toBeUpdatingIdies));
    },

    setInsightsState: (key: any, data: any) => {
      dispatch(InsightsActions.setInsightsState(key, data));
    },
    updateInsightPriority: (data: any) => {
      dispatch(InsightsActions.updateInsightPriorityRequest(data));
    },
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const InsightList = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useDispatch();
  const actions = ActionTypeEnumUtilsInsight.getActionTypeEnumsInsight();
  const statuses = StatusTypeEnumUtilsInsight.getStatusTypeEnumsInsight();
  const insights: any = useSelector(
    (state: any) => state?.insights?.insights?.data
  );
  const totalCount = useSelector((state: any) => state?.insights?.totalCount);
  const isUpdated: any = useSelector(
    (state: any) => state?.insights?.isUpdated
  );
  const isDeleted: any = useSelector(
    (state: any) => state?.insights?.isDeleted
  );
  const isLoading: any = useSelector(
    (state: any) => state?.insights?.isLoading
  );
  const isErrorOccured: any = useSelector(
    (state: any) => state?.insights?.error
  );
  const { page, rowsPerPage, modules } = useSelector((state: any) => {
    return {
      page: state?.insights?.page,
      rowsPerPage: state?.insights?.rowsPerPage,
      modules: state?.rolesAndModules?.module,
    };
  });
  const message: any = useSelector((state: any) => state?.insights?.message);
  const allUsers: any = useSelector(
    (state: any) => state?.users?.usersForFilters
  );
  const isSuccess: any = useSelector(
    (state: any) => state?.insights?.isSuccess
  );
  const shouldOpenFlashDialog: any = useSelector(
    (state: any) => state?.insights?.shouldOpenFlashDialog
  );
  const [selectedInsights, setSelectedInsights] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  console.log("selectedStatus", selectedStatus);

  const [selectedCreatedUser, setSelectedCreatedUser] = useState<any>(null);
  const [selectedModifiedUser, setSelectedModifiedUser] = useState<any>(null);
  const [selectedPublishedUser, setSelectedPublishedUser] = useState<any>(null);
  const [selectedCreatedOrPublishedUser, setSelectedCreatedOrPublishedUser] =
    useState<any>(null);

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [sortByPriority, setSortByPriority] = useState(true);
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const [insightTitle, setInsightTitle] = useState();
  const [sortByCreatedAtOrModifiedAt, setSortByCreatedAtOrModifiedAt] =
    useState(1);
  const [sortAscendingOrdescending, setsortAscendingOrdescending] = useState(0);
  const [isAscending, setIsAscending] = useState(true);

  const [ipAddress, setIpAddress] = useState<any>(null);
  const [modulesAudited, setModulesAudited] = useState<any>(null);

  const {
    getInsights,
    deleteSelectedInsights,
    updateStatusOfSelectedInsights,
    updateInsightPriority,
    setInsightsState,
    getUsers,
    rolesAndModulesRequest,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());

  //Loading Data (Insights data) while mounting and
  // every time after User applies any filters (Status,Created by,Modified by,Search key,Sorting key,page and number of rows per page) .
  useEffect(() => {
    getFilterDataFromDashboard();
    const params = {
      // status: selectedStatus,
      status: location?.state?.statusType
        ? location?.state?.statusType
        : selectedStatus,
      createdBy: selectedCreatedOrPublishedUser?.id,
      modifiedBy: selectedModifiedUser?.id,
      size: rowsPerPage,
      index: page + 1,
      searchKey: searchKey,
      byPrority: sortByPriority,
      sortingKey: sortByCreatedAtOrModifiedAt,
      sortingPriority: sortAscendingOrdescending,
    };
    setSelectedInsights([]);
    getInsights(params);
  }, [
    dispatch,
    isUpdated,
    isDeleted,
    rowsPerPage,
    page,
    selectedModifiedUser,
    selectedStatus,
    selectedCreatedOrPublishedUser,
    selectedCreatedUser,
    sortByPriority,
    sortByCreatedAtOrModifiedAt,
    searchKey,
    sortAscendingOrdescending,
  ]);

  //setting page and rowsPerPage to initial value if user Navigated to the list from Side menu.
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setInsightsState("page", 0);
      setInsightsState("rowsPerPage", 5);
    }
    return () => {};
  }, []);

  //API call to get Current IP address and role,modules.
  useEffect(() => {
    getIpAddress();
    rolesAndModulesRequest();

    return () => {};
  }, []);

  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIpAddress(res.data.IPv4);
  };

  const statusAuditedInsights = () => {
    let auditedInsights: any[] = [];
    insights?.map((insight: any) => {
      selectedInsights?.map((insightId: number) => {
        if (insightId === insight.id) {
          auditedInsights.push(insight);
        }
      });
    });
    return auditedInsights;
  };

  const breadcrumbRoutes: any = [
    {
      name: "Resource Management",
      route: RouteConstants.resourceManagementDashboard,
    },
    {
      name: "Insight List",
      route: RouteConstants.insightList,
    },
  ];

  const getFilterDataFromDashboard = () => {
    if (location && location.state && location.state.statusType) {
      setSelectedStatus(location.state.statusType as string);
    }
    navigate(location.pathname, { replace: true });
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, insight: number) => {
    const selectedIndex = selectedInsights.indexOf(insight);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedInsights, insight);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedInsights.slice(1));
    } else if (selectedIndex === selectedInsights.length - 1) {
      newSelected = newSelected.concat(selectedInsights.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedInsights.slice(0, selectedIndex),
        selectedInsights.slice(selectedIndex + 1)
      );
    }
    setSelectedInsights(newSelected);
  };

  const isSelected = (displayTitle: number) => {
    return selectedInsights.indexOf(displayTitle) !== -1;
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = insights.map((n: any) => n.id);
      setSelectedInsights(newSelecteds);
      return;
    }
    setSelectedInsights([]);
  };

  //handle change function for page in Pagination.
  const handleChangePage = (event: unknown, newPage: number) => {
    setInsightsState("page", newPage);
  };

  //handle change function for number of elements per page in Pagination.
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInsightsState("rowsPerPage", Number(event.target.value));
    setInsightsState("page", 0);
  };

  const handleChangePrioritySwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSortByPriority(event.target.checked);
  };

  //function to export selected insights.
  const handleExportAsExcel = () => {
    if (selectedInsights.length === 0) {
      alert("You have not selected any Insight");
      return;
    }
    let exportingInsights: any = [];
    if (insights.length) {
      selectedInsights.map((selectedInsight: any) => {
        let localSelectedItem = insights.find(
          (item: any) => item.id == selectedInsight
        );
        exportingInsights.push({
          Title: localSelectedItem.displayTitle,
          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Published_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Modified_On: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Last_Modified_by: localSelectedItem?.insightsModifiedAdmin?.firstName
            ? localSelectedItem?.insightsModifiedAdmin?.firstName
            : "----",
          Priority: localSelectedItem?.priority,
          Status: localSelectedItem.status
            ? StatusTypeEnumUtilsInsight.getInsightsStatusEnumText(
                localSelectedItem.status
              )
            : "----",
        });
      });
    }

    exportAsExcel(exportingInsights, "Insights", "sheet1");
  };

  //function to handle Action Selection (Export,Delete,publish,inActive)
  const handleActionChange = (event: SelectChangeEvent) => {
    const selectedActionOption = event.target.value;
    switch (Number(selectedActionOption)) {
      case ActionTypeEnumInsight.Export:
        handleExportAsExcel();
        setSelectedInsights([]);

        break;
      case ActionTypeEnumInsight.Delete:
        if (selectedInsights.length === 0) {
          alert("You have not selected any insights");

          return;
        }

        setIsConfirmDeleteDialogOpen(true);

        break;
      case ActionTypeEnumInsight.MarkAsPublished:
        if (selectedInsights.length === 0) {
          alert("You have not selected any insights");
          return;
        }
        updateInsights(String(StatusTypeEnumInsight.Published));
        break;
      case ActionTypeEnumInsight.MarkAsInactive:
        if (selectedInsights.length === 0) {
          alert("You have not selected any insights");

          return;
        }

        updateInsights(String(StatusTypeEnumInsight.Inactive));

        break;
    }
  };

  //function to make an API call to delete selected Insights.
  const deleteInsights = () => {
    const insightsToBeDeleted = {
      insightIds: selectedInsights,
    };

    let auditedInsights = statusAuditedInsights();
    let auditObjectsArray: any[] = [];
    auditedInsights?.map((insight: any) => {
      let auditObject = {
        itemAffected: `Insight ${insight?.displayTitle} Deleted.(ID${insight?.id})`,
        newValue: "",
        oldValue: "",
        auditedObject: null,
        route: null,
        status: AuditStatus.DELETED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    deleteSelectedInsights(insightsToBeDeleted);
  };

  //function to make an API call to Update the Selected insights.
  const updateInsights = async (status: string) => {
    const insightsToBeUpdated = {
      insightIds: selectedInsights,
      status: status,
    };

    let auditedInsights = statusAuditedInsights();
    let auditObjectsArray: any[] = [];
    auditedInsights?.map((insight: any) => {
      let auditObject = {
        itemAffected: `Status`,
        newValue:
          StatusTypeEnumInsight.Published === status ? "Published" : "Inactive",
        oldValue:
          StatusTypeEnumInsight.Published === status ? "Inactive" : "Published",
        auditedObject: { ...insight, status: status },
        route: RouteConstants.insightCreation,
        status: AuditStatus.EDITED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    updateStatusOfSelectedInsights(insightsToBeUpdated);
  };

  const handleClearButton = (): boolean => {
    if (
      selectedStatus === null &&
      selectedModifiedUser === null &&
      selectedPublishedUser === null &&
      selectedCreatedUser === null
    ) {
      return false;
    }
    return true;
  };

  //function to Reset all filters.
  const handleResetFilter = () => {
    setSelectedStatus(null);
    setSelectedCreatedUser(null);
    setSelectedModifiedUser(null);
    setSelectedPublishedUser(null);
    setSelectedCreatedOrPublishedUser(null);
  };
  const clearSelected = () => {
    setSelectedInsights([]);
  };

  //Navigate to Create Insight.
  const createInsight = () => {
    navigate(RouteConstants.insightCreation);
  };

  //Navigate to Edit Insight.
  const editInsight = (insightObj: any) => {
    navigate(RouteConstants.insightCreation, {
      state: { ...insightObj, isView: false },
    });
  };

  const okHandler = async () => {
    deleteInsights();
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  //function to make Audit log API call after the success of Post API call.
  const handleCloseFlashMessageDialog = () => {
    setInsightsState("shouldOpenFlashDialog", false);

    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Resources Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: null,
      auditedObject: null,
      modulesAudited: modulesAudited,
      status: null,
    };
    postAuditLogRequest(postAuditRequestbody);
  };

  //function to show insight Title  when user clicks on READ MORE.
  const popOverComponent = (event: any, insightTitle: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
    setInsightTitle(insightTitle);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  //handle change function for page in Pagination.
  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 0) {
      setInsightsState("page", 0);
    }
  };
  const viewInsight = (insightObj: any) => {
    navigate(`${RouteConstants.insightCreation}`, {
      state: {
        ...insightObj,
        displayTitle: insightObj?.displayTitle,
        status: insightObj?.status,
        insightsMedia: insightObj?.insightsMedia,
        isView: true,
      },
    });
  };

  //function to get the YouTube Video Thumbnail using URL.
  const getYoutubeVideoThumbnail = (url: any) => {
    return `https://img.youtube.com/vi/${getYoutubeVideoId(url)}/mqdefault.jpg`;
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_insight}
        altText=""
        message="You haven't created any Insights yet. Create your first Insight."
        btnTitle="CREATE INSIGHT"
        btnAction={createInsight}
      />
    );
  };
  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => getInsights} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Published Insights{" "}
            {`(${
              totalCount ? totalCount : insights?.length ? insights?.length : 0
            })`}
            {selectedInsights?.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedInsights?.length} Insight(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
              >
                {actions.map((action) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>

            <Button
              variant="contained"
              onClick={createInsight}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New
            </Button>
          </div>
        </div>

        <Grid container spacing={1}>
          <Grid container spacing={2} md={8} lg={8} xl={8}>
            <Grid
              item
              md={1}
              lg={1}
              xl={1}
              className="margin-left-10 filter-heading-holder"
            >
              <span className="filter-heading">Filters:</span>
            </Grid>

            <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedStatus}
                  label="Status"
                  onChange={(event) => {
                    setSelectedStatus(event.target.value);
                    if (page !== 0) {
                      setInsightsState("page", 0);
                    }
                  }}
                >
                  {statuses.map((status) => {
                    return (
                      <MenuItem value={status.value} key={status.value}>
                        {status.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <AutocompleteComponent
                  options={allUsers}
                  getOptionLabel={(option: any) =>
                    option?.isDuplicated
                      ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                      : `${option?.firstName} ${option?.lastName}`
                  }
                  label="Created By"
                  onChange={(
                    event: React.SyntheticEvent,
                    newValue: any | null
                  ) => {
                    console.log("newValue", newValue);

                    setSelectedCreatedUser(newValue);
                    setSelectedCreatedOrPublishedUser(newValue);
                    if (page !== 0) {
                      setInsightsState("page", 0);
                    }
                  }}
                  value={selectedCreatedUser}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                  key={(values: any) => values.id}
                />
              </FormControl>
            </Grid>

            <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <AutocompleteComponent
                  options={allUsers}
                  getOptionLabel={(option: any) =>
                    option?.isDuplicated
                      ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                      : `${option?.firstName} ${option?.lastName}`
                  }
                  label="Modified By"
                  onChange={(
                    event: React.SyntheticEvent,
                    newValue: any | null
                  ) => {
                    setSelectedModifiedUser(newValue);
                    if (page !== 0) {
                      setInsightsState("page", 0);
                    }
                  }}
                  value={selectedModifiedUser}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                />
              </FormControl>
            </Grid>
            {/* <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <AutocompleteComponent
                  options={allUsers}
                  getOptionLabel={(option: any) => option?.firstName}
                  label="Published By"
                  onChange={(
                    event: React.SyntheticEvent,
                    newValue: any | null
                  ) => {
                    setSelectedPublishedUser(newValue);
                    setSelectedCreatedOrPublishedUser(newValue);
                  }}
                  value={selectedPublishedUser}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                />
              </FormControl>
            </Grid> */}

            {handleClearButton() ? (
              <Grid
                item
                md={0.5}
                lg={0.5}
                xl={0.5}
                className="filter-heading-holder"
                style={{ position: "relative" }}
              >
                <span
                  onClick={handleResetFilter}
                  className="clear-filter"
                  style={{ position: "absolute", top: 32 }}
                >
                  Clear
                </span>
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={2} md={4} lg={4} xl={4} justifyContent="end">
            <Stack justifyContent={"flex-end"} spacing={1}>
              <Typography> Sort by Priority</Typography>
              <SwitchComponent
                checked={sortByPriority}
                onChange={handleChangePrioritySwitch}
              />
              <IconButton>
                <Tooltip
                  arrow
                  title={
                    "By Default Insights are Ordered Based On Their Priority, turn  Off the switch to get the Insights Based On Latest Updated."
                  }
                  placement="top-start"
                >
                  <Avatar
                    className={styles["Avator"]}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <CustomTableContainer>
          <TableContainer
            component={Paper}
            className={`table-container`}
            style={{ height: window.innerHeight - 475 }}
          >
            <Table style={{ position: "relative" }}>
              <TableHead>
                <TableRow className="table-header-row">
                  <TableCell padding="checkbox">
                    <Checkbox
                      className="active-checkbox"
                      indeterminate={
                        selectedInsights?.length > 0 &&
                        selectedInsights?.length < insights?.length
                      }
                      checked={
                        insights?.length > 0 &&
                        selectedInsights?.length === insights?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all insights",
                      }}
                    />
                  </TableCell>
                  {tableHeadings?.map((item, index) => (
                    <TableCell key={index} className="table-header-text">
                      {item.heading}
                      {index === 6 ? (
                        <IconButton>
                          <Tooltip
                            arrow
                            title="The items will be shown in ascending order of the priority number on the Customer App."
                            placement="top-start"
                          >
                            <Avatar
                              className={styles["Avator"]}
                              src={MediaAssets.ic_info}
                              variant="rounded"
                            />
                          </Tooltip>
                        </IconButton>
                      ) : null}
                      {/* {index === 2 || index === 3 || index === 4 ? (
                        <>
                          {isAscending ? (
                            <ArrowDropDownIcon
                              className={styles["Arrow-icon-center"]}
                              onClick={() => {
                                setIsAscending(false);
                              }}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              className={styles["Arrow-icon-center"]}
                              onClick={() => {
                                setIsAscending(true);
                              }}
                            />
                          )}
                        </>
                      ) : null} */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {insights?.length > 0 ? (
                <TableBody>
                  {insights?.map((insight: any, insightsIndex: any) => {
                    const isItemSelected = isSelected(insight.id);
                    return (
                      <TableRow
                        key={insight.id}
                        className="table-data-row"
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox" style={{ minWidth: 50 }}>
                          <Checkbox
                            onClick={(event) => handleClick(event, insight.id)}
                            className="active-checkbox"
                            checked={isItemSelected}
                          />
                        </TableCell>
                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 200 }}
                        >
                          {insight?.displayTitle.length > 0 ? (
                            <>
                              {insight?.displayTitle.length > 18 ? (
                                <Stack justifyContent={"flex-start"}>
                                  <span>
                                    {" "}
                                    {`${insight?.displayTitle.slice(0, 18)}...`}
                                  </span>
                                  <Typography
                                    component="span"
                                    variant="body1"
                                    color="#2d2d2d"
                                    sx={{
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      wordBreak: "keep-all",
                                      width: "150px",
                                      textAlign: "start",
                                    }}
                                    onClick={(event: any) =>
                                      popOverComponent(
                                        event,
                                        insight?.displayTitle
                                      )
                                    }
                                  >
                                    READ MORE
                                  </Typography>
                                </Stack>
                              ) : (
                                insight?.displayTitle
                              )}
                            </>
                          ) : (
                            "-"
                          )}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ width: "150px" }}
                        >
                          <Card
                            elevation={0}
                            className={styles["media-container"]}
                          >
                            {insight?.insightsMedia[0]?.media?.value
                              ?.mediaType == "IMAGE" ? (
                              <CardMedia
                                className={styles["media-area"]}
                                component={"img"}
                                alt="Image"
                                src={
                                  insight?.insightsMedia[0]?.media?.value?.url
                                }
                              />
                            ) : (
                              <>
                                <CardMedia
                                  className={styles["media-area"]}
                                  component={"img"}
                                  alt="Image"
                                  src={getYoutubeVideoThumbnail(
                                    insight?.insightsMedia[0]?.media?.value?.url
                                  )}
                                />
                                <CardActions className={styles["trash-icon"]}>
                                  <IconButton>
                                    <PlayCircleOutlineIcon
                                      sx={{
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "1rem",
                                          md: "1rem",
                                          lg: "1rem",
                                          xl: "2rem",
                                        },
                                        color: "common.white",
                                      }}
                                    />
                                  </IconButton>
                                </CardActions>
                              </>
                            )}
                          </Card>
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 170 }}
                        >
                          {insight?.createdAt
                            ? formatDateTime(
                                insight?.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 170 }}
                        >
                          {insight?.createdAt
                            ? formatDateTime(
                                insight?.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 170 }}
                        >
                          {insight?.updatedAt
                            ? formatDateTime(
                                insight?.updatedAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </TableCell>
                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 170 }}
                        >
                          {insight?.insightsModifiedAdmin?.firstName
                            ? insight?.insightsModifiedAdmin?.firstName
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          <PrioritySelector
                            incrementpriority={() => {
                              const priorityRequestBody = {
                                id: insight?.id,
                                priority: insight?.priority + 1,
                              };
                              updateInsightPriority(priorityRequestBody);
                            }}
                            decrementpriority={() => {
                              const priorityRequestBody = {
                                id: insight?.id,
                                priority: insight?.priority - 1,
                              };
                              updateInsightPriority(priorityRequestBody);
                            }}
                            priority={insight?.priority}
                            disableDecrement={
                              insight?.priority == 1 ? true : false
                            }
                            isPriorityRequired={
                              !sortByPriority ||
                              insight?.status == StatusTypeEnumInsight.Inactive
                            }
                          />
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {insight?.status
                            ? StatusTypeEnumUtilsInsight.getInsightsStatusEnumText(
                                insight?.status
                              )
                            : "-"}
                        </TableCell>

                        <TableCell style={{ minWidth: 120 }}>
                          <Tooltip title="Edit" placement="top">
                            <IconButton
                              aria-label="edit"
                              onClick={() => editInsight(insight)}
                            >
                              <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="View" placement="top">
                            <IconButton
                              aria-label="view"
                              onClick={() => viewInsight(insight)}
                            >
                              <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <div className="no-record">No Insights Found</div>
              )}
            </Table>
          </TableContainer>
        </CustomTableContainer>

        {!searchKey && insights?.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={totalCount ? totalCount : insights?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };
  const shouldProvidePlaceHolder = () => {
    if (
      (insights?.length === 0 || insights?.length === undefined) &&
      !handleClearButton() &&
      searchKey === "" &&
      isLoading === false
    ) {
      return false;
    } else {
      return true;
    }
  };
  const render = () => {
    if (
      (insights?.length > 0 || handleClearButton() || searchKey !== "") &&
      isLoading === false
    ) {
      return renderTable();
    } else if (
      (insights?.length === 0 || insights?.length === undefined) &&
      !handleClearButton() &&
      searchKey === "" &&
      isLoading === false
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    } else if (isErrorOccured) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <BreadCrumbsWrapper>
        <PageHeading title="Resource Management" />
        <BreadCrumbs
          routes={breadcrumbRoutes}
          placeholder={shouldProvidePlaceHolder() ? "Search Insights" : ""}
          handleSearchOnKeyPress={handleSearchChange}
        ></BreadCrumbs>

        <Container className="main-container">{render()}</Container>

        <ConfirmationDialog
          shouldOpen={isConfirmDeleteDialogOpen}
          title="Delete Insight?"
          content={`This action will delete the  ${selectedInsights.length} Insights. Are you sure you want to continue?`}
          okText="Delete"
          cancelHandler={handleCloseConfirmDialog}
          okHandler={okHandler}
        />
        <FlashMessageDialog
          shouldOpen={shouldOpenFlashDialog}
          content={message}
          isSuccess={isSuccess}
          cancelHandler={handleCloseFlashMessageDialog}
        />

        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleClickAway}
        >
          <Popper open={open} placement="bottom" anchorEl={anchor}>
            <ReadmoreDialogWrapper>
              <Box>
                <Paper elevation={3} sx={{ padding: "0.5rem", width: "350px" }}>
                  {insightTitle}
                </Paper>
              </Box>
            </ReadmoreDialogWrapper>
          </Popper>
        </ClickAwayListener>
      </BreadCrumbsWrapper>
    </Fragment>
  );
};

export default InsightList;
