import { Fragment, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import PageHeading from "components/page-heading/PageHeading";
import BreadCrumbSection from "components/custom-breadcrumbs/BreadCrumbSection";

const PaymentFormManagement = () => {
  const location: any = useLocation();
  const createId = useSelector((state: any) => state?.paymentForm?.formId);
  const [searchKey, setSearchKey] = useState<string>("");
  const [shouldClearSearchKey, setShouldClearSearchKey] = useState(false);
  const breadcrumbRoutes = {
    [RouteConstants.paymentFormManagement]: `Payment Form Management`,
    [RouteConstants.formLists]: `Projects`,
    [`${RouteConstants.formLists}/${createId}`]: `${
      location?.state?.isNew
        ? "Create Form"
        : location?.state?.isViewMode
        ? "View"
        : "Edit"
    } ${location?.state?.isNew ? "" : `- Form ID:#ID-${createId}`}  `,
  };

  const handleSearchChange = (searchKey: string) => {
    setShouldClearSearchKey(false);
    setSearchKey(searchKey);
    // if (page !== 0) {
    //   setProjectState("page", 0);
    // }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (location?.state?.isNavigationFromSidemenu) {
      navigate(RouteConstants.formLists);
    }
  }, []);

  return (
    <Fragment>
      <PageHeading title="Payment Form Management" />
      <BreadCrumbSection
        breadcrumbNameMap={breadcrumbRoutes}
        shouldSearchBarAppear={
          RouteConstants.formLists === location.pathname ||
          RouteConstants.paymentFormManagement === location.pathname
        }
        shouldClearSearchKey={shouldClearSearchKey}
        searchPlaceholder="Search Projects"
        handleSearchOnKeyPress={handleSearchChange}
      />
      <Container>
        <Outlet
          context={{ searchKey, setSearchKey, setShouldClearSearchKey }}
        />
      </Container>
    </Fragment>
  );
};

export default PaymentFormManagement;
