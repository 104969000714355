import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import NotificationActions from "redux-container/notification-management/NotificationDashboardRedux";

export function* getNotificationDashboard(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getNotificationDashboard, action.data);
    yield put(NotificationActions.getNotificationDashboardSuccess(response.data));
  } catch (error) {
    yield put(NotificationActions.getNotificationDashboardFailure(error));
  }
}
