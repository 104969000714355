import * as Yup from "yup";

export const ResetPasswordValidationSchema = Yup.object({
  currentPassword: Yup.string()
    .matches(
      /^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,50})$/,
      "Password must contains atleast 1 symbol, digit and special characters"
    )
    .min(8, "Must be 8 characters or above")
    .required("This field is mandatory"),

  newPassword: Yup.string()
    .matches(
      /^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,50})$/,
      "Password must contains atleast 1 symbol, digit and special characters"
    )
    .min(8, "Must be 8 characters or above")
    .required("This field is mandatory"),

  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Password doesn't match with new password")
    .required("This field is mandatory"),
});
