import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  NotificationActionTypeEnum,
  NotificationActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import Button from "@mui/material/Button";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Checkbox from "@mui/material/Checkbox";
import ConfirmationDialog from "../../../components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Loader from "../../../components/loader/Loader";
import MediaAssets from "../../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import Retry from "../../../components/retry/Retry";
import { RouteConstants } from "../../../routes/RouteConstants";
import { SelectChangeEvent } from "@mui/material/Select";
import { TestimonialStatusTypeEnumUtils } from "enumerations/StatusTypeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import NotificationActions from "../../../redux-container/notification-management/NotificationsRedux";
import { exportAsExcel } from "utils/ExportFileUtils";
import UserActions from "../../../redux-container/users/UsersRedux";
import { Card, CardMedia, Stack } from "@mui/material";
import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import { AppUpdateActions } from "redux-container/notification-management/AppUpdatesRedux";

const actionDispatch = (dispatch: any) => {
  return {
    getAppUpdatesRequest: (params: any) =>
      dispatch(AppUpdateActions.getAppUpdatesRequest(params)),
    setAppUpdatesState: (key: any, data: any) =>
      dispatch(AppUpdateActions.setAppUpdatesState(key, data)),
  };
};

const AppUpdates = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const actions =
    NotificationActionTypeEnumUtils.getNotificationActionTypeEnum();
  const statuses =
    TestimonialStatusTypeEnumUtils.getTestimonialStatusTypeEnums();

  const { getAppUpdatesRequest, setAppUpdatesState } = actionDispatch(
    useDispatch()
  );

  const appUpdatesList = useSelector(
    (state: any) => state?.appUpdate.appUpdatesList
  );
  const totalCount = useSelector((state: any) => state?.appUpdate.totalCount);
  const users = useSelector((state: any) => state?.users?.usersForFilters);
  const isLoading = useSelector((state: any) => state?.appUpdate?.isLoading);
  const error = useSelector((state: any) => state?.appUpdate?.error);
  const isSuccess = useSelector((state: any) => state?.appUpdate?.isSuccess);
  const message = useSelector((state: any) => state?.appUpdate?.message);
  const shouldOpenFlashDialog = useSelector(
    (state: any) => state?.appUpdate?.shouldOpenFlashDialog
  );
  const { page, rowsPerPage } = useSelector((state: any) => {
    return {
      page: state?.appUpdate?.page,
      rowsPerPage: state?.appUpdate?.rowsPerPage,
    };
  });
  const [selectedAppUpdates, setSelectedAppUpdates] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedCreatedBy, setCreatedBy] = useState<any>(null);
  const [searchKey, setSearchKey] = useState("");

  const breadcrumbRoutes: any = [
    {
      name: "Notification Management",
      route: RouteConstants.notificationManagementDashboard,
    },
    {
      name: "App Updates List",
      route: RouteConstants.appUpdates,
    },
  ];

  //table headings (heading objects with name,boolean key for whether it is sorting column or not,and sorting key for an API call ).
  const tableHeadings = [
    { heading: "Title" },
    { heading: "Description" },
    { heading: "Background Image" },
    { heading: "Banner Image" },
    { heading: "Created On" },
    { heading: "Published On" },
    { heading: "Action" },
  ];

  //Loading Data (Customers data) while mounting and
  // every time after User applies any filters (Search key,Updated by,Created By,Page,and Index.)
  useEffect(() => {
    const params = {
      index: page,
      size: rowsPerPage,
      createdBy: selectedCreatedBy?.id,
      searchKey: searchKey,
    };
    if (searchKey || selectedCreatedBy || rowsPerPage || page) {
      loadData(params);
      clearSelected();
    }
  }, [page, rowsPerPage, searchKey, selectedCreatedBy]);

  const loadData = (params: any) => {
    getAppUpdatesRequest(params);
  };

  //setting page and rowsPerPage to initial value if user Navigated to the list from Side menu.
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setAppUpdatesState("page", 1);
      setAppUpdatesState("rowsPerPage", 20);
    }

    return () => {};
  }, []);

  //handle change function for page in Pagination.
  const handleChangePage = (event: unknown, newPage: number) => {
    setAppUpdatesState("page", newPage + 1);
  };

  //handle change function for number of elements per Page in Pagination.
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAppUpdatesState("rowsPerPage", Number(event.target.value));
    setAppUpdatesState("page", 1);
  };

  //handle change function for Action Selection (only one "Export" Action available).
  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case NotificationActionTypeEnum.Export:
        handleExportAsExcel();
        break;
    }
  };

  const handleExportAsExcel = () => {
    if (selectedAppUpdates.length === 0) {
      alert("You have not selected any App Updates");
      return;
    }

    let exportingAppUpdates: any = [];
    if (appUpdatesList?.length) {
      selectedAppUpdates?.map((selectedAppUpdate: any) => {
        let localSelectedItem = appUpdatesList?.find(
          (item: any) => item.id == selectedAppUpdate
        );
        exportingAppUpdates?.push({
          Title: localSelectedItem?.title ? localSelectedItem?.title : "----",
          Description: localSelectedItem?.description
            ? localSelectedItem?.description
            : "----",
          BackGround_Image: localSelectedItem?.backgroundImage?.value?.url
            ? localSelectedItem?.backgroundImage?.value?.url
            : "----",
          Banner_Image: localSelectedItem?.foregroundImage?.value?.url
            ? localSelectedItem?.foregroundImage?.value?.url
            : "----",
          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Published_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
        });
      });
    }

    exportAsExcel(exportingAppUpdates, "App Updates", "Sheet1");
  };

  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 1) {
      setAppUpdatesState("page", 1);
    }
  };

  //Resetting Redux State to its initial values after Showing "FlashMessageDialog".
  const resetState = () => {
    setAppUpdatesState("message", null);
    setAppUpdatesState("error", null);
    setAppUpdatesState("isSuccess", false);
  };

  const handleCloseFlashMessageDialog = () => {
    resetState();
    clearSelected();
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = appUpdatesList.map((data: any) => data.id);
      setSelectedAppUpdates(newSelecteds);
      return;
    }
    setSelectedAppUpdates([]);
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedAppUpdates.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAppUpdates, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAppUpdates.slice(1));
    } else if (selectedIndex === selectedAppUpdates?.length - 1) {
      newSelected = newSelected.concat(selectedAppUpdates.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAppUpdates.slice(0, selectedIndex),
        selectedAppUpdates.slice(selectedIndex + 1)
      );
    }

    setSelectedAppUpdates(newSelected);
  };

  const clearSelected = () => {
    setSelectedAppUpdates([]);
  };

  //function to Reset the filters applied by User.
  const clearFilters = () => {
    setCreatedBy(null);
    setSearchKey("");
  };

  const isSelected = (id: any) => selectedAppUpdates.indexOf(id) !== -1;

  const createNotification = () => {
    navigate(RouteConstants.appUpdateCreation, {
      state: { isViewMode: false, appUpdateData: null },
    });
  };

  //navigation to view Notification Detail.
  const viewNotification = (appUpdateData: any) => {
    const dataToView = {
      isViewMode: true,
      appUpdateData: appUpdateData,
    };
    navigate(`${RouteConstants.appUpdateCreation}`, {
      state: dataToView,
    });
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_testimonial}
        altText=""
        message="You haven't created any App Updates yet. Create your first App updates."
        btnTitle="CREATE APP UPDATES"
        btnAction={createNotification}
      />
    );
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Published App Updates
            {`(${
              totalCount
                ? totalCount
                : appUpdatesList?.length
                ? appUpdatesList?.length
                : 0
            })`}
            {selectedAppUpdates.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedAppUpdates?.length} App Update(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
              >
                {actions.map((action: any) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>

            <Button
              variant="contained"
              onClick={createNotification}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New
            </Button>
          </div>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedCreatedBy}
                    label="Created By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setCreatedBy(newValue);
                      if (page !== 0) {
                        setAppUpdatesState("page", 1);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              {selectedCreatedBy !== null ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Stack>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell style={{ minWidth: 50 }} padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedAppUpdates?.length > 0 &&
                      selectedAppUpdates?.length < appUpdatesList?.length
                    }
                    checked={
                      appUpdatesList?.length > 0 &&
                      selectedAppUpdates?.length === appUpdatesList?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all appUpdatesList",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {appUpdatesList && appUpdatesList.length > 0 ? (
              <TableBody>
                {appUpdatesList.map((appUpdate: any) => {
                  const isItemSelected = isSelected(appUpdate.id);
                  return (
                    <TableRow
                      key={appUpdate.id}
                      className="table-data-row"
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ minWidth: 50 }} padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, appUpdate.id)}
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150, maxWidth: 220 }}
                        className="table-data-text"
                      >
                        {appUpdate?.title ? appUpdate?.title : "-"}
                      </TableCell>

                      <TableCell
                        style={{
                          minWidth: 150,
                          maxWidth: 300,
                          wordBreak: "break-all",
                        }}
                        className="table-data-text"
                        align={"left"}
                      >
                        {appUpdate?.description ? appUpdate?.description : "-"}
                      </TableCell>
                      <TableCell
                        style={{ width: "190px" }}
                        className="table-data-text"
                      >
                        {appUpdate?.backgroundImage?.value?.url ? (
                          <Card
                            elevation={0}
                            sx={{
                              position: "relative",
                            }}
                          >
                            <CardMedia
                              component={"img"}
                              alt="Image"
                              src={appUpdate?.backgroundImage?.value?.url}
                              sx={{
                                height: "85px",
                              }}
                            />
                          </Card>
                        ) : (
                          "----"
                        )}
                      </TableCell>
                      <TableCell
                        style={{ width: "190px" }}
                        className="table-data-text"
                      >
                        {appUpdate?.foregroundImage?.value?.url ? (
                          <Card
                            elevation={0}
                            sx={{
                              position: "relative",
                            }}
                          >
                            <CardMedia
                              component={"img"}
                              alt="Image"
                              src={appUpdate?.foregroundImage?.value?.url}
                              sx={{
                                height: "85px",
                              }}
                            />
                          </Card>
                        ) : (
                          "----"
                        )}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 100 }}
                        className="table-data-text"
                      >
                        <div>
                          {appUpdate.createdAt
                            ? formatDateTime(
                                appUpdate.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 140 }}
                        className="table-data-text"
                      >
                        {appUpdate.createdAt
                          ? formatDateTime(
                              appUpdate.createdAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "-"}
                      </TableCell>

                      <TableCell style={{ minWidth: 120 }}>
                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewNotification(appUpdate)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No App Updates Records</div>
            )}
          </Table>
        </TableContainer>

        {!searchKey && appUpdatesList && appUpdatesList.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[20, 40, 50]}
            component="div"
            count={
              totalCount
                ? totalCount
                : appUpdatesList?.length
                ? appUpdatesList?.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    if (
      (appUpdatesList?.length > 0 ||
        selectedCreatedBy !== null ||
        searchKey !== "") &&
      isLoading === false
    ) {
      return renderTable();
    } else if (
      (appUpdatesList?.length === 0 || appUpdatesList?.length === undefined) &&
      selectedCreatedBy === null &&
      searchKey == "" &&
      isLoading === false
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };
  const shouldProvidePlaceholder = () => {
    if (
      (appUpdatesList?.length > 0 ||
        selectedCreatedBy !== null ||
        searchKey !== "") &&
      isLoading === false
    ) {
      return true;
    }
    return false;
  };
  return (
    <Fragment>
      <PageHeading title="Notification Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder={shouldProvidePlaceholder() ? "Search Notifications" : ""}
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>

      <FlashMessageDialog
        shouldOpen={shouldOpenFlashDialog}
        content={message ? message : "Something Went Wrong!"}
        isSuccess={false}
        cancelHandler={handleCloseFlashMessageDialog}
      />
    </Fragment>
  );
};

export default AppUpdates;
