export const AuditStatus = {
  EDITED: "2001",
  CREATED: "2002",
  DELETED: "2003",
};

export enum AuditValueStatus {
  Active = "1001",
  InActive = "1002",
}

export enum AuditToggleValue {
  ON = "ON",
  OFF = "OFF",
}

export const AuditLatestMediaGalleryCategory = {
  Project_Boundary: {
    value: "4001",
    label: "Project Boundary",
  },
  Internal_Roads: {
    value: "4002",
    label: "Internal Roads",
  },
  Amenities_Club_House: {
    value: "4003",
    label: "Amenities & Club House",
  },
  Other_infra_Development: {
    value: "4004",
    label: "Other Infra Development",
  },
  Master_Design: {
    value: "4005",
    label: "Master Design",
  },
};
