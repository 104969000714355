export const enum TestimonailTypeEnum {
  TextTestimonial = "5001",
  VideoTestimonial = "5002",
}

export class TestimonialTypeEnumUtils {
  public static getEnumText(type: TestimonailTypeEnum): string {
    switch (type) {
      case TestimonailTypeEnum.TextTestimonial:
        return "Text Testimonial";
      case TestimonailTypeEnum.VideoTestimonial:
        return "Video Testimonial";
    }
  }
  public static getTestimonailTypeEnums(): {
    label: string;
    value: TestimonailTypeEnum;
  }[] {
    const options: { label: string; value: TestimonailTypeEnum }[] = [
      {
        label: this.getEnumText(TestimonailTypeEnum.TextTestimonial),
        value: TestimonailTypeEnum.TextTestimonial,
      },
      {
        label: this.getEnumText(TestimonailTypeEnum.VideoTestimonial),
        value: TestimonailTypeEnum.VideoTestimonial,
      },
    ];
    return options;
  }
}
