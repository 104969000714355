import { Constants } from "constants/Constants";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { faqRoutePathConst } from "pages/resource-management/faq/faqRoutePathConst";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    //get all faqs 
    getAllFaqsSuccess: ["response"],
    getAllFaqsFailure: ["error"],
    getAllFaqsRequest: ["data"],

    //create faqs
    faqCreationSuccess: ["response"],
    faqCreationFailure: ["error"],
    faqCreationRequest: ["data"],

    ///update faqs
    faqUpdateSuccess: ["response"],
    faqUpdateFailure: ["error"],
    faqUpdateRequest: ["id", "data"],

    //bulk updated request
    faqBulkUpdateFailure: ["error"],
    faqBulkUpdateSuccess: ["response"],
    faqBulkUpdateRequest: ["data"],

    //delete faqs
    faqDeleteSuccess: ["response"],
    faqDeleteFailure: ["error"],
    faqDeleteRequest: ["data"],

    //bulkdelete Faqs
    faqBulkDeleteFailure: ["error"],
    faqBulkDeleteSuccess: ["response"],
    faqBulkDeleteRequest: ["data"],

    //status priority update
    faqPriorityUpdateRequest:["data"],
    faqPriorityUpdateSuccess:["response"],
    faqPriorityUpdateFailure:["error"],


    setFaqState: ["key", "value"]
}
);

export const GenealFaqTypes = Types;

export const GenealFaqActions = Creators

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  data: null,
  code: null,
  allFaqs: null,
  isSuccessfulUpdated: false,
  shouldAudit: false,
  responseData: null,
  isSuccessFulGeneralFaq: false,
  routeToPage: null,
  page: 0,
  rowsPerPage: 10,
  reduxAuditedObject:null
};



//getAllFaqs
export const getAllFaqsRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccessFulGeneralFaq: false,
    }
}
export const getAllFaqsSuccess = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        allFaqs: action.response,
        error: null,
        isSuccessfulFaqCreation: true,
        isSuccessFulGeneralFaq: true,

    }
}

export const getAllFaqsFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        code: action.error.code,
        error: action.error.message,
        isSuccessFulGeneralFaq: true,
    }
}



//FAQS  Creation 
export const faqCreationRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        responseData: null,
        isSuccessFulGeneralFaq: false,
        routeToPage:null

    }
}
export const faqCreationSuccess = (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      responseData: action.response,
      error: null,
      isSuccessfulFaqCreation: true,
      isSuccessFulGeneralFaq: true,
      routeToPage: faqRoutePathConst.CategoryTablePage,
      shouldAudit:true,
      reduxAuditedObject:action?.response?.data
    };
}

export const faqCreationFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        code: action.error.code,
        error: action.error.message,
        responseData: action.error,
        isSuccessFulGeneralFaq: true,
        routeToPage:"samePage"
    }
}

///FAQ UPDATED EVENT
export const faqUpdateRequest = (state: any, action: any) => {
 
    return {
        ...state,
        isLoading: true,
        isSuccessfulUpdated: false,
        responseData: null,
        isSuccessFulGeneralFaq: false,
        routeToPage:null,
    }
}
export const faqUpdateSuccess = (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      allFaqs: action.response,
      error: null,
      isSuccessfulUpdated: true,
      responseData: action.response,
      isSuccessFulGeneralFaq: true,
      routeToPage: faqRoutePathConst.FaqListTablePage,
      shouldAudit:true
    };
}

export const faqUpdateFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        code: action.error.code,
        error: action.error.message,
        isSuccessfulUpdated: true,
        responseData: action.error,
        isSuccessFulGeneralFaq: true,
        
    }
}
// bulk update

export const faqBulkUpdateRequest = (state: any, action: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccessfulUpdated: false,
        responseData: null,
        isSuccessFulGeneralFaq: false,

    }
}

export const faqBulkUpdateSuccess = (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      allFaqs: action.response,
      error: null,
      isSuccessfulUpdated: true,
      responseData: action.response,
      isSuccessFulGeneralFaq: true,
      shouldAudit:true
    };
}


export const faqBulkUpdateFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        code: action.error.code,
        error: action.error.message,
        isSuccessfulUpdated: true,
        responseData: action.error,
        isSuccessFulGeneralFaq: true,
    }
}

//faq Delete event
export const faqDeleteRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        responseData: null,
        isSuccessFulGeneralFaq: false,
        routeToPage: null

    }
}
export const faqDeleteSuccess = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        data: action.response,
        error: null,
        responseData: action.response,
        isSuccessFulGeneralFaq: true,
        routeToPage: faqRoutePathConst.FaqListTablePage
    }
}

export const faqDeleteFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        code: action.error.code,
        error: action.error.message,
        responseData: action.error,
        isSuccessFulGeneralFaq: true,
        routeToPage: "samePage"
    }
}

//faq bulk delete


export const faqBulkDeleteRequest = (state: any, action: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccessfulUpdated: false,
        responseData: null,
        isSuccessFulGeneralFaq: false,

    }
}

export const faqBulkDeleteSuccess = (state: any, action: any) => {
   
    return {
      ...state,
      isLoading: false,
      error: null,
      isSuccessfulUpdated: true,
      responseData: action.response,
      isSuccessFulGeneralFaq: true,
      shouldAudit:true
    };
}


export const faqBulkDeleteFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        code: action.error.code,
        error: action.error.message,
        isSuccessfulUpdated: true,
        responseData: action.error,
        isSuccessFulGeneralFaq: true,
    }
}

//UPDATED IN PRIORITY FAQS
export const faqPriorityUpdateRequest=(state:any)=>{

    return { 
           ...state,
           isLoading:true,
        isSuccessFulGeneralFaq:false,
        isSuccessfulUpdated: false,
           
    }
}
export const faqPriorityUpdateSuccess = (state: any,action:any) => {

    return {
        ...state,
        isLoading: false,
        isSuccessFulGeneralFaq:true,
        isSuccessfulUpdated: true,

    }
}
export const faqPriorityUpdateFailure = (state: any,action:any) => {
 
    return {
        ...state,
        isLoading: false,
        isSuccessFulGeneralFaq:true,
        isSuccessfulUpdated: true,
        responseData: action.error,
    }
}

export const setFaqState = (state: any, action: any) => {
    const newState = {
        ...state,
    };
    newState[action.key] = action.value;
    return newState;
};


export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_ALL_FAQS_REQUEST]: getAllFaqsRequest,
    [Types.GET_ALL_FAQS_SUCCESS]: getAllFaqsSuccess,
    [Types.GET_ALL_FAQS_FAILURE]: getAllFaqsFailure,

    [Types.FAQ_CREATION_SUCCESS]: faqCreationSuccess,
    [Types.FAQ_CREATION_FAILURE]: faqCreationFailure,
    [Types.FAQ_CREATION_REQUEST]: faqCreationRequest,

    [Types.FAQ_UPDATE_SUCCESS]: faqUpdateSuccess,
    [Types.FAQ_UPDATE_FAILURE]: faqUpdateFailure,
    [Types.FAQ_UPDATE_REQUEST]: faqUpdateRequest,

    [Types.FAQ_BULK_UPDATE_SUCCESS]: faqBulkUpdateSuccess,
    [Types.FAQ_BULK_UPDATE_FAILURE]: faqBulkUpdateFailure,
    [Types.FAQ_BULK_UPDATE_REQUEST]: faqBulkUpdateRequest,

    [Types.FAQ_DELETE_SUCCESS]: faqDeleteSuccess,
    [Types.FAQ_DELETE_FAILURE]: faqDeleteFailure,
    [Types.FAQ_DELETE_REQUEST]: faqDeleteRequest,

    [Types.FAQ_BULK_DELETE_SUCCESS]: faqBulkDeleteSuccess,
    [Types.FAQ_BULK_DELETE_FAILURE]: faqBulkDeleteFailure,
    [Types.FAQ_BULK_DELETE_REQUEST]: faqBulkDeleteRequest,

    [Types.FAQ_PRIORITY_UPDATE_SUCCESS]: faqPriorityUpdateSuccess,
    [Types.FAQ_PRIORITY_UPDATE_FAILURE]: faqPriorityUpdateFailure,
    [Types.FAQ_PRIORITY_UPDATE_REQUEST]: faqPriorityUpdateRequest,

    [Types.SET_FAQ_STATE]: setFaqState
}) 