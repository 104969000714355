import {
  Avatar,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import { SwitchComponent, TextFieldComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import styles from "./Opportunity.module.css";

function PageFooter() {
  const { values, handleChange, errors, touched, handleBlur } =
    useFormikContext<IInitinalValueProjectContent>();

    const isViewMode = useSelector((state: any) => (
      state?.projectContentManagement?.isViewMode
   ));
  return (
    <div>
      <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
        <Typography>Page Footer</Typography>

        <FormControlLabel
          className="switch-form-label"
          name={"opportunityDoc.isPageFooterActive"}
          checked={values?.opportunityDoc?.isPageFooterActive}
          onChange={handleChange}
          control={<SwitchComponent />}
          label=""
          labelPlacement="start"
          disabled={isViewMode}
        />

        <IconButton>
          <Tooltip title={"Page Footer"} placement="top">
            <Avatar
              className={styles["Avator"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
      </Stack>
      <Grid item xs={8} sm={8} md={8} lg={9} xl={8} p={3}>
        <TextFieldComponent
          required
          type="text"
          placeholder="Enter"
          label="Page Footer"
          name={`opportunityDoc.pageFooter`}
          value={values?.opportunityDoc?.pageFooter}
          onChange={handleChange}
          inputProps={{ maxLength: 40 }}
          onBlur={handleBlur}
          fieldhelpertext={
            getIn(touched, `opportunityDoc.pageFooter`) &&
            getIn(errors, `opportunityDoc.pageFooter`)
          }
          error={Boolean(
            getIn(errors, `opportunityDoc.pageFooter`) &&
              getIn(touched, `opportunityDoc.pageFooter`)
          )}
          disabled={isViewMode}
        />
      </Grid>
    </div>
  );
}

export default PageFooter;
