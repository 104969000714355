import {
  Avatar,
  Box,
  Typography,
  Link,
  Button,
  Stack,
  IconButton,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/material";
import MediaAssets from "../../../../assets";
import AlertHelperText from "../../../../components/ui-elements/alert-helper-text/AlertHelperText";
import styles from "./ImageUploadReplaceDragDrop.module.css";
import CachedIcon from "@mui/icons-material/Cached";
import LoadingButtonComponent from "components/ui-elements/button/LoadingButtonComponent";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IInsightsCreation } from "../../../../pages/resource-management/insights/insights-creation/InsightsCreationInitialValue";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import { Fragment } from "react";

interface IImageUploadReplaceDragDrop {
  fieldhelpertext: any;
  onDrop: (event: any) => void;
  onChange: (event: any) => void;
  previewimage?: any;
  name?: string;
  value?: string;
  index: number;
  cancelimage?: () => void;
  uploadMediaButton: boolean;
  dragAndDropButton: boolean;
  onEditClick?: (event: any) => void;
  resetLeft?: boolean;
  editButton?: boolean;
  deleteButton?: boolean;
  editDeleteButton?: boolean;
  setCurrentlyOpenedIndex?: () => void;
  isViewMode?: boolean;
  currentlyOpenedIndex?: any;
  onNewClick?: (event: any) => void;
}

const BoxWrapper = styled(Box)({
  // borderRadius: "2px",
  // width: "20rem",
  width: "100%",
  height: "15rem",
  position: "relative",
});

const BoxInnerWrapper = styled(Box)({
  // width: "20rem",
  width: "100%",
  height: "14.5rem",
});

const DragAndDropStyle = styled(Box)({
  border: "3px dotted #9795af",
  borderRadius: "2px",
  // width: "20rem",
  width: "100%",
  height: "15rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
});

const ImageUploadReplaceDragDrop = (props: IImageUploadReplaceDragDrop) => {
  const { values } = useFormikContext<IInsightsCreation>();
  const { isLoading } = useSelector((state: any) => state.fileUpload);

  let mediaUrl = values?.insightsMedia[props.index]?.media?.value?.mediaType;
  return (
    <BoxWrapper>
      {props.previewimage?.length > 0 ? (
        <BoxInnerWrapper>
          <CardMedia
            // alt={props.name}
            className={styles["preview-image-section"]}
            component={mediaUrl === "VIDEO" ? "iframe" : "img"}
            // scrolling="no"
            src={`${props.previewimage}?autoplay=0&mute=1&enablejsapi=0`}
          />
          {props.isViewMode !== true ? (
            <Button
              className={
                props.resetLeft
                  ? styles["left-replace-button-wrapper"]
                  : styles["right-replace-button-wrapper"]
              }
            >
              <label
                className={styles["display-flex-space-btw"]}
                htmlFor={props.name}
              >
                <input
                  // accept={mediaUrl === "Image" ? ".jpg,.png,.jpeg" : ".MP4"}
                  accept=".jpg,.png,.jpeg"
                  id={props.name}
                  name={props.name}
                  type="file"
                  className={styles["image-upload-input"]}
                  onChange={(event: any) => {
                    // props.onChange(event);
                    props.onChange(event.target.files[0]);
                  }}
                  onDrop={(event: any) => {
                    props.onDrop(event);
                  }}
                />
                <CachedIcon className={styles["cached-icon"]} />
                <Typography
                  className={styles["replace-text"]}
                  variant="body1"
                  component="h5"
                >
                  {isLoading && props.currentlyOpenedIndex === props.index
                    ? "Loading"
                    : "Replace"}
                </Typography>
              </label>
            </Button>
          ) : null}
          {props.editDeleteButton && (
            <Box position="absolute" right={0}>
              <Stack
                justifyContent={"space-around"}
                aria-label="text button group"
                className={styles["edit-button-wrapper"]}
              >
                <IconButton
                  onClick={props.onEditClick}
                  className={styles["right-side-icon"]}
                >
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>

                <IconButton
                  onClick={props.cancelimage}
                  className={styles["right-side-icon"]}
                >
                  <DeleteOutlineOutlinedIcon
                    className={`${styles["delete-icon"]} table-action-icon delete-icon`}
                  />
                </IconButton>
              </Stack>
            </Box>
          )}
        </BoxInnerWrapper>
      ) : (
        <DragAndDropStyle>
          {props.dragAndDropButton && (
            <Box component="label" htmlFor={props.name}>
              <input
                // accept={mediaUrl === "Image" ? ".jpg,.png,.jpeg" : ".MP4"}
                accept=".jpg,.png,.jpeg"
                id={props.name}
                name={props.name}
                type="file"
                className={styles["image-upload-input"]}
                onChange={(event: any) => {
                  props.onChange(event.target.files[0]);
                }}
                onDrop={(event: any) => {
                  props.onDrop(event);
                }}
              />
              <Stack direction={"column"}>
                {isLoading && props.currentlyOpenedIndex === props.index ? (
                  <Loader />
                ) : (
                  <Fragment>
                    <Avatar
                      className={styles["upload-icon"]}
                      src={MediaAssets.ic_upload_black}
                    />
                    <Box color="#6e6b8f" fontSize="16px">
                      Drag & Drop here
                    </Box>
                    <Box color="#9b9aa8" fontSize="16px">
                      Or
                    </Box>
                    <Link>
                      <Typography className={styles["browse-files-link"]}>
                        Browser file
                      </Typography>
                    </Link>
                    <Box color="#9795af" fontSize="12px">
                      Max file size : 10 MB
                    </Box>
                    <Box color="#9795af" fontSize="12px" mt="0.2rem">
                      Dimension(W*H): 198*194
                    </Box>
                    <Box color="#9795af" fontSize="12px" mt="0.5rem">
                      File Type: jpeg,svg,png,webp
                    </Box>
                  </Fragment>
                )}
                {props.fieldhelpertext ? (
                  <AlertHelperText color="error" severity="error">
                    {props.fieldhelpertext || ""}
                  </AlertHelperText>
                ) : (
                  <Typography
                    variant="body2"
                    visibility={"visible"}
                    lineHeight={1.5}
                  ></Typography>
                )}
              </Stack>
            </Box>
          )}

          {props.uploadMediaButton && (
            <Stack flexDirection={"column"}>
              <LoadingButtonComponent
                onClick={props.onNewClick}
                disabled={props.isViewMode || false}
              >
                <Avatar
                  sx={{ height: "1.25rem", width: "1.25rem", marginRight: 1 }}
                  src={MediaAssets.ic_upload_white}
                />
                Upload Media
              </LoadingButtonComponent>
              {props.fieldhelpertext && (
                <AlertHelperText color="error" severity="error">
                  {props.fieldhelpertext || ""}
                </AlertHelperText>
              )}
            </Stack>
          )}
        </DragAndDropStyle>
      )}
    </BoxWrapper>
  );
};
export default ImageUploadReplaceDragDrop;
