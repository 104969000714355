import { RouteConstants } from "routes/RouteConstants";
import {
  ActionEnumsUtils,
  ActionsEnums,
} from "../hoabl-invested-user/ActionsEnums";
import { TopicEnumsUtils } from "../hoabl-invested-user/TopicEnums";

const chatManagementRoutes: any = [
  {
    name: "Chat Support Management",
    route: RouteConstants.chatSupportManagementDashboard,
  },
  {
    name: "Hoabl - New User ",
    route: RouteConstants.hoablNewUser,
  },
];

const Topics = TopicEnumsUtils.getTopicEnums();
const optionActions = ActionEnumsUtils.getActionsEnums().filter(
  (actions) => actions.value !== ActionsEnums.AllowTyping
);

const newHoablPayload = {
  smartKey: "NEW_HOABL",
  firstMessage: "",
  welcomeMessage: "",
  // inactiveMessage: "",
  // allowTypingMessage: "",
  finalMessage: "Thank you for reaching out to us. Our sales executive will get in touch you soon to address all your questions.",
  categories: [
    {
      text: "",
      action: null,
      actionType: 1,
      message: "",
      options: [
        {
          text: "",
          action: null,
          actionType: null,
        },
        {
          text: "",
          action: null,
          actionType: null,
        },
      ],
    },
  ],
};

export const hoablNewUserLabels:any = {
  welcomeMessage:"Welcome Message *",
  firstMessage:"Message *",
  categories: [
    {
      text: "Display Name",
      action: "Topics",
      message:"Message body",
      options: [
        {
          text: "Option",
          action: "Actions",
        },
        {
          text: "Option",
          action: "Actions",
        },
      ],
    },
  ],
};

export { chatManagementRoutes, Topics, newHoablPayload, optionActions };
