import { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageDashboardActions from "../../../redux-container/page-management/PageDashboardRedux";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Container from "@mui/material/Container";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Loader from "../../../components/loader/Loader";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PageHeading from "../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import Retry from "../../../components/retry/Retry";
import { RouteConstants } from "../../../routes/RouteConstants";
import { SelectChangeEvent } from "@mui/material/Select";
import { TestimonialStatusTypeEnumUtils } from "enumerations/StatusTypeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import UserActions from "../../../redux-container/users/UsersRedux";
import { Stack } from "@mui/material";
import { TextFieldComponent } from "components/ui-elements";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import { PageTypeEnum } from "enumerations/PageTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    getPages: (params: any) =>
      dispatch(PageDashboardActions.getPageDashboardRequest(params)),
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),
    setPageDashboardState: (key: any, data: any) =>
      dispatch(PageDashboardActions.setPageDashboardState(key, data)),
  };
};

const PageManagementDashboard = () => {
  const { getPages, getUsers, setPageDashboardState } = actionDispatch(
    useDispatch()
  );

  const pages = useSelector((state: any) => state?.pageDashboard?.pages);
  const isLoading = useSelector((state: any) => state.pageDashboard?.isLoading);
  const error = useSelector((state: any) => state.pageDashboard?.error);
  const users = useSelector((state: any) => state?.users?.usersForFilters);

  const [selectedCreatedBy, setCreatedBy] = useState("");
  const [selectedModifiedBy, setModifiedBy] = useState("");

  const navigate = useNavigate();
  const location: any = useLocation();

  const breadcrumbRoutes: any = [
    {
      name: "Page Management",
      route: RouteConstants.pageManagementDashboard,
    },
  ];

  const tableHeadings = [
    { heading: "Page" },
    { heading: "Created On" },
    { heading: "Modified On" },
    { heading: "Status" },
    { heading: "Action" },
  ];

  useEffect(() => {
    getPages({});
  }, []);

  useEffect(() => {
    const params = {
      updatedBy: selectedModifiedBy,
      createdBy: selectedCreatedBy,
    };
    loadData(params);
  }, [selectedCreatedBy, selectedModifiedBy]);

  const loadData = (params: any) => {
    getPages(params);
    // getUsers({});
  };

  const clearFilters = () => {
    setCreatedBy("");
    setModifiedBy("");
  };

  const resetState = () => {
    setPageDashboardState("message", null);
    setPageDashboardState("error", null);
    setPageDashboardState("isSuccess", false);
  };

  const handleCloseFlashMessageDialog = () => {
    resetState();
  };

  const handleCreatedByChange = (event: SelectChangeEvent) => {
    setCreatedBy(event.target.value as string);
  };

  const handleModifiedByChange = (event: SelectChangeEvent) => {
    setModifiedBy(event.target.value as string);
  };

  const editPage = ({ page }: any) => {
    switch (page?.pageType) {
      case PageTypeEnum.HoABL:
        navigate(RouteConstants.page1Hoabl);
        break;
      case PageTypeEnum.Investments:
        navigate(RouteConstants.page2Investments);
        break;
      case PageTypeEnum.Promises:
        navigate(RouteConstants.page3Promises);
        break;
      case PageTypeEnum.Portfolio:
        navigate(RouteConstants.page4Portfolio);
        break;
      case PageTypeEnum.Profile:
        navigate(RouteConstants.page5Profile);
        break;
      case PageTypeEnum.FooterTabs:
        navigate(RouteConstants.page6FooterTabs);
        break;
      default:
        navigate(RouteConstants.page1Hoabl);
        break;
    }
  };

  const viewPage = ({ page }: Record<string, any>) => {
    const isViewMode = true;
    switch (page.pageType) {
      case PageTypeEnum.HoABL:
        return navigate(RouteConstants.page1Hoabl, { state: { isViewMode } });
      case PageTypeEnum.Investments:
        return navigate(RouteConstants.page2Investments, {
          state: { isViewMode },
        });
      case PageTypeEnum.Promises:
        return navigate(RouteConstants.page3Promises, {
          state: { isViewMode },
        });
      case PageTypeEnum.Portfolio:
        return navigate(RouteConstants.page4Portfolio, {
          state: { isViewMode },
        });
      case PageTypeEnum.Profile:
        return navigate(RouteConstants.page5Profile, { state: { isViewMode } });
      case PageTypeEnum.FooterTabs:
        return navigate(RouteConstants.page6FooterTabs, {
          state: { isViewMode },
        });
      default:
        break;
    }
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">Published Pages {`(${pages.length})`}</h4>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedCreatedBy}
                    label="Created By"
                    onChange={handleCreatedByChange}
                  >
                    {users?.map((user: any) => {
                      return (
                        <MenuItem value={user.id} key={user.id}>
                          {user.firstName}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedModifiedBy}
                    label="Modified By"
                    onChange={handleModifiedByChange}
                  >
                    {users?.map((user: any) => {
                      return (
                        <MenuItem value={user.id} key={user.id}>
                          {user.firstName}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              {selectedCreatedBy !== "" || selectedModifiedBy !== "" ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Stack>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 400 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    <Stack justifyContent="flex-start">
                      {index === 4 && (
                        <span className={"margin-right-15"}></span>
                      )}
                      {item.heading}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {pages && pages.length > 0 ? (
              <TableBody>
                {pages?.map((page: any) => {
                  return (
                    <TableRow key={page.id} className="table-data-row">
                      <TableCell
                        className="table-data-text"
                        style={{
                          minWidth: 150,
                          maxWidth: 200,
                          wordBreak: "break-all",
                        }}
                      >
                        {page.pageName ? page.pageName : "-"}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150 }}
                      >
                        <div>
                          {page &&
                          page.pageManagementCreatedBy &&
                          page.pageManagementCreatedBy.firstName
                            ? page?.pageManagementCreatedBy?.firstName
                            : null}
                        </div>
                        <div>
                          {page.createdAt
                            ? formatDateTime(
                                page.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150 }}
                      >
                        <div>
                          {page &&
                          page.pageManagementUpdatedBy &&
                          page.pageManagementUpdatedBy.firstName
                            ? page?.pageManagementUpdatedBy?.firstName
                            : null}
                        </div>
                        <div>
                          {page.updatedAt
                            ? formatDateTime(
                                page.updatedAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150 }}
                      >
                        {page.status
                          ? TestimonialStatusTypeEnumUtils.getEnumText(
                              page.status
                            )
                          : "-"}
                      </TableCell>

                      <TableCell style={{ minWidth: 150 }}>
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editPage({ page })}
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewPage({ page })}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Pages Records</div>
            )}
          </Table>
        </TableContainer>
      </Fragment>
    );
  };

  const render = () => {
    if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <PageHeading title="Page Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

      <Container className="main-container">{render()}</Container>

      {error ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
    </Fragment>
  );
};

export default PageManagementDashboard;
