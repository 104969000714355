import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardMedia,
    IconButton,
    Stack,
    styled,
    Typography,
  } from "@mui/material";
  import { ElementType, FocusEventHandler, Fragment } from "react";
  import MediaAssets from "assets";
  import CachedIcon from "@mui/icons-material/Cached";
import { AlertHelperText, ProgressLoader } from "components/ui-elements";
  interface Iimageuploaderdropbox {
    inputId?: number;
    fieldhelpertext?: any;
    ondrop?: (event: any) => void;
    onChange: (event: any) => void;
    previewimage?: any;
    name?: string;
    value?: string;
    cancelimage?: () => void;
    disabled?: boolean;
    loading?: boolean;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    componentType?: ElementType<HTMLElementTagNameMap>;
    mediaHeight?: any;
    mediaWidth?: any;
  }
  
  const FileUploadComponentStyleWrapper = styled<any>(Box)`
    border: 2px dotted
      ${(props) =>
        props.isError
          ? props.theme.palette.error.main
          : props.theme.palette.grey.A200};
    border-radius: 4px;
    opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
    // width: 20rem;
    max-width: 450px;
    width: fit-content;
    height: 17rem;
    
    position: relative;
    & .MuiCard-root {
      height: 100%;
      width: 100%;
    }
    & .replace-button {
      font-family: "Jost-Regular";
      cursor: pointer;
      padding: 0.2rem 0.5rem;
      border-radius: ${({ theme }) => theme.shape.borderRadius}px;
      position: absolute;
      column-gap: 0.3rem;
      bottom: 0.5rem;
      right: 1rem;
      display: flex;
      align-items: center;
      background-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.black};
    }
    & .trash-icon {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
      z-index: 1;
      border-radius: ${({ theme }) => theme.shape.borderRadius}px;
      padding: 0.1rem;
      background-color: ${({ theme }) => theme.palette.common.white};
    }
    & .to-right {
      right: 4rem;
    }
    & .caption-detail-text {
      line-height: 2;
      color: ${({ theme }) => theme.palette.grey.A200};
    }
  `;
  const FileUploadLoader = () => {
    return (
      <Stack
        position={"relative"}
        zIndex={5}
        justifyContent={"center"}
        height={"inherit"}
      >
        <ProgressLoader />
        <Typography
          color={"text.primary"}
          fontFamily={"Jost-Regular"}
          variant={"body1"}
          ml={"0.5rem"}
          lineHeight={2}
        >
          Loading...
        </Typography>
      </Stack>
    );
  };
  
  const UpcomingProjectsMediaUpload = (props: Iimageuploaderdropbox) => {
    const {
      inputId = 1,
      cancelimage,
      ondrop,
      disabled,
      previewimage,
      loading = false,
      onBlur,
      name,
      value,
      fieldhelpertext,
      onChange,
      componentType = "img",
      mediaWidth,
      mediaHeight,
    } = props;
    console.log("name", previewimage);
  
    return (
      <FileUploadComponentStyleWrapper
        isError={fieldhelpertext}
        isDisabled={disabled}
      >
        {loading ? (
          <FileUploadLoader />
        ) : (
          <Fragment>
            <Box component={"label"} htmlFor={name}>
              <input
                disabled={disabled}
                accept="image/jpg,image/png,image/jpeg"
                id={name}
                type="file"
                name={name}
                hidden
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                }}
                onDrop={(e: any) => {
                  if (ondrop) {
                    ondrop(e);
                  }
                }}
                required
              />
              {!!previewimage ? (
                <Box component={"span"} className="replace-button">
                  {!disabled && (
                    <>
                      <CachedIcon fontSize="small" />
                      Replace
                    </>
                  )}
                </Box>
              ) : (
                <Stack
                  direction={"column"}
                  p={"0.5rem"}
                  sx={{ cursor: disabled ? "not-allowed" : "pointer" }}
                >
                  <Avatar
                    sx={{ height: "30px", width: "30px" }}
                    src={MediaAssets.ic_file_upload}
                  />
                  <Typography
                    color={"text.primary"}
                    variant={"body1"}
                    lineHeight={2}
                  >
                    Drag & Drop here
                  </Typography>
                  <Typography variant={"caption"} className="caption-detail-text">
                    or
                  </Typography>
                  <Typography
                    variant={"body1"}
                    color={"common.black"}
                    sx={{
                      textDecoration: "underline",
                    }}
                  >
                    Browser files
                  </Typography>
                  <Typography className="caption-detail-text" variant="caption">
                    Max file size : 10 MB
                  </Typography>
                  <Typography variant="caption" className="caption-detail-text">
                    Dimension(W*H): {mediaHeight ? mediaHeight : "198"}*
                    {mediaWidth ? mediaWidth : "194"}
                  </Typography>
                  <Typography variant="caption" className="caption-detail-text">
                    File Type: jpeg,jpg,png
                  </Typography>
                  {fieldhelpertext ? (
                    <AlertHelperText color="error" severity="error">
                      {fieldhelpertext || ""}
                    </AlertHelperText>
                  ) : (
                    <Typography variant="body2" lineHeight={1.5}></Typography>
                  )}
                </Stack>
              )}
            </Box>
            {!!previewimage && previewimage.slice(-4) != ".pdf" ? (
              <Card
                sx={{
                  display: mediaWidth || mediaHeight ? "flex" : "block",
                  padding: "2rem 0"
                }}
              >
                <CardMedia
                  component={componentType}
                  alt="Image"
                  src={previewimage}
                  sx={{
                    margin: "auto",
                    width: mediaWidth || "100%",
                    height: mediaHeight || "100%",
                    borderRadius: mediaHeight || mediaWidth ? "6px" : "0px",
                  }}
                />
              </Card>
            ) : (
              value
            )}
          </Fragment>
        )}
      </FileUploadComponentStyleWrapper>
    );
  };
  
  export default UpcomingProjectsMediaUpload;
  