import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getLaunchPhasesSuccess: ["response"],
  getLaunchPhasesFailure: ["error"],
  getLaunchPhasesRequest: ["data"],

  getAllLaunchPhasesSuccess: ["response"],
  getAllLaunchPhasesFailure: ["error"],
  getAllLaunchPhasesRequest: ["data"],

  verifyLaunchPhaseDataSuccess: ["response"],
  verifyLaunchPhaseDataFailure: ["error"],
  verifyLaunchPhaseDataRequest: ["data"],

  setLaunchPhasesState: ["key", "value"],
});

export const launchPhasesTypes = Types;

export const launchPhasesAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  fetchedLaunchPhasesData: null,
  fetchedAllLaunchPhasesData: null,
  isSuccess: false,
  successResponse: null,
  isDialogOpen: false,
  confirmDialogOpen: false,
  isViewMode: false,
  totalCountOfNewProjects:0,
  verifiedResponse: {},
  isVerifyResponse: false,
  rowsPerPageOfNewTable:5,
  pageOfNewTable:0,
};

export const getLaunchPhasesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    verifiedResponse: {},
  };
};

export const getLaunchPhasesSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      fetchedLaunchPhasesData: response.data,
      isLoading: false,
      isSuccess: true,
      successResponse: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getLaunchPhasesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const getAllLaunchPhasesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    verifiedResponse: {},
  };
};

export const getAllLaunchPhasesSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      fetchedAllLaunchPhasesData: response.data,
      isLoading: false,
      isSuccess: true,
      successResponse: response.message,
      totalCountOfNewProjects:response.totalCount ?response.totalCount:0,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getAllLaunchPhasesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
    totalCountOfNewProjects:0
  };
};


// verify Lauch phases for Project Creating Project Content
export const verifyLaunchPhaseDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    error:null,
    verifiedResponse: {},
    isVerifyResponse: false
  };
};

export const verifyLaunchPhaseDataSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      verifiedResponse: response,
      isVerifyResponse: true

    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const verifyLaunchPhaseDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
    verifiedResponse: {},
    isVerifyResponse: false
  };
};

export const setLaunchPhasesState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LAUNCH_PHASES_SUCCESS]: getLaunchPhasesSuccess,
  [Types.GET_LAUNCH_PHASES_FAILURE]: getLaunchPhasesFailure,
  [Types.GET_LAUNCH_PHASES_REQUEST]: getLaunchPhasesRequest,

  [Types.GET_ALL_LAUNCH_PHASES_SUCCESS]: getAllLaunchPhasesSuccess,
  [Types.GET_ALL_LAUNCH_PHASES_FAILURE]: getAllLaunchPhasesFailure,
  [Types.GET_ALL_LAUNCH_PHASES_REQUEST]: getAllLaunchPhasesRequest,

  [Types.VERIFY_LAUNCH_PHASE_DATA_SUCCESS]: verifyLaunchPhaseDataSuccess,
  [Types.VERIFY_LAUNCH_PHASE_DATA_FAILURE]: verifyLaunchPhaseDataFailure,
  [Types.VERIFY_LAUNCH_PHASE_DATA_REQUEST]: verifyLaunchPhaseDataRequest,

  [Types.SET_LAUNCH_PHASES_STATE]: setLaunchPhasesState,
});
