import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { launchPhasesAction } from "redux-container/project-management/launch-phases/LaunchPhasesRedux";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

export function* getLaunchPhases(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getLaunchPhasesData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(launchPhasesAction.getLaunchPhasesSuccess(response.data));
    } else {
      yield put(launchPhasesAction.getLaunchPhasesFailure(response.data));
    }
  } catch (error) {
    yield put(launchPhasesAction.getLaunchPhasesFailure(error));
  }
}

export function* getAllLaunchPhases(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAllLaunchPhasesData, action.data);
    
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(launchPhasesAction.getAllLaunchPhasesSuccess(response.data));
    } else {
      yield put(launchPhasesAction.getAllLaunchPhasesFailure(response.data));
    }
  } catch (error) {
    yield put(launchPhasesAction.getAllLaunchPhasesFailure(error));
  }
}

export function* verifyLauchPhase(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.verifyProjectLauchPhase, action.data);    
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(launchPhasesAction.verifyLaunchPhaseDataSuccess(response.data));
    } else {
      yield put(launchPhasesAction.verifyLaunchPhaseDataFailure(response.data));
    }
  } catch (error) {
    yield put(launchPhasesAction.verifyLaunchPhaseDataFailure(error));
  }
}
