import * as Yup from "yup";

export const validationSchemaInvestedUser = Yup.object().shape({
  firstMessage: Yup.string().required("Required").nullable(),
  welcomeMessage: Yup.string().required("Required").nullable(),
  // inactiveMessage: Yup.string().required("Required").nullable(),
  finalMessage: Yup.string().required("Required").nullable(),
  // allowTypingMessage: Yup.string().required("Required").nullable(),
  categories : Yup.array().of(
    Yup.object({
      text: Yup.string().required("Required").nullable(),
      action: Yup.number().required("Required").nullable(),
    //   actionType: Yup.number().required("Required").nullable(),
      message: Yup.string().required("Required").nullable(),
      options: Yup.array().of(
        Yup.object({
          text: Yup.string().required('Required').nullable(),
          action: Yup.number().required('Required').nullable(),
        //   actionType: Yup.number().required('Required').nullable(),
        })
      )
    })),
  })
