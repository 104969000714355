import { AccordionProps, AccordionSummaryProps, styled } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordion from "@mui/material/Accordion";

interface IAccordianSummaryProps {
  outlined?: boolean;
}
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginTop: "1rem",
  marginBottom: "1rem",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "8px",
  "&&&& .MuiAccordionDetails-root": {
    padding: "0px ",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled(
  (props: AccordionSummaryProps & IAccordianSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        <KeyboardArrowDownIcon sx={{ fontSize: "2rem", color: "grey.600" }} />
      }
      {...props}
    />
  )
)(({ theme, outlined = false }) => ({
  font: "600 0.5rem 'Jost-Regular'",
  height: "4rem",
  borderRadius: "8px",
  color: theme.palette.common.black,
  backgroundColor: outlined ? "transparent" : theme.palette.grey[500],
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "&& .MuiButtonBase-root-MuiAccordionSummary-root": {
    borderRadius: "8px",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export { Accordion, AccordionSummary, AccordionDetails };
