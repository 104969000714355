import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({
  addProjectContentCreationDataSuccess: ["response"],
  addProjectContentCreationDataFailure: ["error"],
  addProjectContentCreationDataRequest: ["data"],

  getProjectContentCreationDataSuccess: ["response"],
  getProjectContentCreationDataFailure: ["error"],
  getProjectContentCreationDataRequest: ["data"],

  updateProjectContentCreationDataSuccess: ["response"],
  updateProjectContentCreationDataFailure: ["error"],
  updateProjectContentCreationDataRequest: ["data"],

  deleteMappedProjectContentFaqRequest: ["data"],
  deleteMappedProjectContentFaqSuccess: ["data"],
  deleteMappedProjectContentFaqFailure: ["data"],

  getProjectContentToCloneSuccess: ["response"],
  getProjectContentToCloneFailure: ["error"],
  getProjectContentToCloneRequest: ["data"],

  setUpdateProjectContentCreation: ["key", "value"],
});

export const ProjectContentCreationTypes = Types;

export default Creators;

export const INITIAL_STATE: any = {
  projectCreationData: [],
  isConfirmDialogOpen: false,
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  isViewMode: false,
  prevProjectValues: null,
  isGetProjectDetailsLoading: false,
  openFlashMessage: false,
  frequentlyAskedQuestion: [],
  projectCloneData: null,
  isProjectCloneLoading: false,
  newLaunchDetailsForProjectClone: null,
};

export const addProjectContentCreationDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    error: null,
  };
};

export const addProjectContentCreationDataSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Project Creation Data added successfully!",
      isLoading: false,
      openFlashMessage: true,
    };
  }
};

export const addProjectContentCreationDataFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    openFlashMessage: true,
  };
};

// get project Content Creation data
export const getProjectContentCreationDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isGetProjectDetailsLoading: true,
    error: null,
  };
};

export const getProjectContentCreationDataSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      projectCreationData: response.data,
      message: response.message,
      isLoading: false,
      isGetProjectDetailsLoading: false,
    };
  }
};

export const getProjectContentCreationDataFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isGetProjectDetailsLoading: false,
  };
};

export const updateProjectContentCreationDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateProjectContentCreationDataSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "project Content Creation Data Updates successfully!",
      isLoading: false,
      openFlashMessage: true,
    };
  }
};

export const updateProjectContentCreationDataFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    openFlashMessage: true,
  };
};

// Delete Project Mapped Faqs
export const deleteMappedProjectContentFaqRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const deleteMappedProjectContentFaqSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Project Mapped Faqs successfully!",
      isLoading: false,
    };
  }
};

export const deleteMappedProjectContentFaqFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action?.error?.message,
  };
};

// Get Project Details For clone
export const getProjectContentToCloneRequest = (state: any) => {
  return {
    ...state,
    isProjectCloneLoading: true,
    isGetProjectDetailsLoading: true,
    error: null,
  };
};

export const getProjectContentToCloneSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      projectCloneData: response.data,
      message: response.message,
      isProjectCloneLoading: false,
      isGetProjectDetailsLoading: false,
    };
  }
};

export const getProjectContentToCloneFailure = (state: any, action: any) => {
  return {
    ...state,
    isProjectCloneLoading: false,
    error: action.error.message,
    isGetProjectDetailsLoading: false,
  };
};

export const setUpdateProjectContentCreation = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_PROJECT_CONTENT_CREATION_DATA_SUCCESS]:
    addProjectContentCreationDataSuccess,
  [Types.ADD_PROJECT_CONTENT_CREATION_DATA_FAILURE]:
    addProjectContentCreationDataFailure,
  [Types.ADD_PROJECT_CONTENT_CREATION_DATA_REQUEST]:
    addProjectContentCreationDataRequest,

  [Types.GET_PROJECT_CONTENT_CREATION_DATA_SUCCESS]:
    getProjectContentCreationDataSuccess,
  [Types.GET_PROJECT_CONTENT_CREATION_DATA_FAILURE]:
    getProjectContentCreationDataFailure,
  [Types.GET_PROJECT_CONTENT_CREATION_DATA_REQUEST]:
    getProjectContentCreationDataRequest,

  [Types.UPDATE_PROJECT_CONTENT_CREATION_DATA_SUCCESS]:
    updateProjectContentCreationDataSuccess,
  [Types.UPDATE_PROJECT_CONTENT_CREATION_DATA_FAILURE]:
    updateProjectContentCreationDataFailure,
  [Types.UPDATE_PROJECT_CONTENT_CREATION_DATA_REQUEST]:
    updateProjectContentCreationDataRequest,

  [Types.DELETE_MAPPED_PROJECT_CONTENT_FAQ_SUCCESS]:
    deleteMappedProjectContentFaqSuccess,
  [Types.DELETE_MAPPED_PROJECT_CONTENT_FAQ_FAILURE]:
    deleteMappedProjectContentFaqFailure,
  [Types.DELETE_MAPPED_PROJECT_CONTENT_FAQ_REQUEST]:
    deleteMappedProjectContentFaqRequest,

  [Types.GET_PROJECT_CONTENT_TO_CLONE_SUCCESS]: getProjectContentToCloneSuccess,
  [Types.GET_PROJECT_CONTENT_TO_CLONE_FAILURE]: getProjectContentToCloneFailure,
  [Types.GET_PROJECT_CONTENT_TO_CLONE_REQUEST]: getProjectContentToCloneRequest,

  [Types.SET_UPDATE_PROJECT_CONTENT_CREATION]: setUpdateProjectContentCreation,
});
