import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import {
  CloseIcon,
  FullWidthDialogBox,
  MyProfileStyleWrapper,
} from "pages/user-management/user-profile/MyProfileDialog.style";
import { Fragment } from "react";
import styles from "./CustomerFeedBackDialog.module.css";
import { categoryEnum } from "enumerations/CustomerManagementEnums";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import { RatingRoleTypeEnumUtils } from "enumerations/RatingTypeEnum";

interface IMyProfileDialogProps {
  shouldOpen: boolean;
  handleClose: () => void;
}

function CustomerFeedBackDialog(props: any) {
  const { shouldOpen, handleClose, content } = props;

  // const profileData = getItemFromLocalStorage("Myprofile");
  const getObjKey = (obj: any, value: any) => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };

  return (
    <Fragment>
      <FullWidthDialogBox
        style={{ padding: "0px" }}
        fullWidth
        open={shouldOpen}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography className={styles["customer-name-heading"]}>
            Customer Feedback - {content?.user?.firstName}{" "}
            {content?.user?.lastName}
          </Typography>

          <IconButton edge={"end"} component={"span"} onClick={handleClose}>
            <CloseIcon src={MediaAssets.ic_close} />
          </IconButton>
        </DialogTitle>

        <MyProfileStyleWrapper>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} lg={12} md={12} xl={12}>
                <Stack spacing={2} justifyContent={"start"}>
                  <Fragment>
                    <Typography component="p" className="kye-typography">
                      CRM ID :
                      <Typography
                        component="span"
                        className="value-typography"
                        data-testid="name"
                      >
                        {content?.user?.crmId}
                      </Typography>
                    </Typography>
                  </Fragment>

                  <Fragment>
                    <Typography component="p" className="kye-typography">
                      CMS ID :
                      <Typography
                        component="span"
                        className="value-typography"
                        data-testid="name"
                        sx={{ textDecoration: "underline" }}
                      >
                        {content.userId}
                      </Typography>
                    </Typography>
                  </Fragment>
                </Stack>
              </Grid>
            </Grid>

            <Stack justifyContent={"space-between"} textAlign={"start"}>
              <Typography component="p" className="kye-typography">
                Customer Name:
                <Typography
                  component="span"
                  className="value-typography"
                  data-testid="designation"
                >
                  {` ${content?.user?.firstName} ${content?.user?.lastName}`}
                </Typography>
              </Typography>
            </Stack>

            <Stack justifyContent={"space-between"} textAlign={"start"}>
              <Typography component="p" className="kye-typography">
                Email:
                <Typography
                  component="span"
                  className="value-typography"
                  data-testid="designation"
                >
                  {` ${content?.user?.email}`}
                </Typography>
              </Typography>
            </Stack>

            <Stack justifyContent={"space-between"} textAlign={"start"}>
              <Typography component="p" className="kye-typography">
                Phone Number:
                <Typography
                  component="span"
                  className="value-typography"
                  data-testid="designation"
                >
                  {` ${content?.user?.countryCode} ${content?.user?.phoneNumber}`}
                </Typography>
              </Typography>
            </Stack>

            <Stack justifyContent={"space-between"} textAlign={"start"}>
              <Typography component="p" className="kye-typography">
                Category:
                <Typography
                  component="span"
                  className="value-typography"
                  data-testid="designation"
                >
                  {content?.categories?.map((item: any, index: any) => {
                    let data = getObjKey(categoryEnum, item);
                    return content?.categories?.length === index + 1
                      ? `${data} `
                      : `${data}, `;
                  })}
                </Typography>
              </Typography>
            </Stack>

            <Stack justifyContent={"space-between"} textAlign={"start"}>
              <Typography component="p" className="kye-typography">
                Rating:
                <Typography
                  component="span"
                  className="value-typography"
                  data-testid="designation"
                >
                  {RatingRoleTypeEnumUtils.getEnumText(content.rating)}
                </Typography>
              </Typography>
            </Stack>

            <Stack justifyContent={"space-between"} textAlign={"start"}>
              <Typography component="p" className="kye-typography">
                Submitted on:
                <Typography
                  component="span"
                  className="value-typography"
                  data-testid="designation"
                >
                  {content.updatedAt
                    ? " " +
                      formatDateTime(
                        content.updatedAt,
                        Constants.dateFormatDDmmYYYY
                      )
                    : " - "}
                </Typography>
              </Typography>
            </Stack>
            {content.description ? (
              <>
                <Typography component="h6" variant={"h6"}>
                  Feedback
                </Typography>
                <Typography component="p" className="kye-typography">
                  {content.description}
                </Typography>
              </>
            ) : null}
          </DialogContent>
        </MyProfileStyleWrapper>
      </FullWidthDialogBox>
    </Fragment>
  );
}

export default CustomerFeedBackDialog;
