import * as Yup from "yup";

const CheckWithCondition = (requiredText: any) => ({
  is: true,
  then: Yup.string().required(requiredText),
});

export const HoablPageSchema = Yup.object().shape({
  mastheadSection: Yup.object().shape({
    totalSqftOfLandTransacted: Yup.object().shape({
      value: Yup.string().required("Required"),
      displayName: Yup.string().required("required"),
    }),
    totalAmoutOfLandTransacted: Yup.object().shape({
      value: Yup.string().required("Required"),
      displayName: Yup.string().required("required"),
    }),
    grossWeightedAvgAppreciation: Yup.object().shape({
      // value: Yup.string().required("Required"),
      displayName: Yup.string().required("required"),
    }),
    totalNumberOfUsersWhoBoughtTheLand: Yup.object().shape({
      value: Yup.string().required("Required"),
      displayName: Yup.string().required("required"),
    }),
  }),
  isLatestUpdatesActive: Yup.boolean(),
  latestUpdates: Yup.object().when("isLatestUpdatesActive", {
    is: true,
    then: Yup.object({
      heading: Yup.string().required("Required"),
      subHeading: Yup.string().required("required"),
    }),
  }),
  isPromisesActive: Yup.boolean().nullable(),
  promisesHeading: Yup.string().when(
    "isPromisesActive",
    CheckWithCondition("Required")
  ),
  isPromotionAndOfferActive: Yup.boolean(),
  promotionAndOffersMedia: Yup.object().when("isPromotionAndOfferActive", {
    is: true,
    then: Yup.object().shape({
      value: Yup.object().shape({
        url: Yup.mixed().required("File is required"),
      }),
    }),
  }),
  promotionAndOffersProjectContentId: Yup.string()
    .when("isPromotionAndOfferActive", {
      is: true,
      then: Yup.string().required("Required"),
    })
    .nullable(),
  facilityManagement: Yup.object().shape({
    value: Yup.object().shape({
      url: Yup.mixed().required("File is required"),
    }),
  }),
  insightsHeading: Yup.string().when("isInsightsActive", {
    is: true,
    then: Yup.string().required("Required"),
  }),
  insightsSubHeading: Yup.string().when("isInsightsActive", {
    is: true,
    then: Yup.string().required("Required"),
  }),
  testimonialsHeading: Yup.string().when("isTestimonialsActive", {
    is: true,
    then: Yup.string().required("Required"),
  }),
  testimonialsSubHeading: Yup.string().when("isTestimonialsActive", {
    is: true,
    then: Yup.string().required("Required"),
  }),

  isCarouselActive: Yup.boolean(),
  isCarouselMediaPresent: Yup.boolean().when("isCarouselActive", {
    is: true,
    then: Yup.boolean().test(
      "is-true",
      "Add atleast 1 Carousel Media Item",
      (value) => value === true
    ),
  }),
  configureAutoSwiping: Yup.number()
    .nullable()
    .when("isCarouselActive", {
      is: true,
      then: Yup.number().required("Required").nullable(),
    }),
  isBrandMediaActive: Yup.boolean(),
  isBrandMediaPresent: Yup.boolean().when("isBrandMediaActive", {
    is: true,
    then: Yup.boolean().test(
      "is-true",
      "Add atleast 1 Brand Media Item",
      (value) => value === true
    ),
  }),
});
