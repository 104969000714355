import { IconButton, Stack, styled } from "@mui/material";
import TextFieldComponent from "../text-field/TextFieldComponent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
interface IPriority {
  incrementpriority?: (e?: any) => void;
  decrementpriority?: (e?: any) => void;
  priority?: number;
  name?: string;
  onChangeHandler?: (e?: any) => void;
  onBlurHandler?: (e?: any) => void;
  disableDecrement?: boolean;
  disableIncrement?: boolean;
  isPriorityRequired?: boolean;
  isDisabled?: boolean;
  isCounter?: boolean;
}
const WrapperPrioritySelector = styled("div")(() => ({
  "& .MuiIconButton-root": {
    padding: "1px",
  },
  "&&& .MuiInputBase-input": {
    padding: "2px 3px 1px 18px ",
    borderRadius: "20px",
  },
  "& .MuiOutlinedInput-root": {
    padding: " 3px 1px 3px 1px!important",
    backgroundColor: "#fff",
    //  backgroundColor: "#f2f0ff",
    // width: "80px",
    borderRadius: "5px",
    fontSize: "18px",
    width: "70px",
  },
  "& .MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: "6.5px",
  },
}));

export default function PrioritySelector(props: IPriority) {
  const {
    incrementpriority,
    decrementpriority,
    priority,
    onChangeHandler,
    onBlurHandler,
    disableDecrement,
    disableIncrement,
    isPriorityRequired,
    isCounter,
  } = props;

  return (
    <WrapperPrioritySelector>
      <TextFieldComponent
        {...props}
        value={isPriorityRequired ? "" : priority}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        disabled={isPriorityRequired}
        InputProps={{
          endAdornment: (
            <Stack direction="column" sx={{ borderLeft: "1px solid black" }}>
              <IconButton
                onClick={isCounter ? incrementpriority : decrementpriority}
                disabled={
                  (isCounter ? disableIncrement : disableDecrement) ||
                  isPriorityRequired
                }
              >
                <KeyboardArrowUpIcon
                  data-testid="increment-btn"
                  sx={{ fontSize: "1.2rem" }}
                />
              </IconButton>
              <IconButton
                onClick={isCounter ? decrementpriority : incrementpriority}
                disabled={
                  (isCounter ? disableDecrement : disableIncrement) ||
                  isPriorityRequired
                }
              >
                <KeyboardArrowDownIcon
                  data-testid="decrement-btn"
                  sx={{ fontSize: "1.2rem" }}
                />
              </IconButton>
            </Stack>
          ),
          inputProps: {
            min: 0,
            max: 10,
            "data-testid ": "counter-value",
            // readOnly: true,
          },
        }}
      />
    </WrapperPrioritySelector>
  );
}
