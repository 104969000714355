import { Constants } from "constants/Constants";
import { faqRoutePathConst } from "pages/resource-management/faq/faqRoutePathConst";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    //get all category 
    getAllCategoriesSuccess: ["response"],
    getAllCategoriesFailure: ["error"],
    getAllCategoriesRequest: ["data"],

    //add 
    addCategorySuccess: ["response"],
    addCategoryFailure: ["error"],
    addCategoryRequest: ["data"],

    //delete
    deleteCategorySuccess: ["response"],
    deleteCategoryFailure: ["error"],
    deleteCategoryRequest: ["data"],

    //put 
    putCategorySuccess: ["response"],
    putCategoryFailure: ["error"],
    putCategoryRequest: ["id", "data"],

    //bulk status change 
    bulkStatusCategorySuccess: ["response"],
    bulkStatusCategoryFailure: ["error"],
    bulkStatusCategoryRequest: ["data"],

    setCategoriesState: ["key", "value"],
});

export const GetAllCategoriesTypes = Types;

export const GetAllCategoriesAction = Creators;

export const INITIAL_STATE = {
    categories: [],
    error: null,
    isLoading: false,
    isSuccess: false,
    message: null,
    responseCategoryData: null,
    routeToPageCategory: null,
};

/* Get Categories */
export const getAllCategoriesRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
    };
};

export const getAllCategoriesSuccess = (state: any, action: any) => {
    const response = [{ name: "Select an option or create one" }, ...action.response.data];

    if (response) {
        return {
            ...state,
            error: null,
            categories: response,
            isLoading: false,
        };
    }
    //   return {
    //     ...state,
    //     isLoading: false,
    //     snackBarError: action.response ? action.response.message : "Failed to load",
    //   };
};

export const getAllCategoriesFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
    };
};


//add catagories

export const addCategoryRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccess: false,
        responseCategoryData: null,
    };
};

export const addCategorySuccess = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        error: null,
        isLoading: false,
        isSuccess: true,
        responseCategoryData: action.response,
    };
};

export const addCategoryFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
        isSuccess: false,
        responseCategoryData: action.error

    };
};

//delete category
export const deleteCategoryRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccess: false,
        responseCategoryData: null,
        routeToPageCategory: null,
    };
};


export const deleteCategoryFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
        isSuccess: true,
        responseCategoryData: action.error,
        routeToPageCategory: null
    };
};


export const deleteCategorySuccess = (state: any, action: any) => {

    return {
        ...state,
        error: null,
        isSuccess: true,
        isLoading: false,
        responseCategoryData: action.response,
        routeToPageCategory: faqRoutePathConst.CategoryDeleteFormList
    };
};


//editing the category

export const putCategoryFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        isSuccess: true,
        responseCategoryData: action.error,
        error: action.error.message,
    };
};



export const putCategoryRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccess: false,
        responseCategoryData: null
    };
};

export const putCategorySuccess = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        error: null,
        isLoading: false,
        isSuccess: true,
        responseCategoryData: action.response,
    };
};
//bulk status changes
export const bulkStatusCategoryRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccess: false,
        responseCategoryData: null
    };
};

export const bulkStatusCategoryFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        isSuccess: true,
        responseCategoryData: action.error,
        error: action.error.message,
    };
};

export const bulkStatusCategorySuccess = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        error: null,
        isLoading: false,
        isSuccess: true,
        responseCategoryData: action.response,
    };
};


//seting upcategory
export const setCategoriesState = (state: any, action: any) => {

    const newState = {
        ...state,
    };
    newState[action.key] = action.value;
    return newState;
};


export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_ALL_CATEGORIES_REQUEST]: getAllCategoriesRequest,
    [Types.GET_ALL_CATEGORIES_SUCCESS]: getAllCategoriesSuccess,
    [Types.GET_ALL_CATEGORIES_FAILURE]: getAllCategoriesFailure,

    [Types.ADD_CATEGORY_REQUEST]: addCategoryRequest,
    [Types.ADD_CATEGORY_SUCCESS]: addCategorySuccess,
    [Types.ADD_CATEGORY_FAILURE]: addCategoryFailure,

    [Types.DELETE_CATEGORY_REQUEST]: deleteCategoryRequest,
    [Types.DELETE_CATEGORY_SUCCESS]: deleteCategorySuccess,
    [Types.DELETE_CATEGORY_FAILURE]: deleteCategoryFailure,


    [Types.PUT_CATEGORY_REQUEST]: putCategoryRequest,
    [Types.PUT_CATEGORY_SUCCESS]: putCategorySuccess,
    [Types.PUT_CATEGORY_FAILURE]: putCategoryFailure,

    [Types.BULK_STATUS_CATEGORY_SUCCESS]: bulkStatusCategorySuccess,
    [Types.BULK_STATUS_CATEGORY_FAILURE]: bulkStatusCategoryFailure,
    [Types.BULK_STATUS_CATEGORY_REQUEST]: bulkStatusCategoryRequest,

    [Types.SET_CATEGORIES_STATE]: setCategoriesState,
});
