import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Tooltip,
  Box,
  Link,
  Container,
} from "@mui/material";
import {
  Card,
  CardMedia,
  FormControl,
  MenuItem,
  Grid,
  Stack,
} from "@mui/material";
import { useState, useEffect } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import projectListActions from "redux-container/project-management/ProjectListRedux";
import formListActions from "../../../redux-container/form-list/FormListRedux";
import { RouteConstants } from "routes/RouteConstants";
import {
  AutocompleteComponent,
  ButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectStatusTypeEnumUtils } from "enumerations/ProjectStatusTypeEnum";
import PanoramaIcon from "@mui/icons-material/Panorama";
import styles from "../../project-management/project-list/ProjectList.module.css";
import Loader from "components/loader/Loader";
import PageHeading from "components/page-heading/PageHeading";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import MediaAssets from "assets";
import NoDataRecord from "components/no-data-record/NoDataRecord";

const actionDispatch = (dispatch: any) => {
  return {
    getProjectList: async (params: any) => {
      dispatch(projectListActions.projectListRequest(params));
    },
    setFormListState: async (key: any, value: any) => {
      dispatch(formListActions.setFormListState(key, value));
    },
    getForms: (params: any) =>
      dispatch(formListActions.formsListRequest(params)),
  };
};

const NewForm = ({ listHeading, isNewForm, searchKey, tabValue }: any) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const users = useSelector((state: any) => state?.users?.usersForFilters);

  const statuses = ProjectStatusTypeEnumUtils.getProjectStatusTypeEnums();

  const { getProjectList, setFormListState, getForms } = actionDispatch(
    useDispatch()
  );

  const breadcrumbRoutes: any = [
    {
      name: "Payment Management",
      route: RouteConstants.paymentFormManagement,
    },
    {
      name: "Payment List",
      route: RouteConstants.paymentFormManagement,
    },
  ];
  const formList = useSelector((state: any) => state?.projectList?.data?.data);
  const CRMProjects = useSelector((state: any) => state?.formsList?.projects);
  const launches = useSelector((state: any) => state?.formsList?.launches);
  const newFormpage = useSelector(
    (state: any) => state?.formsList?.newFormpage
  );
  const newFormRowsPerPage = useSelector(
    (state: any) => state?.formsList?.newFormRowsPerPage
  );
  const isLoading = useSelector((state: any) => state?.projectList?.isLoading);
  const fetchedAllLaunchPhasesData = useSelector(
    (state: any) => state.projectLaunchPhases?.fetchedAllLaunchPhasesData
  );

  const publishedFormList = useSelector(
    (state: any) => state?.formsList?.forms
  );

  const publishedFormListIds = publishedFormList?.map(
    (item: any) => item?.crmLaunchPhaseId
  );

  const crmProjectNamesOfPublished = CRMProjects?.filter(
    (option: any, index: any, element: any) =>
      element.findIndex((item: any) => item?.name === option?.name) === index
  );
  const totalCount = useSelector(
    (state: any) => state?.projectList?.totalCount
  );
  const [selectedForms, _setSelectedForms] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedCRMProject, setSelectedCRMProject] = useState<any>(null);
  const [selectedLaunch, setSelectedLaunch] = useState<any>(null);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState<any>();

  useEffect(() => {
    getForms({});
  }, []);

  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setFormListState("newFormpage", 0);
      setFormListState("newFormRowsPerPage", 5);
    }

    return () => {
      getProjectList({});
    };
  }, []);

  useEffect(() => {
    const params = {
      status: selectedStatus,
      crmProjectName: selectedCRMProject?.name,
      crmLaunchPhaseName: selectedLaunch?.launchName,
      index: newFormpage + 1,
      size: newFormRowsPerPage,
      createdBy: selectedCreatedBy?.id,
      searchKey: searchKey,
    };
    getProjectList(params);
  }, [
    selectedStatus,
    selectedCRMProject,
    selectedLaunch,
    newFormpage,
    newFormRowsPerPage,
    selectedCreatedBy,
    tabValue == 1 ? searchKey : null,
  ]);

  const handleResetFilter = () => {
    setSelectedStatus(null);
    setSelectedCRMProject(null);
    // setSelectedCreatedBy(null);
    setSelectedLaunch(null);
  };
  const handleViewIconClick = (form: any) => {
    navigate(RouteConstants.projects, { state: form });
  };
  const handleEditIconClick = (form: any) => {
    navigate(RouteConstants.projects, { state: form });
  };

  const handleClickCreateNew = (form: any) => {
    navigate(`${RouteConstants.formLists}/${form?.id}`, {
      state: { form: form, isEditMode: false, isNew: true },
    });
  };

  const handleClickPublishedProject = (formCrmLaunchPhaseId: any) => {
    const publishedFormDetails = publishedFormList?.find(
      (item: any) => item?.crmLaunchPhaseId === formCrmLaunchPhaseId
    );
    navigate(`${RouteConstants.formLists}/${publishedFormDetails?.id}`, {
      state: {
        form: publishedFormDetails,
        isEditMode: true,
        isNew: false,
      },
    });
  };
  const isSelected = (id: any) => selectedForms.indexOf(id) !== -1;

  // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     const newSelecteds: any = formList.map((data: any) => data.id);
  //     setSelectedForms(newSelecteds);
  //     return;
  //   }
  //   setSelectedForms([]);
  // };
  // const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
  //   const selectedIndex = selectedForms.indexOf(id);
  //   let newSelected: readonly string[] = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selectedForms, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selectedForms.slice(1));
  //   } else if (selectedIndex === selectedForms?.length - 1) {
  //     newSelected = newSelected.concat(selectedForms.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selectedForms.slice(0, selectedIndex),
  //       selectedForms.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelectedForms(newSelected);
  // };

  // const clearSelected = () => {
  //   setSelectedForms([]);
  // };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFormListState("newFormpage", newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormListState("newFormRowsPerPage", Number(event.target.value));
    setFormListState("newFormpage", 0);
  };

  const renderTable = () => {
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Stack>
              <Grid container spacing={2} md={10} lg={10} xl={10}>
                <Grid
                  item
                  sx={{ marginRight: -"40px" }}
                  md={1}
                  lg={1}
                  xl={1}
                  className="margin-left-10 filter-heading-holder"
                >
                  <span className="filter-heading">Filters:</span>
                </Grid>

                <Grid item md={2} lg={2} xl={2}>
                  <FormControl className="filter-dropdown">
                    <AutocompleteComponent
                      options={crmProjectNamesOfPublished}
                      getOptionLabel={(option: any) => option?.name}
                      label="CRM Project Name"
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: any | null
                      ) => {
                        setSelectedCRMProject(newValue);
                      }}
                      value={selectedCRMProject}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option?.id === value?.id
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item md={2} lg={2} xl={2}>
                  <FormControl className="filter-dropdown">
                    <AutocompleteComponent
                      options={launches}
                      getOptionLabel={(option: any) => option?.launchName}
                      label="Launch"
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: any | null
                      ) => {
                        setSelectedLaunch(newValue);
                      }}
                      value={selectedLaunch}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option?.id === value?.id
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={2} lg={2} xl={2}>
                  <FormControl className="filter-dropdown">
                    <TextFieldComponent
                      select
                      value={selectedStatus}
                      label="Status"
                      onChange={(event: any) => {
                        setSelectedStatus(event.target.value);
                      }}
                    >
                      {statuses.map((status: any) => {
                        return (
                          <MenuItem value={status.value} key={status.value}>
                            {status.label}
                          </MenuItem>
                        );
                      })}
                    </TextFieldComponent>
                  </FormControl>
                </Grid>
                {/* <Grid item md={2} lg={2} xl={2}>
                  <FormControl className="filter-dropdown">
                    <AutocompleteComponent
                      options={users}
                      getOptionLabel={(option: any) =>
                        option?.isDuplicated
                          ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                          : `${option?.firstName} ${option?.lastName}`
                      }
                      value={selectedCreatedBy}
                      label="Created by"
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: any | null
                      ) => {
                        setSelectedCreatedBy(newValue);
                      }}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option?.id === value?.id
                      }
                    />
                  </FormControl>
                </Grid> */}

                {selectedCRMProject ||
                selectedStatus ||
                // selectedCreatedBy ||
                selectedLaunch ? (
                  <Grid
                    item
                    md={0.5}
                    lg={0.5}
                    xl={0.5}
                    className="filter-heading-holder"
                    style={{ position: "relative" }}
                  >
                    <span
                      onClick={handleResetFilter}
                      className="clear-filter"
                      style={{ position: "absolute", top: 32 }}
                    >
                      Clear
                    </span>
                  </Grid>
                ) : null}
              </Grid>
            </Stack>
            <TableContainer
              component={Paper}
              className="table-container"
              style={{ height: window.innerHeight - 475 }}
            >
              <Table style={{ position: "relative" }}>
                <TableHead>
                  <TableRow className="table-header-row">
                    {/* <TableCell className="table-header-text" padding="checkbox">
                    <Checkbox
                      className="active-checkbox"
                      data-testid="table-head-checkbox"
                      color="primary"
                      indeterminate={
                        selectedForms?.length > 0 &&
                        selectedForms?.length < formList?.length
                      }
                      checked={
                        formList?.length > 0 &&
                        selectedForms?.length === formList?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell> */}
                    {listHeading.map(
                      (item: (typeof listHeading)[0], index: number) => (
                        <TableCell key={index} className="table-header-text">
                          {item.heading}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                {formList?.length > 0 ? (
                  <TableBody>
                    {formList.map((form: any) => {
                      const isItemSelected: any = isSelected(form?.id);

                      return (
                        <TableRow
                          key={form.id}
                          className="table-data-row"
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event: any) =>
                              handleClick(event, form?.id)
                            }
                            className="active-checkbox"
                            checked={isItemSelected}
                          />
                        </TableCell> */}

                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {`#ID-${form.id}`}
                          </TableCell>
                          <TableCell
                            style={{ width: 160, justifyContent: "center" }}
                          >
                            {form.projectCoverImages?.homePageMedia?.value
                              ?.url ? (
                              <Card
                                elevation={0}
                                className={styles["project-image"]}
                                style={{
                                  height: "80px",
                                  width: "100%",
                                  objectFit: "fill",
                                }}
                              >
                                <CardMedia
                                  className={styles["media-area"]}
                                  component={"img"}
                                  alt="Image"
                                  src={
                                    form.projectCoverImages?.homePageMedia
                                      ?.value?.url
                                  }
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "fill",
                                  }}
                                />
                              </Card>
                            ) : (
                              <PanoramaIcon
                                // style={{
                                //   height: "3rem",
                                //   width: "3rem",
                                //   marginLeft: "2rem",
                                // }}
                                style={{
                                  height: "80px",
                                  width: "100%",
                                  objectFit: "fill",
                                }}
                              />
                            )}
                          </TableCell>

                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 200 }}
                          >
                            {form?.generalInfoEscalationGraph
                              ?.crmLaunchPhaseName
                              ? form?.generalInfoEscalationGraph
                                  ?.crmLaunchPhaseName
                              : "-"}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 200 }}
                          >
                            {form?.generalInfoEscalationGraph?.crmProjectName
                              ? form?.generalInfoEscalationGraph?.crmProjectName
                              : "-"}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 200 }}
                          >
                            {form?.launchName ? form.launchName : "-"}
                          </TableCell>

                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 200 }}
                          >
                            {/* {form?.generalInfoEscalationGraph?.crmProjectName
                        ? form?.generalInfoEscalationGraph?.crmProjectName
                        : "-"} */}
                            {"----"}
                          </TableCell>

                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 150 }}
                          >
                            {form?.status
                              ? ProjectStatusTypeEnumUtils.getEnumText(
                                  form?.status
                                )
                              : "-"}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 170 }}
                          >
                            {isNewForm ? (
                              <>
                                {publishedFormListIds?.includes(
                                  form?.crmLaunchPhaseId
                                ) ? (
                                  <>
                                    <ButtonComponent
                                      sx={{
                                        color: "var(--quinary-theme-color)",
                                        fontSize: "1rem",
                                        textDecoration: "underline",
                                      }}
                                      variant="text"
                                      onClick={() =>
                                        handleClickPublishedProject(
                                          form?.crmLaunchPhaseId
                                        )
                                      }
                                    >
                                      Published
                                    </ButtonComponent>
                                  </>
                                ) : (
                                  <ButtonComponent
                                    sx={{
                                      color: "var(--quinary-theme-color)",
                                      fontSize: "1rem",
                                      textDecoration: "underline",
                                    }}
                                    variant="text"
                                    onClick={() => handleClickCreateNew(form)}
                                  >
                                    {`Create form >`}
                                  </ButtonComponent>
                                )}
                              </>
                            ) : (
                              <Box>
                                <Tooltip title="Create" placement="top">
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => handleEditIconClick(form)}
                                  >
                                    <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                                    <Link></Link>
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="View" placement="top">
                                  <IconButton
                                    aria-label="view"
                                    onClick={() => handleViewIconClick(form)}
                                  >
                                    <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <div className="no-record">No Projects Found</div>
                )}
              </Table>
            </TableContainer>
            {formList?.length > 0 ? (
              <TablePagination
                // className={styles["table-pagination"]}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  totalCount
                    ? totalCount
                    : formList?.length
                    ? formList?.length
                    : 0
                }
                rowsPerPage={newFormRowsPerPage}
                page={newFormpage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </>
        )}
      </>
    );
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_upload_black}
        altText=""
        message="You haven't created any Projects yet. Create your first Project."
        btnTitle="CREATE Project"
        btnAction={createProject}
      />
    );
  };

  const createProject = () => {
    navigate(RouteConstants.projectList);
  };

  const render = () => {
    if (
      isLoading === false &&
      (formList?.length > 0 ||
        selectedStatus ||
        selectedCRMProject ||
        selectedLaunch ||
        searchKey)
    ) {
      return renderTable();
    } else if (
      isLoading === false &&
      !formList?.length &&
      !selectedStatus &&
      !selectedCRMProject &&
      !selectedLaunch &&
      !searchKey
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    }
    //  else if (error) {
    //   return renderRetry();
    // }
  };

  return (
    <>
      {/* <PageHeading title="Promise Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder="Search Promises"
        handleSearchOnKeyPress={handleSearchChange} 
      ></BreadCrumbs> */}

      <>{render()}</>
    </>
  );
};

export default NewForm;
