import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({
  addBlogsSuccess: ["response"],
  addBlogsFailure: ["error"],
  addBlogsRequest: ["data"],

  getSingleBlogsSuccess: ["response"],
  getSingleBlogsFailure: ["error"],
  getSingleBlogsRequest: ["data"],

  updateBlogsSuccess: ["response"],
  updateBlogsFailure: ["error"],
  updateBlogsRequest: ["data"],

  addDraftsBlogsSuccess: ["response"],
  addDraftsBlogsFailure: ["error"],
  addDraftsBlogsRequest: ["data"],

  deleteBlogsSuccess: ["response"],
  deleteBlogsFailure: ["error"],
  deleteBlogsRequest: ["data"],

  blogCreationStatusSuccess: ["response"],
  blogCreationStatusFailure: ["error"],
  blogCreationStatusRequest: ["data"],

  setBlogState: ["key", "value"],
});

export const BlogCreationTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  updates: [],
  drafts: [],
  singleUpdate: "",
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  showFlashMessage: false,
  mediaDeleteIndex: 0,
  responseForNewAudit: null,
};

// Add Updates
export const addBlogsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const addBlogsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: "Blogs Added Successfully!",
      isLoading: false,
      showFlashMessage: true,
      responseForNewAudit: response.data,
    };
  }
};

export const addBlogsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: "Could Not Add Blog!",
    isSuccess: false,
    showFlashMessage: true,
  };
};

// Add Drafts Blogs
export const addDraftsBlogsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
    showFlashMessage: true,
  };
};

export const addDraftsBlogsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: "Blogs Added Successfully!",
      isLoading: false,
    };
  }
};

export const addDraftsBlogsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: "Could Not Add Blog!",
    isSuccess: false,
  };
};

// Get Single Blogs
export const getSingleBlogsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getSingleBlogsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      singleUpdate: response.data,
      isLoading: false,
      totalCount: response.totalCount,
    };
  }
};

export const getSingleBlogsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: "Could not fetch Blog",
  };
};

// Updating Marketing blogs
export const updateBlogsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const updateBlogsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: "Blog updated successfully!",
      isLoading: false,
      showFlashMessage: true,
    };
  }
};

export const updateBlogsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: "Blog Not Updated!",
    isSuccess: false,
    showFlashMessage: true,
  };
};

//  Delete Updates
export const deleteBlogsRequest = (state: any) => {
  console.log("entering request");
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const deleteBlogsSuccess = (state: any, action: any) => {
  const response = action.response;
  console.log("entering request", response);

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      showFlashMessage: true,
      message: "Blogs deleted successfully!",
      isLoading: false,
    };
  }
};

export const deleteBlogsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: "Could Not Delete Blogs!",
    isSuccess: false,
  };
};

// change update status
export const blogCreationStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const blogCreationStatusSuccess = (state: any, action: any) => {
  const response = action.response;
  console.log("msg", action.response);
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      message: "Blog Updated successfully!",
      showFlashMessage: true,
    };
  }
};

export const blogCreationStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setBlogState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_BLOGS_SUCCESS]: addBlogsSuccess,
  [Types.ADD_BLOGS_FAILURE]: addBlogsFailure,
  [Types.ADD_BLOGS_REQUEST]: addBlogsRequest,

  [Types.GET_SINGLE_BLOGS_SUCCESS]: getSingleBlogsSuccess,
  [Types.GET_SINGLE_BLOGS_FAILURE]: getSingleBlogsFailure,
  [Types.GET_SINGLE_BLOGS_REQUEST]: getSingleBlogsRequest,

  [Types.UPDATE_BLOGS_SUCCESS]: updateBlogsSuccess,
  [Types.UPDATE_BLOGS_FAILURE]: updateBlogsFailure,
  [Types.UPDATE_BLOGS_REQUEST]: updateBlogsRequest,

  [Types.ADD_DRAFTS_BLOGS_SUCCESS]: addDraftsBlogsSuccess,
  [Types.ADD_DRAFTS_BLOGS_FAILURE]: addDraftsBlogsFailure,
  [Types.ADD_DRAFTS_BLOGS_REQUEST]: addDraftsBlogsRequest,

  [Types.DELETE_BLOGS_SUCCESS]: deleteBlogsSuccess,
  [Types.DELETE_BLOGS_FAILURE]: deleteBlogsFailure,
  [Types.DELETE_BLOGS_REQUEST]: deleteBlogsRequest,

  [Types.BLOG_CREATION_STATUS_REQUEST]: blogCreationStatusRequest,
  [Types.BLOG_CREATION_STATUS_SUCCESS]: blogCreationStatusSuccess,
  [Types.BLOG_CREATION_STATUS_FAILURE]: blogCreationStatusFailure,

  [Types.SET_BLOG_STATE]: setBlogState,
});
