export const enum ActionsEnums {
  NavigateToPortFolio = 119,
  NavigateToBookingJourney = 120,
  NavigateToProjectTimeline = 121,
  NavigateToProjectDetails = 111,
  NavigateToFAQs = 114,
  AllowTyping = 108,
}

export class ActionEnumsUtils {
  public static getEnumText(type: ActionsEnums): string {
    switch (type) {
      case ActionsEnums.NavigateToPortFolio:
        return "Navigate To Portfolio";
      case ActionsEnums.NavigateToBookingJourney:
        return "Navigate To Booking Journey";
      case ActionsEnums.NavigateToProjectTimeline:
        return "Navigate To Project Timeline";
      case ActionsEnums.NavigateToProjectDetails:
        return "Navigate To Project Details";
      case ActionsEnums.NavigateToFAQs:
        return "Navigate To FAQs";
      case ActionsEnums.AllowTyping:
        return "Allow Typing";
    }
  }

  public static getActionsEnums(): {
    label: string;
    value: ActionsEnums;
  }[] {
    const options: { label: string; value: ActionsEnums }[] = [
      {
        label: this.getEnumText(ActionsEnums.NavigateToPortFolio),
        value: ActionsEnums.NavigateToPortFolio,
      },
      {
        label: this.getEnumText(ActionsEnums.NavigateToBookingJourney),
        value: ActionsEnums.NavigateToBookingJourney,
      },
      {
        label: this.getEnumText(ActionsEnums.NavigateToProjectTimeline),
        value: ActionsEnums.NavigateToProjectTimeline,
      },
      {
        label: this.getEnumText(ActionsEnums.NavigateToProjectDetails),
        value: ActionsEnums.NavigateToProjectDetails,
      },
      {
        label: this.getEnumText(ActionsEnums.NavigateToFAQs),
        value: ActionsEnums.NavigateToFAQs,
      },
      {
        label: this.getEnumText(ActionsEnums.AllowTyping),
        value: ActionsEnums.AllowTyping,
      },
    ];
    return options;
  }
}
