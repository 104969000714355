import {
  Box,
  Checkbox,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import {
  ButtonComponent,
  FormFieldContainer,
  InfoComponent,
  LoadingButtonComponent,
  MediaUploadComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Fragment, useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PageHeading from "components/page-heading/PageHeading";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { FormikValues, useFormik, getIn } from "formik";
import NotificationActions from "redux-container/notification-management/NotificationsRedux";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { RouteConstants } from "routes/RouteConstants";
import { notificationTypeEnum } from "enumerations/NotificationTypeEnum";
import {
  appUpdateBreadcrumbRoutes,
  appUpdateInitialValues,
} from "./AppUpdatesInitialValues";
import { newNotificationPayload } from "../notification-list/create-notification/NotificationInitialValues";
import { appUpdatesValidationSchema } from "./appUpdatesValidation";
import { AppUpdateActions } from "redux-container/notification-management/AppUpdatesRedux";

export const TooltipContentWrapper = styled(Box)`
  width: 16rem;
  .content-caption {
    margin-bottom: 1rem;
  }
  .content-text {
    line-height: 1;
    color: ${({ theme }) => theme.palette.common.white};
  }
  .order-list-gutter {
    padding: 1rem 1.1rem;
  }
`;

const actionDispatch = (dispatch: any) => {
  return {
    addAppUpdateRequest: (data: any) =>
      dispatch(AppUpdateActions.addAppUpdateRequest(data)),
    setAppUpdatesState: (key: string, value: any) =>
      dispatch(AppUpdateActions.setAppUpdatesState(key, value)),
    fileUploadRequest: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    setUploadFileState: (key: string, value: any) =>
      dispatch(FileUploadActions.setUploadFileState(key, value)),
  };
};

const CreateNotification = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  console.log("location", location);

  const {
    addAppUpdateRequest,
    fileUploadRequest,
    setUploadFileState,
    setAppUpdatesState,
  } = actionDispatch(useDispatch());

  const [appUpdateState, setAppUpdateState] = useState({
    isViewMode: location?.state?.isViewMode,
    saveButtonText: "Publish",
  });
  const [mediaName, setMediaName] = useState("");
  const {
    errorMessage,
    successMessage,
    isSuccess,
    isLoading,
    data,
    error,
    imageDetails,
    isFileLoading,
    isUploadSuccess,
    shouldFlashMessageOpen,
    confirmDialogOpen,
    isDialogOpen,
  } = useSelector((state: any) => ({
    successMessage: state?.appUpdate?.message,
    errorMessage: state?.appUpdate?.error,
    confirmDialogOpen: state?.appUpdate?.confirmDialogOpen,
    isSuccess: state?.appUpdate?.isSuccess,
    isDialogOpen: state?.appUpdate?.isDialogOpen,
    isLoading: state?.appUpdate?.isLoading,
    shouldFlashMessageOpen: state?.fileUpload?.shouldFlashMessageOpen,
    data: state?.fileUpload?.data,
    error: state?.fileUpload?.error,
    isUploadSuccess: state?.fileUpload?.isSuccess,
    imageDetails: state?.fileUpload?.imageDetails,
    isFileLoading: state?.fileUpload?.isLoading,
  }));

  const handleCancelClick = () => {
    navigate(-1);
  };

  const onSubmitHandler = (values: any) => {
    addAppUpdateRequest({
      ...values,
      foregroundImage: values?.foregroundImage?.value?.url
        ? values?.foregroundImage
        : null,
      backgroundImage: values?.backgroundImage?.value?.url
        ? values?.backgroundImage
        : null,
    });
  };

  //handle change for Image Uploader with API call.
  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    const name = event.target.name;
    console.log("name", name);
    setMediaName(name);

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (e: any) => {
        data.binary = e.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);
      setFieldValue(`${name}.value.url`, imageDetails?.url);
      setFieldValue(`${name}.value.mediaType`, file.type);
      setFieldValue(`${name}.value.size`, file.size);
      setFieldValue(`${name}.name`, file.name);
      setFieldValue(`${name}.key`, file.name);
      setUploadFileState("shouldFlashMessageOpen", true);
    }
  };
  useEffect(() => {
    if (imageDetails) {
      setFieldValue(`${mediaName}.value.url`, imageDetails?.url);
      setUploadFileState("imageDetails", null);
    }
  }, [imageDetails]);

  const handleImageDelete = () => {
    setFieldValue(`${mediaName}.value.url`, "");
  };

  const handleCloseFlashMessageDialog = () => {
    setAppUpdatesState("error", null);
    setAppUpdatesState("confirmDialogOpen", false);
    setAppUpdatesState("isDialogOpen", false);
    if (isSuccess) {
      navigate(RouteConstants.appUpdates);
    }
  };

  //using "useFormik" hook for state management,Validation and Submit User data(Post APi call) .
  //for validation "Yup" package is used .
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    initialValues:
      location.state.appUpdateData !== null
        ? location.state.appUpdateData
        : appUpdateInitialValues,
    validationSchema: appUpdatesValidationSchema,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
  });
  console.log("values", values);
  console.log("errors", errors);
  console.log("touched", touched);

  return (
    <Fragment>
      <BreadCrumbs
        routes={appUpdateBreadcrumbRoutes(location.state?.isViewMode)}
      />
      <PageHeading title="Notification Management" />
      <Container>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Stack mt={"1rem"}>
            <Stack>
              <IconButton edge={"start"} onClick={() => navigate(-1)}>
                <ChevronLeftIcon color="inherit" />
              </IconButton>
              <Typography
                color={"secondary.dark"}
                variant="h5"
                marginRight={"1.5rem"}
              >
                {location?.state?.appUpdateData !== null
                  ? "App Update Title"
                  : "Create New"}
              </Typography>
              <InfoComponent
                infoContent={
                  <TooltipContentWrapper>
                    <Typography
                      variant="caption"
                      mb={"1rem"}
                      className={"content-caption content-text"}
                    >
                      App Updates will appear on the app or Web.
                    </Typography>
                  </TooltipContentWrapper>
                }
              />
            </Stack>
            <Stack direction={"row"} columnGap={2}>
              <Collapse in={!appUpdateState.isViewMode}>
                <ButtonComponent
                  type="reset"
                  color="inherit"
                  onClick={handleCancelClick}
                >
                  Cancel
                </ButtonComponent>
              </Collapse>
              <Collapse
                in={!appUpdateState.isViewMode}
                orientation={"horizontal"}
              >
                <LoadingButtonComponent
                  type="submit"
                  loading={isLoading}
                  disabled={
                    !isValid || !dirty
                    //  ||
                    // values.shouldNotifyAndroid ||
                    // values.shouldNotifyIOS
                  }
                >
                  {appUpdateState.saveButtonText}
                </LoadingButtonComponent>
              </Collapse>
            </Stack>
          </Stack>
          <FormFieldContainer>
            <Grid
              container
              alignItems={"flex-end"}
              justifyContent={"space-between"}
              sx={{
                alignItems: "baseline",
              }}
            >
              <Grid item xs={7.5} sm={7.5} lg={7.5} xl={7.5}>
                <Grid container spacing={4}>
                  <Grid item sm={6}>
                    <FormControlLabel
                      disabled={appUpdateState.isViewMode}
                      control={
                        <Checkbox
                          sx={
                            appUpdateState.isViewMode && {
                              color: "rgba(196, 189, 189, 0.38)",
                            }
                          }
                          disabled={appUpdateState.isViewMode}
                          checked={values.shouldNotifyAndroid}
                          onChange={(event: any) => {
                            setFieldValue(
                              "shouldNotifyAndroid",
                              event.target.checked
                            );
                          }}
                        />
                      }
                      label="Notify Android"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <FormControlLabel
                      disabled={appUpdateState.isViewMode}
                      control={
                        <Checkbox
                          sx={
                            appUpdateState.isViewMode && {
                              color: "rgba(196, 189, 189, 0.38)",
                            }
                          }
                          disabled={appUpdateState.isViewMode}
                          checked={values.shouldNotifyIOS}
                          onChange={(event: any) => {
                            setFieldValue(
                              "shouldNotifyIOS",
                              event.target.checked
                            );
                          }}
                        />
                      }
                      label="Notify IOS"
                    />
                  </Grid>
                  <Grid item lg={12} xl={12}>
                    <TextFieldComponent
                      name={`title`}
                      placeholder="Enter"
                      label="Notification Title *"
                      value={values?.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 20,
                      }}
                      InputProps={{
                        readOnly: appUpdateState.isViewMode,
                      }}
                      fieldhelpertext={
                        getIn(touched, "title") && getIn(errors, "title")
                      }
                      error={Boolean(
                        getIn(touched, "title") && getIn(errors, "title")
                      )}
                      disabled={appUpdateState.isViewMode}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldComponent
                      multiline
                      rows={3}
                      name={`description`}
                      placeholder="Enter"
                      label="Description *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      error={Boolean(
                        getIn(touched, "description") &&
                          getIn(errors, "description")
                      )}
                      fieldhelpertext={
                        getIn(touched, "description") &&
                        getIn(errors, "description")
                      }
                      InputProps={{
                        readOnly: appUpdateState.isViewMode,
                      }}
                      inputProps={{
                        maxLength: 110,
                      }}
                      disabled={appUpdateState.isViewMode}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3.5} sm={3.5} lg={3.5} xl={3.5} rowSpacing={2}>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12} lg={12} xl={12}>
                    <Typography marginBottom={1}>Background Image</Typography>
                    <MediaUploadComponent
                      name={`backgroundImage`}
                      disabled={appUpdateState.isViewMode}
                      loading={
                        mediaName === "backgroundImage" ? isFileLoading : false
                      }
                      onChange={handleFileInputChange}
                      previewimage={values?.backgroundImage?.value?.url}
                      cancelimage={handleImageDelete}
                      fieldhelpertext={
                        getIn(touched, `backgroundImage.value.url`) &&
                        getIn(errors, `backgroundImage.value.url`)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={12} xl={12}>
                    <Typography
                      marginBottom={1}
                      sx={{ fontWeight: "400", fontFamily: "inherit" }}
                    >
                      Banner Image
                    </Typography>
                    <MediaUploadComponent
                      name={`foregroundImage`}
                      disabled={appUpdateState.isViewMode}
                      accept={".jpg,.png,.jpeg"}
                      loading={
                        mediaName === "foregroundImage" ? isFileLoading : false
                      }
                      onChange={handleFileInputChange}
                      previewimage={values?.foregroundImage?.value?.url}
                      cancelimage={handleImageDelete}
                      fieldhelpertext={
                        getIn(touched, `foregroundImage.value.url`) &&
                        getIn(errors, `foregroundImage.value.url`)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormFieldContainer>

          {isDialogOpen ? (
            <FlashMessageDialog
              shouldOpen={isDialogOpen}
              content={successMessage ? successMessage : errorMessage}
              isSuccess={isSuccess}
              cancelHandler={handleCloseFlashMessageDialog}
            />
          ) : null}
        </Box>
      </Container>
    </Fragment>
  );
};

export default CreateNotification;
