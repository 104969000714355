import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { FormFieldContainer, TextFieldComponent } from "components/ui-elements";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import PageHeading from "../../../components/page-heading/PageHeading";
import { RouteConstants } from "../../../routes/RouteConstants";
import { SelectChangeEvent } from "@mui/material/Select";
import ResourceDashboardActions from "../../../redux-container/resource-management/ResourceDashboardRedux";
import FaqListActions from "../../../redux-container/resource-management/FaqListRedux";
import CategoryActions from "../../../redux-container/resource-management/CategoryRedux";
import FormControl from "@mui/material/FormControl";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { StatusTypeEnumInsight } from "enumerations/StatusTypeEnum";

var Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

const actionDispatch = (dispatch: any) => {
  return {
    getResourceDashboard: (params: any) =>
      dispatch(ResourceDashboardActions.getResourceDashboardRequest(params)),
    getCategories: () => dispatch(CategoryActions.getCategoriesRequest()),
    setResourceDashboardState: (key: any, data: any) =>
      dispatch(ResourceDashboardActions.setResourceDashboardState(key, data)),
    getFaqs: (params: any) =>
      dispatch(FaqListActions.getFaqListRequest(params)),
  };
};

const ResourceManagementDashboard = () => {
  const {
    getResourceDashboard,
    getCategories,
    getFaqs,
    setResourceDashboardState,
  } = actionDispatch(useDispatch());

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const navigate = useNavigate();

  const dashboard = useSelector(
    (state: any) => state?.resourceDashboard?.dashboard
  );
  const isGraphDataFetched = useSelector(
    (state: any) => state?.resourceDashboard?.isGraphDataFetched
  );
  const categories = useSelector((state: any) => state?.faqList?.faqs); // fetching faq api call for categories here, because we need to show Categories for FAQ dashboard.
  const isLoading = useSelector(
    (state: any) => state.resourceDashboard?.isLoading
  );
  const isErrorOccured = useSelector(
    (state: any) => state.resourceDashboard?.error
  );

  const breadcrumbRoutes: any = [
    {
      name: "Resource Management",
      route: RouteConstants.resourceManagementDashboard,
    },
  ];

  useEffect(() => {
    loadData({});
  }, []);

  useEffect(() => {
    const params = {
      categoryId: Number(selectedCategory),
    };
    loadData(params);
  }, [selectedCategory]);

  useEffect(() => {
    if (isGraphDataFetched) {
      setResourceDashboardState("isGraphDataFetched", false);
      configureInsightsChart();
      configureGeneralFAQChart();
    }
  }, [isGraphDataFetched]);

  const loadData = (params: any) => {
    getResourceDashboard(params);
    // getCategories();
    getFaqs({});
  };

  const configureInsightsChart = () => {
    Highcharts.chart("insights-container", {
      chart: {
        backgroundColor: "#f7f6ff",
        plotBackgroundColor: "#f7f6ff",
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          slicedOffset: 0,
          dataLabels: {
            enabled: true,
            distance: -50,
            y: -5,
            style: {
              fontWeight: "bold",
              color: "black",
              fontSize: "14px",
            },
            filter: {
              property: "name",
              operator: "===",
              value: "Insights",
            },
          },
          borderWidth: 0,
        },
        series: {
          animation: false,
        },
      },
      series: [
        {
          type: "pie",
          name: "Total",
          innerSize: "80%",
          data: [
            {
              name: "Active Insights",
              y: dashboard?.insightOverview?.activeInsigntCount,
              color: "#3cc012",
              sliced: true,
            },
            {
              name: "Inactive Insights",
              y: dashboard?.insightOverview?.inactiveInsigntCount,
              color: "#f37c7c",
              sliced: true,
            },
          ],
        },
      ],
    });
  };

  const configureGeneralFAQChart = () => {
    Highcharts.chart("general-faq-container", {
      chart: {
        backgroundColor: "#f7f6ff",
        plotBackgroundColor: "#f7f6ff",
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          slicedOffset: 0,
          dataLabels: {
            enabled: true,
            distance: -50,
            y: -5,
            style: {
              fontWeight: "bold",
              color: "black",
              fontSize: "14px",
            },
            filter: {
              property: "name",
              operator: "===",
              value: "FAQs",
            },
          },
          borderWidth: 0,
        },
        series: {
          animation: false,
        },
      },
      series: [
        {
          type: "pie",
          name: "Total",
          innerSize: "80%",
          data: [
            {
              name: "Active FAQs",
              y: dashboard?.generalFaqOverview?.activeGeneralFaqsCount,
              color: "#3cc012",
              sliced: true,
            },
            {
              name: "Inactive FAQs",
              y: dashboard?.generalFaqOverview?.inactiveGeneralFaqsCount,
              color: "#f37c7c",
              sliced: true,
            },
          ],
        },
      ],
    });
  };

  const filterByInsightStatus = (statusType: StatusTypeEnumInsight) => {
    const filterData = {
      statusType: statusType,
      isNavigationFromSidemenu: true
    };
    navigate(`${RouteConstants.insightList}`, {
      state: filterData,
    });
  };

  const filterByFAQStatus = (statusType: any) => {
    // const filterData = {
    //   statusType: statusType,
    // };

    if (selectedCategory === "") {
      alert("Please select Category");
    } else {
      navigate(`${RouteConstants.faqCreation}`, {
        state: {
          isEditField: true,
          categoryId: Number(selectedCategory),
          statusType: statusType,
          isNavigationFromSidemenu: true
        },
      });
    }
  };

  const navigatePublishedFaqList = () => {
    if (selectedCategory === "") {
      alert("Please select Category");
    } else {
      navigate(`${RouteConstants.faqCreation}`, {
        state: {
          isEditField: true,
          categoryId: Number(selectedCategory),
          isNavigationFromSidemenu: true
        },
      });
    }
  };

  const clearFilters = () => {
    setSelectedCategory("");
  };

  const handleGeneralFAQCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value as string);
  };

  const navigateToFAQList = () => {
    navigate(RouteConstants.faqList,{ state: { isNavigationFromSidemenu: true } });
  };

  const navigateToInsightList = () => {
    navigate(RouteConstants.insightList,{ state: { isNavigationFromSidemenu: true } });
  };

  return (
    <Fragment>
      <PageHeading title="Resource Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <Container>
        <div className="heading-action-holder">
          <h4 className="heading">Dashboard</h4>

          <div>
            <Button
              variant="contained"
              onClick={navigateToInsightList}
              // autoFocus
              disableElevation
              endIcon={<ChevronRightRoundedIcon />}
              className="btn btn-dark margin-right-15px"
            >
              Go to Insights
            </Button>

            <Button
              variant="contained"
              onClick={navigateToFAQList}
              // autoFocus
              disableElevation
              endIcon={<ChevronRightRoundedIcon />}
              className="btn btn-dark"
            >
              Go to FAQs
            </Button>
          </div>
        </div>

        <h5 className="graph-container-heading">Insights</h5>
        <FormFieldContainer>
          <section className="graph-data-container">
            <h4 className="graph-heading">Insights Overview</h4>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div id="insights-container" className="graph-holder" />
              </Grid>

              <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    className="stat-card margin-bottom-20 total-stats-holder"
                    onClick={navigateToInsightList}
                  >
                    <span className="stat-card-total-value">
                      {dashboard?.insightOverview?.totalInsigntCount}
                    </span>
                    <span className="stat-card-total-heading">
                      Total Published Insights
                    </span>
                    <span className="individual-stat-card-icon">
                      <ChevronRightRoundedIcon />
                    </span>
                  </div>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div
                      className="stat-card individual-stats-holder"
                      onClick={() =>
                        filterByInsightStatus(StatusTypeEnumInsight.Published)
                      }
                    >
                      <span className="individual-stat-value-text green-stat-value">
                        {dashboard?.insightOverview?.activeInsigntCount}
                      </span>
                      <span className="individual-stat-card-heading">
                        Active Insights
                      </span>
                      <span className="individual-stat-card-icon">
                        <ChevronRightRoundedIcon />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div
                      className="stat-card individual-stats-holder"
                      onClick={() =>
                        filterByInsightStatus(StatusTypeEnumInsight.Inactive)
                      }
                    >
                      <span className="individual-stat-value-text red-stat-value">
                        {dashboard?.insightOverview?.inactiveInsigntCount}
                      </span>
                      <span className="individual-stat-card-heading">
                        Inactive Insights
                      </span>
                      <span className="individual-stat-card-icon">
                        <ChevronRightRoundedIcon />
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </section>
        </FormFieldContainer>

        <h5 className="graph-container-heading margin-top-30px">FAQs</h5>
        <FormFieldContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <FormControl className="filter-dropdown margin-bottom-20px margin-top-20">
                <TextFieldComponent
                  required
                  select
                  value={selectedCategory}
                  label="Category"
                  onChange={handleGeneralFAQCategoryChange}
                >
                  {categories?.map((category: any) => {
                    return (
                      <MenuItem value={category.id} key={category.id}>
                        {category?.name}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            {selectedCategory !== "" ? (
              <Grid
                item
                md={0.5}
                lg={0.5}
                xl={0.5}
                className="filter-heading-holder"
                style={{ position: "relative" }}
              >
                <span
                  onClick={clearFilters}
                  className="clear-filter"
                  style={{ position: "absolute", top: 52 }}
                >
                  Clear
                </span>
              </Grid>
            ) : null}
          </Grid>

          <section className="graph-data-container">
            <h4 className="graph-heading">FAQs - Overview</h4>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div id="general-faq-container" className="graph-holder" />
              </Grid>

              <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    className="stat-card margin-bottom-20 total-stats-holder"
                    onClick={navigatePublishedFaqList}
                  >
                    <span className="stat-card-total-value">
                      {dashboard?.generalFaqOverview?.totalGeneralFaqsCount}
                    </span>
                    <span className="stat-card-total-heading">
                      Total Published FAQs
                    </span>
                    <span className="individual-stat-card-icon">
                      <ChevronRightRoundedIcon />
                    </span>
                  </div>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div
                      className="stat-card individual-stats-holder"
                      onClick={() => filterByFAQStatus("Published")}
                    >
                      <span className="individual-stat-value-text green-stat-value">
                        {dashboard?.generalFaqOverview?.activeGeneralFaqsCount}
                      </span>
                      <span className="individual-stat-card-heading">
                        Published FAQs
                      </span>
                      <span className="individual-stat-card-icon">
                        <ChevronRightRoundedIcon />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div
                      className="stat-card individual-stats-holder"
                      onClick={() => filterByFAQStatus("Inactive")}
                    >
                      <span className="individual-stat-value-text red-stat-value">
                        {
                          dashboard?.generalFaqOverview
                            ?.inactiveGeneralFaqsCount
                        }
                      </span>
                      <span className="individual-stat-card-heading">
                        Inactive FAQs
                      </span>
                      <span className="individual-stat-card-icon">
                        <ChevronRightRoundedIcon />
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </section>
        </FormFieldContainer>
      </Container>
    </Fragment>
  );
};

export default ResourceManagementDashboard;
