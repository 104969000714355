import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import MediaAssets from "assets";
import {
  ButtonComponent,
  FormFieldContainer,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { FieldArray, getIn, useFormikContext } from "formik";
import React, { Fragment, useState } from "react";
import { IInitinalValueProjectContent } from "../UpCommingInitialValues";
import QuillEditor from "components/quill-editor/QuillEditor";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CurrentInfraStroyMedia from "../../opportunity-doc/CurrentInfraStroyMedia";

const FormFieldContainerWrapper = styled(FormFieldContainer)`
  padding: 2rem;
  margin: 2rem;
`;

function CurrentInfraStory(props: any) {
  const { isViewMode } = props;

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    handleBlur,
    touched,
    errors,
  } = useFormikContext<IInitinalValueProjectContent>();
  console.log("values", values);

  const [currentlyOpenedIndex, setCurrentlyOpenedIndex] = useState(999);

  const handleTextEditor = (
    name: string,
    newValue: string,
    options: any,
    { setFieldValue, setFieldError }: any
  ) => {
    // if (options?.isError) {
    //   setFieldError(name, "Maximum character exceed");
    // } else {
    setFieldValue(name, newValue);
    // }
  };

  return (
    <div>
      <Divider />

      <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
        <Typography>Current Infra Story</Typography>

        <FormControlLabel
          className="switch-form-label"
          name={"opportunityDoc.isCurrentInfraStoryActive"}
          checked={values?.opportunityDoc?.isCurrentInfraStoryActive}
          onChange={handleChange}
          control={<SwitchComponent />}
          label=""
          labelPlacement="start"
          disabled={isViewMode}
        />
        <IconButton>
          <Tooltip title={"Current Infra Story"} placement="top">
            <Avatar
              style={{
                width: "0.9rem",
                height: "0.9rem",
                cursor: "pointer",
              }}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
        <Typography>Current Infra Story for Website</Typography>

        <FormControlLabel
          className="switch-form-label"
          name={"opportunityDoc.isCurrentInfraStoryActiveForWeb"}
          checked={values?.opportunityDoc?.isCurrentInfraStoryActiveForWeb}
          onChange={handleChange}
          control={<SwitchComponent />}
          label=""
          labelPlacement="start"
          disabled={isViewMode}
        />
        <IconButton>
          <Tooltip title={"Current Infra Story"} placement="top">
            <Avatar
              style={{
                width: "0.9rem",
                height: "0.9rem",
                cursor: "pointer",
              }}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
      </Stack>
      <Grid container>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} p={4} sx={{ padding: 0 }}>
          <TextFieldComponent
            required
            type="text"
            placeholder="Enter"
            label="Section Heading"
            name={`opportunityDoc.currentInfraStory.heading`}
            value={values?.opportunityDoc?.currentInfraStory?.heading}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{ maxLength: 40 }}
            fieldhelpertext={
              getIn(touched, "opportunityDoc.currentInfraStory.heading") &&
              getIn(errors, "opportunityDoc.currentInfraStory.heading")
            }
            error={Boolean(
              getIn(errors, "opportunityDoc.currentInfraStory.heading") &&
                getIn(touched, "opportunityDoc.currentInfraStory.heading")
            )}
            disabled={isViewMode}
          />
        </Grid>
      </Grid>
      <FieldArray
        name="opportunityDoc.currentInfraStory.stories"
        render={(arrayHelpers) => (
          <div>
            {values?.opportunityDoc?.currentInfraStory?.stories?.length > 0 &&
              values?.opportunityDoc?.currentInfraStory?.stories?.map(
                (element: any, index: number) => {
                  return (
                    <Fragment>
                      <FormFieldContainerWrapper sx={{ margin: "2rem 0" }}>
                        <Stack justifyContent={"flex-end"}>
                          {values?.opportunityDoc?.currentInfraStory?.stories
                            ?.length > 3 && (
                            <IconButton
                              disabled={isViewMode}
                              aria-label="delete"
                              data-testid="button-delete"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            >
                              <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                            </IconButton>
                          )}
                        </Stack>
                        <Grid
                          container
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Box mr={"20rem"}>
                              <TextFieldComponent
                                required
                                type="text"
                                placeholder="Enter"
                                label="Image Title"
                                name={`opportunityDoc.currentInfraStory.stories.${index}.title`}
                                value={
                                  values?.opportunityDoc?.currentInfraStory
                                    ?.stories[index]?.title
                                }
                                onChange={handleChange}
                                inputProps={{ maxLength: 30 }}
                                onBlur={handleBlur}
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `opportunityDoc.currentInfraStory.stories.${index}.title`
                                  ) &&
                                  getIn(
                                    errors,
                                    `opportunityDoc.currentInfraStory.stories.${index}.title`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    errors,
                                    `opportunityDoc.currentInfraStory.stories.${index}.title`
                                  ) &&
                                    getIn(
                                      touched,
                                      `opportunityDoc.currentInfraStory.stories.${index}.title`
                                    )
                                )}
                                disabled={isViewMode}
                              />
                            </Box>
                            <Typography>Description *</Typography>
                            <QuillEditor
                              placeholder="Enter description"
                              // className={styles["QuillEditor"]}
                              name={`opportunityDoc.currentInfraStory.stories.${index}.description`}
                              value={
                                values?.opportunityDoc?.currentInfraStory
                                  ?.stories[index]?.description
                              }
                              onChange={(
                                name: string,
                                newValue: string,
                                options: any
                              ) =>
                                handleTextEditor(name, newValue, options, {
                                  setFieldValue,
                                  setFieldError,
                                })
                              }
                              // style={{ height: "12rem" }}s
                              inputProps={{ maxLength: 240 }}
                              onBlur={handleBlur}
                              style={{ height: "126px" }}
                              disabled={isViewMode}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={3} lg={4} xl={3}>
                            <CurrentInfraStroyMedia
                              index={index}
                              isViewModeProp={isViewMode}
                              currentlyOpenedIndex={currentlyOpenedIndex}
                              handleCurrentInfraStoryMedia={(
                                imageIndex: any
                              ) => {
                                setCurrentlyOpenedIndex(imageIndex);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormFieldContainerWrapper>

                      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} ml={2}>
                        {values?.opportunityDoc?.currentInfraStory?.stories
                          ?.length -
                          1 ===
                          index &&
                          values?.opportunityDoc?.currentInfraStory?.stories
                            ?.length < 5 && (
                            <ButtonComponent
                              sx={{
                                textDecoration: "underline",
                                marginTop: "0.5rem",
                              }}
                              variant="text"
                              color="inherit"
                              onClick={() => {
                                arrayHelpers.push({
                                  title: "",
                                  description: "",
                                  media: {
                                    name: "",
                                    key: "",
                                    value: {
                                      url: "",
                                      size: 0,
                                      width: 1024,
                                      height: 1024,
                                      mediaType: "image",
                                    },
                                  },
                                });
                              }}
                              disabled={isViewMode}
                            >
                              + Add more Values
                            </ButtonComponent>
                          )}
                      </Grid>
                    </Fragment>
                  );
                }
              )}
          </div>
        )}
      />
      <Divider />
    </div>
  );
}

export default CurrentInfraStory;
