import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { BasicDetailStyleWrapper } from "./BasicDetailStyleWrapper";
import {
  InfoComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import QuillEditor from "components/quill-editor/QuillEditor";
import ReraDetails from "./sections/ReraDetails";
import ContactDetails from "./sections/ContactDetails";
import { getIn, useFormikContext } from "formik";
import {
  IProjectFormInitialValues,
  ProjectFormInitialValues,
} from "../ProjectFormInitialValues";
import CustomAutoComplete from "components/ui-elements/custom-autocomplete/CustomAutoComplete";
import { handleNumberFieldChange, stripHtml } from "utils/ValidationUtils";
import { useLocation } from "react-router-dom";

const BasicDetailsForm = () => {
  const location: any = useLocation();
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleBlur,
    setErrors,
  } = useFormikContext<IProjectFormInitialValues>();

  const { setTouched } = useFormikContext();

  const handleTextEditor = (name: string, newValue: string) => {
    setFieldValue(name, newValue);
  };

  return (
    <BasicDetailStyleWrapper>
      <Box className="section-gutter">
        <Grid container spacing={2} className="grid-container">
          <Grid item lg={6} xl={5.5}>
            <Typography color={"common.black"} className={"section-heading"}>
              Launch Display Name
            </Typography>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                label={"Form Header *"}
                onChange={handleChange}
                name={`basicDetailsForm.formHeader`}
                value={values?.basicDetailsForm?.formHeader}
                sx={location?.state?.isViewMode && { pointerEvents: "none" }}
                inputProps={{
                  maxLength: 50,
                }}
                onBlur={handleBlur}
                error={Boolean(
                  getIn(touched, `basicDetailsForm.formHeader`) &&
                    getIn(errors, `basicDetailsForm.formHeader`)
                )}
                fieldhelpertext={
                  getIn(touched, `basicDetailsForm.formHeader`) &&
                  getIn(errors, `basicDetailsForm.formHeader`)
                }
              />
            </Box>
          </Grid>
          <Grid item lg={6} xl={5.5}>
            <Typography color={"common.black"} className={"section-heading"}>
              Type of Applicant
            </Typography>
            <Box className={"bottom-spacing"}>
              <CustomAutoComplete
                validationPattern={1}
                disabled={location?.state?.isViewMode ? true : false}
                label={"Type of Applicant"}
                name={`basicDetailsForm.typeOfApplicant`}
                fieldValue={values?.basicDetailsForm?.typeOfApplicant}
              />
            </Box>
          </Grid>
          <Grid item lg={6} xl={5.5}>
            <Typography color={"common.black"} className={"section-heading"}>
              Residential Status
            </Typography>
            <Box className={"bottom-spacing"}>
              <CustomAutoComplete
                validationPattern={1}
                disabled={location?.state?.isViewMode ? true : false}
                name={`basicDetailsForm.residentialStatus`}
                label={"Residential Status"}
                fieldValue={values?.basicDetailsForm?.residentialStatus}
              />
            </Box>
          </Grid>
          <Grid item lg={6} xl={5.5}>
            <Stack justifyContent={"flex-start"} alignItems={"baseline"}>
              <FormControlLabel
                className={"section-heading switch-form-label"}
                name={"basicDetailsForm.shouldDisplaySalutationActive"}
                checked={
                  values?.basicDetailsForm?.shouldDisplaySalutationActive
                }
                onChange={(e: any) => {
                  handleChange(e);
                }}
                disabled={location?.state?.isViewMode}
                control={<SwitchComponent />}
                label="Salutation"
                labelPlacement="start"
              />
              <IconButton>
                <InfoComponent />
              </IconButton>
            </Stack>
            <Box className={"bottom-spacing"}>
              <CustomAutoComplete
                validationPattern={1}
                disabled={location?.state?.isViewMode ? true : false}
                label={"Salutation"}
                name={`basicDetailsForm.salutation`}
                fieldValue={values?.basicDetailsForm?.salutation}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box className="section-gutter">
        <Grid container spacing={3} justifyContent={"space-between"}>
          <Grid item lg={4} xl={4}>
            <Typography color={"common.black"} className={"section-heading"}>
              First Name
            </Typography>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                isPaymentDisabledField={true}
                value={ProjectFormInitialValues?.basicDetailsForm?.firstName}
              />
            </Box>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Stack justifyContent={"flex-start"} alignItems={"baseline"}>
              <FormControlLabel
                className={"section-heading switch-form-label"}
                name={"basicDetailsForm.shouldDisplayMiddleName"}
                checked={values?.basicDetailsForm?.shouldDisplayMiddleName}
                onChange={handleChange}
                disabled={location?.state?.isViewMode}
                control={<SwitchComponent />}
                label="Middle Name"
                labelPlacement="start"
              />
              <IconButton>
                <InfoComponent />
              </IconButton>
            </Stack>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                isPaymentDisabledField={true}
                value={ProjectFormInitialValues?.basicDetailsForm?.middleName}
              />
            </Box>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Typography color={"common.black"} className={"section-heading"}>
              Last Name
            </Typography>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                isPaymentDisabledField={true}
                value={ProjectFormInitialValues?.basicDetailsForm?.lastName}
              />
            </Box>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Typography color={"common.black"} className={"section-heading"}>
              HUF / Company Name
            </Typography>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                isPaymentDisabledField={true}
                value={ProjectFormInitialValues?.basicDetailsForm?.companyName}
              />
            </Box>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Typography color={"common.black"} className={"section-heading"}>
              Gender
            </Typography>
            <Box className={"bottom-spacing"}>
              <CustomAutoComplete
                validationPattern={2}
                disabled={location?.state?.isViewMode ? true : false}
                label={"Gender *"}
                name={"basicDetailsForm.gender"}
                fieldValue={values?.basicDetailsForm?.gender}
              />
            </Box>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Stack justifyContent={"flex-start"} alignItems={"baseline"}>
              <FormControlLabel
                className={"section-heading switch-form-label"}
                name={"basicDetailsForm.shouldDisplayDateOfBirth"}
                checked={values?.basicDetailsForm?.shouldDisplayDateOfBirth}
                onChange={handleChange}
                disabled={location?.state?.isViewMode}
                control={<SwitchComponent />}
                label="Date of Birth"
                labelPlacement="start"
              />
              <IconButton>
                <InfoComponent />
              </IconButton>
            </Stack>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                isPaymentDisabledField={true}
                value={ProjectFormInitialValues?.basicDetailsForm?.dateOfBirth}
              />
            </Box>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Typography color={"common.black"} className={"section-heading"}>
              Date of Incorporation (HUF / Company Name)
            </Typography>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                isPaymentDisabledField={true}
                value={
                  ProjectFormInitialValues?.basicDetailsForm
                    ?.dateOfIncorporation
                }
              />
            </Box>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Typography color={"common.black"} className={"section-heading"}>
              Primary Email
            </Typography>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                isPaymentDisabledField={true}
                value={ProjectFormInitialValues?.basicDetailsForm?.primaryEmail}
              />
            </Box>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Typography color={"common.black"} className={"section-heading"}>
              Mobile Number
            </Typography>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                isPaymentDisabledField={true}
                value={ProjectFormInitialValues?.basicDetailsForm?.phoneNumber}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box className="section-gutter">
        <Grid container spacing={3}>
          <Grid item lg={4} xl={4}>
            <Typography color={"common.black"} className={"section-heading"}>
              Number of Plots
            </Typography>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                label={"Maximum 10 Plots *"}
                placeholder={"Enter Maximum of 10 plots"}
                name={"basicDetailsForm.numberOfPlots"}
                value={values?.basicDetailsForm?.numberOfPlots}
                onChange={(e: any) => {
                  if (
                    (e.target.value != 0 &&
                      e.target.value < 11 &&
                      !e.target.value.includes(".")) ||
                    e.target.value === ""
                  ) {
                    handleNumberFieldChange(setFieldValue)(e);
                  }
                }}
                sx={location?.state?.isViewMode && { pointerEvents: "none" }}
                onBlur={handleBlur}
                error={Boolean(
                  errors.basicDetailsForm?.numberOfPlots &&
                    touched.basicDetailsForm?.numberOfPlots
                )}
                fieldhelpertext={
                  errors.basicDetailsForm?.numberOfPlots &&
                  touched.basicDetailsForm?.numberOfPlots &&
                  errors.basicDetailsForm?.numberOfPlots
                }
              />
            </Box>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Typography color={"common.black"} className={"section-heading"}>
              Booking Amount
            </Typography>
            <Box className={"bottom-spacing"}>
              <TextFieldComponent
                isPaymentDisabledField={true}
                value={"Booking Amount (Static Text) *"}
              />
            </Box>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Typography color={"common.black"} className={"section-heading"}>
              Choice of Plots
            </Typography>
            <Box className={"bottom-spacing"}>
              <CustomAutoComplete
                validationPattern={1}
                disabled={location?.state?.isViewMode ? true : false}
                name={`basicDetailsForm.choiceOfPlots`}
                label={"Choice of Plots"}
                fieldValue={values?.basicDetailsForm?.choiceOfPlots}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box className="section-gutter">
        <Typography color={"common.black"} className={"section-heading"}>
          Terms and Conditions *
        </Typography>
        <Box className={"bottom-spacing"}>
          <QuillEditor
            placeholder="Enter description"
            onChange={(name: string, newValue: string, options: any) =>
              handleTextEditor(name, newValue)
            }
            inputProps={{
              maxLength: 100000,
            }}
            value={values?.basicDetailsForm?.termsAndConditions}
            style={{
              height: "20rem",
            }}
            // disabled={isReduxViewMode}
            name={`basicDetailsForm.termsAndConditions`}
            onBlur={() => {
              setTouched({
                basicDetailsForm: {
                  ...touched.basicDetailsForm,
                  termsAndConditions: true,
                },
              });
            }}
            fieldhelpertext={
              stripHtml(values?.basicDetailsForm?.termsAndConditions).length ===
                0 &&
              errors?.basicDetailsForm?.termsAndConditions &&
              touched?.basicDetailsForm?.termsAndConditions &&
              "Please Enter Terms and Conditions Description"
            }
            error={Boolean(
              getIn(errors, "basicDetailsForm.termsAndConditions") &&
                getIn(touched, "basicDetailsForm.termsAndConditions")
            )}
            readonly={location?.state?.isViewMode}
          />
        </Box>
      </Box>

      <Divider />

      <Box className="section-gutter">
        <Typography color={"common.black"} className={"section-heading"}>
          Privacy *
        </Typography>
        <Box className={"bottom-spacing"}>
          <QuillEditor
            placeholder="Enter description"
            onChange={(name: string, newValue: string, options: any) =>
              handleTextEditor(name, newValue)
            }
            inputProps={{
              maxLength: 100000,
            }}
            value={values?.basicDetailsForm?.privacyDescription}
            style={{
              height: "20rem",
            }}
            // disabled={isReduxViewMode}
            name={`basicDetailsForm.privacyDescription`}
            onBlur={() => {
              setTouched({
                basicDetailsForm: {
                  ...touched.basicDetailsForm,
                  privacyDescription: true,
                },
              });
            }}
            fieldhelpertext={
              stripHtml(values?.basicDetailsForm?.privacyDescription).length ===
                0 &&
              errors?.basicDetailsForm?.privacyDescription &&
              touched?.basicDetailsForm?.privacyDescription &&
              "Please Enter Privacy Description"
            }
            error={Boolean(
              getIn(errors, "basicDetailsForm.privacyDescription") &&
                getIn(touched, "basicDetailsForm.privacyDescription")
            )}
            readonly={location?.state?.isViewMode}
          />
        </Box>
      </Box>

      <Divider />

      <Box className="section-gutter">
        <Typography color={"common.black"} className={"section-heading"}>
          Conditions *
        </Typography>
        <Box className={"bottom-spacing"}>
          <QuillEditor
            placeholder="Enter description"
            onChange={(name: string, newValue: string, options: any) =>
              handleTextEditor(name, newValue)
            }
            inputProps={{
              maxLength: 100000,
            }}
            value={values?.basicDetailsForm?.conditionDescription}
            style={{
              height: "20rem",
            }}
            // disabled={isReduxViewMode}
            name={`basicDetailsForm.conditionDescription`}
            onBlur={() => {
              setTouched({
                basicDetailsForm: {
                  ...touched.basicDetailsForm,
                  conditionDescription: true,
                },
              });
            }}
            fieldhelpertext={
              stripHtml(values?.basicDetailsForm?.conditionDescription)
                .length === 0 &&
              errors?.basicDetailsForm?.conditionDescription &&
              touched?.basicDetailsForm?.conditionDescription &&
              "Please Enter Conditions Description"
            }
            error={Boolean(
              getIn(errors, "basicDetailsForm.conditionDescription") &&
                getIn(touched, "basicDetailsForm.conditionDescription")
            )}
            readonly={location?.state?.isViewMode}
          />
        </Box>
      </Box>

      <Divider />

      <Box className="section-gutter">
        <Typography color={"common.black"} className={"section-heading"}>
          Return Policy *
        </Typography>
        <Box className={"bottom-spacing"}>
          <QuillEditor
            placeholder="Enter description"
            onChange={(name: string, newValue: string, options: any) =>
              handleTextEditor(name, newValue)
            }
            inputProps={{
              maxLength: 100000,
            }}
            value={values?.basicDetailsForm?.returnPolicyDescription}
            style={{
              height: "20rem",
            }}
            // disabled={isReduxViewMode}
            name={`basicDetailsForm.returnPolicyDescription`}
            onBlur={() => {
              setTouched({
                basicDetailsForm: {
                  ...touched.basicDetailsForm,
                  returnPolicyDescription: true,
                },
              });
            }}
            fieldhelpertext={
              stripHtml(values?.basicDetailsForm?.returnPolicyDescription)
                .length === 0 &&
              errors?.basicDetailsForm?.returnPolicyDescription &&
              touched?.basicDetailsForm?.returnPolicyDescription &&
              "Please Enter Return Policy Description"
            }
            error={Boolean(
              getIn(errors, "basicDetailsForm.returnPolicyDescription") &&
                getIn(touched, "basicDetailsForm.returnPolicyDescription")
            )}
            readonly={location?.state?.isViewMode}
          />
        </Box>
      </Box>

      <Divider />
      <ReraDetails />
      <Divider />
      <ContactDetails />
    </BasicDetailStyleWrapper>
  );
};

export default BasicDetailsForm;
