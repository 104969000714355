import { InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import QuillEditor from "components/quill-editor/QuillEditor";
import { SwitchComponent, TextFieldComponent } from "components/ui-elements";
import { Form, Formik, getIn, useFormikContext } from "formik";
import { Fragment } from "react";
import styles from "./AboutUs.module.css";
import { AboutUsContants } from "../../AboutUsPageConstants";
import { IPage5ProfileInitialValue } from "../../../page-5-profile-IniticalValue";
import MediaAssets from "assets";
import { useSelector } from "react-redux";
import { stripHtml } from "utils/ValidationUtils";

interface IAboutUs {
  sectionHeading: string;
  mediaDescription: any;
}
const AboutUsPage = () => {
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    handleBlur,
    isValid,
    errors,
    touched,
  } = useFormikContext<IPage5ProfileInitialValue>();
  console.log("errors", errors);

  const { isReduxViewMode } = useSelector(
    (state: any) => state?.pageManagement
  );

  const handleTextEditor = (
    name: string,
    newValue: string,
    options: any,
    { setFieldValue, setFieldError }: any
  ) => {
    // if (options?.isError) {
    //   setFieldError(name, "Maximum character exceed");
    // } else {
    setFieldValue(name, newValue);
    // }
  };
  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <div className={styles["accordian-global-padding"]}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={styles["priority-holder"]}
            >
              <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
                <Typography>About Hoabl</Typography>

                <FormControlLabel
                  className="switch-form-label"
                  name={"aboutUs.isAboutHoablActive"}
                  disabled={isReduxViewMode || false}
                  checked={values?.aboutUs?.isAboutHoablActive}
                  onChange={handleChange}
                  control={<SwitchComponent />}
                  label=""
                  labelPlacement="start"
                />
                <IconButton>
                  <Tooltip title={"About Hoabl"} placement="top" arrow>
                    <Avatar
                      className={styles["Avator"]}
                      src={MediaAssets.ic_info}
                      variant="rounded"
                    />
                  </Tooltip>
                </IconButton>
              </Stack>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} mt={3}>
              <TextFieldComponent
                required
                label="Section Heading"
                placeholder="Enter"
                name={`aboutUs.aboutHoabl.sectionHeading`}
                disabled={isReduxViewMode || false}
                inputProps={{
                  maxLength: AboutUsContants.sectionHeadingCharacterLimit,
                }}
                value={values?.aboutUs?.aboutHoabl?.sectionHeading}
                onChange={handleChange}
                onBlur={handleBlur}
                fieldhelpertext={
                  getIn(touched, "aboutUs.aboutHoabl.sectionHeading") &&
                  getIn(errors, "aboutUs.aboutHoabl.sectionHeading")
                }
                error={Boolean(
                  getIn(errors, "aboutUs.aboutHoabl.sectionHeading") &&
                    getIn(touched, "aboutUs.aboutHoabl.sectionHeading")
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Stack
                justifyContent={"flex-start"}
                className={styles["Upload-heading"]}
              >
                <Typography>Description *</Typography>
              </Stack>
              <QuillEditor
                placeholder="Enter description"
                className={styles["QuillEditor"]}
                name={`aboutUs.aboutHoabl.description`}
                onChange={(name: string, newValue: string, options: any) =>
                  handleTextEditor(name, newValue, options, {
                    setFieldValue,
                    setFieldError,
                  })
                }
                inputProps={{
                  maxLength: AboutUsContants.mediaDescriptionCharacterLimit,
                }}
                onBlur={handleBlur}
                value={values?.aboutUs?.aboutHoabl?.description}
                style={{ height: "9rem" }}
                disabled={isReduxViewMode}
                fieldhelpertext={
                  stripHtml(values?.aboutUs?.aboutHoabl?.description)
                    ?.length === 0 && errors?.aboutUs?.aboutHoabl?.description
                }
              />  
            </Grid>
          </Grid>
        </div>

        {/* <Divider className={styles["accordian-global-padding"]} />

        <div className={styles["accordian-global-padding"]}>
          <Grid className={styles["button-position"]} mb={2}>
            <Button variant="contained" type="submit" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </div> */}
      </Form>
    </Fragment>
  );
};

export default AboutUsPage;
