import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { call, put } from "redux-saga/effects";
import FaqListActions from "../../redux-container/resource-management/FaqListRedux";

export function* getFaqList(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getFaqList, action.params);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(FaqListActions.getFaqListSuccess(response.data));
    } else {
      yield put(FaqListActions.getFaqListFailure(response.data));
    }
  } catch (error) {
    yield put(FaqListActions.getFaqListFailure(error));
  }
}

export function* deleteFaqs(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteFaqs, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(FaqListActions.deleteFaqsSuccess(response.data));
    } else {
      yield put(FaqListActions.deleteFaqsFailure(response.data));
    }
  } catch (error) {
    yield put(FaqListActions.deleteFaqsFailure(error));
  }
}

export function* changeFaqsStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.changeFaqsStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(FaqListActions.changeFaqsStatusSuccess(response.data));
    } else {
      yield put(FaqListActions.changeFaqsStatusFailure(response.data));
    }
  } catch (error) {
    yield put(FaqListActions.changeFaqsStatusFailure(error));
  }
}

export function* changeFaqPriority(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.changeFaqPriority, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(FaqListActions.changeFaqPrioritySuccess(response.data));
    } else {
      yield put(FaqListActions.changeFaqPriorityFailure(response.data));
    }
  } catch (error) {
    yield put(FaqListActions.changeFaqPriorityFailure(error));
  }
}
