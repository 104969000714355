import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { crmProjectUpdatesAction } from "redux-container/project-management/crm-updates/CrmUpdates";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

export function* getCrmFetchDetails(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getProjectCrmFetchUpdates, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        crmProjectUpdatesAction.getCrmProjectUpdatesSuccess(response.data)
      );
    } else {
      yield put(
        crmProjectUpdatesAction.getCrmProjectUpdatesFailure(response.data)
      );
    }
  } catch (error) {
    yield put(crmProjectUpdatesAction.getCrmProjectUpdatesFailure(error));
  }
}
