import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import {
  ButtonComponent,
  FormFieldContainer,
  LoadingButtonComponent,
  MediaUploadComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Form, Formik, getIn } from "formik";
import { NewInvestmentConstants } from "./NewInvestmentConstants";
import MediaAssets from "assets";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import ProjectListDialog from "../project-list-dialog/ProjectListDialog";
import styles from "./NewInvestmentPage.module.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PageManagentAction from "redux-container/page-management/PageManagementRedux";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import ProjectListActions from "redux-container/project-management/ProjectListRedux";
import Loader from "components/loader/Loader";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import ProjectCollectionListAction from "redux-container/project-management/project-collection/ProjectCollectionListRedux";
import DelayComponent from "./DelayedComponent";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import ImageUploaderForInvestments from "./NewInvestmentImageUploader";
import { validationSchema } from "./InvestValidation";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    addPageManagementData: (params: any) =>
      dispatch(PageManagentAction.addPageManagementDataRequest(params)),
    getPageManagementData: (params: any) =>
      dispatch(PageManagentAction.getPageManagementDataRequest(params)),
    updatePageManagentData: (params: any) =>
      dispatch(PageManagentAction.updatePageManagementDataRequest(params)),
    fileUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    deleteFile: (data: any) =>
      dispatch(FileUploadActions.fileDeleteRequest(data)),
    getProjectList: (params: any) =>
      dispatch(ProjectListActions.projectListRequest(params)),
    getProjectCollectionList: (params: any) =>
      dispatch(
        ProjectCollectionListAction.getProjectCollectionListRequest(params)
      ),
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const NewInvestmentPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showProjectListDialog, setshowProjectListDailog] = useState(false);
  const {
    addPageManagementData,
    getPageManagementData,
    updatePageManagentData,
    fileUpload,
    deleteFile,
    getProjectList,
    getProjectCollectionList,
    rolesAndModulesRequest,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());
  const pageData: any = useSelector(
    (state: any) => state?.pageManagement?.pageManagementData?.page
  );
  const [mappedProjects, setMappedProjects] = useState<any>([]);
  const [priorityCount, setPriorityCount] = useState(0);
  const projectContentList = useSelector(
    (state: any) => state.projectList?.data?.data
  );
  const [mappedProjectContentIds, setMappedProjectContentIds] = useState<any>();
  const [isMapInitialProjectList, setIsMapInitialProjectList] = useState(false);
  const [initialSelectedIds, setInitialSelectedIds] = useState<any>([]);
  const [isMappedListUpdated, setIsMappedListUpdated] = useState(false);
  const [projectListCount, setProjectListCount] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const message = useSelector((state: any) => state?.pageManagement?.message);
  const error = useSelector((state: any) => state?.pageManagement?.error);
  const isSuccess = useSelector(
    (state: any) => state?.pageManagement?.isSuccess
  );
  const [clear, setClear] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const projectCollectionList = useSelector(
    (state: any) => state.projectCollectionList.projectCollection
  );
  const [getListLoading, setGetListLoading] = useState(false);
  const navigate = useNavigate();
  const isViewMode = useSelector(
    (state: any) => state?.pageManagement?.isViewMode
  );

  const [collectionOneTotalCountMax, setCollectionOneTotalCountMax] = useState(
    pageData?.collectionOne?.totalProjectContentsToDisplay
  );
  const [collectionTwoTotalCountMax, setCollectionTwoTotalCountMax] = useState(
    pageData?.collectionTwo?.totalProjectContentsToDisplay
  );
  const [collectOneSelect, setCollectOneSelect] = useState(
    pageData?.collectionOneCollectionId
  );
  const [collectTwoSelect, setCollectTwoSelect] = useState(
    pageData?.collectionTwoCollectionId
  );
  const [isSortByPriority, setIsSortByPriority] = useState(
    pageData?.collectionOne?.sortByPriority
      ? pageData?.collectionOne?.sortByPriority
      : false
  );
  const [removeProjectClick, setRemoveProjectClick] = useState(false);
  const [priorityChange, setPriorityChange] = useState(0);
  const [openProjectRemoveDialog, setOpenProjectRemoveDialog] = useState(false);
  const [projectRemoveId, setProjectRemoveId] = useState(null);

  const modules = useSelector((state: any) => state?.rolesAndModules?.module);
  const [previousStates, setPreviousStates] = useState<any>(pageData);
  const [finalValues, setfinalValues] = useState<any>();

  const [modulesAudited, setModulesAudited] = useState<any>(null);
  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  useEffect(() => {
    rolesAndModulesRequest();
    return () => {};
  }, []);

  const initialProjectMaps = () => {
    pageData?.newInvestmentsWithPriority?.map((item: any) => {
      projectContentList?.map((data: any) => {
        if (item.id === data.id) {
          mappedProjects.push(data);
        }
      });
    });
  };

  useEffect(() => {
    if (
      pageData?.newInvestmentsWithPriority?.length > 0 &&
      mappedProjects?.length === 0 &&
      removeProjectClick === false
    ) {
      initialProjectMaps();
      setProjectListCount(projectListCount + 1);
      setIsMapInitialProjectList(false);
    }
    if (isMapInitialProjectList === true && projectListCount === 0) {
      setIsMapInitialProjectList(false);
    }
  }, [
    isMapInitialProjectList,
    mappedProjects,
    pageData?.newInvestmentsWithPriority,
  ]);

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    const data = {
      pageType: "5002",
    };
    getPageManagementData(data);
    getProjectList({
      status: "1001",
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    });
    getProjectCollectionList({
      status: "1001",
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    });
    setMappedProjectContentIds(
      mappedProjects?.map((project: any, index: any) => {
        return { id: project.id, priority: index + 1 };
      })
    );
  }, [clear]);

  useEffect(() => {
    projectCollectionList?.map((item: any) => {
      if (
        item?.id == collectOneSelect ||
        item?.id == pageData?.collectionOneCollectionId
      ) {
        setCollectionOneTotalCountMax(
          item?.collectionsOrProjectContents?.length
        );
      }
      if (
        item?.id == collectTwoSelect ||
        item?.id == pageData?.collectionTwoCollectionId
      ) {
        setCollectionTwoTotalCountMax(
          item?.collectionsOrProjectContents?.length
        );
      }
    });
  }, [collectOneSelect, collectTwoSelect, projectCollectionList?.length]);

  useEffect(() => {
    setMappedProjectContentIds(
      mappedProjects?.map((project: any, index: any) => {
        return { id: project.id, priority: index + 1 };
      })
    );
    if (
      !pageData?.newInvestments?.displayName &&
      !pageData?.newInvestmentsWithPriority &&
      !pageData?.totalProjectsOnHomeScreen
    ) {
      setGetListLoading(true);
    } else {
      setGetListLoading(false);
    }
  }, [
    mappedProjects,
    getListLoading,
    pageData?.newInvestments?.displayName,
    priorityChange,
  ]);

  useEffect(() => {
    setPreviousStates(pageData);
  }, [pageData]);

  const initialValues = {
    isNewInvestmentsActive: false,
    newInvestments: {
      displayName: "",
      subHeading: "",
    },
    newInvestmentsProjectIds: mappedProjectContentIds,
    newInvestmentsWithPriority: mappedProjectContentIds,
    isCollectionOneActive: false,
    isCollectionOneActiveForWeb: false,
    collectionOne: {
      heading: "",
      subHeading: "",
      totalProjectContentsToDisplay: "",
      sortByPriority: false,
    },
    isCollectionTwoActive: false,
    isCollectionTwoActiveForWeb: false,
    collectionTwo: {
      heading: "",
      subHeading: "",
      totalProjectContentsToDisplay: "",
    },
    collectionOneCollectionId: "",
    collectionOneProjectIds: [1, 2],
    collectionTwoCollectionId: "",
    collectionTwoProjectIds: [3],
    totalProjectsOnHomeScreen: "",
    isPromotionAndOfferActive: false,
    isPromotionAndOfferActiveForWeb: false,
    promotionAndOffersProjectContentId: "",
    promotionAndOffersMedia: {
      key: "",
      name: "",
      value: {
        url: "",
        size: 158.0439453125,
        width: 1024,
        height: 1024,
        mediaType: "image",
      },
    },
  };
  const page2Labels: any = {
    isNewInvestmentsActive: false,
    newInvestments: {
      displayName: "Section Heading*",
      subHeading: "Sub Heading",
    },
    newInvestmentsProjectIds: "",
    newInvestmentsWithPriority: "Projects Mapped to New Launch",
    isCollectionOneActive: "Collection 1 Toggle",
    collectionOne: {
      heading: "Collection 1 Section Heading",
      subHeading: "Collection 1 Sub Heading",
      totalProjectContentsToDisplay:
        "Total Projects on the New Investment Page For Collection 1",
      sortByPriority: "Sort By Priority",
    },
    isCollectionTwoActive: "Collection 2 Toggle",
    collectionTwo: {
      heading: "Collection 2 Section Heading",
      subHeading: "Collection 2 Sub Heading",
      totalProjectContentsToDisplay:
        "Total Projects on the New Investment Page For Collection 2",
    },
    collectionOneCollectionId: "Collection Name For Collection 1",
    collectionTwoCollectionId: "Collection Name For Collection 2",
    isPromotionAndOfferActive: "Promotion and Offers Toggle",
    promotionAndOffersProjectContentId: "Mapped Project",
    promotionAndOffersMedia: {
      value: {
        url: "Promotion and Offer Media",
      },
    },
  };

  const handleGlobalSave = (values: any) => {
    auditedFieldsSetter(values);

    const data = {
      ...values,
      id: pageData?.id,
      isPublished: true,
      pageType: "5002",
      pageName: "Invest",
    };

    if (pageData?.newInvestments?.displayName !== null) {
      updatePageManagentData(data);
    } else {
      addPageManagementData(data);
    }
    setOpenConfirmationDialog(false);
    setShowFlashMessage(true);
  };

  const getProjectName = (id: number) => {
    const getLunchName = projectContentList?.find(
      (projectContent: any) => id === projectContent?.id
    );
    return getLunchName ? getLunchName?.launchName : "";
  };

  const auditedFieldsSetter = (values: any) => {
    let actualValues: any = {};
    let acutalPreviousValues: any = {};

    for (const [key, value] of Object.entries(values)) {
      //  filtering keys that are only present in labels(final value)
      if (page2Labels.hasOwnProperty(key)) {
        actualValues[key] = value;
      }
    }
    for (const [key, value] of Object.entries(previousStates)) {
      //filtering keys that are only present in labels(previuos value)
      if (page2Labels.hasOwnProperty(key)) {
        acutalPreviousValues[key] = value;
      }
    }

    let auditObjectsArray: any[] = [];

    Object?.keys(actualValues)?.map((key: any) => {
      if (actualValues[key] && typeof actualValues[key] == "object") {
        if (
          Array.isArray(actualValues[key]) &&
          key === "newInvestmentsWithPriority"
        ) {
          if (
            page2Labels[key] !== undefined &&
            actualValues[key] !== acutalPreviousValues[key] &&
            actualValues[key].map((project: any) => project.id).join(",") !==
              acutalPreviousValues[key]
                .map((project: any) => project.id)
                .join(",")
          ) {
            let auditObject = {
              itemAffected: page2Labels[key],
              newValue: actualValues[key]
                .map((project: any, index: number) => {
                  return getProjectName(project.id);
                })
                .join(`,  `),
              oldValue: acutalPreviousValues[key]
                .map((project: any, index: number) =>
                  getProjectName(project.id)
                )
                .join(`,  `),
            };
            if (auditObject?.newValue !== auditObject?.oldValue) {
              auditObjectsArray.push(auditObject);
            }
          }
        } else if (key !== "newInvestmentsProjectIds") {
          Object?.keys(actualValues[key])?.map((ObjectKey: any) => {
            if (typeof actualValues[key][ObjectKey] == "object") {
              Object?.keys(actualValues[key][ObjectKey])?.map(
                (mediaObjectKey: any) => {
                  if (
                    typeof actualValues[key][ObjectKey][mediaObjectKey] ==
                    "object"
                  ) {
                  } else {
                    if (
                      page2Labels[key][ObjectKey][mediaObjectKey] !==
                        undefined &&
                      actualValues[key][ObjectKey][mediaObjectKey] !==
                        acutalPreviousValues[key][ObjectKey][mediaObjectKey]
                    ) {
                      let auditObject = {
                        itemAffected:
                          page2Labels[key][ObjectKey][mediaObjectKey],
                        newValue: actualValues[key][ObjectKey][mediaObjectKey],
                        oldValue:
                          acutalPreviousValues[key][ObjectKey][mediaObjectKey],
                      };
                      if (auditObject?.newValue !== auditObject?.oldValue) {
                        auditObjectsArray.push(auditObject);
                      }
                    }
                  }
                }
              );
            } else {
              if (
                page2Labels[key][ObjectKey] !== undefined &&
                actualValues[key][ObjectKey] !==
                  acutalPreviousValues[key][ObjectKey] &&
                ObjectKey !== "sortByPriority"
              ) {
                let auditObject = {
                  itemAffected: page2Labels[key][ObjectKey],
                  newValue: actualValues[key][ObjectKey],
                  oldValue: acutalPreviousValues[key][ObjectKey],
                };
                if (auditObject?.newValue !== auditObject?.oldValue) {
                  auditObjectsArray.push(auditObject);
                }
              }
              if (ObjectKey === "sortByPriority") {
                let auditObject = {
                  itemAffected: page2Labels[key][ObjectKey],
                  newValue: actualValues[key][ObjectKey] ? "ON" : "Off",
                  oldValue: acutalPreviousValues[key][ObjectKey] ? "ON" : "Off",
                };
                if (auditObject?.newValue !== auditObject?.oldValue) {
                  auditObjectsArray.push(auditObject);
                }
              }
            }
          });
        }
      } else {
        if (
          page2Labels[key] !== undefined &&
          // acutalPreviousValues[key] && actualValues[key] &&
          actualValues[key] !== acutalPreviousValues[key] &&
          key !== "promotionAndOffersProjectContentId" &&
          key !== "collectionOneCollectionId" &&
          key !== "collectionTwoCollectionId"
        ) {
          let auditObject = {
            itemAffected: `${page2Labels[key]}`,
            newValue:
              key === "isPromotionAndOfferActive" ||
              key === "isCollectionOneActive" ||
              key === "isCollectionTwoActive"
                ? actualValues[key]
                  ? "ON"
                  : "Off"
                : actualValues[key],
            oldValue:
              key === "isPromotionAndOfferActive" ||
              key === "isCollectionOneActive" ||
              key === "isCollectionTwoActive"
                ? acutalPreviousValues[key]
                  ? "ON"
                  : "Off"
                : acutalPreviousValues[key],
          };
          if (auditObject?.newValue !== auditObject?.oldValue) {
            auditObjectsArray.push(auditObject);
          }
        }
        if (
          page2Labels[key] !== undefined &&
          key === "promotionAndOffersProjectContentId" &&
          actualValues[key] !== acutalPreviousValues[key]
        ) {
          let auditObject = {
            itemAffected: `${page2Labels[key]}`,
            newValue: projectContentList?.find(
              (project: any) => project?.id === actualValues[key]
            ).launchName,
            oldValue: acutalPreviousValues[key]
              ? projectContentList?.find(
                  (project: any) => project?.id === acutalPreviousValues[key]
                )?.launchName
              : "",
          };
          if (auditObject?.newValue !== auditObject?.oldValue) {
            auditObjectsArray.push(auditObject);
          }
        }
        if (
          (key === "collectionOneCollectionId" ||
            key === "collectionTwoCollectionId") &&
          page2Labels[key] !== undefined &&
          actualValues[key] !== acutalPreviousValues[key]
        ) {
          let auditObject = {
            itemAffected: `${page2Labels[key]}`,
            newValue: projectCollectionList?.find(
              (project: any) => project?.id === actualValues[key]
            ).name,
            oldValue: acutalPreviousValues[key]
              ? projectCollectionList?.find(
                  (project: any) => project?.id === acutalPreviousValues[key]
                )?.name
              : "",
          };
          if (auditObject?.newValue !== auditObject?.oldValue) {
            auditObjectsArray.push(auditObject);
          }
        }
      }
    });

    setModulesAudited(auditObjectsArray);
  };
  const handleProjectListDialogClose = () => {
    setshowProjectListDailog(false);
  };

  const tableHeadings = [
    { heading: "Project ID" },
    { heading: "Thumbnail" },
    { heading: "Name" },
    { heading: "Location" },
    { heading: "Starting Price" },
    { heading: "Starting Area" },
    { heading: "Created On" },
    { heading: "Priority" },
    { heading: "Action" },
  ];

  const removeHandler = (id: any) => {
    setMappedProjects(mappedProjects.filter((item: any) => item.id !== id));
    setRemoveProjectClick(true);
    setOpenProjectRemoveDialog(false);
  };

  const handlePriorityChange = (
    indexValue: any,
    list: any,
    priorityUpdatevalue: any,
    updatedItem: any
  ) => {
    if (indexValue) {
      list.splice(indexValue, 1);
      list.splice(indexValue + priorityUpdatevalue, 0, updatedItem);
      return list;
    }
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setOpenProjectRemoveDialog(false);
  };

  const handleCloseFlashMessageDialog = (values: any) => {
    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Pages Management";
    });
    if (isSuccess) {
      if (modulesAudited?.length) {
        const postAuditRequestbody = {
          ipAddress: ipAddress, //ipAddress
          moduleId: moduleObject?.id,
          modulesAudited: modulesAudited,
          status: AuditStatus.EDITED,
          auditedObject: values,
          route: location.pathname,
        };
        postAuditLogRequest(postAuditRequestbody);
      }
    }

    setClear(true);
    setShowFlashMessage(false);
    const data = {
      pageType: "5002",
    };
    getPageManagementData(data);
    !error && navigate(RouteConstants.pageManagementDashboard);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleViewIconClick = (publishedId: number) => {
    navigate(RouteConstants.projectCreation, {
      state: {
        isNew: false,
        ProjectListCreationId: publishedId,
        isViewMode: true,
      },
    });
  };

  return (
    <Fragment>
      {pageData && (
        <DelayComponent>
          <Formik
            initialValues={pageData?.isPublished ? pageData : initialValues}
            validationSchema={validationSchema}
            onSubmit={() => setOpenConfirmationDialog(true)}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
              isValid,
              errors,
              resetForm,
              dirty,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Stack className={styles["heading-content-stack"]}>
                  <Typography
                    style={{ fontSize: "1.5rem" }}
                    variant={"h5"}
                    component={"h5"}
                  >
                    Page 2 - Investments
                  </Typography>

                  {isViewMode ? (
                    <LoadingButtonComponent
                      onClick={() => {
                        dispatch(
                          PageManagentAction.setPageManagementState(
                            "isViewMode",
                            false
                          )
                        );
                      }}
                    >
                      Edit
                    </LoadingButtonComponent>
                  ) : (
                    <Stack
                      className={styles["submit-btn-section"]}
                      justifyContent={"end"}
                      spacing={2}
                    >
                      <ButtonComponent
                        onClick={() => navigate(-1)}
                        color={"inherit"}
                      >
                        Cancel
                      </ButtonComponent>

                      <LoadingButtonComponent
                        type={"submit"}
                        onClick={() => {
                          setFieldValue(
                            "newInvestmentsProjectIds",
                            mappedProjectContentIds
                          );
                          setFieldValue(
                            "newInvestmentsWithPriority",
                            mappedProjectContentIds
                          );
                        }}
                        disabled={!isValid}
                      >
                        {pageData?.isPublished ? "Publish Changes" : "Publish"}
                      </LoadingButtonComponent>
                    </Stack>
                  )}
                </Stack>
                <FormFieldContainer>
                  <Stack
                    justifyContent="start"
                    spacing={2}
                    className={styles["stack-container"]}
                  >
                    <Typography
                      className={styles["heading-text-color"]}
                      variant={"h6"}
                      component={"h6"}
                    >
                      New Launch
                    </Typography>

                    <Tooltip
                      title={"This will be project in focus on Investment page"}
                      placement="top"
                      arrow
                    >
                      <Avatar
                        className={styles["info-icon"]}
                        src={MediaAssets.ic_info}
                        variant="rounded"
                      />
                    </Tooltip>
                  </Stack>
                  <Grid container spacing={3}>
                    <Grid item xs={5} lg={5} md={5} xl={5}>
                      <TextFieldComponent
                        required
                        disabled={isViewMode}
                        type="text"
                        label="Section Heading"
                        placeholder="Enter"
                        name={"newInvestments.displayName"}
                        value={values?.newInvestments?.displayName}
                        onChange={(event: any) => {
                          setFieldValue(
                            `newInvestments.displayName`,
                            event.target.value
                          );
                        }}
                        inputProps={{
                          maxLength: NewInvestmentConstants.sectionHeading,
                        }}
                        fieldhelpertext={
                          getIn(touched, "newInvestments.displayName") &&
                          getIn(errors, "newInvestments.displayName")
                        }
                        error={Boolean(
                          getIn(errors, "newInvestments.displayName") &&
                            getIn(touched, "newInvestments.displayName")
                        )}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={5} lg={5} md={5} xl={5}>
                      <TextFieldComponent
                        required
                        disabled={isViewMode}
                        type="text"
                        label="Sub-Heading"
                        placeholder="Enter"
                        name={"newInvestments.subHeading"}
                        value={values?.newInvestments?.subHeading}
                        onChange={(event: any) => {
                          setFieldValue(
                            `newInvestments.subHeading`,
                            event.target.value
                          );
                        }}
                        fieldhelpertext={
                          getIn(touched, "newInvestments.subHeading") &&
                          getIn(errors, "newInvestments.subHeading")
                        }
                        error={Boolean(
                          getIn(errors, "newInvestments.subHeading") &&
                            getIn(touched, "newInvestments.subHeading")
                        )}
                        inputProps={{
                          maxLength: NewInvestmentConstants.subHeading,
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid
                      className={styles["mapping-project-btn"]}
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      {isViewMode ? (
                        ""
                      ) : (
                        <LoadingButtonComponent
                          onClick={() => {
                            setshowProjectListDailog(true);
                          }}
                        >
                          {mappedProjects?.length > 0
                            ? "Map More Projects To New Launch"
                            : "Map Projects To New Launch"}
                        </LoadingButtonComponent>
                      )}
                    </Grid>

                    {mappedProjects?.length > 0 && (
                      <>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                          <Stack
                            className={styles["stack-container"]}
                            justifyContent="flex-start"
                            spacing={2}
                          >
                            {/* <Typography>
                              Total Projects on the New Investment Page?
                            </Typography>
                            <PrioritySelector
                              isPriorityRequired={isViewMode}
                              priority={
                                values?.totalProjectsOnHomeScreen
                              }
                              disableDecrement={
                                values?.totalProjectsOnHomeScreen === 1
                              }
                              disableIncrement={
                                values?.totalProjectsOnHomeScreen >=
                                mappedProjects?.length
                              }
                              incrementpriority={() =>
                                setFieldValue(
                                  "totalProjectsOnHomeScreen",
                                  values?.totalProjectsOnHomeScreen + 1
                                )
                              }
                              decrementpriority={() =>
                                setFieldValue(
                                  "totalProjectsOnHomeScreen",
                                  values?.totalProjectsOnHomeScreen - 1
                                )
                              }
                            /> */}
                          </Stack>

                          <Stack justifyContent={"end"} spacing={2}>
                            <Typography variant={"body1"} component={"h6"}>
                              Sort by Priority
                            </Typography>
                            <SwitchComponent
                              disabled={isViewMode}
                              name="collectionOne.sortByPriority"
                              checked={values?.collectionOne.sortByPriority}
                              value={values?.collectionOne.sortByPriority}
                              onChange={handleChange}
                            />
                            <Tooltip
                              arrow
                              title={
                                "Disabling the toggle will switch to show items on the Customer App based on Date & Time."
                              }
                              placement="top-start"
                            >
                              <Avatar
                                className={styles["info-icon"]}
                                src={MediaAssets.ic_info}
                                variant="rounded"
                              />
                            </Tooltip>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TableContainer
                            component={Paper}
                            className="table-container"
                            style={{ maxHeight: 400 }}
                          >
                            <Table stickyHeader>
                              <TableHead style={{ position: "relative" }}>
                                <TableRow className="table-header-row">
                                  {tableHeadings.map((item, index) => (
                                    <TableCell
                                      key={index}
                                      className="table-header-text"
                                      style={{ minWidth: 170 }}
                                    >
                                      {item.heading}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {mappedProjects
                                  ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((project: any, index: any) => (
                                    <TableRow
                                      key={project.id}
                                      className="table-data-row"
                                    >
                                      <TableCell className="table-data-text">
                                        {project.id}
                                      </TableCell>

                                      <TableCell className="table-data-text">
                                        {project?.projectCoverImages
                                          .homePageMedia.value.url ? (
                                          <img
                                            src={
                                              project?.projectCoverImages
                                                .homePageMedia.value.url
                                            }
                                            alt={
                                              project?.projectCoverImages
                                                .homePageMedia.name
                                            }
                                            className={
                                              styles["project-list-image"]
                                            }
                                          />
                                        ) : (
                                          "-"
                                        )}
                                      </TableCell>

                                      <TableCell className="table-data-text">
                                        {project?.launchName}
                                      </TableCell>

                                      <TableCell className="table-data-text">
                                        {project?.address?.city &&
                                          `${project?.address?.city},`}
                                        {project?.address?.state}
                                      </TableCell>

                                      <TableCell className="table-data-text">
                                        {project?.priceStartingFrom}
                                      </TableCell>

                                      <TableCell className="table-data-text">
                                        {project?.areaStartingFrom}
                                      </TableCell>

                                      <TableCell
                                        className="table-data-text"
                                        style={{ minWidth: 120 }}
                                      >
                                        <div>
                                          {
                                            project?.projectContentCreatedBy
                                              ?.firstName
                                          }
                                        </div>
                                        {project?.createdAt
                                          ? formatDateTime(
                                              project?.createdAt,
                                              Constants.dateFormatDDmmYYYY
                                            )
                                          : "-"}
                                      </TableCell>

                                      <TableCell className="table-data-text">
                                        {values?.collectionOne
                                          .sortByPriority === true ? (
                                          <PrioritySelector
                                            isPriorityRequired={isViewMode}
                                            priority={index + 1}
                                            disableDecrement={
                                              index + 1 ===
                                              mappedProjects.length
                                            }
                                            disableIncrement={index === 0}
                                            incrementpriority={() => {
                                              handlePriorityChange(
                                                index,
                                                mappedProjects,
                                                -1,
                                                project
                                              );
                                              setPriorityCount(
                                                priorityCount + 1
                                              );
                                              setPriorityChange(
                                                priorityChange + 1
                                              );
                                            }}
                                            decrementpriority={() => {
                                              if (index === 0) {
                                                mappedProjects.splice(0, 1);
                                                mappedProjects.splice(
                                                  1,
                                                  0,
                                                  project
                                                );
                                                setPriorityCount(
                                                  priorityCount + 1
                                                );
                                              } else {
                                                handlePriorityChange(
                                                  index,
                                                  mappedProjects,
                                                  1,
                                                  project
                                                );
                                                setPriorityCount(
                                                  priorityCount - 1
                                                );
                                              }
                                              setPriorityChange(
                                                priorityChange - 1
                                              );
                                            }}
                                            isCounter={true}
                                          />
                                        ) : (
                                          <PrioritySelector
                                            disableDecrement={true}
                                            disableIncrement={true}
                                            isPriorityRequired={true}
                                            priority={index + 1}
                                            isCounter={true}
                                          />
                                        )}
                                      </TableCell>

                                      <TableCell className="table-data-text">
                                        <Stack
                                          justifyContent={"start"}
                                          spacing={2}
                                        >
                                          {mappedProjects?.length > 1 ? (
                                            <Typography
                                              sx={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setOpenProjectRemoveDialog(
                                                  true
                                                );
                                                setProjectRemoveId(project.id);
                                              }}
                                            >
                                              Remove
                                            </Typography>
                                          ) : (
                                            <Typography color={"#CFD2CF"}>
                                              Remove
                                            </Typography>
                                          )}
                                          <IconButton
                                            onClick={() =>
                                              handleViewIconClick(project.id)
                                            }
                                          >
                                            <RemoveRedEyeIcon />
                                          </IconButton>
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[10, 50, 100]}
                            component="div"
                            count={mappedProjects?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Divider className={styles["divider-section"]} />
                    </Grid>
                  </Grid>

                  {showProjectListDialog && (
                    <ProjectListDialog
                      shouldOpen={showProjectListDialog}
                      cancelHandler={handleProjectListDialogClose}
                      mapProjectList={setMappedProjects}
                      initialMapList={mappedProjects}
                      initialSelectedItem={initialSelectedIds}
                      isMappedListUpdated={isMappedListUpdated}
                      setIsMappedListUpdated={setIsMappedListUpdated}
                      setSelectedIds={setSelectedIds}
                    />
                  )}

                  <Box className={styles["sections-division"]}>
                    <Stack
                      className={styles["stack-container"]}
                      justifyContent="flex-start"
                      spacing={2}
                    >
                      <Typography
                        variant={"h6"}
                        component={"h6"}
                        style={{ fontSize: "1.875rem" }}
                      >
                        Promotion and Offer Card
                      </Typography>
                      <Typography
                        variant={"h6"}
                        component={"h6"}
                        style={{ fontSize: "1rem" }}
                      >
                        App Toggle
                      </Typography>

                      <SwitchComponent
                        disabled={isViewMode}
                        name="isPromotionAndOfferActive"
                        checked={values?.isPromotionAndOfferActive}
                        onChange={(e: any) => {
                          console.log(
                            "SwitchComponent e",
                            e?.target?.checked,
                            e?.target?.value
                          );

                          handleChange(e);
                        }}
                      />
                      <Tooltip
                        arrow
                        title={
                          "Toggle is to show/hide this section on the Customer App"
                        }
                        placement="top"
                      >
                        <Avatar
                          className={styles["info-icon"]}
                          src={MediaAssets.ic_info}
                          variant="rounded"
                        />
                      </Tooltip>
                      <Typography
                        variant={"h6"}
                        component={"h6"}
                        style={{ fontSize: "1rem" }}
                      >
                        Web toggle
                      </Typography>

                      <SwitchComponent
                        disabled={isViewMode}
                        name="isPromotionAndOfferActiveForWeb"
                        checked={values?.isPromotionAndOfferActiveForWeb}
                        onChange={(e: any) => {
                          console.log(
                            "SwitchComponent e",
                            e?.target?.checked,
                            e?.target?.value
                          );

                          // handleChange(e);
                          setFieldValue(
                            "isPromotionAndOfferActiveForWeb",
                            e?.target?.checked
                          );
                        }}
                      />
                      <Tooltip
                        arrow
                        title={"Toggle is to show/hide this section on the Web"}
                        placement="top"
                      >
                        <Avatar
                          className={styles["info-icon"]}
                          src={MediaAssets.ic_info}
                          variant="rounded"
                        />
                      </Tooltip>
                    </Stack>

                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3} lg={3.5} md={3} xl={3}>
                        <ImageUploaderForInvestments />
                      </Grid>

                      <Grid item xs={3} sm={3} lg={3} md={3} xl={3}>
                        <TextFieldComponent
                          select
                          disabled={isViewMode}
                          required
                          type="text"
                          label="Map To Projects"
                          placeholder="Enter"
                          name={"promotionAndOffersProjectContentId"}
                          value={values?.promotionAndOffersProjectContentId}
                          onBlur={handleBlur}
                          onChange={(e: any) =>
                            setFieldValue(
                              "promotionAndOffersProjectContentId",
                              Number(e.target.value)
                            )
                          }
                          fieldhelpertext={
                            getIn(
                              touched,
                              "promotionAndOffersProjectContentId"
                            ) &&
                            getIn(errors, "promotionAndOffersProjectContentId")
                              ? "This Field is mandatory"
                              : ""
                          }
                          error={Boolean(
                            getIn(
                              errors,
                              "promotionAndOffersProjectContentId"
                            ) &&
                              getIn(
                                touched,
                                "promotionAndOffersProjectContentId"
                              )
                          )}
                          helpericon={MediaAssets.ic_info}
                          helpericoncontent={
                            "User will be redirected to the selected Project"
                          }
                        >
                          {projectContentList?.map((project: any) => (
                            <MenuItem value={project.id}>
                              {project.launchName}
                            </MenuItem>
                          ))}
                        </TextFieldComponent>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider className={styles["divider-section"]} />

                  <Box className={styles["sections-division"]}>
                    <Stack
                      className={styles["stack-container"]}
                      justifyContent="flex-start"
                      spacing={2}
                    >
                      <Typography
                        variant={"h6"}
                        component={"h6"}
                        style={{ fontSize: "1.875rem" }}
                      >
                        Collection 1
                      </Typography>
                      <Typography
                        variant={"h6"}
                        component={"h6"}
                        style={{ fontSize: "1rem" }}
                      >
                        App toggle
                      </Typography>

                      <SwitchComponent
                        disabled={isViewMode}
                        name="isCollectionOneActive"
                        checked={values?.isCollectionOneActive}
                        onChange={handleChange}
                      />
                      <Tooltip
                        arrow
                        title={
                          "Toggle is to show/hide this section on the Customer App"
                        }
                        placement="top"
                      >
                        <Avatar
                          className={styles["info-icon"]}
                          src={MediaAssets.ic_info}
                          variant="rounded"
                        />
                      </Tooltip>
                      <Typography
                        variant={"h6"}
                        component={"h6"}
                        style={{ fontSize: "1rem" }}
                      >
                        Web Toggle
                      </Typography>

                      <SwitchComponent
                        disabled={isViewMode}
                        name="isCollectionOneActiveForWeb"
                        checked={values?.isCollectionOneActiveForWeb}
                        onChange={(e: any) => {
                          console.log(
                            "SwitchComponent e",
                            e?.target?.checked,
                            e?.target?.value
                          );

                          // handleChange(e);
                          setFieldValue(
                            "isCollectionOneActiveForWeb",
                            e?.target?.checked
                          );
                        }}
                      />
                      <Tooltip
                        arrow
                        title={"Toggle is to show/hide this section on the Web"}
                        placement="top"
                      >
                        <Avatar
                          className={styles["info-icon"]}
                          src={MediaAssets.ic_info}
                          variant="rounded"
                        />
                      </Tooltip>
                    </Stack>

                    <Grid
                      className={styles["setion-heading"]}
                      container
                      spacing={2}
                    >
                      <Grid item xs={5} lg={5} md={5} xl={5}>
                        <TextFieldComponent
                          required
                          disabled={isViewMode}
                          type="text"
                          label="Section Heading"
                          placeholder="Enter"
                          name={"collectionOne.heading"}
                          value={values?.collectionOne?.heading}
                          onChange={(event: any) => {
                            setFieldValue(
                              `collectionOne.heading`,
                              event.target.value
                            );
                          }}
                          fieldhelpertext={
                            getIn(touched, "collectionOne.heading") &&
                            getIn(errors, "collectionOne.heading")
                          }
                          error={Boolean(
                            getIn(errors, "collectionOne.heading") &&
                              getIn(touched, "collectionOne.heading")
                          )}
                          inputProps={{
                            maxLength: NewInvestmentConstants.sectionHeading,
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item xs={5} lg={5} md={5} xl={5}>
                        <TextFieldComponent
                          required
                          disabled={isViewMode}
                          type="text"
                          label="Sub-Heading"
                          placeholder="Enter"
                          name={"collectionOne.subHeading"}
                          value={values?.collectionOne.subHeading}
                          onChange={(event: any) => {
                            setFieldValue(
                              `collectionOne.subHeading`,
                              event.target.value
                            );
                          }}
                          fieldhelpertext={
                            getIn(touched, "collectionOne.subHeading") &&
                            getIn(errors, "collectionOne.subHeading")
                          }
                          error={Boolean(
                            getIn(errors, "collectionOne.subHeading") &&
                              getIn(touched, "collectionOne.subHeading")
                          )}
                          inputProps={{
                            maxLength: NewInvestmentConstants.subHeading,
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item xs={5} lg={5} md={5} xl={5}>
                        <TextFieldComponent
                          select
                          disabled={isViewMode}
                          required
                          type="text"
                          label="Collection"
                          placeholder="Enter"
                          name={"collectionOneCollectionId"}
                          value={values?.collectionOneCollectionId}
                          onChange={(e: any) => {
                            handleChange(e);
                            projectCollectionList.map((item: any) => {
                              if (item.id === e.target.value) {
                                setFieldValue(
                                  "collectionOne.totalProjectContentsToDisplay",
                                  item?.collectionsOrProjectContents?.length
                                );
                                setFieldValue(
                                  "collectionOne.totalProjectContentsToDisplay",
                                  item?.collectionsOrProjectContents?.length
                                );
                                setCollectionOneTotalCountMax(
                                  item?.collectionsOrProjectContents?.length
                                );
                              }
                            });
                            setCollectOneSelect(e.target.value);
                          }}
                          fieldhelpertext={
                            getIn(touched, "collectionOneCollectionId") &&
                            getIn(errors, "collectionOneCollectionId")
                          }
                          error={Boolean(
                            getIn(errors, "collectionOneCollectionId") &&
                              getIn(touched, "collectionOneCollectionId")
                          )}
                          onBlur={handleBlur}
                        >
                          {projectCollectionList?.map((project: any) => (
                            <MenuItem value={project.id}>
                              {project.name}
                            </MenuItem>
                          ))}
                        </TextFieldComponent>
                      </Grid>
                    </Grid>

                    <Divider className={styles["divider-section"]} />

                    <Stack
                      className={styles["stack-container"]}
                      justifyContent="flex-start"
                      spacing={2}
                    >
                      <Typography>
                        Total Collections on the New Investment Page?
                      </Typography>
                      <PrioritySelector
                        isPriorityRequired={
                          isViewMode ||
                          values?.collectionOne
                            ?.totalProjectContentsToDisplay === 0
                        }
                        priority={
                          values?.collectionOne
                            ?.totalProjectContentsToDisplay >=
                          collectionOneTotalCountMax
                            ? collectionOneTotalCountMax
                            : values?.collectionOne
                                ?.totalProjectContentsToDisplay
                        }
                        incrementpriority={() => {
                          setFieldValue(
                            "collectionOne.totalProjectContentsToDisplay",
                            values?.collectionOne
                              ?.totalProjectContentsToDisplay + 1
                          );
                        }}
                        decrementpriority={() => {
                          setFieldValue(
                            "collectionOne.totalProjectContentsToDisplay",
                            values?.collectionOne
                              ?.totalProjectContentsToDisplay >=
                              collectionOneTotalCountMax
                              ? collectionOneTotalCountMax - 1
                              : values?.collectionOne
                                  ?.totalProjectContentsToDisplay - 1
                          );
                        }}
                        disableDecrement={
                          values?.collectionOne
                            ?.totalProjectContentsToDisplay >=
                          collectionOneTotalCountMax
                            ? collectionOneTotalCountMax === 1
                              ? true
                              : false
                            : values?.collectionOne
                                ?.totalProjectContentsToDisplay === 1
                            ? true
                            : false
                        }
                        disableIncrement={
                          values?.collectionOne
                            ?.totalProjectContentsToDisplay >=
                          collectionOneTotalCountMax
                            ? true
                            : false
                        }
                        isCounter={true}
                      />
                    </Stack>
                  </Box>

                  {/* <Divider className={styles["divider-section"]} />

              <div className={styles["save-btn-section"]}>
                <LoadingButtonComponent>Save</LoadingButtonComponent>
              </div> */}

                  <Divider className={styles["divider-section"]} />

                  <Box className={styles["sections-division"]}>
                    <Stack
                      className={styles["stack-container"]}
                      justifyContent="flex-start"
                      spacing={2}
                    >
                      <Typography
                        variant={"h6"}
                        component={"h6"}
                        style={{ fontSize: "1.875rem" }}
                      >
                        Collection 2
                      </Typography>
                      <Typography
                        variant={"h6"}
                        component={"h6"}
                        style={{ fontSize: "1rem" }}
                      >
                        App toggle
                      </Typography>

                      <SwitchComponent
                        disabled={isViewMode}
                        name="isCollectionTwoActive"
                        checked={values?.isCollectionTwoActive}
                        onChange={handleChange}
                      />
                      <Tooltip
                        arrow
                        title={
                          "Toggle is to show/hide this section on the Customer App"
                        }
                        placement="top"
                      >
                        <Avatar
                          className={styles["info-icon"]}
                          src={MediaAssets.ic_info}
                          variant="rounded"
                        />
                      </Tooltip>
                      <Typography
                        variant={"h6"}
                        component={"h6"}
                        style={{ fontSize: "1rem" }}
                      >
                        Web Toggle
                      </Typography>

                      <SwitchComponent
                        disabled={isViewMode}
                        name="isCollectionTwoActiveForWeb"
                        checked={values?.isCollectionTwoActiveForWeb}
                        onChange={(e: any) => {
                          console.log(
                            "SwitchComponent e",
                            e?.target?.checked,
                            e?.target?.value
                          );

                          // handleChange(e);
                          setFieldValue(
                            "isCollectionTwoActiveForWeb",
                            e?.target?.checked
                          );
                        }}
                      />
                      <Tooltip
                        arrow
                        title={"Toggle is to show/hide this section on the Web"}
                        placement="top"
                      >
                        <Avatar
                          className={styles["info-icon"]}
                          src={MediaAssets.ic_info}
                          variant="rounded"
                        />
                      </Tooltip>
                    </Stack>

                    <Grid
                      className={styles["section-heading"]}
                      container
                      spacing={2}
                    >
                      <Grid item xs={5} lg={5} md={5} xl={5}>
                        <TextFieldComponent
                          required
                          disabled={isViewMode}
                          type="text"
                          label="Section Heading"
                          placeholder="Enter"
                          name={"collectionTwo.heading"}
                          value={values?.collectionTwo.heading}
                          onChange={(event: any) => {
                            setFieldValue(
                              `collectionTwo.heading`,
                              event.target.value
                            );
                          }}
                          fieldhelpertext={
                            getIn(touched, "collectionTwo.heading") &&
                            getIn(errors, "collectionTwo.heading")
                          }
                          error={Boolean(
                            getIn(errors, "collectionTwo.heading") &&
                              getIn(touched, "collectionTwo.heading")
                          )}
                          inputProps={{
                            maxLength: NewInvestmentConstants.sectionHeading,
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item xs={5} lg={5} md={5} xl={5}>
                        <TextFieldComponent
                          required
                          disabled={isViewMode}
                          type="text"
                          label="Sub-Heading"
                          placeholder="Enter"
                          name={"collectionTwo.subHeading"}
                          value={values?.collectionTwo.subHeading}
                          onChange={(event: any) => {
                            setFieldValue(
                              `collectionTwo.subHeading`,
                              event.target.value
                            );
                          }}
                          fieldhelpertext={
                            getIn(touched, "collectionTwo.subHeading") &&
                            getIn(errors, "collectionTwo.subHeading")
                          }
                          error={Boolean(
                            getIn(errors, "collectionTwo.subHeading") &&
                              getIn(touched, "collectionTwo.subHeading")
                          )}
                          inputProps={{
                            maxLength: NewInvestmentConstants.subHeading,
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item xs={5} lg={5} md={5} xl={5}>
                        <TextFieldComponent
                          select
                          disabled={isViewMode}
                          required
                          type="text"
                          label="Collection"
                          placeholder="Enter"
                          name={"collectionTwoCollectionId"}
                          value={values?.collectionTwoCollectionId}
                          onChange={(e: any) => {
                            handleChange(e);
                            projectCollectionList.map((item: any) => {
                              if (item.id === e.target.value) {
                                setFieldValue(
                                  "collectionTwo.totalProjectContentsToDisplay",
                                  item?.collectionsOrProjectContents?.length
                                );
                                setFieldValue(
                                  "collectionTwoMaxCount",
                                  item?.collectionsOrProjectContents?.length
                                );
                                setCollectionTwoTotalCountMax(
                                  item?.collectionsOrProjectContents?.length
                                );
                              }
                              setCollectTwoSelect(e.target.value);
                            });
                          }}
                          fieldhelpertext={
                            getIn(touched, "collectionTwoCollectionId") &&
                            getIn(errors, "collectionTwoCollectionId")
                          }
                          error={Boolean(
                            getIn(errors, "collectionTwoCollectionId") &&
                              getIn(touched, "collectionTwoCollectionId")
                          )}
                          onBlur={handleBlur}
                        >
                          {projectCollectionList?.map((project: any) => (
                            <MenuItem value={project.id}>
                              {project.name}
                            </MenuItem>
                          ))}
                        </TextFieldComponent>
                      </Grid>
                    </Grid>

                    <Divider className={styles["divider-section"]} />

                    <Stack
                      className={styles["stack-container"]}
                      justifyContent="flex-start"
                      spacing={2}
                    >
                      <Typography>
                        Total Collections on the New Investment Page?
                      </Typography>
                      <PrioritySelector
                        isPriorityRequired={
                          isViewMode ||
                          values?.collectionTwo
                            ?.totalProjectContentsToDisplay === 0
                        }
                        priority={
                          values?.collectionTwo
                            ?.totalProjectContentsToDisplay >=
                          collectionTwoTotalCountMax
                            ? collectionTwoTotalCountMax
                            : values?.collectionTwo
                                ?.totalProjectContentsToDisplay
                        }
                        incrementpriority={() =>
                          setFieldValue(
                            "collectionTwo.totalProjectContentsToDisplay",
                            values?.collectionTwo
                              ?.totalProjectContentsToDisplay + 1
                          )
                        }
                        decrementpriority={() => {
                          setFieldValue(
                            "collectionTwo.totalProjectContentsToDisplay",
                            values?.collectionTwo
                              ?.totalProjectContentsToDisplay >=
                              collectionTwoTotalCountMax
                              ? collectionTwoTotalCountMax - 1
                              : values?.collectionTwo
                                  ?.totalProjectContentsToDisplay - 1
                          );
                        }}
                        disableDecrement={
                          values?.collectionTwo
                            ?.totalProjectContentsToDisplay >=
                          collectionTwoTotalCountMax
                            ? collectionTwoTotalCountMax === 1
                              ? true
                              : false
                            : values?.collectionTwo
                                ?.totalProjectContentsToDisplay === 1
                            ? true
                            : false
                        }
                        disableIncrement={
                          values?.collectionTwo
                            ?.totalProjectContentsToDisplay >=
                          collectionTwoTotalCountMax
                            ? true
                            : false
                        }
                        isCounter={true}
                      />
                    </Stack>
                  </Box>
                </FormFieldContainer>
                {showFlashMessage && (
                  <FlashMessageDialog
                    shouldOpen={showFlashMessage}
                    content={error ? error : message}
                    isSuccess={error ? false : true}
                    cancelHandler={() => handleCloseFlashMessageDialog(values)}
                  />
                )}

                {openConfirmationDialog && (
                  <ConfirmationDialog
                    shouldOpen={openConfirmationDialog}
                    content="Are you sure you want to Publish?"
                    okText="Publish"
                    cancelHandler={handleCloseConfirmationDialog}
                    okHandler={() => handleGlobalSave(values)}
                  />
                )}

                {openProjectRemoveDialog && (
                  <ConfirmationDialog
                    shouldOpen={openProjectRemoveDialog}
                    title="Remove Mapped Project?"
                    content="This action will Remove Mapped Project. Are you sure you want to continue?"
                    okText="Remove"
                    cancelHandler={handleCloseConfirmationDialog}
                    okHandler={() => removeHandler(projectRemoveId)}
                  />
                )}
              </Form>
            )}
          </Formik>
        </DelayComponent>
      )}
    </Fragment>
  );
};

export default NewInvestmentPage;
