import { Container, Typography } from "@mui/material";
import { Fragment } from "react";
import { Page5AboutUsSection } from "../../Page5ProfileSection";
import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/ui-elements";

const AboutUsPage = () => {
  const handleSubmit = () => {};

  const [expanded, setExpanded] = React.useState<string | false>("");
  const handleChange = (panel: string, setState : any, id : any ) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
    setState(id)
    };
  return (
    <Fragment>
      <div>
        <Container sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
          {Page5AboutUsSection()?.map((page5PAboutUsInfo: any, index: any) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${page5PAboutUsInfo.id}`}
              onChange={handleChange(`panel${page5PAboutUsInfo.id}`,
              page5PAboutUsInfo.setStatus,
              page5PAboutUsInfo.id)}
            >
              <AccordionSummary
                aria-controls={`panel${page5PAboutUsInfo.id}-content`}
                id={`panel${page5PAboutUsInfo.id}-header`}
              >
                <Typography variant="body1">
                  {page5PAboutUsInfo.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{page5PAboutUsInfo.component}</AccordionDetails>
            </Accordion>
          ))}
        </Container>

        {/* <Divider />

        <Grid className={styles["button"]} mr={3}>
          <Stack
            direction={"row"}
            columnGap={2}
            justifyContent={"space-around"}
            mb={2}
            mt={2}
          >
            <ButtonComponent color={"inherit"}>Cancel</ButtonComponent>
            <ButtonComponent>Save as Drafts</ButtonComponent>
            <LoadingButtonComponent type="submit" onClick={handleSubmit}>
              Publish
            </LoadingButtonComponent>
          </Stack>
        </Grid> */}
      </div>
    </Fragment>
  );
};

export default AboutUsPage;
