import { Grid, Stack, Typography } from "@mui/material";
import {
  InfoComponent,
  MediaUploadComponent,
  SwitchComponent,
} from "components/ui-elements";
import { useFormikContext, getIn } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { portfolioPageAction } from "redux-container/page-management/PagePortfolioRedux";
import { portfolioInitialValues } from "../Page4PortfolioSections";
import { PortfolioPageStyleWrapper } from "../PortfolioPage.style";

const actionDispatch = (dispatch: any) => {
  return {
    fileUploadRequest: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    fileDeleteRequest: (data: any) =>
      dispatch(FileUploadActions.fileDeleteRequest(data)),
    setUploadFileState: (key: string, value: any) =>
      dispatch(FileUploadActions.setUploadFileState(key, value)),
    setPortfolioPageState: (key: string, value: any) =>
      dispatch(portfolioPageAction.setPortfolioPageState(key, value)),
  };
};

const FacilityManagement = () => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } =
    useFormikContext<typeof portfolioInitialValues>();
  const {
    isViewMode,
    imageDetails,
    isLoading,
    shouldFlashMessageOpen,
    data,
    error,
    isSuccess,
    fileId,
  } = useSelector((state: any) => ({
    isViewMode: state.pagePortfolio.isViewMode,
    imageDetails: state.fileUpload?.imageDetails,
    isLoading: state.fileUpload?.isLoading,
    shouldFlashMessageOpen: state.fileUpload?.shouldFlashMessageOpen,
    data: state.fileUpload?.data,
    error: state.fileUpload?.error,
    isSuccess: state.fileUpload?.isSuccess,
    fileId: state.pagePortfolio?.fileId,
  }));
  const {
    fileUploadRequest,
    fileDeleteRequest,
    setUploadFileState,
    setPortfolioPageState,
  } = actionDispatch(useDispatch());

  const handleFileInputChange = (event: any) => {
    const file = event?.target.files[0];
    const uploadId = event?.target.id;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (e: any) => {
        data.binary = e.target.result;
        fileUploadRequest(data);
      };
      setPortfolioPageState("fileId", uploadId);
      reader.readAsArrayBuffer(file);
      setFieldValue(`facilityManagement.value.url`, imageDetails?.url);
      setFieldValue(`facilityManagement.value.mediaType`, file.type);
      setFieldValue(`facilityManagement.value.size`, file.size);
      setFieldValue(`facilityManagement.name`, file.name);
      setFieldValue(`facilityManagement.key`, file.name);
      setUploadFileState("shouldFlashMessageOpen", true);
    } else {
      return;
    }
  };

  const handleImageDelete = () => {
    // fileDeleteRequest(values.facilityManagement.name);
    setFieldValue("facilityManagement.value.url", "");
    // setFieldValue("facilityManagement.value.mediaType", "");
    // setFieldValue("facilityManagement.value.size", "");
    // setFieldValue("facilityManagement.name", "");
    // setFieldValue("facilityManagement.key", "");
    // setUploadFileState("shouldFlashMessageOpen", true);
  };

  useEffect(() => {
    const fieldName = `facilityManagement`;
    if (imageDetails && fileId === fieldName) {
      setFieldValue(`facilityManagement.value.url`, imageDetails?.url);
      setUploadFileState("imageDetails", null);
      setUploadFileState("isLoading", false);
    }
  }, [imageDetails]);
  
  return (
    <PortfolioPageStyleWrapper>
      <Stack
        justifyContent="flex-start"
        spacing={2}
        className="field-container-box"
      >
        <Typography>Facility Management</Typography>
        {/* <SwitchComponent
          disabled={isViewMode}
          name={"isFacilityManagementActive"}
          checked={values.isFacilityManagementActive}
          onChange={handleChange}
        />
        <InfoComponent /> */}
      </Stack>
      <Grid container className="field-container-box" columnSpacing={4}>
        <Grid item xs={3} pb={"1rem"}>
          <MediaUploadComponent
            disabled={isViewMode}
            loading={isLoading}
            ondrop={handleChange}
            onBlur={handleBlur}
            name={"facilityManagement"}
            onChange={handleFileInputChange}
            previewimage={values?.facilityManagement?.value?.url}
            cancelimage={handleImageDelete}
            fieldhelpertext={
              getIn(touched, "facilityManagement.value.url") &&
              getIn(errors, "facilityManagement.value.url")
            }
          />
        </Grid>
      </Grid>
      
    </PortfolioPageStyleWrapper>
  );
};

export default FacilityManagement;
