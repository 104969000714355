export const AvOptions = [
  {
    name: "Yes",
    value: true,
  },
  {
    name: "No",
    value: false,
  },
];

export const  milestoneFlagEnumReversed :any= {
    225360000: "APPLICATION_CHARGES",
    225360001: "AGREEMENT_VALUE",
    225360002: "AGREEMENT_VALUE_GST",
    225360003: "TOTAL_AGREEMENT_VALUE",
    225360004: "VIEW_PREMIUM_CHARGES",
    225360005: "VIEW_PREMIUM_CHARGES_GST",
    225360006: "TOTAL_VIEW_PREMIUM_CHARGES",
    225360007: "INFRASTRUCTURE_CHARGES",
    225360008: "INFRASTRUCTURE_CHARGES_GST",
    225360009: "TOTAL_INFRASTRUCTURE_CHARGES",
    225360010: "INCIDENTAL_CHARGES",
    225360011: "INCIDENTAL_CHARGES_GST",
    225360012: "TOTAL_INCIDENTAL_CHARGES",
    225360013: "CAM_CHARGES",
    225360014: "CAM_CHARGES_GST",
    225360015: "TOTAL_CAM_CHARGES",
    225360016: "CORPUS_FUND_CHARGES",
    225360017: "CORPUS_FUND_CHARGES_GST",
    225360018: "TOTAL_CORPUS_FUND_CHARGES",
    225360019: "STAMP_DUTY_CHARGES",
    225360020: "STAMP_DUTY_CHARGES_GST",
    225360021: "TOTAL_STAMP_DUTY_CHARGES",
    225360022: "REGISTRATION_CHARGES",
    225360023: "REGISTRATION_CHARGES_GST",
    225360024: "TOTAL_REGISTRATION_CHARGES",
    225360025: "TOTAL_CAM_CORPUS_WITH_GST"
  };
  