import { styled } from "@mui/material";
import { FormFieldContainer } from "components/ui-elements";

export const PromiseCreationContainer = styled(FormFieldContainer)`
  padding: 0;
  .box {
    padding: 1.5rem;
  }
  .flex-radio-container {
    margin: 0 0 1rem 0;
    justify-content: flex-start;
  }
  .switch-form-label {
    column-gap: 1rem;
    margin-right: 1rem;
    font-family: "Jost-Regular";
  }
  .switch-info-icon {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
  }
`;
