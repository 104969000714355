import PageAndSubHeadings from "./page-and-sub-heading/PageAndSubHeadings";
import PromotionAndOfferCard from "./promotion-offer-card/PromotionAndOfferCard";
// import BookingJourney from "./booking-journey/BookingJourney";
import FacilityManagement from "./facility-management/FacilityManagement";
import { PageManagementModules } from "enumerations/PageManagementEnum";
import * as yup from "yup";

interface IPage4PortfolioContentSections {
  id?: number;
  title?: string;
  component?: React.ReactNode;
}

const Page4PortfolioContentSections: IPage4PortfolioContentSections[] = [
  {
    id: 1,
    title: "Page Heading & Sub Heading",
    component: <PageAndSubHeadings />,
  },
  {
    id: 2,
    title: "Promotion and Offer Card",
    component: <PromotionAndOfferCard />,
  },
  {
    id: 3,
    title: "Facility Management",
    component: <FacilityManagement />,
  },
  // {
  //   id: 4,
  //   title: "Booking Journey",
  //   component: <BookingJourney />,
  // },
];

const portfolioInitialValues = {
  isPublished: false,
  pageType: PageManagementModules.PORTFOLIO,
  pageName: "",
  subHeading: "",
  isPromotionAndOfferActive: false,
  promotionAndOffersProjectContentId: 0,
  promotionAndOffersMedia: {
    name: "",
    key: "",
    value: {
      mediaType: "",
      size: 0,
      width: 0,
      height: 0,
      url: "",
    },
  },
  isFacilityManagementActive: true,
  facilityManagement: {
    key: "",
    name: "",
    value: {
      mediaType: "",
      size: 0,
      width: 0,
      height: 0,
      url: "",
    },
  },
};

export const portfolioLabels:any = {
  isPublished: false,
  pageName: "Page Heading*",
  subHeading: "Sub Heading*",
  isPromotionAndOfferActive:"Promotion and Offer Toggle",
  promotionAndOffersProjectContentId:"Mapped Project",
  promotionAndOffersMedia: {
    value: {
      url: "Promotion And Offers Media",
    },
  },
  isFacilityManagementActive:"Toggle",
  facilityManagement: {
    value: {
      url: "Facility Management Media",
    },
  },
};

const portfolioValidationSchema = yup.object({
  pageName: yup.string().required("This field is mandatory").nullable(),
  subHeading: yup.string().required("This field is mandatory").nullable(),
  promotionAndOffersMedia: yup.object().shape({
    value: yup.object().shape({
      url: yup.mixed().required("File is required"),
    }),
  }),
  facilityManagement: yup.object().shape({
    value: yup.object().shape({
      url: yup.mixed().required("File is required"),
    }),
  }),
  promotionAndOffersProjectContentId: yup
    .string()
    .required("This field is mandatory")
    .nullable(),
});

export {
  Page4PortfolioContentSections,
  portfolioInitialValues,
  portfolioValidationSchema,
};
