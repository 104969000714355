import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({

  getProjectAllFaqsListDataSuccess: ["response"],
  getProjectAllFaqsListDataFailure: ["error"],
  getProjectAllFaqsListDataRequest: ["data"]

});

export const ProjectAllFaqsListTypes = Types;

export default Creators;

export const INITIAL_STATE: any = {
  projectAllFaqsListData: [],
  isConfirmDialogOpen: false,
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  isViewMode: false,
  isGetProjectDetailsLoading: false
};

// get project Content Creation data
export const getProjectAllFaqsListDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isGetProjectDetailsLoading : true
  };
};

export const getProjectAllFaqsListDataSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      projectAllFaqsListData: response.data,
      message: response.message,
      isLoading: false,
      isGetProjectDetailsLoading: false
    };
  }
};

export const getProjectAllFaqsListDataFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isGetProjectDetailsLoading: false
  };
};

export const reducer = createReducer(INITIAL_STATE, {

  [Types.GET_PROJECT_ALL_FAQS_LIST_DATA_SUCCESS]:
    getProjectAllFaqsListDataSuccess,
  [Types.GET_PROJECT_ALL_FAQS_LIST_DATA_FAILURE]:
    getProjectAllFaqsListDataFailure,
  [Types.GET_PROJECT_ALL_FAQS_LIST_DATA_REQUEST]:
    getProjectAllFaqsListDataRequest,

});
