import { Box, Grid, Typography } from "@mui/material";
import { TextFieldComponent } from "components/ui-elements";
import { useFormikContext } from "formik";
import { IProjectFormInitialValues } from "../../ProjectFormInitialValues";
import { useLocation } from "react-router-dom";

const ContactDetails = () => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } =
    useFormikContext<IProjectFormInitialValues>();

  const location: any = useLocation();

  return (
    <Box className="section-gutter">
      <Typography
        variant="h6"
        color={"common.black"}
        fontFamily={"Jost-Medium"}
        mb={"1.5rem"}
      >
        Contact Details
      </Typography>
      <Grid container spacing={3} className="grid-container">
        <Grid item lg={12} xl={12}>
          <TextFieldComponent
            label={"Company Office Address "}
            value={values?.basicDetailsForm?.contactDetails?.companyAddress}
            name="basicDetailsForm.contactDetails.companyAddress"
            onChange={handleChange}
            inputProps={{ maxLength: 250 }}
            sx={
              location?.state?.isViewMode && {
                pointerEvents: "none",
              }
            }
          />
        </Grid>
        <Grid item lg={5} xl={5.5}>
          <TextFieldComponent
            label={"Contact Number"}
            value={values?.basicDetailsForm?.contactDetails?.phoneNumber}
            name="basicDetailsForm.contactDetails.phoneNumber"
            inputProps={{ maxLength: 40 }}
            sx={
              location?.state?.isViewMode && {
                pointerEvents: "none",
              }
            }
            onChange={handleChange}
            // onBlur={handleBlur}
            // error={Boolean(
            //   errors.basicDetailsForm?.contactDetails?.phoneNumber &&
            //     touched.basicDetailsForm?.contactDetails?.phoneNumber
            // )}
            // fieldhelpertext={
            //   errors.basicDetailsForm?.contactDetails?.phoneNumber &&
            //   touched.basicDetailsForm?.contactDetails?.phoneNumber &&
            //   errors.basicDetailsForm?.contactDetails?.phoneNumber
            // }
          />
        </Grid>
        <Grid item lg={5} xl={5.5}>
          <TextFieldComponent
            label={"Email Address"}
            value={values?.basicDetailsForm?.contactDetails?.email}
            name="basicDetailsForm.contactDetails.email"
            onChange={handleChange}
            inputProps={{ maxLength: 40 }}
            sx={
              location?.state?.isViewMode && {
                pointerEvents: "none",
              }
            }
            onBlur={handleBlur}
            error={Boolean(
              errors?.basicDetailsForm?.contactDetails?.email &&
                touched.basicDetailsForm?.contactDetails?.email
            )}
            fieldhelpertext={
              errors.basicDetailsForm?.contactDetails?.email &&
              touched.basicDetailsForm?.contactDetails?.email &&
              errors.basicDetailsForm?.contactDetails?.email
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactDetails;
