export const enum TopicEnums {
    ProjectEnquiry = 106,
    SalesEnquiry = 107,
    Others = 105,
  }
  export class TopicEnumsUtils {
    public static getEnumText(type: TopicEnums): string {
      switch (type) {
        case TopicEnums.ProjectEnquiry:
          return "Project Enquiry";
        case TopicEnums.SalesEnquiry:
          return "Sales Enquiry";
  
        case TopicEnums.Others:
          return "Others";
      }
    }
  
    public static getTopicEnums(): {
      label: string;
      value: TopicEnums;
    }[] {
      const options: { label: string; value: TopicEnums }[] = [
        {
          label: this.getEnumText(TopicEnums.ProjectEnquiry),
          value: TopicEnums.ProjectEnquiry,
        },
        {
          label: this.getEnumText(TopicEnums.SalesEnquiry),
          value: TopicEnums.SalesEnquiry,
        },
  
        {
          label: this.getEnumText(TopicEnums.Others),
          value: TopicEnums.Others,
        },
      ];
      return options;
    }
  }
  