import { Constants } from "constants/Constants";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { faqRoutePathConst } from "pages/resource-management/faq/faqRoutePathConst";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    //get all faqs 
    getAllProjectFaqsSuccess: ["response"],
    getAllProjectFaqsFailure: ["error"],
    getAllProjectFaqsRequest: ["data"],

    //create faqs
    projectFaqCreationSuccess: ["response"],
    projectFaqCreationFailure: ["error"],
    projectFaqCreationRequest: ["data"],

    ///update faqs
    projectFaqUpdateSuccess: ["response"],
    projectFaqUpdateFailure: ["error"],
    projectFaqUpdateRequest: ["id", "data"],

    //bulk updated request
    projectFaqBulkUpdateFailure: ["error"],
    projectFaqBulkUpdateSuccess: ["response"],
    projectFaqBulkUpdateRequest: ["data"],

    //delete faqs
    projectFaqDeleteSuccess: ["response"],
    projectFaqDeleteFailure: ["error"],
    projectFaqDeleteRequest: ["data"],

    //bulkdelete Faqs
    projectFaqBulkDeleteFailure: ["error"],
    projectFaqBulkDeleteSuccess: ["response"],
    projectFaqBulkDeleteRequest: ["data"],

    //status priority update
    projectFaqPriorityUpdateRequest: ["data"],
    projectFaqPriorityUpdateSuccess: ["response"],
    projectFaqPriorityUpdateFailure: ["error"],


    setProjectFaqState: ["key", "value"]
}
);

export const ProjectFaqTypes = Types;

export const ProjectFaqActions = Creators

export const INITIAL_STATE = {
    error: null,
    isLoading: false,
    projectFaqs: null,
    totalCount: null,
    mappedFaqs: []
}



//getAllFaqs
export const getAllProjectFaqsRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        


    }
}
export const getAllProjectFaqsSuccess = (state: any, action: any) => {
    const response = action.response;
    const faqWithToggleButton = action?.response?.data.map((faqs: any) => {
        if (state?.mappedFaqs.indexOf(faqs.id) !== -1) {
            return {
                ...faqs,
                isMappedToggle: true
            }
        } else {
            return {
                ...faqs,
                isMappedToggle: false
            }
        }
    })
    return {
        ...state,
        isLoading: false,
        projectFaqs: faqWithToggleButton,
        totalCount: faqWithToggleButton?.length,
    }
}

export const getAllProjectFaqsFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
    }
}



//FAQS  Creation 
export const projectFaqCreationRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,

    }
}
export const projectFaqCreationSuccess = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.response,
        routeProjectToPage: faqRoutePathConst.CategoryTablePage,
    }
}

export const projectFaqCreationFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.error,
        isProjectFaqResponse: false,

    }
}

///FAQ UPDATED EVENT
export const projectFaqUpdateRequest = (state: any, action: any) => {

    return {
        ...state,
        isLoading: true,
        isProjectFaqResponse: false,


    }
}
export const projectFaqUpdateSuccess = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.response,
        routeProjectToPage: faqRoutePathConst.FaqListTablePage,
        isProjectFaqResponse: true,
    }
}

export const projectFaqUpdateFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        isProjectFaqResponse: false,
    }
}
// bulk update

export const projectFaqBulkUpdateRequest = (state: any, action: any) => {
    return {
        ...state,
        isLoading: true,
        projectFaqResponse:null,
        isProjectFaqResponse: false,
        routeProjectToPage:null

    }
}

export const projectFaqBulkUpdateSuccess = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.response,
        isProjectFaqResponse: true,
        routeProjectToPage: null

    }
}


export const projectFaqBulkUpdateFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.error,
        isProjectFaqResponse: true,
        routeProjectToPage: null

    }
}

//faq Delete event
export const projectFaqDeleteRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        projectFaqResponse: null,
        isProjectFaqResponse: false,
        routeProjectToPage:null,


    }
}
export const projectFaqDeleteSuccess = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.response,
        isProjectFaqResponse: true,
        routeProjectToPage: faqRoutePathConst.FaqListTablePage
    }
}

export const projectFaqDeleteFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.error,
        isProjectFaqResponse: true,
    }
}

//faq bulk delete
export const projectFaqBulkDeleteRequest = (state: any, action: any) => {
    return {
        ...state,
        isLoading: true,
        projectFaqResponse: null,
        isProjectFaqResponse: false,
        routeProjectToPage: null
    }
}

export const projectFaqBulkDeleteSuccess = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.response,
        isProjectFaqResponse: true,
        routeProjectToPage: null
    }
}


export const projectFaqBulkDeleteFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.error,
        isProjectFaqResponse: true,
        routeProjectToPage: null

    }
}

//UPDATED IN PRIORITY FAQS
export const projectFaqPriorityUpdateRequest = (state: any) => {

    return {
        ...state,
        isLoading: true,
        projectFaqResponse: null,
        isProjectFaqResponse: false,
        routeProjectToPage: null
      
    }
}
export const projectFaqPriorityUpdateSuccess = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.response,
        isProjectFaqResponse: true,
        routeProjectToPage: null
    }
}
export const projectFaqPriorityUpdateFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        projectFaqResponse: action.error,
        isProjectFaqResponse: true,
        routeProjectToPage: null
    }
}

export const setProjectFaqState = (state: any, action: any) => {
    const newState = {
        ...state,
    };
    newState[action.key] = action.value;
    return newState;
};


export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_ALL_PROJECT_FAQS_REQUEST]: getAllProjectFaqsRequest,
    [Types.GET_ALL_PROJECT_FAQS_SUCCESS]: getAllProjectFaqsSuccess,
    [Types.GET_ALL_PROJECT_FAQS_FAILURE]: getAllProjectFaqsFailure,

    [Types.PROJECT_FAQ_CREATION_SUCCESS]: projectFaqCreationSuccess,
    [Types.PROJECT_FAQ_CREATION_FAILURE]: projectFaqCreationFailure,
    [Types.PROJECT_FAQ_CREATION_REQUEST]: projectFaqCreationRequest,

    [Types.PROJECT_FAQ_UPDATE_SUCCESS]: projectFaqUpdateSuccess,
    [Types.PROJECT_FAQ_UPDATE_FAILURE]: projectFaqUpdateFailure,
    [Types.PROJECT_FAQ_UPDATE_REQUEST]: projectFaqUpdateRequest,

    [Types.PROJECT_FAQ_BULK_UPDATE_SUCCESS]: projectFaqBulkUpdateSuccess,
    [Types.PROJECT_FAQ_BULK_UPDATE_FAILURE]: projectFaqBulkUpdateFailure,
    [Types.PROJECT_FAQ_BULK_UPDATE_REQUEST]: projectFaqBulkUpdateRequest,

    [Types.PROJECT_FAQ_DELETE_SUCCESS]: projectFaqDeleteSuccess,
    [Types.PROJECT_FAQ_DELETE_FAILURE]: projectFaqDeleteFailure,
    [Types.PROJECT_FAQ_DELETE_REQUEST]: projectFaqDeleteRequest,

    [Types.PROJECT_FAQ_BULK_DELETE_SUCCESS]: projectFaqBulkDeleteSuccess,
    [Types.PROJECT_FAQ_BULK_DELETE_FAILURE]: projectFaqBulkDeleteFailure,
    [Types.PROJECT_FAQ_BULK_DELETE_REQUEST]: projectFaqBulkDeleteRequest,

    [Types.PROJECT_FAQ_PRIORITY_UPDATE_SUCCESS]: projectFaqPriorityUpdateSuccess,
    [Types.PROJECT_FAQ_PRIORITY_UPDATE_FAILURE]: projectFaqPriorityUpdateFailure,
    [Types.PROJECT_FAQ_PRIORITY_UPDATE_REQUEST]: projectFaqPriorityUpdateRequest,

    [Types.SET_PROJECT_FAQ_STATE]: setProjectFaqState
}) 