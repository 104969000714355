import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getProjectContentsSuccess: ["response"],
  getProjectContentsFailure: ["error"],
  getProjectContentsRequest: ["data"],
});

export const projectContentsTypes = Types;

export const projectContentsAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  projectContentsData: null,
};

export const getProjectContentsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getProjectContentsSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      projectContentsData: response.data,
      isLoading: false,
    };
  }
};

export const getProjectContentsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROJECT_CONTENTS_SUCCESS]: getProjectContentsSuccess,
  [Types.GET_PROJECT_CONTENTS_FAILURE]: getProjectContentsFailure,
  [Types.GET_PROJECT_CONTENTS_REQUEST]: getProjectContentsRequest,
});
