import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import LoadingButtonComponent from '../../../../components/ui-elements/button/LoadingButtonComponent'


const MediaToggleDialog = (props : any) => {
  const [open, setOpen] = React.useState(props.open);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false)
  };
  return (
    <div>
        <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{padding : '50px'}}>
          <DialogContentText id="alert-dialog-description" style={{color : "black"}}>
          {props.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{paddingBottom : '30px'}}>
          <LoadingButtonComponent onClick={handleClose} >Okay</LoadingButtonComponent>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default MediaToggleDialog