import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  SelectChangeEvent,
  styled,
  Typography,
  Divider,
} from "@mui/material";
import PDFViewDialog from "components/pdf-view-dialog/PDFViewDialog";
import { MediaCards, TextFieldComponent } from "components/ui-elements";
import { CustomerDocumentTypeEnum } from "enumerations/CustomerDocumentTypeEnum";
import { ActionTypeEnumUtils } from "enumerations/DocumentTypeEnums";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CustomerActions } from "redux-container/customer-management/CustomersListAndDetailsRedux";

const CustomContainer = styled(Box)`
  && .Loader_loading-container__ytNFU {
    height: 300px;
  }
`;

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerDocument: async (params: any) => {
      dispatch(CustomerActions.getCustomerDocumentRequest(params));
    },
    setCustomersState: (key: any, value: any) => {
      dispatch(CustomerActions.setCustomersState(key, value));
    },
  };
};

const OtherDocuments = () => {
  const location: any = useLocation();
  const [customerId, setCustomerId] = useState(location?.state);
  const [url, setURL] = useState("");

  const [isOpen, setIsopen] = useState(false);

  const { getCustomerDocument, setCustomersState } = actionDispatch(
    useDispatch()
  );
  // const customerDocument: any = useSelector((state: any) => "hi");
  const customerDocument: any = useSelector(
    (state: any) => state?.customerListAndDetails?.customerDocument
  );
  const customerOverviewDocuments: any = useSelector((state: any) =>
    state?.customerListAndDetails?.customerOverview?.documents?.sort(
      (a: any, b: any) => a.documentType - b.documentType
    )
  );
  const projectWiseDetails: any = useSelector(
    (state: any) =>
      state?.customerListAndDetails?.customerOverview?.projectWiseDetails
  );
  const [selectedProject, setSelectedProject] = useState<any>(
    projectWiseDetails ? projectWiseDetails[0] : ""
  );
  const [selectedInventory, setSelectedInvetory] = useState<any>(
    selectedProject ? selectedProject?.allDetails[0] : ""
  );

  useEffect(() => {
    if (projectWiseDetails) {
      setSelectedProject(projectWiseDetails[0]);
      setSelectedInvetory(projectWiseDetails[0]?.allDetails[0]);
    }
  }, [projectWiseDetails]);

  const [URLofDocument, setURLofDocument] = useState<any>();
  const [documentType, setDocumentType] = useState<any>("");

  const [shouldOpenPdf, setShouldOpenPdf] = useState<boolean>(false);
  const [isPdf, setIsPdf] = useState(false);
  const isDocumentLoading: any = useSelector(
    (state: any) => state?.customerListAndDetails?.isLoading
  );

  const [filePath, setFilePath] = useState<any>("");

  useEffect(() => {
    const linkSource = `data:application/pdf;base64,${customerDocument}`;
    setURL(linkSource);
  }, [customerDocument]);
  const dataUrl = `data:application/pdf;base64,${customerDocument}`;

  const onCloseModal = () => {
    setIsopen(false);
    setShouldOpenPdf(false);
    // setCustomersState("customerDocument", null);
  };

  const handleViewClick = async (
    path: string | null,
    documentType: string | undefined
  ) => {
    setFilePath(path);
    setDocumentType(documentType);
    await getCustomerDocument(path);

    setShouldOpenPdf(true);
    setIsopen(true);
    if (path?.toLowerCase().includes(".pdf")) {
      setIsPdf(true);
    } else {
      setIsPdf(false);
    }
  };

  function dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  useEffect(() => {
    if (!isDocumentLoading && customerDocument && shouldOpenPdf) {
      // if (!isDocumentLoading && customerDocument) {        
      const blob = dataURItoBlob(customerDocument);
      const url = URL.createObjectURL(blob);
      setURLofDocument(url);
      // window.open(url);
      // }
    }
  }, [customerDocument]);

  if (!(projectWiseDetails ? projectWiseDetails?.length > 0 : false)) {
    return (
      <>
        <Box></Box>
      </>
    );
  }

  return (
    <>
      {projectWiseDetails ? (
        <>
          {" "}
          <Grid container spacing={4} padding={"2rem"}>
            <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedProject}
                  label="Project"
                  onChange={(event: SelectChangeEvent) => {
                    let project: any = event?.target?.value;
                    setSelectedProject(project);
                    setSelectedInvetory(project?.allDetails[0]);
                  }}
                >
                  {projectWiseDetails?.map((project: any) => (
                    <MenuItem value={project} key={project.launchName}>
                      {project.launchName}
                    </MenuItem>
                  ))}
                </TextFieldComponent>
              </FormControl>
            </Grid>
            <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedInventory}
                  label="Plot Number"
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedInvetory(event.target.value);
                  }}
                >
                  {selectedProject?.allDetails?.map((inventory: any) => (
                    <MenuItem value={inventory} key={inventory.inventoryName}>
                      {inventory.inventoryName}
                    </MenuItem>
                  ))}
                </TextFieldComponent>
              </FormControl>
            </Grid>
          </Grid>
          {selectedInventory?.otherDocuments?.length > 0 ? (
            <Divider></Divider>
          ) : null}
          <Grid container padding={"2rem"} justifyContent={"flex-start"}>
            {selectedInventory?.otherDocuments?.map((document: any) => {
              return (
                <>
                  <Grid item lg={3} xl={3} marginBottom={"2rem"}>
                    <Typography gutterBottom>
                      {ActionTypeEnumUtils.getEnumText(document?.documentType)}
                    </Typography>
                    <MediaCards
                      handleViewClick={handleViewClick}
                      path={document.path}
                      mediaName={document.name}
                      documentType={document.documentType}
                      mediaUploadDate={document.updatedAt}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </>
      ) : null}
      {/* <Grid
        container
        padding={"2rem"}
        justifyContent={"flex-start"}
        rowSpacing={3}
      >
        {customerOverviewDocuments?.map((document: any) => {
          if (
            document?.documentType !== CustomerDocumentTypeEnum.PAN_CARD &&
            document?.documentType !== CustomerDocumentTypeEnum.ADDRESS_PROOF
          ) {
            return (
              <>
                <Grid item lg={3} xl={3}>
                  <Typography gutterBottom>
                    {ActionTypeEnumUtils.getEnumText(document?.documentType)}
                  </Typography>
                  <MediaCards
                    handleViewClick={handleViewClick}
                    path={document.path}
                    mediaName={document.name}
                    documentType={document.documentType}
                    mediaUploadDate={document.updatedAt}
                  />
                </Grid>
              </>
            );
          }
        })}
      </Grid> */}
      {isOpen ? (
        <PDFViewDialog
          shouldOpen={isOpen}
          content={isPdf ? URLofDocument : customerDocument}
          cancelHandler={onCloseModal}
          isLoading={isDocumentLoading}
          documentType={ActionTypeEnumUtils.getEnumText(Number(documentType))}
          filePath={filePath}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default OtherDocuments;
