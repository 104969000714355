import { Box, Stack, styled } from "@mui/material";

export const MediaCardsStyleWrapper = styled<any>(Stack)`
  max-width: 16rem;
  border: 1px solid
    ${(props) =>
      props.mediaName !== null
        ? props.theme.palette.common.black
        : props.theme.palette.grey[400]};
  border-radius: 8px;
  padding: 0 0.5rem;
  height: ${(props) => (props.mediaName !== null ? "80px" : "auto")};
  justify-content: ${(props) =>
    props.mediaName !== null ? "center" : "space-between"};
 &&&& .MuiGrid-root{
    margin-bottom:"2rem"
  }
`;
export const ModalContent = styled(Box)(({ theme }) => ({
  "&& .pg-viewer-wrapper::-webkit-scrollbar": {
      display: "none"
  },
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  backgroundColor: "grey",
  border: "2px solid #FFFFF",
  color: "white",
  [theme.breakpoints.down('sm')]: {
      width: 320,
      height: 320,
  },
  [theme.breakpoints.up('xs')]: {
      width: 265,
      height: 265,
  },
  ".MuiIconButton-root": {
      position: "absolute",
      top: "-15%",
      right: "-15%",
      color: theme.palette.common.white,
      ".MuiSvgIcon-root": {
          width: "2em",
          height: "2em",
      },
  },
 
}));

export const PreviewBox = styled('img')(() => ({
  width: "100%",
  height: "100%"
}))