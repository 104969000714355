import { MediaUploadComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import UpcomingProjectsMediaUpload from "../upcomming-projects/UpcomingProjectsMediaUpload";

const actionDispatch = (dispatch: any) => {
  return {
    setUploadFileState: (key: any, data: any) => {
      dispatch(FileUploadActions.setUploadFileState(key, data));
    },
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
    fileDeleteRequest: (data: any) => {
      dispatch(FileUploadActions.fileDeleteRequest(data));
    },
  };
};

function ProjectIconUploader(props: any) {
  const { values, setFieldValue, errors, touched } =
    useFormikContext<IInitinalValueProjectContent>();
  const { Id, name, mediaWidth, mediaHeight } = props;

  const { imageDetails, isLoading } = useSelector(
    (state: any) => state.fileUpload
  );
  const { setUploadFileState, fileUploadRequest, fileDeleteRequest } =
    actionDispatch(useDispatch());
  const [clickedImageUploaderName, setClickedImageUploaderName] = useState("");
  const [clickedImageUploaderId, setClickedImageUploaderId] = useState(0);
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      console.log("dataaaaa", data);

      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);
      console.log("project icon ==>", file);

      setFieldValue(`projectIcon.key`, file?.name);
      setFieldValue(`projectIcon.name`, file?.name);
      setFieldValue(`projectIcon.value.size`, file?.size);
      setFieldValue(`projectIcon.value.mediaType`, file?.type);
      setFieldValue(`projectIcon.value.height`, mediaHeight);
      setFieldValue(`projectIcon.value.width`, mediaWidth);
    }
  };
  const cancelImageHandler = (event: any) => {
    // setClickedImageUploaderName(event.target.name);
    const imageName: any =
      values?.projectCoverImages[clickedImageUploaderName]?.name;

    fileDeleteRequest(
      values?.projectCoverImages[clickedImageUploaderName]?.name
    );

    console.log("clickedImageUploaderName ==>", clickedImageUploaderName);

    setFieldValue(`projectCoverImages.${clickedImageUploaderName}.name`, "");
    setFieldValue(`projectCoverImages.${clickedImageUploaderName}.key`, "");

    setFieldValue(
      `projectCoverImages.${clickedImageUploaderName}.value.size`,
      ""
    );
    setFieldValue(
      `projectCoverImages.${clickedImageUploaderName}.value.url`,
      ""
    );
    setFieldValue(
      `projectCoverImages.${clickedImageUploaderName}.value.mediaType`,
      ""
    );
    setFieldValue(
      `projectCoverImages.${clickedImageUploaderName}.value.height`,
      ""
    );
    setFieldValue(
      `projectCoverImages.${clickedImageUploaderName}.value.width`,
      ""
    );
  };

  useEffect(() => {
    if (imageDetails) {
      const imageRes = imageDetails;
      setUploadFileState("imageDetails", null);

      if (clickedImageUploaderId !== 0 && clickedImageUploaderId == Id) {
        const projectIcon: any = { ...values.projectIcon };
        const { value } = projectIcon;
        value.url = imageRes?.url;
        setFieldValue(`projectIcon`, projectIcon);

        // console.log(values);
        // values.projectIcon.value = {
        //   ...values.projectIcon.value,
        //   url: imageRes?.url,
        // };
        //  values.projectIcon = {

        //   }
        //   values.projectIcon = {
        //     ...values.projectIcon,
        //     value: values.projectIcon?.value,
        //   };

        // projectCoverImages[clickedImageUploaderName].value = {
        //   ...projectCoverImages[clickedImageUploaderName].value,
        //   url: imageRes?.url,
        // };
        // setFieldValue(`projectCoverImages`, projectCoverImages);
        setClickedImageUploaderId(0);
        setClickedImageUploaderName("");
      }
    }
  }, [imageDetails]);

  const onChangeHandler = (event: any) => {
    const fileExtension = event?.target?.value?.slice(((event?.target?.value?.lastIndexOf(".") - 1) >>> 0) + 2);
    if(fileExtension == "png" || fileExtension == "jpg" || fileExtension == "jpeg") {
      handleImageUpload(event);
      setClickedImageUploaderId(Id);
      setClickedImageUploaderName(event.target.name);
    }
    else {
      alert("Only .png, .jpg, .jpeg is accepted !");
    }
  };

  return (
    <>
      <UpcomingProjectsMediaUpload
        name={name}
        // inputId={name}
        onChange={onChangeHandler}
        cancelimage={() => cancelImageHandler(Id)} //won't gives an event so use id prop.
        previewimage={values?.projectIcon?.value?.url}
        loading={clickedImageUploaderId === Id ? isLoading : false}
        mediaHeight={mediaHeight}
        mediaWidth={mediaWidth}
        disabled={isViewMode}
      />
    </>
  );
}

export default ProjectIconUploader;
