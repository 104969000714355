import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Stack,
  CardMedia,
  CardActions,
  Card,
} from "@mui/material";
import { useFormikContext } from "formik";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VideoLabelTwoToneIcon from "@mui/icons-material/VideoLabelTwoTone";
import { getYoutubeVideoId } from "utils/ValidationUtils";
import { number } from "yargs";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { useState, useEffect } from "react";

const tableHeadings = [
  { heading: "Thumbnail" },
  { heading: "Media Type" },
  { heading: "Redirect to" },
  { heading: "Priority" },
  { heading: "Action" },
];

function CarouselList(props: any) {
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  console.log("CarouselList values", values);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [carouselItemToDelete, setcarouselItemToDelete] = useState<any>({});

  console.log("isConfirmDeleteDialogOpen", isConfirmDeleteDialogOpen);

  const getYoutubeVideoThumbnail = (url: any) => {
    return `https://img.youtube.com/vi/${getYoutubeVideoId(url)}/mqdefault.jpg`;
  };

  const deleteCarouselItem = (index: number, pageManagementOrCarousel: any) => {
    console.log("index>", index, "item", pageManagementOrCarousel);

    const locallistArray: any[] = values?.pageManagementOrCarousels;
    console.log("locallistArray", locallistArray);

    locallistArray?.splice(index, 1);
    console.log("locallistArray splice", locallistArray);

    // const updatedArray: any = locallistArray?.map(
    //   (item: any) => {
    //     return {
    //       ...item,
    //       priority: item.priority - 1,
    //     };
    //   }
    // );
    for (let i = index; i < locallistArray.length; i++) {
      console.log("locallistArray loop", locallistArray);
      locallistArray[i].priority = locallistArray[i].priority - 1;
    }

    const localCarouselsToDelete: any[] = values?.carouselsToDelete;
    console.log("localCarouselsToDelete1", pageManagementOrCarousel);

    localCarouselsToDelete?.push(pageManagementOrCarousel?.id);
    setFieldValue("carouselsToDelete", localCarouselsToDelete);

    console.log("localCarouselsToDelete2", localCarouselsToDelete);

    setFieldValue("pageManagementOrCarousels", locallistArray);

    if (!locallistArray?.length) {
      setFieldValue("isCarouselMediaPresent", false);
    } else if (locallistArray?.length > 0 && !values?.isCarouselMediaPresent) {
      setFieldValue("isCarouselMediaPresent", true);
    }

    setIsConfirmDeleteDialogOpen(false);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className="table-container"
        style={{ height: window.innerHeight - 475 }}
      >
        {values?.pageManagementOrCarousels?.map((item: any) => {
          return <></>;
        })}
        <Table>
          <TableHead style={{ position: "sticky", top: 0, zIndex: 2 }}>
            <TableRow
              className="table-header-row"
              style={{ borderTopWidth: 0 }}
            >
              {tableHeadings?.map((item, index) => (
                <TableCell key={index} className="table-header-text">
                  {item.heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {values?.pageManagementOrCarousels &&
          values?.pageManagementOrCarousels?.length > 0 ? (
            <TableBody>
              {values?.pageManagementOrCarousels.map(
                (pageManagementOrCarousel: any, index: number) => {
                  return (
                    <TableRow
                      className="table-data-row"
                      hover
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      // selected={isItemSelected}
                    >
                      <TableCell
                        className="table-data-text"
                        style={{ width: "150px" }}
                      >
                        <Card
                          elevation={0}
                          sx={{
                            position: "relative",
                            "& .MuiSvgIcon-root": {
                              width: "4rem",
                              height: "4rem",
                              marginLeft: "1.5rem",
                            },
                          }}
                          // className={styles["media-container"]}
                        >
                          {pageManagementOrCarousel?.media?.value?.mediaType ==
                          "IMAGE" ? (
                            <CardMedia
                              // className={styles["media-area"]}
                              component={"img"}
                              alt="Image"
                              src={pageManagementOrCarousel?.media?.value?.url}
                            />
                          ) : pageManagementOrCarousel?.media?.value
                              ?.mediaType == "YOUTUBE" ? (
                            <>
                              <CardMedia
                                // className={styles["media-area"]}
                                component={"img"}
                                alt="Image"
                                src={getYoutubeVideoThumbnail(
                                  pageManagementOrCarousel?.media?.value?.url
                                )}
                              />
                              <CardActions
                                // className={styles["trash-icon"]}
                                sx={{
                                  position: "absolute",
                                  left: "-10%",
                                  top: "-18%",
                                }}
                              >
                                <IconButton>
                                  <PlayCircleOutlineIcon
                                    sx={{
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "1rem",
                                        md: "1rem",
                                        lg: "1rem",
                                        xl: "2rem",
                                      },
                                      color: "common.white",
                                    }}
                                  />
                                </IconButton>
                              </CardActions>
                            </>
                          ) : (
                            <VideoLabelTwoToneIcon fontSize="large" />
                          )}
                        </Card>
                      </TableCell>

                      <TableCell
                        style={{
                          minWidth: 150,
                          maxWidth: 300,
                          wordBreak: "break-all",
                        }}
                        className="table-data-text"
                        align={"left"}
                      >
                        {pageManagementOrCarousel?.media?.value?.mediaType ==
                        "IMAGE"
                          ? "Image"
                          : pageManagementOrCarousel?.media?.value?.mediaType ==
                            "VIDEO"
                          ? "Video"
                          : "YouTube Link"}{" "}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 100 }}
                        className="table-data-text"
                      >
                        {pageManagementOrCarousel?.projectContent?.id !== "0" &&
                        pageManagementOrCarousel?.projectContent?.id
                          ? pageManagementOrCarousel?.projectContent?.launchName
                          : pageManagementOrCarousel?.redirectToPage == 7000
                          ? "Refer Now"
                          : "----"}{" "}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 140 }}
                        className="table-data-text"
                      >
                        {/* {index + 1}{" "} */}
                        <PrioritySelector
                          priority={pageManagementOrCarousel?.priority}
                          incrementpriority={() => {
                            const locallistArray: any[] =
                              values?.pageManagementOrCarousels;
                            locallistArray[index].priority =
                              locallistArray[index]?.priority + 1;
                            locallistArray[index + 1].priority =
                              locallistArray[index]?.priority - 1;

                            const temp = locallistArray[index];
                            locallistArray[index] = locallistArray[index + 1];
                            locallistArray[index + 1] = temp;

                            setFieldValue(
                              "pageManagementOrCarousels",
                              locallistArray
                            );
                          }}
                          decrementpriority={() => {
                            const locallistArray: any[] =
                              values?.pageManagementOrCarousels;
                            locallistArray[index].priority =
                              locallistArray[index]?.priority - 1;
                            locallistArray[index - 1].priority =
                              locallistArray[index]?.priority + 1;

                            const temp = locallistArray[index];
                            locallistArray[index] = locallistArray[index - 1];
                            locallistArray[index - 1] = temp;

                            setFieldValue(
                              "pageManagementOrCarousels",
                              locallistArray
                            );
                          }}
                          disableDecrement={
                            values?.pageManagementOrCarousels[index]
                              ?.priority == 1
                          }
                          disableIncrement={
                            values?.pageManagementOrCarousels[index]
                              ?.priority ==
                            values?.pageManagementOrCarousels?.length
                          }
                        />
                      </TableCell>

                      <TableCell style={{ width: "110px" }}>
                        <Stack justifyContent={"center"}>
                          <IconButton
                            aria-label="view"
                            onClick={() =>
                              props.setOpenMediaUploadDialog({
                                open: true,
                                pageManagementOrCarousel:
                                  pageManagementOrCarousel,
                              })
                            }
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              console.log(
                                "index1",
                                index,
                                "pageManagementOrCarousel",
                                pageManagementOrCarousel
                              );
                              setcarouselItemToDelete({
                                index: index,
                                pageManagementOrCarousel:
                                  pageManagementOrCarousel,
                              });
                              setIsConfirmDeleteDialogOpen(true);
                            }}
                          >
                            <Typography
                              sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              Remove
                            </Typography>
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Are you sure you want to Remove ?"
        content={``}
        okText="Yes"
        cancelBtnText="No"
        cancelHandler={() => setIsConfirmDeleteDialogOpen(false)}
        okHandler={() => {
          deleteCarouselItem(
            carouselItemToDelete?.index,
            carouselItemToDelete?.pageManagementOrCarousel
          );
          setcarouselItemToDelete({});
        }}
      />
    </>
  );
}

export default CarouselList;
