export interface IInitinalValueProjectContent {
  launchName: string | null;

  shortDescription: string;
  projectPhoneNumber: string | null;
  address: {
    city: string;
    state: string;
    country: string;
  };
  projectIcon: {
    name: string;
    key: string;
    value: {
      url: string;
      size: number;
      width: number;
      height: number;
      mediaType: string;
    };
  };
  bannerImage: {
    name: string;
    key: string;
    value: {
      url: string;
      size: number;
      width: number;
      height: number;
      mediaType: string;
    };
  };
  opportunityDoc: {
    isCurrentInfraStoryActive: boolean;
    isCurrentInfraStoryActiveForWeb: boolean;
    isUpcomingInfraStoryActive: boolean;
    isUpcomingInfraStoryActiveForWeb: boolean;
    isTourismAroundActive: boolean;
    isTourismAroundActiveForWeb: boolean;

    currentInfraStory: {
      heading: string;
      stories: {
        title: string;
        description: string;
        media: {
          name: string;
          key: string;
          value: {
            url: string;
            size: number;
            width: number;
            height: number;
            mediaType: string;
          };
        };
      }[];
    };
    upcomingInfraStory: {
      heading: string;
      stories: {
        title: string;
        description: string;
        media: {
          name: string;
          key: string;
          value: {
            url: string;
            size: number;
            width: number;
            height: number;
            mediaType: string;
          };
        };
      }[];
    };
    tourismAround: {
      heading: string;
      stories: {
        title: string;
        description: string;
        media: {
          name: string;
          key: string;
          value: {
            url: string;
            size: number;
            width: number;
            height: number;
            mediaType: string;
          };
        };
      }[];
    };
  };
  isKeyPillarsActive: boolean;
  isKeyPillarsActiveForWeb: boolean;
  keyPillars: {
    heading: string;
    values: {
      name: string;
      icon: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      points: string[];
    }[];
  };
}

const InitinalValueProjectContent: IInitinalValueProjectContent = {
  launchName: "",
  shortDescription: "",
  address: {
    city: "",
    state: "",
    country: "",
  },
  projectPhoneNumber: "",
  projectIcon: {
    name: "",
    key: "",
    value: {
      url: "",
      size: 0,
      width: 0,
      height: 0,
      mediaType: "image",
    },
  },
  bannerImage: {
    name: "",
    key: "",
    value: {
      url: "",
      size: 0,
      width: 0,
      height: 0,
      mediaType: "image",
    },
  },
  opportunityDoc: {
    isCurrentInfraStoryActive: false,
    isCurrentInfraStoryActiveForWeb: false,

    isUpcomingInfraStoryActive: false,
    isUpcomingInfraStoryActiveForWeb: false,
    
    isTourismAroundActive: false,
    isTourismAroundActiveForWeb: false,

    currentInfraStory: {
      heading: "",
      stories: [
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
      ],
    },

    upcomingInfraStory: {
      heading: "",
      stories: [
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
      ],
    },

    tourismAround: {
      heading: "",
      stories: [
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
      ],
    },
  },
  isKeyPillarsActive: false,
  isKeyPillarsActiveForWeb: false,
  keyPillars: {
    heading: "",
    values: [
      {
        name: "",
        icon: {
          name: "",
          key: "",
          value: {
            url: "",
            size: 0,
            width: 1024,
            height: 1024,
            mediaType: "image",
          },
        },
        points: [""],
      },
      {
        name: "",
        icon: {
          name: "",
          key: "",
          value: {
            url: "",
            size: 0,
            width: 1024,
            height: 1024,
            mediaType: "image",
          },
        },
        points: ["", ""],
      },
    ],
  },
};
export { InitinalValueProjectContent };
