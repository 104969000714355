import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import {
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useFormikContext } from "formik";
import { FAQcreationLabels, IFaqProps } from "../FAQInitialValues";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "./../../../../../components/ui-elements/button/ButtonComponent";
import { faqUpdateSuccess } from "./../../../../../redux-container/faq/GeneralFaqRedux";
import { GenealFaqActions } from "redux-container/faq/GeneralFaqRedux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RouteConstants } from "routes/RouteConstants";
import { format, compareAsc } from "date-fns";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import axios from "axios";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";

interface IFaqFieldProps {
  fieldId: number;
  handleDelete: (index: number) => void;
  values: IFaqProps;
  questionFieldName: string;
  answerFieldName: string;
  questionFieldValue: string;
  answerFieldValue: string;
  touchFAQquestion: any;
  touchFAQAnswer: any;
  errorFAQquestion: any;
  errorFAQAnswer: any;
  priorityFieldValue: any;
  priorityFieldName: any;
  isEdit: boolean;
  isEditName: string;
  faqId: number | string;
  isEditLocale: any;
  settingIconAble?: () => void;
  setPreviousValuesOfCreation?: any;
  previousValuesOfCreation?: any;
}

const actionDispatch = (dispatch: any) => {
  return {
    generalFaqUpdate: (id: any, data: any) =>
      dispatch(GenealFaqActions.faqUpdateRequest(id, data)),
    deleteFaq: (id: any) => dispatch(GenealFaqActions.faqDeleteRequest(id)),
    setFaqState: (key: any, value: any) =>
      dispatch(GenealFaqActions.setFaqState(key, value)),
    getAllFaqs: (data: any) =>
      dispatch(GenealFaqActions.getAllFaqsRequest(data)),
    bulkFaqsUpdate: (data: any) =>
      dispatch(GenealFaqActions.faqBulkUpdateRequest(data)),
    bulkFaqsDelete: (data: any) =>
      dispatch(GenealFaqActions.faqBulkDeleteRequest(data)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
  };
};
const NewFAQBox = (props: IFaqFieldProps) => {
  const {
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    isValid,
    dirty,
    errors,
  } = useFormikContext<any>();
  const {
    responseData,
    categories,
    isSuccessful,
    isLoading,
    isSuccessfulUpdated,
    shouldAudit,
  } = useSelector((state: any) => state?.generalFaq);
  const users = useSelector((state: any) => state?.users.users);
  const faqData = useSelector((state: any) => state?.faq);
  // const [isEditLocale, setIsEditLocale] = useState(false)
  const location: any = useLocation();
  const allCategories = useSelector(
    (state: any) => state?.categories?.categories
  );
  const modules = useSelector((state: any) => state?.rolesAndModules?.module);
  const [anchor, setAnchor] = useState(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [modulesAuditedForUpdate, setModulesAuditedForUpdate] =
    useState<any>(null);
  const [ipAddress, setIpAddress] = useState<any>(null);

  const {
    generalFaqUpdate,
    deleteFaq,
    setFaqState,
    getAllFaqs,
    bulkFaqsUpdate,
    bulkFaqsDelete,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());
  const navigate = useNavigate();

  const popOverComponent = (event: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
  };
  const handleClickAway = () => {
    setOpen(false);
  };

  const {
    questionFieldName,
    answerFieldName,
    answerFieldValue,
    questionFieldValue,
    priorityFieldValue,
    priorityFieldName,
    fieldId,
    handleDelete,
    touchFAQquestion,
    touchFAQAnswer,
    errorFAQAnswer,
    errorFAQquestion,
    isEditName,
    isEdit,
    faqId,
    isEditLocale,
    settingIconAble,
    previousValuesOfCreation,
    setPreviousValuesOfCreation,
  } = props;

  const faqStatus = faqData?.faqs?.find(
    (faq: any) => faq.id === location.state.id
  );
  const createdAdminId = users?.find(
    (userId: any) => userId.id === location?.state?.faqsCreatedAdmin?.id
  );
  const updatedAdminId = users?.find(
    (userId: any) => userId.id === location?.state?.faqsUpdatedAdmin?.id
  );
  const handleMarkStatus = () => {
    let auditObject = {
      itemAffected: `FAQ Status '${location?.state?.faqQuestion?.question}'`,
      newValue: location.state.status === true ? "Inactive" : "Published",
      oldValue: location.state.status === true ? "Published" : "Inactive",
    };

    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Resources Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: RouteConstants?.faqCreation,
      auditedObject: {
        ...location.state,
        status: !location.state.status,
      },
      modulesAudited: [auditObject],
      status: AuditStatus.EDITED,
    };
    postAuditLogRequest(postAuditRequestbody);

    if (faqStatus.status === true) {
      const reqBody = {
        faqIds: [location.state.id],
        status: false,
        categoryId: location?.state?.categoryId,
      };
      bulkFaqsUpdate(reqBody);
    } else {
      const reqBody = {
        faqIds: [location.state.id],
        status: true,
        categoryId: location?.state?.categoryId,
      };
      bulkFaqsUpdate(reqBody);
    }
  };

  const deleteFaqsOnSelect = () => {
    const reqBody = {
      faqIds: [Number(location?.state?.id)],
    };
    deleteFaq(Number(location?.state?.id));
    setIsConfirmDeleteDialogOpen(false);
  };

  // delete dialog openFaqEditDialog
  const handleCloseLogoutDialog = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const handleCloseConfirmDialog = (id: any) => {
    setIsConfirmDeleteDialogOpen(false);
  };

  useEffect(() => {
    getIpAddress();
    return () => {};
  }, []);
  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIpAddress(res.data.IPv4);
  };
  useEffect(() => {
    if (shouldAudit && modulesAuditedForUpdate?.length > 0) {
      const moduleObject: any = modules?.find((element: any) => {
        return element?.name === "Resources Management";
      });
      const postAuditRequestbody = {
        ipAddress: ipAddress, //ipAddress
        moduleId: moduleObject?.id,
        route: null,
        auditedObject: null,
        modulesAudited: modulesAuditedForUpdate,
        status: null,
      };
      postAuditLogRequest(postAuditRequestbody);
      setFaqState("shouldAudit", false);
    }
  }, [shouldAudit]);
  const handlePublish = (values: any) => {
    //here u need to use location.state as auditObject because we dont have all the data in values .
    const reqBody = {
      category: location?.state?.categoryId,
      faqQuestion: {
        question: questionFieldValue,
      },
      faqAnswer: {
        answer: answerFieldValue,
      },
    };
    auditLogHandler(values);
    generalFaqUpdate(faqId, reqBody);
  };

  const auditLogHandler = (values: any) => {
    let auditObjectsArray: any[] = [];

    values?.faqs?.map((faq: any) => {
      Object.keys(values)?.map((valuesKeys: any) => {
        //Values objectkeys getting
        if (typeof values[valuesKeys] == "object") {
          values[valuesKeys]?.map(
            (faqsArrayObject: any, faqsArrayObjectIndex: number) => {
              Object.keys(values[valuesKeys][faqsArrayObjectIndex])?.map(
                (faqsArrayObjectKeys: any) => {
                  //faqsArrayObject objectkeys getting
                  if (
                    typeof values[valuesKeys][faqsArrayObjectIndex][
                      faqsArrayObjectKeys
                    ] == "object"
                  ) {
                    Object.keys(
                      values[valuesKeys][faqsArrayObjectIndex][
                        faqsArrayObjectKeys
                      ]
                    )?.map((faqsArrayObjectKeysObjectKeys: any) => {
                      if (
                        faqsArrayObjectKeysObjectKeys === "question" ||
                        (faqsArrayObjectKeysObjectKeys == "answer" &&
                          values[valuesKeys][faqsArrayObjectIndex][
                            faqsArrayObjectKeys
                          ][faqsArrayObjectKeysObjectKeys] !==
                            previousValuesOfCreation[valuesKeys][
                              faqsArrayObjectIndex
                            ][faqsArrayObjectKeys][
                              faqsArrayObjectKeysObjectKeys
                            ])
                      ) {
                        let auditObject = {
                          itemAffected: `'${location?.state?.category?.name}' Category FAQ ${FAQcreationLabels[valuesKeys][faqsArrayObjectIndex][faqsArrayObjectKeys][faqsArrayObjectKeysObjectKeys]}`,
                          newValue:
                            values[valuesKeys][faqsArrayObjectIndex][
                              faqsArrayObjectKeys
                            ][faqsArrayObjectKeysObjectKeys],
                          oldValue:
                            previousValuesOfCreation[valuesKeys][
                              faqsArrayObjectIndex
                            ][faqsArrayObjectKeys][
                              faqsArrayObjectKeysObjectKeys
                            ],
                          auditedObject: {
                            // ...values,
                            // faqs: [faqsArrayObject],
                            ...location?.state,
                            faqQuestion: {
                              ...location?.state?.faqQuestion,
                              question: values?.faqs[0]?.faqQuestion?.question,
                            },
                            faqAnswer: {
                              ...location?.state?.faqAnswer,
                              answer: values?.faqs[0]?.faqAnswer?.answer,
                            },
                            isEditField: true,
                            isCreatfield: true,
                          },
                          route: location.pathname,
                          status: AuditStatus.EDITED,
                        };
                        auditObjectsArray.push(auditObject);
                      }
                    });
                  }
                }
              );
            }
          );
        }
      });
    });

    setModulesAuditedForUpdate(auditObjectsArray);
  };
  return (
    <FormFieldContainer>
      <Grid container mb={"0.5rem"} alignItems={"center"}>
        <Grid item xs={2}>
          <Stack justifyContent={"flex-start"} spacing={2}>
            <Typography color={"common.black"} variant="h6">
              FAQ no.0{fieldId + 1}
            </Typography>
            <IconButton
              onClick={(event) => popOverComponent(event)}
              disabled={
                location?.state && !isEditLocale && location?.state.isViewField
              }
            >
              {location.state?.isEditField ? (
                <SettingsOutlinedIcon fontSize="medium" />
              ) : null}
            </IconButton>
          </Stack>
          {location.state?.isEditField && (
            <Typography color={"grey.A700"} variant="body1">
              Status:
              {faqStatus?.status ? "Published" : "Inactive"}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          {/* {location.state?.isEditField && !isEditLocale ? (
            <Stack justifyContent={"flex"} columnGap={1}>
              <Typography
                color={"gray.A700"}
                variant="body2"
              >
                Created on:
              </Typography>
              {`${format(new Date(location?.state?.createdAt), 'dd/MM/yyyy')} by `}

              <Typography
                component={"span"}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => navigate(`${RouteConstants.userCreation}`, {
                  state: { ...createdAdminId, isView: true },
                })}
              >
                {`${location?.state.faqsCreatedAdmin?.firstName}`}
              </Typography>
              {location?.state?.faqsUpdatedAdmin ? "Modified on:" : ""}
              {`${location.state.faqsUpdatedAdmin ? format(new Date(location?.state?.updatedAt), 'dd/MM/yyyy') : ""} `}
              {location?.state?.faqsUpdatedAdmin ? "by" : ""}
              <Typography
                component={"span"}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => navigate(`${RouteConstants.userCreation}`, {
                  state: { ...updatedAdminId, isView: true },
                })}
              >
                {`${location?.state?.faqsUpdatedAdmin ? location?.state?.faqsUpdatedAdmin?.firstName : ""}`}
              </Typography>
              <Typography color={"gray.A700"}
                variant="body1" sx={{ textDecoration: "underline", cursor: "pointer" }}>View Modified Content</Typography>
            </Stack>
          ) : null} */}
        </Grid>
        <Grid item xs={4}>
          {
            <Stack justifyContent={"flex-end"} spacing={1}>
              {location.state.isCreatfield &&
                location.state.isEditField &&
                location.state.isViewField &&
                !isEditLocale && (
                  <Stack spacing={1}>
                    <ButtonComponent
                      onClick={() => {
                        navigate(RouteConstants.faqCreation, {
                          state: {
                            ...location?.state,
                            category: location.state.category,
                            isEditField: true,
                            isCreatfield: false,
                          },
                        });
                      }}
                    >
                      close
                    </ButtonComponent>
                    <ButtonComponent
                      variant="contained"
                      onClick={settingIconAble}
                    >
                      Edit
                    </ButtonComponent>
                  </Stack>
                )}

              {location.state.isCreatfield &&
                location.state.isEditField &&
                (!location.state.isViewField || isEditLocale) && (
                  <Stack spacing={2}>
                    <ButtonComponent
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </ButtonComponent>
                    <LoadingButtonComponent
                      onClick={() => handlePublish(values)}
                      disabled={!isValid || !dirty}
                    >
                      Publish Changes
                    </LoadingButtonComponent>
                  </Stack>
                )}
              {values.faqs.length > 1 ? (
                location?.state?.isCreatfield &&
                location.state.isEditField ? null : (
                  <IconButton
                    size="small"
                    color="error"
                    aria-label="delete"
                    onClick={() => {
                      handleDelete(fieldId);
                      let FaqArray = previousValuesOfCreation?.faqs;
                      FaqArray?.splice(fieldId, 1);
                      setPreviousValuesOfCreation({
                        ...previousValuesOfCreation,
                        faqs: FaqArray,
                      });
                    }}
                  >
                    <DeleteOutlineOutlinedIcon fontSize="large" />
                  </IconButton>
                )
              ) : null}
            </Stack>
          }
        </Grid>
      </Grid>
      <Box mb={"0.5rem"}>
        {" "}
        {location.state?.isEditField && !isEditLocale ? (
          <Stack justifyContent={"flex"} columnGap={1}>
            <Typography color={"gray.A700"} variant="body2">
              Created on:
            </Typography>
            {`${format(
              new Date(location?.state?.createdAt),
              "dd/MM/yyyy"
            )} by `}

            <Typography
              component={"span"}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() =>
                navigate(`${RouteConstants.userCreation}`, {
                  state: { ...createdAdminId, isView: true },
                })
              }
            >
              {`${location?.state.faqsCreatedAdmin?.firstName}`}
            </Typography>
            {location?.state?.faqsUpdatedAdmin ? "Modified on:" : ""}
            {`${
              location.state.faqsUpdatedAdmin
                ? format(new Date(location?.state?.updatedAt), "dd/MM/yyyy")
                : ""
            } `}
            {location?.state?.faqsUpdatedAdmin ? "by" : ""}
            <Typography
              component={"span"}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() =>
                navigate(`${RouteConstants.userCreation}`, {
                  state: { ...updatedAdminId, isView: true },
                })
              }
            >
              {`${
                location?.state?.faqsUpdatedAdmin
                  ? location?.state?.faqsUpdatedAdmin?.firstName
                  : ""
              }`}
            </Typography>
            <Typography
              color={"gray.A700"}
              variant="body1"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              View Modified Content
            </Typography>
          </Stack>
        ) : null}
      </Box>
      <Grid container columnSpacing={8} rowSpacing={2}>
        <Grid item xs={12}>
          <TextFieldComponent
            value={questionFieldValue}
            label="Question *"
            name={questionFieldName}
            disabled={
              location?.state && !isEditLocale && location?.state.isViewField
            }
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{ maxLength: 150 }}
            placeholder="Enter"
            error={Boolean(touchFAQquestion && errorFAQquestion)}
            fieldhelpertext={
              touchFAQquestion && errorFAQquestion ? errorFAQquestion : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            multiline
            rows={6}
            value={answerFieldValue}
            onChange={handleChange}
            name={answerFieldName}
            inputProps={{ maxLength: 500 }}
            disabled={
              location?.state && !isEditLocale && location?.state.isViewField
            }
            onBlur={handleBlur}
            label="Answer *"
            placeholder="Enter"
            error={Boolean(touchFAQAnswer && errorFAQAnswer)}
            fieldhelpertext={
              touchFAQAnswer && errorFAQAnswer ? errorFAQAnswer : ""
            }
          />
        </Grid>
      </Grid>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Popper open={open} placement="right-start" anchorEl={anchor}>
          <Paper elevation={12}>
            <MenuList>
              <MenuItem sx={{ bgcolor: "white" }} onClick={handleMarkStatus}>
                {faqStatus?.status ? " Mark as Inactive" : "Mark as Published"}
              </MenuItem>
              <MenuItem
                sx={{ bgcolor: "white" }}
                onClick={handleCloseLogoutDialog}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Paper>
        </Popper>
      </ClickAwayListener>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        content={`Are you sure that you want to delete the  Faq(s)?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteFaqsOnSelect}
      />
    </FormFieldContainer>
  );
};

export default NewFAQBox;
