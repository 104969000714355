import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({
  addUpdatesSuccess: ["response"],
  addUpdatesFailure: ["error"],
  addUpdatesRequest: ["data"],

  getSingleUpdatesSuccess: ["response"],
  getSingleUpdatesFailure: ["error"],
  getSingleUpdatesRequest: ["data"],

  updateMarketingUpdatesSuccess: ["response"],
  updateMarketingUpdatesFailure: ["error"],
  updateMarketingUpdatesRequest: ["data"],

  addDraftsUpdatesSuccess: ["response"],
  addDraftsUpdatesFailure: ["error"],
  addDraftsUpdatesRequest: ["data"],

  deleteUpdatesSuccess: ["response"],
  deleteUpdatesFailure: ["error"],
  deleteUpdatesRequest: ["data"],

  updateCreationStatusSuccess: ["response"],
  updateCreationStatusFailure: ["error"],
  updateCreationStatusRequest: ["data"],

  setUpdatesState: ["key", "value"],
});

export const UpdateCreationTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  updates: [],
  drafts: [],
  singleUpdate: "",
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  showFlashMessage: false,
  mediaDeleteIndex: 0,
  responseForNewAudit: null,
};

// Add Updates
export const addUpdatesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const addUpdatesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Updates added successfully!",
      isLoading: false,
      showFlashMessage: true,
      responseForNewAudit: response.data,
    };
  }
};

export const addUpdatesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
    showFlashMessage: true,
  };
};

// Add Drafts Updates
export const addDraftsUpdatesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
    showFlashMessage: true,
  };
};

export const addDraftsUpdatesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Updates added successfully!",
      isLoading: false,
    };
  }
};

export const addDraftsUpdatesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

// Get Single Updates
export const getSingleUpdatesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getSingleUpdatesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      singleUpdate: response.data,
      isLoading: false,
      totalCount: response.totalCount,
    };
  }
};

export const getSingleUpdatesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Updating Marketing Updates
export const updateMarketingUpdatesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const updateMarketingUpdatesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message,
      isLoading: false,
      showFlashMessage: true,
    };
  }
};

export const updateMarketingUpdatesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
    showFlashMessage: true,
  };
};

//  Delete Updates
export const deleteUpdatesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const deleteUpdatesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      showFlashMessage: true,
      message: response.message
        ? response.message
        : "Updates deleted successfully!",
      isLoading: false,
    };
  }
};

export const deleteUpdatesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

// change update status
export const updateCreationStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateCreationStatusSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      message: response.message,
      showFlashMessage: true,
    };
  }
};

export const updateCreationStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setUpdatesState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_UPDATES_SUCCESS]: addUpdatesSuccess,
  [Types.ADD_UPDATES_FAILURE]: addUpdatesFailure,
  [Types.ADD_UPDATES_REQUEST]: addUpdatesRequest,

  [Types.GET_SINGLE_UPDATES_SUCCESS]: getSingleUpdatesSuccess,
  [Types.GET_SINGLE_UPDATES_FAILURE]: getSingleUpdatesFailure,
  [Types.GET_SINGLE_UPDATES_REQUEST]: getSingleUpdatesRequest,

  [Types.UPDATE_MARKETING_UPDATES_SUCCESS]: updateMarketingUpdatesSuccess,
  [Types.UPDATE_MARKETING_UPDATES_FAILURE]: updateMarketingUpdatesFailure,
  [Types.UPDATE_MARKETING_UPDATES_REQUEST]: updateMarketingUpdatesRequest,

  [Types.ADD_DRAFTS_UPDATES_SUCCESS]: addDraftsUpdatesSuccess,
  [Types.ADD_DRAFTS_UPDATES_FAILURE]: addDraftsUpdatesFailure,
  [Types.ADD_DRAFTS_UPDATES_REQUEST]: addDraftsUpdatesRequest,

  [Types.DELETE_UPDATES_SUCCESS]: deleteUpdatesSuccess,
  [Types.DELETE_UPDATES_FAILURE]: deleteUpdatesFailure,
  [Types.DELETE_UPDATES_REQUEST]: deleteUpdatesRequest,

  [Types.UPDATE_CREATION_STATUS_REQUEST]: updateCreationStatusRequest,
  [Types.UPDATE_CREATION_STATUS_SUCCESS]: updateCreationStatusSuccess,
  [Types.UPDATE_CREATION_STATUS_FAILURE]: updateCreationStatusFailure,

  [Types.SET_UPDATES_STATE]: setUpdatesState,
});
