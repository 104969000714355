import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { TextFieldComponent } from "components/ui-elements";
import { PortfolioPageStyleWrapper } from "../PortfolioPage.style";
import { portfolioInitialValues } from "../Page4PortfolioSections";
import { useSelector } from "react-redux";

const PageAndSubHeadings = () => {
  const { values, handleBlur, handleChange, errors, touched } =
    useFormikContext<typeof portfolioInitialValues>();
  const { isViewMode } = useSelector((state: any) => state.pagePortfolio);

  return (
    <PortfolioPageStyleWrapper>
      <Grid
        container
        className="field-container-box"
        rowSpacing={2}
        columnSpacing={4}
      >
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <TextFieldComponent
            required
            type="text"
            label="Page Heading "
            placeholder="Enter"
            name="pageName"
            value={values?.pageName}
            inputProps={{ maxLength: 100 }}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              readOnly: isViewMode,
            }}
            fieldhelpertext={
              errors.pageName && touched.pageName && errors.pageName
            }
            error={Boolean(errors.pageName && touched.pageName)}
          />
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <TextFieldComponent
            required
            type="text"
            label="Sub-Heading"
            placeholder="Enter"
            name="subHeading"
            value={values?.subHeading}
            inputProps={{ maxLength: 100 }}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              readOnly: isViewMode,
            }}
            fieldhelpertext={
              errors.subHeading && touched.subHeading && errors.subHeading
            }
            error={Boolean(errors.subHeading && touched.subHeading)}
          />
        </Grid>
      </Grid>
    </PortfolioPageStyleWrapper>
  );
};

export default PageAndSubHeadings;
