import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getCrmProjectUpdatesSuccess: ["response"],
  getCrmProjectUpdatesFailure: ["error"],
  getCrmProjectUpdatesRequest: ["data"],

  setCrmProjectUpdatesState: ["key", "value"],
});

export const crmProjectUpdatesTypes = Types;

export const crmProjectUpdatesAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  crmUpdates: null,
  isSuccess: false,
};

export const getCrmProjectUpdatesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    crmUpdates: null,
    isSuccess: false,
  };
};

export const getCrmProjectUpdatesSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      isSuccess: true,
      isLoading: false,
      error: null,
      crmUpdates: response?.data || null,
    };
  }
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
  };
};

export const getCrmProjectUpdatesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const setCrmProjectUpdatesState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CRM_PROJECT_UPDATES_SUCCESS]: getCrmProjectUpdatesSuccess,
  [Types.GET_CRM_PROJECT_UPDATES_FAILURE]: getCrmProjectUpdatesFailure,
  [Types.GET_CRM_PROJECT_UPDATES_REQUEST]: getCrmProjectUpdatesRequest,

  [Types.SET_CRM_PROJECT_UPDATES_STATE]: setCrmProjectUpdatesState,
});
