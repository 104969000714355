import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import ReferralsActions from "../../redux-container/referrals/ReferralsRedux";


export function* getReferrals(api: any, action: any): SagaIterator {
    try {
      const response = yield call(api.getReferrals, action.data);
      yield put(ReferralsActions.referralsSuccess(response.data));
    } catch (error) {
      yield put(ReferralsActions.referralsFailure(error));
    }
  }
  