import { Box, styled } from "@mui/material";

export const CustomerViewStyleWrapper = styled(Box)`
  .title-section {
    margin: 0.5rem;
    justify-content: flex-start;
  }
  .MuiTab-root {
    text-transform: capitalize;
  }
  .MuiTab-root.Mui-selected {
    color: ${({ theme }) => theme.palette.common.black};
  }
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.palette.common.black};
  }
  .MuiListItem-padding {
    padding: 0;
  }
  .MuiTabPanel-root {
    padding: 1rem 0;
  }
  .card-label {
    display: block;
    color: ${({ theme }) => theme.palette.grey[400]};
  }
  .filter-label {
    margin-top: -1.5rem;
  }
`;
