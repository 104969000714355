import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { FormFieldContainer } from "components/ui-elements";
import Grid from "@mui/material/Grid";
import Loader from "../../../components/loader/Loader";
import PageHeading from "../../../components/page-heading/PageHeading";
import Retry from "../../../components/retry/Retry";
import { RouteConstants } from "../../../routes/RouteConstants";
import UserActions from "../../../redux-container/users/UsersRedux";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import styles from "./UserManagementDashboard.module.css";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { StatusTypeEnum } from "enumerations/StatusTypeEnum";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";

var Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

const actionDispatch = (dispatch: any) => {
  return {
    getUserDashboard: () => dispatch(UserActions.getUserDashboardRequest()),
    setUsersState: (key: any, data: any) =>
      dispatch(UserActions.setUsersState(key, data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const UserManagementDashboard = () => {
  const navigate = useNavigate();

  const { getUserDashboard, setUsersState, rolesAndModulesRequest } =
    actionDispatch(useDispatch());
  const isGraphDataFetched = useSelector(
    (state: any) => state?.users.isGraphDataFetched
  );
  const dashboard = useSelector((state: any) => state?.users.dashboardData);
  const isLoading = useSelector((state: any) => state.users?.isLoading);
  const isErrorOccured = useSelector((state: any) => state.users?.error);

  const breadcrumbRoutes: any = [
    {
      name: "CMS User Management",
      route: RouteConstants.userManagementDashboard,
    },
  ];

  useEffect(() => {
    loadData();
    rolesAndModulesRequest();
  }, []);

  useEffect(() => {
    if (isGraphDataFetched) {
      setUsersState("isGraphDataFetched", false);
      configureChart();
    }
  }, [isGraphDataFetched]);

  const loadData = () => {
    getUserDashboard();
  };
 
  useEffect(()=>{
    setUsersState('activeSideMenu', "CMS User Management");
    setUsersState('isRouteUnderActiveSideMenu', "");    
  },[])

  const configureChart = () => {
    Highcharts.chart("user-container", {
      chart: {
        backgroundColor: "#f7f6ff",
        plotBackgroundColor: "#f7f6ff",
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            y: -5,
            style: {
              fontWeight: "bold",
              color: "black",
              fontSize: "14px",
            },
            filter: {
              property: "name",
              operator: "===",
              value: "Users",
            },
          },
          borderWidth: 1,
        },
        series: {
          animation: false,
        },
      },
      series: [
        {
          type: "pie",
          name: "Total",
          innerSize: "80%",
          data: [
            {
              name: "Active Users",
              y: dashboard?.adminStatus?.activeAdmins,
              color: "#3cc012",
            },
            {
              name: "Inactive Users",
              y: dashboard?.adminStatus?.inactiveAdmins,
              color: "#f37c7c",
            },
          ],
        },
      ],
    });
  };

  const getTotalAdmins = () => {
    const superAdminCount =
      dashboard &&
      dashboard.cmsOverview &&
      dashboard.cmsOverview.superAdminCount
        ? parseInt(dashboard.cmsOverview.superAdminCount)
        : 0;
    const adminCount =
      dashboard && dashboard.cmsOverview && dashboard.cmsOverview.adminCount
        ? parseInt(dashboard.cmsOverview.adminCount)
        : 0;
    return superAdminCount + adminCount;
  };

  const createOrInviteUser = () => {
    navigate(RouteConstants.userCreation);
  };

  const navigateUserList = () => {
    navigate(RouteConstants.userList,{ state: { isNavigationFromSidemenu: true } });
  };

  const filterByUsers = (userType: RoleTypeEnum) => {
    // setUsersState("isDashboardDataClicked", true);
    const filterData = {
      userType: userType,
      isNavigationFromSidemenu: true
    };
    navigate(`${RouteConstants.userList}`, {
      state: filterData,
    });
  };

  const filterByStatus = (statusType: StatusTypeEnum) => {
    // setUsersState("isDashboardDataClicked", true);
    const filterData = {
      statusType: statusType,
      isNavigationFromSidemenu: true
    };
    navigate(`${RouteConstants.userList}`, {
      state: filterData,
    });
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData} />;
  };

  const renderDashboard = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">Dashboard</h4>

          <Button
            variant="contained"
            onClick={createOrInviteUser}
            //autoFocus
            disableElevation
            className="btn btn-dark"
          >
            Invite New
          </Button>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <FormFieldContainer>
              <section className="graph-data-container">
                <h4 className="graph-heading">CMS Overview</h4>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    className="stat-card margin-bottom-20 total-stats-holder"
                    onClick={navigateUserList}
                  >
                    <span className="stat-card-total-value">
                      {getTotalAdmins()}
                    </span>
                    <span className="stat-card-total-heading">
                      Total CMS users
                    </span>
                    <span className={styles["stat-card-total-icon"]}>
                      <ChevronRightRoundedIcon />
                    </span>
                  </div>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div
                      className="stat-card individual-stats-holder"
                      onClick={() => filterByUsers(RoleTypeEnum.SuperAdmin)}
                    >
                      <span className="individual-stat-value-text green-stat-value">
                        {dashboard?.cmsOverview?.superAdminCount}
                      </span>
                      <span className="individual-stat-card-heading">
                        Super Admin users
                      </span>
                      <span className={styles["individual-stat-card-icon"]}>
                        <ChevronRightRoundedIcon />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div
                      className="stat-card individual-stats-holder"
                      onClick={() => filterByUsers(RoleTypeEnum.Admin)}
                    >
                      <span className="individual-stat-value-text red-stat-value">
                        {dashboard?.cmsOverview?.adminCount}
                      </span>
                      <span className="individual-stat-card-heading">
                        Admin users
                      </span>
                      <span className={styles["individual-stat-card-icon"]}>
                        <ChevronRightRoundedIcon />
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </section>
            </FormFieldContainer>
          </Grid>

          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <FormFieldContainer>
              <section className="graph-data-container">
                <h4 className="graph-heading">User Status</h4>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div id="user-container" className="graph-holder" />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div
                        className="stat-card individual-stats-holder-row  margin-bottom-20"
                        onClick={() => filterByStatus(StatusTypeEnum.Active)}
                      >
                        <span className="green-dot" />
                        <span className="individual-stat-value-text margin-right-15 margin-top-25">
                          {dashboard?.adminStatus?.activeAdmins}
                        </span>
                        <span className="individual-stat-card-heading">
                          Active Users
                        </span>
                        <span className={styles["individual-stat-card-icon"]}>
                          <ChevronRightRoundedIcon />
                        </span>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div
                        className="stat-card individual-stats-holder-row"
                        onClick={() => filterByStatus(StatusTypeEnum.Inactive)}
                      >
                        <span className="red-dot" />
                        <span className="individual-stat-value-text margin-right-15 margin-top-25">
                          {dashboard?.adminStatus?.inactiveAdmins}
                        </span>
                        <span className="individual-stat-card-heading">
                          Inactive Users
                        </span>
                        <span className={styles["individual-stat-card-icon"]}>
                          <ChevronRightRoundedIcon />
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </section>
            </FormFieldContainer>
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  const render = () => {
    // if (isLoading === false) {
    //   return renderDashboard();
    // } else if (isLoading) {
    //   return renderLoader();
    // } else if (isErrorOccured) {
    //   return renderRetry();
    // }
    return renderDashboard();
  };

  return (
    <Fragment>
      <PageHeading title="CMS User Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <Container className="main-container">{render()}</Container>
    </Fragment>
  );
};

export default UserManagementDashboard;
