import { Box, styled } from "@mui/material";

export const ProjectListStyleWrapper = styled(Box)`
  /* .MuiTab-root {
    color: ${({ theme }) => theme.palette.grey.A100};
  } */
  .MuiTab-root.Mui-selected {
    color: ${({ theme }) => theme.palette.common.black};
  }
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.palette.common.black};
  }
  .MuiOutlinedInput-root{
    margin-right: 42px;
  }
  .MuiFormControl-root{
    margin-right: 24px;
  }
  .MuiGrid-root{
    /* margin-left: 15px; */
  }
`;
