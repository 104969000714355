import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const InvestmentCard = styled(Box)`
  background-color: #f6f6fa;
  padding: 2.5rem;
  border-radius: 8px;
`;
export const InvestmentDetailsCard = () => {
  const { customerInvestmentSummary } = useSelector((state: any) => {
    return {
      customerInvestmentSummary:
        state?.customerListAndDetails?.customerSummary?.summary,
    };
  });

  return (
    <>
      <Stack
        display={"grid"}
        gridTemplateColumns={"1fr 1fr"}
        columnGap={"0.5rem"}
      >
        {customerInvestmentSummary?.completed?.count ? (
          <>
            <InvestmentCard padding={3}>
              <Grid container>
                <Grid xs={12} marginBottom={3}>
                  <Typography>Completed Investments</Typography>
                </Grid>
                <Grid xs={12}>
                  <Grid container>
                    <Grid xs={3}>
                      <Typography>No. of Products</Typography>
                      <Typography>
                        {customerInvestmentSummary?.completed?.count !==
                          undefined &&
                        customerInvestmentSummary?.completed?.count !== null
                          ? customerInvestmentSummary?.completed?.count
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography>Area(Sqft)</Typography>
                      <Typography>
                        {customerInvestmentSummary?.completed?.areaSqFt !==
                          undefined &&
                        customerInvestmentSummary?.completed?.areaSqFt !== null
                          ? customerInvestmentSummary?.completed?.areaSqFt
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography>Amount Invested</Typography>
                      <Typography>
                        {customerInvestmentSummary?.completed
                          ?.amountInvested !== undefined &&
                        customerInvestmentSummary?.completed?.amountInvested !==
                          null
                          ? customerInvestmentSummary?.completed?.amountInvested
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography>Avg Estimated Appreciation</Typography>
                      <Typography>
                        {customerInvestmentSummary?.iea !== undefined &&
                        customerInvestmentSummary?.iea !== null &&
                        customerInvestmentSummary?.iea !== "-"
                          ? customerInvestmentSummary?.iea
                          : 0}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </InvestmentCard>
          </>
        ) : null}
        {customerInvestmentSummary?.ongoing?.count ? (
          <>
            <InvestmentCard>
              <Grid container>
                <Grid xs={12} marginBottom={3}>
                  <Typography>Ongoing Investments</Typography>
                </Grid>
                <Grid xs={12}>
                  <Grid container>
                    <Grid xs={3}>
                      <Typography>No. of Products</Typography>
                      <Typography>
                        {customerInvestmentSummary?.ongoing?.count !==
                          undefined &&
                        customerInvestmentSummary?.ongoing?.count !== null
                          ? customerInvestmentSummary?.ongoing?.count
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography>Area(Sqft)</Typography>
                      <Typography>
                        {customerInvestmentSummary?.ongoing?.areaSqFt !==
                          undefined &&
                        customerInvestmentSummary?.ongoing?.areaSqFt !== null
                          ? customerInvestmentSummary?.ongoing?.areaSqFt
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography>Amount Paid</Typography>
                      <Typography>
                        {customerInvestmentSummary?.ongoing?.amountPaid !==
                          undefined &&
                        customerInvestmentSummary?.ongoing?.amountPaid !== null
                          ? customerInvestmentSummary?.ongoing?.amountPaid
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography>Amount Pending</Typography>
                      <Typography>
                        {customerInvestmentSummary?.ongoing?.amountPending !==
                          undefined &&
                        customerInvestmentSummary?.ongoing?.amountPending !==
                          null
                          ? customerInvestmentSummary?.ongoing?.amountPending
                          : 0}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </InvestmentCard>
          </>
        ) : null}
      </Stack>
    </>
  );
};
