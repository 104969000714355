import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Stack, Typography } from "@mui/material";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Container from "@mui/material/Container";
import { FormFieldContainer } from "components/ui-elements";
import MarketingDashboardActions from "../../../redux-container/marketing-management/MarketingDashboardRedux";
import PageHeading from "../../../components/page-heading/PageHeading";
import { RouteConstants } from "routes/RouteConstants";
import styles from "./BlogManagement.module.css";
import { UpdatesStatusTypeEnum } from "enumerations/StatusTypeEnum";

var Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

const actionDispatch = (dispatch: any) => {
  return {
    getMarketingDashboard: () =>
      dispatch(MarketingDashboardActions.getMarketingDashboardRequest()),
    setMarketingDashboardState: (key: any, data: any) =>
      dispatch(MarketingDashboardActions.setMarketingDashboardState(key, data)),
  };
};

const BlogManagementDashboard = () => {
  const { getMarketingDashboard, setMarketingDashboardState } = actionDispatch(
    useDispatch()
  );

  const navigate = useNavigate();

  const marketingDashboard = useSelector(
    (state: any) => state?.marketingDashboard?.marketingDashboardData
  );
  const isGraphDataFetched = useSelector(
    (state: any) => state?.marketingDashboard?.isGraphDataFetched
  );

  const handleUpdateIcon = () => {
    navigate(RouteConstants.blogs, {
      state: { isNavigationFromSidemenu: true },
    });
  };

  const filterByUpdateStatus = (statusType: UpdatesStatusTypeEnum) => {
    const filterData = {
      statusType: statusType,
      isNavigationFromSidemenu: true,
    };
    navigate(`${RouteConstants.blogs}`, {
      state: filterData,
    });
  };

  const breadcrumbRoutes: any = [
    {
      name: "Blog Management",
      route: RouteConstants.blogManagementDashboard,
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (isGraphDataFetched) {
      setMarketingDashboardState("isGraphDataFetched", false);
      configureMarketingChart();
    }
  }, [isGraphDataFetched]);

  const loadData = () => {
    getMarketingDashboard();
  };

  const configureMarketingChart = () => {
    Highcharts.chart("updates-container", {
      chart: {
        backgroundColor: "#f7f6ff",
        plotBackgroundColor: "#f7f6ff",
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          slicedOffset: 0,
          dataLabels: {
            enabled: true,
            distance: -50,
            y: 0,
            style: {
              fontWeight: "bold",
              color: "black",
              fontSize: "14px",
            },
            filter: {
              property: "name",
              operator: "===",
              value: "Updates",
            },
          },
          borderWidth: 0,
        },
        series: {
          animation: true,
        },
      },
      series: [
        {
          type: "pie",
          name: "Total",
          innerSize: "80%",
          data: [
            {
              name: "Active Blogs",
              y: marketingDashboard?.updatesOverview?.activeUpdatesCount,
              color: "#54b652",
              sliced: true,
            },
            {
              name: "Inactive Blogs",
              y: marketingDashboard?.updatesOverview?.inactiveUpdatesCount,
              color: "#f37c7c",
              sliced: true,
            },
          ],
        },
      ],
    });
  };

  const navigateToBlogs = () => {
    navigate(RouteConstants.blogs, {
      state: { isNavigationFromSidemenu: true },
    });
  };

  return (
    <Fragment>
      <PageHeading title="Blog Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

      <Container>
        <Grid className="heading-action-holder">
          <Typography className="heading">Dashboard</Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              onClick={handleUpdateIcon}
              // autoFocus
              endIcon={<ChevronRightRoundedIcon />}
              disableElevation
              className="btn btn-dark"
            >
              Go To Blogs
            </Button>
          </Stack>
        </Grid>

        <Typography className="graph-container-heading"> Blogs </Typography>
        <FormFieldContainer>
          <Grid className="graph-data-container">
            <Typography className="graph-heading">Blogs Overview</Typography>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                className={styles["graph-container-holder"]}
              >
                <Grid id="updates-container" className="graph-holder" />
              </Grid>

              <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    className={styles["total-stats-holder"]}
                    onClick={navigateToBlogs}
                  >
                    <span className="stat-card-total-value">
                      {marketingDashboard?.updatesOverview?.totalUpdatesCount}
                    </span>
                    <span className="stat-card-total-heading">
                      Total Published Blogs
                    </span>
                    <span
                      className={styles["stat-card-total-icon"]}
                      onClick={handleUpdateIcon}
                    >
                      <ChevronRightRoundedIcon />
                    </span>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid
                      className={styles["individual-stats-holder"]}
                      onClick={() =>
                        filterByUpdateStatus(UpdatesStatusTypeEnum.Inactive)
                      }
                    >
                      <span className="individual-stat-value-text red-stat-value">
                        {
                          marketingDashboard?.updatesOverview
                            ?.inactiveUpdatesCount
                        }
                      </span>
                      <span className="individual-stat-card-heading">
                        Inactive Blogs
                      </span>
                      <span
                        className={styles["individual-stat-card-icon"]}
                        onClick={handleUpdateIcon}
                      >
                        <ChevronRightRoundedIcon />
                      </span>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid
                      className={styles["individual-stats-holder"]}
                      onClick={() =>
                        filterByUpdateStatus(UpdatesStatusTypeEnum.Published)
                      }
                    >
                      <span className="individual-stat-value-text green-stat-value">
                        {marketingDashboard?.updatesOverview.activeUpdatesCount}
                      </span>
                      <span className="individual-stat-card-heading">
                        Active Blogs
                      </span>
                      <span
                        className={styles["individual-stat-card-icon"]}
                        onClick={handleUpdateIcon}
                      >
                        <ChevronRightRoundedIcon />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormFieldContainer>
      </Container>
    </Fragment>
  );
};

export default BlogManagementDashboard;
