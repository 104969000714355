import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({
  customerFeedbackListRequest: ["data"],
  customerFeedbackListSuccess: ["response"],
  customerFeedbackListFailure: ["error"],

  setCustomerManagementState: ["key", "value"],

    getCustomerBookingJourneyRequest: ["data"],
    getCustomerBookingJourneySuccess: ["response"],
    getCustomerBookingJourneyFailure: ["error"],

});

export const customerManagementTypes = Types;

export default Creators;

export const INITIAL_STATE: any = {
  customerFeedbackList: [],
  bookingJourneyData: {},
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  page: 0,
  rowsPerPage: 10,
};

// get customer feed back list
export const customerFeedbackListRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const customerFeedbackListSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      customerFeedbackList:response.data,
      message: response.message,
      isLoading: false,
      totalCount: response?.totalCount,
    };
  }
};

export const customerFeedbackListFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};
export const setCustomerManagementState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

// Booking Journery
export const getCustomerBookingJourneyRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getCustomerBookingJourneySuccess = (
  state: any,
  action: any
) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      bookingJourneyData:response.data,
      message: response.message,
      isLoading: false,
    };
  }
};

export const getCustomerBookingJourneyFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CUSTOMER_FEEDBACK_LIST_SUCCESS]: customerFeedbackListSuccess,
  [Types.CUSTOMER_FEEDBACK_LIST_FAILURE]: customerFeedbackListFailure,
  [Types.CUSTOMER_FEEDBACK_LIST_REQUEST]: customerFeedbackListRequest,
  [Types.SET_CUSTOMER_MANAGEMENT_STATE]: setCustomerManagementState,
  [Types.GET_CUSTOMER_BOOKING_JOURNEY_SUCCESS]:
  getCustomerBookingJourneySuccess,
  [Types.GET_CUSTOMER_BOOKING_JOURNEY_FAILURE]:
  getCustomerBookingJourneyFailure,
  [Types.GET_CUSTOMER_BOOKING_JOURNEY_REQUEST]:
  getCustomerBookingJourneyRequest,

});
