import { Box, Grid, Typography } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CustomDatePicker,
} from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ProjectTimelineSections } from "./ProjectTimelineInitialData";
import { ProjectTimelineStyleWrapper } from "./ProjectTimelineStyleWrapper";

const ProjectTimeline = () => {
  const { values, setFieldValue, touched, errors } = useFormikContext<any>();
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const [expanded, setExpanded] = useState<string | boolean>("panel-1");
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const handleDatePickerChange = (name: string) => (newValue: string) => {
    setFieldValue(name, newValue);
  };
  return (
    <ProjectTimelineStyleWrapper>
      <Box className="section-gutter">
        <Grid container spacing={2}>
          <Grid item lg={3} xl={3}>
            <Typography color={"common.black"} className={"section-heading"}>
              Timeline
            </Typography>
            <Box className={"picker-bottom-spacing"}>
              <CustomDatePicker
                required
                label={"Project Completion"}
                placeholder={"Select Date"}
                name={`projectCompletionDate`}
                value={new Date(values?.projectCompletionDate)}
                onChange={handleDatePickerChange(`projectCompletionDate`)}
                disabled={isViewMode}
                error={Boolean(
                  getIn(touched, `projectCompletionDate`) &&
                    getIn(errors, `projectCompletionDate`)
                )}
                fieldhelpertext={
                  getIn(touched, `projectCompletionDate`) &&
                  getIn(errors, `projectCompletionDate`)
                }
              />
            </Box>
          </Grid>
        </Grid>
        {ProjectTimelineSections.map((content: any, index: any) => (
          <Accordion
            key={index}
            expanded={expanded === `panel-${content.id}`}
            onChange={handleAccordionChange(`panel-${content.id}`)}
          >
            <AccordionSummary
              aria-controls={`panel-${content.id}-content`}
              id={`panel-${content.id}-header`}
              outlined
            >
              <Typography variant="body1">{`${content.id}. ${content.title}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>{content.component}</AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </ProjectTimelineStyleWrapper>
  );
};

export default ProjectTimeline;
