import react from "react";
import styles from "./BasicDataTable.module.css";
import { Stack, Typography } from "@mui/material";

const BasicDataTable = (props: any) => {
  const { tableHeadings, tableData } = props;

  return (
    <div>
      <table className={styles["basic-table"]}>
        <tr className={styles["table-row"]}>
          {tableHeadings?.map((item: any) => (
            <th>{item?.label}</th>
          ))}
        </tr>
        {tableData?.map((element: any) => (
          <tr className={styles["table-row"]}>
            {element?.map((item: any) => (
              <td>
                <Stack justifyContent={"flex-start"} spacing={1}>
                  <Typography className={styles["label-typography"]}>
                    {item?.label}
                  </Typography>
                  <Typography>:</Typography>
                  <Typography className={styles["value-typography"]}>
                    {item?.value ? item?.value : "-"}
                  </Typography>
                </Stack>
              </td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
};

export default BasicDataTable;
