import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { FaqActions } from "redux-container/faq/FaqRedux";

export function* getFaqs(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAllFaqs, action.data);
    yield put(FaqActions.faqSuccess(response.data));
  } catch (error) {
    yield put(FaqActions.faqFailure(error));
  }
}

export function* deleteFaq(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteFaq, action.data);
    yield put(FaqActions.deleteFaqSuccess(response.data));
  } catch (error) {
    yield put(FaqActions.deleteFaqFailure(error));
  }
}

export function* updateFaq(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateFaq, action.data);
    yield put(FaqActions.updateFaqStatusSuccess(response.data));
  } catch (error) {
    yield put(FaqActions.updateFaqStatusFailure(error));
  }
}

export function* updateFaqPriority(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateFaqPriority, action.data);
    yield put(FaqActions.updateFaqPrioritySuccess(response.data));
  } catch (error) {
    yield put(FaqActions.updateFaqPriorityFailure(error));
  }
}




