export const enum ActionsEnums {
  NavigateToAboutUs = 109,
  NavigateToInvestments = 110,
  NavigateToPromises = 113,
  NavigateToFAQS = 112,
  AllowTyping = 108,
  ShowFinalResponse = 100,
}

export class ActionEnumsUtils {
  public static getEnumText(type: ActionsEnums): string {
    switch (type) {
      case ActionsEnums.NavigateToAboutUs:
        return "Navigate To About Us";
      case ActionsEnums.NavigateToInvestments:
        return "Navigate To Investments";
      case ActionsEnums.NavigateToPromises:
        return "Navigate To Promises";
      case ActionsEnums.NavigateToFAQS:
        return "Navigate To FAQs";
      case ActionsEnums.AllowTyping:
        return "Allow Typing";
      case ActionsEnums.ShowFinalResponse:
        return "Show Final Response";
    }
  }

  public static getActionsEnums(): {
    label: string;
    value: ActionsEnums;
  }[] {
    const options: { label: string; value: ActionsEnums }[] = [
      {
        label: this.getEnumText(ActionsEnums.NavigateToAboutUs),
        value: ActionsEnums.NavigateToAboutUs,
      },
      {
        label: this.getEnumText(ActionsEnums.NavigateToInvestments),
        value: ActionsEnums.NavigateToInvestments,
      },
      {
        label: this.getEnumText(ActionsEnums.NavigateToPromises),
        value: ActionsEnums.NavigateToPromises,
      },
      {
        label: this.getEnumText(ActionsEnums.NavigateToFAQS),
        value: ActionsEnums.NavigateToFAQS,
      },
      {
        label: this.getEnumText(ActionsEnums.AllowTyping),
        value: ActionsEnums.AllowTyping,
      },
      {
        label: this.getEnumText(ActionsEnums.ShowFinalResponse),
        value: ActionsEnums.ShowFinalResponse,
      },
    ];
    return options;
  }
}
