import {
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
  ButtonComponent,
  FileUploadComponent,
  MediaUploadComponent,
} from "components/ui-elements";
import {
  Avatar,
  Box,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  MenuItem,
  Divider,
  styled,
  Tooltip,
  IconButton,
} from "@mui/material";
import MediaAssets from "assets";
import { useEffect, useState } from "react";
import ImageUploadDragDrop from "pages/marketing-management/updates/update-creation/ImageUploader/ImageUploader";
import { useFormikContext } from "formik";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { useDispatch, useSelector } from "react-redux";
import { getIn } from "formik";
import { useLocation } from "react-router-dom";
import InfoOutlinedIcon  from '@mui/icons-material/InfoOutlined';


const actionDispatch = (dispatch: any) => {
  return {
    fileUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    setUploadFileState: (key: string, value: any) =>
      dispatch(FileUploadActions.setUploadFileState(key, value)),
  }
};

const FacilityManagement = ({ isEditMode }: any) => {
  const [open, setOpen] = useState(false);
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } = useFormikContext<any>()
  const { fileUpload, setUploadFileState } = actionDispatch(useDispatch())
  const [facilityImage, setFacliltyImage] = useState(0)
  const location: any = useLocation();
  const {
    imageDetails,
    isLoading
  } = useSelector((state: any) => state?.fileUpload);

  useEffect(() => {
    if (imageDetails) {
      if (facilityImage === 1) {
        setFieldValue(`facilityManagement.value.url`, imageDetails?.url);
        setUploadFileState("imageDetails", null);
        setFacliltyImage(0)
      }
    }
  }, [imageDetails]);

  const handleNudgeCardOpen = () => {
    setOpen(true);
  };

  const handleNudgeCardModalClose = () => {
    setOpen(false);
  };

  const FacilityManagementContainer = styled(FormFieldContainer)`
    padding: 0;
  `;


  const handleImageUpload1 = (event: any) => {
    setFacliltyImage(1)
    const file=event.target.files[0]
    let imageUrl;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUpload(data);
      };
      reader.readAsArrayBuffer(file);

      setFieldValue(`facilityManagement.value.url`, imageDetails?.url);
    } else {
      imageUrl = null;
    }
  }

  return (
    <FacilityManagementContainer>
      <Grid container p={"1rem"}>
        <Grid item xs={6}>
          <Stack columnGap={2} justifyContent={"flex-start"}>
            <Typography
              className="accordion-heading"
              margin={"0.5rem 0"}
            >
              Facility Management
            </Typography>
            {/* <SwitchComponent name="isFacilityManagementActive" checked={values?.isFacilityManagementActive} disabled={location?.state?.isViewMode && isEditMode} onChange={handleChange} /> */}
            <Tooltip
              title={"This represent the ticker on Customer App"}
              arrow
              placement="top-start"
            >
            
                <InfoOutlinedIcon fontSize="small" className="infooutlined-alignment" />
          
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Grid container p={"1rem"}>
        <Grid item xs={4}>
          <Box width={"335px"}>
            <MediaUploadComponent
              fieldhelpertext={getIn(touched, "facilityManagement.value.url") &&
                getIn(errors, "facilityManagement.value.url")}
              name={"facilityManagement.value.url"}
              previewimage={values?.facilityManagement?.value?.url}
              onChange={handleImageUpload1}
              cancelimage={() => setFieldValue("facilityManagement.value.url", "")}
              disabled={location?.state?.isViewMode && isEditMode}
              loading={facilityImage===1 && isLoading}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider />

      {/* <NudgeCardListing open={open} handleClose={handleNudgeCardModalClose} /> */}
      <Box p={"1rem"} textAlign={"right"}>
        {/* <ButtonComponent variant="contained" onClick={handleNudgeCardOpen}>
          Save
        </ButtonComponent> */}
      </Box>
    </FacilityManagementContainer>
  );
};

export default FacilityManagement;
