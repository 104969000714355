import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  projectListHoablPage1Success: ["response"],
  projectListHoablPage1Failure: ["error"],
  projectListHoablPage1Request: ["data"],

  setProjectListHoablPage1State: ["key", "value"],
});

export const projectListHoablPage1Types = Types;

export const projectListHoablPage1Action = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  data: null,
};

export const projectListHoablPage1Request = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const projectListHoablPage1Success = (state: any, action: any) => {
  const response = action.response;
  return {
    ...state,
    error: null,
    projectContentList: response.data,
    isLoading: false,
    totalCount: response?.totalCount,
  };
};

export const projectListHoablPage1Failure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setProjectListHoablPage1State = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PROJECT_LIST_HOABL_PAGE1_SUCCESS]: projectListHoablPage1Success,
  [Types.PROJECT_LIST_HOABL_PAGE1_FAILURE]: projectListHoablPage1Failure,
  [Types.PROJECT_LIST_HOABL_PAGE1_REQUEST]: projectListHoablPage1Request,

  [Types.SET_PROJECT_LIST_HOABL_PAGE1_STATE]: setProjectListHoablPage1State,
});
