import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import ResourceDashboardActions from "redux-container/resource-management/ResourceDashboardRedux";
import CategoryActions from "redux-container/resource-management/CategoryRedux";
import { HttpResponseTypeEnum } from 'enumerations/HttpResponseTypeEnum';

export function* getResourceDashboard(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getResourceDashboard, action.params);
    yield put(
      ResourceDashboardActions.getResourceDashboardSuccess(response.data)
    );
  } catch (error) {
    yield put(ResourceDashboardActions.getResourceDashboardFailure(error));
  }
}
//GET ALL CATEGO
export function* getCategories(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getCategories, action.data);
    yield put(CategoryActions.getCategoriesSuccess(response.data));
  } catch (error) {
    yield put(CategoryActions.getCategoriesFailure(error));
  }
}
