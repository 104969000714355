import { useState } from "react";
import { FieldArray, getIn, useFormikContext } from "formik";
import { HoablInvestedUserConstants } from "./HoablInvestedUserConstants";
import { FormFieldContainer, TextFieldComponent } from "components/ui-elements";
import {
  Divider,
  Grid,
  Link,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import styles from "./HoablInvestedUser.module.css";
import { ActionsEnums, ActionEnumsUtils } from "./ActionsEnums";
import { TopicEnumsUtils } from "./TopicEnums";
import { HoablInvestedInitialStateInterFace } from "./HoablInvestedInitialValues";

const HoablInvestedUserFields = (props: any) => {
  const topics = TopicEnumsUtils.getTopicEnums();
  const actions = ActionEnumsUtils.getActionsEnums();

  const { values, handleChange, setFieldValue, touched, errors, handleBlur } =
    useFormikContext<HoablInvestedInitialStateInterFace>();

  let {
    isViewMode,
    previousValues,
    setPreviousValues,
    modulesAudited,
    setIsCategoryDeleted,
    setModulesAudited,
    categoryCount,
  } = props;

  const option1 = 0;
  const option2 = 1;

  return (
    <div>
      <FormFieldContainer>
        <Grid container className={"margin-top-20"}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextFieldComponent
              required
              name={"welcomeMessage"}
              label={"Welcome Message"}
              inputProps={{
                maxLength: HoablInvestedUserConstants.message,
              }}
              value={values.welcomeMessage}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isViewMode}
              fieldhelpertext={
                getIn(touched, "welcomeMessage") &&
                getIn(errors, "welcomeMessage")
              }
              error={Boolean(
                getIn(errors, "welcomeMessage") &&
                  getIn(touched, "welcomeMessage")
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={"margin-top-20"}
          >
            <TextFieldComponent
              fullWidth
              required
              label={"Message"}
              inputProps={{
                maxLength: HoablInvestedUserConstants.message,
              }}
              value={values.firstMessage}
              name={"firstMessage"}
              onChange={handleChange}
              disabled={isViewMode}
              onBlur={handleBlur}
              fieldhelpertext={
                getIn(touched, "firstMessage") && getIn(errors, "firstMessage")
              }
              error={Boolean(
                getIn(errors, "firstMessage") && getIn(touched, "firstMessage")
              )}
            />
          </Grid>
        </Grid>

        <FormFieldContainer>
          <Typography
            variant={"body1"}
            component={"h3"}
            className={styles["section-content-heading"]}
          >
            Categories
          </Typography>

          <FieldArray
            name="categories"
            render={(arrayHelper) => (
              <div>
                {values?.categories?.map((category: any, index: number) => {
                  return (
                    <div key={index}>
                      <Divider className={styles.divider}></Divider>
                      <Stack>
                        <Stack
                          justifyContent={"start"}
                          spacing={2}
                          className={"margin-top-20"}
                        >
                          <Typography>Category {`${index + 1}`} </Typography>
                        </Stack>
                        {values.categories?.length > 1 && (
                          <Typography
                            onClick={() => {
                              setIsCategoryDeleted(true);
                              arrayHelper.remove(index);
                              let categoryArray = previousValues?.categories;
                              categoryArray.splice(index);
                              setPreviousValues({
                                ...previousValues,
                                categories: categoryArray,
                              });
                              //here we need to add condition that previousState.categoreis.lenght should be more than values.categories.
                              if (categoryCount > values?.categories?.length) {
                                setModulesAudited((prev: any) => [
                                  ...prev,
                                  {
                                    itemAffected: `Category ${
                                      index + 1
                                    } Deleted`,
                                    newValue: "",
                                    oldValue: "",
                                  },
                                ]);
                              }
                            }}
                          >
                            {!isViewMode && (
                              <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                            )}
                          </Typography>
                        )}
                      </Stack>

                      <Grid container spacing={2} className={"margin-top-20"}>
                        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                          <TextFieldComponent
                            required
                            label="Disaply Name"
                            inputProps={{
                              maxLength: HoablInvestedUserConstants.displayName,
                            }}
                            value={values.categories[index].text}
                            name={`categories[${index}].text`}
                            onChange={handleChange}
                            disabled={isViewMode}
                            onBlur={handleBlur}
                            fieldhelpertext={
                              getIn(touched, `categories[${index}].text`) &&
                              getIn(errors, `categories[${index}].text`)
                            }
                            error={Boolean(
                              getIn(errors, `categories[${index}].text`) &&
                                getIn(touched, `categories[${index}].text`)
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                          <TextFieldComponent
                            required
                            label="Topic"
                            onChange={(e: any) => {
                              handleChange(e);
                              setFieldValue(
                                `categories[${index}].actionType`,
                                1
                              );
                            }}
                            value={values.categories[index].action}
                            name={`categories[${index}].action`}
                            select
                            disabled={isViewMode}
                            onBlur={handleBlur}
                            fieldhelpertext={
                              getIn(touched, `categories[${index}].action`) &&
                              getIn(errors, `categories[${index}].action`)
                            }
                            error={Boolean(
                              getIn(errors, `categories[${index}].action`) &&
                                getIn(touched, `categories[${index}].action`)
                            )}
                          >
                            {topics.map((topic: any) => (
                              <MenuItem value={topic.value}>
                                {topic.label}
                              </MenuItem>
                            ))}
                          </TextFieldComponent>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <TextFieldComponent
                            required
                            label="Message Body"
                            inputProps={{
                              maxLength: HoablInvestedUserConstants.messageBody,
                            }}
                            value={values.categories[index].message}
                            name={`categories[${index}].message`}
                            onChange={handleChange}
                            disabled={isViewMode}
                            onBlur={handleBlur}
                            fieldhelpertext={
                              getIn(touched, `categories[${index}].message`) &&
                              getIn(errors, `categories[${index}].message`)
                            }
                            error={Boolean(
                              getIn(errors, `categories[${index}].message`) &&
                                getIn(touched, `categories[${index}].message`)
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                          <TextFieldComponent
                            required
                            label={"Option 1"}
                            inputProps={{
                              maxLength: HoablInvestedUserConstants.options,
                            }}
                            value={values.categories[index].options[0].text}
                            name={`categories[${index}].options[${option1}].text`}
                            onChange={handleChange}
                            disabled={isViewMode}
                            onBlur={handleBlur}
                            fieldhelpertext={
                              getIn(
                                touched,
                                `categories[${index}].options[${option1}].text`
                              ) &&
                              getIn(
                                errors,
                                `categories[${index}].options[${option1}].text`
                              )
                            }
                            error={Boolean(
                              getIn(
                                errors,
                                `categories[${index}].options[${option1}].text`
                              ) &&
                                getIn(
                                  touched,
                                  `categories[${index}].options[${option1}].text`
                                )
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                          <TextFieldComponent
                            required
                            label={"Actions"}
                            select
                            value={values.categories[index].options[0].action}
                            name={`categories[${index}].options[${option1}].action`}
                            // Generally we are useing Formik's "handleChange","handleBlur" functions to all the text-fields But for all Select-Fields
                            // we use our manual handle change.
                            //since this is Select-field so we manually setting value using formik's function "setFieldValue" .
                            onChange={(e: any) => {
                              handleChange(e);
                              if (
                                e.target.value ===
                                  ActionsEnums.ShowFinalResponse ||
                                e.target.value === ActionsEnums.AllowTyping
                              ) {
                                setFieldValue(
                                  `categories[${index}].options[${option1}].actionType`,
                                  3
                                );
                              } else {
                                setFieldValue(
                                  `categories[${index}].options[${option1}].actionType`,
                                  2
                                );
                              }
                            }}
                            disabled={isViewMode}
                            onBlur={handleBlur}
                            fieldhelpertext={
                              getIn(
                                touched,
                                `categories[${index}].options[${option1}].action`
                              ) &&
                              getIn(
                                errors,
                                `categories[${index}].options[${option1}].action`
                              )
                            }
                            error={Boolean(
                              getIn(
                                errors,
                                `categories[${index}].options[${option1}].action`
                              ) &&
                                getIn(
                                  touched,
                                  `categories[${index}].options[${option1}].action`
                                )
                            )}
                          >
                            {actions.map((action: any) => (
                              <MenuItem value={action.value}>
                                {action.label}
                              </MenuItem>
                            ))}
                          </TextFieldComponent>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} mt={1}>
                        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                          <TextFieldComponent
                            required
                            onChange={handleChange}
                            label={"Option 2"}
                            inputProps={{
                              maxLength: HoablInvestedUserConstants.options,
                            }}
                            value={values.categories[index].options[1].text}
                            name={`categories[${index}].options[${option2}].text`}
                            disabled={isViewMode}
                            onBlur={handleBlur}
                            fieldhelpertext={
                              getIn(
                                touched,
                                `categories[${index}].options[${option2}].text`
                              ) &&
                              getIn(
                                errors,
                                `categories[${index}].options[${option2}].text`
                              )
                            }
                            error={Boolean(
                              getIn(
                                errors,
                                `categories[${index}].options[${option2}].text`
                              ) &&
                                getIn(
                                  touched,
                                  `categories[${index}].options[${option2}].text`
                                )
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                          <TextFieldComponent
                            required
                            label={"Actions"}
                            onChange={(e: any) => {
                              handleChange(e);
                              if (
                                e.target.value ===
                                  ActionsEnums.ShowFinalResponse ||
                                e.target.value === ActionsEnums.AllowTyping
                              ) {
                                setFieldValue(
                                  `categories[${index}].options[${option2}].actionType`,
                                  3
                                );
                              } else {
                                setFieldValue(
                                  `categories[${index}].options[${option2}].actionType`,
                                  2
                                );
                              }
                            }}
                            select
                            value={values.categories[index].options[1].action}
                            name={`categories[${index}].options[${option2}].action`}
                            disabled={isViewMode}
                            onBlur={handleBlur}
                            fieldhelpertext={
                              getIn(
                                touched,
                                `categories[${index}].options[${option2}].action`
                              ) &&
                              getIn(
                                errors,
                                `categories[${index}].options[${option2}].action`
                              )
                            }
                            error={Boolean(
                              getIn(
                                errors,
                                `categories[${index}].options[${option2}].action`
                              ) &&
                                getIn(
                                  touched,
                                  `categories[${index}].options[${option2}].action`
                                )
                            )}
                          >
                            {actions.map((action: any) => (
                              <MenuItem value={action.value}>
                                {action.label}
                              </MenuItem>
                            ))}
                          </TextFieldComponent>
                        </Grid>
                      </Grid>
                      {values?.categories?.length - 1 === index &&
                        index !== 3 && (
                          <Link
                            underline="always"
                            component="button"
                            onClick={() => {
                              arrayHelper.push({
                                text: "",
                                action: null,
                                actionType: 1,
                                message: "",
                                options: [
                                  {
                                    text: "",
                                    action: null,
                                    actionType: 3,
                                  },
                                  {
                                    text: "",
                                    action: null,
                                    actionType: 3,
                                  },
                                ],
                              });
                              let categoryArray = previousValues?.categories;

                              categoryArray.push({
                                text: "",
                                action: null,
                                actionType: 0,
                                optionNumber: 0,
                                message: "",
                                options: [
                                  {
                                    text: "",
                                    action: null,
                                    actionType: 0,
                                    optionNumber: 0,
                                  },
                                  {
                                    text: "",
                                    action: null,
                                    actionType: 0,
                                    optionNumber: 0,
                                  },
                                ],
                                linkedOption: 0,
                              });
                              setPreviousValues({
                                ...previousValues,
                                categories: categoryArray,
                              });
                              if (
                                categoryCount <
                                values?.categories?.length + 1
                              ) {
                                setModulesAudited((prev: any) => [
                                  ...prev,
                                  {
                                    itemAffected: `Category ${
                                      index + 1 + 1
                                    } Added`,
                                    newValue: "",
                                    oldValue: "",
                                  },
                                ]);
                              }
                            }}
                            disabled={isViewMode}
                          >
                            + Add more
                          </Link>
                        )}
                    </div>
                  );
                })}
              </div>
            )}
          />
        </FormFieldContainer>

        {/* <Stack rowGap={3} mt={5} flexDirection={"column"}>
          <TextFieldComponent
            fullWidth
            required
            label={"Final Message"}
            name={"finalMessage"}
            inputProps={{
              maxLength: HoablInvestedUserConstants.message,
            }}
            onChange={handleChange}
            value={values.finalMessage}
            disabled={isViewMode}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, "finalMessage") && getIn(errors, "finalMessage")
            }
            error={Boolean(
              getIn(errors, "finalMessage") && getIn(touched, "finalMessage")
            )}
          />
          <TextFieldComponent
            fullWidth
            required
            label={"Inactive Message"}
            inputProps={{
              maxLength: HoablInvestedUserConstants.message,
            }}
            value={values.inactiveMessage}
            onChange={handleChange}
            name={"inactiveMessage"}
            disabled={isViewMode}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, "inactiveMessage") &&
              getIn(errors, "inactiveMessage")
            }
            error={Boolean(
              getIn(errors, "inactiveMessage") &&
                getIn(touched, "inactiveMessage")
            )}
          />
          <TextFieldComponent
            fullWidth
            required
            label={"Typing enabled Message"}
            inputProps={{
              maxLength: HoablInvestedUserConstants.message,
            }}
            value={values.allowTypingMessage}
            name={"allowTypingMessage"}
            onChange={handleChange}
            disabled={isViewMode}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, "allowTypingMessage") &&
              getIn(errors, "allowTypingMessage")
            }
            error={Boolean(
              getIn(errors, "allowTypingMessage") &&
                getIn(touched, "allowTypingMessage")
            )}
          />
        </Stack> */}
      </FormFieldContainer>
    </div>
  );
};

export default HoablInvestedUserFields;
