import React from "react";
import { FormFieldContainer, TextFieldComponent } from "components/ui-elements";
import { Divider, Grid, styled } from "@mui/material";
import styles from "../Page3promises.module.css";

// Overring the padding for formFieldContainer
const AboutPromisesContainer = styled(FormFieldContainer)`
  padding: 30px 0px 0px 0px;
`;

interface IAboutPromisesProps {
  heading: string;
  description: string;
  aboutPromiseHeadingError: string;
  aboutPromiseDescriptionError: string;
  handleAboutPromiseDescriptionChange: (e?: any) => any;
  handleAboutPromiseSectionHeadingChange: (e?: any) => any;
  handleOnBlurAboutPromiseDescription: (e?: any) => any;
  handleOnBlurAboutPromiseHeading: (e?: any) => any;
  disabledComponent: boolean;
}

const AboutPromisesSection = ({
  heading,
  description,
  aboutPromiseHeadingError,
  aboutPromiseDescriptionError,
  handleAboutPromiseSectionHeadingChange,
  handleAboutPromiseDescriptionChange,
  handleOnBlurAboutPromiseDescription,
  handleOnBlurAboutPromiseHeading,
  disabledComponent,
}: IAboutPromisesProps) => {
  const handleSectionHeadingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleAboutPromiseSectionHeadingChange(event?.target);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleAboutPromiseDescriptionChange(event?.target);
  };

  return (
    <AboutPromisesContainer>
      <h4 className={styles["section-heading"]}>About Promises</h4>

      <Divider></Divider>

      <div className={styles["section-content"]}>
        <Grid container spacing={1}>
          <Grid item md={5} lg={5} xl={5}>
            <TextFieldComponent
              disabled={disabledComponent}
              className=""
              fullWidth
              required
              type="text"
              label="Section Heading"
              placeholder="Enter"
              name="aboutPromiseSectionHeading"
              value={heading}
              inputProps={{ maxLength: 100 }}
              onChange={handleSectionHeadingChange}
              onBlur={handleOnBlurAboutPromiseHeading}
              error={aboutPromiseHeadingError !== "" ? true : false}
              fieldhelpertext={aboutPromiseHeadingError}
            />
          </Grid>
        </Grid>

        <TextFieldComponent
          disabled={disabledComponent}
          className="margin-top-20"
          fullWidth
          required
          multiline
          minRows={3}
          type="text"
          label="Description"
          placeholder="Enter"
          name="aboutPromiseDescription"
          value={description}
          inputProps={{ maxLength: 250 }}
          onChange={handleDescriptionChange}
          onBlur={handleOnBlurAboutPromiseDescription}
          error={aboutPromiseDescriptionError !== "" ? true : false}
          fieldhelpertext={aboutPromiseDescriptionError}
        />
      </div>
    </AboutPromisesContainer>
  );
};

export default AboutPromisesSection;
