import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import {
  ButtonComponent,
  FormFieldContainer,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { FieldArray, getIn, useFormikContext } from "formik";
import React, { Fragment, useState } from "react";
import styles from "./Opportunity.module.css";
import QuillEditor from "components/quill-editor/QuillEditor";
import FileUploadComponent from "components/ui-elements/media-upload-component/FileUploadComponent";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import UpcomingInfraStoryMedia from "../opportunity-doc/UpcomingInfraStoryMedia";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSelector } from "react-redux";

const FormFieldContainerWrapper = styled(FormFieldContainer)`
  padding: 2rem;
  margin: 2rem;
`;

function UpcomingInfraStory() {
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<IInitinalValueProjectContent>();

  const [currentlyOpenedIndex, setCurrentlyOpenedIndex] = useState(999);
  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));

  const handleUpcomingInfraStoryMedia = (imageIndex: number) => {
    setCurrentlyOpenedIndex(imageIndex);
  };

  const handleTextEditor = (
    name: string,
    newValue: string,
    options: any,
    { setFieldValue, setFieldError }: any
  ) => {
    // if (options?.isError) {
    //   setFieldError(name, "Maximum character exceed");
    // } else {
      setFieldValue(name, newValue);
    // }
  };

  return (
    <div>
      <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
        <Typography>Upcoming Infra Story</Typography>

        <FormControlLabel
          className="switch-form-label"
          name={"opportunityDoc.isUpcomingInfraStoryActive"}
          checked={values?.opportunityDoc?.isUpcomingInfraStoryActive}
          onChange={handleChange}
          control={<SwitchComponent />}
          label=""
          labelPlacement="start"
          disabled={isViewMode}
        />
        <IconButton>
          <Tooltip title={"Upcoming Infra Story"} placement="top">
            <Avatar
              className={styles["Avator"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
      </Stack>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} p={3}>
        <TextFieldComponent
          required
          type="text"
          placeholder="Enter"
          label="Section Heading"
          name={`opportunityDoc.upcomingInfraStory.heading`}
          value={values?.opportunityDoc?.upcomingInfraStory?.heading}
          onChange={handleChange}
          inputProps={{ maxLength: 40 }}
          onBlur={handleBlur}
          fieldhelpertext={
            getIn(touched, "opportunityDoc.upcomingInfraStory.heading") &&
            getIn(errors, "opportunityDoc.upcomingInfraStory.heading")
          }
          error={Boolean(
            getIn(errors, "opportunityDoc.upcomingInfraStory.heading") &&
              getIn(touched, "opportunityDoc.upcomingInfraStory.heading")
          )}
          disabled={isViewMode}
        />
      </Grid>

      <FieldArray
        name="opportunityDoc.upcomingInfraStory.stories"
        render={(arrayHelpers) => (
      <div>
      {values?.opportunityDoc?.upcomingInfraStory?.stories.length > 0 &&
        values?.opportunityDoc?.upcomingInfraStory?.stories.map(
          (element: any, index: number) => {
            return (
              <Fragment>
                <FormFieldContainerWrapper>
                  <Stack justifyContent={"flex-end"}>
                    {values?.opportunityDoc?.upcomingInfraStory?.stories
                      ?.length > 3 && (
                      <IconButton
                        disabled={isViewMode}
                        aria-label="delete"
                        data-testid="button-delete"
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      >
                        <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                      </IconButton>
                    )}
                  </Stack>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={7} sm={7} md={7} lg={7} xl={8}>
                      <Box mr={"20rem"}>
                        <TextFieldComponent
                          required
                          type="text"
                          placeholder="Enter"
                          label="Image Title"
                          name={`opportunityDoc.upcomingInfraStory.stories.${index}.title`}
                          value={
                            values?.opportunityDoc?.upcomingInfraStory?.stories[
                              index
                            ]?.title
                          }
                          onChange={handleChange}
                          inputProps={{ maxLength: 30 }}
                          onBlur={handleBlur}
                          fieldhelpertext={
                            getIn(
                              touched,
                              `opportunityDoc.upcomingInfraStory.stories.${index}.title`
                            ) &&
                            getIn(
                              errors,
                              `opportunityDoc.upcomingInfraStory.stories.${index}.title`
                            )
                          }
                          error={Boolean(
                            getIn(
                              errors,
                              `opportunityDoc.upcomingInfraStory.stories.${index}.title`
                            ) &&
                              getIn(
                                touched,
                                `opportunityDoc.upcomingInfraStory.stories.${index}.title`
                              )
                          )}
                          disabled={isViewMode}
                        />
                      </Box>
                      <Typography>Description</Typography>
                      <QuillEditor
                        placeholder="Enter description"
                        className={styles["QuillEditor"]}
                        name={`opportunityDoc.upcomingInfraStory.stories.${index}.description`}
                        value={
                          values?.opportunityDoc?.upcomingInfraStory?.stories[
                            index
                          ]?.description
                        }
                        onChange={(
                          name: string,
                          newValue: string,
                          options: any
                        ) =>
                          handleTextEditor(name, newValue, options, {
                            setFieldValue,
                            setFieldError,
                          })
                        }
                        // style={{ height: "12rem" }}
                        inputProps={{ maxLength: 240 }}
                        style={
                         { height: "126px" }
                        }
                        disabled={isViewMode}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={4} xl={3}>
                      <UpcomingInfraStoryMedia
                        index={index}
                        currentlyOpenedIndex={currentlyOpenedIndex}
                        handleUpcomingInfraStoryMedia={
                          handleUpcomingInfraStoryMedia
                        }
                      />
                    </Grid>
                  </Grid>
                </FormFieldContainerWrapper>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3} ml={2}>
                  {values?.opportunityDoc?.upcomingInfraStory?.stories?.length -
                    1 ===
                    index &&
                    values?.opportunityDoc?.upcomingInfraStory?.stories
                      ?.length < 5 && (
                      <ButtonComponent
                        sx={{
                          textDecoration: "underline",
                          marginTop: "0.5rem",
                        }}
                        variant="text"
                        color="inherit"
                        onClick={() => {
                          arrayHelpers.push({
                            title: "",
                            description: "",
                            media: {
                              name: "",
                              key: "",
                              value: {
                                url: "",
                                size: 0,
                                width: 1024,
                                height: 1024,
                                mediaType: "image",
                              },
                            },
                          });
                        }}
                        disabled={isViewMode}
                      >
                        + Add more Values
                      </ButtonComponent>
                    )}
                </Grid>
              </Fragment>
            );
          }
        )}
        </div>
        )} />
      <Divider sx={{ mt: "0.5rem" }} />
    </div>
  );
}

export default UpcomingInfraStory;
