import CustomerPortpolioInvestmentDetails from "./CustomerPortpolioInvestmentDetails";
import CustomerPortpolioWatchlist from "./CustomerPortpolioWatchlist";

export const CustomerPortpolioSections = () => {

  return [
    {
      id: 1,
      title: "Completed and Ongoing Investment Details",
      component:CustomerPortpolioInvestmentDetails
    },
    {
      id: 2,
      title: "Watchlist",
      component: CustomerPortpolioWatchlist
    },
  ];
};
