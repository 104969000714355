import {
  Container,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import PageHeading from "components/page-heading/PageHeading";
import {
  ButtonComponent,
  FormFieldContainer,
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Categories from "./Categories";
import styles from "./ProjectsInvestedUser.module.css";
import { Form, Formik, getIn } from "formik";
import {
  projectInvestedUserInitialValues,
  projectInvestedUserLabels,
} from "./ProjectsInvestedUserInitialValues";
import chatManagementAction from "redux-container/chat-management/ChatManagementRedux";
import { useDispatch, useSelector } from "react-redux";
import { Delayed } from "utils/DelayRender";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { validationSchemaInvestedUser } from "./ProjectsInvestedUserValidation";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import axios from "axios";
import { stripHtml } from "utils/ValidationUtils";
import {
  ActionEnumsUtils,
  ActionsEnums,
} from "enumerations/ActionsTypesForInvestedNewUser";
import {
  TopicEnumsUtils,
  TopicEnums,
} from "enumerations/TopicEnumsForInvestedNewUser";
import { AuditStatus } from "enumerations/AuditStatusTypes";

export const TextFieldWrapper = styled(TextFieldComponent)`
  margin-top: 30px;
`;

const actionDispatch = (dispatch: any) => {
  return {
    getChatManagementData: (id: any) =>
      dispatch(chatManagementAction.getChatManagementDataRequest(id)),
    addChatManagementData: (data: any) => {
      dispatch(chatManagementAction.addChatManagementDataRequest(data));
    },
    updateChatManagementData: (data: any) => {
      dispatch(chatManagementAction.updateChatManagementDataRequest(data));
    },
    setChatManagementState: (key: any, data: any) => {
      dispatch(chatManagementAction.setChatManagementState(key, data));
    },
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    getAuditLogRequest: (data: any) =>
      dispatch(AuditActions.getAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const ProjectsInvestedUser = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const breadcrumbRoutes: any = [
    {
      name: "Chat Support Management",
      route: RouteConstants.chatSupportManagementDashboard,
    },
    {
      name: "Projects-Invested User",
      route: RouteConstants.projectsInvestedUser,
    },
  ];

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const isDataPresent = useSelector(
    (state: any) => state?.chatManagement?.isDataPresent
  );
  const [isViewMode, setIsViewMode] = useState(
    isDataPresent ? location?.state?.isViewMode : false
  );
  const [isEditMode, setIsEditMode] = useState(
    isDataPresent ? location?.state?.isEditMode : false
  );
  const smartKeyQueryParam = "INVESTED_PROJECT";

  const {
    getChatManagementData,
    updateChatManagementData,
    setChatManagementState,
    postAuditLogRequest,
    getAuditLogRequest,
    rolesAndModulesRequest,
  } = actionDispatch(useDispatch());

  const {
    chatManagementData,
    openFlashMessage,
    message,
    isSuccess,
    error,
    modules,
  } = useSelector((state: any) => {
    return {
      chatManagementData: state?.chatManagement?.chatManagementData,
      openFlashMessage: state?.chatManagement?.openFlashMessage,
      message: state?.chatManagement?.message,
      isSuccess: state?.chatManagement?.isSuccess,
      error: state?.chatManagement?.error,
      modules: state?.rolesAndModules?.module,
    };
  });
  const [previousValues, setPreviousValues] = useState<any>();
  const [modulesAudited, setModulesAudited] = useState<any>([]);
  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);
  const [isCategoryDeleted, setIsCategoryDeleted] = useState<boolean>(false);
  const [categoryCount, setCategoryCount] = useState(
    previousValues?.categories?.length
  );

  //API call to get roles and modules which are used in POST API call.
  useEffect(() => {
    rolesAndModulesRequest();
    return () => {};
  }, []);

  //set API Data in Previous Values state after getting it from "getChatManagementData" API.
  useEffect(() => {
    setPreviousValues(chatManagementData);
  }, [chatManagementData]);

  useEffect(() => {
    getChatManagementData(smartKeyQueryParam);
  }, []);

  useEffect(() => {
    setCategoryCount(chatManagementData?.categories?.length);
  }, [chatManagementData]);

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  //function to publish changes made to Project Invested User's chat (Post API call) and Audit Log API call.
  const okHandler = async (formikObjects: any) => {
    let formValues = formikObjects?.values;
    let touchedObject = formikObjects?.touched;

    let localPreviousValues: any = previousValues;
    let localCategories: any = localPreviousValues?.categories;

    if (categoryCount < formValues?.categories?.length) {
      for (
        let index = categoryCount;
        index < formValues?.categories?.length;
        index++
      ) {
        localCategories[index] = formValues?.categories[index];
      }
      localPreviousValues = {
        ...localPreviousValues,
        categories: localCategories,
      };
    }
    //Post API call
    updateChatManagementData(formValues);
    setIsConfirmDeleteDialogOpen(false);

    //Audit Log Functionality.

    // if (location?.state !== null) {
    let auditObjectsArray: any[] = [];
    Object?.keys(touchedObject)?.map((key: any) => {
      //values objects
      if (typeof touchedObject[key] == "object") {
        //categories as object checking
        if (Array.isArray(touchedObject[key])) {
          //categories as array checking
          //if key is array
          touchedObject[key]?.map((element: any, index: number) => {
            //categories array looping
            if (typeof touchedObject[key][index] == "object") {
              //categories array's obejct checking
              Object?.keys(touchedObject[key][index])?.map(
                (categoryKey: any, categoryKeyIndex: number) => {
                  //categories array's object key looping
                  if (Array.isArray(touchedObject[key][index][categoryKey])) {
                    //categories array's object's(options) key is array checking
                    touchedObject[key][index][categoryKey].map(
                      (optionsObject: any, optionsKeyIndex: number) => {
                        //options array looping
                        if (
                          typeof touchedObject[key][index][categoryKey][
                            optionsKeyIndex
                          ] == "object" //1st object of options is object checking
                        ) {
                          Object?.keys(
                            touchedObject[key][index][categoryKey][
                              optionsKeyIndex //keys of options object mapping
                            ]
                          )?.map((optionsKeys: any) => {
                            //options array's object's key looping
                            if (
                              projectInvestedUserLabels[key][0][categoryKey][0][
                                optionsKeys
                              ] !== undefined &&
                              formValues[key][index][categoryKey][
                                optionsKeyIndex
                              ][optionsKeys] !==
                                localPreviousValues[key][index][categoryKey][
                                  optionsKeyIndex
                                ][optionsKeys]
                            ) {
                              let auditObject = {
                                itemAffected: ` ${
                                  projectInvestedUserLabels[key][0][
                                    categoryKey
                                  ][0][optionsKeys]
                                } ${optionsKeyIndex + 1} of Category ${
                                  index + 1
                                } `,
                                newValue:
                                  optionsKeys === "action"
                                    ? ActionEnumsUtils.getEnumText(
                                        formValues[key][index][categoryKey][
                                          optionsKeyIndex
                                        ][optionsKeys]
                                      )
                                    : formValues[key][index][categoryKey][
                                        optionsKeyIndex
                                      ][optionsKeys],
                                oldValue:
                                  optionsKeys === "action"
                                    ? ActionEnumsUtils.getEnumText(
                                        localPreviousValues[key][index][
                                          categoryKey
                                        ][optionsKeyIndex][optionsKeys]
                                      )
                                    : localPreviousValues[key][index][
                                        categoryKey
                                      ][optionsKeyIndex][optionsKeys],
                              };
                              auditObjectsArray.push(auditObject);
                            }
                          });
                        }
                      }
                    );
                  } else {
                    if (
                      projectInvestedUserLabels[key][0][categoryKey] !==
                        undefined &&
                      formValues[key][index][categoryKey] !==
                        localPreviousValues[key][index][categoryKey]
                    ) {
                      let auditObject = {
                        itemAffected: `${
                          projectInvestedUserLabels[key][0][categoryKey]
                        }  of Category ${index + 1} `,
                        newValue:
                          categoryKey === "action"
                            ? TopicEnumsUtils.getEnumText(
                                formValues[key][index][categoryKey]
                              )
                            : formValues[key][index][categoryKey],
                        oldValue:
                          categoryKey === "action"
                            ? TopicEnumsUtils.getEnumText(
                                localPreviousValues[key][index][categoryKey]
                              )
                            : localPreviousValues[key][index][categoryKey],
                      };
                      auditObjectsArray.push(auditObject);
                    }
                  }
                }
              );
            } else if (
              projectInvestedUserLabels[key][0] !== undefined &&
              formValues[key][index] !== localPreviousValues[key][index]
            ) {
              let auditObject = {
                itemAffected: `${projectInvestedUserLabels[key]} of Category ${
                  index + 1
                }`,
                newValue: formValues[key][index],
                oldValue: localPreviousValues[key][index],
              };
              auditObjectsArray.push(auditObject);
            }
          });
        }
      } else {
        // if key is just variable

        if (
          projectInvestedUserLabels[key] !== undefined &&
          formValues[key] !== localPreviousValues[key]
        ) {
          let auditObject = {
            itemAffected: projectInvestedUserLabels[key],
            newValue: stripHtml(formValues[key]),
            oldValue: stripHtml(localPreviousValues[key]),
          };
          auditObjectsArray.push(auditObject);
        }
      }
    });
    //set auditObjectsArray to a state to use it for api call ..
    // setModulesAudited(auditObjectsArray);
    // setModulesAudited([...modulesAudited,...auditObjectsArray]);
    setModulesAudited((prev: any) => {
      return [...prev, ...auditObjectsArray];
    });
    // }
  };
  const handleCloseFlashMessageDialog = (values: any) => {
    setChatManagementState("openFlashMessage", false);
    !error && navigate(RouteConstants.chatSupportManagementDashboard);

    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Chat Support Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: location.pathname,
      auditedObject: values,
      // auditedObject:chatManagementData,
      modulesAudited: modulesAudited,
      status: AuditStatus.EDITED,
    };
    !error &&
      modulesAudited?.length &&
      postAuditLogRequest(postAuditRequestbody);
  };

  const checkDisableState = (dirty: any) => {
    if (isCategoryDeleted) {
      return !isCategoryDeleted;
    } else {
      return !dirty;
    }
  };

  return (
    <Fragment>
      <PageHeading title="Chat Support Management" />
      <BreadCrumbs routes={breadcrumbRoutes} />
      <Container className="main-container">
        <Delayed>
          <Formik
            initialValues={
              chatManagementData === null
                ? projectInvestedUserInitialValues
                : chatManagementData
            }
            onSubmit={(values) => {
              setIsConfirmDeleteDialogOpen(true);
            }}
            validationSchema={validationSchemaInvestedUser}
          >
            {({
              isValid,
              dirty,
              values,
              setFieldValue,
              handleChange,
              handleBlur,
              setFieldError,
              errors,
              touched,
            }) => {
              return (
                <>
                  <Form>
                    <Stack>
                      <Stack>
                        <IconButton
                          color="inherit"
                          edge={"start"}
                          onClick={() => navigate(-1)}
                        >
                          <ChevronLeftIcon />
                        </IconButton>
                        <Typography color={"secondary.dark"} variant="h5">
                          Project-Invested User
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} columnGap={2}>
                        {!isViewMode ? (
                          <>
                            <ButtonComponent
                              type="reset"
                              variant="outlined"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </ButtonComponent>

                            <LoadingButtonComponent
                              variant="contained"
                              type="submit"
                              disabled={!isValid || checkDisableState(dirty)}
                            >
                              Publish
                            </LoadingButtonComponent>
                          </>
                        ) : (
                          <LoadingButtonComponent
                            type="button"
                            onClick={() => {
                              setIsViewMode(false);
                              setIsEditMode(true);
                            }}
                          >
                            Edit
                          </LoadingButtonComponent>
                        )}
                      </Stack>
                    </Stack>
                    <FormFieldContainer>
                      <Grid container>
                        <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                          <TextFieldWrapper
                            name="welcomeMessage"
                            value={values?.welcomeMessage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{
                              maxLength: "300",
                            }}
                            disabled={isViewMode}
                            placeholder="Enter"
                            label="Welcome Message"
                            required
                            fieldhelpertext={
                              getIn(touched, `welcomeMessage`) &&
                              getIn(errors, `welcomeMessage`)
                            }
                            error={Boolean(
                              getIn(touched, `welcomeMessage`) &&
                                getIn(errors, `welcomeMessage`)
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                          <TextFieldWrapper
                            name="firstMessage"
                            value={values?.firstMessage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{
                              maxLength: "300",
                            }}
                            disabled={isViewMode}
                            placeholder="Enter"
                            label="Message"
                            required
                            fieldhelpertext={
                              getIn(touched, `firstMessage`) &&
                              getIn(errors, `firstMessage`)
                            }
                            error={Boolean(
                              getIn(touched, `firstMessage`) &&
                                getIn(errors, `firstMessage`)
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sm={12}
                          lg={12}
                          xl={12}
                          className={styles["chat-box"]}
                        >
                          <Categories
                            isViewMode={isViewMode}
                            setPreviousValues={setPreviousValues}
                            previousValues={previousValues}
                            setIsCategoryDeleted={setIsCategoryDeleted}
                            setModulesAudited={setModulesAudited}
                            modulesAudited={modulesAudited}
                            categoryCount={categoryCount}
                          />
                        </Grid>

                        {/* <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                          <TextFieldWrapper
                            name="finalMessage"
                            value={values?.finalMessage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{
                              maxLength: "300",
                            }}
                            disabled={isViewMode}
                            placeholder="Enter"
                            label="Final Message"
                            required
                            fieldhelpertext={
                              getIn(touched, `finalMessage`) &&
                              getIn(errors, `finalMessage`)
                            }
                            error={Boolean(
                              getIn(touched, `finalMessage`) &&
                                getIn(errors, `finalMessage`)
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                          <TextFieldWrapper
                            name="inactiveMessage"
                            value={values?.inactiveMessage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{
                              maxLength: "300",
                            }}
                            disabled={isViewMode}
                            placeholder="Enter"
                            label="Inactive Message"
                            required
                            fieldhelpertext={
                              getIn(touched, `inactiveMessage`) &&
                              getIn(errors, `inactiveMessage`)
                            }
                            error={Boolean(
                              getIn(touched, `inactiveMessage`) &&
                                getIn(errors, `inactiveMessage`)
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                          <TextFieldWrapper
                            name="allowTypingMessage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.allowTypingMessage}
                            inputProps={{
                              maxLength: "300",
                            }}
                            disabled={isViewMode}
                            placeholder="Enter"
                            label="Typing enabled Message"
                            required
                            fieldhelpertext={
                              getIn(touched, `allowTypingMessage`) &&
                              getIn(errors, `allowTypingMessage`)
                            }
                            error={Boolean(
                              getIn(touched, `allowTypingMessage`) &&
                                getIn(errors, `allowTypingMessage`)
                            )}
                          />
                        </Grid> */}
                      </Grid>
                    </FormFieldContainer>
                  </Form>

                  <ConfirmationDialog
                    shouldOpen={isConfirmDeleteDialogOpen}
                    title="Publish?"
                    content={`Are you sure you want to Publish?`}
                    okText="Publish"
                    cancelHandler={handleCloseConfirmDialog}
                    okHandler={() => {
                      okHandler({ values, touched });
                    }}
                  />
                  <FlashMessageDialog
                    shouldOpen={openFlashMessage}
                    content={message}
                    isSuccess={isSuccess}
                    cancelHandler={() => handleCloseFlashMessageDialog(values)}
                  />
                </>
              );
            }}
          </Formik>
        </Delayed>
      </Container>
    </Fragment>
  );
};

export default ProjectsInvestedUser;
