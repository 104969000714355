import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { MediaAndPrsActions } from "redux-container/marketing-management/media-and-prs/MediaAndPrRedux";

export function* getMediaAndPrs(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getMediaAndPrs, action.params);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(MediaAndPrsActions.getMediaAndPrsSuccess(response.data));
    } else {
      yield put(MediaAndPrsActions.getMediaAndPrsFailure(response.data));
    }
  } catch (error) {
    yield put(MediaAndPrsActions.getMediaAndPrsFailure(error));
  }
}

export function* addMediaAndPr(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addMediaAndPr, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(MediaAndPrsActions.addMediaAndPrSuccess(response.data));
    } else {
      yield put(MediaAndPrsActions.addMediaAndPrFailure(response.data));
    }
  } catch (error) {
    yield put(MediaAndPrsActions.addMediaAndPrFailure(error));
  }
}

export function* updateMediaAndPr(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateMediaAndPr, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(MediaAndPrsActions.updateMediaAndPrSuccess(response.data));
    } else {
      yield put(MediaAndPrsActions.updateMediaAndPrFailure(response.data));
    }
  } catch (error) {
    yield put(MediaAndPrsActions.updateMediaAndPrFailure(error));
  }
}

export function* deleteMediaAndPrs(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteMediaAndPr, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(MediaAndPrsActions.deleteMediaAndPrSuccess(response.data));
    } else {
      yield put(MediaAndPrsActions.deleteMediaAndPrFailure(response.data));
    }
  } catch (error) {
    yield put(MediaAndPrsActions.deleteMediaAndPrFailure(error));
  }
}

export function* changeMediaAndPrsStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.changeMediaAndPrStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(MediaAndPrsActions.changeMediaAndPrStatusSuccess(response.data));
    } else {
      yield put(MediaAndPrsActions.changeMediaAndPrStatusFailure(response.data));
    }
  } catch (error) {
    yield put(MediaAndPrsActions.changeMediaAndPrStatusFailure(error));
  }
}

export function* updateMediaAndPrsPriority(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateMediaAndPrPriority, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        MediaAndPrsActions.updateMediaAndPrPrioritySuccess(response.data)
      );
    } else {
      yield put(
        MediaAndPrsActions.updateMediaAndPrPriorityFailure(response.data)
      );
    }
  } catch (error) {
    yield put(MediaAndPrsActions.updateMediaAndPrPriorityFailure(error));
  }
}
