import { Fragment, useState } from "react";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MediaAssets from "../../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "./MediaUploadList.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import ImageUploadDialog from "./image-upload-dialog/ImageUploadDialog";
import { useNavigate } from "react-router-dom";

const MediaUploadList = () => {
  const [selectedName, setSelectedName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showImageUploadDialog, setShowImageUploadDialog] = useState(false);
  const navigate = useNavigate();

  const breadcrumbRoutes: any = [
    {
      name: "Project Management",
      route: RouteConstants.projectManagementDashboard,
    },
    {
      name: "Media Upload",
      route: RouteConstants.mediaUploadList,
    },
  ];

  const tableHeadings = [
    { heading: "Media" },
    { heading: "Type" },
    { heading: "Name" },
    { heading: "URL" },
    { heading: "" },
  ];

  const medias = [
    {
      id: 1,
      media:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuV3f9xDvULNuSGq6on7d6-3H3EYeBrTd79YFSAscZDnrlADatbYCMIQ5bjtDV7hz4x8E&usqp=CAU",
      type: "Image",
      name: "Dapoli Plot",
      url: "https://plot-inventory/dapoli",
    },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleNameChange = (event: SelectChangeEvent) => {
    setSelectedName(event.target.value as string);
  };

  const handleMediaUpload = () => {
    setShowImageUploadDialog(true);
  };

  const handleBulkUpload = () => {
    navigate(RouteConstants.mediaBulkUpload);
  };

  const copyMediaLink = () => {};

  const handleCloseConfirmDialog = () => {
    setShowImageUploadDialog(false);
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_update}
        altText=""
        message="You haven't created any Media yet. Create your first Media."
      />
    );
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">Media Upload {`(${medias.length})`}</h4>
          {showImageUploadDialog === true && (
            <div data-testid="image-upload-dialog">
              <ImageUploadDialog
                shouldOpen={showImageUploadDialog}
                title="Upload New Image"
                cancelHandler={handleCloseConfirmDialog}
              />
            </div>
          )}
          <div>
            <Button
              variant="contained"
              onClick={handleMediaUpload}
              // autoFocus
              disableElevation
              className="btn btn-dark margin-right-15"
              data-testid="media-upload-btn"
            >
              Media Upload
            </Button>
            <Button
              variant="contained"
              onClick={handleBulkUpload}
              // autoFocus
              disableElevation
              className="btn btn-dark"
              data-testid="bulk-upload-btn"
            >
              Bulk Upload
            </Button>
          </div>
        </div>

        <Grid container spacing={1}>
          <Grid container spacing={2} md={5} lg={5} xl={5}>
            <Grid
              item
              md={1.5}
              lg={1.5}
              xl={1.5}
              className="margin-left-10 filter-heading-holder"
            >
              <span className="filter-heading">Filters:</span>
            </Grid>
            <Grid item md={5} lg={5} xl={5}>
              <FormControl className="filter-dropdown">
                <InputLabel>Name</InputLabel>
                <Select
                  value={selectedName}
                  label="Type"
                  onChange={handleNameChange}
                  IconComponent={() => (
                    <ExpandMoreRoundedIcon className="dropdown-icon" />
                  )}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer component={Paper} className="table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header-row">
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {medias.map((media) => (
                <TableRow key={media.id} className="table-data-row">
                  <TableCell>
                    <img
                      src={media.media}
                      alt=""
                      className={styles["media-dimension"]}
                    />
                  </TableCell>

                  <TableCell className="table-data-text">
                    {media.type}
                  </TableCell>

                  <TableCell className="table-data-text">
                    {media.name}
                  </TableCell>

                  <TableCell className="table-data-text">{media.url}</TableCell>

                  <TableCell>
                    <Tooltip title="Copy Link" placement="top">
                      <IconButton
                        aria-label="copy link"
                        onClick={copyMediaLink}
                      >
                        <ContentCopyIcon className="table-action-icon duplicate-icon" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={medias.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <PageHeading title="Project Management" />
      <Container className="main-container">
        {medias.length > 0 ? renderTable() : renderNoData()}
      </Container>
    </Fragment>
  );
};

export default MediaUploadList;
