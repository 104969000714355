import moment from "moment";

export const formatDateTime = (date: Date, format: string) => {
  let dateIST = new Date(date).toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  return moment(dateIST).format(format);
};

export const previousYearsConstant: any = () => {
  let previousYears: any = [];
  const mapYears = () =>
    [...Array(20)].map((_, index: any) => {
      previousYears.push((new Date().getFullYear() - index).toString());
    });
  mapYears();
  return previousYears;
};

export const futureYearsConstant: any = () => {
  let futureYears: any = [];
  const mapYears = () =>
    [...Array(20)].map((_, index: any) => {
      futureYears.push((new Date().getFullYear() + index).toString());
    });
  mapYears();
  return futureYears;
};

// export class DateUtils {
//   public startOfDateByAddingDays(date: Date, daysToAdd = 0) {
//     const momentObj = moment(date).startOf("day");
//     momentObj.add(daysToAdd, "days");
//     return momentObj.toDate();
//   }

//   public startOfMonth(date: Date) {
//     const momentObj = moment(date).startOf("month");
//     return momentObj.toDate();
//   }

//   public endOfMonth(date: Date) {
//     const momentObj = moment(date).endOf("month");
//     return momentObj.toDate();
//   }

//   public startOfPreviousMonth(date: Date) {
//     const momentObj = moment(date).subtract(1, "months").startOf("month");
//     return momentObj.toDate();
//   }

//   public endOfPreviousMonth(date: Date) {
//     const momentObj = moment(date).subtract(1, "months").endOf("month");
//     return momentObj.toDate();
//   }

//   public startOfWeek(date: Date) {
//     const momentObj = moment(date).startOf("isoWeek");
//     return momentObj.toDate();
//   }

//   public endOfWeek(date: Date) {
//     const momentObj = moment(date).endOf("isoWeek");
//     return momentObj.toDate();
//   }

//   public startOfPreviousWeek(date: Date) {
//     const momentObj = moment(date).subtract(1, "weeks").startOf("isoWeek");
//     return momentObj.toDate();
//   }

//   public endOfPreviousWeek(date: Date) {
//     const momentObj = moment(date).subtract(1, "weeks").endOf("isoWeek");
//     return momentObj.toDate();
//   }

//   public startOfYear(date: Date) {
//     const momentObj = moment(date).startOf("year");
//     return momentObj.toDate();
//   }

//   public startOfPreviousYear(date: Date) {
//     const momentObj = moment(date).subtract(1, "years").startOf("year");
//     return momentObj.toDate();
//   }

//   public endOfPreviousYear(date: Date) {
//     const momentObj = moment(date).subtract(1, "years").endOf("year");
//     return momentObj.toDate();
//   }

//   public daysDiff(date1: Date, date2: Date) {
//     return moment(date1).diff(moment(date2), "days");
//   }

//   public format(date: Date, format: string) {
//     return moment(date).format(format);
//   }

//   public getComponents(date: Date) {
//     const momentObj = moment(date);

//     const components = {
//       date: momentObj.format("D"),
//       month: momentObj.format("M"),
//       year: momentObj.format("YYYY"),
//     };
//     return components;
//   }
// }
