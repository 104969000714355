import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonComponent } from "components/ui-elements";
import { ReactNode } from "react";
import styles from "./CustomerRegistrationDetailsDialog.module.css";
import ClearIcon from "@mui/icons-material/Clear";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";

export interface ICustomerRegistration {
  fieldTitle: string;
  value: string | number;
}

interface ICustomerRegistrationDetailsDialog {
  size?: any;
  shouldOpen: boolean;
  title?: string;
  content: any;
  cancelHandler: (e?: any) => any;
  cancelBtnText?: string;
  paymentStatus?: boolean;
}

const CustomerRegistrationDetailsDialog = ({
  size = "md",
  shouldOpen,
  title = "Pending Amount",
  content,
  cancelHandler,
  cancelBtnText = "OKAY",
  paymentStatus = false,
}: ICustomerRegistrationDetailsDialog) => {
  const handleClose = () => {
    cancelHandler();
  };

  return (
    <Dialog
      maxWidth={size}
      fullWidth
      open={shouldOpen}
      disableEscapeKeyDown={true}
    >
      <Grid container>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <DialogTitle sx={{ justifyContent: "center" }}>
            <Typography
              textAlign={"end"}
              fontWeight={500}
              variant={"h6"}
              component={"h6"}
              ml={10}
            >
              {content?.paymentMilestone}
            </Typography>
          </DialogTitle>
        </Grid>

        <Grid
          className={styles["pending-payment-dialog-header"]}
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          justifyContent={"flex-end"}
        >
          <IconButton
            aria-label="cancel"
            onClick={handleClose}
            onMouseDown={(e: any) => e.preventDefault()}
          >
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent className={styles["dialog-content-section"]}>
        <Box className={styles["pending-amount-mention-section"]}>
          <div>
            <Typography
              sx={{ color: "#61592b" }}
              fontWeight={600}
              variant={"h6"}
              component={"h6"}
            >
              {/* {title} */}
              Amount Paid
            </Typography>
            <Typography fontWeight={600} variant={"h5"} component={"h6"}>
              ₹ {content?.paidAmount ? content?.paidAmount : " 0"}
            </Typography>
          </div>
        </Box>

        <Box paddingX={5} mt={2}>
          <Stack justifyContent={"center"} spacing={1}>
            <Typography>{"Pending Amount"}</Typography>
            <Typography fontWeight={600} variant={"h6"} component={"h6"}>
              ₹{content?.pendingAmount ? content?.pendingAmount : " 0"}
            </Typography>
          </Stack>
          {content?.targetDate ? (
            <Stack justifyContent={"center"} spacing={1} mt={1}>
              <Typography className={styles["font-weight-bold"]}>
                Due Date :{" "}
              </Typography>
              <Typography className={styles["font-weight-bold"]}>
                {formatDateTime(
                  content?.targetDate,
                  Constants.dateFormatDDmmYYYY
                )}
              </Typography>
            </Stack>
          ) : (
            "-"
          )}
        </Box>
      </DialogContent>

      <Divider />
      <DialogActions className={styles["dialog-actions"]}>
        <ButtonComponent
          className={styles["okay-btn"]}
          variant="text"
          color="inherit"
          onClick={handleClose}
        >
          {cancelBtnText}
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerRegistrationDetailsDialog;
