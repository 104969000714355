import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import { SelectChangeEvent } from "@mui/material/Select";
import styles from "./ProjectCloneList.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PanoramaIcon from "@mui/icons-material/Panorama";

import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import {
  Box,
  Card,
  CardMedia,
  DialogActions,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ProjectListActions from "redux-container/project-management/ProjectListRedux";
import Loader from "components/loader/Loader";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";
import { Search } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { priceFormatter } from "utils/AmountFormatter";

const actionDispatch = (dispatch: any) => {
  return {
    getProjectList: (params: any) =>
      dispatch(ProjectListActions.projectListRequest(params)),
  };
};

interface IProjectListDialogProps {
  shouldOpen: boolean;
  cancelHandler: () => void;
  mapProjectList?: any;
  initialMapList?: any;
  onCloneProjectClick?: any;
}

const ProjectListDialog = ({
  shouldOpen,
  cancelHandler,
  mapProjectList,
  initialMapList,
  onCloneProjectClick,
}: IProjectListDialogProps) => {
  let initialMappedIds = initialMapList?.map((item: any) => item?.id);
  const projects = useSelector((state: any) => state.projectList?.data.data);
  let filterSelectedRows = projects?.filter((item: any) =>
    initialMappedIds?.includes(item?.id)
  );

  const [selectedProjects, setSelectedProjects] = useState<any>(
    initialMappedIds?.length > 0 ? initialMappedIds : []
  );

  const [selectedRow, setSelectedRow] = useState<any>(
    filterSelectedRows?.length > 0 ? filterSelectedRows : []
  );
  const { getProjectList } = actionDispatch(useDispatch());
  const isLoading = useSelector((state: any) => state.projectList.isLoading);

  useEffect(() => {
    getProjectList({});
  }, []);

  const tableHeadings = [
    { heading: "Project ID" },
    { heading: "Thumbnail" },
    { heading: "Name" },
    { heading: "Location" },
    { heading: "Starting Price" },
    { heading: "Starting Area" },
    { heading: "Status" },
  ];

  const handleClose = () => {
    cancelHandler();
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedProjects?.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedProjects, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedProjects?.slice(1));
    } else if (selectedIndex === selectedProjects?.length - 1) {
      newSelected = newSelected.concat(selectedProjects?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedProjects?.slice(0, selectedIndex),
        selectedProjects?.slice(selectedIndex + 1)
      );
    }
    setSelectedProjects(newSelected);
  };

  const handleOnRowClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedRow?.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRow, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRow?.slice(1));
    } else if (selectedIndex === selectedRow?.length - 1) {
      newSelected = newSelected.concat(selectedRow?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRow?.slice(0, selectedIndex),
        selectedRow?.slice(selectedIndex + 1)
      );
    }
    setSelectedRow(newSelected);
  };

  const isSelected = (name: string) => selectedProjects?.indexOf(name) !== -1;

  const mapProjectSelection = () => {
    let combineList = initialMapList.concat(selectedRow);
    const filterOnlySelecetedId = projects?.filter((item: any) =>
      selectedProjects?.includes(item?.id)
    );
    const removeDuplicates = filterOnlySelecetedId.filter(
      (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i
    );
    mapProjectList(removeDuplicates);
    setSelectedProjects([]);
    setSelectedRow([]);
    onCloneProjectClick();
    handleClose();
  };

  const handleResetFilter = () => {
    setSelectedProjects([]);
  };

  return (
    <Dialog
      open={shouldOpen}
      onClose={handleClose}
      maxWidth={"xl"}
      fullWidth={true}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Fragment>
          <Container className="main-container">
            <div className="heading-action-holder">
              <Stack
                flexDirection={"column"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
              >
                <Typography
                  variant="h6"
                  fontWeight={"Bold"}
                  color={"secondary.dark"}
                >
                  Select any one Project to Clone
                </Typography>

                <Box>
                  {selectedProjects.length > 0 ? (
                    <Typography>
                      Only 1 Project is allowed to clone at a time{" "}
                      <span
                        onClick={handleResetFilter}
                        className={`${styles["clear-filter-text"]} clear-filter`}
                      >
                        Clear
                      </span>{" "}
                    </Typography>
                  ) : null}
                </Box>
              </Stack>
              <div>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  disableElevation
                  className="btn btn-light-outline margin-right-15"
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  onClick={mapProjectSelection}
                  autoFocus
                  disableElevation
                  disabled={selectedProjects?.length === 0 ? true : false}
                  className={
                    selectedProjects?.length > 0 ? "btn btn-dark" : "btn"
                  }
                >
                  Clone Project
                </Button>
              </div>
            </div>

            <Box mt={5}>
              <TableContainer
                component={Paper}
                className="table-container"
                style={{ height: window.innerHeight - 475 }}
              >
                {isLoading ? (
                  <Loader />
                ) : (
                  <Table style={{ position: "relative" }}>
                    <TableHead>
                      <TableRow className="table-header-row">
                        <TableCell padding="checkbox">
                          <Checkbox
                            className="active-checkbox"
                            indeterminate={
                              selectedProjects?.length > 0 &&
                              selectedProjects?.length < projects?.length
                            }
                            checked={
                              projects?.length > 0 &&
                              selectedProjects?.length === projects?.length
                            }
                            onChange={handleResetFilter}
                            inputProps={{
                              "aria-label": "select all desserts",
                            }}
                          />
                        </TableCell>
                        {tableHeadings.map((item, index) => (
                          <TableCell key={index} className="table-header-text">
                            {item.heading}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projects?.length > 0 ? (
                        projects?.map((project: any) => {
                          const isItemSelected = isSelected(project.id);
                          return (
                            <TableRow
                              key={project.id}
                              className="table-data-row"
                              hover
                              onClick={(event) => {
                                if (
                                  selectedProjects?.length === 0 ||
                                  isItemSelected
                                ) {
                                  handleClick(event, project.id);
                                  handleOnRowClick(event, project);
                                }
                              }}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  className="active-checkbox"
                                  checked={isItemSelected}
                                />
                              </TableCell>

                              <TableCell className="table-data-text">
                                #ID-{project.id}
                              </TableCell>

                              <TableCell>
                                {project?.projectCoverImages?.homePageMedia
                                  ?.value?.url ? (
                                  <Card
                                    elevation={0}
                                    className={styles["project-image"]}
                                    style={{
                                      height: "80px",
                                      width: "100%",
                                      objectFit: "fill",
                                    }}
                                  >
                                    <CardMedia
                                      className={styles["media-area"]}
                                      component={"img"}
                                      alt="Image"
                                      src={
                                        project?.projectCoverImages
                                          ?.homePageMedia?.value?.url
                                      }
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "fill",
                                      }}
                                    />
                                  </Card>
                                ) : (
                                  <Box sx={{ textAlign: "center" }}>
                                    <PanoramaIcon
                                      style={{
                                        height: "3.5rem",
                                        width: "4rem",
                                      }}
                                    />
                                  </Box>
                                )}
                              </TableCell>

                              <TableCell className="table-data-text">
                                {project?.launchName}
                              </TableCell>

                              <TableCell className="table-data-text">
                                {project?.address?.city}
                                {project?.address?.state
                                  ? ", " + project?.address?.state
                                  : ""}
                              </TableCell>

                              <TableCell className="table-data-text">
                                {project?.priceStartingFrom
                                  ? `₹` +
                                    priceFormatter(project?.priceStartingFrom)
                                  : "-"}
                              </TableCell>

                              <TableCell className="table-data-text">
                                {project?.areaStartingFrom}
                              </TableCell>

                              <TableCell className="table-data-text">
                                {project?.status === "1001" ? "Live" : "Hidden"}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <div className="no-record">No Projects Found</div>
                      )}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Box>
          </Container>
        </Fragment>
      </DialogContent>

      {/* <DialogActions className={styles["dialog-table-pagination"]}>
        {!searchKey && (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={
              projectTotalCount
                ? projectTotalCount
                : projects?.length
                ? projects?.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </DialogActions> */}
    </Dialog>
  );
};

export default ProjectListDialog;
