import { Avatar, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { couldStartTrivia } from 'typescript';
import { format, compareAsc } from 'date-fns'
import PrioritySelector from 'components/ui-elements/priority-selector/PrioritySelector';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { SwitchComponent } from 'components/ui-elements';
import MediaAssets from 'assets';
import { hoablPage1Action } from 'redux-container/page-management/hoabl-page-1/HoablPage1Redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDateTime } from 'utils/DateFormatterUtils';
import { Constants } from 'constants/Constants';
import { useFormikContext } from 'formik';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import { RouteConstants } from 'routes/RouteConstants';

const actionDispatch = (dispatch: any) => {
    return {
        reduxProjectListWithPriority: (key: any, data: any) => dispatch(hoablPage1Action.setHoablPage1State(key, data)),
    };
};


export default function MappedTable(props: any) {
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } = useFormikContext<any>()
    const { reduxProjectListWithPriority } = actionDispatch(useDispatch())
    const { projectListFromProjectTable, mappedNewInvestment, setMappedNewInvestment, isEditMode } = props

    const { totalCount, projectContentList, isLoading
    } = useSelector((state: any) => state.projectContentListHoablPage1);
    // const [mappedNewInvestment, setMappedNewInvestment] = useState([])
    const { hoablPage1, projectListWithPriorityReduxState } = useSelector((state: any) => state?.hoablPage1Data)
    const [isSortByPriority, setSortByPriority] = useState(false)
    const [openProjectRemoveConfirmation, setOpenProjectRemoveConfirmation] = useState(false)
    const [projectRemoveId, setProjectRemoveId] = useState(null)
    const [removeProjectPriority, setRemoveProjectPriority] = useState(false)
    const [removeProjectindex, setRemoveProjectindex] = useState(null)
    const location: any = useLocation();
    const navigate = useNavigate()
    const tableHeadings = [
        { heading: "Project ID" },
        { heading: "Thumbnail" },
        { heading: "Name" },
        { heading: "Location" },
        { heading: "Price Starts from" },
        { heading: "Area Starts from" },
        { heading: "Created on" },
        { heading: "Priority" },
        { heading: "Action" }
    ];


    const handlePriorityIncreament = (id: any, changePriority: any) => {
        const projectChangedPriority = mappedNewInvestment.map((project: any, index: any) => {
            if (id === project.id) {
                return {
                    ...project,
                    priority: project.priority + 1
                }
            } else {
                if (project.priority === changePriority + 1) {
                    return {
                        ...project,
                        priority: project.priority - 1
                    }
                } else {
                    return project
                }
            }
        })
        setMappedNewInvestment(projectChangedPriority)
        reduxProjectListWithPriority("projectListWithPriorityReduxState", projectChangedPriority)
    }


    const handlePriorityDecreament = (id: any, changePriority: any) => {
        const projectChangedPriority = mappedNewInvestment.map((project: any, index: any) => {
            if (id === project.id) {
                return {
                    ...project,
                    priority: project.priority - 1
                }
            } else {
                if (project.priority === changePriority - 1) {
                    return {
                        ...project,
                        priority: project.priority + 1
                    }
                } else {

                    return project
                }
            }
        })
        setMappedNewInvestment(projectChangedPriority)
        reduxProjectListWithPriority("projectListWithPriorityReduxState", projectChangedPriority)
    }

    const handleDeleteProject = (id: any, index: any, priority: any) => {
        if(mappedNewInvestment.length === values?.totalProjectsOnHomeScreen){
            setFieldValue('totalProjectsOnHomeScreen', values?.totalProjectsOnHomeScreen - 1)
        }
        const deletingProjectListItem = mappedNewInvestment.filter((project: any) => project.id !== id)
        const deletedProjectList = deletingProjectListItem.map((project: any, index: any) => {
            if (project?.priority > priority) {
                return {
                    ...project,
                    priority: project?.priority - 1
                }
            } else {
                return project
            }
        })
        setMappedNewInvestment(deletedProjectList)
        reduxProjectListWithPriority("projectListWithPriorityReduxState", deletedProjectList)
        setOpenProjectRemoveConfirmation(false)
    }

    const sortingByPriority = mappedNewInvestment.sort((project1: any, project2: any) => {
        return project1.priority - project2.priority
    })
    const handlePriorityChange = () => {
        setSortByPriority(!isSortByPriority)
    }

    const handleCloseConfirmationDialog = () => {
        setOpenProjectRemoveConfirmation(false)
    }

    const handleViewIconClick = (publishedId: number) => {
        navigate(RouteConstants.projectCreation, {
          state: { isNew: false, ProjectListCreationId: publishedId, isViewMode: true },
        });
      };

    return (
        <>
        {openProjectRemoveConfirmation && (
            <ConfirmationDialog
            shouldOpen={openProjectRemoveConfirmation}
            title="Remove Mapped Project?"
            content="This action will Remove Mapped Project. Are you sure you want to continue?"
            okText="Remove"
            cancelHandler={handleCloseConfirmationDialog}
            okHandler={() => handleDeleteProject(projectRemoveId, removeProjectindex, removeProjectPriority)}
          />
        )}
            <Stack justifyContent={"flex-end"} spacing={2}>
                <Typography>Sort by Priority</Typography>
                <SwitchComponent checked={isSortByPriority} onChange={handlePriorityChange} />
                <Tooltip title={"Toggle is to show/hide this section on the Customer App"} placement="top-start">
                    <Avatar
                        sx={{
                            width: "0.9rem",
                            height: "0.9rem",
                            cursor: "pointer",
                            marginTop: "0.10rem",
                            marginBottom: "0.10rem",
                        }}
                        src={MediaAssets.ic_info}
                        variant="rounded"
                    />
                </Tooltip>
            </Stack>
            <TableContainer
                component={Paper}
                className="table-container"
                style={{ height: window.innerHeight - 575 }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {tableHeadings.map((item, index) => (
                                <TableCell key={index} className="table-header-text">
                                    {item.heading}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(mappedNewInvestment).map((project: any, index: any) => {
                            return (
                              <TableRow className="table-data-row">
                                <TableCell className="table-data-text">
                                  {project?.id}
                                </TableCell>
                                <TableCell className="table-data-text">
                                  {project.projectCoverImages?.homePageMedia
                                    ?.value?.url.length > 0 && (
                                    <Avatar
                                      style={{ height: "70px", width: "110px" }}
                                      // className={styles["Media-box"]}
                                      src={
                                        project.projectCoverImages
                                          ?.homePageMedia?.value?.url
                                      }
                                    ></Avatar>
                                  )}
                                </TableCell>
                                <TableCell className="table-data-text">
                                  {project?.launchName}
                                </TableCell>
                                <TableCell className="table-data-text">
                                  {`${project?.address?.city}, ${project?.address?.state} `}
                                </TableCell>
                                <TableCell className="table-data-text">
                                  {project?.priceStartingFrom}
                                </TableCell>
                                <TableCell className="table-data-text">
                                  {project?.areaStartingFrom}
                                </TableCell>
                                <TableCell
                                  className="table-data-text"
                                  style={{ minWidth: 120 }}
                                >
                                  <div>
                                    {
                                      project?.projectContentCreatedBy
                                        ?.firstName
                                    }
                                  </div>
                                  {project?.createdAt
                                    ? formatDateTime(
                                        project?.createdAt,
                                        Constants.dateFormatDDmmYYYY
                                      )
                                    : "-"}
                                </TableCell>
                                <TableCell>
                                  <PrioritySelector
                                    priority={project?.priority}
                                    incrementpriority={() =>
                                      handlePriorityIncreament(
                                        project?.id,
                                        project?.priority
                                      )
                                    }
                                    decrementpriority={() =>
                                      handlePriorityDecreament(
                                        project?.id,
                                        project?.priority
                                      )
                                    }
                                    disableIncrement={
                                      project?.priority ===
                                      mappedNewInvestment?.length
                                        ? true
                                        : false
                                    }
                                    disableDecrement={
                                      project?.priority === 1 ? true : false
                                    }
                                    isPriorityRequired={
                                      location?.state?.isViewMode && isEditMode
                                        ? true
                                        : !isSortByPriority
                                    }
                                    isCounter={true}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Stack
                                    justifyContent={"flex-start"}
                                    spacing={1}
                                  >
                                    <IconButton
                                      disabled={
                                        location?.state?.isViewMode &&
                                        isEditMode
                                      }
                                    >
                                      {mappedNewInvestment.length > 1 ? (
                                        <Typography
                                          sx={{
                                            cursor: "pointer",
                                            color: "black",
                                            textDecoration: "underline",
                                          }}
                                          onClick={() => {
                                            setOpenProjectRemoveConfirmation(
                                              true
                                            );
                                            setProjectRemoveId(project?.id);
                                            setRemoveProjectPriority(
                                              project?.priority
                                            );
                                            setRemoveProjectindex(index);
                                          }}
                                        >
                                          Remove
                                        </Typography>
                                      ) : (
                                        <Typography
                                          color={"#CFD2CF"}
                                          sx={{ cursor: "text" }}
                                        >
                                          Remove
                                        </Typography>
                                      )}
                                    </IconButton>
                                    <IconButton
                                      onClick={() =>
                                        handleViewIconClick(project?.id)
                                      }
                                    >
                                      <RemoveRedEyeIcon />
                                    </IconButton>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </>

    )
}
