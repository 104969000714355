import { createActions, createReducer } from "reduxsauce";

export const INITIAL_STATE = {
  isLoading: false,
  insights: [],
  isUpdated: false,
  isDeleted: false,
  message: "",
  isSuccess: false,
  shouldOpenFlashDialog: false,
  totalCount: 0,
  page:0,
  rowsPerPage:5
};

const { Types, Creators } = createActions({
  getInsightsRequest: ["data"],
  getInsightsSuccess: ["response"],
  getInsightsFailure: ["error"],

  updateInsightStatusRequest: ["data"],
  updateInsightStatusSuccess: ["response"],
  updateInsightStatusFailure: ["error"],

  updateInsightPriorityRequest: ["data"],
  updateInsightPrioritySuccess: ["response"],
  updateInsightPriorityFailure: ["error"],

  deleteInsightsRequest: ["data"],
  deleteInsightsSuccess: ["response"],
  deleteInsightsFailure: ["error"],

  setInsightsState: ["key", "value"],
});
export const InsightsTypes = Types;
export default Creators;

const updateInsightPriorityRequest = (state: any) => {
  return {
    ...state,
    isloading: true,
  };
};
const updateInsightPrioritySuccess = (state: any, action: any) => {
  return {
    ...state,
    isloading: false,
    isUpdated: true,
    // // shouldOpenFlashDialog: true,
    // isSuccess: true,
    // message: action?.response?.data?.message,
  };
};
const updateInsightPriorityFailure = (state: any, action: any) => {
  return {
    ...state,
    isloding: false,
    isUpdated: true,
    shouldOpenFlashDialog: true,
    isSuccess: false,
    message: action?.error?.data?.message
      ? action?.error?.data?.message
      : "Failed to Update",
  };
};

export const deleteInsightsRequest = (state: any) => {
  return {
    ...state,
    isloading: true,
  };
};

export const deleteInsightsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isDeleted: false,
    message: action?.error?.message
      ? action?.error?.message
      : "Failed to Delete",
    shouldOpenFlashDialog: true,
    isSuccess: false,
  };
};
export const deleteInsightsSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isDeleted: true,
    message: action?.response?.data?.message,
    shouldOpenFlashDialog: true,
    isSuccess: true,
  };
};

export const updateInsightStatusRequest = (state: any) => {
  return {
    ...state,
    isloading: true,
  };
};

export const updateUserStatusSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isUpdated: true,
    message: action?.response?.data?.message,
    shouldOpenFlashDialog: true,
    isSuccess: true,
  };
};
export const updateInsightStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isloading: false,
    message: action?.error?.data?.message
      ? action?.error?.data?.message
      : "Failed to Update",
    shouldOpenFlashDialog: true,
    isSuccess: false,

    // isSuccess:true,
  };
};

export const getInsightsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getInsightsSuccess = (state: any, action: any) => {
  return {
    ...state,
    insights: action?.response?.data,
    totalCount: action?.response?.data?.totalCount,
    isLoading: false,
    isUpdated: false,
    isDeleted: false,
  };
};

export const getInsightsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    message: action?.error?.data?.message,
    isUpdated: false,
    isDeleted: false,
    insights: [],
    totalCount: 0,
  };
};

export const setInsightsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_INSIGHTS_REQUEST]: getInsightsRequest,
  [Types.GET_INSIGHTS_SUCCESS]: getInsightsSuccess,
  [Types.GET_INSIGHTS_FAILURE]: getInsightsFailure,

  [Types.UPDATE_INSIGHT_STATUS_REQUEST]: updateInsightStatusRequest,
  [Types.UPDATE_INSIGHT_STATUS_SUCCESS]: updateUserStatusSuccess,
  [Types.UPDATE_INSIGHT_STATUS_FAILURE]: updateInsightStatusFailure,

  [Types.UPDATE_INSIGHT_PRIORITY_REQUEST]: updateInsightPriorityRequest,
  [Types.UPDATE_INSIGHT_PRIORITY_SUCCESS]: updateInsightPrioritySuccess,
  [Types.UPDATE_INSIGHT_PRIORITY_FAILURE]: updateInsightPriorityFailure,

  [Types.DELETE_INSIGHTS_REQUEST]: deleteInsightsRequest,
  [Types.DELETE_INSIGHTS_SUCCESS]: deleteInsightsSuccess,
  [Types.DELETE_INSIGHTS_FAILURE]: deleteInsightsFailure,

  [Types.SET_INSIGHTS_STATE]: setInsightsState,
});
