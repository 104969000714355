import * as Yup from "yup";

const CheckWithCondition = (requiredText: any) => ({
  is: true,
  then: Yup.string().required(requiredText),
});

export const validationSchema = Yup.object({
  newInvestments: Yup.object({
    displayName: Yup.string().required("Required"),
    subHeading: Yup.string().required("Required"),
  }),
  isPromotionAndOfferActive: Yup.boolean(),
  isPromotionAndOfferActiveForWeb: Yup.boolean(),
  promotionAndOffersProjectContentId: Yup.string()
    .nullable()
    .when("isPromotionAndOfferActive", {
      is: true,
      then: Yup.string().required("Required"),
    }),
  promotionAndOffersMedia: Yup.object().when(
    ["isPromotionAndOfferActive", "isPromotionAndOfferActiveForWeb"],
    {
      is: (
        isPromotionAndOfferActive: any,
        isPromotionAndOfferActiveForWeb: any
      ) => isPromotionAndOfferActive || isPromotionAndOfferActiveForWeb,
      then: Yup.object({
        value: Yup.object({
          url: Yup.string().required("Required"),
        }),
      }),
    }
  ),

  isCollectionOneActive: Yup.boolean(),
  isCollectionOneActiveForWeb: Yup.boolean(),
  collectionOne: Yup.object().when(
    ["isCollectionOneActive", "isCollectionOneActiveForWeb"],
    {
      is: (isCollectionOneActive: any, isCollectionOneActiveForWeb: any) =>
        isCollectionOneActive || isCollectionOneActiveForWeb,
      then: Yup.object({
        heading: Yup.string().required("Required"),
        subHeading: Yup.string().required("Required"),
        totalProjectContentsToDisplay: Yup.string().required("Required"),
      }),
    }
  ),

  isCollectionTwoActive: Yup.boolean(),
  isCollectionTwoActiveForWeb: Yup.boolean(),
  collectionTwo: Yup.object().when(
    ["isCollectionTwoActive", "isCollectionTwoActiveForWeb"],
    {
      is: (isCollectionTwoActive: any, isCollectionTwoActiveForWeb: any) =>
        isCollectionTwoActive || isCollectionTwoActiveForWeb,
      then: Yup.object({
        heading: Yup.string().required("Required"),
        subHeading: Yup.string().required("Required"),
        totalProjectContentsToDisplay: Yup.string().required("Required"),
      }),
    }
  ),
});
