import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Stack, Typography } from "@mui/material";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Container from "@mui/material/Container";
import { FormFieldContainer } from "components/ui-elements";
import UrlDashboardActions from "../../../redux-container/landing-page-management/LandingPageDashboardRedux";
import PageHeading from "../../../components/page-heading/PageHeading";
import { RouteConstants } from "routes/RouteConstants";
import styles from "./LandingPageManagement.module.css";
import { TestimonialStatusTypeEnum } from "enumerations/StatusTypeEnum";
import { LandingPageStatusTypeEnum } from "enumerations/StatusTypeEnum";

var Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

const actionDispatch = (dispatch: any) => {
  return {
    getLandingPageDashboardRequest: () =>
      dispatch(UrlDashboardActions.getLandingPageDashboardRequest()),
    setLandingPageDashboardState: (key: any, data: any) =>
      dispatch(UrlDashboardActions.setLandingPageDashboardState(key, data)),
  };
};

const LandingPageManagementDashboard = () => {
  const { getLandingPageDashboardRequest, setLandingPageDashboardState } =
    actionDispatch(useDispatch());

  const navigate = useNavigate();

  const landingPageDashboard = useSelector(
    (state: any) => state?.landingPageDashboard?.urlDashboardData
  );
  const isGraphDataFetched = useSelector(
    (state: any) => state?.landingPageDashboard?.isGraphDataFetched
  );

  const handleUpdateIcon = () => {
    navigate(RouteConstants.urlList, {
      state: { isNavigationFromSidemenu: true },
    });
  };

  const handleTestimonialIcon = () => {
    navigate(RouteConstants.testimonialsList, {
      state: { isNavigationFromSidemenu: true },
    });
  };

  const filterByStatus = (statusType: TestimonialStatusTypeEnum) => {
    const filterData = {
      statusType: statusType,
      isNavigationFromSidemenu: true,
    };
    navigate(`${RouteConstants.testimonialsList}`, {
      state: filterData,
    });
  };

  const filterByUpdateStatus = (statusType: LandingPageStatusTypeEnum) => {
    const filterData = {
      statusType: statusType,
      isNavigationFromSidemenu: true,
    };
    navigate(`${RouteConstants.urlList}`, {
      state: filterData,
    });
  };

  const breadcrumbRoutes: any = [
    {
      name: "Landing Page Management",
      route: RouteConstants.landingPageManagementDashboard,
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  // useEffect(() => {
  //   loadData();
  //   configureMarketingChart();
  //   configureTestimonialsChart();
  // }, []);

  useEffect(() => {
    if (isGraphDataFetched) {
      setLandingPageDashboardState("isGraphDataFetched", false);
      configureMarketingChart();
    }
  }, [isGraphDataFetched]);

  const loadData = () => {
    getLandingPageDashboardRequest();
  };

  const configureMarketingChart = () => {
    Highcharts.chart("updates-container", {
      chart: {
        backgroundColor: "#f7f6ff",
        plotBackgroundColor: "#f7f6ff",
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          slicedOffset: 0,
          dataLabels: {
            enabled: true,
            distance: -50,
            y: 0,
            style: {
              fontWeight: "bold",
              color: "black",
              fontSize: "14px",
            },
            filter: {
              property: "name",
              operator: "===",
              value: "Urls",
            },
          },
          borderWidth: 0,
        },
        series: {
          animation: true,
        },
      },
      series: [
        {
          type: "pie",
          name: "Total",
          innerSize: "80%",
          data: [
            {
              name: "Active Urls",
              y: landingPageDashboard?.urlsOverview?.activeUrlsCount,
              color: "#54b652",
              sliced: true,
            },
            {
              name: "Inactive Urls",
              y: landingPageDashboard?.urlsOverview?.inactiveUrlsCount,
              color: "#f37c7c",
              sliced: true,
            },
          ],
        },
      ],
    });
  };

  const navigateToUpdates = () => {
    navigate(RouteConstants.urlList, {
      state: { isNavigationFromSidemenu: true },
    });
  };

  return (
    <Fragment>
      <PageHeading title="Url Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

      <Container>
        <Grid className="heading-action-holder">
          <Typography className="heading">Dashboard</Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              onClick={handleUpdateIcon}
              // autoFocus
              endIcon={<ChevronRightRoundedIcon />}
              disableElevation
              className="btn btn-dark"
            >
              Go To Urls
            </Button>
          </Stack>
        </Grid>

        <Typography className="graph-container-heading"> Urls </Typography>
        <FormFieldContainer>
          <Grid className="graph-data-container">
            <Typography className="graph-heading">Urls Overview</Typography>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                className={styles["graph-container-holder"]}
              >
                <Grid id="updates-container" className="graph-holder" />
              </Grid>

              <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    className={styles["total-stats-holder"]}
                    onClick={navigateToUpdates}
                  >
                    <span className="stat-card-total-value">
                      {landingPageDashboard?.urlsOverview?.totalUrlsCount}
                    </span>
                    <span className="stat-card-total-heading">
                      Total Published Urls
                    </span>
                    <span
                      className={styles["stat-card-total-icon"]}
                      onClick={handleUpdateIcon}
                    >
                      <ChevronRightRoundedIcon />
                    </span>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid
                      className={styles["individual-stats-holder"]}
                      onClick={() =>
                        filterByUpdateStatus(LandingPageStatusTypeEnum.Inactive)
                      }
                    >
                      <span className="individual-stat-value-text red-stat-value">
                        {landingPageDashboard?.urlsOverview?.inactiveUrlsCount}
                      </span>
                      <span className="individual-stat-card-heading">
                        Inactive Urls
                      </span>
                      <span
                        className={styles["individual-stat-card-icon"]}
                        onClick={handleUpdateIcon}
                      >
                        <ChevronRightRoundedIcon />
                      </span>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid
                      className={styles["individual-stats-holder"]}
                      onClick={() =>
                        filterByUpdateStatus(
                          LandingPageStatusTypeEnum.Published
                        )
                      }
                    >
                      <span className="individual-stat-value-text green-stat-value">
                        {landingPageDashboard?.urlsOverview.activeUrlsCount}
                      </span>
                      <span className="individual-stat-card-heading">
                        Active Urls
                      </span>
                      <span
                        className={styles["individual-stat-card-icon"]}
                        onClick={handleUpdateIcon}
                      >
                        <ChevronRightRoundedIcon />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormFieldContainer>
      </Container>
    </Fragment>
  );
};

export default LandingPageManagementDashboard;
