export const enum RatingTypeEnum {
  // All = -1,
  RatingOne = 1,
  RatingTwo = 2,
  RatingThree = 3,
  RatingFour = 4,
  RatingFive = 5,
}
export class RatingRoleTypeEnumUtils {
  public static getEnumText(type: RatingTypeEnum): string {
    switch (type) {
      case RatingTypeEnum.RatingOne:
        return "Very Poor";
      case RatingTypeEnum.RatingTwo:
        return "Bad";
      case RatingTypeEnum.RatingThree:
        return "OK";
      case RatingTypeEnum.RatingFour:
        return "Good";
      case RatingTypeEnum.RatingFive:
        return "Excellent";
    }
  }

  public static getRatingTypeEnums(): {
    label: string;
    value: RatingTypeEnum;
  }[] {
    const options: { label: string; value: RatingTypeEnum }[] = [
      {
        label: this.getEnumText(RatingTypeEnum.RatingOne),
        value: RatingTypeEnum.RatingOne,
      },
      {
        label: this.getEnumText(RatingTypeEnum.RatingTwo),
        value: RatingTypeEnum.RatingTwo,
      },
      {
        label: this.getEnumText(RatingTypeEnum.RatingThree),
        value: RatingTypeEnum.RatingThree,
      },
      {
        label: this.getEnumText(RatingTypeEnum.RatingFour),
        value: RatingTypeEnum.RatingFour,
      },
      {
        label: this.getEnumText(RatingTypeEnum.RatingFive),
        value: RatingTypeEnum.RatingFive,
      },
    ];
    return options;
  }
}
