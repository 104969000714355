import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  popoverClasses,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import MediaAssets from "assets";
import {
  ButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { FieldArray, Formik, getIn, useFormikContext } from "formik";
// import styles from "./KeyPillars.module.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  IInitinalValueProjectContent,
  InitinalValueProjectContent,
} from "../UpCommingInitialValues";
import KeyPillarsImageUploader from "../../key-pillars/KeyPillarsImageUploader";
import { FormFieldContainerWrapper } from "./TourismAround";

function KeyPillars(props:any) {
    const { isViewMode } = props;

  const handleOnGlobalSave = (errors: any, touched: any) => {};
  const { values, setFieldValue, errors, handleChange, handleBlur, touched } =
    useFormikContext<IInitinalValueProjectContent>();
  console.log("errors", errors);

  // const isViewMode = useSelector(
  //   (state: any) => state?.projectContentManagement?.isViewMode
  // );
  return (
    <>
      <div
      //   className={styles["container-padding"]}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Stack justifyContent={"flex-start"} paddingBottom={2} spacing={1}>
              <Typography>Key Pillars</Typography>

              <SwitchComponent
                name="isKeyPillarsActive"
                checked={values?.isKeyPillarsActive}
                onChange={handleChange}
                disabled={isViewMode}
              />

              <IconButton>
                <Tooltip
                  title={
                    "Toggle is to show/hide this section on the Customer App"
                  }
                  placement="top"
                >
                  <Avatar
                    style={{
                      width: "0.9rem",
                      height: "0.9rem",
                      cursor: "pointer",
                    }}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>
              <Typography>Key Pillars for Website</Typography>

              <SwitchComponent
                name="isKeyPillarsActiveForWeb"
                checked={values?.isKeyPillarsActiveForWeb}
                onChange={handleChange}
                disabled={isViewMode}
              />

              <IconButton>
                <Tooltip
                  title={
                    "Toggle is to show/hide this section on the Customer App"
                  }
                  placement="top"
                >
                  <Avatar
                    style={{
                      width: "0.9rem",
                      height: "0.9rem",
                      cursor: "pointer",
                    }}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={3.5} lg={3.5} md={3.5} sm={3.5} xl={4}>
            <TextFieldComponent
              name="keyPillars.heading"
              placeholder="Enter"
              label="Section Heading *"
              fieldhelpertext={
                getIn(touched, `keyPillars.heading`) &&
                getIn(errors, `keyPillars.heading`)
              }
              error={Boolean(
                getIn(errors, "keyPillars.heading") &&
                  getIn(touched, "keyPillars.heading")
              )}
              inputProps={{
                maxLength: 40,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.keyPillars?.heading}
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
      </div>

      <FieldArray
        name="keyPillars.values"
        render={(arrayHelpers) => {
          const { push, remove, form } = arrayHelpers;
          const { values, errors } = form;
          const { keyPillars } = values;

          return (
            <>
              {keyPillars?.values?.map((keyPillar: any, index: number) => (
                <div>
                  <Divider />
                  <div
                    style={
                      {
                        // padding: "30px",
                      }
                    }
                  >
                    <FormFieldContainerWrapper>
                      <Grid
                        container
                        style={{
                          alignItems: "center",
                        }}
                        columnSpacing={8}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={12} sm={12} lg={7} xl={8}>
                          <Grid
                            container
                            spacing={4}
                            justifyContent={"space-between"}
                          >
                            <Grid item xs={6}>
                              <TextFieldComponent
                                name={`keyPillars.values.${index}.name`}
                                placeholder="Enter"
                                label="Key Pillar Name *"
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `keyPillars.values[${index}].name`
                                  ) &&
                                  getIn(
                                    errors,
                                    `keyPillars.values[${index}].name`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    errors,
                                    `keyPillars.values[${index}].name`
                                  ) &&
                                    getIn(
                                      touched,
                                      `keyPillars.values[${index}].name`
                                    )
                                )}
                                inputProps={{
                                  maxLength: 30,
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={keyPillars?.values[index].name}
                                disabled={isViewMode}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextFieldComponent
                                name={`keyPillars.values.${index}.points[0]`}
                                label="Point 1 "
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `keyPillars.values[${index}].points[0]`
                                  ) &&
                                  getIn(
                                    errors,
                                    `keyPillars.values[${index}].points[0]`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    errors,
                                    `keyPillars.values[${index}].points[0]`
                                  ) &&
                                    getIn(
                                      touched,
                                      `keyPillars.values[${index}].points[0]`
                                    )
                                )}
                                placeholder="Enter"
                                inputProps={{
                                  maxLength: 60,
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={keyPillars?.values[index]?.points[0]}
                                disabled={isViewMode}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          lg={5}
                          xl={4}
                          style={{ paddingLeft: "150px" }}
                          justifyContent="flex-end"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {keyPillars?.values?.length > 2 ? (
                              <IconButton
                                aria-label="delete"
                                data-testid="button-delete"
                                onClick={() => remove(index)}
                                disabled={isViewMode}
                              >
                                <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                              </IconButton>
                            ) : null}
                          </div>

                          <KeyPillarsImageUploader index={index} />
                        </Grid>
                      </Grid>
                    </FormFieldContainerWrapper>
                    <Stack>
                      <div>
                        {index === keyPillars?.values?.length - 1 &&
                        keyPillars?.values?.length < 5 ? (
                          <ButtonComponent
                            sx={{
                              textDecoration: "underline",
                              marginTop: "0.5rem",
                            }}
                            color="inherit"
                            variant="text"
                            onClick={() =>
                              push(
                                InitinalValueProjectContent.keyPillars.values[0]
                              )
                            }
                            // disabled={isViewMode}
                          >
                            +add more Values
                          </ButtonComponent>
                        ) : null}
                      </div>
                    </Stack>
                  </div>

                  {/* <Divider /> */}
                </div>
              ))}
            </>
          );
        }}
      />
    </>
  );
}

export default KeyPillars;
