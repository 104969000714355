export const enum DepartmentTypeEnum {
  SalesAndMarketing = "Sales and Marketing",
  ProductAndTech = "Product and Tech",
  HFM = "HFM",
  CRM = "CRM",
  Others = "Others",
}

export class DepartmentTypeEnumUtils {
  public static getEnumText(type: DepartmentTypeEnum): string {
    switch (type) {
      case DepartmentTypeEnum.SalesAndMarketing:
        return "Sales and Marketing";
      case DepartmentTypeEnum.ProductAndTech:
        return "Product and Tech";
      case DepartmentTypeEnum.HFM:
        return "HFM";
      case DepartmentTypeEnum.CRM:
        return "CRM";
      case DepartmentTypeEnum.Others:
        return "Others";
    }
  }

  public static getDepartmentTypeEnums(): {
    label: string;
    value: DepartmentTypeEnum;
  }[] {
    const options: { label: string; value: DepartmentTypeEnum }[] = [
      {
        label: this.getEnumText(DepartmentTypeEnum.SalesAndMarketing),
        value: DepartmentTypeEnum.SalesAndMarketing,
      },
      {
        label: this.getEnumText(DepartmentTypeEnum.ProductAndTech),
        value: DepartmentTypeEnum.ProductAndTech,
      },
      {
        label: this.getEnumText(DepartmentTypeEnum.HFM),
        value: DepartmentTypeEnum.HFM,
      },
      {
        label: this.getEnumText(DepartmentTypeEnum.CRM),
        value: DepartmentTypeEnum.CRM,
      },
      {
        label: this.getEnumText(DepartmentTypeEnum.Others),
        value: DepartmentTypeEnum.Others,
      },
    ];
    return options;
  }
}
