export const enum ProjectStatusTypeEnum {
  Live = "1001",
  Hidden = "1002",
}

export class ProjectStatusTypeEnumUtils {
  public static getEnumText(type: ProjectStatusTypeEnum): string {
    switch (type) {
      case ProjectStatusTypeEnum.Live:
        return "Live";
      case ProjectStatusTypeEnum.Hidden:
        return "Hidden";
    }
  }

  public static getProjectStatusTypeEnums(): {
    label: string;
    value: ProjectStatusTypeEnum;
  }[] {
    const options: { label: string; value: ProjectStatusTypeEnum }[] = [
      {
        label: this.getEnumText(ProjectStatusTypeEnum.Live),
        value: ProjectStatusTypeEnum.Live,
      },
      {
        label: this.getEnumText(ProjectStatusTypeEnum.Hidden),
        value: ProjectStatusTypeEnum.Hidden,
      },
    ];
    return options;
  }
}
