import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Stack,
} from "@mui/material";
import MediaAssets from "assets";
import NoDataRecord from "components/no-data-record/NoDataRecord";
import { TextFieldComponent } from "components/ui-elements";
import {
  ActionTypeEnumUtils,
  ActionTypeEnum,
} from "enumerations/ActionTypeEnum";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GetProjectCategoriesAction } from "redux-container/project-management/ProjectCategoryRedux";
import { RouteConstants } from "routes/RouteConstants";
import { useSelector } from "react-redux";
import { format, compareAsc } from "date-fns";
import {
  StatusTypeEnum,
  StatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { exportAsExcel } from "utils/ExportFileUtils";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import Loader from "components/loader/Loader";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { useFormikContext } from "formik";
import { IInitinalValueProjectContent } from "../../InitinalValuesProjectContent";
import ProjectContentCreationAction from "redux-container/project-management/project-content/ProjectContentRedux";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { AllProjectFaqsAction } from "redux-container/project-management/ProjectAllFaqsRedux";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";

const tableHeadings = [
  { heading: "Category" },
  { heading: "Numbers of Faqs" },
  { heading: "Last Updated on" },
  // { heading: "Priority" },
  { heading: "Status" },
  { heading: "Actions" },
];
const actionDispatch = (dispatch: any) => {
  return {
    getProjectCategroies: (params: any) =>
      dispatch(GetProjectCategoriesAction.getProjectCategoriesRequest(params)),
    categoriesStatusUpdate: (data: any) =>
      dispatch(
        GetProjectCategoriesAction.bulkStatusUpdateProjectCategoryRequest(data)
      ),
    setProjectCategoriesState: (key: any, data: any) =>
      dispatch(GetProjectCategoriesAction.setProjectCategoriesState(key, data)),
    categoriesDelete: (data: any) =>
      dispatch(
        GetProjectCategoriesAction.bulkDeleteProjectCategoryRequest(data)
      ),
    categoriesPriorityUpdate: (data: any) =>
      dispatch(GetProjectCategoriesAction.changeCategoryPriorityRequest(data)),
    setUpdateProjectContentCreation: (key: any, value: any) =>
      dispatch(
        ProjectContentCreationAction.setUpdateProjectContentCreation(key, value)
      ),
    setALLProjectFaqState: (key: any, value: any) =>
      dispatch(AllProjectFaqsAction.setALLProjectFaqState(key, value)),
    setProjectFaqState: (key: any, value: any) =>
      dispatch(ProjectFaqActions.setProjectFaqState(key, value)),
  };
};

export default function CategoriesFaqsTable() {
  const {
    getProjectCategroies,
    categoriesStatusUpdate,
    setProjectCategoriesState,
    categoriesDelete,
    setUpdateProjectContentCreation,
    setALLProjectFaqState,
    setProjectFaqState,
  } = actionDispatch(useDispatch());
  const navigate: any = useNavigate();
  const statuses = StatusTypeEnumUtils.getStatusTypeEnums();
  const actions = ActionTypeEnumUtils.getActionTypeEnums();
  const location: any = useLocation();

  const createFAQ = () => {
    navigate(RouteConstants.projectFaqCreation, {
      state: {
        ...location?.state,
        ProjectListCreationId: location?.state?.ProjectListCreationId,
        isFaqCreation: true,
        isFaqTable: false,
        categoryId: "",
        openForm: false,
        category: null,
        isCreateNewCategory: true,
      },
    });
  };
  const {
    projectCategories,
    totalCount,
    isProjectCategoriesSuccess,
    isLoading,
    projectCategoryTableResponse,
  } = useSelector((state: any) => state?.projectCategories);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [status, setStatus] = useState("");
  const [sortingPriority, setSortingPriority] = useState(1);
  const [sortingKey, setSortingKey] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const activeAndInactiveStatus = statuses.splice(0, 2);
  const [priorityCount, setPriorityCount] = useState(0);

  const projectAllFaqs = useSelector(
    (state: any) => state?.projectAllFaqsList?.projectAllFaqsListData
  );

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    const params = {
      searchKey: searchKey,
      index: page,
      size: rowsPerPage,
      status: status,
      sortingPriority: sortingPriority,
      sortingKey: sortingKey,
      categoryType: "2002",
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    getProjectCategroies(params);
  }, [searchKey, rowsPerPage, status, page, location]);

  useEffect(() => {
    if (isProjectCategoriesSuccess) {
      const params = {
        searchKey: searchKey,
        index: page,
        size: rowsPerPage,
        status: status,
        sortingPriority: sortingPriority,
        sortingKey: sortingKey,
        categoryType: "2002",
        shouldValidateAdmin:
          loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
      };
      getProjectCategroies(params);
      setProjectCategoriesState("isProjectCategoriesSuccess", false);
    }
  }, [searchKey, rowsPerPage, status, page, isProjectCategoriesSuccess]);

  const isSelected: any = (id: any) => selectedCategory.indexOf(id) !== -1;

  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const { projectFaqs, mappedFaqs } = useSelector(
    (state: any) => state?.projectFaqs
  );

  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedCategory.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCategory, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCategory.slice(1));
    } else if (selectedIndex === selectedCategory?.length - 1) {
      newSelected = newSelected.concat(selectedCategory.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCategory.slice(0, selectedIndex),
        selectedCategory.slice(selectedIndex + 1)
      );
    }

    setSelectedCategory(newSelected);
  };

  const handleCloseConfirmDialog = () => {
    setSelectedCategory([]);
    setSelectedAction("");
    setIsConfirmDeleteDialogOpen(false);
  };

  const deleteSelectedCategory = async () => {
    const reqBody = {
      ids: selectedCategory,
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };

    let deletingCategory = await projectCategories?.filter((item: any) =>
      selectedCategory?.includes(item?.id)
    );

    const deletingFaqs: any = [];

    await deletingCategory?.map((category: any) =>
      category.faqs?.map((faqs: any) => {
        deletingFaqs.push(faqs);
      })
    );

    mappedFaqs?.map(async (faqId: any) => {
      const removeFalseFaqID = await deletingFaqs.filter((faq: any) => {
        return faq?.id !== faqId;
      });

      await setALLProjectFaqState("isFaqAddOrRemoved", true);
      await setProjectFaqState("mappedFaqs", removeFalseFaqID);
      await setALLProjectFaqState(
        "filterAllfaqBasedGivenArray",
        removeFalseFaqID
      );
      let getMappedIds = removeFalseFaqID?.map((item: any, index: any) => {
        return { faqId: item?.id, priority: index };
      });
      setFieldValue("faqsToDisplayInDetails", getMappedIds);
    });

    await categoriesDelete(reqBody);
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = projectCategories.map((data: any) => data.id);
      setSelectedCategory(newSelecteds);
      return;
    }
    setSelectedCategory([]);
  };
  // handle tabulationchange

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
  };
  //Handling action on table

  const handleActionChange = (event: SelectChangeEvent) => {
    setSelectedAction(event.target.value as string);
    const actionSelected = event.target.value;
    switch (Number(actionSelected)) {
      case ActionTypeEnum.Export:
        handleExportAsExcel();
        break;
      case ActionTypeEnum.Delete:
        bulkCategoryDelete();
        break;
      case ActionTypeEnum.MarkAsActive:
        bulkCategoryStatusActive();
        break;
      case ActionTypeEnum.MarkAsInactive:
        bulkCategoryStatusInactive();
        break;
    }
  };
  //action handle changes
  const handleExportAsExcel = () => {
    exportAsExcel(projectCategories, "ProjectCategories", "Sheet1");
  };
  const handleCloseFlashMessageDialog = () => {
    setProjectCategoriesState("projectCategoryTableResponse", null);
    clearSelected();
  };

  const bulkCategoryDelete = () => {
    if (selectedCategory.length !== 0) {
      setIsConfirmDeleteDialogOpen(true);
    } else {
      alert("Please select at least one category");
    }
  };
  const bulkCategoryStatusActive = () => {
    if (selectedCategory.length !== 0) {
      const reqBody = {
        categoryIds: selectedCategory,
        status: StatusTypeEnum.Active,
        shouldValidateAdmin:
          loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
      };
      categoriesStatusUpdate(reqBody);
    } else {
      alert("Please select at least one category");
    }
  };
  const bulkCategoryStatusInactive = () => {
    if (selectedCategory.length !== 0) {
      const reqBody = {
        categoryIds: selectedCategory,
        status: StatusTypeEnum.Inactive,
        shouldValidateAdmin:
          loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
      };
      categoriesStatusUpdate(reqBody);
    } else {
      alert("Please select at least one category");
    }
  };

  const clearSelected = () => {
    setSelectedAction("");
    setSelectedCategory([]);
  };

  //filter out categories based on status and category
  const handleClearFilter = () => {
    setStatus("");
    setSearchKey("");
  };
  //Click on edit

  const handleChangeEdit = (category: any, categoryId: any) => {
    navigate(RouteConstants.projectFaqCreation, {
      state: {
        ...location.state,
        categoryId: categoryId,
        isFaqTable: true,
        isFaqCreation: false,
        category: category,
        isViewMode: false,
        isOnlyView: false,
      },
    });
  };
  const handleChangeView = (category: any, categoryId: any) => {
    navigate(RouteConstants.projectFaqCreation, {
      state: {
        ...location.state,
        categoryId: categoryId,
        isFaqTable: true,
        isOnlyView: true,
        isFaqCreation: false,
        category: category,
      },
    });
  };

  //filter in on data
  const handleSortByDate = () => {
    if (sortingPriority) {
      setSortingPriority(0);
      setSortingKey(0);
    } else {
      setSortingPriority(1);
      setSortingKey(1);
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSearchKey(event.target.value as string);
  };
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    touched,
    errors,
  } = useFormikContext<IInitinalValueProjectContent>();

  const projectFaqCategories = useSelector(
    (state: any) => state?.projectFaqsCategories?.projectFaqCategories
  );

  const avoidFirstNameInCategory =
    values?.otherSectionHeadings?.faqSection?.categoryNameList?.filter(
      (item: any) => item !== "Select an option or create one"
    );
  const projectCategoryNameList = projectFaqCategories?.filter((item: any) =>
    avoidFirstNameInCategory?.includes(item?.name)
  );
  const removeDuplicatesProjectCategoryNameList =
    projectCategoryNameList.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((v2: any) => v2.name === v.name) === i
    );

  const handlePriorityChange = (
    indexValue: any,
    list: any,
    priorityUpdatevalue: any,
    updatedItem: any
  ) => {
    if (indexValue) {
      list.splice(indexValue, 1);
      list.splice(indexValue + priorityUpdatevalue, 0, updatedItem);
      return list;
    }
  };

  useEffect(() => {
    let getProjectCategoryids = projectCategories?.map(
      (category: any) => category?.id
    );
    let allProjectFaqs = projectAllFaqs?.filter((item: any) =>
      getProjectCategoryids.includes(item.categoryId)
    );

    setFieldValue(
      "frequentlyAskedQuestions",
      allProjectFaqs.sort(
        (currentFaq: any, previousFaq: any) => currentFaq?.id - previousFaq?.id
      )
    );
    setUpdateProjectContentCreation(
      "frequentlyAskedQuestions",
      allProjectFaqs.sort(
        (currentFaq: any, previousFaq: any) => currentFaq?.id - previousFaq?.id
      )
    );
  }, [projectCategories?.length, projectAllFaqs?.length]);

  const renderTable = () => {
    return (
      <Box p={"1rem"}>
        <div className="heading-action-holder">
          <h4 className="heading">
            Project Category {`(${totalCount})`}
            {selectedCategory.length !== 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedCategory.length} ${
                    selectedCategory?.length === 1 ? "Category" : "Categories"
                  } Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>
          <div>
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
                disabled={isViewMode}
              >
                {actions.map((action: any) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>
            <Button
              variant="contained"
              onClick={createFAQ}
              // autoFocus
              disableElevation
              className="btn btn-dark"
              disabled={isViewMode}
            >
              Add New Category and FAQs
            </Button>
          </div>
        </div>
        <Grid container spacing={2} md={8} lg={8} xl={8}>
          <Grid
            item
            md={1}
            lg={1}
            xl={1}
            className="margin-left-10 filter-heading-holder"
            style={{ position: "relative" }}
          >
            <span
              className="filter-heading"
              style={{ position: "absolute", top: 32 }}
            >
              Filters:
            </span>
          </Grid>

          {/* We have Removed Category Filter as it not make any sense to filter one record */}
          {/* <Grid item md={2} lg={2} xl={2}>
            <FormControl className="filter-dropdown">
              <TextFieldComponent
                select
                label="Category"
                onChange={handleCategoryChange}
                value={searchKey}
                disabled={isViewMode}
              >
                {removeDuplicatesProjectCategoryNameList?.map(
                  (category: any, index: number) => {
                    return (
                      <MenuItem value={category.name} key={index}>
                        {category.name}
                      </MenuItem>
                    );
                  }
                )}
              </TextFieldComponent>
            </FormControl>
          </Grid> */}

          <Grid item md={2} lg={2} xl={2}>
            <FormControl className="filter-dropdown">
              <TextFieldComponent
                select
                value={status}
                label="Status"
                onChange={handleStatusChange}
                disabled={isViewMode}
              >
                {activeAndInactiveStatus.map((status: any) => {
                  return (
                    <MenuItem value={status.value} key={status.value}>
                      {status.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>
          </Grid>
          {searchKey.length !== 0 || status.length !== 0 ? (
            <Grid item>
              <div
                className="clear-selected-text"
                style={{ position: "absolute", top: 193 }}
                onClick={handleClearFilter}
              >
                Clear
              </div>
            </Grid>
          ) : null}
        </Grid>
        {isLoading ? (
          <Loader />
        ) : (
          <TableContainer
            component={Paper}
            className="table-container"
            style={{ height: window.innerHeight - 475 }}
          >
            <Table style={{ position: "relative" }}>
              <TableHead>
                <TableRow className="table-header-row">
                  <TableCell style={{ minWidth: 50 }} padding="checkbox">
                    <Checkbox
                      className="active-checkbox"
                      indeterminate={
                        selectedCategory?.length > 0 &&
                        selectedCategory?.length < projectCategories?.length
                      }
                      checked={
                        projectCategories?.length > 0 &&
                        selectedCategory?.length === projectCategories?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all users",
                      }}
                    />
                  </TableCell>
                  {tableHeadings.map((item, index) => (
                    <TableCell key={index} className="table-header-text">
                      <Stack justifyContent="flex-start">
                        <div>{item.heading}</div>
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {projectCategories && projectCategories.length > 0 ? (
                <TableBody>
                  {projectCategories.map((category: any, index: any) => {
                    const isItemSelected = isSelected(category.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        key={category.id}
                        className="table-data-row"
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell style={{ minWidth: 50 }} padding="checkbox">
                          <Checkbox
                            className="active-checkbox"
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, category.id)}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            disabled={isViewMode}
                          />
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 100 }}
                          className="table-data-text"
                        >
                          {category?.name}
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 100 }}
                          className="table-data-text"
                        >
                          {category?.numberOfFaqs}
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 100 }}
                          className="table-data-text"
                        >
                          {format(new Date(category?.updatedAt), "dd/MM/yyyy")}
                        </TableCell>

                        <TableCell
                          style={{ minWidth: 100 }}
                          className="table-data-text"
                        >
                          {category.status
                            ? StatusTypeEnumUtils.getEnumText(category.status)
                            : "-"}
                        </TableCell>
                        <TableCell style={{ minWidth: 120 }}>
                          <Tooltip title="Edit" placement="top">
                            <IconButton
                              disabled={isViewMode}
                              onClick={() =>
                                handleChangeEdit(category, category?.id)
                              }
                            >
                              <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="View" placement="top">
                            <IconButton
                              disabled={isViewMode}
                              onClick={() =>
                                handleChangeView(category, category?.id)
                              }
                            >
                              <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <div className="no-record">No Records Found</div>
              )}
            </Table>
          </TableContainer>
        )}
        {!searchKey &&
        projectCategories &&
        projectCategories.length > 0 &&
        !isViewMode ? (
          <TablePagination
            rowsPerPageOptions={[10, 20, 40, 50]}
            component="div"
            count={totalCount ? totalCount : 0}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}

        {projectCategoryTableResponse ? (
          <FlashMessageDialog
            shouldOpen={projectCategoryTableResponse ? true : false}
            content={
              projectCategoryTableResponse?.code === 200
                ? projectCategoryTableResponse?.message
                : projectCategoryTableResponse?.message
            }
            isSuccess={
              projectCategoryTableResponse?.code === 200 ? true : false
            }
            cancelHandler={() => handleCloseFlashMessageDialog()}
          />
        ) : null}

        <ConfirmationDialog
          shouldOpen={isConfirmDeleteDialogOpen}
          title="Delete Category(s)?"
          content={`This action will delete ${selectedCategory?.length} category(s) and related FAQs permanently from database and cannot be reverted. Are you sure you want to continue?`}
          okText="Delete"
          cancelHandler={handleCloseConfirmDialog}
          okHandler={deleteSelectedCategory}
        />
      </Box>
    );
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_faq}
        altText=""
        message="You haven't created any FAQ's yet. Create your first FAQ."
        btnTitle="Create New Category and FAQ"
        btnAction={createFAQ}
      />
    );
  };
  return projectCategories && projectCategories.length == 0 && !status
    ? renderNoData()
    : renderTable();
}
