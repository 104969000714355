import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import { TextFieldComponent } from "components/ui-elements";
import { IProjectFormInitialValues } from "../ProjectFormInitialValues";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import { useSelector } from "react-redux";

const GeneralInformation = () => {
  const { setFieldValue, values } =
    useFormikContext<IProjectFormInitialValues>();
  const location: any = useLocation();

  useEffect(() => {
    if (location?.state?.form?.crmProjectId) {
      setFieldValue("crmProjectId", location?.state?.form?.crmProjectId);
    }

    if (location?.state?.form?.crmLaunchPhaseId) {
      setFieldValue(
        "crmLaunchPhaseId",
        location?.state?.form?.crmLaunchPhaseId
      );
    }
  }, []);

  const { paymentForm } = useSelector((state: any) => ({
    paymentForm: state?.paymentForm?.paymentForm,
  }));

  return (
    <div>
      <Box p={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextFieldComponent
              label={"Form ID"}
              value={
                location?.state?.isNew
                  ? "-"
                  : `#ID-${location?.state?.form?.id}`
              }
              sx={{ pointerEvents: "none" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextFieldComponent
              label={"Project ID"}
              value={`#ID-${
                location?.state?.isNew
                  ? location?.state?.form?.id
                  : paymentForm?.crmLaunchPhase?.id
              }`}
              sx={{ pointerEvents: "none" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextFieldComponent
              label={"CRM Project Name"}
              value={
                location?.state?.isNew
                  ? location?.state?.form?.generalInfoEscalationGraph
                      ?.crmProjectName
                  : paymentForm?.crmProject?.name
              }
              sx={{ pointerEvents: "none" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextFieldComponent
              label={"CRM Launch Name"}
              value={
                location?.state?.isNew
                  ? location?.state?.form?.generalInfoEscalationGraph
                      ?.crmLaunchPhaseName
                  : paymentForm?.crmLaunchPhase?.launchName
              }
              sx={{ pointerEvents: "none" }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextFieldComponent
              label={"Created By"}
              value={
                location?.state?.isNew
                  ? "-"
                  : paymentForm?.paymentFormCreatedBy?.firstName
              }
              sx={{ pointerEvents: "none" }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextFieldComponent
              label={"Booking Amount"}
              value={values?.bookingAmount || "-"}
              sx={{ pointerEvents: "none" }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextFieldComponent
              label={"Status"}
              value={
                location?.state?.isNew
                  ? "-"
                  : paymentForm?.status === "1001"
                  ? "Live"
                  : "Hidden"
              }
              sx={{ pointerEvents: "none" }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextFieldComponent
              label={"Created On"}
              value={
                location?.state?.isNew
                  ? "-"
                  : paymentForm?.createdAt
                  ? formatDateTime(
                      paymentForm?.createdAt,
                      Constants.dateFormatDDmmYYYY
                    )
                  : "-"
              }
              sx={{ pointerEvents: "none" }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextFieldComponent
              label={"Area Starting From"}
              value={
                location?.state?.isNew
                  ? location?.state?.form?.areaStartingFrom
                  : paymentForm?.crmLaunchPhase?.areaStartingFrom
                  ? paymentForm?.crmLaunchPhase?.areaStartingFrom
                  : "0"
              }
              sx={{ pointerEvents: "none" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Sqft</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextFieldComponent
              label={"Price Starting From"}
              value={
                location?.state?.isNew
                  ? location?.state?.form?.priceStartingFrom
                  : paymentForm?.crmLaunchPhase?.priceStartingFrom
                  ? paymentForm?.crmLaunchPhase?.priceStartingFrom
                  : "0"
              }
              sx={{ pointerEvents: "none" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography variant="h6">₹</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default GeneralInformation;
