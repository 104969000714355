import { Fragment, useEffect, useState } from "react";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { RouteConstants } from "routes/RouteConstants";
import PageHeading from "components/page-heading/PageHeading";
import {
  AccordionProps,
  AccordionSummaryProps,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { Page5ProfileSection } from "./Page5ProfileSection";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonComponent,
  LoadingButtonComponent,
} from "components/ui-elements";
import { Form, Formik } from "formik";
import {
  page5ProfileInitialValue,
  page5ProfileLabels,
} from "./sections/page-5-profile-IniticalValue";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { validationSchemaforProfile } from "./sections/page-5-profile-validation";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import Loader from "components/loader/Loader";
import Page5ProfileDelayed from "./page-5-profile-delayed/Page5profileDelayed";
import PageManagementCreationAction from "redux-container/page-management/PageManagementRedux";
import { hoablPage1Action } from "redux-container/page-management/hoabl-page-1/HoablPage1Redux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { stripHtml } from "utils/ValidationUtils";
import { AuditStatus } from "enumerations/AuditStatusTypes";

const breadcrumbRoutes: any = [
  {
    name: "Page Management",
    route: RouteConstants.pageManagementDashboard,
  },
  {
    name: "Page 5 - Profile",
    route: RouteConstants.page5Profile,
  },
];

const actionDispatch = (dispatch: any) => {
  return {
    //   post
    addPageManagementData: (params: any) =>
      dispatch(
        PageManagementCreationAction.addPageManagementDataRequest(params)
      ),
    getPageManagementData: (params: any) =>
      dispatch(
        PageManagementCreationAction.getPageManagementDataRequest(params)
      ),
    updatePageManagentData: (params: any) =>
      dispatch(
        PageManagementCreationAction.updatePageManagementDataRequest(params)
      ),
    setPageManagementCreationProfile: (key: any, value: any) =>
      dispatch(
        PageManagementCreationAction.setUpdatePageManagement(key, value)
      ),
    getHoablPage1: () => dispatch(hoablPage1Action.hoablPage1Request()),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const Page5Profile = () => {
  const [flashMessageShow, setFlashMessageShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const {
    message,
    isSuccess,
    isLoading,
    error,
    isReduxViewMode,
    pageManagementData,
    page5Data,
    modulesAudited,
    productCategoryLength,
    corporatePhilosophyLength,
  } = useSelector((state: any) => state?.pageManagement);

  const {
    getPageManagementData,
    updatePageManagentData,
    addPageManagementData,
    setPageManagementCreationProfile,
    getHoablPage1,
    postAuditLogRequest,
    rolesAndModulesRequest,
  } = actionDispatch(useDispatch());

  const [getPage5ProfileState, setGetPage5ProfileState] = useState({
    isViewMode: location?.state?.isViewMode,
  });
  const [expanded, setExpanded] = React.useState<string | false>("");

  const modules = useSelector((state: any) => state?.rolesAndModules?.module);

  // const [modulesAudited, setPageManagementCreationProfile]"modulesAudited",  = useState<any>(null);
  const [finalValues, setFinalValues] = useState<any>(null);
  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  useEffect(() => {
    rolesAndModulesRequest();
    setPageManagementCreationProfile("modulesAudited", []);
    return () => {};
  }, []);

  const handleChangeExpanded =
    (panel: string, setState: any, id: any) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      setState(id);
    };
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    getHoablPage1();
    const data = {
      pageType: "5005",
    };
    getPageManagementData(data);
  }, []);

  //Get page data
  useEffect(() => {
    const data = {
      pageType: "5005",
    };
    getPageManagementData(data);
    setPageManagementCreationProfile(
      "isReduxViewMode",
      getPage5ProfileState.isViewMode
    );
  }, []);

  const handleCloseFlashMessage = () => {
    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Pages Management";
    });
    if (isSuccess) {
      if (modulesAudited?.length) {
        const postAuditRequestbody = {
          ipAddress: ipAddress, //ipAddress
          moduleId: moduleObject?.id,
          modulesAudited: modulesAudited,
          status: AuditStatus.EDITED,
          auditedObject: finalValues,
          route: location.pathname,
        };
        postAuditLogRequest(postAuditRequestbody);
      }
    }

    setFlashMessageShow(false);
    const data = {
      pageType: "5005",
    };
    getPageManagementData(data);
    if (error === null) {
      navigate(RouteConstants.pageManagementDashboard);
    }
  };
  const handleGlobalSubmit = (values: any) => {
    setConfirmDialogOpen(true);
  };

  const handleEditClick = () => {
    setGetPage5ProfileState((prev) => ({
      ...prev,
      isViewMode: false,
    }));
    setPageManagementCreationProfile("isReduxViewMode", false);
  };
  const handleDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  const handleDeleteOkClick = async (values: any) => {
    auditedFieldsSetter(values);

    if (pageManagementData?.page !== null) {
      updatePageManagentData(values);
      setFlashMessageShow(true);
    } else {
      addPageManagementData(values);
      setFlashMessageShow(true);
    }
    setConfirmDialogOpen(false);
  };

  const auditedFieldsSetter = (formvalues: any) => {
    let values: any = formvalues;
    let localPreviousValues: any = page5Data;
    let localCorporatePhilosophyDetailedInformation: any =
      localPreviousValues?.aboutUs?.corporatePhilosophy?.detailedInformation;
    let localProductCategoryDetailedInformation: any =
      localPreviousValues?.aboutUs?.productCategory?.detailedInformation;

    if (
      corporatePhilosophyLength <
      values?.aboutUs?.corporatePhilosophy?.detailedInformation?.length
    ) {
      for (
        let index = corporatePhilosophyLength;
        index <
        values?.aboutUs?.corporatePhilosophy?.detailedInformation?.length;
        index++
      ) {
        localCorporatePhilosophyDetailedInformation[index] =
          values?.aboutUs?.corporatePhilosophy?.detailedInformation[index];
      }
      localPreviousValues = {
        ...localPreviousValues,
        aboutUs: {
          ...localPreviousValues.aboutUs,
          corporatePhilosophy: {
            ...localPreviousValues?.aboutUs?.corporatePhilosophy,
            detailedInformation: localCorporatePhilosophyDetailedInformation,
          },
        },
      };
    }
    if (
      productCategoryLength <
      values?.aboutUs?.productCategory?.detailedInformation?.length
    ) {
      for (
        let index = productCategoryLength;
        index < values?.aboutUs?.productCategory?.detailedInformation?.length;
        index++
      ) {
        localProductCategoryDetailedInformation[index] =
          values?.aboutUs?.productCategory?.detailedInformation[index];
      }
      localPreviousValues = {
        ...localPreviousValues,
        aboutUs: {
          ...localPreviousValues.aboutUs,
          productCategory: {
            ...localPreviousValues?.aboutUs?.productCategory,
            detailedInformation: localProductCategoryDetailedInformation,
          },
        },
      };
    }

    let auditObjectsArray: any[] = [];
    Object.keys(values)?.map((valuesObjectKeys: string) => {
      if (
        values[valuesObjectKeys] &&
        typeof values[valuesObjectKeys] == "object"
      ) {
        if (Array?.isArray(values[valuesObjectKeys])) {
        } else {
          //get inner object keys by mapping.
          Object?.keys(values[valuesObjectKeys]).map(
            (firstLevelObjectKey: any) => {
              if (
                values[valuesObjectKeys][firstLevelObjectKey] &&
                typeof values[valuesObjectKeys][firstLevelObjectKey] == "object"
              ) {
                if (
                  Array.isArray(values[valuesObjectKeys][firstLevelObjectKey])
                ) {
                  values[valuesObjectKeys][firstLevelObjectKey]?.map(
                    (secondLevelArrayObject: any, secondLevelIndex: number) => {
                      //detailedInformation array mapping.
                      if (
                        values[valuesObjectKeys][firstLevelObjectKey][
                          secondLevelIndex
                        ] &&
                        typeof values[valuesObjectKeys][firstLevelObjectKey][
                          secondLevelIndex
                        ] == "object" //array object no need to check but any way we have checked it.
                      ) {
                        if (
                          Array.isArray(
                            values[valuesObjectKeys][firstLevelObjectKey][
                              secondLevelIndex
                            ]
                          )
                        ) {
                        } else {
                          //array object
                          Object.keys(
                            values[valuesObjectKeys][firstLevelObjectKey][
                              secondLevelIndex
                            ]
                          ).map((thirdLevelObjectKey: any) => {
                            if (
                              typeof values[valuesObjectKeys][
                                firstLevelObjectKey
                              ][secondLevelIndex][thirdLevelObjectKey] ==
                              "object"
                            ) {
                              //get array object's keys by mapping.(media,not description)

                              if (
                                Array.isArray(
                                  values[valuesObjectKeys][firstLevelObjectKey][
                                    secondLevelIndex
                                  ][thirdLevelObjectKey]
                                )
                              ) {
                              } else {
                                //get media object keys by mapping.

                                Object.keys(
                                  values[valuesObjectKeys][firstLevelObjectKey][
                                    secondLevelIndex
                                  ][thirdLevelObjectKey]
                                ).map((fourthLevelObjectKey: any) => {
                                  if (
                                    typeof values[valuesObjectKeys][
                                      firstLevelObjectKey
                                    ][secondLevelIndex][thirdLevelObjectKey][
                                      fourthLevelObjectKey
                                    ] &&
                                    typeof values[valuesObjectKeys][
                                      firstLevelObjectKey
                                    ][secondLevelIndex][thirdLevelObjectKey][
                                      fourthLevelObjectKey
                                    ] == "object"
                                  ) {                                   
                                    //login for url
                                    Object.keys(
                                      values[valuesObjectKeys][
                                        firstLevelObjectKey
                                      ][secondLevelIndex][thirdLevelObjectKey][
                                        fourthLevelObjectKey
                                      ]
                                    ).map((fifthLevelObjectKeys: any) => {
                                      if (
                                        fifthLevelObjectKeys === "url" &&
                                        page5ProfileLabels[valuesObjectKeys][
                                          firstLevelObjectKey
                                        ][secondLevelIndex][
                                          thirdLevelObjectKey
                                        ][fourthLevelObjectKey][
                                          fifthLevelObjectKeys
                                        ] !== undefined &&
                                        page5Data[valuesObjectKeys][
                                          firstLevelObjectKey
                                        ][secondLevelIndex][
                                          thirdLevelObjectKey
                                        ][fourthLevelObjectKey][
                                          fifthLevelObjectKeys
                                        ] !==
                                          values[valuesObjectKeys][
                                            firstLevelObjectKey
                                          ][secondLevelIndex][
                                            thirdLevelObjectKey
                                          ][fourthLevelObjectKey][
                                            fifthLevelObjectKeys
                                          ]
                                      ) {
                                        let auditObject = {
                                          itemAffected: `${page5ProfileLabels[valuesObjectKeys][firstLevelObjectKey][secondLevelIndex][thirdLevelObjectKey][fourthLevelObjectKey][fifthLevelObjectKeys]}`,
                                          newValue: stripHtml(
                                            values[valuesObjectKeys][
                                              firstLevelObjectKey
                                            ][secondLevelIndex][
                                              thirdLevelObjectKey
                                            ][fourthLevelObjectKey][
                                              fifthLevelObjectKeys
                                            ]
                                          ),
                                          oldValue: stripHtml(
                                            page5Data[valuesObjectKeys][
                                              firstLevelObjectKey
                                            ][secondLevelIndex][
                                              thirdLevelObjectKey
                                            ][fourthLevelObjectKey][
                                              fifthLevelObjectKeys
                                            ]
                                          ),
                                        };
                                        auditObjectsArray.push(auditObject);
                                      }
                                    });
                                  } else {
                                    //logic here.for array object's object's object's string values.
                                    // no string is there only object value is there. (not required).
                                  }
                                });
                              }
                            } else {                             
                              //logic here.for array object's object's string values (description).
                              if (
                                page5ProfileLabels[valuesObjectKeys][
                                  firstLevelObjectKey
                                ][secondLevelIndex][thirdLevelObjectKey] !==
                                  undefined &&
                                values[valuesObjectKeys][firstLevelObjectKey][
                                  secondLevelIndex
                                ][thirdLevelObjectKey] !==
                                  page5Data[valuesObjectKeys][
                                    firstLevelObjectKey
                                  ][secondLevelIndex][thirdLevelObjectKey]
                              ) {                               
                                let auditObject = {
                                  itemAffected: `${page5ProfileLabels[valuesObjectKeys][firstLevelObjectKey][secondLevelIndex][thirdLevelObjectKey]}`,
                                  newValue: stripHtml(
                                    values[valuesObjectKeys][
                                      firstLevelObjectKey
                                    ][secondLevelIndex][thirdLevelObjectKey]
                                  ),
                                  oldValue: stripHtml(
                                    page5Data[valuesObjectKeys][
                                      firstLevelObjectKey
                                    ][secondLevelIndex][thirdLevelObjectKey]
                                  ),
                                };
                                auditObjectsArray.push(auditObject);
                              }
                            }
                          });
                        }
                      } else {
                        //array always has object only no string values.
                      }
                    }
                  );
                } else {                 
                  //corporatePhilosophy object.
                  Object.keys(
                    values[valuesObjectKeys][firstLevelObjectKey]
                  ).map((secondLevelObjectKey: string) => {
                    if (
                      values[valuesObjectKeys][firstLevelObjectKey][
                        secondLevelObjectKey
                      ] &&
                      typeof values[valuesObjectKeys][firstLevelObjectKey][
                        secondLevelObjectKey
                      ] == "object"
                    ) {
                      if (
                        Array.isArray(
                          values[valuesObjectKeys][firstLevelObjectKey][
                            secondLevelObjectKey
                          ]
                        )
                      ) {
                        //detailedInformation array logic array mapping.                       
                        values[valuesObjectKeys][firstLevelObjectKey][
                          secondLevelObjectKey
                        ]?.map(
                          (
                            thirdLevelArrayObject: any,
                            thirdLevelIndex: number
                          ) => {                           
                            //here array always have objects so no need to check.
                            Object.keys(
                              values[valuesObjectKeys][firstLevelObjectKey][ //detailedInformation array object mapping.
                                secondLevelObjectKey // get keys of detailedInformation array objects.
                              ][thirdLevelIndex]
                            )?.map((fourthLevelObjectKeys: any) => {                             
                              if (
                                values[valuesObjectKeys][firstLevelObjectKey][
                                  secondLevelObjectKey
                                ][thirdLevelIndex][fourthLevelObjectKeys] &&
                                typeof values[valuesObjectKeys][
                                  firstLevelObjectKey
                                ][secondLevelObjectKey][thirdLevelIndex][
                                  fourthLevelObjectKeys
                                ] == "object"
                              ) {                                
                                Object.keys(
                                  values[valuesObjectKeys][firstLevelObjectKey][
                                    secondLevelObjectKey
                                  ][thirdLevelIndex][ //detailedInformation array object media mapping.
                                    fourthLevelObjectKeys //get media object keys.
                                  ]
                                ).map((fifthLevelObjectKeys: any) => {
                                  //no need to check whether it is an object or not any way media is an object only in all the array object.just go inside for nesting.
                                  //get value object  keys by mapping.                                 
                                  Object.keys(
                                    values[valuesObjectKeys][
                                      firstLevelObjectKey
                                    ][secondLevelObjectKey][thirdLevelIndex][
                                      fourthLevelObjectKeys
                                    ][fifthLevelObjectKeys]
                                  ).map((sixthLevelObjectkeys: any) => {
                                    //write logic for url of value object.                                   
                                    if (
                                      sixthLevelObjectkeys === "url" &&
                                      page5ProfileLabels[valuesObjectKeys][
                                        firstLevelObjectKey
                                      ][secondLevelObjectKey][0][
                                        fourthLevelObjectKeys
                                      ][fifthLevelObjectKeys][
                                        sixthLevelObjectkeys
                                      ] !== undefined &&
                                      values[valuesObjectKeys][
                                        firstLevelObjectKey
                                      ][secondLevelObjectKey][thirdLevelIndex][
                                        fourthLevelObjectKeys
                                      ][fifthLevelObjectKeys][
                                        sixthLevelObjectkeys
                                      ] !==
                                        page5Data[valuesObjectKeys][
                                          firstLevelObjectKey
                                        ][secondLevelObjectKey][
                                          thirdLevelIndex
                                        ][fourthLevelObjectKeys][
                                          fifthLevelObjectKeys
                                        ][sixthLevelObjectkeys]
                                    ) {
                                      let auditObject = {
                                        itemAffected: `${
                                          page5ProfileLabels[valuesObjectKeys][
                                            firstLevelObjectKey
                                          ][secondLevelObjectKey][0][
                                            fourthLevelObjectKeys
                                          ][fifthLevelObjectKeys][
                                            sixthLevelObjectkeys
                                          ]
                                        } ${thirdLevelIndex + 1}`,
                                        newValue: stripHtml(
                                          values[valuesObjectKeys][
                                            firstLevelObjectKey
                                          ][secondLevelObjectKey][
                                            thirdLevelIndex
                                          ][fourthLevelObjectKeys][
                                            fifthLevelObjectKeys
                                          ][sixthLevelObjectkeys]
                                        ),
                                        oldValue: stripHtml(
                                          page5Data[valuesObjectKeys][
                                            firstLevelObjectKey
                                          ][secondLevelObjectKey][
                                            thirdLevelIndex
                                          ][fourthLevelObjectKeys][
                                            fifthLevelObjectKeys
                                          ][sixthLevelObjectkeys]
                                        ),
                                      };
                                      auditObjectsArray.push(auditObject);
                                    }
                                  });
                                });
                              } else {                               
                                //logic for array object's string values.(displayName,description)
                                if (
                                  page5ProfileLabels[valuesObjectKeys][
                                    firstLevelObjectKey
                                  ][secondLevelObjectKey][0][
                                    fourthLevelObjectKeys
                                  ] !== undefined &&
                                  values[valuesObjectKeys][firstLevelObjectKey][
                                    secondLevelObjectKey
                                  ][thirdLevelIndex][fourthLevelObjectKeys] !==
                                    page5Data[valuesObjectKeys][
                                      firstLevelObjectKey
                                    ][secondLevelObjectKey][thirdLevelIndex][
                                      fourthLevelObjectKeys
                                    ]
                                ) {
                                  let auditObject = {
                                    itemAffected: `${
                                      page5ProfileLabels[valuesObjectKeys][
                                        firstLevelObjectKey
                                      ][secondLevelObjectKey][0][
                                        fourthLevelObjectKeys
                                      ]
                                    } ${thirdLevelIndex + 1}`,
                                    newValue: stripHtml(
                                      values[valuesObjectKeys][
                                        firstLevelObjectKey
                                      ][secondLevelObjectKey][thirdLevelIndex][
                                        fourthLevelObjectKeys
                                      ]
                                    ),
                                    oldValue: stripHtml(
                                      page5Data[valuesObjectKeys][
                                        firstLevelObjectKey
                                      ][secondLevelObjectKey][thirdLevelIndex][
                                        fourthLevelObjectKeys
                                      ]
                                    ),
                                  };
                                  auditObjectsArray.push(auditObject);
                                }
                              }
                            });
                          }
                        );
                      } else {
                        Object.keys(
                          values[valuesObjectKeys][firstLevelObjectKey][
                            secondLevelObjectKey
                          ]
                        ).map((thirdLevelObjectKey: any) => {
                          //mapping to get keys of foundersVision==>media .

                          Object.keys(
                            values[valuesObjectKeys][firstLevelObjectKey][
                              secondLevelObjectKey
                            ][thirdLevelObjectKey]
                          )?.map((fourthLevelObjectKeys: any) => {
                            //map value object to get keys.
                            //logic for url                           
                            if (
                              fourthLevelObjectKeys == "url" &&
                              page5ProfileLabels[valuesObjectKeys][
                                firstLevelObjectKey
                              ][secondLevelObjectKey][thirdLevelObjectKey][
                                fourthLevelObjectKeys
                              ] !== undefined &&
                              values[valuesObjectKeys][firstLevelObjectKey][
                                secondLevelObjectKey
                              ][thirdLevelObjectKey][fourthLevelObjectKeys] !==
                                page5Data[valuesObjectKeys][
                                  firstLevelObjectKey
                                ][secondLevelObjectKey][thirdLevelObjectKey][
                                  fourthLevelObjectKeys
                                ]
                            ) {
                              let auditObject = {
                                itemAffected: `${page5ProfileLabels[valuesObjectKeys][firstLevelObjectKey][secondLevelObjectKey][thirdLevelObjectKey][fourthLevelObjectKeys]}`,
                                newValue: stripHtml(
                                  values[valuesObjectKeys][firstLevelObjectKey][
                                    secondLevelObjectKey
                                  ][thirdLevelObjectKey][fourthLevelObjectKeys]
                                ),
                                oldValue: stripHtml(
                                  page5Data[valuesObjectKeys][
                                    firstLevelObjectKey
                                  ][secondLevelObjectKey][thirdLevelObjectKey][
                                    fourthLevelObjectKeys
                                  ]
                                ),
                              };
                              auditObjectsArray.push(auditObject);
                            }
                          });
                        });
                      }
                    } else {
                      //section heading of corporatePhilosophy.
                      if (
                        page5ProfileLabels[valuesObjectKeys][
                          firstLevelObjectKey
                        ][secondLevelObjectKey] !== undefined &&
                        values[valuesObjectKeys][firstLevelObjectKey][
                          secondLevelObjectKey
                        ] !==
                          page5Data[valuesObjectKeys][firstLevelObjectKey][
                            secondLevelObjectKey
                          ]
                      ) {
                        let auditObject = {
                          itemAffected: `${page5ProfileLabels[valuesObjectKeys][firstLevelObjectKey][secondLevelObjectKey]}`,
                          newValue: stripHtml(
                            values[valuesObjectKeys][firstLevelObjectKey][
                              secondLevelObjectKey
                            ]
                          ),
                          oldValue: stripHtml(
                            page5Data[valuesObjectKeys][firstLevelObjectKey][
                              secondLevelObjectKey
                            ]
                          ),
                        };
                        auditObjectsArray.push(auditObject);
                      }
                    }
                  });
                }
              } else {
                //logic here.for first level object string values.(securityTips==>sectionHeading)and more.               
                if (
                  values[valuesObjectKeys][firstLevelObjectKey] !== undefined &&
                  values[valuesObjectKeys][firstLevelObjectKey] !==
                    page5Data[valuesObjectKeys][firstLevelObjectKey] &&
                  firstLevelObjectKey !== "isAboutHoablActive" &&
                  firstLevelObjectKey !== "isFoundersVisionActive" &&
                  firstLevelObjectKey !== "isCorporatePhilosophyActive" &&
                  firstLevelObjectKey !== "isProductCategoryActive" &&
                  firstLevelObjectKey !== "isStatsOverviewActive"
                ) {
                  let auditObject = {
                    itemAffected: `${page5ProfileLabels[valuesObjectKeys][firstLevelObjectKey]}`,
                    newValue: stripHtml(
                      values[valuesObjectKeys][firstLevelObjectKey]
                    ),
                    oldValue: stripHtml(
                      page5Data[valuesObjectKeys][firstLevelObjectKey]
                    ),
                  };
                  auditObjectsArray.push(auditObject);
                }
                if (
                  values[valuesObjectKeys][firstLevelObjectKey] !== undefined &&
                  values[valuesObjectKeys][firstLevelObjectKey] !==
                    page5Data[valuesObjectKeys][firstLevelObjectKey] &&
                  (firstLevelObjectKey == "isAboutHoablActive" ||
                    firstLevelObjectKey == "isFoundersVisionActive" ||
                    firstLevelObjectKey == "isCorporatePhilosophyActive" ||
                    firstLevelObjectKey == "isProductCategoryActive" ||
                    firstLevelObjectKey == "isStatsOverviewActive")
                ) {
                  let auditObject = {
                    itemAffected: `${page5ProfileLabels[valuesObjectKeys][firstLevelObjectKey]}`,
                    newValue: values[valuesObjectKeys][firstLevelObjectKey]
                      ? "ON"
                      : "Off",
                    oldValue: page5Data[valuesObjectKeys][firstLevelObjectKey]
                      ? "ON"
                      : "Off",
                  };
                  auditObjectsArray.push(auditObject);
                }
              }
            }
          );
        }
      } else {
        //logic here.just values object's strings value.
        if (
          page5ProfileLabels[valuesObjectKeys] !== undefined &&
          values[valuesObjectKeys] !== page5Data[valuesObjectKeys] &&
          (valuesObjectKeys === "isSecurityTipsActive" ||
            valuesObjectKeys === "isTermsActive")
        ) {
          let auditObject = {
            itemAffected: `${page5ProfileLabels[valuesObjectKeys]}`,
            newValue: values[valuesObjectKeys] ? "ON" : "Off",
            oldValue: page5Data[valuesObjectKeys] ? "ON" : "Off",
          };
          if (auditObject.newValue !== auditObject.oldValue) {
            auditObjectsArray.push(auditObject);
          }
        }
      }
    });
    setPageManagementCreationProfile("modulesAudited", [
      ...modulesAudited,
      ...auditObjectsArray,
    ]);
    setFinalValues(values);
  };

  return (
    <Fragment>
      <PageHeading title="Page Management" />
      <BreadCrumbs routes={breadcrumbRoutes} />
      {isLoading ? (
        <Loader />
      ) : (
        <Page5ProfileDelayed>
          <Formik
            initialValues={
              pageManagementData !== null &&
              pageManagementData !== undefined &&
              !!pageManagementData
                ? pageManagementData?.page
                : page5ProfileInitialValue
            }
            validationSchema={validationSchemaforProfile}
            onSubmit={handleGlobalSubmit}
          >
            {({ values, isValid, dirty }) => (
              <Form>
                <Container className="main-container">
                  <Stack>
                    <div className="heading-action-holder">
                      <h4 className="heading">Page 5 - Profile</h4>
                    </div>
                    {getPage5ProfileState.isViewMode ? (
                      <LoadingButtonComponent
                        onClick={handleEditClick}
                        color={"inherit"}
                      >
                        Edit
                      </LoadingButtonComponent>
                    ) : (
                      <Stack columnGap={2}>
                        <ButtonComponent onClick={() => navigate(-1)}>
                          Cancel
                        </ButtonComponent>
                        <LoadingButtonComponent
                          disabled={!isValid}
                          type="submit"
                        >
                          {pageManagementData !== null
                            ? "Publish Changes"
                            : "Publish"}
                        </LoadingButtonComponent>
                      </Stack>
                    )}
                  </Stack>
                  {Page5ProfileSection()?.map(
                    (page5ProfileInfo: any, index: any) => (
                      <Accordion
                        key={index}
                        expanded={expanded === `panel${page5ProfileInfo.id}`}
                        onChange={handleChangeExpanded(
                          `panel${page5ProfileInfo.id}`,
                          page5ProfileInfo.setStatus,
                          page5ProfileInfo.id
                        )}
                      >
                        <AccordionSummary
                          aria-controls={`panel${page5ProfileInfo.id}-content`}
                          id={`panel${page5ProfileInfo.id}-header`}
                        >
                          <Typography variant="body1">
                            {page5ProfileInfo.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {page5ProfileInfo.component}
                        </AccordionDetails>
                      </Accordion>
                    )
                  )}
                </Container>
                <ConfirmationDialog
                  shouldOpen={confirmDialogOpen}
                  content="Are you sure you want to Publish?"
                  okText="Publish"
                  okHandler={() => handleDeleteOkClick(values)}
                  cancelHandler={handleDialogClose}
                />
              </Form>
            )}
          </Formik>
          {!isLoading && flashMessageShow && (
            <FlashMessageDialog
              cancel={false}
              shouldOpen={flashMessageShow}
              content={error ? error : message}
              isSuccess={error ? false : true}
              cancelHandler={handleCloseFlashMessage}
            />
          )}
        </Page5ProfileDelayed>
      )}
    </Fragment>
  );
};
export default Page5Profile;
