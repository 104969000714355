import { Constants } from "constants/Constants";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  //Get All Project Drafts
  getProjectDraftSuccess: ["response"],
  getProjectDraftFailure: ["error"],
  getProjectDraftRequest: ["data"],

  addProjectDraftSuccess: ["response"],
  addProjectDraftFailure: ["error"],
  addProjectDraftRequest: ["data"],

  updateProjectDraftSuccess: ["response"],
  updateProjectDraftFailure: ["error"],
  updateProjectDraftRequest: ["data"],

  deleteProjectDraftSuccess: ["response"],
  deleteProjectDraftFailure: ["error"],
  deleteProjectDraftRequest: ["data"],

  setProjectDraftState: ["key", "value"],
});

export const projectDraftTypes = Types;

const projecDraftActions = Creators;
export default projecDraftActions;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  data: [],
  message: "",
  openFlashMessage: false,
  isSuccess: false,
  totalCount: 0,
  page: 0,
  rowsPerPage: 5,
};

export const getProjectDraftRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getProjectDraftSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      data: response.data,
      isLoading: false,
      totalCount: response.totalCount ? response.totalCount : 0,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const getProjectDraftFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
    openFlashMessage: true,
  };
};

//add project Draft

export const addProjectDraftRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    message: null,
    error: null,
    isSuccess: false,
  };
};

export const addProjectDraftSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      openFlashMessage: true,
      message: response.message,
      isSuccess: true,
    };
  }
};

export const addProjectDraftFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    openFlashMessage: true,
    isSuccess: false,
  };
};

// Update Project Draft
export const updateProjectDraftRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    message: null,
    error: null,
    isSuccess: false,
  };
};

export const updateProjectDraftSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      openFlashMessage: true,
      message: response.message,
      isSuccess: true,
    };
  }
};

export const updateProjectDraftFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    openFlashMessage: true,
    isSuccess: false,
  };
};

// delete draft
export const deleteProjectDraftRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    message: null,
    error: null,
    isSuccess: false,
  };
};

export const deleteProjectDraftSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      isSuccess: true,
      openFlashMessage: true,
      message: response?.message,
    };
  }
};

export const deleteProjectDraftFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    openFlashMessage: true,
    isSuccess: false,
  };
};

export const setProjectDraftState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROJECT_DRAFT_REQUEST]: getProjectDraftRequest,
  [Types.GET_PROJECT_DRAFT_SUCCESS]: getProjectDraftSuccess,
  [Types.GET_PROJECT_DRAFT_FAILURE]: getProjectDraftFailure,

  [Types.ADD_PROJECT_DRAFT_REQUEST]: addProjectDraftRequest,
  [Types.ADD_PROJECT_DRAFT_SUCCESS]: addProjectDraftSuccess,
  [Types.ADD_PROJECT_DRAFT_FAILURE]: addProjectDraftFailure,

  [Types.UPDATE_PROJECT_DRAFT_REQUEST]: updateProjectDraftRequest,
  [Types.UPDATE_PROJECT_DRAFT_SUCCESS]: updateProjectDraftSuccess,
  [Types.UPDATE_PROJECT_DRAFT_FAILURE]: updateProjectDraftFailure,

  [Types.DELETE_PROJECT_DRAFT_REQUEST]: deleteProjectDraftRequest,
  [Types.DELETE_PROJECT_DRAFT_SUCCESS]: deleteProjectDraftSuccess,
  [Types.DELETE_PROJECT_DRAFT_FAILURE]: deleteProjectDraftFailure,

  [Types.SET_PROJECT_DRAFT_STATE]: setProjectDraftState,
});
