import {
  Avatar,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MediaAssets from "../../../../../../assets";
import PDFViewDialog from "components/pdf-view-dialog/PDFViewDialog";
import { ButtonComponent } from "components/ui-elements";
import { Constants } from "constants/Constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomerActions } from "redux-container/customer-management/CustomersListAndDetailsRedux";
import { formatDateTime } from "utils/DateFormatterUtils";
import { grey } from "@mui/material/colors";

interface IPaymentReceiptsDialogProps {
  shouldOpen: boolean;
  cancelHandler: () => void;
  receiptsList?: any;
}
const columnLabels = [
  { heading: "Document Name" },
  { heading: "Updated Date" },
  { heading: "Action" },
];
const receiptsList1 = [
  { name: "Document Name", path: "" },
  { name: "Document Name", path: "" },
  { name: "Document Name", path: "" },
];
const actionDispatch = (dispatch: any) => {
  return {
    getCustomerDocument: async (params: any) => {
      dispatch(CustomerActions.getCustomerDocumentRequest(params));
    },
    setCustomersState: (key: any, value: any) => {
      dispatch(CustomerActions.setCustomersState(key, value));
    },
  };
};

function PaymentReceiptsDialog({
  shouldOpen,
  cancelHandler,
  receiptsList,
}: IPaymentReceiptsDialogProps) {
  const { getCustomerDocument, setCustomersState } = actionDispatch(
    useDispatch()
  );
  const customerDocument: any = useSelector(
    (state: any) => state?.customerListAndDetails?.customerDocument
  );
  const isDocumentLoading: any = useSelector(
    (state: any) => state?.customerListAndDetails?.isLoading
  );
  const [shouldOpenPDFViewDialog, setShouldOpenPDFViewDialog] = useState(false);
  const [shouldOpenPdf, setShouldOpenPdf] = useState<boolean>(false);
  const [URLofDocument, setURLofDocument] = useState<any>();
  const [isPdf, setIsPdf] = useState(false);

  function dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  useEffect(() => {
    if (!isDocumentLoading && customerDocument && shouldOpenPdf) {
      // if (!isDocumentLoading && customerDocument) {
      const blob = dataURItoBlob(customerDocument);
      const url = URL.createObjectURL(blob);
      setURLofDocument(url);
      // window.open(url);
      // }
    }
  }, [customerDocument]);

  const [filePath, setFilePath] = useState<any>("");

  const handleViewClick = async (path: string | null) => {
    setFilePath(path);
    await getCustomerDocument(path);
    setShouldOpenPdf(true);
    setShouldOpenPDFViewDialog(true);

    if (path?.toLowerCase().includes(".pdf")) {
      setIsPdf(true);
    } else {
      setIsPdf(false);
    }
  };

  const onCloseModal = () => {
    setShouldOpenPDFViewDialog(false);
    setShouldOpenPdf(false);
    setCustomersState("customerDocument", null);
  };
  return (
    <>
      <Dialog
        open={shouldOpen}
        // onClose={cancelHandler}
        disableEscapeKeyDown={true}
      >
        {receiptsList?.length > 0 && <DialogTitle>See Receipts</DialogTitle>}
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={cancelHandler}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: grey,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Container className="main-container">
            {receiptsList?.length > 0 ? (
              <Table style={{ position: "relative" }}>
                <TableHead>
                  <TableRow className="table-header-row">
                    {columnLabels?.map((columnName: any) => {
                      return (
                        <TableCell
                          sx={{ textAlign: "center" }}
                          className="table-header-text"
                        >
                          {columnName?.heading}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {receiptsList?.map((receipt: any) => {
                    return (
                      <TableRow>
                        <TableCell className="table-data-text">
                          {/* {receipt.name} */}
                          <Stack>
                            <Avatar src={MediaAssets.ic_docs_icon} />
                            {receipt.name?.length > 18
                              ? `${receipt.name?.slice(0, 20)}...`
                              : receipt.name}
                          </Stack>
                        </TableCell>
                        <TableCell className="table-data-text">
                          {formatDateTime(
                            receipt.updatedAt,
                            Constants.dateFormatDDmmYYYY
                          )}
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 120 }}
                          className="table-data-text"
                        >
                          <Tooltip title="Edit" placement="top">
                            <ButtonComponent
                              sx={{
                                textDecoration: "underline",
                                marginTop: "0.5rem",
                              }}
                              variant="text"
                              color="inherit"
                              onClick={() => handleViewClick(receipt.path)}
                            >
                              See Receipt
                            </ButtonComponent>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <DialogContent>
                <DialogTitle>No Receipts For This Payment</DialogTitle>
              </DialogContent>
            )}
          </Container>
        </DialogContent>
      </Dialog>
      {shouldOpenPDFViewDialog ? (
        <PDFViewDialog
          shouldOpen={shouldOpenPDFViewDialog}
          content={isPdf ? URLofDocument : customerDocument}
          cancelHandler={onCloseModal}
          isLoading={isDocumentLoading}
          isDocument={false}
          filePath={filePath}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default PaymentReceiptsDialog;
