import {
  Avatar,
  Box,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./EssentialDetailsForm.module.css";
import { SwitchComponent } from "components/ui-elements";
import MediaAssets from "assets";

const FieldHeadingToggle = (props: any) => {
  const {
    fieldName,
    handleToggleChange,
    value,
    toggleName,
    tooltipContent,
    disableToggle,
  } = props;
  return (
    <div>
      <Stack spacing={2} justifyContent={"flex-start"} mb={"3px"}>
        <Typography fontSize={"1.125rem"} fontFamily={"Jost-Medium"}>
          {fieldName}
        </Typography>

        <SwitchComponent
          name={toggleName}
          checked={value}
          onChange={handleToggleChange}
          disabled={disableToggle}
        />
        <IconButton>
          <Tooltip
            title={
              tooltipContent ||
              "Toggle is to show/hide this section on the Customer app"
            }
            placement="top"
            arrow
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
      </Stack>
    </div>
  );
};

export default FieldHeadingToggle;
