import { InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import QuillEditor from "components/quill-editor/QuillEditor";
import { SwitchComponent, TextFieldComponent } from "components/ui-elements";
import { Form, Formik, getIn, useFormikContext } from "formik";
import { Fragment, useEffect, useState } from "react";
import styles from "./FoundersVision.module.css";
import { FoundersConstants } from "../../AboutUsPageConstants";
import * as Yup from "yup";
import ProfileImageUploadDragDrop from "pages/page-management/page-management-dashboard/page-5-profile/profile-mediaUpload/ProfileImageUploadDragDrop";
import MediaAssets from "assets";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { IPage5ProfileInitialValue } from "../../../page-5-profile-IniticalValue";
import { stripHtml } from "utils/ValidationUtils";

interface IFoundersVision {
  imageUpload: any;
  mediaDescription: any;
  sectionHeading: string;
  foundersName: string;
}

const FounderVision = () => {
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [founderVisionImageMedia, setFounderVisionImageMedia] = useState(false);
  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };
  const handleOpenConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const {
    imageDetails,
    isLoading,
    error,
    isSuccess,
    isDelete,
    isDeleteConfirmInPageManagementProfile,
    data,
  } = useSelector((state: any) => state.fileUpload);
  const { isReduxViewMode } = useSelector(
    (state: any) => state?.pageManagement
  );
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    handleBlur,
    isValid,
    errors,
    touched,
  } = useFormikContext<IPage5ProfileInitialValue>();
  const dispatch = useDispatch();

  const handleOnGlobalSave = () => {};

  useEffect(() => {
    if (founderVisionImageMedia) {
      const insightMediaImageUpload = `aboutUs.foundersVision.media.value.url`;
      setFieldValue(insightMediaImageUpload, imageDetails?.url);
    }
    setFounderVisionImageMedia(false);
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  useEffect(() => {
    if (founderVisionImageMedia) {
      const empty = "";
      const insightMediaImageUpload = `aboutUs.foundersVision.media.value.url`;
      setFieldValue(insightMediaImageUpload, empty);
    }
    setFounderVisionImageMedia(false);
    dispatch(FileUploadActions.setUploadFileState("isDelete", false));
  }, [isDelete]);

  const handleSetFileNameAndSize = (file: any) => {
    const fileNameOfMedia = `aboutUs.foundersVision.media.name`;
    const fileKeyOfMedia = `aboutUs.foundersVision.media.key`;
    const fileSizeOfMedia = `aboutUs.foundersVision.media.value.size`;
    setFieldValue(fileNameOfMedia, file?.name);
    setFieldValue(fileKeyOfMedia, file?.name);
    setFieldValue(fileSizeOfMedia, file?.size);
  };

  const handleImageUpload = (file: any) => {
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        dispatch(FileUploadActions.fileUploadRequest(data));
        data.binary = event.target.result;
      };
      reader.readAsArrayBuffer(file);
      setFounderVisionImageMedia(true); //this is for making true of particular image
      {
        !isLoading && handleSetFileNameAndSize(file);
      }
    } else {
      return;
    }
  };
  const handleConfirmDelete = (values: any) => {
    let fileName = values?.aboutUs?.foundersVision?.media?.name;
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
    setFounderVisionImageMedia(true); //this is for making true of particular image
    // {
    //   !isLoading &&
    //     dispatch(
    //       FileUploadActions.setUploadFileState(
    //         "isDeleteConfirmInPageManagementProfile",
    //         true
    //       )
    //     );
    // }
    handleCloseConfirmDialog();
  };

  const handleConfirmDeleteImage = () => {
    // setFounderVisionImageMedia(true); //this is for making true of particular image
    // if (founderVisionImageMedia) {
    const empty = "";
    const insightMediaImageUpload = `aboutUs.foundersVision.media.value.url`;
    setFieldValue(insightMediaImageUpload, empty);
    // }
    setFounderVisionImageMedia(false);
    setFounderVisionImageMedia(true); //this is for making true of particular image
    handleCloseConfirmDialog();
  };
  // const handleCloseFlashMessage = () => {
  //   dispatch(
  //     FileUploadActions.setUploadFileState(
  //       "isDeleteConfirmInPageManagementProfile",
  //       false
  //     )
  //   );
  //   // dispatch(FileUploadActions.setUploadFileState("error", null));
  // };
  const handleTextEditor = (
    name: string,
    newValue: string,
    options: any,
    { setFieldValue, setFieldError }: any
  ) => {
    // if (options?.isError) {
    //   setFieldError(name, "Maximum character exceed");
    // } else {
      setFieldValue(name, newValue);
    // }
  };

  return (
    <Fragment>
      <div className={styles["accordian-global-padding"]}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={12}
            xl={12}
            className={styles["priority-holder"]}
          >
            <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
              <Typography>Founder's Vision</Typography>

              <FormControlLabel
                className="switch-form-label"
                name={"aboutUs.isFoundersVisionActive"}
                checked={values?.aboutUs?.isFoundersVisionActive}
                disabled={isReduxViewMode || false}
                onChange={handleChange}
                control={<SwitchComponent />}
                label=""
                labelPlacement="start"
              />
              <IconButton>
                <Tooltip title={"Founder's Vision"} placement="top" arrow>
                  <Avatar
                    className={styles["Avator"]}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} mt={3}>
            <TextFieldComponent
              required
              label="Section Heading"
              placeholder="Enter"
              name={`aboutUs.foundersVision.sectionHeading`}
              disabled={isReduxViewMode || false}
              inputProps={{
                maxLength: FoundersConstants.sectionHeadingCharacterLimit,
              }}
              value={values?.aboutUs?.foundersVision?.sectionHeading}
              onChange={handleChange}
              onBlur={handleBlur}
              fieldhelpertext={
                getIn(touched, "aboutUs.foundersVision.sectionHeading") &&
                getIn(errors, "aboutUs.foundersVision.sectionHeading")
              }
              error={Boolean(
                getIn(errors, "aboutUs.foundersVision.sectionHeading") &&
                  getIn(touched, "aboutUs.foundersVision.sectionHeading")
              )}
            />
          </Grid>

          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} mt={3}>
            <TextFieldComponent
              required
              label="Founder Name"
              placeholder="Enter"
              name={`aboutUs.foundersVision.founderName`}
              disabled={isReduxViewMode || false}
              inputProps={{
                maxLength: FoundersConstants.foundersNAmeCharacterLimit,
              }}
              value={values?.aboutUs?.foundersVision?.founderName}
              onChange={handleChange}
              onBlur={handleBlur}
              fieldhelpertext={
                getIn(touched, "aboutUs.foundersVision.founderName") &&
                getIn(errors, "aboutUs.foundersVision.founderName")
              }
              error={Boolean(
                getIn(errors, "aboutUs.foundersVision.founderName") &&
                  getIn(touched, "aboutUs.foundersVision.founderName")
              )}
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles["accordian-global-padding"]}>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={4} xl={3.5}>
            <Typography className={styles["Upload-heading"]}>
              Upload Image
            </Typography>
            <ProfileImageUploadDragDrop
              uploadMediaButton={false}
              dragAndDropButton={true}
              onClick={handleChange}
              onDrop={handleChange}
              onChange={(file: any) => {
                handleImageUpload(file);
              }}
              previewImage={values?.aboutUs?.foundersVision?.media?.value?.url}
              cancelImage={handleOpenConfirmDialog}
              fieldhelpertext={
                errors?.aboutUs?.foundersVision?.media?.value?.url
              }
            />
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8.5}>
            <Stack
              justifyContent={"flex-start"}
              className={styles["Upload-heading"]}
            >
              <Typography>Description</Typography>
            </Stack>
            <QuillEditor
              placeholder="Enter description"
              className={styles["QuillEditor"]}
              name={"aboutUs.foundersVision.description"}
              onBlur={handleBlur}
              onChange={(name: string, newValue: string, options: any) =>
                handleTextEditor(name, newValue, options, {
                  setFieldValue,
                  setFieldError,
                })
              }
              inputProps={{
                maxLength: FoundersConstants.description,
              }}
              value={values?.aboutUs?.foundersVision?.description}
              style={
                 { height: "12.3rem" }
              }
              disabled={isReduxViewMode}
              fieldhelpertext={
                stripHtml(values?.aboutUs?.foundersVision?.description)
                  .length === 0 && errors?.aboutUs?.foundersVision?.description
              }
              error={Boolean(
                getIn(errors, "aboutUs?.foundersVision?.description") &&
                  getIn(touched, "aboutUs?.foundersVision?.description")
              )}
            />
          </Grid>
        </Grid>
      </div>

      {/* <Divider />

      <div className={styles["accordian-global-padding"]}>
        <Grid className={styles["button-position"]} mb={2}>
          <Button
            variant="contained"
            type="submit"
            onSubmit={handleOnGlobalSave}
          >
            Save
          </Button>
        </Grid>
      </div> */}
      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Insights Creation Media?"
        content="This action will delete the Insights Creation Media. Are you sure you want to continue?"
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        // okHandler={() => handleConfirmDelete(values)}
        okHandler={() => handleConfirmDeleteImage()}
      />
      {/* <FlashMessageDialog
        cancel={false}
        shouldOpen={isDeleteConfirmInPageManagementProfile}
        content={data}
        isSuccess={error === null ? true : false}
        cancelHandler={handleCloseFlashMessage}
      /> */}
    </Fragment>
  );
};

export default FounderVision;
