import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import {
  Stack,
  Typography,
  Container,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Link,
  Box,
  TextField,
  Divider,
  Grid,
  InputAdornment,
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonComponent,
  LoadingButtonComponent,
  MediaUploadComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { useFormikContext } from "formik";

import { ProjectContentSections } from "../ProjectContentSections";
import { upCommingValidationSchema } from "./UpcommingValidation";
// import { InitinalValueProjectContent } from "../InitinalValuesProjectContent";
import {
  IInitinalValueProjectContent,
  InitinalValueProjectContent,
} from "./UpCommingInitialValues";
import FormFields from "./FormFields";
import UpCommingProjectContentCreationTypes from "redux-container/project-management/upcomming-project/UpcommingProjectRedux";
import { useDispatch, useSelector } from "react-redux";
import UpdateFormFields from "./UpdateFormFields";

const actionDispatch = (dispatch: any) => {
  return {
    upCommingProjectContentCreationDataRequest: (data: any) =>
      dispatch(
        UpCommingProjectContentCreationTypes.upCommingProjectContentCreationDataRequest(
          data
        )
      ),
  };
};

const UpdateUpcommingProject = () => {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const singlleProduct = useSelector((state: any) => {
    return state?.upCommingProjects?.singleProduct;
  });
  console.log("state", singlleProduct);

  const [project, setProject] = useState<any>({
    launchName: singlleProduct.launchName,
    shortDescription: singlleProduct.shortDescription,
  });

  const { upCommingProjectContentCreationDataRequest } = actionDispatch(
    useDispatch()
  );
  const initialValues = {
    title: "",
    location: "",
    description: "",
    ProjectPhoneNumber: "",
    projectIcon: {
      name: "",
      key: "",
      value: {
        url: "",
        size: "",
        width: 1024,
        height: 1024,
        mediaType: "image",
      },
    },
    bannerImage: {
      name: "",
      key: "",
      value: {
        url: "",
        size: "",
        width: 1024,
        height: 1024,
        mediaType: "image",
      },
    },
  };

  const handleOnSave = (values: any) => {
    alert("dewfre");
    console.log(values);
  };
  const handleOnChnage = (e: any) => {
    const { name, value } = e?.target;
    setProject((preVal: any) => {
      return { ...preVal, [name]: value };
    });
  };
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    console.log(project);
  };
  useEffect(() => {
    // update state value by store;
  }, []);

  return (
    <>
      {/* <Formik
        initialValues={InitinalValueProjectContent}
        enableReinitialize={true}
        validationSchema={upCommingValidationSchema}
        onSubmit={handleOnSave}
      >
        {({ values, isValid }) => (
          <Form noValidate>
            <UpdateFormFields /> */}
      {/* <Collapse in={!isViewMode} orientation={"horizontal"}> */}
      <UpdateFormFields />
      {/*             
          </Form>
        )}
      </Formik> */}

      {/* <form onSubmit={handleOnSubmit}>
        <input
          type="text"
          defaultValue={project.launchName}
          name="launchName"
          onChange={handleOnChnage}
        />
        <input
          type="text"
          defaultValue={project.shortDescription}
          name="shortDescription"
          onChange={handleOnChnage}
        />
        <button type="submit">Submit</button>
      </form> */}
    </>
  );
};

export default UpdateUpcommingProject;
