import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import ProjectContentCreationAction from "redux-container/project-management/project-content/ProjectContentRedux";

export function* addProjectContentCreationDataSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.addProjectContentCreationData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectContentCreationAction.addProjectContentCreationDataSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProjectContentCreationAction.addProjectContentCreationDataFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      ProjectContentCreationAction.addProjectContentCreationDataFailure(error)
    );
  }
}

export function* updateProjectContentCreationDataSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(
      api.updateProjectContentCreationData,
      action.data
    );
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectContentCreationAction.updateProjectContentCreationDataSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProjectContentCreationAction.updateProjectContentCreationDataFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      ProjectContentCreationAction.updateProjectContentCreationDataFailure(
        error
      )
    );
  }
}

export function* getProjectContentCreationDataSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.getProjectContentCreationData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectContentCreationAction.getProjectContentCreationDataSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProjectContentCreationAction.getProjectContentCreationDataFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      ProjectContentCreationAction.getProjectContentCreationDataFailure(error)
    );
  }
}

export function* deleteProjectContentMappedFaq(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.deleteProjectMappedFaq, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectContentCreationAction.deleteMappedProjectContentFaqSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProjectContentCreationAction.deleteMappedProjectContentFaqFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      ProjectContentCreationAction.deleteMappedProjectContentFaqFailure(error)
    );
  }
}

export function* getProjectContentToCloneSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.getProjectContentCreationData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectContentCreationAction.getProjectContentToCloneSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProjectContentCreationAction.getProjectContentToCloneFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      ProjectContentCreationAction.getProjectContentToCloneFailure(error)
    );
  }
}
