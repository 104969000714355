import {
  Avatar,
  Box,
  DialogTitle,
  Stack,
  styled,
  Typography,
} from "@mui/material";

export const ResetPasswordStyleWrapper = styled(Box)`
  & .MuiDialogActions-root {
    padding: 1rem;
    padding-bottom: 2rem;
  }
  & .MuiDialogContent-root {
    padding: 0.5rem 1.5rem;
  }
`;

export const TextFieldSection = styled(Box)`
  margin: 1rem 0;
`;

export const CloseIcon = styled(Avatar)`
  width: 1rem;
  height: 1rem;
`;

export const DialogBoxOuter = styled(DialogTitle)`
  padding: 20px 30px 36px;
`;

export const ImageWrapper = styled(Stack)`
  height: 30px;
  width: 30px;
  margin-right: 20px;
  margin-top: 20px;
`;

export const TypographyWrapper = styled(Typography)`
  margin-top: 20px;
`;

export const AvatarWrapper = styled(Avatar)`
  width: 0.9rem;
  height: 0.9rem;
  cursor: pointer;
  margintop: 0.1rem;
  marginbottom: 0.1rem;
`;
