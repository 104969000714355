import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import ForgotPassword from "../forgot-password/ForgotPassword";
import { ForgotPasswordAction } from "redux-container/forgot-password/ForgotPasswordRedux";
import IconButton from "@mui/material/IconButton";
import { isEmpty, isEmailValid, isPasswordValid } from "utils/ValidationUtils";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";
import { Link } from "@mui/material";
import LoginActions from "../../redux-container/login/LoginRedux";
import MediaAssets from "../../assets";
import { RouteConstants } from "routes/RouteConstants";
import ResetPassword from "../forgot-password/ResetPassword";
import styles from "./Login.module.css";
import TextField from "@mui/material/TextField";
import { TextFieldComponent } from "components/ui-elements";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import UserActions from "redux-container/users/UsersRedux";

const actionDispatch = (dispatch: any) => {
  return {
    setUsersState: (key: any, data: any) =>
      dispatch(UserActions.setUsersState(key, data)),
  };
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginError = useSelector((state: any) => state?.login.error);
  const isLoading = useSelector((state: any) => state?.login.isLoading);
  const params = useParams();

  const [ip, setIP] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [isEmailError, setEmailError] = useState(false);
  const [isPasswordError, setPasswordError] = useState(false);
  const [isPasswordVisble, setPasswordVisble] = useState(false);
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [isForgotEmailDialogOpen, setForgotEmailDialogOpen] = useState(false);

  const { setUsersState } = actionDispatch(useDispatch());

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
    if (params?.token) {
      setResetPasswordOpen(true);
    }
  });

  useEffect(() => {
    setUsersState("isLoggedIn", false);
  }, []);

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim());
    setEmailErrorMessage("");
    setEmailError(false);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value.trim());
    setPasswordErrorMessage("");
    setPasswordError(false);
  };

  const handleCloseFlashMessageDialog = () => {
    dispatch(LoginActions.setLoginState("error", null));
  };

  const handleOpenForgotPassword = () => {
    setResetPasswordOpen(true);
  };

  const handleCloseResetPassword = () => {
    setResetPasswordOpen(false);
    navigate(RouteConstants.login);
  };

  const handleOpenEmailDialog = () => {
    setForgotEmailDialogOpen(true);
    dispatch(
      ForgotPasswordAction.setForgotPasswordState("enteredEmail", email)
    );
  };
  const handleCloseEmailDialog = () => {
    setForgotEmailDialogOpen(false);
    dispatch(ForgotPasswordAction.setForgotPasswordState("error", null));
  };

  const checkIsPasswordValid = (password: string) => {
    if (isEmpty(password)) {
      setPasswordErrorMessage("Please enter password");
      setPasswordError(true);
      return false;
    }
    if (password.length < 8) {
      setPasswordErrorMessage("Password should contain minimum 8 characters");
      setPasswordError(true);
      return false;
    }
    const isValid = isPasswordValid(password);

    if (!isValid) {
      setPasswordError(true);
      setPasswordErrorMessage("Password is incorrect");
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }
    return isValid;
  };

  const checkIsEmailValid = (email: string) => {
    if (isEmpty(email)) {
      setEmailErrorMessage("Please enter a valid email address");
      setEmailError(true);
      return false;
    }
    const isValid = isEmailValid(email);
    if (!isValid) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address");
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }
    return isValid;
  };

  const authenticateUser = async () => {
    if (checkIsEmailValid(email) && checkIsPasswordValid(password)) {
      const data = {
        email: email,
        password: password,
        ipAddress: ip,
      };
      await dispatch(LoginActions.loginRequest(data));
    }
  };

  const handleLoginOnKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      authenticateUser();
    }
  };

  const handleClickPassword = () => {
    setPasswordVisble(!isPasswordVisble);
  };

  const handleOnBlurEmail = () => {
    if (!isEmailValid(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address");
    } else {
      setEmailErrorMessage("");
      setEmailError(false);
    }
  };

  const handleOnBlurPassword = () => {
    if (!isPasswordValid(password)) {
      setPasswordError(true);
      setPasswordErrorMessage("Password is incorrect");
    } else {
      setPasswordErrorMessage("");
      setPasswordError(false);
    }
  };

  return (
    <div className={styles["login-container"]}>
      <div className={styles["login-card"]}>
        <img src={MediaAssets.ic_logo_black} alt="" className={styles.logo} />

        <h1 className={styles["login-heading"]}>Admin Login</h1>

        <div className={styles["textfiled-holder"]}>
          <TextFieldComponent
            required
            label="Email Address"
            placeholder="Enter"
            type="text"
            autoFocus
            focused
            value={email}
            onChange={handleEmailChange}
            onBlur={handleOnBlurEmail}
            onKeyPress={handleLoginOnKeyPress}
            className={styles["email-password-textfield"]}
            fieldhelpertext={emailErrorMessage}
            error={isEmailError === true ? true : false}
          />

          <TextFieldComponent
            required
            className={`${styles["email-password-textfield"]} ${styles["password-textfield"]}`}
            type={isPasswordVisble ? "text" : "password"}
            label="Password"
            placeholder="Enter"
            // onBlur={handleBlur}
            value={password}
            onChange={handlePasswordChange}
            onBlur={handleOnBlurPassword}
            onKeyPress={handleLoginOnKeyPress}
            error={isPasswordError === true ? true : false}
            fieldhelpertext={passwordErrorMessage}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickPassword}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {isPasswordVisble ? (
                    <Visibility className="password-eye-icon" />
                  ) : (
                    <VisibilityOff className="password-eye-icon" />
                  )}
                </IconButton>
              ),
            }}
          />
        </div>

        <Link
          underline="always"
          sx={{ cursor: "pointer" }}
          className={styles["forgot-password-link"]}
          onClick={handleOpenEmailDialog}
          data-testid="forgot-password-link"
        >
          Forgot Password?
        </Link>
        <ForgotPassword
          open={isForgotEmailDialogOpen}
          onClose={handleCloseEmailDialog}
        />
        <ResetPassword
          open={isResetPasswordOpen}
          onClose={handleCloseResetPassword}
        />
        <Button
          disabled={!email || !password}
          variant="contained"
          onClick={authenticateUser}
          autoFocus
          disableElevation
          className={
            !email || !password
              ? "btn btn-disabled login-btn"
              : "btn btn-dark login-btn"
          }
        >
          {isLoading ? "Loading..." : "Login"}
        </Button>
      </div>
      {loginError ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={loginError}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
    </div>
  );
};

export default Login;
