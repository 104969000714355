import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { call, put } from "redux-saga/effects";
import FormsListActions from "../../redux-container/form-list/FormListRedux";

export function* getFormsListSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getFormsList, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(FormsListActions.formsListSuccess(response.data));
    } else {
      yield put(FormsListActions.formsListFailure(response.data));
    }
  } catch (error) {
    yield put(FormsListActions.formsListFailure(error));
  }
}
export function* getProjectsListSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getProjectsList, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(FormsListActions.getProjectsSuccess(response.data));
    } else {
      yield put(FormsListActions.getProjectsFailure(response.data));
    }
  } catch (error) {
    yield put(FormsListActions.getProjectsFailure(error));
  }
}
export function* getLaunchesListSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getLaunchesList, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(FormsListActions.getLaunchesSuccess(response.data));
    } else {
      yield put(FormsListActions.getLaunchesFailure(response.data));
    }
  } catch (error) {
    yield put(FormsListActions.getLaunchesFailure(error));
  }
}
export function* updateFromStatusSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateFormStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(FormsListActions.updateFormStatusSuccess(response.data));
    } else {
      yield put(FormsListActions.updateFormStatusFailure(response.data));
    }
  } catch (error) {
    yield put(FormsListActions.updateFormStatusFailure(error));
  }
}
export function* deleteFormsSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteForms, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(FormsListActions.deleteFormsSuccess(response.data));
    } else {
      yield put(FormsListActions.deleteFormsFailure(response.data));
    }
  } catch (error) {
    yield put(FormsListActions.deleteFormsFailure(error));
  }
}
