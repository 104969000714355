import { Box, Divider, Grid, MenuItem, Stack, Typography } from "@mui/material";
import {
  CustomDatePicker,
  InfoComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { TimeLineStatusTypeEnumUtils } from "enumerations/timeLineStatusTypeEnum";
import { useFormikContext, getIn } from "formik";
import { ChangeEvent, Fragment } from "react";
import { useSelector } from "react-redux";
import { handleNumberFieldChange, shouldNumberOnly } from "utils/ValidationUtils";
import { infraSubHeadings, statusOptions } from "../ProjectTimelineInitialData";

const InfrastructureAndAmenitiesDevelopment = () => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } =
    useFormikContext<any>();
  const infrastructureSectionIndex = 2;
  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));

  const InfrastutureAndAmenitiesStatusOption =
    TimeLineStatusTypeEnumUtils.getTimeLineStatusTypeEnums();

  const handleDatePickerChange = (name: string) => (newValue: string) => {
    setFieldValue(name, newValue);
  };

  const handleNumberFieldChange =
  (setFieldValue: any) => (event: ChangeEvent<HTMLInputElement>) => {
    if (shouldNumberOnly(event.target.value) && event.target.value.length < 3) {
      setFieldValue(`${event.target.name}`, Number(event.target.value));
    }
  };
  return (
    <Fragment>
      <Box className="section-gutter">
              <Grid container>
                <Grid item lg={3} xl={3}>
                  <TextFieldComponent
                    label="Section Heading*"
                    inputProps={{ maxLength: 40 }}
                    name={`projectTimelines[${infrastructureSectionIndex}].timeLineSectionHeading`}
                    value={
                      values?.projectTimelines[infrastructureSectionIndex]?.timeLineSectionHeading
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      getIn(
                        touched,
                        `projectTimelines[${infrastructureSectionIndex}].timeLineSectionHeading`
                      ) &&
                        getIn(
                          errors,
                          `projectTimelines[${infrastructureSectionIndex}].timeLineSectionHeading`
                        )
                    )}
                    fieldhelpertext={
                      getIn(
                        touched,
                        `projectTimelines[${infrastructureSectionIndex}].timeLineSectionHeading`
                      ) &&
                      getIn(
                        errors,
                        `projectTimelines[${infrastructureSectionIndex}].timeLineSectionHeading`
                      )
                    }
                    disabled={isViewMode}
                  />
                </Grid>
              </Grid>
      </Box>
      {values?.projectTimelines[infrastructureSectionIndex]?.timeLines?.map(
        (timeline: any, timelineIndex: number) => (
          <Fragment key={timelineIndex + 1}>
            <Divider />
              <Fragment key={timelineIndex + 1}>
                <Stack className="field-container-box">
                  <Typography>
                    3.{timelineIndex + 1}.
                    {infraSubHeadings[timelineIndex]?.title}
                  </Typography>
                  <SwitchComponent
                    name={`projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].isSectionActive`}
                    onChange={handleChange}
                    checked={
                      values?.projectTimelines[infrastructureSectionIndex]
                        ?.timeLines[timelineIndex]?.isSectionActive
                    }
                    disabled={isViewMode}
                  />
                  <InfoComponent />
                </Stack>
                <Box className="section-gutter">
                  <Grid container columnSpacing={5} rowSpacing={3}>
                    <Grid item lg={3} xl={3}>
                      <TextFieldComponent
                        label="Display Name*"
                        inputProps={{ maxLength: 30 }}
                        onChange={handleChange}
                        value={
                          values?.projectTimelines[infrastructureSectionIndex]
                            ?.timeLines[timelineIndex]?.values?.displayName
                        }
                        name={`projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.displayName`}
                        onBlur={handleBlur}
                        error={Boolean(
                          getIn(
                            touched,
                            `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.displayName`
                          ) &&
                            getIn(
                              errors,
                              `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.displayName`
                            )
                        )}
                        fieldhelpertext={
                          getIn(
                            touched,
                            `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.displayName`
                          ) &&
                          getIn(
                            errors,
                            `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.displayName`
                          )
                        }
                        disabled={isViewMode}
                      />
                    </Grid>
                    <Grid item lg={3} xl={3}>
                      <CustomDatePicker
                        label={"Date of Completion"}
                        placeholder={"Select Date"}
                        name={`projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.dateOfCompletion`}
                        value={
                          new Date(
                            values?.projectTimelines[
                              infrastructureSectionIndex
                            ]?.timeLines[timelineIndex]?.values?.dateOfCompletion
                          )
                        }
                        onChange={handleDatePickerChange(
                          `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.dateOfCompletion`
                        )}
                        disabled={isViewMode}
                      />
                    </Grid>
                    <Grid item lg={3} xl={3}>
                      <TextFieldComponent
                        select
                        label="Status *"
                        onChange={(e : any) => {
                          handleChange(e);
                          if(e.target.value === "6003"){
                            setFieldValue(`projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.percentage`, 100)
                          }else{
                            if(values?.projectTimelines[infrastructureSectionIndex].timeLines[timelineIndex].values.percentage === 100){
                              setFieldValue(`projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.percentage`, 0)
                            }
                          }
                         }}
                        value={
                          values?.projectTimelines[infrastructureSectionIndex]
                            ?.timeLines[timelineIndex]?.values?.status
                        }
                        name={`projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.status`}
                        onBlur={handleBlur}
                        error={Boolean(
                          getIn(
                            touched,
                            `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.status`
                          ) &&
                            getIn(
                              errors,
                              `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.status`
                            )
                        )}
                        fieldhelpertext={
                          getIn(
                            touched,
                            `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.status`
                          ) &&
                          getIn(
                            errors,
                            `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.status`
                          )
                        }
                        disabled={isViewMode}
                      >
                        {InfrastutureAndAmenitiesStatusOption.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextFieldComponent>
                    </Grid>
                    {values?.projectTimelines[infrastructureSectionIndex]
                      ?.timeLines[timelineIndex]?.values
                      ?.status === "6002" && (
                      <Grid item lg={2} xl={2}>
                        <TextFieldComponent
                          label="% Value*"
                          onChange={handleNumberFieldChange(setFieldValue)}
                          value={
                            values?.projectTimelines[infrastructureSectionIndex]
                              ?.timeLines[timelineIndex]?.values
                              ?.percentage
                          }
                          name={`projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.percentage`}
                          onBlur={handleBlur}
                          error={Boolean(
                            getIn(
                              touched,
                              `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.percentage`
                            ) &&
                              getIn(
                                errors,
                                `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.percentage`
                              )
                          )}
                          fieldhelpertext={
                            getIn(
                              touched,
                              `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.percentage`
                            ) &&
                            getIn(
                              errors,
                              `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.percentage`
                            )
                          }
                          disabled={isViewMode}
                        />
                      </Grid>
                    )}
                    <Grid item lg={12} xl={12}>
                      <TextFieldComponent
                        required
                        label="Tool tip Details"
                        inputProps={{ maxLength: 240 }}
                        name={`projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`}
                        onChange={handleChange}
                        value={
                          values?.projectTimelines[infrastructureSectionIndex]
                            ?.timeLines[timelineIndex]?.values?.toolTipDetails
                        }
                        onBlur={handleBlur}
                        error={Boolean(
                          getIn(
                            touched,
                            `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                          ) &&
                            getIn(
                              errors,
                              `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                            )
                        )}
                        fieldhelpertext={
                          getIn(
                            touched,
                            `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                          ) &&
                          getIn(
                            errors,
                            `projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                          )
                        }
                        disabled={isViewMode}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Stack className="field-container-bottom">
                  <SwitchComponent
                    name={`projectTimelines[${infrastructureSectionIndex}].timeLines[${timelineIndex}].values.isCtaActive`}
                    checked={
                      values?.projectTimelines[infrastructureSectionIndex]
                        ?.timeLines[timelineIndex]?.values?.isCtaActive
                    }
                    onChange={handleChange}
                    disabled={isViewMode}
                  />
                  <Typography>Show CTA on Customer app</Typography>
                </Stack>
                <Divider />
              </Fragment>
          </Fragment>
        )
      )}
    </Fragment>
  );
};

export default InfrastructureAndAmenitiesDevelopment;
