import { Box } from "@mui/material";
import NewForm from "./NewForm";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

const newFormHeadings = [
  { heading: "Name" },
  { heading: "Project Name" },
  { heading: "Email id" },
  { heading: "Contact" },
  { heading: "Paid Amount" },
  { heading: "Pending Amount" },
  { heading: "Total amount" },
  { heading: "Av Includes" },
  { heading: "Milestone Name" },
  { heading: "Milestone Flag" },
];


const PaymentHistoryListing = () => {

  const { searchKey }: any = useOutletContext();
  const tabValue = useSelector((state: any) => state?.formsList?.tabValue);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <NewForm
        listHeading={newFormHeadings}
        isNewForm={true}
        tabValue={tabValue}
        searchKey={tabValue == "1" ? searchKey : ""}
      />
    </Box>
  );
};

export default PaymentHistoryListing;
