import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionTypeEnum,
  ActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import CachedIcon from "@mui/icons-material/Cached";
import { Constants } from "constants/Constants";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { exportAsExcel } from "utils/ExportFileUtils";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { formatDateTime } from "utils/DateFormatterUtils";
import Loader from "components/loader/Loader";
import MediaAssets from "assets";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NoDataRecord from "components/no-data-record/NoDataRecord";
import PageHeading from "components/page-heading/PageHeading";
import Retry from "components/retry/Retry";
import { RoleTypeEnum, RoleTypeEnumUtils } from "enumerations/RoleTypeEnum";
import { RouteConstants } from "routes/RouteConstants";
import {
  StatusTypeEnum,
  StatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import { TextFieldComponent } from "components/ui-elements";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import UserActions from "redux-container/users/UsersRedux";
import { DesignationTypeEnumUtils } from "enumerations/DesignationTypeEnum";
import { DepartmentTypeEnumUtils } from "enumerations/DepartmentTypeEnum";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";

const actionDispatch = (dispatch: any) => {
  return {
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),
    deleteUsers: (data: any) => dispatch(UserActions.deleteUsersRequest(data)),
    updateUserStatus: (data: any) =>
      dispatch(UserActions.changeUserStatusRequest(data)),
    setUsersState: (key: any, data: any) =>
      dispatch(UserActions.setUsersState(key, data)),
  };
};

const UserList = () => {
  const { getUsers, deleteUsers, updateUserStatus, setUsersState } =
    actionDispatch(useDispatch());

  const navigate = useNavigate();
  const location: any = useLocation();
  const actions = ActionTypeEnumUtils.getUserActionTypeEnums();
  // const roles = RoleTypeEnumUtils.getRoleTypeEnums();project
  const statuses = StatusTypeEnumUtils.getStatusTypeEnums();
  const designations = DesignationTypeEnumUtils.getDesignationTypeEnums();
  const departments = DepartmentTypeEnumUtils.getDepartmentTypeEnums();

  const users = useSelector((state: any) => state?.users.users);
  const totalCount = useSelector((state: any) => state?.users.totalCount);
  const isLoading = useSelector((state: any) => state.users?.isLoading);
  const error = useSelector((state: any) => state.users?.error);
  const isSuccess = useSelector((state: any) => state.users?.isSuccess);
  const message = useSelector((state: any) => state.users?.message);
  const shouldOpenFlashDialog = useSelector(
    (state: any) => state.users?.shouldOpenFlashDialog
  );
  const { page, rowsPerPage } = useSelector((state: any) => {
    return {
      page: state.users?.page,
      rowsPerPage: state.users?.rowsPerPage,
    };
  });
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [updatedTime, setUpdatedTime] = useState(new Date());
  const [roles, setRoles] = useState<any>([]);

  const breadcrumbRoutes: any = [
    {
      name: "CMS User Management",
      route: RouteConstants.userManagementDashboard,
    },
    {
      name: "User List",
      route: RouteConstants.userList,
    },
  ];

  //table headings (heading objects with name,boolean key for whether it is sorting column or not,and sorting key for an API call )
  const tableHeadings = [
    { heading: "User ID" },
    { heading: "First Name" },
    { heading: "Last Name" },
    { heading: "Email Address" },
    { heading: "Phone Number" },
    { heading: "Role" },
    { heading: "Assigned Module" },
    { heading: "Status" },
    { heading: "Actions" },
  ];

  useEffect(() => {
    const params = {};
    // loadData(params);
    getFilterDataFromDashboard();
  }, []);

  const getFilterDataFromDashboard = () => {
    if (location && location.state && location.state.userType) {
      setSelectedRole(location.state.userType as string);
    }
    if (location && location.state && location.state.statusType) {
      setSelectedStatus(location.state.statusType as string);
    }
    navigate(location.pathname, { replace: true });
  };

  //Loading Data (Insights data) while mounting and
  // every time after User applies any filters (Status,roleId,department,Search key,designation,page and number of rows per page) .
  useEffect(() => {
    if (isSuccess) {
      const user = getItemFromLocalStorage(Constants.loggedInUser);
      if (user?.admin?.roleId === RoleTypeEnum.SuperAdmin) {
        setRoles(RoleTypeEnumUtils.getRoleTypeEnumsForSuperAdmin());
      } else {
        setRoles(RoleTypeEnumUtils.getRoleTypeEnums());
      }
      const params = {
        index: page,
        size: rowsPerPage,
        roleId: location?.state?.userType
          ? location?.state?.userType
          : selectedRole,
        status: location?.state?.statusType
          ? location?.state?.statusType
          : selectedStatus,
        department: selectedDepartment,
        designation: selectedDesignation,
        searchKey: encodeURIComponent(searchKey),
      };
      if (
        searchKey ||
        selectedRole ||
        selectedStatus ||
        rowsPerPage ||
        page ||
        selectedDesignation ||
        selectedDepartment
      ) {
        loadData(params);
        clearSelected();
      }
    }
  }, [
    page,
    rowsPerPage,
    selectedRole,
    selectedStatus,
    selectedDepartment,
    selectedDesignation,
    searchKey,
  ]);

  const loadData = (params: any) => {
    getUsers(params);
  };

  //setting page and rowsPerPage to initial value if user Navigated to the list from Side menu.
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setUsersState("page", 1);
      setUsersState("rowsPerPage", 5);
    }

    return () => {};
  }, []);
  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  //handle change function for page in Pagination.
  const handleChangePage = (event: unknown, newPage: number) => {
    setUsersState("page", newPage + 1);
  };

  //handle change function for number of elements per page in Pagination.
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUsersState("rowsPerPage", Number(event.target.value));
    setUsersState("page", 1);
  };

  //handle change function for Action change .
  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case ActionTypeEnum.Export:
        handleExportAsExcel();
        break;
      case ActionTypeEnum.Delete:
        handleDeleteUser();
        break;
      case ActionTypeEnum.MarkAsInvited:
        changeUserStatus(String(StatusTypeEnum.Invited));
        break;
      case ActionTypeEnum.MarkAsSuspended:
        changeUserStatus(String(StatusTypeEnum.Suspended));
        break;
    }
  };

  const handleDeleteUser = () => {
    if (selectedUsers.length === 0) {
      alert("You have not selected any users");
      return;
    }
    setIsConfirmDeleteDialogOpen(true);
  };

  //function to make an API call to delete the user.
  const deleteSelectedUsers = () => {
    setIsConfirmDeleteDialogOpen(false);
    const admins = {
      admins: selectedUsers,
    };
    deleteUsers(admins);
    clearSelected();
  };

  //function to make an API call to change status of the user.
  const changeUserStatus = (status: string) => {
    const data = {
      status: status,
      admins: selectedUsers,
    };

    if (selectedUsers.length === 0) {
      alert("You have not selected any users");
      return;
    }
    updateUserStatus(data);
  };

  //function to export Selected user.
  const handleExportAsExcel = () => {
    if (selectedUsers.length === 0) {
      alert("You have not selected any User");
      return;
    }
    let exportingUsers: any = [];
    if (users.length) {
      selectedUsers.map((selectedUser: any) => {
        let localSelectedItem = users.find(
          (item: any) => item.id == selectedUser
        );
        exportingUsers.push({
          User_ID: localSelectedItem.id,
          First_Name: localSelectedItem.firstName
            ? localSelectedItem.firstName
            : "----",
          Last_Name: localSelectedItem.lastName
            ? localSelectedItem.lastName
            : "----",
          Email: localSelectedItem.email
            ? String(localSelectedItem.email).toLowerCase()
            : "----",
          Phone_Number: localSelectedItem.phoneNumber
            ? `${localSelectedItem.countryCode} ${localSelectedItem.phoneNumber}`
            : "----",
          Role: localSelectedItem.roleId
            ? RoleTypeEnumUtils.getEnumText(localSelectedItem.roleId)
            : "----",
          Assigned_Module:
            localSelectedItem.roleId === RoleTypeEnum.RootAdmin ||
            localSelectedItem.roleId === RoleTypeEnum.SuperAdmin
              ? "All"
              : `${localSelectedItem.adminsOrModules.map((module: any) => {
                  return module.name;
                })}`,
          Status: localSelectedItem.status
            ? StatusTypeEnumUtils.getEnumText(localSelectedItem.status)
            : "----",
        });
      });
    }

    exportAsExcel(exportingUsers, "Users", "Sheet1");
  };

  //handle change function to status filter.
  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
    if (page !== 1) {
      setUsersState("page", 1);
    }
  };

  //  //handle change function to Department filter.
  const handleDepartmentChange = (event: SelectChangeEvent) => {
    setSelectedDepartment(event.target.value as string);
    if (page !== 1) {
      setUsersState("page", 1);
    }
  };

  //handle change function to Role filter .
  const handleRoleChange = (event: SelectChangeEvent) => {
    setSelectedRole(event.target.value as string);
    if (page !== 1) {
      setUsersState("page", 1);
    }
  };

  //handle change function for Search key.
  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 1) {
      setUsersState("page", 1);
    }
  };

  const resetState = () => {
    // setUsersState("message", null);
    // setUsersState("error", null);
    // setUsersState("isSuccess", false);
    setUsersState("shouldOpenFlashDialog", false);
  };

  const handleCloseFlashMessageDialog = () => {
    resetState();
    clearSelected();
    if (isSuccess) {
      setUsersState("isSuccess", false);
      const params = {
        index: page,
        size: rowsPerPage,
        roleId: selectedRole,
        status: selectedStatus,
        department: selectedDepartment,
        designation: selectedDesignation,
        searchKey: searchKey,
      };
      loadData(params);
      clearSelected();
    }
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = users.map((data: any) => data.id);
      setSelectedUsers(newSelecteds);
      return;
    }
    setSelectedUsers([]);
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers?.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelected);
  };

  const clearSelected = () => {
    setSelectedUsers([]);
  };

  //function to clear all the filter .
  const clearFilters = () => {
    setSelectedDesignation("");
    setSelectedStatus("");
    setSelectedDepartment("");
    setSelectedRole("");
    setSearchKey("");
  };

  const isSelected = (id: any) => selectedUsers.indexOf(id) !== -1;

  const createOrInviteUser = () => {
    navigate(RouteConstants.userCreation);
  };

  //Navigate to Edit user details.
  const onEditUser = (userObj: any) => {
    navigate(`${RouteConstants.userCreation}`, {
      state: { ...userObj, isView: false, isEditMode: true },
    });
  };

  //Navigate to View user Details.
  const viewUser = (userObj: any) => {
    navigate(`${RouteConstants.userCreation}`, {
      state: { ...userObj, isView: true },
    });
  };

  const syncUsers = () => {
    setUpdatedTime(new Date());
    const params = {
      index: page,
      size: rowsPerPage,
      roleId: selectedRole,
      status: selectedStatus,
      department: selectedDepartment,
      designation: selectedDesignation,
      searchKey: searchKey,
    };
    loadData(params);
    clearFilters();
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_add_user}
        altText=""
        message="You haven't created or invited any User yet. Create your first User."
        btnTitle="CREATE USER"
        btnAction={createOrInviteUser}
      />
    );
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Users
            {`(${totalCount ? totalCount : users?.length ? users?.length : 0})`}
            {selectedUsers.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedUsers.length} User(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
              >
                {actions.map((action: any) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>

            <Button
              variant="contained"
              onClick={createOrInviteUser}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Invite New
            </Button>
          </div>
        </div>

        <Grid container spacing={1}>
          <Grid container spacing={2} md={8} lg={8} xl={8}>
            <Grid
              item
              md={1}
              lg={1}
              xl={1}
              className="margin-left-10 filter-heading-holder"
              style={{ position: "relative" }}
            >
              <span
                className="filter-heading"
                style={{ position: "absolute", top: 32 }}
              >
                Filters:
              </span>
            </Grid>

            <Grid item md={2} lg={2} xl={2}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedRole}
                  label="Role"
                  onChange={handleRoleChange}
                >
                  {roles.map((role: any) => {
                    return (
                      <MenuItem value={role.value} key={role.value}>
                        {role.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            <Grid item md={2} lg={2} xl={2}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedStatus}
                  label="Status"
                  onChange={handleStatusChange}
                >
                  {statuses.map((status: any) => {
                    return (
                      <MenuItem value={status.value} key={status.value}>
                        {status.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedDepartment}
                  label="Department"
                  onChange={handleDepartmentChange}
                >
                  {departments.map((department: any) => {
                    return (
                      <MenuItem value={department.label} key={department.value}>
                        {department.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            {/* <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedDesignation}
                  label="Designation"
                  onChange={handleDesignationChange}
                >
                  {designations.map((designation: any) => {
                    return (
                      <MenuItem
                        value={designation.label}
                        key={designation.value}
                      >
                        {designation.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid> */}

            {selectedDepartment !== "" ||
            selectedDesignation !== "" ||
            selectedRole !== "" ||
            selectedStatus !== "" ? (
              <Grid
                item
                md={0.5}
                lg={0.5}
                xl={0.5}
                className="filter-heading-holder"
                style={{ position: "relative" }}
              >
                <span
                  onClick={clearFilters}
                  className="clear-filter"
                  style={{ position: "absolute", top: 32 }}
                >
                  Clear
                </span>
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={0} md={4} lg={4} xl={4}>
            <Grid item md={12} lg={12} xl={12}>
              <div className="time-sync-holder">
                <div className="time-sync-holder margin-right-15">
                  <span className="margin-right-5 updated-on-heading">
                    Updated on:
                  </span>
                  <span>
                    {formatDateTime(updatedTime, Constants.timeFormathhmmssA)}
                  </span>
                </div>

                <Button
                  variant="contained"
                  onClick={syncUsers}
                  // autoFocus
                  disableElevation
                  className="btn btn-sync"
                >
                  <CachedIcon />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell style={{ minWidth: 50 }} padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedUsers?.length > 0 &&
                      selectedUsers?.length < users?.length
                    }
                    checked={
                      users?.length > 0 &&
                      selectedUsers?.length === users?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all users",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {users && users.length > 0 ? (
              <TableBody>
                {users.map((user: any) => {
                  const isItemSelected = isSelected(user.id);
                  return (
                    <TableRow
                      key={user.id}
                      className="table-data-row"
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ minWidth: 50 }} padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, user.id)}
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 100 }}
                        className="table-data-text"
                      >
                        {user.id}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {user.firstName ? user.firstName : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {user.lastName ? user.lastName : "----"}
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 100 }}
                        className="table-data-text"
                      >
                        {user.email ? String(user.email).toLowerCase() : "-"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {user.phoneNumber
                          ? `${user.countryCode} ${user.phoneNumber}`
                          : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {user.roleId
                          ? RoleTypeEnumUtils.getEnumText(user.roleId)
                          : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 200 }}
                        className="table-data-text"
                      >
                        {user.roleId === RoleTypeEnum.RootAdmin ||
                        user.roleId === RoleTypeEnum.SuperAdmin
                          ? "All"
                          : user.adminsOrModules.map((module: any) => {
                              return <div key={module.name}>{module.name}</div>;
                            })}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {user.status
                          ? StatusTypeEnumUtils.getEnumText(user.status)
                          : "----"}
                      </TableCell>

                      <TableCell style={{ minWidth: 120 }}>
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => onEditUser(user)}
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewUser(user)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No User Records</div>
            )}
          </Table>
        </TableContainer>

        {!searchKey && users && users.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={totalCount ? totalCount : 0}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    // if (users?.length > 0 && isLoading === false) {
    //   return renderTable();
    // } else if (users?.length === 0) {
    //   return renderNoData();
    // } else if (isLoading) {
    //   return renderLoader();
    // } else if (error) {
    //   return renderRetry();
    // }

    if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <PageHeading title="CMS User Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder={"Search Users"}
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete User?"
        content={`This action will delete ${selectedUsers.length} selected user(s). Are you sure you want to continue?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteSelectedUsers}
      />

      {message && shouldOpenFlashDialog ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : ""}
          isSuccess={true}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}

      {error && shouldOpenFlashDialog ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : "Something went wrong!"}
          isSuccess={isSuccess}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
    </Fragment>
  );
};

export default UserList;
