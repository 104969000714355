import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionTypeEnum,
  ActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import Button from "@mui/material/Button";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Checkbox from "@mui/material/Checkbox";
import ConfirmationDialog from "../../../../components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Loader from "../../../../components/loader/Loader";
import MediaAssets from "../../../../assets";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NoDataRecord from "../../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import Retry from "../../../../components/retry/Retry";
import { RouteConstants } from "../../../../routes/RouteConstants";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  StatusTypeEnum,
  StatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FaqListActions from "../../../../redux-container/resource-management/FaqListRedux";
import { exportAsExcel } from "utils/ExportFileUtils";
import { Stack } from "@mui/material";
import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import CategoryActions from "../../../../redux-container/resource-management/CategoryRedux";
import { BreadCrumbsWrapper } from "pages/resource-management/insights/insights-list/InsightList";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import axios from "axios";
import { AuditStatus } from "enumerations/AuditStatusTypes";

const actionDispatch = (dispatch: any) => {
  return {
    getFaqs: (
      params: any //here getFaqs and getCategories both are same end point.
    ) => dispatch(FaqListActions.getFaqListRequest(params)),
    getCategories: () => dispatch(CategoryActions.getCategoriesRequest()),
    deleteFaqs: (data: any) => dispatch(FaqListActions.deleteFaqsRequest(data)),
    changeFaqStatus: (data: any) =>
      dispatch(FaqListActions.changeFaqsStatusRequest(data)),
    changeFaqPriority: (data: any) =>
      dispatch(FaqListActions.changeFaqPriorityRequest(data)),
    setFaqListState: (key: any, data: any) =>
      dispatch(FaqListActions.setFaqListState(key, data)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
  };
};

const FAQList = () => {
  const {
    getFaqs,
    deleteFaqs,
    changeFaqStatus,
    changeFaqPriority,
    setFaqListState,
    getCategories,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());

  const navigate = useNavigate();
  const location: any = useLocation();
  const actions = ActionTypeEnumUtils.getActionTypeEnums();
  const statuses = StatusTypeEnumUtils.getFaqsStatusTypeEnums();

  const faqs = useSelector((state: any) => state?.faqList?.faqs);
  const totalCount = useSelector((state: any) => state?.faqList?.totalCount);
  const isLoading = useSelector((state: any) => state.faqList?.isLoading);
  const error = useSelector((state: any) => state.faqList?.error);
  const isSuccess = useSelector((state: any) => state.faqList?.isSuccess);
  const message = useSelector((state: any) => state.faqList?.message);
  const shouldAudit = useSelector((state: any) => state.faqList?.shouldAudit);
  const categories = useSelector((state: any) => state?.categories?.categories);
  const { page, rowsPerPage, modules } = useSelector((state: any) => {
    return {
      page: state?.faqList?.page,
      rowsPerPage: state?.faqList?.rowsPerPage,
      modules: state?.rolesAndModules?.module,
    };
  });
  const [selectedFaqs, setSelectedFaqs] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [searchKey, setSearchKey] = useState("");

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const [modulesAudited, setModulesAudited] = useState<any>(null);
  const [ipAddress, setIpAddress] = useState<any>(null);

  const breadcrumbRoutes: any = [
    {
      name: "Resource Management",
      route: RouteConstants.resourceManagementDashboard,
    },
    {
      name: "FAQ List",
      route: RouteConstants.faqList,
    },
  ];

  const tableHeadings = [
    { heading: "Category" },
    { heading: "Number of FAQs" },
    { heading: "Last Modified On" },
    { heading: "Last Modified By" },
    { heading: "Priority" },
    { heading: "Status" },
    { heading: "Action" },
  ];

  useEffect(() => {
    getFilterDataFromDashboard();
  }, []);

  useEffect(() => {
    const params = {
      index: page,
      size: rowsPerPage,
      status: location?.state?.statusType
        ? location?.state?.statusType
        : selectedStatus,
      searchKey: searchKey,
      categoryName: selectedCategory?.name,
    };
    if (
      searchKey ||
      selectedCategory ||
      selectedStatus ||
      rowsPerPage ||
      page
    ) {
      loadData(params);
    }
  }, [page, rowsPerPage, selectedCategory, searchKey, selectedStatus]);

  useEffect(() => {
    if (isSuccess) {
      setFaqListState("isSuccess", false);
      const params = {
        index: page,
        size: rowsPerPage,
        status: selectedStatus,
        searchKey: searchKey,
        shouldDisplayMappedFaqs: false,
        categoryName: selectedCategory?.name,
      };
      loadData(params);
      clearSelected();
    }
  }, [isSuccess]);

  const loadData = (params: any) => {
    getFaqs(params);
    getCategories();
  };
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setFaqListState("page", 1);
      setFaqListState("rowsPerPage", 20);
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (shouldAudit) {
      const moduleObject: any = modules?.find((element: any) => {
        return element?.name === "Resources Management";
      });
      const postAuditRequestbody = {
        ipAddress: ipAddress, //ipAddress
        moduleId: moduleObject?.id,
        route: null,
        auditedObject: null,
        modulesAudited: modulesAudited,
        status: null,
      };
      postAuditLogRequest(postAuditRequestbody);
      setFaqListState("shouldAudit", false);
    }
  }, [shouldAudit]);
  useEffect(() => {
    getIpAddress();
    return () => {};
  }, []);
  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIpAddress(res.data.IPv4);
  };

  const getFilterDataFromDashboard = () => {
    if (location && location.state && location.state.statusType) {
      setSelectedStatus(location.state.statusType as string);
    }
    navigate(location.pathname, { replace: true });
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFaqListState("page", newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFaqListState("rowsPerPage", Number(event.target.value));
    setFaqListState("page", 1);
  };

  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case ActionTypeEnum.Export:
        handleExportAsExcel();
        break;
      case ActionTypeEnum.Delete:
        handleDeleteFaqs();
        break;
      case ActionTypeEnum.MarkAsActive:
        changeFaqStatuses(String(StatusTypeEnum.Active));
        break;
      case ActionTypeEnum.MarkAsInactive:
        changeFaqStatuses(String(StatusTypeEnum.Inactive));
        break;
    }
  };

  const handleDeleteFaqs = () => {
    if (selectedFaqs.length === 0) {
      alert("You have not selected any faqs");
      return;
    }

    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Category ${item?.name} Deleted.(ID${item?.id})`,
        newValue: "",
        oldValue: "",
        auditedObject: null,
        route: null,
        status: AuditStatus.DELETED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    setIsConfirmDeleteDialogOpen(true);
  };

  const deleteSelectedFaqs = () => {
    setIsConfirmDeleteDialogOpen(false);
    const faqs = {
      ids: selectedFaqs,
    };
    deleteFaqs(faqs);
    clearSelected();
  };

  const changeFaqStatuses = (status: string) => {
    const data = {
      status: status,
      categoryIds: selectedFaqs,
    };

    if (selectedFaqs.length === 0) {
      alert("You have not selected any Faqs");
      return;
    }

    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Category Status '${item?.name}'`,
        newValue: StatusTypeEnum.Active === status ? "Active" : "Inactive",
        oldValue: StatusTypeEnum.Active === status ? "Inactive" : "Active",
        auditedObject: {
          // data: { ...item, status: status },
          categoryId: item?.id,
          isEditField: true,
        },
        route: RouteConstants?.faqCreation,
        status: AuditStatus.EDITED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    changeFaqStatus(data);
  };

  const handleExportAsExcel = () => {
    if (selectedFaqs.length === 0) {
      alert("You have not selected any Category");
      return;
    }
    let exportingCategories: any = [];
    if (faqs.length) {
      selectedFaqs.map((selectedCategory: any) => {
        let localSelectedItem = faqs.find(
          (item: any) => item.id == selectedCategory
        );
        exportingCategories.push({
          Promise_Name: localSelectedItem.name,
          No_Of_FAQs: localSelectedItem.numberOfFaqs
            ? localSelectedItem.numberOfFaqs
            : 0,

          Modified_On: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Last_Modified_by: localSelectedItem?.updatedBy
            ? localSelectedItem?.updatedBy
            : "----",
          Priority: localSelectedItem?.priority,
          Status: localSelectedItem.status
            ? StatusTypeEnumUtils.getEnumText(localSelectedItem.status)
            : "----",
        });
      });
    }
    exportAsExcel(exportingCategories, "FAQs", "Sheet1");
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value as string);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
  };

  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
  };

  const resetState = () => {
    setFaqListState("message", null);
    setFaqListState("error", null);
    setFaqListState("isSuccess", false);
  };

  const handleCloseFlashMessageDialog = () => {
    resetState();
    clearSelected();
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = faqs.map((data: any) => data.id);
      setSelectedFaqs(newSelecteds);
      return;
    }
    setSelectedFaqs([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedFaqs.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedFaqs, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedFaqs.slice(1));
    } else if (selectedIndex === selectedFaqs?.length - 1) {
      newSelected = newSelected.concat(selectedFaqs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedFaqs.slice(0, selectedIndex),
        selectedFaqs.slice(selectedIndex + 1)
      );
    }

    setSelectedFaqs(newSelected);
  };

  const clearSelected = () => {
    setSelectedFaqs([]);
  };

  const clearFilters = () => {
    setSelectedStatus("");
    setSearchKey("");
    setSelectedCategory(null);
  };

  const isSelected = (id: any) => selectedFaqs.indexOf(id) !== -1;

  const createFaq = () => {
    navigate(RouteConstants.faqCreation, {
      state: { isCreatfield: true, isNewFaqForm: true },
    });
  };

  const editFaq = (categoryId: any) => {
    navigate(RouteConstants.faqCreation, {
      state: {
        categoryId: categoryId,
        isEditField: true,
        isNavigationFromSidemenu: true,
      },
    });
  };

  const viewFaq = (categoryId: any) => {
    navigate(RouteConstants.faqCreation, {
      state: {
        categoryId: categoryId,
        isEditField: true,
        isViewField: true,
        isNavigationFromSidemenu: true,
      },
    });
  };

  const statusAuditedItems = () => {
    let auditedItems: any[] = [];
    faqs?.map((item: any) => {
      selectedFaqs?.map((itemId: number) => {
        if (itemId === item.id) {
          auditedItems.push(item);
        }
      });
    });
    return auditedItems;
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_faq}
        altText=""
        message="You haven't created any FAQs yet. Create your first FAQ."
        btnTitle="CREATE FAQS"
        btnAction={createFaq}
      />
    );
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Published Categories {`(${faqs.length})`}
            {selectedFaqs.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedFaqs.length} Category Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
              >
                {actions.map((action: any) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>

            <Button
              variant="contained"
              onClick={createFaq}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New Category and FAQs
            </Button>
          </div>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={8} lg={10} xl={10}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={categories}
                    getOptionLabel={(option: any) => option?.name}
                    value={selectedCategory}
                    label="Category"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedCategory(newValue);
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedStatus}
                    label="Status"
                    onChange={handleStatusChange}
                  >
                    {statuses?.map((status: any) => {
                      return (
                        <MenuItem value={status.value} key={status.value}>
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              {selectedStatus !== "" || selectedCategory !== null ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Stack>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell style={{ minWidth: 50 }} padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedFaqs?.length > 0 &&
                      selectedFaqs?.length < faqs?.length
                    }
                    checked={
                      faqs?.length > 0 && selectedFaqs?.length === faqs?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all faqs",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {faqs && faqs.length > 0 ? (
              <TableBody>
                {faqs.map((faq: any) => {
                  const isItemSelected = isSelected(faq.id);

                  return (
                    <TableRow
                      key={faq.id}
                      className="table-data-row"
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ minWidth: 50 }} padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, faq.id)}
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {faq.name ? faq.name : "-"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {faq.numberOfFaqs ? faq.numberOfFaqs : 0}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {faq?.categoryUpdatedAdmin?.firstName
                          ? faq.updatedAt
                            ? formatDateTime(
                                faq.updatedAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"
                          : "-"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {faq?.categoryUpdatedAdmin?.firstName
                          ? faq?.categoryUpdatedAdmin?.firstName
                          : "-"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        <PrioritySelector
                          incrementpriority={() => {
                            const priorityRequestBody = {
                              id: faq?.id,
                              priority: faq?.priority + 1,
                            };
                            changeFaqPriority(priorityRequestBody);
                          }}
                          decrementpriority={() => {
                            const priorityRequestBody = {
                              id: faq?.id,
                              priority: faq?.priority - 1,
                            };
                            changeFaqPriority(priorityRequestBody);
                          }}
                          priority={faq?.priority}
                          disableDecrement={faq?.priority == 1 ? true : false}
                          isPriorityRequired={
                            StatusTypeEnum?.Active === faq?.status
                              ? false
                              : true
                          }
                        />
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 120 }}
                        className="table-data-text"
                      >
                        {faq.status
                          ? StatusTypeEnumUtils.getEnumText(faq.status)
                          : "-"}
                      </TableCell>

                      <TableCell style={{ minWidth: 120 }}>
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editFaq(faq.id)}
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewFaq(faq.id)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No FAQ Records</div>
            )}
          </Table>
        </TableContainer>

        {!searchKey && faqs && faqs.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[20, 40, 50]}
            component="div"
            count={totalCount ? totalCount : faqs?.length}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <BreadCrumbsWrapper>
        <PageHeading title="Resource Management" />
        <BreadCrumbs
          routes={breadcrumbRoutes}
          placeholder="Search Category"
          handleSearchOnKeyPress={handleSearchChange}
        ></BreadCrumbs>

        <Container className="main-container">{render()}</Container>

        <ConfirmationDialog
          shouldOpen={isConfirmDeleteDialogOpen}
          title="Delete FAQ(s)?"
          content={`This action will delete ${selectedFaqs.length} selected faq(s). Are you sure you want to continue?`}
          okText="Delete"
          cancelHandler={handleCloseConfirmDialog}
          okHandler={deleteSelectedFaqs}
        />

        {message ? (
          <FlashMessageDialog
            shouldOpen={true}
            content={message ? message : "Something Went Wrong!"}
            isSuccess={true}
            cancelHandler={handleCloseFlashMessageDialog}
          />
        ) : null}

        {error ? (
          <FlashMessageDialog
            shouldOpen={true}
            content={error}
            isSuccess={false}
            cancelHandler={handleCloseFlashMessageDialog}
          />
        ) : null}
      </BreadCrumbsWrapper>
    </Fragment>
  );
};

export default FAQList;
