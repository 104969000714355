import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import {
  ButtonComponent,
  FormFieldContainer,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CarouselMediaUploadDialog from "./CarouselMediaUploadDialog";
import CarouselList from "./CarouselList";
import { useFormikContext } from "formik";
import { handleNumberFieldChange } from "utils/ValidationUtils";

const CarouselsContainer = styled(FormFieldContainer)`
  padding: 0;
`;

function Carousels(props: any) {
  const [openMediaUploadDialog, setOpenMediaUploadDialog] = useState({
    open: false,
    pageManagementOrCarousel: "",
  });
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  console.log("Carousels values", values);
  console.log("Carousels errors", errors);
  console.log("Carousels touched", touched);

  const closeCarouselDialog = () => {
    setOpenMediaUploadDialog({
      ...openMediaUploadDialog,
      open: false,
      pageManagementOrCarousel: "",
    });
  };

  return (
    <>
      <CarouselsContainer>
        <Grid container p={"1rem"}>
          <Grid item xs={6}>
            <Stack columnGap={2} justifyContent={"flex-start"}>
              <Typography className="accordion-heading" margin={"0.5rem 0"}>
                Carousels
              </Typography>
              <SwitchComponent
                name="isCarouselActive"
                checked={values?.isCarouselActive}
                value={values?.isCarouselActive}
                onChange={(event: any) => {
                  //   console.log("event", event.target.checked);
                  setFieldValue("isCarouselActive", event.target.checked);
                }}
              />
              <Tooltip
                title={"This Represents the Carousels on Customer app"}
                arrow
                placement="top-start"
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  className="infooutlined-alignment"
                />
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Grid container paddingY={"25px"} paddingX={"20px"}>
          <Grid item xs={4}>
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "space-evenly",
                marginLeft: "-25px",
              }}
            >
              <Typography sx={{ fontWeight: "400", fontSize: "1rem" }}>
                Configure Auto swiping
              </Typography>
              <Box
                sx={{
                  marginTop: "20px",
                  "& .MuiOutlinedInput-root": {
                    width: "6rem",
                    height: "50px",
                    fontSize: "1rem",
                  },
                  "& .MuiFormControl-root": {
                    width: "6rem",
                    height: "50px",
                  },
                }}
              >
                <TextFieldComponent
                  // type="number"
                  name="configureAutoSwiping"
                  value={values.configureAutoSwiping}
                  // onChange={(event: any) => {
                  //   console.log(
                  //     "validated configureAutoSwiping",
                  //     event?.target?.value
                  //   );
                  //   if (
                  //     event?.target.value != 0 &&
                  //     event?.target?.value > 0 &&
                  //     event?.target?.value < 21
                  //   ) {
                  //     console.log("validated", event?.target?.value);
                  //     setFieldValue(
                  //       "configureAutoSwiping",
                  //       event?.target?.value
                  //     );
                  //   } else if (event?.target?.value == "") {
                  //     setFieldValue("configureAutoSwiping", null);
                  //   }
                  // }}
                  onChange={(e: any) => {
                    if (
                      (e.target.value != 0 &&
                        e.target.value < 21 &&
                        !e.target.value.includes(".")) ||
                      e.target.value === ""
                    ) {
                      handleNumberFieldChange(setFieldValue)(e);
                      return;
                    }
                    //  else if (!e.target.value) {
                    //   setFieldValue("configureAutoSwiping", null);
                    // }
                  }}
                  onBlur={handleBlur}
                  fieldhelpertext={
                    touched?.configureAutoSwiping &&
                    errors?.configureAutoSwiping
                  }
                  error={Boolean(
                    touched?.configureAutoSwiping &&
                      errors?.configureAutoSwiping
                  )}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          color: "#c0bbbb",
                        }}
                      >
                        sec
                      </Typography>
                    ),
                  }}
                />
              </Box>
              <Typography
                sx={{
                  marginLeft: "-15px",
                }}
              >
                (Limited to 20 Sec)
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        {values?.pageManagementOrCarousels?.length > 0 ? (
          <Box sx={{ padding: "20px" }}>
            <CarouselList setOpenMediaUploadDialog={setOpenMediaUploadDialog} />
          </Box>
        ) : (
          ""
        )}
        <Box sx={{ paddingY: "25px", paddingX: "20px" }}>
          <ButtonComponent
            variant="contained"
            disabled={!values?.isCarouselActive}
            startIcon={<FileUploadOutlinedIcon />}
            onClick={() => {
              setOpenMediaUploadDialog({
                ...openMediaUploadDialog,
                open: true,
              });
            }}
          >
            {!(values?.pageManagementOrCarousels?.length > 0)
              ? "Add media"
              : "Add another Media"}
          </ButtonComponent>
          {errors?.isCarouselMediaPresent ? (
            <Typography sx={{ color: "red" }}>
              {errors?.isCarouselMediaPresent}
            </Typography>
          ) : null}
        </Box>
      </CarouselsContainer>
      {openMediaUploadDialog?.open ? (
        <CarouselMediaUploadDialog
          open={openMediaUploadDialog?.open}
          MediaObject={openMediaUploadDialog?.pageManagementOrCarousel}
          closeCarouselDialog={closeCarouselDialog}
        />
      ) : null}
    </>
  );
}

export default Carousels;
