import { utils, writeFileXLSX } from "xlsx";

export const exportAsExcel = (
  data: any,
  fileName: string,
  sheetName: string
) => {
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet(data);
  utils.book_append_sheet(workbook, worksheet, sheetName);
  writeFileXLSX(workbook, `${fileName}.xlsx`);
};
