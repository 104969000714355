import * as Yup from "yup";

export const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .required("This field is mandatory")
    .nullable(),
  lastName: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .required("This field is mandatory")
    .nullable(),
  department: Yup.string().required("This field is mandatory").nullable(),
  // designation: Yup.string().required("This field is mandatory").nullable(),

  phoneNumber: Yup.string()
    .matches(
      /^((?!(0))[0-9]{10})$/,
      "Phone number shouldn't start with 0 and must have 10 digit "
    )
    .required("This field is mandatory"),

  email: Yup.string()
    .email("Invalid email address")
    .required("This field is mandatory"),

  moduleIds: Yup.array().when("roleId", {
    is: "3",
    then: Yup.array().min(1, "This field is mandatory"),
  }),
});
