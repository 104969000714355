import { Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import BookingJourneySectionComponent from "./booking-journey-section/BookingJourneySection";

import JourneyBlock from "./booking-journey-section/JouneyBlocks";
import customerManagementActions from "redux-container/customer-management/CustomerManagementRedux";
import { useDispatch, useSelector } from "react-redux";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import {
  CustomerActions,
  setCustomersState,
} from "redux-container/customer-management/CustomersListAndDetailsRedux";
import PDFViewDialog from "components/pdf-view-dialog/PDFViewDialog";
import CustomerRegistrationDetailsDialog, {
  ICustomerRegistration,
} from "components/customer-registration-dialog/CustomerRegistrationDetailsDialog";
import CustomerPaymentPendingDialog from "components/customer-registration-dialog/CustomerPaymentPendingDialog";

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerBookingJourney: (params: any) =>
      dispatch(
        customerManagementActions.getCustomerBookingJourneyRequest(params)
      ),
    getCustomerDocument: async (params: any) => {
      dispatch(CustomerActions.getCustomerDocumentRequest(params));
    },
  };
};

const BookingJourney = (props: any) => {
  const { setShowAllPaymentReceipts, locationState } = props;
  const { getCustomerBookingJourney, getCustomerDocument } = actionDispatch(
    useDispatch()
  );
  const customerDocument: any = useSelector(
    (state: any) => state?.customerListAndDetails?.customerDocument
  );
  const isDocumentLoading: any = useSelector(
    (state: any) => state?.customerListAndDetails?.isLoading
  );
  const [openViewPDFDialog, setOpenViewPDFDialog] = useState<boolean>(false);
  const [openCustomerRegistrationDialog, setOpenCustomerRegistrationDialog] =
    useState<boolean>(false);
  const [registrationDetailsContent, setRegistrationDetailsContent] = useState<
    ICustomerRegistration[]
  >([]);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [openPaymentPendingDialog, setOpenPaymentPendingDialog] =
    useState(false);
  const [pendingPaymentDialogContent, setPendingPaymentDialogContent] =
    useState({});
  const [paymentStatus, setPaymentstatus] = useState<boolean>(false);
  const [pdfContentPath, setPdfContentPath] = useState<any>("");

  const bookingJourneyData = useSelector(
    (state: any) =>
      state?.customerManagement?.bookingJourneyData?.data?.bookingJourney
  );

  const projectList = useSelector(
    (state: any) => state?.projectList?.data?.data
  );

  const projectById = projectList?.find(
    (project: any) =>
      project?.id === locationState?.investmentDetailsOnAProject?.project?.id
  );

  const [isPdf, setIsPdf] = useState(false);
  const [URLofDocument, setURLofDocument] = useState("");

  useEffect(() => {
    const params = {
      cmsId: locationState?.customerData?.id,
      investmentId: locationState?.investmentDetailsOnAProject?.investment?.id,
    };
    getCustomerBookingJourney(params);
  }, []);

  const paymentPendingList = bookingJourneyData?.payments?.filter(
    (item: any) => item?.isPaymentDone === false
  );

  const handleViewClick = async (path?: string) => {
    setPdfContentPath(path);
    await getCustomerDocument(path);

    // if (path?.toLowerCase().includes(".pdf")) {
    //   setIsPdf(true);
    // } else if (path?.toLowerCase()?.split(".")[-1] === "docx") {
    // } else {
    //   setIsPdf(false);
    // }
    !isDocumentLoading && setOpenViewPDFDialog(true);
  };

  const handleViewRegistration = (dialogContent: any) => {
    const content: ICustomerRegistration[] = [
      {
        fieldTitle: "Registration Date: ",
        value: formatDateTime(
          dialogContent.registrationDate,
          Constants.dateFormatDDmmYYYY
        ),
      },
      {
        fieldTitle: "Registration Number: ",
        value: dialogContent.registrationNumber,
      },
    ];
    setDialogTitle("Registration Details");
    setRegistrationDetailsContent(content);
    setOpenCustomerRegistrationDialog(true);
  };

  const handleHandOver = (dialogContent: any) => {
    const content: ICustomerRegistration[] = [
      {
        fieldTitle: "Handover Date:",
        value: formatDateTime(
          dialogContent.handoverDate,
          Constants.dateFormatDDmmYYYY
        ),
      },
    ];
    setDialogTitle("Handover Details");
    setRegistrationDetailsContent(content);
    setOpenCustomerRegistrationDialog(true);
  };

  const handleOpenPaymentPendingDialog = (data: any, status: boolean) => {
    setOpenPaymentPendingDialog(true);
    setPendingPaymentDialogContent(data);
    setPaymentstatus(status);
  };

  const handleViewCustomerGuildline = (path: string) => {
    window.open(path, "_blank");
  };

  function dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  useEffect(() => {
    if (!isDocumentLoading && customerDocument && openViewPDFDialog) {
      const blob = dataURItoBlob(customerDocument);
      const url = URL.createObjectURL(blob);
      setURLofDocument(url);
    }
  }, [customerDocument]);

  return (
    <Fragment>
      <PDFViewDialog
        shouldOpen={openViewPDFDialog}
        content={isPdf ? URLofDocument : customerDocument}
        cancelHandler={() => setOpenViewPDFDialog(false)}
        isLoading={isDocumentLoading}
        handleRetry={() => handleViewClick(pdfContentPath)}
        filePath={pdfContentPath}
      />

      <CustomerPaymentPendingDialog
        title={paymentStatus ? "Amount Paid" : "Pending Amount"}
        shouldOpen={openPaymentPendingDialog}
        content={pendingPaymentDialogContent}
        cancelHandler={() => setOpenPaymentPendingDialog(false)}
        paymentStatus={paymentStatus}
      />

      <CustomerRegistrationDetailsDialog
        shouldOpen={openCustomerRegistrationDialog}
        title={dialogTitle}
        content={registrationDetailsContent}
        cancelHandler={() => setOpenCustomerRegistrationDialog(false)}
      />
      <BookingJourneySectionComponent
        titleText={"TRANSACTION"}
        isInvestmentCompleted={true}
        isSectionCompleted={
          bookingJourneyData?.transaction?.application?.isApplicationDone ||
          bookingJourneyData?.transaction?.allotment?.allotmentDate
        }
        isPaymentCompleted={
          bookingJourneyData?.transaction?.application?.isApplicationDone &&
          bookingJourneyData?.transaction?.allotment?.allotmentDate
            ?.allotmentLetter
        }
      >
        {bookingJourneyData?.transaction?.application && (
          <JourneyBlock
            isMilestoneSuccess={
              bookingJourneyData?.transaction?.application?.isApplicationDone
            }
            milestoneName={"Application"}
            milestoneSubName={
              bookingJourneyData?.transaction?.application?.milestoneName
            }
            isDividerRequired={true}
          />
        )}

        {bookingJourneyData?.transaction?.allotment && (
          <JourneyBlock
            isMilestoneSuccess={
              bookingJourneyData?.transaction?.allotment?.allotmentDate
                ? true
                : false
            }
            milestoneName={"Allotment"}
            milestoneSubName={"Plot Allotted"}
            viewText={
              bookingJourneyData?.transaction?.allotment?.allotmentLetter
                ? "View Allotment Letter"
                : ""
            }
            onViewClick={() =>
              handleViewClick(
                bookingJourneyData?.transaction?.allotment?.allotmentLetter
              )
            }
            isViewReceiptDisabled={
              bookingJourneyData?.transaction?.allotment?.allotmentLetter
                ? false
                : true
            }
          />
        )}
      </BookingJourneySectionComponent>

      <BookingJourneySectionComponent
        titleText={"DOCUMENTATION"}
        isSectionCompleted={
          bookingJourneyData?.documentation?.POA?.isPOAAlloted ||
          bookingJourneyData?.documentation?.AFS?.isAfsVisible ||
          (!bookingJourneyData?.documentation?.POA?.isPOAAlloted &&
            !bookingJourneyData?.documentation?.AFS?.isAfsVisible)
        }
        shouldHideDivider={
          !bookingJourneyData?.documentation?.POA?.isPOAAlloted &&
          !bookingJourneyData?.documentation?.AFS?.isAfsVisible
            ?.isRegistrationScheduled
            ? true
            : false
        }
      >
        {(bookingJourneyData?.documentation?.POA?.isPOAAlloted ||
          bookingJourneyData?.documentation?.AFS?.isAfsVisible) && (
          <JourneyBlock
            isMilestoneSuccess={
              bookingJourneyData?.documentation?.POA?.isPOAAlloted
            }
            milestoneName={"Power Of Attorney"}
            milestoneSubName={
              bookingJourneyData?.documentation?.POA?.isPOARequired
                ? "POA assigned"
                : ""
            }
            viewText={
              bookingJourneyData?.documentation?.POA?.poaLetter?.path
                ? "View Allotment Letter "
                : ""
            }
            onViewClick={() =>
              handleViewClick(
                bookingJourneyData?.documentation?.POA?.poaLetter?.path
              )
            }
            isDividerRequired={
              bookingJourneyData?.documentation?.AFS?.isAfsVisible && true
            }
            isViewReceiptDisabled={
              bookingJourneyData?.documentation?.POA?.poaLetter ? false : true
            }
          />
        )}

        {bookingJourneyData?.documentation?.AFS?.isAfsVisible && (
          <JourneyBlock
            isMilestoneSuccess={
              bookingJourneyData?.documentation?.AFS?.isAfsVisible &&
              bookingJourneyData?.documentation?.AFS?.afsLetter?.path
                ? true
                : false
            }
            milestoneName={"Agreement for sale"}
            milestoneSubName={
              bookingJourneyData?.documentation?.AFS?.afsLetter?.path
                ? "Completed"
                : ""
            }
            viewText={
              bookingJourneyData?.documentation?.AFS?.afsLetter?.path
                ? "View Details"
                : ""
            }
            onViewClick={() =>
              handleViewClick(
                bookingJourneyData?.documentation?.AFS?.afsLetter?.path
              )
            }
            isDividerRequired={
              bookingJourneyData?.documentation?.Registration
                ?.isRegistrationScheduled
                ? true
                : false
            }
            isViewReceiptDisabled={
              bookingJourneyData?.documentation?.AFS?.afsLetter ? false : true
            }
          />
        )}

        {(bookingJourneyData?.documentation?.AFS?.isAfsVisible ||
          bookingJourneyData?.documentation?.isRegistrationScheduled) && (
          <JourneyBlock
            isMilestoneSuccess={
              bookingJourneyData?.documentation?.Registration
                ?.isRegistrationScheduled
            }
            milestoneName={"AFS Registration"}
            milestoneSubName={
              bookingJourneyData?.documentation?.Registration
                ?.isRegistrationScheduled
                ? "Registration Scheduled"
                : ""
            }
            viewText={"View Details"}
            onViewClick={() =>
              handleViewRegistration(
                bookingJourneyData?.documentation?.Registration
              )
            }
            isViewReceiptDisabled={
              bookingJourneyData?.documentation?.Registration?.registrationDate
                ? false
                : true
            }
          />
        )}
      </BookingJourneySectionComponent>

      <BookingJourneySectionComponent
        titleText={"PAYMENTS"}
        onClickViewAllReceipts={() => setShowAllPaymentReceipts(true)}
        isSectionCompleted={
          bookingJourneyData?.payments?.length &&
          bookingJourneyData?.payments?.filter(
            (payment: any) => payment?.isPaymentDone
          )?.length > 0 &&
          true
        }
        shouldHideDivider={
          bookingJourneyData?.payments?.length > 0 ? false : true
        }
        isAnyPaymentCompleted={
          bookingJourneyData?.payments?.length === paymentPendingList?.length
            ? false
            : true
        }
        isViewReceiptDisabled={
          bookingJourneyData?.paymentHistory?.length > 0 &&
          bookingJourneyData?.payments?.length > 0
            ? false
            : true
        }
      >
        {bookingJourneyData?.payments?.map((item: any, index: any) => (
          <JourneyBlock
            isMilestoneSuccess={item?.isPaymentDone}
            milestoneName={item?.paymentMilestone}
            showPaymentDetails={true}
            showPaymentDetailsText={
              item?.isPaymentDone ? "Payment Completed" : "Payment Pending"
            }
            viewText={"View Details"}
            onViewClick={() =>
              handleOpenPaymentPendingDialog(item, item?.isPaymentDone)
            }
            isDividerRequired={
              index === bookingJourneyData?.payments?.length - 1 ? false : true
            }
          />
        ))}
      </BookingJourneySectionComponent>

      <BookingJourneySectionComponent
        titleText={"OWNERSHIP"}
        isSectionCompleted={
          bookingJourneyData?.ownership?.documents?.DOC ||
          bookingJourneyData?.ownership?.documents["712"]
        }
        isDisabled={
          bookingJourneyData?.ownership?.documents?.DOC ||
          bookingJourneyData?.ownership?.documents["712"]
            ? false
            : true
        }
      >
        <JourneyBlock
          isMilestoneSuccess={
            bookingJourneyData?.ownership?.documents?.DOC ||
            bookingJourneyData?.ownership?.documents["712"]
          }
          milestoneName={"Documents"}
          isDividerRequired={true}
          isDisabled={
            bookingJourneyData?.ownership?.documents?.DOC &&
            bookingJourneyData?.ownership?.documents["712"]
              ? false
              : true
          }
        >
          <JourneyBlock
            isMilestoneSuccess={bookingJourneyData?.ownership?.documents?.DOC}
            milestoneName={"Deed of conveyance"}
            viewText={"View"}
            onViewClick={() =>
              handleViewClick(
                bookingJourneyData?.ownership?.documents?.DOC?.path
              )
            }
            isDividerRequired={true}
            childrenDivider={true}
            isDisabled={
              bookingJourneyData?.ownership?.documents?.DOC ? false : true
            }
          />

          <JourneyBlock
            isMilestoneSuccess={bookingJourneyData?.ownership?.documents["712"]}
            milestoneName={"7/12 document"}
            viewText={"View"}
            onViewClick={() =>
              handleViewClick(
                bookingJourneyData?.ownership?.documents["712"]?.path
              )
            }
            isDisabled={
              bookingJourneyData?.ownership?.documents["712"] ? false : true
            }
          />
        </JourneyBlock>
      </BookingJourneySectionComponent>

      <BookingJourneySectionComponent
        titleText={"POSSESSION"}
        isDisabled={
          bookingJourneyData?.possession?.handover?.handoverFlag ? false : true
        }
        isSectionCompleted={
          bookingJourneyData?.possession?.handover?.handoverFlag
        }
      >
        <JourneyBlock
          isMilestoneSuccess={
            bookingJourneyData?.possession?.handover?.handoverFlag
          }
          milestoneName={"Handover"}
          isDividerRequired={true}
          isDisabled={
            bookingJourneyData?.possession?.handover?.handoverFlag
              ? false
              : true
          }
        >
          <JourneyBlock
            isMilestoneSuccess={
              bookingJourneyData?.possession?.handover?.handoverFlag
            }
            milestoneName={"Handover Completed"}
            viewText={"View"}
            onViewClick={() =>
              handleHandOver(bookingJourneyData?.possession?.handover)
            }
            childrenDivider={true}
            isDividerRequired={true}
            isDisabled={
              bookingJourneyData?.possession?.handover?.handoverFlag
                ? false
                : true
            }
          />

          <JourneyBlock
            isMilestoneSuccess={
              bookingJourneyData?.possession?.handover?.handoverFlag &&
              projectById?.customerGuideLines?.value?.url
            }
            milestoneName={"Customer Guidelines"}
            viewText={"View"}
            onViewClick={() =>
              handleViewCustomerGuildline(
                projectById?.customerGuideLines?.value?.url
              )
            }
            isDisabled={
              bookingJourneyData?.possession?.handover?.handoverFlag &&
              projectById?.customerGuideLines?.value?.url
                ? false
                : true
            }
          />
        </JourneyBlock>
      </BookingJourneySectionComponent>

      <BookingJourneySectionComponent
        titleText={"LAND MANAGEMENT"}
        isDisabled={
          bookingJourneyData?.facility?.isFacilityVisible ? false : true
        }
        isSectionCompleted={bookingJourneyData?.facility?.isFacilityVisible}
      >
        <JourneyBlock
          isMilestoneSuccess={bookingJourneyData?.facility?.isFacilityVisible}
          milestoneName={"Manage my Land"}
          isDisabled={
            bookingJourneyData?.facility?.isFacilityVisible ? false : true
          }
        />
      </BookingJourneySectionComponent>
    </Fragment>
  );
};

export default BookingJourney;
