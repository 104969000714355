import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";
import { state } from "utils/States";

const { Types, Creators } = createActions({
  getMarketingDashboardRequest: ["data"],
  getMarketingDashboardSuccess: ["response"],
  getMarketingDashboardFailure: ["error"],

  setMarketingDashboardState: ["key", "value"],
});

export const MarketingDashboardTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  marketingDashboardData: null,
  error: null,
  isLoading: false,
  snackBarError: "",
  isGraphDataFetched: false,
};

export const getMarketingDashboardRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getMarketingDashboardSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      marketingDashboardData: response.data,
      isLoading: false,
      isGraphDataFetched: true,
    };
  }
};

export const getMarketingDashboardFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setMarketingDashboardState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MARKETING_DASHBOARD_FAILURE]: getMarketingDashboardFailure,
  [Types.GET_MARKETING_DASHBOARD_SUCCESS]: getMarketingDashboardSuccess,
  [Types.GET_MARKETING_DASHBOARD_REQUEST]: getMarketingDashboardRequest,

  [Types.SET_MARKETING_DASHBOARD_STATE]: setMarketingDashboardState,
});
