import { Fragment, useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styles from "./App.module.css";
import AppRoutes from "./routes/AppRoutes";
import Layout from "./components/layout/Layout";
import { CssBaseline, ThemeProvider } from "@mui/material";
import OverridedThemes from "mui-themes/ThemeOverrides";
import { useDispatch, useSelector } from "react-redux";
import NoInternetConnection from "components/noInternetConnection/NoInternetConnection";
import { getIpAddress } from "utils/GeneralFunctionalityUtils";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import UsersAction from "redux-container/users/UsersRedux";

const actionDispatch = (dispatch: any) => {
  return {
    setAuditState: (key: any, value: any) =>
      dispatch(AuditActions.setAuditLogState(key, value)),
    getAdminsListForFiltes: () =>
      dispatch(UsersAction.getAdminListForFiltersRequest()),
  };
};

function App() {
  const { setAuditState, getAdminsListForFiltes } = actionDispatch(
    useDispatch()
  );
  /* Scroll to top button code */
  const [isScrollTopVisible, setScrollTopVisible] = useState(false);
  const login = useSelector((state: any) => state?.login);
  const callGetIpAddress = async () => {
    let ipAddress = await getIpAddress();
    setAuditState("ipAddress", ipAddress);
  };

  useEffect(() => {
    if (login?.isLoggedIn) {
      callGetIpAddress();
      getAdminsListForFiltes();
    }
  }, [login?.isLoggedIn]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const toggleVisible = () => {
    const distanceScrolled = document.documentElement.scrollTop;
    const scrollThreshold = 300;
    if (distanceScrolled > scrollThreshold) {
      setScrollTopVisible(true);
    } else if (distanceScrolled <= scrollThreshold) {
      setScrollTopVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Fragment>
      <CssBaseline />
      <div
        className={
          isScrollTopVisible
            ? styles["scroll-top-btn"]
            : styles["hide-scroll-top-btn"]
        }
        onClick={() => scrollToTop()}
        title="Scroll To Top"
      >
        <KeyboardArrowUpIcon sx={{ color: "#fff" }} />
      </div>
      <ThemeProvider theme={OverridedThemes}>
        <NoInternetConnection>
          {/* <Layout> */}
          <AppRoutes />
          {/* </Layout> */}
        </NoInternetConnection>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
