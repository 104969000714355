import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import PageHeading from "components/page-heading/PageHeading";
import React, { Fragment, useEffect, useState } from "react";
import { RouteConstants } from "routes/RouteConstants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./CustomerInvestmentDetails.module.css";
import { FormFieldContainer } from "components/ui-elements";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/ui-elements";
import BookingJourney from "./booking-journey/BookingJourney";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import CustomerPaymentReceipts from "./customer-payment-receipts/CustomerPaymentReceipts";
import { useDispatch, useSelector } from "react-redux";
import { amountFormatter } from "utils/AmountFormatter";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import {
  CustomerActions,
  setCustomersState,
} from "redux-container/customer-management/CustomersListAndDetailsRedux";
import { getBookingStatusMessage } from "enumerations/CustomerBookingStatus";

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerInvestmentDetails: async (params: any) => {
      dispatch(CustomerActions.getCustomerInvestmentDetailsRequest(params));
    },
  };
};

const CustomerInvestmentDetails = () => {
  const breadcrumbRoutes: any = [
    {
      name: "Customer Management",
      route: RouteConstants.customerManagementDashboard,
    },
    {
      name: "Customer List",
      route: RouteConstants.customerList,
    },
  ];

  const { getCustomerInvestmentDetails } = actionDispatch(useDispatch());

  const navigate = useNavigate();
  const location: any = useLocation();

  const cutomerInvestmentSummary = useSelector(
    (state: any) => state?.customerListAndDetails?.customerSummary
  );
  const customerInvestmentDetails = useSelector(
    (state: any) => state?.customerListAndDetails?.customerInvestmentDetails
  );
  const [isInvestmentPaymentDone, setIsInvestmentPaymentDone] = useState(
    location?.state?.investmentDetailsOnAProject?.investment?.isBookingComplete
  );

  const [expandAccordion, setExpandAccordion] = useState(false);
  const [showAllPaymentReceipts, setShowAllPaymentReceipts] = useState(false);

  useEffect(() => {
    const params = {
      cmsId: location?.state?.customerData?.id,
      investmentId:
        location?.state?.investmentDetailsOnAProject?.investment?.id,
      projectId: location?.state?.investmentDetailsOnAProject?.project?.id,
    };
    getCustomerInvestmentDetails(params);
  }, []);

  const navigateBack = () => {
    return navigate(RouteConstants.customerView, {
      state: {
        ...location?.state,
        isNavigationFromSidemenu: false,
        isNavigatedFromList: false,
      },
    });
  };

  return (
    <Fragment>
      <PageHeading title="Customer Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <Container>
        {showAllPaymentReceipts ? (
          <CustomerPaymentReceipts
            setShowAllPaymentReceipts={setShowAllPaymentReceipts}
          />
        ) : (
          <div>
            <Stack justifyContent={"start"} textAlign={"start"} spacing={1}>
              <IconButton edge={"start"} onClick={navigateBack}>
                <ChevronLeftIcon />
              </IconButton>

              <Typography variant={"h6"}>
                {
                  location?.state?.investmentDetailsOnAProject?.project
                    ?.launchName
                }{" "}
                - Investment Details
              </Typography>
            </Stack>

            <Box className={styles["heading-box-container"]}>
              <Typography className={styles["customer-name-heading"]}>
                Status:{" "}
                {customerInvestmentDetails?.investmentInformation?.bookingStatus
                  ? getBookingStatusMessage(
                      customerInvestmentDetails?.investmentInformation
                        ?.bookingStatus
                    )
                  : "-"}
              </Typography>

              <Stack justifyContent={"start"} spacing={1} mt={2}>
                <Typography className={styles["key-typography"]}>
                  Land ID
                </Typography>

                <Typography className={styles["value-typography"]}>
                  {
                    location?.state?.investmentDetailsOnAProject?.investment
                      ?.crmInventory?.name
                  }
                </Typography>
              </Stack>

              <Stack>
                <Stack justifyContent={"start"} spacing={1} mt={2}>
                  <Typography className={styles["key-typography"]}>
                    SKU Type
                  </Typography>

                  <Typography className={styles["value-typography"]}>
                    {
                      location?.state?.investmentDetailsOnAProject?.investment
                        ?.inventoryBucket
                    }
                  </Typography>
                </Stack>

                <Stack justifyContent={"start"} spacing={1} mt={2}>
                  <Typography className={styles["value-typography"]}>
                    Allocation Date
                  </Typography>

                  <Typography className={styles["key-typography"]}>
                    {location?.state?.investmentDetailsOnAProject?.investment
                      ?.allocationDate
                      ? formatDateTime(
                          location?.state?.investmentDetailsOnAProject
                            ?.investment?.allocationDate,
                          Constants.dateFormatDDMMMYY
                        )
                      : "-"}
                  </Typography>

                  <Typography className={styles["value-typography"]}>
                    Possesion Date
                  </Typography>

                  <Typography className={styles["key-typography"]}>
                    {location?.state?.investmentDetailsOnAProject?.investment
                      ?.possesionDate
                      ? formatDateTime(
                          location?.state?.investmentDetailsOnAProject
                            ?.investment?.possesionDate,
                          Constants.dateFormatDDMMMYY
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>
            </Box>

            <FormFieldContainer>
              <Grid container>
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  {isInvestmentPaymentDone ? (
                    <Box style={{ maxWidth: "350px" }}>
                      <Stack
                        justifyContent={"space-between"}
                        paddingX={2}
                        paddingY={1.5}
                      >
                        <div>
                          <Typography variant={"h6"} component={"h6"}>
                            {customerInvestmentDetails?.investmentInformation
                              ?.amountInvested
                              ? `₹ ${amountFormatter(
                                  customerInvestmentDetails
                                    ?.investmentInformation?.amountInvested
                                )}`
                              : "-"}
                          </Typography>
                          <Typography>Invested amount</Typography>
                        </div>

                        <Stack direction={"column"} maxWidth={"50px"}>
                          <Stack>
                            {location?.state?.investmentDetailsOnAProject
                              ?.investment?.projectIea !== "-" ? (
                              <KeyboardDoubleArrowUpIcon color={"primary"} />
                            ) : (
                              ""
                            )}
                            <Typography
                              color={"primary"}
                              fontWeight={700}
                              variant={"h6"}
                              component={"h3"}
                            >
                              {
                                location?.state?.investmentDetailsOnAProject
                                  ?.investment?.projectIea
                              }
                            </Typography>
                          </Stack>
                          <Typography>OEA</Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  ) : (
                    <Box
                      style={{ border: "1px solid gray", borderRadius: "5px" }}
                    >
                      <Stack
                        justifyContent={"space-between"}
                        paddingX={2}
                        paddingY={1.5}
                      >
                        <div>
                          <Typography variant={"h6"} component={"h6"}>
                            ₹
                            {amountFormatter(
                              location?.state?.investmentDetailsOnAProject
                                ?.investment?.amountInvested
                            )}
                          </Typography>
                          <Typography>Investment amount</Typography>
                        </div>

                        <div>
                          <Typography variant={"h6"} component={"h6"}>
                            ₹
                            {amountFormatter(
                              location?.state?.investmentDetailsOnAProject
                                ?.investment?.paidAmount
                            )}
                          </Typography>
                          <Typography>Paid amount</Typography>
                        </div>

                        <div>
                          <Typography variant={"h6"} component={"h6"}>
                            ₹
                            {amountFormatter(
                              location?.state?.investmentDetailsOnAProject
                                ?.investment?.pendingAmount
                            )}
                          </Typography>
                          <Typography>Pending amount</Typography>
                        </div>
                      </Stack>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <Stack justifyContent={"end"} spacing={1}>
                    <Typography
                      sx={{ position: "relative", top: 0 }}
                      className={styles["key-typography"]}
                    >
                      Current Owners:
                    </Typography>

                    <div>
                      {customerInvestmentDetails?.investmentInformation?.owners?.map(
                        (name: any) => (
                          <Typography className={styles["value-typography"]}>
                            {name}
                          </Typography>
                        )
                      )}
                    </div>
                  </Stack>
                </Grid>

                <Grid item xs={7} md={7} lg={7} xl={7} mt={2}>
                  {isInvestmentPaymentDone ? (
                    <Stack
                      justifyContent={"space-between"}
                      marginY="30px"
                      spacing={3}
                    >
                      <div>
                        <Stack
                          width="250px"
                          justifyContent={"start"}
                          spacing={1}
                        >
                          <Typography className={styles["value-typography"]}>
                            Registration Charges paid :
                          </Typography>

                          <Typography className={styles["key-typography"]}>
                            {customerInvestmentDetails?.investmentInformation
                              ?.paymentInfo?.registryCharges?.paid
                              ? `: ₹ ${amountFormatter(
                                  customerInvestmentDetails
                                    ?.investmentInformation?.paymentInfo
                                    ?.registryCharges?.paid
                                )} `
                              : "0"}
                          </Typography>
                        </Stack>
                      </div>

                      <div>
                        <Stack
                          width="250px"
                          justifyContent={"start"}
                          spacing={1}
                        >
                          <Typography className={styles["value-typography"]}>
                            Area :
                          </Typography>

                          <Typography className={styles["key-typography"]}>
                            {customerInvestmentDetails?.investmentInformation
                              ?.crmInventory?.areaSqFt
                              ? `${customerInvestmentDetails?.investmentInformation?.crmInventory?.areaSqFt} Sqft`
                              : "0"}
                          </Typography>
                        </Stack>
                      </div>
                    </Stack>
                  ) : (
                    <Stack
                      justifyContent={"space-between"}
                      marginY="30px"
                      spacing={3}
                    >
                      <div>
                        <Stack
                          justifyContent={"start"}
                          spacing={1}
                          width="250px"
                        >
                          <Typography className={styles["value-typography"]}>
                            Registery Amount
                          </Typography>

                          <Typography className={styles["key-typography"]}>
                            {customerInvestmentDetails?.investmentInformation
                              ?.paymentInfo?.registryCharges?.total
                              ? `₹ ${amountFormatter(
                                  customerInvestmentDetails
                                    ?.investmentInformation?.paymentInfo
                                    ?.registryCharges?.total
                                )} `
                              : "0"}
                          </Typography>
                        </Stack>
                      </div>

                      <div>
                        <Stack
                          justifyContent={"start"}
                          spacing={1}
                          width="250px"
                        >
                          <Typography className={styles["value-typography"]}>
                            Registery Paid :
                          </Typography>

                          <Typography className={styles["key-typography"]}>
                            {customerInvestmentDetails?.investmentInformation
                              ?.paymentInfo?.registryCharges?.paid
                              ? `₹ ${amountFormatter(
                                  customerInvestmentDetails
                                    ?.investmentInformation?.paymentInfo
                                    ?.registryCharges?.paid
                                )} `
                              : "0"}
                          </Typography>
                        </Stack>
                      </div>
                      <div>
                        <Stack
                          width="250px"
                          justifyContent={"start"}
                          spacing={1}
                        >
                          <Typography className={styles["value-typography"]}>
                            Pending :
                          </Typography>

                          <Typography className={styles["key-typography"]}>
                            {customerInvestmentDetails?.investmentInformation
                              ?.paymentInfo?.registryCharges?.pending
                              ? `₹ ${amountFormatter(
                                  customerInvestmentDetails
                                    ?.investmentInformation?.paymentInfo
                                    ?.registryCharges?.pending
                                )} `
                              : "0"}
                          </Typography>
                        </Stack>
                      </div>
                    </Stack>
                  )}

                  <Stack
                    justifyContent={"space-between"}
                    marginY="30px"
                    spacing={3}
                  >
                    <div>
                      <Stack width="250px" justifyContent={"start"} spacing={1}>
                        <Typography className={styles["value-typography"]}>
                          Other Charges :
                        </Typography>

                        <Typography className={styles["key-typography"]}>
                          {customerInvestmentDetails?.investmentInformation
                            ?.paymentInfo?.otherCharges?.total
                            ? `₹ ${amountFormatter(
                                customerInvestmentDetails?.investmentInformation
                                  ?.paymentInfo?.otherCharges?.total
                              )}`
                            : "0"}
                        </Typography>
                      </Stack>
                    </div>
                    <div>
                      <Stack width="250px" justifyContent={"start"} spacing={1}>
                        <Typography className={styles["value-typography"]}>
                          Charges Paid :
                        </Typography>

                        <Typography className={styles["key-typography"]}>
                          {customerInvestmentDetails?.investmentInformation
                            ?.paymentInfo?.otherCharges?.paid
                            ? `₹ ${amountFormatter(
                                customerInvestmentDetails?.investmentInformation
                                  ?.paymentInfo?.otherCharges?.paid
                              )}`
                            : "0"}
                        </Typography>
                      </Stack>
                    </div>
                    <div>
                      <Stack width="350px" justifyContent={"start"} spacing={1}>
                        <Typography className={styles["value-typography"]}>
                          Charges Pending :
                        </Typography>

                        <Typography className={styles["key-typography"]}>
                          {customerInvestmentDetails?.investmentInformation
                            ?.paymentInfo?.otherCharges?.pending
                            ? `₹ ${amountFormatter(
                                customerInvestmentDetails?.investmentInformation
                                  ?.paymentInfo?.otherCharges?.pending
                              )}`
                            : "0"}
                        </Typography>
                      </Stack>
                    </div>
                  </Stack>
                  <div>
                    <Stack width="250px" justifyContent={"start"} spacing={1}>
                      <Typography className={styles["value-typography"]}>
                        Area :
                      </Typography>

                      <Typography className={styles["key-typography"]}>
                        {customerInvestmentDetails?.investmentInformation
                          ?.crmInventory?.areaSqFt
                          ? `${customerInvestmentDetails?.investmentInformation?.crmInventory?.areaSqFt} Sqft`
                          : "0"}
                      </Typography>
                    </Stack>
                  </div>
                </Grid>

                <Grid item xs={5} md={5} lg={5} xl={5} mt={2}>
                  <Stack
                    justifyContent={"end"}
                    alignItems={"end"}
                    flexDirection={"column"}
                  >
                    {customerInvestmentDetails?.projectInformation?.reraDetails?.reraNumbers?.map(
                      (reraNumber: any) => (
                        <Typography>{reraNumber}</Typography>
                      )
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </FormFieldContainer>

            <Accordion
              expanded={expandAccordion}
              onChange={() => setExpandAccordion(!expandAccordion)}
            >
              <AccordionSummary>
                <Typography variant="body1">Booking Journey</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ marginBottom: "20px" }}>
                <BookingJourney
                  setShowAllPaymentReceipts={setShowAllPaymentReceipts}
                  locationState={location?.state}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default CustomerInvestmentDetails;
