import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getTestimonialsSuccess: ["response"],
  getTestimonialsFailure: ["error"],
  getTestimonialsRequest: ["params"],

  addTestimonialSuccess: ["response"],
  addTestimonialFailure: ["error"],
  addTestimonialRequest: ["data"],

  updateTestimonialSuccess: ["response"],
  updateTestimonialFailure: ["error"],
  updateTestimonialRequest: ["data"],

  deleteTestimonialSuccess: ["response"],
  deleteTestimonialFailure: ["error"],
  deleteTestimonialRequest: ["data"],

  changeTestimonialStatusSuccess: ["response"],
  changeTestimonialStatusFailure: ["error"],
  changeTestimonialStatusRequest: ["data"],

  updateTestimonialPrioritySuccess: ["response"],
  updateTestimonialPriorityFailure: ["error"],
  updateTestimonialPriorityRequest: ["data"],

  setTestimonialsState: ["key", "value"],
});

export const TestimonialsTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  testimonials: [],
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  isPriorityUpdated:false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  page:1,
  rowsPerPage:20,
  auditedObject:null
};

/* Get Testimonials */
export const getTestimonialsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getTestimonialsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      testimonials: response.data,
      isLoading: false,
      totalCount: response.totalCount,
    };
  }
};

export const getTestimonialsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Add Testimonials */
export const addTestimonialRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const addTestimonialSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Testimonial added successfully!",
      isLoading: false,
      auditedObject: response?.data
    };
  }
};

export const addTestimonialFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

/* Update Testimonials */
export const updateTestimonialRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const updateTestimonialSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Testimonial updated successfully!",
      isLoading: false,
    };
  }
};

export const updateTestimonialFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

/* Delete Testimonial */
export const deleteTestimonialRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const deleteTestimonialSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Testimonial deleted successfully!",
      isLoading: false,
    };
  }
};

export const deleteTestimonialFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

/* Update Testimonial(s) status */
export const changeTestimonialStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const changeTestimonialStatusSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Testimonial status updated successfully",
      isLoading: false,
    };
  }
};

export const changeTestimonialStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Update Testimonial Priority status */
export const updateTestimonialPriorityRequest = (state: any) => {
  return {
    ...state,
    // isLoading: true,
  };
};

export const updateTestimonialPrioritySuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isPriorityUpdated: true,
      // message: response.message
      //   ? response.message
      //   : "Testimonial status updated successfully",
      isLoading: false,
    };
  }
};

export const updateTestimonialPriorityFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setTestimonialsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TESTIMONIALS_SUCCESS]: getTestimonialsSuccess,
  [Types.GET_TESTIMONIALS_FAILURE]: getTestimonialsFailure,
  [Types.GET_TESTIMONIALS_REQUEST]: getTestimonialsRequest,

  [Types.ADD_TESTIMONIAL_SUCCESS]: addTestimonialSuccess,
  [Types.ADD_TESTIMONIAL_FAILURE]: addTestimonialFailure,
  [Types.ADD_TESTIMONIAL_REQUEST]: addTestimonialRequest,

  [Types.UPDATE_TESTIMONIAL_SUCCESS]: updateTestimonialSuccess,
  [Types.UPDATE_TESTIMONIAL_FAILURE]: updateTestimonialFailure,
  [Types.UPDATE_TESTIMONIAL_REQUEST]: updateTestimonialRequest,

  [Types.DELETE_TESTIMONIAL_SUCCESS]: deleteTestimonialSuccess,
  [Types.DELETE_TESTIMONIAL_FAILURE]: deleteTestimonialFailure,
  [Types.DELETE_TESTIMONIAL_REQUEST]: deleteTestimonialRequest,

  [Types.CHANGE_TESTIMONIAL_STATUS_REQUEST]: changeTestimonialStatusRequest,
  [Types.CHANGE_TESTIMONIAL_STATUS_SUCCESS]: changeTestimonialStatusSuccess,
  [Types.CHANGE_TESTIMONIAL_STATUS_FAILURE]: changeTestimonialStatusFailure,

  [Types.UPDATE_TESTIMONIAL_PRIORITY_SUCCESS]: updateTestimonialPrioritySuccess,
  [Types.UPDATE_TESTIMONIAL_PRIORITY_FAILURE]: updateTestimonialPriorityFailure,
  [Types.UPDATE_TESTIMONIAL_PRIORITY_REQUEST]: updateTestimonialPriorityRequest,

  [Types.SET_TESTIMONIALS_STATE]: setTestimonialsState,
});
