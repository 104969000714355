import { useState } from "react";
import {
  Accordion,
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { ButtonComponent, TextFieldComponent } from "components/ui-elements";
import { FieldArray, getIn, useFormikContext } from "formik";
import { Fragment } from "react";
import {
  IProjectsNewUser,
  projectNewUserInitialValues,
} from "./projectsNewUserInitialValues";
import styles from "./ProjectNewUser.module.css";
import {
  ActionEnumsUtils,
  ActionsEnums,
} from "enumerations/ActionTypesForProjectsNewUser";
import {
  TopicEnums,
  TopicEnumsUtils,
} from "enumerations/TopicEnumsForProjectsNewUser";

export const AccordionWrapper = styled(Accordion)`
  border-radius: 0.4rem;
  padding: 0;
  .MuiAccordionDetails-root {
    padding: 0;
  }
  .MuiAccordion-root,
  .MuiPaper-root {
    border-radius: 0.4rem;
  }
`;

function Categories(props: any) {
  const { setFieldValue, handleChange, handleBlur, touched, errors } =
    useFormikContext<IProjectsNewUser>();
  const actions = ActionEnumsUtils.getActionsEnums();
  const topics = TopicEnumsUtils.getTopicEnums();
  const {
    isViewMode,
    setPreviousValues,
    previousValues,
    setIsCategoryDeleted,
    setModulesAudited,
    modulesAudited,
    categoryCount,
  } = props;

  const actionTypesForTopic: number = 1;
  const actionTypesForNavigation: number = 2;
  const actionTypesForOthers: number = 3;

  return (
    <>
      <Box padding={"1.5rem"}>
        <Typography>Categories</Typography>
      </Box>

      <Divider />

      <FieldArray
        name="categories"
        render={(arrayHelpers) => {
          const { push: formikPush, remove, form } = arrayHelpers;
          const { values, errors, touched } = form;

          return (
            <>
              {values?.categories?.map((category: any, catergoryIndex: any) => (
                <Fragment key={category.id}>
                  <Stack className={styles["field-box"]}>
                    <Typography>Category {catergoryIndex + 1}</Typography>
                    {values?.categories?.length > 1 ? (
                      <IconButton
                        aria-label="delete"
                        data-testid="button-delete"
                        onClick={() => {
                          setIsCategoryDeleted(true);
                          remove(catergoryIndex);
                          let categoryArray = previousValues?.categories;
                          categoryArray.splice(catergoryIndex);

                          setPreviousValues({
                            ...previousValues,
                            categories: categoryArray,
                          });
                          if (categoryCount > values?.categories?.length - 1) {
                            setModulesAudited((prev: any) => [
                              ...prev,
                              {
                                itemAffected: `Category ${
                                  catergoryIndex + 1
                                } Deleted`,
                                newValue: "",
                                oldValue: "",
                              },
                            ]);
                          }
                        }}
                      >
                        <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                      </IconButton>
                    ) : null}
                  </Stack>
                  <Grid
                    container
                    spacing={3}
                    className={styles["section-gutter"]}
                  >
                    <Grid item lg={3} xl={3}>
                      <TextFieldComponent
                        label={"Display Name"}
                        name={`categories[${catergoryIndex}].text`}
                        value={values?.categories[catergoryIndex]?.text}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 100,
                        }}
                        disabled={isViewMode}
                        required
                        fieldhelpertext={
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].text`
                          ) &&
                          getIn(errors, `categories[${catergoryIndex}].text`)
                        }
                        error={Boolean(
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].text`
                          ) &&
                            getIn(errors, `categories[${catergoryIndex}].text`)
                        )}
                      />
                    </Grid>
                    <Grid item lg={3} xl={3}>
                      <TextFieldComponent
                        select
                        label={"Topics"}
                        name={`categories[${catergoryIndex}].action`}
                        value={values?.categories[catergoryIndex]?.action}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(
                            `categories.${catergoryIndex}.actionType`,
                            actionTypesForTopic
                          );

                          if (e.target.value === TopicEnums.Others) {
                            values?.categories[catergoryIndex]?.options.map(
                              (option: any, index: number) => {
                                setFieldValue(
                                  `categories.${catergoryIndex}.options.${index}.text`,
                                  ""
                                );
                                setFieldValue(
                                  `categories.${catergoryIndex}.options.${index}.action`,
                                  0
                                );
                                setFieldValue(
                                  `categories.${catergoryIndex}.optionNumber`,
                                  0
                                );
                              }
                            );
                          }
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        disabled={isViewMode}
                        required
                        fieldhelpertext={
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].action`
                          ) &&
                          getIn(errors, `categories[${catergoryIndex}].action`)
                        }
                        onBlur={handleBlur}
                        error={Boolean(
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].action`
                          ) &&
                            getIn(
                              errors,
                              `categories[${catergoryIndex}].action`
                            )
                        )}
                      >
                        {topics?.map((topic: any) => {
                          return (
                            <MenuItem value={topic?.value} key={topic?.value}>
                              {topic.label}
                            </MenuItem>
                          );
                        })}
                      </TextFieldComponent>
                    </Grid>
                    <Grid item lg={11} xl={11}>
                      <TextFieldComponent
                        label={"Message body"}
                        name={`categories[${catergoryIndex}].message`}
                        value={values?.categories[catergoryIndex]?.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 100,
                        }}
                        disabled={isViewMode}
                        required
                        fieldhelpertext={
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].message`
                          ) &&
                          getIn(errors, `categories[${catergoryIndex}].message`)
                        }
                        error={Boolean(
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].message`
                          ) &&
                            getIn(
                              errors,
                              `categories[${catergoryIndex}].message`
                            )
                        )}
                      />
                    </Grid>

                    <Grid item lg={6} xl={6}>
                      <Grid container spacing={4}>
                        {category?.options?.map((action: any, index: any) => (
                          <Fragment key={action.id}>
                            <Grid item lg={6} xl={6}>
                              <TextFieldComponent
                                label={`Option ${index + 1}`}
                                name={`categories[${catergoryIndex}].options[${index}].text`}
                                value={
                                  values?.categories[catergoryIndex]?.options[
                                    index
                                  ]?.text
                                }
                                onChange={(e: any) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                inputProps={{
                                  maxLength: 100,
                                }}
                                required
                                disabled={
                                  isViewMode
                                    ? isViewMode
                                    : values?.categories[catergoryIndex]
                                        ?.action === TopicEnums.Others
                                    ? true
                                    : false
                                }
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `categories[${catergoryIndex}].options[${index}].text`
                                  ) &&
                                  getIn(
                                    errors,
                                    `categories[${catergoryIndex}].options[${index}].text`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    `categories[${catergoryIndex}].options[${index}].text`
                                  ) &&
                                    getIn(
                                      errors,
                                      `categories[${catergoryIndex}].options[${index}].text`
                                    )
                                )}
                              />
                            </Grid>
                            <Grid item lg={6} xl={6}>
                              <TextFieldComponent
                                select
                                label={`Actions `}
                                name={`categories[${catergoryIndex}].options[${index}].action`}
                                value={
                                  values?.categories[catergoryIndex]?.options[
                                    index
                                  ]?.action
                                }
                                // Generally we are useing Formik's "handleChange","handleBlur" functions to all the text-fields But for all Select-Fields
                                // we use our manual handle change.
                                //since this is Select-field so we manually setting value using formik's function "setFieldValue" .
                                onChange={(e: any) => {
                                  handleChange(e);
                                  setFieldValue(
                                    `categories.${catergoryIndex}.options.${index}.optionNumber`,
                                    0
                                  );
                                  if (
                                    e.target.value ===
                                    ActionsEnums.NavigateToProjectDetails
                                  ) {
                                    setFieldValue(
                                      `categories.${catergoryIndex}.options.${index}.actionType`,
                                      actionTypesForNavigation
                                    );
                                    return;
                                  }
                                  setFieldValue(
                                    `categories.${catergoryIndex}.options.${index}.actionType`,
                                    actionTypesForOthers
                                  );
                                }}
                                onBlur={handleBlur}
                                required
                                disabled={
                                  isViewMode
                                    ? isViewMode
                                    : values?.categories[catergoryIndex]
                                        ?.action === TopicEnums.Others
                                    ? true
                                    : false
                                }
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `categories[${catergoryIndex}].options[${index}].action`
                                  ) &&
                                  getIn(
                                    errors,
                                    `categories[${catergoryIndex}].options[${index}].action`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    `categories[${catergoryIndex}].options[${index}].action`
                                  ) &&
                                    getIn(
                                      errors,
                                      `categories[${catergoryIndex}].options[${index}].action`
                                    )
                                )}
                              >
                                {actions.map((action: any) => (
                                  <MenuItem key={action.c} value={action.value}>
                                    {action.label}
                                  </MenuItem>
                                ))}
                              </TextFieldComponent>
                            </Grid>
                          </Fragment>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Stack padding={"10px"}>
                    {catergoryIndex === values?.categories?.length - 1 &&
                    values?.categories?.length < 3 ? (
                      <ButtonComponent
                        variant="text"
                        onClick={() => {
                          formikPush(projectNewUserInitialValues.categories[0]);
                          let categoryArray = previousValues?.categories;

                          categoryArray.push({
                            text: "",
                            action: null,
                            actionType: 0,
                            optionNumber: 0,
                            message: "",
                            options: [
                              {
                                text: "",
                                action: null,
                                actionType: 0,
                                optionNumber: 0,
                              },
                              {
                                text: "",
                                action: null,
                                actionType: 0,
                                optionNumber: 0,
                              },
                            ],
                            linkedOption: 0,
                          });
                          setPreviousValues({
                            ...previousValues,
                            categories: categoryArray,
                          });

                          if (categoryCount < values?.categories?.length + 1) {
                            setModulesAudited((prev: any) => [
                              ...prev,
                              {
                                itemAffected: `Category ${
                                  catergoryIndex + 1 + 1
                                } Added`,
                                newValue: "",
                                oldValue: "",
                              },
                            ]);
                          }
                        }}
                      >
                        +add more
                      </ButtonComponent>
                    ) : null}
                  </Stack>
                </Fragment>
              ))}
            </>
          );
        }}
      />
    </>
  );
}

export default Categories;
