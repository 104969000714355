import Container from "@mui/material/Container";
import { Fragment } from "react";
import PageHeading from "../../../../components/page-heading/PageHeading";
import styles from "./NudgeCardCreation.module.css";
import FormFieldContainer from "../../../../components/ui-elements/form-field-container/FormFieldContainer";
import { Typography, Box, Grid, Stack, Divider } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import TextFieldComponent from "../../../../components/ui-elements/text-field/TextFieldComponent";
import MediaAssets from "../../../../assets";
import Imageuploaderdropbox from "../../../../components/ui-elements/Imageuploaderdropbox/Imageuploaderdropbox";
import ButtonComponent from "../../../../components/ui-elements/button/ButtonComponent";
import { Form, Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { NudgeCardsCreationConst } from "./NudgeCardCreationConstants";

interface nudgeCardCreation {
  nudgeCreation: {
    title: string;
    subtitle: string;
    image: any;
  }[];
}
let cardIndexCount = 1;

const NudgeCardCreation = () => {
  let navigate = useNavigate();

  const handleCancel = () => {
    const goBack = -1;
    navigate(goBack);
  };

  const initialValues: nudgeCardCreation = {
    nudgeCreation: [
      {
        title: "",
        subtitle: "",
        image: "",
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    nudgeCreation: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Required"),
        subtitle: Yup.string().required("Required"),
        image: Yup.string().required("A file is required"),
      })
    ),
  });

  const onSaveByIndex = (values: any, index: any) => {};

  const handleGlobalSave = (values: any) => {};

  const handleSaveandNew = (values: any) => {};

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleGlobalSave}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <PageHeading title="Marketing Management" />
            <Container className="main-container">
              <Stack>
                <h4>Nudge cards creation</h4>
                <Stack direction={"row"} columnGap={2} data-testid="button">
                  <ButtonComponent onClick={handleCancel}>
                    Cancel
                  </ButtonComponent>
                  <ButtonComponent
                    data-testid="save-and-New"
                    type="reset"
                    disabled={!isValid}
                    onClick={() => handleSaveandNew(values)}
                  >
                    Save and New
                  </ButtonComponent>
                  <ButtonComponent
                    data-testid="form-save-btn"
                    className="btn btn-dark margin-right-15"
                    type="submit"
                  >
                    Save
                  </ButtonComponent>
                </Stack>
              </Stack>

              <FormFieldContainer>
                <FieldArray
                  data-testid="cardIndexCount"
                  name="nudgeCreation"
                  render={(arrayHelpers) => (
                    <div>
                      <ButtonComponent
                        className="btn btn-dark margin-right-15"
                        data-testid="addNudgeCard"
                        onClick={() => {
                          arrayHelpers.push({
                            title: "",
                            subtitle: "",
                            image: "",
                          });
                          cardIndexCount++;
                        }}
                      >
                        + Add New Nudge
                      </ButtonComponent>
                      {values.nudgeCreation.length > 0
                        ? values.nudgeCreation.map((elem, index: any) => (
                            <Fragment>
                              <FormFieldContainer>
                                <Box>
                                  <div
                                    className="heading-action-holder"
                                    key={index}
                                  >
                                    <span
                                      data-testid="cardCount"
                                      className="filter-heading"
                                    >
                                      Card {index + 1}
                                    </span>

                                    <Stack>
                                      <ButtonComponent
                                        className="btn btn-dark margin-right-15"
                                        // type="submit"
                                        value="submit"
                                        onClick={() =>
                                          onSaveByIndex(values, index)
                                        }
                                      >
                                        Save
                                      </ButtonComponent>

                                      {cardIndexCount > 1 && (
                                        <IconButton
                                          aria-label="delete"
                                          // disabled={values?.nudgeCreation?.length > 1 ? false : true}
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            cardIndexCount--;
                                          }}
                                        >
                                          <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                                        </IconButton>
                                      )}
                                    </Stack>
                                  </div>

                                  <Divider className={styles.divider} />

                                  <Grid
                                    container
                                    spacing={2}
                                    className={styles["textfield-container"]}
                                  >
                                    <Grid item xs={3} md={3} lg={3} xl={3}>
                                      <TextFieldComponent
                                        placeholder="Enter"
                                        fieldtitle="Display Title *"
                                        name={`nudgeCreation.${index}.title`}
                                        value={elem.title}
                                        onChange={handleChange}
                                        helpericon={MediaAssets.ic_faq}
                                        inputProps={{
                                          "data-testid": "title",
                                          maxLength:
                                            NudgeCardsCreationConst.TitleCharacterLimit,
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          (touched?.nudgeCreation &&
                                          touched?.nudgeCreation[index] &&
                                          touched?.nudgeCreation[index].title
                                            ? true
                                            : false) &&
                                          (elem.title.length >
                                          NudgeCardsCreationConst.minLength
                                            ? false
                                            : true)
                                        }
                                        fieldhelpertext={
                                          (touched?.nudgeCreation &&
                                          touched?.nudgeCreation[index] &&
                                          touched?.nudgeCreation[index].title
                                            ? "Required"
                                            : "") &&
                                          (elem.title.length >
                                          NudgeCardsCreationConst.minLength
                                            ? ""
                                            : "Required")
                                        }
                                      />
                                    </Grid>

                                    <Grid item xs={5} md={5} lg={5} xl={5}>
                                      <TextFieldComponent
                                        fieldtitle="Sub Title *"
                                        placeholder="Enter"
                                        name={`nudgeCreation.${index}.subtitle`}
                                        value={elem.subtitle}
                                        onChange={handleChange}
                                        helpericon={MediaAssets.ic_faq}
                                        inputProps={{
                                          "data-testid": "subTitle",
                                          maxLength:
                                            NudgeCardsCreationConst.SubTitleCharacterLimit,
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          (touched?.nudgeCreation &&
                                          touched?.nudgeCreation[index] &&
                                          touched?.nudgeCreation[index].subtitle
                                            ? true
                                            : false) &&
                                          (elem.subtitle.length >
                                          NudgeCardsCreationConst.minLength
                                            ? false
                                            : true)
                                        }
                                        fieldhelpertext={
                                          (touched?.nudgeCreation &&
                                          touched?.nudgeCreation[index] &&
                                          touched?.nudgeCreation[index].subtitle
                                            ? "Required"
                                            : "") &&
                                          (elem.subtitle.length >
                                          NudgeCardsCreationConst.minLength
                                            ? ""
                                            : "Required")
                                        }
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={6} md={6} lg={6} xl={5}>
                                    <Grid
                                      className={styles.insertMedia}
                                      columnGap={2}
                                    >
                                      <Typography>Insert Media</Typography>
                                      <img
                                        src={MediaAssets.ic_info}
                                        alt="icon_image"
                                        height="15px"
                                        width="15px"
                                      />
                                    </Grid>

                                    <Grid>
                                      <Imageuploaderdropbox
                                        onDrop={handleChange}
                                        name={`nudgeCreation.${index}.image`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          let imageUrl = URL.createObjectURL(
                                            e.target.files[0]
                                          );
                                          setFieldValue(
                                            `nudgeCreation.${index}.image`,
                                            imageUrl
                                          );
                                        }}
                                        previewimage={elem.image}
                                        cancelimage={() => {
                                          setFieldValue(
                                            `nudgeCreation.${index}.image`,
                                            ""
                                          );
                                        }}
                                        fieldhelpertext={
                                          (touched?.nudgeCreation &&
                                          touched?.nudgeCreation[index] &&
                                          touched?.nudgeCreation[index].image
                                            ? "A File is Required"
                                            : "") &&
                                          (elem.image.length > 0
                                            ? ""
                                            : "A File is Required")
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              </FormFieldContainer>
                            </Fragment>
                          ))
                        : null}
                    </div>
                  )}
                />
              </FormFieldContainer>
            </Container>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default NudgeCardCreation;
