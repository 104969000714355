import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { hoablPage1Action } from "redux-container/page-management/hoabl-page-1/HoablPage1Redux";

import { call, put } from "redux-saga/effects";

export function* getProjectListHoablPage1(api: any, action: any): SagaIterator {
  try {
    const response = yield call(
      api.getProjectHoablPage1ProjectList,
      action.data
    );
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(hoablPage1Action.projectListHoablPageSuccess(response.data));
    } else {
      yield put(hoablPage1Action.projectListHoablPageFailure(response.data));
    }
  } catch (error) {
    yield put(hoablPage1Action.projectListHoablPageFailure(error));
  }
}

export function* getHoablPage1(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getHoablPage1, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(hoablPage1Action.hoablPage1Success(response.data));
    } else {
      yield put(hoablPage1Action.hoablPage1Failure(response.data));
    }
  } catch (error) {
    yield put(hoablPage1Action.hoablPage1Failure(error));
  }
}

export function* createHoablPage1(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.createHoablPage1, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(hoablPage1Action.createHoablPage1Success(response.data));
    } else {
      yield put(hoablPage1Action.createHoablPage1Failure(response.data));
    }
  } catch (error) {
    yield put(hoablPage1Action.createHoablPage1Failure(error));
  }
}
export function* updateHoablPage1(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateHoablPage1, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(hoablPage1Action.updateHoablPage1Success(response.data));
    } else {
      yield put(hoablPage1Action.updateHoablPage1Failure(response.data));
    }
  } catch (error) {
    yield put(hoablPage1Action.updateHoablPage1Failure(error));
  }
}
