import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  userLogsSuccess: ["response"],
  userLogsFailure: ["error"],
  userLogsRequest: ["data"],

  setUserLogsState: ["key", "value"],
});

export const UserLogsTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  userLogs: [],
  snackBarError: "",
  totalCount: Constants.defaultTotalCount,
  page: 1,
  rowsPerPage: 10,
};

/* Get All User Logs  */
export const userLogsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const userLogsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      userLogs: response.data,
      isLoading: false,
      totalCount: response.totalCount,
    };
  }
  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const userLogsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setUserLogsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_LOGS_SUCCESS]: userLogsSuccess,
  [Types.USER_LOGS_FAILURE]: userLogsFailure,
  [Types.USER_LOGS_REQUEST]: userLogsRequest,

  [Types.SET_USER_LOGS_STATE]: setUserLogsState,
});
