import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { call, put } from "redux-saga/effects";
import PromisesListActions from "../../redux-container/promises/PromisesListRedux";

export function* getPromisesList(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPromises, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesListActions.promisesListSuccess(response.data));
    } else {
      yield put(PromisesListActions.promisesListFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesListActions.promisesListFailure(error));
  }
}

export function* getPromisesCopyList(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPromises, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesListActions.promisesListCopySuccess(response.data));
    } else {
      yield put(PromisesListActions.promisesListCopyFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesListActions.promisesListCopyFailure(error));
  }
}

export function* deletepromise(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deletePromise, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesListActions.deletePromiseSuccess(response.data));
    } else {
      yield put(PromisesListActions.deletePromiseFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesListActions.deletePromiseFailure(error));
  }
}

export function* updatePromiseStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updatePromiseStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesListActions.updatePromiseStatusSuccess(response.data));
    } else {
      yield put(PromisesListActions.updatePromiseStatusFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesListActions.updatePromiseStatusFailure(error));
  }
}

export function* promiseStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.promiseStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesListActions.promiseStatusSuccess(response.data));
    } else {
      yield put(PromisesListActions.promiseStatusFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesListActions.promiseStatusFailure(error));
  }
}

export function* updatePromisePriority(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updatePromisePriority, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        PromisesListActions.updatePromisePrioritySuccess(response.data)
      );
    } else {
      yield put(
        PromisesListActions.updatePromisePriorityFailure(response.data)
      );
    }
  } catch (error) {
    yield put(PromisesListActions.updatePromisePriorityFailure(error));
  }
}

export function* bulkDeletePromise(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.bulkDeletePromises, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesListActions.bulkDeletePromiseSuccess(response.data));
    } else {
      yield put(PromisesListActions.bulkDeletePromiseFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesListActions.bulkDeletePromiseFailure(error));
  }
}
