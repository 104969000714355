import { Fragment, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import CachedIcon from "@mui/icons-material/Cached";
import Container from "@mui/material/Container";
import DateAdapter from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MediaAssets from "../../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { InfoComponent, TextFieldComponent } from "components/ui-elements";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  IconButton,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { RoleTypeEnumUtils } from "enumerations/RoleTypeEnum";
import Loader from "components/loader/Loader";
import Retry from "components/retry/Retry";
import AuditedFieldViewDialog from "./AuditedFieldViewDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import {
  AuditStatus,
  AuditToggleValue,
  AuditValueStatus,
  AuditLatestMediaGalleryCategory,
} from "enumerations/AuditStatusTypes";
import { StatusTypeEnum } from "enumerations/timeLineStatusTypeEnum";
import { stripHtml } from "utils/ValidationUtils";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { SortingOrder } from "enumerations/SortingOrderTypeEnum";
import { ActionTypeEnum } from "enumerations/ActionTypeEnum";
import { exportAsExcel } from "utils/ExportFileUtils";

const actionDispatch = (dispatch: any) => {
  return {
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    getAuditLogRequest: (data: any) =>
      dispatch(AuditActions.getAuditLogRequest(data)),
    setAuditLogState: (key: any, value: any) =>
      dispatch(AuditActions.setAuditLogState(key, value)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

export const parseToReadableValue = (value: any, label?: string) => {
  let splitLabels = label && label?.split(" ");
  if (typeof value === "number") {
    return value.toString();
  } else {
    if (typeof value !== "object") {
      if (typeof value === "boolean") {
        if (value === true) {
          if (splitLabels?.includes("Status") && splitLabels?.includes("FAQ")) {
            return "Active";
          } else {
            return AuditToggleValue.ON;
          }
        } else {
          if (splitLabels?.includes("Status") && splitLabels?.includes("FAQ")) {
            return "InActive";
          } else {
            return AuditToggleValue.OFF;
          }
        }
      }
      if (
        (((value &&
          value.toString() === StatusTypeEnum.NOT_STARTED_YET.toString()) ||
          value.toString() ===
            StatusTypeEnum.IN_PROGRESS_PERCENTAGE.toString() ||
          value.toString() === StatusTypeEnum.COMPLETED.toString() ||
          value.toString() === AuditValueStatus.Active.toString() ||
          value.toString() === AuditValueStatus.InActive.toString() ||
          value.toString() ===
            AuditLatestMediaGalleryCategory.Project_Boundary.value ||
          value.toString() ===
            AuditLatestMediaGalleryCategory.Amenities_Club_House.value ||
          value.toString() ===
            AuditLatestMediaGalleryCategory.Internal_Roads.value ||
          value.toString() ===
            AuditLatestMediaGalleryCategory.Master_Design.value ||
          value.toString() ===
            AuditLatestMediaGalleryCategory.Other_infra_Development.value) &&
          label &&
          splitLabels?.includes("Status")) ||
        splitLabels?.includes("status") ||
        splitLabels?.includes("Category") ||
        splitLabels?.includes("category")
      ) {
        switch (value.toString()) {
          case StatusTypeEnum.NOT_STARTED_YET.toString():
            return "Not Started Yet";

          case StatusTypeEnum.IN_PROGRESS_PERCENTAGE.toString():
            return "In Progress";

          case StatusTypeEnum.COMPLETED.toString():
            return "Completed";

          case StatusTypeEnum.COMPLETED.toString():
            return "Completed";

          case AuditValueStatus.Active.toString():
            return "Active";

          case AuditValueStatus.InActive.toString():
            return "InActive";

          case AuditLatestMediaGalleryCategory.Amenities_Club_House.value:
            return AuditLatestMediaGalleryCategory.Amenities_Club_House.label;

          case AuditLatestMediaGalleryCategory.Internal_Roads.value:
            return AuditLatestMediaGalleryCategory.Internal_Roads.label;

          case AuditLatestMediaGalleryCategory.Master_Design.value:
            return AuditLatestMediaGalleryCategory.Master_Design.label;

          case AuditLatestMediaGalleryCategory.Other_infra_Development.value:
            return AuditLatestMediaGalleryCategory.Other_infra_Development
              .label;

          case AuditLatestMediaGalleryCategory.Project_Boundary.value:
            return AuditLatestMediaGalleryCategory.Project_Boundary.label;

          default:
            return false;
        }
      }
    } else {
      return false;
    }
  }
};

const AuditLog = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [updatedTime, setUpdatedTime] = useState(new Date());
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [searchKey, setSearchKey] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [auditedFieldObject, setauditedFieldObject] = useState<any>();
  const [columnSort, setColumnSort] = useState({
    sortingKey: "",
    sortingPriority: "",
    isActiveSortingColumn: "",
  });

  const [selectedAudit, setSelectedAudit] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const [auditTitle, setAuditTitle] = useState(null);
  const {
    postAuditLogRequest,
    getAuditLogRequest,
    rolesAndModulesRequest,
    setAuditLogState,
  } = actionDispatch(useDispatch());

  useEffect(() => {
    rolesAndModulesRequest();

    return () => {};
  }, []);

  const {
    audits,
    page,
    rowsPerPage,
    totalCount,
    message,
    isSuccess,
    shouldOpenFlashDialog,
    modules,
    isLoading,
  } = useSelector((state: any) => {
    return {
      audits: state?.auditLog?.audits,
      shouldOpenFlashDialog: state?.auditLog?.shouldOpenFlashDialog,
      isSuccess: state?.auditLog?.isSuccess,
      message: state?.auditLog?.message,
      totalCount: state?.auditLog?.totalCount,
      page: state?.auditLog?.page,
      rowsPerPage: state?.auditLog?.rowsPerPage,
      isLoading: state?.auditLog?.isLoading,
      modules: state?.rolesAndModules?.module,
    };
  });
  console.log("audits", audits);

  useEffect(() => {
    const params = {
      size: rowsPerPage,
      index: page + 1,
      toDate: toDate,
      fromDate: fromDate,
      moduleId: selectedModule,
      roleId: selectedUserId,
      sortingKey: columnSort.sortingKey,
      sortingPriority: columnSort.sortingPriority,
    };
    getAuditLogRequest(params);
  }, [
    rowsPerPage,
    page,
    selectedModule,
    selectedUserId,
    toDate,
    fromDate,
    columnSort,
  ]);

  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setAuditLogState("page", 0);
      setAuditLogState("rowsPerPage", 10);
      navigate(location.pathname, { replace: true });
    }

    return () => {};
  }, []);

  const roles = RoleTypeEnumUtils.getRoleTypeEnums();

  const breadcrumbRoutes: any = [
    {
      name: "CMS User Management",
      route: RouteConstants.userManagementDashboard,
    },
    {
      name: "Audit Log",
      route: RouteConstants.auditLog,
    },
  ];

  const tableHeadings = [
    { heading: "Date And Time", isSortingColumn: true, sortingKey: "1" },
    { heading: "Role" },
    { heading: "Created By" },
    { heading: "IP Address" },
    { heading: "Modules" },
    { heading: "Item Affected" },
    { heading: "Old Value" },
    { heading: "New Value" },
    { heading: "Status" },
    { heading: "Actions" },
  ];
  const handleChangePage = (event: unknown, newPage: number) => {
    setAuditLogState("page", newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAuditLogState("rowsPerPage", Number(event.target.value));
    setAuditLogState("page", 0);
  };

  const handleUserIdChange = (event: SelectChangeEvent) => {
    setSelectedUserId(event.target.value as string);
    if (page !== 0) {
      setAuditLogState("page", 0);
    }
  };

  const handleModuleChange = (event: SelectChangeEvent) => {
    setSelectedModule(event.target.value as string);

    if (page !== 0) {
      setAuditLogState("page", 0);
    }
  };
  const handleResetFilter = () => {
    setToDate(null);
    setFromDate(null);
    setSelectedModule("");
    setSelectedUserId("");

    if (page !== 0) {
      setAuditLogState("page", 0);
    }
  };
  const handleClearButton = (): boolean => {
    if (
      selectedUserId === "" &&
      selectedModule === "" &&
      toDate === null &&
      fromDate === null
    ) {
      return false;
    }
    return true;
  };

  const edit = (auditedField: any) => () => {
    setauditedFieldObject(auditedField);
    setOpenDialog(true);
  };

  const syncAuditLogs = () => {
    setUpdatedTime(new Date());
    const params = {
      size: rowsPerPage,
      index: page + 1,
      toDate: toDate,
      fromDate: fromDate,
      moduleId: selectedModule,
      roleId: selectedUserId,
    };
    getAuditLogRequest(params);
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_update}
        altText=""
        message="There are no Audit Logs"
      />
    );
  };
  const renderLoader = () => {
    return <Loader />;
  };
  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => {}} />;
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = audits.map((data: any) => data.id);
      setSelectedAudit(newSelecteds);
      return;
    }
    setSelectedAudit([]);
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selectedAudit.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAudit, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAudit.slice(1));
    } else if (selectedIndex === selectedAudit?.length - 1) {
      newSelected = newSelected.concat(selectedAudit.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAudit.slice(0, selectedIndex),
        selectedAudit.slice(selectedIndex + 1)
      );
    }
    setSelectedAudit(newSelected);
  };
  
  // const isSelected = (id: number) => selectedAudit?.indexOf(id) !== -1;
  const isSelected = (id: number) => {
    return selectedAudit.indexOf(id) !== -1;
  };

  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case ActionTypeEnum?.Export:
        handleExportAsExcel();
        break;
    }
  };
  const handleExportAsExcel = () => {
    if (selectedAudit?.length === 0) {
      alert("You have not selected any Audits");
      return;
    }
    let exportingAudits: any = [];
    if (audits?.length) {
      selectedAudit?.map((selectedCustomer: any) => {
        let localSelectedItem = audits.find(
          (item: any) => item.id == selectedCustomer
        );
        exportingAudits.push({
          Date_Time: localSelectedItem?.createdAt
            ? localSelectedItem?.createdAt
            : "----",
          Role: localSelectedItem?.auditedAdmins?.firstName
            ? `${RoleTypeEnumUtils.getEnumText(
                localSelectedItem.auditedAdmins?.roleId
              )}`
            : "----",
          Created_by: localSelectedItem.auditedAdmins?.firstName
            ? `${localSelectedItem.auditedAdmins?.firstName}`
            : "----",
          IP_address: localSelectedItem.ipAddress
            ? localSelectedItem.ipAddress
            : "----",

          Module: localSelectedItem?.module?.name
            ? localSelectedItem?.module?.name
            : "----",
          Item_Affected: localSelectedItem?.modulesAudited?.itemAffected,
          Old_value: localSelectedItem?.modulesAudited?.oldValue,
          New_value: localSelectedItem?.modulesAudited?.newValue,
          Status: localSelectedItem?.status
            ? localSelectedItem?.status === AuditStatus.EDITED
              ? "Edited"
              : localSelectedItem?.status === AuditStatus.CREATED
              ? "Newly Created"
              : localSelectedItem?.status === AuditStatus.DELETED
              ? "Deleted"
              : !localSelectedItem?.modulesAudited?.status && "----"
            : localSelectedItem?.modulesAudited?.status
            ? localSelectedItem?.modulesAudited?.status === AuditStatus.EDITED
              ? "Edited"
              : localSelectedItem?.modulesAudited?.status ===
                AuditStatus.CREATED
              ? "Newly Created"
              : localSelectedItem?.modulesAudited?.status ===
                AuditStatus.DELETED
              ? "Deleted"
              : "----"
            : "---",
        });
      });
    }
    exportAsExcel(exportingAudits, "Audits", "Sheet1");
    setSelectedAudit([]);
  };
  const renderTable = () => {
    const parseToReadableValue = (value: any, label?: string) => {
      if (typeof value === "number") {
        return value.toString();
      } else {
        if (typeof value !== "object") {
          if (typeof value === "boolean") {
            if (value === true) {
              return AuditToggleValue.ON;
            } else {
              return AuditToggleValue.OFF;
            }
          }
          let splitLabels = label && label?.split(" ");
          if (
            (((value &&
              value?.toString() ===
                StatusTypeEnum.NOT_STARTED_YET.toString()) ||
              value?.toString() ===
                StatusTypeEnum.IN_PROGRESS_PERCENTAGE?.toString() ||
              value?.toString() === StatusTypeEnum.COMPLETED?.toString() ||
              value?.toString() === AuditValueStatus.Active?.toString() ||
              value?.toString() === AuditValueStatus.InActive?.toString() ||
              value?.toString() ===
                AuditLatestMediaGalleryCategory?.Project_Boundary.value ||
              value?.toString() ===
                AuditLatestMediaGalleryCategory?.Amenities_Club_House.value ||
              value?.toString() ===
                AuditLatestMediaGalleryCategory?.Internal_Roads.value ||
              value?.toString() ===
                AuditLatestMediaGalleryCategory?.Master_Design.value ||
              value?.toString() ===
                AuditLatestMediaGalleryCategory?.Other_infra_Development
                  .value) &&
              label &&
              splitLabels?.includes("Status")) ||
            splitLabels?.includes("status") ||
            splitLabels?.includes("Category") ||
            splitLabels?.includes("category")
          ) {
            switch (value?.toString()) {
              case StatusTypeEnum.NOT_STARTED_YET?.toString():
                return "Not Started Yet";

              case StatusTypeEnum.IN_PROGRESS_PERCENTAGE?.toString():
                return "In Progress";

              case StatusTypeEnum.COMPLETED?.toString():
                return "Completed";

              case StatusTypeEnum.COMPLETED?.toString():
                return "Completed";

              case AuditValueStatus.Active?.toString():
                return "Active";

              case AuditValueStatus.InActive?.toString():
                return "InActive";

              case AuditLatestMediaGalleryCategory?.Amenities_Club_House?.value:
                return AuditLatestMediaGalleryCategory?.Amenities_Club_House
                  ?.label;
              case AuditLatestMediaGalleryCategory?.Internal_Roads?.value:
                return AuditLatestMediaGalleryCategory?.Internal_Roads?.label;
              case AuditLatestMediaGalleryCategory?.Master_Design?.value:
                return AuditLatestMediaGalleryCategory?.Master_Design?.label;
              case AuditLatestMediaGalleryCategory?.Other_infra_Development
                ?.value:
                return AuditLatestMediaGalleryCategory?.Other_infra_Development
                  ?.label;
              case AuditLatestMediaGalleryCategory?.Project_Boundary?.value:
                return AuditLatestMediaGalleryCategory?.Project_Boundary?.label;

              default:
                return false;
            }
          }
        } else {
          return false;
        }
      }
    };
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading margin-bottom-30">
            Audit Log
            {`(${
              totalCount ? totalCount : audits?.length ? audits?.length : 0
            })`}
          </h4>
          <div>
            <FormControl className="action-dropdown">
              <InputLabel>Actions</InputLabel>
              <Select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
                // IconComponent={() => (
                //   <ExpandMoreRoundedIcon className="dropdown-icon" />
                // )}
              >
                <MenuItem value={1}>{"Export"}</MenuItem>
              </Select>
            </FormControl>
          </div>{" "}
        </div>

        <Grid container spacing={1}>
          <Grid container spacing={2} md={8} lg={8} xl={8}>
            <Grid
              item
              md={1}
              lg={1}
              xl={1}
              className="margin-left-10 filter-heading-holder"
            >
              <span className="filter-heading">Filters:</span>
            </Grid>

            <Grid item md={2.5} lg={2.5} xl={2.5}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedUserId}
                  label="Role"
                  onChange={handleUserIdChange}
                >
                  {roles?.map((roles: any) => {
                    return (
                      <MenuItem value={roles.value} key={roles.value}>
                        {roles.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            <Grid item md={2.7} lg={2.7} xl={2.7}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedModule}
                  label="Modules"
                  onChange={handleModuleChange}
                >
                  {modules?.map((moduleObject: any) => {
                    return (
                      <MenuItem value={moduleObject?.id}>
                        {moduleObject?.name}{" "}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            <Grid item md={2.5} lg={2.5} xl={2.5}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="From Date"
                  value={fromDate}
                  maxDate={toDate === null ? new Date() : toDate}
                  onChange={(newDate) => {
                    setFromDate(newDate);
                    if (page !== 0) {
                      setAuditLogState("page", 0);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              md={2.5}
              lg={2.5}
              xl={2.5}
              mt={Boolean(!fromDate) ? "-1.6rem" : ""}
            >
              {Boolean(!fromDate) && (
                <Stack mb={"0.4rem"} justifyContent={"flex-end"}>
                  <InfoComponent infoContent="Please select from date first" />
                </Stack>
              )}
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  disabled={Boolean(!fromDate)}
                  label="To Date"
                  value={toDate}
                  maxDate={new Date()}
                  minDate={fromDate}
                  onChange={(newDate) => {
                    setToDate(newDate);
                    if (page !== 0) {
                      setAuditLogState("page", 0);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            {toDate === null &&
            fromDate === null &&
            selectedModule === "" &&
            selectedUserId === "" ? null : (
              <Grid
                item
                md={0.5}
                lg={0.5}
                xl={0.5}
                className="filter-heading-holder"
                style={{ position: "relative" }}
              >
                <span
                  onClick={handleResetFilter}
                  className="clear-filter"
                  style={{ position: "absolute", top: 32 }}
                >
                  Clear
                </span>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={0} md={4} lg={4} xl={4}>
            <Grid item md={12} lg={12} xl={12}>
              <div className="time-sync-holder">
                <div className="time-sync-holder margin-right-15">
                  <span className="margin-right-5 updated-on-heading">
                    Updated on:
                  </span>
                  <span>
                    {formatDateTime(updatedTime, Constants.timeFormathhmmssA)}
                  </span>
                </div>

                <Button
                  variant="contained"
                  onClick={syncAuditLogs}
                  // autoFocus
                  disableElevation
                  className="btn btn-sync"
                >
                  <CachedIcon />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 425 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell
                  className="table-header-text"
                  padding="checkbox"
                  style={{ minWidth: 50 }}
                >
                  <Checkbox
                    className="active-checkbox"
                    data-testid="table-head-checkbox"
                    color="primary"
                    indeterminate={
                      selectedAudit?.length > 0 &&
                      selectedAudit?.length < audits?.length
                    }
                    checked={
                      audits?.length > 0 &&
                      selectedAudit?.length === audits?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all audits",
                    }}
                  />
                </TableCell>
                {tableHeadings?.map((item, index) => (
                  <TableCell
                    key={index}
                    className="table-header-text"
                    style={item.isSortingColumn ? { minWidth: 200 } : {}}
                  >
                    {item.heading}
                    {item.isSortingColumn ? (
                      <>
                        {columnSort.sortingPriority ===
                          SortingOrder.Descending &&
                        columnSort.isActiveSortingColumn === item.heading ? (
                          <IconButton
                            onClick={() => {
                              setColumnSort({
                                sortingKey: item.sortingKey,
                                sortingPriority: SortingOrder.Ascending,
                                isActiveSortingColumn: item.heading,
                              });
                              if (page !== 0) {
                                setAuditLogState("page", 0);
                              }
                            }}
                          >
                            <ArrowDropUpIcon
                              fontSize={"large"}
                              style={{
                                position: "relative",
                                // top: "16px",
                                right: "10px",
                                fontSize: "3rem",
                                color:
                                  columnSort.isActiveSortingColumn ===
                                  item.heading
                                    ? "#676ac0"
                                    : "rgb(0 0 0 / 87%)",
                              }}
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setColumnSort({
                                sortingKey: item.sortingKey,
                                sortingPriority: SortingOrder.Descending,
                                isActiveSortingColumn: item.heading,
                              });
                              if (page !== 0) {
                                setAuditLogState("page", 0);
                              }
                            }}
                          >
                            <ArrowDropDownIcon
                              fontSize={"large"}
                              style={{
                                position: "relative",
                                // top: "10px",
                                right: "10px",
                                fontSize: "3rem",
                                color:
                                  columnSort.isActiveSortingColumn ===
                                  item.heading
                                    ? "#676ac0"
                                    : "rgb(0 0 0 / 87%)",
                              }}
                            />
                          </IconButton>
                        )}
                      </>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {audits?.map((log: any, index: number) => {
                console.log("log", log);

                const isItemSelected = isSelected(log?.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <>
                    <TableRow key={log.id} className="table-data-row">
                      <TableCell padding="checkbox" style={{ minWidth: 50 }}>
                        <Checkbox
                          className="active-checkbox"
                          onClick={(event) => handleClick(event, log?.id)}
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 200 }}
                      >
                        {log?.updatedAt ? (
                          <>
                            <div>
                              {formatDateTime(
                                log?.updatedAt,
                                Constants.dateFormatDDmmYYYY
                              )}
                            </div>
                            <div>
                              {formatDateTime(
                                log?.updatedAt,
                                Constants.timeFormathhmmssA
                              )}
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ width: 200 }}
                      >
                        <div>
                          {log.auditedAdmins?.firstName
                            ? `${RoleTypeEnumUtils.getEnumText(
                                log.auditedAdmins?.roleId
                              )}`
                            : "----"}
                        </div>
                        {/* {log.auditedAdmins?.firstName
                          ? `#ID-${log.auditedAdmins?.id}`
                          : "----"} */}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ width: 250 }}
                      >
                        {log.auditedAdmins?.firstName
                          ? `${log.auditedAdmins?.firstName}`
                          : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ width: 200 }}
                      >
                        {log.ipAddress ? log.ipAddress : "----"}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ width: 200 }}
                      >
                        {log?.module?.name ? log?.module?.name : "----"}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ width: 300 }}
                      >
                        {log?.modulesAudited?.itemAffected}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ width: 500 }}
                      >
                        {/* {log?.modulesAudited?.oldValue ?log?.modulesAudited?.oldValue:"----"} */}

                        {typeof log?.modulesAudited?.oldValue !== "object" &&
                        log?.modulesAudited?.oldValue &&
                        parseToReadableValue(
                          log?.modulesAudited?.oldValue,
                          log?.modulesAudited?.itemAffected
                        ) ? (
                          parseToReadableValue(
                            log?.modulesAudited?.oldValue,
                            log?.modulesAudited?.itemAffected
                          )
                        ) : log?.modulesAudited?.oldValue?.toString()?.length >
                          0 ? (
                          <>
                            {log?.modulesAudited?.oldValue?.length > 150 ? (
                              <>
                                {`${log?.modulesAudited?.oldValue.slice(
                                  0,
                                  150
                                )}...`}
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="#2d2d2d"
                                  sx={{
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    // wordBreak: "break-all",
                                    textAlign: "end",
                                    width: "150px",
                                  }}
                                  onClick={edit(log)}
                                >
                                  READ MORE
                                </Typography>
                              </>
                            ) : typeof log?.modulesAudited?.oldValue ===
                                "string" &&
                              log?.modulesAudited?.oldValue?.length > 0 ? (
                              stripHtml(log?.modulesAudited?.oldValue)
                            ) : (
                              parseToReadableValue(
                                log?.modulesAudited?.oldValue,
                                log?.modulesAudited?.itemAffected
                              )
                            )}
                          </>
                        ) : (
                          "----"
                        )}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ width: 500 }}
                      >
                        {/* {log?.modulesAudited?.newValue
                          ? log?.modulesAudited?.newValue
                          : "----"} */}

                        {typeof log?.modulesAudited?.newValue !== "object" &&
                        parseToReadableValue(
                          log?.modulesAudited?.newValue,
                          log?.modulesAudited?.itemAffected
                        ) ? (
                          parseToReadableValue(
                            log?.modulesAudited?.newValue,
                            log?.modulesAudited?.itemAffected
                          )
                        ) : log?.modulesAudited?.newValue?.toString()?.length >
                          0 ? (
                          <>
                            {log?.modulesAudited?.newValue?.length > 150 ? (
                              <>
                                {`${log?.modulesAudited?.newValue?.slice(
                                  0,
                                  150
                                )}...`}
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="#2d2d2d"
                                  sx={{
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    wordBreak: "keep-all",
                                    textAlign: "end",
                                    width: "150px",
                                  }}
                                  onClick={edit(log)}
                                >
                                  READ MORE
                                </Typography>
                              </>
                            ) : typeof log?.modulesAudited?.newValue ===
                                "string" &&
                              log?.modulesAudited?.newValue?.length > 0 ? (
                              stripHtml(log?.modulesAudited?.newValue)
                            ) : (
                              parseToReadableValue(
                                log?.modulesAudited?.newValue,
                                log?.modulesAudited?.itemAffected
                              )
                            )}
                          </>
                        ) : (
                          "----"
                        )}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ width: 200 }}
                      >
                        {log?.status
                          ? log?.status === AuditStatus.EDITED
                            ? "Edited"
                            : log?.status === AuditStatus.CREATED
                            ? "Newly Created"
                            : log?.status === AuditStatus.DELETED
                            ? "Deleted"
                            : !log?.modulesAudited?.status && "----"
                          : log?.modulesAudited?.status
                          ? log?.modulesAudited?.status === AuditStatus.EDITED
                            ? "Edited"
                            : log?.modulesAudited?.status ===
                              AuditStatus.CREATED
                            ? "Newly Created"
                            : log?.modulesAudited?.status ===
                              AuditStatus.DELETED
                            ? "Deleted"
                            : "----"
                          : "---"}
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        {/* <Tooltip title="Edit" placement="top">
                          <IconButton aria-label="edit" onClick={edit(log)}>
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip> */}

                        <Tooltip title="View" placement="top">
                          <IconButton aria-label="view" onClick={edit(log)}>
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!searchKey && audits?.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={totalCount ? totalCount : audits.length ? audits.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };
  const render = () => {
    if (
      (audits?.length > 0 || handleClearButton() || searchKey !== "") &&
      isLoading === false
    ) {
      return renderTable();
    } else if (
      (audits?.length === 0 || audits?.length === undefined) &&
      !handleClearButton() &&
      // searchKey === "" &&
      isLoading === false
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    }
    // else if (isErrorOccured) {
    //   return renderRetry();
    // }
  };

  return (
    <Fragment>
      <PageHeading title="CMS User Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

      <Container className="main-container">{render()}</Container>
      <AuditedFieldViewDialog
        shouldOpen={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        auditedFieldObject={auditedFieldObject}
      />
    </Fragment>
  );
};

export default AuditLog;
