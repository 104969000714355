import React, { Fragment, useEffect, useState } from "react";
import {
  Avatar,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ButtonComponent,
  LoadingButtonComponent,
  FormFieldContainer,
  RadioButtonGroupComponent,
  StyledRadio,
  // SwitchComponent,
} from "../../../../components/ui-elements";
import { Container, Stack } from "@mui/material";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import PageHeading from "components/page-heading/PageHeading";
import { useNavigate, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Form, Formik } from "formik";
import MediaAssets from "assets";
import styles from "./UpdateCreation.module.css";
import TextFieldComponent from "components/ui-elements/text-field/TextFieldComponent";
import {
  updateCreationConstants,
  DescriptionTypeEnum,
  TypeOfUpdate,
} from "./UpdateCreationConstants";
import UpdateCreationAction from "redux-container/marketing-management/updates/UpdateCreationRedux";
import { useDispatch, useSelector } from "react-redux";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import MediaToggleDialog from "./MediaToggleDialog";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { stripHtml } from "utils/ValidationUtils";
import { UpdatesStatusTypeEnum } from "enumerations/StatusTypeEnum";
import { UpdatesInitialValues } from "./updates-constants/UpdatesInitialValues";
import { UpdatesValidationSchema } from "./updates-constants/UpdatesValidations";
import UpdateCreationDetailedInfo from "./UpdateCreationDetailedInfo";
import { UpdatesInitialLabels } from "./UpdatesInitialLabels";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import {
  getUpdateTypeLabels,
  descriptionSelection,
  breadcrumbRoutes,
} from "./updates-constants/UpdatesConstantMethods";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";

const actionDispatch = (dispatch: any) => {
  return {
    addUpdates: (params: any) =>
      dispatch(UpdateCreationAction.addUpdatesRequest(params)),
    addDraftsUpdates: (params: any) =>
      dispatch(UpdateCreationAction.addDraftsUpdatesRequest(params)),
    editUpdates: (params: any) =>
      dispatch(UpdateCreationAction.updateMarketingUpdatesRequest(params)),
    deleteUpdates: (params: any) =>
      dispatch(UpdateCreationAction.deleteUpdatesRequest(params)),
    addMediaUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    updateStatus: (data: any) =>
      dispatch(UpdateCreationAction.updateCreationStatusRequest(data)),
    setUpdatesState: (key: any, value: any) =>
      dispatch(UpdateCreationAction?.setUpdatesState(key, value)),
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const UpdatesCreation = () => {
  const {
    addUpdates,
    editUpdates,
    deleteUpdates,
    updateStatus,
    setUpdatesState,
    postAuditLogRequest,
    rolesAndModulesRequest,
  } = actionDispatch(useDispatch());
  const location: any = useLocation();
  const [isDisabled, setDisabled] = useState<boolean>(
    location?.state?.isDisabled
  );
  const [isEditMode, setIsEditMode] = useState<boolean>(
    location?.state?.data ? true : false
  );
  const [openMediaToggleDialog, setOpenMediaToggleDialog] =
    useState<boolean>(false);
  const [mediaToggleText, setMediaToggleText] = useState<string>("");
  const [mediaToggle, setMediaToggle] = useState<boolean>(false);

  // states for audit logs
  const [modulesAudited, setModulesAudited] = useState<any>([]);
  const [isUpdateDeleted, setIsUpdateDeleted] = useState<boolean>(false);
  const [isUpdateStatusChanged, setIsUpdateStatusChanged] =
    useState<boolean>(false);
  const [displayTitle, setDisplayTitle] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string>("");
  const [updateStatusValue, setUpdateStatusValue] = useState<string>(
    UpdatesStatusTypeEnum.Published
  );

  const { error, message, modules, responseObjectForAudit } = useSelector(
    (state: any) => ({
      error: state.marketingUpdates?.error,
      message: state.marketingUpdates?.message,
      modules: state?.rolesAndModules?.module,
      responseObjectForAudit: state?.marketingUpdates?.responseForNewAudit,
    })
  );
  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  const showFlashMessage = useSelector(
    (state: any) => state?.marketingUpdates?.showFlashMessage
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [openPublishConfirmationDialog, setOpenPublishConfirmationDialog] =
    useState<boolean>(false);

  let locationId = location?.state?.data?.id;
  let individualUpdate = location?.state?.data;

  useEffect(() => {
    rolesAndModulesRequest();
    if (location?.state?.data?.displayTitle) {
      setDisplayTitle(location?.state?.data?.displayTitle);
      setSubTitle(location?.state?.data?.subTitle);
    }
  }, []);

  const handleCloseFlashMessageDialog = (values: any) => {
    setUpdatesState("showFlashMessage", false);
    if (!error) {
      const moduleObject = modules?.find((element: any) => {
        return element?.name === "Marketing Management";
      });

      if (isUpdateDeleted) {
        const postAuditRequestbodyForInternalDelete = {
          ipAddress: ipAddress,
          moduleId: moduleObject?.id,
          route: null,
          auditedObject: null,
          modulesAudited: [
            {
              itemAffected: `Update "${location?.state?.data?.displayTitle}" is Deleted. (ID ${location?.state?.data?.id})`,
              newValue: "",
              oldValue: "",
            },
          ],
          status: "2003",
        };
        postAuditLogRequest(postAuditRequestbodyForInternalDelete);
      } else if (isUpdateStatusChanged) {
        const { status, ...restValues } = values;
        const postAuditRequestbodyForInternalStatusChange = {
          ipAddress: ipAddress,
          moduleId: moduleObject?.id,
          route: location.pathname,
          auditedObject: { data: { ...restValues, status: updateStatusValue } },
          modulesAudited: [
            {
              itemAffected: `Update - status of "${location?.state?.data?.displayTitle}"`,
              newValue: updateStatusValue === "1001" ? "Published" : "InActive",
              oldValue:
                location?.state?.data?.status === "1001"
                  ? "Published"
                  : "InActive",
            },
          ],
          status: "2001",
        };
        postAuditLogRequest(postAuditRequestbodyForInternalStatusChange);
      } else {
        const postAuditRequestbody = {
          ipAddress: ipAddress,
          moduleId: moduleObject?.id,
          route: location.pathname,
          auditedObject: {
            data: location?.state?.data?.id ? values : responseObjectForAudit,
          },
          modulesAudited: modulesAudited,
          status: location?.state?.data?.id ? "2001" : "2002",
        };
        postAuditLogRequest(postAuditRequestbody);
      }

      navigate(-1);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeUpdateStatus = (status: any, updateId: any) => {
    setIsUpdateStatusChanged(true);
    setUpdateStatusValue(status);
    const data = {
      status: status,
      marketingUpdateIds: updateId,
    };

    updateStatus(data);
  };

  const handleDelete = (id: any) => {
    deleteUpdates(id);
    setIsUpdateDeleted(true);
  };

  const HeadingToolTipText = () => {
    return (
      <div className={styles["heading-tooltip-text"]}>
        <p>Update will appear on the app in the following sequence:</p>
        <ol>
          <li>Display Title </li>
          <li>Sub Title (Optional) </li>
          <li>Detailed Info as per the sequence below</li>
        </ol>
      </div>
    );
  };

  const handleDescriptionChange = (event: any, setFieldValue: any) => {
    setFieldValue("formType", event.target.value);
  };

  const [_descriptionError, setDescriptionError] = useState<boolean>(false);
  const [shouldChangeStatus, setShouldChangeStatus] = useState<boolean>(false);

  const handleOpenConfirmationDialog = (isChangingStatus: boolean) => {
    if (isChangingStatus) {
      setShouldChangeStatus(true);
    }
    setOpenConfirmationDialog(true);
  };
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setShouldChangeStatus(false);
  };

  const handleOkConfirmationDialog = () => {
    if (shouldChangeStatus) {
      if (individualUpdate.status === UpdatesStatusTypeEnum.Published) {
        changeUpdateStatus(UpdatesStatusTypeEnum.Inactive, [locationId]);
      } else {
        changeUpdateStatus(UpdatesStatusTypeEnum.Published, [locationId]);
      }
    } else {
      handleDelete(locationId);
    }
    handleCloseConfirmationDialog();
  };

  const handleOnGlobalSave = (values: any, dispatchUpdates: any) => {
    const detailedInfoDetails = [
      {
        media: {
          key: !values?.detailedInfo[0]?.media?.value?.url
            ? ""
            : values?.detailedInfo[0]?.media?.value?.url.split("/")[3],
          name: !values?.detailedInfo[0]?.media?.value?.url
            ? ""
            : values?.detailedInfo[0]?.media?.value?.url.split("/")[3],
          value: {
            url: values?.detailedInfo[0]?.media?.value?.url,
            mediaType: "IMAGE",
          },
        },
        description: values.detailedInfo[0]?.description,
      },
      {
        media: {
          key: !values?.detailedInfo[1]?.media?.value?.url
            ? values?.detailedInfo[1]?.media?.value?.url
            : values?.detailedInfo[1]?.media?.value?.url.split("/")[3],
          name: !values?.detailedInfo[1]?.media?.value?.url
            ? values?.detailedInfo[1]?.media?.value?.url
            : values?.detailedInfo[1]?.media?.value?.url.split("/")[3],
          value: {
            url: values?.detailedInfo[1]?.media?.value?.url,
            mediaType: "IMAGE",
          },
        },
        description: values.detailedInfo[1]?.description,
      },
      {
        media: {
          key: !values?.detailedInfo[2]?.media?.value?.url
            ? values?.detailedInfo[2]?.media?.value?.url
            : values?.detailedInfo[2]?.media?.value?.url.split("/")[3],
          name: !values?.detailedInfo[2]?.media?.value?.url
            ? values?.detailedInfo[2]?.media?.value?.url
            : values?.detailedInfo[2]?.media?.value?.url.split("/")[3],
          value: {
            url: values?.detailedInfo[2]?.media?.value?.url,
            mediaType: "IMAGE",
          },
          mediaType: "IMAGE",
        },
        description: values.detailedInfo[2]?.description,
      },
    ];

    const finalDetailedInfoReqbody = () => {
      const reqBody = detailedInfoDetails?.map((item: any) => {
        if (
          item?.media?.value?.url?.length > 0 &&
          item?.description?.length > 0
        ) {
          return item;
        } else if (
          !item?.media?.value?.url?.length &&
          item?.description?.length > 0
        ) {
          return { description: item?.description };
        } else if (
          item?.media?.value?.url?.length > 0 &&
          !item?.description?.length
        ) {
          return { media: item?.media, description: "<p></p>" };
        } else {
          return { description: "<p></p>" }; //  null
        }
      });

      return reqBody?.filter((item: any) => !!item);
    };

    const detailedData: any = {
      displayTitle: values?.displayTitle,
      subTitle: values?.subTitle,
      formType: values?.formType,
      updateType: values?.updateType,
      shouldDisplayDate: values?.shouldDisplayDate,
      detailedInfo: finalDetailedInfoReqbody(),
    };

    if (values?.displayTitle && values?.updateType) {
      locationId
        ? dispatchUpdates({ data: detailedData, id: locationId })
        : dispatchUpdates({ data: detailedData });
    }

    let auditedLogArray: any = [];
    let previousValues = location?.state?.data
      ? location?.state?.data
      : UpdatesInitialValues;

    // audit logs for updates

    const checkBooleanValues = (value: any) => {
      if (typeof value === "boolean") {
        if (value === true) {
          return "ON";
        } else {
          return "Off";
        }
      } else {
        return value;
      }
    };
    if (isEditMode) {
      Object?.keys(values)?.map((key: any) => {
        if (typeof values[key] === "object") {
          if (Array.isArray(values[key])) {
            values[key]?.map((elementKey: any, elementKeyIndex: number) => {
              if (typeof values[key][elementKeyIndex] === "object") {
                Object.keys(values[key][elementKeyIndex])?.map(
                  (detailedInfo: any, deletailedInfoIndex: any) => {
                    if (
                      typeof values[key][elementKeyIndex][detailedInfo] ===
                      "object"
                    ) {
                      if (
                        UpdatesInitialLabels[key][elementKeyIndex][detailedInfo]
                          ?.value?.url !== undefined &&
                        values[key][elementKeyIndex][detailedInfo]?.value
                          ?.url !==
                          previousValues[key][elementKeyIndex][detailedInfo]
                            ?.value?.url
                      ) {
                        let auditObject = {
                          itemAffected: `${UpdatesInitialLabels[key][elementKeyIndex][detailedInfo]?.value?.url} of "${values?.displayTitle}" Update`,
                          newValue: stripHtml(
                            values[key][elementKeyIndex][detailedInfo]?.value
                              ?.url
                          ),
                          oldValue: stripHtml(
                            previousValues[key][elementKeyIndex][detailedInfo]
                              ?.value?.url !== undefined
                              ? previousValues[key][elementKeyIndex][
                                  detailedInfo
                                ]?.value?.url
                              : ""
                          ),
                        };
                        auditedLogArray.push(auditObject); // pushing data of updates detailed info media
                      }
                    } else {
                      if (
                        UpdatesInitialLabels[key][elementKeyIndex]
                          .description !== undefined &&
                        values[key][elementKeyIndex].description !==
                          previousValues[key][elementKeyIndex].description
                      ) {
                        let auditObject = {
                          itemAffected: `${UpdatesInitialLabels[key][elementKeyIndex].description} of "${values?.displayTitle}" Update`,
                          newValue: stripHtml(
                            values[key][elementKeyIndex].description
                          ),
                          oldValue: stripHtml(
                            previousValues[key][elementKeyIndex].description
                          ),
                        };
                        auditedLogArray.push(auditObject); // pushing data of updates detailed info description
                      }
                    }
                  }
                );
              }
            });
          }
        } else {
          if (
            UpdatesInitialLabels[key] !== undefined &&
            values[key] !== previousValues[key]
          ) {
            if (UpdatesInitialLabels[key] === UpdatesInitialLabels.updateType) {
              // having condition to check updateType, so we can send labels for updateType
              let auditObject = {
                itemAffected: `${UpdatesInitialLabels[key]} of "${values?.displayTitle}" Update`,
                newValue: getUpdateTypeLabels(values[key]),
                oldValue: getUpdateTypeLabels(previousValues[key]),
              };
              auditedLogArray.push(auditObject); // pushing only updateType
            } else {
              let auditObject = {
                itemAffected: `${UpdatesInitialLabels[key]} of "${values?.displayTitle}" Update`,
                newValue: checkBooleanValues(values[key]),
                oldValue: checkBooleanValues(previousValues[key]),
              };
              auditedLogArray.push(auditObject); // pushing all upper level keys of the main object
            }
          }
        }
      });
      setModulesAudited(auditedLogArray);
    } else {
      // else block will execute for newly created update
      setModulesAudited([
        {
          itemAffected: `Created New Update - "${values?.displayTitle}"`,
          newValue: "",
          oldValue: "",
        },
      ]);
    }
  };

  const handleOnClickPublish = (values: any) => {
    if (
      values?.formType !== DescriptionTypeEnum.DetailedDescription &&
      mediaToggle &&
      !values?.detailedInfo[0]?.media?.value?.url
    ) {
      setOpenMediaToggleDialog(true);
      setMediaToggleText(
        "Upload Media Toggle is enabled , please upload a Media file!"
      );
      return;
    }

    if (
      values?.formType === DescriptionTypeEnum.BriefDescription &&
      !mediaToggle &&
      !values?.detailedInfo[0]?.media?.value?.url &&
      !stripHtml(values?.detailedInfo[0]?.description)?.length
    ) {
      setOpenMediaToggleDialog(true);
      setMediaToggleText("Media Or Description is Required!");
      return;
    }

    if (
      values?.formType === DescriptionTypeEnum.DetailedDescription &&
      !values?.detailedInfo[0]?.media?.value?.url &&
      !values?.detailedInfo[1]?.media?.value?.url &&
      !values?.detailedInfo[2]?.media?.value?.url &&
      !stripHtml(values?.detailedInfo[0]?.description)?.length &&
      !stripHtml(values?.detailedInfo[1]?.description)?.length &&
      !stripHtml(values?.detailedInfo[2]?.description)?.length
    ) {
      setOpenMediaToggleDialog(true);
      setMediaToggleText("Any One Media Or Description is Required!");
      return;
    }

    if (
      !values?.detailedInfo[0]?.media?.value?.url &&
      !mediaToggle &&
      !stripHtml(values?.detailedInfo[0]?.description)?.length
    ) {
      setDescriptionError(true);
      return;
    } else if (stripHtml(values?.description1)?.length > 0) {
      setDescriptionError(false);
    }
    setOpenPublishConfirmationDialog(true);
  };

  return (
    <Fragment>
      <Formik
        initialValues={
          location?.state?.data ? location?.state?.data : UpdatesInitialValues
        }
        validationSchema={UpdatesValidationSchema}
        onSubmit={handleOnClickPublish}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          setFieldValue,
          isValid,
          dirty,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <PageHeading title="Marketing Management" />
            <BreadCrumbs
              routes={breadcrumbRoutes(
                values?.displayTitle,
                location?.state?.data?.id,
                isEditMode,
                isDisabled
              )}
            ></BreadCrumbs>
            <FlashMessageDialog
              shouldOpen={showFlashMessage}
              content={error ? error : message}
              isSuccess={message ? true : false}
              cancelHandler={() => handleCloseFlashMessageDialog(values)}
            />
            <MediaToggleDialog
              open={openMediaToggleDialog}
              setOpen={setOpenMediaToggleDialog}
              dialogText={mediaToggleText}
            />
            {/* Confirmation Dialog for Publish */}
            <ConfirmationDialog
              shouldOpen={
                !openConfirmationDialog && openPublishConfirmationDialog
              }
              title="Publish Update"
              content="This action will Publish Update. Are you sure you want to continue?"
              okText="Publish"
              cancelHandler={() => setOpenPublishConfirmationDialog(false)}
              okHandler={() => {
                isEditMode
                  ? handleOnGlobalSave(values, editUpdates)
                  : handleOnGlobalSave(values, addUpdates);
                setOpenPublishConfirmationDialog(false);
              }}
            />

            {/* Confirmation Dialog for Update and Delete */}
            <ConfirmationDialog
              shouldOpen={
                !openPublishConfirmationDialog && openConfirmationDialog
              }
              title={
                shouldChangeStatus
                  ? "Change Status of Update?"
                  : "Delete Update?"
              }
              content={
                shouldChangeStatus
                  ? "This Action will change the status of Update. Are you sure you want to continue?"
                  : "This action will Delete Update. Are you sure you want to continue?"
              }
              okText={shouldChangeStatus ? "Change Status" : "Delete"}
              cancelHandler={handleCloseConfirmationDialog}
              okHandler={handleOkConfirmationDialog}
            />

            <Container className="main-container">
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack spacing={2}>
                  <IconButton edge={"start"} onClick={() => navigate(-1)}>
                    <ChevronLeftIcon />
                  </IconButton>
                  {isEditMode || isDisabled ? (
                    <Typography color={"palette.secondary.dark"} variant="h6">
                      {displayTitle?.length > 30
                        ? `${displayTitle?.slice(0, 20)}....${subTitle?.slice(
                            0,
                            5
                          )}`
                        : displayTitle}
                    </Typography>
                  ) : (
                    <Typography color={"palette.secondary.dark"} variant="h6">
                      Create New
                    </Typography>
                  )}

                  {isEditMode || isDisabled ? (
                    <>
                      <span
                        onClick={isEditMode ? handleClick : () => {}}
                        className={
                          isDisabled && !isEditMode
                            ? `${styles["setting-span"]}`
                            : `${styles["cursor-pointer"]} margin-left-15px align-center`
                        }
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <SettingsOutlinedIcon
                          color={isDisabled ? "disabled" : "inherit"}
                        />
                      </span>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        hideBackdrop
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => handleOpenConfirmationDialog(true)}
                        >
                          {individualUpdate.status ===
                          UpdatesStatusTypeEnum.Published
                            ? "Mark As Inactive"
                            : "Mark As Published"}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleOpenConfirmationDialog(false); // send param as false to ensure this is delete action for dialog
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <Tooltip arrow title={HeadingToolTipText()} placement="top">
                      <Avatar
                        className={styles["info-icon"]}
                        src={MediaAssets.ic_info}
                        variant="rounded"
                      />
                    </Tooltip>
                  )}
                </Stack>

                <Stack direction={"row"} columnGap={2}>
                  {isDisabled === true ? (
                    <LoadingButtonComponent
                      onClick={() => {
                        setDisabled(false);
                        setIsEditMode(true);
                      }}
                    >
                      EDIT
                    </LoadingButtonComponent>
                  ) : (
                    <>
                      <ButtonComponent
                        onClick={() => navigate(-1)}
                        color={"inherit"}
                      >
                        Cancel
                      </ButtonComponent>

                      <LoadingButtonComponent
                        disabled={!isValid || !dirty}
                        type={"submit"}
                      >
                        {isEditMode ? "Publish Changes" : "Publish"}
                      </LoadingButtonComponent>
                    </>
                  )}
                </Stack>
              </Stack>
              {isDisabled && (
                <div className={styles["createdby-section"]}>
                  <Stack justifyContent={"start"} spacing={2}>
                    <Typography
                      className={styles["createdby-detailes"]}
                      variant="body1"
                    >
                      {`Created On : ${individualUpdate?.createdAt.slice(
                        0,
                        10
                      )} by `}
                      <span>
                        {individualUpdate?.marketingUpdateCreatedBy?.firstName}
                      </span>
                    </Typography>
                  </Stack>
                </div>
              )}

              <FormFieldContainer>
                {!isDisabled && !isEditMode && (
                  <RadioButtonGroupComponent
                    name="formType"
                    value={values?.formType}
                    onChange={(event: any) => {
                      handleDescriptionChange(event, setFieldValue);
                    }}
                    data-testid="select-option"
                    className={styles["descrition-radio-btn"]}
                  >
                    {descriptionSelection?.map((role) => {
                      return (
                        <FormControlLabel
                          key={role.id}
                          name={role.value}
                          value={role.value}
                          control={<StyledRadio />}
                          label={role.label}
                          checked={values?.formType === role.value}
                        />
                      );
                    })}
                  </RadioButtonGroupComponent>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={6} lg={6} sm={6}>
                    <TextFieldComponent
                      required
                      disabled={isDisabled}
                      type="text"
                      label="Display Title"
                      placeholder="Enter"
                      name={"displayTitle"}
                      inputProps={{
                        maxLength:
                          updateCreationConstants.displayTitleCharacterLimit,
                      }}
                      onBlur={handleBlur}
                      value={values.displayTitle}
                      onChange={handleChange}
                      error={
                        touched?.displayTitle && !values.displayTitle
                          ? true
                          : false
                      }
                      fieldhelpertext={
                        touched.displayTitle &&
                        !values.displayTitle &&
                        "Required"
                      }
                    />
                  </Grid>

                  <Grid item xs={4} lg={4} sm={4}>
                    <TextFieldComponent
                      disabled={isDisabled}
                      type="text"
                      label="Sub Title"
                      placeholder="Enter"
                      name={"subTitle"}
                      inputProps={{
                        maxLength:
                          updateCreationConstants.subTitleCharacterLimit,
                      }}
                      onBlur={handleBlur}
                      value={values.subTitle}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={3} lg={3} sm={3}>
                    <TextFieldComponent
                      required
                      disabled={isDisabled}
                      select
                      type="text"
                      label="Type of Update"
                      placeholder={"Select"}
                      name={"updateType"}
                      onBlur={handleBlur}
                      value={values.updateType}
                      onChange={handleChange}
                      error={
                        touched?.updateType && values?.updateType === ""
                          ? true
                          : false
                      }
                      fieldhelpertext={
                        touched?.updateType && !values?.updateType && "Required"
                      }
                    >
                      <MenuItem value={TypeOfUpdate.hoablType}>HoABL</MenuItem>
                      <MenuItem value={TypeOfUpdate.projectType}>
                        Project
                      </MenuItem>
                      <MenuItem value={TypeOfUpdate.factualType}>
                        Factual
                      </MenuItem>
                    </TextFieldComponent>
                  </Grid>

                  {/* <Grid item xs={4} lg={4} sm={4}>
                    <Stack spacing={2}>
                      <TextFieldComponent
                        required
                        disabled
                        type="text"
                        label="Date"
                        name={"displayDate"}
                        value={
                          locationId && individualUpdate?.createdAt
                            ? individualUpdate?.createdAt.slice(0, 10)
                            : new Date().toISOString().slice(0, 10)
                        }
                      />
                      <Stack spacing={2}>
                        <SwitchComponent
                          disabled={isDisabled}
                          id={"2"}
                          name={"shouldDisplayDate"}
                          value={values?.shouldDisplayDate}
                          checked={values?.shouldDisplayDate}
                          onChange={handleChange}
                        />
                        <Tooltip
                          arrow
                          title={
                            "Disabling date for current update will disable date for all other updates"
                          }
                          placement="top"
                        >
                          <Avatar
                            className={styles["info-icon"]}
                            src={MediaAssets.ic_info}
                            variant="rounded"
                          />
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Grid> */}
                </Grid>

                <Divider className={`${styles["divider"]}`} />

                <UpdateCreationDetailedInfo
                  isDisabled={isDisabled}
                  setMediaToggle={setMediaToggle}
                  mediaToggle={mediaToggle}
                />
              </FormFieldContainer>
            </Container>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default UpdatesCreation;
