import { styled } from "@mui/material";
import { FormFieldContainer } from "components/ui-elements";

export const HoablNewUserStyleWrapper = styled(FormFieldContainer)`
  .field-container-box {
    padding: 1.5rem 1.5rem 0.5rem;
    column-gap: 1.2rem;
  }
  .section-gutter {
    padding: 1.5rem;
  }
`;
