import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";
import { AllProjectFaqsAction } from "redux-container/project-management/ProjectAllFaqsRedux";


// all Faqs
export function* allProjectFaqs(api: any, action: any): any {
    try {
        const response = yield call(api.allProjectFaqs, action.data);

        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(AllProjectFaqsAction.allFaqsProjectSuccess(response.data));
        } else {
            yield put(AllProjectFaqsAction.allFaqsProjectFailure(response.data));
        }
    } catch (error) {
        yield put(AllProjectFaqsAction.allFaqsProjectFailure(error));
    }
}