import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import MarketingDashboardActions from "redux-container/marketing-management/MarketingDashboardRedux";

export function* getMarketingDashboard(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getMarketingDashboard, action.data);
    yield put(
      MarketingDashboardActions.getMarketingDashboardSuccess(response.data)
    );
  } catch (error) {
    yield put(MarketingDashboardActions.getMarketingDashboardFailure(error));
  }
}
