import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { PromisesActions } from "redux-container/promises/PromisesCreationRedux";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

export function* postPromises(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.postPromises, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesActions.promisesCreationSuccess(response.data));
    } else {
      yield put(PromisesActions.promisesCreationFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesActions.promisesCreationFailure(error));
  }
}

export function* updatePromises(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updatePromises, action.id, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesActions.promisesUpdateSuccess(response.data));
    } else {
      yield put(PromisesActions.promisesUpdateFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesActions.promisesUpdateFailure(error));
  }
}

export function* updatePromisesDraft(api: any, action: any): SagaIterator {
  try {
    const response = yield call(
      api.updatePromisesDraft,
      action.id,
      action.data
    );
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesActions.promisesUpdateDraftSuccess(response.data));
    } else {
      yield put(PromisesActions.promisesUpdateDraftFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesActions.promisesUpdateDraftFailure(error));
  }
}

export function* postPromisesDraft(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.postPromisesDraft, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesActions.promisesDraftSuccess(response.data));
    } else {
      yield put(PromisesActions.promisesDraftFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesActions.promisesDraftFailure(error));
  }
}

export function* changePromisesStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updatePromisesStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesActions.promisesStatusSuccess(response.data));
    } else {
      yield put(PromisesActions.promisesStatusFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesActions.promisesStatusFailure(error));
  }
}

export function* removePromises(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.removePromises, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesActions.removePromisesSuccess(response.data));
    } else {
      yield put(PromisesActions.removePromisesFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesActions.removePromisesFailure(error));
  }
}

export function* getPromises(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAllPromises, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PromisesActions.getAllPromisesSuccess(response.data));
    } else {
      yield put(PromisesActions.getAllPromisesFailure(response.data));
    }
  } catch (error) {
    yield put(PromisesActions.getAllPromisesFailure(error));
  }
}
