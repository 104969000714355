import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { ForgotPasswordAction } from "../../redux-container/forgot-password/ForgotPasswordRedux"


export function* forgotPasswordEmailSent(api: any, action: any): any {
    try {
        const response = yield call(api.forgotPassword, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ForgotPasswordAction.forgotPasswordEmailSentSuccess(response.data));
        } else {
            yield put(ForgotPasswordAction.forgotPasswordEmailSentFailure(response.data));
        }
    } catch (error) {
        yield put(ForgotPasswordAction.forgotPasswordEmailSentFailure(error));
    }
}