import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { Stack, Typography, Container, Collapse } from "@mui/material";
import { RouteConstants } from "routes/RouteConstants";
import {
  Page4PortfolioContentSections,
  portfolioInitialValues,
  portfolioLabels,
  portfolioValidationSchema,
} from "./Page4PortfolioSections";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonComponent,
  LoadingButtonComponent,
} from "components/ui-elements";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { portfolioPageAction } from "redux-container/page-management/PagePortfolioRedux";
import { useEffect, useState } from "react";
import { PageManagementModules } from "enumerations/PageManagementEnum";
import { projectContentsAction } from "redux-container/page-management/ProjectContentsRedux";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import PageHeading from "components/page-heading/PageHeading";
import Loader from "components/loader/Loader";
import { Delayed } from "utils/DelayRender";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { StatusTypeEnum } from "enumerations/StatusTypeEnum";

const breadcrumbRoutes: any = [
  {
    name: "Page Management",
    route: RouteConstants.pageManagementDashboard,
  },
  {
    name: "Page 4 - Portfolio",
    route: RouteConstants.page4Portfolio,
  },
];

const actionDispatch = (dispatch: any) => {
  return {
    addPortfolioPageRequest: (data: any) =>
      dispatch(portfolioPageAction.addPortfolioPageRequest(data)),
    updatePortfolioPageDetails: (data: any) =>
      dispatch(portfolioPageAction.updatePortfolioPageRequest(data)),
    getPageDetails: (page: string) =>
      dispatch(portfolioPageAction.getPortfolioPageRequest(page)),
    getProjectContentsData: (data: any) =>
      dispatch(projectContentsAction.getProjectContentsRequest(data)),
    setPortfolioPageState: (key: string, value: any) =>
      dispatch(portfolioPageAction.setPortfolioPageState(key, value)),
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const Page4Portfolio = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const {
    isLoading,
    isSuccess,
    successResponse,
    isDialogOpen,
    error,
    fetchPortfolioData,
    confirmDialogOpen,
    isViewMode,
  } = useSelector((state: any) => state?.pagePortfolio);
  const {
    getPageDetails,
    updatePortfolioPageDetails,
    addPortfolioPageRequest,
    getProjectContentsData,
    setPortfolioPageState,
    postAuditLogRequest,
    rolesAndModulesRequest,
  } = actionDispatch(useDispatch());
  const [expanded, setExpanded] = useState<string | false>("");

  const modules = useSelector((state: any) => state?.rolesAndModules?.module);
  const projectContentsData = useSelector(
    (state: any) => state.projectContents?.projectContentsData
  );
  const [previousStates, setPreviousStates] = useState<any>(
    fetchPortfolioData?.page
  );
  const [finalValues, setfinalValues] = useState<any>();

  const [modulesAudited, setModulesAudited] = useState<any>(null);
  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  useEffect(() => {
    setPreviousStates(fetchPortfolioData?.page);
  }, [fetchPortfolioData?.page]);

  useEffect(() => {
    rolesAndModulesRequest();
    return () => {};
  }, []);

  const auditedFieldsSetter = (FormikObject: any) => {
    let values: any = {
      facilityManagement: FormikObject?.facilityManagement,
      promotionAndOffersMedia: FormikObject?.promotionAndOffersMedia,
      pageName: FormikObject?.pageName,
      subHeading: FormikObject?.subHeading,
      promotionAndOffersProjectContentId:
        FormikObject?.promotionAndOffersProjectContentId,
      isPromotionAndOfferActive: FormikObject?.isPromotionAndOfferActive,
      isFacilityManagementActive: FormikObject?.isFacilityManagementActive,
    };

    let auditObjectsArray: any[] = [];

    Object?.keys(values)?.map((key: any) => {
      if (values[key] && typeof values[key] == "object") {
        Object?.keys(values[key])?.map((ObjectKey: any) => {
          if (typeof values[key][ObjectKey] == "object") {
            Object?.keys(values[key][ObjectKey])?.map((mediaObjectKey: any) => {
              if (typeof values[key][ObjectKey][mediaObjectKey] == "object") {
              } else {
                if (
                  portfolioLabels[key][ObjectKey][mediaObjectKey] !==
                    undefined &&
                  values[key][ObjectKey][mediaObjectKey] !==
                    previousStates[key][ObjectKey][mediaObjectKey]
                ) {
                  let auditObject = {
                    itemAffected:
                      portfolioLabels[key][ObjectKey][mediaObjectKey],
                    newValue: values[key][ObjectKey][mediaObjectKey],
                    oldValue: previousStates[key][ObjectKey][mediaObjectKey],
                  };
                  auditObjectsArray.push(auditObject);
                }
              }
            });
          } else {
            if (
              portfolioLabels[key][ObjectKey] !== undefined &&
              values[key][ObjectKey] !== previousStates[key][ObjectKey]
            ) {
              let auditObject = {
                itemAffected: portfolioLabels[key][ObjectKey],
                newValue: values[key][ObjectKey],
                oldValue: previousStates[key][ObjectKey],
              };
              auditObjectsArray.push(auditObject);
            }
          }
        });
      } else {
        if (
          portfolioLabels[key] !== undefined &&
          // previousStates[key] && values[key] &&
          values[key] !== previousStates[key] &&
          key !== "promotionAndOffersProjectContentId"
        ) {
          let auditObject = {
            itemAffected: `${portfolioLabels[key]}`,
            newValue:
              key === "isPromotionAndOfferActive" ||
              key === "isFacilityManagementActive"
                ? values[key]
                  ? "ON"
                  : "Off"
                : values[key],
            oldValue:
              key === "isPromotionAndOfferActive" ||
              key === "isFacilityManagementActive"
                ? previousStates[key]
                  ? "ON"
                  : "Off"
                : previousStates[key],
          };
          auditObjectsArray.push(auditObject);
        }
        if (
          portfolioLabels[key] !== undefined &&
          key === "promotionAndOffersProjectContentId" &&
          values[key] !== previousStates[key]
        ) {
          let auditObject = {
            itemAffected: `${portfolioLabels[key]}`,
            newValue: projectContentsData?.find(
              (project: any) => project?.id === values[key]
            )?.launchName,
            oldValue: projectContentsData?.find(
              (project: any) => project?.id === previousStates[key]
            )?.launchName,
          };
          auditObjectsArray.push(auditObject);
        }
      }
    });

    setModulesAudited(auditObjectsArray);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleCloseFlashMessageDialog = () => {
    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Pages Management";
    });
    if (isSuccess) {
      if (modulesAudited?.length) {
        const postAuditRequestbody = {
          ipAddress: ipAddress,
          moduleId: moduleObject?.id,
          modulesAudited: modulesAudited,
          status: AuditStatus.EDITED,
          auditedObject: finalValues,
          route: location.pathname,
        };
        postAuditLogRequest(postAuditRequestbody);
      }
    }

    setPortfolioPageState("error", null);
    setPortfolioPageState("confirmDialogOpen", false);
    setPortfolioPageState("isDialogOpen", false);
    getPageDetails(PageManagementModules.PORTFOLIO);
    navigate(RouteConstants.pageManagementDashboard);
  };

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleDeleteOkClick = (formValues: FormikValues) => {
    if (formValues.isPublished) {
      updatePortfolioPageDetails(formValues);
    } else {
      const newFormValues = {
        ...formValues,
        isPublished: true,
      };
      addPortfolioPageRequest(newFormValues);
    }
    setPortfolioPageState("confirmDialogOpen", false);
    setPortfolioPageState("isDialogOpen", true);
  };

  const handleEditClick = () => {
    setPortfolioPageState("isViewMode", false);
  };

  const handleDialogClose = () => {
    setPortfolioPageState("confirmDialogOpen", false);
    setPortfolioPageState("error", null);
  };

  const handleSubmit = (
    formValues: FormikValues,
    { setSubmitting }: FormikHelpers<typeof portfolioInitialValues>
  ) => {
    auditedFieldsSetter(formValues);
    setPortfolioPageState("confirmDialogOpen", true);
    setSubmitting(false);
  };

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    let cancelEffect = true;
    if (cancelEffect) {
      let params = {
        shouldValidateAdmin:
          loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
      };
      setPortfolioPageState("isViewMode", location?.state?.isViewMode);
      getPageDetails(PageManagementModules.PORTFOLIO);
      getProjectContentsData({
        shouldValidateAdmin:
          loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
        byPrority: true,
        status: StatusTypeEnum.Active,
      });
    }
    return () => {
      cancelEffect = false;
    };
  }, []);

  return (
    <>
      <PageHeading title="Page Mangement" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <Delayed>
        <Formik
          initialValues={
            fetchPortfolioData !== null
              ? fetchPortfolioData?.page
              : portfolioInitialValues
          }
          validationSchema={portfolioValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, isValid, dirty }) => (
            <Form>
              {isLoading ? (
                <Loader />
              ) : (
                <Container className="main-container">
                  <Stack>
                    <Typography
                      style={{ fontSize: "1.5rem" }}
                      variant="h5"
                      component={"h5"}
                      gutterBottom
                    >
                      Page 4 - Portfolio
                    </Typography>
                    <Stack columnGap={"0.5rem"}>
                      <Collapse in={!isViewMode}>
                        <ButtonComponent onClick={handleCancel}>
                          Cancel
                        </ButtonComponent>
                      </Collapse>
                      <Collapse in={!isViewMode} orientation={"horizontal"}>
                        <LoadingButtonComponent
                          type="submit"
                          loading={isLoading}
                          disabled={
                            !isValid &&
                            values.isPromotionAndOfferActive &&
                            !values?.promotionAndOffersProjectContentId
                          }
                        >
                          {values.isPublished ? "Publish Changes" : "Publish"}
                        </LoadingButtonComponent>
                      </Collapse>
                      <Collapse in={isViewMode} orientation={"horizontal"}>
                        <ButtonComponent
                          variant="contained"
                          type="button"
                          onClick={handleEditClick}
                        >
                          {"Edit"}
                        </ButtonComponent>
                      </Collapse>
                    </Stack>
                  </Stack>
                  {Page4PortfolioContentSections.map(
                    (content: any, index: any) => (
                      <Accordion
                        key={index}
                        expanded={expanded === `panel-${content.id}`}
                        onChange={handleAccordionChange(`panel-${content.id}`)}
                      >
                        <AccordionSummary
                          aria-controls={`panel-${content.id}-content`}
                          id={`panel-${content.id}-header`}
                        >
                          <Typography variant="body1">
                            {content.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{content.component}</AccordionDetails>
                      </Accordion>
                    )
                  )}
                </Container>
              )}
              {!isLoading && (
                <FlashMessageDialog
                  shouldOpen={isDialogOpen}
                  content={successResponse ? successResponse : error}
                  isSuccess={isSuccess}
                  cancelHandler={handleCloseFlashMessageDialog}
                />
              )}
              <ConfirmationDialog
                shouldOpen={confirmDialogOpen}
                content="Are you sure you want to Publish?"
                okText="Publish"
                okHandler={() => handleDeleteOkClick(values)}
                cancelHandler={handleDialogClose}
              />
            </Form>
          )}
        </Formik>
      </Delayed>
    </>
  );
};

export default Page4Portfolio;
