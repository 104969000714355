import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import PromiseActions from "redux-container/promises/PromiseRedux";

export function* getPromiseDashboard(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPromiseDashboard, action.data);
    yield put(PromiseActions.getPromiseDashboardSuccess(response.data));
  } catch (error) {
    yield put(PromiseActions.getPromiseDashboardFailure(error));
  }
}
