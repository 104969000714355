import { Box, Divider, Grid, MenuItem, Stack, Typography } from "@mui/material";
import {
  CustomDatePicker,
  InfoComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { TimeLineStatusTypeEnumUtils } from "enumerations/timeLineStatusTypeEnum";
import { useFormikContext, getIn } from "formik";
import { ChangeEvent, Fragment } from "react";
import { useSelector } from "react-redux";
import {
  handleNumberFieldChange,
  shouldNumberOnly,
} from "utils/ValidationUtils";
import {
  plotDeliverySubHeadings,
  statusOptions,
} from "../ProjectTimelineInitialData";

const PlotDelivery = () => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } =
    useFormikContext<any>();
  const plotDeliverySectionIndex = 3;

  const PlotDeliveryStatusOption =
    TimeLineStatusTypeEnumUtils.getTimeLineStatusTypeEnums();

  const handleDatePickerChange = (name: string) => (newValue: string) => {
    setFieldValue(name, newValue);
  };
  const { isViewMode } = useSelector((state: any) => ({
    isViewMode: state?.projectContentManagement?.isViewMode,
  }));

  const handleNumberFieldChange =
    (setFieldValue: any) => (event: ChangeEvent<HTMLInputElement>) => {
      if (
        shouldNumberOnly(event.target.value) &&
        event.target.value.length < 3
      ) {
        setFieldValue(`${event.target.name}`, Number(event.target.value));
      }
    };
  return (
    <Fragment>
      <Box className="section-gutter">
        <Grid container>
          <Grid item lg={3} xl={3}>
            <TextFieldComponent
              label="Section Heading*"
              inputProps={{ maxLength: 40 }}
              name={`projectTimelines[${plotDeliverySectionIndex}].timeLineSectionHeading`}
              value={
                values?.projectTimelines[plotDeliverySectionIndex]
                  ?.timeLineSectionHeading
              }
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(
                getIn(
                  touched,
                  `projectTimelines[${plotDeliverySectionIndex}].timeLineSectionHeading`
                ) &&
                  getIn(
                    errors,
                    `projectTimelines[${plotDeliverySectionIndex}].timeLineSectionHeading`
                  )
              )}
              fieldhelpertext={
                getIn(
                  touched,
                  `projectTimelines[${plotDeliverySectionIndex}].timeLineSectionHeading`
                ) &&
                getIn(
                  errors,
                  `projectTimelines[${plotDeliverySectionIndex}].timeLineSectionHeading`
                )
              }
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
      </Box>
      {values?.projectTimelines[plotDeliverySectionIndex]?.timeLines?.map(
        (timeline: any, timelineIndex: number) => (
          <Fragment key={timelineIndex + 1}>
            <Divider />
            <Fragment key={timelineIndex + 1}>
              <Stack className="field-container-box">
                <Typography>
                  4.{timelineIndex + 1}.
                  {plotDeliverySubHeadings[timelineIndex].title}
                </Typography>
                <SwitchComponent
                  name={`projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].isSectionActive`}
                  onChange={handleChange}
                  disabled={isViewMode}
                  checked={
                    values?.projectTimelines[plotDeliverySectionIndex]
                      ?.timeLines[timelineIndex]?.isSectionActive
                  }
                />
                <InfoComponent />
              </Stack>
              <Box className="section-gutter">
                <Grid container columnSpacing={5} rowSpacing={3}>
                  <Grid item lg={3} xl={3}>
                    <TextFieldComponent
                      label="Display Name*"
                      inputProps={{ maxLength: 30 }}
                      onChange={handleChange}
                      value={
                        values?.projectTimelines[plotDeliverySectionIndex]
                          ?.timeLines[timelineIndex]?.values?.displayName
                      }
                      name={`projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.displayName`}
                      onBlur={handleBlur}
                      error={Boolean(
                        getIn(
                          touched,
                          `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.displayName`
                        ) &&
                          getIn(
                            errors,
                            `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.displayName`
                          )
                      )}
                      fieldhelpertext={
                        getIn(
                          touched,
                          `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.displayName`
                        ) &&
                        getIn(
                          errors,
                          `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.displayName`
                        )
                      }
                      disabled={isViewMode}
                    />
                  </Grid>
                  <Grid item lg={3} xl={3}>
                    <CustomDatePicker
                      label={"Date of Completion"}
                      placeholder={"Select Date"}
                      name={`projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.dateOfCompletion`}
                      value={
                        new Date(
                          values?.projectTimelines[
                            plotDeliverySectionIndex
                          ]?.timeLines[timelineIndex]?.values?.dateOfCompletion
                        )
                      }
                      onChange={handleDatePickerChange(
                        `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.dateOfCompletion`
                      )}
                      disabled={isViewMode}
                    />
                  </Grid>
                  <Grid item lg={3} xl={3}>
                    <TextFieldComponent
                      select
                      label="Status *"
                      onChange={(e: any) => {
                        handleChange(e);
                        if (e.target.value === "6003") {
                          setFieldValue(
                            `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.percentage`,
                            100
                          );
                        } else {
                          if (
                            values?.projectTimelines[plotDeliverySectionIndex]
                              .timeLines[timelineIndex].values.percentage ===
                            100
                          ) {
                            setFieldValue(
                              `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.percentage`,
                              0
                            );
                          }
                        }
                      }}
                      value={
                        values?.projectTimelines[plotDeliverySectionIndex]
                          ?.timeLines[timelineIndex]?.values?.status
                      }
                      name={`projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.status`}
                      onBlur={handleBlur}
                      error={Boolean(
                        getIn(
                          touched,
                          `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.status`
                        ) &&
                          getIn(
                            errors,
                            `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.status`
                          )
                      )}
                      fieldhelpertext={
                        getIn(
                          touched,
                          `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.status`
                        ) &&
                        getIn(
                          errors,
                          `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.status`
                        )
                      }
                      disabled={isViewMode}
                    >
                      {PlotDeliveryStatusOption.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </TextFieldComponent>
                  </Grid>
                  {values?.projectTimelines[plotDeliverySectionIndex]
                    ?.timeLines[timelineIndex]?.values?.status === "6002" && (
                    <Grid item lg={2} xl={2}>
                      <TextFieldComponent
                        label="% Value*"
                        onChange={handleNumberFieldChange(setFieldValue)}
                        value={
                          values?.projectTimelines[plotDeliverySectionIndex]
                            ?.timeLines[timelineIndex]?.values?.percentage
                        }
                        name={`projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.percentage`}
                        onBlur={handleBlur}
                        error={Boolean(
                          getIn(
                            touched,
                            `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.percentage`
                          ) &&
                            getIn(
                              errors,
                              `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.percentage`
                            )
                        )}
                        fieldhelpertext={
                          getIn(
                            touched,
                            `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.percentage`
                          ) &&
                          getIn(
                            errors,
                            `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.percentage`
                          )
                        }
                        disabled={isViewMode}
                      />
                    </Grid>
                  )}
                  <Grid item lg={12} xl={12}>
                    <TextFieldComponent
                      label="Tool tip Details *"
                      inputProps={{ maxLength: 240 }}
                      name={`projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`}
                      onChange={handleChange}
                      value={
                        values?.projectTimelines[plotDeliverySectionIndex]
                          ?.timeLines[timelineIndex]?.values?.toolTipDetails
                      }
                      onBlur={handleBlur}
                      error={Boolean(
                        getIn(
                          touched,
                          `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                        ) &&
                          getIn(
                            errors,
                            `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                          )
                      )}
                      fieldhelpertext={
                        getIn(
                          touched,
                          `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                        ) &&
                        getIn(
                          errors,
                          `projectTimelines[${plotDeliverySectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                        )
                      }
                      disabled={isViewMode}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </Fragment>
          </Fragment>
        )
      )}
    </Fragment>
  );
};

export default PlotDelivery;
