import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import {
  MediaUploadComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import QuillEditor from "components/quill-editor/QuillEditor";
import styles from "./Opportunity.module.css";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { useEffect, useState } from "react";

function AboutTheProject() {
  const dispatch = useDispatch();
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<IInitinalValueProjectContent>();

  const { imageDetails, isLoading, isSuccess, isDelete } = useSelector(
    (state: any) => state.fileUpload
  );

  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));

  const [aboutTheProjectMediaValue, setAboutTheProjectMediaValue] =
    useState("");
  const opportunityDocAboutProject = `opportunityDoc.aboutProjects.media.value.url`;

  const handleTextEditor = (
    name: string,
    newValue: string,
    options: any,
    { setFieldValue, setFieldError }: any
  ) => {
    // if (options?.isError) {
    //   setFieldError(name, "Maximum character exceed");
    // } else {
      setFieldValue(name, newValue);
    // }
  };

  //upload Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const insightMediaImageUploadSuccess = `opportunityDoc.aboutProjects.media.value.url`;
      if (aboutTheProjectMediaValue === insightMediaImageUploadSuccess) {
        setFieldValue(insightMediaImageUploadSuccess, imageDetails?.url);
        setAboutTheProjectMediaValue("");
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  //delete Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const insightMediaImageUploadDelete = `opportunityDoc.aboutProjects.media.value.url`;
      if (insightMediaImageUploadDelete === aboutTheProjectMediaValue) {
        const empty = "";
        setFieldValue(insightMediaImageUploadDelete, empty);
        setAboutTheProjectMediaValue("");
      }
    }
    getAllInsightsCreationFunction();
  }, [isDelete]);

  const handleuploadImage = (event: any) => {
    const file = event?.target?.files[0];
    const MediaId = event?.target?.id;

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      setAboutTheProjectMediaValue(MediaId);

      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
      };
      reader.readAsArrayBuffer(file);
      const currentInfraStoryMediaName = `opportunityDoc.aboutProjects.media.name`;
      const currentInfraStoryMediaKey = `opportunityDoc.aboutProjects.media.key`;
      setFieldValue(currentInfraStoryMediaName, file?.name);
      setFieldValue(currentInfraStoryMediaKey, file?.name);
    }
  };

  const handleConfirmDelete = () => {
    let fileName = values?.opportunityDoc?.aboutProjects?.media?.name;
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
  };

  return (
    <div>
      <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
        <Typography>About The Project</Typography>

        <FormControlLabel
          className="switch-form-label"
          name={"opportunityDoc.isAboutProjectsActive"}
          checked={values?.opportunityDoc?.isAboutProjectsActive}
          onChange={handleChange}
          control={<SwitchComponent />}
          label=""
          labelPlacement="start"
          disabled={isViewMode}
        />
        <IconButton>
          <Tooltip title={"About The Project"} placement="top">
            <Avatar
              className={styles["Avator"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
      </Stack>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item xs={7} sm={7} md={7} lg={7.3} xl={8} p={3}>
          <Box mr={"20rem"}>
            <TextFieldComponent
              required
              type="text"
              placeholder="Enter"
              label="Section Heading"
              name={`opportunityDoc.aboutProjects.heading`}
              value={values?.opportunityDoc?.aboutProjects?.heading}
              onChange={handleChange}
              inputProps={{ maxLength: 40 }}
              onBlur={handleBlur}
              fieldhelpertext={
                getIn(touched, `opportunityDoc.aboutProjects.heading`) &&
                getIn(errors, `opportunityDoc.aboutProjects.heading`)
              }
              error={Boolean(
                getIn(errors, `opportunityDoc.aboutProjects.heading`) &&
                  getIn(touched, `opportunityDoc.aboutProjects.heading`)
              )}
              disabled={isViewMode}
            />
          </Box>

          <Typography>Description</Typography>
          <QuillEditor
            placeholder="Enter description"
            className={styles["QuillEditor"]}
            name={`opportunityDoc.aboutProjects.description`}
            value={values?.opportunityDoc?.aboutProjects?.description}
            onChange={(name: string, newValue: string, options: any) =>
              handleTextEditor(name, newValue, options, {
                setFieldValue,
                setFieldError,
              })
            }
            // style={{ height: "12rem" }}
            inputProps={{ maxLength: 240 }}
            style={
               { height: "126px" }
            }
            disabled={isViewMode}
          />
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={3.7} xl={3} mr={"2rem"}>
          <MediaUploadComponent
            ondrop={handleChange}
            loading={
              isLoading &&
              aboutTheProjectMediaValue === opportunityDocAboutProject
            }
            name={`opportunityDoc.aboutProjects.media.value.url`}
            value={values?.opportunityDoc?.aboutProjects?.media?.value?.url}
            // onBlur={handleBlur}
            onChange={(event: any) => {
              handleuploadImage(event);
            }}
            previewimage={
              values?.opportunityDoc?.aboutProjects?.media?.value?.url
            }
            cancelimage={() => {
              handleConfirmDelete();
              setAboutTheProjectMediaValue(opportunityDocAboutProject);
            }}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, `opportunityDoc.aboutProjects.media.value.url`) &&
              getIn(errors, `opportunityDoc.aboutProjects.media.value.url`)
            }
            disabled={isViewMode}
          />
        </Grid>
      </Grid>
      <Divider />
    </div>
  );
}

export default AboutTheProject;
