import { Constants } from "constants/Constants";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { createReducer, createActions } from "reduxsauce";
import { setItemToLocalStorage, setItemToSessionStorage } from "../../utils/LocalStorageUtils";

const { Types, Creators } = createActions({
  loginSuccess: ["response"],
  loginFailure: ["error"],
  loginRequest: ["data"],

  setLoginState: ["key", "value"],
});

export const LoginTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  error: null,
  isLoggedIn: false,
  data: null,
  isLoading: false,
};

export const loginRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const loginSuccess = (state: any, action: any) => { 
  const response = action?.response;
    const loggedInUser = response?.data;
    const profileData = response?.data?.admin;
    setItemToLocalStorage(Constants.loggedInUser, loggedInUser);
    setItemToLocalStorage(Constants.authorizationToken, loggedInUser?.token);
    setItemToLocalStorage(Constants.userData, profileData);
    
    setItemToSessionStorage(Constants.authorizationToken, loggedInUser?.token);

    return {
      ...state,
      data: loggedInUser,
      isLoggedIn: true,
      error: null,
      isLoading: false,
    };
  
};

export const loginFailure = (state: any, action: any) => {  
  return {
    ...state,
    isLoading: false,
    error: action?.error?.data?.message ? action?.error?.data?.message : "Check Your Network!",
  };
};

export const setLoginState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGIN_REQUEST]: loginRequest,

  [Types.SET_LOGIN_STATE]: setLoginState,
});
