import {
  Avatar,
  Button,
  Checkbox,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";
import { useDispatch, useSelector } from "react-redux";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
import { Grid } from "@mui/material";
import { FormControl } from "@mui/material";
import {
  AutocompleteComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import {
  ActionTypeEnumInsight,
  ActionTypeEnumUtilsInsight,
} from "enumerations/ActionTypeEnum";
import MediaAssets from "assets";
import Loader from "components/loader/Loader";
import { exportAsExcel } from "utils/ExportFileUtils";
import { SelectChangeEvent } from "@mui/material/Select";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { ReadmoreDialogWrapper } from "pages/promise-management/promise-list/PromiseList";
import { Constants } from "constants/Constants";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { IObjectProps } from "pages/user-management/user-creation/UserCreationInitialValues";
import { useFormikContext } from "formik";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { AllProjectFaqsAction } from "redux-container/project-management/ProjectAllFaqsRedux";
import { FaqsTypeEnum } from "enumerations/FaqsTypes";

const actionDispatch = (dispatch: any) => {
  return {
    getProjectFaqs: (params: any) =>
      dispatch(ProjectFaqActions.getAllProjectFaqsRequest(params)),
    projectFaqsBulkDelete: (data: any) =>
      dispatch(ProjectFaqActions.projectFaqBulkDeleteRequest(data)),
    projectFaqsBulkUpdate: (data: any) =>
      dispatch(ProjectFaqActions.projectFaqBulkUpdateRequest(data)),
    projectFaqPriorityUpdate: (data: any) =>
      dispatch(ProjectFaqActions.projectFaqPriorityUpdateRequest(data)),
    setProjectFaqState: (key: any, value: any) =>
      dispatch(ProjectFaqActions.setProjectFaqState(key, value)),
    setALLProjectFaqState: (key: any, value: any) =>
      dispatch(AllProjectFaqsAction.setALLProjectFaqState(key, value)),
  };
};
export default function CategoryBasedFaqList(props: any) {
  const { isEditable } = props;
  const {
    getProjectFaqs,
    setProjectFaqState,
    setALLProjectFaqState,
    projectFaqsBulkDelete,
    projectFaqsBulkUpdate,
    projectFaqPriorityUpdate,
  } = actionDispatch(useDispatch());
  const location: any = useLocation();
  const navigate: any = useNavigate();
  const {
    projectFaqs,
    totalCount,
    isProjectFaqResponse,
    isLoading,
    mappedFaqs,
  } = useSelector((state: any) => state?.projectFaqs);

  const users = useSelector((state: any) => state?.users.users);
  const actions = ActionTypeEnumUtilsInsight.getActionTypeEnumsInsight();

  const { setFieldValue } = useFormikContext<IObjectProps>();

  const tableHeadings = [
    { heading: "Question" },
    { heading: `Created on` },
    { heading: "Modified on" },
    { heading: "Last Modified By" },
    { heading: "Priority" },
    { heading: "Status" },
    { heading: "Map FAQ" },
    { heading: "Action" },
  ];

  //all state
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [page, setPage] = useState(0);
  const [createdBy, setCreatedBy] = useState<any>(null);
  const [publishedBy, setPulishedBy] = useState<any>(null);
  const [modifiedBy, setModifiedBy] = useState<any>(null);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [sortByPriority, setSortByPriority] = useState(true);
  const [anchor, setAnchor] = useState(null);
  const [question, setQuestion] = useState("");
  const [priorityCount, setPriorityCount] = useState(0);

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    const params = {
      index: page + 1,
      size: rowsPerPage,
      createdBy: createdBy?.id || publishedBy?.id,
      categoryId: location?.state?.categoryId || location.state?.category?.id,
      updatedBy: modifiedBy?.id,
      byPrority: sortByPriority,
      typeOfFAQ: FaqsTypeEnum.ProjectFaq,
      shouldDisplayMappedFaqs: false,
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    getProjectFaqs(params);
  }, [
    page,
    rowsPerPage,
    modifiedBy,
    sortByPriority,
    location,
    createdBy,
    publishedBy,
  ]);

  useEffect(() => {
    if (isProjectFaqResponse) {
      const params = {
        index: page + 1,
        size: rowsPerPage,
        createdBy: createdBy?.id || publishedBy?.id,
        categoryId: location?.state?.categoryId || location.state?.category?.id,
        updatedBy: modifiedBy,
        byPrority: sortByPriority,
        typeOfFAQ: FaqsTypeEnum.ProjectFaq,
        shouldDisplayMappedFaqs: false,
        shouldValidateAdmin:
          loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
      };
      getProjectFaqs(params);
      setProjectFaqState("isProjectFaqResponse", false);
    }
  }, [isProjectFaqResponse]);
  //check click

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = projectFaqs?.map((n: any) => n?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  //select all
  const handleClick = (event: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  //sort filter
  const handlePriorityChange = () => {
    setSortByPriority(!sortByPriority);
  };

  //handle Page RangeChange
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
  };

  //handle change page
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  //handleClickAway
  const handleClickAway = () => {
    setOpen(false);
  };
  // tooltip
  const popOverComponent = (event: any, question: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
    setQuestion(question);
  };
  // view on faqList Item
  const viewFAQ = (faq: any) => {
    setFieldValue("categoryId", faq?.category?.name);
    navigate(
      `${RouteConstants.projectCreation}/${RouteConstants.projectFaqCreation}`,
      {
        state: {
          ...location.state,
          isFaqTable: true,
          isOnlyView: true,
          isFaqCreation: true,
          openForm: true,
          ...faq,
          isFormEdit: true,
        },
      }
    );
  };
  /// edit on faqlist item
  const editFAQ = (faq: any) => {
    setFieldValue("categoryId", faq?.category?.name);
    navigate(
      `${RouteConstants.projectCreation}/${RouteConstants.projectFaqCreation}`,
      {
        state: {
          ...location.state,
          isFaqTable: true,
          isFaqCreation: true,
          openForm: true,
          ...faq,
          isFormEdit: true,
          isViewMode: false,
          isOnlyView: false,
        },
      }
    );
  };
  //clearning filter
  const clearFilters = () => {
    setPulishedBy(null);
    setCreatedBy(null);
    setModifiedBy(null);
  };

  //action
  const handleActionChange = (event: SelectChangeEvent) => {
    setSelectedAction(event.target.value as string);
    const actionSelected = event.target.value;
    switch (Number(actionSelected)) {
      case ActionTypeEnumInsight.Export:
        handleExportAsExcel();
        break;
      case ActionTypeEnumInsight.Delete:
        deleteSelectedFaqs();
        break;
      case ActionTypeEnumInsight.MarkAsPublished:
        changeFaqStatus("true");
        break;
      case ActionTypeEnumInsight.MarkAsInactive:
        changeFaqStatus("false");
        break;
    }
  };

  const clearSelected = () => {
    setSelected([]);
    setSelectedAction("");
  };
  //statusChange

  const changeFaqStatus = (status: any) => {
    if (selected.length === 0) {
      alert("You have not selected any Faqs");
      return;
    }

    if (status === "true") {
      const reqBody = {
        faqIds: selected,
        status: true,
        categoryId: location?.state?.categoryId
          ? location?.state?.categoryId
          : location.state?.category?.id,
        shouldValidateAdmin:
          loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
      };
      projectFaqsBulkUpdate(reqBody);
    } else {
      const reqBody = {
        faqIds: selected,
        status: false,
        categoryId: location?.state?.categoryId
          ? location?.state?.categoryId
          : location.state?.category?.id,
        shouldValidateAdmin:
          loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
      };

      selected?.map(async (faqId: any) => {
        const faqStatus = await projectFaqs?.find(
          (faq: any) => faq?.id === faqId
        );
        if (faqStatus.isMappedToggle === true) {
          const removeFalseFaqID = await mappedFaqs.filter((id: any) => {
            return !selected?.includes(id);
          });
          setALLProjectFaqState("isFaqAddOrRemoved", true);
          setProjectFaqState("mappedFaqs", removeFalseFaqID);
          setALLProjectFaqState(
            "filterAllfaqBasedGivenArray",
            removeFalseFaqID
          );
        }
      });

      projectFaqsBulkUpdate(reqBody);

      setIsConfirmDeleteDialogOpen(false);
      setSelected([]);
      setSelectedAction("");
    }
    clearSelected();
  };

  //export
  const handleExportAsExcel = () => {
    exportAsExcel(projectFaqs, "ProjectFaqs", "Sheet1");
  };
  //deleteselectedFaqs
  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
    setSelectedAction("");
  };
  const deleteSelectedFaqs = () => {
    setIsConfirmDeleteDialogOpen(true);
  };
  const deleteFaqs = async () => {
    const reqBody = {
      faqIds: selected,
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    await projectFaqsBulkDelete(reqBody);
    selected?.map(async (faqId: any) => {
      const faqStatus = await projectFaqs?.find(
        (faq: any) => faq?.id === faqId
      );
      if (faqStatus.isMappedToggle === true) {
        const removeFalseFaqID = mappedFaqs.filter((id: any) => {
          return id !== faqId;
        });
        setALLProjectFaqState("isFaqAddOrRemoved", true);
        setProjectFaqState("mappedFaqs", removeFalseFaqID);
        setALLProjectFaqState("filterAllfaqBasedGivenArray", removeFalseFaqID);
      }
    });
    setIsConfirmDeleteDialogOpen(false);
    setSelected([]);
    setSelectedAction("");
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );
  const handlePriorityChangeIndex = (
    indexValue: any,
    list: any,
    priorityUpdatevalue: any,
    updatedItem: any
  ) => {
    if (indexValue) {
      list.splice(indexValue, 1);
      list.splice(indexValue + priorityUpdatevalue, 0, updatedItem);
      return list;
    }
  };

  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const mappedFaqsBottomTableValues = useSelector(
    (state: any) => state?.projectAllfaqs.allProjectFaqs
  );

  const handleMappedFaq = (faqId: any) => {
    const faqStatus = projectFaqs?.find((faq: any) => faq?.id === faqId);
    if (
      mappedFaqsBottomTableValues?.length >= 2 &&
      faqStatus.isMappedToggle === false
    ) {
      setOpenWarningDialog(true);
    } else {
      const newMappedArray = projectFaqs.map((faq: any) => {
        if (faq?.id === faqStatus?.id) {
          if (faqStatus.isMappedToggle === false) {
            const newArray = mappedFaqs;
            newArray.push(faqStatus?.id);
            setProjectFaqState("mappedFaqs", newArray);
            setALLProjectFaqState("isFaqAddOrRemoved", true);
            setALLProjectFaqState("filterAllfaqBasedGivenArray", newArray);
            return {
              ...faq,
              isMappedToggle: true,
            };
          }
          if (faqStatus.isMappedToggle === true) {
            const removeFalseFaqID = mappedFaqs.filter((id: any) => {
              return id !== faq?.id;
            });
            setALLProjectFaqState("isFaqAddOrRemoved", true);
            setProjectFaqState("mappedFaqs", removeFalseFaqID);
            setALLProjectFaqState(
              "filterAllfaqBasedGivenArray",
              removeFalseFaqID
            );
            return {
              ...faq,
              isMappedToggle: false,
            };
          }
        } else {
          return { ...faq };
        }
      });
      setProjectFaqState("projectFaqs", newMappedArray);
    }
  };

  return (
    <>
      <FlashMessageDialog
        shouldOpen={openWarningDialog}
        content={"We Cannot map more than two FAQs"}
        isSuccess={false}
        showWarning={true}
        cancelHandler={() => setOpenWarningDialog(false)}
      />
      <Grid container my={4} justifyContent={"space-between"}>
        <Grid item>
          <Typography
            color={"secondary.dark"}
            variant="h6"
            mx={"2rem"}
          >{` Published (${projectFaqs?.length})`}</Typography>
          <Divider sx={{ borderWidth: "1px", borderColor: "black" }} />
        </Grid>
        <Grid item xs={5}>
          <Stack
            justifyContent={"flex-end"}
            alignItems={"flex-start"}
            spacing={2}
          >
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
                disabled={isViewMode}
              >
                {actions.map((action: any, index: any) => {
                  return (
                    <MenuItem
                      disabled={
                        action.value !== 1 &&
                        !isEditable &&
                        location?.state?.isOnlyView
                      }
                      value={action.value}
                    >
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>
            <Button
              variant="contained"
              onClick={() => {
                setFieldValue("categoryId", location?.state?.category?.name);
                navigate(
                  `${RouteConstants.projectCreation}/${RouteConstants.projectFaqCreation}`,
                  {
                    state: {
                      ...location.state,
                      isFaqCreation: true,
                      isFaqTable: false,
                      categoryId: location?.state?.category?.name,
                      openForm: true,
                      isFormEdit: true,
                      isCreateNewCategory: false,
                    },
                  }
                );
              }}
              // autoFocus
              disableElevation
              className="btn btn-dark"
              disabled={isViewMode}
            >
              Add More FAQs
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid container spacing={2} md={10} lg={10} xl={10}>
          <Grid item xs={1} className="margin-left-10 filter-heading-holder">
            <span className="filter-heading" style={{ marginBottom: "15px" }}>
              Filters:
            </span>
          </Grid>
          <Grid item xs={2}>
            <FormControl size="small" className="filter-dropdown">
              <AutocompleteComponent
                options={users}
                label="Created by"
                onChange={(
                  event: React.SyntheticEvent,
                  newValue: any | null
                ) => {
                  setCreatedBy(newValue);
                }}
                value={createdBy}
                getOptionLabel={(option: any) => option?.firstName}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.id === value?.id
                }
                disabled={isViewMode}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl size="small" className="filter-dropdown">
              <AutocompleteComponent
                options={users}
                label="Modified by"
                onChange={(
                  event: React.SyntheticEvent,
                  newValue: any | null
                ) => {
                  setModifiedBy(newValue);
                }}
                value={modifiedBy}
                getOptionLabel={(option: any) => option?.firstName}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.id === value?.id
                }
                disabled={isViewMode}
              />
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl size="small" className="filter-dropdown">
              <AutocompleteComponent
                options={users}
                label="Published By"
                onChange={(
                  event: React.SyntheticEvent,
                  newValue: any | null
                ) => {
                  setPulishedBy(newValue);
                }}
                value={publishedBy}
                getOptionLabel={(option: any) => option?.firstName}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.id === value?.id
                }
                disabled={isViewMode}
              />
            </FormControl>
          </Grid>
          {publishedBy !== null || modifiedBy !== null || createdBy !== null ? (
            <Grid item xs={1} className="filter-heading-holder">
              <span
                onClick={clearFilters}
                className="clear-filter"
                style={
                  isViewMode
                    ? { marginBottom: "15px", pointerEvents: "none" }
                    : { marginBottom: "15px" }
                }
              >
                Clear
              </span>
            </Grid>
          ) : null}
        </Grid>
        <Grid item>
          <Stack spacing={1}>
            <Typography>Sort by Priority</Typography>
            <SwitchComponent
              disabled={location?.state?.isOnlyView || isViewMode}
              checked={sortByPriority}
              onChange={handlePriorityChange}
            />
            <Tooltip
              title={"Toggle is to show/hide this section on the Customer App"}
              placement="top-start"
            >
              <Avatar
                sx={{
                  width: "0.9rem",
                  height: "0.9rem",
                  cursor: "pointer",
                  marginTop: "0.10rem",
                  marginBottom: "0.10rem",
                }}
                src={MediaAssets.ic_info}
                variant="rounded"
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    data-testid="table-head-checkbox"
                    color="primary"
                    indeterminate={
                      selected.length > 0 &&
                      selected.length < projectFaqs?.length
                    }
                    checked={
                      projectFaqs?.length > 0 &&
                      selected?.length === projectFaqs?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    <Stack justifyContent="flex-start">
                      <div>{item.heading}</div>
                      {index == 6 && (
                        <Tooltip
                          arrow
                          title={
                            "Toggle is to map this FAQ in project detailed page on the Customer App"
                          }
                          placement="top-start"
                        >
                          <Avatar
                            sx={{
                              width: "0.9rem",
                              height: "0.9rem",
                              cursor: "pointer",
                              marginTop: "0.10rem",
                              marginBottom: "0.10rem",
                              marginLeft: "0.3rem",
                            }}
                            src={MediaAssets.ic_info}
                            variant="rounded"
                          />
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {projectFaqs?.length > 0 ? (
              <TableBody>
                {projectFaqs?.map((faq: any, index: any) => {
                  const isItemSelected = isSelected(faq?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={faq?.id}
                      className="table-data-row"
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          className="active-checkbox"
                          onClick={(event) => handleClick(event, faq?.id)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          disabled={isViewMode}
                        />
                      </TableCell>
                      <TableCell className="table-data-text">
                        {/* {`${faq?.faqQuestion?.question.slice(0, 18)}...`}
                        <Typography
                          component="span"
                          variant="body1"
                          color="#2d2d2d"
                          sx={{ fontWeight: "bold", cursor: "pointer" }}
                          onClick={(event: any) =>
                            popOverComponent(event, faq?.faqQuestion?.question)
                          }
                        >
                          READ MORE
                        </Typography> */}
                        {faq?.faqQuestion?.question.length > 0 ? (
                          <div>
                            {faq?.faqQuestion?.question.length > 28 ? (
                              <Stack justifyContent={"flex-start"} spacing={2}>
                                <span>
                                  {" "}
                                  {`${faq?.faqQuestion?.question.slice(
                                    0,
                                    26
                                  )}...`}
                                </span>
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="#2d2d2d"
                                  sx={{
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    wordBreak: "keep-all",
                                    textAlign: "start",
                                    width: "110px",
                                  }}
                                  onClick={(event: any) =>
                                    popOverComponent(
                                      event,
                                      faq?.faqQuestion?.question
                                    )
                                  }
                                >
                                  READ MORE
                                </Typography>
                              </Stack>
                            ) : (
                              faq?.faqQuestion?.question
                            )}
                          </div>
                        ) : (
                          "-"
                        )}
                      </TableCell>

                      <TableCell className="table-data-text">
                        <div>{faq?.createdAt?.slice(0, 10)}</div>
                      </TableCell>

                      <TableCell className="table-data-text">
                        <div>{faq?.updatedAt?.slice(0, 10)}</div>
                      </TableCell>

                      <TableCell className="table-data-text">
                        {faq?.faqsUpdatedAdmin?.firstName.length > 0 ? (
                          <div>
                            <div>
                              {" "}
                              {` ${
                                faq?.faqsUpdatedAdmin?.firstName
                                  ? faq?.faqsUpdatedAdmin?.firstName
                                  : "-"
                              }`}
                            </div>
                            <div>
                              {faq?.faqsUpdatedAdmin?.updatedAt?.slice(0, 10)}
                            </div>
                          </div>
                        ) : (
                          <div>-</div>
                        )}
                      </TableCell>
                      <TableCell className="table-data-text">
                        {faq?.status === true ? (
                          <PrioritySelector
                            priority={index + 1}
                            isPriorityRequired={
                              (location?.state?.isOnlyView && !isEditable) ||
                              !sortByPriority
                            }
                            disableIncrement={index + 1 === projectFaqs?.length}
                            disableDecrement={index === 0}
                            decrementpriority={() => {
                              handlePriorityChangeIndex(
                                index,
                                projectFaqs,
                                -1,
                                faq
                              );
                              setPriorityCount(priorityCount + 1);
                            }}
                            incrementpriority={() => {
                              if (index === 0) {
                                projectFaqs.splice(0, 1);
                                projectFaqs.splice(1, 0, faq);
                                setPriorityCount(priorityCount + 1);
                              } else {
                                handlePriorityChangeIndex(
                                  index,
                                  projectFaqs,
                                  1,
                                  faq
                                );
                                setPriorityCount(priorityCount - 1);
                              }
                            }}
                          />
                        ) : (
                          <PrioritySelector isPriorityRequired={true} />
                        )}
                      </TableCell>
                      <TableCell className="table-data-text">
                        {faq?.status === true ? `Published` : "Inactive"}
                      </TableCell>

                      <TableCell className="table-data-text">
                        <SwitchComponent
                          disabled={
                            (location?.state?.isOnlyView && !isEditable) ||
                            faq?.status === false
                          }
                          checked={
                            mappedFaqs &&
                            faq?.status === true &&
                            mappedFaqs?.includes(faq?.id)
                              ? true
                              : false
                          }
                          onChange={() => handleMappedFaq(faq?.id)}
                        />
                      </TableCell>

                      <TableCell>
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editFAQ(faq)}
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewFAQ(faq)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">
                Try Adjusting your search or filter to find what you're looking
                for
              </div>
            )}
          </Table>
        </TableContainer>
      )}

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Popper open={open} placement="bottom-start" anchorEl={anchor}>
          <ReadmoreDialogWrapper>
            <Paper elevation={3} sx={{ p: "0.5rem", width: "350px" }}>
              {question}
            </Paper>
          </ReadmoreDialogWrapper>
        </Popper>
      </ClickAwayListener>
      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete FAQ(s)?"
        content={`This action will delete ${selected?.length} selected faq(s) from the Database and cannot undo. Are you sure you want to continue?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteFaqs}
      />

      {!isViewMode && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}
