import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { /*useNavigate,*/ useLocation } from "react-router-dom";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import PageHeading from "../../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Checkbox,
  ClickAwayListener,
  Stack,
  Typography,
  Popper,
  Link,
  InputLabel,
  OutlinedInput,
  ListItemText,
  styled,
  Box,
  Tooltip,
} from "@mui/material";
import { TextFieldComponent } from "components/ui-elements";
import {
  ActionTypeEnumUpdates,
  ActionTypeEnumUtilsUpdate,
} from "enumerations/ActionTypeEnum";
import { useDispatch, useSelector } from "react-redux";
import { exportAsExcel } from "utils/ExportFileUtils";
import Loader from "components/loader/Loader";
import Retry from "components/retry/Retry";
import CustomerFeedBackDialog from "./customer-feedback-dialog/CustomerFeedbackDialog";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import customerManagementActions from "redux-container/customer-management/CustomerManagementRedux";
import {
  categoryEnum,
  CategoryTypeEnumUtils,
} from "enumerations/CustomerManagementEnums";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { RatingRoleTypeEnumUtils } from "enumerations/RatingTypeEnum";
import { CustomerEnumsUtils } from "enumerations/CustomerTypeEnums";

import { SortingOrder } from "enumerations/SortingOrderTypeEnum";
import { DeviceEnumsUtils } from "enumerations/DeviceTypeEnum";

export const ReadmoreDialogWrapper = styled(Box)`
  && .MuiPaper-root {
    overflow-wrap: break-word;
    width: 200px;
    /* margin-left: 20px; */
  }
`;

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerFeedBackList: (data: any) =>
      dispatch(customerManagementActions.customerFeedbackListRequest(data)),
    setCustomerManagementState: (key: any, data: any) =>
      dispatch(customerManagementActions.setCustomerManagementState(key, data)),
  };
};

const CustomerFeedBack = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const location: any = useLocation();
  const ratingOptions = RatingRoleTypeEnumUtils.getRatingTypeEnums();
  const deviceTypes = DeviceEnumsUtils.getDeviceEnums();
  const customerTypes = CustomerEnumsUtils.getActionsEnums();
  const actionsData = ActionTypeEnumUtilsUpdate.getActionTypeEnumsUpdates();
  const [selectedAction, _setSelectedAction] = useState("");
  const [selectedStatus, _setSelectedStatus] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState<any>([]);
  const [categoryType, setCategoryType] = useState<any>([]);

  const [ratingType, setRatingType] = useState<any>("");
  const [deviceType, setDeviceType] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [anchor, setAnchor] = useState(null);
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [columnSort, setColumnSort] = useState({
    sortingKey: "",
    sortingPriority: "",
    isActiveSortingColumn: "",
  });
  const [openCustomerFeedBackPopUp, setOpenCustomerFeedBackPopUp] =
    useState(false);
  const [customerFeedBackDialogContent, setCustomerFeedBackDialogContent] =
    useState({});
  const customerFeedBackListData = useSelector(
    (state: any) => state?.customerManagement?.customerFeedbackList
  );
  const isLoading = useSelector(
    (state: any) => state.customerManagement?.isLoading
  );
  const error = useSelector((state: any) => state.customerManagement?.error);
  const message = useSelector(
    (state: any) => state.customerManagement?.message
  );
  const totalCount = useSelector(
    (state: any) => state?.customerManagement.totalCount
  );
  const { page, rowsPerPage } = useSelector((state: any) => {
    return {
      page: state?.customerManagement.page,
      rowsPerPage: state?.customerManagement.rowsPerPage,
    };
  });
  const breadcrumbRoutes: any = [
    {
      name: "Customer Management",
      route: RouteConstants.customerManagementDashboard,
    },
    {
      name: "Customer Feedback",
      route: RouteConstants.customerFeedback,
    },
  ];

  const getObjKey = (obj: any, value: any) => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };

  const { getCustomerFeedBackList, setCustomerManagementState } =
    actionDispatch(useDispatch());

  const categoryTypesEnums = CategoryTypeEnumUtils.getCategoryTypeEnums();

  //Loading Data (Customers feed back data) while mounting and
  // every time after User applies any filters (device Type,Contact type,Search key,Sorting key,page and number of rows per page,categories) .
  useEffect(() => {
    let categoriesArrayList = categoryTypesEnums?.filter((category: any) => {
      return categoryType?.includes(category?.label);
    });
    let categoryListValue = categoriesArrayList?.map(
      (category: any) => category?.value
    );

    const params = {
      index: page + 1,
      size: rowsPerPage,
      searchKey: searchKey,
      sortingKey: columnSort.sortingKey,
      sortingPriority: columnSort.sortingPriority,
      categories: categoryListValue,
      rating: ratingType,
      deviceType: deviceType,
      contactType: customerType,
    };
    getCustomerFeedBackList(params);
  }, [
    dispatch,
    rowsPerPage,
    message,
    page,
    searchKey,
    columnSort,
    categoryType,
    ratingType,
    deviceType,
    customerType,
  ]);

  //Setting page and RowsPerPage to initial value if user Navigated to the list from Side menu.
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setCustomerManagementState("page", 0);
      setCustomerManagementState("rowsPerPage", 10);
    }

    return () => {};
  }, []);

  //table headings (heading objects with name,boolean key for whether it is sorting column or not,and sorting key for an API call )
  const tableHeadings = [
    // { heading: "CRM ID" },
    // { heading: "CMS ID" },
    { heading: "Customer Name" },
    { heading: "Customer Type" },
    { heading: "Email" },
    { heading: "Phone Number" },
    { heading: "Device Type" },
    { heading: "Rating" },
    { heading: "Category" },
    { heading: "Feedback" },
    { heading: "Submitted date", isSortingColumn: true, sortingKey: "12" },
    { heading: "View" },
  ];

  //handle change function for page in Pagination.
  const handleChangePage = (event: unknown, newPage: number) => {
    setCustomerManagementState("page", newPage);
  };

  //handle change function for number of elements per Page in Pagination.
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomerManagementState("rowsPerPage", Number(event.target.value));
    setCustomerManagementState("page", 0);
  };

  const renderLoader = () => {
    return <Loader />;
  };
  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };
  const loadData = (params: any) => {
    getCustomerFeedBackList(params);
  };

  //handle change for Action select in List.
  const handleActionChange = (event: SelectChangeEvent) => {
    // setSelectedAction(event.target.value as string);
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case ActionTypeEnumUpdates.Export:
        handleExportAsExcel();
        break;
    }
  };

  const handleExportAsExcel = () => {
    if (selectedCustomer.length === 0) {
      alert("You have not selected any Customer");
      return;
    }
    let exportingFeedbacks: any = [];
    if (customerFeedBackListData.length > 0) {
      selectedCustomer.map((selectedItem: any) => {
        let localSelectedItem = customerFeedBackListData.find(
          (item: any) => item.id == selectedItem
        );
        exportingFeedbacks.push({
          Customer_Name: `${localSelectedItem.user.firstName} ${localSelectedItem.user.lastName}`,
          Customer_Type: localSelectedItem?.user?.contactType
            ? CustomerEnumsUtils.getEnumText(
                localSelectedItem?.user?.contactType
              )
            : "----",
          Email: localSelectedItem.user.email,
          Phone_Number: `${localSelectedItem?.user?.countryCode} ${localSelectedItem.user?.phoneNumber}`,
          Device_Type: localSelectedItem?.deviceType
            ? DeviceEnumsUtils.getEnumText(localSelectedItem?.deviceType)
            : "----",
          Rating: localSelectedItem.rating
            ? RatingRoleTypeEnumUtils.getEnumText(localSelectedItem.rating)
            : "----",
          Category: `${
            localSelectedItem?.categories?.length > 0
              ? localSelectedItem?.categories?.map((item: any, index: any) => {
                  let data = getObjKey(categoryEnum, item);
                  return localSelectedItem?.categories?.length === index + 1
                    ? `${data} `
                    : `${data}, `;
                })
              : "----"
          }`,

          Feedback: localSelectedItem.description
            ? localSelectedItem.description
            : "----",
          Submitted_Date: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
        });
      });
    }
    exportAsExcel(exportingFeedbacks, "CustomerFeedBack", "Sheet1");
  };


  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedCustomer.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCustomer, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCustomer.slice(1));
    } else if (selectedIndex === selectedCustomer.length - 1) {
      newSelected = newSelected.concat(selectedCustomer.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCustomer.slice(0, selectedIndex),
        selectedCustomer.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomer(newSelected);
  };

  const isSelected = (id: any) => selectedCustomer.indexOf(id) !== -1;

  const clearSelected = () => {
    setSelectedCustomer([]);
  };

  //handle change function for select all checkbox.
  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = customerFeedBackListData.map((n: any) => n.id);
      setSelectedCustomer(newSelecteds);
      return;
    }
    setSelectedCustomer([]);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  //function to open ClickAway Dialog after clicking on READ MORE.
  const popOverComponent = (event: any, description: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
    setDescription(description);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //function to Reset the filters applied by User.
  const clearFilters = () => {
    setCategoryType([]);
    setRatingType("");
    setDeviceType("");
    setCustomerType("");
  };

  //handle change for search key filter.
  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 0) {
      setCustomerManagementState("page", 0);
    }
  };

  //handle change for Type of Category filter.
  const handleCategoryChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setCategoryType(typeof value === "string" ? value.split(",") : value);
    if (page !== 0) {
      setCustomerManagementState("page", 0);
    }
  };

  const renderTable = () => {
    return (
      <Fragment>
        <CustomerFeedBackDialog
          shouldOpen={openCustomerFeedBackPopUp}
          handleClose={() => setOpenCustomerFeedBackPopUp(false)}
          content={customerFeedBackDialogContent}
        />
        <div className="heading-action-holder">
          <h4 className="heading">
            Customer Feedback
            {`(${
              totalCount
                ? totalCount
                : customerFeedBackListData?.length
                ? customerFeedBackListData?.length
                : 0
            })`}
            {selectedCustomer?.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedCustomer.length} Customer(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Action"
                onChange={handleActionChange}
              >
                <MenuItem value={actionsData[0].value}>
                  {actionsData[0].label}
                </MenuItem>
              </TextFieldComponent>
            </FormControl>
          </div>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={1} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <InputLabel>Category</InputLabel>
                  <Select
                    multiple
                    value={categoryType}
                    onChange={handleCategoryChange}
                    input={<OutlinedInput label="Category" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {categoryTypesEnums.map((category: any): any => (
                      <MenuItem key={category.label} value={category.label}>
                        <Checkbox
                          checked={categoryType.indexOf(category.label) > -1}
                        />
                        <ListItemText primary={category.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    label="Rating"
                    value={ratingType}
                    onChange={(event: any) => {
                      setRatingType(event.target.value);
                    }}
                  >
                    {ratingOptions.map((rating: any) => {
                      return (
                        <MenuItem value={rating.value}>{rating.label}</MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>
              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    label="Device Type"
                    value={deviceType}
                    onChange={(event: any) => {
                      setDeviceType(event.target.value);
                    }}
                  >
                    {deviceTypes.map((deviceType: any) => {
                      return (
                        <MenuItem value={deviceType.value}>
                          {deviceType.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>
              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    label="Customer Type"
                    value={customerType}
                    onChange={(event: any) => {
                      setCustomerType(event.target.value);
                    }}
                  >
                    {customerTypes?.map((customerType: any) => {
                      return (
                        <MenuItem value={customerType.value}>
                          {customerType.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              {categoryType?.length > 0 ||
              ratingType ||
              deviceType ||
              customerType ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Stack>
        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative", width: "100%" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell
                  className="table-header-text"
                  padding="checkbox"
                  style={{ minWidth: 50 }}
                >
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedCustomer?.length > 0 &&
                      selectedCustomer?.length <
                        customerFeedBackListData?.length
                    }
                    checked={
                      customerFeedBackListData?.length > 0 &&
                      selectedCustomer?.length ===
                        customerFeedBackListData?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select Customers",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell
                    // style={{textAlign : item.heading==="Feedback"?"center":"start" }}
                    key={index}
                    className="table-header-text"
                  >
                    {item.heading}
                    {item.isSortingColumn ? (
                      <>
                        {columnSort.sortingPriority ===
                          SortingOrder.Descending &&
                        columnSort.isActiveSortingColumn === item.heading ? (
                          <IconButton
                            onClick={() => {
                              setColumnSort({
                                sortingKey: item.sortingKey,
                                sortingPriority: SortingOrder.Ascending,
                                isActiveSortingColumn: item.heading,
                              });
                              if (page !== 0) {
                                setCustomerManagementState("page", 0);
                              }
                            }}
                          >
                            <ArrowDropUpIcon
                              fontSize={"large"}
                              style={{
                                position: "relative",
                                // top: "16px",
                                right: "10px",
                                fontSize: "3rem",
                                color:
                                  columnSort.isActiveSortingColumn ===
                                  item.heading
                                    ? "#676ac0"
                                    : "rgb(0 0 0 / 87%)",
                              }}
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setColumnSort({
                                sortingKey: item.sortingKey,
                                sortingPriority: SortingOrder.Descending,
                                isActiveSortingColumn: item.heading,
                              });
                              if (page !== 0) {
                                setCustomerManagementState("page", 0);
                              }
                            }}
                          >
                            <ArrowDropDownIcon
                              fontSize={"large"}
                              style={{
                                position: "relative",
                                // top: "10px",
                                right: "10px",
                                fontSize: "3rem",
                                color:
                                  columnSort.isActiveSortingColumn ===
                                  item.heading
                                    ? "#676ac0"
                                    : "rgb(0 0 0 / 87%)",
                              }}
                            />
                          </IconButton>
                        )}
                      </>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {customerFeedBackListData && customerFeedBackListData.length > 0 ? (
              <TableBody>
                {customerFeedBackListData?.map((customer: any) => {
                  const isItemSelected = isSelected(customer.id);
                  return (
                    <TableRow
                      key={customer.id}
                      className="table-data-row"
                      hover
                      role="checkbox"
                      selected={isItemSelected}
                      tabIndex={-1}
                      aria-checked={isItemSelected}
                      sx={{ margin: "50px 0" }}
                    >
                      <TableCell padding="checkbox" style={{ minWidth: 50 }}>
                        <Checkbox
                          onClick={(event) => handleClick(event, customer.id)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={customer.id}
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>

                      {/* <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120, maxWidth: 150 }}
                      >
                        #ID-{customer?.user?.crmId}
                      </TableCell> */}

                      {/* <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        <Link
                          underline="always"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenCustomerFeedBackPopUp(true);
                            setCustomerFeedBackDialogContent(customer);
                          }}
                        >
                          #ID-{customer.userId}
                        </Link>
                      </TableCell> */}

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150, maxWidth: 180 }}
                      >
                        {customer?.user?.firstName
                          ? customer?.user?.firstName
                          : null}{" "}
                        {customer?.user?.firstName
                          ? customer?.user?.lastName
                          : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150, maxWidth: 180 }}
                      >
                        {/* {customer?.user?.customerType} */}
                        {customer?.user?.contactType
                          ? CustomerEnumsUtils.getEnumText(
                              customer?.user?.contactType
                            )
                          : "----"}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ width: 170 }}
                      >
                        <>
                          <Typography>
                            {customer?.user?.email
                              ? customer?.user?.email?.length > 18
                                ? `${customer?.user?.email.slice(0, 18)}...`
                                : customer?.user?.email
                              : "----"}
                          </Typography>
                        </>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <>
                          <Typography>
                            {customer?.user?.phoneNumber
                              ? `${customer?.user?.countryCode} ${customer?.user?.phoneNumber}`
                              : "----"}
                          </Typography>
                        </>
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150, maxWidth: 180 }}
                      >
                        {customer?.deviceType
                          ? DeviceEnumsUtils.getEnumText(customer?.deviceType)
                          : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 80 }}
                      >
                        {customer.rating
                          ? RatingRoleTypeEnumUtils.getEnumText(customer.rating)
                          : "----"}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120, maxWidth: 120 }}
                      >
                        {customer?.categories?.length > 0
                          ? customer?.categories?.map(
                              (item: any, index: any) => {
                                let data = getObjKey(categoryEnum, item);
                                return customer?.categories?.length ===
                                  index + 1
                                  ? `${data} `
                                  : `${data}, `;
                              }
                            )
                          : "----"}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{
                          width: 360,
                          // ,textAlign:"center"
                          wordBreak: "break-all",
                        }}
                      >
                        {customer?.description?.length > 0 ? (
                          <>
                            {customer?.description?.length > 18 ? (
                              <>
                                <Stack justifyContent={"flex-start"}>
                                  <span>{`${customer?.description?.slice(
                                    0,
                                    18
                                  )}...`}</span>
                                  <Typography
                                    component="span"
                                    variant="body1"
                                    color="#2d2d2d"
                                    sx={{
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      wordBreak: "keep-all",
                                      width: "180px",
                                    }}
                                    onClick={(event: any) =>
                                      popOverComponent(
                                        event,
                                        customer?.description
                                      )
                                    }
                                  >
                                    READ MORE
                                  </Typography>{" "}
                                </Stack>
                              </>
                            ) : (
                              customer?.description
                            )}
                          </>
                        ) : (
                          "----"
                        )}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 220 }}
                      >
                        {customer.updatedAt
                          ? formatDateTime(
                              customer.updatedAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "----"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => {
                              setOpenCustomerFeedBackPopUp(true);
                              setCustomerFeedBackDialogContent(customer);
                            }}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Records Found</div>
            )}
          </Table>
        </TableContainer>

        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleClickAway}
        >
          <Popper open={open} placement="bottom-start" anchorEl={anchor}>
            <ReadmoreDialogWrapper>
              <Paper elevation={3} sx={{ p: "0.5rem", width: "350px" }}>
                {description}
              </Paper>
            </ReadmoreDialogWrapper>
          </Popper>
        </ClickAwayListener>
        {!searchKey &&
        customerFeedBackListData &&
        customerFeedBackListData.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={totalCount ? totalCount : customerFeedBackListData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };
  const isFilterApplied = () => {
    if (selectedStatus === "") {
      return false;
    }
    return true;
  };

  const render = () => {
    if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };
  const shouldProvidePlaceholder = () => {
    if (
      (customerFeedBackListData?.length > 0 || isFilterApplied()) &&
      isLoading === false
    ) {
      return true;
    }
    return false;
  };
  return (
    <Fragment>
      <PageHeading title="Customer Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder={"Search Customer"}
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>
    </Fragment>
  );
};

export default CustomerFeedBack;
