import {
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
  ButtonComponent,
} from "components/ui-elements";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { useFormikContext } from "formik";
import { getIn } from "formik";
import { ChangeEvent, useEffect } from "react";
import { useLocation } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";

const LastestUpdateContainer = styled(FormFieldContainer)`
  padding: 0;
`;
const LatestUpdates = ({ isEditMode }: any) => {
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  const location: any = useLocation();
  const updatesListData = useSelector((state: any) => state?.update?.updates);

  const handleTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${event.target.name}`, event.target.value);
  };

  useEffect(() => {
    if (values?.totalUpdatesOnListView >= updatesListData?.length) {
      setFieldValue("totalUpdatesOnListView", updatesListData?.length);
    }

    if (values?.totalUpdatesOnHomeScreen >= updatesListData?.length) {
      setFieldValue("totalUpdatesOnHomeScreen", updatesListData?.length);
    }
  }, [updatesListData?.length]);

  useEffect(() => {
    if (values?.totalUpdatesOnHomeScreen > values?.totalUpdatesOnListView) {
      setFieldValue("totalUpdatesOnListView", values?.totalUpdatesOnHomeScreen);
    }
  }, [values?.totalUpdatesOnListView, values?.totalUpdatesOnHomeScreen]);
  return (
    <LastestUpdateContainer>
      <Grid container p={"1rem"}>
        <Grid item xs={6}>
          <Stack columnGap={2} justifyContent={"flex-start"}>
            <Typography className="accordion-heading" margin={"0.5rem 0"}>
              Latest Updates
            </Typography>
            <SwitchComponent
              name="isLatestUpdatesActive"
              checked={values?.isLatestUpdatesActive}
              onChange={handleChange}
              disabled={location?.state?.isViewMode && isEditMode}
            />
            <Tooltip
              title={"This represent the ticker on Customer App"}
              arrow
              placement="top-start"
            >
              <InfoOutlinedIcon
                fontSize="small"
                className="infooutlined-alignment"
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Grid container columnSpacing={4} rowSpacing={2} p={"1rem"}>
        <Grid item xs={5} marginRight={"1rem"}>
          <TextFieldComponent
            inputProps={{ maxLength: 100 }}
            label="Section Heading"
            required
            name={"latestUpdates.heading"}
            disabled={location?.state?.isViewMode && isEditMode}
            value={values?.latestUpdates?.heading}
            onChange={handleTextInput}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, "latestUpdates.heading") &&
              getIn(errors, "latestUpdates.heading")
            }
            error={Boolean(
              getIn(errors, "latestUpdates.heading") &&
                getIn(touched, "latestUpdates.heading")
            )}
          />
        </Grid>
        <Grid item xs={5} marginRight={"1rem"}>
          <TextFieldComponent
            inputProps={{ maxLength: 100 }}
            label="Sub Heading"
            required
            name={"latestUpdates.subHeading"}
            value={values?.latestUpdates?.subHeading}
            disabled={location?.state?.isViewMode && isEditMode}
            onChange={handleTextInput}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, "latestUpdates.subHeading") &&
              getIn(errors, "latestUpdates.subHeading")
            }
            error={Boolean(
              getIn(errors, "latestUpdates.subHeading") &&
                getIn(touched, "latestUpdates.subHeading")
            )}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container rowSpacing={1} p={"1rem"}>
        <Grid item lg={3} md={5} xs={5}>
          <Stack justifyContent="flex-start" spacing={2}>
            <Typography mb={"1.2rem"}>Updates on List View</Typography>
            <PrioritySelector
              priority={values?.totalUpdatesOnListView}
              disableDecrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalUpdatesOnListView <= 1 ||
                values?.totalUpdatesOnListView <=
                  values?.totalUpdatesOnHomeScreen
              }
              disableIncrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalUpdatesOnListView === updatesListData?.length
              }
              decrementpriority={() => {
                values?.totalUpdatesOnListView > 0
                  ? setFieldValue(
                      "totalUpdatesOnListView",
                      values?.totalUpdatesOnListView - 1
                    )
                  : alert("no less then 0 ");
              }}
              incrementpriority={() => {
                values?.totalUpdatesOnListView <=
                (updatesListData?.length >= 20
                  ? 20
                  : updatesListData?.length - 1)
                  ? setFieldValue(
                      "totalUpdatesOnListView",
                      values?.totalUpdatesOnListView + 1
                    )
                  : alert(
                      `no more then ${
                        updatesListData?.length >= 20
                          ? 20
                          : updatesListData?.length
                      }`
                    );
              }}
              isCounter={true}
            />
          </Stack>
        </Grid>
        <Grid item lg={5} md={5} xs={5}>
          <Stack justifyContent="flex-start" spacing={1}>
            <Typography mb={"1.2rem"}>
              {" "}
              Total Updates on the home screen?
            </Typography>
            <PrioritySelector
              priority={values?.totalUpdatesOnHomeScreen}
              disableDecrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalUpdatesOnHomeScreen <= 1
              }
              disableIncrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalUpdatesOnHomeScreen === updatesListData?.length
              }
              decrementpriority={() => {
                values?.totalUpdatesOnHomeScreen > 0
                  ? setFieldValue(
                      "totalUpdatesOnHomeScreen",
                      values?.totalUpdatesOnHomeScreen - 1
                    )
                  : alert("no less then 0 ");
              }}
              incrementpriority={() => {
                values?.totalUpdatesOnHomeScreen <=
                (updatesListData?.length >= 20
                  ? 20
                  : updatesListData?.length - 1)
                  ? setFieldValue(
                      "totalUpdatesOnHomeScreen",
                      values?.totalUpdatesOnHomeScreen + 1
                    )
                  : alert(
                      `no more then ${
                        updatesListData?.length >= 20
                          ? 20
                          : updatesListData?.length
                      }`
                    );
              }}
              isCounter={true}
            />
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Box p="1rem" textAlign="right">
        {/* <ButtonComponent variant="contained">Save</ButtonComponent> */}
      </Box>
    </LastestUpdateContainer>
  );
};

export default LatestUpdates;
