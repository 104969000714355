export const enum CTAActionTypeEnum {
  CANCEL_TICKET = "7001",
  RESALE_TICKET = "7002",
  NAVIGATION_TO_BOOKING_JOURNEY = "7003",
}
export class CTAActionTypeEnumUtils {
  public static getEnumText(type: CTAActionTypeEnum): string {
    switch (type) {
      case CTAActionTypeEnum.CANCEL_TICKET:
        return "Cancel/Refund Ticket";
      case CTAActionTypeEnum.RESALE_TICKET:
        return "Resale Ticket";
      case CTAActionTypeEnum.NAVIGATION_TO_BOOKING_JOURNEY:
        return "Navigate To Booking Journey";
    }
  }
  public static getCTAActionTypeEnums(): {
    label: string;
    value: CTAActionTypeEnum;
  }[] {
    const options: { label: string; value: CTAActionTypeEnum }[] = [
      {
        label: this.getEnumText(CTAActionTypeEnum.CANCEL_TICKET),
        value: CTAActionTypeEnum.CANCEL_TICKET,
      },
      {
        label: this.getEnumText(CTAActionTypeEnum.RESALE_TICKET),
        value: CTAActionTypeEnum.RESALE_TICKET,
      },
      {
        label: this.getEnumText(
          CTAActionTypeEnum.NAVIGATION_TO_BOOKING_JOURNEY
        ),
        value: CTAActionTypeEnum.NAVIGATION_TO_BOOKING_JOURNEY,
      },
    ];
    return options;
  }
}
