import { Grid, MenuItem, Stack, Typography } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import MediaAssets from "assets";
import {
  InfoComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { PortfolioPageStyleWrapper } from "../PortfolioPage.style";
import { portfolioInitialValues } from "../Page4PortfolioSections";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import MediaUploadComponent from "components/ui-elements/media-upload-component/MediaUploadComponent";
import { portfolioPageAction } from "redux-container/page-management/PagePortfolioRedux";

const actionDispatch = (dispatch: any) => {
  return {
    fileUploadRequest: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    fileDeleteRequest: (data: any) =>
      dispatch(FileUploadActions.fileDeleteRequest(data)),
    setUploadFileState: (key: string, value: any) =>
      dispatch(FileUploadActions.setUploadFileState(key, value)),
    setPortfolioPageState: (key: string, value: any) =>
      dispatch(portfolioPageAction.setPortfolioPageState(key, value)),
  };
};

const PromotionAndOfferCard = () => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } =
    useFormikContext<typeof portfolioInitialValues>();
  const {
    fileUploadRequest,
    fileDeleteRequest,
    setUploadFileState,
    setPortfolioPageState,
  } = actionDispatch(useDispatch());
  const {
    projectContentsData,
    imageDetails,
    isLoading,
    shouldFlashMessageOpen,
    data,
    error,
    isSuccess,
    isViewMode,
    fileId,
  } = useSelector((state: any) => ({
    isViewMode: state.pagePortfolio?.isViewMode,
    projectContentsData: state.projectContents?.projectContentsData,
    imageDetails: state.fileUpload?.imageDetails,
    isLoading: state.fileUpload?.isLoading,
    shouldFlashMessageOpen: state.fileUpload?.shouldFlashMessageOpen,
    data: state.fileUpload?.data,
    error: state.fileUpload?.error,
    isSuccess: state.fileUpload?.isSuccess,
    fileId: state.pagePortfolio?.fileId,
  }));

  const handleFileInputChange = (event: any) => {
    const file = event?.target.files[0];
    const uploadId = event?.target.id;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (e: any) => {
        data.binary = e.target.result;
        fileUploadRequest(data);
      };
      setPortfolioPageState("fileId", uploadId);
      reader.readAsArrayBuffer(file);
      setFieldValue(`promotionAndOffersMedia.value.url`, imageDetails?.url);
      setFieldValue(`promotionAndOffersMedia.value.mediaType`, file.type);
      setFieldValue(`promotionAndOffersMedia.value.size`, file.size);
      setFieldValue(`promotionAndOffersMedia.name`, file.name);
      setFieldValue(`promotionAndOffersMedia.key`, file.name);
      setUploadFileState("shouldFlashMessageOpen", true);
    } else {
      return;
    }
  };

  const handleImageDelete = () => {
    // fileDeleteRequest(values.promotionAndOffersMedia.name);
    setFieldValue("promotionAndOffersMedia.value.url", "");
    // setFieldValue("promotionAndOffersMedia.value.mediaType", "");
    // setFieldValue("promotionAndOffersMedia.value.size", "");
    // setFieldValue("promotionAndOffersMedia.name", "");
    // setFieldValue("promotionAndOffersMedia.key", "");
    // setUploadFileState("shouldFlashMessageOpen", true);
  };

  useEffect(() => {
    const fieldName = `promotionAndOffersMedia`;
    if (imageDetails && fileId === fieldName) {
      setFieldValue(`promotionAndOffersMedia.value.url`, imageDetails?.url);
      setUploadFileState("imageDetails", null);
      setUploadFileState("isLoading", false);
      setPortfolioPageState("fileId", "");
    }
  }, [imageDetails]);

  return (
    <PortfolioPageStyleWrapper>
      <Stack
        justifyContent="flex-start"
        spacing={2}
        className="field-container-box"
      >
        <Typography>Promotion and Offer Card</Typography>
        <SwitchComponent
          disabled={isViewMode}
          name={"isPromotionAndOfferActive"}
          checked={!!values.isPromotionAndOfferActive}
          onChange={handleChange}
        />
        <InfoComponent />
      </Stack>
      <Grid
        container
        className="field-container-box"
        rowSpacing={2}
        columnSpacing={4}
      >
        <Grid item xs={3}>
          <MediaUploadComponent
            disabled={isViewMode}
            loading={isLoading}
            ondrop={handleChange}
            onBlur={handleBlur}
            name={"promotionAndOffersMedia"}
            onChange={handleFileInputChange}
            previewimage={values?.promotionAndOffersMedia?.value?.url}
            cancelimage={handleImageDelete}
            fieldhelpertext={
              getIn(touched, "promotionAndOffersMedia.value.url") &&
              getIn(errors, "promotionAndOffersMedia.value.url")
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            label="Map Projects *"
            name="promotionAndOffersProjectContentId"
            select
            helpericon={MediaAssets.ic_info}
            value={values?.promotionAndOffersProjectContentId}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              readOnly: isViewMode,
            }}
            fieldhelpertext={
              values.isPromotionAndOfferActive &&
              errors.promotionAndOffersProjectContentId &&
              touched.promotionAndOffersProjectContentId &&
              errors.promotionAndOffersProjectContentId
            }
            error={Boolean(
              values.isPromotionAndOfferActive &&
                errors.promotionAndOffersProjectContentId &&
                touched.promotionAndOffersProjectContentId
            )}
          >
            {projectContentsData?.map((project: any) => (
              <MenuItem key={project.id} value={project.id}>
                {project.launchName}
              </MenuItem>
            ))}
          </TextFieldComponent>
        </Grid>
      </Grid>
    </PortfolioPageStyleWrapper>
  );
};

export default PromotionAndOfferCard;
