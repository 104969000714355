import { RouteConstants } from "routes/RouteConstants";
import GeneralInfo from "./customer-view-components/customer-overview-components/GeneralInfo";
import CustomerKYC from "./customer-view-components/customer-overview-components/CustomerKYC";
import OtherDocuments from "./customer-view-components/customer-overview-components/OtherDocuments";
import PaymentHistory from "./customer-view-components/customer-overview-components/PaymentHistory";

interface ICustomerSections {
  id?: number;
  title?: string;
  component?: React.ReactNode;
}

export const customerBreadcrumbRoutes = (
  customerName?: any,
  isViewMode?: any
) => {
  return [
    {
      name: "Customer Management",
      route: RouteConstants.customerManagementDashboard,
    },
    {
      name: "Customer List",
      route: RouteConstants.customerList,
    },
    {
      name: `Customer : ${customerName} `,
      route: RouteConstants.customerView,
    },
  ];
};

export const CustomerOverviewSections: ICustomerSections[] = [
  {
    id: 1,
    title: "General Info",
    component: <GeneralInfo />,
  },
  {
    id: 2,
    title: "Customer KYC",
    component: <CustomerKYC />,
  },
  {
    id: 3,
    title: "Other Documents",
    component: <OtherDocuments />,
  },
  {
    id: 4,
    title: "Payment History",
    component: <PaymentHistory />,
  },
];

export const paymentHistoryTableHeadings = [
  { heading: "Project ID" },
  { heading: "Project Name" },
  { heading: "Amount paid" },
  { heading: "Date of payment", isSortingColumn: true, sortingKey: "15" },
  { heading: "Actions" },
];

export const paymentHistoryDetails = [
  {
    projectID: 123,
    projectName: "Isle of blis",
    Milestone: "Payment Milestone 2",
    amountPaid: 20000,
    dateOfPayment: "10/02/2022",
  },
  {
    projectID: 122,
    projectName: "The Rush",
    Milestone: "Payment Milestone 1",
    amountPaid: 20000,
    dateOfPayment: "10/02/2022",
  },
  {
    projectID: 124,
    projectName: "Tomorrow Land",
    Milestone: "Payment Milestone 3",
    amountPaid: 20000,
    dateOfPayment: "10/02/2022",
  },
];
