import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import chatManagementActions from "redux-container/chat-management/ChatManagementRedux"
import chatManagementListActions from "redux-container/chat-management/ChatManagementListRedux";

export function* addChatManagement(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addChatManagementData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(chatManagementActions.addChatManagementDataSuccess(response.data));
    } else {
      yield put(chatManagementActions.addChatManagementDataFailure(response.data));
    }
  } catch (error) {
    yield put(chatManagementActions.addChatManagementDataFailure(error));
  }
}

export function* getChatManagement(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getChatManagementData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(chatManagementActions.getChatManagementDataSuccess(response.data));
    } else {
      yield put(chatManagementActions.getChatManagementDataFailure(response.data));
    }
  } catch (error) {
    yield put(chatManagementActions.getChatManagementDataFailure(error));
  }
}

export function* updateChatManagement(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateChatManagementData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(chatManagementActions.updateChatManagementDataSuccess(response.data));
    } else {
      yield put(chatManagementActions.updateChatManagementDataFailure(response.data));
    }
  } catch (error) {
    yield put(chatManagementActions.updateChatManagementDataFailure(error));
  }
}

// chat management List 
export function* chatManagementList(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getChatManagementList, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(chatManagementListActions.chatManagementListSuccess(response.data));
    } else {
      yield put(chatManagementListActions.chatManagementListFailure(response.data));
    }
  } catch (error) {
    yield put(chatManagementListActions.chatManagementListFailure(error));
  }
}
