import MyProfileDialog from "./MyProfileDialog";

export const myProfileDialogVariabes = {
  name: "Name: ",
  designation: "Designation: ",
  team: "Team: ",
  email: "Email Address: ",
  phoneNumber: "Phone Number: ",
};

export const myProfileDialogTitles = {
  myProfile: "My Profile",
  contactDetails: "Contact Details",
};
