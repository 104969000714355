import { Fragment } from "react";
import { RouteConstants } from "routes/RouteConstants";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import PageHeading from "components/page-heading/PageHeading";
import {
  Typography,
  Container,
  Stack,
  IconButton,
  Divider,
  styled,
  Box,
} from "@mui/material";
import styles from "./BulkUpload.module.css";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Formik, Form } from "formik";
import {
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import CancelIcon from "@mui/icons-material/Cancel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";

const rows = [
  {
    fileName: "file name",
    dateAndTime: "12th March 2022, 12:30PM",
  },
  {
    fileName: "file names",
    dateAndTime: "12th March 2022, 12:30PM",
  },
];

const BasicTable = () => {
  return (
    <TableContainer>
      <Table aria-label="bulk-upload-table">
        <TableHead>
          <TableRow>
            <TableCell className={styles["table-heading-text"]}>
              File Name
            </TableCell>
            <TableCell className={styles["table-heading-text"]} align="left">
              Date & Time
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.fileName}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Link className={styles["file-link"]} to="#">
                  {row.fileName}
                </Link>
              </TableCell>
              <TableCell align="left" className={styles["date-time"]} >{row.dateAndTime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BulkUpload = () => {
  const breadcrumbRoutes: any = [
    {
      name: "Project Management",
      route: RouteConstants.projectManagementDashboard,
    },
    {
      name: "Media Upload",
      route: RouteConstants.mediaUploadList,
    },
    {
      name: "Bulk Upload",
      route: RouteConstants.mediaBulkUpload,
    },
  ];

  const initialState = {
    imageUrl: "",
  };

  const CancelButtonStyle = styled(Box)({
    position: "relative",
    right: 35,
    marginTop: "15px",
    cursor: "pointer",
    zIndex: 1,
  });

  return (
    <Fragment>
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <PageHeading title="Project Management" />
      <Container>
        <div
          data-testid="bulk-upload-content"
          className={`${styles["bulk-upload-content"]} margin-bottom-30`}
        >
          <Typography
            variant="h3"
            component="h1"
            className={styles["bulk-upload-text"]}
            data-testid="bulk-upload-text"
          >
            Bulk Upload
          </Typography>

          <Stack
            className={`${styles["download-template-section"]} margin-bottom-30`}
            width="650px"
            direction="row"
            spacing={1}
          >
            <Typography variant="h5" component="h5">
              Download Template for Bulk Project Upload.
            </Typography>
            <div>
              <IconButton className={styles["download-icon"]}>
                <DownloadForOfflineIcon color="info" />
              </IconButton>
              <a
                className={styles["download-link-text"]}
                target="_blank"
                href="#"
              >
                Download Template
              </a>
            </div>
          </Stack>

          <Formik initialValues={initialState} onSubmit={(values) => {}}>
            {({
              values,
              setFieldValue,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Stack width="600px" mt="10px" spacing={1}>
                    <Stack>
                      <TextFieldComponent
                        type="file"
                        placeholder="Enter"
                        name={"imageUrl"}
                        fieldtitle="Upload File  *"
                        onBlur={handleBlur}
                        value={values.imageUrl}
                        required
                        onChange={handleChange}
                        className={styles["input-section"]}
                        inputProps={{ "data-testid": "file-upload-btn" }}
                      />
                      <CancelButtonStyle>
                        {values.imageUrl && (
                          <span>
                            <CancelIcon
                              aria-label="upload-cancel-btn"
                              className={styles["cancel-icon"]}
                              onClick={() => setFieldValue("imageUrl", "")}
                            />
                          </span>
                        )}
                      </CancelButtonStyle>
                    </Stack>

                    <div id={styles["upload-btn-section"]}>
                      <LoadingButtonComponent
                        className="btn btn-dark"
                        type="submit"
                        disabled={!values.imageUrl ? true : false}
                        variant="contained"
                        disableElevation
                        data-testid="upload-btn"
                        onClick={() =>
                          setTimeout(() => {
                            setFieldValue("imageUrl", "");
                          }, 300)
                        }
                      >
                        UPLOAD
                      </LoadingButtonComponent>
                    </div>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </div>

        <Divider />

        <div>
          <BasicTable />
        </div>
      </Container>
    </Fragment>
  );
};

export default BulkUpload;
