import React, { useState } from "react";
import AboutUsPage from "./sections/aboutUsPage/AboutUsPage";
import AboutUs from "./sections/aboutUsPage/sections/aboutUs/AboutUs";
import CorporatePhilosophy from "./sections/aboutUsPage/sections/corporatePhilosophy/CorporatePhilosophy";
import FoundersVision from "./sections/aboutUsPage/sections/foundersVision/FoundersVision";
import ProductCategory from "./sections/aboutUsPage/sections/productCategory/ProductCategory";
import StatsOverview from "./sections/aboutUsPage/sections/statsOverview/StatsOverview";
import Faq from "./sections/faq/Faq";
import SecurityTips from "./sections/securityTips/SecurityTips";
import TermsAndConditions from "./sections/termsAndCondition/TermsAndConditions";


interface IPage5ProfileSections {
    id: number;
    title: string;
    setStatus: any;
    component: React.ReactNode;
}


export const Page5ProfileSection = () : IPage5ProfileSections[] => {

    const [showAccordionId, setShowAccordionId] = useState(0)
    return [
        {
            id: 1,
            title: "Security Tips",
            setStatus : setShowAccordionId,
            component: showAccordionId === 1 ? <SecurityTips /> : <></>,
        },
        // {
        //     id: 2,
        //     title: "FAQs",
        //     component: <Faq />
        // },
        {
            id: 2,
            title: "Terms & Conditions",
            setStatus : setShowAccordionId,
            component: showAccordionId === 2 ? <TermsAndConditions /> : <></>,
        },
        {
            id: 3,
            title: "About Us Page",
            setStatus : setShowAccordionId,
            component: showAccordionId === 3 ? <AboutUsPage /> : <></>,
        },
    ]
};

export const Page5AboutUsSection = () : IPage5ProfileSections[] => {

    const [showAccordionId, setShowAccordionId] = useState(0)

    return [
        {
            id: 1,
            title: "About Us",
            setStatus : setShowAccordionId,
            component: showAccordionId === 1 ? <AboutUs /> : <></>,
        },
        {
            id: 2,
            title: "Founder's Vision",
            setStatus : setShowAccordionId,
            component: showAccordionId === 2 ? <FoundersVision /> : <></>,
        },
        {
            id: 3,
            title: "Corporate Philosophy",
            setStatus : setShowAccordionId,
            component:showAccordionId === 3 ? <CorporatePhilosophy /> : <></>,
        },
        {
            id: 4,
            title: "Product Category",
            setStatus : setShowAccordionId,
            component: showAccordionId === 4 ? <ProductCategory /> : <></>,
        },
        {
            id: 5,
            title: "Stats Overview",
            setStatus : setShowAccordionId,
            component: showAccordionId === 5 ? <StatsOverview /> : <></>,
        }
    ]
}
