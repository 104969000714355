import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import CachedIcon from "@mui/icons-material/Cached";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import DateAdapter from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MediaAssets from "../../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "./CustomerList.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CustomerActions } from "redux-container/customer-management/CustomersListAndDetailsRedux";
import { CardMedia, Stack } from "@mui/material";
import { CustomerEnumsUtils } from "enumerations/CustomerTypeEnums";
import projectListActions from "redux-container/project-management/ProjectListRedux";
import {
  AutocompleteComponent,
  InfoComponent,
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import Loader from "components/loader/Loader";
import Retry from "components/retry/Retry";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { SortingOrder } from "enumerations/SortingOrderTypeEnum";
import { exportAsExcel } from "utils/ExportFileUtils";
import { ActionTypeEnum } from "enumerations/ActionTypeEnum";
import { DeviceEnumsUtils } from "enumerations/DeviceTypeEnum";
import SyncIcon from "@mui/icons-material/Sync";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";

const actionDispatch = (dispatch: any) => {
  return {
    getCustomers: async (params: any) => {
      dispatch(CustomerActions.getCustomersRequest(params));
    },
    setCustomersState: (key: any, data: any) => {
      dispatch(CustomerActions.setCustomersState(key, data));
    },
    getProjectList: async (params: any) => {
      dispatch(projectListActions.projectListRequest(params));
    },
    customerDataRefreshRequest: async (params: any) => {
      dispatch(CustomerActions.customerDataRefreshRequest(params));
    },
  };
};

const CustomerList = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const customerTypes = CustomerEnumsUtils.getActionsEnums();
  const [selectedCustomers, setSelectedCustomers] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [openDatepicker, setOpenDatepicker] = useState(false);

  console.log("fromDate", fromDate);
  console.log("toDate", toDate);

  const [searchKey, setSearchKey] = useState("");
  const [columnSort, setColumnSort] = useState({
    sortingKey: "",
    sortingPriority: "",
    isActiveSortingColumn: "",
  });

  const { page, rowsPerPage } = useSelector((state: any) => {
    return {
      page: state?.customerListAndDetails?.page,
      rowsPerPage: state?.customerListAndDetails?.rowsPerPage,
    };
  });

  const {
    customers,
    totalCount,
    isLoading,
    shouldOpenFlashDialog,
    isSuccess,
    message,
  } = useSelector((state: any) => {
    return {
      customers: state?.customerListAndDetails?.customerData,
      totalCount: state?.customerListAndDetails?.totalCount,
      isLoading: state?.customerListAndDetails?.isLoading,
      shouldOpenFlashDialog:
        state?.customerListAndDetails?.shouldOpenFlashDialog,
      isSuccess: state?.customerListAndDetails?.isSuccess,
      message: state?.customerListAndDetails?.message,
    };
  });
  console.log("customers", customers);

  const publishedProjects = useSelector(
    (state: any) => state?.projectList?.data?.data
  );

  const {
    getCustomers,
    setCustomersState,
    getProjectList,
    customerDataRefreshRequest,
  } = actionDispatch(useDispatch());

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  //Loading Data (Customers data) while mounting and
  // every time after User applies any filters (Project Name,Contact type,Search key,Sorting key,page and number of rows per page) .
  useEffect(() => {
    const params = {
      size: rowsPerPage,
      index: page + 1,
      projectName: selectedProject?.launchName,
      contactType: selectedProjectType,
      searchKey: encodeURIComponent(searchKey),
      sortingKey: columnSort.sortingKey,
      sortingPriority: columnSort.sortingPriority,
      fromRegisteredDate: fromDate
        ? formatDateTime(fromDate, "YYYY-MM-DD")
        : null,
      toRegisteredDate: toDate ? formatDateTime(toDate, "YYYY-MM-DD") : null,
    };
    setSelectedCustomers([]);

    getCustomers(params);
    return () => {};
  }, [
    rowsPerPage,
    page,
    selectedProject,
    selectedProjectType,
    searchKey,
    columnSort,
    fromDate,
    toDate,
  ]);

  //Loading projects while mounting to use them in "project name" filter.
  useEffect(() => {
    let projectParam = {
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    getProjectList(projectParam);
    return () => {};
  }, []);

  //setting page and rowsPerPage to initial value if user Navigated to the list from Side menu.
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setCustomersState("page", 0);
      setCustomersState("rowsPerPage", 10);
      navigate(location.pathname, { replace: true });
    }

    return () => {};
  }, []);

  //
  const breadcrumbRoutes: any = [
    {
      name: "Customer Management",
      route: RouteConstants.customerManagementDashboard,
    },
    {
      name: "Customer List",
      route: RouteConstants.customerList,
    },
  ];

  //table headings (heading objects with name,boolean key for whether it is sorting column or not,and sorting key for an API call )
  const tableHeadings = [
    { heading: "Image" },
    // { heading: "CRM ID" },
    // { heading: "CMS ID" },
    { heading: "Customer Name" },
    { heading: "Customer Type" },
    { heading: "Device Type" },
    { heading: "Logged In" },
    { heading: "Project Name" },
    { heading: "Email Address" },
    { heading: "Phone Number" },
    { heading: "Investments", isSortingColumn: true, sortingKey: "15" },
    { heading: "Registered On", isSortingColumn: true, sortingKey: "8" },
    { heading: "Last Login", isSortingColumn: true, sortingKey: "9" },
    { heading: "LastActive On", isSortingColumn: true, sortingKey: "10" },
    { heading: "Details" },
  ];

  //handle change function for page in Pagination.
  const handleChangePage = (event: unknown, newPage: number) => {
    setCustomersState("page", newPage);
  };

  //handle change function for number of elements per Page in Pagination.
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomersState("rowsPerPage", Number(event.target.value));
    setCustomersState("page", 0);
  };

  //handle change for "Type of Customer" filter.
  const handleTypeChange = (event: SelectChangeEvent) => {
    setSelectedProjectType(event.target.value as string);
    if (page !== 0) {
      setCustomersState("page", 0);
    }
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = customers.map((data: any) => data.id);
      setSelectedCustomers(newSelecteds);
      return;
    }
    setSelectedCustomers([]);
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selectedCustomers.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCustomers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCustomers.slice(1));
    } else if (selectedIndex === selectedCustomers.length - 1) {
      newSelected = newSelected.concat(selectedCustomers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCustomers.slice(0, selectedIndex),
        selectedCustomers.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomers(newSelected);
  };

  const isSelected = (email: string) => selectedCustomers.indexOf(email) !== -1;

  //navigation to view Customer Detail.
  const viewCustomer = (customerData: any) => () => {
    navigate(RouteConstants.customerView, {
      state: {
        ...location?.state,
        customerData,
        isNavigatedFromList: true,
      },
    });
  };
  const clearSelected = () => {
    setSelectedCustomers([]);
  };
  const handleClearButton = (): boolean => {
    if (
      selectedProject === null &&
      selectedProjectType === "" &&
      fromDate == null &&
      toDate == null
    ) {
      return false;
    }
    return true;
  };

  //function to Reset the filters applied by User.
  const handleResetFilter = () => {
    setSelectedProject(null);
    setSelectedProjectType("");
    setCustomersState("page", 0);
    setFromDate(null);
    setToDate(null);
  };

  //handle change for search key filter.
  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 0) {
      setCustomersState("page", 0);
    }
  };
  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case ActionTypeEnum?.Export:
        handleExportAsExcel();
        break;
    }
  };

  const handleExportAsExcel = () => {
    if (selectedCustomers.length === 0) {
      alert("You have not selected any Customers");
      return;
    }
    let exportingCustomers: any = [];
    if (customers?.length) {
      selectedCustomers?.map((selectedCustomer: any) => {
        let localSelectedItem = customers.find(
          (item: any) => item.id == selectedCustomer
        );
        exportingCustomers.push({
          firstName: localSelectedItem?.firstName
            ? localSelectedItem?.firstName
            : "----",
          lastName: localSelectedItem?.lastName
            ? localSelectedItem?.lastName
            : "----",
          id: localSelectedItem.id ? localSelectedItem.id : "----",
          crmId: localSelectedItem.crmId ? localSelectedItem.crmId : "----",
          showPushNotifications: localSelectedItem.showPushNotifications
            ? localSelectedItem.showPushNotifications
            : "----",
          dateOfBirth: localSelectedItem.dateOfBirth
            ? localSelectedItem.dateOfBirth
            : "----",
          isKycComplete: localSelectedItem.isKycComplete
            ? localSelectedItem.isKycComplete
            : "----",
          isProfileComplete: localSelectedItem?.isProfileComplete
            ? localSelectedItem?.isProfileComplete
            : "----",
          lastBlockedAt: localSelectedItem?.lastBlockedAt
            ? localSelectedItem?.lastBlockedAt
            : "----",
          createdAt: localSelectedItem?.createdAt
            ? localSelectedItem?.createdAt
            : "----",
          updatedAt: localSelectedItem?.updatedAt
            ? localSelectedItem?.updatedAt
            : "----",
          Investments: localSelectedItem?.investmentCount,
          project_names: `${
            localSelectedItem?.investmentCount
              ? localSelectedItem?.projectnames?.map(
                  (project: any) => `${project} `
                )
              : "----"
          }`,
          amountPendingOngoingInvestments:
            localSelectedItem?.amountPendingOngoingInvestments
              ? localSelectedItem?.amountPendingOngoingInvestments
              : "----",
          numberOfProductsOngoingInvestments:
            localSelectedItem?.numberOfProductsOngoingInvestments
              ? localSelectedItem?.numberOfProductsOngoingInvestments
              : "----",
          amountInvestedOngoingInvestments:
            localSelectedItem?.amountInvestedOngoingInvestments
              ? localSelectedItem?.amountInvestedOngoingInvestments
              : "----",
          areaSqftOngoingInvestments:
            localSelectedItem?.areaSqftOngoingInvestments
              ? localSelectedItem?.areaSqftOngoingInvestments
              : "----",
          numberOfProductsCompletedInvestments:
            localSelectedItem?.numberOfProductsCompletedInvestments
              ? localSelectedItem?.numberOfProductsCompletedInvestments
              : "----",
          amountInvestedCompletedInvestments:
            localSelectedItem?.amountInvestedCompletedInvestments
              ? localSelectedItem?.amountInvestedCompletedInvestments
              : "----",
          totalCount: localSelectedItem?.totalCount
            ? localSelectedItem?.totalCount
            : "----",
          areaSqftCompletedInvestments:
            localSelectedItem?.areaSqftCompletedInvestments
              ? localSelectedItem?.areaSqftCompletedInvestments
              : "----",
          customer_Type: parseInt(localSelectedItem?.contactType)
            ? CustomerEnumsUtils.getEnumText(
                parseInt(localSelectedItem?.contactType)
              )
            : "----",
          Device_Type: localSelectedItem?.deviceType
            ? DeviceEnumsUtils.getEnumText(localSelectedItem?.deviceType)
            : "----",
          App_Downloaded:
            typeof localSelectedItem?.isAppDownloaded == "boolean"
              ? localSelectedItem?.isAppDownloaded
                ? "YES"
                : "NO"
              : "----",
          lastLogin: localSelectedItem?.lastLogin
            ? localSelectedItem?.lastLogin
            : "----",
          lastActiveAt: localSelectedItem?.lastActiveAt
            ? localSelectedItem?.lastActiveAt
            : "----",
          firstLoginDate: localSelectedItem?.firstLoginDate
            ? localSelectedItem?.firstLoginDate
            : "----",
          isInvalidOtpCount: localSelectedItem?.isInvalidOtpCount
            ? localSelectedItem?.isInvalidOtpCount
            : "----",
          sendOtpCount: localSelectedItem?.sendOtpCount
            ? localSelectedItem?.sendOtpCount
            : "----",
          whatsappConsent: localSelectedItem?.whatsappConsent
            ? localSelectedItem?.whatsappConsent
            : "----",
          phoneNumber: localSelectedItem?.phoneNumber
            ? localSelectedItem?.phoneNumber
            : "----",
          profilePictureUrl: localSelectedItem?.profilePictureUrl
            ? localSelectedItem?.profilePictureUrl
            : "----",
          otp: localSelectedItem?.otp ? localSelectedItem?.otp : "----",
          verificationStatus: localSelectedItem?.verificationStatus
            ? localSelectedItem?.verificationStatus
            : "----",
          country: localSelectedItem?.country
            ? localSelectedItem?.country
            : "----",
          state: localSelectedItem?.state ? localSelectedItem?.state : "----",
          city: localSelectedItem?.city ? localSelectedItem?.city : "----",
          facilityUrl: localSelectedItem?.facilityUrl
            ? localSelectedItem?.facilityUrl
            : "----",
          email: localSelectedItem?.email ? localSelectedItem?.email : "----",
          countryCode: localSelectedItem?.countryCode
            ? localSelectedItem?.countryCode
            : "----",
          salutation: localSelectedItem?.salutation
            ? localSelectedItem?.salutation
            : "----",
          gender: localSelectedItem?.gender
            ? localSelectedItem?.gender
            : "----",
          houseNumber: localSelectedItem?.houseNumber
            ? localSelectedItem?.houseNumber
            : "----",
          streetAddress: localSelectedItem?.streetAddress
            ? localSelectedItem?.streetAddress
            : "----",
          locality: localSelectedItem?.locality
            ? localSelectedItem?.locality
            : "----",
          pincode: localSelectedItem?.pincode
            ? localSelectedItem?.pincode
            : "----",
        });
      });
    }
    exportAsExcel(exportingCustomers, "Customers", "Sheet1");
    setSelectedCustomers([]);
  };
  const handleCloseFlashMessageDialog = () => {
    setCustomersState("shouldOpenFlashDialog", false);
    setCustomersState("isSuccess", false);
    setCustomersState("message", "");
  };

  const renderLoader = () => {
    return <Loader />;
  };
  const renderRetry = () => {
    return (
      <Retry
        message={"Error"}
        retryHandler={() => {
          return "";
        }}
      />
    );
  };
  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_add_user}
        altText=""
        message="You haven't created or invited any Customers yet. Create your first Customer."
      />
    );
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Customers
            {`(${
              totalCount
                ? totalCount
                : customers?.length
                ? customers?.length
                : 0
            })`}
            {selectedCustomers?.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedCustomers?.length} Customers(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl className="action-dropdown">
              <InputLabel>Actions</InputLabel>
              <Select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
                // IconComponent={() => (
                //   <ExpandMoreRoundedIcon className="dropdown-icon" />
                // )}
              >
                <MenuItem value={1}>{"Export"}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <Grid container>
          <Grid
            spacing={2}
            item
            container
            justifyContent={"flex-start"}
            md={10.5}
            lg={10.5}
            xl={10.5}
          >
            {/* <Grid container spacing={1} md={8} lg={8} xl={8}> */}
            <Grid
              item
              md={1}
              lg={1}
              xl={0.5}
              className="margin-left-10 filter-heading-holder"
            >
              <span className="filter-heading">Filters:</span>
            </Grid>

            <Grid item md={2} lg={2} xl={1.5}>
              <FormControl className="action-dropdown">
                <TextFieldComponent
                  select
                  value={selectedProjectType}
                  label="Customer Type"
                  onChange={handleTypeChange}
                  sx={{ width: "160px" }}
                >
                  {customerTypes.map((customerType) => {
                    return (
                      <MenuItem
                        value={customerType.value}
                        key={customerType.value}
                      >
                        {customerType.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <AutocompleteComponent
                  options={publishedProjects}
                  getOptionLabel={(option: any) =>
                    option?.generalInfoEscalationGraph?.crmProjectName
                      ? option?.launchName +
                        ` (${option?.generalInfoEscalationGraph?.crmProjectName})`
                      : option?.launchName
                  }
                  label="Project Name (CRM Project Name)"
                  onChange={(
                    event: React.SyntheticEvent,
                    newValue: any | null
                  ) => {
                    setSelectedProject(newValue);
                    if (page !== 0) {
                      setCustomersState("page", 0);
                    }
                  }}
                  value={selectedProject}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                />
              </FormControl>
            </Grid>

            <Grid item md={2} lg={2} xl={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="From Date"
                  inputFormat="yyyy-MM-dd"
                  mask={"____-__-__"}
                  value={fromDate}
                  maxDate={toDate === null ? new Date() : toDate}
                  onChange={(newDate) => {
                    setCustomersState("page", 0);
                    setFromDate(newDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              mt={Boolean(!fromDate) ? "-1.6rem" : ""}
              md={2}
              lg={2}
              xl={2}
            >
              {Boolean(!fromDate) && (
                <Stack mb={"0.4rem"} justifyContent={"flex-end"}>
                  <InfoComponent infoContent="Please select from date first" />
                </Stack>
              )}
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  disabled={
                    Boolean(!fromDate) ||
                    fromDate?.setHours(0, 0, 0, 0) ===
                      new Date().setHours(0, 0, 0, 0)
                  }
                  label="To Date"
                  inputFormat="yyyy-MM-dd"
                  mask={"____-__-__"}
                  value={toDate}
                  maxDate={new Date()}
                  minDate={fromDate}
                  onChange={(newDate: any) => {
                    setToDate(newDate);
                    setCustomersState("page", 0);
                  }}
                  onOpen={() => setOpenDatepicker(true)}
                  onClose={() => setOpenDatepicker(false)}
                  open={openDatepicker}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            {handleClearButton() ? (
              <Grid
                item
                md={0.5}
                lg={0.5}
                xl={0.5}
                className="filter-heading-holder"
                style={{ position: "relative" }}
              >
                <span
                  onClick={handleResetFilter}
                  className="clear-filter"
                  style={{ position: "absolute", top: 32 }}
                >
                  Clear
                </span>
              </Grid>
            ) : null}
            {/* </Grid> */}
          </Grid>
          <Grid
            item
            container
            md={1.5}
            lg={1.5}
            xl={1.5}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "end",
            }}
          >
            <Grid
              item
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginRight: "15px",
              }}
            >
              <LoadingButtonComponent
                onClick={() => {
                  console.log("clicked");

                  customerDataRefreshRequest({ option: 1 });
                }}
              >
                Fetch
                <SyncIcon />
              </LoadingButtonComponent>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedCustomers.length > 0 &&
                      selectedCustomers.length < customers.length
                    }
                    checked={
                      customers.length > 0 &&
                      selectedCustomers.length === customers.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all customers",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell
                    key={index}
                    className="table-header-text"
                    // style={item.isSortingColumn ? { minWidth: 150 } : {}}
                  >
                    <>
                      {item.heading}
                      {item.isSortingColumn ? (
                        <>
                          {columnSort.sortingPriority ===
                            SortingOrder.Descending &&
                          columnSort.isActiveSortingColumn === item.heading ? (
                            <IconButton
                              onClick={() => {
                                setColumnSort({
                                  sortingKey: item.sortingKey,
                                  sortingPriority: SortingOrder.Ascending,
                                  isActiveSortingColumn: item.heading,
                                });
                                if (page !== 0) {
                                  setCustomersState("page", 0);
                                }
                              }}
                            >
                              <ArrowDropUpIcon
                                fontSize={"large"}
                                style={{
                                  position: "relative",
                                  // top: "16px",
                                  right: "10px",
                                  fontSize: "3rem",
                                  color:
                                    columnSort.isActiveSortingColumn ===
                                    item.heading
                                      ? "#676ac0"
                                      : "rgb(0 0 0 / 87%)",
                                }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setColumnSort({
                                  sortingKey: item.sortingKey,
                                  sortingPriority: SortingOrder.Descending,
                                  isActiveSortingColumn: item.heading,
                                });
                                if (page !== 0) {
                                  setCustomersState("page", 0);
                                }
                              }}
                            >
                              <ArrowDropDownIcon
                                fontSize={"large"}
                                style={{
                                  position: "relative",
                                  // top: "10px",
                                  right: "10px",
                                  fontSize: "3rem",
                                  color:
                                    columnSort.isActiveSortingColumn ===
                                    item.heading
                                      ? "#676ac0"
                                      : "rgb(0 0 0 / 87%)",
                                }}
                              />
                            </IconButton>
                          )}
                        </>
                      ) : null}
                    </>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {customers?.length > 0 ? (
              <TableBody>
                {customers?.map((customer: any) => {
                  const isItemSelected = isSelected(customer?.id);
                  return (
                    <TableRow
                      key={customer?.crmId}
                      className="table-data-row"
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" style={{ minWidth: 80 }}>
                        <Checkbox
                          onClick={(event) => handleClick(event, customer?.id)}
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {customer?.profilePictureUrl ? (
                          <img
                            src={customer?.profilePictureUrl}
                            alt=""
                            className={styles["customer-image"]}
                          />
                        ) : (
                          <AccountBoxIcon
                            style={{ height: "3rem", width: "3rem" }}
                          />
                        )}
                        {/* <CardMedia
                        className={styles["media-area"]}
                        component={"img"}
                        alt="Image"
                        src={customer?.profilePictureUrl}
                      /> */}
                      </TableCell>
                      {/* <TableCell
                        className="table-data-text"
                        style={{ minWidth: "150px", maxWidth: "190px" }}
                      >
                        {customer?.crmId}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {`#ID-${customer?.id}`}
                      </TableCell> */}
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150 }}
                      >
                        {customer?.firstName
                          ? `${customer?.firstName} ${customer?.lastName}`
                          : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {parseInt(customer?.contactType)
                          ? CustomerEnumsUtils.getEnumText(
                              parseInt(customer?.contactType)
                            )
                          : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {customer?.deviceType
                          ? DeviceEnumsUtils.getEnumText(customer?.deviceType)
                          : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {typeof customer?.isAppDownloaded == "boolean"
                          ? customer?.isAppDownloaded
                            ? "YES"
                            : "NO"
                          : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ width: 250 }}
                      >
                        {customer?.investmentCount
                          ? customer?.projectnames?.map((project: any) => {
                              return <div>{project}</div>;
                            })
                          : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {customer?.email ? customer?.email : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 160 }}
                      >
                        {customer?.phoneNumber
                          ? `${customer.countryCode} ${customer?.phoneNumber}`
                          : null}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 180, textAlign: "center" }}
                      >
                        {customer?.investmentCount}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 200 }}
                      >
                        {customer?.firstLoginDate
                          ? formatDateTime(
                              customer?.firstLoginDate,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        {customer?.lastLogin
                          ? formatDateTime(
                              customer?.lastLogin,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "----"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 200 }}
                      >
                        {customer?.lastActiveAt
                          ? formatDateTime(
                              customer?.lastActiveAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "----"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={viewCustomer(customer)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Customers Found</div>
            )}
          </Table>
        </TableContainer>

        {!searchKey && customers?.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[
              10,
              50,
              100,
              { value: totalCount, label: "All" },
            ]}
            component="div"
            count={totalCount ? totalCount : customers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const shouldProvidePlaceHolder = () => {
    if (
      (customers?.length === 0 || customers?.length === undefined) &&
      !handleClearButton() &&
      searchKey === "" &&
      isLoading === false
    ) {
      return false;
    } else {
      return true;
    }
  };

  const render = () => {
    if (
      (customers?.length > 0 || handleClearButton() || searchKey !== "") &&
      isLoading === false
    ) {
      return renderTable();
    } else if (
      (customers?.length === 0 || customers?.length === undefined) &&
      !handleClearButton() &&
      searchKey === "" &&
      isLoading === false
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    } else if (false) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <PageHeading title="Customer Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder={shouldProvidePlaceHolder() ? "Search Customers" : ""}
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>

      <FlashMessageDialog
        shouldOpen={shouldOpenFlashDialog}
        content={message}
        isSuccess={isSuccess}
        cancelHandler={handleCloseFlashMessageDialog}
      />
    </Fragment>
  );
};

export default CustomerList;
