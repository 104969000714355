import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Link,
  ListItem,
  Stack,
} from "@mui/material";
import {
  AutocompleteComponent,
  ButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { FC, Fragment, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import NewFAQBox from "./NewFAQBox";
import { FieldArray, useFormikContext, getIn } from "formik";
import { IObjectProps } from "pages/user-management/user-creation/UserCreationInitialValues";
import { FAQInitialValues } from "../FAQInitialValues";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { GetAllCategoriesAction } from "redux-container/faq/GetAllCategoriesRedux";
import { GenealFaqActions } from "redux-container/faq/GeneralFaqRedux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FaqCategoryEditDialog from "../FaqCategoryEditDialog";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { RouteConstants } from "routes/RouteConstants";

//all dispatch action
const actionDispatch = (dispatch: any) => {
  return {
    getCategories: () =>
      dispatch(GetAllCategoriesAction.getAllCategoriesRequest()),
    deleteCategory: (data: any) =>
      dispatch(GetAllCategoriesAction.deleteCategoryRequest(data)),
    setCategoriesState: (key: any, value: any) =>
      dispatch(GetAllCategoriesAction.setCategoriesState(key, value)),
    addNewCategory: (data: any) =>
      dispatch(GetAllCategoriesAction.addCategoryRequest(data)),
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
  };
};

const GeneralFAQ: FC<any> = (props) => {
  const { isEditLocale, settingIconAble, previousValues, setPreviousValues } =
    props;
  const {
    getCategories,
    deleteCategory,
    setCategoriesState,
    addNewCategory,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());
  const dispatch = useDispatch();
  const location: any = useLocation();

  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<IObjectProps>();
  const { responseData, categories, isSuccess, isLoading } = useSelector(
    (state: any) => state?.allCategoriesForFaq
  );

  const { modules, ipAddress } = useSelector((state: any) => ({
    modules: state?.rolesAndModules?.module,
    ipAddress: state?.auditLog?.ipAddress,
  }));
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [deletedId, setDeletedId] = useState(0);
  const [openFaqEditDialog, setOpenEdidDialog] = useState(false);
  const [editCategory, setEditCategory] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [categoryName, setCategoryName] = useState("");
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [categoryValue, setCategoryValue] = useState(location?.state?.category);
  const handleClickOpenCategoryEdit = (category: any) => {
    // to open the edit category
    setOpenEdidDialog(true);
    setEditCategory(category.name);
    setCategoryName(category.name);
    setCategoryId(category.id);
  };

  const handleEditCategoryInputChange = (e: any) => {
    let newValue = e.target.value||""
    const onlyChars = newValue.replace(/[^a-zA-Z\s]+/g, "");
    setEditCategory(onlyChars);
  };
  const handleResetCategoryField = () => {
    setEditCategory("");
  };
  const handleClickOpenCategoryClose = () => {
    // to close the edit category
    setOpenEdidDialog(false);
  };

  const handleOpenConfirmDialog = (e: any) => {
    setIsConfirmDeleteDialogOpen(!isConfirmDeleteDialogOpen);
  };

  // useEffect(() => {
  //   getCategories();
  // }, []);

  const handleCloseConfirmDialog = (id: any) => {
    setIsConfirmDeleteDialogOpen(false);
    setDeletedId(id);
  };

  const deletedCategory = (e: any) => {
    deleteCategory(deletedId);
    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Resources Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress,
      moduleId: moduleObject?.id,
      route: null,
      auditedObject: null,
      modulesAudited: [
        {
          itemAffected: `Category "${categoryName}" - id: ${deletedId} Deleted.`,
          newValue: "",
          oldValue: "",
        },
      ],
      status: AuditStatus.DELETED,
    };
    postAuditLogRequest(postAuditRequestbody);
    setIsConfirmDeleteDialogOpen(false);
  };

  const auditForCategoryNameUpdate = (newValue: string) => {
    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Resources Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress,
      moduleId: moduleObject?.id,
      route: RouteConstants.faqList,
      auditedObject: null,
      modulesAudited: [
        {
          itemAffected: `Category Name`,
          newValue: newValue,
          oldValue: categoryName,
        },
      ],
      status: AuditStatus.EDITED,
    };
    postAuditLogRequest(postAuditRequestbody);
  };

  // useEffect(() => {
  //   if (isSuccess) {
  //     getCategories();
  //     setCategoriesState("isSuccess", false);
  //   }
  // }, [isSuccess]);

  const handleCloseFlashMessageDialog = () => {
    setCategoriesState("responseData", null);
    setOpenEdidDialog(false);
  };
  useEffect(() => {
    if (location?.state?.category) {
      setCategoryValue(location?.state?.category);
      setFieldValue("categoryId", location?.state?.category?.name);
      setIsCategorySelected(true);
    }
  }, [values]);

  const [shouldSetFaqsLength, setShouldSetFaqsLength] = useState(false);

  useEffect(() => {
    if (
      location?.state?.isNewFaqForm &&
      values?.faqs?.length > 1 &&
      !shouldSetFaqsLength
    ) {
      setFieldValue("faqs", values?.faqs?.slice(0, 1));
    }
  }, [
    location?.state?.isNewFaqForm,
    values?.faqs?.length,
    shouldSetFaqsLength,
  ]);

  return (
    <Fragment>
      <Grid container mt={"0.2rem"} rowSpacing={4} columnSpacing={3}>
        {location.state?.isEditField ? null : (
          <Grid item xs={4} md={3} lg={3} xl={3}>
            <AutocompleteComponent
              name={"categoryId"}
              onBlur={handleBlur}
              value={categoryValue}
              disabled={location?.state?.category ? true : false}
              options={
                categories?.length > 0 &&
                categories?.filter((item: any) => item?.status === "1001")
              }
              label="Category *"
              size="medium"
              loading={isLoading && isLoading}
              error={errors?.categoryId && touched?.categoryId ? true : false}
              helpertext={
                errors?.categoryId && touched?.categoryId
                  ? errors?.categoryId
                  : ""
              }
              isOptionEqualToValue={(
                option: { name: string },
                value: { name: string }
              ) => option?.name === value?.name}
              onChange={(event: React.SyntheticEvent, newValue: any) => {
                if (newValue?.name) {
                  setIsCategorySelected(true);
                } else {
                  setIsCategorySelected(false);
                }
                setFieldValue("categoryId", newValue?.name);
              }}
              getOptionLabel={(option: any) => option?.name}
              getOptionDisabled={(option: any) => option === categories[0]}
              noOptionsText={
                <div>
                  <Link
                    sx={{
                      color: "black",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const reqBody = {
                        name: values?.categoryId,
                        categoryType: "2001",
                      };
                      addNewCategory(reqBody);
                    }}
                  >
                    Create
                  </Link>
                  <span style={{ color: "#676ac0" }}>
                    {" "}
                    {` ${values?.categoryId}`}
                  </span>
                </div>
              }
              onInputChange={(event: any, newValue: any) => {
                setIsCategorySelected(false);
                const onlyChars = newValue.trim().replace(/^\s+|\s+$|[^A-Za-z\s]/g, "");
                setFieldValue("categoryId", onlyChars);
              }}
              renderOption={(props: any, option: any) => (
                <Fragment>
                  <div>
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Box
                        {...props}
                        sx={{
                          width: `${option == categories[0] ? "100%" : "60%"}`,
                        }}
                      >
                        {option.name}
                      </Box>
                      {option !== categories[0] && option !== categories[0] && (
                        <div>
                          <IconButton
                            onClick={() => {
                              handleClickOpenCategoryEdit(option);
                            }}
                            disabled={values?.categoryId === option?.name}
                          >
                            <ModeEditOutlineOutlinedIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            color="error"
                            disabled={values?.categoryId === option?.name}
                            onClick={(e: any) => {
                              handleOpenConfirmDialog(e);
                              setDeletedId(option.id);
                              setCategoryName(option?.name);
                            }}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </div>
                      )}
                    </Stack>
                  </div>
                </Fragment>
              )}
            />
          </Grid>
        )}

        <ConfirmationDialog
          shouldOpen={isConfirmDeleteDialogOpen}
          title="Delete Catagory?"
          content={`This action will delete the  ${categoryName} Category and related FAQs permanently. Are you sure you want to continue?`}
          okText="Delete"
          cancelHandler={handleCloseConfirmDialog}
          okHandler={deletedCategory}
        />
      </Grid>
      {isCategorySelected ? (
        <FieldArray
          name="faqs"
          render={({ push, remove, form: { values } }) => (
            <Box>
              {values.faqs.map(
                (
                  field: { faqQuestion: string; faqAnswer: string },
                  fieldIndex: number
                ) => {
                  const questionFieldName = `faqs[${fieldIndex}].faqQuestion.question`;
                  const answerFieldName = `faqs[${fieldIndex}].faqAnswer.answer`;
                  const priorityFieldName = `faqs[${fieldIndex}].priority`;
                  const priorityFieldValue = values?.faqs[fieldIndex]?.priority;
                  const questionFieldValue =
                    values?.faqs[fieldIndex]?.faqQuestion?.question;
                  const answerFieldValue =
                    values?.faqs[fieldIndex]?.faqAnswer?.answer;
                  const touchedQuestion = getIn(touched, questionFieldName);
                  const errorsQuestion = getIn(errors, questionFieldName);
                  const errorAnsfield = getIn(errors, answerFieldName);
                  const touchedAnsfield = getIn(touched, answerFieldName);
                  const isEditName = `faqs[${fieldIndex}].isEdit`;
                  const isEdit = values?.faqs[fieldIndex]?.isEdit;
                  const faqId = values?.faqs[fieldIndex]?.faqId;
                  return (
                    <NewFAQBox
                      key={fieldIndex}
                      questionFieldName={questionFieldName}
                      answerFieldName={answerFieldName}
                      questionFieldValue={questionFieldValue}
                      answerFieldValue={answerFieldValue}
                      priorityFieldName={priorityFieldName}
                      priorityFieldValue={priorityFieldValue}
                      fieldId={fieldIndex}
                      values={values}
                      handleDelete={remove}
                      touchFAQquestion={touchedQuestion}
                      errorFAQquestion={errorsQuestion}
                      touchFAQAnswer={touchedAnsfield}
                      errorFAQAnswer={errorAnsfield}
                      isEditName={isEditName}
                      isEdit={isEdit}
                      faqId={faqId}
                      isEditLocale={isEditLocale}
                      settingIconAble={settingIconAble}
                      previousValuesOfCreation={previousValues}
                      setPreviousValuesOfCreation={setPreviousValues}
                    />
                  );
                }
              )}
              <Box mt={"1rem"}>
                {location.state?.isEditField ? null : (
                  <ButtonComponent
                    variant="contained"
                    startIcon={<AddIcon fontSize="small" />}
                    onClick={() => {
                      setShouldSetFaqsLength(true);
                      push(FAQInitialValues.faqs[0]);

                      let FaqArray = previousValues?.faqs;
                      FaqArray.push(FAQInitialValues.faqs[0]);
                      setPreviousValues({ ...previousValues, faqs: FaqArray });
                    }}
                  >
                    Create New FAQ
                  </ButtonComponent>
                )}
              </Box>
            </Box>
          )}
        />
      ) : (
        ""
      )}
      <FaqCategoryEditDialog
        open={openFaqEditDialog}
        handleClose={handleClickOpenCategoryClose}
        editCategory={editCategory}
        handleInputChange={handleEditCategoryInputChange}
        handleResetCategoryField={handleResetCategoryField}
        categoryId={categoryId}
        auditForCategoryNameUpdate={auditForCategoryNameUpdate}
      />
    </Fragment>
  );
};

export default GeneralFAQ;
