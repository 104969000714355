import {
  Avatar,
  Container,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ButtonComponent,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import React, { Fragment, useState } from "react";
import { Form, Formik, getIn } from "formik";
import MediaAssets from "assets";
import styles from "./LatestMediaGallery.module.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MediaUploadDialog from "../media-gallery/image-upload-dialog/ImageUploadDialog";
import MediaGalleryTable from "../media-gallery/MediaGalleryTable";
import { latestMediaUploadInitialState } from "./LatestMediaGalleryConstants";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

export default function LatestMediaGallery() {
  const [openMediaUploadDialog, setOpenMediaUploadDialog] = useState(false);
  const [mediaUploadContentType, setMediaUploadContentType] = useState("");
  const [mediaUploadFor, setMediaUploadFor] = useState("");
  const [singleMediaUpdateData, setSingleMediaUpdateData] = useState<any>();
  const [isViewMode, setIsViewMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    errors,
    touched,
    isValid,
  } = useFormikContext<IInitinalValueProjectContent>();
  const isProjectViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );
console.log(values,"fffffffffffffffffffffffffffffffffffff")
  const handleOpenMediaUploadDialog = (
    setFieldValue: any,
    mediaUploadFor: any,
    mediaUploadContentType?: any
  ) => {
    setMediaUploadFor(mediaUploadFor);
    setMediaUploadContentType(mediaUploadContentType); //image or video
    setOpenMediaUploadDialog(true);
  };

  return (
    <Fragment>
      <Container>
        {openMediaUploadDialog && (
          <MediaUploadDialog
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            isViewMode={isViewMode}
            setIsViewMode={setIsViewMode}
            shouldOpen={openMediaUploadDialog}
            title="Upload New Image"
            cancelHandler={() => setOpenMediaUploadDialog(false)}
            showCategory={true}
            uploadContentType={mediaUploadContentType}
            mediaUploadFor={mediaUploadFor}
            singleMediaUpdateData={singleMediaUpdateData}
          />
        )}

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Latest Media Gallery
          </Typography>
          <SwitchComponent
            name={"isLatestMediaGalleryActive"}
            checked={values?.isLatestMediaGalleryActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <Grid container spacing={2} mt={3} mb={3}>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <TextFieldComponent
              required
              label={"Section Heading"}
              type="text"
              placeholder="Enter"
              name={"latestMediaGallerySectionHeading"}
              inputProps={{
                maxLength: 25,
              }}
              onBlur={handleBlur}
              value={values?.latestMediaGallerySectionHeading}
              onChange={handleChange}
              fieldhelpertext={
                getIn(touched, `latestMediaGallerySectionHeading`) &&
                getIn(errors, `latestMediaGallerySectionHeading`)
              }
              error={Boolean(
                getIn(errors, `latestMediaGallerySectionHeading`) &&
                  getIn(touched, `latestMediaGallerySectionHeading`)
              )}
              disabled={isProjectViewMode}
            />
          </Grid>

          {/* <Grid item xs={3} sm={3} md={3} lg={3}>
            <TextFieldComponent
              required
              label={"Sub Heading"}
              type="text"
              placeholder="Enter"
              name={"latestMediaGallerySubHeading"}
              inputProps={{
                maxLength: 25,
              }}
              onBlur={handleBlur}
              value={values?.latestMediaGallerySubHeading}
              onChange={handleChange}
              fieldhelpertext={
                getIn(touched, `latestMediaGallerySubHeading`) &&
                getIn(errors, `latestMediaGallerySubHeading`)
              }
              error={Boolean(
                getIn(errors, `latestMediaGallerySubHeading`) &&
                  getIn(touched, `latestMediaGallerySubHeading`)
              )}
              disabled={isProjectViewMode}
            />
          </Grid> */}
        </Grid>

        <Divider className={styles["content-divider"]} />

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Image
          </Typography>
          <SwitchComponent
            name={`latestMediaGallery.isImagesActive`}
            checked={values?.latestMediaGallery?.isImagesActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <div className={styles["top-upload-btn"]}>
          <LoadingButtonComponent
            onClick={() => {
              handleOpenMediaUploadDialog(
                setFieldValue,
                "imageUploadListDetails",
                "image"
              );
              setOpenMediaUploadDialog(true);
              setSingleMediaUpdateData({});
              setIsViewMode(false);
              setIsEditMode(false);
            }}
            className={"btn btn-dark"}
            disabled={isProjectViewMode}
          >
            <Stack spacing={1} justifyContent={"space-evenly"}>
              <FileUploadOutlinedIcon />
              <Typography color={"#fff"}>
                {values?.latestMediaGallery?.images?.length > 0
                  ? "Upload More Images"
                  : "Upload Image"}
              </Typography>
            </Stack>
          </LoadingButtonComponent>
        </div>

        {values?.latestMediaGallery?.images?.length > 0 && (
          <div>
            <MediaGalleryTable
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
              setSingleMediaUpdateData={setSingleMediaUpdateData}
              dataMap={values?.latestMediaGallery?.images}
              name={"latestMediaGallery.images"}
              setMediaUploadFor={setMediaUploadFor}
              mediaUploadFor={"imageUploadListDetails"}
            />
          </div>
        )}

        {/* <Divider className={styles["content-divider"]} />

              <Stack justifyContent={"end"} margin={"20px 0"}>
                <LoadingButtonComponent
                  disabled={
                    values?.latestMediaGallery?.images?.length === 0 ? true : false
                  }
                >
                  Save
                </LoadingButtonComponent>
              </Stack> */}

        {/* video upload section */}
        <Divider className={styles["content-divider"]} />

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Video
          </Typography>
          <SwitchComponent
            name={`latestMediaGallery.isVideosActive`}
            checked={values?.latestMediaGallery?.isVideosActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <div className={styles["top-upload-btn"]}>
          <LoadingButtonComponent
            onClick={() => {
              handleOpenMediaUploadDialog(
                setFieldValue,
                "videoUploadListDetails",
                "video"
              );
              setOpenMediaUploadDialog(true);
              setSingleMediaUpdateData({});
              setIsViewMode(false);
              setIsEditMode(false);
            }}
            className={"btn btn-dark"}
            disabled={isProjectViewMode}
          >
            <Stack spacing={1} justifyContent={"space-evenly"}>
              <FileUploadOutlinedIcon />
              <Typography color={"#fff"}>
                {values?.latestMediaGallery?.videos?.length > 0
                  ? "Upload More Videos"
                  : "Upload Video"}
              </Typography>
            </Stack>
          </LoadingButtonComponent>
        </div>

        {values?.latestMediaGallery?.videos?.length > 0 && (
          <div>
            <MediaGalleryTable
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
              setSingleMediaUpdateData={setSingleMediaUpdateData}
              dataMap={values?.latestMediaGallery?.videos}
              name={"latestMediaGallery.videos"}
              setMediaUploadFor={setMediaUploadFor}
              mediaUploadFor={"videoUploadListDetails"}
            />
          </div>
        )}

        {/* <Divider className={styles["content-divider"]} />

              <Stack justifyContent={"end"} margin={"20px 0"}>
                <LoadingButtonComponent
                  disabled={
                    values?.latestMediaGallery?.videos?.length === 0 ? true : false
                  }
                >
                  Save
                </LoadingButtonComponent>
              </Stack> */}

        {/* drone shoots section  */}

        <Divider className={styles["content-divider"]} />

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Drone Shoots
          </Typography>
          <SwitchComponent
            name={`latestMediaGallery.isDroneShootsActive`}
            checked={values?.latestMediaGallery?.isDroneShootsActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <div className={styles["top-upload-btn"]}>
          <LoadingButtonComponent
            onClick={() => {
              handleOpenMediaUploadDialog(
                setFieldValue,
                "droneShootsUploadListDetails",
                "video"
              );
              setOpenMediaUploadDialog(true);
              setSingleMediaUpdateData({});
              setIsViewMode(false);
              setIsEditMode(false);
            }}
            className={"btn btn-dark"}
            disabled={isProjectViewMode}
          >
            <Stack spacing={1} justifyContent={"space-evenly"}>
              <FileUploadOutlinedIcon />
              <Typography color={"#fff"}>
                {values?.latestMediaGallery?.droneShoots?.length > 0
                  ? "Upload More Videos"
                  : "Upload Video"}
              </Typography>
            </Stack>
          </LoadingButtonComponent>
        </div>

        {values?.latestMediaGallery?.droneShoots?.length > 0 && (
          <div>
            <MediaGalleryTable
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
              setSingleMediaUpdateData={setSingleMediaUpdateData}
              dataMap={values?.latestMediaGallery?.droneShoots}
              name={"latestMediaGallery.droneShoots"}
              setMediaUploadFor={setMediaUploadFor}
              mediaUploadFor={"droneShootsUploadListDetails"}
            />
          </div>
        )}

        {/* <Divider className={styles["content-divider"]} />

              <Stack justifyContent={"end"} margin={"20px 0"}>
                <LoadingButtonComponent
                  disabled={
                    values?.latestMediaGallery?.droneShoots?.length === 0
                      ? true
                      : false
                  }
                  onClick={() =>
                    console.log(values?.latestMediaGallery?.droneShoots)
                  }
                >
                  Save
                </LoadingButtonComponent>
              </Stack> */}

        {/* 360 images */}
        <Divider className={styles["content-divider"]} />

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            360&deg; Images
          </Typography>
          <SwitchComponent
            name={`latestMediaGallery.isThreeSixtyImagesActive`}
            checked={values?.latestMediaGallery?.isThreeSixtyImagesActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <div className={styles["top-upload-btn"]}>
          <LoadingButtonComponent
            onClick={() => {
              handleOpenMediaUploadDialog(
                setFieldValue,
                "threeSixtyUploadListDetails",
                "image"
              );
              setOpenMediaUploadDialog(true);
              setSingleMediaUpdateData({});
              setIsViewMode(false);
              setIsEditMode(false);
            }}
            className={"btn btn-dark"}
            disabled={isProjectViewMode}
          >
            <Stack spacing={1} justifyContent={"space-evenly"}>
              <FileUploadOutlinedIcon />
              <Typography color={"#fff"}>
                {values?.latestMediaGallery?.threeSixtyImages?.length > 0
                  ? "Upload More Images"
                  : "Upload Image"}
              </Typography>
            </Stack>
          </LoadingButtonComponent>
        </div>

        {values?.latestMediaGallery?.threeSixtyImages?.length > 0 && (
          <div>
            <MediaGalleryTable
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setSingleMediaUpdateData={setSingleMediaUpdateData}
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
              dataMap={values?.latestMediaGallery?.threeSixtyImages}
              name={"latestMediaGallery.threeSixtyImages"}
              setMediaUploadFor={setMediaUploadFor}
              mediaUploadFor={"threeSixtyUploadListDetails"}
            />
          </div>
        )}

        {/* <Divider className={styles["content-divider"]} />

              <Stack justifyContent={"end"} margin={"20px 0"}>
                <LoadingButtonComponent
                  disabled={
                    values?.latestMediaGallery?.threeSixtyImages?.length === 0
                      ? true
                      : false
                  }
                  onClick={() =>
                    console.log(values?.latestMediaGallery?.threeSixtyImages?)
                  }
                >
                  Save
                </LoadingButtonComponent>
              </Stack> */}

        {/* Latest Media Gallery Virtual Walk through videos */}
        <Divider className={styles["content-divider"]} />

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Virtual Video
          </Typography>
          <SwitchComponent
            name={`latestMediaGallery.isVirtualWalkthroughActive`}
            checked={values?.latestMediaGallery?.isVirtualWalkthroughActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <div className={styles["top-upload-btn"]}>
          <LoadingButtonComponent
            onClick={() => {
              handleOpenMediaUploadDialog(
                setFieldValue,
                "virtualVideoUploadListDetails",
                "video"
              );
              setOpenMediaUploadDialog(true);
              setSingleMediaUpdateData({});
              setIsViewMode(false);
              setIsEditMode(false);
            }}
            className={"btn btn-dark"}
            disabled={isProjectViewMode}
          >
            <Stack spacing={1} justifyContent={"space-evenly"}>
              <FileUploadOutlinedIcon />
              <Typography color={"#fff"}>
                {values?.latestMediaGallery?.virtualWalkthrough?.length > 0
                  ? "Upload More Videos"
                  : "Upload Video"}
              </Typography>
            </Stack>
          </LoadingButtonComponent>
        </div>

        {values?.latestMediaGallery?.virtualWalkthrough?.length > 0 && (
          <div>
            <MediaGalleryTable
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
              setSingleMediaUpdateData={setSingleMediaUpdateData}
              dataMap={values?.latestMediaGallery?.virtualWalkthrough}
              name={"latestMediaGallery.virtualWalkthrough"}
              setMediaUploadFor={setMediaUploadFor}
              mediaUploadFor={"virtualVideoUploadListDetails"}
            />
          </div>
        )}
      </Container>
    </Fragment>
  );
}
