import { useEffect } from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import {
  TextFieldComponent,
  SwitchComponent,
  InfoComponent,
  ButtonComponent,
} from "components/ui-elements";
import { PriceTransparencyStyleWrapper } from "./PriceTransparency.style";
import { FieldArray, getIn, useFormikContext } from "formik";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import QuillEditor from "components/quill-editor/QuillEditor";
import { handleNumberFieldChange, stripHtml } from "utils/ValidationUtils";
import { useSelector } from "react-redux";

const PriceTransparency = () => {
  const { values, touched, errors, handleChange, setFieldValue, handleBlur } =
    useFormikContext<any>();
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );
  const handleTextEditor = (name: string, newValue: string) => {
    setFieldValue(name, newValue);
  };

  return (
    <PriceTransparencyStyleWrapper>
      <Box className="section-gutter">
        <Grid container margin={"1rem 0"}>
          <Grid item xs={12}>
            <Stack justifyContent={"flex-start"} pb={5} spacing={1}>
              <Typography>Price Transparency</Typography>

              <SwitchComponent
                name="priceTransparency.isPriceTransparencyActive"
                checked={values?.priceTransparency?.isPriceTransparencyActive}
                onChange={handleChange}
                disabled={isViewMode}
              />

              <InfoComponent infoContent="toggle is to show/hide Price Transparency section on customer web" />
            </Stack>
          </Grid>

          <Grid item lg={3} xl={3}>
            <TextFieldComponent
              label="Section Heading *"
              inputProps={{ maxLength: 40 }}
              name={`priceTransparency.sectionHeading`}
              value={values?.priceTransparency?.sectionHeading}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(
                getIn(touched, `priceTransparency.sectionHeading`) &&
                  getIn(errors, `priceTransparency.sectionHeading`)
              )}
              fieldhelpertext={
                getIn(touched, `priceTransparency.sectionHeading`) &&
                getIn(errors, `priceTransparency.sectionHeading`)
              }
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item lg={3} xl={3}>
            <TextFieldComponent
              label="Project Name *"
              onChange={handleChange}
              value={values?.priceTransparency?.projectName}
              onBlur={handleBlur}
              name={`priceTransparency.projectName`}
              error={Boolean(
                getIn(touched, `priceTransparency.projectName`) &&
                  getIn(errors, `priceTransparency.projectName`)
              )}
              fieldhelpertext={
                getIn(touched, `priceTransparency.projectName`) &&
                getIn(errors, `priceTransparency.projectName`)
              }
              disabled={isViewMode}
            />
          </Grid>

          <Grid item lg={6} xl={6}>
            <TextFieldComponent
              label="Price Transparency CTA Name *"
              name={`priceTransparency.priceTransparencyCTAName`}
              onChange={handleChange}
              value={values?.priceTransparency?.priceTransparencyCTAName}
              onBlur={handleBlur}
              error={
                getIn(touched, `priceTransparency.priceTransparencyCTAName`) &&
                getIn(errors, `priceTransparency.priceTransparencyCTAName`)
              }
              fieldhelpertext={
                getIn(touched, `priceTransparency.priceTransparencyCTAName`) &&
                getIn(errors, `priceTransparency.priceTransparencyCTAName`)
              }
              disabled={isViewMode}
            />
          </Grid>

          <Grid item lg={12} xl={12}>
            <TextFieldComponent
              label="Short Description *"
              name={`priceTransparency.shortDescription`}
              onChange={handleChange}
              value={values?.priceTransparency?.shortDescription}
              onBlur={handleBlur}
              inputProps={{ maxLength: 240 }}
              error={Boolean(
                getIn(touched, `priceTransparency.shortDescription`) &&
                  getIn(errors, `priceTransparency.shortDescription`)
              )}
              fieldhelpertext={
                getIn(touched, `priceTransparency.shortDescription`) &&
                getIn(errors, `priceTransparency.shortDescription`)
              }
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
        <FieldArray
          name="priceTransparency.projects"
          render={({ push, remove }) => (
            <>
              {values?.priceTransparency?.projects?.length > 0 &&
                values?.priceTransparency?.projects?.map(
                  (_element: any, projectIndex: number) => {
                    return (
                      <Grid container spacing={5} mt={"0.1rem"}>
                        <Grid item lg={3} xl={3}>
                          <TextFieldComponent
                            label="SKU Name *"
                            name={`priceTransparency.projects[${projectIndex}].skuName`}
                            onChange={handleChange}
                            value={
                              values?.priceTransparency?.projects[projectIndex]
                                ?.skuName
                            }
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(
                                touched,
                                `priceTransparency.projects[${projectIndex}].skuName`
                              ) &&
                                getIn(
                                  errors,
                                  `priceTransparency.projects[${projectIndex}].skuName`
                                )
                            )}
                            fieldhelpertext={
                              getIn(
                                touched,
                                `priceTransparency.projects[${projectIndex}].skuName`
                              ) &&
                              getIn(
                                errors,
                                `priceTransparency.projects[${projectIndex}].skuName`
                              )
                            }
                            disabled={isViewMode}
                          />
                        </Grid>
                        <Grid item lg={3} xl={3}>
                          <TextFieldComponent
                            label="Area *"
                            name={`priceTransparency.projects[${projectIndex}].areaStartingFrom`}
                            onChange={handleNumberFieldChange(setFieldValue)}
                            value={
                              values?.priceTransparency?.projects[projectIndex]
                                ?.areaStartingFrom
                            }
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(
                                touched,
                                `priceTransparency.projects[${projectIndex}].areaStartingFrom`
                              ) &&
                                getIn(
                                  errors,
                                  `priceTransparency.projects[${projectIndex}].areaStartingFrom`
                                )
                            )}
                            fieldhelpertext={
                              getIn(
                                touched,
                                `priceTransparency.projects[${projectIndex}].areaStartingFrom`
                              ) &&
                              getIn(
                                errors,
                                `priceTransparency.projects[${projectIndex}].areaStartingFrom`
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography
                                    variant="body1"
                                    color={"text.primary"}
                                  >
                                    {`sq ft`}
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                            disabled={isViewMode}
                          />
                        </Grid>
                        <Grid item lg={3} xl={3}>
                          <TextFieldComponent
                            label="Price *"
                            name={`priceTransparency.projects[${projectIndex}].priceStartingFrom`}
                            onChange={handleNumberFieldChange(setFieldValue)}
                            value={
                              values?.priceTransparency?.projects[projectIndex]
                                ?.priceStartingFrom
                            }
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(
                                touched,
                                `priceTransparency.projects[${projectIndex}].priceStartingFrom`
                              ) &&
                                getIn(
                                  errors,
                                  `priceTransparency.projects[${projectIndex}].priceStartingFrom`
                                )
                            )}
                            fieldhelpertext={
                              getIn(
                                touched,
                                `priceTransparency.projects[${projectIndex}].priceStartingFrom`
                              ) &&
                              getIn(
                                errors,
                                `priceTransparency.projects[${projectIndex}].priceStartingFrom`
                              )
                            }
                            disabled={isViewMode}
                          />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          {values?.priceTransparency?.projects?.length > 3 ? (
                            <IconButton
                              aria-label="delete"
                              data-testid="button-delete"
                              onClick={() => remove(projectIndex)}
                              disabled={isViewMode}
                            >
                              <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                            </IconButton>
                          ) : null}
                        </Grid>
                        {values?.priceTransparency?.projects?.length > 1 ? (
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Divider sx={{ my: "0.5rem" }} />
                          </Grid>
                        ) : null}
                      </Grid>
                    );
                  }
                )}

              {values?.priceTransparency?.projects?.length < 10 ? (
                <Stack>
                  <ButtonComponent
                    variant="text"
                    color="inherit"
                    onClick={() => {
                      push({
                        skuName: "",
                        areaStartingFrom: "",
                        priceStartingFrom: "",
                      });
                    }}
                    disabled={isViewMode}
                  >
                    + Add more Values
                  </ButtonComponent>
                </Stack>
              ) : null}
            </>
          )}
        />
        <Divider sx={{ my: "0.5rem" }} />
        <Grid container>
          <Grid item xs={12} lg={12} xl={12}>
            <Stack justifyContent={"flex-start"} py={2}>
              <Typography>Terms & Conditions</Typography>

              <FormControlLabel
                className="switch-form-label"
                name={
                  "priceTransparency.termsAndConditions.isTermsAndConditionsActive"
                }
                checked={
                  values?.priceTransparency?.termsAndConditions
                    ?.isTermsAndConditionsActive
                }
                onChange={handleChange}
                disabled={false}
                control={<SwitchComponent />}
                label=""
                labelPlacement="start"
              />
              <InfoComponent infoContent="toggle is to show/hide Terms & Conditions of Price Transparency section on customer web" />
            </Stack>
          </Grid>

          <Grid item xs={4} mt={3}>
            <TextFieldComponent
              label="Section Heading *"
              placeholder="Enter"
              name={`priceTransparency.termsAndConditions.sectionHeading`}
              disabled={isViewMode}
              value={
                values?.priceTransparency?.termsAndConditions?.sectionHeading
              }
              onChange={handleChange}
              onBlur={handleBlur}
              fieldhelpertext={
                getIn(
                  touched,
                  "priceTransparency.termsAndConditions.sectionHeading"
                ) &&
                getIn(
                  errors,
                  "priceTransparency.termsAndConditions.sectionHeading"
                )
              }
              error={Boolean(
                getIn(
                  errors,
                  "priceTransparency.termsAndConditions.sectionHeading"
                ) &&
                  getIn(
                    touched,
                    "priceTransparency.termsAndConditions.sectionHeading"
                  )
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Stack m={"1rem 0.5rem 1rem 0"} justifyContent={"flex-start"}>
              <Typography mr={"0.5rem"}>Description</Typography>
              <InfoComponent
                infoContent={
                  "Description can be a combination of more than 1 Paragraph. Maximum Character limit for the complete Terms & Conditions is 1,00,000."
                }
              />
            </Stack>
            <QuillEditor
              disabled={isViewMode}
              placeholder="Enter description"
              onChange={(name: string, newValue: string, options: any) =>
                handleTextEditor(name, newValue)
              }
              value={values?.priceTransparency?.termsAndConditions?.description}
              style={{
                height: "10rem",
              }}
              inputProps={{ maxLength: 100000 }}
              name={`priceTransparency.termsAndConditions.description`}
              onBlur={handleBlur}
              fieldhelpertext={
                stripHtml(
                  values?.priceTransparency?.termsAndConditions?.description
                ).length === 0 &&
                getIn(
                  errors,
                  "priceTransparency.termsAndConditions.sectionHeading"
                )
              }
              error={Boolean(
                getIn(errors, "termsAndConditions.description") &&
                  getIn(touched, "termsAndConditions.description")
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </PriceTransparencyStyleWrapper>
  );
};

export default PriceTransparency;
