import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  inviteUsersSuccess: ["response"],
  inviteUsersFailure: ["error"],
  inviteUsersRequest: ["data"],

  getUsersSuccess: ["response"],
  getUsersFailure: ["error"],
  getUsersRequest: ["data"],

  updateInviteUsersSuccess: ["response"],
  updateInviteUsersFailure: ["error"],
  updateUsersRequest: ["id", "data"],

  deleteUserSuccess: ["response"],
  deleteUserFailure: ["error"],
  deleteUserRequest: ["data"],

  updateUserStatusSuccess: ["response"],
  updateUserStatusFailure: ["error"],
  updateUserStatusRequest: ["data"],

  setInviteUsersState: ["key", "value"],
});

export const InviteUserTypes = Types;

export const InviteActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  inviteUsers: [],
  shouldDialogOpen: false,
  responseMessage: null,
  isSuccess: false,
};

//* Getting all added users
export const getUsersRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const getUsersSuccess = (state: any, action: any) => {
  const response = action.response;
  
  
  if (response) {
    return {
      ...state,
      error: null,
      inviteUsers: response.data,
      isSuccess: true,
      isLoading: false,
      shouldDialogOpen: false,
      // responseMessage: response.message || "User fetch successfully",
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getUsersFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: false,
    // error: action.error.message,
  };
};

//* Adding a new user

export const inviteUsersRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const inviteUsersSuccess = (state: any, action: any) => {
  const response = action.response;
 
  if (response) {
    return {
      ...state,
      error: null,
      inviteUsers: response.data,
      isSuccess: true,
      isLoading: false,
      shouldDialogOpen: true,
      responseMessage: response.message || "Admin created successfully",
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const inviteUsersFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: true,
    error: action.error.message,
  };
};

//* Update existing users

export const updateUsersRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const updateInviteUsersSuccess = (state: any, action: any) => {
  const { response } = action;
 
  if (response) {
    return {
      ...state,
      error: null,
      inviteUsers: response.data,
      isLoading: false,
      isSuccess: true,
      shouldDialogOpen: true,
      responseMessage: response.message || "Admin Updated Successfully",
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const updateInviteUsersFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: true,
    error: action.error.message,
  };
};

//* Deleting existing user
export const deleteUserRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const deleteUserSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      isLoading: false,
      shouldDialogOpen: true,
      responseMessage: response.message
        ? response.message
        : "Admin deleted successfully!",
    };
  }
};

export const deleteUserFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: true,
    error: action.error.message,
  };
};

//* Updating admins status
export const updateUserStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const updateUserStatusSuccess = (state: any, action: any) => {
  const response = action.response;
  

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      shouldDialogOpen: true,
      responseMessage: response.message
        ? response.message
        : "Admins status updated successfully!",
      isLoading: false,
    };
  }
};

export const updateUserStatusFailure = (state: any, action: any) => {
  
  
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: true,
    error: action.error.message,
    // responseMessage: action.error.message,
  };
};

export const setInviteUsersState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.INVITE_USERS_SUCCESS]: inviteUsersSuccess,
  [Types.INVITE_USERS_FAILURE]: inviteUsersFailure,
  [Types.INVITE_USERS_REQUEST]: inviteUsersRequest,//post admin(s)

  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAILURE]: getUsersFailure,
  [Types.GET_USERS_REQUEST]: getUsersRequest,//get admins

  [Types.UPDATE_INVITE_USERS_SUCCESS]: updateInviteUsersSuccess,
  [Types.UPDATE_INVITE_USERS_FAILURE]: updateInviteUsersFailure,
  [Types.UPDATE_USERS_REQUEST]: updateUsersRequest,//update admin

  [Types.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [Types.DELETE_USER_FAILURE]: deleteUserFailure,
  [Types.DELETE_USER_REQUEST]: deleteUserRequest,//delete admin

  [Types.UPDATE_USER_STATUS_REQUEST]: updateUserStatusRequest,//update admin status
  [Types.UPDATE_USER_STATUS_SUCCESS]: updateUserStatusSuccess,
  [Types.UPDATE_USER_STATUS_FAILURE]: updateUserStatusFailure,

  [Types.SET_INVITE_USERS_STATE]: setInviteUsersState,
});
