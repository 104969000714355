import {
  FileUploadComponent,
  MediaUploadComponent,
} from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";

const actionDispatch = (dispatch: any) => {
  return {
    setUploadFileState: (key: any, data: any) => {
      dispatch(FileUploadActions.setUploadFileState(key, data));
    },
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
    fileDeleteRequest: (data: any) => {
      dispatch(FileUploadActions.fileDeleteRequest(data));
    },
  };
};

function KeyPillarsImageUploader(props: any) {
  const { values, setFieldValue, errors, touched } =
    useFormikContext<IInitinalValueProjectContent>();
  const { index, isViewModeProp } = props;

  const { imageDetails, isLoading } = useSelector(
    (state: any) => state.fileUpload
  );

  const { setUploadFileState, fileUploadRequest, fileDeleteRequest } =
    actionDispatch(useDispatch());

  const [uploadedImageIndex, setUploadedImageIndex]: any = useState(null);
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );
  useEffect(() => {
    if (imageDetails) {
      const imageRes = imageDetails;
      setUploadFileState("imageDetails", null);
      const keyPillars = { ...values.keyPillars };
      if (
        uploadedImageIndex !== null &&
        keyPillars.values &&
        keyPillars.values[uploadedImageIndex]
      ) {
        keyPillars.values[uploadedImageIndex].icon.value.url = imageRes?.url;
      }
      setUploadedImageIndex(null);
      setFieldValue(`keyPillars`, keyPillars);
    }
  }, [imageDetails]);

  const handleImageUpload = (event: any, index: any) => {
    const file = event.target.files[0];
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);

      setFieldValue(`keyPillars.values.${index}.icon.name`, file?.name);
      setFieldValue(`keyPillars.values.${index}.icon.key`, file?.name);
      setFieldValue(`keyPillars.values.${index}.icon.value.size`, file?.size);
      setFieldValue(
        `keyPillars.values.${index}.icon.value.mediatype`,
        file?.type
      );
    }
  };
  const cancelImageHandler = () => {
    fileDeleteRequest(values?.keyPillars?.values[index]?.icon?.name);

    setFieldValue(`keyPillars.values.${index}.icon.value.url`, "");
    setFieldValue(`keyPillars.values[${index}].icon.name`, "");
    setFieldValue(`keyPillars.values[${index}].icon.key`, "");
  };
  const onChangeHandler = (event: any) => {
    handleImageUpload(event, index);
    setUploadedImageIndex(index);
  };

  return (
    <div>
      <MediaUploadComponent
        name={`keyPillars.values.${index}.icon.value.url`}
        loading={uploadedImageIndex === index ? isLoading : false}
        inputId={index}
        onChange={(event: any) => onChangeHandler(event)}
        // disabled={true}
        previewimage={values?.keyPillars?.values[index]?.icon?.value?.url}
        cancelimage={cancelImageHandler}
        fieldhelpertext={
          getIn(touched, `keyPillars.values[${index}].icon.value.url`) &&
          getIn(errors, `keyPillars.values[${index}].icon.value.url`)
        }
        disabled={isViewMode || isViewModeProp}
      />
    </div>
  );
}

export default KeyPillarsImageUploader;
