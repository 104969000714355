import { Button, ButtonProps } from "@mui/material";
import { Box, styled } from "@mui/material";

const ButtonWrapper = styled(Box)``;

export default function ButtonComponent(props: ButtonProps) {
  const { children } = props;
  return (
    <ButtonWrapper>
      <Button {...props}>{children}</Button>
    </ButtonWrapper>
  );
}
