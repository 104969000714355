import { styled } from "@mui/material";

export const PriceTransparencyStyleWrapper = styled("div")`
  .section-gutter {
    padding: 1.5rem;
    & .no-gutter {
      padding: 0;
    }
  }
  .switch-form-label{
    margin-right: 0.5rem;
  }
`;
