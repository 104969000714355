import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";

import LandingPageUrlAction from "redux-container/landing-page-management/LandingPageUrlCreationRedux";

export function* addLandingPageUrlCreation(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.addLandingPageUrlCreation, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        LandingPageUrlAction.addLandingPageUrlCreationSuccess(response.data)
      );
    } else {
      yield put(
        LandingPageUrlAction.addLandingPageUrlCreationFailure(response.data)
      );
    }
  } catch (error) {
    yield put(LandingPageUrlAction.addLandingPageUrlCreationFailure(error));
  }
}

export function* getUrls(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getUrls, action.params);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(LandingPageUrlAction.getUrlsSuccess(response.data));
    } else {
      yield put(LandingPageUrlAction.getUrlsFailure(response.data));
    }
  } catch (error) {
    yield put(LandingPageUrlAction.getUrlsFailure(error));
  }
}

export function* changeUrlStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.changeUrlStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(LandingPageUrlAction.changeUrlStatusSuccess(response.data));
    } else {
      yield put(LandingPageUrlAction.changeUrlStatusFailure(response.data));
    }
  } catch (error) {
    yield put(LandingPageUrlAction.changeUrlStatusFailure(error));
  }
}

export function* deleteUrl(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteUrl, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(LandingPageUrlAction.deleteUrlSuccess(response.data));
    } else {
      yield put(LandingPageUrlAction.deleteUrlFailure(response.data));
    }
  } catch (error) {
    yield put(LandingPageUrlAction.deleteUrlFailure(error));
  }
}