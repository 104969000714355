import { RouteConstants } from "routes/RouteConstants";

export const appUpdateInitialValues = {
  title: "",
  description: "",
  shouldNotifyIOS: false,
  shouldNotifyAndroid: false,

  backgroundImage: {
    name: "",
    key: "",
    value: {
      mediaType: "",
      size: 1,
      width: 2,
      height: 3,
      url: "",
    },
  },
  foregroundImage: {
    name: "",
    key: "",
    value: {
      mediaType: "",
      size: 1,
      width: 2,
      height: 3,
      url: "",
    },
  },
};

export const appUpdateBreadcrumbRoutes = (isViewMode?: any) => {
  return [
    {
      name: "Notification Management",
      route: RouteConstants.notificationManagementDashboard,
    },
    {
      name: "App Updates List",
      route: RouteConstants.appUpdates,
    },
    {
      name: !isViewMode ? "Create - App Update" : `View - App Updates`,
      route: RouteConstants.appUpdateCreation,
    },
  ];
};
