import { Typography } from "@mui/material";
import QuillEditor from "components/quill-editor/QuillEditor";
import { getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { removeHtmlTags, stripHtml } from "utils/ValidationUtils";

function MediaQuillEditor({ mediaAndPrState }: any) {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<any>();

  const handleTextEditor = (name: string, newValue: string, options: any) => {
    setFieldValue(name, newValue);
    setFieldTouched(name, true);

    console.log("inside outer strip html,", name, stripHtml(newValue));
    if (!stripHtml(newValue)) {
      console.log("inside strip html,", stripHtml(newValue));
      setFieldError(name, "This field is mandatory");
    }
  };
  console.log("errors from Media Quill", errors);

  return (
    <div>
      <Typography variant="body1">Description*</Typography>
      <QuillEditor
        readonly={mediaAndPrState.isViewMode}
        value={values?.description}
        onChange={(name: string, newValue: string, options: any) =>
          handleTextEditor(name, newValue, options)
        }
        name={"description"}
        placeholder={"Enter Description"}
        fieldhelpertext={
          stripHtml(values?.description)?.length === 0 &&
          touched?.description &&
          "This field is mandatory"
        }
        error={Boolean(
          getIn(touched, "description") && getIn(errors, "description")
        )}
        inputProps={{
          maxLength: 250,
        }}
        style={{ height: "14rem" }}
        disabled={mediaAndPrState.isViewMode}
      />
    </div>
  );
}

export default MediaQuillEditor;
