import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import PageDashboardActions from "redux-container/page-management/PageDashboardRedux";

export function* getPageDashboard(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPageDashboard, action.params);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PageDashboardActions.getPageDashboardSuccess(response.data));
    } else {
      yield put(PageDashboardActions.getPageDashboardFailure(response.data));
    }
  } catch (error) {
    yield put(PageDashboardActions.getPageDashboardFailure(error));
  }
}
