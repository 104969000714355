export const Page1HoablInitialValues = {
  mastheadSection: {
    totalSqftOfLandTransacted: {
      value: "",
      displayName: "",
      shouldDisplay: false,
    },
    totalAmoutOfLandTransacted: {
      value: "",
      displayName: "",
      shouldDisplay: false,
    },
    grossWeightedAvgAppreciation: {
      value: "",
      displayName: "",
      shouldDisplay: false,
    },
    totalNumberOfUsersWhoBoughtTheLand: {
      value: "",
      displayName: "",
      shouldDisplay: false,
    },
  },
  isNewInvestmentsActive: true,
  newInvestments: {
    heading: "",
    subHeading: "",
  },
  totalProjectsOnHomeScreen: 3,
  isLatestUpdatesActive: true,
  latestUpdates: {
    heading: "",
    subHeading: "",
  },
  totalUpdatesOnListView: 5,
  totalUpdatesOnHomeScreen: 3,
  isPromisesActive: true,
  promisesHeading: "",
  totalPromisesOnHomeScreen: 5,

  isPromotionAndOfferActive: true,
  promotionAndOfferCard: {
    key: "rose ambuguet.png",
    name: "rose ambuguet.png",
    value: {
      url: "https://prodcuts-inventory-btf-be-stage.s3-ap-south-1.amazonaws.com/large/rose ambuguet.png",
      size: 158.0439453125,
      width: 1024,
      height: 1024,
      mediaType: "image",
    },
  },

  promotionAndOffersProjectContentId: "",
  isFacilityManagementActive: true,
  facilityManagement: {
    key: "rose ambuguet.png",
    name: "rose ambuguet.png",
    value: {
      url: "https://prodcuts-inventory-btf-be-stage.s3-ap-south-1.amazonaws.com/large/rose ambuguet.png",
      size: 158.0439453125,
      width: 1024,
      height: 1024,
      mediaType: "image",
    },
  },

  isInsightsActive: true,
  insight: {
    Heading: "",
    subHeading: "",
  },
  totalUpdatesOnListViewInsights: 5,
  totalUpdatesOnHomeScreenInsights: 3,

  isTestimonialsActive: true,
  testimonials: {
    heading: "",
    subHeading: "",
  },
  totalUpdatesOnListViewTestimonials: 5,
  totalUpdatesOnHomeScreenTestimonials: 3,
};

export const Page1HoablLabels: any = {
  mastheadSection: {
    totalSqftOfLandTransacted: {
      value: "Total amount of land transacted Value",
      displayName: "Total amount of land transacted DisplayName",
      shouldDisplay: "Masthead Total amount of land transacted switch",
    },
    totalAmoutOfLandTransacted: {
      value: "Total sqft of land transacted Value",
      displayName: "Total sqft of land transacted DisplayName",
      shouldDisplay:
        "Masthead Total sqft of land transacted DisplayName switch",
    },
    grossWeightedAvgAppreciation: {
      value: "Gross weighted appreciation Value",
      displayName: "Gross weighted appreciation DisplayName",
      shouldDisplay: "Masthead Gross weighted appreciation DisplayName switch",
    },
    totalNumberOfUsersWhoBoughtTheLand: {
      value: "Total number of users who bought the land Value",
      displayName: "Total number of users who bought the land DisplayName",
      shouldDisplay:
        "Masthead Total number of users who bought the land DisplayName switch",
    },
  },

  isNewInvestmentsActive: "New Investments Toggle",
  newInvestments: {
    heading: "",
    subHeading: "",
  },
  newInvestmentsWithPriority: "New Investments Mapped Projects",
  totalProjectsOnHomeScreen: "Total Project on the home screen",

  isLatestUpdatesActive: "Latest Updates Toggle",
  latestUpdates: {
    heading: "Latest Updates Heading",
    subHeading: "Latest Updates Sub Heading",
  },
  totalUpdatesOnListView: "Updates on List View",
  totalUpdatesOnHomeScreen: "Total Updates on the home screen",

  isPromisesActive: "Promises Toggle",
  promisesHeading: "Promises Section Heading",
  totalPromisesOnHomeScreen: "Total Promises on the home screen",

  isPromotionAndOfferActive: "Promotion and Offer Toggle",
  promotionAndOffersMedia: {
    value: {
      url: "Promotion and Offer Media",
    },
  },
  promotionAndOffersProjectContentId: "Promotion and Offer Mapped Project",

  // isFacilityManagementActive: true,
  facilityManagement: {
    value: {
      url: "Facility Management Media",
    },
  },

  isInsightsActive: "Insights Toggle",
  insightsHeading: "Insights Heading",
  insightsSubHeading: "Insighs Sub Heading",
  totalInsightsOnListView: "Total Insights on List View",
  totalInsightsOnHomeScreen: "Total Insights on the home screen",

  isTestimonialsActive: "Testimonial Toggle",
  testimonialsHeading: "Testimonials Heading",
  testimonialsSubHeading: "Testimonials Sub Heading",
  totalTestimonialsOnListView: "Total Testmonial on List View",
  totalTestimonialsOnHomeScreen: "Total Testmonial on the home screen",
};
