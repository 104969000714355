import {
  Avatar,
  Box,
  Typography,
  Link,
  Button,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material";
import MediaAssets from "assets";
import AlertHelperText from "../../../../../components/ui-elements/alert-helper-text/AlertHelperText";
import styles from "./ImageUploader.module.css";
import CachedIcon from "@mui/icons-material/Cached";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSelector } from "react-redux";
import { useState } from "react";

interface IImageUploadDragDrop {
  fieldhelpertext?: any;
  onDrop: (event: any) => void;
  onChange: (event: any) => void;
  previewimage?: any;
  name?: string;
  value?: string;
  cancelimage: () => void;
  onBlur: (event: any) => void;
  isDisabled?: any;
  isLoading: boolean;
}

export const DragAndDropStyle = styled(Box)({
  borderRadius: "2px",
  width: "20rem",
  height: "3rem",
  // marginBottom: "10rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  "& .innerButton": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const ImageUploadDragDrop = (props: IImageUploadDragDrop) => {
  const [isHover, setIsHover] = useState(false);
  const url = props?.previewimage;
  console.log(url,"mkl")
  const substring =
    "https://hoabl-development-template.s3-ap-south-1.amazonaws.com/landingUrl/";

  const extractedValue =
    url && url.slice(url.indexOf(substring) + substring.length);
  const truncated = (str: string, maxLength: number) => {
    if (str?.length > maxLength) {
      return str.slice(0, maxLength - 1) + "...";
    }

    return str;
  };
  return (
    <>
      <div data-testid="media-upload-drag-drop">
        {props.isLoading ? (
          <Box className={styles["loader-content"]}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {props.previewimage !== "false" &&
            props.previewimage?.length > 0 ? (
              <Box className={styles["image-dropdown-section"]}>
                <a
                  href={`${props?.previewimage}`}
                  target="__blank"
                  className={styles["replace-button-wrapper"]}
                >
                  {" "}
                  View{" "}
                </a>

                <p
                  className={styles["replace-button-wrapper"]}
                  title={extractedValue}
                >
                  {truncated(extractedValue, 15)}
                </p>
                {/* <Button className={styles["replace-button-wrapper"]}>
              <label
                className={styles["display-flex-space-btw"]}
                htmlFor={props.name}
              >
                <input
                  accept=".svg,.png,.jpg,.jpeg"
                  id={props.name}
                  name={props.name}
                  disabled={props.isDisabled}
                  type="file"
                  // value={props.value}
                  onBlur={props.onBlur}
                  className={styles["input-display-none"]}
                  onChange={(event: any) => {
                    // props.onChange(event);
                    props.onChange(event.target.files[0]);
                  }}
                  onDrop={(event: any) => {
                    props.onDrop(event);
                  }}
                />

                <Typography
                  className={styles["replace-text"]}
                  variant="body1"
                  component="h5"
                >
                  Replace
                </Typography>
              </label>
            </Button> */}

                <Stack
                  justifyContent={"space-around"}
                  className={`${styles["right-side-button"]} edit-icon-wrapper`}
                >
                  <div style={{ height: "30px", maxWidth: "30px" }}>
                    <label
                      className={styles["display-flex-space-btw"]}
                      htmlFor={props.name}
                    >
                      <input
                        accept=".svg,.png,.jpg,.jpeg"
                        id={props.name}
                        name={props.name}
                        disabled={props.isDisabled}
                        type="file"
                        onBlur={props.onBlur}
                        className={styles["input-display-none"]}
                        onChange={(event: any) => {
                          props.onChange(event.target.files[0]);
                        }}
                        onDrop={(event: any) => {
                          props.onDrop(event);
                        }}
                      />
                      <Typography
                        className={`${styles["right-side-icon"]} edit-icon`}
                        variant="body1"
                        component="h5"
                      >
                        <ModeEditOutlineOutlinedIcon />
                      </Typography>
                    </label>
                  </div>

                  <div
                    onClick={props.cancelimage}
                    className={`${styles["right-side-icon"]} delete-icon`}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </div>
                </Stack>
              </Box>
            ) : (
              <DragAndDropStyle>
                <Box
                  component="label"
                  htmlFor={props.name}
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                >
                  <input
                    disabled={props.isDisabled}
                    data-testid="media-input-field"
                    accept=".svg,.png,.jpg,.jpeg"
                    id={props.name}
                    name={props.name}
                    type="file"
                    value={props.value == "false" ? "" : props.value}
                    onBlur={props.onBlur}
                    className={styles["image-upload-input"]}
                    onChange={(event: any) => {
                      // props.onChange(event);
                      props.onChange(event.target.files[0]);
                    }}
                    onDrop={(event: any) => {
                      props.onDrop(event);
                    }}
                    style={{ cursor: "pointer" }}
                    // disabled={true}
                  />

                  <div>
                    {isHover ? (
                      <Stack
                        className={
                          props.isDisabled
                            ? styles["upload-btn-input-hover-disabled"]
                            : styles["upload-btn-input-hover"]
                        }
                      >
                        <FileUploadOutlinedIcon sx={{ color: "white" }} />
                        <span style={{ color: "white", fontSize: "1rem" }}>
                          Upload Media
                        </span>
                      </Stack>
                    ) : (
                      <Stack
                        className={
                          props.isDisabled
                            ? styles["upload-btn-input-disabled"]
                            : styles["upload-btn-input"]
                        }
                      >
                        <FileUploadOutlinedIcon sx={{ color: "white" }} />
                        <span style={{ color: "white", fontSize: "1rem" }}>
                          Upload Media
                        </span>
                      </Stack>
                    )}
                  </div>

                  <Box className="innerButton">
                    {props.fieldhelpertext ? (
                      <AlertHelperText
                        sx={{ marginTop: "5px" }}
                        color="error"
                        severity="error"
                      >
                        {props.fieldhelpertext || ""}
                      </AlertHelperText>
                    ) : (
                      <Typography
                        variant="body2"
                        visibility={"visible"}
                        lineHeight={1.5}
                      ></Typography>
                    )}
                  </Box>
                </Box>
              </DragAndDropStyle>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ImageUploadDragDrop;

// export default ImageUploadDragDrop;
