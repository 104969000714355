import React, { useState, useEffect, Fragment, ChangeEvent } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import styles from "./Leads.module.css";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import leadTypes from "redux-container/lead-management/LeadRedux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SearchIcon from "@mui/icons-material/Search";
import DateAdapter from "@mui/lab/AdapterDateFns";

import {
  InfoComponent,
  TextFieldComponent,
} from "../../components/ui-elements";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  Divider,
  IconButton,
  Stack,
  Tab,
  Tooltip,
  Typography,
  Checkbox,
  CardMedia,
  Card,
  Box,
  SelectChangeEvent,
  Container,
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
} from "@mui/material";
import { exportAsExcel } from "utils/ExportFileUtils";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeading from "components/page-heading/PageHeading";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { breadcrumbRoutes } from "pages/blog-management/blog-creation/blogs-constants/BlogsConstantMethods";
import { RouteConstants } from "routes/RouteConstants";
import NoDataRecord from "components/no-data-record/NoDataRecord";
import MediaAssets from "assets";
import Loader from "components/loader/Loader";
import Retry from "components/retry/Retry";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { formatDateTime } from "utils/DateFormatterUtils";
import CachedIcon from "@mui/icons-material/Cached";

const actionDispatch = (dispatch: any) => {
  return {
    // filterUpCommingProjectContentCreationDataRequest,
    getLeadDataRequest: (params: any) =>
      dispatch(leadTypes.getLeadDataRequest(params)),
    setLeadState: (key: any, data: any) => {
      dispatch(leadTypes.setLeadState(key, data));
    },
  };
};

const Leads = () => {
  const [totalProject, setTotalProject] = useState<any>();
  const [trackPage, setTrackPage] = useState<any>(5);
  const [lastIndex, setLastIndex] = useState<any>();
  const [startIndex, setStartIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [inc, setinc] = useState<any>(5);
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [checkedIds, setCheckedIds] = useState<any>([
    {
      id: null,
      checked: "",
    },
  ]);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [openDatepicker, setOpenDatepicker] = useState(false);

  const [selectedAction, setSelectedAction] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();
  const leads = useSelector((state: any) => {
    return state?.leads?.leaddata;
  });
  const totalCount = useSelector((state: any) => state?.leads.totalCount);
  const isLoading = useSelector((state: any) => state?.leads?.isLoading);
  const error = useSelector((state: any) => state?.leads?.error);
  const { getLeadDataRequest, setLeadState } = actionDispatch(useDispatch());
  const location: any = useLocation();
  const { page, rowsPerPage } = useSelector((state: any) => {
    return {
      page: state?.leads?.page,
      rowsPerPage: state?.leads?.rowsPerPage,
    };
  });
  useEffect(() => {
    let params: any = {
      index: page + 1,
      size: rowsPerPage,
      fromDate: fromDate,
      toDate: toDate,
    };

    if (searchKey !== "") {
      params.searchKey = searchKey;
    }
    getLeadDataRequest(params);
  }, [searchKey, rowsPerPage, page, toDate, fromDate]);

  useEffect(() => {
    setTotalProject(leads?.length);
  }, []);
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setLeadState("page", 0);
      setLeadState("rowsPerPage", 10);
    }

    return () => {};
  }, []);
  const publishedHeadings = [
    { heading: "Project Name" },
    { heading: "full Name" },
    { heading: "Email" },
    { heading: "Mobile" },
    { heading: "ProjectId" },
    { heading: "CRMLaunchPhaseId" },
    { heading: "City" },
    { heading: "Role" },
    { heading: "QR scan" },
    { heading: "Created At" },
    { heading: "Updated At" },
  ];
  const leadActonTypes = [
    {
      label: "exports",
      value: 1,
    },
  ];

  let sortedLeads = leads?.sort((a: any, b: any) => {
    return b?.id - a?.id;
  });

  const isSelected = (id: any) => selectedLeads.indexOf(id) !== -1;
  const removeDuplicateIds = (id: any, event: any) => {
    checkedIds.forEach((data: any, index: any) => {
      if (id == data?.id) {
        checkedIds[index].id = id;
        checkedIds[index].checked = event;
      }
    });
    let ans = checkedIds.filter(function (item: any, pos: any) {
      return item.checked == true;
    });
  };
  const handleCheckBoxClick = (event: any, id: any) => {
    checkedIds.push({ id: id, checked: event.target.checked });
    removeDuplicateIds(id, event.target.checked);
  };

  const handleExportAsExcel = () => {
    if (selectedLeads.length === 0) {
      alert("You have not selected any Leads");
      return;
    }
    let exportingLeads: any = [];
    if (leads.length) {
      selectedLeads.map((selectedLead: any) => {
        let localSelectedItem = leads.find(
          (item: any) => item.id == selectedLead
        );
        exportingLeads.push({
          Project_Name: localSelectedItem?.projectName
            ? localSelectedItem.projectName
            : "--",
          Full_Name: localSelectedItem?.fullName
            ? localSelectedItem?.fullName
            : "--",
          Email: localSelectedItem?.email ? localSelectedItem?.email : "--",
          Phone: localSelectedItem?.phone ? localSelectedItem?.phone : "--",
          ProjectId: localSelectedItem?.projectId
            ? localSelectedItem?.projectId
            : "--",
          Crm_Launch_Phase_Id: localSelectedItem?.crmLaunchPhaseId
            ? localSelectedItem?.crmLaunchPhaseId
            : "--",
          Crm_Project_Id: localSelectedItem?.crmProjectId
            ? localSelectedItem?.crmProjectId
            : "--",
          Crm_Success: localSelectedItem?.crmSuccess
            ? localSelectedItem?.crmSuccess
            : "--",
          Id: localSelectedItem?.id ? localSelectedItem?.id : "--",

          Is_Upcoming: localSelectedItem?.isUpcoming
            ? localSelectedItem?.isUpcoming
            : "--",
          City: localSelectedItem?.city ? localSelectedItem?.city : "--",
          Country: localSelectedItem?.country
            ? localSelectedItem?.country
            : "--",
          Role: localSelectedItem?.role ? localSelectedItem?.role : "--",
          QrScan: localSelectedItem.isQrCode,
          Created_On: localSelectedItem?.createdAt
            ? localSelectedItem?.createdAt
            : "--",
          Updated_At: localSelectedItem?.updatedAt
            ? localSelectedItem?.updatedAt
            : "--",
          UTM_Details: localSelectedItem?.utmDetails
            ? `utmCampagin=${localSelectedItem?.utmCampagin} utmMedium=${localSelectedItem?.utmMedium} utmSource=${localSelectedItem?.utmSource}`
            : "--",
        });
      });
    }

    exportAsExcel(exportingLeads, "leads", "leads");
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setLeadState("page", newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLeadState("rowsPerPage", Number(event.target.value));
    setLeadState("page", 0);
  };
  const handleActionChange = (e: SelectChangeEvent) => {
    handleExportAsExcel();
  };
  const shouldProvidePlaceholder = () => {
    if (
      (sortedLeads?.length === 0 || sortedLeads?.length === undefined) &&
      searchKey == "" &&
      isLoading === false
    ) {
      return false;
    }
    return true;
  };
  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
  };
  const breadcrumbRoutes: any = [
    {
      name: "Leads Management",
      route: RouteConstants.leadsList,
    },
  ];
  const loadData = (data: any) => {
    const params: any = { index: page + 1, size: rowsPerPage };
    params.searchKey = `${data}`;
    getLeadDataRequest(params);
  };
  const renderLoader = () => {
    return <Loader />;
  };
  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };
  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = sortedLeads.map((n: any) => n.id);
      setSelectedLeads(newSelecteds);
      return;
    }
    setSelectedLeads([]);
  };
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedLeads.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedLeads, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedLeads.slice(1));
    } else if (selectedIndex === selectedLeads.length - 1) {
      newSelected = newSelected.concat(selectedLeads.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedLeads.slice(0, selectedIndex),
        selectedLeads.slice(selectedIndex + 1)
      );
    }
    setSelectedLeads(newSelected);
  };

  const clearFilters = () => {
    setFromDate(null);
    setToDate(null);
  };

  const renderNoData = () => {
    return (
      <Fragment>
        <NoDataRecord
          image={MediaAssets.ic_newspaper}
          altText=""
          message="No leads found"
        />
      </Fragment>
    );
  };
  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">Lead List</h4>

          <FormControl className="action-dropdown">
            <TextFieldComponent
              select
              value={selectedAction}
              label="Action"
              onChange={handleActionChange}
            >
              {leadActonTypes.map((action: any) => {
                return <MenuItem value={action.value}>{action.label}</MenuItem>;
              })}
            </TextFieldComponent>
          </FormControl>
        </div>

        <Grid container spacing={1}>
          <Grid container spacing={2} md={8} lg={8} xl={8} marginBottom={2}>
            <Grid
              item
              md={1}
              lg={1}
              xl={1}
              className="margin-left-10 filter-heading-holder"
              style={{ position: "relative" }}
            >
              <span
                className="filter-heading"
                style={{ position: "absolute", top: 32 }}
              >
                Filters:
              </span>
            </Grid>

            <Grid item md={3} lg={3} xl={3}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="From Date"
                  inputFormat="yyyy-MM-dd"
                  mask={"____-__-__"}
                  value={fromDate}
                  maxDate={toDate === null ? new Date() : toDate}
                  onChange={(newDate) => {
                    setLeadState("page", 0);
                    setFromDate(newDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              mt={Boolean(!fromDate) ? "-1.6rem" : ""}
              md={3}
              lg={3}
              xl={3}
            >
              {Boolean(!fromDate) && (
                <Stack mb={"0.4rem"} justifyContent={"flex-end"}>
                  <InfoComponent infoContent="Please select from date first" />
                </Stack>
              )}
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  disabled={
                    Boolean(!fromDate) ||
                    fromDate?.setHours(0, 0, 0, 0) ===
                      new Date().setHours(0, 0, 0, 0)
                  }
                  label="To Date"
                  inputFormat="yyyy-MM-dd"
                  mask={"____-__-__"}
                  value={toDate}
                  maxDate={new Date()}
                  minDate={fromDate}
                  onChange={(newDate: any) => {
                    setToDate(newDate);
                    setLeadState("page", 0);
                  }}
                  onOpen={() => setOpenDatepicker(true)}
                  onClose={() => setOpenDatepicker(false)}
                  open={openDatepicker}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            {fromDate !== null || toDate !== null ? (
              <Grid
                item
                md={0.5}
                lg={0.5}
                xl={0.5}
                className="filter-heading-holder"
                style={{ position: "relative" }}
              >
                <span
                  onClick={clearFilters}
                  className="clear-filter"
                  style={{ position: "absolute", top: 32 }}
                >
                  Clear
                </span>
              </Grid>
            ) : null}
          </Grid>

          {/* <Grid container spacing={0} md={4} lg={4} xl={4}>
            <Grid item md={12} lg={12} xl={12}>
              <div className="time-sync-holder">
                <div className="time-sync-holder margin-right-15">
                  <span className="margin-right-5 updated-on-heading">
                    Updated on:
                  </span>
                  <span>
                    {formatDateTime(updatedTime, Constants.timeFormathhmmssA)}
                  </span>
                </div>

                <Button
                  variant="contained"
                  onClick={syncUserLogs}
                  // autoFocus
                  disableElevation
                  className="btn btn-sync"
                >
                  <CachedIcon />
                </Button>
              </div>
            </Grid>
          </Grid> */}
        </Grid>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 300 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell
                  style={{ minWidth: 50 }}
                  className="table-header-text"
                  padding="checkbox"
                >
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedLeads?.length > 0 &&
                      selectedLeads?.length < sortedLeads?.length
                    }
                    checked={
                      sortedLeads?.length > 0 &&
                      selectedLeads?.length === sortedLeads?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all promotions and offers",
                    }}
                  />
                </TableCell>
                {publishedHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {sortedLeads && sortedLeads?.length > 0 ? (
              <TableBody>
                {sortedLeads?.map((leadData: any) => {
                  const isItemSelected = isSelected(leadData.id);
                  return (
                    <TableRow
                      className="table-data-row"
                      hover
                      role="checkbox"
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" style={{ minWidth: 50 }}>
                        <Checkbox
                          onClick={(event) => handleClick(event, leadData.id)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={leadData.id}
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>{leadData?.projectName}</div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>{leadData?.fullName}</div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>
                          {leadData?.email
                            ?.split("")
                            ?.map((char: any, index: any) => {
                              if (index <= 1) {
                                return char;
                              } else {
                                return "*";
                              }
                            })}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        {leadData?.phone
                          ?.split("")
                          ?.map((char: any, index: any) => {
                            if (index <= 1) {
                              return char;
                            } else {
                              return "*";
                            }
                          })}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {leadData?.projectId}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {leadData?.crmLaunchPhaseId}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {leadData?.city ? leadData?.city : "-"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {leadData?.role ? leadData?.role : "-"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {leadData?.isQrCode?.toString()}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {new Date(leadData?.createdAt)?.toDateString()}
                        <br />
                        {new Date(leadData?.createdAt)?.getHours()}:
                        {new Date(leadData?.createdAt)?.getMinutes()}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {new Date(leadData?.updatedAt)?.toDateString()}
                        <br />
                        {new Date(leadData?.updatedAt)?.getHours()}:
                        {new Date(leadData?.updatedAt)?.getMinutes()}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Leads</div>
            )}
          </Table>
        </TableContainer>
        {!searchKey && sortedLeads && sortedLeads.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[
              10,
              50,
              100,
              { value: totalCount, label: "All" },
            ]}
            component="div"
            count={totalCount ? totalCount : sortedLeads.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    if (
      (sortedLeads?.length > 0 ||
        searchKey !== "" ||
        toDate !== null ||
        fromDate !== null) &&
      isLoading === false
    ) {
      return renderTable();
    } else if (
      (sortedLeads?.length === 0 || sortedLeads?.length === undefined) &&
      searchKey === "" &&
      isLoading === false &&
      toDate === null &&
      fromDate === null
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder={shouldProvidePlaceholder() ? "Search Leads" : ""}
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>
      <Container className="main-container">{render()}</Container>
    </Fragment>
  );
};

export default Leads;
