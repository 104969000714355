import { Alert, AlertProps, alertClasses } from "@mui/material";
import { FC } from "react";

const AlertHelperText: FC<AlertProps> = (props) => {
  const { children } = props;
  return children ? (
    <Alert
      sx={{
        [alertClasses.icon]: {
          color: "error.main",
          fontSize: "0.8rem",
          padding: 0,
          marginRight: "0.2rem",
          marginTop: "0.1rem",
        },
        [alertClasses.message]: {
          padding: 0,
          fontSize: "0.8rem",
        },
      }}
      {...props}
    >
      {children}
    </Alert>
  ) : null;
};

export default AlertHelperText;
