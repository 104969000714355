import {
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { Fragment } from "react";
import PageHeading from "../../../../components/page-heading/PageHeading";
import {
  ButtonComponent,
  LoadingButtonComponent,
  TextFieldComponent,
  FormFieldContainer,
  RadioButtonGroupComponent,
  StyledRadio,
} from "../../../../components/ui-elements";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Imageuploaderdropbox from "components/ui-elements/image-uploader-dropdown/ImageUploaderDropdown";
import MediaAssets from "assets";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import { PromotionAndOfferCreationConst } from "./PromotionAndOffersCreationConstants";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import { AvatarWrapper } from "./PromotionsAndOffersCreation.style";

interface IPromotionAndOffer {
  promotionCreation: {
    title: string;
    project: any;
    subTitle: string;
    imageMedia: any;
    role: string;
  }[];
}

const PromotionsAndOffersCreation = () => {
  const navigate = useNavigate();

  const breadcrumbRoutes: any = [
    {
      name: "Marketing Management",
      route: RouteConstants.marketingManagementDashboard,
    },
    {
      name: "Promotion & Offers",
      route: RouteConstants.promotionsAndOffersList,
    },
    {
      name: "Create New",
      route: RouteConstants.promotionAndOffersCreation,
    },
  ];

  const projectSelection = ["one", "two", "three", "Tomorrow land"];
  let cardIndexCount = 1;
  const promotionOrOfferSelection = [
    { id: 1, label: "Promotion", value: "Promotion" },
    { id: 2, label: "Offer", value: "Offer" },
  ];

  const defaultValue: IPromotionAndOffer = {
    promotionCreation: [
      {
        title: "",
        project: "",
        subTitle: "",
        imageMedia: "",
        role: "Promotion",
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    promotionCreation: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required(),
        project: Yup.string().required(),
        subTitle: Yup.string().required(),
        imageMedia: Yup.string().required(),
      })
    ),
  });

  const createPromotionsAndOffers = () => {
    // -1 indicates go one step back
    const goBack = -1;
    navigate(goBack);
  };

  const handleOnGlobalSave = (values: any) => {};

  const handleOnCardSave = (values: any, index: number) => {
    let cardOnSave =
      values.promotionCreation[index].title.length >
        PromotionAndOfferCreationConst.minimumLength &&
      values.promotionCreation[index].subTitle.length >
        PromotionAndOfferCreationConst.minimumLength &&
      values.promotionCreation[index].project.length >
        PromotionAndOfferCreationConst.minimumLength &&
      values.promotionCreation[index].imageMedia.length >
        PromotionAndOfferCreationConst.minimumLength &&
      values.promotionCreation[index].role.length >
        PromotionAndOfferCreationConst.minimumLength;
  };

  const handleSaveAndNew = (values: any) => {};

  return (
    <Fragment>
      <Formik
        initialValues={defaultValue}
        validationSchema={validationSchema}
        onSubmit={handleOnGlobalSave}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
            <PageHeading title="Marketing Management" />
            <Container className="main-container">
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="h5"
                  color={"palette.secondary.dark"}
                  data-testid="create-new-text"
                >
                  Create New
                </Typography>
                <Stack direction={"row"} columnGap={2}>
                  <ButtonComponent
                    onClick={createPromotionsAndOffers}
                    color={"inherit"}
                  >
                    Cancel
                  </ButtonComponent>
                  <ButtonComponent
                    type="reset"
                    disabled={!isValid}
                    data-testid="save-and-new-button"
                    onClick={() => handleSaveAndNew(values)}
                  >
                    Save & New
                  </ButtonComponent>
                  <LoadingButtonComponent
                    type="submit"
                    data-testid="form-save-button"
                  >
                    Save
                  </LoadingButtonComponent>
                </Stack>
              </Stack>

              <FormFieldContainer>
                <FieldArray
                  name="promotionCreation"
                  render={(arrayHelpers) => (
                    <div>
                      <LoadingButtonComponent
                        data-testid="add-new-card-button"
                        onClick={() => {
                          arrayHelpers.push({
                            title: "",
                            project: "",
                            subTitle: "",
                            imageMedia: "",
                            role: "Promotion",
                          });
                          cardIndexCount++;
                        }}
                      >
                        + Add Promotion Offers
                      </LoadingButtonComponent>
                      {values.promotionCreation.length > 0 &&
                        values.promotionCreation.map((element, index: any) => (
                          <div data-testid="number-of-card">
                            <FormFieldContainer>
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                key={index}
                              >
                                <Typography
                                  variant="h6"
                                  color={"text.secondary"}
                                  data-testid="card-index-number"
                                >
                                  Card {index + 1}
                                </Typography>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  columnGap={2}
                                >
                                  <LoadingButtonComponent
                                    onClick={() =>
                                      handleOnCardSave(values, index)
                                    }
                                  >
                                    Save
                                  </LoadingButtonComponent>
                                  {cardIndexCount > 1 && (
                                    <IconButton
                                      aria-label="delete"
                                      data-testid="button-delete"
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                        cardIndexCount--;
                                      }}
                                    >
                                      <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                                    </IconButton>
                                  )}
                                </Stack>
                              </Stack>
                              <Divider sx={{ mt: "0.5rem" }} />

                              <RadioButtonGroupComponent
                                name="role"
                                value={element.role}
                                onChange={handleChange}
                                data-testid="select-option"
                              >
                                {promotionOrOfferSelection.map((role) => {
                                  return (
                                    <FormControlLabel
                                      key={role.id}
                                      name={`promotionCreation.${index}.role`}
                                      value={role.value}
                                      control={<StyledRadio />}
                                      label={role.label}
                                      onChange={handleChange}
                                      checked={element.role === role.value}
                                    />
                                  );
                                })}
                              </RadioButtonGroupComponent>

                              <Grid
                                container
                                mt={"0.2rem"}
                                rowSpacing={2}
                                columnSpacing={3}
                              >
                                <Grid item xs={3} md={3} lg={3} xl={3}>
                                  <TextFieldComponent
                                    placeholder="Enter"
                                    fieldtitle="Display Title"
                                    name={`promotionCreation.${index}.title`}
                                    value={element.title}
                                    onChange={handleChange}
                                    helpericon={MediaAssets.ic_info}
                                    inputProps={{
                                      "data-testid": "title",
                                      maxLength:
                                        PromotionAndOfferCreationConst.titleCharacterLimit,
                                    }}
                                    onBlur={handleBlur}
                                    error={
                                      (touched?.promotionCreation &&
                                      touched?.promotionCreation[index] &&
                                      touched?.promotionCreation[index].title
                                        ? true
                                        : false) &&
                                      (element.title.length >
                                      PromotionAndOfferCreationConst.minimumLength
                                        ? false
                                        : true)
                                    }
                                    fieldhelpertext={
                                      (touched?.promotionCreation &&
                                      touched?.promotionCreation[index] &&
                                      touched?.promotionCreation[index].title
                                        ? "Required"
                                        : "") &&
                                      (element.title.length >
                                      PromotionAndOfferCreationConst.minimumLength
                                        ? ""
                                        : "Required")
                                    }
                                  />
                                </Grid>

                                <Grid item xs={3} md={3} lg={3} xl={3}>
                                  <TextFieldComponent
                                    name={`promotionCreation.${index}.project`}
                                    variant="outlined"
                                    inputProps={{
                                      "data-testid": "project-name",
                                    }}
                                    fieldtitle="Select Project"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={element.project}
                                    select
                                    fullWidth
                                    error={
                                      (touched?.promotionCreation &&
                                      touched?.promotionCreation[index] &&
                                      touched?.promotionCreation[index].project
                                        ? true
                                        : false) &&
                                      (element.project.length >
                                      PromotionAndOfferCreationConst.minimumLength
                                        ? false
                                        : true)
                                    }
                                    fieldhelpertext={
                                      (touched?.promotionCreation &&
                                      touched?.promotionCreation[index] &&
                                      touched?.promotionCreation[index].project
                                        ? "Required"
                                        : "") &&
                                      (element.project.length >
                                      PromotionAndOfferCreationConst.minimumLength
                                        ? ""
                                        : "Required")
                                    }
                                  >
                                    {projectSelection.map(
                                      (
                                        projectValueField: string,
                                        key: number
                                      ) => (
                                        <MenuItem
                                          key={key}
                                          value={`${projectValueField}`}
                                        >
                                          {projectValueField}
                                        </MenuItem>
                                      )
                                    )}
                                  </TextFieldComponent>
                                </Grid>

                                <Grid item xs={6} md={6} lg={6} xl={6}>
                                  <TextFieldComponent
                                    placeholder="Enter"
                                    fieldtitle="Sub Title"
                                    name={`promotionCreation.${index}.subTitle`}
                                    fullWidth
                                    helpericon={MediaAssets.ic_info}
                                    value={element.subTitle}
                                    onChange={handleChange}
                                    inputProps={{
                                      "data-testid": "sub-title",
                                      maxLength:
                                        PromotionAndOfferCreationConst.subTitleCharacterLimit,
                                    }}
                                    onBlur={handleBlur}
                                    error={
                                      (touched?.promotionCreation &&
                                      touched?.promotionCreation[index] &&
                                      touched?.promotionCreation[index].subTitle
                                        ? true
                                        : false) &&
                                      (element.subTitle.length >
                                      PromotionAndOfferCreationConst.minimumLength
                                        ? false
                                        : true)
                                    }
                                    fieldhelpertext={
                                      (touched?.promotionCreation &&
                                      touched?.promotionCreation[index] &&
                                      touched?.promotionCreation[index].subTitle
                                        ? "Required"
                                        : "") &&
                                      (element.subTitle.length >
                                      PromotionAndOfferCreationConst.minimumLength
                                        ? ""
                                        : "Required")
                                    }
                                  />
                                </Grid>

                                <Grid item xs={4} md={4} lg={4} xl={4}>
                                  <Stack justifyContent={"flex-start"} mb={1}>
                                    <Typography data-testid="image-media">
                                      Promotion Media
                                    </Typography>
                                    <IconButton>
                                      <Tooltip
                                        title={"Upload Image"}
                                        placement="top"
                                      >
                                        <AvatarWrapper
                                          src={MediaAssets.ic_info}
                                          variant="rounded"
                                        />
                                      </Tooltip>
                                    </IconButton>
                                  </Stack>

                                  <Grid>
                                    <Imageuploaderdropbox
                                      onDrop={handleChange}
                                      name={`promotionCreation.${index}.imageMedia`}
                                      value={element.imageMedia}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        let imageUrl = URL.createObjectURL(
                                          e.target.files[
                                            PromotionAndOfferCreationConst
                                              .minimumLength
                                          ]
                                        );
                                        setFieldValue(
                                          `promotionCreation.${index}.imageMedia`,
                                          imageUrl
                                        );
                                      }}
                                      previewimage={element.imageMedia}
                                      cancelimage={() => {
                                        let empty = "";
                                        setFieldValue(
                                          `promotionCreation.${index}.imageMedia`,
                                          empty
                                        );
                                      }}
                                      fieldhelpertext={
                                        (touched?.promotionCreation &&
                                        touched?.promotionCreation[index] &&
                                        touched?.promotionCreation[index]
                                          .imageMedia
                                          ? "Required"
                                          : "") &&
                                        (element.imageMedia.length >
                                        PromotionAndOfferCreationConst.minimumLength
                                          ? ""
                                          : "Required")
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </FormFieldContainer>
                          </div>
                        ))}
                    </div>
                  )}
                />
              </FormFieldContainer>
            </Container>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default PromotionsAndOffersCreation;
