import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import styles from "./ProjectCoverImages.module.css";
import React, { Fragment, useState } from "react";
import * as yup from "yup";
import { LoadingButtonComponent } from "components/ui-elements";
import Imageuploaderdropbox from "components/ui-elements/Imageuploaderdropbox";
import { projectCoverImagesInitialValues } from "./ProjectCoverImagesInitialValues";
import ProjectCoverImagesUploader from "./ProjectCoverImagesUploader";

function ProjectCoverImages() {
  // const [heightAndWidthOfHomePageMedia,setHeightAndWidthOfHomePageMedia]=useState({mediaHeight:197,mediaWidth:194});
  // const [heightAndWidthOfNewInvestmentPageMedia,setHeightAndWidthOfNewInvestmentPageMedia]=useState({mediaHeight:44,mediaWidth:44});
  // const [heightAndWidthOfCollectionListViewPageMedia,setheightAndWidthOfCollectionListViewPageMedia]
  return (
    <Fragment>
      <Form noValidate>
        <div>
          <Grid container rowSpacing={4} columnSpacing={8} padding={4}>
            <Grid item xs={4} md={4} lg={4} xl={4}>
              <Typography className={styles["heading-alignment"]}>
                Home Page
              </Typography>
              <ProjectCoverImagesUploader
                Id={1}
                name="homePageMedia"
                mediaWidth={194}
                mediaHeight={197}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4} xl={4}>
              <Typography className={styles["heading-alignment"]}>
                New Investment Page
              </Typography>
              <ProjectCoverImagesUploader
                Id={2}
                name="newInvestmentPageMedia"
                mediaWidth={44}
                mediaHeight={44}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4} xl={4}>
              <Typography className={styles["heading-alignment"]}>
                Collection list view Page
              </Typography>
              <ProjectCoverImagesUploader
                Id={3}
                name="collectionListViewPageMedia"
                mediaWidth={150}
                mediaHeight={74}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4} xl={4}>
              <Typography className={styles["heading-alignment"]}>
                Portfolio Page
              </Typography>
              <ProjectCoverImagesUploader
                Id={4}
                name="portfolioPageMedia"
                mediaWidth={37}
                mediaHeight={37}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4} xl={4}>
              <Typography className={styles["heading-alignment"]}>
                Chat list view Page
              </Typography>
              <ProjectCoverImagesUploader
                Id={5}
                name="chatListViewPageMedia"
                mediaWidth={55}
                mediaHeight={55}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4} xl={4}>
              <Typography className={styles["heading-alignment"]}>
                Chat Page
              </Typography>
              <ProjectCoverImagesUploader
                Id={6}
                name="chatPageMedia"
                mediaWidth={25}
                mediaHeight={25}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4} xl={4}>
              <Typography className={styles["heading-alignment"]}>
                Mobile Cover Media
              </Typography>
              <ProjectCoverImagesUploader
                Id={7}
                name="mobileCoverMedia"
                mediaWidth={55}
                mediaHeight={55}
              />
            </Grid>
          </Grid>

          {/* <Divider />
          <Stack
            columnGap={1}
            spacing={1}
            justifyContent={"flex-end"}
            padding={2}
            paddingRight={4}
          >
            <LoadingButtonComponent type="submit">Save</LoadingButtonComponent>
          </Stack> */}
        </div>
      </Form>
    </Fragment>
  );
}

export default ProjectCoverImages;
