import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  fileUploadRequest: ["data"],
  fileUploadSuccess: ["response"],
  fileUploadFailure: ["error"],

  fileDeleteRequest: ["data"],
  fileDeleteSuccess: ["response"],
  fileDeleteFailure: ["error"],
});

export const FileUploadTypes = Types;

const FileUploadActions = Creators;

export default FileUploadActions;

export const INITIAL_STATE = {
  isLoading: false,
  error: "",
  imageDetails: {
    url: "",
    file: "",
  },
  isSuccess: false,
  data: null,
};

export const fileUploadRequest = (state: any) => {
  const newState = {
    ...state,
    isLoading: true,
  };
  return newState;
};

export const fileUploadSuccess = (state: any, action: any) => {
  let url = action.response?.data?.preSignedUrl;
  if (url) {
    url = url.split("?")[0];
  }
  const newState = {
    ...state,
    isLoading: false,
    imageDetails: {
      url,
      file: action.response.file,
    },
    data: action.response.message || "Successfully uploaded",
    isSuccess: true,
  };

  return newState;
};

export const fileUploadFailure = (state: any, action: any) => {
  const newState = {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
  return newState;
};

export const fileDeleteRequest = (state: any) => {
  const newState = {
    ...state,
    isLoading: true,
  };
  return newState;
};

export const fileDeleteSuccess = (state: any, action: any) => {
  let message = action.response?.message;

  const newState = {
    ...state,
    isLoading: false,
    error: null,
    data: message || "Successfully deleted",
    isSuccess: true,
  };

  return newState;
};

export const fileDeleteFailure = (state: any, action: any) => {
  let message = action.error?.message;
  const newState = {
    ...state,
    isLoading: false,
    error: message,
    isSuccess: false,
    data: message,
  };

  return newState;
};

// creating reducer
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FILE_UPLOAD_REQUEST]: fileUploadRequest,
  [Types.FILE_UPLOAD_SUCCESS]: fileUploadSuccess,
  [Types.FILE_UPLOAD_FAILURE]: fileUploadFailure,

  [Types.FILE_DELETE_REQUEST]: fileDeleteRequest,
  [Types.FILE_DELETE_SUCCESS]: fileDeleteSuccess,
  [Types.FILE_DELETE_FAILURE]: fileDeleteFailure,
});
