import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";

import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { GetAllCategoriesAction } from "redux-container/faq/GetAllCategoriesRedux";

export function* getAllCategories(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.getAllCategories, action.data);
        yield put(GetAllCategoriesAction.getAllCategoriesSuccess(response.data));
    } catch (error) {
        yield put(GetAllCategoriesAction.getAllCategoriesFailure(error));
    }
}

//ADD NEW CATEGORY 

export function* addNewCategory(api: any, action: any): any {
    try {
        const response = yield call(api.addNewCategory, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GetAllCategoriesAction.addCategorySuccess(response.data));
        } else {
            yield put(GetAllCategoriesAction.addCategoryFailure(response.data));
        }
    } catch (error) {
        yield put(GetAllCategoriesAction.addCategoryFailure(error));
    }
}

//Delete category

export function* deleteCategory(api: any, action: any): any {
    try {
        const response = yield call(api.deleteCategory, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {

            yield put(GetAllCategoriesAction.deleteCategorySuccess(response.data));
        } else {
            yield put(GetAllCategoriesAction.deleteCategoryFailure(response.data));
        }
    } catch (error) {
        yield put(GetAllCategoriesAction.deleteCategoryFailure(error));
    }
}

//put category 

export function* putCategory(api: any, action: any): any {
   
    try {
        const response = yield call(api.putCategory, action.id, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GetAllCategoriesAction.putCategorySuccess(response.data));
        } else {
            yield put(GetAllCategoriesAction.putCategoryFailure(response.data));
        }
    } catch (error) {
        yield put(GetAllCategoriesAction.putCategoryFailure(error));
    }
}

//bulk status changes for category 
export function* bulkStatusCategory(api: any, action: any): any {

    try {
        const response = yield call(api.changeFaqsStatus,action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GetAllCategoriesAction.bulkStatusCategorySuccess(response.data));
        } else {
            yield put(GetAllCategoriesAction.bulkStatusCategoryFailure(response.data));
        }
    } catch (error) {
        yield put(GetAllCategoriesAction.bulkStatusCategoryFailure(error));
    }
}
