import { Constants } from "constants/Constants";
import { faqRoutePathConst } from "pages/resource-management/faq/faqRoutePathConst";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    //get all category 
    projectCategoryForFaqCreationSuccess: ["response"],
    projectCategoryForFaqCreationFailure: ["error"],
    projectCategoryForFaqCreationRequest: ["data"],

    //add 
    addProjectCategoryForFaqCreationSuccess: ["response"],
    addProjectCategoryForFaqCreationFailure: ["error"],
    addProjectCategoryForFaqCreationRequest: ["data"],

    //delete
    deleteProjectCategoryForFaqCreationSuccess: ["response"],
    deleteProjectCategoryForFaqCreationFailure: ["error"],
    deleteProjectCategoryForFaqCreationRequest: ["data"],

    //put 
    putProjectCategoryForFaqCreationSuccess: ["response"],
    putProjectCategoryForFaqCreationFailure: ["error"],
    putProjectCategoryForFaqCreationRequest: ["id", "data"],

    //bulk status change 
    bulkStatusProjectCategoryForFaqCreationSuccess: ["response"],
    bulkStatusProjectCategoryForFaqCreationFailure: ["error"],
    bulkStatusProjectCategoryForFaqCreationRequest: ["data"],

    setProjectCategoryForFaqCreationState: ["key", "value"],
});

export const projectCategoryForFaqCreationTypes = Types;

export const projectCategoryForFaqAction = Creators;

export const INITIAL_STATE = {
    projectFaqCategories: [],
    error: null,
    isLoading: false,
    isSuccessProjectFaqCategories: false,
    message: null,
    responseProjectFaqCategoryData: null,
    projectRouteToPageCategory: null,
};

/* Get Categories */
export const projectCategoryForFaqCreationRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
    };
};

export const projectCategoryForFaqCreationSuccess = (state: any, action: any) => {
    const response = [{ name: "Select an option or create one" }, ...action.response.data];

    if (response) {
        return {
            ...state,
            error: null,
            projectFaqCategories: response,
            isLoading: false,
        };
    }
    //   return {
    //     ...state,
    //     isLoading: false,
    //     snackBarError: action.response ? action.response.message : "Failed to load",
    //   };
};

export const projectCategoryForFaqCreationFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
    };
};


//add catagories

export const addProjectCategoryForFaqCreationRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccessProjectFaqCategories: false,
        responseProjectFaqCategoryData: null,
    };
};

export const addProjectCategoryForFaqCreationSuccess = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        error: null,
        isLoading: false,
        isSuccessProjectFaqCategories: true,
        responseProjectFaqCategoryData: action.response,
    };
};

export const addProjectCategoryForFaqCreationFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
        isSuccessProjectFaqCategories: false,
        responseProjectFaqCategoryData: action.error

    };
};

//delete category
export const deleteProjectCategoryForFaqCreationRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccessProjectFaqCategories: false,
        responseProjectFaqCategoryData: null,
        projectRouteToPageCategory: null,
    };
};


export const deleteProjectCategoryForFaqCreationFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
        isSuccessProjectFaqCategories: true,
        responseProjectFaqCategoryData: action.error,
        projectRouteToPageCategory: null
    };
};


export const deleteProjectCategoryForFaqCreationSuccess = (state: any, action: any) => {

    return {
        ...state,
        error: null,
        isSuccessProjectFaqCategories: true,
        isLoading: false,
        responseProjectFaqCategoryData: action.response,
        projectRouteToPageCategory: faqRoutePathConst.CategoryDeleteFormList
    };
};


//editing the category

export const putProjectCategoryForFaqCreationFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        isSuccessProjectFaqCategories: true,
        responseProjectFaqCategoryData: action.error,
        error: action.error.message,
    };
};



export const putProjectCategoryForFaqCreationRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccessProjectFaqCategories: false,
        responseProjectFaqCategoryData: null
    };
};

export const putProjectCategoryForFaqCreationSuccess = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        error: null,
        isLoading: false,
        isSuccessProjectFaqCategories: true,
        responseProjectFaqCategoryData: action.response,
    };
};
//bulk status changes
export const bulkStatusProjectCategoryForFaqCreationRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccessProjectFaqCategories: false,
        responseProjectFaqCategoryData: null
    };
};

export const bulkStatusProjectCategoryForFaqCreationFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        isSuccessProjectFaqCategories: true,
        responseProjectFaqCategoryData: action.error,
        error: action.error.message,
    };
};

export const bulkStatusProjectCategoryForFaqCreationSuccess = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        error: null,
        isLoading: false,
        isSuccessProjectFaqCategories: true,
        responseProjectFaqCategoryData: action.response,
    };
};


//seting upcategory
export const setProjectCategoryForFaqCreationState = (state: any, action: any) => {

    const newState = {
        ...state,
    };
    newState[action.key] = action.value;
    return newState;
};


export const reducer = createReducer(INITIAL_STATE, {
    [Types.PROJECT_CATEGORY_FOR_FAQ_CREATION_REQUEST]: projectCategoryForFaqCreationRequest,
    [Types.PROJECT_CATEGORY_FOR_FAQ_CREATION_SUCCESS]: projectCategoryForFaqCreationSuccess,
    [Types.PROJECT_CATEGORY_FOR_FAQ_CREATION_FAILURE]: projectCategoryForFaqCreationFailure,

    [Types.ADD_PROJECT_CATEGORY_FOR_FAQ_CREATION_REQUEST]: addProjectCategoryForFaqCreationRequest,
    [Types.ADD_PROJECT_CATEGORY_FOR_FAQ_CREATION_SUCCESS]: addProjectCategoryForFaqCreationSuccess,
    [Types.ADD_PROJECT_CATEGORY_FOR_FAQ_CREATION_FAILURE]: addProjectCategoryForFaqCreationFailure,

    [Types.DELETE_PROJECT_CATEGORY_FOR_FAQ_CREATION_REQUEST]: deleteProjectCategoryForFaqCreationRequest,
    [Types.DELETE_PROJECT_CATEGORY_FOR_FAQ_CREATION_SUCCESS]: deleteProjectCategoryForFaqCreationSuccess,
    [Types.DELETE_PROJECT_CATEGORY_FOR_FAQ_CREATION_FAILURE]: deleteProjectCategoryForFaqCreationFailure,


    [Types.PUT_PROJECT_CATEGORY_FOR_FAQ_CREATION_REQUEST]: putProjectCategoryForFaqCreationRequest,
    [Types.PUT_PROJECT_CATEGORY_FOR_FAQ_CREATION_SUCCESS]: putProjectCategoryForFaqCreationSuccess,
    [Types.PUT_PROJECT_CATEGORY_FOR_FAQ_CREATION_FAILURE]: putProjectCategoryForFaqCreationFailure,

    [Types.BULK_STATUS_PROJECT_CATEGORY_FOR_FAQ_CREATION_SUCCESS]: bulkStatusProjectCategoryForFaqCreationSuccess,
    [Types.BULK_STATUS_PROJECT_CATEGORY_FOR_FAQ_CREATION_FAILURE]: bulkStatusProjectCategoryForFaqCreationFailure,
    [Types.BULK_STATUS_PROJECT_CATEGORY_FOR_FAQ_CREATION_REQUEST]: bulkStatusProjectCategoryForFaqCreationRequest,

    [Types.SET_PROJECT_CATEGORY_FOR_FAQ_CREATION_STATE]: setProjectCategoryForFaqCreationState,
});
