export const TestimonialCreationConstants = {
  CustomerNameCharacterLimit: 20,
  SubTitleCharacterLimit: 20,
  TestimonialContentsLimit: 46,
  minLength: 0,
};

export const testimonialsLabels: any = {
  firstName: "First Name",
  lastName: "Last Name",
  designation: "Destination",
  companyName: "Company Name",
  testimonial: "Testimonial content Or URL",
};
