import {
  ProjectRegistration,
  InfrastructureAndAmenitiesDevelopment,
  MasterDesignAndProjectBoundary,
  LandManagement,
  PlotDelivery,
} from "./sections/index";

interface IProjectTimelineSections {
  id?: number;
  title?: string;
  component?: React.ReactNode;
}

const ProjectTimelineSections: IProjectTimelineSections[] = [
  {
    id: 1,
    title: "Project Registration",
    component: <ProjectRegistration />,
  },
  {
    id: 2,
    title: "Master Design & Project Boundary",
    component: <MasterDesignAndProjectBoundary />,
  },
  {
    id: 3,
    title: "Infrastructure & Amenities Development ",
    component: <InfrastructureAndAmenitiesDevelopment />,
  },
  {
    id: 4,
    title: "Plot Delivery",
    component: <PlotDelivery />,
  },
  {
    id: 5,
    title: "Land Management",
    component: <LandManagement />,
  },
];

const statusOptions = [
  {
    id: 1,
    title: "Not Started Yet",
  },
  {
    id: 2,
    title: "Started",
  },
  {
    id: 3,
    title: "Completed",
  },
];

const infraSubHeadings = [
  {
    id: 1,
    title: "Internal Roads",
  },
  {
    id: 2,
    title: "Amenities & Club House",
  },
  {
    id: 3,
    title: "Other Infra Development",
  },
];

const plotDeliverySubHeadings = [
  {
    id: 1,
    title: "Electrical & Plumbing",
  },
  {
    id: 2,
    title: "Clearing & Levelling",
  },
  {
    id: 3,
    title: "Plot Boundry Construction",
  },
  {
    id: 4,
    title: "Plot Handover ",
  },
];

export {
  ProjectTimelineSections,
  statusOptions,
  infraSubHeadings,
  plotDeliverySubHeadings,
};
