import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";
import styles from "./ConfirmationDialog.module.css";

interface IConfirmationDialogProps {
  shouldOpen: boolean;
  title?: string;
  content: string;
  okText?: string;
  showWarning?: boolean;
  cancelHandler: (e?: any) => any;
  okHandler?: (e?: any) => any;
  cancelBtnText?: string;
  notifycheckoptions?: ReactNode;
  isOkDisabled?: boolean;
}

const ConfirmationDialog = ({
  shouldOpen,
  title,
  content,
  okText,
  showWarning = false,
  cancelHandler,
  okHandler,
  cancelBtnText = "Cancel",
  notifycheckoptions,
  isOkDisabled,
}: IConfirmationDialogProps) => {
  const dialogTitle = title;
  const dialogContent = content ? content : "";
  const okBtnText = okText ? okText : "Okay";

  const handleClose = () => {
    cancelHandler();
  };

  const handleOkay = () => {
    okHandler ? okHandler() : cancelHandler();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={shouldOpen}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>

      <DialogContent>
        {showWarning ? (
          <Alert severity="warning" className={styles["confirm-dialog-alert"]}>
            <AlertTitle>
              <strong>Warning</strong>
            </AlertTitle>
            This action cannot be undone!
          </Alert>
        ) : null}

        <DialogContentText>{dialogContent}</DialogContentText>
        {notifycheckoptions}
      </DialogContent>

      <DialogActions className={styles["confirm-dialog-actions"]}>
        <Button
          color="inherit"
          variant="contained"
          onClick={handleClose}
          disableElevation
          className="btn btn-light-outline"
        >
          {cancelBtnText}
        </Button>
        <Button
          variant="contained"
          onClick={handleOkay}
          // autoFocus
          disableElevation
          className="btn btn-dark"
          disabled={isOkDisabled}
        >
          {okBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
