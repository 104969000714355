import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({
  formsListSuccess: ["response"],
  formsListFailure: ["error"],
  formsListRequest: ["data"],

  getProjectsSuccess: ["response"],
  getProjectsFailure: ["error"],
  getProjectsRequest: ["data"],

  getLaunchesSuccess: ["response"],
  getLaunchesFailure: ["error"],
  getLaunchesRequest: ["data"],

  updateFormStatusRequest: ["data"],
  updateFormStatusSuccess: ["response"],
  updateFormStatusFailure: ["error"],

  deleteFormsRequest: ["data"],
  deleteFormsSuccess: ["response"],
  deleteFormsFailure: ["error"],

  setFormListState: ["key", "value"],
});

export const FormsListTypes = Types;
export default Creators;
export const INITIAL_STATE = {
  forms: [],
  projects: [],
  launches: [],
  error: null,
  isLoading: false,
  isSuccess: false,
  shouldOpenFlashDialog: false,
  message: null,
  newFormpage: 0,
  newFormRowsPerPage: 5,
  publishedFormpage: 0,
  publishedFormRowsPerPage: 5,
  totalCount: Constants.defaultTotalCount,

  tabValue: "1",
};

export const formsListRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const formsListSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      forms: response.data,
      isLoading: false,
      totalCount: response.totalCount,
    };
  }
};

export const formsListFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};
export const getProjectsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getProjectsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      projects: response.data,
      isLoading: false,
    };
  }
};

export const getProjectsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};
export const getLaunchesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getLaunchesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      launches: response.data,
      isLoading: false,
    };
  }
};

export const getLaunchesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const updateFormStatusRequest = (state: any) => {
  return {
    ...state,
    isloading: true,
  };
};

export const updateFormStatusSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: true,
    shouldOpenFlashDialog: true,
    message: action.response.message,
  };
};
export const updateFormStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isloading: false,
    isSuccess: false,
    shouldOpenFlashDialog: true,
    message: action.error.message,
  };
};

export const deleteFormsRequest = (state: any) => {
  return {
    ...state,
    isloading: true,
  };
};

export const deleteFormsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    message: "",
    shouldOpenFlashDialog: true,
    isSuccess: false,
  };
};
export const deleteFormsSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    message: action.response.message,
    shouldOpenFlashDialog: true,
    isSuccess: true,
  };
};

export const setFormListState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FORMS_LIST_SUCCESS]: formsListSuccess,
  [Types.FORMS_LIST_FAILURE]: formsListFailure,
  [Types.FORMS_LIST_REQUEST]: formsListRequest,

  [Types.GET_PROJECTS_SUCCESS]: getProjectsSuccess,
  [Types.GET_PROJECTS_FAILURE]: getProjectsFailure,
  [Types.GET_PROJECTS_REQUEST]: getProjectsRequest,

  [Types.GET_LAUNCHES_SUCCESS]: getLaunchesSuccess,
  [Types.GET_LAUNCHES_FAILURE]: getLaunchesFailure,
  [Types.GET_LAUNCHES_REQUEST]: getLaunchesRequest,

  [Types.UPDATE_FORM_STATUS_REQUEST]: updateFormStatusRequest,
  [Types.UPDATE_FORM_STATUS_SUCCESS]: updateFormStatusSuccess,
  [Types.UPDATE_FORM_STATUS_FAILURE]: updateFormStatusFailure,

  [Types.DELETE_FORMS_REQUEST]: deleteFormsRequest,
  [Types.DELETE_FORMS_SUCCESS]: deleteFormsSuccess,
  [Types.DELETE_FORMS_FAILURE]: deleteFormsFailure,

  [Types.SET_FORM_LIST_STATE]: setFormListState,
});
