import { FormikValues } from "formik";
import { RouteConstants } from "routes/RouteConstants";

const enum PromiseMediaTypeEnum {
  PromiseIcon = "6001",
  PromiseDisplayIcon = "6002",
}

export interface promiseCreationStateType {
  deleteConfirm?: boolean;
  publishConfirm: boolean;
  isViewMode: boolean;
  formValues: FormikValues;
  buttonText: string;
}
export interface AddPromiseContent {
  name: string;
  shortDescription: string;
  isHowToApplyActive: boolean;
  howToApply: {
    title: string;
    description: string;
  };
  isPromiseCTADetailsActive: boolean;
  promiseCTADetails: {
    ctaName: string;
    toolTipDescription: string;
    promiseCTAAction: string;
  };
  description: string[];
  promiseIconType: string;
  isTermsAndConditionsActive: boolean;
  termsAndConditions: {
    displayName: string;
    description: string;
  };
  displayMedia: {
    name: string;
    key: string;
    value: {
      mediaType: string;
      size: number;
      width: number;
      height: number;
      url: string;
    };
  };
  promiseMedia: {
    name: string;
    key: string;
    value: {
      mediaType: string;
      size: number;
      width: number;
      height: number;
      url: string;
    };
  };
}

export const promiseCreationInitialValues: AddPromiseContent = {
  name: "",
  shortDescription: "",
  isHowToApplyActive: false,
  howToApply: {
    title: "",
    description: "",
  },
  isPromiseCTADetailsActive: false,
  isTermsAndConditionsActive: false,
  termsAndConditions: {
    displayName: "",
    description: "",
  },
  promiseCTADetails: {
    ctaName: "",
    toolTipDescription: "",
    promiseCTAAction: "",
  },
  description: [""],
  promiseIconType: PromiseMediaTypeEnum.PromiseIcon,
  displayMedia: {
    name: "",
    key: "",
    value: {
      mediaType: "",
      size: 0,
      width: 0,
      height: 0,
      url: "",
    },
  },
  promiseMedia: {
    name: "",
    key: "",
    value: {
      mediaType: "",
      size: 0,
      width: 0,
      height: 0,
      url: "",
    },
  },
};

export const promiseCreationLabels: any = {
  name: "Promise Name *",
  shortDescription: "Short description *",
  isHowToApplyActive: "How To Apply Toggle",
  howToApply: {
    title: "howToApply Display Name *",
    description: "howToApply Description *",
  },
  isPromiseCTADetailsActive: "Promises CTA Details toggle",
  promiseCTADetails: {
    ctaName: "promise CTA Details Display Name *",
    toolTipDescription: "promise CTA Details Description ",
    promiseCTAAction: "CTA Action",
  },
  isTermsAndConditionsActive: "Terms and Conditions toggle",
  termsAndConditions: {
    displayName: "termsAndConditions Display Name *",
    description: "termsAndConditions Description ",
  },
  description: "Pointer",
  displayMedia: {
    value: {
      url: "Promise Icon",
    },
  },
};

export const fieldTitle = {
  promiseName: "Promise Name *",
  shortDescription: "Short description *",
  displayName: "Display Name *",
  descriptionForHowToApply: "Description *",
  promiseImageURL: "Promise Image *",
  ctaName: "CTA Name",
  ctaTooltipDescription: "Tooltip Text Description",
  promiseCTAAction: "CTA Action",
};

export const textFieldMaxCharLimitFor = {
  displayName: 40,
  descriptionForHowToApply: 250,
  discriptionForTermsAndConditions: 100000,
};

export const promiseBreadcrumbRoutes = (routName?: any, isViewMode?: any) => {
  return [
    {
      name: "Promise Management",
      route: RouteConstants.promiseCreation,
    },
    {
      name: "Promise",
      route: RouteConstants.promiseList,
    },
    {
      name:
        routName === "Create Promise"
          ? routName
          : `${isViewMode ? "View" : "Edit"} - ${routName}`,
      route: RouteConstants.promiseCreation,
    },
  ];
};

export const settingMenu = [
  {
    id: 1,
    name: "Mark as Inactive",
  },
  {
    id: 2,
    name: "Delete",
  },
];

export const promiseMediaTypes = [
  {
    id: 1,
    label: "Promise Icon",
    value: PromiseMediaTypeEnum.PromiseIcon,
    infoContent: "This Icon will be displayed as Cover image on Customer App",
  },
];
