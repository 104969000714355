import { ChangeEvent } from "react";

const isMobileNumberValid = (ph: string) => {
  const regX = new RegExp(/^((?!(0))[0-9]{10})$/);
  return regX.test(ph);
};

const shouldNumberOnly = (num: string) => {
  const regX = new RegExp(/^\d*[.]?\d*$/);
  return regX.test(num.toString());
};

const minAndSecHourSet = (num: string | number) => {
  if (Number(num) > 59) {
    return false;
  }
  return true;
};

const isPasswordValid = (ph: string) => {
  const regX = new RegExp(/^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,32})$/);
  return regX.test(ph);
};

const isEmailValid = (email: string) => {
  const regX = new RegExp(
    /^[a-zA-Z0-9_]+(\.[_a-zA-Z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/
  );
  return regX.test(email);
};

const isEmpty = (str: string) => {
  return str.length === 0 ? true : false;
};

const isLatValid = (Lat: string) => {
  const regX = new RegExp(
    "^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,6})?))$"
  );
  return !regX.test(Lat);
};

const isLongValid = (Long: string) => {
  const regX = new RegExp(
    "^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,6})?))$"
  );
  return !regX.test(Long);
};

const stripHtml = (html: string) => {
  // Create a new div element
  let temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html?.trim();
  // Retrieve the text property of the element (cross-browser support)
  return (
    temporalDivElement.textContent?.trimStart() ||
    temporalDivElement.innerText.trimStart() ||
    ""
  );
};
const removeHtmlTags=(input:string)=> {
  return input.replace(/<\/?[^>]+(>|$)/g, "");
}
const isValidYoutubeUrl = (url: any) => {
  let regX = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regX);
  return match && match[2].length === 11 ? match[2] : null;
};
const getYoutubeVideoId = (url: any) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url?.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

/* These are the types of URLs supported

http://www.youtube.com/watch?v=0zM3nApSvMg&feature=feedrec_grec_index
http://www.youtube.com/user/IngridMichaelsonVEVO#p/a/u/1/QdK8U-VIH_o
http://www.youtube.com/v/0zM3nApSvMg?fs=1&amp;hl=en_US&amp;rel=0
http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
http://www.youtube.com/embed/0zM3nApSvMg?rel=0
http://www.youtube.com/watch?v=0zM3nApSvMg
http://youtu.be/0zM3nApSvMg
*/

const handleNumberFieldChange =
  (setFieldValue: any) => (event: ChangeEvent<HTMLInputElement>) => {
    if (shouldNumberOnly(event.target.value)) {
      setFieldValue(`${event.target.name}`, event.target.value);
    }
  };

const handleChangeNoSpecialCharacter =
  (setFieldValue: any, path?: any, value?: any) =>
  (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      `${event.target.name}`,
      event.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
    );
    if (path) {
      setFieldValue(path, event.target.value.replace(/[^a-zA-Z0-9 ]/g, ""));
    }
  };

// const handleChangeNo;

export {
  isMobileNumberValid,
  isPasswordValid,
  isEmailValid,
  isEmpty,
  shouldNumberOnly,
  isLatValid,
  isLongValid,
  minAndSecHourSet,
  stripHtml,
  removeHtmlTags,
  isValidYoutubeUrl,
  handleNumberFieldChange,
  getYoutubeVideoId,
  handleChangeNoSpecialCharacter,
};
