import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import { ButtonComponent, SwitchComponent } from "components/ui-elements";
import { Formik, useFormikContext } from "formik";
import ImageUploderOffersAndPromotions from "./ImageUploderOffersAndPromotions";
import { OffersAndPromotionsInitialValues } from "./OffersAndPromotionsInitialValues";
import { offersAndPromotionsValidationSchema } from "./OffersAndPromotionsValidationSchema";
import styles from "./OffersAndPromotions.module.css";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { Fragment } from "react";
import { useSelector } from "react-redux";

const Offers = () => {
  const { values, setFieldValue, handleChange, errors, touched } =
    useFormikContext<IInitinalValueProjectContent>();
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );
  return (
    <Fragment>
      <Box padding="2rem">
        <Stack justifyContent={"flex-start"} spacing={1}>
          <Typography>Offers & Promotions</Typography>

          <SwitchComponent
            name="isOffersAndPromotionsActive"
            onChange={handleChange}
            checked={values?.isOffersAndPromotionsActive}
            disabled={isViewMode}
          />

          <IconButton>
            <Tooltip title={"Offer And Promotions"} placement="top">
              <Avatar
                className={styles["Avator"]}
                src={MediaAssets.ic_info}
                variant="rounded"
              />
            </Tooltip>
          </IconButton>
        </Stack>
        <Grid item xs={3} md={3} lg={3} xl={3}>
          <ImageUploderOffersAndPromotions />
        </Grid>
      </Box>
      {/* <Divider sx={{ mt: "0.5rem" }} /> */}
      {/* <Grid container padding={2} justify-content={"flex-end"}>
      <Divider sx={{ mt: "0.5rem" }} />
      <Grid container padding={2} justify-content={"flex-end"}>
        <Grid item xs={12} textAlign="right">
          <ButtonComponent
            type="submit"
            variant="contained"
            disabled={isViewMode}
          >
            Save
          </ButtonComponent>
        </Grid>
      </Grid> */}
    </Fragment>
  );
};
export default Offers;
