export const InsightsCreationConstants = {
  titleCharacterLimit: 90,
  subTitleCharacterLimit: 20,
  minimumLength: 0,

  videoDescription: 50,
  imageDescription: 50,

  mediaDescription: 50,
  description: 1000,
};
