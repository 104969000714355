import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import {
  InfoComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { useFormikContext, getIn } from "formik";
import { Fragment } from "react";
import { useSelector } from "react-redux";

const LandManagement = () => {
  const { values, errors, touched, handleBlur, handleChange } =
    useFormikContext<any>();
  const landSectionIndex = 4;
  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));
  return (
    <Fragment>
      {values?.projectTimelines[landSectionIndex]?.timeLines?.map(
        (timeline: any, timelineIndex: number) => (
          <Fragment key={timelineIndex + 1}>
            <Box className="section-gutter">
              <Grid container>
                <Grid item lg={3} xl={3}>
                  <TextFieldComponent
                    label="Section Heading*"
                    inputProps={{ maxLength: 40 }}
                    name={`projectTimelines[${landSectionIndex}].timeLineSectionHeading`}
                    value={
                      values?.projectTimelines[landSectionIndex]?.timeLineSectionHeading
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      getIn(
                        touched,
                        `projectTimelines[${landSectionIndex}].timeLineSectionHeading`
                      ) &&
                        getIn(
                          errors,
                          `projectTimelines[${landSectionIndex}].timeLineSectionHeading`
                        )
                    )}
                    fieldhelpertext={
                      getIn(
                        touched,
                        `projectTimelines[${landSectionIndex}].timeLineSectionHeading`
                      ) &&
                      getIn(
                        errors,
                        `projectTimelines[${landSectionIndex}].timeLineSectionHeading`
                      )
                    }
                    disabled={isViewMode}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
              <Fragment key={timelineIndex + 1}>
                <Stack className="field-container-box">
                  <Typography>5.1 Manage my Land</Typography>
                  <SwitchComponent
                    name={`projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].isSectionActive`}
                    onChange={handleChange}
                    checked={
                      values?.projectTimelines[landSectionIndex]?.timeLines[
                        timelineIndex
                      ]?.isSectionActive
                    }
                    disabled={isViewMode}
                  />
                  <InfoComponent />
                </Stack>
                <Box className="section-gutter">
                  <Grid container columnSpacing={5} rowSpacing={3}>
                    <Grid item lg={3} xl={3}>
                      <TextFieldComponent
                        label="Display Name*"
                        inputProps={{ maxLength: 30 }}
                        onChange={handleChange}
                        value={
                          values?.projectTimelines[landSectionIndex]?.timeLines[
                            timelineIndex
                          ]?.values?.displayName
                        }
                        name={`projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].values.displayName`}
                        onBlur={handleBlur}
                        error={Boolean(
                          getIn(
                            touched,
                            `projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].values.displayName`
                          ) &&
                            getIn(
                              errors,
                              `projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].values.displayName`
                            )
                        )}
                        fieldhelpertext={
                          getIn(
                            touched,
                            `projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].values.displayName`
                          ) &&
                          getIn(
                            errors,
                            `projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].values.displayName`
                          )
                        }
                        disabled={isViewMode}
                      />
                    </Grid>
                    <Grid item lg={12} xl={12}>
                      <TextFieldComponent
                        label="Tool tip Details *"
                        inputProps={{ maxLength: 240 }}
                        name={`projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`}
                        onChange={handleChange}
                        value={
                          values?.projectTimelines[landSectionIndex]?.timeLines[
                            timelineIndex
                          ]?.values?.toolTipDetails
                        }
                        onBlur={handleBlur}
                        error={Boolean(
                          getIn(
                            touched,
                            `projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                          ) &&
                            getIn(
                              errors,
                              `projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                            )
                        )}
                        fieldhelpertext={
                          getIn(
                            touched,
                            `projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                          ) &&
                          getIn(
                            errors,
                            `projectTimelines[${landSectionIndex}].timeLines[${timelineIndex}].values.toolTipDetails`
                          )
                        }
                        disabled={isViewMode}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Fragment>
          </Fragment>
        )
      )}
    </Fragment>
  );
};

export default LandManagement;
