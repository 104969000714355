import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
    blockedCustomerListRequest: ["data"],
    blockedCustomerListSuccess: ["response"],
    blockedCustomerListFailure: ["error"],

    unblockedCustomerRequest: ["data"],
    unblockedCustomerSuccess: ["response"],
    unblockedCustomerFailure: ["error"],


    setBlockedCustomerListState: ["key", "value"],
});

export const blockedCustomerListTypes = Types;

export default Creators;

export const INITIAL_STATE = {
    blockedCustomerList: [],
    isLoading: false,
    snackbarError: "",
    isSuccess: false,
    message: null,
    error: null,
    shouldOpenFlashDialog:false
};
//GET API: get the blocked customers list 
export const blockedCustomerListRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
    }
};

export const blockedCustomerListSuccess = (state: any, action: any) => {
    const response = action.response;
    if (response) {
        return {
            ...state,
            error: null,
            blockedCustomerList: response.data,
            isLoading: false,
        };
    };
};

export const blockedCustomerListFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
    };
};

// PUT API: unblock customer

export const unblockedCustomerRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccess: false,
    };
};

export const unblockedCustomerSuccess = (state: any, action: any) => {
    const response = action.response;

    if (response) {
        return {
          ...state,
          error: null,
          isSuccess: true,
          message: response.message
            ? response.message
            : "Blocked Customer updated Successfully!",
          isLoading: false,
          shouldOpenFlashDialog:true
        };
    };
};

export const unblockedCustomerFailure = (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      error: action.error.message,
      isSuccess: false,
      shouldOpenFlashDialog: true,
    };
};
//set state
export const setBlockedCustomerListState = (state: any, action: any) => {
    const newState = {
        ...state,
    };
    newState[action.key] = action.value;
    return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.BLOCKED_CUSTOMER_LIST_REQUEST]: blockedCustomerListRequest,
    [Types.BLOCKED_CUSTOMER_LIST_SUCCESS]: blockedCustomerListSuccess,
    [Types.BLOCKED_CUSTOMER_LIST_FAILURE]: blockedCustomerListFailure,

    [Types.UNBLOCKED_CUSTOMER_REQUEST]: unblockedCustomerRequest,
    [Types.UNBLOCKED_CUSTOMER_SUCCESS]: unblockedCustomerSuccess,
    [Types.UNBLOCKED_CUSTOMER_FAILURE]: unblockedCustomerFailure,

    [Types.SET_BLOCKED_CUSTOMER_LIST_STATE]: setBlockedCustomerListState,
}
)
