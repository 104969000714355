import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Stack,
  CardMedia,
  CardActions,
  Card,
} from "@mui/material";
import { useFormikContext } from "formik";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VideoLabelTwoToneIcon from "@mui/icons-material/VideoLabelTwoTone";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import { getYoutubeVideoId } from "utils/ValidationUtils";
import { number } from "yargs";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { useState, useEffect } from "react";

const tableHeadings = [
  { heading: "Web Thumbnail" },
  { heading: "Mobile Thumbnail" },
  { heading: "Media Type" },
  // { heading: "Redirect to" },
  { heading: "Priority" },
  { heading: "Action" },
];

function BrandVideoList(props: any) {
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  console.log("BrandVideoList values", values);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [brandMediaItemToDelete, setBrandMeidaItemToDelete] = useState<any>({});

  console.log("isConfirmDeleteDialogOpen", isConfirmDeleteDialogOpen);

  const deleteBrandMediaItem = (index: number, brandMediaItem: any) => {
    console.log("index>", index, "item", brandMediaItem);

    const locallistArray: any[] = values?.brandMedia;
    console.log("locallistArray", locallistArray);

    locallistArray?.splice(index, 1);
    console.log("locallistArray splice", locallistArray);

    for (let i = index; i < locallistArray?.length; i++) {
      console.log("locallistArray loop", locallistArray);
      locallistArray[i].priority = locallistArray[i].priority - 1;
    }

    const localCarouselsToDelete: any[] = values?.brandMediasToDelete;
    console.log("localCarouselsToDelete1", brandMediaItem);

    localCarouselsToDelete?.push(brandMediaItem?.id);
    setFieldValue("brandMediasToDelete", localCarouselsToDelete);

    console.log("localCarouselsToDelete2", localCarouselsToDelete);

    setFieldValue("brandMedia", locallistArray);

    if (!locallistArray?.length) {
      setFieldValue("isBrandMediaPresent", false);
    } else if (locallistArray?.length > 0 && !values?.isBrandMediaPresent) {
      setFieldValue("isBrandMediaPresent", true);
    }

    setIsConfirmDeleteDialogOpen(false);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className="table-container"
        style={{ height: window.innerHeight - 475 }}
      >
        {values?.brandMedia?.map((item: any) => {
          return <></>;
        })}
        <Table>
          <TableHead style={{ position: "sticky", top: 0, zIndex: 2 }}>
            <TableRow
              className="table-header-row"
              style={{ borderTopWidth: 0, width: "250px" }}
            >
              {tableHeadings?.map((item, index) => (
                <TableCell key={index} className="table-header-text">
                  {item.heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {values?.brandMedia && values?.brandMedia?.length > 0 ? (
            <TableBody>
              {values?.brandMedia.map((brandMediaItem: any, index: number) => {
                return (
                  <TableRow
                    className="table-data-row"
                    hover
                    role="checkbox"
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    // selected={isItemSelected}
                  >
                    <TableCell
                      className="table-data-text"
                      // style={{ width: "250px" }}
                    >
                      <Card
                        elevation={0}
                        sx={{
                          position: "relative",
                          "& .MuiSvgIcon-root": {
                            width: "4rem",
                            height: "4rem",
                            marginLeft: "1.5rem",
                            textAlign: "center",
                          },
                          backgroundColor: "#f7f6f8",
                        }}
                        // className={styles["media-container"]}
                      >
                        <VideoLabelTwoToneIcon fontSize="large" />
                      </Card>
                    </TableCell>
                    <TableCell
                      className="table-data-text"
                      // style={{ width: "250px" }}
                    >
                      <Card
                        elevation={0}
                        sx={{
                          position: "relative",
                          "& .MuiSvgIcon-root": {
                            width: "4rem",
                            height: "4rem",
                            marginLeft: "1.5rem",
                          },
                          backgroundColor: "#f7f6f8",
                        }}
                        // className={styles["media-container"]}
                      >
                        <MobileScreenShareIcon fontSize="large" />
                      </Card>
                    </TableCell>

                    <TableCell
                      style={{
                        minWidth: 150,
                        maxWidth: 300,
                        wordBreak: "break-all",
                      }}
                      className="table-data-text"
                      align={"left"}
                    >
                      {"Video"}
                    </TableCell>

                    {/* <TableCell
                      style={{ minWidth: 100 }}
                      className="table-data-text"
                    >
                      {brandMediaItem?.projectContent?.id !== "0" &&
                      brandMediaItem?.projectContent?.id
                        ? brandMediaItem?.projectContent?.launchName
                        : brandMediaItem?.redirectToPage == 7000
                        ? "Refer Now"
                        : "----"}{" "}
                    </TableCell> */}

                    <TableCell
                      style={{ minWidth: 140 }}
                      className="table-data-text"
                    >
                      {/* {index + 1}{" "} */}
                      <PrioritySelector
                        priority={brandMediaItem?.priority}
                        incrementpriority={() => {
                          const locallistArray: any[] = values?.brandMedia;
                          locallistArray[index].priority =
                            locallistArray[index]?.priority + 1;
                          locallistArray[index + 1].priority =
                            locallistArray[index]?.priority - 1;

                          const temp = locallistArray[index];
                          locallistArray[index] = locallistArray[index + 1];
                          locallistArray[index + 1] = temp;

                          setFieldValue("brandMedia", locallistArray);
                        }}
                        decrementpriority={() => {
                          const locallistArray: any[] = values?.brandMedia;
                          locallistArray[index].priority =
                            locallistArray[index]?.priority - 1;
                          locallistArray[index - 1].priority =
                            locallistArray[index]?.priority + 1;

                          const temp = locallistArray[index];
                          locallistArray[index] = locallistArray[index - 1];
                          locallistArray[index - 1] = temp;

                          setFieldValue("brandMedia", locallistArray);
                        }}
                        disableDecrement={
                          values?.brandMedia[index]?.priority == 1
                        }
                        disableIncrement={
                          values?.brandMedia[index]?.priority ==
                          values?.brandMedia?.length
                        }
                      />
                    </TableCell>

                    <TableCell style={{ width: "110px" }}>
                      <Stack justifyContent={"center"}>
                        <IconButton
                          aria-label="view"
                          onClick={() =>
                            props.setOpenMediaUploadDialog({
                              open: true,
                              pageManagementOrBrandMedia: brandMediaItem,
                            })
                          }
                        >
                          <ModeEditOutlineOutlinedIcon className="table-action-icon view-icon" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            console.log(
                              "index1",
                              index,
                              "brandMediaItem",
                              brandMediaItem
                            );
                            setBrandMeidaItemToDelete({
                              index: index,
                              brandMediaItem: brandMediaItem,
                            });
                            setIsConfirmDeleteDialogOpen(true);
                          }}
                        >
                          <Typography
                            sx={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Remove
                          </Typography>
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Are you sure you want to Remove ?"
        content={``}
        okText="Yes"
        cancelBtnText="No"
        cancelHandler={() => setIsConfirmDeleteDialogOpen(false)}
        okHandler={() => {
          deleteBrandMediaItem(
            brandMediaItemToDelete?.index,
            brandMediaItemToDelete?.brandMediaItem
          );
          setBrandMeidaItemToDelete({});
        }}
      />
    </>
  );
}

export default BrandVideoList;
