import { useEffect, useState } from "react";
import Loader from "../components/loader/Loader";

const Delayed = ({ children, waitBeforeShow = 500 }: any) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : <Loader />;
};

export { Delayed };
