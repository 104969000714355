import React, { Fragment, useEffect, useState } from "react";
import { FieldArray, Form, Formik, getIn, useFormikContext } from "formik";
import { Box, Grid, IconButton, Link, Stack } from "@mui/material";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  AutocompleteComponent,
  ButtonComponent,
  FormFieldContainer,
  LoadingButtonComponent,
} from "components/ui-elements";
import { RouteConstants } from "routes/RouteConstants";
import { FAQInitialValues } from "pages/resource-management/faq/faq-creation/FAQInitialValues";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { projectCategoryForFaqAction } from "redux-container/project-management/ProjectCategoryForFaqCreationRedux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import ProjectCategoryEditPopUp from "./ProjectCategoryEditPopUp";
import NewProjectFaqBox from "./NewProjectFaqBox";
import { FAQSchemaValidation } from "pages/resource-management/faq/faq-creation/FAQSchemaValidation";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { IObjectProps } from "pages/user-management/user-creation/UserCreationInitialValues";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { Constants } from "constants/Constants";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    deleteProjectCategoryforFaq: (data: any) =>
      dispatch(
        projectCategoryForFaqAction.deleteProjectCategoryForFaqCreationRequest(
          data
        )
      ),
    addProjectCategoryforFaq: (data: any) =>
      dispatch(
        projectCategoryForFaqAction.addProjectCategoryForFaqCreationRequest(
          data
        )
      ),
    projectFaqCreationRequest: (data: any) =>
      dispatch(ProjectFaqActions.projectFaqCreationRequest(data)),
  };
};

export default function ProjectFaqCreation(props: any) {
  const { handleEditable, isEditable } = props;
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<IObjectProps>();

  const { projectFaqCategories, isLoading } = useSelector(
    (state: any) => state.projectFaqsCategories
  );
  const {
    addProjectCategoryforFaq,
    deleteProjectCategoryforFaq,
    projectFaqCreationRequest,
  } = actionDispatch(useDispatch());
  const navigate: any = useNavigate();
  const [isConfirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
    useState(false);
  const [deletedId, setDeletedId] = useState(0);
  const [categoryName, setCategoryName] = useState("");
  const [openFaqEditDialog, setOpenFaqEditDialog] = useState(false);
  const [editCategory, setEditCategory] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const location: any = useLocation();
  const [openForm, setOpenForm] = useState(
    location?.state?.openForm ? true : false
  );
  const [openErrorFlashmessage, setOpenErrorFlashmessage] = useState(false);
  const { formikValues, formikSetFieldValue } = props;

  const [categoryNameList, setCategoryNameList]: any = useState(
    formikValues?.otherSectionHeadings?.faqSection?.categoryNameList?.length > 1
      ? formikValues?.otherSectionHeadings?.faqSection?.categoryNameList
      : ["Select an option or create one"]
  );

  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  //geting individual category list

  //editing category
  const handleClickCategoryEditClose = () => {
    setOpenFaqEditDialog(false);
  };
  const handleEditCategoryInputChange = (event: any) => {
    const onlyChars = event.target.value.replace(/[^A-Za-z]/gi, "");
    setEditCategory(onlyChars);
  };

  const handleClickOpenCategoryEdit = (option: any) => {
    setOpenFaqEditDialog(true);
    setEditCategory(option.name);
    setCategoryId(option.id);
  };

  //opening for delete confirmation dialog
  const handleOpenConfirmDialog = (e: any) => {
    setConfirmDeleteDialogOpen(true);
  };
  const handleCloseConfirmDialog = () => {
    setConfirmDeleteDialogOpen(false);
  };
  const handleResetCategoryField = () => {
    setEditCategory("");
  };
  // deleteing category

  const deletedCategory = () => {
    const removeDeleteCategoryList =
      formikValues?.otherSectionHeadings?.faqSection?.categoryNameList?.filter(
        (item: any) => item !== categoryName
      );
    formikSetFieldValue(
      "otherSectionHeadings.faqSection.categoryNameList",
      removeDeleteCategoryList
    );
    deleteProjectCategoryforFaq(deletedId);
    setConfirmDeleteDialogOpen(false);
  };

  const filteredItem = projectFaqCategories?.filter((item: any) =>
    categoryNameList?.includes(item?.name)
  );
  const removeDupliacteIds = filteredItem?.filter(
    (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i
  );
  const removeDuplicatesFilteredItem = removeDupliacteIds.filter(
    (v: any, i: any, a: any) =>
      a.findIndex((v2: any) => v2.name === v.name) === i
  );

  const removeNullProjectContentId = removeDuplicatesFilteredItem?.filter(
    (item: any) =>
      item?.projectContentId === null ||
      item?.projectContentId === location?.ProjectListCreationId
  );

  useEffect(() => {
    const RemoveFirstIndex = removeNullProjectContentId;
    let removedFirstIndex = RemoveFirstIndex.slice(
      1,
      removeNullProjectContentId?.length + 1
    );
    const mappedCategoriesIds = removedFirstIndex?.map((item: any) => item?.id);

    formikSetFieldValue("mappedCategories", mappedCategoriesIds);
  }, [filteredItem?.length, projectFaqCategories?.length]);

  const handleClickCategoryCreate = () => {
    const reqBody = {
      name: values?.categoryId,
      categoryType: "2002",
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    const isCategoryExists = filteredItem?.filter(
      (item: any) => item?.name === reqBody?.name
    );
    if (isCategoryExists?.length > 0) {
      setOpenErrorFlashmessage(true);
    } else {
      addProjectCategoryforFaq(reqBody);
      setCategoryNameList([...categoryNameList, reqBody?.name]);
      // const categoyNamesRemoveDuplicates = [...categoryNameList, reqBody?.name].filter((v : any,i : any,a : any)=>a.findIndex((v2 : any)=>(v2.name===v.name))===i)
      formikSetFieldValue("otherSectionHeadings.faqSection.categoryNameList", [
        ...categoryNameList,
        reqBody?.name,
      ]);
    }
  };

  const handleCloseFlashMessageDialog = () => {
    setOpenErrorFlashmessage(false);
  };

  const handleCategoryChange = (value: any) => {
    setFieldValue("categoryId", value);
    setOpenForm(true);
  };

  const [selectedCategory, setSelectedCategory] = useState(
    location?.state?.category ? location?.state?.category : null
  );

  useEffect(() => {
    if (location?.state?.openForm && !values?.categoryId) {
      handleCategoryChange(location?.state?.categoryId);
    }
  }, [location?.state, values?.categoryId]);

  return (
    <>
      <Box>
        {openErrorFlashmessage && (
          <FlashMessageDialog
            shouldOpen={openErrorFlashmessage}
            content={"Category Already Exists"}
            isSuccess={false}
            cancelHandler={handleCloseFlashMessageDialog}
          />
        )}
        <Grid container mt={"1rem"}>
          <Grid item xs={4} md={3} lg={3} xl={3}>
            {location.state.isFaqTable ? null : (
              <AutocompleteComponent
                name={"categoryId"}
                onBlur={handleBlur}
                value={selectedCategory}
                options={removeDuplicatesFilteredItem}
                label="Category*"
                size="medium"
                loading={isLoading && isLoading}
                disabled={
                  (!location?.state?.isCreateNewCategory && selectedCategory) ||
                  isViewMode
                    ? true
                    : false
                }
                error={errors?.categoryId && touched?.categoryId ? true : false}
                helpertext={
                  errors?.categoryId && touched?.categoryId
                    ? errors?.categoryId
                    : ""
                }
                isOptionEqualToValue={(
                  option: { name: string },
                  value: { name: string }
                ) => option?.name === value?.name}
                // onChange={(event: React.SyntheticEvent, newValue: any) => {
                //   setFieldValue("categoryId", newValue?.name);
                //   setOpenForm(true);
                // }}
                onChange={(event: React.SyntheticEvent, newValue: any) => {
                  handleCategoryChange(newValue?.name);
                  setSelectedCategory(newValue);
                }}
                getOptionLabel={(option: any) => option?.name}
                getOptionDisabled={(option: any) =>
                  option === projectFaqCategories[0]
                }
                noOptionsText={
                  <div>
                    <Link
                      sx={{
                        color: "black",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleClickCategoryCreate}
                    >
                      Create
                    </Link>
                    <span style={{ color: "#676ac0" }}>
                      {" "}
                      {` ${values?.categoryId}`}
                    </span>
                  </div>
                }
                onInputChange={(event: any, newValue: any) => {
                  const onlyChars = newValue.replace(/[^A-Za-z]/gi, "");
                  setFieldValue("categoryId", onlyChars);
                  setOpenForm(false);
                }}
                renderOption={(props: any, option: any) => (
                  <Fragment>
                    <div>
                      <Stack direction="row" justifyContent={"space-between"}>
                        <Box
                          {...props}
                          sx={{
                            width: `${
                              option == projectFaqCategories[0] ? "100%" : "60%"
                            }`,
                          }}
                        >
                          {option.name}
                        </Box>
                        {option !== projectFaqCategories[0] && (
                          <div>
                            <IconButton
                              disabled={
                                values?.categoryId === option?.name ||
                                isViewMode
                              }
                              onClick={() => {
                                handleClickOpenCategoryEdit(option);
                              }}
                            >
                              <ModeEditOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="error"
                              disabled={
                                values?.categoryId === option?.name ||
                                isViewMode
                              }
                              onClick={(e: any) => {
                                handleOpenConfirmDialog(e);
                                setDeletedId(option.id);
                                setCategoryName(option?.name);
                              }}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </div>
                        )}
                      </Stack>
                    </div>
                  </Fragment>
                )}
              />
            )}
          </Grid>
        </Grid>

        {((values?.categoryId && openForm) || location?.state?.isFormEdit) &&
        selectedCategory ? (
          <FieldArray
            name="faqs"
            render={({ push, remove }) => (
              <Box>
                {values.faqs.map((field: any, fieldIndex: number) => {
                  const questionFieldName = `faqs[${fieldIndex}].faqQuestion.question`;
                  const questionFieldValue =
                    values?.faqs[fieldIndex]?.faqQuestion?.question;
                  const answerFieldName = `faqs[${fieldIndex}].faqAnswer.answer`;
                  const answerFieldValue =
                    values?.faqs[fieldIndex]?.faqAnswer?.answer;
                  const touchedQuestion = getIn(touched, questionFieldName);
                  const errorsQuestion = getIn(errors, questionFieldName);
                  const errorAnsfield = getIn(errors, answerFieldName);
                  const touchedAnsfield = getIn(touched, answerFieldName);

                  return (
                    <FormFieldContainer>
                      <Grid container spacing={2}>
                        <Grid item xs={11.5} md={11.5} lg={11.5} xl={11.5}>
                          <NewProjectFaqBox
                            questionFieldName={questionFieldName}
                            fieldId={fieldIndex}
                            handleDelete={remove}
                            answerFieldName={answerFieldName}
                            answerFieldValue={answerFieldValue}
                            questionFieldValue={questionFieldValue}
                            touchedQuestion={touchedQuestion}
                            errorsQuestion={errorsQuestion}
                            errorAnsfield={errorAnsfield}
                            touchedAnsfield={touchedAnsfield}
                            handleEditable={handleEditable}
                            isEditable={isEditable}
                          />
                        </Grid>
                        {values.faqs?.length > 1 && (
                          <Grid mt={6} item xs={0.5} md={0.5} lg={0.5} xl={0.5}>
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => remove(fieldIndex)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </FormFieldContainer>
                  );
                })}
                <Box mt={"1rem"}>
                  {!location.state.isFaqTable ? (
                    <ButtonComponent
                      variant="contained"
                      onClick={() => push(FAQInitialValues.faqs[0])}
                    >
                      + Add more FAQ'S
                    </ButtonComponent>
                  ) : null}
                </Box>
              </Box>
            )}
          />
        ) : (
          !location.state.isFaqTable && (
            <Box sx={{ textAlign: "center" }}>
              Please Select Category to Create FAQs
            </Box>
          )
        )}
      </Box>

      <ProjectCategoryEditPopUp
        setCategoryNameList={setCategoryNameList}
        categoryNameList={categoryNameList}
        open={openFaqEditDialog}
        handleClose={handleClickCategoryEditClose}
        editCategory={editCategory}
        handleInputChange={handleEditCategoryInputChange}
        handleResetCategoryField={handleResetCategoryField}
        categoryId={categoryId}
      />

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Catagory?"
        content={`This action will delete the  ${categoryName} Category and related FAQs permanently. Are you sure you want to continue?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deletedCategory}
      />
    </>
  );
}
