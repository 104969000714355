import { Fragment, useState, useEffect } from "react";
import {
  Container,
  FormControlLabel,
  Typography,
  Grid,
  Divider,
  IconButton,
  Stack,
  Collapse,
  Link,
  Box,
  MenuItem,
  // Checkbox,
} from "@mui/material";
import PageHeading from "components/page-heading/PageHeading";
import {
  ButtonComponent,
  InfoComponent,
  LoadingButtonComponent,
  RadioButtonGroupComponent,
  StyledRadio,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import {
  Formik,
  Form,
  getIn,
  FormikHelpers,
  FormikValues,
  FieldArray,
} from "formik";
import {
  fieldTitle,
  promiseBreadcrumbRoutes,
  promiseCreationInitialValues,
  promiseCreationLabels,
  promiseCreationStateType,
  promiseMediaTypes,
  textFieldMaxCharLimitFor,
} from "./PromiseCreationConstants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { PromiseCreationContainer } from "./PromiseCreation.style";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdatesStatusTypeEnum,
  UpdatesStatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import QuillEditor from "components/quill-editor/QuillEditor";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { PromisesActions } from "redux-container/promises/PromisesCreationRedux";
import moment from "moment";
import PromiseIconTypes from "./promise-components/PromiseIconTypes";
import { RouteConstants } from "routes/RouteConstants";
import { stripHtml } from "utils/ValidationUtils";
import SettingMenuDropdown from "components/ui-elements/setting-menu-dropdown/SettingMenuDropdown";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { validationSchema } from "./PromisesValidation";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { CTAActionTypeEnumUtils } from "enumerations/CTAActionTypeEnum";
import TermsAndConditions from "./TermsAndConditions";

const actionDispatch = (dispatch: any) => {
  return {
    promisesUpdateRequest: (id: number, data: any) =>
      dispatch(PromisesActions.promisesUpdateRequest(id, data)),
    promisesRequest: (data: any) =>
      dispatch(PromisesActions.promisesCreationRequest(data)),
    getAllPromisesData: () => dispatch(PromisesActions.getAllPromisesRequest()),
    setPromisesState: (key: string, data: any) =>
      dispatch(PromisesActions.setPromisesState(key, data)),
    promisesStatusRequest: (data: any) =>
      dispatch(PromisesActions.promisesStatusRequest(data)),
    removePromisesRequest: (id: number) =>
      dispatch(PromisesActions.removePromisesRequest(id)),
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    getAuditLogRequest: (data: any) =>
      dispatch(AuditActions.getAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const PromiseCreation = () => {
  const navigate = useNavigate();
  const CTAActionTypes = CTAActionTypeEnumUtils.getCTAActionTypeEnums();
  const location: any = useLocation();
  const {
    promisesRequest,
    promisesUpdateRequest,
    setPromisesState,
    promisesStatusRequest,
    removePromisesRequest,
    postAuditLogRequest,
    rolesAndModulesRequest,
  } = actionDispatch(useDispatch());

  const {
    isSuccess,
    responseMessage,
    error,
    shouldDialogOpen,
    isLoading,
    userData,
    modules,
    auditedObject,
  } = useSelector((state: any) => ({
    responseMessage: state?.promisesCreation?.responseMessage,
    error: state?.promisesCreation?.error,
    shouldDialogOpen: state?.promisesCreation?.shouldDialogOpen,
    isSuccess: state?.promisesCreation?.isSuccess,
    auditedObject: state?.promisesCreation?.auditedObject,
    isLoading: state?.promisesCreation?.isLoading,
    userData: state?.users?.usersForFilters,
    modules: state?.rolesAndModules?.module,
  }));

  const initialFormValues = () => {
    if (location.state !== null) {
      return location?.state;
    } else {
      return promiseCreationInitialValues;
    }
  };
  const [previousValues, setPreviousValues] = useState<any>(
    initialFormValues()
  );
  const [finalValues, setFinalValues] = useState<any>(null);
  const [modulesAudited, setModulesAudited] = useState<any>([]);

  const [statusAudited, setStatusAudited] = useState<any>(null);
  const [isItemDeleted, setIsItemDeleted] = useState(false);
  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);
  const [categoryCount, setCategoryCount] = useState(
    location?.state?.description?.length
  );
  const [promiseState, setPromiseState] = useState<promiseCreationStateType>({
    deleteConfirm: false,
    publishConfirm: false,
    isViewMode: location?.state?.isView || false,
    formValues: initialFormValues(),
    buttonText: location?.state ? "Publish Changes" : "Publish",
  });

  useEffect(() => {
    rolesAndModulesRequest();
    setCategoryCount(location?.state?.description?.length);
    return () => {};
  }, []);

  const handleCloseFlashMessageDialog = () => {
    setPromisesState("shouldDialogOpen", false);
    setPromisesState("error", null);
    setPromiseState((prevState) => ({
      ...prevState,
      publishConfirm: false,
      deleteConfirm: false,
    }));
    if (isSuccess) {
      const moduleObject: any = modules?.find((element: any) => {
        return element?.name === "Promise Management";
      });
      if (modulesAudited?.length) {
        if (isSuccess && location?.state) {
          //edited.
          const postAuditRequestbody = {
            ipAddress: ipAddress, //ipAddress
            moduleId: moduleObject?.id,
            route: location.pathname,
            auditedObject: finalValues,
            modulesAudited: modulesAudited,

            status: AuditStatus.EDITED,
          };
          postAuditLogRequest(postAuditRequestbody);
        } else {
          //new created.
          const postAuditRequestbody = {
            ipAddress: ipAddress, //ipAddress
            moduleId: moduleObject?.id,
            route: location.pathname,
            auditedObject: auditedObject,
            // modulesAudited: modulesAudited,
            modulesAudited: [
              {
                itemAffected: `Promise ${finalValues?.name} Created.`,
                newValue: "",
                oldValue: "",
              },
            ],

            status: AuditStatus.CREATED,
          };
          postAuditLogRequest(postAuditRequestbody);
        }
      } else if (statusAudited) {
        const postAuditRequestbody = {
          ipAddress: ipAddress, //ipAddress
          moduleId: moduleObject?.id,
          route: location.pathname,
          auditedObject: {
            ...initialFormValues(),
            status:
              statusAudited === "Published"
                ? UpdatesStatusTypeEnum.Published
                : UpdatesStatusTypeEnum.Inactive,
          },
          modulesAudited: [
            {
              itemAffected: `Promise Status`,
              newValue: statusAudited,
              oldValue:
                statusAudited === "Published" ? "Inactive" : "Published",
            },
          ],
          status: AuditStatus.EDITED,
        };
        postAuditLogRequest(postAuditRequestbody);
      } else if (isItemDeleted) {
        const postAuditRequestbody = {
          ipAddress: ipAddress, //ipAddress
          moduleId: moduleObject?.id,
          route: null,
          auditedObject: null,
          modulesAudited: [
            {
              itemAffected: `Promise==>${location?.state?.name} Deleted. (ID ${location?.state?.id})`,
              newValue: "",
              oldValue: "",
            },
          ],
          status: AuditStatus.DELETED,
        };
        postAuditLogRequest(postAuditRequestbody);
      }
      navigate(-1);
    }
  };

  const handleTextEditor = (
    name: string,
    newValue: string,
    options: any,
    { setFieldValue, setFieldError, setFieldTouched }: any
  ) => {
    // if (options?.isError) {
    //   setFieldError(name, "Maximum character exceed");
    // } else {
    setFieldValue(name, newValue);
    // }
    setFieldTouched(name, true);
  };

  const handleDeleteOkClick = () => {
    removePromisesRequest(promiseState.formValues.id);
    setPromiseState((prevState) => ({
      ...prevState,
      deleteConfirm: false,
    }));
    setPromisesState("shouldDialogOpen", true);
  };

  const handleDialogClose = () => {
    setPromiseState((prevState) => ({
      ...prevState,
      deleteConfirm: false,
      publishConfirm: false,
    }));
    setPromisesState("shouldDialogOpen", false);
    setPromisesState("error", null);
  };

  const onDeleteClick = () => {
    setPromiseState((prevState) => ({
      ...prevState,
      deleteConfirm: true,
    }));
    setIsItemDeleted(true);
  };

  const handleStatusChange = () => {
    setPromiseState((prevState) => ({
      ...prevState,
    }));
    let currentStatus: string;
    if (promiseState.formValues.status === UpdatesStatusTypeEnum.Published) {
      currentStatus = UpdatesStatusTypeEnum.Inactive;
      setStatusAudited(
        UpdatesStatusTypeEnumUtils.getEnumText(UpdatesStatusTypeEnum.Inactive)
      );
    } else {
      currentStatus = UpdatesStatusTypeEnum.Published;
      setStatusAudited(
        UpdatesStatusTypeEnumUtils.getEnumText(UpdatesStatusTypeEnum.Published)
      );
    }
    const adminStatusPayload = {
      status: currentStatus,
      promiseIds: [promiseState.formValues.id],
    };
    promisesStatusRequest(adminStatusPayload);
    setPromisesState("shouldDialogOpen", true);
  };

  const handleEditClick = () => {
    setPromiseState((prev) => ({
      ...prev,
      isViewMode: false,
    }));
  };

  const createdAdminId = userData?.find(
    (userId: any) => userId.id === promiseState.formValues.createdBy
  );

  const updatedAdminId = userData?.find(
    (userId: any) => userId.id === promiseState.formValues.updatedBy
  );

  const handleAdminView = (type: string) => () => {
    if (promiseState.isViewMode) {
      if (type === "createdBy") {
        navigate(`${RouteConstants.userCreation}`, {
          state: { ...createdAdminId, isView: true },
        });
      } else {
        navigate(`${RouteConstants.userCreation}`, {
          state: { ...updatedAdminId, isView: true },
        });
      }
    }
  };

  const handleSaveClick = () => {
    if (location?.state && location?.state?.id === promiseState.formValues.id) {
      promisesUpdateRequest(
        promiseState.formValues.id,
        promiseState.formValues
      );
    } else {
      promisesRequest(promiseState.formValues);
    }
    setPromiseState((prevState) => ({
      ...prevState,
      publishConfirm: false,
      deleteConfirm: false,
    }));
    setPromisesState("shouldDialogOpen", false);
    setPromisesState("error", null);
  };

  const onSubmit = (
    // formValues: FormikValues,
    formikObjects: any,
    // formikProps: FormikHelpers<FormikValues>,
    formikProps: any
    // touched:any
  ) => {
    let formValues = formikObjects?.values;
    let touched = formikObjects?.touched;

    if (
      formValues?.isTermsAndConditionsActive &&
      stripHtml(formValues.termsAndConditions.description) === ""
    ) {
      return formikProps.setFieldError(
        "termsAndConditions.description",
        "description is required"
      );
    }
    setPromiseState((prevState) => ({
      ...prevState,
      publishConfirm: true,
      formValues,
    }));

    // if (location?.state !== null) {
    let auditObjectsArray: any[] = [];
    Object?.keys(formValues)?.map((key: any) => {
      if (
        key !== null &&
        formValues[key] &&
        formValues[key] !== null &&
        typeof formValues[key] == "object"
      ) {
        if (Array.isArray(formValues[key])) {
          //if key is array
          formValues[key].map((element: any, index: number) => {
            if (
              formValues[key][index] !== previousValues[key][index] &&
              promiseCreationLabels[key][0] !== undefined
            ) {
              let auditObject = {
                itemAffected: `${promiseCreationLabels[key]} ${index + 1}`,
                newValue: formValues[key][index]
                  ? stripHtml(formValues[key][index])
                  : "",
                oldValue: previousValues[key][index]
                  ? stripHtml(previousValues[key][index])
                  : "",
              };
              auditObjectsArray.push(auditObject);
            }
          });
        } else {
          //if key is object
          Object?.keys(formValues[key])?.map((objKey: any) => {
            if (
              formValues[key][objKey] &&
              formValues[key][objKey] !== null &&
              typeof formValues[key][objKey] == "object"
            ) {
              Object?.keys(formValues[key][objKey])?.map((mediaObject: any) => {
                if (mediaObject === "url") {
                  if (
                    promiseCreationLabels[key][objKey]["url"] !== undefined &&
                    formValues[key][objKey]["url"] !==
                      previousValues[key][objKey]["url"]
                  ) {
                    let auditObject = {
                      itemAffected: `${promiseCreationLabels[key][objKey]["url"]}`,
                      newValue: formValues[key][objKey]["url"],
                      oldValue: previousValues[key][objKey]["url"],
                    };
                    auditObjectsArray.push(auditObject);
                  }
                } else if (
                  promiseCreationLabels[key][objKey][mediaObject] !==
                    undefined &&
                  formValues[key][objKey][mediaObject] !==
                    previousValues[key][objKey][mediaObject]
                ) {
                  //"object inside object like value in displayMedia."

                  let auditObject = {
                    itemAffected: `${promiseCreationLabels[key][objKey][mediaObject]}`,
                    newValue: stripHtml(formValues[key][objKey][mediaObject]),
                    oldValue: stripHtml(
                      previousValues[key][objKey][mediaObject]
                    ),
                  };
                  auditObjectsArray.push(auditObject);
                }
              });
            } else if (
              formValues[key][objKey] !== null &&
              formValues[key][objKey] &&
              previousValues[key][objKey] &&
              (promiseCreationLabels["displayMedia"][objKey] !== undefined ||
                promiseCreationLabels["promiseMedia"][objKey] !== undefined ||
                promiseCreationLabels["termsAndConditions"][objKey] !==
                  undefined ||
                promiseCreationLabels["promiseCTADetails"][objKey] !==
                  undefined ||
                promiseCreationLabels["howToApply"][objKey] !== undefined) &&
              // promiseCreationLabels[key] !== undefined &&
              formValues[key][objKey] !== previousValues[key][objKey]
            ) {
              if (
                key == "displayMedia" ||
                key == "promiseMedia" ||
                key == "termsAndConditions" ||
                key == "promiseCTADetails" ||
                key == "howToApply"
              ) {
                let auditObject = {
                  itemAffected: `${promiseCreationLabels[key][objKey]}`,
                  newValue:
                    objKey == "promiseCTAAction"
                      ? CTAActionTypeEnumUtils.getEnumText(
                          formValues[key][objKey]
                        )
                      : formValues[key][objKey],
                  oldValue: previousValues[key][objKey]
                    ? objKey == "promiseCTAAction"
                      ? CTAActionTypeEnumUtils.getEnumText(
                          previousValues[key][objKey]
                        )
                      : previousValues[key][objKey]
                    : "----",
                };
                auditObjectsArray.push(auditObject);
              }
            }
          });
        }
      } else {
        // if key is just variable
        if (
          promiseCreationLabels[key] !== undefined &&
          formValues[key] !== previousValues[key] &&
          key !== "isHowToApplyActive" &&
          key !== "isTermsAndConditionsActive" &&
          key !== "isPromiseCTADetailsActive"
        ) {
          let auditObject = {
            itemAffected: promiseCreationLabels[key],
            newValue: stripHtml(formValues[key]),
            oldValue: stripHtml(previousValues[key]),
          };
          auditObjectsArray.push(auditObject);
        }
        if (
          promiseCreationLabels[key] !== undefined &&
          (key == "isHowToApplyActive" ||
            key == "isPromiseCTADetailsActive" ||
            key == "isTermsAndConditionsActive") &&
          formValues[key] !== previousValues[key]
          // true
        ) {
          let auditObject = {
            itemAffected: promiseCreationLabels[key],
            newValue: formValues[key] ? "On" : "Off",
            oldValue: previousValues[key] ? "On" : "Off",
          };
          auditObjectsArray.push(auditObject);
        }
      }
    });
    //set auditObjectsArray to a state to use it for api call ..
    // setModulesAudited(auditObjectsArray);
    setModulesAudited((prev: any) => {
      return [...prev, ...auditObjectsArray];
    });
    setFinalValues(formValues);
    // }
  };

  const handleSetPromiseTypeValue = (
    value: any,
    fieldName: string,
    setFieldValue: any
  ) => {
    setFieldValue(fieldName, value);
  };

  return (
    <Fragment>
      <PageHeading title="Promise Management" />
      <BreadCrumbs
        routes={promiseBreadcrumbRoutes(
          promiseState.formValues.name || "Create Promise",
          promiseState.isViewMode
        )}
      />
      <Container className="main-container">
        <Formik
          initialValues={promiseState.formValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            values,
            setFieldValue,
            handleChange,
            handleBlur,
            setFieldError,
            setFieldTouched,
            errors,
            touched,
          }) => (
            <Form data-testid="PromiseCreation">
              <Stack>
                <Stack>
                  <IconButton
                    color="inherit"
                    edge={"start"}
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  <Typography color={"secondary.dark"} variant="h5">
                    {promiseState.formValues.name || "Create Promise"}
                  </Typography>
                  <Collapse in={promiseState.formValues.id}>
                    <SettingMenuDropdown
                      disabled={
                        promiseState.isViewMode
                        //  ||
                        // previousValues?.isAuditedWhileCreating
                      }
                      onstatuschange={handleStatusChange}
                      ondelete={onDeleteClick}
                      statusmenutext={
                        promiseState.formValues.status ===
                        UpdatesStatusTypeEnum.Published
                          ? "Inactive"
                          : "Published"
                      }
                    />
                  </Collapse>
                </Stack>
                <Stack direction={"row"} columnGap={2}>
                  <Collapse in={!promiseState.isViewMode}>
                    <ButtonComponent
                      type="reset"
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      disabled={promiseState.isViewMode}
                    >
                      Cancel
                    </ButtonComponent>
                  </Collapse>
                  <Collapse
                    in={!promiseState.isViewMode}
                    orientation={"horizontal"}
                  >
                    <LoadingButtonComponent
                      type="submit"
                      disabled={
                        (values?.isTermsAndConditionsActive &&
                          !stripHtml(values?.termsAndConditions?.description)
                            ?.length) ||
                        !isValid ||
                        !dirty
                      }
                      onClick={(event: any) => {
                        onSubmit({ values, touched }, { setFieldError });
                      }}
                    >
                      {promiseState.buttonText}
                    </LoadingButtonComponent>
                  </Collapse>
                  <Collapse
                    in={promiseState.isViewMode}
                    orientation={"horizontal"}
                  >
                    <ButtonComponent
                      variant="contained"
                      type="button"
                      onClick={handleEditClick}
                    >
                      {"Edit"}
                    </ButtonComponent>
                  </Collapse>
                </Stack>
              </Stack>
              <Collapse in={promiseState.isViewMode}>
                <Stack columnGap={"1rem"} justifyContent={"flex-start"}>
                  <Typography>
                    <Typography component={"i"}> Created on:&nbsp;</Typography>
                    {`${moment(values.createdAt).format("L") || ""} by `}
                    <Link
                      component="button"
                      onClick={handleAdminView("createdBy")}
                    >
                      {createdAdminId?.firstName || "User"}
                    </Link>
                  </Typography>
                  <Typography>
                    <Typography component={"i"}>Modified on:&nbsp;</Typography>
                    {`${moment(values.updatedAt).format("L") || ""} by `}
                    <Link
                      component="button"
                      onClick={handleAdminView("updatedBy")}
                    >
                      {updatedAdminId?.firstName || "User"}
                    </Link>
                  </Typography>
                  <ButtonComponent
                    sx={{ textDecoration: "underline" }}
                    variant="text"
                    color="inherit"
                  >
                    View Modified Content
                  </ButtonComponent>
                </Stack>
              </Collapse>
              <PromiseCreationContainer>
                <Grid
                  container
                  mt={"0.5rem"}
                  spacing={3}
                  className={"box"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                    <TextFieldComponent
                      label={fieldTitle.promiseName}
                      disabled={promiseState.isViewMode}
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        "data-testid": "promise-name",
                      }}
                      InputProps={{
                        readOnly: promiseState.isViewMode,
                      }}
                      error={Boolean(errors.name && touched.name)}
                      fieldhelpertext={
                        errors.name && touched.name && errors.name
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                    <TextFieldComponent
                      name={"shortDescription"}
                      disabled={promiseState.isViewMode}
                      value={values.shortDescription}
                      label={fieldTitle.shortDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        "data-testid": "short-description",
                      }}
                      InputProps={{
                        readOnly: promiseState.isViewMode,
                      }}
                      error={Boolean(
                        errors.shortDescription && touched.shortDescription
                      )}
                      fieldhelpertext={
                        errors.shortDescription &&
                        touched.shortDescription &&
                        errors.shortDescription
                      }
                    />
                  </Grid>
                  <FieldArray name="description">
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const { values } = form;
                      const { description } = values;

                      return (
                        <Fragment>
                          {description?.map((desc: string, index: number) => (
                            <Fragment key={index + 1}>
                              <Grid item xs={11}>
                                <TextFieldComponent
                                  name={`description[${index}]`}
                                  value={description[index]}
                                  disabled={promiseState.isViewMode}
                                  label={`Pointer ${index + 1}`}
                                  inputProps={{
                                    "data-testid": "description",
                                  }}
                                  InputProps={{
                                    readOnly: promiseState.isViewMode,
                                  }}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fieldhelpertext={
                                    getIn(touched, `description[${index}]`) &&
                                    getIn(errors, `description[${index}]`)
                                  }
                                  error={Boolean(
                                    getIn(touched, `description[${index}]`) &&
                                      getIn(errors, `description[${index}]`)
                                  )}
                                />
                              </Grid>
                              {description?.length > 1 ? (
                                <Grid item xs={1}>
                                  <IconButton
                                    size="small"
                                    color="error"
                                    aria-label="delete"
                                    onClick={() => {
                                      remove(index);
                                      if (
                                        categoryCount >
                                        values?.description?.length - 1
                                      ) {
                                        setModulesAudited((prev: any) => [
                                          ...prev,
                                          {
                                            itemAffected: `Pointer ${
                                              index + 1
                                            } Deleted`,
                                            newValue: "",
                                            oldValue: "",
                                          },
                                        ]);
                                      }
                                    }}
                                    disabled={promiseState.isViewMode}
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </IconButton>
                                </Grid>
                              ) : null}
                            </Fragment>
                          ))}
                          <Grid item xs={12}>
                            <ButtonComponent
                              variant="text"
                              color="inherit"
                              sx={{ textDecoration: "underline" }}
                              onClick={() => push("")}
                              disabled={promiseState.isViewMode}
                            >
                              + Add More
                            </ButtonComponent>
                          </Grid>
                        </Fragment>
                      );
                    }}
                  </FieldArray>
                </Grid>
                <Divider />
                <Grid container mt={3}>
                  {promiseMediaTypes?.map((promiseType: any) => {
                    return (
                      <Grid
                        ml={3.5}
                        item
                        xs={6}
                        md={3.5}
                        sm={3.5}
                        lg={3.5}
                        xl={3.5}
                      >
                        <Stack
                          justifyContent={"flex-start"}
                          textAlign={"start"}
                          spacing={2}
                        >
                          <Typography>{promiseType.label}</Typography>

                          <InfoComponent
                            infoContent={promiseType.infoContent}
                          />
                        </Stack>
                      </Grid>
                    );
                  })}
                </Grid>
                <PromiseIconTypes isViewMode={promiseState.isViewMode} />
                <Divider />
                <Stack mt={"1.5rem"} justifyContent={"flex-start"}>
                  <FormControlLabel
                    className="switch-form-label"
                    name={"isHowToApplyActive"}
                    checked={values.isHowToApplyActive}
                    onChange={handleChange}
                    control={<SwitchComponent />}
                    label="How to apply"
                    labelPlacement="start"
                    disabled={promiseState.isViewMode}
                  />
                  <InfoComponent infoContent="Toggle is to show/hide this section on the Customer App" />
                </Stack>
                <Grid container rowSpacing={2} className={"box"}>
                  <Grid item xs={3}>
                    <TextFieldComponent
                      label={fieldTitle.displayName}
                      disabled={promiseState.isViewMode}
                      fieldhelpertext={
                        getIn(touched, "howToApply.title") &&
                        getIn(errors, "howToApply.title")
                      }
                      error={Boolean(
                        getIn(touched, "howToApply.title") &&
                          getIn(errors, "howToApply.title")
                      )}
                      inputProps={{
                        "data-testid": "title",
                        maxLength: textFieldMaxCharLimitFor.displayName,
                      }}
                      InputProps={{
                        readOnly: promiseState.isViewMode,
                      }}
                      value={values.howToApply?.title}
                      name={"howToApply.title"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={fieldTitle.descriptionForHowToApply}
                      disabled={promiseState.isViewMode}
                      fieldhelpertext={
                        getIn(touched, "howToApply.description") &&
                        getIn(errors, "howToApply.description")
                      }
                      error={Boolean(
                        getIn(touched, "howToApply.description") &&
                          getIn(errors, "howToApply.description")
                      )}
                      placeholder={"Enter"}
                      multiline
                      rows={3}
                      inputProps={{
                        "data-testid": "description-for-how-to-apply",
                        maxLength:
                          textFieldMaxCharLimitFor.descriptionForHowToApply,
                      }}
                      InputProps={{
                        readOnly: promiseState.isViewMode,
                      }}
                      value={values.howToApply?.description}
                      name={"howToApply.description"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Divider />

                <TermsAndConditions
                  isViewMode={promiseState?.isViewMode}
                  fieldTitle={fieldTitle}
                  handleTextEditor={handleTextEditor}
                />

                <Divider />

                <Stack mt={"1.5rem"} justifyContent={"flex-start"}>
                  <FormControlLabel
                    className="switch-form-label"
                    name={"isPromiseCTADetailsActive"}
                    checked={values.isPromiseCTADetailsActive}
                    onChange={(e, newValue) =>
                      setFieldValue("isPromiseCTADetailsActive", newValue)
                    }
                    control={<SwitchComponent />}
                    label="Promises CTA Details"
                    labelPlacement="start"
                    disabled={promiseState.isViewMode}
                  />
                  <InfoComponent infoContent="Toggle is to show/hide this section on the Customer App" />
                </Stack>
                <Grid container spacing={2} className={"box"}>
                  <Grid item xs={3}>
                    <TextFieldComponent
                      label={fieldTitle.ctaName}
                      disabled={promiseState.isViewMode}
                      fieldhelpertext={
                        getIn(touched, "promiseCTADetails.ctaName") &&
                        getIn(errors, "promiseCTADetails.ctaName")
                      }
                      error={Boolean(
                        getIn(touched, "promiseCTADetails.ctaName") &&
                          getIn(errors, "promiseCTADetails.ctaName")
                      )}
                      inputProps={{
                        "data-testid": "title",
                        maxLength: textFieldMaxCharLimitFor.displayName,
                      }}
                      InputProps={{
                        readOnly: promiseState.isViewMode,
                      }}
                      name={"promiseCTADetails.ctaName"}
                      value={values.promiseCTADetails?.ctaName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextFieldComponent
                      select
                      label={fieldTitle.promiseCTAAction}
                      disabled={promiseState.isViewMode}
                      fieldhelpertext={
                        getIn(touched, "promiseCTADetails.promiseCTAAction") &&
                        getIn(errors, "promiseCTADetails.promiseCTAAction")
                      }
                      error={Boolean(
                        getIn(touched, "promiseCTADetails.promiseCTAAction") &&
                          getIn(errors, "promiseCTADetails.promiseCTAAction")
                      )}
                      InputProps={{
                        readOnly: promiseState.isViewMode,
                      }}
                      name={"promiseCTADetails.promiseCTAAction"}
                      value={values.promiseCTADetails?.promiseCTAAction}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {CTAActionTypes.map((CTAActionType: any) => {
                        return (
                          <MenuItem
                            key={CTAActionType.value}
                            value={CTAActionType.value}
                          >
                            {CTAActionType.label}
                          </MenuItem>
                        );
                      })}
                    </TextFieldComponent>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={fieldTitle.ctaTooltipDescription}
                      disabled={promiseState.isViewMode}
                      fieldhelpertext={
                        getIn(
                          touched,
                          "promiseCTADetails.toolTipDescription"
                        ) &&
                        getIn(errors, "promiseCTADetails.toolTipDescription")
                      }
                      error={Boolean(
                        getIn(
                          touched,
                          "promiseCTADetails.toolTipDescription"
                        ) &&
                          getIn(errors, "promiseCTADetails.toolTipDescription")
                      )}
                      inputProps={{
                        "data-testid": "title",
                        maxLength:
                          textFieldMaxCharLimitFor.descriptionForHowToApply,
                      }}
                      InputProps={{
                        readOnly: promiseState.isViewMode,
                      }}
                      name={"promiseCTADetails.toolTipDescription"}
                      value={values.promiseCTADetails?.toolTipDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </PromiseCreationContainer>
              <ConfirmationDialog
                shouldOpen={!!promiseState.deleteConfirm}
                content="Are you sure you want to delete this promise?"
                okText="Yes"
                cancelBtnText={"No"}
                okHandler={handleDeleteOkClick}
                cancelHandler={handleDialogClose}
              />
              <ConfirmationDialog
                shouldOpen={promiseState.publishConfirm}
                content="Are you sure you want to Publish?"
                okText="Publish"
                cancelBtnText={"No"}
                cancelHandler={handleDialogClose}
                okHandler={handleSaveClick}
              />
              <FlashMessageDialog
                shouldOpen={shouldDialogOpen}
                content={responseMessage ? responseMessage : error}
                isSuccess={isSuccess}
                cancelHandler={handleCloseFlashMessageDialog}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Fragment>
  );
};

export default PromiseCreation;
