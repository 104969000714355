import { InfoOutlined } from "@mui/icons-material";
import {
  Button,
  Card,
  CardMedia,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import Loader from "components/loader/Loader";
import {
  AutocompleteComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PanoramaIcon from "@mui/icons-material/Panorama";
import PageHeading from "components/page-heading/PageHeading";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { RouteConstants } from "routes/RouteConstants";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { MediaAndPrsActions } from "redux-container/marketing-management/media-and-prs/MediaAndPrRedux";
import {
  MediaAndPrActionTypeEnum,
  MediaAndPrActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import UserActions from "../../../redux-container/users/UsersRedux";
import {
  TestimonialStatusTypeEnum,
  TestimonialStatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import styles from "../testimonials/testimonial-list/TestimonialList.module.css";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import { exportAsExcel } from "utils/ExportFileUtils";
import { useLocation, useNavigate } from "react-router-dom";

const actionDispatch = (dispatch: any) => {
  return {
    getMediaAndPrs: (params: any) =>
      dispatch(MediaAndPrsActions.getMediaAndPrsRequest(params)),
    deleteMediaAndPr: (data: any) =>
      dispatch(MediaAndPrsActions.deleteMediaAndPrRequest(data)),
    setMediaAndPrsState: (key: any, data: any) =>
      dispatch(MediaAndPrsActions.setMediaAndPrsState(key, data)),
    changeMediaAndPrStatus: (data: any) =>
      dispatch(MediaAndPrsActions.changeMediaAndPrStatusRequest(data)),
    updateMediaAndPrPriority: (data: any) =>
      dispatch(MediaAndPrsActions.updateMediaAndPrPriorityRequest(data)),
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

function MediaAndPrList() {
  const navigate = useNavigate();
  const location: any = useLocation();
  console.log("location", location);

  const {
    getMediaAndPrs,
    deleteMediaAndPr,
    changeMediaAndPrStatus,
    updateMediaAndPrPriority,
    setMediaAndPrsState,
    // getUsers,
    postAuditLogRequest,
    rolesAndModulesRequest,
  } = actionDispatch(useDispatch());
  const actions = MediaAndPrActionTypeEnumUtils.getMediaAndPrActionTypeEnum();
  const statuses =
    TestimonialStatusTypeEnumUtils.getTestimonialStatusTypeEnums();
  const users = useSelector((state: any) => state?.users?.usersForFilters);

  const mediaAndPrsList = useSelector(
    (state: any) => state?.mediaAndPrs.MediaAndPrList
  );

  const isLoading = useSelector((state: any) => state.mediaAndPrs?.isLoading);
  const message = useSelector((state: any) => state.mediaAndPrs?.message);
  const isSuccess = useSelector((state: any) => state.mediaAndPrs?.isSuccess);
  const isPriorityUpdated = useSelector(
    (state: any) => state.mediaAndPrs?.isPriorityUpdated
  );
  const error = useSelector((state: any) => state.mediaAndPrs?.error);
  const totalCount = useSelector((state: any) => state?.mediaAndPrs.totalCount);
  const { page, rowsPerPage, modules } = useSelector((state: any) => {
    return {
      page: state.mediaAndPrs?.page,
      rowsPerPage: state.mediaAndPrs?.rowsPerPage,
      modules: state?.rolesAndModules?.module,
    };
  });
  const [sortByPriority, setSortByPriority] = useState(true);
  const [selectedMediaAndPrs, setSelectedMediaAndPrs] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTestimonialType, setSelectedTestimonialType] = useState("");
  const [selectedCreatedBy, setCreatedBy] = useState<any>(null);
  const [selectedModifiedBy, setModifiedBy] = useState<any>(null);
  const [searchKey, setSearchKey] = useState("");

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);
  const [modulesAudited, setModulesAudited] = useState<any>(null);

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const getFilterDataFromDashboard = () => {
    if (location && location.state && location.state.statusType) {
      setSelectedStatus(location.state.statusType as string);
    }
    navigate(location.pathname, { replace: true });
  };

  useEffect(() => {
    getFilterDataFromDashboard();
    const params = {
      index: page,
      size: rowsPerPage,
      status: location?.state?.statusType
        ? location?.state?.statusType
        : selectedStatus,
      modifiedBy: selectedModifiedBy?.id,
      createdBy: selectedCreatedBy?.id,
      searchKey: searchKey,
      byPrority: sortByPriority,
    };
    console.log("params", params);

    getMediaAndPrs(params);
  }, [
    page,
    rowsPerPage,

    searchKey,
    selectedStatus,
    selectedCreatedBy,
    selectedModifiedBy,

    sortByPriority,
  ]);

  useEffect(() => {
    if (isSuccess) {
      setMediaAndPrsState("isSuccess", false);
      getMediaAndPrs({
        byPrority: sortByPriority,
        index: page,
        size: rowsPerPage,
      });
      clearSelected();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isPriorityUpdated) {
      setMediaAndPrsState("isPriorityUpdated", false);
      const params = {
        index: page,
        size: rowsPerPage,
        updatedBy: selectedModifiedBy,
        status: selectedStatus,
        createdBy: selectedCreatedBy,
        searchKey: searchKey,
        byPrority: sortByPriority,
      };
      getMediaAndPrs(params);
      clearSelected();
    }
  }, [isPriorityUpdated]);

  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setMediaAndPrsState("page", 1);
      setMediaAndPrsState("rowsPerPage", 20);
    }

    return () => {};
  }, []);

  const breadcrumbRoutes: any = [
    {
      name: "Marketing Management",
      route: RouteConstants.marketingManagementDashboard,
    },
    {
      name: "Media and Prs List",
      route: RouteConstants.mediaAndPrList,
    },
  ];

  const tableHeadings = [
    { heading: "Logo" },
    { heading: "Title" },
    { heading: "Description" },
    { heading: "URL" },
    { heading: "Created On" },
    { heading: "Published On" },
    { heading: "Modified On" },
    { heading: "Last Modified By" },
    { heading: "Priority" },
    { heading: "Status" },
    { heading: "Action" },
  ];
  const renderLoader = () => {
    return <Loader />;
  };

  //handle change function for Status filter.
  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
    if (page !== 1) {
      setMediaAndPrsState("page", 1);
    }
  };
  const handleChangePrioritySwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSortByPriority(event.target.checked);
  };
  const clearSelected = () => {
    setSelectedMediaAndPrs([]);
  };
  //function to Reset all filters.
  const clearFilters = () => {
    setSelectedStatus("");
    setCreatedBy(null);
    setModifiedBy(null);
    setSearchKey("");
  };

  //function to handle Action Selection change.
  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case MediaAndPrActionTypeEnum.Export:
        handleExportAsExcel();
        break;
      case MediaAndPrActionTypeEnum.Delete:
        handleDeleteTestimonial();
        break;
      case MediaAndPrActionTypeEnum.MarkAsPublished:
        changeTestimonialStatuses(String(TestimonialStatusTypeEnum.Published));
        break;
      case MediaAndPrActionTypeEnum.MarkAsInactive:
        changeTestimonialStatuses(String(TestimonialStatusTypeEnum.Inactive));
        break;
    }
  };
  const handleDeleteTestimonial = () => {
    if (selectedMediaAndPrs.length === 0) {
      alert("You have not selected any Media Prs");
      return;
    }
    setIsConfirmDeleteDialogOpen(true);
  };
  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  //function to make an API call to delete selected Testimonials.
  const deleteSelectedMediaAndPrs = () => {
    setIsConfirmDeleteDialogOpen(false);
    const mediaAndPrs = {
      mediaAndPrIds: selectedMediaAndPrs,
    };

    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Media and PR ${item?.firstName} ${item?.lastName} Deleted.(ID${item?.id})`,
        newValue: "",
        oldValue: "",
        auditedObject: null,
        route: null,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    deleteMediaAndPr(mediaAndPrs);
    clearSelected();
  };
  //function to make an API call to Update the Selected Media and Prs.
  const changeTestimonialStatuses = (status: string) => {
    const data = {
      status: status,
      mediaAndPrIds: selectedMediaAndPrs,
    };

    if (selectedMediaAndPrs.length === 0) {
      alert("You have not selected any testimonials");
      return;
    }
    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Media and Prs Status`,
        newValue:
          TestimonialStatusTypeEnum.Published === status
            ? "Published"
            : "Inactive",
        oldValue:
          TestimonialStatusTypeEnum.Published === status
            ? "Inactive"
            : "Published",
        auditedObject: {
          testimonial: { ...item, status: status },
          isEditMode: true,
        },
        route: RouteConstants?.testimonialCreation,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);
    changeMediaAndPrStatus(data);
  };

  const statusAuditedItems = () => {
    let auditedItems: any[] = [];
    mediaAndPrsList?.map((item: any) => {
      selectedMediaAndPrs?.map((itemId: number) => {
        if (itemId === item.id) {
          auditedItems.push(item);
        }
      });
    });
    return auditedItems;
  };
  //function to make an Audit log API call.
  const handleCloseFlashMessageDialog = () => {
    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Marketing Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: null,
      auditedObject: null,
      modulesAudited: modulesAudited,
      status: "2001",
    };
    postAuditLogRequest(postAuditRequestbody);

    resetState();
    setSelectedMediaAndPrs([]);
  };

  const resetState = () => {
    setMediaAndPrsState("message", null);
    setMediaAndPrsState("error", null);
    setMediaAndPrsState("isSuccess", false);
  };

  const handleExportAsExcel = () => {
    if (selectedMediaAndPrs.length === 0) {
      alert("You have not selected any Media Prs");
      return;
    }
    let exportingTestimonials: any = [];
    if (mediaAndPrsList.length) {
      selectedMediaAndPrs?.map((selectedTestimonial: any) => {
        let localSelectedItem = mediaAndPrsList.find(
          (item: any) => item.id == selectedTestimonial
        );
        exportingTestimonials.push({
          Title: localSelectedItem.displayTitle
            ? localSelectedItem.displayTitle
            : "----",
          Logo: localSelectedItem.logo?.media?.value?.url
            ? localSelectedItem.logo?.media?.value?.url
            : "----",
          Description: localSelectedItem.description
            ? localSelectedItem.description
            : "----",
          URL: localSelectedItem.url ? localSelectedItem.url : "----",
          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Published_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Modified_On: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Last_Modified_by: localSelectedItem?.mediaAndPrModifiedAdmin
            ?.firstName
            ? localSelectedItem?.mediaAndPrModifiedAdmin?.firstName
            : "----",
          Created_By: localSelectedItem?.mediaAndPrsCreatedAdmin?.firstName
            ? localSelectedItem?.mediaAndPrsCreatedAdmin?.firstName
            : "----",
          Priority: localSelectedItem?.priority,
          Status: localSelectedItem.status
            ? TestimonialStatusTypeEnumUtils.getEnumText(
                localSelectedItem.status
              )
            : "----",
        });
      });
    }

    exportAsExcel(exportingTestimonials, "Media and Prs", "Sheet1");
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = mediaAndPrsList.map((data: any) => data.id);
      setSelectedMediaAndPrs(newSelecteds);
      return;
    }
    setSelectedMediaAndPrs([]);
  };
  const isSelected = (id: any) => selectedMediaAndPrs.indexOf(id) !== -1;

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedMediaAndPrs.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedMediaAndPrs, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedMediaAndPrs.slice(1));
    } else if (selectedIndex === selectedMediaAndPrs?.length - 1) {
      newSelected = newSelected.concat(selectedMediaAndPrs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedMediaAndPrs.slice(0, selectedIndex),
        selectedMediaAndPrs.slice(selectedIndex + 1)
      );
    }

    setSelectedMediaAndPrs(newSelected);
  };

  //handle change for search key filter.
  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 1) {
      setMediaAndPrsState("page", 1);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setMediaAndPrsState("page", newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMediaAndPrsState("rowsPerPage", Number(event.target.value));
    setMediaAndPrsState("page", 1);
  };

  const createMediaAndPR = () => {
    navigate(RouteConstants.mediaAndPrCreation);
  };

  //Navigation to edit Testimonial.
  const editMediaAndPR = (mediaAndPR: any) => {
    const dataToEdit = {
      isEdit: true,
      mediaAndPR: mediaAndPR,
    };
    navigate(`${RouteConstants.mediaAndPrCreation}`, {
      state: dataToEdit,
    });
  };

  //Navigation to View Testimonial.
  const viewMediaAndPR = (mediaAndPR: any) => {
    const dataToView = {
      isView: true,
      mediaAndPR: mediaAndPR,
    };
    navigate(`${RouteConstants.mediaAndPrCreation}`, {
      state: dataToView,
    });
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Media and PRs
            {`(${
              totalCount
                ? totalCount
                : mediaAndPrsList?.length
                ? mediaAndPrsList?.length
                : 0
            })`}
            {selectedMediaAndPrs.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedMediaAndPrs.length} Media and Pr(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
              >
                {actions.map((action: any) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>

            <Button
              variant="contained"
              onClick={createMediaAndPR}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New
            </Button>
          </div>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedStatus}
                    label="Status"
                    onChange={handleStatusChange}
                  >
                    {statuses.map((status: any) => {
                      return (
                        <MenuItem value={status.value} key={status.value}>
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              <Grid item md={2.6} lg={2.6} xl={2.6}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedCreatedBy}
                    label="Created By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setCreatedBy(newValue);
                      if (page !== 1) {
                        setMediaAndPrsState("page", 1);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item md={2.6} lg={2.6} xl={2.6}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedModifiedBy}
                    label="Modified By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setModifiedBy(newValue);
                      if (page !== 1) {
                        setMediaAndPrsState("page", 1);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              {selectedCreatedBy !== null ||
              selectedStatus !== "" ||
              selectedModifiedBy !== null ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
            <Grid
              spacing={0}
              md={4}
              lg={4}
              xl={4}
              className="time-sync-holder"
              justifyContent="end"
            >
              <Stack justifyContent={"flex-end"} spacing={1}>
                <p className={styles["priority-switch"]}>Sort by Priority</p>
                <SwitchComponent
                  checked={sortByPriority}
                  onChange={handleChangePrioritySwitch}
                />
                <Tooltip
                  arrow
                  title="Disabling the toggle will switch to show items on the Customer App based on Date & Time."
                  placement="top-start"
                >
                  <InfoOutlined className={styles["infooutlined-alignment"]} />
                </Tooltip>
              </Stack>
            </Grid>

            {/* <Grid container spacing={2} md={4} lg={4} xl={4}></Grid> */}
          </Grid>
        </Stack>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell style={{ minWidth: 50 }} padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedMediaAndPrs?.length > 0 &&
                      selectedMediaAndPrs?.length < mediaAndPrsList?.length
                    }
                    checked={
                      mediaAndPrsList?.length > 0 &&
                      selectedMediaAndPrs?.length === mediaAndPrsList?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all mediaAndPrsList",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {mediaAndPrsList && mediaAndPrsList.length > 0 ? (
              <TableBody>
                {mediaAndPrsList.map((mediaAndPr: any) => {
                  const isItemSelected = isSelected(mediaAndPr.id);
                  return (
                    <TableRow
                      key={mediaAndPr.id}
                      className="table-data-row"
                      hover
                      role="checkbox"
                      //   aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ minWidth: 50 }} padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, mediaAndPr.id)}
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>
                      {/* <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {mediaAndPr.logo?.media?.value?.url
                          ? mediaAndPr.logo?.media?.value?.url
                          : "----"}
                      </TableCell> */}
                      <TableCell
                        style={{ minWidth: 120, justifyContent: "center" }}
                      >
                        {mediaAndPr.logo?.media?.value?.url ? (
                          <Card
                            elevation={0}
                            className={styles["project-image"]}
                            style={{
                              height: "80px",
                              width: "100%",
                              objectFit: "fill",
                            }}
                          >
                            <CardMedia
                              className={styles["media-area"]}
                              component={"img"}
                              alt="Image"
                              src={mediaAndPr.logo?.media?.value?.url}
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "fill",
                              }}
                            />
                          </Card>
                        ) : (
                          <PanoramaIcon
                            style={{ height: "3rem", width: "3rem" }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 250 }}
                        className="table-data-text"
                      >
                        {mediaAndPr.displayTitle
                          ? mediaAndPr.displayTitle?.length > 50
                            ? `${mediaAndPr.displayTitle.slice(0, 50)}...`
                            : mediaAndPr.displayTitle
                          : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 250 }}
                        className="table-data-text"
                      >
                        {mediaAndPr.description
                          ? mediaAndPr.description?.length > 50
                            ? `${mediaAndPr.description.slice(0, 50)}...`
                            : mediaAndPr.description
                          : "----"}
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 200 }}
                        className="table-data-text"
                      >
                        {mediaAndPr?.url
                          ? mediaAndPr?.url?.length > 50
                            ? `${mediaAndPr.url.slice(0, 50)}...`
                            : mediaAndPr.url
                          : "----"}
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 100 }}
                        className="table-data-text"
                      >
                        {mediaAndPr.createdAt
                          ? formatDateTime(
                              mediaAndPr.createdAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "----"}
                      </TableCell>

                      {/* check published on date */}
                      <TableCell
                        style={{ minWidth: 140 }}
                        className="table-data-text"
                      >
                        {mediaAndPr.createdAt
                          ? formatDateTime(
                              mediaAndPr.createdAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 140 }}
                        className="table-data-text"
                      >
                        {mediaAndPr.updatedAt
                          ? formatDateTime(
                              mediaAndPr.updatedAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "----"}
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        <div>
                          {mediaAndPr &&
                          mediaAndPr.mediaAndPrModifiedAdmin &&
                          mediaAndPr.mediaAndPrModifiedAdmin.firstName
                            ? mediaAndPr?.mediaAndPrModifiedAdmin?.firstName
                            : "----"}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 100 }}
                        className="table-data-text"
                      >
                        <PrioritySelector
                          incrementpriority={() => {
                            const priorityRequestBody = {
                              id: mediaAndPr?.id,
                              priority: mediaAndPr?.priority + 1,
                            };
                            updateMediaAndPrPriority(priorityRequestBody);
                          }}
                          decrementpriority={() => {
                            const priorityRequestBody = {
                              id: mediaAndPr?.id,
                              priority: mediaAndPr?.priority - 1,
                            };
                            updateMediaAndPrPriority(priorityRequestBody);
                          }}
                          priority={mediaAndPr?.priority}
                          disableDecrement={
                            mediaAndPr?.priority == 1 ? true : false
                          }
                          disableIncrement={
                            mediaAndPr?.priority == mediaAndPrsList?.length
                              ? true
                              : false
                          }
                          isPriorityRequired={
                            !sortByPriority ||
                            mediaAndPr?.status ==
                              TestimonialStatusTypeEnum.Inactive
                          }
                        />
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 120 }}
                        className="table-data-text"
                      >
                        {mediaAndPr.status
                          ? TestimonialStatusTypeEnumUtils.getEnumText(
                              mediaAndPr.status
                            )
                          : "----"}
                      </TableCell>

                      <TableCell style={{ minWidth: 120 }}>
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editMediaAndPR(mediaAndPr)}
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewMediaAndPR(mediaAndPr)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Media And Prs Records</div>
            )}
          </Table>
        </TableContainer>

        {!searchKey && mediaAndPrsList && mediaAndPrsList.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[20, 40, 50]}
            component="div"
            count={
              totalCount
                ? totalCount
                : mediaAndPrsList.length
                ? mediaAndPrsList.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    // if (mediaAndPrsList?.length > 0 && isLoading === false) {
    //   return renderTable();
    // } else if (mediaAndPrsList?.length === 0) {
    //   return renderNoData();
    // } else if (isLoading) {
    //   return renderLoader();
    // } else if (error) {
    //   return renderRetry();
    // }
    if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    }
    // else if (error) {
    //   return renderRetry();
    // }
  };

  return (
    <Fragment>
      <PageHeading title="Marketing Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder="Search Media and PR"
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Media and Prs(s)?"
        content={`This action will delete ${selectedMediaAndPrs.length} selected Media and Prs(s). Are you sure you want to continue?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteSelectedMediaAndPrs}
      />

      {message ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : "Something Went Wrong!"}
          isSuccess={true}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
      {error ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error ? error : "Something Went Wrong!"}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
    </Fragment>
  );
}

export default MediaAndPrList;
