export const enum StatusTypeEnum {
  Active = "1001",
  Inactive = "1002",
  Invited = "1003",
  Suspended = "1004",
}

export class StatusTypeEnumUtils {
  public static getEnumText(type: StatusTypeEnum): string {
    switch (type) {
      case StatusTypeEnum.Active:
        return "Active";
      case StatusTypeEnum.Inactive:
        return "Inactive";
      case StatusTypeEnum.Invited:
        return "Invited";
      case StatusTypeEnum.Suspended:
        return "Suspended";
    }
  }

  public static getStatusTypeEnums(): {
    label: string;
    value: StatusTypeEnum;
  }[] {
    const options: { label: string; value: StatusTypeEnum }[] = [
      {
        label: this.getEnumText(StatusTypeEnum.Active),
        value: StatusTypeEnum.Active,
      },
      {
        label: this.getEnumText(StatusTypeEnum.Inactive),
        value: StatusTypeEnum.Inactive,
      },
      {
        label: this.getEnumText(StatusTypeEnum.Invited),
        value: StatusTypeEnum.Invited,
      },
      {
        label: this.getEnumText(StatusTypeEnum.Suspended),
        value: StatusTypeEnum.Suspended,
      },
    ];
    return options;
  }

  public static getFaqsStatusTypeEnums(): {
    label: string;
    value: StatusTypeEnum;
  }[] {
    const options: { label: string; value: StatusTypeEnum }[] = [
      {
        label: this.getEnumText(StatusTypeEnum.Active),
        value: StatusTypeEnum.Active,
      },
      {
        label: this.getEnumText(StatusTypeEnum.Inactive),
        value: StatusTypeEnum.Inactive,
      },
    ];
    return options;
  }
}

// --------------------------------------

export const enum UpdatesStatusTypeEnum {
  Published = "1001",
  Inactive = "1002",
}

export class UpdatesStatusTypeEnumUtils {
  public static getEnumText(type: UpdatesStatusTypeEnum): string {
    switch (type) {
      case UpdatesStatusTypeEnum.Published:
        return "Published";
      case UpdatesStatusTypeEnum.Inactive:
        return "Inactive";
    }
  }

  public static getUpdatesStatusTypeEnums(): {
    label: string;
    value: UpdatesStatusTypeEnum;
  }[] {
    const options: { label: string; value: UpdatesStatusTypeEnum }[] = [
      {
        label: this.getEnumText(UpdatesStatusTypeEnum.Published),
        value: UpdatesStatusTypeEnum.Published,
      },
      {
        label: this.getEnumText(UpdatesStatusTypeEnum.Inactive),
        value: UpdatesStatusTypeEnum.Inactive,
      },
    ];
    return options;
  }
}
// export const enum StatusTypeEnumInsight {
//   Published = "1001",
//   Inactive = "1002",
// }
// export class StatusTypeEnumUtilsInsight {
//   public static getInsightsStatusEnumText(type: StatusTypeEnumInsight): string {
//     switch (type) {
//       case StatusTypeEnumInsight.Published:
//         return "Published";
//       case StatusTypeEnumInsight.Inactive:
//         return "Inactive";
//     }
//   }
//   public static getStatusTypeEnumsInsight(): {
//     label: string;
//     value: StatusTypeEnum;
//   }[] {
//     const options: { label: string; value: StatusTypeEnum }[] = [
//       {
//         label: this.getInsightsStatusEnumText(StatusTypeEnumInsight.Published),
//         value: StatusTypeEnum.Active,
//       },
//       {
//         label: this.getInsightsStatusEnumText(StatusTypeEnumInsight.Inactive),
//         value: StatusTypeEnum.Inactive,
//       },
//     ];
//     return options;
//   }
// }

// ----------------------------------------

export const enum TestimonialStatusTypeEnum {
  Published = "1001",
  Inactive = "1002",
}

export class TestimonialStatusTypeEnumUtils {
  public static getEnumText(type: TestimonialStatusTypeEnum): string {
    switch (type) {
      case TestimonialStatusTypeEnum.Published:
        return "Published";
      case TestimonialStatusTypeEnum.Inactive:
        return "Inactive";
    }
  }

  public static getTestimonialStatusTypeEnums(): {
    label: string;
    value: StatusTypeEnum;
  }[] {
    const options: { label: string; value: StatusTypeEnum }[] = [
      {
        label: this.getEnumText(TestimonialStatusTypeEnum.Published),
        value: StatusTypeEnum.Active,
      },
      {
        label: this.getEnumText(TestimonialStatusTypeEnum.Inactive),
        value: StatusTypeEnum.Inactive,
      },
    ];
    return options;
  }
}


export const enum BlogStatusTypeEnum {
  Published = "1001",
  Inactive = "1002",
}

export class BlogStatusTypeEnumUtils {
  public static getEnumText(type: BlogStatusTypeEnum): string {
    switch (type) {
      case BlogStatusTypeEnum.Published:
        return "Published";
      case BlogStatusTypeEnum.Inactive:
        return "Inactive";
    }
  }

  public static getBlogStatusTypeEnums(): {
    label: string;
    value: StatusTypeEnum;
  }[] {
    const options: { label: string; value: StatusTypeEnum }[] = [
      {
        label: this.getEnumText(BlogStatusTypeEnum.Published),
        value: StatusTypeEnum.Active,
      },
      {
        label: this.getEnumText(BlogStatusTypeEnum.Inactive),
        value: StatusTypeEnum.Inactive,
      },
    ];
    return options;
  }
}



export const enum UrlStatusTypeEnum {
  Published = "1001",
  Inactive = "1002",
}

export class UrlStatusTypeEnumUtils {
  public static getEnumText(type: UrlStatusTypeEnum): string {
    switch (type) {
      case UrlStatusTypeEnum.Published:
        return "Published";
      case UrlStatusTypeEnum.Inactive:
        return "Inactive";
    }
  }

  public static getUrlStatusTypeEnums(): {
    label: string;
    value: StatusTypeEnum;
  }[] {
    const options: { label: string; value: StatusTypeEnum }[] = [
      {
        label: this.getEnumText(UrlStatusTypeEnum.Published),
        value: StatusTypeEnum.Active,
      },
      {
        label: this.getEnumText(UrlStatusTypeEnum.Inactive),
        value: StatusTypeEnum.Inactive,
      },
    ];
    return options;
  }
}

export const enum LandingPageStatusTypeEnum {
  Published = "1001",
  Inactive = "1002",
}

export class LandingPageStatusTypeEnumUtils {
  public static getEnumText(type: LandingPageStatusTypeEnum): string {
    switch (type) {
      case LandingPageStatusTypeEnum.Published:
        return "Published";
      case LandingPageStatusTypeEnum.Inactive:
        return "Inactive";
    }
  }
}




export const enum StatusTypeEnumInsight {
  Published = "1001",
  Inactive = "1002",
}
export class StatusTypeEnumUtilsInsight {
  public static getInsightsStatusEnumText(type: StatusTypeEnumInsight): string {
    switch (type) {
      case StatusTypeEnumInsight.Published:
        return "Published";
      case StatusTypeEnumInsight.Inactive:
        return "Inactive";
    }
  }
  public static getStatusTypeEnumsInsight(): {
    label: string;
    value: StatusTypeEnum;
  }[] {
    const options: { label: string; value: StatusTypeEnum }[] = [
      {
        label: this.getInsightsStatusEnumText(StatusTypeEnumInsight.Published),
        value: StatusTypeEnum.Active,
      },
      {
        label: this.getInsightsStatusEnumText(StatusTypeEnumInsight.Inactive),
        value: StatusTypeEnum.Inactive,
      },
    ];
    return options;
  }
}

export const enum ProjectCollectionStatusTypeEnum {
  Published = "1001",
  Inactive = "1002",
}

export class ProjectCollectionStatusTypeEnumUtils {
  public static getEnumText(type: ProjectCollectionStatusTypeEnum): string {
    switch (type) {
      case ProjectCollectionStatusTypeEnum.Published:
        return "Published";
      case ProjectCollectionStatusTypeEnum.Inactive:
        return "Inactive";
    }
  }

  public static getProjectCollectionStatusTypeEnums(): {
    label: string;
    value: StatusTypeEnum;
  }[] {
    const options: { label: string; value: StatusTypeEnum }[] = [
      {
        label: this.getEnumText(ProjectCollectionStatusTypeEnum.Published),
        value: StatusTypeEnum.Active,
      },
      {
        label: this.getEnumText(ProjectCollectionStatusTypeEnum.Inactive),
        value: StatusTypeEnum.Inactive,
      },
    ];
    return options;
  }
}

export const enum ProjectStatusTypeEnum {
  Live = "1001",
  Hidden = "1002",
}

export class ProjectStatusTypeEnumUtils {
  public static getEnumText(type: ProjectStatusTypeEnum): string {
    switch (type) {
      case ProjectStatusTypeEnum.Live:
        return "Live";
      case ProjectStatusTypeEnum.Hidden:
        return "Hidden";
    }
  }

  public static getProjectStatusTypeEnums(): {
    label: string;
    value: ProjectStatusTypeEnum;
  }[] {
    const options: { label: string; value: ProjectStatusTypeEnum }[] = [
      {
        label: this.getEnumText(ProjectStatusTypeEnum.Live),
        value: ProjectStatusTypeEnum.Live,
      },
      {
        label: this.getEnumText(ProjectStatusTypeEnum.Hidden),
        value: ProjectStatusTypeEnum.Hidden,
      },
    ];
    return options;
  }
}
