export const enum CustomerTypeEnum {
  PRELEAD = 1,
  CUSTOMER = 225360001,
  INVESTOR = 225360002,
}

export class CustomerEnumsUtils {
  public static getEnumText(type: CustomerTypeEnum): string {
    switch (type) {
      case CustomerTypeEnum.PRELEAD:
        return "Pre Lead";
      case CustomerTypeEnum.CUSTOMER:
        return "Customer";
      case CustomerTypeEnum.INVESTOR:
        return "Investor";
    }
  }
  public static getActionsEnums(): {
    label: string;
    value: CustomerTypeEnum;
  }[] {
    const options: { label: string; value: CustomerTypeEnum }[] = [
      {
        label: this.getEnumText(CustomerTypeEnum.PRELEAD),
        value: CustomerTypeEnum.PRELEAD,
      },
      {
        label: this.getEnumText(CustomerTypeEnum.CUSTOMER),
        value: CustomerTypeEnum.CUSTOMER,
      },
      {
        label: this.getEnumText(CustomerTypeEnum.INVESTOR),
        value: CustomerTypeEnum.INVESTOR,
      },
      
    ];
    return options;
  }
}
