import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getPagePromisesSuccess: ["response"],
  getPagePromisesFailure: ["error"],
  getPagePromisesRequest: ["data"],

  addPagePromisesSuccess: ["response"],
  addPagePromisesFailure: ["error"],
  addPagePromisesRequest: ["data"],

  updatePagePromisesSuccess: ["response"],
  updatePagePromisesFailure: ["error"],
  updatePagePromisesRequest: ["data"],

  setPagePromisesState: ["key", "value"],
});

export const PagePromisesTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  pageData: null,
  error: null,
  loadError: null,
  message: null,
  isLoading: false,
  isSuccess: false,
};

/* Get Page Promises Data */
export const getPagePromisesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPagePromisesSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      pageData: response.data,
      error: null,
      isLoading: false,
    };
  }
};

export const getPagePromisesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Add Page Promises Data */
export const addPagePromisesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const addPagePromisesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Page Management Data added successfully!",
      isLoading: false,
    };
  }
};

export const addPagePromisesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Update Page Promises Data */
export const updatePagePromisesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updatePagePromisesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Page Management Data Updates successfully!",
      isLoading: false,
    };
  }
};

export const updatePagePromisesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setPagePromisesState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAGE_PROMISES_SUCCESS]: getPagePromisesSuccess,
  [Types.GET_PAGE_PROMISES_FAILURE]: getPagePromisesFailure,
  [Types.GET_PAGE_PROMISES_REQUEST]: getPagePromisesRequest,

  [Types.ADD_PAGE_PROMISES_SUCCESS]: addPagePromisesSuccess,
  [Types.ADD_PAGE_PROMISES_FAILURE]: addPagePromisesFailure,
  [Types.ADD_PAGE_PROMISES_REQUEST]: addPagePromisesRequest,

  [Types.UPDATE_PAGE_PROMISES_SUCCESS]: updatePagePromisesSuccess,
  [Types.UPDATE_PAGE_PROMISES_FAILURE]: updatePagePromisesFailure,
  [Types.UPDATE_PAGE_PROMISES_REQUEST]: updatePagePromisesRequest,

  [Types.SET_PAGE_PROMISES_STATE]: setPagePromisesState,
});
