import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionTypeEnum,
  ActionTypeEnumUtils,
  TestimonialActionTypeEnum,
  TestimonialActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import Button from "@mui/material/Button";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Checkbox from "@mui/material/Checkbox";
import ConfirmationDialog from "../../../../components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Loader from "../../../../components/loader/Loader";
import MediaAssets from "../../../../assets";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NoDataRecord from "../../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import Retry from "../../../../components/retry/Retry";
import { RouteConstants } from "../../../../routes/RouteConstants";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  StatusTypeEnum,
  StatusTypeEnumInsight,
  StatusTypeEnumUtils,
  TestimonialStatusTypeEnum,
  TestimonialStatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TestimonialActions from "../../../../redux-container/marketing-management/testimonials/TestimonialsRedux";
import { exportAsExcel } from "utils/ExportFileUtils";
import UserActions from "../../../../redux-container/users/UsersRedux";
import { Stack } from "@mui/material";
import {
  AutocompleteComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { InfoOutlined } from "@mui/icons-material";
import styles from "./TestimonialList.module.css";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { TestimonialTypeEnumUtils } from "enumerations/TestimonialTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    getTestimonials: (params: any) =>
      dispatch(TestimonialActions.getTestimonialsRequest(params)),
    deleteTestimonials: (data: any) =>
      dispatch(TestimonialActions.deleteTestimonialRequest(data)),
    setTestimonialsState: (key: any, data: any) =>
      dispatch(TestimonialActions.setTestimonialsState(key, data)),
    changeTestimonialStatus: (data: any) =>
      dispatch(TestimonialActions.changeTestimonialStatusRequest(data)),
    updateTestimonialPriority: (data: any) =>
      dispatch(TestimonialActions.updateTestimonialPriorityRequest(data)),
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const TestimonialList = () => {
  const {
    getTestimonials,
    deleteTestimonials,
    changeTestimonialStatus,
    updateTestimonialPriority,
    setTestimonialsState,
    getUsers,
    postAuditLogRequest,
    rolesAndModulesRequest,
  } = actionDispatch(useDispatch());

  const navigate = useNavigate();
  const location: any = useLocation();
  const actions = TestimonialActionTypeEnumUtils.getTestimonialActionTypeEnum();
  const statuses =
    TestimonialStatusTypeEnumUtils.getTestimonialStatusTypeEnums();
  const testimonialTypes = TestimonialTypeEnumUtils.getTestimonailTypeEnums();
  const testimonials = useSelector(
    (state: any) => state?.testimonials.testimonials
  );
  const totalCount = useSelector(
    (state: any) => state?.testimonials.totalCount
  );
  const users = useSelector((state: any) => state?.users?.usersForFilters);
  const isLoading = useSelector((state: any) => state.testimonials?.isLoading);
  const error = useSelector((state: any) => state.testimonials?.error);
  const isSuccess = useSelector((state: any) => state.testimonials?.isSuccess);
  const isPriorityUpdated = useSelector(
    (state: any) => state.testimonials?.isPriorityUpdated
  );
  const message = useSelector((state: any) => state.testimonials?.message);
  const { page, rowsPerPage, modules } = useSelector((state: any) => {
    return {
      page: state.testimonials?.page,
      rowsPerPage: state.testimonials?.rowsPerPage,
      modules: state?.rolesAndModules?.module,
    };
  });
  const [sortByPriority, setSortByPriority] = useState(true);
  const [selectedTestimonials, setSelectedTestimonials] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTestimonialType, setSelectedTestimonialType] = useState("");
  const [selectedCreatedBy, setCreatedBy] = useState<any>(null);
  const [selectedPublishedBy, setPublishedBy] = useState("");
  const [selectedModifiedBy, setModifiedBy] = useState<any>(null);
  const [searchKey, setSearchKey] = useState("");

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);
  const [modulesAudited, setModulesAudited] = useState<any>(null);

  const breadcrumbRoutes: any = [
    {
      name: "Marketing Management",
      route: RouteConstants.marketingManagementDashboard,
    },
    {
      name: "Testimonial List",
      route: RouteConstants.testimonialsList,
    },
  ];

  const tableHeadings = [
    { heading: "First Name" },
    { heading: "Type" },
    { heading: "Designation" },
    { heading: "Company" },
    { heading: "Created On" },
    { heading: "Published On" },
    { heading: "Modified On" },
    { heading: "Last Modified By" },
    { heading: "Priority" },
    { heading: "Status" },
    { heading: "Action" },
  ];

  useEffect(() => {
    const params = {};
    // loadData(params);
    getFilterDataFromDashboard();
  }, []);

  //Loading Data (Testimonials data) while mounting and
  // every time after User applies any filters (Status,Created by,Modified by,Search key,Sorting key,Testimonial Type,page and number of rows per page) .
  useEffect(() => {
    const params = {
      index: page,
      size: rowsPerPage,
      status: location?.state?.statusType
        ? location?.state?.statusType
        : selectedStatus,
      updatedBy: selectedModifiedBy?.id,
      createdBy: selectedCreatedBy?.id,
      searchKey: searchKey,
      byPrority: sortByPriority,
      testimonialType: selectedTestimonialType,
    };
    if (
      searchKey ||
      selectedStatus ||
      selectedCreatedBy ||
      rowsPerPage ||
      page ||
      selectedModifiedBy ||
      sortByPriority
    ) {
      loadData(params);
      clearSelected();
    }
  }, [
    page,
    rowsPerPage,
    searchKey,
    selectedStatus,
    selectedCreatedBy,
    selectedModifiedBy,
    selectedTestimonialType,
    sortByPriority,
  ]);

  useEffect(() => {
    if (isSuccess) {
      setTestimonialsState("isSuccess", false);
      loadData({});
      clearSelected();
    }
  }, [isSuccess]);

  //Load Testimonial Data after Update of Testimonial Priority.
  useEffect(() => {
    if (isPriorityUpdated) {
      setTestimonialsState("isPriorityUpdated", false);
      const params = {
        index: page,
        size: rowsPerPage,
        updatedBy: selectedModifiedBy,
        status: selectedStatus,
        createdBy: selectedCreatedBy,
        searchKey: searchKey,
        byPrority: sortByPriority,
      };
      loadData(params);
      clearSelected();
    }
    return () => {};
  }, [isPriorityUpdated]);

  const loadData = (params: any) => {
    getTestimonials(params);
    // getUsers({});
  };

  //setting page and rowsPerPage to initial value if user Navigated to the list from Side menu.
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setTestimonialsState("page", 1);
      setTestimonialsState("rowsPerPage", 20);
    }

    return () => {};
  }, []);

  const statusAuditedItems = () => {
    let auditedItems: any[] = [];
    testimonials?.map((item: any) => {
      selectedTestimonials?.map((itemId: number) => {
        if (itemId === item.id) {
          auditedItems.push(item);
        }
      });
    });
    return auditedItems;
  };

  const getFilterDataFromDashboard = () => {
    if (location && location.state && location.state.statusType) {
      setSelectedStatus(location.state.statusType as string);
    }
    navigate(location.pathname, { replace: true });
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setTestimonialsState("page", newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTestimonialsState("rowsPerPage", Number(event.target.value));
    setTestimonialsState("page", 1);
  };

  const handleChangePrioritySwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSortByPriority(event.target.checked);
  };

  //function to handle Action Selection change.
  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case TestimonialActionTypeEnum.Export:
        handleExportAsExcel();
        break;
      case TestimonialActionTypeEnum.Delete:
        handleDeleteTestimonial();
        break;
      case TestimonialActionTypeEnum.MarkAsPublished:
        changeTestimonialStatuses(String(TestimonialStatusTypeEnum.Published));
        break;
      case TestimonialActionTypeEnum.MarkAsInactive:
        changeTestimonialStatuses(String(TestimonialStatusTypeEnum.Inactive));
        break;
    }
  };

  const handleDeleteTestimonial = () => {
    if (selectedTestimonials.length === 0) {
      alert("You have not selected any testimonials");
      return;
    }
    setIsConfirmDeleteDialogOpen(true);
  };

  //function to make an API call to delete selected Testimonials.
  const deleteSelectedTestimonials = () => {
    setIsConfirmDeleteDialogOpen(false);
    const testimonials = {
      testimonialIds: selectedTestimonials,
    };

    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Testimonial ${item?.firstName} ${item?.lastName} Deleted.(ID${item?.id})`,
        newValue: "",
        oldValue: "",
        auditedObject: null,
        route: null,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    deleteTestimonials(testimonials);
    clearSelected();
  };

  //function to make an API call to Update the Selected Testimonial.
  const changeTestimonialStatuses = (status: string) => {
    const data = {
      status: status,
      testimonialIds: selectedTestimonials,
    };

    if (selectedTestimonials.length === 0) {
      alert("You have not selected any testimonials");
      return;
    }
    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Testimonial Status`,
        newValue:
          TestimonialStatusTypeEnum.Published === status
            ? "Published"
            : "Inactive",
        oldValue:
          TestimonialStatusTypeEnum.Published === status
            ? "Inactive"
            : "Published",
        auditedObject: {
          testimonial: { ...item, status: status },
          isEditMode: true,
        },
        route: RouteConstants?.testimonialCreation,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);
    changeTestimonialStatus(data);
  };

  //function to Export the Testimonial data.
  const handleExportAsExcel = () => {
    if (selectedTestimonials.length === 0) {
      alert("You have not selected any Testimonial");
      return;
    }
    let exportingTestimonials: any = [];
    if (testimonials.length) {
      selectedTestimonials.map((selectedTestimonial: any) => {
        let localSelectedItem = testimonials.find(
          (item: any) => item.id == selectedTestimonial
        );
        exportingTestimonials.push({
          First_Name: localSelectedItem.firstName
            ? localSelectedItem.firstName
            : "----",
          Type: localSelectedItem.testimonialType
            ? TestimonialTypeEnumUtils.getEnumText(
                localSelectedItem.testimonialType
              )
            : "----",
          Designation: localSelectedItem.designation
            ? localSelectedItem.designation
            : "----",
          Company: localSelectedItem.companyName
            ? localSelectedItem.companyName
            : "----",
          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Published_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Modified_On: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Last_Modified_by: localSelectedItem?.testimonialUpdatedBy?.firstName
            ? localSelectedItem?.testimonialUpdatedBy?.firstName
            : "----",
          Priority: localSelectedItem?.priority,
          Status: localSelectedItem.status
            ? TestimonialStatusTypeEnumUtils.getEnumText(
                localSelectedItem.status
              )
            : "----",
        });
      });
    }

    exportAsExcel(exportingTestimonials, "Testimonials", "Sheet1");
  };

  //handle change function for Status filter.
  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
    if (page !== 1) {
      setTestimonialsState("page", 1);
    }
  };

  //handle chage function for
  const handleTestimonialTypeChange = (event: SelectChangeEvent) => {
    setSelectedTestimonialType(event.target.value as string);
    if (page !== 1) {
      setTestimonialsState("page", 1);
    }
  };

  //handle change function for search key filter.
  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 1) {
      setTestimonialsState("page", 1);
    }
  };

  const resetState = () => {
    setTestimonialsState("message", null);
    setTestimonialsState("error", null);
    setTestimonialsState("isSuccess", false);
  };

  //function to make an Audit log API call.
  const handleCloseFlashMessageDialog = () => {
    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Marketing Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: null,
      auditedObject: null,
      modulesAudited: modulesAudited,
      status: "2001",
    };
    postAuditLogRequest(postAuditRequestbody);

    resetState();
    clearSelected();
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = testimonials.map((data: any) => data.id);
      setSelectedTestimonials(newSelecteds);
      return;
    }
    setSelectedTestimonials([]);
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedTestimonials.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTestimonials, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTestimonials.slice(1));
    } else if (selectedIndex === selectedTestimonials?.length - 1) {
      newSelected = newSelected.concat(selectedTestimonials.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTestimonials.slice(0, selectedIndex),
        selectedTestimonials.slice(selectedIndex + 1)
      );
    }

    setSelectedTestimonials(newSelected);
  };

  const clearSelected = () => {
    setSelectedTestimonials([]);
  };

  //function to Reset all filters.
  const clearFilters = () => {
    setSelectedStatus("");
    setCreatedBy(null);
    setModifiedBy(null);
    setPublishedBy("");
    setSelectedTestimonialType("");
    setSearchKey("");
  };

  const isSelected = (id: any) => selectedTestimonials.indexOf(id) !== -1;

  const createTestimonial = () => {
    navigate(RouteConstants.testimonialCreation);
  };

  //Navigation to edit Testimonial.
  const editTestimonial = (testimonial: any) => {
    const dataToEdit = {
      isEditMode: true,
      testimonial: testimonial,
    };
    navigate(`${RouteConstants.testimonialCreation}`, {
      state: dataToEdit,
    });
  };

  //Navigation to View Testimonial.
  const viewTestimonial = (testimonial: any) => {
    const dataToView = {
      isViewMode: true,
      testimonial: testimonial,
    };
    navigate(`${RouteConstants.testimonialCreation}`, {
      state: dataToView,
    });
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_testimonial}
        altText=""
        message="You haven't created any Testimonials yet. Create your first Testimonial."
        btnTitle="CREATE TESTIMONIALS"
        btnAction={createTestimonial}
      />
    );
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Testimonials
            {`(${
              totalCount
                ? totalCount
                : testimonials?.length
                ? testimonials?.length
                : 0
            })`}
            {selectedTestimonials.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedTestimonials.length} Testimonial(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
              >
                {actions.map((action: any) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>

            <Button
              variant="contained"
              onClick={createTestimonial}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New
            </Button>
          </div>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedStatus}
                    label="Status"
                    onChange={handleStatusChange}
                  >
                    {statuses.map((status: any) => {
                      return (
                        <MenuItem value={status.value} key={status.value}>
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              <Grid item md={2.6} lg={2.6} xl={2.6}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedCreatedBy}
                    label="Created By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setCreatedBy(newValue);
                      if (page !== 1) {
                        setTestimonialsState("page", 1);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item md={2.6} lg={2.6} xl={2.6}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedModifiedBy}
                    label="Modified By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setModifiedBy(newValue);
                      if (page !== 1) {
                        setTestimonialsState("page", 1);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item md={2.4} lg={2.4} xl={2.4}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedTestimonialType}
                    label="Type"
                    onChange={handleTestimonialTypeChange}
                  >
                    {testimonialTypes.map((testimonialType: any) => {
                      return (
                        <MenuItem
                          value={testimonialType.value}
                          key={testimonialType.value}
                        >
                          {testimonialType.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              {selectedCreatedBy !== null ||
              selectedStatus !== "" ||
              selectedModifiedBy !== null ||
              selectedTestimonialType !== "" ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
            <Grid
              spacing={0}
              md={4}
              lg={4}
              xl={4}
              className="time-sync-holder"
              justifyContent="end"
            >
              <Stack justifyContent={"flex-end"} spacing={1}>
                <p className={styles["priority-switch"]}>Sort by Priority</p>
                <SwitchComponent
                  checked={sortByPriority}
                  onChange={handleChangePrioritySwitch}
                />
                <Tooltip
                  arrow
                  title="Disabling the toggle will switch to show items on the Customer App based on Date & Time."
                  placement="top-start"
                >
                  <InfoOutlined className={styles["infooutlined-alignment"]} />
                </Tooltip>
              </Stack>
            </Grid>

            {/* <Grid container spacing={2} md={4} lg={4} xl={4}></Grid> */}
          </Grid>
        </Stack>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell style={{ minWidth: 50 }} padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedTestimonials?.length > 0 &&
                      selectedTestimonials?.length < testimonials?.length
                    }
                    checked={
                      testimonials?.length > 0 &&
                      selectedTestimonials?.length === testimonials?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all testimonials",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {testimonials && testimonials.length > 0 ? (
              <TableBody>
                {testimonials.map((testimonial: any) => {
                  const isItemSelected = isSelected(testimonial.id);
                  return (
                    <TableRow
                      key={testimonial.id}
                      className="table-data-row"
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ minWidth: 50 }} padding="checkbox">
                        <Checkbox
                          onClick={(event) =>
                            handleClick(event, testimonial.id)
                          }
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {testimonial.firstName ? testimonial.firstName : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 170 }}
                        className="table-data-text"
                      >
                        {testimonial.testimonialType
                          ? TestimonialTypeEnumUtils.getEnumText(
                              testimonial.testimonialType
                            )
                          : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 200 }}
                        className="table-data-text"
                      >
                        {testimonial.designation
                          ? testimonial.designation
                          : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        {testimonial.companyName
                          ? testimonial.companyName
                          : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 100 }}
                        className="table-data-text"
                      >
                        {testimonial.createdAt
                          ? formatDateTime(
                              testimonial.createdAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "----"}
                      </TableCell>

                      {/* check published on date */}
                      <TableCell
                        style={{ minWidth: 140 }}
                        className="table-data-text"
                      >
                        {testimonial.createdAt
                          ? formatDateTime(
                              testimonial.createdAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 140 }}
                        className="table-data-text"
                      >
                        {testimonial.updatedAt
                          ? formatDateTime(
                              testimonial.updatedAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "----"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        <div>
                          {testimonial &&
                          testimonial.testimonialUpdatedBy &&
                          testimonial.testimonialUpdatedBy.firstName
                            ? testimonial?.testimonialUpdatedBy?.firstName
                            : "----"}
                        </div>
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 100 }}
                        className="table-data-text"
                      >
                        <PrioritySelector
                          incrementpriority={() => {
                            const priorityRequestBody = {
                              id: testimonial?.id,
                              priority: testimonial?.priority + 1,
                            };
                            updateTestimonialPriority(priorityRequestBody);
                          }}
                          decrementpriority={() => {
                            const priorityRequestBody = {
                              id: testimonial?.id,
                              priority: testimonial?.priority - 1,
                            };
                            updateTestimonialPriority(priorityRequestBody);
                          }}
                          priority={testimonial?.priority}
                          disableDecrement={
                            testimonial?.priority == 1 ? true : false
                          }
                          isPriorityRequired={
                            !sortByPriority ||
                            testimonial?.status ==
                              StatusTypeEnumInsight.Inactive
                          }
                        />
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 120 }}
                        className="table-data-text"
                      >
                        {testimonial.status
                          ? TestimonialStatusTypeEnumUtils.getEnumText(
                              testimonial.status
                            )
                          : "----"}
                      </TableCell>

                      <TableCell style={{ minWidth: 120 }}>
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editTestimonial(testimonial)}
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewTestimonial(testimonial)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Testimonials Records</div>
            )}
          </Table>
        </TableContainer>

        {!searchKey && testimonials && testimonials.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[20, 40, 50]}
            component="div"
            count={totalCount ? totalCount : 0}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    // if (testimonials?.length > 0 && isLoading === false) {
    //   return renderTable();
    // } else if (testimonials?.length === 0) {
    //   return renderNoData();
    // } else if (isLoading) {
    //   return renderLoader();
    // } else if (error) {
    //   return renderRetry();
    // }
    if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <PageHeading title="Marketing Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder="Search Testimonials"
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Testimonial(s)?"
        content={`This action will delete ${selectedTestimonials.length} selected testimonial(s). Are you sure you want to continue?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteSelectedTestimonials}
      />

      {message ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : "Something Went Wrong!"}
          isSuccess={true}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}

      {error ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
    </Fragment>
  );
};

export default TestimonialList;
