import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { AutocompleteComponent, ButtonComponent } from "components/ui-elements";
import { Constants } from "constants/Constants";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CustomerActions } from "redux-container/customer-management/CustomersListAndDetailsRedux";
import { formatDateTime } from "utils/DateFormatterUtils";
import {
  paymentHistoryDetails,
  paymentHistoryTableHeadings,
} from "../../CustomerViewInitialValues";
import Loader from "components/loader/Loader";
import PaymentReceiptsDialog from "./PaymentReceiptsDialog";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { SortingOrder } from "enumerations/SortingOrderTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerOverview: async (customerId: any) => {
      dispatch(CustomerActions.getCustomerOverviewRequest(customerId));
    },
  };
};
const PaymentHistory = () => {
  const location: any = useLocation();

  const [customerId, setCustomerId] = useState(
    location?.state?.customerData?.id
  );
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [shouldOpenReceiptsListDialog, setShouldOpenReceiptsListDialog] =
    useState(false);
  const [paymentReceipts, setPaymentReceipts] = useState<any>([]);
  const [columnSort, setColumnSort] = useState({
    sortingKey: "",
    sortingPriority: "",
    isActiveSortingColumn: "",
  });
  const { getCustomerOverview } = actionDispatch(useDispatch());

  const { paymentHistory, isLoading, paymentObject } = useSelector(
    (state: any) => {
      return {
        paymentHistory:
          state?.customerListAndDetails?.customerOverview?.paymentHistory,
        paymentObject: state?.customerListAndDetails?.customerOverview,
        isLoading: state?.customerListAndDetails?.isLoading,
      };
    }
  );

  const publishedProjects = useSelector(
    (state: any) => state?.projectList?.data?.data
  );
  useEffect(() => {
    const params = {
      cmsId: customerId,
      projectName: selectedProject?.launchName,
      sortingKey: columnSort.sortingKey,
      sortingPriority: columnSort.sortingPriority,
    };
    getCustomerOverview(params);
    return () => {};
  }, [selectedProject, columnSort]);

  const SeeReceiptHandler = (crmBookingID: string) => {
    paymentObject?.paymentReceipts?.map((paymentReceipt: any) => {
      if (paymentReceipt?.crmBookingId === crmBookingID) {
        let paymentReceiptsArray = paymentReceipts;
        paymentReceiptsArray?.push(paymentReceipt);
        setPaymentReceipts(paymentReceiptsArray);
      }
    });
    setShouldOpenReceiptsListDialog(true);
  };
  const cancelHandler = () => {
    setShouldOpenReceiptsListDialog(false);
    setTimeout(() => {
      setPaymentReceipts([]);
    }, 500);
  };
  return (
    <Box padding={"2rem"}>
      <Grid container alignItems={"center"}>
        <Grid item xs={0.5}>
          <Typography className="filter-label">Filter:</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <FormControl className="filter-dropdown">
            <AutocompleteComponent
              options={publishedProjects}
              getOptionLabel={(option: any) => option?.launchName}
              label="Project Name"
              onChange={(event: React.SyntheticEvent, newValue: any | null) => {
                setSelectedProject(newValue);
              }}
              value={selectedProject}
              isOptionEqualToValue={(option: any, value: any) =>
                option?.id === value?.id
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer
          component={Paper}
          className={`table-container`}
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                {paymentHistoryTableHeadings?.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}

                    {item.isSortingColumn ? (
                      <>
                        {columnSort.sortingPriority ===
                          SortingOrder.Descending &&
                        columnSort.isActiveSortingColumn === item.heading ? (
                          <IconButton
                            onClick={() => {
                              setColumnSort({
                                sortingKey: item.sortingKey,
                                sortingPriority: SortingOrder.Ascending,
                                isActiveSortingColumn: item.heading,
                              });
                            }}
                          >
                            <ArrowDropUpIcon
                              fontSize={"large"}
                              style={{
                                position: "relative",
                                // top: "16px",
                                right: "10px",
                                fontSize: "3rem",
                                color:
                                  columnSort.isActiveSortingColumn ===
                                  item.heading
                                    ? "#676ac0"
                                    : "rgb(0 0 0 / 87%)",
                              }}
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setColumnSort({
                                sortingKey: item.sortingKey,
                                sortingPriority: SortingOrder.Descending,
                                isActiveSortingColumn: item.heading,
                              });
                            }}
                          >
                            <ArrowDropDownIcon
                              fontSize={"large"}
                              style={{
                                position: "relative",
                                // top: "10px",
                                right: "10px",
                                fontSize: "3rem",
                                color:
                                  columnSort.isActiveSortingColumn ===
                                  item.heading
                                    ? "#676ac0"
                                    : "rgb(0 0 0 / 87%)",
                              }}
                            />
                          </IconButton>
                        )}
                      </>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {paymentHistory?.length > 0 ? (
              <TableBody>
                {paymentHistory?.map((paymentHistoryObject: any) => {
                  return (
                    <TableRow
                      key={paymentHistoryObject.projectId}
                      className="table-data-row"
                    >
                      <TableCell padding="checkbox" style={{ minWidth: 130 }}>
                        {`#ID - ${paymentHistoryObject?.projectId}`}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 200 }}
                      >
                        {paymentHistoryObject?.launchName}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150 }}
                      >
                        {paymentHistoryObject?.paidAmount}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150 }}
                      >
                        {paymentHistoryObject?.paymentDate
                          ? formatDateTime(
                              paymentHistoryObject?.paymentDate,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "--"}
                      </TableCell>
                      <TableCell style={{ minWidth: 120 }}>
                        <Tooltip title="Edit" placement="top">
                          <ButtonComponent
                            sx={{
                              textDecoration: "underline",
                              marginTop: "0.5rem",
                            }}
                            variant="text"
                            color="inherit"
                            onClick={() =>
                              SeeReceiptHandler(
                                paymentHistoryObject?.crmBookingId
                              )
                            }
                          >
                            See Receipt
                          </ButtonComponent>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Payment History Found</div>
            )}
          </Table>
        </TableContainer>
      )}
      {shouldOpenReceiptsListDialog ? (
        <PaymentReceiptsDialog
          shouldOpen={shouldOpenReceiptsListDialog}
          cancelHandler={cancelHandler}
          receiptsList={paymentReceipts}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default PaymentHistory;
