import React, { useEffect, useState } from "react";
import ProfileImageUploadDragDrop from "pages/page-management/page-management-dashboard/page-5-profile/profile-mediaUpload/ProfileImageUploadDragDrop";
import { useFormikContext } from "formik";
import { IPage5ProfileInitialValue } from "../../../page-5-profile-IniticalValue";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";

interface ICorporatePhilosphyImageMedia {
  index: number;
  currentlyOpenedIndex: number;
  handleShowImageMedia: (imageIndex: number) => void;
}
function CorporatePhilosphyImageMedia({
  index,
  currentlyOpenedIndex,
  handleShowImageMedia,
}: ICorporatePhilosphyImageMedia) {
  const { values, handleChange, setFieldValue } =
    useFormikContext<IPage5ProfileInitialValue>();

  const {
    imageDetails,
    isLoading,
    error,
    isSuccess,
    isDelete,
    isDeleteConfirmInPageManagementProfile,
    data,
  } = useSelector((state: any) => state.fileUpload);
  const [corporatePhilosphyImage, setCorporatePhilosphyImage] = useState(false);
  const dispatch = useDispatch();
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleOpenConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  // const handleCloseFlashMessage = () => {
  //   dispatch(
  //     FileUploadActions.setUploadFileState(
  //       "isDeleteConfirmInPageManagementProfile",
  //       false
  //     )
  //   );
  // };
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      if (currentlyOpenedIndex === index && corporatePhilosphyImage) {
        const corporatePhilosophyImageUpload = `aboutUs.corporatePhilosophy.detailedInformation[${currentlyOpenedIndex}].media.value.url`;
        setFieldValue(corporatePhilosophyImageUpload, imageDetails?.url);
      }
    }
    setCorporatePhilosphyImage(false); //set to false
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      if (currentlyOpenedIndex === index && corporatePhilosphyImage) {
        const empty = "";
        const corporatePhilosophyImageRemove = `aboutUs.corporatePhilosophy.detailedInformation[${currentlyOpenedIndex}].media.value.url`;
        setFieldValue(corporatePhilosophyImageRemove, empty);
      }
    }
    setCorporatePhilosphyImage(false);
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isDelete", false));
  }, [isDelete]);

  const handleConfirmDelete = (values: any) => {
    let fileName =
      values?.aboutUs?.corporatePhilosophy?.detailedInformation[index]?.media
        ?.name;
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
    setCorporatePhilosphyImage(true);
    // {
    //   !isLoading &&
    //     dispatch(
    //       FileUploadActions.setUploadFileState(
    //         "isDeleteConfirmInPageManagementProfile",
    //         true
    //       )
    //     );
    // }
    handleCloseConfirmDialog();
  };

  const handleConfirmDeleteImage = () => {  //if in Case to Delete the Image from Aws than Remove This function
    const empty = "";
    const corporatePhilosophyImageRemove = `aboutUs.corporatePhilosophy.detailedInformation[${currentlyOpenedIndex}].media.value.url`;
    setFieldValue(corporatePhilosophyImageRemove, empty);
    setCorporatePhilosphyImage(true);
    handleCloseConfirmDialog();
  };

  const handleSetFileNameAndSize = (file: any) => {
    const fileNameOfMedia = `aboutUs.corporatePhilosophy.detailedInformation[${index}].media.name`;
    const fileKeyOfMedia = `aboutUs.corporatePhilosophy.detailedInformation[${index}].media.key`;
    const fileSizeOfMedia = `aboutUs.corporatePhilosophy.detailedInformation[${index}].media.value.size`;
    setFieldValue(fileNameOfMedia, file?.name);
    setFieldValue(fileKeyOfMedia, file?.name);
    setFieldValue(fileSizeOfMedia, file?.size);
  };

  const handleImageUpload = (file: any) => {
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        dispatch(FileUploadActions.fileUploadRequest(data));
        data.binary = event.target.result;
      };
      reader.readAsArrayBuffer(file);
      setCorporatePhilosphyImage(true);
      {
        !isLoading && handleSetFileNameAndSize(file);
      }
    } else {
      return;
    }
  };

  return (
    <div>
      <ProfileImageUploadDragDrop
        index={index}
        currentlyOpenedIndex={currentlyOpenedIndex}
        uploadMediaButton={false}
        dragAndDropButton={true}
        name={`aboutUs.corporatePhilosophy.detailedInformation[${index}].media.value.url`}
        onClick={handleChange}
        onDrop={handleChange}
        onChange={(file: any) => {
          handleImageUpload(file);
          handleShowImageMedia(index);
        }}
        previewImage={
          values?.aboutUs?.corporatePhilosophy?.detailedInformation[index]
            ?.media?.value?.url
        }
        cancelImage={() => {
          handleOpenConfirmDialog();
          handleShowImageMedia(index);
        }}
        fieldhelpertext={ values?.aboutUs?.isCorporatePhilosophyActive && values?.aboutUs?.corporatePhilosophy?.detailedInformation[index]
          ?.media?.value?.url?.length === 0 && "Required"}
      />
      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Insights Creation Media?"
        content="This action will delete the Insights Creation Media. Are you sure you want to continue?"
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        // okHandler={() => handleConfirmDelete(values)}
        okHandler={() => handleConfirmDeleteImage()}
      />
      {/* <FlashMessageDialog
        cancel={false}
        shouldOpen={isDeleteConfirmInPageManagementProfile}
        content={data}
        isSuccess={error === null ? true : false}
        cancelHandler={handleCloseFlashMessage}
      /> */}
    </div>
  );
}

export default CorporatePhilosphyImageMedia;
