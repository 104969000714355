import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  DialogTitle,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MediaAssets from "assets";
import {
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import styles from "./ForgotPasswordEmail.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { isPasswordValid } from "utils/ValidationUtils";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordAction } from "redux-container/reset-password/ResetPasswordRedux";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ErrorIcon from "@mui/icons-material/Error";
interface IResetPassword {
  open: boolean;
  onClose: () => void;
}

const PasswordTooltipInfo = () => {
  return (
    <Box>
      <Typography variant="h6">New Password requirements:</Typography>
      <Typography variant="body1">A minimum of 8 characters</Typography>
      <Typography variant="body1">
        Include at least one lowercase letter (a-z)
      </Typography>
      <Typography variant="body1">
        Include at least one uppercase letter (A-Z)
      </Typography>
      <Typography variant="body1">Include at least one number (0-9)</Typography>
      <Typography variant="body1">Include a Special Character</Typography>
      <Typography variant="body1" gutterBottom>
        Cannot use last 3 old passwords.
      </Typography>
    </Box>
  );
};

const SuccessfulPasswordChanged = (props: any) => {
  const { error } = useSelector((state: any) => state.resetPassword);
  return (
    <React.Fragment>
      <Modal
        open={props.open}
        onClose={props.handleCloseChild}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className={styles["backgroundblur"]}
      >
        <Box className={styles["dialogBox-outer"]}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent="center"
          >
            {error?.length ? (
              <ErrorIcon sx={{ color: "Red", fontSize: "40px" }} />
            ) : (
              <CheckCircleIcon sx={{ color: "green", fontSize: "40px" }} />
            )}
            {error?.length ? (
              <Typography variant="h5" data-testid="password-changed-sucessful">
                Unauthorized
              </Typography>
            ) : (
              <Typography variant="h5" data-testid="password-changed-sucessful">
                Password Changed successfully
              </Typography>
            )}
          </Stack>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default function ResetPassword(props: IResetPassword) {
  const { error, isLoading, data, code, isSuccessfulResetPassword } =
    useSelector((state: any) => state.resetPassword);
  const [isOpenChildModel, setOpenChildModel] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, SetConfirmPassword] = React.useState("");
  const [isHideNewPassword, setHideNewPassword] = React.useState(true);
  const [isHideConfirmPassword, setHideConfirmPassword] = React.useState(true);
  const [errorNewPassword, setErrorNewPassword] = React.useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = React.useState("");
  const dispatch = useDispatch();
  const params = useParams();

  const closeResetPasswordDialog = () => {
    if (isSuccessfulResetPassword) {
      props.onClose();
      // setOpenChildModel(true);
    }
    // ResetPasswordAction.setResetPasswordState(
    //   "isSuccessfulResetPassword",
    //   false
    // );
  };

  React.useEffect(() => {
    closeResetPasswordDialog();
  }, [isSuccessfulResetPassword]);

  const handleResetPassword = () => {
    if (isPasswordValid(newPassword)) {
      if (confirmPassword === newPassword) {
        const reqBody = {
          newPassword: newPassword,
          token: params?.token,
        };
      dispatch(ResetPasswordAction.resetPasswordRequest(reqBody));
        // setOpenChildModel(true);
      } else {
        setErrorConfirmPassword("Passwords are not matching");
      }
    } else {
      setErrorNewPassword(
        "Minimum of 8 character At least One uppercase and lower case with special characters "
      );
    }
  };

  const handleResetPasswordOnKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      handleResetPassword();
    }
  };

  const handleCloseChildModel = () => {
    dispatch(
      ResetPasswordAction.setResetPasswordState(
        "isSuccessfulResetPassword",
        false
      )
    );
  };
  const onTouchConfirmPassword = () => {
    if (confirmPassword.length === 0) {
      setErrorConfirmPassword("Fill the required field");
    } else {
      setErrorConfirmPassword("");
    }
  };
  const onTouchNewPassword = () => {
    if (newPassword.length === 0) {
      setErrorNewPassword("Required");
    } else {
      setErrorNewPassword("");
    }
  };

  const handleNewHidePassword = () => {
    setHideNewPassword(!isHideNewPassword);
  };
  const handleHideConfirmPassword = () => {
    setHideConfirmPassword(!isHideConfirmPassword);
  };

  const handleChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value.trim());
    setErrorNewPassword("");
  };
  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    SetConfirmPassword(event.target.value.trim());
    setErrorConfirmPassword("");
  };
  return (
    <div>
      <Modal
        open={props.open}
        // onClose={props.onClose}
        className={styles["backgroundblur"]}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className={styles["dialogBox-outer"]}>
          <Box mb={2}>
            <DialogTitle className={styles["dialog-title"]}>
              <Typography
                component={"span"}
                variant="h5"
                color={"common.black"}
                data-testid="forget-password-header"
              >
                Reset Password
              </Typography>
              <IconButton
                edge={"end"}
                component={"span"}
                onClick={props.onClose}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          </Box>
          <Box mb={2}>
            <TextFieldComponent
              required
              onKeyPress={handleResetPasswordOnKeyPress}
              label="New Password"
              placeholder="Enter"
              variant="outlined"
              autoFocus
              focused
              type={isHideNewPassword ? "password" : "text"}
              fieldhelpertext={errorNewPassword}
              error={errorNewPassword.length !== 0}
              onChange={handleChangeNewPassword}
              onBlur={onTouchNewPassword}
              inputProps={{ "data-testid": "new-password" }}
              InputProps={{
                endAdornment: (
                  <Stack spacing={1}>
                    <Tooltip
                      title={<PasswordTooltipInfo />}
                      arrow
                      placement="top"
                    >
                      <InfoOutlinedIcon sx={{ fontSize: "20px" }} />
                    </Tooltip>

                    <IconButton onClick={handleNewHidePassword}>
                      {isHideNewPassword ? (
                        <Visibility className="password-eye-icon" />
                      ) : (
                        <VisibilityOff className="password-eye-icon" />
                      )}
                    </IconButton>
                  </Stack>
                ),
              }}
              name="newPassword"
              value={newPassword}
            />
          </Box>

          <TextFieldComponent
            required
            onKeyPress={handleResetPasswordOnKeyPress}
            label="Confirm New Password"
            placeholder="Enter"
            fieldhelpertext={errorConfirmPassword}
            error={errorConfirmPassword.length !== 0}
            variant="outlined"
            onChange={handleChangeConfirmPassword}
            name="confirmPassword"
            type={isHideConfirmPassword ? "password" : "text"}
            inputProps={{ "data-testid": "confirm-password" }}
            value={confirmPassword}
            onBlur={onTouchConfirmPassword}
            InputProps={{
              endAdornment: (
                <Stack spacing={1}>
                  <IconButton onClick={handleHideConfirmPassword}>
                    {isHideConfirmPassword ? (
                      <Visibility className="password-eye-icon" />
                    ) : (
                      <VisibilityOff className="password-eye-icon" />
                    )}
                  </IconButton>
                </Stack>
              ),
            }}
          />
          <Box textAlign="center" mt={2}>
            <LoadingButtonComponent
              onClick={handleResetPassword}
              data-testid="confirm-reset-password"
              loading={isLoading}
            >
              Confirm Reset Password
            </LoadingButtonComponent>
          </Box>
        </Box>
      </Modal>
      <SuccessfulPasswordChanged
        open={isSuccessfulResetPassword}
        handleCloseChild={handleCloseChildModel}
      />
    </div>
  );
}
