export interface IReraPhase {
  phaseName: string;
  reraNumber: string;
}

export interface IProjectFormInitialValues {
  status: string;
  bookingAmount: null;
  basicDetailsForm: {
    formHeader: string;
    typeOfApplicant: string[];
    residentialStatus: string[];
    salutation: string[];
    shouldDisplaySalutationActive: boolean;
    firstName: string;
    middleName: string;
    lastName: string;
    shouldDisplayMiddleName: boolean;
    companyName: string;
    gender: string[];
    dateOfBirth: string;
    shouldDisplayDateOfBirth: boolean;
    dateOfIncorporation: string;
    primaryEmail: string;
    phoneNumber: string;
    numberOfPlots: string;
    choiceOfPlots: any;
    termsAndConditions: string;
    privacyDescription: string;
    conditionDescription: string;
    returnPolicyDescription: string;
    reraDetails: {
      projectName: string;
      staticLableOne: string;
      companyRegistrationAddress: string;
      staticLableTwo: string;
      staticLableThree: string;
      gstinNumber: string;
      phase: IReraPhase[];
      staticLableFour: string;
      registeredProjects: string;
      staticLableFive: string;
      websiteName: string;
    };
    contactDetails: {
      companyAddress: string;
      phoneNumber: string;
      email: string;
    };
  };
  essentialDetailsForm: {
    addressLineOne: string;
    addressLineTwo: string;
    isAddressLineTwoActive: boolean;
    countries: string[];
    states: string[];
    cities: string;
    pincode: string;
    contactPersonOneNumber: string;
    occupation: string[];
    designation: string;
    isDesignationActive: boolean;
    applicantEmployerOrCompany: string;
    isApplicantEmployerOrCompanyActive: boolean;
    maritalStatuses: string[];
    sourceOfFunding: string[];
    puposeOfPurchase: string[];
    panCardNumber: string;
    panUpload: string;
    isPanCardUploadActive: boolean;
    isPanCardNumberActive: boolean;

    isAadharCardUploadActive: boolean;
    isAadharCardNumberActive: boolean;

    isCompanyOrHUFPanUploadActive: boolean;
    isCompanyOrHUFPanNumberActive: boolean;

    companyOrHUFPanNumber: string;
    companyOrHUFPanUpload: string;
    passportNumber: string;
    isPassportNumberActive: boolean;
    passportUpload: string;
    isPassportUploadActive: boolean;
    adhaarCardNumber: string;
    adhaarCardNumberUpload: string;
    hufLetter: string;
    authorizationLetter: string;
    boardResolutionLetter: string;
  };
  paymentFormBanner: {
    mediaWeb: {
      name: string;
      key: string;
      value: {
        mediaType: string;
        size: number;
        width: number;
        height: number;
        url: string;
      };
    };
    mediaMobile: {
      name: string;
      key: string;
      value: {
        mediaType: string;
        size: number;
        width: number;
        height: number;
        url: string;
      };
    };
    mediaTab: {
      name: string;
      key: string;
      value: {
        mediaType: string;
        size: number;
        width: number;
        height: number;
        url: string;
      };
    };
    description: string;
  };
}

export const ProjectFormInitialValues: IProjectFormInitialValues = {
  status: "1001",
  bookingAmount: null,
  basicDetailsForm: {
    formHeader: "",
    typeOfApplicant: [
      "Individual",
      "HUF",
      "Proprietorship",
      "Partnership",
      "LLP",
      "Private Limited",
      "Public Limited",
      "Trust",
      "Society",
    ],
    residentialStatus: ["Indian", "NRI", "OCI / PIO"],
    salutation: ["Mr.", "Miss", "Mrs.", "M/S", "Dr.", "Adv", "CA."],
    shouldDisplaySalutationActive: false,
    firstName: "First Name (Input field) *",
    middleName: "Middle Name (Input field) *",
    lastName: "Last Name (Input field) *",
    shouldDisplayMiddleName: false,
    companyName: "HUF / Company Name (Input field) *",
    gender: ["Male", "Female", "Prefer not to say"],
    dateOfBirth: "Date of Birth (Date selector) *",
    shouldDisplayDateOfBirth: false,
    dateOfIncorporation: "Date of Incorporation (Date selector) *",
    primaryEmail: "Primary Email (Input field) *",
    phoneNumber: "Mobile Number (Input field) *",
    numberOfPlots: "",
    choiceOfPlots: "Choice of Plots (Static Text) *",
    termsAndConditions: "",
    privacyDescription: "",
    conditionDescription: "",
    returnPolicyDescription: "",
    reraDetails: {
      projectName: "",
      staticLableOne: "The project is being  developed in phases by",
      companyRegistrationAddress: "",
      staticLableTwo: "GSTIN",
      staticLableThree: "MAHARERA Registartion No.",
      gstinNumber: "",
      phase: [
        {
          phaseName: "",
          reraNumber: "",
        },
      ],
      staticLableFour: "Registered as",
      registeredProjects: "",
      staticLableFive: "available on",
      websiteName: "",
    },
    contactDetails: {
      companyAddress: "",
      phoneNumber: "",
      email: "",
    },
  },
  essentialDetailsForm: {
    addressLineOne: "Address Line 1 (Input field) *",
    addressLineTwo: "Address Line 2 (Input field) *",
    isAddressLineTwoActive: false,
    countries: ["India"], // need to discuss with ankur
    states: ["karnataka"], // need to discuss with ankur
    cities: "City (Input field) *",
    pincode: "Pincode (Input field) *",
    contactPersonOneNumber: "Contact Person 1 Number (Input field) *",
    occupation: [
      "Salaried Private",
      "Salaried Government",
      "Business",
      "Professional",
      "Others",
    ],
    designation: "Designation (Input field)",
    isDesignationActive: false,
    applicantEmployerOrCompany: "Employer/Company (Input field)",
    isApplicantEmployerOrCompanyActive: false,
    maritalStatuses: ["Married", "Unmarried"],
    sourceOfFunding: ["Loan", "Self", "Conditional Sale of Asset"],
    puposeOfPurchase: ["Investment", "End Use", "Mixed Use", "Second Home"],
    panCardNumber: "PAN Card number (Input field) *",
    panUpload: "PAN Upload (Document Upload) *",
    companyOrHUFPanNumber: "Company/HUF PAN Card (Input field) *",
    companyOrHUFPanUpload: "Company/HUF PAN(Document Upload) *",
    passportNumber: "Passport Number (Input field)",
    isPassportNumberActive: false,
    passportUpload: "Passport  (Document Upload)",
    isPassportUploadActive: false,
    isPanCardUploadActive: false,
    isPanCardNumberActive: false,

    isAadharCardUploadActive: false,
    isAadharCardNumberActive: false,

    isCompanyOrHUFPanUploadActive: false,
    isCompanyOrHUFPanNumberActive: false,
    adhaarCardNumber: "Aadhaar Card Number (Input field) *",
    adhaarCardNumberUpload: "Aadhaar  (Document Upload) *",
    hufLetter: "HUF Letter (Document Upload) *",
    authorizationLetter: "Authorization letter (Document Upload) *",
    boardResolutionLetter: " Board Resolution Letter (Document Upload) *",
  },
  paymentFormBanner: {
    mediaWeb: {
      name: "",
      key: "",
      value: {
        mediaType: "",
        size: 0,
        width: 0,
        height: 0,
        url: "",
      },
    },
    mediaMobile: {
      name: "",
      key: "",
      value: {
        mediaType: "",
        size: 0,
        width: 0,
        height: 0,
        url: "",
      },
    },
    mediaTab: {
      name: "",
      key: "",
      value: {
        mediaType: "",
        size: 0,
        width: 0,
        height: 0,
        url: "",
      },
    },
    description: "",
  },
};
