import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button";
import CachedIcon from "@mui/icons-material/Cached";
import { Constants } from "../../../constants/Constants";
import Container from "@mui/material/Container";
import DateAdapter from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import { formatDateTime } from "../../../utils/DateFormatterUtils";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Loader from "../../../components/loader/Loader";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MediaAssets from "../../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import Retry from "../../../components/retry/Retry";
import { RoleTypeEnum, RoleTypeEnumUtils } from "enumerations/RoleTypeEnum";
import { RouteConstants } from "../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import UserLogsActions from "../../../redux-container/users/UserLogsRedux";
import TextFieldComponent from "components/ui-elements/text-field/TextFieldComponent";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { InfoComponent } from "components/ui-elements";
import { Checkbox, IconButton, InputLabel } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { SortingOrder } from "enumerations/SortingOrderTypeEnum";
import { Stack } from "@mui/material";
import { DepartmentTypeEnumUtils } from "enumerations/DepartmentTypeEnum";
import { useLocation } from "react-router-dom";
import { ActionTypeEnum } from "enumerations/ActionTypeEnum";
import { exportAsExcel } from "utils/ExportFileUtils";

const actionDispatch = (dispatch: any) => {
  return {
    getUserLogs: (data: any) => dispatch(UserLogsActions.userLogsRequest(data)),
    setUserLogsState: (key: any, data: any) =>
      dispatch(UserLogsActions.setUserLogsState(key, data)),
  };
};

const UserLog = () => {
  const { getUserLogs, setUserLogsState } = actionDispatch(useDispatch());
  const location: any = useLocation();
  // let roles: any = [];
  const userLogs = useSelector((state: any) => state?.userLogs.userLogs);
  const totalCount = useSelector((state: any) => state?.userLogs.totalCount);
  const isLoading = useSelector((state: any) => state.userLogs?.isLoading);
  const isErrorOccured = useSelector((state: any) => state.userLogs?.error);

  const { page, rowsPerPage } = useSelector((state: any) => {
    return {
      page: state.userLogs?.page,
      rowsPerPage: state.userLogs?.rowsPerPage,
    };
  });
  const [selectedRole, setSelectedRole] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [updatedTime, setUpdatedTime] = useState(new Date());
  const [roles, setRoles] = useState<any>([]);
  const [openDatepicker, setOpenDatepicker] = useState(false);
  const [columnSort, setColumnSort] = useState({
    sortingKey: "",
    sortingPriority: "",
    isActiveSortingColumn: "",
  });

  const [selectedUserLogs, setSelectedUserLogs] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");

  const [department, setDepartment] = useState("");
  const departments = DepartmentTypeEnumUtils.getDepartmentTypeEnums();
  const breadcrumbRoutes: any = [
    {
      name: "CMS User Management",
      route: RouteConstants.userManagementDashboard,
    },
    {
      name: "User Log",
      route: RouteConstants.userLog,
    },
  ];

  //table headings (heading objects with name,boolean key for whether it is sorting column or not,and sorting key for an API call )
  const tableHeadings = [
    { heading: "Date", isSortingColumn: true, sortingKey: "6" },
    { heading: "User" },
    { heading: "User ID" },
    { heading: "Department" },
    { heading: "Role" },
    { heading: "IP Address" },
    { heading: "Login Time" },
    { heading: "Logout Time" },
  ];

  useEffect(() => {
    clearFilters();
    // loadData({});
  }, []);

  //Loading Data (Insights data) while mounting and
  // every time after User applies any filters (roleId,Created by,fromDate,toDate,department,Sorting key,page and number of rows per page) .
  useEffect(() => {
    const user = getItemFromLocalStorage(Constants.loggedInUser);
    if (user?.admin?.roleId === RoleTypeEnum.SuperAdmin) {
      setRoles(RoleTypeEnumUtils.getRoleTypeEnumsForSuperAdmin());
    } else {
      setRoles(RoleTypeEnumUtils.getRoleTypeEnums());
    }
    const params = {
      index: page,
      size: rowsPerPage,
      roleId: selectedRole,
      fromDate: fromDate,
      toDate: toDate,
      sortingKey: columnSort.sortingKey,
      sortingPriority: columnSort.sortingPriority,
      department: department,
    };
    loadData(params);
  }, [
    page,
    rowsPerPage,
    selectedRole,
    toDate,
    fromDate,
    columnSort,
    department,
  ]);

  //setting page and rowsPerPage to initial value if user Navigated to the list from Side menu.
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setUserLogsState("page", 1);
      setUserLogsState("rowsPerPage", 20);
    }

    return () => {};
  }, []);
  const loadData = (params: any) => {
    getUserLogs(params);
  };

  //handle change function for page in Pagination.
  const handleChangePage = (event: unknown, newPage: number) => {
    setUserLogsState("page", newPage + 1);
  };

  //handle change function for Rows per page in Pagination.
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserLogsState("rowsPerPage", Number(event.target.value));
    setUserLogsState("page", 1);
  };

  //handle change function for role.
  const handleRoleChange = (event: SelectChangeEvent) => {
    setUserLogsState("page", 1);
    setSelectedRole(event.target.value as string);
  };
  const handleDepartment = (event: SelectChangeEvent) => {
    setUserLogsState("page", 1);
    setDepartment(event.target.value as string);
  };

  //function to fetch user data when user apply data filter.
  const syncUserLogs = () => {
    setUpdatedTime(new Date());
    const params = {
      index: page,
      size: rowsPerPage,
      roleId: selectedRole,
      fromDate: fromDate,
      toDate: toDate,
      sortingKey: fromDate ? 0 : 1,
      sortingPriority: fromDate ? 0 : 1,
      department: department,
    };
    loadData(params);
    clearFilters();
  };

  //function to remove all filters applied.
  const clearFilters = () => {
    setSelectedRole("");
    setFromDate(null);
    setToDate(null);
    setDepartment("");
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_add_user}
        altText=""
        message="There are no User Logs yet."
      />
    );
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = userLogs.map((data: any) => data.id);
      setSelectedUserLogs(newSelecteds);
      return;
    }
    setSelectedUserLogs([]);
  };
  const isSelected = (id: number) => {
    return selectedUserLogs.indexOf(id) !== -1;
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selectedUserLogs.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUserLogs, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUserLogs.slice(1));
    } else if (selectedIndex === selectedUserLogs?.length - 1) {
      newSelected = newSelected.concat(selectedUserLogs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUserLogs.slice(0, selectedIndex),
        selectedUserLogs.slice(selectedIndex + 1)
      );
    }
    setSelectedUserLogs(newSelected);
  };

  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case ActionTypeEnum?.Export:
        handleExportAsExcel();
        break;
    }
  };

  const handleExportAsExcel = () => {
    if (selectedUserLogs?.length === 0) {
      alert("You have not selected any User Logs");
      return;
    }
    let exportingAudits: any = [];
    if (userLogs?.length) {
      selectedUserLogs?.map((selectedCustomer: any) => {
        let localSelectedItem = userLogs.find(
          (item: any) => item.id == selectedCustomer
        );
        exportingAudits.push({
          Date: localSelectedItem.loginDate
            ? formatDateTime(
                localSelectedItem.loginDate,
                Constants.dateFormatDDmmYYYY
              )
            : "-",
          User: localSelectedItem.admins?.firstName
            ? `${localSelectedItem.admins?.firstName} ${localSelectedItem.admins?.lastName}`
            : "--",

          Email: localSelectedItem.email ? `${localSelectedItem.email}` : "-",
          Admin_ID: localSelectedItem.adminId
            ? `#-${localSelectedItem.adminId}`
            : "-",
          Department: localSelectedItem?.admins?.department
            ? `${localSelectedItem?.admins?.department}`
            : "-",
          Role:
            localSelectedItem?.admins && localSelectedItem?.admins?.roleId
              ? RoleTypeEnumUtils.getEnumText(localSelectedItem?.admins?.roleId)
              : "-",
          IP_Address: localSelectedItem.ipAddress
            ? localSelectedItem.ipAddress
            : "-",
          Login_Time: localSelectedItem.loginDate
            ? formatDateTime(
                localSelectedItem.loginDate,
                Constants.timeFormathhmmssA
              )
            : "-",
          Logout_Time: localSelectedItem.logoutDate
            ? formatDateTime(
                localSelectedItem.logoutDate,
                Constants.timeFormathhmmssA
              )
            : "-",
          Created_At: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateAndTimeFormatDDMMYYYYhhmmss
              )
            : "-",
          Updated_At: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateAndTimeFormatDDMMYYYYhhmmss
              )
            : "-",
        });
      });
    }

    exportAsExcel(exportingAudits, "User Logs", "Sheet1");
    setSelectedUserLogs([]);
  };
  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading margin-bottom-30">
            User Log
            {`(${
              totalCount ? totalCount : userLogs?.length ? userLogs?.length : 0
            })`}
          </h4>
          <div>
            <FormControl className="action-dropdown">
              <InputLabel>Actions</InputLabel>
              <Select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
                // IconComponent={() => (
                //   <ExpandMoreRoundedIcon className="dropdown-icon" />
                // )}
              >
                <MenuItem value={1}>{"Export"}</MenuItem>
              </Select>
            </FormControl>
          </div>{" "}
        </div>

        <Grid container spacing={1}>
          <Grid container spacing={2} md={8} lg={8} xl={8}>
            <Grid
              item
              md={1}
              lg={1}
              xl={1}
              className="margin-left-10 filter-heading-holder"
              style={{ position: "relative" }}
            >
              <span
                className="filter-heading"
                style={{ position: "absolute", top: 32 }}
              >
                Filters:
              </span>
            </Grid>

            <Grid item md={2.5} lg={2.5} xl={2.5}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedRole}
                  label="Role"
                  onChange={handleRoleChange}
                >
                  {roles.map((role: any) => {
                    return (
                      <MenuItem value={role.value} key={role.value}>
                        {role.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>
            <Grid item md={2.5} lg={2.5} xl={2.5}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={department}
                  label="Department"
                  onChange={handleDepartment}
                >
                  {departments.map((department: any) => {
                    return (
                      <MenuItem value={department.value} key={department.value}>
                        {department.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            <Grid item md={2.5} lg={2.5} xl={2.5}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="From Date"
                  inputFormat="yyyy-MM-dd"
                  mask={"____-__-__"}
                  value={fromDate}
                  maxDate={toDate === null ? new Date() : toDate}
                  onChange={(newDate) => {
                    setUserLogsState("page", 1);
                    setFromDate(newDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              mt={Boolean(!fromDate) ? "-1.6rem" : ""}
              md={2.5}
              lg={2.5}
              xl={2.5}
            >
              {Boolean(!fromDate) && (
                <Stack mb={"0.4rem"} justifyContent={"flex-end"}>
                  <InfoComponent infoContent="Please select from date first" />
                </Stack>
              )}
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  disabled={
                    Boolean(!fromDate) ||
                    fromDate?.setHours(0, 0, 0, 0) ===
                      new Date().setHours(0, 0, 0, 0)
                  }
                  label="To Date"
                  inputFormat="yyyy-MM-dd"
                  mask={"____-__-__"}
                  value={toDate}
                  maxDate={new Date()}
                  minDate={fromDate}
                  onChange={(newDate: any) => {
                    setToDate(newDate);
                    setUserLogsState("page", 1);
                  }}
                  onOpen={() => setOpenDatepicker(true)}
                  onClose={() => setOpenDatepicker(false)}
                  open={openDatepicker}
                  // onError={(reason, value) => {
                  //   switch (reason) {
                  //     case "minDate":
                  //       setOpenDatepicker(true);
                  //       break;
                  //     default:
                  //       setOpenDatepicker(false);
                  //   }
                  // }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            {selectedRole !== "" ||
            fromDate !== null ||
            toDate !== null ||
            department !== "" ? (
              <Grid
                item
                md={0.5}
                lg={0.5}
                xl={0.5}
                className="filter-heading-holder"
                style={{ position: "relative" }}
              >
                <span
                  onClick={clearFilters}
                  className="clear-filter"
                  style={{ position: "absolute", top: 32 }}
                >
                  Clear
                </span>
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={0} md={4} lg={4} xl={4}>
            <Grid item md={12} lg={12} xl={12}>
              <div className="time-sync-holder">
                <div className="time-sync-holder margin-right-15">
                  <span className="margin-right-5 updated-on-heading">
                    Updated on:
                  </span>
                  <span>
                    {formatDateTime(updatedTime, Constants.timeFormathhmmssA)}
                  </span>
                </div>

                <Button
                  variant="contained"
                  onClick={syncUserLogs}
                  // autoFocus
                  disableElevation
                  className="btn btn-sync"
                >
                  <CachedIcon />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 455 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell
                  className="table-header-text"
                  padding="checkbox"
                  style={{ minWidth: 50 }}
                >
                  <Checkbox
                    className="active-checkbox"
                    data-testid="table-head-checkbox"
                    color="primary"
                    indeterminate={
                      selectedUserLogs?.length > 0 &&
                      selectedUserLogs?.length < userLogs?.length
                    }
                    checked={
                      userLogs?.length > 0 &&
                      selectedUserLogs?.length === userLogs?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all userLogs",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    <>
                      {item.heading}
                      {item.isSortingColumn ? (
                        <>
                          {columnSort.sortingPriority ===
                            SortingOrder.Descending &&
                          columnSort.isActiveSortingColumn === item.heading ? (
                            <IconButton
                              onClick={() => {
                                setColumnSort({
                                  sortingKey: item.sortingKey,
                                  sortingPriority: SortingOrder.Ascending,
                                  isActiveSortingColumn: item.heading,
                                });
                                if (page !== 1) {
                                  setUserLogsState("page", 1);
                                }
                              }}
                            >
                              <ArrowDropUpIcon
                                fontSize={"large"}
                                style={{
                                  position: "relative",
                                  // top: "16px",
                                  right: "10px",
                                  fontSize: "3rem",
                                  color:
                                    columnSort.isActiveSortingColumn ===
                                    item.heading
                                      ? "#676ac0"
                                      : "rgb(0 0 0 / 87%)",
                                }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setColumnSort({
                                  sortingKey: item.sortingKey,
                                  sortingPriority: SortingOrder.Descending,
                                  isActiveSortingColumn: item.heading,
                                });
                                if (page !== 1) {
                                  setUserLogsState("page", 1);
                                }
                              }}
                            >
                              <ArrowDropDownIcon
                                fontSize={"large"}
                                style={{
                                  position: "relative",
                                  // top: "10px",
                                  right: "10px",
                                  fontSize: "3rem",
                                  color:
                                    columnSort.isActiveSortingColumn ===
                                    item.heading
                                      ? "#676ac0"
                                      : "rgb(0 0 0 / 87%)",
                                }}
                              />
                            </IconButton>
                          )}
                        </>
                      ) : null}
                    </>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {userLogs && userLogs.length > 0 ? (
              <TableBody>
                {userLogs.map((log: any) => {
                  const isItemSelected = isSelected(log?.id);

                  return (
                    <TableRow
                      key={log.id}
                      className="table-data-row"
                      style={{ height: "80px" }}
                    >
                      <TableCell padding="checkbox" style={{ minWidth: 50 }}>
                        <Checkbox
                          className="active-checkbox"
                          onClick={(event) => handleClick(event, log?.id)}
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": "labelId",
                          }}
                        />
                      </TableCell>
                      <TableCell className="table-data-text">
                        {log.loginDate
                          ? formatDateTime(
                              log.loginDate,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "-"}
                      </TableCell>

                      <TableCell className="table-data-text">
                        {log.admins?.firstName
                          ? `${log.admins?.firstName} ${log.admins?.lastName}`
                          : "--"}
                      </TableCell>
                      <TableCell className="table-data-text">
                        {log.adminId ? `#-${log.adminId}` : "-"}
                      </TableCell>
                      <TableCell className="table-data-text">
                        {log?.admins?.department
                          ? `${log?.admins?.department}`
                          : "-"}
                      </TableCell>

                      <TableCell className="table-data-text">
                        {log?.admins && log?.admins?.roleId
                          ? RoleTypeEnumUtils.getEnumText(log?.admins?.roleId)
                          : "-"}
                      </TableCell>

                      <TableCell className="table-data-text">
                        {log.ipAddress ? log.ipAddress : "-"}
                      </TableCell>

                      <TableCell className="table-data-text">
                        {log.loginDate
                          ? formatDateTime(
                              log.loginDate,
                              Constants.timeFormathhmmssA
                            )
                          : "-"}
                      </TableCell>

                      <TableCell className="table-data-text">
                        {log.logoutDate
                          ? formatDateTime(
                              log.logoutDate,
                              Constants.timeFormathhmmssA
                            )
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No User Log Records</div>
            )}
          </Table>
        </TableContainer>
        {userLogs && userLogs.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={totalCount ? totalCount : 0}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    // if (userLogs.length > 0 && isLoading === false) {
    //   return renderTable();
    // } else if (userLogs.length === 0) {
    //   return renderNoData();
    // } else if (isLoading) {
    //   return renderLoader();
    // } else if (isErrorOccured) {
    //   return renderRetry();
    // }

    if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    } else if (isErrorOccured) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <PageHeading title="CMS User Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

      <Container className="main-container">{render()}</Container>
    </Fragment>
  );
};

export default UserLog;
