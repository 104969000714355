import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";
import { state } from "utils/States";

const { Types, Creators } = createActions({
  getLandingPageDashboardRequest: ["data"],
  getLandingPageDashboardSuccess: ["response"],
  getLandingPageDashboardFailure: ["error"],

  setLandingPageDashboardState: ["key", "value"],
});

export const LandingPageDashboardTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  urlDashboardData: null,
  error: null,
  isLoading: false,
  snackBarError: "",
  isGraphDataFetched: false,
};

export const getLandingPageDashboardRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getLandingPageDashboardSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      urlDashboardData: response.data,
      isLoading: false,
      isGraphDataFetched: true,
    };
  }
};

export const getLandingPageDashboardFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setLandingPageDashboardState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LANDING_PAGE_DASHBOARD_FAILURE]: getLandingPageDashboardFailure,
  [Types.GET_LANDING_PAGE_DASHBOARD_SUCCESS]: getLandingPageDashboardSuccess,
  [Types.GET_LANDING_PAGE_DASHBOARD_REQUEST]: getLandingPageDashboardRequest,

  [Types.SET_LANDING_PAGE_DASHBOARD_STATE]: setLandingPageDashboardState,
});
