import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { CustomerActions } from "../../redux-container/customer-management/CustomersListAndDetailsRedux";

export function* getCustomers(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getCustomers, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(CustomerActions.getCustomersSuccess(response));
    } else {
      yield put(CustomerActions.getCustomersFailure(response));
    }
  } catch (error) {
    yield put(CustomerActions.getCustomersFailure(error));
  }
}

export function* getCustomerWatchlist(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getCustomerWatchList, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(CustomerActions.getCustomerWatchListSuccess(response));
    } else {
      yield put(CustomerActions.getCustomerWatchListFailure(response));
    }
  } catch (error) {
    yield put(CustomerActions.getCustomerWatchListFailure(error));
  }
}
export function* getCustomerInvestmentSummary(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.getCustomerInvestmentSummary, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(CustomerActions.getCustomerInvestmentSummarySuccess(response));
    } else {
      yield put(CustomerActions.getCustomerInvestmentSummaryFailure(response));
    }
  } catch (error) {
    yield put(CustomerActions.getCustomerInvestmentSummaryFailure(error));
  }
}
export function* getCustomerOverviewSummary(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.getCustomerOverview, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(CustomerActions.getCustomerOverviewSuccess(response));
    } else {
      yield put(CustomerActions.getCustomerOverviewFailure(response));
    }
  } catch (error) {
    yield put(CustomerActions.getCustomerOverviewFailure(error));
  }
}

export function* getCustomerDocument(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getDocuments, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(CustomerActions.getCustomerDocumentSuccess(response));
    } else {
      yield put(CustomerActions.getCustomerDocumentFailure(response));
    }
  } catch (error) {
    yield put(CustomerActions.getCustomerDocumentFailure(error));
  }
}

export function* getCustomerInvestmentDetails(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.investmentDetails, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(CustomerActions.getCustomerInvestmentDetailsSuccess(response));
    } else {
      yield put(CustomerActions.getCustomerInvestmentDetailsFailure(response));
    }
  } catch (error) {
    yield put(CustomerActions.getCustomerInvestmentDetailsFailure(error));
  }
}
export function* customerDataRefreshSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.refreshCustomerModuleData, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(CustomerActions.customerDataRefreshSuccess(response));
    } else {
      yield put(CustomerActions.customerDataRefreshFailure(response));
    }
  } catch (error) {
    yield put(CustomerActions.customerDataRefreshFailure(error));
  }
}
