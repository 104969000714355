import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getPageDashboardSuccess: ["response"],
  getPageDashboardFailure: ["error"],
  getPageDashboardRequest: ["params"],

  setPageDashboardState: ["key", "value"],
});

export const PageDashboardTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  pages: [],
  error: null,
  loadError: null,
  message: null,
  isLoading: false,
  isSuccess: false,
};

/* Get Page Dashboard Data */
export const getPageDashboardRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPageDashboardSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      pages: response.data,
      error: null,
      isLoading: false,
    };
  }
};

export const getPageDashboardFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setPageDashboardState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAGE_DASHBOARD_SUCCESS]: getPageDashboardSuccess,
  [Types.GET_PAGE_DASHBOARD_FAILURE]: getPageDashboardFailure,
  [Types.GET_PAGE_DASHBOARD_REQUEST]: getPageDashboardRequest,

  [Types.SET_PAGE_DASHBOARD_STATE]: setPageDashboardState,
});
