import {
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import PageHeading from "components/page-heading/PageHeading";
import { HoablNewUserStyleWrapper } from "./HoablNewUser.style";
import {
  chatManagementRoutes,
  hoablNewUserLabels,
  newHoablPayload,
  optionActions,
  Topics,
} from "./HoablNewUserInitialValues";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
  ButtonComponent,
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { FieldArray, Form, Formik, FormikValues, getIn } from "formik";
import { IObjectProps } from "pages/notification-management/notification-list/create-notification/NotificationInitialValues";
import { useDispatch, useSelector } from "react-redux";
import chatManagementAction from "redux-container/chat-management/ChatManagementRedux";
import { Delayed } from "utils/DelayRender";
import { newHoablValidationSchema } from "./HoableNewUserValidation";
import Loader from "components/loader/Loader";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { RouteConstants } from "routes/RouteConstants";
import { ActionsEnums } from "../hoabl-invested-user/ActionsEnums";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import axios from "axios";
import { ActionEnumsUtils } from "../hoabl-invested-user/ActionsEnums";
import { TopicEnumsUtils } from "../hoabl-invested-user/TopicEnums";
import { stripHtml } from "utils/ValidationUtils";
import { AuditStatus } from "enumerations/AuditStatusTypes";

const actionDispatch = (dispatch: any) => {
  return {
    getChatManagementData: (smartKey: string) =>
      dispatch(chatManagementAction.getChatManagementDataRequest(smartKey)),
    updateChatManagementData: (data: any) => {
      dispatch(chatManagementAction.updateChatManagementDataRequest(data));
    },
    setChatManagementState: (key: string, value: any) => {
      dispatch(chatManagementAction.setChatManagementState(key, value));
    },
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const HoablNewUser = () => {
  const {
    chatManagementData,
    isLoading,
    message,
    error,
    isSuccess,
    isDialogOpen,
    confirmDialogOpen,
  }: any = useSelector((state: any) => state?.chatManagement);
  const modules = useSelector((state: any) => state?.rolesAndModules?.module);
  const {
    getChatManagementData,
    updateChatManagementData,
    setChatManagementState,
    postAuditLogRequest,
    rolesAndModulesRequest,
  } = actionDispatch(useDispatch());

  const navigate = useNavigate();
  const location: any = useLocation();

  const [newUserState, setNewUserState] = useState({
    buttonText: "Publish",
    isViewMode: false,
  });

  const [previousValues, setPreviousValues] = useState<any>();
  const [modulesAudited, setModulesAudited] = useState<any>([]);
  const [isCategoryDeleted, setIsCategoryDeleted] = useState<boolean>(false);

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);
  const [categoryCount, setCategoryCount] = useState(
    previousValues?.categories?.length
  );

  //API call to get roles and modules which are used in POST API call.
  useEffect(() => {
    rolesAndModulesRequest();
    return () => {};
  }, []);

  //set API Data in Previous Values state after getting it from "getChatManagementData" API.
  useEffect(() => {
    setPreviousValues(chatManagementData);
  }, [chatManagementData]);

  useEffect(() => {
    setCategoryCount(chatManagementData?.categories?.length);
  }, [chatManagementData]);

  const handleSubmit = () => {
    setChatManagementState("confirmDialogOpen", true);
  };

  const handleCloseFlashMessageDialog = (values: any) => {
    setChatManagementState("error", null);
    setChatManagementState("confirmDialogOpen", false);
    setChatManagementState("isDialogOpen", false);
    !error && navigate(RouteConstants.chatSupportManagementDashboard);

    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Chat Support Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: location.pathname,
      auditedObject: values,
      // auditedObject:chatManagementData,
      modulesAudited: modulesAudited,
      status: AuditStatus.EDITED,
    };
    !error && postAuditLogRequest(postAuditRequestbody);
  };

  //function to publish changes made to  Hoabl New User's chat (Post API call) and Audit Log API call.
  const handleDeleteOkClick = (formikObjects: any) => {
    let formValues = formikObjects?.values;
    let touchedObject = formikObjects?.touched;

    let localPreviousValues: any = previousValues;
    let localCategories: any = localPreviousValues?.categories;

    if (categoryCount < formValues?.categories?.length) {
      for (
        let index = categoryCount;
        index < formValues?.categories?.length;
        index++
      ) {
        localCategories[index] = formValues?.categories[index];
      }
      localPreviousValues = {
        ...localPreviousValues,
        categories: localCategories,
      };
    }

    //Post API call
    updateChatManagementData(formValues);
    setChatManagementState("confirmDialogOpen", false);
    setChatManagementState("isDialogOpen", true);

    //Audit Log Functionality.

    // if (location?.state !== null) {
    let auditObjectsArray: any[] = [];
    Object?.keys(touchedObject)?.map((key: any) => {
      //values objects
      if (typeof touchedObject[key] == "object") {
        //categories as object checking
        if (Array.isArray(touchedObject[key])) {
          //categories as array checking
          //if key is array
          touchedObject[key]?.map((element: any, index: number) => {
            //categories array looping
            if (typeof touchedObject[key][index] == "object") {
              //categories array's obejct checking
              Object?.keys(touchedObject[key][index])?.map(
                (categoryKey: any, categoryKeyIndex: number) => {
                  //categories array's object key looping
                  if (Array.isArray(touchedObject[key][index][categoryKey])) {
                    //categories array's object's(options) key is array checking
                    touchedObject[key][index][categoryKey].map(
                      (optionsObject: any, optionsKeyIndex: number) => {
                        //options array looping
                        if (
                          typeof touchedObject[key][index][categoryKey][
                            optionsKeyIndex
                          ] == "object" //1st object of options is object checking
                        ) {
                          Object?.keys(
                            touchedObject[key][index][categoryKey][
                              optionsKeyIndex //keys of options object mapping
                            ]
                          )?.map((optionsKeys: any) => {
                            //options array's object's key looping
                            if (
                              hoablNewUserLabels[key][0][categoryKey][0][
                                optionsKeys
                              ] !== undefined &&
                              formValues[key][index][categoryKey][
                                optionsKeyIndex
                              ][optionsKeys] !==
                                localPreviousValues[key][index][categoryKey][
                                  optionsKeyIndex
                                ][optionsKeys]
                            ) {
                              let auditObject = {
                                itemAffected: ` ${
                                  hoablNewUserLabels[key][0][categoryKey][0][
                                    optionsKeys
                                  ]
                                } ${optionsKeyIndex + 1} of Category ${
                                  index + 1
                                } `,
                                newValue:
                                  optionsKeys === "action"
                                    ? ActionEnumsUtils.getEnumText(
                                        formValues[key][index][categoryKey][
                                          optionsKeyIndex
                                        ][optionsKeys]
                                      )
                                    : formValues[key][index][categoryKey][
                                        optionsKeyIndex
                                      ][optionsKeys],
                                oldValue:
                                  optionsKeys === "action"
                                    ? ActionEnumsUtils.getEnumText(
                                        localPreviousValues[key][index][
                                          categoryKey
                                        ][optionsKeyIndex][optionsKeys]
                                      )
                                    : localPreviousValues[key][index][
                                        categoryKey
                                      ][optionsKeyIndex][optionsKeys],
                              };
                              auditObjectsArray.push(auditObject);
                            }
                          });
                        }
                      }
                    );
                  } else {
                    if (
                      hoablNewUserLabels[key][0][categoryKey] !== undefined &&
                      formValues[key][index][categoryKey] !==
                        localPreviousValues[key][index][categoryKey]
                    ) {
                      let auditObject = {
                        itemAffected: `${
                          hoablNewUserLabels[key][0][categoryKey]
                        }  of Category ${index + 1} `,
                        newValue:
                          categoryKey === "action"
                            ? TopicEnumsUtils.getEnumText(
                                formValues[key][index][categoryKey]
                              )
                            : formValues[key][index][categoryKey],
                        oldValue:
                          categoryKey === "action"
                            ? TopicEnumsUtils.getEnumText(
                                localPreviousValues[key][index][categoryKey]
                              )
                            : localPreviousValues[key][index][categoryKey],
                      };
                      auditObjectsArray.push(auditObject);
                    }
                  }
                }
              );
            } else if (
              hoablNewUserLabels[key][0] !== undefined &&
              formValues[key][index] !== localPreviousValues[key][index]
            ) {
              let auditObject = {
                itemAffected: `${hoablNewUserLabels[key]} of Category ${
                  index + 1
                }`,
                newValue: formValues[key][index],
                oldValue: localPreviousValues[key][index],
              };
              auditObjectsArray.push(auditObject);
            }
          });
        }
      } else {
        // if key is just variable
        if (
          hoablNewUserLabels[key] !== undefined &&
          formValues[key] !== localPreviousValues[key]
        ) {
          let auditObject = {
            itemAffected: hoablNewUserLabels[key],
            newValue: stripHtml(formValues[key]),
            oldValue: stripHtml(localPreviousValues[key]),
          };
          auditObjectsArray.push(auditObject);
        }
      }
    });
    //set auditObjectsArray to a state to use it for api call ..
    // setModulesAudited(auditObjectsArray);
    // setModulesAudited([...modulesAudited, ...auditObjectsArray]);
    setModulesAudited((prev: any) => [...prev, ...auditObjectsArray]);

    // }
  };

  const handleDialogClose = () => {
    setChatManagementState("confirmDialogOpen", false);
    setChatManagementState("error", null);
  };

  const handleEditClick = () => {
    setNewUserState((prev) => ({
      ...prev,
      isViewMode: false,
    }));
  };

  // Generally we are useing Formik's "handleChange","handleBlur" functions to all the text-fields But for all Select-Fields
  // we use our manual handle change.
  //since this is Select-field so we manually setting value using formik's function "setFieldValue" .
  const handleActionChange =
    ({
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void;
    }) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(`${event.target.name}.action`, event.target.value);
      if (Number(event.target.value) === ActionsEnums.ShowFinalResponse) {
        setFieldValue(`${event.target.name}.actionType`, 3);
      } else {
        setFieldValue(`${event.target.name}.actionType`, 2);
      }
    };

  //get Hoabl New User Chat management data while mounting .
  useEffect(() => {
    let cancelEffect = true;
    if (cancelEffect) {
      getChatManagementData(newHoablPayload.smartKey);
    }
    return () => {
      cancelEffect = false;
    };
  }, []);

  const checkDisableState = (dirty: any) => {
    if (isCategoryDeleted) {
      return !isCategoryDeleted;
    } else {
      return !dirty;
    }
  };

  return (
    <Fragment>
      <PageHeading title="Chat Support Mangement" />
      <BreadCrumbs routes={chatManagementRoutes}></BreadCrumbs>
      <Delayed>
        <Formik
          initialValues={
            chatManagementData !== null ? chatManagementData : newHoablPayload
          }
          validationSchema={newHoablValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            isValid,
            dirty,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => (
            <Form>
              {isLoading ? (
                <Loader />
              ) : (
                <Container>
                  <Stack>
                    <Stack>
                      <IconButton
                        color="inherit"
                        edge={"start"}
                        onClick={() => navigate(-1)}
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                      <Typography color={"secondary.dark"} variant="h5">
                        {"HoABL - New User"}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} columnGap={2}>
                      <Collapse in={!newUserState.isViewMode}>
                        <ButtonComponent
                          type="reset"
                          variant="outlined"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </ButtonComponent>
                      </Collapse>
                      <Collapse
                        in={!newUserState.isViewMode}
                        orientation={"horizontal"}
                      >
                        <LoadingButtonComponent
                          type="submit"
                          disabled={!isValid || checkDisableState(dirty)}
                        >
                          {newUserState.buttonText}
                        </LoadingButtonComponent>
                      </Collapse>
                      <Collapse
                        in={newUserState.isViewMode}
                        orientation={"horizontal"}
                      >
                        <ButtonComponent
                          variant="contained"
                          type="button"
                          onClick={handleEditClick}
                        >
                          {"Edit"}
                        </ButtonComponent>
                      </Collapse>
                    </Stack>
                  </Stack>
                  <HoablNewUserStyleWrapper>
                    <Grid container paddingTop={"1rem"} spacing={4}>
                      <Grid item lg={12} xl={12}>
                        <TextFieldComponent
                          label={"Welcome Message *"}
                          name="welcomeMessage"
                          value={values.welcomeMessage}
                          error={Boolean(
                            errors?.welcomeMessage && touched.welcomeMessage
                          )}
                          fieldhelpertext={
                            errors.welcomeMessage &&
                            touched.welcomeMessage &&
                            errors.welcomeMessage
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            readOnly: newUserState.isViewMode,
                          }}
                          inputProps={{
                            maxLength: 100,
                          }}
                        />
                      </Grid>
                      <Grid item lg={12} xl={12}>
                        <TextFieldComponent
                          label={"Message *"}
                          name="firstMessage"
                          value={values.firstMessage}
                          error={Boolean(
                            errors?.firstMessage && touched.firstMessage
                          )}
                          fieldhelpertext={
                            errors.firstMessage &&
                            touched.firstMessage &&
                            errors.firstMessage
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            readOnly: newUserState.isViewMode,
                          }}
                          inputProps={{
                            maxLength: 100,
                          }}
                        />
                      </Grid>
                      <Grid item lg={12} xl={12}>
                        <Accordion expanded={true}>
                          <AccordionSummary expandIcon={<></>} outlined>
                            <Typography variant="body1">{`Categories`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FieldArray name="categories">
                              {(fieldArrayProps) => {
                                const {
                                  push: formikPush,
                                  remove: formikRemove,
                                  form: { values },
                                } = fieldArrayProps;
                                const { categories } = values;
                                return (
                                  <Fragment>
                                    {categories?.map(
                                      (
                                        category: IObjectProps,
                                        catergoryIndex: number
                                      ) => (
                                        <Fragment key={catergoryIndex + 1}>
                                          <Stack className="field-container-box">
                                            <Typography>
                                              Category {catergoryIndex + 1}
                                            </Typography>
                                            {!!catergoryIndex && (
                                              <IconButton
                                                edge={"start"}
                                                size="small"
                                                color="error"
                                                aria-label="delete"
                                                //handle Click which deletes one Category from Categories array.
                                                onClick={() => {
                                                  setIsCategoryDeleted(true);
                                                  formikRemove(catergoryIndex);
                                                  let categoryArray =
                                                    previousValues?.categories;
                                                  categoryArray.splice(
                                                    catergoryIndex
                                                  );
                                                  setPreviousValues({
                                                    ...previousValues,
                                                    categories: categoryArray,
                                                  });
                                                  if (
                                                    categoryCount >
                                                    values?.categories?.length
                                                  ) {
                                                    setModulesAudited(
                                                      (prev: any) => [
                                                        ...prev,
                                                        {
                                                          itemAffected: `Category ${
                                                            catergoryIndex + 1
                                                          } Deleted`,
                                                          newValue: "",
                                                          oldValue: "",
                                                        },
                                                      ]
                                                    );
                                                  }
                                                }}
                                              >
                                                <DeleteOutlineOutlinedIcon />
                                              </IconButton>
                                            )}
                                          </Stack>
                                          <Grid
                                            container
                                            spacing={3}
                                            className="section-gutter"
                                          >
                                            <Grid item lg={3} xl={3}>
                                              <TextFieldComponent
                                                label={"Display Name*"}
                                                name={`categories[${catergoryIndex}].text`}
                                                value={
                                                  values.categories[
                                                    catergoryIndex
                                                  ].text
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(
                                                  getIn(
                                                    touched,
                                                    `categories[${catergoryIndex}].text`
                                                  ) &&
                                                    getIn(
                                                      errors,
                                                      `categories[${catergoryIndex}].text`
                                                    )
                                                )}
                                                fieldhelpertext={
                                                  getIn(
                                                    touched,
                                                    `categories[${catergoryIndex}].text`
                                                  ) &&
                                                  getIn(
                                                    errors,
                                                    `categories[${catergoryIndex}].text`
                                                  )
                                                }
                                                InputProps={{
                                                  readOnly:
                                                    newUserState.isViewMode,
                                                }}
                                                inputProps={{
                                                  maxLength: 100,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item lg={3} xl={3}>
                                              <TextFieldComponent
                                                select
                                                label="Topic *"
                                                name={`categories[${catergoryIndex}].action`}
                                                value={
                                                  values.categories[
                                                    catergoryIndex
                                                  ]?.action
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(
                                                  getIn(
                                                    touched,
                                                    `categories[${catergoryIndex}].action`
                                                  ) &&
                                                    getIn(
                                                      errors,
                                                      `categories[${catergoryIndex}].action`
                                                    )
                                                )}
                                                fieldhelpertext={
                                                  getIn(
                                                    touched,
                                                    `categories[${catergoryIndex}].action`
                                                  ) &&
                                                  getIn(
                                                    errors,
                                                    `categories[${catergoryIndex}].action`
                                                  )
                                                }
                                              >
                                                {Topics.map((option) => (
                                                  <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </MenuItem>
                                                ))}
                                              </TextFieldComponent>
                                            </Grid>
                                            <Grid item lg={11} xl={11}>
                                              <TextFieldComponent
                                                label={"Message Body *"}
                                                name={`categories[${catergoryIndex}].message`}
                                                value={
                                                  values.categories[
                                                    catergoryIndex
                                                  ].message
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(
                                                  getIn(
                                                    touched,
                                                    `categories[${catergoryIndex}].message`
                                                  ) &&
                                                    getIn(
                                                      errors,
                                                      `categories[${catergoryIndex}].message`
                                                    )
                                                )}
                                                fieldhelpertext={
                                                  getIn(
                                                    touched,
                                                    `categories[${catergoryIndex}].message`
                                                  ) &&
                                                  getIn(
                                                    errors,
                                                    `categories[${catergoryIndex}].message`
                                                  )
                                                }
                                                InputProps={{
                                                  readOnly:
                                                    newUserState.isViewMode,
                                                }}
                                                inputProps={{
                                                  maxLength: 100,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item lg={6} xl={6}>
                                              <Grid container spacing={4}>
                                                {categories[
                                                  catergoryIndex
                                                ]?.options?.map(
                                                  (
                                                    action: IObjectProps,
                                                    actionIndex: number
                                                  ) => (
                                                    <Fragment key={actionIndex}>
                                                      <Grid item lg={6} xl={6}>
                                                        <TextFieldComponent
                                                          label={`option ${
                                                            actionIndex + 1
                                                          } *`}
                                                          name={`categories[${catergoryIndex}].options[${actionIndex}].text`}
                                                          value={
                                                            values.categories[
                                                              catergoryIndex
                                                            ].options[
                                                              actionIndex
                                                            ].text
                                                          }
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={handleBlur}
                                                          error={Boolean(
                                                            getIn(
                                                              touched,
                                                              `categories[${catergoryIndex}].options[${actionIndex}].text`
                                                            ) &&
                                                              getIn(
                                                                errors,
                                                                `categories[${catergoryIndex}].options[${actionIndex}].text`
                                                              )
                                                          )}
                                                          fieldhelpertext={
                                                            getIn(
                                                              touched,
                                                              `categories[${catergoryIndex}].options[${actionIndex}].text`
                                                            ) &&
                                                            getIn(
                                                              errors,
                                                              `categories[${catergoryIndex}].options[${actionIndex}].text`
                                                            )
                                                          }
                                                          InputProps={{
                                                            readOnly:
                                                              newUserState.isViewMode,
                                                          }}
                                                          inputProps={{
                                                            maxLength: 100,
                                                          }}
                                                        />
                                                      </Grid>
                                                      <Grid item lg={6} xl={6}>
                                                        <TextFieldComponent
                                                          select
                                                          label="Actions *"
                                                          name={`categories[${catergoryIndex}].options[${actionIndex}]`}
                                                          value={
                                                            values.categories[
                                                              catergoryIndex
                                                            ].options[
                                                              actionIndex
                                                            ].action
                                                          }
                                                          onChange={handleActionChange(
                                                            {
                                                              setFieldValue,
                                                            }
                                                          )}
                                                          onBlur={handleBlur}
                                                          error={Boolean(
                                                            getIn(
                                                              touched,
                                                              `categories[${catergoryIndex}].options[${actionIndex}].action`
                                                            ) &&
                                                              getIn(
                                                                errors,
                                                                `categories[${catergoryIndex}].options[${actionIndex}].action`
                                                              )
                                                          )}
                                                          fieldhelpertext={
                                                            getIn(
                                                              touched,
                                                              `categories[${catergoryIndex}].options[${actionIndex}].action`
                                                            ) &&
                                                            getIn(
                                                              errors,
                                                              `categories[${catergoryIndex}].options[${actionIndex}].action`
                                                            )
                                                          }
                                                        >
                                                          {optionActions.map(
                                                            (option) => (
                                                              <MenuItem
                                                                key={
                                                                  option.value
                                                                }
                                                                value={
                                                                  option.value
                                                                }
                                                              >
                                                                {option.label}
                                                              </MenuItem>
                                                            )
                                                          )}
                                                        </TextFieldComponent>
                                                      </Grid>
                                                    </Fragment>
                                                  )
                                                )}
                                              </Grid>
                                            </Grid>
                                            {catergoryIndex ===
                                              categories.length - 1 &&
                                              catergoryIndex < 3 && (
                                                <Grid item lg={12} xl={12}>
                                                  <ButtonComponent
                                                    variant="text"
                                                    color="inherit"
                                                    sx={{
                                                      textDecoration:
                                                        "underline",
                                                    }}
                                                    //handle Click function which adds A Category to Categories Array.
                                                    onClick={() => {
                                                      formikPush(
                                                        newHoablPayload
                                                          .categories[0]
                                                      );
                                                      let categoryArray =
                                                        previousValues?.categories;
                                                      categoryArray.push({
                                                        text: "",
                                                        action: null,
                                                        actionType: 0,
                                                        optionNumber: 0,
                                                        message: "",
                                                        options: [
                                                          {
                                                            text: "",
                                                            action: null,
                                                            actionType: 0,
                                                            optionNumber: 0,
                                                          },
                                                          {
                                                            text: "",
                                                            action: null,
                                                            actionType: 0,
                                                            optionNumber: 0,
                                                          },
                                                        ],
                                                        linkedOption: 0,
                                                      });
                                                      setPreviousValues({
                                                        ...previousValues,
                                                        categories:
                                                          categoryArray,
                                                      });
                                                      if (
                                                        categoryCount <
                                                        values?.categories
                                                          ?.length +
                                                          1
                                                      ) {
                                                        setModulesAudited(
                                                          (prev: any) => [
                                                            ...prev,
                                                            {
                                                              itemAffected: `Category ${
                                                                catergoryIndex +
                                                                1 +
                                                                1
                                                              } Added`,
                                                              newValue: "",
                                                              oldValue: "",
                                                            },
                                                          ]
                                                        );
                                                      }
                                                    }}
                                                    disabled={
                                                      newUserState.isViewMode
                                                    }
                                                  >
                                                    + Add More
                                                  </ButtonComponent>
                                                </Grid>
                                              )}
                                          </Grid>
                                          {catergoryIndex !==
                                            categories.length - 1 && (
                                            <Divider />
                                          )}
                                        </Fragment>
                                      )
                                    )}
                                  </Fragment>
                                );
                              }}
                            </FieldArray>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      {/* <Grid item lg={12} xl={12}>
                        <TextFieldComponent
                          label={"Final Message *"}
                          name="finalMessage"
                          value={values.finalMessage}
                          error={Boolean(
                            errors?.finalMessage && touched.finalMessage
                          )}
                          fieldhelpertext={
                            errors.finalMessage &&
                            touched.finalMessage &&
                            errors.finalMessage
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            readOnly: newUserState.isViewMode,
                          }}
                          inputProps={{
                            maxLength: 100,
                          }}
                        />
                      </Grid> */}
                      {/* <Grid item lg={12} xl={12}>
                        <TextFieldComponent
                          label={"Inactive Message *"}
                          name="inactiveMessage"
                          value={values.inactiveMessage}
                          error={Boolean(
                            errors?.inactiveMessage && touched.inactiveMessage
                          )}
                          fieldhelpertext={
                            errors.inactiveMessage &&
                            touched.inactiveMessage &&
                            errors.inactiveMessage
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            readOnly: newUserState.isViewMode,
                          }}
                          inputProps={{
                            maxLength: 100,
                          }}
                        />
                      </Grid> */}
                    </Grid>
                  </HoablNewUserStyleWrapper>
                </Container>
              )}
              {!isLoading && (
                <FlashMessageDialog
                  shouldOpen={isDialogOpen}
                  content={message ? message : error}
                  isSuccess={isSuccess}
                  cancelHandler={() => handleCloseFlashMessageDialog(values)}
                />
              )}
              <ConfirmationDialog
                shouldOpen={confirmDialogOpen}
                content="Are you sure you want to Publish?"
                okText="Publish"
                okHandler={() => handleDeleteOkClick({ values, touched })}
                cancelHandler={handleDialogClose}
              />
            </Form>
          )}
        </Formik>
      </Delayed>
    </Fragment>
  );
};

export default HoablNewUser;
