
export const categoryEnum = {
  HOME: "5001",
  INVEST: "5002",
  PROMISES: "5003",
  PORTFOLIO: "5004",
  PROFILE: "5005",
  OTHERS: "5006",
};

export const enum categoryEnums {
  HOME = "5001",
  INVEST = "5002",
  PROMISES = "5003",
  PORTFOLIO = "5004",
  PROFILE = "5005",
  OTHERS = "5006",
}

export class CategoryTypeEnumUtils {
  public static getEnumText(type: categoryEnums): string {
    switch (type) {
      case categoryEnums.HOME:
        return "HOME";
      case categoryEnums.INVEST:
        return "INVEST";
      case categoryEnums.PROMISES:
        return "PROMISES";
      case categoryEnums.PORTFOLIO:
        return "PORTFOLIO";
      case categoryEnums.PROFILE:
        return "PROFILE";
      case categoryEnums.OTHERS:
        return "OTHERS";
    }
  }

  public static getCategoryTypeEnums(): {
    label: string;
    value: categoryEnums;
  }[] {
    const options: { label: string; value: categoryEnums }[] = [
      {
        label: this.getEnumText(categoryEnums.HOME),
        value: categoryEnums.HOME,
      },
      {
        label: this.getEnumText(categoryEnums.INVEST),
        value: categoryEnums.INVEST,
      },
      {
        label: this.getEnumText(categoryEnums.PROMISES),
        value: categoryEnums.PROMISES,
      },
      {
        label: this.getEnumText(categoryEnums.PORTFOLIO),
        value: categoryEnums.PORTFOLIO,
      },
      {
        label: this.getEnumText(categoryEnums.PROFILE),
        value: categoryEnums.PROFILE,
      },
      {
        label: this.getEnumText(categoryEnums.OTHERS),
        value: categoryEnums.OTHERS,
      },
    ];
    return options;
  }
}

//blocked Customers enums

export const enum BlockedCustomerTypeEnum {
  Prelead = 1,
  Customer = 225360001,
  Investor = 225360002,
}

export class BlockedCustomerTypeEnumUtils {
  public static getEnumText(type: BlockedCustomerTypeEnum): string {
    switch (type) {
      case BlockedCustomerTypeEnum.Prelead:
        return "Pre Lead";
      case BlockedCustomerTypeEnum.Customer:
        return "Customer";
      case BlockedCustomerTypeEnum.Investor:
        return "Investor";
    }
  }

  public static getBlockedCustomersTypeEnums(): {
    label: string;
    value: BlockedCustomerTypeEnum;
  }[] {
    const options: { label: string; value: BlockedCustomerTypeEnum }[] = [
      {
        label: this.getEnumText(BlockedCustomerTypeEnum.Prelead),
        value: BlockedCustomerTypeEnum.Prelead,
      },
      {
        label: this.getEnumText(BlockedCustomerTypeEnum.Customer),
        value: BlockedCustomerTypeEnum.Customer,
      },
      {
        label: this.getEnumText(BlockedCustomerTypeEnum.Investor),
        value: BlockedCustomerTypeEnum.Investor,
      },
    ];
    return options;
  }
}
