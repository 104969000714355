import * as yup from "yup";

export const validationSchema = yup.object({
  name: yup.string().required("This field is mandatory").nullable(),
  shortDescription: yup.string().required("This field is mandatory").nullable(),
  isHowToApplyActive: yup.boolean(),
  howToApply: yup.object().when("isHowToApplyActive", {
    is: true,
    then: yup.object({
      title: yup.string().required("title is required").nullable(),
      description: yup.string().required("description is required").nullable(),
    }),
  }),
  displayMedia: yup.object().shape({
    value: yup.object().shape({
      url: yup.mixed().required("File is required"),
    }),
  }),

  termsAndConditions: yup.object().when("isTermsAndConditionsActive", {
    is: true,
    then: yup.object({
      displayName: yup.string().required("title is required").nullable(),
      description: yup.string().required("description is required").nullable(),
    }),
  }),

  description: yup.array().of(yup.string().required("required")),
  isPromiseCTADetailsActive: yup.boolean(),

  promiseCTADetails: yup.object().when("isPromiseCTADetailsActive", {
    is: true,
    then: yup.object({
      ctaName: yup.string().required("CTA Name is required").nullable(),
      toolTipDescription: yup
        .string()
        .required("Tooltip Description is required")
        .nullable(),
    }),
  }),
});
