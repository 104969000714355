import * as Yup from "yup";

export const validationSchemaforProfile = Yup.object().shape({
  isSecurityTipsActive: Yup.boolean(),
  securityTips: Yup.object().when("isSecurityTipsActive", {
    is: true,
    then: Yup.object({
      sectionHeading: Yup.string().required("Required"),
      detailedInformation: Yup.array().of(
        Yup.object({
          media: Yup.object({
            value: Yup.object({
              url: Yup.string().required("Required").nullable(),
            }),
          }),
          description: Yup.string()
            .required("Required")
            .min(13, "Please provide valid description"),
        })
      ),
    }),
  }),

  isTermsActive: Yup.boolean(),
  termsAndConditions: Yup.object().when("isTermsActive", {
    is: true,
    then: Yup.object({
      displayName: Yup.string().required("Required"),
      description: Yup.string().min(13, "Required").required("Required"),
    }),
  }),
  aboutUs: Yup.object({
    isAboutHoablActive: Yup.boolean(),
    aboutHoabl: Yup.object().when("isAboutHoablActive", {
      is: true,
      then: Yup.object({
        sectionHeading: Yup.string().required("Required"),
        description: Yup.string().min(13, "Required").required("Required"),
      }),
    }),
    isFoundersVisionActive: Yup.boolean(),
    foundersVision: Yup.object().when("isFoundersVisionActive", {
      is: true,
      then: Yup.object({
        sectionHeading: Yup.string().required("Required"),
        founderName: Yup.string().required("Required"),
        description: Yup.string().min(13, "Required").required("Required"),
        media: Yup.object({
          value: Yup.object({
            url: Yup.string().required("Required"),
          }),
        }),
        // aboutUs?.foundersVision?.media?.value?.url
      }),
    }),

    isCorporatePhilosophyActive: Yup.boolean(),
    corporatePhilosophy: Yup.object().when("isCorporatePhilosophyActive", {
      is: true,
      then: Yup.object({
        sectionHeading: Yup.string().required("Required"),
        detailedInformation: Yup.array().of(
          Yup.object({
            displayName: Yup.string().required("Required"),
            description: Yup.string().min(9, "Required").required("Required"),
            media: Yup.object({
              value: Yup.object({
                url: Yup.string().required("Required"),
              }),
            }),
          })
        ),
      }),
    }),
    isProductCategoryActive: Yup.boolean(),
    productCategory: Yup.object().when("isProductCategoryActive", {
      is: true,
      then: Yup.object({
        sectionHeading: Yup.string().required("Required"),
        detailedInformation: Yup.array().of(
          Yup.object({
            displayName: Yup.string().required("Required"),
            description: Yup.string().min(9, "Required").required("Required"),
            media: Yup.object({
              value: Yup.object({
                url: Yup.string().required("Required"),
              }),
            }),
          })
        ),
      }),
    }),
    isStatsOverviewActive: Yup.boolean(),
    statsOverview: Yup.object().when("isStatsOverviewActive", {
      is: true,
      then: Yup.object({
        sectionHeading: Yup.string().required("Required"),
        detailedInformation: Yup.array().of(
          Yup.object({
            description: Yup.string().required("Required").min(9, "Required"),
          })
        ),
      }),
    }),
  }),
});
