import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({
  promisesListSuccess: ["response"],
  promisesListFailure: ["error"],
  promisesListRequest: ["data"],

  promisesListCopySuccess: ["response"],
  promisesListCopyFailure: ["error"],
  promisesListCopyRequest: ["data"],

  deletePromiseSuccess: ["response"],
  deletePromiseFailure: ["error"],
  deletePromiseRequest: ["data"],

  bulkDeletePromiseSuccess: ["response"],
  bulkDeletePromiseFailure: ["error"],
  bulkDeletePromiseRequest: ["data"],

  updatePromiseStatusSuccess: ["response"],
  updatePromiseStatusFailure: ["error"],
  updatePromiseStatusRequest: ["data"],

  promiseStatusSuccess: ["response"],
  promiseStatusFailure: ["error"],
  promiseStatusRequest: ["data"],

  updatePromisePrioritySuccess: ["response"],
  updatePromisePriorityFailure: ["error"],
  updatePromisePriorityRequest: ["data"],

  setPromiseListState: ["key", "value"],
});

export const PromisesListTypes = Types;
export default Creators;
export const INITIAL_STATE = {
  promises: [],
  promisesCopy: [],
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  isPriorityUpdated: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  page: 1,
  rowsPerPage: 20,
};

export const promisesListRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const promisesListSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      promises: response.data,
      isLoading: false,
      totalCount: response.totalCount,
    };
  }
};

export const promisesListFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const promisesListCopyRequest = (state: any) => {
  return {
    ...state,
    // isLoading: true,
  };
};

export const promisesListCopySuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      promisesCopy: response.data,
      isLoading: false,
    };
  }
};

export const promisesListCopyFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setPromiseListState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const updatePromiseStatusRequest = (state: any, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updatePromiseStatusSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "promise status updated successfully!",
      isLoading: false,
    };
  }
  // return {
  //   ...state,
  //   isLoading: false,
  //   snackBarError: action.response ? action.response.message : "Failed to load",
  // };
};

export const updatePromiseStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const deletePromiseRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const deletePromiseSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Promise deleted successfully!",
      isLoading: false,
    };
  }
};

export const deletePromiseFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

// Bulk Delete Promises
export const bulkDeletePromiseRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const bulkDeletePromiseSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Promise deleted successfully!",
      isLoading: false,
    };
  }
};

export const bulkDeletePromiseFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

export const promiseStatusRequest = (state: any, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const promiseStatusSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Promise status updated successfully!",
      isLoading: false,
    };
  }
};

export const promiseStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const updatePromisePriorityRequest = (state: any) => {
  return {
    ...state,
    // isLoading: true,
  };
};

export const updatePromisePrioritySuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isPriorityUpdated: true,
      isLoading: false,
    };
  }
};

export const updatePromisePriorityFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PROMISES_LIST_SUCCESS]: promisesListSuccess,
  [Types.PROMISES_LIST_FAILURE]: promisesListFailure,
  [Types.PROMISES_LIST_REQUEST]: promisesListRequest,

  [Types.PROMISES_LIST_COPY_SUCCESS]: promisesListCopySuccess,
  [Types.PROMISES_LIST_COPY_FAILURE]: promisesListCopyFailure,
  [Types.PROMISES_LIST_COPY_REQUEST]: promisesListCopyRequest,

  [Types.DELETE_PROMISE_SUCCESS]: deletePromiseSuccess,
  [Types.DELETE_PROMISE_FAILURE]: deletePromiseFailure,
  [Types.DELETE_PROMISE_REQUEST]: deletePromiseRequest,

  [Types.BULK_DELETE_PROMISE_SUCCESS]: bulkDeletePromiseSuccess,
  [Types.BULK_DELETE_PROMISE_FAILURE]: bulkDeletePromiseFailure,
  [Types.BULK_DELETE_PROMISE_REQUEST]: bulkDeletePromiseRequest,

  [Types.UPDATE_PROMISE_STATUS_SUCCESS]: updatePromiseStatusSuccess,
  [Types.UPDATE_PROMISE_STATUS_FAILURE]: updatePromiseStatusFailure,
  [Types.UPDATE_PROMISE_STATUS_REQUEST]: updatePromiseStatusRequest,

  [Types.PROMISE_STATUS_SUCCESS]: promiseStatusSuccess,
  [Types.PROMISE_STATUS_FAILURE]: promiseStatusFailure,
  [Types.PROMISE_STATUS_REQUEST]: promiseStatusRequest,

  [Types.UPDATE_PROMISE_PRIORITY_REQUEST]: updatePromisePriorityRequest,
  [Types.UPDATE_PROMISE_PRIORITY_SUCCESS]: updatePromisePrioritySuccess,
  [Types.UPDATE_PROMISE_PRIORITY_FAILURE]: updatePromisePriorityFailure,

  [Types.SET_PROMISE_LIST_STATE]: setPromiseListState,
});
