import { MediaUploadComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";

const actionDispatch = (dispatch: any) => {
  return {
    setUploadFileState: (key: any, data: any) => {
      dispatch(FileUploadActions.setUploadFileState(key, data));
    },
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
    fileDeleteRequest: (data: any) => {
      dispatch(FileUploadActions.fileDeleteRequest(data));
    },
  };
};
function ImageUploderOffersAndPromotions() {
  const { values, setFieldValue, errors, touched } =
    useFormikContext<IInitinalValueProjectContent>();

  const { imageDetails, isLoading } = useSelector(
    (state: any) => state.fileUpload
  );

  const { setUploadFileState, fileUploadRequest, fileDeleteRequest } =
    actionDispatch(useDispatch());

  const [componentName, setComponentName] = useState<any>(null);
  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));
  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);

      setFieldValue(`offersAndPromotions.key`, file?.name);
      setFieldValue(`offersAndPromotions.name`, file?.name);
      setFieldValue(`offersAndPromotions.value.size`, file?.size);
      setFieldValue(`offersAndPromotions.value.type`, file?.type);
    }
  };

  const cancelImageHandler = () => {
    fileDeleteRequest(values?.offersAndPromotions?.name);

    setFieldValue(`offersAndPromotions.value.url`, "");
    setFieldValue(`offersAndPromotions.value.size`, "");
    setFieldValue(`offersAndPromotions.name`, "");
    setFieldValue(`offersAndPromotions.key`, "");
  };

  const onChangeHandler = (event: any) => {
    handleImageUpload(event);
  };
  useEffect(() => {
    if (imageDetails) {
      const imageRes = imageDetails;
      setUploadFileState("imageDetails", null);
      if (componentName === "ImageUploderOffersAndPromotions") {
        const offersAndPromotions = { ...values.offersAndPromotions };

        offersAndPromotions.value.url = imageRes?.url;

        setFieldValue(`offersAndPromotions`, offersAndPromotions);
      }
    }
  }, [imageDetails]);
  return (
    <>
      <MediaUploadComponent
        name="ImageUploderOffersAndPromotions"
        onChange={(event: any) => {
          onChangeHandler(event);
          setComponentName(event.target.name);
        }}
        cancelimage={cancelImageHandler}
        previewimage={values?.offersAndPromotions?.value?.url}
        loading={isLoading}
        fieldhelpertext={
          getIn(touched, `offersAndPromotions.value.url`) &&
          getIn(errors, `offersAndPromotions.value.url`)
        }
        disabled={isViewMode}
      />
    </>
  );
}

export default ImageUploderOffersAndPromotions;
