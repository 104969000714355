import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Fragment } from "react";
import PageHeading from "../../../../components/page-heading/PageHeading";
import styles from "./ReferralCardsCreation.module.css";
import ButtonComponent from "../../../../components/ui-elements/button/ButtonComponent";
import FormFieldContainer from "../../../../components/ui-elements/form-field-container/FormFieldContainer";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip, Avatar, Divider } from "@mui/material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  LoadingButtonComponent,
  TextFieldComponent,
} from "../../../../components/ui-elements";
import Imageuploaderdropbox from "../../../../components/ui-elements/image-uploader-dropdown/ImageUploaderDropdown";
import MediaAssets from "../../../../assets";
import { Formik, FieldArray, Form } from "formik";
import * as yup from "yup";
import referralCardCreationConstants from "./ReferralCardsCreationConstant";
import { RouteConstants } from "routes/RouteConstants";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";

interface IReferralCardState {
  referralCardState: {
    displayTitle: string;
    subTitle: string;
    imageUrl: string;
  }[];
}

const ReferralCardCreation = () => {
  let cardIndexCount = 1;
  const navigate = useNavigate();

  const breadcrumbRoutes: any = [
    {
      name: "Marketing Management",
      route: RouteConstants.projectManagementDashboard,
    },
    {
      name: "Referral Cards",
      route: RouteConstants.referralCardList,
    },
    {
      name: "Create New",
      route: RouteConstants.referralCardCreation,
    },
  ];

  const { displayTitleCharacterLength, subTitleCharacterLength } =
    referralCardCreationConstants;

  const initialState: IReferralCardState = {
    referralCardState: [
      {
        displayTitle: "",
        subTitle: "",
        imageUrl: "",
      },
    ],
  };

  const handleSingleCardSave = (values: any, index: any, resetForm?: any) => {};

  let referralValidationSchema = yup.object().shape({
    referralCardState: yup.array().of(
      yup.object().shape({
        displayTitle: yup.string().required(),
        subTitle: yup.string().required(),
        imageUrl: yup.string().required(),
      })
    ),
  });

  const handleCancel = () => {
    navigate(RouteConstants.referralCardList);
  };

  const handleGlobalSave = (values: any) => {};

  const handleFormReset = (values: any, resetForm?: any) => {
    let count = 0;
    for (let i = 0; i <= cardIndexCount; i++) {
      let data = values?.referralCardState[i];
      if (data?.displayTitle && data?.subTitle && data?.imageUrl) {
        count = count + 1;
        if (cardIndexCount === count) {
          setTimeout(() => {
            resetForm();
          }, 500);
        }
      }
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialState}
        validationSchema={referralValidationSchema}
        onSubmit={(values) => handleGlobalSave(values)}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          resetForm,
        }) => {
          return (
            <Form onSubmit={handleSubmit} noValidate>
              <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
              <PageHeading title="Marketing Management" />
              <Container className="main-container">
                <div className="heading-action-holder">
                  <Typography
                    variant="h5"
                    component="h1"
                    className={styles["heading-action-text"]}
                  >
                    Referral cards creation
                  </Typography>
                  <div
                    className={`${styles["heading-btn-wrapper"]} ${styles["display-flex-space-btw"]}`}
                  >
                    <ButtonComponent
                      onClick={handleCancel}
                      className="btn btn-light-outline"
                      aria-label="cancel-btn"
                    >
                      Cancel
                    </ButtonComponent>

                    <ButtonComponent
                      className="btn btn-dark-outline"
                      type="submit"
                      onClick={() => handleFormReset(values, resetForm)}
                    >
                      Save & New
                    </ButtonComponent>

                    <LoadingButtonComponent
                      type="submit"
                      onClick={() => handleFormReset(values, resetForm)}
                      data-testid="form-save-btn"
                      className="btn btn-dark"
                    >
                      Save
                    </LoadingButtonComponent>
                  </div>
                </div>

                <FormFieldContainer>
                  <FieldArray
                    name="referralCardState"
                    render={(arrayHelpers) => (
                      <div>
                        <div className={styles["inner-form-wrapper"]}>
                          <LoadingButtonComponent
                            className="btn btn-dark"
                            data-testid="add-new-card-btn"
                            onClick={() => {
                              arrayHelpers.push({
                                displayTitle: "",
                                subTitle: "",
                                imageUrl: "",
                              });
                              cardIndexCount++;
                            }}
                          >
                            + Add New Referral
                          </LoadingButtonComponent>
                        </div>

                        {values.referralCardState &&
                        values.referralCardState.length > 0
                          ? values.referralCardState.map(
                              (element, index: any) => (
                                <div key={index} data-testid="referral-card">
                                  <div
                                    className={styles["card-form-container"]}
                                  >
                                    <FormFieldContainer>
                                      <div className="heading-action-holder">
                                        <Typography
                                          variant="h5"
                                          component="h1"
                                          className={styles["card-count"]}
                                          data-testid="card-index-count"
                                        >
                                          Card {index + 1}
                                        </Typography>

                                        <div className="heading-action-holder">
                                          <LoadingButtonComponent
                                            className="btn btn-dark"
                                            type="submit"
                                            onClick={() =>
                                              handleSingleCardSave(
                                                values,
                                                index,
                                                resetForm
                                              )
                                            }
                                          >
                                            Save
                                          </LoadingButtonComponent>

                                          {cardIndexCount > 1 && (
                                            <span
                                              onClick={() => cardIndexCount--}
                                            >
                                              <Tooltip
                                                title="Delete"
                                                placement="top"
                                              >
                                                <IconButton
                                                  label-id="deleteIcon"
                                                  className={
                                                    styles["delete-btn-icon"]
                                                  }
                                                  aria-label="delete"
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                >
                                                  <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                                                </IconButton>
                                              </Tooltip>
                                            </span>
                                          )}
                                        </div>
                                      </div>

                                      <Divider className={styles["divider"]} />

                                      <div
                                        className={styles["textfield-section"]}
                                      >
                                        <div
                                          className={
                                            styles["display-title-field"]
                                          }
                                        >
                                          <div>
                                            <TextFieldComponent
                                              placeholder="Enter"
                                              name={`referralCardState.${index}.displayTitle`}
                                              fieldtitle="Display Title *"
                                              helpericon={MediaAssets.ic_info}
                                              helpericoncontent="Display Title text content"
                                              inputProps={{
                                                maxLength:
                                                  displayTitleCharacterLength,
                                                "data-testid": "display-title",
                                              }}
                                              onBlur={handleBlur}
                                              value={element.displayTitle}
                                              required
                                              onChange={handleChange}
                                              error={
                                                (touched?.referralCardState &&
                                                touched?.referralCardState[
                                                  index
                                                ] &&
                                                touched?.referralCardState[
                                                  index
                                                ].displayTitle
                                                  ? true
                                                  : false) &&
                                                (element.displayTitle.length > 0
                                                  ? false
                                                  : true)
                                              }
                                              fieldhelpertext={
                                                (touched?.referralCardState &&
                                                touched?.referralCardState[
                                                  index
                                                ] &&
                                                touched?.referralCardState[
                                                  index
                                                ].displayTitle
                                                  ? "Required"
                                                  : "") &&
                                                (element.displayTitle.length >
                                                  0 &&
                                                element.displayTitle.length < 2
                                                  ? "Too Short"
                                                  : element.displayTitle
                                                      .length > 0
                                                  ? ""
                                                  : "Required")
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div
                                          className={styles["sub-title-field"]}
                                        >
                                          <div>
                                            <TextFieldComponent
                                              required
                                              placeholder="Enter"
                                              name={`referralCardState.${index}.subTitle`}
                                              fieldtitle="Sub Title *"
                                              helpericon={MediaAssets.ic_info}
                                              helpericoncontent="Sub Title Text Content"
                                              inputProps={{
                                                maxLength:
                                                  subTitleCharacterLength,
                                                "data-testid":
                                                  "sub-title-field",
                                              }}
                                              onBlur={handleBlur}
                                              value={element.subTitle}
                                              onChange={handleChange}
                                              error={
                                                (touched?.referralCardState &&
                                                touched?.referralCardState[
                                                  index
                                                ] &&
                                                touched?.referralCardState[
                                                  index
                                                ].subTitle
                                                  ? true
                                                  : false) &&
                                                (element.subTitle.length > 0
                                                  ? false
                                                  : true)
                                              }
                                              fieldhelpertext={
                                                (touched?.referralCardState &&
                                                touched?.referralCardState[
                                                  index
                                                ] &&
                                                touched?.referralCardState[
                                                  index
                                                ].subTitle
                                                  ? "Required"
                                                  : "") &&
                                                (element.subTitle.length > 0 &&
                                                element.subTitle.length < 2
                                                  ? "Too Short"
                                                  : element.subTitle.length > 0
                                                  ? ""
                                                  : "Required")
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className={
                                          styles["referral-media-wrapper"]
                                        }
                                      >
                                        <div
                                          className={`${styles["display-flex-space-btw"]} ${styles["referral-media-text"]}`}
                                        >
                                          <Typography
                                            variant="h6"
                                            component="h1"
                                          >
                                            Referral Media
                                          </Typography>

                                          <Typography
                                            variant="h6"
                                            component="h1"
                                          >
                                            <Tooltip
                                              title={"Referral Text content"}
                                              placement="top"
                                            >
                                              <Avatar
                                                className={styles["info-icon"]}
                                                src={MediaAssets.ic_info}
                                                variant="rounded"
                                              />
                                            </Tooltip>
                                          </Typography>
                                        </div>

                                        <div
                                          id="photo-uploader"
                                          data-testid="image-upload-field"
                                          className={
                                            styles["drag-and-drop-section"]
                                          }
                                        >
                                          <Imageuploaderdropbox
                                            aria-label="image-upload-drag-drop"
                                            onDrop={handleChange}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                              let imageUrl =
                                                URL.createObjectURL(
                                                  e.target.files[0]
                                                );
                                              setFieldValue(
                                                `referralCardState.${index}.imageUrl`,
                                                imageUrl
                                              );
                                            }}
                                            previewimage={element.imageUrl}
                                            cancelimage={() => {
                                              setFieldValue(
                                                `referralCardState.${index}.imageUrl`,
                                                ""
                                              );
                                            }}
                                            fieldhelpertext={
                                              (touched?.referralCardState &&
                                              touched?.referralCardState[
                                                index
                                              ] &&
                                              touched?.referralCardState[index]
                                                .imageUrl
                                                ? "Required"
                                                : "") &&
                                              (element.imageUrl.length > 0
                                                ? ""
                                                : "Required")
                                            }
                                          />
                                        </div>
                                      </div>
                                    </FormFieldContainer>
                                  </div>
                                </div>
                              )
                            )
                          : ""}
                      </div>
                    )}
                  />
                </FormFieldContainer>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default ReferralCardCreation;
