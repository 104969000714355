import { MediaUploadComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";

const actionDispatch = (dispatch: any) => {
  return {
    setUploadFileState: (key: any, data: any) => {
      dispatch(FileUploadActions.setUploadFileState(key, data));
    },
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
    fileDeleteRequest: (data: any) => {
      dispatch(FileUploadActions.fileDeleteRequest(data));
    },
  };
};

function ProjectCoverImagesUploader(props: any) {
  const { values, setFieldValue, errors, touched } =
    useFormikContext<IInitinalValueProjectContent>();
  const { Id, name, mediaWidth, mediaHeight } = props;

  const { imageDetails, isLoading } = useSelector(
    (state: any) => state.fileUpload
  );
  const { setUploadFileState, fileUploadRequest, fileDeleteRequest } =
    actionDispatch(useDispatch());
  const [clickedImageUploaderName, setClickedImageUploaderName] = useState("");
  const [clickedImageUploaderId, setClickedImageUploaderId] = useState(0);
  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);

      setFieldValue(`projectCoverImages.${event.target.name}.key`, file?.name);
      setFieldValue(`projectCoverImages.${event.target.name}.name`, file?.name);
      setFieldValue(
        `projectCoverImages.${event.target.name}.value.size`,
        file?.size
      );
      setFieldValue(
        `projectCoverImages.${event.target.name}.value.mediaType`,
        file?.type
      );
      setFieldValue(
        `projectCoverImages.${event.target.name}.value.height`,
        mediaHeight
      );
      setFieldValue(
        `projectCoverImages.${event.target.name}.value.width`,
        mediaWidth
      );
    }
  };
  const cancelImageHandler = (event: any) => {
    // setClickedImageUploaderName(event.target.name);
    const imageName: any =
      values?.projectCoverImages[clickedImageUploaderName]?.name;

    fileDeleteRequest(
      values?.projectCoverImages[clickedImageUploaderName]?.name
    );

    setFieldValue(`projectCoverImages.${clickedImageUploaderName}.name`, "");
    setFieldValue(`projectCoverImages.${clickedImageUploaderName}.key`, "");

    setFieldValue(
      `projectCoverImages.${clickedImageUploaderName}.value.size`,
      ""
    );
    setFieldValue(
      `projectCoverImages.${clickedImageUploaderName}.value.url`,
      ""
    );
    setFieldValue(
      `projectCoverImages.${clickedImageUploaderName}.value.mediaType`,
      ""
    );
    setFieldValue(
      `projectCoverImages.${clickedImageUploaderName}.value.height`,
      ""
    );
    setFieldValue(
      `projectCoverImages.${clickedImageUploaderName}.value.width`,
      ""
    );
  };

  useEffect(() => {
    if (imageDetails) {
      const imageRes = imageDetails;
      setUploadFileState("imageDetails", null);

      if (clickedImageUploaderId !== 0 && clickedImageUploaderId == Id) {
        const projectCoverImages: any = { ...values.projectCoverImages };
        projectCoverImages[clickedImageUploaderName] = {
          ...projectCoverImages[clickedImageUploaderName],
          value: projectCoverImages[clickedImageUploaderName]?.value,
        };

        projectCoverImages[clickedImageUploaderName].value = {
          ...projectCoverImages[clickedImageUploaderName].value,
          url: imageRes?.url,
        };
        setFieldValue(`projectCoverImages`, projectCoverImages);
        setClickedImageUploaderId(0);
        // setClickedImageUploaderName("");
      }
    }
  }, [imageDetails]);

  const onChangeHandler = (event: any) => {
    setClickedImageUploaderId(Id);
    setClickedImageUploaderName(event.target.name);
    handleImageUpload(event);
  };

  return (
    <>
      <MediaUploadComponent
        name={name}
        // inputId={name}
        onChange={onChangeHandler}
        cancelimage={() => cancelImageHandler(Id)} //won't gives an event so use id prop.
        previewimage={values?.projectCoverImages[name]?.value?.url}
        loading={clickedImageUploaderId === Id ? isLoading : false}
       
         mediaHeight={mediaHeight}
         mediaWidth={mediaWidth}
         disabled={isViewMode}
      />
    </>
  );
}

export default ProjectCoverImagesUploader;
