import {
  Avatar,
  Box,
  Typography,
  Link,
  Button,
} from "@mui/material";
import { styled } from "@mui/material";
import MediaAssets from "../../../../assets";
import AlertHelperText from "../../../../components/ui-elements/alert-helper-text/AlertHelperText";
import styles from "./ImageUploadMediaDialog.module.css";
import CachedIcon from "@mui/icons-material/Cached";

interface IImageUploadDragDrop {
  fieldhelpertext: any;
  onDrop: (event: any) => void;
  onChange: (event: any) => void;
  previewimage?: any;
  name?: string;
  value?: string;
  cancelimage: () => void;
  onBlur: (event: any) => void;
}

const DragAndDropStyle = styled(Box)({
  border: "3px dotted #9795af",
  borderRadius: "2px",
  width: "20rem",
  height: "15rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  "& .innerButton": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const ImageUploadDragDrop = (props: IImageUploadDragDrop) => {
  return (
    <div data-testid="media-upload-drag-drop">
      {props.previewimage?.length > 0 ? (
        <Box className={styles["image-dropdown-section"]}>
          <Avatar
            className={styles["preview-image-section"]}
            src={`${props.previewimage}`}
            alt={props.name}
          />
          <Button className={styles["replace-button-wrapper"]}>
            <label
              className={styles["display-flex-space-btw"]}
              htmlFor="contained-button-file"
            >
              <input
                accept=".jpg,.png,.jpeg"
                id={`contained-button-file`}
                name={props.name}
                type="file"
                value={props.value}
                onBlur={props.onBlur}
                className={styles["input-display-none"]}
                onChange={(event: any) => {
                  props.onChange(event);
                }}
                onDrop={(event: any) => {
                  props.onDrop(event);
                }}
              />
              <CachedIcon className={styles["cached-icon"]} />
              <Typography
                className={styles["replace-text"]}
                variant="body1"
                component="h5"
              >
                Replace
              </Typography>
            </label>
          </Button>
        </Box>
      ) : (
        <DragAndDropStyle>
          <Box component="label" htmlFor={`contained-button-file`}>
            <input
              data-testid="media-input-field"
              accept=".jpg,.png,.jpeg"
              id={`contained-button-file`}
              name={props.name}
              type="file"
              value={props.value}
              onBlur={props.onBlur}
              className={styles["image-upload-input"]}
              onChange={(event: any) => {
                props.onChange(event);
              }}
              onDrop={(event: any) => {
                props.onDrop(event);
              }}
            />
            <Box className="innerButton">
              <Avatar
                className={styles["upload-icon"]}
                src={MediaAssets.ic_upload}
              />
              <Box color="#6e6b8f" fontSize="16px">
                Drag & Drop here
              </Box>
              <Box color="#9b9aa8" fontSize="16px">
                Or
              </Box>
              <Link>
                <Typography className={styles["browse-files-link"]}>
                  Browser files
                </Typography>
              </Link>
              <Box color="#9795af" fontSize="12px">
                Max file size : 2 MB
              </Box>
              {props.fieldhelpertext ? (
                <AlertHelperText color="error" severity="error">
                  {props.fieldhelpertext || ""}
                </AlertHelperText>
              ) : (
                <Typography
                  variant="body2"
                  visibility={"visible"}
                  lineHeight={1.5}
                ></Typography>
              )}
            </Box>
          </Box>
        </DragAndDropStyle>
      )}
    </div>
  );
};
export default ImageUploadDragDrop;
