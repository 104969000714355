import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";

import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { GetProjectCategoriesAction } from "redux-container/project-management/ProjectCategoryRedux";


export function* getProjectCategories(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.getProjectCategories, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GetProjectCategoriesAction.getProjectCategoriesSuccess(response.data));
        } else {
            yield put(GetProjectCategoriesAction.getProjectCategoriesFailure(response.data));
        }
    } catch (error) {
        yield put(GetProjectCategoriesAction.getProjectCategoriesFailure(error));
    }
}

//ADD NEW CATEGORY 

export function* addNewProjectCategory(api: any, action: any): any {
    try {
        const response = yield call(api.addNewCategory, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GetProjectCategoriesAction.addProjectCategorySuccess(response.data));
        } else {
            yield put(GetProjectCategoriesAction.addProjectCategoryFailure(response.data));
        }
    } catch (error) {
        yield put(GetProjectCategoriesAction.addProjectCategoryFailure(error));
    }
}

//Delete category

export function* deleteProjectCategory(api: any, action: any): any {
    try {
        const response = yield call(api.deleteCategory, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {

            yield put(GetProjectCategoriesAction.deleteProjectCategorySuccess(response.data));
        } else {
            yield put(GetProjectCategoriesAction.deleteProjectCategoryFailure(response.data));
        }
    } catch (error) {
        yield put(GetProjectCategoriesAction.deleteProjectCategoryFailure(error));
    }
}

//put category 

export function* putProjectCategory(api: any, action: any): any {

    try {
        const response = yield call(api.putCategory, action.id, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GetProjectCategoriesAction.putProjectCategorySuccess(response.data));
        } else {
            yield put(GetProjectCategoriesAction.putProjectCategoryFailure(response.data));
        }
    } catch (error) {
        yield put(GetProjectCategoriesAction.putProjectCategoryFailure(error));
    }
}

//bulk status change 

export function* statusUpdateProjectCategory(api: any, action: any): any {

    try {
        const response = yield call(api.bulkStatusProjectCategory,action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GetProjectCategoriesAction.bulkStatusUpdateProjectCategorySuccess(response.data));
        } else {
            yield put(GetProjectCategoriesAction.bulkStatusUpdateProjectCategoryFailure(response.data));
        }
    } catch (error) {
        yield put(GetProjectCategoriesAction.bulkStatusUpdateProjectCategoryFailure(error));
    }
}
//bulk delete for category delete
export function* bulkDeleteProjectCategory(api: any, action: any): any {

    try {
        const response = yield call(api.deleteFaqs, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GetProjectCategoriesAction.bulkDeleteProjectCategorySuccess(response.data));
        } else {
            yield put(GetProjectCategoriesAction.bulkDeleteProjectCategoryFailure(response.data));
        }
    } catch (error) {
        yield put(GetProjectCategoriesAction.bulkDeleteProjectCategoryFailure(error));
    }
}

//priority change for category
export function* changeCategoryPriority(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.changeFaqPriority, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GetProjectCategoriesAction.changeCategoryPrioritySuccess(response.data));
        } else {
            yield put(GetProjectCategoriesAction.changeCategoryPriorityFailure(response.data));
        }
    } catch (error) {
        yield put(GetProjectCategoriesAction.changeCategoryPriorityFailure(error));
    }
}