import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";


export function* getAuditsSaga(api: any, action: any): SagaIterator {
    try {
      const response = yield call(api.getAuditsServise, action.data);
  
      if (response.status === HttpResponseTypeEnum.Success) {
        yield put(AuditActions.getAuditLogSuccess(response));
      } else {
        yield put(AuditActions.getAuditLogFailure(response));
      }
    } catch (error) {
      yield put(AuditActions.getAuditLogFailure(error));
    }
  }
export function* postAuditsSaga(api: any, action: any): SagaIterator {
    try {
      const response = yield call(api.postAuditsServise, action.data);
  
      if (response.status === HttpResponseTypeEnum.Success) {
        yield put(AuditActions.postAuditLogSuccess(response));
      } else {
        yield put(AuditActions.postAuditLogFailure(response));
      }
    } catch (error) {
      yield put(AuditActions.postAuditLogFailure(error));
    }
  }