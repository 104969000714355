import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getResourceDashboardSuccess: ["response"],
  getResourceDashboardFailure: ["error"],
  getResourceDashboardRequest: ["params"],

  setResourceDashboardState: ["key", "value"],
});

export const ResourceDashboardTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  dashboard: null,
  error: null,
  isLoading: false,
  snackBarError: "",
  isGraphDataFetched: false,
};

/* Get Resource Dashboard */
export const getResourceDashboardRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getResourceDashboardSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      dashboard: response.data,
      isLoading: false,
      isGraphDataFetched: true,
    };
  }
};

export const getResourceDashboardFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setResourceDashboardState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RESOURCE_DASHBOARD_SUCCESS]: getResourceDashboardSuccess,
  [Types.GET_RESOURCE_DASHBOARD_FAILURE]: getResourceDashboardFailure,
  [Types.GET_RESOURCE_DASHBOARD_REQUEST]: getResourceDashboardRequest,

  [Types.SET_RESOURCE_DASHBOARD_STATE]: setResourceDashboardState,
});
