import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  NotificationActionTypeEnum,
  NotificationActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import Button from "@mui/material/Button";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Checkbox from "@mui/material/Checkbox";
import ConfirmationDialog from "../../../components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Loader from "../../../components/loader/Loader";
import MediaAssets from "../../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import Retry from "../../../components/retry/Retry";
import { RouteConstants } from "../../../routes/RouteConstants";
import { SelectChangeEvent } from "@mui/material/Select";
import { TestimonialStatusTypeEnumUtils } from "enumerations/StatusTypeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import NotificationActions from "../../../redux-container/notification-management/NotificationsRedux";
import { exportAsExcel } from "utils/ExportFileUtils";
import UserActions from "../../../redux-container/users/UsersRedux";
import { Stack } from "@mui/material";
import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";

const actionDispatch = (dispatch: any) => {
  return {
    getNotifications: (params: any) =>
      dispatch(NotificationActions.getNotificationsRequest(params)),
    changeNotificationStatus: (data: any) =>
      dispatch(NotificationActions.changeNotificationStatusRequest(data)),
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),
    setNotificationsState: (key: any, data: any) =>
      dispatch(NotificationActions.setNotificationsState(key, data)),
  };
};

const NotificationList = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const actions =
    NotificationActionTypeEnumUtils.getNotificationActionTypeEnum();
  const statuses =
    TestimonialStatusTypeEnumUtils.getTestimonialStatusTypeEnums();

  const {
    getNotifications,
    changeNotificationStatus,
    getUsers,
    setNotificationsState,
  } = actionDispatch(useDispatch());

  const notifications = useSelector(
    (state: any) => state?.notifications.notifications
  );
  const totalCount = useSelector(
    (state: any) => state?.notifications.totalCount
  );
  const users = useSelector((state: any) => state?.users?.usersForFilters);
  const isLoading = useSelector(
    (state: any) => state?.notifications?.isLoading
  );
  const error = useSelector((state: any) => state?.notifications?.error);
  const isSuccess = useSelector(
    (state: any) => state?.notifications?.isSuccess
  );
  const message = useSelector((state: any) => state?.notifications?.message);
  const shouldOpenFlashDialog = useSelector(
    (state: any) => state?.notifications?.shouldOpenFlashDialog
  );
  const { page, rowsPerPage } = useSelector((state: any) => {
    return {
      page: state?.notifications?.page,
      rowsPerPage: state?.notifications?.rowsPerPage,
    };
  });
  const [selectedNotifications, setSelectedNotifications] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCreatedBy, setCreatedBy] = useState<any>(null);
  const [selectedModifiedBy, setModifiedBy] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const breadcrumbRoutes: any = [
    {
      name: "Notification Management",
      route: RouteConstants.notificationManagementDashboard,
    },
    {
      name: "Notifications",
      route: RouteConstants.notificationList,
    },
  ];

  //table headings (heading objects with name,boolean key for whether it is sorting column or not,and sorting key for an API call ).
  const tableHeadings = [
    { heading: "Title" },
    { heading: "Notification Message" },
    { heading: "Created On" },
    { heading: "Published On" },
    // { heading: "Modified On" },
    // { heading: "Status" },
    { heading: "Action" },
  ];

  useEffect(() => {
    const params = {};
    // loadData(params);
    getFilterDataFromDashboard();
  }, []);

  //Loading Data (Customers data) while mounting and
  // every time after User applies any filters (Search key,Updated by,Created By,Page,and Index.)
  useEffect(() => {
    const params = {
      index: page,
      size: rowsPerPage,
      // status: location?.state?.statusType
      //   ? location?.state?.statusType
      //   : selectedStatus,
      updatedBy: selectedModifiedBy,
      createdBy: selectedCreatedBy?.id,
      searchKey: searchKey,
    };
    if (
      searchKey ||
      selectedStatus ||
      selectedCreatedBy ||
      rowsPerPage ||
      page ||
      selectedModifiedBy
    ) {
      loadData(params);
      clearSelected();
    }
  }, [
    page,
    rowsPerPage,
    searchKey,
    // selectedStatus,
    selectedCreatedBy,
    selectedModifiedBy,
  ]);

  const loadData = (params: any) => {
    getNotifications(params);
    // getUsers({});
  };

  //setting page and rowsPerPage to initial value if user Navigated to the list from Side menu.
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setNotificationsState("page", 1);
      setNotificationsState("rowsPerPage", 20);
    }

    return () => {};
  }, []);
  const getFilterDataFromDashboard = () => {
    if (location && location.state && location.state.statusType) {
      setSelectedStatus(location.state.statusType as string);
    }
    navigate(location.pathname, { replace: true });
  };

  //handle change function for page in Pagination.
  const handleChangePage = (event: unknown, newPage: number) => {
    setNotificationsState("page", newPage + 1);
  };

  //handle change function for number of elements per Page in Pagination.
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNotificationsState("rowsPerPage", Number(event.target.value));
    setNotificationsState("page", 1);
  };

  //handle change function for Action Selection (only one "Export" Action available).
  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case NotificationActionTypeEnum.Export:
        handleExportAsExcel();
        break;
    }
  };

  const handleExportAsExcel = () => {
    if (selectedNotifications.length === 0) {
      alert("You have not selected any Notifications");
      return;
    }

    let exportingNotifications: any = [];
    if (notifications.length) {
      selectedNotifications.map((selectedNotification: any) => {
        let localSelectedItem = notifications.find(
          (item: any) => item.id == selectedNotification
        );
        exportingNotifications.push({
          Title: localSelectedItem.notificationDescription?.title
            ? localSelectedItem.notificationDescription?.title
            : "----",
          Notification_Message: localSelectedItem.notificationDescription?.body
            ? localSelectedItem.notificationDescription?.body
            : "----",
          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Published_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
        });
      });
    }

    exportAsExcel(exportingNotifications, "Notifications", "Sheet1");
  };

  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 1) {
      setNotificationsState("page", 1);
    }
  };

  //Resetting Redux State to its initial values after Showing "FlashMessageDialog".
  const resetState = () => {
    setNotificationsState("message", null);
    setNotificationsState("error", null);
    setNotificationsState("isSuccess", false);
  };

  const handleCloseFlashMessageDialog = () => {
    resetState();
    clearSelected();
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = notifications.map((data: any) => data.id);
      setSelectedNotifications(newSelecteds);
      return;
    }
    setSelectedNotifications([]);
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedNotifications.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedNotifications, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedNotifications.slice(1));
    } else if (selectedIndex === selectedNotifications?.length - 1) {
      newSelected = newSelected.concat(selectedNotifications.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedNotifications.slice(0, selectedIndex),
        selectedNotifications.slice(selectedIndex + 1)
      );
    }

    setSelectedNotifications(newSelected);
  };

  const clearSelected = () => {
    setSelectedNotifications([]);
  };

  //function to Reset the filters applied by User.
  const clearFilters = () => {
    setSelectedStatus("");
    setCreatedBy(null);
    setModifiedBy("");
    setSearchKey("");
  };

  const isSelected = (id: any) => selectedNotifications.indexOf(id) !== -1;

  const createNotification = () => {
    navigate(RouteConstants.notificationCreation, {
      state: { isViewMode: false, notification: null },
    });
  };

  //navigation to view Notification Detail.
  const viewNotification = (notification: any) => {
    const dataToView = {
      isViewMode: true,
      notification: notification,
    };
    navigate(`${RouteConstants.notificationCreation}`, {
      state: dataToView,
    });
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_testimonial}
        altText=""
        message="You haven't created any Notifications yet. Create your first Notification."
        btnTitle="CREATE NOTIFICATIONS"
        btnAction={createNotification}
      />
    );
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Published Notifications
            {`(${
              totalCount
                ? totalCount
                : notifications?.length
                ? notifications?.length
                : 0
            })`}
            {selectedNotifications.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedNotifications?.length} Notification(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
              >
                {actions.map((action: any) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>

            <Button
              variant="contained"
              onClick={createNotification}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New
            </Button>
          </div>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              {/* <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedStatus}
                    label="Status"
                    onChange={handleStatusChange}
                  >
                    {statuses.map((status: any) => {
                      return (
                        <MenuItem value={status.value} key={status.value}>
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid> */}

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedCreatedBy}
                    label="Created By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setCreatedBy(newValue);
                      if (page !== 0) {
                        setNotificationsState("page", 1);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              {/* <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedModifiedBy}
                    label="Modified By"
                    onChange={handleModifiedByChange}
                  >
                    {users?.map((user: any) => {
                      return (
                        <MenuItem value={user.id} key={user.id}>
                          {user.firstName}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid> */}

              {selectedCreatedBy !== null ||
              selectedStatus !== "" ||
              selectedModifiedBy !== "" ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Stack>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell style={{ minWidth: 50 }} padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedNotifications?.length > 0 &&
                      selectedNotifications?.length < notifications?.length
                    }
                    checked={
                      notifications?.length > 0 &&
                      selectedNotifications?.length === notifications?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all notifications",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {notifications && notifications.length > 0 ? (
              <TableBody>
                {notifications.map((notification: any) => {
                  const isItemSelected = isSelected(notification.id);
                  return (
                    <TableRow
                      key={notification.id}
                      className="table-data-row"
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ minWidth: 50 }} padding="checkbox">
                        <Checkbox
                          onClick={(event) =>
                            handleClick(event, notification.id)
                          }
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 150, maxWidth: 220 }}
                        className="table-data-text"
                      >
                        {notification.notificationDescription?.title
                          ? notification.notificationDescription?.title
                          : "-"}
                      </TableCell>

                      <TableCell
                        style={{
                          minWidth: 150,
                          maxWidth: 300,
                          wordBreak: "break-all",
                        }}
                        className="table-data-text"
                        align={"left"}
                      >
                        {notification.notificationDescription?.body
                          ? notification.notificationDescription?.body
                          : "-"}
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 100 }}
                        className="table-data-text"
                      >
                        <div>
                          {notification.createdAt
                            ? formatDateTime(
                                notification.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell
                        style={{ minWidth: 140 }}
                        className="table-data-text"
                      >
                        {notification.createdAt
                          ? formatDateTime(
                              notification.createdAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "-"}
                      </TableCell>

                      <TableCell style={{ minWidth: 120 }}>
                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewNotification(notification)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Notifications Records</div>
            )}
          </Table>
        </TableContainer>

        {!searchKey && notifications && notifications.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[20, 40, 50]}
            component="div"
            count={
              totalCount
                ? totalCount
                : notifications?.length
                ? notifications?.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    if (
      (notifications?.length > 0 || selectedCreatedBy !== null) &&
      isLoading === false
    ) {
      return renderTable();
    } else if (
      (notifications?.length === 0 || notifications?.length === undefined) &&
      selectedCreatedBy === null &&
      isLoading === false
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };
  const shouldProvidePlaceholder = () => {
    if (
      (notifications?.length > 0 || selectedCreatedBy !== null) &&
      isLoading === false
    ) {
      return true;
    }
    return false;
  };
  return (
    <Fragment>
      <PageHeading title="Notification Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder={shouldProvidePlaceholder() ? "Search Notifications" : ""}
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>

      <FlashMessageDialog
        shouldOpen={shouldOpenFlashDialog}
        content={message ? message : "Something Went Wrong!"}
        isSuccess={false}
        cancelHandler={handleCloseFlashMessageDialog}
      />
    </Fragment>
  );
};

export default NotificationList;
