import { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Paper from "@mui/material/Paper";
import { SelectChangeEvent } from "@mui/material/Select";
import SwitchComponent from "components/ui-elements/switch/SwitchComponent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Avatar,
  Checkbox,
  ClickAwayListener,
  Popper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Loader from "components/loader/Loader";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useDispatch, useSelector } from "react-redux";
import { exportAsExcel } from "utils/ExportFileUtils";
import Retry from "components/retry/Retry";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Box } from "@mui/system";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import {
  ActionTypeEnumInsight,
  ActionTypeEnumUtilsInsight,
} from "enumerations/ActionTypeEnum";
import {
  StatusTypeEnumInsight,
  StatusTypeEnumUtilsInsight,
} from "enumerations/StatusTypeEnum";
import { getYoutubeVideoId } from "utils/ValidationUtils";
import { RouteConstants } from "routes/RouteConstants";
import PageHeading from "components/page-heading/PageHeading";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import MediaAssets from "assets";
import NoDataRecord from "components/no-data-record/NoDataRecord";
import { CustomerActions } from "redux-container/customer-management/CustomersListAndDetailsRedux";
import { CustomerEnumsUtils } from "enumerations/ProjectTypeEnums";
import { SortingOrder } from "enumerations/SortingOrderTypeEnum";

const CustomTableContainer = styled(Box)`
  &&.MuiTableContainer-root {
    overflow-x: none;
  }
  && .MuiOutlinedInput-root {
    margin-right: 55px;
  }
`;
export const BreadCrumbsWrapper = styled(Box)`
  && .MuiOutlinedInput-root {
    margin-right: 50px;
  }
  .MuiButton-root {
    /* margin-right: 6px; */
  }
`;
const FilterContainer = styled(Grid)`
  .MuiGrid-container {
    margin-top: 30px;
  }
`;
const tableHeadings = [
  { heading: "Type" },
  { heading: "Launch Display Name" },
  { heading: "CRM Project Name" },
  { heading: "SKU Name" },
  { heading: "Land ID" },
  { heading: "Price", isSortingColumn: true, sortingKey: "14" },
  { heading: "Area(Sqft)", isSortingColumn: true, sortingKey: "13" },
  { heading: "Owned Since" },
  { heading: "OEA" },
  { heading: "EA" },
  { heading: "Amount Paid" },
  { heading: "Amount Pending" },
  { heading: "Action Item" },
  { heading: "FM Status" },
  { heading: "Actions" },
];

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerInvestmentSummary: async (customerId: any) => {
      dispatch(CustomerActions.getCustomerInvestmentSummaryRequest(customerId));
    },
  };
};

const CustomerPortpolioInvestmentDetails = () => {
  const navigate = useNavigate();
  const projectTypes = CustomerEnumsUtils.getActionsEnums();
  const location: any = useLocation();
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [selectedProjectType, setSelectedProjectType] = useState<any>("");
  const [showAccordionId, setShowAccordionId] = useState<number | null>(0);
  const [customerId, setCustomerId] = useState(
    location?.state?.customerData?.id
  );
  const [columnSort, setColumnSort] = useState({
    sortingKey: "",
    sortingPriority: "",
    isActiveSortingColumn: "",
  });
  const { customerInvestmentSummary, isLoading } = useSelector((state: any) => {
    return {
      customerInvestmentSummary:
        state?.customerListAndDetails?.customerSummary?.projects,
      isLoading: state?.customerListAndDetails?.isLoading,
    };
  });
  const publishedProjects = useSelector(
    (state: any) => state?.projectList?.data?.data
  );
  const { getCustomerInvestmentSummary } = actionDispatch(useDispatch());

  useEffect(() => {
    const params = {
      cmsId: customerId,
      projectName: selectedProject?.launchName,
      bookingStatus: selectedProjectType,
      sortingKey: columnSort.sortingKey,
      sortingPriority: columnSort.sortingPriority,
    };
    getCustomerInvestmentSummary(params);
    return () => {};
  }, [selectedProject?.launchName, selectedProjectType, columnSort]);

  const viewInsight = (investmentDetailsOnAProject: any) => {
    navigate(`${RouteConstants.customerInvestmentDetails}`, {
      state: {
        investmentDetailsOnAProject,
        customerData: location?.state?.customerData,
      },
    });
  };
  const handleClearButton = (): boolean => {
    if (selectedProject === null && selectedProjectType === "") {
      return false;
    }
    return true;
  };
  const handleResetFilter = () => {
    setSelectedProject(null);
    setSelectedProjectType("");
  };

  const renderTable = () => {
    return (
      <Fragment>
        <>
          <FilterContainer container spacing={1} className="filter-container">
            <Grid container spacing={2} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={publishedProjects}
                    getOptionLabel={(option: any) => option?.launchName}
                    label="Project Name"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedProject(newValue);
                    }}
                    value={selectedProject}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedProjectType}
                    label="Type"
                    onChange={(event) =>
                      setSelectedProjectType(event.target.value as string)
                    }
                  >
                    {projectTypes.map((projectType) => {
                      return (
                        <MenuItem
                          value={projectType.value}
                          key={projectType.value}
                        >
                          {projectType.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              {handleClearButton() ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={handleResetFilter}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </FilterContainer>
          {isLoading ? (
            <Loader />
          ) : (
            <CustomTableContainer>
              <TableContainer
                component={Paper}
                className={`table-container`}
                style={{ height: window.innerHeight - 475 }}
              >
                <Table style={{ position: "relative" }}>
                  <TableHead>
                    <TableRow className="table-header-row">
                      {tableHeadings?.map((item, index) => (
                        <TableCell key={index} className="table-header-text">
                          {item.heading}

                          {item.isSortingColumn ? (
                            <>
                              {columnSort.sortingPriority ===
                                SortingOrder.Descending &&
                              columnSort.isActiveSortingColumn ===
                                item.heading ? (
                                <IconButton
                                  onClick={() => {
                                    setColumnSort({
                                      sortingKey: item.sortingKey,
                                      sortingPriority: SortingOrder.Ascending,
                                      isActiveSortingColumn: item.heading,
                                    });
                                  }}
                                >
                                  <ArrowDropUpIcon
                                    fontSize={"large"}
                                    style={{
                                      position: "relative",
                                      // top: "16px",
                                      right: "10px",
                                      fontSize: "3rem",
                                      color:
                                        columnSort.isActiveSortingColumn ===
                                        item.heading
                                          ? "#676ac0"
                                          : "rgb(0 0 0 / 87%)",
                                    }}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() => {
                                    setColumnSort({
                                      sortingKey: item.sortingKey,
                                      sortingPriority: SortingOrder.Descending,
                                      isActiveSortingColumn: item.heading,
                                    });
                                  }}
                                >
                                  <ArrowDropDownIcon
                                    fontSize={"large"}
                                    style={{
                                      position: "relative",
                                      // top: "10px",
                                      right: "10px",
                                      fontSize: "3rem",
                                      color:
                                        columnSort.isActiveSortingColumn ===
                                        item.heading
                                          ? "#676ac0"
                                          : "rgb(0 0 0 / 87%)",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </>
                          ) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {customerInvestmentSummary?.length > 0 ? (
                    <TableBody>
                      {customerInvestmentSummary?.map(
                        (investmentDetailsOnAProject: any, index: any) => {
                          return (
                            <TableRow
                              key={investmentDetailsOnAProject.id}
                              className="table-data-row"
                            >
                              <TableCell
                                padding="checkbox"
                                style={{ minWidth: 120 }}
                              >
                                {investmentDetailsOnAProject?.investment
                                  ?.isBookingComplete
                                  ? "Completed"
                                  : "Ongoing"}
                              </TableCell>
                              <TableCell
                                className="table-data-text"
                                style={{ minWidth: 250 }}
                              >
                                {`#ID-${investmentDetailsOnAProject?.project?.id}`}
                                <div>
                                  {
                                    investmentDetailsOnAProject?.project
                                      ?.launchName
                                  }
                                </div>
                              </TableCell>
                              <TableCell style={{ minWidth: 200 }}>
                                {investmentDetailsOnAProject?.project
                                  ?.crmProjectName
                                  ? investmentDetailsOnAProject?.project
                                      ?.crmProjectName
                                  : "----"}
                              </TableCell>
                              <TableCell style={{ minWidth: 150 }}>
                                {`${investmentDetailsOnAProject?.investment?.crmInventoryBucket?.name}`}{" "}
                              </TableCell>
                              <TableCell style={{ minWidth: 150 }}>
                                {`${investmentDetailsOnAProject?.investment?.crmInventory?.name}`}{" "}
                              </TableCell>
                              <TableCell
                                className="table-data-text"
                                style={{ minWidth: 150 }}
                              >
                                {
                                  investmentDetailsOnAProject?.investment
                                    ?.amountInvested
                                }
                              </TableCell>

                              <TableCell
                                className="table-data-text"
                                style={{ minWidth: 200 }}
                              >
                                {
                                  investmentDetailsOnAProject?.investment
                                    ?.crmInventory?.areaSqFt
                                }
                              </TableCell>

                              <TableCell
                                className="table-data-text"
                                style={{ minWidth: 150 }}
                              >
                                {investmentDetailsOnAProject?.investment
                                  ?.isBookingComplete
                                  ? investmentDetailsOnAProject?.investment
                                      ?.ownershipDate
                                    ? formatDateTime(
                                        investmentDetailsOnAProject?.investment
                                          ?.ownershipDate,
                                        Constants.dateFormatDDmmYYYY
                                      )
                                    : "--"
                                  : "--"}
                              </TableCell>

                              <TableCell
                                className="table-data-text"
                                style={{ minWidth: 120 }}
                              >
                                {`${
                                  investmentDetailsOnAProject?.investment
                                    ?.isBookingComplete
                                    ? investmentDetailsOnAProject?.investment
                                        ?.projectIea
                                    : "--"
                                }`}
                              </TableCell>
                              <TableCell
                                className="table-data-text"
                                style={{ minWidth: 120 }}
                              >
                                {
                                  investmentDetailsOnAProject?.project
                                    ?.generalInfoEscalationGraph
                                    ?.estimatedAppreciation
                                }
                              </TableCell>
                              <TableCell
                                className="table-data-text"
                                style={{ minWidth: 150 }}
                              >
                                {!investmentDetailsOnAProject?.investment
                                  ?.isBookingComplete
                                  ? investmentDetailsOnAProject?.investment
                                      ?.paidAmount
                                  : investmentDetailsOnAProject?.investment
                                      ?.amountInvested}
                              </TableCell>
                              <TableCell
                                className="table-data-text"
                                style={{ minWidth: 150 }}
                              >
                                {!investmentDetailsOnAProject?.investment
                                  ?.isBookingComplete
                                  ? investmentDetailsOnAProject?.investment
                                      ?.pendingAmount
                                  : "--"}
                              </TableCell>
                              <TableCell
                                className="table-data-text"
                                style={{ minWidth: 150 }}
                              >
                                {!investmentDetailsOnAProject?.investment
                                  ?.isBookingComplete
                                  ? investmentDetailsOnAProject?.investment
                                      ?.actionItemCount
                                  : "--"}
                              </TableCell>

                              <TableCell style={{ minWidth: 150 }}>
                                {typeof investmentDetailsOnAProject?.investment
                                  ?.facilityStatus == "boolean"
                                  ? investmentDetailsOnAProject?.investment
                                      ?.facilityStatus
                                    ? "Complete"
                                    : "InComplete"
                                  : "----"}
                              </TableCell>
                              <TableCell style={{ minWidth: 120 }}>
                                <Tooltip title="View" placement="top">
                                  <IconButton
                                    aria-label="view"
                                    onClick={() =>
                                      viewInsight(investmentDetailsOnAProject)
                                    }
                                  >
                                    <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  ) : (
                    <div className="no-record">No Projects Found</div>
                  )}
                </Table>
              </TableContainer>
            </CustomTableContainer>
          )}
        </>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Container className="main-container">
        {customerInvestmentSummary?.length > 0 ? renderTable() : null}
      </Container>
    </Fragment>
  );
};

export default CustomerPortpolioInvestmentDetails;
