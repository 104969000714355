export const enum PageTypeEnum {
  HoABL = "5001",
  Investments = "5002",
  Promises = "5003",
  Portfolio = "5004",
  Profile = "5005",
  FooterTabs = "5006",
}

export class PageTypeEnumUtils {
  public static getEnumText(type: PageTypeEnum): string {
    switch (type) {
      case PageTypeEnum.HoABL:
        return "HoABL";
      case PageTypeEnum.Investments:
        return "Investments";
      case PageTypeEnum.Promises:
        return "Promises";
      case PageTypeEnum.Portfolio:
        return "Portfolio";
      case PageTypeEnum.Profile:
        return "Profile";
      case PageTypeEnum.FooterTabs:
        return "Footer Tabs";
    }
  }

  public static getPageTypeEnums(): {
    label: string;
    value: PageTypeEnum;
  }[] {
    const options: { label: string; value: PageTypeEnum }[] = [
      {
        label: this.getEnumText(PageTypeEnum.HoABL),
        value: PageTypeEnum.HoABL,
      },
      {
        label: this.getEnumText(PageTypeEnum.Investments),
        value: PageTypeEnum.Investments,
      },
      {
        label: this.getEnumText(PageTypeEnum.Promises),
        value: PageTypeEnum.Promises,
      },
      {
        label: this.getEnumText(PageTypeEnum.Portfolio),
        value: PageTypeEnum.Portfolio,
      },
      {
        label: this.getEnumText(PageTypeEnum.Profile),
        value: PageTypeEnum.Profile,
      },
      {
        label: this.getEnumText(PageTypeEnum.FooterTabs),
        value: PageTypeEnum.FooterTabs,
      },
    ];
    return options;
  }
}
