import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ElementType, FocusEventHandler, Fragment } from "react";
import AlertHelperText from "../alert-helper-text/AlertHelperText";
import MediaAssets from "assets";
import ProgressLoader from "../progress-loader/ProgressLoader";
interface Iimageuploaderdropbox {
  inputId?: number;
  fieldhelpertext?: any;
  ondrop?: (event: any) => void;
  onChange: (event: any) => void;
  previewimage?: any;
  name?: string;
  value?: string;
  cancelimage?: () => void;
  disabled?: boolean;
  loading?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  componentType?: ElementType<HTMLElementTagNameMap>;
  mediaHeight?: any;
  mediaWidth?: any;
  error?: boolean;
}

const FileUploadComponentStyleWrapper = styled<any>(Box)`
  border: 2px dotted
    ${(props) =>
      props.isError
        ? props.theme.palette.error.main
        : props.theme.palette.grey.A200};
  border-radius: 4px;
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
  /* width: 20rem;  */
  height: 14.5rem;
  position: relative;
  & .MuiCard-root {
    height: 100%;
    width: 100%;
  }
  & .replace-button {
    font-family: "Jost-Regular";
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    position: absolute;
    column-gap: 0.3rem;
    bottom: 0.5rem;
    right: 1rem;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.common.black};
  }
  & .trash-icon {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    z-index: 1;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    padding: 0.1rem;
    background-color: ${({ theme }) => theme.palette.common.white};
  }
  & .to-right {
    right: 4rem;
  }
  & .caption-detail-text {
    line-height: 2;
    color: ${({ theme }) => theme.palette.grey.A200};
  }
`;
const PdfLoader = () => {
  return (
    <Stack
      position={"relative"}
      zIndex={5}
      justifyContent={"center"}
      height={"inherit"}
    >
      <ProgressLoader />
      <Typography
        color={"text.primary"}
        fontFamily={"Jost-Regular"}
        variant={"body1"}
        ml={"0.5rem"}
        lineHeight={2}
      >
        Loading...
      </Typography>
    </Stack>
  );
};

const PdfUpload = (props: Iimageuploaderdropbox) => {
  const {
    inputId = 1,
    ondrop,
    disabled,
    previewimage,
    loading = false,
    onBlur,
    name,
    fieldhelpertext,
    onChange,
    componentType = "img",
    mediaWidth,
    mediaHeight,
    error,
  } = props;
  return (
    <FileUploadComponentStyleWrapper
      isError={fieldhelpertext}
      isDisabled={disabled}
    >
      {loading ? (
        <PdfLoader />
      ) : (
        <Fragment>
          <Box component={"label"} htmlFor={name}>
            <input
              disabled={disabled}
              accept=".pdf"
              id={name}
              type="file"
              name={name}
              hidden
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e);
              }}
              onDrop={(e: any) => {
                if (ondrop) {
                  ondrop(e);
                }
              }}
            />
            <Stack
              direction={"column"}
              p={"0.5rem"}
              sx={{ cursor: disabled ? "not-allowed" : "pointer" }}
            >
              <Avatar
                sx={{ height: "30px", width: "30px" }}
                src={MediaAssets.ic_file_upload}
              />
              <Typography
                color={"text.primary"}
                variant={"body1"}
                lineHeight={2}
              >
                Drag & Drop here
              </Typography>
              <Typography variant={"caption"} className="caption-detail-text">
                or
              </Typography>
              <Typography
                variant={"body1"}
                color={"common.black"}
                sx={{
                  textDecoration: "underline",
                }}
              >
                Browser files
              </Typography>
              {/* <Typography className="caption-detail-text" variant="caption">
                Max file size : 10 MB
              </Typography> */}
              <Typography variant="caption" className="caption-detail-text">
                File Type: pdf
              </Typography>
              {error ? (
                <AlertHelperText color="error" severity="error">
                  {"Required"}
                </AlertHelperText>
              ) : (
                <Typography variant="body2" lineHeight={1.5}></Typography>
              )}
            </Stack>
          </Box>
          {!!previewimage && (
            <Card
              sx={{
                display: mediaWidth || mediaHeight ? "flex" : "block",
              }}
            >
              <CardMedia
                component={componentType}
                alt="Image"
                src={previewimage}
                sx={{
                  margin: "auto",
                  width: mediaWidth || "100%",
                  height: mediaHeight || "100%",
                  borderRadius: mediaHeight || mediaWidth ? "6px" : "0px",
                }}
              />
            </Card>
          )}
        </Fragment>
      )}
    </FileUploadComponentStyleWrapper>
  );
};

export default PdfUpload;
