import styles from "./PageHeading.module.css";

interface IPageHeadingProps {
  title: string;
}

const PageHeading = ({ title }: IPageHeadingProps) => {
  return (
    <div className={styles["page-heading-container"]}>
      <h4 className={styles["page-heading"]}>{title}</h4>
    </div>
  );
};

export default PageHeading;
