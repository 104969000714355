import React, { useState } from "react";
import {
  FormFieldContainer,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import {
  Avatar,
  Divider,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "../Page3promises.module.css";
import MediaAssets from "assets";

// Overring the padding for formFieldContainer
const DisclaimerContainer = styled(FormFieldContainer)`
  padding: 30px 0px 0px 0px;
  &&& .MuiTypography-root{
    font-size: 1.875rem
  }
`;

interface IDisclaimerProps {
  disclaimer: string;
  disclaimerError: string;
  handleDisclaimerChange: (e?: any) => any;
  handleOnBlurDisclaimer: (e?: any) => any;
  disabledComponent: boolean;
  isDisclaimerActive: boolean;
  setIsDisclaimerActive?: any;
}

const DisclaimerSection = ({
  disclaimer,
  disclaimerError,
  handleDisclaimerChange,
  handleOnBlurDisclaimer,
  disabledComponent,
  setIsDisclaimerActive,
  isDisclaimerActive,
}: IDisclaimerProps) => {
  const handleDisclaimerFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleDisclaimerChange(event?.target);
  };

  return (
    <DisclaimerContainer>
      <Stack
        className={styles["section-heading-with-switch"]}
        justifyContent={"start"}
        spacing={2}
      >
        <Typography variant={"h5"} component={"h5"} color={"#494a67"}>
          Disclaimer
        </Typography>

        <Stack spacing={3}>
          <SwitchComponent
            name="isDisclaimerActive"
            onChange={() => {
              setIsDisclaimerActive(!isDisclaimerActive);
            }}
            value={isDisclaimerActive}
            checked={isDisclaimerActive}
            disabled={disabledComponent}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>
      </Stack>

      <Divider></Divider>

      <div className={styles["section-content"]}>
        <TextFieldComponent
          disabled={disabledComponent}
          className=""
          fullWidth
          required
          type="text"
          label="Disclaimer"
          placeholder="Enter"
          name="disclaimer"
          value={disclaimer}
          inputProps={{ maxLength: 100 }}
          onChange={handleDisclaimerFieldChange}
          onBlur={handleOnBlurDisclaimer}
          error={disclaimerError !== "" ? true : false}
          fieldhelpertext={disclaimerError}
        />
      </div>
    </DisclaimerContainer>
  );
};

export default DisclaimerSection;
