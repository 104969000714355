import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { call, put } from "redux-saga/effects";
import LoginActions from "../../redux-container/login/LoginRedux";

export function* login(api: any, action: any): SagaIterator {
 
  try {
    const response = yield call(api.login, action.data);
    if (response?.status === HttpResponseTypeEnum.Success) {      
      yield put(LoginActions.loginSuccess(response.data));
    }
    else {      
      yield put(LoginActions.loginFailure(response));
    }
  } catch (error) {        
    yield put(LoginActions.loginFailure(error));
  }
}
