import { Box, Divider, Grid, MenuItem, Stack, Typography } from "@mui/material";
import {
  CustomDatePicker,
  InfoComponent,
  MediaUploadComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { TimeLineStatusTypeEnumUtils } from "enumerations/timeLineStatusTypeEnum";
import { useFormikContext, getIn } from "formik";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { shouldNumberOnly } from "utils/ValidationUtils";
// import { handleNumberFieldChange } from "utils/ValidationUtils";
import { statusOptions } from "../ProjectTimelineInitialData";

const actionDispatch = (dispatch: any) => {
  return {
    fileUploadRequest: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    fileDeleteRequest: (data: any) =>
      dispatch(FileUploadActions.fileDeleteRequest(data)),
    setUploadFileState: (key: string, value: any) =>
      dispatch(FileUploadActions.setUploadFileState(key, value)),
  };
};

const MasterDesignAndProjectBoundary = () => {
  const { imageDetails, isLoading } = useSelector(
    (state: any) => state?.fileUpload
  );
  const { fileUploadRequest, fileDeleteRequest, setUploadFileState } =
    actionDispatch(useDispatch());
  const { values, touched, errors, handleChange, setFieldValue, handleBlur } =
    useFormikContext<any>();
  const [fileUpload, setFileUpload] = useState("");
  const handleDatePickerChange = (name: string) => (newValue: string) => {
    setFieldValue(name, newValue);
  };
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const sectionIndex = 1;
  const projectBoundaryIndex = 1;
  const masterDesignIndex = 0;
  const projectDesignValues =
    values?.projectTimelines[sectionIndex]?.timeLines[masterDesignIndex];
  const masterStatusOption =
    TimeLineStatusTypeEnumUtils.getTimeLineStatusTypeEnums();
  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    const fileId = event.target.id;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      setFileUpload(fileId);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        data.binary = e.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);
      setFieldValue(
        `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.value.url`,
        imageDetails?.url
      );
      setFieldValue(
        `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.value.mediaType`,
        file.type
      );
      setFieldValue(
        `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.value.size`,
        file.size
      );
      setFieldValue(
        `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.name`,
        file.name
      );
      setFieldValue(
        `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.key`,
        file.name
      );
      setUploadFileState("shouldFlashMessageOpen", true);
    } else {
      return;
    }
  };

  const handleImageDelete = () => {
    fileDeleteRequest(projectDesignValues?.values?.medias.name);
    setFieldValue(
      `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.value.url`,
      ""
    );
    setFieldValue(
      `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.value.mediaType`,
      ""
    );
    setFieldValue(
      `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.value.size`,
      ""
    );
    setFieldValue(
      `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.name`,
      ""
    );
    setFieldValue(
      `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.key`,
      ""
    );
    setUploadFileState("shouldFlashMessageOpen", true);
    setUploadFileState("isSuccess", false);
  };

  useEffect(() => {
    const fileName = `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias`;
    if (imageDetails && fileUpload === fileName) {
      setFieldValue(
        `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.value.url`,
        imageDetails?.url
      );
      setUploadFileState("imageDetails", null);
      setFileUpload("");
      // setFieldValue(`projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.percentage`, 100)
    }
  }, [imageDetails]);

  const handleNumberFieldChange =
    (setFieldValue: any) => (event: ChangeEvent<HTMLInputElement>) => {
      if (
        shouldNumberOnly(event.target.value) &&
        event.target.value.length < 3
      ) {
        setFieldValue(`${event.target.name}`, Number(event.target.value));
      }
    };

  return (
    <Fragment>
      <Box className="section-gutter">
        <Grid container margin={"1rem 0 0"}>
          <Grid item lg={3} xl={3}>
            <TextFieldComponent
              label="Section Heading*"
              inputProps={{ maxLength: 40 }}
              name={`projectTimelines[${sectionIndex}].timeLineSectionHeading`}
              value={
                values?.projectTimelines[sectionIndex]?.timeLineSectionHeading
              }
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLineSectionHeading`
                ) &&
                  getIn(
                    errors,
                    `projectTimelines[${sectionIndex}].timeLineSectionHeading`
                  )
              )}
              fieldhelpertext={
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLineSectionHeading`
                ) &&
                getIn(
                  errors,
                  `projectTimelines[${sectionIndex}].timeLineSectionHeading`
                )
              }
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Stack className="field-container-box">
        <Typography color={"common.black"}>2.1 Master Design</Typography>
        <SwitchComponent
          name={`projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].isSectionActive`}
          onChange={handleChange}
          checked={projectDesignValues?.isSectionActive}
          disabled={isViewMode}
        />
        <InfoComponent />
      </Stack>
      <Box className="section-gutter">
        <Grid container columnSpacing={5} rowSpacing={3}>
          <Grid item lg={3} xl={3}>
            <TextFieldComponent
              label="Display Name*"
              inputProps={{ maxLength: 30 }}
              onChange={handleChange}
              value={projectDesignValues?.values?.displayName}
              name={`projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.displayName`}
              onBlur={handleBlur}
              error={Boolean(
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.displayName`
                ) &&
                  getIn(
                    errors,
                    `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.displayName`
                  )
              )}
              fieldhelpertext={
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.displayName`
                ) &&
                getIn(
                  errors,
                  `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.displayName`
                )
              }
              disabled={isViewMode}
            />
          </Grid>
          <Grid item lg={3} xl={3}>
            <CustomDatePicker
              label={"Date of Completion"}
              placeholder={"Select Date"}
              name={`projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.dateOfCompletion`}
              value={new Date(projectDesignValues?.values?.dateOfCompletion)}
              onChange={handleDatePickerChange(
                `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.dateOfCompletion`
              )}
              disabled={isViewMode}
            />
          </Grid>
          <Grid item lg={12} xl={12}>
            <TextFieldComponent
              label="Tool tip Details*"
              inputProps={{ maxLength: 240 }}
              name={`projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.toolTipDetails`}
              onChange={handleChange}
              value={projectDesignValues?.values?.toolTipDetails}
              onBlur={handleBlur}
              error={Boolean(
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.toolTipDetails`
                ) &&
                  getIn(
                    errors,
                    `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.toolTipDetails`
                  )
              )}
              fieldhelpertext={
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.toolTipDetails`
                ) &&
                getIn(
                  errors,
                  `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.toolTipDetails`
                )
              }
              disabled={isViewMode}
            />
          </Grid>
          <Grid item lg={12} xl={12}>
            <Stack className="field-container-box no-gutter">
              <SwitchComponent
                name={`projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.isCtaActive`}
                checked={projectDesignValues?.values?.isCtaActive}
                onChange={handleChange}
                disabled={isViewMode}
              />
              <Typography>Show CTA on Customer app</Typography>
            </Stack>
          </Grid>
          <Grid item lg={3.5} xl={3.5}>
            <MediaUploadComponent
              loading={isLoading}
              ondrop={handleChange}
              name={`projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias`}
              onChange={handleFileInputChange}
              cancelimage={handleImageDelete}
              previewimage={projectDesignValues?.values?.medias?.value?.url}
              fieldhelpertext={
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.value.url`
                ) &&
                getIn(
                  errors,
                  `projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.medias.value.url`
                )
              }
              disabled={isViewMode}
            />
          </Grid>
          <Grid item lg={6} xl={6}>
            <TextFieldComponent
              label="Image Title"
              inputProps={{ maxLength: 50 }}
              name={`projectTimelines[${sectionIndex}].timeLines[${masterDesignIndex}].values.imageTitle`}
              onChange={handleChange}
              value={projectDesignValues?.values?.imageTitle}
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Stack className="field-container-box">
        <Typography>2.2 Project Boundry</Typography>
        <SwitchComponent
          name={`projectTimelines[${sectionIndex}].timeLines[1].isSectionActive`}
          onChange={handleChange}
          checked={
            values?.projectTimelines[sectionIndex]?.timeLines[1]
              ?.isSectionActive
          }
          disabled={isViewMode}
        />
        <InfoComponent />
      </Stack>
      <Box className="section-gutter">
        <Grid container columnSpacing={4} rowSpacing={3}>
          <Grid item lg={3} xl={3}>
            <TextFieldComponent
              label="Display Name*"
              onChange={handleChange}
              value={
                // projectDesignValues?.values
                //   ?.displayName
                values?.projectTimelines[sectionIndex]?.timeLines[1]?.values
                  ?.displayName
              }
              name={`projectTimelines[${sectionIndex}].timeLines[1].values.displayName`}
              onBlur={handleBlur}
              error={Boolean(
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[1].values.displayName`
                ) &&
                  getIn(
                    errors,
                    `projectTimelines[${sectionIndex}].timeLines[1].values.displayName`
                  )
              )}
              fieldhelpertext={
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[1].values.displayName`
                ) &&
                getIn(
                  errors,
                  `projectTimelines[${sectionIndex}].timeLines[1].values.displayName`
                )
              }
              disabled={isViewMode}
            />
          </Grid>
          <Grid item lg={3} xl={3}>
            <CustomDatePicker
              label={"Date of Completion"}
              placeholder={"Select Date"}
              disabled={isViewMode}
              name={`projectTimelines[${sectionIndex}].timeLines[1].values.dateOfCompletion`}
              value={
                new Date(
                  values?.projectTimelines[
                    sectionIndex
                  ]?.timeLines[1]?.values?.dateOfCompletion
                )
              }
              onChange={handleDatePickerChange(
                `projectTimelines[${sectionIndex}].timeLines[1].values.dateOfCompletion`
              )}
            />
          </Grid>
          <Grid item lg={3} xl={3}>
            <TextFieldComponent
              select
              label="Status*"
              onChange={(e: any) => {
                handleChange(e);
                if (e.target.value === "6003") {
                  setFieldValue(
                    `projectTimelines[${sectionIndex}].timeLines[1].values.percentage`,
                    100
                  );
                } else {
                  if (
                    values?.projectTimelines[sectionIndex].timeLines[1].values
                      .percentage === 100
                  ) {
                    setFieldValue(
                      `projectTimelines[${sectionIndex}].timeLines[1].values.percentage`,
                      0
                    );
                  }
                }
              }}
              value={
                values?.projectTimelines[sectionIndex]?.timeLines[1]?.values
                  ?.status
              }
              name={`projectTimelines[${sectionIndex}].timeLines[1].values.status`}
              error={Boolean(
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[1].values.status`
                ) &&
                  getIn(
                    errors,
                    `projectTimelines[${sectionIndex}].timeLines[1].values.status`
                  )
              )}
              fieldhelpertext={
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[1].values.status`
                ) &&
                getIn(
                  errors,
                  `projectTimelines[${sectionIndex}].timeLines[1].values.status`
                )
              }
              disabled={isViewMode}
            >
              {masterStatusOption.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </TextFieldComponent>
          </Grid>
          {values?.projectTimelines[sectionIndex]?.timeLines[1]?.values
            ?.status === "6002" && (
            <Grid item lg={2} xl={2}>
              <TextFieldComponent
                label="% Value*"
                onChange={handleNumberFieldChange(setFieldValue)}
                value={
                  values?.projectTimelines[sectionIndex]?.timeLines[1]?.values
                    ?.percentage
                }
                name={`projectTimelines[${sectionIndex}].timeLines[1].values.percentage`}
                onBlur={handleBlur}
                error={Boolean(
                  getIn(
                    touched,
                    `projectTimelines[${sectionIndex}].timeLines[1].values.percentage`
                  ) &&
                    getIn(
                      errors,
                      `projectTimelines[${sectionIndex}].timeLines[1].values.percentage`
                    )
                )}
                fieldhelpertext={
                  getIn(
                    touched,
                    `projectTimelines[${sectionIndex}].timeLines[1].values.percentage`
                  ) &&
                  getIn(
                    errors,
                    `projectTimelines[${sectionIndex}].timeLines[1].values.percentage`
                  )
                }
                disabled={isViewMode}
              />
            </Grid>
          )}
          <Grid item lg={12} xl={12}>
            <TextFieldComponent
              label="Tool tip Details *"
              name={`projectTimelines[${projectBoundaryIndex}].timeLines[1].values.toolTipDetails`}
              onChange={handleChange}
              value={
                values?.projectTimelines[sectionIndex]?.timeLines[1]?.values
                  ?.toolTipDetails
              }
              inputProps={{ maxLength: 240 }}
              onBlur={handleBlur}
              error={Boolean(
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[1].values.toolTipDetails`
                ) &&
                  getIn(
                    errors,
                    `projectTimelines[${sectionIndex}].timeLines[1].values.toolTipDetails`
                  )
              )}
              fieldhelpertext={
                getIn(
                  touched,
                  `projectTimelines[${sectionIndex}].timeLines[1].values.toolTipDetails`
                ) &&
                getIn(
                  errors,
                  `projectTimelines[${sectionIndex}].timeLines[1].values.toolTipDetails`
                )
              }
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
      </Box>
      <Stack className="field-container-bottom">
        <SwitchComponent
          name={`projectTimelines[${sectionIndex}].timeLines[1].values.isCtaActive`}
          checked={
            values?.projectTimelines[sectionIndex]?.timeLines[1]?.values
              ?.isCtaActive
          }
          onChange={handleChange}
          disabled={isViewMode}
        />
        <Typography>Show CTA on Customer app</Typography>
      </Stack>
    </Fragment>
  );
};

export default MasterDesignAndProjectBoundary;
