import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getNotificationsSuccess: ["response"],
  getNotificationsFailure: ["error"],
  getNotificationsRequest: ["params"],

  addNotificationSuccess: ["response"],
  addNotificationFailure: ["error"],
  addNotificationRequest: ["data"],

  updateNotificationSuccess: ["response"],
  updateNotificationFailure: ["error"],
  updateNotificationRequest: ["data"],

  changeNotificationStatusSuccess: ["response"],
  changeNotificationStatusFailure: ["error"],
  changeNotificationStatusRequest: ["data"],

  setNotificationsState: ["key", "value"],
});

export const NotificationsTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  notifications: [],
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  shouldOpenFlashDialog: false,
  isDialogOpen: false,
  isGraphDataFetched: false,
  page: 1,
  rowsPerPage: 20,
};

/* Get Notifications */
export const getNotificationsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getNotificationsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      notifications: response.data,
      isLoading: false,
      totalCount: response.totalCount,
      isGraphDataFetched: true,
    };
  }
};

export const getNotificationsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Add Notifications */
export const addNotificationRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const addNotificationSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Notification added successfully!",
      isLoading: false,
      isDialogOpen: true,
    };
  }
};

export const addNotificationFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    message: action.error.message,
    isSuccess: false,
    isDialogOpen: true,
  };
};

/* Update Notifications */
export const updateNotificationRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const updateNotificationSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Notification updated successfully!",
      isLoading: false,
    };
  }
};

export const updateNotificationFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

/* Update Notification(s) status */
export const changeNotificationStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const changeNotificationStatusSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Notification status updated successfully",
      isLoading: false,
    };
  }
};

export const changeNotificationStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setNotificationsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,
  [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,

  [Types.ADD_NOTIFICATION_SUCCESS]: addNotificationSuccess,
  [Types.ADD_NOTIFICATION_FAILURE]: addNotificationFailure,
  [Types.ADD_NOTIFICATION_REQUEST]: addNotificationRequest,

  [Types.UPDATE_NOTIFICATION_SUCCESS]: updateNotificationSuccess,
  [Types.UPDATE_NOTIFICATION_FAILURE]: updateNotificationFailure,
  [Types.UPDATE_NOTIFICATION_REQUEST]: updateNotificationRequest,

  [Types.CHANGE_NOTIFICATION_STATUS_REQUEST]: changeNotificationStatusRequest,
  [Types.CHANGE_NOTIFICATION_STATUS_SUCCESS]: changeNotificationStatusSuccess,
  [Types.CHANGE_NOTIFICATION_STATUS_FAILURE]: changeNotificationStatusFailure,

  [Types.SET_NOTIFICATIONS_STATE]: setNotificationsState,
});
