import React, { useState, useEffect } from "react";

import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import {
  Stack,
  Typography,
  Container,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Link,
  Box,
  TextField,
  Divider,
  Grid,
  InputAdornment,
  TextareaAutosize,
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonComponent,
  LoadingButtonComponent,
  MediaUploadComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";

import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import UpCommingProjectContentCreationTypes from "redux-container/project-management/upcomming-project/UpcommingProjectRedux";
import {
  IInitinalValueProjectContent,
  InitinalValueProjectContent,
} from "./UpCommingInitialValues";

import { state } from "../../../../utils/States";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIn } from "yup/lib/util/reach";
import { log } from "console";
import styles from "../project-cover-images/ProjectCoverImages.module.css";
import UpCommingMediaUploader from "./UpCommingMediaUploader";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { RouteConstants } from "routes/RouteConstants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import UpcomingProjectsMediaUpload from "./UpcomingProjectsMediaUpload";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import CurrentInfraStory from "./upcommingProjectSections/CurrentInfraStory";
import TourismAround from "./upcommingProjectSections/TourismAround";
import KeyPillars from "./upcommingProjectSections/KeyPillars";
import UpcomingInfraStory from "./upcommingProjectSections/UpcomingInfraStory";
import { PromiseCreationContainer } from "pages/promise-management/promise-creation/PromiseCreation.style";
import {
  upCommingUpdateValidationSchema,
  upCommingValidationSchema,
} from "./UpcommingValidation";

const actionDispatch = (dispatch: any) => {
  return {
    updateUpCommingProjectContentCreationDataRequest: (id: any, data: any) => {
      dispatch(
        UpCommingProjectContentCreationTypes.updateUpCommingProjectContentCreationDataRequest(
          id,
          data
        )
      );
    },
    getSingleUpCommingProjectContentCreationDataRequest: (data: any) =>
      dispatch(
        UpCommingProjectContentCreationTypes.getSingleUpCommingProjectContentCreationDataRequest(
          data
        )
      ),

    setUpcomingProjectState: (key: string, data: any) =>
      dispatch(
        UpCommingProjectContentCreationTypes.setUpcomingProjectState(key, data)
      ),

    setUploadFileState: (key: any, data: any) => {
      dispatch(FileUploadActions.setUploadFileState(key, data));
    },
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
    fileDeleteRequest: (data: any) => {
      dispatch(FileUploadActions.fileDeleteRequest(data));
    },
  };
};

const UpdateFormFields = () => {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const [fileKey, setFileKey] = useState("");
  const [fileName, setFileName] = useState("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    React.useState<boolean>(false);
  // const [fileSize, setFileSize] = useState("");

  console.log(location.state, "bhbhbhbh");
  const { imageDetails, isLoading } = useSelector(
    (state: any) => state.fileUpload
  );

  // console.log(imageDetails, isLoading);
  const navigate = useNavigate();
  // const projectDocMedia = `bannerImage.value.url`;
  const {
    setUploadFileState,
    fileUploadRequest,
    fileDeleteRequest,
    updateUpCommingProjectContentCreationDataRequest,
    setUpcomingProjectState,
    getSingleUpCommingProjectContentCreationDataRequest,
  } = actionDispatch(useDispatch());
  const singleProject = useSelector((state: any) => {
    return state?.upCommingProjects?.singleProduct;
  });
  useEffect(() => {
    getSingleUpCommingProjectContentCreationDataRequest(
      location?.state?.project?.id
    );
  }, []);
  console.log("single product", singleProject);
  const [imageUploadToBanner, setImageUploadToBanner] = useState("");

  const [pdfName, setPdfName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [clickedImageUploaderName, setClickedImageUploaderName] = useState("");
  const [clickedImageUploaderId, setClickedImageUploaderId] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [address, setAddress] = useState({
    city: location?.state?.project?.address?.city,

    state: location?.state?.project?.address?.state,

    country: location?.state?.project?.address?.country,
  });

  const [bannerImage, setBannerImage] = useState<any>({
    name: location?.state?.project?.bannerImage.name,
    key: location?.state?.project?.bannerImage.key,
    value: {
      url: location?.state?.project?.bannerImage?.value?.url,
      size: location?.state?.project?.bannerImage?.value?.size,
      width: location?.state?.project?.bannerImage?.value?.width,
      height: location?.state?.project?.bannerImage?.value?.height,
      mediaType: "image",
    },
  });
  const [projectIcon, setProjectIcon] = useState<any>({
    name: location?.state?.project?.projectIcon.name,
    key: location?.state?.project?.projectIcon.key,
    value: {
      url: location?.state?.project?.projectIcon?.value?.url,
      size: location?.state?.project?.projectIcon?.value?.size,
      width: location?.state?.project?.projectIcon?.value?.width,
      height: location?.state?.project?.projectIcon?.value?.height,
      mediaType: "image",
    },
  });
  const [projectDetailes, setprojectDetailes] = useState({
    launchName: location?.state?.project?.launchName,

    shortDescription: location?.state?.project?.shortDescription,

    address: {},
    projectPhoneNumber: location?.state?.project?.projectPhoneNumber,
    projectIcon: {
      name: location?.state?.project?.projectIcon.name,
      key: location?.state?.project?.projectIcon.key,
      value: {
        url: location?.state?.project?.projectIcon?.value?.url,
        size: location?.state?.project?.projectIcon?.value?.size,
        width: location?.state?.project?.projectIcon?.value?.width,
        height: location?.state?.project?.projectIcon?.value?.height,
        mediaType: "image",
      },
    },
    bannerImage: {},
  });
  console.log("location", location);

  const handleBannerDocMedia = (event: any) => {
    const file = event?.target?.files[0];

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      // reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        // dispatch(FileUploadActions.fileUploadRequest(data));
        fileUploadRequest(data);
        // setImageUploadToBanner(MediaId);
      };
      reader.readAsArrayBuffer(file);

      setBannerImage((preVal: any) => {
        return {
          ...preVal,
          key: file?.name,
          name: file?.name,
          value: {
            size: file?.size,
            mediaType: file?.type,
            height: "1024",
            width: "1024",
          },
        };
      });
    }
    return;
  };

  const handleIconUpload = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      console.log("dataaaaa", data);

      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);
      console.log("project icon ==>", file);
      setProjectIcon((preVal: any) => {
        return {
          ...preVal,
          key: file?.name,
          name: file?.name,
          value: {
            size: file?.size,
            mediaType: file?.type,
            height: "1024",
            width: "1024",
          },
        };
      });
    }
  };
  console.log("storeee", imageDetails);

  const onChangeIconHandler = (event: any) => {
    const fileExtension = event?.target?.value?.slice(
      ((event?.target?.value?.lastIndexOf(".") - 1) >>> 0) + 2
    );
    if (
      fileExtension == "png" ||
      fileExtension == "jpg" ||
      fileExtension == "jpeg"
    ) {
      handleBannerDocMedia(event);
      setClickedImageUploaderId(1);
      setClickedImageUploaderName(event.target.name);
    } else {
      alert("Only .png, .jpg, .jpeg is accepted !");
    }
  };

  const onChangeBannerHandler = (event: any) => {
    const fileExtension = event?.target?.value?.slice(
      ((event?.target?.value?.lastIndexOf(".") - 1) >>> 0) + 2
    );
    if (
      fileExtension == "png" ||
      fileExtension == "jpg" ||
      fileExtension == "jpeg"
    ) {
      handleBannerDocMedia(event);
      setClickedImageUploaderId(2);
      setClickedImageUploaderName(event.target.name);
    } else {
      alert("Only .png, .jpg, .jpeg is accepted !");
    }
  };

  useEffect(() => {
    if (imageDetails) {
      const imageRes = imageDetails;
      setUploadFileState("imageDetails", null);

      if (clickedImageUploaderId !== 0 && clickedImageUploaderId == 1) {
        const { value } = projectIcon;
        value.url = imageRes?.url;

        setClickedImageUploaderId(0);
        setClickedImageUploaderName("");
      } else {
        const { value } = bannerImage;
        value.url = imageRes?.url;
        setClickedImageUploaderId(0);
        setClickedImageUploaderName("");
      }
    }
  }, [imageDetails]);

  const handleProjectChange = (e: any) => {
    const { name, value } = e.target;
    setprojectDetailes((preVal: any) => {
      return { ...preVal, [name]: value };
    });
    console.log(e);
  };

  const handleOnSave = (values: any) => {
    setIsConfirmDialogOpen(false);

    projectDetailes.address = { ...address };
    projectDetailes.bannerImage = { ...bannerImage };
    projectDetailes.projectIcon = { ...projectIcon };
    updateUpCommingProjectContentCreationDataRequest(singleProject?.id, {
      ...values,
      ...projectDetailes,
    });
  };
  const handleAddressChange = (e: any) => {
    const { name, value } = e.target;
    setAddress((preVal: any) => {
      return { ...preVal, [name]: value };
    });
  };

  const breadcrumbRoutes: any[] = [
    {
      name: "Project Management",
      route: RouteConstants.projectManagementDashboard,
    },
    {
      name: "Projects",
      route: RouteConstants.projectList,
    },
    {
      name: `Edit - ${location?.state?.project?.launchName}`,
      route: RouteConstants.upCommingProjectUpdate,
    },
  ];

  const handleCloseFlashMessageDialog = () => {
    setUpcomingProjectState("shouldOpenFlashDialog", false);
    setUpcomingProjectState("pageOfUpcomingProject", 0);
    setUpcomingProjectState("rowsPerPage", 5);
    navigate("/projects");
  };

  const shouldOpenFlashDialog = useSelector(
    (state: any) => state?.upCommingProjects?.shouldOpenFlashDialog
  );

  const message = useSelector(
    (state: any) => state?.upCommingProjects?.message
  );

  const error = useSelector((state: any) => state?.upCommingProjects?.error);

  return (
    <>
      <Formik
        initialValues={{
          ...singleProject,
          opportunityDoc: singleProject?.opportunityDoc
            ? singleProject?.opportunityDoc
            : InitinalValueProjectContent?.opportunityDoc,
          keyPillars: singleProject?.keyPillars
            ? singleProject?.keyPillars
            : InitinalValueProjectContent?.keyPillars,
          isKeyPillarsActive: singleProject?.isKeyPillarsActive
            ? singleProject?.isKeyPillarsActive
            : InitinalValueProjectContent?.isKeyPillarsActive,
          isKeyPillarsActiveForWeb: singleProject?.isKeyPillarsActiveForWeb
            ? singleProject?.isKeyPillarsActiveForWeb
            : InitinalValueProjectContent?.isKeyPillarsActiveForWeb,
        }}
        validationSchema={upCommingUpdateValidationSchema}
        onSubmit={handleOnSave}
        enableReinitialize
      >
        {({ values, isValid, dirty }) => (
          <Form noValidate>
            <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
            <Container className="main-container">
              <ConfirmationDialog
                shouldOpen={isConfirmDialogOpen}
                content="Are you sure you want to Update?"
                okText="Update"
                okHandler={() => handleOnSave(values)}
                cancelHandler={() => setIsConfirmDialogOpen(false)}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginInline: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    edge={"start"}
                    onClick={() => navigate(RouteConstants.projectList)}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  <Typography variant="h5">{`${location?.state?.project?.launchName} - #ID${location?.state?.project?.id}`}</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ButtonComponent
                    onClick={() => navigate(RouteConstants.projectList)}
                  >
                    Cancel
                  </ButtonComponent>
                  <LoadingButtonComponent
                    onClick={() => setIsConfirmDialogOpen(true)}
                    // type={ "submit" }
                    style={{ marginLeft: "1rem" }}
                    disabled={
                      projectDetailes?.launchName === "" ||
                      projectDetailes?.projectPhoneNumber === "" ||
                      address?.city === "" ||
                      address?.country === "" ||
                      address?.state === "" ||
                      projectDetailes?.shortDescription === "" ||
                      bannerImage?.value?.url === "" ||
                      projectIcon?.value?.url === "" ||
                      !isValid
                      // ||
                      // !dirty
                    }
                  >
                    Update
                  </LoadingButtonComponent>
                </div>
              </div>
              <div>
                <PromiseCreationContainer sx={{ padding: 3 }}>
                  <TextFieldComponent
                    label={"Title"}
                    name="launchName"
                    defaultValue={projectDetailes.launchName}
                    onChange={handleProjectChange}
                    style={{ marginTop: "4px" }}
                    required
                  />

                  <TextFieldComponent
                    fullWidth
                    required
                    name="shortDescription"
                    defaultValue={projectDetailes.shortDescription}
                    placeholder="short description"
                    onChange={handleProjectChange}
                  />
                  <TextFieldComponent
                    fullWidth
                    label="ProjectPhoneNumber"
                    name={`projectPhoneNumber`}
                    defaultValue={projectDetailes.projectPhoneNumber}
                    onChange={handleProjectChange}
                    style={{ marginTop: "4px" }}
                    required
                  />
                  <div>
                    <Stack
                      alignItems="center"
                      justifyContent="flexStart"
                      spacing={2}
                      mb={1}
                    >
                      <Typography variant="h6" color="#000">
                        Address
                      </Typography>
                    </Stack>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextFieldComponent
                          name={`country`}
                          variant="outlined"
                          label={"Country"}
                          onChange={handleAddressChange}
                          defaultValue={address?.country}
                          fullWidth
                          inputProps={{
                            "data-testId": "country",
                          }}
                          required
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <TextFieldComponent
                          select
                          name={`state`}
                          variant="outlined"
                          label={"State"}
                          defaultValue={address?.state}
                          onChange={handleAddressChange}
                          fullWidth
                          required
                        >
                          {state.map((stateValue: string, key: number) => (
                            <MenuItem key={key} value={`${stateValue}`}>
                              {stateValue}
                            </MenuItem>
                          ))}
                        </TextFieldComponent>
                      </Grid>

                      <Grid item xs={3}>
                        <TextFieldComponent
                          fullWidth
                          name={"city"}
                          defaultValue={address?.city}
                          label={"City"}
                          onChange={handleAddressChange}
                          inputProps={{
                            "data-testId": "city",
                          }}
                          required
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      columnGap: "2rem",
                      marginTop: "1rem",
                    }}
                  >
                    <Grid item>
                      <Typography className={styles["heading-alignment"]}>
                        Banner Image*
                      </Typography>
                      <UpcomingProjectsMediaUpload
                        name="bannerImage"
                        onChange={onChangeBannerHandler}
                        previewimage={bannerImage?.value?.url}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={styles["heading-alignment"]}>
                        Project Icon*
                      </Typography>
                      <UpcomingProjectsMediaUpload
                        name="projectIcon"
                        // inputId={name}
                        onChange={onChangeIconHandler}
                        // cancelimage={() => cancelImageHandler(Id)} //won't gives an event so use id prop.
                        previewimage={projectIcon?.value?.url}
                        // loading={clickedImageUploaderId === Id ? isLoading : false}
                        mediaHeight={194}
                        mediaWidth={197}
                      />
                    </Grid>
                  </div>
                  <CurrentInfraStory />
                  <UpcomingInfraStory />
                  <TourismAround />
                  <KeyPillars />
                </PromiseCreationContainer>
              </div>
            </Container>
          </Form>
        )}
      </Formik>

      {message && shouldOpenFlashDialog ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : "Something Went Wrong!"}
          isSuccess={true}
          cancelHandler={() => handleCloseFlashMessageDialog()}
        />
      ) : null}

      {error && shouldOpenFlashDialog ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error}
          isSuccess={false}
          cancelHandler={() => handleCloseFlashMessageDialog()}
        />
      ) : null}
    </>
  );
};

export default UpdateFormFields;
