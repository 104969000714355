import {
  Avatar,
  Box,
  Stack,
  Tooltip,
  Typography,
  Divider,
  FormControlLabel,
} from "@mui/material";
import MediaAssets from "assets";
import {
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IInitinalValueProjectContent } from "../../InitinalValuesProjectContent";
import TableForProjectMappedDetails from "./TableForProjectMappedDetails";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";

export default function MappedFaqsBasedOnProject() {
  const [sectionHeading, setSectionHeading] = useState("");
  const [isMapped, setMapped] = useState(false);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    touched,
    errors,
  } = useFormikContext<IInitinalValueProjectContent>();

  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );


  const mappedFaqs = useSelector(
    (state: any) => state?.projectAllfaqs?.allProjectFaqs
  );

  const [openWarningMessage, setOpenWarningMessage] = useState<boolean>(false);

  const handleCloseFlashMessage = () => {
    setOpenWarningMessage(false);
  };

  return (
    <Box>
      <FlashMessageDialog
        shouldOpen={openWarningMessage}
        content={
          "Please Map atleast One FAQ To Show this Section In Customer App"
        }
        isSuccess={false}
        showWarning={true}
        cancelHandler={handleCloseFlashMessage}
      />
      <Box p={"1.5rem"}>
        <Stack spacing={2} justifyContent={"flex-start"} pb={"1rem"}>
          <Typography color="common.black">
            Map FAQ To Project Detailed Page
          </Typography>
          <FormControlLabel
            className="switch-form-label"
            name={"isMappedFaqSectionHeaderActive"}
            checked={values?.isMappedFaqSectionHeaderActive}
            onChange={(e: any) => {
              if (
                !mappedFaqs?.length &&
                values?.isMappedFaqSectionHeaderActive === false
              ) {
                setOpenWarningMessage(true);
              } else {
                handleChange(e);
              }
            }}
            control={<SwitchComponent />}
            label=""
            // labelPlacement="start"
            // disabled={promiseState.isViewMode}
            disabled={isViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top-start"
          >
            <Avatar
              sx={{
                width: "0.9rem",
                height: "0.9rem",
                cursor: "pointer",
                marginTop: "0.10rem",
                marginBottom: "0.10rem",
              }}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>
        <TextFieldComponent
          required
          fullWidth={false}
          type="text"
          name={"otherSectionHeadings.faqSection.sectionHeading"}
          onChange={handleChange}
          placeholder="Enter"
          label={"Section Heading"}
          value={values?.otherSectionHeadings?.faqSection?.sectionHeading}
          disabled={isViewMode}
          onBlur={handleBlur}
          fieldhelpertext={
            getIn(touched, "otherSectionHeadings.faqSection.sectionHeading") &&
            getIn(errors, "otherSectionHeadings.faqSection.sectionHeading")
          }
          error={Boolean(
            getIn(errors, "otherSectionHeadings.faqSection.sectionHeading") &&
              getIn(touched, "otherSectionHeadings.faqSection.sectionHeading")
          )}
        />
      </Box>

      <Box>
        <TableForProjectMappedDetails />
      </Box>
    </Box>
  );
}
