import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import UpdateActions from "redux-container/marketing-management/updates/UpdatesRedux";
import UpdateCreationAction from "redux-container/marketing-management/updates/UpdateCreationRedux";

export function* getUpdates(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAllUpdates, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UpdateActions.updateSuccess(response.data));
    } else {
      yield put(UpdateActions.updateFailure(response.data));
    }
  } catch (error) {
    yield put(UpdateActions.updateFailure(error));
  }
}

export function* deleteUpdate(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteUpdate, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UpdateActions.deleteUpdateSuccess(response.data));
    } else {
      yield put(UpdateActions.deleteUpdateFailure(response.data));
    }
  } catch (error) {
    yield put(UpdateActions.deleteUpdateFailure(error));
  }
}

export function* updateStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateStatus, action.data);
    yield put(UpdateActions.updateStatusSuccess(response.data));
  } catch (error) {
    yield put(UpdateActions.updateStatusFailure(error));
  }
}

export function* updatePriority(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updatePriority, action.data);
    yield put(UpdateActions.updatePrioritySuccess(response.data));
  } catch (error) {
    yield put(UpdateActions.updatePriorityFailure(error));
  }
}

// Yuvaraj

export function* addUpdates(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addUpdates, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UpdateCreationAction.addUpdatesSuccess(response.data));
    } else {
      yield put(UpdateCreationAction.addUpdatesFailure(response.data));
    }
  } catch (error) {
    yield put(UpdateCreationAction.addUpdatesFailure(error));
  }
}

export function* updateMarketingUpdates(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateMarketingUpdates, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        UpdateCreationAction.updateMarketingUpdatesSuccess(response.data)
      );
    } else {
      yield put(
        UpdateCreationAction.updateMarketingUpdatesFailure(response.data)
      );
    }
  } catch (error) {
    yield put(UpdateCreationAction.updateMarketingUpdatesFailure(error));
  }
}

export function* addDraftsUpdates(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addDraftMarketingUpdates, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UpdateCreationAction.addDraftsUpdatesSuccess(response.data));
    } else {
      yield put(UpdateCreationAction.addDraftsUpdatesFailure(response.data));
    }
  } catch (error) {
    yield put(UpdateCreationAction.addDraftsUpdatesFailure(error));
  }
}

export function* getSingleUpdate(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getSingleUpdate, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UpdateCreationAction.getSingleUpdatesSuccess(response.data));
    } else {
      yield put(UpdateCreationAction.getSingleUpdatesFailure(response.data));
    }
  } catch (error) {
    yield put(UpdateCreationAction.getSingleUpdatesFailure(error));
  }
}

export function* deleteUpdates(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteUpdates, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UpdateCreationAction.deleteUpdatesSuccess(response.data));
    } else {
      yield put(UpdateCreationAction.deleteUpdatesFailure(response.data));
    }
  } catch (error) {
    yield put(UpdateCreationAction.deleteUpdatesFailure(error));
  }
}

export function* updateCreationStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateStatus, action.data);
    yield put(UpdateCreationAction.updateCreationStatusSuccess(response.data));
  } catch (error) {
    yield put(UpdateCreationAction.updateCreationStatusFailure(error));
  }
}
