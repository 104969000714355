import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./ImageUploadMediaDialog.module.css";
import CloseIcon from "@mui/icons-material/Close";
import ImageUploaderDragDrop from "./ImageUploadDragDrop";
import TextFieldComponent from "../../../../../components/ui-elements/text-field/TextFieldComponent";
import {
  Avatar,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";
import {
  LoadingButtonComponent,
  MediaUploadComponent,
  RadioButtonGroupComponent,
  StyledRadio,
  SwitchComponent,
} from "components/ui-elements";
import MediaAssets from "assets";
import { Fragment, useEffect, useState } from "react";
import { TypeOfUploadListDetails } from "../MediaGalleryConstants";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/project-media-gallery-s3-upload/ProjectMediaGalleryUpload";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";

const actionDispatch = (dispatch: any) => {
  return {
    fileUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    deleteFile: (data: any) =>
      dispatch(FileUploadActions.fileDeleteRequest(data)),
  };
};

interface IImageUploadDialog {
  shouldOpen: boolean;
  title: string;
  showWarning?: boolean;
  cancelHandler: () => void;
  uploadContentType?: any;
  showCategory?: any;
  uploadListDetails?: any;
  setMediaUploadList?: any;
  imageUploadHandler?: any;
  mediaUploadFor?: any;
  singleMediaUpdateData?: any;
  setSingleMediaUpdateData?: any;
  isEditMode?: any;
  setIsEditMode?: any;
  isViewMode?: any;
  setIsViewMode?: any;
  setMediaUploadFor?: any;
}

const ImageUploadDialog = ({
  shouldOpen,
  title,
  showWarning = false,
  cancelHandler,
  showCategory,
  uploadContentType,
  imageUploadHandler,
  mediaUploadFor,
  singleMediaUpdateData,
  setSingleMediaUpdateData,
  isEditMode,
  setIsEditMode,
  isViewMode,
  setIsViewMode,
  setMediaUploadFor,
}: IImageUploadDialog) => {
  const handleClose = () => {
    cancelHandler();
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    resetForm,
    errors,
  } = useFormikContext<any>();

  const { fileUpload, deleteFile } = actionDispatch(useDispatch());
  const isMediaUploadLoading = useSelector(
    (state: any) => state?.projectMediaGallery?.isLoading
  );

  const [imageUpload, setImageUpload] = useState(
    singleMediaUpdateData?.mediaContent?.value?.url
      ? singleMediaUpdateData?.mediaContent?.value?.url
      : ""
  );
  const [imageTitle, setImageTitle] = useState(
    singleMediaUpdateData?.name ? singleMediaUpdateData?.name : ""
  );
  const [videoTitle, setVideoTitle] = useState(
    singleMediaUpdateData?.name ? singleMediaUpdateData?.name : ""
  );
  const [videoUrl, setVideoUrl] = useState("");
  const [category, setCategory] = useState(
    singleMediaUpdateData.category ? singleMediaUpdateData?.category : ""
  );
  const [pageWidthImage, setPageWidthImage] = useState(
    singleMediaUpdateData?.isPageWidthEnabled
      ? singleMediaUpdateData?.isPageWidthEnabled
      : false
  );
  const fileUploadDetails = useSelector(
    (state: any) => state?.projectMediaGallery?.imageDetails
  );
  const [isMediaUploading, setIsMediaUploading] = useState(false);
  const [id, setId] = useState(
    singleMediaUpdateData?.mediaContent?.key
      ? singleMediaUpdateData?.mediaContent?.key
      : Date.now().toString()
  );
  const [thumbnailImageUpload, setThumbnailImageUpload] = useState(
    singleMediaUpdateData?.thumbnail ? singleMediaUpdateData?.thumbnail : ""
  );
  const [mediaUploadType, setMediaUploadType] = useState(
    singleMediaUpdateData?.mediaContent?.value?.mediaType
      ? singleMediaUpdateData?.mediaContent?.value?.mediaType
      : uploadContentType.length > 0 && uploadContentType !== "all"
      ? uploadContentType
      : "image"
  );

  const [uploadStatus, setUploadStatus] = useState(
    singleMediaUpdateData?.status ? singleMediaUpdateData?.status : "1001"
  );
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const isProjectViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const mediaUploadTypeSelection = [
    {
      id: 1,
      label: "Image",
      value: "image",
    },
    {
      id: 2,
      label: "Video",
      value: "video",
    },
  ];

  const handleuploadImage = (file: any) => {
    let imageUrl;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUpload(data);
      };
      reader.readAsArrayBuffer(file);
    } else {
      imageUrl = null;
    }
  };

  useEffect(() => {
    if (
      mediaUploadFor === TypeOfUploadListDetails.droneShootsUploadListDetails
    ) {
      setMediaUploadType("video");
    }
  }, [mediaUploadFor]);

  useEffect(() => {
    if (singleMediaUpdateData?.mediaContentType === "video") {
      setVideoUrl(singleMediaUpdateData?.mediaContent?.value?.url);
    }
    if (isMediaUploading && mediaUploadType === "image") {
      setImageUpload(fileUploadDetails?.url);
    }
  }, [fileUploadDetails?.url, isMediaUploading]);

  const handleRemoveDuplicateId = (
    id: any,
    listItems: any,
    updatedItem: any
  ) => {
    if (id) {
      listItems?.map((item: any, index: any) => {
        if (item?.mediaContent?.key === id) {
          listItems?.splice(index, 1);
          listItems?.splice(index, 0, updatedItem);
        }
      });
      return listItems;
    }
  };

  const handleSubmitValuesWithoutCategory = () => {
    switch (mediaUploadFor) {
      case TypeOfUploadListDetails.imageUploadListDetails:
        if (isEditMode === true) {
          return handleRemoveDuplicateId(
            singleMediaUpdateData?.mediaContent?.key,
            values?.mediaGallery?.images,
            {
              mediaContent: {
                name: imageUpload.split("/")[3],
                key: id?.toString(),
                value: {
                  url: imageUpload,
                  size: 0,
                  width: 1024,
                  height: 1024,
                  mediaType: mediaUploadType,
                },
              },
              mediaContentType: mediaUploadType,
              isPageWidthEnabled: pageWidthImage,
              name: imageTitle,
              status: uploadStatus,
              thumbnail: imageUpload,
            }
          );
        } else {
          if (values?.mediaGallery?.images) {
            return setFieldValue(`mediaGallery.images`, [
              ...values?.mediaGallery?.images,
              {
                mediaContent: {
                  name: imageUpload?.split("/")[3],
                  key: id?.toString(),
                  value: {
                    url: imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: false,
                name: imageTitle,
                status: "1001",
                thumbnail: imageUpload,
              },
            ]);
          } else {
            return setFieldValue("mediaGallery.images", [
              {
                mediaContent: {
                  name: imageUpload?.split("/")[3],
                  key: id?.toString(),
                  value: {
                    url: imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: false,
                name: imageTitle,
                status: "1001",
                thumbnail: imageUpload,
              },
            ]);
          }
        }

      case TypeOfUploadListDetails.videoUploadListDetails:
        if (isEditMode === true) {
          return handleRemoveDuplicateId(
            singleMediaUpdateData?.mediaContent?.key,
            values?.mediaGallery?.videos,
            {
              mediaContent: {
                name: imageUpload?.split("/")[3],
                key: id?.toString(),
                value: {
                  url: videoUrl,
                  size: 0,
                  width: 1024,
                  height: 1024,
                  mediaType: mediaUploadType,
                },
              },
              mediaContentType: mediaUploadType,
              isPageWidthEnabled: pageWidthImage,
              name: videoTitle,
              status: uploadStatus,
            }
          );
        } else {
          if (values?.mediaGallery?.videos) {
            return setFieldValue(`mediaGallery.videos`, [
              ...values?.mediaGallery?.videos,
              {
                mediaContent: {
                  name: "mediaGallery videos",
                  key: id?.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: videoTitle,
                status: "1001",
              },
            ]);
          } else {
            return setFieldValue("mediaGallery.videos", [
              {
                mediaContent: {
                  name: "mediaGallery videos",
                  key: id?.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: videoTitle,
                status: "1001",
              },
            ]);
          }
        }

      case TypeOfUploadListDetails.droneShootsUploadListDetails:
        if (isEditMode === true) {
          return handleRemoveDuplicateId(
            singleMediaUpdateData?.mediaContent?.key,
            values?.mediaGallery?.droneShoots,
            {
              mediaContent: {
                name: "droneShoots",
                key: id?.toString(),
                value: {
                  url: mediaUploadType === "video" ? videoUrl : imageUpload,
                  size: 0,
                  width: 1024,
                  height: 1024,
                  mediaType: mediaUploadType,
                },
              },
              mediaContentType: mediaUploadType,
              isPageWidthEnabled: pageWidthImage,
              name: mediaUploadType === "image" ? imageTitle : videoTitle,
              status: "1001",
            }
          );
        } else {
          if (values?.mediaGallery?.droneShoots) {
            return setFieldValue(`mediaGallery.droneShoots`, [
              ...values?.mediaGallery?.droneShoots,
              {
                mediaContent: {
                  name: "droneShoots",
                  key: id.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: mediaUploadType === "image" ? imageTitle : videoTitle,
                status: "1001",
              },
            ]);
          } else {
            return setFieldValue(`mediaGallery.droneShoots`, [
              {
                mediaContent: {
                  name: "droneShoots",
                  key: id?.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: mediaUploadType === "image" ? imageTitle : videoTitle,
                status: "1001",
              },
            ]);
          }
        }

      case TypeOfUploadListDetails.threeSixtyUploadListDetails:
        if (isEditMode === true) {
          return handleRemoveDuplicateId(
            singleMediaUpdateData?.mediaContent?.key,
            values?.mediaGallery?.threeSixtyImages,
            {
              mediaContent: {
                name: imageUpload?.split("/")[3],
                key: id?.toString(),
                value: {
                  url: imageUpload,
                  size: 0,
                  width: 1024,
                  height: 1024,
                  mediaType: "image",
                },
              },
              mediaContentType: mediaUploadType,
              isPageWidthEnabled: pageWidthImage,
              name: imageTitle,
              status: uploadStatus,
            }
          );
        } else {
          if (values?.mediaGallery?.threeSixtyImages) {
            return setFieldValue(`mediaGallery.threeSixtyImages`, [
              ...values?.mediaGallery?.threeSixtyImages,
              {
                mediaContent: {
                  name: imageUpload?.split("/")[3],
                  key: id?.toString(),
                  value: {
                    url: imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: "image",
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: imageTitle,
                status: "1001",
              },
            ]);
          } else {
            return setFieldValue(`mediaGallery.threeSixtyImages`, [
              {
                mediaContent: {
                  name: imageUpload?.split("/")[3],
                  key: id?.toString(),
                  value: {
                    url: imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: imageTitle,
                status: true,
              },
            ]);
          }
        }

      case TypeOfUploadListDetails.virtualVideoUploadListDetails:
        if (isEditMode === true) {
          return handleRemoveDuplicateId(
            singleMediaUpdateData?.mediaContent?.key,
            values?.mediaGallery?.virtualWalkthrough,
            {
              mediaContent: {
                name: "virtualWalkthrough",
                key: id?.toString(),
                value: {
                  url: mediaUploadType === "video" ? videoUrl : imageUpload,
                  size: 0,
                  width: 1024,
                  height: 1024,
                  mediaType: mediaUploadType,
                },
              },
              mediaContentType: mediaUploadType,
              isPageWidthEnabled: pageWidthImage,
              name: mediaUploadType === "image" ? imageTitle : videoTitle,
              status: "1001",
            }
          );
        } else {
          if (values?.mediaGallery?.virtualWalkthrough) {
            return setFieldValue(`mediaGallery.virtualWalkthrough`, [
              ...values?.mediaGallery?.virtualWalkthrough,
              {
                mediaContent: {
                  name: "virtualWalkthrough",
                  key: id.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: mediaUploadType === "image" ? imageTitle : videoTitle,
                status: "1001",
              },
            ]);
          } else {
            return setFieldValue(`mediaGallery.virtualWalkthrough`, [
              {
                mediaContent: {
                  name: "virtualWalkthrough",
                  key: id?.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: mediaUploadType === "image" ? imageTitle : videoTitle,
                status: "1001",
              },
            ]);
          }
        }

      default:
        break;
    }
  };

  const handleSubmitValuesWithCategory = () => {
    switch (mediaUploadFor) {
      case TypeOfUploadListDetails.imageUploadListDetails:
        if (isEditMode === true) {
          return handleRemoveDuplicateId(
            singleMediaUpdateData?.mediaContent?.key,
            values?.latestMediaGallery?.images,
            {
              mediaContent: {
                name: imageUpload?.split("/")[3],
                key: id?.toString(),
                value: {
                  url: imageUpload,
                  size: 0,
                  width: 1024,
                  height: 1024,
                  mediaType: mediaUploadType,
                },
              },
              mediaContentType: mediaUploadType,
              isPageWidthEnabled: pageWidthImage,
              name: imageTitle,
              status: uploadStatus,
              category: category,
            }
          );
        } else {
          if (values?.latestMediaGallery?.images) {
            return setFieldValue(`latestMediaGallery.images`, [
              ...values?.latestMediaGallery?.images,
              {
                mediaContent: {
                  name: imageUpload?.split("/")[3],
                  key: id?.toString(),
                  value: {
                    url: imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: false,
                name: imageTitle,
                status: "1001",
                category: category,
              },
            ]);
          } else {
            return setFieldValue(`latestMediaGallery.images`, [
              {
                mediaContent: {
                  name: imageUpload?.split("/")[3],
                  key: id?.toString(),
                  value: {
                    url: imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: false,
                name: imageTitle,
                status: "1001",
                category: category,
              },
            ]);
          }
        }

      case TypeOfUploadListDetails.videoUploadListDetails:
        if (isEditMode === true) {
          return handleRemoveDuplicateId(
            singleMediaUpdateData?.mediaContent?.key,
            values?.latestMediaGallery?.videos,
            {
              mediaContent: {
                name: "latestMediaGallery video",
                key: id?.toString(),
                value: {
                  url: videoUrl,
                  size: 0,
                  width: 1024,
                  height: 1024,
                  mediaType: mediaUploadType,
                },
              },
              mediaContentType: mediaUploadType,
              isPageWidthEnabled: pageWidthImage,
              name: videoTitle,
              status: uploadStatus,
            }
          );
        } else {
          if (values?.latestMediaGallery?.videos) {
            return setFieldValue(`latestMediaGallery.videos`, [
              ...values?.latestMediaGallery?.videos,
              {
                mediaContent: {
                  name: "latestMediaGallery video",
                  key: id?.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: videoTitle,
                status: "1001",
              },
            ]);
          } else {
            return setFieldValue(`latestMediaGallery.videos`, [
              {
                mediaContent: {
                  name: "latestMediaGallery video",
                  key: id?.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: videoTitle,
                status: "1001",
              },
            ]);
          }
        }

      case TypeOfUploadListDetails.droneShootsUploadListDetails:
        if (isEditMode === true) {
          return handleRemoveDuplicateId(
            singleMediaUpdateData?.mediaContent?.key,
            values?.latestMediaGallery?.droneShoots,
            {
              mediaContent: {
                name: "droneShoots",
                key: id?.toString(),
                value: {
                  url: mediaUploadType === "video" ? videoUrl : imageUpload,
                  size: 0,
                  width: 1024,
                  height: 1024,
                  mediaType: mediaUploadType,
                },
              },
              mediaContentType: mediaUploadType,
              isPageWidthEnabled: pageWidthImage,
              name: mediaUploadType === "image" ? imageTitle : videoTitle,
              status: uploadStatus,
            }
          );
        } else {
          if (values?.latestMediaGallery?.droneShoots) {
            return setFieldValue(`latestMediaGallery.droneShoots`, [
              ...values?.latestMediaGallery?.droneShoots,
              {
                mediaContent: {
                  name: "droneShoots",
                  key: id?.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: mediaUploadType === "image" ? imageTitle : videoTitle,
                status: "1001",
              },
            ]);
          } else {
            return setFieldValue(`latestMediaGallery.droneShoots`, [
              {
                mediaContent: {
                  name: "droneShoots",
                  key: id?.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: mediaUploadType === "image" ? imageTitle : videoTitle,
                status: "1001",
              },
            ]);
          }
        }

      case TypeOfUploadListDetails.threeSixtyUploadListDetails:
        if (isEditMode === true) {
          return handleRemoveDuplicateId(
            singleMediaUpdateData?.mediaContent?.key,
            values?.latestMediaGallery?.threeSixtyImages,
            {
              mediaContent: {
                name: imageUpload?.split("/")[3],
                key: id?.toString(),
                value: {
                  url: imageUpload,
                  size: 0,
                  width: 1024,
                  height: 1024,
                  mediaType: mediaUploadType,
                },
              },
              mediaContentType: mediaUploadType,
              isPageWidthEnabled: pageWidthImage,
              name: imageTitle,
              status: uploadStatus,
              category: category,
            }
          );
        } else {
          if (values?.latestMediaGallery?.threeSixtyImages) {
            return setFieldValue(`latestMediaGallery.threeSixtyImages`, [
              ...values?.latestMediaGallery?.threeSixtyImages,
              {
                mediaContent: {
                  name: imageUpload?.split("/")[3],
                  key: id?.toString(),
                  value: {
                    url: imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: "image",
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: imageTitle,
                status: "1001",
                category: category,
              },
            ]);
          } else {
            return setFieldValue(`latestMediaGallery.threeSixtyImages`, [
              {
                mediaContent: {
                  name: imageUpload?.split("/")[3],
                  key: id?.toString(),
                  value: {
                    url: imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: imageTitle,
                status: "1001",
                category: category,
              },
            ]);
          }
        }

      case TypeOfUploadListDetails.virtualVideoUploadListDetails:
        if (isEditMode === true) {
          return handleRemoveDuplicateId(
            singleMediaUpdateData?.mediaContent?.key,
            values?.latestMediaGallery?.virtualWalkthrough,
            {
              mediaContent: {
                name: "latestMediaGallery virtualWalkthrough",
                key: id?.toString(),
                value: {
                  url: videoUrl,
                  size: 0,
                  width: 1024,
                  height: 1024,
                  mediaType: mediaUploadType,
                },
              },
              mediaContentType: mediaUploadType,
              isPageWidthEnabled: pageWidthImage,
              name: videoTitle,
              status: uploadStatus,
            }
          );
        } else {
          if (values?.latestMediaGallery?.virtualWalkthrough) {
            return setFieldValue(`latestMediaGallery.virtualWalkthrough`, [
              ...values?.latestMediaGallery?.virtualWalkthrough,
              {
                mediaContent: {
                  name: "latestMediaGallery virtualWalkthrough",
                  key: id?.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: videoTitle,
                status: "1001",
              },
            ]);
          } else {
            return setFieldValue(`latestMediaGallery.virtualWalkthrough`, [
              {
                mediaContent: {
                  name: "latestMediaGallery virtualWalkthrough",
                  key: id?.toString(),
                  value: {
                    url: mediaUploadType === "video" ? videoUrl : imageUpload,
                    size: 0,
                    width: 1024,
                    height: 1024,
                    mediaType: mediaUploadType,
                  },
                },
                mediaContentType: mediaUploadType,
                isPageWidthEnabled: pageWidthImage,
                name: videoTitle,
                status: "1001",
              },
            ]);
          }
        }

      default:
        break;
    }
  };

  const disableUploadBtn = () => {
    if (mediaUploadType === "image") {
      return !imageUpload || !imageTitle ? true : false;
    } else if (mediaUploadType === "video") {
      return !videoTitle || !videoUrl ? true : false;
    } else {
      return false;
    }
  };

  const handleSubmitValues = () => {
    if (showCategory) {
      return handleSubmitValuesWithCategory();
    } else {
      return handleSubmitValuesWithoutCategory();
    }
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleOkConfirmationDialog = () => {
    if (imageUpload) {
      deleteFile(imageUpload?.split("/")?.pop());
      setImageUpload("");
    }
    setOpenConfirmationDialog(false);
  };
  return (
    <Fragment>
      {openConfirmationDialog && (
        <ConfirmationDialog
          shouldOpen={openConfirmationDialog}
          title="Delete Media Image?"
          content="This action will Delete Media Image data. Are you sure you want to continue?"
          okText="Delete"
          cancelHandler={handleCloseConfirmationDialog}
          okHandler={handleOkConfirmationDialog}
        />
      )}
      <Dialog
        data-testid="main-dialog-box"
        maxWidth="lg"
        open={shouldOpen}
        onClose={handleClose}
        disableEscapeKeyDown={true}
      >
        <DialogTitle data-testid="dialog-title" color="black">
          <Typography variant="h5" component="h5">
            {uploadContentType === "image" ? "Upload" : "Upload Media"}
          </Typography>
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          className={styles["close-icon-btn-wrapper"]}
          aria-label="close-icon"
        >
          <CloseIcon className={styles["close-icon-btn"]} />
        </IconButton>

        <DialogContent>
          {showWarning ? (
            <Alert
              severity="warning"
              className={styles["confirm-dialog-alert"]}
            >
              <AlertTitle>
                <strong>Warning</strong>
              </AlertTitle>
              This action cannot be done!
            </Alert>
          ) : null}

          <DialogContentText>
            <div>
              <div className={styles["upload-type-radio"]}>
                <RadioButtonGroupComponent
                  name="uploadContentType"
                  value={uploadContentType}
                  data-testid="select-option"
                  className={styles["descrition-radio-btn"]}
                >
                  {mediaUploadTypeSelection?.map((role) => {
                    return (
                      <FormControlLabel
                        key={role?.id}
                        name={role?.value}
                        value={role?.value}
                        control={<StyledRadio />}
                        label={role?.label}
                        checked={mediaUploadType === role?.value}
                        disabled={
                          isViewMode
                            ? true
                            : mediaUploadType !== role?.value
                            ? true
                            : false
                        }
                      />
                    );
                  })}
                </RadioButtonGroupComponent>
              </div>

              <div className={styles["form-section"]}>
                <Stack flexDirection={"column"} justifyContent={"start"}>
                  {mediaUploadType === "image" && (
                    <div className={styles["media-upload-content"]}>
                      <MediaUploadComponent
                        disabled={isViewMode}
                        loading={isMediaUploadLoading}
                        ondrop={handleChange}
                        onBlur={handleBlur}
                        name={"mediaImage"}
                        onChange={(event: any) => {
                          const fileDetails = event?.target.files[0];
                          handleuploadImage(fileDetails);
                          setIsMediaUploading(true);
                        }}
                        inputId={Date.now()}
                        previewimage={imageUpload}
                        cancelimage={() => {
                          setOpenConfirmationDialog(true);
                        }}
                        fieldhelpertext={""}
                      />
                    </div>
                  )}
                </Stack>

                <div>
                  {showCategory && mediaUploadType === "image" && (
                    <TextFieldComponent
                      className={styles["textfield-section"]}
                      type="select"
                      placeholder="Enter"
                      name={"category"}
                      fieldtitle={"Category"}
                      onBlur={handleBlur}
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      label={"Category"}
                      select
                      disabled={isViewMode || isProjectViewMode ? true : false}
                    >
                      <MenuItem value={"4001"}> Project Boundry </MenuItem>
                      <MenuItem value={"4002"}> Internal Roads </MenuItem>
                      <MenuItem value={"4003"}>
                        {" "}
                        Amenities and Club House{" "}
                      </MenuItem>
                      <MenuItem value={"4004"}>
                        {" "}
                        Other Infra Development
                      </MenuItem>
                      <MenuItem value={"4005"}> Master Design</MenuItem>
                    </TextFieldComponent>
                  )}

                  <div>
                    {mediaUploadType === "image" && (
                      <div className={styles["text-select-elements-wrapper"]}>
                        <TextFieldComponent
                          className={styles["textfield-section"]}
                          placeholder="Enter"
                          name={"imageTitle"}
                          label="Image Title"
                          onBlur={handleBlur}
                          value={imageTitle}
                          required
                          onChange={(e) => setImageTitle(e.target.value)}
                          inputProps={{ maxLength: 50 }}
                          disabled={
                            isViewMode || isProjectViewMode ? true : false
                          }
                        />
                      </div>
                    )}

                    {mediaUploadType === "video" && (
                      <div style={{ marginBottom: "30px", marginTop: "30px" }}>
                        <div className={styles["textfield-wrapper"]}>
                          <TextFieldComponent
                            className={styles["textfield-section"]}
                            placeholder="Enter"
                            name={"videoTitle"}
                            label="Video Title"
                            onBlur={handleBlur}
                            value={videoTitle}
                            required
                            onChange={(e) => setVideoTitle(e.target.value)}
                            inputProps={{ maxLength: 50 }}
                            disabled={
                              isViewMode || isProjectViewMode ? true : false
                            }
                          />
                        </div>

                        <div>
                          <TextFieldComponent
                            className={styles["textfield-section"]}
                            placeholder="Enter"
                            name={"videoUrl"}
                            label="Video URL"
                            onBlur={handleBlur}
                            value={videoUrl}
                            required
                            onChange={(e) => setVideoUrl(e.target.value)}
                            disabled={
                              isViewMode || isProjectViewMode ? true : false
                            }
                          />
                        </div>
                      </div>
                    )}

                    {isViewMode ? (
                      <div className={styles["upload-btn-wrapper"]}>
                        <LoadingButtonComponent
                          className="btn btn-dark"
                          variant="contained"
                          disableElevation
                          data-testid="upload-btn-dialog"
                          onClick={() => {
                            setIsEditMode(true);
                            setIsViewMode(false);
                          }}
                          disabled={isProjectViewMode}
                        >
                          Edit
                        </LoadingButtonComponent>
                      </div>
                    ) : (
                      <div className={styles["upload-btn-wrapper"]}>
                        <LoadingButtonComponent
                          className="btn btn-dark"
                          disabled={disableUploadBtn() || isProjectViewMode}
                          variant="contained"
                          disableElevation
                          data-testid="upload-btn-dialog"
                          onClick={() => {
                            handleSubmitValues();
                            handleClose();
                            setIsEditMode(false);
                            setIsViewMode(false);
                          }}
                        >
                          {isEditMode ? "Upload Changes" : "Upload"}
                        </LoadingButtonComponent>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ImageUploadDialog;
