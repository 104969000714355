import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Container from "@mui/material/Container";
import { FormFieldContainer } from "components/ui-elements";
import Grid from "@mui/material/Grid";
import PageHeading from "../../../components/page-heading/PageHeading";
import { Typography } from "@mui/material";
import { RouteConstants } from "../../../routes/RouteConstants";

const ProjectManagementDashboard = () => {
  const navigate = useNavigate();

  const breadcrumbRoutes: any = [
    {
      name: "Project Management",
      route: RouteConstants.projectManagementDashboard,
    },
  ];

  const navigateToProjects = () => {
    navigate(RouteConstants.projectList);
  };

  return (
    <Fragment>
      <PageHeading title="Project Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

      <Container>
        <Grid className="heading-action-holder">
          <Typography className="heading">Dashboard</Typography>
          <Button
            variant="contained"
            onClick={navigateToProjects}
            // autoFocus
            endIcon={<ChevronRightRoundedIcon />}
            disableElevation
            className="btn btn-dark"
          >
            Go To Projects
          </Button>
        </Grid>

        <FormFieldContainer>
          <Typography
            className="heading"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            Dashboard content currently not available
          </Typography>
        </FormFieldContainer>
      </Container>
    </Fragment>
  );
};

export default ProjectManagementDashboard;
