import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { portfolioPageAction } from "redux-container/page-management/PagePortfolioRedux";
import { projectContentsAction } from "redux-container/page-management/ProjectContentsRedux";
import { call, put } from "redux-saga/effects";

export function* updatePortfolioPage(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updatePortfolioPage, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(portfolioPageAction.updatePortfolioPageSuccess(response.data));
    } else {
      yield put(portfolioPageAction.updatePortfolioPageFailure(response.data));
    }
  } catch (error) {
    yield put(portfolioPageAction.updatePortfolioPageFailure(error));
  }
}

export function* getPortfolioPage(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPortfolioPage, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(portfolioPageAction.getPortfolioPageSuccess(response.data));
    } else {
      yield put(portfolioPageAction.getPortfolioPageFailure(response.data));
    }
  } catch (error) {
    yield put(portfolioPageAction.getPortfolioPageFailure(error));
  }
}

export function* addPortfolioPage(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addPortfolioPage, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(portfolioPageAction.addPortfolioPageSuccess(response.data));
    } else {
      yield put(portfolioPageAction.addPortfolioPageFailure(response.data));
    }
  } catch (error) {
    yield put(portfolioPageAction.addPortfolioPageFailure(error));
  }
}

export function* getProjectContents(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getProjectContents, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(projectContentsAction.getProjectContentsSuccess(response.data));
    } else {
      yield put(projectContentsAction.getProjectContentsFailure(response.data));
    }
  } catch (error) {
    yield put(projectContentsAction.getProjectContentsFailure(error));
  }
}
