import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import PageManagementCreationAction from "redux-container/page-management/PageManagementRedux";

export function* addPageManagementDataSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addPageManagementData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PageManagementCreationAction.addPageManagementDataSuccess(response.data));
    } else {
      yield put(PageManagementCreationAction.addPageManagementDataFailure(response.data));
    }
  } catch (error) {
    yield put(PageManagementCreationAction.addPageManagementDataFailure(error));
  }
}

export function* updatePageManagementDataSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updatePageManagementData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PageManagementCreationAction.updatePageManagementDataSuccess(response.data));
    } else {
      yield put(PageManagementCreationAction.updatePageManagementDataFailure(response.data));
    }
  } catch (error) {
    yield put(PageManagementCreationAction.updatePageManagementDataFailure(error));
  }
}

export function* getPageManagementDataSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPageManagementData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PageManagementCreationAction.getPageManagementDataSuccess(response.data));
    } else {
      yield put(PageManagementCreationAction.getPageManagementDataFailure(response.data));
    }
  } catch (error) {
    yield put(PageManagementCreationAction.getPageManagementDataFailure(error));
  }
}

