import { MediaUploadComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";

interface IUpcomingInfraStoryMedia {
  index?: any;
  isViewModeProp?: any;
  currentlyOpenedIndex?: any;
  handleUpcomingInfraStoryMedia: (imageIndex: number) => void;
}

function UpcomingInfraStoryMedia({
  index,
  isViewModeProp,
  currentlyOpenedIndex,
  handleUpcomingInfraStoryMedia,
}: IUpcomingInfraStoryMedia) {
  const { values, handleChange, setFieldValue, handleBlur, errors, touched } =
    useFormikContext<IInitinalValueProjectContent>();

  const { imageDetails, isLoading, isSuccess, isDelete } = useSelector(
    (state: any) => state.fileUpload
  );
  const dispatch = useDispatch();

  const [upComingInfraStoryMedia, setUpcomingInfraStoryMedia] = useState<any[]>(
    []
  );
  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));

  //upload Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const insightMediaImageUploadSuccess = `opportunityDoc.upcomingInfraStory.stories.${index}.media.value.url`;
      if (
        upComingInfraStoryMedia[0] === insightMediaImageUploadSuccess &&
        index === currentlyOpenedIndex
      ) {
        setFieldValue(insightMediaImageUploadSuccess, imageDetails?.url);
        handleUpcomingInfraStoryMedia(999);
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  //delete Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const insightMediaImageUploadDelete = `opportunityDoc.upcomingInfraStory.stories.${index}.media.value.url`;
      if (
        insightMediaImageUploadDelete === upComingInfraStoryMedia[0] &&
        index === currentlyOpenedIndex
      ) {
        const empty = "";
        setFieldValue(insightMediaImageUploadDelete, empty);
      }
    }
    getAllInsightsCreationFunction();
  }, [isDelete]);

  const handleuploadImage = (event: any) => {
    const file = event?.target?.files[0];
    const MediaId = event?.target?.id;

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
        setUpcomingInfraStoryMedia((prevState) => [...prevState, MediaId]);
      };
      handleUpcomingInfraStoryMedia(index);
      reader.readAsArrayBuffer(file);
      const currentInfraStoryMediaName = `opportunityDoc.upcomingInfraStory.stories.${index}.media.name`;
      const currentInfraStoryMediaKey = `opportunityDoc.upcomingInfraStory.stories.${index}.media.key`;
      setFieldValue(currentInfraStoryMediaName, file?.name);
      setFieldValue(currentInfraStoryMediaKey, file?.name);
    }
  };

  const handleConfirmDelete = () => {
    let fileName =
      values?.opportunityDoc?.upcomingInfraStory?.stories[index]?.media?.name;
    let fileUrl = `opportunityDoc.upcomingInfraStory.stories.${index}.media.value.url`;
    setUpcomingInfraStoryMedia((prevState) => [...prevState, fileUrl]);
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
  };

  return (
    <MediaUploadComponent
      ondrop={handleChange}
      loading={isLoading && index === currentlyOpenedIndex}
      name={`opportunityDoc.upcomingInfraStory.stories.${index}.media.value.url`}
      value={
        values?.opportunityDoc?.upcomingInfraStory?.stories[index]?.media?.value
          ?.url
      }
      onChange={(event: any) => {
        handleuploadImage(event);
      }}
      previewimage={
        values?.opportunityDoc?.upcomingInfraStory?.stories[index]?.media?.value
          ?.url
      }
      cancelimage={() => {
        handleUpcomingInfraStoryMedia(index);
        handleConfirmDelete();
      }}
      onBlur={handleBlur}
      fieldhelpertext={
        getIn(touched, "opportunityDoc.escalationGraph.title") &&
        getIn(errors, "opportunityDoc.escalationGraph.title")
      }
      disabled={isViewMode || isViewModeProp}
    />
  );
}

export default UpcomingInfraStoryMedia;
