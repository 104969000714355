import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  //get all category 
  getCategoriesSuccess: ["response"],
  getCategoriesFailure: ["error"],
  getCategoriesRequest: ["data"],

  setCategoriesState: ["key", "value"],
});

export const CategoriesTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  categories: [],
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
};

/* Get Categories */
export const getCategoriesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const getCategoriesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      categories: response.data,
      isSuccess: true,
      isLoading: false,
    };
  }
  //   return {
  //     ...state,
  //     isLoading: false,
  //     snackBarError: action.response ? action.response.message : "Failed to load",
  //   };
};

export const getCategoriesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: true,
    error: action.error.message,
  };
};

export const setCategoriesState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};


export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CATEGORIES_REQUEST]: getCategoriesRequest,
  [Types.GET_CATEGORIES_SUCCESS]: getCategoriesSuccess,
  [Types.GET_CATEGORIES_FAILURE]: getCategoriesFailure,

  [Types.SET_CATEGORIES_STATE]: setCategoriesState,
});
