export const enum DeviceTypeEnum {
  ANDROID = 1,
  IOS = 2,
  WEB = 3,
}

export class DeviceEnumsUtils {
  public static getEnumText(type: DeviceTypeEnum): string {
    switch (type) {
      case DeviceTypeEnum.ANDROID:
        return "Android";
      case DeviceTypeEnum.IOS:
        return "IOS";
      case DeviceTypeEnum.WEB:
        return "WEB";
    }
  }
  public static getDeviceEnums(): {
    label: string;
    value: DeviceTypeEnum;
  }[] {
    const options: { label: string; value: DeviceTypeEnum }[] = [
      {
        label: this.getEnumText(DeviceTypeEnum.ANDROID),
        value: DeviceTypeEnum.ANDROID,
      },
      {
        label: this.getEnumText(DeviceTypeEnum.IOS),
        value: DeviceTypeEnum.IOS,
      },
      {
        label: this.getEnumText(DeviceTypeEnum.WEB),
        value: DeviceTypeEnum.WEB,
      },
    ];
    return options;
  }
}
