import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";
import { state } from "utils/States";

const { Types, Creators } = createActions({
  getPromiseDashboardSuccess: ["response"],
  getPromiseDashboardFailure: ["error"],
  getPromiseDashboardRequest: ["data"],

  setPromiseDashboardState: ["key", "value"],
});

export const PromiseTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  promiseDashboardData: null,
  error: null,
  isLoading: false,
  snackBarError: "",
  isGraphDataFetched: false,
};

/*  get Promise Dashboard  */
export const getPromiseDashboardRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPromiseDashboardSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      promiseDashboardData: response.data,
      isLoading: false,
      isGraphDataFetched: true,
    };
  }

  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const getPromiseDashboardFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setPromiseDashboardState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROMISE_DASHBOARD_SUCCESS]: getPromiseDashboardSuccess,
  [Types.GET_PROMISE_DASHBOARD_FAILURE]: getPromiseDashboardFailure,
  [Types.GET_PROMISE_DASHBOARD_REQUEST]: getPromiseDashboardRequest,

  [Types.SET_PROMISE_DASHBOARD_STATE]: setPromiseDashboardState,
});
