import { Fragment, useEffect, useState } from "react";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import MediaAssets from "../../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  AutocompleteComponent,
  ButtonComponent,
  InfoComponent,
  SwitchComponent,
  TextFieldComponent,
} from "../../../components/ui-elements";
import styles from "./ProjectList.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Checkbox,
  CardMedia,
  Card,
  Box,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { ProjectListStyleWrapper } from "./ProjectListStyleWrapper.style";
import { launchPhasesAction } from "redux-container/project-management/launch-phases/LaunchPhasesRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import {
  ActionTypeEnumInsight,
  ActionTypeEnumUtilsInsight,
  ProjectActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import {
  ProjectStatusTypeEnum,
  StatusTypeEnumInsight,
  StatusTypeEnumUtilsInsight,
} from "enumerations/StatusTypeEnum";
import projectListActions from "redux-container/project-management/ProjectListRedux";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import { exportAsExcel } from "utils/ExportFileUtils";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import Retry from "components/retry/Retry";
import { AllProjectFaqsAction } from "redux-container/project-management/ProjectAllFaqsRedux";
import { GetProjectCategoriesAction } from "redux-container/project-management/ProjectCategoryRedux";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";
import UserActions from "redux-container/users/UsersRedux";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { ProjectListConstants } from "./ProjectListConstants";
import ProjectDraftsList from "redux-container/project-management/project-drafts/ProjectDraftsRedux";
import { ProjectStatusTypeEnumUtils } from "enumerations/ProjectStatusTypeEnum";
import PanoramaIcon from "@mui/icons-material/Panorama";
import { priceFormatter } from "utils/AmountFormatter";
import formListActions from "redux-container/form-list/FormListRedux";

const actionDispatch = (dispatch: any) => {
  return {
    getAllLaunchPhases: (params: any) =>
      dispatch(launchPhasesAction.getAllLaunchPhasesRequest(params)),
    getProjectList: async (params: any) => {
      dispatch(projectListActions.projectListRequest(params));
    },
    getCRMProjectsRequest: async (params: any) => {
      dispatch(formListActions.getProjectsRequest(params));
    },
    setProjectState: (key: any, data: any) => {
      dispatch(projectListActions.setProjectState(key, data));
    },
    updateProjectPriority: (data: any) => {
      dispatch(projectListActions.updateProjectPriorityRequest(data));
    },
    allProjectFaqs: (data: any) =>
      dispatch(AllProjectFaqsAction.allFaqsProjectRequest(data)),
  };
};

const ProjectPublishedList = (props: any) => {
  const { selectedPublish, setSelectedPublish, searchKey, renderNoData } =
    props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    getAllLaunchPhases,
    getProjectList,
    setProjectState,
    updateProjectPriority,
    allProjectFaqs,
    getCRMProjectsRequest,
  } = actionDispatch(dispatch);

  const publishedHeadings = [
    { heading: "Project ID" },
    { heading: "Thumbnail" },
    { heading: "Display Name" },
    { heading: "CRM Launch Name" },
    { heading: "CRM Project Name" },
    { heading: "Location" },
    { heading: "Starting Price" },
    { heading: "Starting Area" },
    { heading: "Views" },
    { heading: "Created on" },
    { heading: "Published on" },
    { heading: "Modified on" },
    { heading: "Last Modified By" },
    { heading: "Status" },
    { heading: "Availability" },
    { heading: "Priority" },
    { heading: "Actions" },
  ];

  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [selectedCreatedUser, setSelectedCreatedUser] = useState<any>(null);
  const [selectedModifiedUser, setSelectedModifiedUser] = useState<any>(null);
  const [selectedCRMProject, setSelectedCRMProject] = useState<any>(null);
  const [sortByPriority, setSortByPriority] = useState(true);

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);
  const statuses = ProjectStatusTypeEnumUtils.getProjectStatusTypeEnums();

  const CRMProjects = useSelector((state: any) => state?.formsList?.projects);

  const {
    page,
    rowsPerPage,
    isDeleted,
    isUpdated,
    isLoading,
    allUsers,
    tabValue,
    totalCount,
    publishedProjects,
    fetchedAllLaunchPhasesData,
    shouldOpenFlashDialog,
    error,
    message,
    isSuccess,
  } = useSelector((state: any) => {
    return {
      page: state?.projectList?.page,
      rowsPerPage: state?.projectList?.rowsPerPage,
      isDeleted: state?.projectList?.isDeleted,
      isUpdated: state?.projectList?.isUpdated,
      isLoading: state?.projectList?.isLoading,
      allUsers: state?.users?.usersForFilters,
      tabValue: state?.projectList?.tabValue,
      totalCount: state?.projectList?.totalCount,
      publishedProjects: state?.projectList?.data?.data,
      fetchedAllLaunchPhasesData:
        state.projectLaunchPhases?.fetchedAllLaunchPhasesData,
      shouldOpenFlashDialog: state?.projectList?.shouldOpenFlashDialog,
      error: state?.projectList?.error,
      isSuccess: state?.projectList?.isSuccess,
      message: state?.projectList?.message,
    };
  });

  useEffect(() => {
    getCRMProjectsRequest({});
    getAllLaunchPhases({ size: 5, index: 1 });
  }, []);

  useEffect(() => {
    const params = {
      status: selectedStatus,
      createdBy: selectedCreatedUser?.id,
      updatedBy: selectedModifiedUser?.id,
      size: rowsPerPage,
      index: page + 1,
      byPrority: sortByPriority,
      sortingKey: "",
      sortingPriority: "",
      searchKey: searchKey,
      crmProjectName: selectedCRMProject?.name,
    };
    setSelectedPublish([]);

    let faqsParams = {
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };

    allProjectFaqs(faqsParams);
    getProjectList(params);
  }, [
    dispatch,
    selectedStatus,
    selectedCreatedUser,
    selectedModifiedUser,
    selectedCRMProject,
    rowsPerPage,
    page,
    sortByPriority,
    searchKey,
    isUpdated,
    isDeleted,
    tabValue,
  ]);

  // Checking and Removing Duplicate CRM Project Name.
  const crmProjectNamesOfPublished = CRMProjects?.filter(
    (option: any, index: any, element: any) =>
      element.findIndex((item: any) => item?.name === option?.name) === index
  );

  const handleClearButton = (): boolean => {
    if (
      selectedStatus === null &&
      selectedModifiedUser === null &&
      selectedCreatedUser === null &&
      selectedCRMProject === null &&
      searchKey === ""
    ) {
      return false;
    }
    return true;
  };

  const handleResetFilter = () => {
    setSelectedStatus(null);
    setSelectedCreatedUser(null);
    setSelectedModifiedUser(null);
    setSelectedCRMProject(null);
  };

  const handleChangePrioritySwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSortByPriority(event.target.checked);
  };

  const isSelected = (id: number) => selectedPublish.indexOf(id) !== -1;
  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selectedPublish.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPublish, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPublish.slice(1));
    } else if (selectedIndex === selectedPublish.length - 1) {
      newSelected = newSelected.concat(selectedPublish.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPublish.slice(0, selectedIndex),
        selectedPublish.slice(selectedIndex + 1)
      );
    }
    setSelectedPublish(newSelected);
  };

  //Projects Select In List.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected: any = publishedProjects.map(
        (published: any) => published?.id
      );
      setSelectedPublish(newSelected);
      return;
    }
    setSelectedPublish([]);
  };

  const handleEditIconClick = (publishedId: number, crmLaunchPhaseId: any) => {
    navigate(RouteConstants.projectCreation, {
      state: {
        isNew: false,
        ProjectListCreationId: publishedId,
        isViewMode: false,
        isEditMode: true,
        crmLaunchPhaseId: crmLaunchPhaseId,
      },
    });
  };

  const handleViewIconClick = (publishedId: number, crmLaunchPhaseId: any) => {
    navigate(RouteConstants.projectCreation, {
      state: {
        isNew: false,
        ProjectListCreationId: publishedId,
        isViewMode: true,
        crmLaunchPhaseId: crmLaunchPhaseId,
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setProjectState("page", newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProjectState("rowsPerPage", Number(event.target.value));
    setProjectState("page", 0);
  };

  const handleCloseFlashMessageDialog = () => {
    setProjectState("shouldOpenFlashDialog", false);
    setProjectState("error", false);
    setProjectState("message", false);
    setProjectState("isSuccess", false);
  };

  return (
    <Fragment>
      {shouldOpenFlashDialog && (
        <FlashMessageDialog
          shouldOpen={shouldOpenFlashDialog}
          content={message}
          isSuccess={isSuccess}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedStatus}
                    label="Status"
                    onChange={(event: any) => {
                      setSelectedStatus(event.target.value);
                      if (page !== 0) {
                        setProjectState("page", 0);
                      }
                    }}
                  >
                    {statuses.map((status: any) => {
                      return (
                        <MenuItem value={status.value} key={status.value}>
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={allUsers}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    label="Created By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedCreatedUser(newValue);
                      if (page !== 0) {
                        setProjectState("page", 0);
                      }
                    }}
                    value={selectedCreatedUser}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={allUsers}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    label="Modified By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedModifiedUser(newValue);
                      if (page !== 0) {
                        setProjectState("page", 0);
                      }
                    }}
                    value={selectedModifiedUser}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={crmProjectNamesOfPublished}
                    getOptionLabel={(option: any) => option?.name}
                    label="CRM Project Name"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedCRMProject(newValue);
                      if (page !== 0) {
                        setProjectState("page", 0);
                      }
                    }}
                    value={selectedCRMProject}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>
              {handleClearButton() ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={handleResetFilter}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
            <Grid
              container
              spacing={2}
              md={4}
              lg={4}
              xl={4}
              justifyContent="end"
            >
              <Stack justifyContent={"flex-end"} spacing={1}>
                <Typography> Sort by Priority</Typography>
                <SwitchComponent
                  checked={sortByPriority}
                  onChange={handleChangePrioritySwitch}
                />

                <InfoComponent infoContent={"Sort projects by priority"} />
              </Stack>
            </Grid>
          </Grid>

          <TableContainer
            component={Paper}
            className="table-container"
            style={{ height: window.innerHeight - 475 }}
          >
            <Table style={{ position: "relative" }}>
              <TableHead>
                <TableRow className="table-header-row">
                  <TableCell className="table-header-text" padding="checkbox">
                    <Checkbox
                      className="active-checkbox"
                      data-testid="table-head-checkbox"
                      color="primary"
                      indeterminate={
                        selectedPublish?.length > 0 &&
                        selectedPublish?.length < publishedProjects?.length
                      }
                      checked={
                        publishedProjects?.length > 0 &&
                        selectedPublish?.length === publishedProjects?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  {publishedHeadings.map((item, index) => (
                    <TableCell key={index} className="table-header-text">
                      {item.heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {publishedProjects?.length > 0 ? (
                <TableBody>
                  {publishedProjects.map((published: any) => {
                    const isItemSelected: any = isSelected(published?.id);

                    return (
                      <TableRow
                        key={published.id}
                        className="table-data-row"
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event: any) =>
                              handleClick(event, published?.id)
                            }
                            className="active-checkbox"
                            checked={isItemSelected}
                          />
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {`#ID-${published.id}`}
                        </TableCell>

                        <TableCell
                          style={{ minWidth: 120, justifyContent: "center" }}
                        >
                          {published.projectCoverImages?.homePageMedia?.value
                            ?.url ? (
                            <Card
                              elevation={0}
                              className={styles["project-image"]}
                              style={{
                                height: "80px",
                                width: "100%",
                                objectFit: "fill",
                              }}
                            >
                              <CardMedia
                                className={styles["media-area"]}
                                component={"img"}
                                alt="Image"
                                src={
                                  published.projectCoverImages?.homePageMedia
                                    ?.value?.url
                                }
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "fill",
                                }}
                              />
                            </Card>
                          ) : (
                            <PanoramaIcon
                              style={{ height: "3rem", width: "3rem" }}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 200 }}
                        >
                          {published?.launchName ? published.launchName : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 200 }}
                        >
                          {published?.generalInfoEscalationGraph
                            ?.crmLaunchPhaseName
                            ? published?.generalInfoEscalationGraph
                                ?.crmLaunchPhaseName
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 200 }}
                        >
                          {published?.generalInfoEscalationGraph?.crmProjectName
                            ? published?.generalInfoEscalationGraph
                                ?.crmProjectName
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {!published?.address?.city &&
                          !published.address?.state ? (
                            "-"
                          ) : (
                            <>
                              <div>{published?.address?.city} </div>
                              {published.address?.state}
                            </>
                          )}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 150 }}
                        >
                          {published?.priceStartingFrom
                            ? `₹${priceFormatter(published.priceStartingFrom)}`
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 150 }}
                        >
                          {published?.areaStartingFrom
                            ? `${priceFormatter(published.areaStartingFrom)}`
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {published?.fomoContent?.noOfViews
                            ? published.fomoContent?.noOfViews
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {published?.createdAt
                            ? formatDateTime(
                                published?.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {published?.createdAt
                            ? formatDateTime(
                                published?.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {published?.updatedAt &&
                          published?.projectContentUpdatedBy?.firstName
                            ? formatDateTime(
                                published?.updatedAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 150 }}
                        >
                          {published?.projectContentUpdatedBy?.firstName
                            ? published?.projectContentUpdatedBy?.firstName
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {published?.status
                            ? ProjectStatusTypeEnumUtils.getEnumText(
                                published?.status
                              )
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          <div>
                            {published?.isSoldOut ? "Sold out" : "Available"}
                          </div>
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {published?.priority ? (
                            <PrioritySelector
                              priority={published?.priority}
                              decrementpriority={() => {
                                const priorityRequestBody = {
                                  id: published?.id,
                                  priority: published?.priority - 1,
                                };
                                updateProjectPriority(priorityRequestBody);
                              }}
                              incrementpriority={() => {
                                const priorityRequestBody = {
                                  id: published?.id,
                                  priority: published?.priority + 1,
                                };
                                updateProjectPriority(priorityRequestBody);
                              }}
                              disableDecrement={
                                published?.priority == 1 ? true : false
                              }
                              isPriorityRequired={!sortByPriority}
                            />
                          ) : (
                            <PrioritySelector
                              disableDecrement={true}
                              disableIncrement={true}
                              isPriorityRequired={true}
                            />
                          )}
                        </TableCell>

                        <TableCell>
                          <Tooltip title="Edit" placement="top">
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                handleEditIconClick(
                                  published?.id,
                                  published?.crmLaunchPhaseId
                                )
                              }
                            >
                              <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="View" placement="top">
                            <IconButton
                              aria-label="view"
                              onClick={() =>
                                handleViewIconClick(
                                  published?.id,
                                  published?.crmLaunchPhaseId
                                )
                              }
                            >
                              <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <Box mt={-5} className="no-record">
                  {renderNoData()}
                </Box>
              )}
            </Table>
          </TableContainer>
          {!searchKey && (
            <TablePagination
              className={styles["table-pagination"]}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount ? totalCount : publishedProjects?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default ProjectPublishedList;
