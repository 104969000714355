import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { IPage5ProfileInitialValue } from "../page-5-profile-IniticalValue";
import ProfileImageUploadDragDrop from "pages/page-management/page-management-dashboard/page-5-profile/profile-mediaUpload/ProfileImageUploadDragDrop";

interface SecurityTipsImageDescription {
  index: any;
  currentlyOpenedIndex: number;
  handleShowInsightsCreationMediaDialog: (imageIndex: number) => void;
  isConfirmDeleteDialogOpen: boolean;
  handleCloseConfirmDialog: () => void;
  handleOpenConfirmDialog: () => void;
}

function SecurityTipsImageDescription({
  index,
  currentlyOpenedIndex,
  handleShowInsightsCreationMediaDialog,
  isConfirmDeleteDialogOpen,
  handleCloseConfirmDialog,
  handleOpenConfirmDialog,
}: SecurityTipsImageDescription) {
  const dispatch = useDispatch();
  const {
    imageDetails,
    isLoading,
    error,
    isSuccess,
    isDelete,
    isDeleteConfirmInPageManagementProfile,
    data,
  } = useSelector((state: any) => state.fileUpload);
  const { values, handleChange, handleSubmit, setFieldValue, handleBlur, touched, errors } =
    useFormikContext<IPage5ProfileInitialValue>();
  const [securityTipsImageMedia, setSecurityTipsImageMedia] = useState(false);

  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      if (currentlyOpenedIndex === index && securityTipsImageMedia) {
        const insightMediaImageUpload = `securityTips.detailedInformation[${index}].media.value.url`;
        setFieldValue(insightMediaImageUpload, imageDetails?.url);
      }
    }
    setSecurityTipsImageMedia(false);
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  //delete
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      if (currentlyOpenedIndex === index && securityTipsImageMedia) {
        const empty = "";
        const insightMediaImageUpload = `securityTips.detailedInformation[${index}].media.value.url`;
        setFieldValue(insightMediaImageUpload, empty);
      }
    }
    setSecurityTipsImageMedia(false);
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isDelete", false));
    // dispatch(FileUploadActions.setUploadFileState("fileName", ""));
  }, [isDelete]);

  const handleConfirmDelete = (values: any) => {
    let fileName =
      values?.securityTips?.detailedInformation[currentlyOpenedIndex]?.media
        ?.name;
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
    setSecurityTipsImageMedia(true);
    // {
    //   !isLoading &&
    //     dispatch(
    //       FileUploadActions.setUploadFileState(
    //         "isDeleteConfirmInPageManagementProfile",
    //         true
    //       )
    //     );
    // }
    handleCloseConfirmDialog();
  };
  const handleConfirmDeleteImage = () => {
    const insightMediaImageUpload = `securityTips.detailedInformation[${index}].media.value.url`;
    setFieldValue(insightMediaImageUpload, "");
    setSecurityTipsImageMedia(true);
    handleCloseConfirmDialog();
  };

  // const handleCloseFlashMessage = () => {
  //   dispatch(
  //     FileUploadActions.setUploadFileState(
  //       "isDeleteConfirmInPageManagementProfile",
  //       false
  //     )
  //   );
  //   // dispatch(FileUploadActions.setUploadFileState("error", null));
  // };
  const handleSetFileNameAndSize = (file: any) => {
    const fileNameOfMedia = `securityTips.detailedInformation[${index}].media.name`;
    const fileSizeOfMedia = `securityTips.detailedInformation[${index}].media.value.size`;
    setFieldValue(fileNameOfMedia, file?.name);
    setFieldValue(fileSizeOfMedia, file?.size);
  };

  const handleImageUpload = (file: any) => {
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        dispatch(FileUploadActions.fileUploadRequest(data));
        data.binary = event.target.result;
      };
      reader.readAsArrayBuffer(file);
      setSecurityTipsImageMedia(true);
      {
        !isLoading && handleSetFileNameAndSize(file);
      }
    } else {
      return;
    }
  };

  return (
    <div>
      <ProfileImageUploadDragDrop
        index={index}
        currentlyOpenedIndex={currentlyOpenedIndex}
        onDrop={handleChange}
        uploadMediaButton={false}
        dragAndDropButton={true}
        name={`securityTips.detailedInformation.${index}.media.value.url`}
        onClick={handleChange}
        onChange={(file: any) => {
          handleImageUpload(file);
          handleShowInsightsCreationMediaDialog(index);
        }}
        cancelImage={() => {
          handleOpenConfirmDialog();
          handleShowInsightsCreationMediaDialog(index); //here i m setting the particular Index
        }}
        onBlur={handleBlur}
        fieldhelpertext={
          getIn(
            touched,
            "securityTips.detailedInformation.${index}.media.value.url"
          ) &&
          getIn(errors, "securityTips.detailedInformation.${index}.media.value.url")
        }
        previewImage={
          values?.securityTips.detailedInformation[index].media.value.url
        }
      />
      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Insights Creation Media?"
        content="This action will delete the Insights Creation Media. Are you sure you want to continue?"
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        // okHandler={() => handleConfirmDelete(values)} //This is if we need to delete that image for AWS
        okHandler={() => handleConfirmDeleteImage()}
      />
      {/* {currentlyOpenedIndex === index && (
        <FlashMessageDialog
          cancel={false}
          shouldOpen={isDeleteConfirmInPageManagementProfile}
          content={data}
          isSuccess={error === null ? true : false}
          cancelHandler={handleCloseFlashMessage}
        />
      )} */}
    </div>
  );
}

export default SecurityTipsImageDescription;
