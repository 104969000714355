export const UpdatesInitialLabels: any = {
  formType: "Type Of Description",
  displayTitle: "Display Title",
  subTitle: "Sub Title",
  updateType: "Upate Type",
  shouldDisplayDate: "Display Date Toggle",
  detailedInfo: [
    {
      media: {
        value: {
          url: "Media One",
        },
      },
      description: "Description One",
    },
    {
      media: {
        value: {
          url: "Media Two",
        },
      },
      description: "Description Two",
    },
    {
      media: {
        value: {
          url: "Media Three",
        },
      },
      description: "Description Three",
    },
  ],
};
