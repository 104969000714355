import { Box, Stack, Typography } from "@mui/material";
import { AlertHelperText } from "components/ui-elements";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./QuillEditor.css";
import { stripHtml } from "utils/ValidationUtils";

// const Quill = require("react-quill").Quill;
// var Font = Quill.import("formats/font");
// Font.whitelist = ["open"];
// Quill.register(Font, true);

function QuillEditor(props: any) {
  const {
    fieldhelpertext,
    value = "",
    inputProps,
    defaultValue = "",
    id,
    name,
    onBlur,
  } = props;
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      [{ color: [] }],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "video", "image"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "align",
    "italic",
    "underline",
    "color",
    "list",
    "bullet",
    "link",
    "image",
    "video",
  ];

  const onChange = (newValue: string) => {
    console.log("QuillEditor");

    if (stripHtml(newValue).length > inputProps?.maxLength) {
      props.onChange(name, value, { isError: true });
    } else {
      props.onChange(name, newValue);
    }
  };

  return (
    <>
      {props.disabled ? (
        <Box
          sx={{
            // marginTop: "20px",
            padding: "15px",
            height: "250px",
            overflowY: "scroll",
            border: "1px solid #0000003b",
            opacity: 0.5,
          }}
        >
          <p dangerouslySetInnerHTML={{ __html: props?.value }}></p>
        </Box>
      ) : (
        <Box className={`ql-editor-wrapper`} onBlur={onBlur}>
          <ReactQuill
            theme="snow"
            id={id}
            value={props.value}
            modules={modules}
            formats={formats}
            onChange={onChange}
            style={props.style}
            placeholder={props.placeholder}
            readOnly={props.readonly}
          />
        </Box>
      )}
      <Stack mt={"0.5rem"}>
        {fieldhelpertext ? (
          <AlertHelperText color="error" severity="error">
            {fieldhelpertext || ""}
          </AlertHelperText>
        ) : (
          <Typography variant="body2" visibility={"hidden"} lineHeight={"1em"}>
            error
          </Typography>
        )}
        <AlertHelperText icon={false} color="info">
          {`${
            inputProps?.maxLength - stripHtml(value)?.length ||
            defaultValue?.length
          } Characters`}
        </AlertHelperText>
      </Stack>
    </>
  );
}

export default QuillEditor;
