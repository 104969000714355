import { Constants } from "constants/Constants";
import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  //get all Project Collection
  addProjectCollectionSuccess: ["response"],
  addProjectCollectionFailure: ["error"],
  addProjectCollectionRequest: ["data"],

  //get Project collection By ID
  getProjectCollectionByIdSuccess: ["response"],
  getProjectCollectionByIdFailure: ["error"],
  getProjectCollectionByIdRequest: ["data"],

  //delete Project collection By Id
  deleteProjectCollectionByIdSuccess: ["response"],
  deleteProjectCollectionByIdFailure: ["error"],
  deleteProjectCollectionByIdRequest: ["data"],

  //update project collection by Id
  updateProjectCollectionByIdSuccess: ["response"],
  updateProjectCollectionByIdFailure: ["error"],
  updateProjectCollectionByIdRequest: ["id", "data"],

  //update status
  changeProjectCollectionListStatusSuccess: ["response"],
  changeProjectCollectionListStatusFailure: ["error"],
  changeProjectCollectionListStatusRequest: ["data"],

  setProjectCollectionState: ["key", "value"],
});

export const ProjectCollectionCreationTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  projectCollectionData: [],
  isLoading: false,
  error: null,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  dataRecivedProjectCollection: false,
  mappedProjectCollectionList: [],
  creationProjectCollection: [],
  singleProjectCollection: [],
  isCreationProjectCollection: false,
  projectCollectionId: [],
  saveProjectCollection: false,
  auditedObject: null,
};

//Add  new Project Collection

export const addProjectCollectionRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const addProjectCollectionSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Project Collection added Successfully!",
      isLoading: false,
      auditedObject: response.data,
      dataRecivedProjectCollection: true,
    };
  }
};

export const addProjectCollectionFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
    dataRecivedProjectCollection: true,
    messsage: action?.error?.message,
  };
};

// single get Id Project Collection

export const getProjectCollectionByIdRequest = (state: any, action: any) => {
  return {
    ...state,
    isLoading: true,
    isAddedNewProeject: false,
  };
};

export const getProjectCollectionByIdSuccess = (state: any, action: any) => {
  const response = action.response;

  //   const duplicateProject = [
  //     ...state?.mappedProjectCollectionList,
  //     ...response.data.collectionsOrProjectContents,
  //   ];
  //   const removeDuplicate = new Set();
  //   const uniqueProject = duplicateProject.filter((project: any) => {
  //     const isDuplicate = removeDuplicate.has(project.id);
  //     removeDuplicate.add(project.id);
  //     if (!isDuplicate) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });

  //   const withPriorityProjectCollection = uniqueProject.map(
  //     (project: any, index: any) => {
  //       return {
  //         ...project,
  //         priority: index + 1,
  //       };
  //     }
  //   );

  const sortedProjectWithPriority = response?.data?.projectsWithPriority?.sort(
    (a: any, b: any) => a?.priority - b?.priority
  );
  console.log("sortedProjectWithPriority", sortedProjectWithPriority);

  const responseData: any = [];
  sortedProjectWithPriority?.map((item: any) => {
    response?.data?.collectionsOrProjectContents?.map((childElement: any) => {
      if (childElement?.id === item?.projectContentId) {
        responseData.push(childElement);
      }
    });
  });
  console.log("responseData", responseData);

  if (response) {
    return {
      ...state,
      error: null,
      singleProjectCollection: responseData,
      isSingleProjectCollection: true,
      isLoading: false,
      isAddedNewProeject: false,
      projectCollectionId: response.data,
    };
  }
};
// export const getProjectCollectionByIdSuccess = async (
//   state: any,
//   action: any
// ) => {
//   const response = action.response;
//   console.log("response", response);

//   const sortedProjectWithPriority =
//     await response?.data?.projectsWithPriority?.sort(
//       (a: any, b: any) => a?.priority - b?.priority
//     );
//   console.log("sortedProjectWithPriority", sortedProjectWithPriority);

//   const constructProjectCollectLists = [];

//   const responseData = await sortedProjectWithPriority?.map((item: any) =>
//     response.data.collectionsOrProjectContents?.map(
//       (childElement: any) => childElement?.id === item?.projectContentId
//     )
//   );
//   console.log("responseData", responseData);

//   const duplicateProject = [
//     ...state?.mappedProjectCollectionList,
//     ...responseData,
//   ];

//   const removeDuplicate = new Set();
//   const uniqueProject = duplicateProject.filter((project: any) => {
//     const isDuplicate = removeDuplicate.has(project.id);
//     removeDuplicate.add(project.id);
//     if (!isDuplicate) {
//       return true;
//     } else {
//       return false;
//     }
//   });

//   const withPriorityProjectCollection = uniqueProject.map(
//     (project: any, index: any) => {
//       return {
//         ...project,
//         priority: index + 1,
//       };
//     }
//   );

//   console.log(
//     "responseData",
//     responseData,
//     response.data.collectionsOrProjectContents,
//     withPriorityProjectCollection
//   );

//   return {
//     ...state,
//     error: null,
//     singleProjectCollection: withPriorityProjectCollection,
//     isSingleProjectCollection: true,
//     isLoading: false,
//     isAddedNewProeject: false,
//     projectCollectionId: response.data,
//   };
// };

export const getProjectCollectionByIdFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isAddedNewProeject: false,
    isCreationProjectCollection: true,
    creationProjectCollection: null,
  };
};

//delete Project collection By Id

export const deleteProjectCollectionByIdRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const deleteProjectCollectionByIdSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Project Collection Deleted SuccessFully",
      isLoading: false,
      dataRecivedProjectCollection: true,
    };
  }
};

export const deleteProjectCollectionByIdFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
    dataRecivedProjectCollection: true,
  };
};

//Update project collection by id
export const updateProjectCollectionByIdRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateProjectCollectionByIdSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Project Collection updated SuccessFully",
      isLoading: false,
      dataRecivedProjectCollection: true,
    };
  }
};

export const updateProjectCollectionByIdFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
    dataRecivedProjectCollection: true,
  };
};

export const changeProjectCollectionListStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const changeProjectCollectionListStatusSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Project Collection Status Updated Successfully",
      isLoading: false,
      dataRecivedProjectCollection: true,
    };
  }
};

export const changeProjectCollectionListStatusFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setProjectCollectionState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_PROJECT_COLLECTION_SUCCESS]: addProjectCollectionSuccess,
  [Types.ADD_PROJECT_COLLECTION_FAILURE]: addProjectCollectionFailure,
  [Types.ADD_PROJECT_COLLECTION_REQUEST]: addProjectCollectionRequest,

  [Types.GET_PROJECT_COLLECTION_BY_ID_SUCCESS]: getProjectCollectionByIdSuccess,
  [Types.GET_PROJECT_COLLECTION_BY_ID_FAILURE]: getProjectCollectionByIdFailure,
  [Types.GET_PROJECT_COLLECTION_BY_ID_REQUEST]: getProjectCollectionByIdRequest,

  [Types.DELETE_PROJECT_COLLECTION_BY_ID_SUCCESS]:
    deleteProjectCollectionByIdSuccess,
  [Types.DELETE_PROJECT_COLLECTION_BY_ID_FAILURE]:
    deleteProjectCollectionByIdFailure,
  [Types.DELETE_PROJECT_COLLECTION_BY_ID_REQUEST]:
    deleteProjectCollectionByIdRequest,

  [Types.UPDATE_PROJECT_COLLECTION_BY_ID_SUCCESS]:
    updateProjectCollectionByIdSuccess,
  [Types.UPDATE_PROJECT_COLLECTION_BY_ID_FAILURE]:
    updateProjectCollectionByIdFailure,
  [Types.UPDATE_PROJECT_COLLECTION_BY_ID_REQUEST]:
    updateProjectCollectionByIdRequest,

  [Types.CHANGE_PROJECT_COLLECTION_LIST_STATUS_SUCCESS]:
    changeProjectCollectionListStatusSuccess,
  [Types.CHANGE_PROJECT_COLLECTION_LIST_STATUS_FAILURE]:
    changeProjectCollectionListStatusFailure,
  [Types.CHANGE_PROJECT_COLLECTION_LIST_STATUS_REQUEST]:
    changeProjectCollectionListStatusRequest,

  [Types.SET_PROJECT_COLLECTION_STATE]: setProjectCollectionState,
});
