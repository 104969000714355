import { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Paper from "@mui/material/Paper";
import { SelectChangeEvent } from "@mui/material/Select";
import SwitchComponent from "components/ui-elements/switch/SwitchComponent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Avatar,
  Checkbox,
  ClickAwayListener,
  Popper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import styles from "./InsightList.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useDispatch, useSelector } from "react-redux";
import { exportAsExcel } from "utils/ExportFileUtils";
import Retry from "components/retry/Retry";
import Loader from "components/loader/Loader";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Box } from "@mui/system";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import {
  ActionTypeEnumInsight,
  ActionTypeEnumUtilsInsight,
} from "enumerations/ActionTypeEnum";
import {
  StatusTypeEnumInsight,
  StatusTypeEnumUtilsInsight,
} from "enumerations/StatusTypeEnum";
import { getYoutubeVideoId } from "utils/ValidationUtils";
import { RouteConstants } from "routes/RouteConstants";
import PageHeading from "components/page-heading/PageHeading";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import MediaAssets from "assets";
import NoDataRecord from "components/no-data-record/NoDataRecord";
import { CustomerActions } from "redux-container/customer-management/CustomersListAndDetailsRedux";
import { SortingOrder } from "enumerations/SortingOrderTypeEnum";

const CustomTableContainer = styled(Box)`
  &&.MuiTableContainer-root {
    overflow-x: none;
  }
  && .MuiOutlinedInput-root {
    margin-right: 55px;
  }
`;
export const BreadCrumbsWrapper = styled(Box)`
  && .MuiOutlinedInput-root {
    margin-right: 50px;
  }
  .MuiButton-root {
    /* margin-right: 6px; */
  }
`;
const FilterContainer = styled(Grid)`
  .MuiGrid-container {
    margin-top: 30px;
  }
`;
const tableHeadings = [
  { heading: "Project Id" },
  { heading: "Project Name" },
  { heading: "Starting Price", isSortingColumn: true, sortingKey: "14" },
  { heading: "Starting Area", isSortingColumn: true, sortingKey: "13" },
  { heading: "EA" },
];

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerWatchlist: async (params: any) => {
      dispatch(CustomerActions.getCustomerWatchListRequest(params));
    },
  };
};

const CustomerPortpolioWatchlist = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const dispatch = useDispatch();

  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [columnSort, setColumnSort] = useState({
    sortingKey: "",
    sortingPriority: "",
    isActiveSortingColumn: "",
  });
  const [customerId, setCustomerId] = useState(
    location?.state?.customerData?.id
  );
  const { customerWatchlist, totalCount, isLoading } = useSelector(
    (state: any) => {
      return {
        customerWatchlist: state?.customerListAndDetails?.customerData,
        totalCount: state?.customerListAndDetails?.totalCount,
        isLoading: state?.customerListAndDetails?.isLoading,
      };
    }
  );
  const publishedProjects = useSelector(
    (state: any) => state?.projectList?.data?.data
  );

  const { getCustomerWatchlist } = actionDispatch(useDispatch());

  useEffect(() => {
    const params = {
      cmsId: customerId,
      projectName: selectedProject?.launchName,
      sortingKey: columnSort.sortingKey,
      sortingPriority: columnSort.sortingPriority,
    };
    getCustomerWatchlist(params);
    return () => {};
  }, [selectedProject, columnSort]);

  const breadcrumbRoutes: any = [
    {
      name: "Resource Management",
      route: RouteConstants.resourceManagementDashboard,
    },
    {
      name: "Insight List",
      route: RouteConstants.insightList,
    },
  ];

  const viewInsight = (investmentDetailsOnAProject: any) => {
    navigate(`${RouteConstants.insightCreation}`, {
      state: { ...investmentDetailsOnAProject, isView: true },
    });
  };

  const renderTable = () => {
    return (
      <Fragment>
        <FilterContainer container spacing={1} className="filter-container">
          <Grid container spacing={2} md={8} lg={8} xl={8}>
            <Grid
              item
              md={1}
              lg={1}
              xl={1}
              className="margin-left-10 filter-heading-holder"
            >
              <span className="filter-heading">Filters:</span>
            </Grid>

            <Grid item md={3} lg={3} xl={3}>
              <FormControl className="filter-dropdown">
                <AutocompleteComponent
                  options={publishedProjects}
                  getOptionLabel={(option: any) => option?.launchName}
                  label="Project Name"
                  onChange={(
                    event: React.SyntheticEvent,
                    newValue: any | null
                  ) => {
                    setSelectedProject(newValue);
                  }}
                  value={selectedProject}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </FilterContainer>
        <CustomTableContainer>
          <TableContainer
            component={Paper}
            className={`table-container`}
            style={{ height: window.innerHeight - 475 }}
          >
            <Table style={{ position: "relative" }}>
              <TableHead>
                <TableRow className="table-header-row">
                  {tableHeadings?.map((item, index) => (
                    <TableCell key={index} className="table-header-text">
                      {item.heading}

                      {item.isSortingColumn ? (
                        <>
                          {columnSort.sortingPriority ===
                            SortingOrder.Descending &&
                          columnSort.isActiveSortingColumn === item.heading ? (
                            <IconButton
                              onClick={() => {
                                setColumnSort({
                                  sortingKey: item.sortingKey,
                                  sortingPriority: SortingOrder.Ascending,
                                  isActiveSortingColumn: item.heading,
                                });
                              }}
                            >
                              <ArrowDropUpIcon
                                fontSize={"large"}
                                style={{
                                  position: "relative",
                                  // top: "16px",
                                  right: "10px",
                                  fontSize: "3rem",
                                  color:
                                    columnSort.isActiveSortingColumn ===
                                    item.heading
                                      ? "#676ac0"
                                      : "rgb(0 0 0 / 87%)",
                                }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setColumnSort({
                                  sortingKey: item.sortingKey,
                                  sortingPriority: SortingOrder.Descending,
                                  isActiveSortingColumn: item.heading,
                                });
                              }}
                            >
                              <ArrowDropDownIcon
                                fontSize={"large"}
                                style={{
                                  position: "relative",
                                  // top: "10px",
                                  right: "10px",
                                  fontSize: "3rem",
                                  color:
                                    columnSort.isActiveSortingColumn ===
                                    item.heading
                                      ? "#676ac0"
                                      : "rgb(0 0 0 / 87%)",
                                }}
                              />
                            </IconButton>
                          )}
                        </>
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <Loader />
              ) : customerWatchlist?.length > 0 ? (
                <TableBody>
                  {customerWatchlist?.map(
                    (interestedProject: any, insightsIndex: any) => {
                      return (
                        <TableRow
                          key={interestedProject.id}
                          className="table-data-row"
                          style={{ height: "80px" }}
                        >
                          <TableCell
                            padding="checkbox"
                            style={{ minWidth: 150 }}
                          >
                            {`#ID-${interestedProject?.project?.id}`}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 200 }}
                          >
                            {interestedProject?.project?.launchName?.slice(
                              0,
                              20
                            )}
                          </TableCell>

                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 150 }}
                          >
                            {interestedProject?.project?.priceStartingFrom}
                          </TableCell>

                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 150 }}
                          >
                            {interestedProject?.project?.areaStartingFrom}
                          </TableCell>

                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 50 }}
                          >
                            {`${interestedProject?.project?.estimatedAppreciation}%`}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              ) : (
                <div className="no-record">No Projects Found</div>
              )}
            </Table>
          </TableContainer>
        </CustomTableContainer>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Container className="main-container">{renderTable()}</Container>
    </Fragment>
  );
};

export default CustomerPortpolioWatchlist;
