import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import { SelectChangeEvent } from "@mui/material/Select";
import styles from "./ProjectListDialog.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import {
  DialogActions,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ProjectListActions from "redux-container/project-management/ProjectListRedux";
import Loader from "components/loader/Loader";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";
import { Search } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import ProjectCollectionActions from "redux-container/project-management/project-collection/ProjectCollectionCreationRedux";

const actionDispatch = (dispatch: any) => {
  return {
    getProjectList: (params: any) =>
      dispatch(ProjectListActions.projectListRequest(params)),
    setProjectCollectionState: (key: any, data: any) =>
      dispatch(ProjectCollectionActions.setProjectCollectionState(key, data)),
  };
};

interface IProjectListDialogProps {
  shouldOpen: boolean;
  cancelHandler: () => void;
  mapProjectList?: any;
  initialMapList?: any;
  setProjectListWithPriority?: any;
  projectListWithPriority?: any;
  setAllProjectList: any;
  isAllProjectList: any;
  projectCollectionId: any;
  isEditMode: boolean;
  // isSaveAndNew:boolean
}

const ProjectListDialog = ({
  shouldOpen,
  cancelHandler,
  mapProjectList,
  initialMapList,
  setProjectListWithPriority,
  projectListWithPriority,
  setAllProjectList,
  isAllProjectList,
  projectCollectionId,
  isEditMode,
}: // isSaveAndNew
IProjectListDialogProps) => {
  const singleProjectCollection = useSelector(
    (state: any) => state?.projectCollectionCreation?.singleProjectCollection
  );
  const creationProjectCollection = useSelector(
    (state: any) => state?.projectCollectionCreation?.creationProjectCollection
  );
  let initialMappedIds =
    isEditMode && !!singleProjectCollection?.length
      ? singleProjectCollection?.map((item: any) => item?.id)
      : !!creationProjectCollection?.length
      ? creationProjectCollection?.map((item: any) => item?.id)
      : [];

  const projects = useSelector((state: any) => state.projectList?.data.data);
  const isCreationProjectCollection = useSelector(
    (state: any) =>
      state?.projectCollectionCreation?.isCreationProjectCollection
  );
  let filterSelectedRows = projects?.filter((item: any) =>
    initialMappedIds?.includes(item?.id)
  );

  const [selectedProjects, setSelectedProjects] = useState<any>([]);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [selectedAreaRange, setSelectedAreaRange] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState<any>(
    filterSelectedRows?.length > 0 ? filterSelectedRows : []
  );
  const { getProjectList, setProjectCollectionState } = actionDispatch(
    useDispatch()
  );
  const [selectedCreatedBy, setSelectedCreatedBy] = useState(null);
  const [selectedUpdatedBy, setSelectedUpdatedBy] = useState(null);
  const projectTotalCount = useSelector(
    (state: any) => state.projectList.data.totalCount
  );
  const [searchKey, setSearchKey] = useState("");
  const allUsers: any = useSelector(
    (state: any) => state?.users?.usersForFilters
  );
  const [selectedCreatedUser, setSelectedCreatedUser] = useState<any>(null);
  const [selectedModifiedUser, setSelectedModifiedUser] = useState<any>(null);
  const isLoading = useSelector((state: any) => state.projectList.isLoading);
  const [onKeyPressSearchValue, setOnKeyPressSearchValue] = useState("");

  useEffect(() => {
    const data = {
      size: rowsPerPage,
      index: page + 1,
      searchKey: onKeyPressSearchValue,
      createdBy: selectedCreatedUser?.id,
      updatedBy: selectedModifiedUser?.id,
      status: "1001",
    };
    getProjectList(data);
  }, [
    rowsPerPage,
    page,
    onKeyPressSearchValue,
    selectedCreatedUser,
    selectedModifiedUser,
  ]);

  useEffect(() => {
    // if (count < 2) {
    //   count++
    if (isEditMode) {
      setSelectedProjects(
        singleProjectCollection?.map((item: any) => item?.id)
      );
    } else if (
      isCreationProjectCollection &&
      creationProjectCollection?.length
    ) {
      setSelectedProjects(
        creationProjectCollection?.map((item: any) => item?.id)
      );
    }
    // }
  }, [
    singleProjectCollection?.length,
    isCreationProjectCollection,
    creationProjectCollection?.length,
  ]);

  const tableHeadings = [
    { heading: "Project ID" },
    { heading: "Thumbnail" },
    { heading: "Name" },
    { heading: "Location" },
    { heading: "Starting Price" },
    { heading: "Starting Area" },
    { heading: "Created on" },
    { heading: "Published on" },
  ];

  const handleClose = () => {
    cancelHandler();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = projects?.map((n: any) => n.id);
      const newSelectedRow: any = projects?.map((n: any) => n);
      setSelectedProjects(newSelecteds);
      setSelectedRow([...newSelectedRow]);
      return;
    }
    setSelectedProjects([]);
    setSelectedRow([]);
  };
  const clearFilters = () => {
    setSelectedModifiedUser(null);
    setSelectedCreatedUser(null);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedProjects?.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selectedProjects, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selectedProjects?.slice(1));
    } else if (selectedIndex === selectedProjects?.length - 1) {
      newSelected = newSelected?.concat(selectedProjects?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selectedProjects?.slice(0, selectedIndex),
        selectedProjects?.slice(selectedIndex + 1)
      );
    }
    setSelectedProjects(newSelected);
  };

  const handleOnRowClick = (event: React.MouseEvent<unknown>, name: string) => {
    // setSelectedRow([...selectedRow, project])
    const selectedIndex = selectedRow?.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRow, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRow?.slice(1));
    } else if (selectedIndex === selectedRow?.length - 1) {
      newSelected = newSelected.concat(selectedRow?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRow?.slice(0, selectedIndex),
        selectedRow?.slice(selectedIndex + 1)
      );
    }
    setSelectedRow(newSelected);
  };

  const isSelected = (name: string) => selectedProjects?.indexOf(name) !== -1;

  const mapProjectSelection = () => {
    const selectedProjectList = projects.filter((project: any) => {
      return selectedProjects.find((id: any) => id == project.id);
    });

    const addingPriorityToList = selectedProjectList.map(
      (project: any, index: any) => {
        return {
          ...project,
          priority: index + 1,
        };
      }
    );
    // let combineList = singleProjectCollection?.concat(selectedRow)
    const filterOnlySelecetedId = projects?.filter((item: any) =>
      selectedProjects?.includes(item?.id)
    );
    const removeDuplicates = filterOnlySelecetedId.filter(
      (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i
    );
    // mapProjectList(removeDuplicates)
    // set redux state
    if (isEditMode) {
      setProjectCollectionState("singleProjectCollection", removeDuplicates);
    } else {
      setProjectListWithPriority(selectedProjectList);
      if (!isCreationProjectCollection) {
        setProjectCollectionState("isAddedNewProject", true);
      }
      setProjectCollectionState(
        "mappedProjectCollectionList",
        addingPriorityToList
      );
      setProjectCollectionState(
        "creationProjectCollection",
        selectedProjectList
      );
    }
    setSelectedRow([]);
    setSelectedProjects([]);
    handleClose();
  };

  const handleResetFilter = () => {
    setSelectedProjects([]);
  };

  const handleOnChangeSearchKey = (e: any) => {
    setSearchKey(e.target.value);
  };

  const handleOnEnterKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      setOnKeyPressSearchValue(searchKey);
    }
  };

  const searchKeyPress = () => {
    setOnKeyPressSearchValue(searchKey);
  };

  const clearSearchKey = () => {
    setSearchKey("");
    setOnKeyPressSearchValue("");
  };
  return (
    <Dialog
      open={shouldOpen}
      onClose={handleClose}
      maxWidth={"xl"}
      fullWidth={true}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Fragment>
          <Container className="main-container">
            <div className="heading-action-holder">
              <Stack justifyContent={"flex-start"} spacing={2}>
                <Typography
                  variant="h6"
                  fontWeight={"Bold"}
                  color={"secondary.dark"}
                >
                  Project({projects?.length})
                </Typography>
                {selectedProjects?.length > 0 ? (
                  <Typography>
                    {" "}
                    {selectedProjects?.length} Project selected{" "}
                    <span onClick={handleResetFilter} className="clear-filter">
                      Clear
                    </span>{" "}
                  </Typography>
                ) : null}
              </Stack>
              <div>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  // autoFocus
                  disableElevation
                  className="btn btn-light-outline margin-right-15"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={mapProjectSelection}
                  autoFocus
                  disableElevation
                  disabled={selectedProjects?.length === 0 ? true : false}
                  className={
                    selectedProjects?.length > 0 ? "btn btn-dark" : "btn"
                  }
                >
                  Map Selection
                </Button>
              </div>
            </div>

            <Grid container spacing={2}>
              <Grid container spacing={2} item md={8} lg={8} xl={8}>
                <Grid item md={3} lg={3} xl={3}>
                  <FormControl className="filter-dropdown">
                    <AutocompleteComponent
                      options={allUsers}
                      getOptionLabel={(option: any) => option?.firstName}
                      label="Created By"
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: any | null
                      ) => {
                        setSelectedCreatedUser(newValue);
                      }}
                      value={selectedCreatedUser}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option?.id === value?.id
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={3} lg={3} xl={3}>
                  <FormControl className="filter-dropdown">
                    <AutocompleteComponent
                      options={allUsers}
                      getOptionLabel={(option: any) => option?.firstName}
                      label="Modified By"
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: any | null
                      ) => {
                        setSelectedModifiedUser(newValue);
                      }}
                      value={selectedModifiedUser}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option?.id === value?.id
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item>
                  {selectedModifiedUser !== null ||
                  selectedCreatedUser !== null ? (
                    <Grid
                      item
                      md={0.5}
                      lg={0.5}
                      xl={0.5}
                      className="filter-heading-holder"
                      style={{ position: "relative" }}
                    >
                      <span
                        onClick={clearFilters}
                        className="clear-filter"
                        style={{ position: "absolute", top: 32 }}
                      >
                        Clear
                      </span>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>

              <Grid item md={4} lg={4} xl={4}>
                <TextFieldComponent
                  className={styles["search-input"]}
                  placeholder="Search Projects"
                  value={searchKey}
                  onChange={handleOnChangeSearchKey}
                  onKeyPress={handleOnEnterKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="search"
                          onClick={searchKeyPress}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        {searchKey?.length > 0 ? (
                          <IconButton
                            aria-label="cancel"
                            onClick={clearSearchKey}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          <div></div>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <TableContainer
              component={Paper}
              className="table-container"
              style={{ height: window.innerHeight - 475 }}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <Table style={{ position: "relative" }}>
                  <TableHead>
                    <TableRow className="table-header-row">
                      <TableCell padding="checkbox">
                        <Checkbox
                          className="active-checkbox"
                          indeterminate={
                            selectedProjects?.length > 0 &&
                            selectedProjects?.length < projects?.length
                          }
                          checked={
                            projects?.length > 0 &&
                            selectedProjects?.length === projects?.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </TableCell>
                      {tableHeadings.map((item, index) => (
                        <TableCell key={index} className="table-header-text">
                          {item.heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects?.length > 0 ? (
                      projects?.map((project: any) => {
                        const isItemSelected = isSelected(project.id);
                        return (
                          <TableRow
                            key={project.id}
                            className="table-data-row"
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                className="active-checkbox"
                                checked={isItemSelected}
                                onClick={(event) => {
                                  handleClick(event, project.id);
                                  handleOnRowClick(event, project);
                                }}
                              />
                            </TableCell>

                            <TableCell className="table-data-text">
                              {project.id}
                            </TableCell>

                            <TableCell>
                              <img
                                src={
                                  project?.projectCoverImages?.homePageMedia
                                    ?.value?.url
                                }
                                alt={"image"}
                                className={styles["project-image"]}
                              />
                            </TableCell>

                            <TableCell className="table-data-text">
                              {project?.launchName}
                            </TableCell>

                            <TableCell className="table-data-text">
                              <div> {project?.address?.city}</div>
                              {project?.address?.state}
                            </TableCell>

                            <TableCell className="table-data-text">
                              {`(₹) ${project?.priceStartingFrom || "-"}`}
                            </TableCell>

                            <TableCell className="table-data-text">
                              {project?.areaStartingFrom}
                            </TableCell>

                            <TableCell
                              className="table-data-text"
                              style={{ minWidth: 120 }}
                            >
                              <div>
                                {project?.projectContentCreatedBy?.firstName}
                              </div>
                              {project?.createdAt
                                ? formatDateTime(
                                    project?.createdAt,
                                    Constants.dateFormatDDmmYYYY
                                  )
                                : "-"}
                            </TableCell>

                            <TableCell
                              className="table-data-text"
                              style={{ minWidth: 120 }}
                            >
                              {
                                <div>
                                  {project?.projectContentUpdatedBy?.firstName}
                                </div>
                              }
                              {project?.updatedAt
                                ? formatDateTime(
                                    project?.updatedAt,
                                    Constants.dateFormatDDmmYYYY
                                  )
                                : "-"}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <div className="no-record">No Projects Found</div>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Container>
        </Fragment>
      </DialogContent>

      <DialogActions className={styles["dialog-table-pagination"]}>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={
            projectTotalCount
              ? projectTotalCount
              : projects?.length
              ? projects?.length
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ProjectListDialog;
