import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  promisesCreationRequest: ["data"],
  promisesCreationSuccess: ["response"],
  promisesCreationFailure: ["error"],

  getAllPromisesRequest: ["data"],
  getAllPromisesSuccess: ["response"],
  getAllPromisesFailure: ["error"],

  promisesUpdateRequest: ["id", "data"],
  promisesUpdateSuccess: ["response"],
  promisesUpdateFailure: ["error"],

  promisesDraftRequest: ["data"],
  promisesDraftSuccess: ["response"],
  promisesDraftFailure: ["error"],

  promisesStatusRequest: ["data"],
  promisesStatusSuccess: ["response"],
  promisesStatusFailure: ["error"],

  removePromisesRequest: ["data"],
  removePromisesSuccess: ["response"],
  removePromisesFailure: ["error"],

  promisesUpdateDraftRequest: ["id", "data"],
  promisesUpdateDraftSuccess: ["response"],
  promisesUpdateDraftFailure: ["error"],

  setPromisesState: ["key", "value"],
});

export const PromisesCreationTypes = Types;

export const PromisesActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  promiseData: [],
  responseMessage: "",
  shouldDialogOpen: false,
  isSuccess: false,
  auditedObject:null
};

export const getAllPromisesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
    shouldDialogOpen: false,
  };
};

export const getAllPromisesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      promiseData: response,
      isLoading: false,
      isSuccess: true,
      shouldDialogOpen: false,
      responseMessage: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getAllPromisesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: false,
    error: action.error.message,
  };
};

//create promises
export const promisesCreationRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const promisesCreationSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      promiseData: response,
      isLoading: false,
      isSuccess: true,
      shouldDialogOpen: true,
      responseMessage: response.message,
      auditedObject: response.data
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const promisesCreationFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: true,
    error: action.error.message,
  };
};

export const promisesUpdateRequest = (state: any) => {
  return {
    ...state,
    isSuccess: false,
    isLoading: true,
  };
};

export const promisesUpdateSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      promiseData: response,
      isLoading: false,
      shouldDialogOpen: true,
      responseMessage: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const promisesUpdateFailure = (state: any, action: any) => {
  return {
    ...state,
    isSuccess: false,
    isLoading: false,
    shouldDialogOpen: true,
    error: action.error.message,
  };
};

export const promisesUpdateDraftRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const promisesUpdateDraftSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      promiseData: response,
      isLoading: false,
      isSuccess: true,
      shouldDialogOpen: true,
      responseMessage: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const promisesUpdateDraftFailure = (state: any, action: any) => {
  return {
    ...state,
    isSuccess: false,
    isLoading: false,
    shouldDialogOpen: true,
    error: action.error.message,
  };
};

export const promisesDraftRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const promisesDraftSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      promiseData: response,
      isLoading: false,
      isSuccess: true,
      shouldDialogOpen: true,
      responseMessage: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const promisesDraftFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: true,
    error: action.error.message,
  };
};

//* Updating promise status
export const promisesStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const promisesStatusSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      shouldDialogOpen: true,
      promiseData: response.data,
      responseMessage:
        response.message,
      isLoading: false,
    };
  }
};

export const promisesStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: true,
    error: action.error.message,
  };
};

// Delete promises

export const removePromisesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const removePromisesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      isLoading: false,
      shouldDialogOpen: true,
      promiseData: response.data,
      responseMessage: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const removePromisesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    shouldDialogOpen: true,
    isSuccess: false,
    error: action.error.message,
  };
};

export const setPromisesState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_PROMISES_REQUEST]: getAllPromisesRequest,
  [Types.GET_ALL_PROMISES_SUCCESS]: getAllPromisesSuccess,
  [Types.GET_ALL_PROMISES_FAILURE]: getAllPromisesFailure,

  [Types.PROMISES_CREATION_SUCCESS]: promisesCreationSuccess,
  [Types.PROMISES_CREATION_FAILURE]: promisesCreationFailure,
  [Types.PROMISES_CREATION_REQUEST]: promisesCreationRequest,

  [Types.PROMISES_UPDATE_SUCCESS]: promisesUpdateSuccess,
  [Types.PROMISES_UPDATE_FAILURE]: promisesUpdateFailure,
  [Types.PROMISES_UPDATE_REQUEST]: promisesUpdateRequest,

  [Types.PROMISES_DRAFT_FAILURE]: promisesDraftFailure,
  [Types.PROMISES_DRAFT_SUCCESS]: promisesDraftSuccess,
  [Types.PROMISES_DRAFT_REQUEST]: promisesDraftRequest,

  [Types.PROMISES_STATUS_FAILURE]: promisesStatusFailure,
  [Types.PROMISES_STATUS_SUCCESS]: promisesStatusSuccess,
  [Types.PROMISES_STATUS_REQUEST]: promisesStatusRequest,

  [Types.PROMISES_UPDATE_DRAFT_FAILURE]: promisesUpdateDraftFailure,
  [Types.PROMISES_UPDATE_DRAFT_SUCCESS]: promisesUpdateDraftSuccess,
  [Types.PROMISES_UPDATE_DRAFT_REQUEST]: promisesUpdateDraftRequest,

  [Types.REMOVE_PROMISES_FAILURE]: removePromisesFailure,
  [Types.REMOVE_PROMISES_SUCCESS]: removePromisesSuccess,
  [Types.REMOVE_PROMISES_REQUEST]: removePromisesRequest,

  [Types.SET_PROMISES_STATE]: setPromisesState,
});
