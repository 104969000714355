import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import styles from "./ProjectCoverImages.module.css";
import React, { Fragment, useState } from "react";
import * as yup from "yup";
import { LoadingButtonComponent } from "components/ui-elements";
import Imageuploaderdropbox from "components/ui-elements/Imageuploaderdropbox";
import { projectCoverImagesInitialValues } from "./ProjectCoverImagesInitialValues";
// import ProjectCoverImagesUploader from "./ProjectIconUploader";
import ProjectIconUploader from "./ProjectIconUploader";
function ProjectIcons() {
  // const [heightAndWidthOfHomePageMedia,setHeightAndWidthOfHomePageMedia]=useState({mediaHeight:197,mediaWidth:194});
  // const [heightAndWidthOfNewInvestmentPageMedia,setHeightAndWidthOfNewInvestmentPageMedia]=useState({mediaHeight:44,mediaWidth:44});
  // const [heightAndWidthOfCollectionListViewPageMedia,setheightAndWidthOfCollectionListViewPageMedia]
  return (
    <Fragment>
      <Form noValidate>
        <div>
          <Grid container>
            <Grid item>
              <Typography className={styles["heading-alignment"]}>
                Project Icon*
              </Typography>
              <ProjectIconUploader
                Id={1}
                name="projectIcon"
              />
            </Grid>
          </Grid>
        </div>
      </Form>
    </Fragment>
  );
}

export default ProjectIcons;
