import { Avatar, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import MediaAssets from "assets";
import {
  ButtonComponent,
  FormFieldContainer,
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Fragment, useEffect, useState } from "react";
import { Form, Formik, getIn } from "formik";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import PageHeading from "../../../../components/page-heading/PageHeading";
import Container from "@mui/material/Container";
import {
  Insights,
  LatestUpdates,
  Masthead,
  NewInvestments,
  PromotionAndOffer,
  FacilityManagement,
  Promises,
  Referral,
  Testimonials,
} from "./sections";
import {
  Page1HoablInitialValues,
  Page1HoablLabels,
} from "./Page1HoablInitialValues";
import { hoablPage1Action } from "redux-container/page-management/hoabl-page-1/HoablPage1Redux";
import { useDispatch, useSelector } from "react-redux";
import { HoablPageSchema } from "./Page1HoablSchema";
import { projectListHoablPage1Action } from "redux-container/page-management/hoabl-page-1/ProjectListInHoablPage1Redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import InsightsActions from "../../../../redux-container/insights/InsightsRedux";
import PromiseActions from "redux-container/promises/PromisesListRedux";
import TestimonialActions from "../../../../redux-container/marketing-management/testimonials/TestimonialsRedux";
import UpdateActions from "../../../../redux-container/marketing-management/updates/UpdatesRedux";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import projectListActions from "redux-container/project-management/ProjectListRedux";
import Loader from "components/loader/Loader";
import DelayedComponent from "./../investments/new-investment-page/DelayedComponent";
import { Constants } from "constants/Constants";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import Carousels from "./sections/carousels/Carousels";
import BrandVideos from "./sections/brand-medias/BrandVideos";
import OtpToggle from "./sections/otp-toggle/OtpToggle";

const actionDispatch = (dispatch: any) => {
  return {
    projectListHoablPage1Request: (data: any) =>
      dispatch(hoablPage1Action.projectListHoablPageRequest(data)),
    getHoablPage1: () => dispatch(hoablPage1Action.hoablPage1Request()),
    updateHoablPage1: (data: any) =>
      dispatch(hoablPage1Action.updateHoablPage1Request(data)),
    getProjectContentList: (data: any) =>
      dispatch(projectListHoablPage1Action.projectListHoablPage1Request(data)),
    setHoablPage1State: (key: any, data: any) =>
      dispatch(hoablPage1Action.setHoablPage1State(key, data)),
    createHoablPage1: (data: any) =>
      dispatch(hoablPage1Action.createHoablPage1Request(data)),
    getInsights: (params: any) =>
      dispatch(InsightsActions.getInsightsRequest(params)),
    getPromises: (params: any) =>
      dispatch(PromiseActions.promisesListRequest(params)),
    getTestimonials: (params: any) =>
      dispatch(TestimonialActions.getTestimonialsRequest(params)),
    getUpdates: (params: any) => dispatch(UpdateActions.updateRequest(params)),

    getProjectList: (params: any) =>
      dispatch(projectListActions.projectListRequest(params)),
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const Page1Hoabl = () => {
  const {
    getHoablPage1,
    updateHoablPage1,
    setHoablPage1State,
    createHoablPage1,
    projectListHoablPage1Request,
    getInsights,
    getPromises,
    getTestimonials,
    getUpdates,
    postAuditLogRequest,
    rolesAndModulesRequest,
    getProjectList,
  } = actionDispatch(useDispatch());
  const {
    hoablPage1,
    projectListWithPriorityReduxState,
    hoablPage1UpdateResponse,
    isSuccess,
    isLoading,
    isGetPageFailure,
  } = useSelector((state: any) => state?.hoablPage1Data);

  console.log("hoablPage1", hoablPage1);

  // const [carouselCounter, setCorouselCounter] = useState(0);
  // useEffect(() => {
  //   if (hoablPage1?.pageManagementOrCarousels?.length !== carouselCounter)
  //     setCorouselCounter(hoablPage1?.pageManagementOrCarousels?.length);
  // }, [
  //   hoablPage1?.pageManagementOrCarousels?.length,
  //   hoablPage1?.configureAutoSwiping,
  //   hoablPage1?.isCarouselActive,
  // ]);

  const projectContentList = useSelector(
    (state: any) => state.projectList?.data?.data
  );
  const projectCollectionList = useSelector(
    (state: any) => state.projectCollectionList.projectCollection
  );

  const navigate = useNavigate();
  const [isConfirmUpdateDialogOpen, setConfirmUpdateDialogOpen] =
    useState(false);
  const location: any = useLocation();
  const [isEditMode, setEditMode] = useState<Boolean>(true);

  const modules = useSelector((state: any) => state?.rolesAndModules?.module);
  const [previousStates, setPreviousStates] = useState<any>(hoablPage1?.page);
  const [finalValues, setfinalValues] = useState<any>();

  const [modulesAudited, setModulesAudited] = useState<any>(null);
  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    rolesAndModulesRequest();
    getProjectList({
      status: "1001",
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    });
  }, []);

  useEffect(() => {
    setPreviousStates(hoablPage1?.page);
  }, [hoablPage1?.page]);

  const breadcrumbRoutes: any = [
    {
      name: "Page Management",
      route: RouteConstants.pageManagementDashboard,
    },
    {
      name: "Page 1 - HoABL",
      route: RouteConstants.page1Hoabl,
    },
  ];

  const handlePage1Submit = (values: any) => {
    if (hoablPage1 !== null || hoablPage1 !== undefined) {
      setConfirmUpdateDialogOpen(true);
    } else {
      const reqBody = {
        ...values,
        isPublished: true,
        pageType: "5001",
        pageName: "HOABL",
      };
      createHoablPage1(reqBody);
    }
  };

  useEffect(() => {
    const params = {
      byPrority: true,
      status: "1001",
      shouldValidateAdmin: loggedInUser?.admin?.roleId !== 3 ? true : false,
    };
    getHoablPage1();
    projectListHoablPage1Request(params);
    getInsights(params);
    getPromises(params);
    getTestimonials(params);
    getUpdates(params);
  }, []);

  const handleCloseConfirmDialog = () => {
    setConfirmUpdateDialogOpen(false);
  };
  const updateHoablPage = (values: any) => {
    // auditedFieldsSetter(values);

    const reqBodyForPriority = projectListWithPriorityReduxState.map(
      (project: any) => {
        return {
          id: project.id,
          priority: project.priority,
        };
      }
    );
    const newInvestmentscrmProjectIds: any = [];
    projectListWithPriorityReduxState.forEach((project: any) =>
      newInvestmentscrmProjectIds.push(project.id)
    );

    const requestBody = {
      ...values,
      newInvestmentsWithPriority: reqBodyForPriority,
      newInvestmentscrmProjectIds: newInvestmentscrmProjectIds,
      latestUpdatesDisplayName: "Latest Update",
      carousels: values?.pageManagementOrCarousels,
      configureAutoSwiping: values?.configureAutoSwiping
        ? values?.configureAutoSwiping
        : 0,
    };
    updateHoablPage1(requestBody);
    setConfirmUpdateDialogOpen(false);
  };

  const auditedFieldsSetter = (values: any) => {
    let auditObjectsArray: any[] = [];

    Object?.keys(values)?.map((key: any) => {
      if (values[key] && typeof values[key] == "object") {
        if (
          Array.isArray(values[key]) &&
          key === "newInvestmentsWithPriority"
        ) {
          if (
            Page1HoablLabels[key] !== undefined &&
            projectListWithPriorityReduxState !== previousStates[key] &&
            projectListWithPriorityReduxState
              ?.map((project: any) => project.id)
              .join(",") !==
              previousStates[key].map((project: any) => project.id).join(",")
          ) {
            let auditObject = {
              itemAffected: Page1HoablLabels[key],
              newValue:
                projectListWithPriorityReduxState?.length > 0
                  ? projectListWithPriorityReduxState
                      .map((project: any, index: number) => {
                        // return getProjectName(project.id);
                        return project?.launchName;
                      })
                      .join(`,  `)
                  : "",
              oldValue: previousStates[key]
                ? previousStates[key]
                    .map((project: any, index: number) =>
                      getProjectName(project.id)
                    )
                    ?.join(`,  `)
                : "",
            };
            auditObjectsArray.push(auditObject);
          }
        } else if (key !== "newInvestmentsProjectIds") {
          Object?.keys(values[key])?.map((ObjectKey: any) => {
            if (typeof values[key][ObjectKey] == "object") {
              Object?.keys(values[key][ObjectKey])?.map(
                (mediaObjectKey: any) => {
                  if (
                    typeof values[key][ObjectKey][mediaObjectKey] == "object"
                  ) {
                    Object?.keys(values[key][ObjectKey])?.map(
                      (ObjectLevelTwo: any) => {
                        if (
                          Page1HoablLabels[key][ObjectKey][ObjectLevelTwo] !==
                            undefined &&
                          values[key][ObjectKey][ObjectLevelTwo] !==
                            previousStates[key][ObjectKey][ObjectLevelTwo]
                        ) {
                          let auditObject = {
                            itemAffected:
                              Page1HoablLabels[key][ObjectKey][ObjectLevelTwo],
                            newValue: values[key][ObjectKey][ObjectLevelTwo],
                            oldValue:
                              previousStates[key][ObjectKey][ObjectLevelTwo],
                          };
                          auditObjectsArray.push(auditObject);
                        }
                      }
                    );
                  } else {
                    if (
                      Page1HoablLabels[key][ObjectKey][mediaObjectKey] !==
                        undefined &&
                      values[key][ObjectKey][mediaObjectKey] !==
                        previousStates[key][ObjectKey][mediaObjectKey]
                    ) {
                      let auditObject = {
                        itemAffected:
                          Page1HoablLabels[key][ObjectKey][mediaObjectKey],
                        newValue: values[key][ObjectKey][mediaObjectKey],
                        oldValue:
                          previousStates[key][ObjectKey][mediaObjectKey],
                      };
                      auditObjectsArray.push(auditObject);
                    }
                  }
                }
              );
            } else {
              if (
                Page1HoablLabels[key] &&
                Page1HoablLabels[key][ObjectKey] !== undefined &&
                values[key][ObjectKey] !== previousStates[key][ObjectKey] &&
                ObjectKey !== "sortByPriority"
              ) {
                let auditObject = {
                  itemAffected: Page1HoablLabels[key][ObjectKey],
                  newValue: values[key][ObjectKey],
                  oldValue: previousStates[key][ObjectKey],
                };
                auditObjectsArray.push(auditObject);
              }
              if (ObjectKey === "sortByPriority") {
                let auditObject = {
                  itemAffected: Page1HoablLabels[key][ObjectKey],
                  newValue: values[key][ObjectKey] ? "ON" : "Off",
                  oldValue: previousStates[key][ObjectKey] ? "ON" : "Off",
                };
                auditObjectsArray.push(auditObject);
              }
            }
          });
        }
      } else {
        if (
          Page1HoablLabels[key] !== undefined &&
          // previousStates[key] && values[key] &&
          values[key] !== previousStates[key] &&
          key !== "promotionAndOffersProjectContentId" &&
          key !== "collectionOneCollectionId" &&
          key !== "collectionTwoCollectionId"
        ) {
          let auditObject = {
            itemAffected: `${Page1HoablLabels[key]}`,
            newValue:
              key === "isPromotionAndOfferActive" ||
              key === "isInsightsActive" ||
              key === "isTestimonialsActive" ||
              key === "isPromisesActive" ||
              key === "isLatestUpdatesActive" ||
              key === "isNewInvestmentsActive"
                ? values[key]
                  ? "ON"
                  : "Off"
                : values[key],
            oldValue:
              key === "isPromotionAndOfferActive" ||
              key === "isInsightsActive" ||
              key === "isTestimonialsActive" ||
              key === "isPromisesActive" ||
              key === "isLatestUpdatesActive" ||
              key === "isNewInvestmentsActive"
                ? previousStates[key]
                  ? "ON"
                  : "Off"
                : previousStates[key],
          };
          auditObjectsArray.push(auditObject);
        }
        if (
          Page1HoablLabels[key] !== undefined &&
          key === "promotionAndOffersProjectContentId" &&
          values[key] !== previousStates[key]
        ) {
          let auditObject = {
            itemAffected: `${Page1HoablLabels[key]}`,
            newValue: projectContentList?.find(
              (project: any) => project?.id === values[key]
            ).launchName,
            oldValue: previousStates[key]
              ? projectContentList?.find(
                  (project: any) => project?.id === previousStates[key]
                )?.launchName
              : "",
          };
          auditObjectsArray.push(auditObject);
        }
        // if (
        //   (key === "collectionOneCollectionId" ||
        //     key === "collectionTwoCollectionId") &&
        //   Page1HoablLabels[key] !== undefined &&
        //   values[key] !== previousStates[key]
        // ) {
        //   let auditObject = {
        //     itemAffected: `${Page1HoablLabels[key]}`,
        //     newValue: projectCollectionList?.find(
        //       (project: any) => project?.id === values[key]
        //     ).name,
        //     oldValue: previousStates[key]
        //       ? (projectCollectionList?.find(
        //           (project: any) => project?.id === previousStates[key]
        //         )).name
        //       : "",
        //   };
        //   auditObjectsArray.push(auditObject);
        // }
      }
    });

    setModulesAudited(auditObjectsArray);
  };

  const getProjectName = (id: number) => {
    return projectContentList?.find(
      (projectContent: any) => id === projectContent?.id
    )?.launchName;
  };

  const cancelHandler = (values: any) => {
    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Pages Management";
    });
    if (isSuccess) {
      if (modulesAudited?.length) {
        const postAuditRequestbody = {
          ipAddress: ipAddress,
          moduleId: moduleObject?.id,
          modulesAudited: modulesAudited,
          status: AuditStatus.EDITED,
          auditedObject: values,
          route: location.pathname,
        };
        postAuditLogRequest(postAuditRequestbody);
      }
    }

    if (hoablPage1UpdateResponse?.code === 200) {
      navigate(RouteConstants.pageManagementDashboard);
    } else if (isGetPageFailure) {
      setHoablPage1State("isGetPageFailure", false);
      navigate(-1);
    }
    setHoablPage1State("hoablPage1UpdateResponse", null);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <DelayedComponent waitBeforeShow={1000}>
          <Formik
            initialValues={
              hoablPage1 !== null
                ? {
                    ...hoablPage1?.page,
                    pageManagementOrCarousels:
                      hoablPage1?.pageManagementOrCarousels
                        ? hoablPage1?.pageManagementOrCarousels
                        : [],
                    isCarouselMediaPresent: hoablPage1
                      ?.pageManagementOrCarousels?.length
                      ? true
                      : false,
                    carouselsToDelete: [],
                    configureAutoSwiping: hoablPage1?.page?.configureAutoSwiping
                      ? hoablPage1?.page?.configureAutoSwiping
                      : null,

                    brandMedia: hoablPage1?.page?.brandMedia
                      ? hoablPage1?.page?.brandMedia
                      : [],
                    isBrandMediaPresent: hoablPage1?.page?.brandMedia?.length
                      ? true
                      : false,
                    brandMediasToDelete: [],
                  }
                : Page1HoablInitialValues
            }
            onSubmit={handlePage1Submit}
            validationSchema={HoablPageSchema}
            // enableReinitialize={true}
          >
            {({
              values,
              handleBlur,
              handleChange,
              isValid,
              dirty,
              errors,
              touched,
            }) => (
              <Form>
                <Fragment>
                  <PageHeading title="Page Management" />
                  <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

                  <Container>
                    <Stack
                      // mt={"1rem"}
                      direction={"row"}
                      justifyContent={"space-between"}
                      columnGap={2}
                    >
                      <Typography className="heading">
                        Page 1 - Hoabl
                      </Typography>
                      {location?.state?.isViewMode && isEditMode ? (
                        <Stack>
                          <ButtonComponent
                            onClick={() => {
                              setEditMode(false);
                            }}
                            variant="contained"
                          >
                            Edit
                          </ButtonComponent>
                        </Stack>
                      ) : (
                        <Stack columnGap={2}>
                          <ButtonComponent
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            Cancel
                          </ButtonComponent>
                          <LoadingButtonComponent
                            type="submit"
                            // loading={isLoading}
                            onClick={() => handlePage1Submit(values)}
                            loadingPosition="start"
                            disabled={!isValid}
                          >
                            Publish
                          </LoadingButtonComponent>
                        </Stack>
                      )}
                    </Stack>
                    <Masthead isEditMode={isEditMode} />
                    <Carousels isEditMode={isEditMode} />
                    <BrandVideos isEditMode={isEditMode} />
                    <OtpToggle isEditMode={isEditMode} />
                    <NewInvestments isEditMode={isEditMode} />
                    <LatestUpdates isEditMode={isEditMode} />
                    <Promises isEditMode={isEditMode} />
                    <PromotionAndOffer isEditMode={isEditMode} />
                    <FacilityManagement isEditMode={isEditMode} />
                    <Insights isEditMode={isEditMode} />
                    {/* <Referral /> */}
                    <Testimonials isEditMode={isEditMode} />
                  </Container>
                </Fragment>

                <ConfirmationDialog
                  shouldOpen={isConfirmUpdateDialogOpen}
                  title="Update Hoabl Page 1?"
                  content="This action will Update the Hoabl Page 1. Are you sure you want to continue?"
                  okText="Update"
                  cancelHandler={handleCloseConfirmDialog}
                  okHandler={() => updateHoablPage(values)}
                />
                {hoablPage1UpdateResponse ? (
                  <FlashMessageDialog
                    shouldOpen={hoablPage1UpdateResponse ? true : false}
                    content={
                      hoablPage1UpdateResponse?.code === 200
                        ? hoablPage1UpdateResponse?.message
                        : hoablPage1UpdateResponse?.message
                    }
                    isSuccess={
                      hoablPage1UpdateResponse?.code === 200 ? true : false
                    }
                    cancelHandler={() => cancelHandler(values)}
                  />
                ) : null}
              </Form>
            )}
          </Formik>
        </DelayedComponent>
      )}
    </>
  );
};

export default Page1Hoabl;
