import {
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import { ButtonComponent, TextFieldComponent } from "components/ui-elements";
import { FieldArray, getIn, useFormikContext } from "formik";
import React, { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { futureYearsConstant } from "utils/DateFormatterUtils";
import { shouldNumberOnly } from "utils/ValidationUtils";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

function ExpectedGrowth() {
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<IInitinalValueProjectContent>();

  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));

  return (
    <div>
      <FieldArray
        name="opportunityDoc.escalationGraph.dataPoints.points"
        render={(arrayHelpers) => (
          <div>
            {values?.opportunityDoc?.escalationGraph?.dataPoints?.points?.map(
              (element: any, index: any) => (
                <Grid container p={3} spacing={2} alignItems={"flex-end"}>
                  <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
                    <TextFieldComponent
                      type="text"
                      label="Year"
                      required
                      name={`opportunityDoc.escalationGraph.dataPoints.points[${index}].year`}
                      value={
                        values?.opportunityDoc?.escalationGraph?.dataPoints
                          ?.points[index]?.year
                      }
                      onChange={handleChange}
                      select
                      inputProps={{
                        maxLength: 2,
                      }}
                      onBlur={handleBlur}
                      fieldhelpertext={
                        getIn(
                          touched,
                          `opportunityDoc.escalationGraph.dataPoints.points[${index}].year`
                        ) &&
                        getIn(
                          errors,
                          `opportunityDoc.escalationGraph.dataPoints.points[${index}].year`
                        )
                      }
                      error={Boolean(
                        getIn(
                          errors,
                          `opportunityDoc.escalationGraph.dataPoints.points[${index}].year`
                        ) &&
                          getIn(
                            touched,
                            `opportunityDoc.escalationGraph.dataPoints.points[${index}].year`
                          )
                      )}
                      disabled={isViewMode}
                    >
                      {futureYearsConstant()?.map(
                        (yearField: any, key: any) => (
                          <MenuItem key={key} value={`${yearField}`}>
                            {yearField}
                          </MenuItem>
                        )
                      )}
                    </TextFieldComponent>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2.5} xl={2}>
                    <TextFieldComponent
                      required
                      type="text"
                      placeholder="Enter"
                      label="Appreciation"
                      name={`opportunityDoc.escalationGraph.dataPoints.points[${index}].value`}
                      value={
                        values?.opportunityDoc?.escalationGraph?.dataPoints
                          ?.points[index]?.value
                      }
                      onChange={(event) => {
                        if (shouldNumberOnly(event.target.value)) {
                          setFieldValue(
                            `${event.target.name}`,
                            event.target.value
                          );
                        }
                      }}
                      helpericon={MediaAssets.ic_info}
                      helpericoncontent="This field will indicate the time required to cover the distance  between the selected Location Infrastructure & the Project."
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Typography>%</Typography>
                          </InputAdornment>
                        ),
                      }}
                      onBlur={handleBlur}
                      fieldhelpertext={
                        getIn(
                          touched,
                          `opportunityDoc.escalationGraph.dataPoints.points[${index}].value`
                        ) &&
                        getIn(
                          errors,
                          `opportunityDoc.escalationGraph.dataPoints.points[${index}].value`
                        )
                      }
                      error={Boolean(
                        getIn(
                          errors,
                          `opportunityDoc.escalationGraph.dataPoints.points[${index}].value`
                        ) &&
                          getIn(
                            touched,
                            `opportunityDoc.escalationGraph.dataPoints.points[${index}].value`
                          )
                      )}
                      disabled={isViewMode}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    spacing={2}
                    mb={3}
                  >
                    {values?.opportunityDoc?.escalationGraph?.dataPoints?.points
                      ?.length > 2 && !isViewMode && (
                      // <ButtonComponent
                      //   sx={{
                      //     textDecoration: "underline",
                      //   }}
                      //   variant="text"
                      //   color="inherit"
                      //   onClick={() => {
                      //     arrayHelpers.remove(index);
                      //   }}
                      //   disabled={isViewMode}
                      // >
                      //   Delete
                      // </ButtonComponent>
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          marginTop: "15px",
                          marginRight: "-50px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      >
                        <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {values?.opportunityDoc.escalationGraph.dataPoints.points
                      .length -
                      1 ===
                      index &&
                      values?.opportunityDoc.escalationGraph.dataPoints.points
                        .length < 10 && (
                        <ButtonComponent
                          sx={{
                            textDecoration: "underline",
                            marginTop: "0.5rem",
                          }}
                          variant="text"
                          color="inherit"
                          onClick={() => {
                            arrayHelpers.push({
                              year: "",
                              value: "",
                              month: "Jan",
                              quater: "Quarter 1(Jan,Feb,Mar)",
                              halfYear: "First Half (Jan,Feb,Mar,Apr,Mar,Jun)"
                            });
                          }}
                          disabled={isViewMode}
                        >
                          + Add more Rows
                        </ButtonComponent>
                      )}
                  </Grid>
                </Grid>
              )
            )}
            <Divider sx={{ mt: "0.5rem" }} />
          </div>
        )}
      />
    </div>
  );
}

export default ExpectedGrowth;
