import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import React from "react";
import MediaAssets from "../../assets";
import styles from "./Retry.module.css";

interface IRetryProps {
  message: string;
  retryHandler: () => void;
}

const Retry = ({ message, retryHandler }: IRetryProps) => {
  const errorMessage = message
    ? message
    : "Something went wrong. Please try again!";

  const handleRetry = () => {
    retryHandler();
  };

  return (
    <Container className="main-container align-center ">
      <div className={styles["retry-container"]}>
        <img
          src={MediaAssets.ic_retry_error}
          alt="Women with a warning flag along with a danger sign"
        />
        <h2>Error!</h2>
        <p>errorMessage</p>
        <Button
          variant="contained"
          onClick={handleRetry}
          autoFocus
          disableElevation
          className="btn btn-dark"
        >
          Retry
        </Button>
      </div>
    </Container>
  );
};

export default Retry;
