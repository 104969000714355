import { Divider, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";

const GeneralInfo = () => {
  const location: any = useLocation();

  const [customerId, setCustomerId] = useState(
    location?.state?.customerData?.id
  );

  const getAddress = () => {
    let address: string = "";
    if (
      location?.state?.customerData?.streetAddress ||
      location?.state?.customerData?.city ||
      location?.state?.customerData?.houseNumber ||
      location?.state?.customerData?.locality ||
      location?.state?.customerData?.state ||
      location?.state?.customerData?.country
    ) {
      address = `${location?.state?.customerData?.streetAddress} ${location?.state?.customerData?.houseNumber} ${location?.state?.customerData?.locality} ${location?.state?.customerData?.city} ${location?.state?.customerData?.pincode} ${location?.state?.customerData?.state} ${location?.state?.customerData?.country}`;
    } else {
      address = "----";
    }
    return address
  };

  return (
    <>
      <Grid container padding={"2rem"} justifyContent={"space-between"}>
        <Grid item lg={2} xl={2}>
          <Typography gutterBottom>General Info</Typography>
          <Grid container columns={4}>
            <Grid item lg={1.4} xl={2}>
              <Typography className="card-label" variant="caption">
                Gender
              </Typography>
              <Typography variant="caption">
                {location?.state?.customerData?.gender
                  ? location?.state?.customerData?.gender
                  : "----"}
              </Typography>
            </Grid>
            <Grid item lg={1.4} xl={2}>
              <Typography className="card-label" variant="caption">
                DOB
              </Typography>
              <Typography variant="caption">
                {location?.state?.customerData?.dateOfBirth
                  ? formatDateTime(
                      location?.state?.customerData?.dateOfBirth,
                      Constants.dateFormatDDmmYYYY
                    )
                  : "----"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item lg={4} xl={4}>
          <Typography gutterBottom>Contact Information</Typography>
          <Grid container columns={4}>
            <Grid item lg={2} xl={2}>
              <Typography className="card-label" variant="caption">
                Phone Number
              </Typography>
              <Typography variant="caption">
                {location?.state?.customerData?.phoneNumber
                  ? `${location?.state?.customerData?.countryCode} ${location?.state?.customerData?.phoneNumber}`
                  : "----"}
              </Typography>
            </Grid>
            <Grid item lg={2} xl={2}>
              <Typography className="card-label" variant="caption">
                Email Address
              </Typography>
              <Typography variant="caption">
                {location?.state?.customerData?.email
                  ? location?.state?.customerData?.email
                  : "----"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item lg={4} xl={4}>
          <Typography gutterBottom>Address</Typography>
          <Typography variant="caption">
            {getAddress()}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container padding={"2rem"} justifyContent={"flex-start"}>
        <Typography gutterBottom>Access and Security</Typography>
        <Grid item lg={12} xl={12} md={12}>
          <Grid container>
            <Grid item lg={3} xl={3}>
              <Typography className="card-label" variant="caption">
                Push Notifications
              </Typography>
              <Typography variant="caption">
                {location?.state?.customerData?.showPushNotifications
                  ? "On"
                  : "Off"}
              </Typography>
            </Grid>

            <Grid item lg={3} xl={3}>
              <Typography className="card-label" variant="caption">
                Whatsapp Communication
              </Typography>
              <Typography variant="caption">
                {location?.state?.customerData?.whatsappConsent ? "On" : "Off"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralInfo;
