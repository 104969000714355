import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import {
  ButtonComponent,
  FormFieldContainer,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useFormikContext } from "formik";
import { handleNumberFieldChange } from "utils/ValidationUtils";
import BrandVideoList from "./BrandVideoList";
import BrandVideoUploadDialog from "./BrandMediaUploadDialog";

const CarouselsContainer = styled(FormFieldContainer)`
  padding: 0;
`;

function BrandVideos(props: any) {
  const [openMediaUploadDialog, setOpenMediaUploadDialog] = useState({
    open: false,
    pageManagementOrBrandMedia: "",
  });
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  console.log("BrandVideos values", values);
  console.log("BrandVideos errors", errors);
  console.log("BrandVideos touched", touched);

  const closeMediaUploadDialog = () => {
    setOpenMediaUploadDialog({
      ...openMediaUploadDialog,
      open: false,
      pageManagementOrBrandMedia: "",
    });
  };

  return (
    <>
      <CarouselsContainer>
        <Grid container p={"1rem"}>
          <Grid item xs={6}>
            <Stack columnGap={2} justifyContent={"flex-start"}>
              <Typography className="accordion-heading" margin={"0.5rem 0"}>
                Brand Videos
              </Typography>
              <SwitchComponent
                name="isBrandMediaActive"
                checked={values?.isBrandMediaActive}
                value={values?.isBrandMediaActive}
                onChange={(event: any) => {
                  //   console.log("event", event.target.checked);
                  setFieldValue("isBrandMediaActive", event.target.checked);
                }}
              />
              <Tooltip
                title={"This Represents the Brand Videos on Customer app"}
                arrow
                placement="top-start"
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  className="infooutlined-alignment"
                />
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
        <Divider />

        {values?.brandMedia?.length > 0 ? (
          <Box sx={{ padding: "20px" }}>
            <BrandVideoList
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
            />
          </Box>
        ) : (
          ""
        )}
        <Box sx={{ paddingY: "25px", paddingX: "20px" }}>
          <ButtonComponent
            variant="contained"
            disabled={!values?.isBrandMediaActive}
            startIcon={<FileUploadOutlinedIcon />}
            onClick={() => {
              setOpenMediaUploadDialog({
                ...openMediaUploadDialog,
                open: true,
              });
            }}
          >
            {!(values?.brandMedia?.length > 0)
              ? "Add media"
              : "Add another Media"}
          </ButtonComponent>
          {errors?.isBrandMediaPresent ? (
            <Typography sx={{ color: "red" }}>
              {errors?.isBrandMediaPresent}
            </Typography>
          ) : null}
        </Box>
      </CarouselsContainer>
      {openMediaUploadDialog?.open ? (
        <BrandVideoUploadDialog
          open={openMediaUploadDialog?.open}
          MediaObject={openMediaUploadDialog?.pageManagementOrBrandMedia}
          closeMediaUploadDialog={closeMediaUploadDialog}
        />
      ) : null}
    </>
  );
}

export default BrandVideos;
