import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({
  addChatManagementDataSuccess: ["response"],
  addChatManagementDataFailure: ["error"],
  addChatManagementDataRequest: ["data"],

  getChatManagementDataSuccess: ["response"],
  getChatManagementDataFailure: ["error"],
  getChatManagementDataRequest: ["data"],

  updateChatManagementDataSuccess: ["response"],
  updateChatManagementDataFailure: ["error"],
  updateChatManagementDataRequest: ["data"],

  setChatManagementState:["key","value"],
});

export const chatManagementTypes = Types;

export default Creators;

export const INITIAL_STATE: any = {
  chatManagementData: null,
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  isDialogOpen: false,
  confirmDialogOpen: false,
  openFlashMessage: false,
  isDataPresent: false
};

export const addChatManagementDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const addChatManagementDataSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Chat Data added successfully!",
      isLoading: false,
    };
  }
};

export const addChatManagementDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const getChatManagementDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    openFlashMessage: false
  };
};

export const getChatManagementDataSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      chatManagementData: response.data,
      message: response.message,
      isLoading: false,
      isDataPresent: true,
      openFlashMessage: false
    };
  }
};

export const getChatManagementDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    chatManagementData: null,
    isDataPresent: false,
    openFlashMessage: false
  };
};

export const updateChatManagementDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateChatManagementDataSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Chat Data Updates successfully!",
      isLoading: false,
      openFlashMessage: true,
    };
  }
};

export const updateChatManagementDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    openFlashMessage: true,
    message: action.error.message,
    isSuccess: false,
  };
};


export const setChatManagementState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_CHAT_MANAGEMENT_DATA_SUCCESS]: addChatManagementDataSuccess,
  [Types.ADD_CHAT_MANAGEMENT_DATA_FAILURE]: addChatManagementDataFailure,
  [Types.ADD_CHAT_MANAGEMENT_DATA_REQUEST]: addChatManagementDataRequest,

  [Types.GET_CHAT_MANAGEMENT_DATA_SUCCESS]: getChatManagementDataSuccess,
  [Types.GET_CHAT_MANAGEMENT_DATA_FAILURE]: getChatManagementDataFailure,
  [Types.GET_CHAT_MANAGEMENT_DATA_REQUEST]: getChatManagementDataRequest,

  [Types.UPDATE_CHAT_MANAGEMENT_DATA_SUCCESS]: updateChatManagementDataSuccess,
  [Types.UPDATE_CHAT_MANAGEMENT_DATA_FAILURE]: updateChatManagementDataFailure,
  [Types.UPDATE_CHAT_MANAGEMENT_DATA_REQUEST]: updateChatManagementDataRequest,
  [Types.SET_CHAT_MANAGEMENT_STATE]: setChatManagementState,
});
