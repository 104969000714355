import {
  Checkbox,
  Paper,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Typography,
  Tooltip,
  IconButton,
  Avatar,
  ClickAwayListener,
  Popper,
  Box,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { format, compareAsc } from "date-fns";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { AllProjectFaqsAction } from "redux-container/project-management/ProjectAllFaqsRedux";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";
import { RouteConstants } from "routes/RouteConstants";
import { useLocation, useNavigate } from "react-router-dom";
import MediaAssets from "assets";
import { IInitinalValueProjectContent } from "../../InitinalValuesProjectContent";
import { useFormikContext } from "formik";
import ProjectAllFaqsListAction from "redux-container/project-management/project-content/ProjectAllFaqsListRedux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import ProjectContentCreationAction from "redux-container/project-management/project-content/ProjectContentRedux";
import { ReadmoreDialogWrapper } from "pages/promise-management/promise-list/PromiseList";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { FaqsTypeEnum } from "enumerations/FaqsTypes";

const tableHeadings = [
  { heading: "Question" },
  { heading: `Category` },
  { heading: "Created On" },
  { heading: "Modified On" },
  { heading: "Last Modified By" },
  { heading: "Priority" },
  { heading: "Action" },
];

const actionDispatch = (dispatch: any) => {
  return {
    setALLProjectFaqState: (key: any, value: any) =>
      dispatch(AllProjectFaqsAction.setALLProjectFaqState(key, value)),
    setProjectFaqState: (key: any, value: any) =>
      dispatch(ProjectFaqActions.setProjectFaqState(key, value)),
    projectFaqsBulkDelete: (data: any) =>
      dispatch(ProjectFaqActions.projectFaqBulkDeleteRequest(data)),
    getProjectFaqs: (params: any) =>
      dispatch(
        ProjectAllFaqsListAction.getProjectAllFaqsListDataRequest(params)
      ),
    deleteProjectMappedFaq: (data: any) =>
      dispatch(
        ProjectContentCreationAction.deleteMappedProjectContentFaqRequest(data)
      ),
  };
};
export default function TableForProjectMapedDetails() {
  const navigate: any = useNavigate();
  const {
    setALLProjectFaqState,
    setProjectFaqState,
    projectFaqsBulkDelete,
    getProjectFaqs,
    deleteProjectMappedFaq,
  } = actionDispatch(useDispatch());
  const mappedFaqs = useSelector(
    (state: any) => state?.projectAllfaqs?.allProjectFaqs
  );

  const location: any = useLocation();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [removePriority, setRemovePriority] = useState<any>();
  const [removeIndex, setRemoveIndex] = useState();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [question, setQuestion] = useState("");

  const needThisArrayTofilterAllFaqs = useSelector(
    (state: any) => state?.projectAllfaqs.filterAllfaqBasedGivenArray
  );

  const EditFaqs = (faq: any) => {
    navigate(
      `${RouteConstants.projectCreation}/${RouteConstants.projectFaqCreation}`,
      {
        state: {
          ...faq,
          isFaqCreation: true,
          isFaqTable: true,
          ...location.state,
        },
      }
    );
  };
  const viewFaqs = (faq: any) => {
    navigate(
      `${RouteConstants.projectCreation}/${RouteConstants.projectFaqCreation}`,
      {
        state: {
          ...faq,
          isFaqCreation: true,
          isFaqTable: true,
          isOnlyView: true,
          ...location.state,
        },
      }
    );
  };

  const projectFaqs = useSelector(
    (state: any) => state?.projectFaqs?.projectFaqs
  );
  const projectCategories = useSelector(
    (state: any) => state?.projectCategories?.mappedCategoryArray
  );
  const projectAllFaqsList = useSelector(
    (state: any) => state?.projectAllFaqsList?.projectAllFaqsListData
  );

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    errors,
    touched,
    isValid,
  } = useFormikContext<IInitinalValueProjectContent>();
  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    const getCategoryParams = {
      typeOfFAQ: FaqsTypeEnum.ProjectFaq,
      shouldDisplayMappedFaqs: false,
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    getProjectFaqs(getCategoryParams);
    let mapProjectFaqs = projectAllFaqsList.filter((item: any) =>
      projectCategories?.includes(item?.categoryId)
    );
    const mappedProjectIds = mapProjectFaqs.map((item: any) => item.id);
    setFieldValue("mappedFaqs", mappedProjectIds);
  }, [projectAllFaqsList?.length, projectCategories?.length]);

  const handleDeleteFaq = () => {
    const filterArray = mappedFaqs.filter((faq: any) => faq.id !== removeId);
    const deletedFaqs = needThisArrayTofilterAllFaqs.filter(
      (faqId: any) => faqId !== removeId
    );
    setALLProjectFaqState("filterAllfaqBasedGivenArray", deletedFaqs);
    const mappedArray = filterArray.map((faq: any) => {
      if (faq?.priority > removePriority) {
        return {
          ...faq,
          priority: faq?.priority - 1,
        };
      }
      return { ...faq };
    });
    setALLProjectFaqState("allProjectFaqs", mappedArray);
    setALLProjectFaqState("isFaqAddOrRemoved", true);
    setProjectFaqState("projectFaqs", deletedFaqs);
    setProjectFaqState("isProjectFaqResponse", true);
    setProjectFaqState("isProjectFaqResponse", true);
    setProjectFaqState("mappedFaqs", deletedFaqs);

    if (!location?.state?.isNew) {
      const deleteIds = {
        projectId: location?.state?.ProjectListCreationId,
        faqId: removeId,
      };
      deleteProjectMappedFaq(deleteIds);
    }

    setOpenConfirmationDialog(false);
    navigate(RouteConstants.projectCreation, { state: { ...location.state } });
  };

  const handleDecrement = (id: any, priority: any) => {
    const decrementFaqDataMappedFaqs = mappedFaqs.map((faq: any) => {
      if (id === faq.id) {
        return {
          ...faq,
          priority: priority - 1,
        };
      } else {
        if (faq.priority === priority - 1) {
          return {
            ...faq,
            priority: faq.priority + 1,
          };
        }
        return { ...faq };
      }
    });
    setALLProjectFaqState("allProjectFaqs", decrementFaqDataMappedFaqs);
  };
  useEffect(() => {
    if (mappedFaqs?.length > 1) {
      const extractingMappedFaqId = mappedFaqs?.map((item: any) => {
        if (item?.id) {
          return { faqId: item?.id, priority: item?.priority };
        }
      });
      setFieldValue("faqsToDisplayInDetails", extractingMappedFaqId);
    }
  }, [
    mappedFaqs?.length,
    projectAllFaqsList?.length,
    projectCategories?.length,
  ]);

  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const handleIncrement = (id: any, priority: any) => {
    const incrementFaqDataMappedFaqs = mappedFaqs.map((faq: any) => {
      if (id === faq.id) {
        return {
          ...faq,
          priority: priority + 1,
        };
      } else {
        if (faq.priority === priority + 1) {
          return {
            ...faq,
            priority: faq.priority - 1,
          };
        }
        return { ...faq };
      }
    });

    setALLProjectFaqState("allProjectFaqs", incrementFaqDataMappedFaqs);
  };

  const handlePriorityChangeIndex = (
    indexValue: any,
    list: any,
    priorityUpdatevalue: any,
    updatedItem: any
  ) => {
    if (indexValue) {
      list.splice(indexValue, 1);
      list.splice(indexValue + priorityUpdatevalue, 0, updatedItem);
      return list;
    }
  };

  const popOverComponent = (event: any, question: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
    setQuestion(question);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const viewFAQ = (faq: any) => {
    setFieldValue("categoryId", faq?.category?.name);
    navigate(
      `${RouteConstants.projectCreation}/${RouteConstants.projectFaqCreation}`,
      {
        state: {
          ...location.state,
          isFaqTable: true,
          isOnlyView: true,
          isFaqCreation: true,
          openForm: true,
          ...faq,
          isFormEdit: true,
        },
      }
    );
  };

  /// edit on faqlist item
  const editFAQ = (faq: any) => {
    setFieldValue("categoryId", faq?.category?.name);
    navigate(
      `${RouteConstants.projectCreation}/${RouteConstants.projectFaqCreation}`,
      {
        state: {
          ...location.state,
          isFaqTable: true,
          isFaqCreation: true,
          openForm: true,
          ...faq,
          isFormEdit: true,
          isOnlyView: false,
        },
      }
    );
  };

  return (
    <Fragment>
      <TableContainer
        component={Paper}
        className="table-container"
        style={{ height: window.innerHeight - 600 }}
      >
        <Table style={{ position: "relative" }}>
          <TableHead>
            <TableRow className="table-header-row">
              {tableHeadings?.map((item, index) => (
                <TableCell key={index} className="table-header-text">
                  <Stack justifyContent="flex-start">
                    <div>{item.heading}</div>
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {mappedFaqs?.length !== 0 ? (
            <TableBody>
              {mappedFaqs &&
                mappedFaqs?.map((faqsList: any, index: any) => {
                  return (
                    <TableRow
                      key={faqsList?.id}
                      className="table-data-row"
                      role="checkbox"
                    >
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 200 }}
                      >
                        <div>
                          {faqsList?.faqQuestion?.question?.length > 20 ? (
                            <Stack justifyContent={"flex-start"} spacing={2}>
                              <span>
                                {" "}
                                {`${faqsList?.faqQuestion?.question.slice(
                                  0,
                                  18
                                )}...`}
                              </span>
                              <Typography
                                component="span"
                                variant="body1"
                                color="#2d2d2d"
                                sx={{
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  wordBreak: "keep-all",
                                  textAlign: "start",
                                  width: "110px",
                                }}
                                onClick={(event: any) =>
                                  popOverComponent(
                                    event,
                                    faqsList?.faqQuestion?.question
                                  )
                                }
                              >
                                READ MORE
                              </Typography>
                            </Stack>
                          ) : (
                            faqsList?.faqQuestion?.question
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 200 }}
                      >
                        {faqsList?.category?.name}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>
                          {faqsList?.createdAt !== undefined
                            ? format(
                                new Date(faqsList?.createdAt),
                                "dd/MM/yyyy"
                              )
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>
                          {faqsList?.updatedAt !== undefined
                            ? format(
                                new Date(faqsList?.updatedAt),
                                "dd/MM/yyyy"
                              )
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>
                          {faqsList?.faqsUpdatedAdmin?.firstName
                            ? `${faqsList?.faqsUpdatedAdmin?.firstName}`
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <PrioritySelector
                          priority={index + 1}
                          incrementpriority={() => {
                            if (index === 0) {
                              mappedFaqs.splice(0, 1);
                              mappedFaqs.splice(1, 0, faqsList);
                            } else {
                              handlePriorityChangeIndex(
                                index,
                                mappedFaqs,
                                1,
                                faqsList
                              );
                            }
                            handleIncrement(faqsList.id, faqsList?.priority);
                          }}
                          decrementpriority={() => {
                            handlePriorityChangeIndex(
                              index,
                              mappedFaqs,
                              -1,
                              faqsList
                            );
                            handleDecrement(faqsList.id, faqsList?.priority);
                          }}
                          disableIncrement={index + 1 === mappedFaqs?.length}
                          disableDecrement={index === 0}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit" placement="top">
                          <IconButton onClick={() => editFAQ(faqsList)}>
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton onClick={() => viewFAQ(faqsList)}>
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                        <IconButton>
                          <Typography
                            sx={
                              isViewMode
                                ? {
                                    cursor: "pointer",
                                    color: "black",
                                    textDecoration: "underline",
                                    pointerEvents: "none",
                                  }
                                : {
                                    cursor: "pointer",
                                    color: "black",
                                    textDecoration: "underline",
                                  }
                            }
                            onClick={() => {
                              setRemoveId(faqsList?.id);
                              setRemovePriority(faqsList?.priority);
                              setRemoveIndex(index);
                              setOpenConfirmationDialog(true);
                            }}
                          >
                            Remove
                          </Typography>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            <div className="no-record">No Faqs Records</div>
          )}
        </Table>
      </TableContainer>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Popper open={open} placement="bottom" anchorEl={anchor}>
          <ReadmoreDialogWrapper>
            <Box>
              <Paper elevation={3} sx={{ padding: "0.5rem", width: "350px" }}>
                {question}
              </Paper>
            </Box>
          </ReadmoreDialogWrapper>
        </Popper>
      </ClickAwayListener>
      <ConfirmationDialog
        shouldOpen={openConfirmationDialog}
        content={
          "Are You Sure?. Do You Want To Remove Mapped FAQ from This Project?"
        }
        cancelHandler={() => setOpenConfirmationDialog(false)}
        okText="Remove"
        okHandler={() => handleDeleteFaq()}
      />
    </Fragment>
  );
}
