import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonComponent, TextFieldComponent } from "components/ui-elements";
import { GetAllCategoriesAction } from "redux-container/faq/GetAllCategoriesRedux";
import { useDispatch, useSelector } from "react-redux";

import { Box, IconButton, InputAdornment, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { projectCategoryForFaqAction } from "redux-container/project-management/ProjectCategoryForFaqCreationRedux";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";

interface IFaqCategoryEditDialogProps {
  open: any;
  handleClose: () => any;
  editCategory: any;
  handleInputChange: (e: any) => void;
  categoryId: any;
  handleResetCategoryField: () => void;
  setCategoryNameList: any;
  categoryNameList: any;
}
const actionDispatch = (dispatch: any) => {
  return {
    editCategory: (id: any, data: any) =>
      dispatch(
        projectCategoryForFaqAction.putProjectCategoryForFaqCreationRequest(
          id,
          data
        )
      ),
  };
};

const ProjectCategoryEditPopUp = (props: IFaqCategoryEditDialogProps) => {
  const { editCategory } = actionDispatch(useDispatch());

  const [open, setOpen] = React.useState(false);
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );
  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpadteCategoryName = () => {
    const reqBody = {
      name: props.editCategory,
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };

    editCategory(props.categoryId, reqBody);
    props.setCategoryNameList([...props.categoryNameList, props.editCategory]);
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "483px",
            },
          },
        }}
      >
        <DialogTitle>Edit Category</DialogTitle>
        <Box pt={2} px={2}>
          <TextFieldComponent
            margin="dense"
            id="name"
            fullWidth
            name="categoryName"
            variant="outlined"
            label="Category"
            value={props.editCategory}
            onChange={props.handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {props.editCategory ? (
                    <IconButton onClick={props.handleResetCategoryField}>
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </InputAdornment>
              ),
            }}
            disabled={isViewMode}
          />
        </Box>
        <Box p={2}>
          <Stack spacing="1rem" justifyContent={"flex-end"}>
            <ButtonComponent disabled={isViewMode} onClick={props.handleClose}>
              Cancel
            </ButtonComponent>
            <ButtonComponent
              disabled={isViewMode}
              variant="contained"
              onClick={handleUpadteCategoryName}
            >
              Save Changes
            </ButtonComponent>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default ProjectCategoryEditPopUp;
