import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import React from "react";
import TextFieldComponent from "../text-field/TextFieldComponent";
import ButtonComponent from "../button/ButtonComponent";
import ClearIcon from "@mui/icons-material/Clear";

const OptionEditDialog = (props: any) => {
  const {
    open,
    titleText,
    handleClose,
    handleUpdateOption,
    handleChangeOptionValue,
    optionValue,
    handleResetField,
  } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "483px",
            },
          },
        }}
        PaperProps={{ sx: { padding: 3 } }}
      >
        <DialogTitle>Edit {titleText}</DialogTitle>
        <Box p={2}>
          <TextFieldComponent
            margin="dense"
            id="name"
            fullWidth
            variant="outlined"
            placeholder="Enter Dropdown value"
            // label="value"
            value={optionValue}
            onChange={handleChangeOptionValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {optionValue.length > 0 ? (
                    <IconButton onClick={handleResetField}>
                      <ClearIcon />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box p={2}>
          <Stack spacing="2rem" justifyContent={"center"}>
            <ButtonComponent onClick={handleClose}>Cancel</ButtonComponent>
            <ButtonComponent
              disabled={!optionValue}
              variant="contained"
              onClick={handleUpdateOption}
            >
              Save Changes
            </ButtonComponent>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default OptionEditDialog;
