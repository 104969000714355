import { InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import QuillEditor from "components/quill-editor/QuillEditor";
import { SwitchComponent, TextFieldComponent } from "components/ui-elements";
import { Form, getIn, useFormikContext } from "formik";
import { Fragment } from "react";
import styles from "./TermsAndConditions.module.css";
import { TermsAndConditionsConstants } from "./TermsAndConditionsConstant";
import { IPage5ProfileInitialValue } from "../page-5-profile-IniticalValue";
import { useSelector } from "react-redux";
import { stripHtml } from "utils/ValidationUtils";

interface ITermsAndConditions {
  sectionHeading: string;
  mediaDescription: any;
}
const TermsAndConditions = () => {
  const {
    values,
    handleChange,
    setFieldValue,
    setFieldError,
    errors,
    touched,
    handleBlur,
  } = useFormikContext<IPage5ProfileInitialValue>();

  const { isReduxViewMode } = useSelector(
    (state: any) => state?.pageManagement
  );

  const handleTextEditor = (
    name: string,
    newValue: string,
    options: any,
    { setFieldValue, setFieldError }: any
  ) => {
    // if (options?.isError) {
    //   setFieldError(name, "Maximum character exceed");
    // } else {
    // setFieldValue(name, newValue);
    if (!!newValue) {
      setFieldValue(name, newValue);
    }
    // }
  };

  return (
    <Fragment>
      <div className={styles["accordian-global-padding"]}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={12}
            xl={12}
            className={styles["priority-holder"]}
          >
            <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
              <Typography>Terms & Conditions</Typography>

              <FormControlLabel
                className="switch-form-label"
                name={"isTermsActive"}
                checked={values?.isTermsActive}
                onChange={handleChange}
                disabled={isReduxViewMode || false}
                control={<SwitchComponent />}
                label=""
                labelPlacement="start"
              />
              <IconButton>
                <Tooltip title={"Terms & Conditions"} placement="top" arrow>
                  <Avatar
                    className={styles["Avator"]}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={4} mt={3}>
            <TextFieldComponent
              required
              label="Section Heading"
              placeholder="Enter"
              name={`termsAndConditions.displayName`}
              disabled={isReduxViewMode || false}
              inputProps={{
                maxLength:
                  TermsAndConditionsConstants.termsAndConditionsDisplayName,
              }}
              value={values?.termsAndConditions?.displayName}
              onChange={handleChange}
              onBlur={handleBlur}
              fieldhelpertext={
                getIn(touched, "termsAndConditions.displayName") &&
                getIn(errors, "termsAndConditions.displayName")
              }
              error={Boolean(
                getIn(errors, "termsAndConditions.displayName") &&
                  getIn(touched, "termsAndConditions.displayName")
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Stack
              justifyContent={"flex-start"}
              className={styles["Upload-heading"]}
            >
              <Typography>Description</Typography>
              <IconButton>
                <Tooltip
                  title={
                    "Description can be a combination of more than 1 Paragraph. Maximum Character limit for the complete Terms & Conditions is 1,00,000."
                  }
                  placement="top"
                >
                  <Avatar
                    className={styles["Avator"]}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>
            </Stack>
            <QuillEditor
              placeholder="Enter description"
              // className={styles["QuillEditor"]}
              onChange={(name: string, newValue: string, options: any) =>
                handleTextEditor(name, newValue, options, {
                  setFieldValue,
                  setFieldError,
                })
              }
              inputProps={{
                maxLength: TermsAndConditionsConstants.description,
              }}
              value={values?.termsAndConditions?.description}
              style={{
                height: "20rem",
              }}
              disabled={isReduxViewMode}
              name={`termsAndConditions.description`}
              onBlur={handleBlur}
              fieldhelpertext={
                stripHtml(values?.termsAndConditions?.description).length ===
                  0 && errors?.termsAndConditions?.description
              }
              error={Boolean(
                getIn(errors, "termsAndConditions.description") &&
                  getIn(touched, "termsAndConditions.description")
              )}
            />
          </Grid>
        </Grid>
      </div>

      {/* <Divider className={styles["accordian-global-padding"]} />

      <div className={styles["accordian-global-padding"]}>
        <Grid className={styles["button-position"]}>
          <Stack
            direction={"row"}
            columnGap={2}
            justifyContent={"space-around"}
            mb={2}
          >
            <ButtonComponent
              // onClick={createInsightsCreation}
              color={"inherit"}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              type="reset"
              disabled={!isValid}
              data-testid="save-and-new-button"
              onClick={() => handleSaveAndNew(values)}
            >
              Save as Drafts
            </ButtonComponent>
            <LoadingButtonComponent
              type="submit"
              data-testid="form-save-button"
            >
              Publish
            </LoadingButtonComponent>
          </Stack>
        </Grid>
      </div> */}
    </Fragment>
  );
};

export default TermsAndConditions;
