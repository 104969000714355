import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./PdfDocumentUploader.module.css";
import MediaAssets from "assets";
import PdfUpload from "../pdf-upload/PdfUpload";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

interface IPdfUploadComponent {
  uploadName: string;
  value: any;
  isLoading: boolean;
  isViewMode: boolean;
  handleImageUpload: (file: any) => void;
  handleDeletePdf?: (id: any) => void;
  error?: boolean;
}

const PdfDocumentUploader = (props: IPdfUploadComponent) => {
  const {
    uploadName,
    value,
    isLoading,
    isViewMode,
    handleImageUpload,
    handleDeletePdf,
    error,
  } = props;

  const documentLabel = value
    ? value?.split("/")[value?.split("/")?.length - 1]
    : value
    ? value
    : "";

  return (
    <div>
      <Box sx={{ width: "400px", margin: "0px 30px" }}>
        {value ? (
          <Stack spacing={2}>
            <Box className={styles["view-replace-wrapper"]}>
              {isLoading ? (
                <Box className={styles["loader-content"]}>
                  <CircularProgress />
                </Box>
              ) : (
                <Stack spacing={1}>
                  <Avatar
                    alt="Document"
                    src={MediaAssets.ic_google_docs}
                    variant="rounded"
                  />
                  <Box sx={{ width: "150px", textAlign: "center" }}>
                    <Tooltip title={documentLabel} placement={"top"}>
                      <Typography>
                        {documentLabel?.length > 15
                          ? documentLabel?.slice(0, 13) + "..."
                          : documentLabel}
                      </Typography>
                    </Tooltip>
                  </Box>
                  {!isViewMode && (
                    <Box component={"label"} htmlFor={`${uploadName}`}>
                      <input
                        disabled={isViewMode}
                        accept={".pdf"}
                        id={uploadName}
                        type="file"
                        name={uploadName}
                        hidden
                        onChange={handleImageUpload}
                      />
                      <span className={styles["replace-button"]}>Replace</span>
                    </Box>
                  )}
                  <a
                    target="_blank"
                    href={`${value}`}
                    className={styles["link-a-tag"]}
                  >
                    View
                  </a>

                  {!isViewMode && handleDeletePdf && (
                    <DeleteOutlineOutlinedIcon
                      onClick={handleDeletePdf}
                      className="table-action-icon delete-icon"
                    />
                  )}
                </Stack>
              )}
            </Box>
          </Stack>
        ) : (
          <PdfUpload
            onChange={handleImageUpload}
            disabled={isViewMode}
            name={uploadName}
            loading={isLoading}
            error={error}
          />
        )}
      </Box>
    </div>
  );
};

export default PdfDocumentUploader;
