import { Fragment, useState, useEffect, ChangeEvent } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MediaAssets from "../../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SwitchComponent from "components/ui-elements/switch/SwitchComponent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import PromisesListActions, {
  setPromiseListState,
} from "../../../redux-container/promises/PromisesListRedux";
import Loader from "../../../components/loader/Loader";
import {
  Checkbox,
  IconButton,
  Stack,
  Typography,
  Collapse,
  ClickAwayListener,
  Popper,
  styled,
  Box,
} from "@mui/material";
import {
  StatusTypeEnum,
  StatusTypeEnumUtils,
  TestimonialStatusTypeEnum,
  TestimonialStatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import {
  ActionTypeEnum,
  ActionTypeEnumUtils,
  TestimonialActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import { exportAsExcel } from "utils/ExportFileUtils";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { InfoOutlined } from "@mui/icons-material";
import styles from "./PromiseList.module.css";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import PromiseActions from "../../../redux-container/promises/PromisesListRedux";
import UserActions from "../../../redux-container/users/UsersRedux";
import {
  ActionTypeEnumPromise,
  ActionTypeEnumUtilsPromise,
} from "enumerations/ActionTypeEnum";
import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { AddPromiseContent } from "../promise-creation/PromiseCreationConstants";
import Retry from "components/retry/Retry";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import axios from "axios";
import { AuditStatus } from "enumerations/AuditStatusTypes";
export const ReadmoreDialogWrapper = styled(Box)`
  &&& .MuiPaper-root {
    overflow-wrap: break-word;
    width: 200px;
    margin-right: 20px;
  }
`;

const actionDispatch = (dispatch: any) => {
  return {
    getPromises: (params: any) =>
      dispatch(PromiseActions.promisesListRequest(params)),
    bulkDeletePromise: (data: any) =>
      dispatch(PromiseActions.bulkDeletePromiseRequest(data)),
    setPromiseState: (key: any, data: any) =>
      dispatch(PromiseActions.setPromiseListState(key, data)),
    updatePromiseStatus: (data: any) =>
      dispatch(PromiseActions.updatePromiseStatusRequest(data)),
    updatePromisePriority: (data: any) =>
      dispatch(PromiseActions.updatePromisePriorityRequest(data)),
    changePromiseStatus: (data: any) => {
      dispatch(PromiseActions.promiseStatusRequest(data));
    },
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),
    getPromisesCopy: (params: any) =>
      dispatch(PromiseActions.promisesListCopyRequest(params)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    getAuditLogRequest: (data: any) =>
      dispatch(AuditActions.getAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const PromiseList = () => {
  const {
    getPromises,
    updatePromiseStatus,
    setPromiseState,
    changePromiseStatus,
    updatePromisePriority,
    bulkDeletePromise,
    getUsers,
    getPromisesCopy,
    postAuditLogRequest,
    rolesAndModulesRequest,
  } = actionDispatch(useDispatch());

  // -----------
  const navigate = useNavigate();
  const location: any = useLocation();
  const actions = ActionTypeEnumUtilsPromise.getActionTypeEnumsPromise();
  const statuses =
    TestimonialStatusTypeEnumUtils.getTestimonialStatusTypeEnums();
  const promises = useSelector((state: any) => state?.promisesList?.promises);
  const promisesCopy = useSelector(
    (state: any) => state?.promisesList?.promisesCopy
  );
  const isLoading = useSelector((state: any) => state?.promisesList?.isLoading);
  const totalCount = useSelector((state: any) => state.promisesList.totalCount);
  const users = useSelector((state: any) => state?.users?.usersForFilters);
  const error = useSelector((state: any) => state?.promisesList?.error);
  const isSuccess = useSelector((state: any) => state?.promisesList?.isSuccess);
  const isPriorityUpdated = useSelector(
    (state: any) => state?.promisesList?.isPriorityUpdated
  );
  const message = useSelector((state: any) => state?.promisesList?.message);
  const { page, rowsPerPage, modules } = useSelector((state: any) => {
    return {
      page: state?.promisesList?.page,
      rowsPerPage: state?.promisesList?.rowsPerPage,
      modules: state?.rolesAndModules?.module,
    };
  });
  const [sortByPriority, setSortByPriority] = useState(true);
  const [selectedPromises, setSelectedPromises] = useState<any>([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCreatedBy, setCreatedBy] = useState<any>(null);
  const [selectedPublishedBy, setPublishedBy] = useState("");
  const [selectedModifiedBy, setModifiedBy] = useState<any>(null);
  const [searchKey, setSearchKey] = useState("");

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const [anchor, setAnchor] = useState(null);
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false);

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  const [modulesAudited, setModulesAudited] = useState<any>(null);
  const breadcrumbRoutes: any = [
    {
      name: "Promise Management",
      route: RouteConstants.promiseManagementDashboard,
    },
    {
      name: "Promise List",
      route: RouteConstants.promiseList,
    },
  ];

  //table headings (heading objects with name,boolean key for whether it is sorting column or not,and sorting key for an API call )
  const tableHeadings = [
    { heading: "Name" },
    { heading: "Short Description" },
    { heading: "Created on" },
    { heading: "Published on" },
    { heading: "Modified on" },
    { heading: "Last Modified By" },
    { heading: "Priority" },
    { heading: "Status" },
    { heading: "Actions" },
  ];

  useEffect(() => {
    getFilterDataFromDashboard();
  }, []);

  useEffect(() => {
    rolesAndModulesRequest();
    return () => {};
  }, []);

  //Loading Data (Customers data) while mounting and
  // every time after User applies any filters (Promise Name,status,Search key,createdBy,updatedBy,page and number of rows per page) .
  useEffect(() => {
    const params = {
      index: page,
      size: rowsPerPage,
      status: location?.state?.statusType
        ? location?.state?.statusType
        : selectedStatus,
      updatedBy: selectedModifiedBy?.id,
      createdBy: selectedCreatedBy?.id,
      searchKey: searchKey,
      byPrority: sortByPriority,
      promiseName: selectedName,
    };
    if (
      searchKey ||
      selectedStatus ||
      selectedCreatedBy ||
      rowsPerPage ||
      page ||
      selectedModifiedBy ||
      sortByPriority ||
      selectedName
    ) {
      loadData(params);
      clearSelected();
    }
  }, [
    page,
    rowsPerPage,
    searchKey,
    selectedStatus,
    selectedCreatedBy,
    selectedModifiedBy,
    sortByPriority,
    selectedName,
  ]);

  useEffect(() => {
    if (isSuccess) {
      setPromiseState("isSuccess", false);
      loadData({});
      clearSelected();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isPriorityUpdated) {
      setPromiseState("isPriorityUpdated", false);
      const params = {
        index: page,
        size: rowsPerPage,
        updatedBy: selectedModifiedBy,
        status: selectedStatus,
        createdBy: selectedCreatedBy,
        searchKey: searchKey,
        byPrority: sortByPriority,
        promiseName: selectedName,
      };
      loadData(params);
      clearSelected();
    }
  }, [isPriorityUpdated]);

  const loadData = (params: any) => {
    getPromises(params);
    getPromisesCopy({});
    // getUsers({});
  };

  //setting page and rowsPerPage to initial value if user Navigated to the list from Side menu.
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setPromiseState("page", 1);
      setPromiseState("rowsPerPage", 20);
    }

    return () => {};
  }, []);
  const getFilterDataFromDashboard = () => {
    if (location && location.state && location.state.statusType) {
      setSelectedStatus(location.state.statusType as string);
    }
    navigate(location.pathname, { replace: true });
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  //handle change function for page in Pagination.
  const handleChangePage = (event: unknown, newPage: number) => {
    setPromiseState("page", newPage + 1);
  };

  //handle change function for number of elements per Page in Pagination.
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPromiseState("rowsPerPage", Number(event.target.value));
    setPromiseState("page", 1);
  };

  const handleChangePrioritySwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSortByPriority(event.target.checked);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  //function to show Description  when user clicks on READ MORE.
  const popOverComponent = (event: any, description: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
    setDescription(description);
  };

  //handle change for Action Selection with API calls .
  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case ActionTypeEnumPromise.Export:
        handleExportAsExcel();
        break;
      case ActionTypeEnumPromise.Delete:
        handleDeletePromise();
        break;
      case ActionTypeEnumPromise.MarkAsPublished:
        changePromiseStatuses(String(TestimonialStatusTypeEnum.Published));
        break;
      case ActionTypeEnumPromise.MarkAsInactive:
        changePromiseStatuses(String(TestimonialStatusTypeEnum.Inactive));
        break;
    }
  };

  const handleDeletePromise = () => {
    if (selectedPromises.length === 0) {
      alert("You have not selected any promises");
      return;
    }
    setIsConfirmDeleteDialogOpen(true);
  };

  //Status delete and Audit Log API call.
  const deleteSelectedPromises = () => {
    setIsConfirmDeleteDialogOpen(false);
    const promise = {
      ids: selectedPromises,
    };
    let auditedPromises = statusAuditedPromises();
    let auditObjectsArray: any[] = [];
    auditedPromises?.map((promise: any) => {
      let auditObject = {
        itemAffected: `Promise ${promise?.name} Deleted.(ID${promise?.id})`,
        newValue: "",
        oldValue: "",
        auditedObject: null,
        route: null,
        status: AuditStatus.DELETED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    bulkDeletePromise(promise);
    clearSelected();
  };

  //Status update and Audit Log API call.
  const changePromiseStatuses = (status: string) => {
    const data = {
      status: status,
      promiseIds: selectedPromises,
    };

    if (selectedPromises.length === 0) {
      alert("You have not selected any promises");
      return;
    }
    let auditedPromises = statusAuditedPromises();
    let auditObjectsArray: any[] = [];
    auditedPromises?.map((promise: any) => {
      let auditObject = {
        itemAffected: `Promise Status`,
        newValue:
          TestimonialStatusTypeEnum.Published === status
            ? "Published"
            : "Inactive",
        oldValue:
          TestimonialStatusTypeEnum.Published === status
            ? "Inactive"
            : "Published",
        auditedObject: { ...promise, status: status },
        route: RouteConstants.promiseCreation,
        status: AuditStatus.EDITED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    changePromiseStatus(data);
  };

  const statusAuditedPromises = () => {
    let auditedPromises: any[] = [];
    promises?.map((promise: any) => {
      selectedPromises?.map((promiseId: number) => {
        if (promiseId === promise.id) {
          auditedPromises.push(promise);
        }
      });
    });
    return auditedPromises;
  };
  const handleExportAsExcel = () => {
    if (selectedPromises.length === 0) {
      alert("You have not selected any promises");
      return;
    }
    let exportingpromises: any = [];
    if (promises.length) {
      selectedPromises.map((selectedPromise: any) => {
        let localSelectedItem = promises.find(
          (item: any) => item.id == selectedPromise
        );
        exportingpromises.push({
          Promise_Name: localSelectedItem.name,
          Short_Description: localSelectedItem.shortDescription,
          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Published_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Modified_On: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Last_Modified_by: localSelectedItem?.crmPromisesUpdatedBy?.firstName
            ? localSelectedItem?.crmPromisesUpdatedBy?.firstName
            : "----",
          Priority: localSelectedItem?.priority,
          Status: localSelectedItem.status
            ? TestimonialStatusTypeEnumUtils.getEnumText(
                localSelectedItem.status
              )
            : "----",
        });
      });
    }
    exportAsExcel(exportingpromises, "Promises", "Sheet1");
  };

  //handle change for Promise Name filter.
  const handleNameChange = (event: SelectChangeEvent) => {
    setSelectedName(event.target.value as string);
    if (page !== 1) {
      setPromiseState("page", 1);
    }
  };

  //handle change for Status filter.
  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
    if (page !== 1) {
      setPromiseState("page", 1);
    }
  };

  const handleModifiedByChange = (event: SelectChangeEvent) => {
    setModifiedBy(event.target.value as string);
  };

  //handle change for search key filter.
  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 1) {
      setPromiseState("page", 1);
    }
  };

  //Reset Redux state after Post API call.
  const resetState = () => {
    setPromiseState("message", null);
    setPromiseState("error", null);
    setPromiseState("isSuccess", false);
  };

  //Handle close function with Audit log API call.
  const handleCloseFlashMessageDialog = () => {
    // if(isSuccess){
    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Promise Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: null,
      auditedObject: null,
      modulesAudited: modulesAudited,
      status: null,
    };
    postAuditLogRequest(postAuditRequestbody);
    // }
    resetState();
    clearSelected();
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = promises.map((data: any) => data.id);
      setSelectedPromises(newSelecteds);
      return;
    }
    setSelectedPromises([]);
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedPromises.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPromises, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPromises.slice(1));
    } else if (selectedIndex === selectedPromises?.length - 1) {
      newSelected = newSelected.concat(selectedPromises.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPromises.slice(0, selectedIndex),
        selectedPromises.slice(selectedIndex + 1)
      );
    }

    setSelectedPromises(newSelected);
  };

  const clearSelected = () => {
    setSelectedPromises([]);
  };

  //function to Reset the filters applied by User.
  const clearFilters = () => {
    setSelectedStatus("");
    setCreatedBy(null);
    setModifiedBy(null);
    setPublishedBy("");
    setSearchKey("");
    setSelectedName("");
  };

  const isSelected = (id: any) => selectedPromises.indexOf(id) !== -1;

  const createPromise = () => {
    navigate(RouteConstants.promiseCreation);
  };

  //Navigation to edit Promise.
  const editPromise = (selectedPromise: AddPromiseContent) => () => {
    navigate(RouteConstants.promiseCreation, {
      state: { ...selectedPromise, isView: false },
    });
  };

  //Navigation to View Promise.
  const viewPromise = (selectedPromise: AddPromiseContent) => () => {
    navigate(RouteConstants.promiseCreation, {
      state: { ...selectedPromise, isView: true },
    });
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_promise}
        altText=""
        message="You haven’t fetched any Promises yet, fetch your first promise.."
        btnTitle="Fetch Promises"
        btnAction={createPromise}
      />
    );
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    const isSelected = (name: string) => selectedPromises.indexOf(name) !== -1;

    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Published Promises
            {`(${
              totalCount ? totalCount : promises?.length ? promises?.length : 0
            })`}
            {selectedPromises.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedPromises.length} Promise(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
              >
                {actions.map((action: any) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>

            <Button
              variant="contained"
              onClick={createPromise}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New
            </Button>
          </div>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={10} lg={10} xl={10}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedName}
                    label="Name"
                    onChange={handleNameChange}
                  >
                    {promisesCopy && promisesCopy.length > 0
                      ? promisesCopy.map((promise: any) => (
                          <MenuItem value={promise.name} key={promise.id}>
                            {promise.name}
                          </MenuItem>
                        ))
                      : null}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedStatus}
                    label="Status"
                    onChange={handleStatusChange}
                  >
                    {statuses.map((status: any) => {
                      return (
                        <MenuItem value={status.value}>{status.label}</MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedCreatedBy}
                    label="Created by"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setCreatedBy(newValue);
                      if (page !== 1) {
                        setPromiseState("page", 1);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedModifiedBy}
                    label="Modified by"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setModifiedBy(newValue);
                      if (page !== 1) {
                        setPromiseState("page", 1);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              {selectedName ||
              selectedCreatedBy !== null ||
              selectedStatus !== "" ||
              selectedModifiedBy !== null ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <div className="time-sync-holder margin-right-15">
            <p className={styles["priority-switch"]}>Sort by Priority</p>
            <SwitchComponent
              checked={sortByPriority}
              onChange={handleChangePrioritySwitch}
            />
            <Tooltip
              arrow
              title="Disabling the toggle will switch to show items on the Customer App based on Date & Time."
              placement="top-start"
            >
              <InfoOutlined className={styles["infooutlined-alignment"]} />
            </Tooltip>{" "}
          </div>
        </Stack>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell
                  className="table-header-text"
                  padding="checkbox"
                  style={{ minWidth: 50 }}
                >
                  <Checkbox
                    className="active-checkbox"
                    data-testid="table-head-checkbox"
                    color="primary"
                    indeterminate={
                      selectedPromises?.length > 0 &&
                      selectedPromises?.length < promises?.length
                    }
                    checked={
                      promises?.length > 0 &&
                      selectedPromises?.length === promises?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all promises",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {promises && promises.length > 0 ? (
              <TableBody>
                {promises &&
                  promises.map((promise: any, index: any) => {
                    const isItemSelected = isSelected(promise.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        className="table-data-row"
                        role="checkbox"
                        aria-checked={isItemSelected}
                        // onClick={(event) => handleClick(event, promise.id)}
                        tabIndex={-1}
                        key={promise.id}
                        // selected={isItemSelected}
                      >
                        <TableCell padding="checkbox" style={{ minWidth: 50 }}>
                          <Checkbox
                            className="active-checkbox"
                            onClick={(event) => handleClick(event, promise.id)}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {promise.name ? promise.name : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 250 }}
                        >
                          {promise.shortDescription.length > 0 ? (
                            <div>
                              {promise.shortDescription.length > 18 ? (
                                <Stack justifyContent={"flex-start"}>
                                  <span>
                                    {" "}
                                    {`${promise.shortDescription.slice(
                                      0,
                                      18
                                    )}...`}
                                  </span>
                                  <Typography
                                    component="span"
                                    variant="body1"
                                    color="#2d2d2d"
                                    sx={{
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      wordBreak: "keep-all",
                                      width: "150px",
                                      textAlign: "start",
                                    }}
                                    onClick={(event: any) =>
                                      popOverComponent(
                                        event,
                                        promise.shortDescription
                                      )
                                    }
                                  >
                                    READ MORE
                                  </Typography>
                                </Stack>
                              ) : (
                                promise.shortDescription
                              )}
                            </div>
                          ) : (
                            "-"
                          )}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 170 }}
                        >
                          {promise.createdAt
                            ? formatDateTime(
                                promise.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 170 }}
                        >
                          {promise.createdAt
                            ? formatDateTime(
                                promise.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 170 }}
                        >
                          <div>
                            {promise.updatedAt
                              ? formatDateTime(
                                  promise.updatedAt,
                                  Constants.dateFormatDDmmYYYY
                                )
                              : "-"}
                          </div>
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 150 }}
                        >
                          <div>
                            {promise &&
                            promise.crmPromisesUpdatedBy &&
                            promise.crmPromisesUpdatedBy.firstName
                              ? promise?.crmPromisesUpdatedBy?.firstName
                              : null}
                          </div>
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          <PrioritySelector
                            disableDecrement={Boolean(promise?.priority == 1)}
                            incrementpriority={() => {
                              const priorityRequestBody = {
                                id: promise?.id,
                                priority: promise?.priority + 1,
                              };
                              updatePromisePriority(priorityRequestBody);
                            }}
                            decrementpriority={() => {
                              const priorityRequestBody = {
                                id: promise?.id,
                                priority: promise?.priority - 1,
                              };
                              updatePromisePriority(priorityRequestBody);
                            }}
                            priority={promise?.priority}
                            isPriorityRequired={
                              !sortByPriority ||
                              promise.status === StatusTypeEnum.Inactive
                            }
                          />
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {promise.status
                            ? TestimonialStatusTypeEnumUtils.getEnumText(
                                promise.status
                              )
                            : "-"}
                        </TableCell>

                        <TableCell style={{ minWidth: 120 }}>
                          {/* <Collapse in={promise.howToApply === null}>
                          <ButtonComponent
                            sx={{
                              textDecoration: "underline",
                              marginTop: "0.5rem",
                            }}
                            variant="text"
                            color="inherit"
                            onClick={handleContentCreate(promise)}
                          >
                            Create Content
                          </ButtonComponent>
                        </Collapse> */}
                          <Tooltip title="Edit" placement="top">
                            <IconButton
                              aria-label="edit"
                              onClick={editPromise(promise)}
                            >
                              <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="View" placement="top">
                            <IconButton
                              aria-label="view"
                              onClick={viewPromise(promise)}
                            >
                              <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (
              <div className="no-record">No Promises Records</div>
            )}
          </Table>
        </TableContainer>

        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleClickAway}
        >
          <Popper open={open} placement="bottom-start" anchorEl={anchor}>
            <ReadmoreDialogWrapper>
              <Paper elevation={3} sx={{ p: "0.5rem", width: "350px" }}>
                {description}
              </Paper>
            </ReadmoreDialogWrapper>
          </Popper>
        </ClickAwayListener>

        {!searchKey && promises && promises.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[20, 40, 50]}
            component="div"
            count={
              totalCount ? totalCount : promises?.length ? promises?.length : 0
            }
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <PageHeading title="Promise Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder="Search Promises"
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Promise(s)?"
        content={`This action will delete ${selectedPromises.length} selected promise(s). Are you sure you want to continue?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteSelectedPromises}
      />

      {message ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : "Something Went Wrong!"}
          isSuccess={true}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}

      {error ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
    </Fragment>
  );
};
export default PromiseList;
