import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import ProjectListActions from "../../redux-container/project-management/ProjectListRedux";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import ProjectDraftsList from "redux-container/project-management/project-drafts/ProjectDraftsRedux";

export function* getProjectList(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAllProjectContentList, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProjectListActions.projectListSuccess(response.data));
    } else {
      yield put(ProjectListActions.projectListFailure(response.data));
    }
  } catch (error) {
    yield put(ProjectListActions.projectListFailure(error));
  }
}

export function* updateProjectPriority(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateProjectPriority, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProjectListActions.updateProjectPrioritySuccess(response));
    } else {
      yield put(ProjectListActions.updateProjectPriorityFailure(response));
    }
  } catch (error) {
    yield put(ProjectListActions.updateProjectPriorityFailure(error));
  }
}

export function* updateProjectsStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateProjectsStatus, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProjectListActions.updateProjectsStatusSuccess(response));
    } else {
      yield put(ProjectListActions.updateProjectsStatusFailure(response));
    }
  } catch (error) {
    yield put(ProjectListActions.updateProjectsStatusFailure(error));
  }
}
export function* deleteSelectedProjects(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteSelectedProjects, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProjectListActions.deleteProjectsSuccess(response));
    } else {
      yield put(ProjectListActions.deleteProjectsFailure(response));
    }
  } catch (error) {
    yield put(ProjectListActions.deleteProjectsFailure(error));
  }
}

//Drafts
export function* getProjectDraftsList(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getProjectDraftList, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProjectDraftsList.getProjectDraftSuccess(response.data));
    } else {
      yield put(ProjectDraftsList.getProjectDraftFailure(response.data));
    }
  } catch (error) {
    yield put(ProjectDraftsList.getProjectDraftFailure(error));
  }
}

export function* addProjectDraft(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addProjectContentDraft, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProjectDraftsList.addProjectDraftSuccess(response.data));
    } else {
      yield put(ProjectDraftsList.addProjectDraftFailure(response.data));
    }
  } catch (error) {
    yield put(ProjectDraftsList.addProjectDraftFailure(error));
  }
}

export function* updateProjectDraft(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateProjectContentDraft, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProjectDraftsList.updateProjectDraftSuccess(response.data));
    } else {
      yield put(ProjectDraftsList.updateProjectDraftFailure(response.data));
    }
  } catch (error) {
    yield put(ProjectDraftsList.updateProjectDraftFailure(error));
  }
}

export function* deleteProjectDraft(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteProjectContentDraft, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProjectDraftsList.deleteProjectDraftSuccess(response.data));
    } else {
      yield put(ProjectDraftsList.deleteProjectDraftFailure(response.data));
    }
  } catch (error) {
    yield put(ProjectDraftsList.deleteProjectDraftFailure(error));
  }
}
