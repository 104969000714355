import { InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import QuillEditor from "components/quill-editor/QuillEditor";
import { SwitchComponent, TextFieldComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { Fragment, useEffect } from "react";
import styles from "./StatsOverview.module.css";
import * as Yup from "yup";
import { StatsOverviewConstants } from "../../AboutUsPageConstants";
import { IPage5ProfileInitialValue } from "../../../page-5-profile-IniticalValue";
import MediaAssets from "assets";
import { useSelector } from "react-redux";
import { stripHtml } from "utils/ValidationUtils";

interface IStatsOverview {
  sectionHeading: string;

  statsOverview: {
    statsOverview_Title: any;
    statsOverview_Value: any;
    statsOverview_Description: any;
  }[];
}

const StatsOverview = () => {
  const {
    values,
    handleChange,

    setFieldValue,
    setFieldError,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<IPage5ProfileInitialValue>();
  const { pageManagementData, isReduxViewMode } = useSelector(
    (state: any) => state?.pageManagement
  );

  const page1HoablData = useSelector((state : any) => state?.hoablPage1Data?.hoablPage1?.page?.mastheadSection)

  const handleTextEditor = (
    name: string,
    newValue: string,
    options: any,
    { setFieldValue, setFieldError }: any
  ) => {
    // if (options?.isError) {
    //   setFieldError(name, "Maximum character exceed");
    // } else {
      setFieldValue(name, newValue);
    // }
  };

  useEffect(() => {
    setFieldValue(`aboutUs.statsOverview.detailedInformation[0].title`, page1HoablData?.totalAmoutOfLandTransacted?.displayName)
    setFieldValue(`aboutUs.statsOverview.detailedInformation[0].value`, page1HoablData?.totalAmoutOfLandTransacted?.value)

    setFieldValue(`aboutUs.statsOverview.detailedInformation[1].title`, page1HoablData?.totalSqftOfLandTransacted?.displayName)
    setFieldValue(`aboutUs.statsOverview.detailedInformation[1].value`, page1HoablData?.totalSqftOfLandTransacted?.value)

    setFieldValue(`aboutUs.statsOverview.detailedInformation[2].title`, page1HoablData?.grossWeightedAvgAppreciation?.displayName)
    setFieldValue(`aboutUs.statsOverview.detailedInformation[2].value`, page1HoablData?.grossWeightedAvgAppreciation?.value)

    setFieldValue(`aboutUs.statsOverview.detailedInformation[3].title`, page1HoablData?.totalNumberOfUsersWhoBoughtTheLand?.displayName)
    setFieldValue(`aboutUs.statsOverview.detailedInformation[3].value`, page1HoablData?.totalNumberOfUsersWhoBoughtTheLand?.value)

  }, [])

  return (
    <Fragment>
      <div className={styles["accordian-global-padding"]}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={12}
            xl={12}
            className={styles["priority-holder"]}
          >
            <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
              <Typography>Stats Overview</Typography>

              <FormControlLabel
                className="switch-form-label"
                name={"aboutUs.isStatsOverviewActive"}
                disabled={isReduxViewMode || false}
                checked={values?.aboutUs?.isStatsOverviewActive}
                onChange={handleChange}
                control={<SwitchComponent />}
                label=""
                labelPlacement="start"
              />
              <IconButton>
                <Tooltip title={"Stats Overview"} placement="top" arrow>
                  <Avatar
                    className={styles["Avator"]}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={5} mt={3}>
            <TextFieldComponent
              required
              label="Section Heading"
              placeholder="Enter"
              name={`aboutUs.statsOverview.sectionHeading`}
              disabled={isReduxViewMode || false}
              inputProps={{
                maxLength: StatsOverviewConstants.sectionHeadingCharacterLimit,
              }}
              value={values?.aboutUs?.statsOverview?.sectionHeading}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors?.aboutUs?.statsOverview?.sectionHeading &&
                touched?.aboutUs?.statsOverview?.sectionHeading
                  ? true
                  : false
              }
              fieldhelpertext={
                errors?.aboutUs?.statsOverview?.sectionHeading &&
                touched?.aboutUs?.statsOverview?.sectionHeading &&
                errors?.aboutUs?.statsOverview?.sectionHeading
              }
            />
          </Grid>
        </Grid>
      </div>

      <Divider className={styles["accordian-global-padding"]} />

      {values?.aboutUs?.statsOverview?.detailedInformation?.length > 0 &&
        values?.aboutUs?.statsOverview?.detailedInformation?.map(
          (element, index: number) => (
            <React.Fragment>
              <div className={styles["accordian-global-padding"]}>
                <Grid container spacing={2}>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5} mt={3}>
                    <TextFieldComponent
                      required
                      label="Title"
                      name={`aboutUs.statsOverview.detailedInformation[${index}].title`}
                      placeholder="Enter"
                      disabled={isReduxViewMode !== null}
                      inputProps={
                        {
                          // maxLength: StatsOverviewConstants.titleCharactersLimit,
                        }
                      }
                      value={element?.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        (touched?.aboutUs?.statsOverview?.detailedInformation &&
                        touched?.aboutUs?.statsOverview?.detailedInformation[
                          index
                        ] &&
                        touched?.aboutUs?.statsOverview?.detailedInformation[
                          index
                        ].title
                          ? true
                          : false) &&
                        (element?.title?.length >
                        StatsOverviewConstants.minimumLength
                          ? false
                          : true)
                      }
                      fieldhelpertext={
                        (touched?.aboutUs?.statsOverview?.detailedInformation &&
                        touched?.aboutUs?.statsOverview?.detailedInformation[
                          index
                        ] &&
                        touched?.aboutUs?.statsOverview?.detailedInformation[
                          index
                        ].title
                          ? "Required"
                          : "") &&
                        (element.title.length >
                        StatsOverviewConstants.minimumLength
                          ? ""
                          : "Required")
                      }
                    />
                  </Grid>

                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5} mt={3}>
                    <TextFieldComponent
                      required
                      label="Value"
                      disabled={isReduxViewMode !== null}
                      name={`aboutUs.statsOverview.detailedInformation[${index}].value`}
                      placeholder="Enter"
                      inputProps={
                        {
                          // maxLength: StatsOverviewConstants.titleCharactersLimit,
                        }
                      }
                      value={element?.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        (touched?.aboutUs?.statsOverview?.detailedInformation &&
                        touched?.aboutUs?.statsOverview?.detailedInformation[
                          index
                        ] &&
                        touched?.aboutUs?.statsOverview?.detailedInformation[
                          index
                        ].value
                          ? true
                          : false) &&
                        (element.value.length >
                        StatsOverviewConstants.minimumLength
                          ? false
                          : true)
                      }
                      fieldhelpertext={
                        (touched?.aboutUs?.statsOverview?.detailedInformation &&
                        touched?.aboutUs?.statsOverview?.detailedInformation[
                          index
                        ] &&
                        touched?.aboutUs?.statsOverview?.detailedInformation[
                          index
                        ].value
                          ? "Required"
                          : "") &&
                        (element?.value?.length >
                        StatsOverviewConstants.minimumLength
                          ? ""
                          : "Required")
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Stack
                      justifyContent={"flex-start"}
                      className={styles["Upload-heading"]}
                    >
                      <Typography>Description</Typography>
                    </Stack>
                    <QuillEditor
                      placeholder="Enter Description"
                      className={styles["QuillEditor"]}
                      name={`aboutUs.statsOverview.detailedInformation[${index}].description`}
                      onChange={(
                        name: string,
                        newValue: string,
                        options: any
                      ) =>
                        handleTextEditor(name, newValue, options, {
                          setFieldValue,
                          setFieldError,
                        })
                      }
                      inputProps={{
                        maxLength: StatsOverviewConstants.description,
                      }}
                      onBlur={handleBlur}
                      value={element?.description}
                      style={
                        { height: "8rem" }
                      }
                      disabled={isReduxViewMode}
                      fieldhelpertext={
                        values?.aboutUs?.isStatsOverviewActive &&
                        stripHtml(
                          values?.aboutUs?.statsOverview?.detailedInformation[
                            index
                          ]?.description
                        )?.length === 0 &&
                        "Required"
                      }
                    />
                  </Grid>
                </Grid>
              </div>
              {/* <Divider /> */}
            </React.Fragment>
          )
        )}

      {/* <div className={styles["accordian-global-padding"]}>
        <Grid className={styles["button-position"]} mb={2}>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Grid>
      </div> */}
    </Fragment>
  );
};

export default StatsOverview;
