import { portfolioInitialValues } from "pages/page-management/page-management-dashboard/page-4-portfolio/Page4PortfolioSections";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  updatePortfolioPageSuccess: ["response"],
  updatePortfolioPageFailure: ["error"],
  updatePortfolioPageRequest: ["data"],

  getPortfolioPageSuccess: ["response"],
  getPortfolioPageFailure: ["error"],
  getPortfolioPageRequest: ["data"],

  addPortfolioPageSuccess: ["response"],
  addPortfolioPageFailure: ["error"],
  addPortfolioPageRequest: ["data"],

  setPortfolioPageState: ["key", "value"],
});

export const portfolioPageTypes = Types;

export const portfolioPageAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  portfolioData: null,
  fetchPortfolioData: null,
  isSuccess: false,
  successResponse: null,
  isDialogOpen: false,
  confirmDialogOpen: false,
  isViewMode: false,
  fileId: "",
};

export const updatePortfolioPageRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updatePortfolioPageSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      portfolioData: response.data,
      isLoading: false,
      isSuccess: true,
      successResponse: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const updatePortfolioPageFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const getPortfolioPageRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPortfolioPageSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      fetchPortfolioData: response.data,
      isLoading: false,
      isSuccess: true,
      successResponse: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getPortfolioPageFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const addPortfolioPageRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const addPortfolioPageSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      portfolioData: response.data,
      isLoading: false,
      isSuccess: true,
      successResponse: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const addPortfolioPageFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const setPortfolioPageState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_PORTFOLIO_PAGE_SUCCESS]: updatePortfolioPageSuccess,
  [Types.UPDATE_PORTFOLIO_PAGE_FAILURE]: updatePortfolioPageFailure,
  [Types.UPDATE_PORTFOLIO_PAGE_REQUEST]: updatePortfolioPageRequest,

  [Types.GET_PORTFOLIO_PAGE_SUCCESS]: getPortfolioPageSuccess,
  [Types.GET_PORTFOLIO_PAGE_FAILURE]: getPortfolioPageFailure,
  [Types.GET_PORTFOLIO_PAGE_REQUEST]: getPortfolioPageRequest,

  [Types.ADD_PORTFOLIO_PAGE_SUCCESS]: addPortfolioPageSuccess,
  [Types.ADD_PORTFOLIO_PAGE_FAILURE]: addPortfolioPageFailure,
  [Types.ADD_PORTFOLIO_PAGE_REQUEST]: addPortfolioPageRequest,

  [Types.SET_PORTFOLIO_PAGE_STATE]: setPortfolioPageState,
});
