import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({
  addLandingPageUrlCreationSuccess: ["response"],
  addLandingPageUrlCreationFailure: ["error"],
  addLandingPageUrlCreationRequest: ["data"],

  getUrlsSuccess: ["response"],
  getUrlsFailure: ["error"],
  getUrlsRequest: ["params"],

  changeUrlStatusSuccess: ["response"],
  changeUrlStatusFailure: ["error"],
  changeUrlStatusRequest: ["data"],

  deleteUrlSuccess: ["response"],
  deleteUrlFailure: ["error"],
  deleteUrlRequest: ["data"],

  setLandingPageUrlCreationState: ["key", "value"],
});

export const LandingPageUrlCreationTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  landingPageUrls: [],
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  shouldOpenFlashDialog: false,
  isDialogOpen: false,
  isGraphDataFetched: false,
  page: 1,
  rowsPerPage: 20,
};

// Add Updates
export const addLandingPageUrlCreationRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const addLandingPageUrlCreationSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message ? response.message : "Url added successfully!",
      isLoading: false,
      isDialogOpen: true,
    };
  }
};

export const addLandingPageUrlCreationFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    message: action.error.message,
    isSuccess: false,
  };
};

/* Get Notifications */
export const getUrlsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getUrlsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      landingPageUrls: response.data,
      isLoading: false,
      totalCount: response.totalCount,
      isGraphDataFetched: true,
    };
  }
};

export const getUrlsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Update Notification(s) status */
export const changeUrlStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const changeUrlStatusSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Url status updated successfully",
      isLoading: false,
    };
  }
};

export const changeUrlStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Delete Url */
export const deleteUrlRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const deleteUrlSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Url deleted successfully!",
      isLoading: false,
    };
  }
};

export const deleteUrlFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

export const setLandingPageUrlCreationState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_LANDING_PAGE_URL_CREATION_SUCCESS]:
    addLandingPageUrlCreationSuccess,
  [Types.ADD_LANDING_PAGE_URL_CREATION_FAILURE]:
    addLandingPageUrlCreationFailure,
  [Types.ADD_LANDING_PAGE_URL_CREATION_REQUEST]:
    addLandingPageUrlCreationRequest,

  [Types.GET_URLS_SUCCESS]: getUrlsSuccess,
  [Types.GET_URLS_FAILURE]: getUrlsFailure,
  [Types.GET_URLS_REQUEST]: getUrlsRequest,

  [Types.CHANGE_URL_STATUS_REQUEST]: changeUrlStatusRequest,
  [Types.CHANGE_URL_STATUS_SUCCESS]: changeUrlStatusSuccess,
  [Types.CHANGE_URL_STATUS_FAILURE]: changeUrlStatusFailure,

  [Types.DELETE_URL_SUCCESS]: deleteUrlSuccess,
  [Types.DELETE_URL_FAILURE]: deleteUrlFailure,
  [Types.DELETE_URL_REQUEST]: deleteUrlRequest,

  [Types.SET_LANDING_PAGE_URL_CREATION_STATE]: setLandingPageUrlCreationState,
});
