import { PopperPlacementType } from "@mui/material";
import { RouteConstants } from "routes/RouteConstants";

export type PropperStateType = {
  anchorEl: null;
  isSettingOpen: boolean;
  placement: PopperPlacementType;
};
export interface IObjectProps {
  [key: string]: any;
}
interface IOptionProps {
  id?: number;
  title: string;
}

export const userCreate: IObjectProps = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  department: "",
  // designation: "",
  roleId: "2",
  // status: "",
  // crmAdminId: "5678",
  countryCode: "91",
  createdAt: new Date(),
  updatedAt: new Date(),
  moduleIds: [],
  adminUpdatedBy: null,
  adminCreatedBy: null,
};

export const DepartmentsList: IOptionProps[] = [
  { id: 1, title: "Sales and Marketing" },
  { id: 2, title: "Product and Tech" },
  { id: 3, title: "HFM" },
  { id: 4, title: "CRM" },
  { id: 5, title: "Others" },
];

export const DesignationsList: IOptionProps[] = [
  { id: 1, title: "Sales Manager" },
  { id: 2, title: "Land Consultant" },
  { id: 3, title: "Brokerage Manager" },
];

export const inviteUserBreadcrumbRoutes = (
  routName?: any,
  isViewMode?: any
) => {
  return [
    {
      name: "CMS User Management",
      route: RouteConstants.userManagementDashboard,
    },
    {
      name: "User List",
      route: RouteConstants.userList,
    },
    {
      name:
        isViewMode === undefined
          ? routName
          : `${isViewMode ? "View" : "Edit"} - ${routName}`,
      route: RouteConstants.userCreation,
    },
  ];
};

export const settingMenu = [
  {
    id: 1,
    name: "Mark as Inactive",
  },
  {
    id: 2,
    name: "Delete",
  },
];
