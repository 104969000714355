import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({
  upCommingProjectContentCreationDataSuccess: ["response"],
  upCommingProjectContentCreationDataFailure: ["error"],
  upCommingProjectContentCreationDataRequest: ["data"],

  getUpCommingProjectContentCreationDataSuccess: ["response"],
  getUpCommingProjectContentCreationDataFailure: ["error"],
  getUpCommingProjectContentCreationDataRequest: ["data"],

  getSingleUpCommingProjectContentCreationDataSuccess: ["response"],
  getSingleUpCommingProjectContentCreationDataFailure: ["error"],
  getSingleUpCommingProjectContentCreationDataRequest: ["data"],

  updateStatusUpCommingProjectContentCreationDataSuccess: ["response"],
  updateStatusUpCommingProjectContentCreationDataFailure: ["error"],
  updateStatusUpCommingProjectContentCreationDataRequest: ["data"],

  updateUpCommingProjectContentCreationDataSuccess: ["response"],
  updateUpCommingProjectContentCreationDataFailure: ["error"],
  updateUpCommingProjectContentCreationDataRequest: ["id", "data"],

  filterUpCommingProjectContentCreationDataSuccess: ["response"],
  filterUpCommingProjectContentCreationDataFailure: ["error"],
  filterUpCommingProjectContentCreationDataRequest: ["data"],


    setUpcomingProjectState: ["key", "value"],
});

export const UpCommingProjectContentCreationTypes = Types;

export default Creators;

export const INITIAL_STATE: any = {
  upCommingprojectCreationData: [],
  singleProduct: {},
  error: false,
  isLoading: false,
  isSuccess: false,
  message: null,
  openFlashMessage: false,
  pageOfUpcomingProject: 0,
  rowsPerPage: 5,
  totalCount: 0,
  shouldOpenFlashDialog: false,
};

export const upCommingProjectContentCreationDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    error: false,
  };
};

export const upCommingProjectContentCreationDataSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: false,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Project Creation Data added successfully!",
      isLoading: false,
      openFlashMessage: true,
      totalCount: response.totalCount
    };
  }
};

export const upCommingProjectContentCreationDataFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    openFlashMessage: true,
  };
};
// get project Content Creation data
export const getUpCommingProjectContentCreationDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    error: false,
  };
};

export const getUpCommingProjectContentCreationDataSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      upCommingprojectCreationData: response.data,
      message: response.message,
      isLoading: false,
    };
  }
};

export const getUpCommingProjectContentCreationDataFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

// get single Product

export const getSingleUpCommingProjectContentCreationDataRequest = (
  state: any
) => {
  console.log("fefrjeg");

  return {
    ...state,
    isLoading: true,
  };
};

export const getSingleUpCommingProjectContentCreationDataSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;
  console.log("response from redux", response);

  if (response) {
    return {
      ...state,
      error: null,
      singleProduct: response?.data,
      isSuccess: true,
      message: response.message
        ? response.message
        : "project Content fetched successfully!",
      isLoading: false,
    };
  }
};

export const getSingleUpCommingProjectContentCreationDataFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};
// update sttaus
export const updateStatusUpCommingProjectContentCreationDataSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;
  console.log("response from redux", response);

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      isLoading: false,
    };
  }
};
export const updateStatusUpCommingProjectContentCreationDataFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};
export const updateStatusUpCommingProjectContentCreationDataRequest = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: true,
  };
};

// update product
export const updateUpCommingProjectContentCreationDataSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;
  console.log("response from redux", response);

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      isLoading: false,
      message: action?.response?.message,
      shouldOpenFlashDialog: true,
    };
  }
};
export const updateUpCommingProjectContentCreationDataFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    message: action?.error?.data?.message
      ? action?.error?.data?.message
      : "Failed to Update",
    shouldOpenFlashDialog: true,
    isSuccess: false,
  };
};

export const updateUpCommingProjectContentCreationDataRequest = (
  state: any,
  action: any
) => {
  console.log("action", action);

  return {
    ...state,
    isLoading: true,
  };
};

// filter products
export const filterUpCommingProjectContentCreationDataSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;
  console.log("response from redux", response);

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      upCommingprojectCreationData: response.data,
      message: response.message,
      isLoading: false,
      totalCount: response.totalCount
    };
  }
};

export const filterUpCommingProjectContentCreationDataFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const filterUpCommingProjectContentCreationDataRequest = (
  state: any,
  action: any
) => {
  console.log("action", action);

  return {
    ...state,
    isLoading: true,
  };
};

export const setUpcomingProjectState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UP_COMMING_PROJECT_CONTENT_CREATION_DATA_SUCCESS]:
    upCommingProjectContentCreationDataSuccess,
  [Types.UP_COMMING_PROJECT_CONTENT_CREATION_DATA_FAILURE]:
    upCommingProjectContentCreationDataFailure,
  [Types.UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST]:
    upCommingProjectContentCreationDataRequest,
  [Types.GET_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_SUCCESS]:
    getUpCommingProjectContentCreationDataSuccess,
  [Types.GET_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_FAILURE]:
    getUpCommingProjectContentCreationDataFailure,
  [Types.GET_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST]:
    getUpCommingProjectContentCreationDataRequest,
  [Types.GET_SINGLE_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_SUCCESS]:
    getSingleUpCommingProjectContentCreationDataSuccess,
  [Types.GET_SINGLE_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_FAILURE]:
    getSingleUpCommingProjectContentCreationDataFailure,
  [Types.GET_SINGLE_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST]:
    getSingleUpCommingProjectContentCreationDataRequest,
  [Types.UPDATE_STATUS_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST]:
    updateStatusUpCommingProjectContentCreationDataRequest,
  [Types.UPDATE_STATUS_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_SUCCESS]:
    updateStatusUpCommingProjectContentCreationDataSuccess,
  [Types.UPDATE_STATUS_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_FAILURE]:
    updateStatusUpCommingProjectContentCreationDataFailure,
  [Types.UPDATE_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST]:
    updateUpCommingProjectContentCreationDataRequest,
  [Types.UPDATE_STATUS_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_SUCCESS]:
    updateUpCommingProjectContentCreationDataSuccess,
  [Types.UPDATE_STATUS_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_FAILURE]:
    updateUpCommingProjectContentCreationDataFailure,
  [Types.FILTER_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST]:
    filterUpCommingProjectContentCreationDataRequest,
  [Types.FILTER_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_SUCCESS]:
    filterUpCommingProjectContentCreationDataSuccess,
  [Types.FILTER_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_FAILURE]:
    filterUpCommingProjectContentCreationDataFailure,
  [Types.SET_UPCOMING_PROJECT_STATE]: setUpcomingProjectState,
});