import {
  ClickAwayListener,
  Fade,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
} from "@mui/material";
import { Fragment, useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

export type PropperStateType = {
  anchorEl: null;
  isSettingOpen: boolean;
  placement: PopperPlacementType;
};

interface ImenuItemsProps {
  statusmenutext: string;
  disabled: boolean;
  onstatuschange: () => void;
  ondelete: () => void;
}

const SettingMenuDropdown = (props: ImenuItemsProps) => {
  const { disabled, onstatuschange, ondelete, statusmenutext } = props;
  const [popperState, setPopperState] = useState<PropperStateType>({
    anchorEl: null,
    isSettingOpen: false,
    placement: "auto",
  });

  const handleSettingIconClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setPopperState((prev: any) => ({
        ...prev,
        anchorEl: event.currentTarget,
        isSettingOpen:
          popperState.placement !== newPlacement || !prev.isSettingOpen,
        placement: newPlacement,
      }));
    };

  const handleClose = () => {
    setPopperState((prevState) => ({
      ...prevState,
      isSettingOpen: false,
    }));
  };

  return (
    <Fragment>
      <IconButton
        sx={{ marginLeft: "1rem" }}
        color="inherit"
        edge={"end"}
        disabled={disabled}
        onClick={handleSettingIconClick("right-start")}
      >
        <SettingsOutlinedIcon />
      </IconButton>
      <Popper
        open={popperState.isSettingOpen}
        anchorEl={popperState.anchorEl}
        placement={popperState.placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  <MenuItem onClick={onstatuschange}>
                    {`Mark as ${statusmenutext}`}
                  </MenuItem>
                  <MenuItem onClick={ondelete}>Delete</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Fragment>
  );
};

export default SettingMenuDropdown;
