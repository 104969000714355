import {
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
  ButtonComponent,
} from "components/ui-elements";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { useFormikContext, getIn } from "formik";
import { ChangeEvent, useEffect } from "react";
import { useLocation } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";

const InsightContainer = styled(FormFieldContainer)`
  padding: 0;
`;

const Insights = ({ isEditMode }: any) => {
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  const location: any = useLocation();
  const insights: any = useSelector(
    (state: any) => state?.insights?.insights?.data
  );

  const handleTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${event.target.name}`, event.target.value);
  };

  useEffect(() => {
    if (values?.totalInsightsOnListView >= insights?.length) {
      setFieldValue("totalInsightsOnListView", insights?.length);
    }

    if (values?.totalInsightsOnHomeScreen >= insights?.length) {
      setFieldValue("totalInsightsOnHomeScreen", insights?.length);
    }
  }, [insights?.length]);

  useEffect(() => {
    if (
      values?.totalInsightsOnHomeScreen >
      values?.totalInsightsOnListView
    ) {
      setFieldValue(
        "totalInsightsOnListView",
        values?.totalInsightsOnHomeScreen
      );
    }
  }, [
    values?.totalInsightsOnListView,
    values?.totalInsightsOnHomeScreen,
  ]);

  return (
    <InsightContainer>
      <Grid container p={"1rem"}>
        <Grid item xs={6}>
          <Stack columnGap={2} justifyContent={"flex-start"}>
            <Typography className="accordion-heading" margin={"0.5rem 0"}>
              Insights
            </Typography>
            <SwitchComponent
              name="isInsightsActive"
              checked={values?.isInsightsActive}
              onChange={handleChange}
              disabled={location?.state?.isViewMode && isEditMode}
            />
            <Tooltip
              title={"This represent the ticker on Customer App"}
              arrow
              placement="top-start"
            >
              <InfoOutlinedIcon
                fontSize="small"
                className="infooutlined-alignment"
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Grid container columnSpacing={4} rowSpacing={2} p={"1rem"}>
        <Grid item xs={5} marginRight={"1rem"}>
          <TextFieldComponent
            inputProps={{ maxLength: 100 }}
            label="Section Heading"
            // defaultValue="Latest Updates"
            name="insightsHeading"
            value={values?.insightsHeading}
            disabled={location?.state?.isViewMode && isEditMode}
            onChange={handleTextInput}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, "insightsHeading") &&
              getIn(errors, "insightsHeading")
            }
            error={Boolean(
              getIn(errors, "insightsHeading") &&
                getIn(touched, "insightsHeading")
            )}
          />
        </Grid>
        <Grid item xs={5} marginRight={"1rem"}>
          <TextFieldComponent
            inputProps={{ maxLength: 100 }}
            label="Sub Heading"
            required
            name="insightsSubHeading"
            value={values?.insightsSubHeading}
            disabled={location?.state?.isViewMode && isEditMode}
            onChange={handleTextInput}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, "insightsSubHeading") &&
              getIn(errors, "insightsSubHeading")
            }
            error={Boolean(
              getIn(errors, "insightsSubHeading") &&
                getIn(touched, "insightsSubHeading")
            )}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container rowSpacing={2} p={"1rem"}>
        <Grid item xs={4}>
          <Stack justifyContent="flex-start" spacing={2}>
            <Typography mb={"15px"}>Total Insights on List View</Typography>
            <PrioritySelector
              priority={values?.totalInsightsOnListView}
              disableDecrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalInsightsOnListView <= 1 ||
                values?.totalInsightsOnListView <=
                  values?.totalInsightsOnHomeScreen
              }
              disableIncrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalInsightsOnListView === insights?.length
              }
              decrementpriority={() => {
                values?.totalInsightsOnListView > 0
                  ? setFieldValue(
                      "totalInsightsOnListView",
                      values?.totalInsightsOnListView - 1
                    )
                  : alert("no less then 0 ");
              }}
              incrementpriority={() => {
                values?.totalInsightsOnListView <=
                (insights?.length - 1 >= 20 ? 20 : insights?.length - 1)
                  ? setFieldValue(
                      "totalInsightsOnListView",
                      values?.totalInsightsOnListView + 1
                    )
                  : alert(
                      `not more then ${
                        insights?.length - 1 >= 20 ? 20 : insights?.length
                      }`
                    );
              }}
              isCounter={true}
            />
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack justifyContent="flex-start" spacing={1}>
            <Typography mb={"15px"}>
              {" "}
              Total Insights on the home screen?
            </Typography>
            <PrioritySelector
              priority={values?.totalInsightsOnHomeScreen}
              disableDecrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalInsightsOnHomeScreen <= 1
              }
              disableIncrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalInsightsOnHomeScreen === insights?.length
              }
              decrementpriority={() => {
                values?.totalInsightsOnHomeScreen > 0
                  ? setFieldValue(
                      "totalInsightsOnHomeScreen",
                      values?.totalInsightsOnHomeScreen - 1
                    )
                  : alert("no less then 0 ");
              }}
              incrementpriority={() => {
                values?.totalInsightsOnHomeScreen <=
                (insights?.length - 1 >= 20 ? 20 : insights?.length - 1)
                  ? setFieldValue(
                      "totalInsightsOnHomeScreen",
                      values?.totalInsightsOnHomeScreen + 1
                    )
                  : alert(
                      `not more then ${
                        insights?.length - 1 >= 20 ? 20 : insights?.length
                      }`
                    );
              }}
              isCounter={true}
            />
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Box p="1rem" textAlign="right">
        {/* <ButtonComponent variant="contained">Save</ButtonComponent> */}
      </Box>
    </InsightContainer>
  );
};

export default Insights;
