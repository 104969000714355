import { Stack, Tooltip, Typography, styled } from "@mui/material";
import FormFieldContainer from "components/ui-elements/form-field-container/FormFieldContainer";
import SwitchComponent from "components/ui-elements/switch/SwitchComponent";
import { useFormikContext } from "formik";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const OtpToggle = (props: any) => {
  const { values, handleChange } = useFormikContext<any>();

  const LastestUpdateContainer = styled(FormFieldContainer)`
    padding: 1rem;
  `;
  return (
    <LastestUpdateContainer>
      <Stack columnGap={2} justifyContent={"flex-start"}>
        <Typography className="accordion-heading" margin={"0.5rem 0"}>
          Enquiry Form OTP
        </Typography>
        <SwitchComponent
          name="isLeadOtpActive"
          checked={values?.isLeadOtpActive}
          onChange={handleChange}
        />
        <Tooltip
          title={
            "Toggle is to show/hide the OTP verification feature in enquiry form of HoABL website"
          }
          arrow
          placement="top-start"
        >
          <InfoOutlinedIcon
            fontSize="small"
            className="infooutlined-alignment"
          />
        </Tooltip>
      </Stack>
    </LastestUpdateContainer>
  );
};

export default OtpToggle;
