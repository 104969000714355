export const CrmUpdatesConstants = {
  launchName: "Launch Name",
  state: "State",
  city: "City",
  priceStartingFrom: "Price Staring From",
  areaStartingFrom: "Area Staring From",
  inventoryName: "Inventory Name",
  areaRange: "Area Range",
  priceRange: "Price Range",
  listOfAddedItems: "List Of Added Items",
  listOfRemovedItems: "List Of Removed Items",
  listOfExistingItems: "List Of Existing Items",
  listOfUpdatedItems: "List Of Updated Items",
};
