import { Fragment, useEffect, useState } from "react";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import MediaAssets from "../../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  AutocompleteComponent,
  ButtonComponent,
  InfoComponent,
  SwitchComponent,
  TextFieldComponent,
} from "../../../components/ui-elements";
import styles from "./ProjectList.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Divider,
  IconButton,
  Stack,
  Tab,
  Tooltip,
  Typography,
  Checkbox,
  CardMedia,
  Card,
  Box,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { ProjectListStyleWrapper } from "./ProjectListStyleWrapper.style";
import { launchPhasesAction } from "redux-container/project-management/launch-phases/LaunchPhasesRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import {
  ActionTypeEnumInsight,
  ActionTypeEnumUtilsInsight,
  ProjectActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import {
  ProjectStatusTypeEnum,
  StatusTypeEnumInsight,
  StatusTypeEnumUtilsInsight,
} from "enumerations/StatusTypeEnum";
import projectListActions from "redux-container/project-management/ProjectListRedux";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import { exportAsExcel } from "utils/ExportFileUtils";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import Retry from "components/retry/Retry";
import { AllProjectFaqsAction } from "redux-container/project-management/ProjectAllFaqsRedux";
import { GetProjectCategoriesAction } from "redux-container/project-management/ProjectCategoryRedux";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";
import UserActions from "redux-container/users/UsersRedux";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { ProjectListConstants } from "./ProjectListConstants";
import ProjectDraftsList from "redux-container/project-management/project-drafts/ProjectDraftsRedux";
import { ProjectStatusTypeEnumUtils } from "enumerations/ProjectStatusTypeEnum";
import PanoramaIcon from "@mui/icons-material/Panorama";
import { priceFormatter } from "utils/AmountFormatter";

const actionDispatch = (dispatch: any) => {
  return {
    getAllLaunchPhases: (params: any) =>
      dispatch(launchPhasesAction.getAllLaunchPhasesRequest(params)),
    setLaunchPhasesState: (key: any, data: any) =>
      dispatch(launchPhasesAction.setLaunchPhasesState(key, data)),
    getProjectList: async (params: any) => {
      dispatch(projectListActions.projectListRequest(params));
    },
    deleteSelectedProjects: (newtoBeDeletingIds: any) => {
      dispatch(projectListActions.deleteProjectsRequest(newtoBeDeletingIds));
    },
    updateProjectsStatus: (toBeUpdatingIdies: any) => {
      dispatch(
        projectListActions.updateProjectsStatusRequest(toBeUpdatingIdies)
      );
    },
    setProjectState: (key: any, data: any) => {
      dispatch(projectListActions.setProjectState(key, data));
    },
    updateProjectPriority: (data: any) => {
      dispatch(projectListActions.updateProjectPriorityRequest(data));
    },
    allProjectFaqs: (data: any) =>
      dispatch(AllProjectFaqsAction.allFaqsProjectRequest(data)),
    setProjectCategoriesState: (key: any, data: any) =>
      dispatch(GetProjectCategoriesAction.setProjectCategoriesState(key, data)),
    setProjectFaqState: (key: any, value: any) =>
      dispatch(ProjectFaqActions.setProjectFaqState(key, value)),
    setALLProjectFaqState: (key: any, value: any) =>
      dispatch(AllProjectFaqsAction.setALLProjectFaqState(key, value)),
    verifyLaunchPhase: (params: any) =>
      dispatch(launchPhasesAction.verifyLaunchPhaseDataRequest(params)),
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),
    getProjectDraftsList: (data: any) =>
      dispatch(ProjectDraftsList.getProjectDraftRequest(data)),
    setProjectDraftState: (key: string, value: any) =>
      dispatch(ProjectDraftsList.setProjectDraftState(key, value)),
    bulkDeleteDrafts: (ids: any) =>
      dispatch(ProjectDraftsList.deleteProjectDraftRequest(ids)),
  };
};

const ProjectDraftList = (props: any) => {
  const { selectedDraft, setSelectedDraft, searchKey, renderNoData } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    getAllLaunchPhases,
    setLaunchPhasesState,
    getUsers,
    setProjectFaqState,
    setProjectCategoriesState,
    setALLProjectFaqState,
    verifyLaunchPhase,
    getProjectDraftsList,
    bulkDeleteDrafts,
    setProjectDraftState,
    getProjectList,
    setProjectState,
    updateProjectPriority,
    deleteSelectedProjects,
    updateProjectsStatus,
    allProjectFaqs,
  } = actionDispatch(dispatch);

  const draftsHeadings = [
    { heading: "Draft ID" },
    { heading: "Thumbnail" },
    { heading: "Display Name" },
    { heading: "Location" },
    { heading: "Starting Price" },
    { heading: "Starting Area" },
    { heading: "Created on" },
    { heading: "Actions" },
  ];

  const [selectedCreatedUser, setSelectedCreatedUser] = useState<any>(null);

  const {
    page,
    rowsPerPage,
    projectDraftList,
    draftsTotalCount,
    verifyLaunchPhaseError,
    totalCountOfNewProjects,
    isDeleted,
    isUpdated,
    isErrorOccured,
    isLoading,
    isSuccess,
    message,
    shouldOpenFlashDialog,
    allUsers,
    tabValue,
    totalCount,
    publishedProjects,
    fetchedAllLaunchPhasesData,
    launchPhasesLoader,
    isErrorOccuredOfNewTable,
    pageOfNewTable,
    rowsPerPageOfNewTable,
    verifyLauchPhaseInventoryCount,
    isVerifyLoading,
    openDraftFlashDialog,
    draftMessage,
    draftError,
    isDraftLoading,
  } = useSelector((state: any) => {
    return {
      page: state?.projectList?.page,
      rowsPerPage: state?.projectList?.rowsPerPage,
      projectDraftList: state?.projectDrafts?.data,
      draftsTotalCount: state?.projectDrafts?.totalCount,
      verifyLaunchPhaseError: state?.projectLaunchPhases.error,
      totalCountOfNewProjects:
        state?.projectLaunchPhases?.totalCountOfNewProjects,
      isDeleted: state?.projectList?.isDeleted,
      isUpdated: state?.projectList?.isUpdated,
      isErrorOccured: state?.projectList?.error,
      isLoading: state?.projectList?.isLoading,
      isSuccess: state?.projectList?.isSuccess,
      message: state?.projectList?.message,
      shouldOpenFlashDialog: state?.projectList?.shouldOpenFlashDialog,
      allUsers: state?.users?.usersForFilters,
      tabValue: state?.projectList?.tabValue,
      totalCount: state?.projectList?.totalCount,
      publishedProjects: state?.projectList?.data?.data,
      launchPhasesLoader: state.projectLaunchPhases?.isLoading,
      isErrorOccuredOfNewTable: state.projectLaunchPhases?.isLoading,
      fetchedAllLaunchPhasesData:
        state.projectLaunchPhases?.fetchedAllLaunchPhasesData,
      pageOfNewTable: state?.projectLaunchPhases?.pageOfNewTable,
      rowsPerPageOfNewTable: state?.projectLaunchPhases?.rowsPerPageOfNewTable,
      verifyLauchPhaseInventoryCount:
        state?.projectLaunchPhases?.verifiedResponse?.inventoryBucketsCount,
      isVerifyLoading: state?.projectLaunchPhases.isLoading,
      openDraftFlashDialog: state?.projectDrafts?.openFlashMessage,
      draftError: state?.projectDrafts?.error,
      draftMessage: state?.projectDrafts?.message,
      isDraftLoading: state?.projectDrafts?.isLoading,
    };
  });

  useEffect(() => {
    let params = {
      createdBy: selectedCreatedUser,
      size: rowsPerPage,
      index: page + 1,
      searchKey,
    };
    getProjectDraftsList(params);
  }, [selectedCreatedUser, searchKey, rowsPerPage, page]);

  const handleResetFilter = () => {
    setSelectedCreatedUser(null);
  };

  const isDraftSelected = (id: number) => selectedDraft?.indexOf(id) !== -1;

  const handleDraftListClick = (
    event: React.MouseEvent<unknown>,
    id: number
  ) => {
    const selectedIndex = selectedDraft?.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDraft, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDraft?.slice(1));
    } else if (selectedIndex === selectedDraft?.length - 1) {
      newSelected = newSelected.concat(selectedDraft?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDraft?.slice(0, selectedIndex),
        selectedDraft?.slice(selectedIndex + 1)
      );
    }
    setSelectedDraft(newSelected);
  };

  const handleSelectAllDrafts = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelected: any = projectDraftList.map((draft: any) => draft?.id);
      setSelectedDraft(newSelected);
      return;
    }
    setSelectedDraft([]);
  };

  const handleDraftEditIconClick = (draftId: number, draft: any) => {
    navigate(RouteConstants.projectCreation, {
      state: {
        isNew: true,
        ProjectListCreationId: draftId,
        projectContent: draft,
        isViewMode: false,
        isDraft: true,
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setProjectState("page", newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProjectState("rowsPerPage", Number(event.target.value));
    setProjectState("page", 0);
  };

  const handleCloseFlashMessageDialog = () => {
    setProjectDraftState("draftError", null);
    setProjectDraftState("draftMessage", null);
    setProjectDraftState("openFlashMessage", false);
  };

  return (
    <Fragment>
      <FlashMessageDialog
        shouldOpen={openDraftFlashDialog}
        content={draftError ? draftError : draftMessage}
        isSuccess={draftError ? false : true}
        cancelHandler={handleCloseFlashMessageDialog}
      />
      {isDraftLoading ? (
        <Loader />
      ) : (
        <div>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={allUsers}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (${option?.firstName})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    label="Created By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedCreatedUser(newValue);
                      if (page !== 0) {
                        setProjectState("page", 0);
                      }
                    }}
                    value={selectedCreatedUser}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              {selectedCreatedUser ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={handleResetFilter}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <TableContainer
            component={Paper}
            className="table-container"
            style={{ height: window.innerHeight - 475 }}
          >
            <Table style={{ position: "relative" }}>
              <TableHead>
                <TableRow className="table-header-row">
                  <TableCell className="table-header-text" padding="checkbox">
                    <Checkbox
                      className="active-checkbox"
                      data-testid="table-head-checkbox"
                      color="primary"
                      indeterminate={
                        selectedDraft?.length > 0 &&
                        selectedDraft?.length < projectDraftList?.length
                      }
                      checked={
                        projectDraftList?.length > 0 &&
                        selectedDraft?.length === projectDraftList?.length
                      }
                      onChange={handleSelectAllDrafts}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  {draftsHeadings.map((item, index) => (
                    <TableCell key={index} className="table-header-text">
                      {item.heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {projectDraftList?.length > 0 ? (
                <TableBody>
                  {projectDraftList.map((draft: any) => {
                    const isItemSelected: any = isDraftSelected(draft?.id);

                    return (
                      <TableRow
                        key={draft.id}
                        className="table-data-row"
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event: any) =>
                              handleDraftListClick(event, draft?.id)
                            }
                            className="active-checkbox"
                            checked={isItemSelected}
                          />
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 40 }}
                        >
                          {`#ID-${draft.id}`}
                        </TableCell>

                        <TableCell
                          style={{ minWidth: 80, justifyContent: "center" }}
                        >
                          {draft.projectCoverImages?.homePageMedia?.value
                            ?.url ? (
                            <Card
                              elevation={0}
                              className={styles["project-image"]}
                              style={{
                                height: "70px",
                                width: "100%",
                                objectFit: "fill",
                              }}
                            >
                              <CardMedia
                                className={styles["media-area"]}
                                component={"img"}
                                alt="Image"
                                src={
                                  draft.projectCoverImages?.homePageMedia?.value
                                    ?.url
                                }
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "fill",
                                }}
                              />
                            </Card>
                          ) : (
                            <PanoramaIcon
                              style={{ height: "3rem", width: "3rem" }}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 200 }}
                        >
                          {draft?.launchName ? draft?.launchName : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          {!draft?.address?.city && !draft.address?.state ? (
                            "-"
                          ) : (
                            <>
                              <div>{draft?.address?.city} </div>
                              {draft.address?.state}
                            </>
                          )}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 150 }}
                        >
                          {draft?.priceStartingFrom
                            ? `₹${priceFormatter(draft.priceStartingFrom)}`
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 150 }}
                        >
                          {draft?.areaStartingFrom
                            ? draft.areaStartingFrom
                            : "-"}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 120 }}
                        >
                          <div>{draft?.projectContentCreatedBy?.firstName}</div>
                          {draft?.createdAt
                            ? formatDateTime(
                                draft?.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </TableCell>

                        <TableCell>
                          <Tooltip title="Edit" placement="top">
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                handleDraftEditIconClick(draft.id, draft)
                              }
                            >
                              <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <Box mt={-5} className="no-record">
                  {renderNoData("No Projects Found. Create Your Drafts")}
                </Box>
              )}
            </Table>
          </TableContainer>
          {!searchKey && (
            <TablePagination
              className={styles["table-pagination"]}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                draftsTotalCount ? draftsTotalCount : projectDraftList?.length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ProjectDraftList;
