import { useRef } from "react";

import {
  Box,
  Checkbox,
  Collapse,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  ButtonComponent,
  FormFieldContainer,
  InfoComponent,
  LoadingButtonComponent,
  VideoUploadComponent,
} from "components/ui-elements";

import { Fragment, useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PageHeading from "components/page-heading/PageHeading";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import {
  newCreationPayload,
  urlCreationBreadcrumbRoutes,
} from "./LandingPageInitialValues";
import { FormikValues, useFormik, getIn } from "formik";
import { landingPageValidationSchema } from "./LandingPageUrlValidations";
import LandingPageUrlCreationActions from "redux-container/landing-page-management/LandingPageUrlCreationRedux";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { RouteConstants } from "routes/RouteConstants";

const actionDispatch = (dispatch: any) => {
  return {
    addLandingPageUrlCreationRequest: (data: any) =>
      dispatch(
        LandingPageUrlCreationActions.addLandingPageUrlCreationRequest(data)
      ),
    setLandingPageUrlCreationState: (key: string, value: any) =>
      dispatch(
        LandingPageUrlCreationActions.setLandingPageUrlCreationState(key, value)
      ),
    fileUploadRequest: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    setUploadFileState: (key: string, value: any) =>
      dispatch(FileUploadActions.setUploadFileState(key, value)),
  };
};

const LandingPageUrlCreation = () => {
  // const [video, setVideo] = useState<string>("");
  const [fileData, setFileData] = useState<object>({});
  const [url, setUrl] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [openPublishConfirmationDialog, setOpenPublishConfirmationDialog] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const location: any = useLocation();
  const {
    addLandingPageUrlCreationRequest,
    fileUploadRequest,
    setUploadFileState,
    setLandingPageUrlCreationState,
  } = actionDispatch(useDispatch());

  // const BinaryVideoPlayer = (videoData: any) => {
  //   const blob = new Blob([videoData], { type: "video/mp4" });
  //   const videoUrl = URL.createObjectURL(blob);
  //   setVideo(videoUrl);
  // };

  const [urlState, setUrlState] = useState({
    isViewMode: location?.state?.isViewMode,
    saveButtonText: "Publish",
  });

  const {
    errorMessage,
    successMessage,
    isSuccess,
    isLoading,
    data,
    error,
    imageDetails,
    isFileLoading,
    isUploadSuccess,
    confirmDialogOpen,
    isDialogOpen,
  } = useSelector((state: any) => ({
    successMessage: state?.landingPageUrlCreation?.message,
    errorMessage: state?.landingPageUrlCreation?.error,
    confirmDialogOpen: state?.landingPageUrlCreation?.confirmDialogOpen,
    isSuccess: state?.landingPageUrlCreation?.isSuccess,
    isDialogOpen: state?.landingPageUrlCreation?.isDialogOpen,
    isLoading: state?.landingPageUrlCreation?.isLoading,
    data: state?.fileUpload?.data,
    error: state?.fileUpload?.error,
    isUploadSuccess: state?.fileUpload?.isSuccess,
    imageDetails: state?.fileUpload?.imageDetails,
    isFileLoading: state?.fileUpload?.isLoading,
  }));

  useEffect(() => {
    if (imageDetails) {
      setFieldValue(`urlDescription.media.value.url`, imageDetails?.url);
      setUploadFileState("imageDetails", null);
    }
  }, [imageDetails]);
  const handlePublishClick = (formValues: FormikValues) => {
    formValues.status = "1002";
    // setVideo("");
    addLandingPageUrlCreationRequest(formValues);
    setLandingPageUrlCreationState("confirmDialogOpen", false);
  };

  const handleCloseFlashMessageDialog = () => {
    setLandingPageUrlCreationState("isDialogOpen", false);
    setLandingPageUrlCreationState("message", null);

    if (isSuccess) {
      navigate(RouteConstants.urlList);
    }
  };

  const handleDialogClose = () => {
    setLandingPageUrlCreationState("confirmDialogOpen", false);
    setLandingPageUrlCreationState("error", null);
  };
  const handleCancelClick = () => {
    navigate(-1);
  };

  const onSubmitHandler = () => {
    setLandingPageUrlCreationState("confirmDialogOpen", true);
  };
  interface MyObject {
    name: string;
    file: object;
    urlType: string;
    binary: string;
  }

  const handleFileInputChange = async (event: any) => {
    const file = event.target.files[0];

    if (file) {
      let size = file?.size;
      let type = file?.type;

      const data: MyObject = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };

      const reader = new FileReader();
      reader.onload = async (e: any) => {
        data.binary = e.target.result;
        fileUploadRequest(data);
        // setFileData(data);
        // setName(data.name);
        // setSize(size);
        // setType(type);
        // BinaryVideoPlayer(e.target.result);
      };
      reader.readAsArrayBuffer(file);
      setFieldValue(`urlDescription.media.value.url`, imageDetails?.url);
      setFieldValue(`urlDescription.media.value.mediaType`, type);
      setFieldValue(`urlDescription.media.value.size`, size);
      setFieldValue(`urlDescription.media.name`, file.name);
      setFieldValue(`urlDescription.media.key`, file.name);
    } else {
      return;
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    initialValues:
      location.state.landingUrl !== null
        ? location.state.landingUrl
        : newCreationPayload,
    validationSchema: landingPageValidationSchema,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
  });

  return (
    <Fragment>
      <BreadCrumbs routes={urlCreationBreadcrumbRoutes(location.state)} />
      <PageHeading title="Urls Management" />
      <Container>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Stack mt={"1rem"}>
            <Stack>
              <IconButton edge={"start"} onClick={() => navigate(-1)}>
                <ChevronLeftIcon color="inherit" />
              </IconButton>
              <Typography
                color={"secondary.dark"}
                variant="h5"
                marginRight={"1.5rem"}
              >
                Create New
              </Typography>
            </Stack>
            <Stack direction={"row"} columnGap={2}>
              <Collapse in={!urlState.isViewMode}>
                <ButtonComponent
                  type="reset"
                  color="inherit"
                  onClick={handleCancelClick}
                >
                  Cancel
                </ButtonComponent>
              </Collapse>
              <Collapse in={!urlState.isViewMode} orientation={"horizontal"}>
                <LoadingButtonComponent type="submit">
                  {urlState.saveButtonText}
                </LoadingButtonComponent>
              </Collapse>
            </Stack>
          </Stack>
          <FormFieldContainer>
            <Grid item xs={4} lg={3} xl={3}>
              {/* {video === "" ? ( */}
              <VideoUploadComponent
                name={`urlDescription.media`}
                onChange={handleFileInputChange}
                loading={isFileLoading}
                previewimage={values.urlDescription?.media?.value?.url}
              />
              {/* ) : (
                <LandingPageVideoPlayer path={video} />
              )} */}
            </Grid>
          </FormFieldContainer>
          <ConfirmationDialog
            shouldOpen={confirmDialogOpen}
            content="Are you sure you want to Publish?"
            okText="Publish"
            okHandler={() => handlePublishClick(values)}
            cancelHandler={handleDialogClose}
          />
          <FlashMessageDialog
            shouldOpen={isDialogOpen}
            content={successMessage ? successMessage : errorMessage}
            isSuccess={isSuccess}
            cancelHandler={() => handleCloseFlashMessageDialog()}
          />
        </Box>
      </Container>
    </Fragment>
  );
};

export default LandingPageUrlCreation;
