import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import React from "react";
import { CloseIcon } from "pages/user-management/audit-log/AuditViewDialog";
import BasicDataTable from "components/basic-data-table/BasicDataTable";
import { useSelector } from "react-redux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { useFormikContext } from "formik";
import { LoadingButton } from "@mui/lab";
import { ButtonComponent } from "components/ui-elements";
import { CrmUpdatesConstants } from "./CrmUpdatesConstants";
import { InrCurrencyFormat } from "utils/INRcurrency";

const CrmUpdatesDetailsDialog = (props: any) => {
  const { shouldOpen, handleClose, projectValues, setDisableFetchUpdate } =
    props;

  const { values, setFieldValue } =
    useFormikContext<IInitinalValueProjectContent>();

  const crmFetchUpdatesDetails = useSelector(
    (state: any) => state?.crmFetchUpdates?.crmUpdates
  );

  let configureAreaRange = (areaRange: any) => {
    // configure area range with formatter
    if (!areaRange?.from && !areaRange?.to) {
      return "-";
    }
    if (areaRange?.from && areaRange?.to) {
      return (
        InrCurrencyFormat(areaRange?.from?.toString()) +
        " - " +
        InrCurrencyFormat(areaRange?.to?.toString()) +
        " sqft"
      );
    }

    if (areaRange?.from && (!areaRange?.to || areaRange?.to == 0)) {
      return (
        InrCurrencyFormat(areaRange?.from?.toString()) + " - " + 0 + " sqft"
      );
    }

    if (areaRange?.to && (!areaRange?.from || areaRange?.from == 0)) {
      return 0 + " - " + InrCurrencyFormat(areaRange?.to?.toString()) + " sqft";
    }
  };

  let configureAreaRangeForModifiedInventory = (
    areaRange: any,
    checkOldValue?: boolean
  ) => {
    if (checkOldValue) {
      if (!areaRange?.from?.__old && !areaRange?.to?.__old) {
        return "-";
      }
      if (areaRange?.from?.__old && areaRange?.to?.__old) {
        return (
          InrCurrencyFormat(areaRange?.from?.__old?.toString()) +
          " - " +
          InrCurrencyFormat(areaRange?.to?.__old?.toString()) +
          " sqft"
        );
      }

      if (
        areaRange?.from?.__old &&
        (!areaRange?.to?.__old || areaRange?.to?.__old == 0)
      ) {
        return (
          InrCurrencyFormat(areaRange?.from?.__old?.toString()) +
          " - " +
          0 +
          " sqft"
        );
      }

      if (
        areaRange?.to?.__old &&
        (!areaRange?.from?.__old || areaRange?.from?.__old == 0)
      ) {
        return (
          0 +
          " - " +
          InrCurrencyFormat(areaRange?.to?.__old?.toString()) +
          " sqft"
        );
      }
    } else {
      if (!areaRange?.from?.__new && !areaRange?.to?.__new) {
        return "-";
      }
      if (areaRange?.from?.__new && areaRange?.to?.__new) {
        return (
          InrCurrencyFormat(areaRange?.from?.__new?.toString()) +
          " - " +
          InrCurrencyFormat(areaRange?.to?.__new?.toString()) +
          " sqft"
        );
      }

      if (
        areaRange?.from?.__new &&
        (!areaRange?.to?.__new || areaRange?.to?.__new == 0)
      ) {
        return (
          InrCurrencyFormat(areaRange?.from?.__new?.toString()) +
          " - " +
          0 +
          " sqft"
        );
      }

      if (
        areaRange?.to?.__new &&
        (!areaRange?.from?.__new || areaRange?.from?.__new == 0)
      ) {
        return (
          0 +
          " - " +
          InrCurrencyFormat(areaRange?.to?.__new?.toString()) +
          " sqft"
        );
      }
    }
  };

  // updated function to configure inventory area range
  const configureAreaRangeForModifiedInventories = (
    existingValues: any,
    updatedValues: any,
    checkOldValue?: boolean
  ) => {
    if (checkOldValue) {
      if (!existingValues?.areaRange?.from && !existingValues?.areaRange?.to) {
        return "-";
      }
      if (existingValues?.areaRange?.from && existingValues?.areaRange?.to) {
        return (
          InrCurrencyFormat(existingValues?.areaRange?.from?.toString()) +
          " - " +
          InrCurrencyFormat(existingValues?.areaRange?.to?.toString()) +
          " sqft"
        );
      }

      if (
        existingValues?.areaRange?.from &&
        (!existingValues?.areaRange?.to || existingValues?.areaRange?.to == 0)
      ) {
        return (
          InrCurrencyFormat(existingValues?.areaRange?.from?.toString()) +
          " - " +
          0 +
          " sqft"
        );
      }

      if (
        existingValues?.areaRange?.to &&
        (!existingValues?.areaRange?.from ||
          existingValues?.areaRange?.from == 0)
      ) {
        return (
          0 +
          " - " +
          InrCurrencyFormat(existingValues?.areaRange?.to?.toString()) +
          " sqft"
        );
      }
    } else {
      if (!updatedValues?.areaRange?.from && !updatedValues?.areaRange?.to) {
        return "-";
      }
      if (updatedValues?.areaRange?.from && updatedValues?.areaRange?.to) {
        return (
          InrCurrencyFormat(updatedValues?.areaRange?.from?.toString()) +
          " - " +
          InrCurrencyFormat(updatedValues?.areaRange?.to?.toString()) +
          " sqft"
        );
      }

      if (
        updatedValues?.areaRange?.from &&
        (!updatedValues?.areaRange?.to || updatedValues?.areaRange?.to == 0)
      ) {
        return (
          InrCurrencyFormat(updatedValues?.areaRange?.from?.toString()) +
          " - " +
          0 +
          " sqft"
        );
      }

      if (
        updatedValues?.areaRange?.to &&
        (!updatedValues?.areaRange?.from || updatedValues?.areaRange?.from == 0)
      ) {
        return (
          0 +
          " - " +
          InrCurrencyFormat(updatedValues?.areaRange?.to?.toString()) +
          " sqft"
        );
      }
    }
  };

  // updated function to configure inventory price range
  let configurePriceRangeForModifiedInventories = (
    existingValue: any,
    updatedValues: any,
    checkOldValue?: boolean
  ) => {
    if (checkOldValue) {
      if (!existingValue?.priceRange?.from && !existingValue?.priceRange?.to) {
        return "-";
      }
      if (existingValue?.priceRange?.from && existingValue?.priceRange?.to) {
        return (
          "₹" +
          InrCurrencyFormat(existingValue?.priceRange?.from?.toString()) +
          " - " +
          "₹" +
          InrCurrencyFormat(existingValue?.priceRange?.to?.toString())
        );
      }

      if (
        existingValue?.priceRange?.from &&
        (!existingValue?.priceRange?.to || existingValue?.priceRange?.to == 0)
      ) {
        return (
          "₹" +
          InrCurrencyFormat(existingValue?.priceRange?.from?.toString()) +
          " - " +
          "₹" +
          0
        );
      }

      if (
        existingValue?.priceRange?.to &&
        (!existingValue?.priceRange?.from ||
          existingValue?.priceRange?.from == 0)
      ) {
        return (
          "₹" +
          0 +
          " - " +
          "₹" +
          InrCurrencyFormat(existingValue?.priceRange?.to?.toString())
        );
      }
    } else {
      if (!updatedValues?.priceRange?.from && !updatedValues?.priceRange?.to) {
        return "-";
      }
      if (updatedValues?.priceRange?.from && updatedValues?.priceRange?.to) {
        return (
          "₹" +
          InrCurrencyFormat(updatedValues?.priceRange?.from?.toString()) +
          " - " +
          "₹" +
          InrCurrencyFormat(updatedValues?.priceRange?.to?.toString())
        );
      }

      if (
        updatedValues?.priceRange?.from &&
        (!updatedValues?.priceRange?.to || updatedValues?.priceRange?.to == 0)
      ) {
        return (
          "₹" +
          InrCurrencyFormat(updatedValues?.priceRange?.from?.toString()) +
          " - " +
          "₹" +
          0
        );
      }

      if (
        updatedValues?.priceRange?.to &&
        (!updatedValues?.priceRange?.from ||
          updatedValues?.priceRange?.from == 0)
      ) {
        return (
          "₹" +
          0 +
          " - " +
          "₹" +
          InrCurrencyFormat(updatedValues?.priceRange?.to?.toString())
        );
      }
    }
  };

  let configurePriceRange = (priceRange: any) => {
    // configure price range with amount formatter
    if (!priceRange?.from && !priceRange?.to) {
      return "-";
    }
    if (priceRange?.from && priceRange?.to) {
      return (
        "₹" +
        InrCurrencyFormat(priceRange?.from?.toString()) +
        " - " +
        "₹" +
        InrCurrencyFormat(priceRange?.to?.toString())
      );
    }

    if (priceRange?.from && (!priceRange?.to || priceRange?.to == 0)) {
      return (
        "₹" + InrCurrencyFormat(priceRange?.from?.toString()) + " - " + "₹" + 0
      );
    }

    if (priceRange?.to && (!priceRange?.from || priceRange?.from == 0)) {
      return (
        "₹" + 0 + " - " + "₹" + InrCurrencyFormat(priceRange?.to?.toString())
      );
    }
  };

  let configurePriceRangeForModifiedInventory = (
    areaRange: any,
    checkOldValue?: boolean
  ) => {
    if (checkOldValue) {
      if (!areaRange?.from?.__old && !areaRange?.to?.__old) {
        return "-";
      }
      if (areaRange?.from?.__old && areaRange?.to?.__old) {
        return (
          "₹" +
          InrCurrencyFormat(areaRange?.from?.__old?.toString()) +
          " - " +
          "₹" +
          InrCurrencyFormat(areaRange?.to?.__old?.toString())
        );
      }

      if (
        areaRange?.from?.__old &&
        (!areaRange?.to?.__old || areaRange?.to?.__old == 0)
      ) {
        return (
          "₹" +
          InrCurrencyFormat(areaRange?.from?.__old?.toString()) +
          " - " +
          "₹" +
          0
        );
      }

      if (
        areaRange?.to?.__old &&
        (!areaRange?.from?.__old || areaRange?.from?.__old == 0)
      ) {
        return (
          "₹" +
          0 +
          " - " +
          "₹" +
          InrCurrencyFormat(areaRange?.to?.__old?.toString())
        );
      }
    } else {
      if (!areaRange?.from?.__new && !areaRange?.to?.__new) {
        return "-";
      }
      if (areaRange?.from?.__new && areaRange?.to?.__new) {
        return (
          "₹" +
          InrCurrencyFormat(areaRange?.from?.__new?.toString()) +
          " - " +
          "₹" +
          InrCurrencyFormat(areaRange?.to?.__new?.toString())
        );
      }

      if (
        areaRange?.from?.__new &&
        (!areaRange?.to?.__new || areaRange?.to?.__new == 0)
      ) {
        return (
          "₹" +
          InrCurrencyFormat(areaRange?.from?.__new?.toString()) +
          " - " +
          "₹" +
          0
        );
      }

      if (
        areaRange?.to?.__new &&
        (!areaRange?.from?.__new || areaRange?.from?.__new == 0)
      ) {
        return (
          "₹" +
          0 +
          " - " +
          "₹" +
          InrCurrencyFormat(areaRange?.to?.__new?.toString())
        );
      }
    }
  };

  const listOfAddedData = () => {
    let finalValue: any = []; // constructing object structure for list of added items in CRM
    crmFetchUpdatesDetails?.differenceInInventoryBuckets?.added?.map(
      (item: any) => {
        if (item?.name) {
          finalValue.push([
            {
              label: CrmUpdatesConstants.inventoryName,
              value: item?.name,
            },
          ]);

          finalValue.push([
            {
              label: CrmUpdatesConstants.areaRange,
              value: configureAreaRange(item?.areaRange),
            },
          ]);

          finalValue.push([
            {
              label: CrmUpdatesConstants.priceRange,
              value: configurePriceRange(item?.priceRange),
            },
          ]);
        }
      }
    );
    return finalValue;
  };

  const listOfDeletedItems = () => {
    let removedList: any = []; // constructing list of removed items from CRM
    crmFetchUpdatesDetails?.differenceInInventoryBuckets?.removed?.map(
      (item: any) => {
        if (item?.name) {
          removedList.push([
            {
              label: CrmUpdatesConstants.inventoryName,
              value: item?.name,
            },
          ]);

          removedList.push([
            {
              label: CrmUpdatesConstants.areaRange,
              value: configureAreaRange(item?.areaRange),
            },
          ]);

          removedList.push([
            {
              label: CrmUpdatesConstants.priceRange,
              value: configurePriceRange(item?.priceRange),
            },
          ]);
        }
      }
    );
    return removedList;
  };

  const listOfUpdatedAndExistingData = () => {
    let listItems: any = []; // constructing objects to render as list for fetch updates

    // Get Launch Name
    if (crmFetchUpdatesDetails?.differenceInProjectContent?.launchName) {
      listItems.push([
        {
          label: CrmUpdatesConstants.launchName,
          value:
            crmFetchUpdatesDetails?.differenceInProjectContent?.launchName?.__old?.toString(),
        },
        {
          label: CrmUpdatesConstants.launchName,
          value:
            crmFetchUpdatesDetails?.differenceInProjectContent?.launchName?.__new?.toString(),
        },
      ]);
    }

    // Get project Price Starting from
    if (crmFetchUpdatesDetails?.differenceInProjectContent?.priceStartingFrom) {
      listItems.push([
        {
          label: CrmUpdatesConstants.priceStartingFrom,
          value:
            crmFetchUpdatesDetails?.differenceInProjectContent
              ?.priceStartingFrom?.__old ||
            crmFetchUpdatesDetails?.differenceInProjectContent
              ?.priceStartingFrom?.__old == 0
              ? "₹" +
                InrCurrencyFormat(
                  crmFetchUpdatesDetails?.differenceInProjectContent?.priceStartingFrom?.__old?.toString()
                )
              : "-",
        },
        {
          label: CrmUpdatesConstants.priceStartingFrom,
          value:
            crmFetchUpdatesDetails?.differenceInProjectContent
              ?.priceStartingFrom?.__new ||
            crmFetchUpdatesDetails?.differenceInProjectContent
              ?.priceStartingFrom?.__new == 0
              ? "₹" +
                InrCurrencyFormat(
                  crmFetchUpdatesDetails?.differenceInProjectContent?.priceStartingFrom?.__new?.toString()
                )
              : "-",
        },
      ]);
    }

    // Get project Area Starting from
    if (crmFetchUpdatesDetails?.differenceInProjectContent?.areaStartingFrom) {
      listItems.push([
        {
          label: CrmUpdatesConstants.areaStartingFrom,
          value:
            crmFetchUpdatesDetails?.differenceInProjectContent?.areaStartingFrom
              ?.__old ||
            crmFetchUpdatesDetails?.differenceInProjectContent?.areaStartingFrom
              ?.__old == 0
              ? InrCurrencyFormat(
                  crmFetchUpdatesDetails?.differenceInProjectContent?.areaStartingFrom?.__old?.toString()
                ) + " sqft"
              : "-",
        },
        {
          label: CrmUpdatesConstants.areaStartingFrom,
          value:
            crmFetchUpdatesDetails?.differenceInProjectContent?.areaStartingFrom
              ?.__new ||
            crmFetchUpdatesDetails?.differenceInProjectContent?.areaStartingFrom
              ?.__new == 0
              ? InrCurrencyFormat(
                  crmFetchUpdatesDetails?.differenceInProjectContent?.areaStartingFrom?.__new?.toString()
                ) + " sqft"
              : "-",
        },
      ]);
    }

    // Changes in Inventory Buckets
    if (
      crmFetchUpdatesDetails?.differenceInInventoryBuckets?.modified?.length > 0
    ) {
      crmFetchUpdatesDetails?.differenceInInventoryBuckets?.modified?.map(
        (item: any) => {
          if (item?.changes?.name) {
            listItems.push([
              {
                label: CrmUpdatesConstants.inventoryName,
                value: item?.oldData?.name?.toString(),
              },
              {
                label: CrmUpdatesConstants.inventoryName,
                value: item?.changes?.name?.toString(),
              },
            ]);
          }

          let getProjectInventoryContent =
            values?.inventoryBucketContents?.find(
              (inventory: any) => item?.id == inventory?.crmInventoryBucketId
            );

          if (item?.changes?.areaRange) {
            listItems.push([
              {
                label: getProjectInventoryContent
                  ? getProjectInventoryContent
                    ? CrmUpdatesConstants.areaRange +
                      ` of ${getProjectInventoryContent?.name}`
                    : ""
                  : CrmUpdatesConstants.areaRange,
                value: configureAreaRangeForModifiedInventories(
                  item?.oldData,
                  item?.changes,
                  true
                ),
              },
              {
                label: getProjectInventoryContent
                  ? getProjectInventoryContent
                    ? CrmUpdatesConstants.areaRange +
                      ` of ${getProjectInventoryContent?.name}`
                    : ""
                  : CrmUpdatesConstants.areaRange,
                value: configureAreaRangeForModifiedInventories(
                  item?.oldData,
                  item?.changes,
                  false
                ),
              },
            ]);
          }

          if (item?.changes?.priceRange) {
            listItems.push([
              {
                label: getProjectInventoryContent
                  ? getProjectInventoryContent
                    ? CrmUpdatesConstants.priceRange +
                      ` of ${getProjectInventoryContent?.name}`
                    : ""
                  : CrmUpdatesConstants.priceRange,
                value: configurePriceRangeForModifiedInventories(
                  item?.oldData,
                  item?.changes,
                  true
                ),
              },
              {
                label: getProjectInventoryContent
                  ? getProjectInventoryContent
                    ? CrmUpdatesConstants.priceRange +
                      ` of ${getProjectInventoryContent?.name}`
                    : ""
                  : CrmUpdatesConstants.priceRange,
                value: configurePriceRangeForModifiedInventories(
                  item?.oldData,
                  item?.changes,
                  false
                ),
              },
            ]);
          }
        }
      );
    }

    const removeDuplicateValue = listItems?.filter(
      // removing duplicate value by comparing old and new values
      (item: any) =>
        item[0]?.value !== item[1]?.value &&
        item[0]?.value !== "0 - 0 sqft" &&
        item[0]?.value !== "-" &&
        item[1]?.value !== "0 - 0 sqft" &&
        item[1]?.value !== "-"
    );

    return removeDuplicateValue;
  };

  const handleUpdatesDeny = () => {
    setDisableFetchUpdate(true);
    handleClose();
  };

  const handleAcceptUpdates = () => {
    // updated inventory bucket
    if (
      crmFetchUpdatesDetails?.differenceInInventoryBuckets?.modified?.length > 0
    ) {
      crmFetchUpdatesDetails?.differenceInInventoryBuckets?.modified?.map(
        (modifiedInventory: any) => {
          let inventoryPostion: any;
          let fieldData: any = values?.inventoryBucketContents?.find(
            (projectInventory: any, positionIndex: any) => {
              inventoryPostion = positionIndex;
              return (
                projectInventory?.crmInventoryBucketId == modifiedInventory?.id
              );
            }
          );

          if (fieldData && (inventoryPostion || inventoryPostion == 0)) {
            if (modifiedInventory?.name) {
              fieldData.name = modifiedInventory?.changes?.name;
            }

            if (modifiedInventory?.changes?.priceRange) {
              fieldData.priceRange.from =
                modifiedInventory?.changes.priceRange.from?.toString() || "0";
              fieldData.priceRange.to =
                modifiedInventory?.changes?.priceRange?.to?.toString() || "0";
            }

            if (modifiedInventory?.changes?.areaRange) {
              fieldData.areaRange.from =
                modifiedInventory?.changes?.areaRange.from?.toString() || "0";
              fieldData.areaRange.to =
                modifiedInventory?.changes?.areaRange?.to?.toString() || "0";
            }
            setFieldValue(
              `inventoryBucketContents[${inventoryPostion}]`,
              fieldData
            );
          }
        }
      );
    }
    // Add Inventory
    if (
      crmFetchUpdatesDetails?.differenceInInventoryBuckets?.added?.length > 0
    ) {
      crmFetchUpdatesDetails?.differenceInInventoryBuckets?.added?.map(
        (inventory: any) => {
          let newInventory: any = {
            name: inventory?.name,
            isSoldOut: false,
            shortDescription: "",
            areaRange: {
              from: inventory?.areaRange?.from?.toString() || "0",
              to: inventory?.areaRange?.to?.toString() || "0",
            },
            priceRange: {
              from: inventory?.priceRange?.from?.toString() || "0",
              to: inventory?.priceRange?.to?.toString() || "0",
            },
            crmInventoryBucketId: inventory?.id,
            projectContentId: projectValues?.id,
          };
          values?.inventoryBucketContents?.push(newInventory);
        }
      );
    }

    // Remove Inventory
    if (
      crmFetchUpdatesDetails?.differenceInInventoryBuckets?.removed?.length > 0
    ) {
      let removedIds: any =
        crmFetchUpdatesDetails?.differenceInInventoryBuckets?.removed?.map(
          (inventory: any) => Number(inventory?.id)
        );

      let removeInventoriesFromProject: any =
        values?.inventoryBucketContents?.filter(
          (projectInventory: any) =>
            !removedIds?.includes(
              Number(projectInventory?.crmInventoryBucketId)
            ) && projectInventory
        );

      setFieldValue("inventoryBucketContents", removeInventoriesFromProject);
      setFieldValue("deletedInventoryBucketIds", removedIds);
    }

    // Project Content Changes
    if (crmFetchUpdatesDetails?.differenceInProjectContent?.launchName) {
      // updated Values
      setFieldValue(
        "launchName",
        crmFetchUpdatesDetails?.differenceInProjectContent?.launchName?.__new
      );
    }

    if (crmFetchUpdatesDetails?.differenceInProjectContent?.priceStartingFrom) {
      setFieldValue(
        "priceStartingFrom",
        crmFetchUpdatesDetails?.differenceInProjectContent?.priceStartingFrom
          ?.__new || "0"
      );
    }

    if (crmFetchUpdatesDetails?.differenceInProjectContent?.areaStartingFrom) {
      setFieldValue(
        "areaStartingFrom",
        crmFetchUpdatesDetails?.differenceInProjectContent?.areaStartingFrom
          ?.__new || "0"
      );
    }

    handleUpdatesDeny(); // for close dialog after accepting updates
  };

  return (
    <div>
      <Dialog
        maxWidth={"md"}
        fullWidth
        open={shouldOpen}
        disableEscapeKeyDown={true}
        sx={{ textAlign: "center" }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            position: "sticky",
            top: 0,
            zIndex: 1000,
            borderBottom: "1px solid #c7c7cd",
            backgroundColor: "white",
          }}
        >
          <Typography fontWeight={500} variant={"h6"} component={"h6"}>
            New Updates From CRM
          </Typography>

          <Stack justifyContent={"flex-start"} spacing={2} ml={-5}>
            <Typography>CRM ID : #ID-{projectValues?.crmProjectId}</Typography>
            <Box sx={{ border: "0.2px solid #d3d3d9", height: "25px" }}></Box>
            <Typography>CMS ID : #ID-{projectValues?.id}</Typography>
          </Stack>
          <IconButton edge={"end"} component={"span"} onClick={handleClose}>
            <CloseIcon src={MediaAssets.ic_close} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {listOfAddedData()?.length ||
          listOfDeletedItems()?.length ||
          listOfUpdatedAndExistingData()?.length ? (
            <>
              <Box>
                {listOfAddedData()?.length > 0 && (
                  <Box mt={3}>
                    <BasicDataTable
                      tableHeadings={[
                        { label: CrmUpdatesConstants.listOfAddedItems },
                      ]}
                      tableData={listOfAddedData()}
                    />
                  </Box>
                )}

                {listOfDeletedItems()?.length > 0 && (
                  <Box mt={3}>
                    <BasicDataTable
                      tableHeadings={[
                        { label: CrmUpdatesConstants.listOfRemovedItems },
                      ]}
                      tableData={listOfDeletedItems()}
                    />
                  </Box>
                )}

                {listOfUpdatedAndExistingData()?.length > 0 && (
                  <Box mt={3}>
                    <BasicDataTable
                      tableHeadings={[
                        { label: CrmUpdatesConstants.listOfExistingItems },
                        { label: CrmUpdatesConstants.listOfUpdatedItems },
                      ]}
                      tableData={listOfUpdatedAndExistingData()}
                    />
                  </Box>
                )}
              </Box>

              <Box mt={5}>
                <Stack justifyContent={"center"} spacing={2}>
                  <ButtonComponent onClick={handleUpdatesDeny}>
                    Deny
                  </ButtonComponent>
                  <LoadingButton onClick={handleAcceptUpdates}>
                    Accept Updates
                  </LoadingButton>
                </Stack>
              </Box>
            </>
          ) : (
            <Box mt={3} mb={3}>
              No Updates From CRM
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CrmUpdatesDetailsDialog;
