import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import React, { Fragment, useState } from "react";
import {
  CloseIcon,
  FullWidthDialogBox,
  MyProfileStyleWrapper,
} from "./AuditViewDialog";
import styles from "./AuditLog.module.css";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { parseToReadableValue } from "./AuditLog";
import { RouteConstants } from "routes/RouteConstants";

interface IMyProfileDialogProps {
  shouldOpen: boolean;
  handleClose: () => void;
  auditedFieldObject: any;
}

function AuditedFieldViewDialog(props: IMyProfileDialogProps) {
  const { shouldOpen, handleClose, auditedFieldObject } = props;
  const navigate = useNavigate();

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (shouldOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [shouldOpen]);

  // const auditedFieldObject = getItemFromLocalStorage("Myprofile");
  const navigateToEdit = () => {
    if (
      auditedFieldObject?.route &&
      auditedFieldObject?.route === RouteConstants.projectCreation
    ) {
      navigate(auditedFieldObject?.route, {
        state: {
          projectContent: auditedFieldObject?.auditedObject,
          isView: false,
          isNavigatedFromAudit: true,
          isAuditedWhileCreating:
            auditedFieldObject?.status === AuditStatus.EDITED ? false : true,
        },
      });
    } else if (auditedFieldObject?.route) {
      navigate(auditedFieldObject?.route, {
        state: {
          ...auditedFieldObject?.auditedObject,
          isView: false,
          isAuditedWhileCreating:
            auditedFieldObject?.status === AuditStatus.EDITED ? false : true,
        },
      });
    } else if (auditedFieldObject?.modulesAudited?.route) {
      navigate(auditedFieldObject?.modulesAudited?.route, {
        state: {
          ...auditedFieldObject?.modulesAudited?.auditedObject,
          isView: false,
          isAuditedWhileCreating:
            auditedFieldObject?.modulesAudited?.status === AuditStatus.EDITED
              ? false
              : true,
        },
      });
    }
  };
  return (
    <Fragment>
      <FullWidthDialogBox
        style={{ padding: "0px" }}
        fullWidth
        scroll={"paper"}
        open={shouldOpen}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Stack spacing={0}>
            <Typography
              className={styles["my-profile-heading"]}
              data-testid="my-profile"
            >
              {auditedFieldObject?.module?.name}
            </Typography>
            <IconButton>
              {auditedFieldObject &&
              (auditedFieldObject?.route ||
                auditedFieldObject?.modulesAudited?.route) ? (
                <EditOutlinedIcon onClick={navigateToEdit} />
              ) : null}
            </IconButton>
          </Stack>
          <IconButton edge={"end"} component={"span"} onClick={handleClose}>
            <CloseIcon src={MediaAssets.ic_close} />
          </IconButton>
        </DialogTitle>

        <MyProfileStyleWrapper>
          <DialogContent>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography component="p" className="key-typography">
                    Created On :
                    <Typography
                      component="span"
                      className="value-typography"
                      data-testid="name"
                    >
                      {auditedFieldObject?.updatedAt
                        ? `${formatDateTime(
                            auditedFieldObject?.updatedAt,
                            Constants.dateFormatDDmmYYYY
                          )} ${formatDateTime(
                            auditedFieldObject?.updatedAt,
                            Constants.timeFormathhmmssA
                          )} by ${auditedFieldObject?.auditedAdmins?.firstName}`
                        : "----"}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography component="p" className="key-typography">
                    IP Address :
                    <Typography
                      component="span"
                      className="value-typography"
                      data-testid="designation"
                    >
                      {auditedFieldObject?.ipAddress}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography component="p" className="key-typography">
                    Module :
                    <Typography component="span" className="value-typography">
                      {auditedFieldObject?.module?.name}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="p" className="key-typography">
                    Item Affected :
                    <Typography component="span" className="value-typography">
                      {auditedFieldObject?.modulesAudited?.itemAffected}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="p" className="key-typography">
                    Status :
                    <Typography component="span" className="value-typography">
                      {auditedFieldObject?.status
                        ? auditedFieldObject?.status === AuditStatus.EDITED
                          ? "Edited"
                          : auditedFieldObject?.status === AuditStatus.CREATED
                          ? "Newly Created"
                          : auditedFieldObject?.status === AuditStatus.DELETED
                          ? "Deleted"
                          : "----"
                        : auditedFieldObject?.modulesAudited?.status
                        ? auditedFieldObject?.modulesAudited?.status ===
                          AuditStatus.EDITED
                          ? "Edited"
                          : auditedFieldObject?.modulesAudited?.status ===
                            AuditStatus.CREATED
                          ? "Newly Created"
                          : auditedFieldObject?.status === AuditStatus.DELETED
                          ? "Deleted"
                          : "----"
                        : "----"}
                    </Typography>
                  </Typography>
                </Grid>
                {(auditedFieldObject &&
                  // (auditedFieldObject?.route ||
                  //   auditedFieldObject?.modulesAudited?.route) &&
                  auditedFieldObject?.modulesAudited?.newValue) ||
                auditedFieldObject?.modulesAudited?.oldValue ? (
                  <>
                    <Grid item xs={12}>
                      <Typography className="contact-details">
                        Old Value
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography component="p" className="key-typography">
                        {parseToReadableValue(
                          auditedFieldObject?.modulesAudited?.oldValue,
                          auditedFieldObject?.modulesAudited?.itemAffected
                        )
                          ? parseToReadableValue(
                              auditedFieldObject?.modulesAudited?.oldValue,
                              auditedFieldObject?.modulesAudited?.itemAffected
                            )
                          : auditedFieldObject?.modulesAudited?.oldValue}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className="contact-details">
                        New Value
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography component="p" className="key-typography">
                        {parseToReadableValue(
                          auditedFieldObject?.modulesAudited?.newValue
                        )
                          ? parseToReadableValue(
                              auditedFieldObject?.modulesAudited?.newValue
                            )
                          : auditedFieldObject?.modulesAudited?.newValue}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </DialogContentText>
          </DialogContent>
        </MyProfileStyleWrapper>
      </FullWidthDialogBox>
    </Fragment>
  );
}

export default AuditedFieldViewDialog;
