import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";
import { log } from "console";

const { Types, Creators } = createActions({
  getLeadDataSuccess: ["response"],
  getLeadDataFailure: ["error"],
  getLeadDataRequest: ["data"],

  setLeadState: ["key", "value"],
});

export const leadTypes = Types;

export default Creators;

export const INITIAL_STATE: any = {
  leaddata: [],

  isLoading: false,
  isSuccess: false,
  message: "",
  error: null,
  pageSize: Constants.defaultPageSize,
  pageIndex: Constants.defaultPageIndex,
  totalPages: Constants.defaultTotalPages,
  totalCount: Constants.defaultTotalCount,
  page: 0,
  rowsPerPage: 10,
};
export const setLeadState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  console.log("entering here?");
  newState[action.key] = action.value;
  return newState;
};
export const getLeadDataRequest = (state: any) => {
  console.log("got request.....");

  return {
    ...state,
    isLoading: true,
  };
};

export const getLeadDataSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      leaddata: response?.data,
      isSuccess: true,
      message: response?.message
        ? response?.message
        : "Leads data added successfully!",
      isLoading: false,
      error: null,
      totalCount: response.totalCount,
    };
  }

  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const getLeadDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LEAD_DATA_SUCCESS]: getLeadDataSuccess,
  [Types.GET_LEAD_DATA_FAILURE]: getLeadDataFailure,
  [Types.GET_LEAD_DATA_REQUEST]: getLeadDataRequest,

  [Types.SET_LEAD_STATE]: setLeadState,
});
