import { FormikValues } from "formik";
import * as yup from "yup";

export interface mediaAndPrCreationStateType {
  isEditMode: boolean;
  isViewMode: boolean;
  formValues: FormikValues;
  buttonText: string;

  deleteConfirm: boolean;
}

export interface MediaAndPrType {
  displayTitle: string;
  description: string;
  logo: {
    media: {
      key: string;
      name: string;
      value: {
        url: string;
        size: number;
        width: number;
        height: number;
        mediaType: string;
      };
    };
  };
  url: string;
}

export const mediaAndPrCreationInitialValues: MediaAndPrType = {
  displayTitle: "",
  description: "",
  logo: {
    media: {
      key: "",
      name: "",
      value: {
        url: "",
        size: 1,
        width: 320,
        height: 240,
        mediaType: "IMAGE",
      },
    },
  },
  url: "",
};

export const validationSchema = yup.object({
  displayTitle: yup.string().required("This field is mandatory").nullable(),
  // description: yup.string().required("This field is mandatory").nullable(),
  // description: yup
  //   .string()
  //   .min(9, "This field is mandatory")
  //   .required("This field is mandatory"),
  url: yup.string().required("This field is mandatory").nullable(),

  logo: yup.object().shape({
    media: yup.object().shape({
      value: yup.object().shape({
        url: yup.string().required("Logo required"),
      }),
    }),
  }),
});
