import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import { Fragment } from "react";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import {
  CloseIcon,
  FullWidthDialogBox,
  MyProfileStyleWrapper,
} from "../user-profile/MyProfileDialog.style";
import styles from "./MyProfileDialog.module.css";
import {
  myProfileDialogTitles,
  myProfileDialogVariabes,
} from "./UserProfileDialogConstants";

interface IMyProfileDialogProps {
  shouldOpen: boolean;
  handleClose: () => void;
}

function MyProfileDialog(props: IMyProfileDialogProps) {
  const { shouldOpen, handleClose } = props;

  const profileData = getItemFromLocalStorage("Myprofile");

  return (
    <Fragment>
      <FullWidthDialogBox
        style={{ padding: "0px" }}
        fullWidth
        open={shouldOpen}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography
            className={styles["my-profile-heading"]}
            data-testid="my-profile"
          >
            {myProfileDialogTitles.myProfile}
          </Typography>
          <IconButton edge={"end"} component={"span"} onClick={handleClose}>
            <CloseIcon src={MediaAssets.ic_close} />
          </IconButton>
        </DialogTitle>

        <MyProfileStyleWrapper>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography component="p" className="kye-typography">
                  {myProfileDialogVariabes?.name}
                  <Typography
                    component="span"
                    className="value-typography"
                    data-testid="name"
                  >
                    {profileData?.firstName} {profileData?.lastName}
                  </Typography>
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography component="p" className="kye-typography">
                  {myProfileDialogVariabes?.designation}
                  <Typography
                    component="span"
                    className="value-typography"
                    data-testid="designation"
                  >
                    {profileData?.designation}
                  </Typography>
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography component="p" className="kye-typography">
                  {myProfileDialogVariabes?.team}
                  <Typography component="span" className="value-typography">
                    {profileData?.department}
                  </Typography>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className="contact-details">
                  {myProfileDialogTitles?.contactDetails}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography component="p" className="kye-typography">
                  {myProfileDialogVariabes?.email}
                  <Typography
                    component="span"
                    className={styles["value-typography"]}
                  >
                    {profileData?.email}
                  </Typography>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography component="p" className="kye-typography">
                  {myProfileDialogVariabes?.phoneNumber}
                  <Typography component="span" className="value-typography">
                    {profileData?.phoneNumber}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </MyProfileStyleWrapper>
      </FullWidthDialogBox>
    </Fragment>
  );
}

export default MyProfileDialog;
