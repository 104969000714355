import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Grid, Stack, Tab, Typography } from "@mui/material";
import { SyntheticEvent, useState, useEffect } from "react";
import NewForm from "./NewForm";
import PublishedForm from "./PublishedForm";
import { CustomDatePicker, TextFieldComponent } from "components/ui-elements";
import formListActions from "redux-container/form-list/FormListRedux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useOutlet, useOutletContext } from "react-router-dom";
import FormsListActons from "../../../redux-container/form-list/FormListRedux";

const newFormHeadings = [
  { heading: "Launch ID" },
  { heading: "Thumbnail Image" },
  { heading: "CRM Launch Name" },
  { heading: "CRM Project Name" },
  { heading: "Display Name" },
  { heading: "Booking Amount" },
  { heading: "Project Status" },
  { heading: "Actions" },
];
const paymentFormList = [
  { heading: "Form ID" },
  { heading: "CRM Launch Name" },
  { heading: "CRM Project Name" },
  { heading: "Display Name" },
  { heading: "Booking Amount" },
  { heading: "Created On", isSortingColumn: true, sortingKey: "0" },
  { heading: "Created By" },
  { heading: "Modified By " },
  { heading: "Status" },
  { heading: "Actions" },
];

const actionDispatch = (dispatch: any) => {
  return {
    getProjectsRequest: async (params: any) => {
      dispatch(formListActions.getProjectsRequest(params));
    },
    getLaunchesRequest: async (params: any) => {
      dispatch(formListActions.getLaunchesRequest(params));
    },
    setFormListState: async (key: any, value: any) => {
      dispatch(FormsListActons.setFormListState(key, value));
    },
    getForms: (params: any) =>
      dispatch(FormsListActons.formsListRequest(params)),
  };
};
const ProjectFormList = () => {
  const location: any = useLocation();

  const { getProjectsRequest, getLaunchesRequest, getForms, setFormListState } =
    actionDispatch(useDispatch());
  const { searchKey, setSearchKey, setShouldClearSearchKey }: any =
    useOutletContext();

  const totalCountProjectList = useSelector((state: any) => state?.projectList);
  const totalCountFormlist = useSelector((state: any) => state?.formsList);
  const tabValue = useSelector((state: any) => state?.formsList?.tabValue);

  useEffect(() => {
    getProjectsRequest({});
    getLaunchesRequest({});
    getForms({});
  }, []);

  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setFormListState("tabValue", "1");
    }

    return () => {};
  }, []);

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    setFormListState("tabValue", newValue);
    setShouldClearSearchKey(true);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tabValue}>
        <Grid container justifyContent={"space-between"}>
          <Grid item lg={4} xl={4}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label={
                  <Typography variant="h6" textTransform={"capitalize"}>
                    {`Live Launches (${
                      totalCountProjectList?.totalCount
                        ? totalCountProjectList?.totalCount
                        : totalCountProjectList?.data?.data?.length
                        ? totalCountProjectList?.data?.data?.length
                        : 0
                    })`}
                  </Typography>
                }
                value="1"
              />
              <Tab
                label={
                  <Typography variant="h6" textTransform={"capitalize"}>
                    {`Live Payment Forms (${
                      totalCountFormlist?.totalCount
                        ? totalCountFormlist?.totalCount
                        : totalCountFormlist?.forms?.length
                        ? totalCountFormlist?.forms?.length
                        : 0
                    })`}
                  </Typography>
                }
                value="2"
              />
            </TabList>
          </Grid>
        </Grid>
        {/* <Grid container spacing={2}>
          <Grid item lg={2} xl={2}>
            <Stack alignItems={"center"} columnGap={"0.5rem"}>
              <Typography mt={"-15px"}>Filters:</Typography>
              <TextFieldComponent
                select
                variant="outlined"
                label="Project Name"
              />
            </Stack>
          </Grid>
          <Grid item lg={1} xl={1}>
            <TextFieldComponent select variant="outlined" label="Launch" />
          </Grid>
          <Grid item lg={1} xl={1}>
            <TextFieldComponent select variant="outlined" label="Status" />
          </Grid>
          <Grid item lg={1} xl={1}>
            <CustomDatePicker variant="outlined" label="Date" />
          </Grid>
          <Grid item lg={1.5} xl={1.5}>
            <TextFieldComponent select variant="outlined" label="Created by" />
          </Grid>
        </Grid> */}
        {/* <Divider variant="middle" /> */}
        <TabPanel value="1">
          <NewForm
            listHeading={newFormHeadings}
            isNewForm={true}
            tabValue={tabValue}
            searchKey={tabValue == "1" ? searchKey : ""}
          />
        </TabPanel>
        <TabPanel value="2">
          <PublishedForm
            listHeading={paymentFormList}
            tabValue={tabValue}
            searchKey={tabValue == "2" ? searchKey : ""}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ProjectFormList;
