import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import ProjectCollectionListActions from "redux-container/project-management/project-collection/ProjectCollectionListRedux";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";

/* get Project Collection */
export function* getProjectCollectionList(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.getProjectCollectionList, action.data);

        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ProjectCollectionListActions.getProjectCollectionListSuccess(response.data));
        } else {
            yield put(ProjectCollectionListActions.getProjectCollectionListFailure(response.data));
        }
    } catch (error) {
        yield put(ProjectCollectionListActions.getProjectCollectionListFailure(error));
    }
}
/* delete Project collection */
export function* deleteProjectCollectionList(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.deleteProjectCollectionList, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ProjectCollectionListActions.deleteProjectCollectionListSuccess(response.data));
        } else {
            yield put(ProjectCollectionListActions.deleteProjectCollectionListFailure(response.data));
        }
    } catch (error) {
        yield put(ProjectCollectionListActions.deleteProjectCollectionListFailure(error));
    }
}
/* change Project collection status*/
export function* changeProjectCollectionListStatus(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.changeProjectCollectionListStatus, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ProjectCollectionListActions.changeProjectCollectionListStatusSuccess(response.data));
        } else {
            yield put(ProjectCollectionListActions.changeProjectCollectionListStatusFailure(response.data));
        }
    } catch (error) {
        yield put(ProjectCollectionListActions.changeProjectCollectionListStatusFailure(error));
    }
}