import { ChangeEvent, ReactNode, FC } from "react";
import {
  Avatar,
  BaseTextFieldProps,
  Box,
  IconButton,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AlertHelperText from "../alert-helper-text/AlertHelperText";
import InfoComponent from "../info-component/InfoComponent";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

export interface ICustomTexfieldProps extends BaseTextFieldProps {
  helpericon?: string;
  fieldhelpertext?: any;
  helpericoncontent?: any;
  fieldtitle?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  InputProps?: { [key: string]: ReactNode };
  value?: any;
  defaultValue?: any;
  error?: boolean;
  placement?: any;
  isPaymentDisabledField?: boolean;
  showUploadIcon?: boolean;
}

const TextStyleWrapper = styled(Box)<ICustomTexfieldProps>(
  ({ theme, fieldhelpertext }) => `
    width:100%;
    & .MuiOutlinedInput-root {
      padding-left: 0.5rem;
      & .MuiInputBase-inputAdornedEnd  {
        color: ${
          fieldhelpertext
            ? theme.palette.error.main
            : theme.palette.common.black
        };
      }
    }    
    & .MuiFormHelperText-root.Mui-error {
      color: ${theme.palette.error.main};
    }  
    & .MuiAlert-message {
      font-size: 0.875rem;
    } 
    & .MuiAlert-icon {
      font-size: .875rem;
      color: #ff485a;
      margin-top: 3px;
    }
`
);

const PaymentDisabledFieldStyles = styled(Box)<ICustomTexfieldProps>(
  ({ theme }) => `
    width:100%;
    padding: 1rem;
    border: 1px solid ${theme.palette.primary.main};
    border-radius: 6px;
    background-color: rgba(246,246,250, 1);
    cursor:not-allowed;

    & .MuiTypography-root{
      font-family: "Jost-Medium"
    }
    `
);

const TextFieldComponent: FC<ICustomTexfieldProps> = (props) => {
  const {
    children,
    helpericon,
    helpericoncontent,
    fieldhelpertext,
    value = "",
    inputProps,
    defaultValue = "",
    placement,
    isPaymentDisabledField,
    showUploadIcon,
  } = props;

  return (
    <>
      {isPaymentDisabledField ? (
        <PaymentDisabledFieldStyles>
          <Stack>
            <Typography>{value}</Typography>
            {showUploadIcon && (
              <FileUploadOutlinedIcon
                color={"inherit"}
                sx={{ color: "#000" }}
              />
            )}
          </Stack>
        </PaymentDisabledFieldStyles>
      ) : (
        <TextStyleWrapper>
          <Stack justifyContent={"flex-end"}>
            {helpericon && (
              <InfoComponent
                infoContent={helpericoncontent}
                placement={placement}
              />
            )}
          </Stack>
          <TextField {...props}>{children}</TextField>
          <Stack mt={"0.1rem"}>
            {fieldhelpertext ? (
              <AlertHelperText
                variant="standard"
                color="error"
                severity="error"
              >
                {fieldhelpertext || ""}
              </AlertHelperText>
            ) : (
              <Typography
                variant="body2"
                visibility={"hidden"}
                lineHeight={"1em"}
              >
                error
              </Typography>
            )}
            <AlertHelperText icon={false} variant="standard" color="info">
              {inputProps?.maxLength > 10
                ? `${
                    inputProps?.maxLength - value?.length ||
                    defaultValue?.length
                  } Characters`
                : ""}
            </AlertHelperText>
          </Stack>
        </TextStyleWrapper>
      )}
    </>
  );
};

export default TextFieldComponent;
