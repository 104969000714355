import {
  Avatar,
  FormControl,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from "@mui/material";
import MediaAssets from "assets";

const RadioIcon = () => (
  <Avatar
    sx={{ width: 23, height: 23 }}
    src={MediaAssets.ic_radio_icon}
    alt="radio-icon"
    variant="rounded"
  />
);

const RadioCheckedIcon = () => (
  <Avatar
    sx={{ width: 23, height: 23 }}
    src={MediaAssets.ic_radio_checked_icon}
    alt="radio-icon"
    variant="rounded"
  />
);

export function StyledRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<RadioCheckedIcon />}
      icon={<RadioIcon />}
      {...props}
    />
  );
}

const RadioButtonGroupComponent = (props: RadioGroupProps) => {
  const { children } = props;
  return (
    <FormControl fullWidth component="fieldset">
      <RadioGroup row {...props}>
        {children}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroupComponent;
