import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  moduleSuccess: ["response"],
  roleSuccess: ["response"],
  rolesAndModulesFailure: ["error"],
  rolesAndModulesRequest: ["data"],

  setRoleOrModulesState: ["key", "value"],
});

export const RoleAndModuleTypes = Types;

export const RoleAndModuleActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  module: [],
  role: [],
};

export const rolesAndModulesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const moduleSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      module: response.data.map((moduleItems: any) => ({
        ...moduleItems,
        disabled: false,
      })),
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const roleSuccess = (state: any, action: any) => {
  const response = action.response;
  const filteredRoles = response.data.filter(
    (role: { id: number; name: string }) => {
      return role.id !== 1;
    }
  );
  if (response) {
    return {
      ...state,
      error: null,
      role: filteredRoles,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const rolesAndModulesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setRoleOrModulesState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.MODULE_SUCCESS]: moduleSuccess,
  [Types.ROLE_SUCCESS]: roleSuccess,
  [Types.ROLES_AND_MODULES_FAILURE]: rolesAndModulesFailure,
  [Types.ROLES_AND_MODULES_REQUEST]: rolesAndModulesRequest,
  [Types.SET_ROLE_OR_MODULES_STATE]: setRoleOrModulesState,
});
