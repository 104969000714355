import { Box, styled } from "@mui/material";

export const TooltipContentWrapper = styled(Box)`
  width: 16rem;
  .content-caption {
    margin-bottom: 1rem;
  }
  .content-text {
    line-height: 1;
    color: ${({ theme }) => theme.palette.common.white};
  }
  .order-list-gutter {
    padding: 1rem 1.1rem;
  }
`;
