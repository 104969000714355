import Button from "@mui/material/Button";
import styles from "./NoDataRecord.module.css";

interface INoDataRecordProps {
  image: string;
  altText: string;
  message: string;
  btnTitle?: string;
  btnAction?: () => void;
}

const NoDataRecord = ({
  image,
  altText,
  message,
  btnTitle,
  btnAction,
}: INoDataRecordProps) => {
  return (
    <div className={styles["no-data-container"]}>
      <div className={styles["image-holder"]}>
        <img src={image} alt={altText} />
      </div>
      <p className={styles["no-data-message"]}>{message}</p>

      {btnTitle ? (
        <Button
          variant="contained"
          onClick={btnAction}
          // autoFocus
          disableElevation
          className="btn btn-dark"
        >
          {btnTitle}
        </Button>
      ) : null}
    </div>
  );
};

export default NoDataRecord;
