import { Constants } from "constants/Constants";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  forgotPasswordEmailSentSuccess: ["response"],
  forgotPasswordEmailSentFailure: ["error"],
  forgotPasswordEmailSentRequest: ["data"],

  setForgotPasswordState: ["key", "value"],
});

export const ForgetPasswordTypes = Types;

export const ForgotPasswordAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  data: null,
  isSucessfulSentEmail: false,
  enteredEmail: "",
};

export const forgotPasswordEmailSentRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSucessfulSentEmail: false,
  };
};
export const forgotPasswordEmailSentSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    data: action.response,
    error: null,
    isSucessfulSentEmail: true,
  };
};

export const forgotPasswordEmailSentFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    // isSucessfulSentEmail: true
  };
};

export const setForgotPasswordState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FORGOT_PASSWORD_EMAIL_SENT_SUCCESS]: forgotPasswordEmailSentSuccess,
  [Types.FORGOT_PASSWORD_EMAIL_SENT_FAILURE]: forgotPasswordEmailSentFailure,
  [Types.FORGOT_PASSWORD_EMAIL_SENT_REQUEST]: forgotPasswordEmailSentRequest,
  [Types.SET_FORGOT_PASSWORD_STATE]: setForgotPasswordState,
});
