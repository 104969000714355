import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    //get all category 
    getProjectCategoriesSuccess: ["response"],
    getProjectCategoriesFailure: ["error"],
    getProjectCategoriesRequest: ["data"],

    //add 
    addProjectCategorySuccess: ["response"],
    addProjectCategoryFailure: ["error"],
    addProjectCategoryRequest: ["data"],

    //delete
    deleteProjectCategorySuccess: ["response"],
    deleteProjectCategoryFailure: ["error"],
    deleteProjectCategoryRequest: ["data"],

    //put 
    putProjectCategorySuccess: ["response"],
    putProjectCategoryFailure: ["error"],
    putProjectCategoryRequest: ["id", "data"],

    //bulk status change
    bulkStatusUpdateProjectCategoryRequest: ["data"],
    bulkStatusUpdateProjectCategorySuccess: ["response"],
    bulkStatusUpdateProjectCategoryFailure: ["error"],

    //priority changes for project category 
    changeCategoryPrioritySuccess: ["response"],
    changeCategoryPriorityFailure: ["error"],
    changeCategoryPriorityRequest: ["data"],

    //bulk delete change 

    bulkDeleteProjectCategorySuccess: ["response"],
    bulkDeleteProjectCategoryRequest: ["data"],
    bulkDeleteProjectCategoryFailure: ["error"],

    // set 
    setProjectCategoriesState: ["key", "value"],
});

export const GetProjectCategoriesTypes = Types;

export const GetProjectCategoriesAction = Creators;

export const INITIAL_STATE = {
    projectCategories: [],
    isProjectCategoriesSuccess: false,
    isLoading: false,
    totalCount: null,
    mappedCategoryArray:[],
    projectCategoryTableResponse:null

};

/* Get Categories */
export const getProjectCategoriesRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
    };
};

export const getProjectCategoriesSuccess = (state: any, action: any) => {
    const response = action.response;
    const mappedArray = response?.data?.filter((category: any) => {
        return state?.mappedCategoryArray?.indexOf(category?.id) !== -1
    })
  
    return {
        ...state,
        projectCategories: mappedArray,
        isLoading: false,
        totalCount: mappedArray?.length,
    };

};

export const getProjectCategoriesFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
    };
};


//add catagories

export const addProjectCategoryRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,

    };
};

export const addProjectCategorySuccess = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        isLoading: false,
    };
};

export const addProjectCategoryFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,

    };
};

//delete category
export const deleteProjectCategoryRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isProjectCategoriesSuccess: false,
        projectCategoryTableResponse: null,
    };
};


export const deleteProjectCategoryFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        isProjectCategoriesSuccess: true,
        projectCategoryTableResponse: action.error
    };
};


export const deleteProjectCategorySuccess = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        isProjectCategoriesSuccess: true,
        projectCategoryTableResponse: action.response

    };
};


//editing the category

export const putProjectCategoryFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,

    };
};



export const putProjectCategoryRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
    };
};

export const putProjectCategorySuccess = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        isLoading: false,

    };
};

//bulk status update for categories
export const bulkStatusUpdateProjectCategoryRequest = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        isLoading: true,
        isProjectCategoriesSuccess: false,
        projectCategoryTableResponse:null
    };
}
export const bulkStatusUpdateProjectCategorySuccess = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        isLoading: false,
        isProjectCategoriesSuccess: true,
        projectCategoryTableResponse:action.response
    };
}
export const bulkStatusUpdateProjectCategoryFailure = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        isLoading: false,
        isProjectCategoriesSuccess: true,
        projectCategoryTableResponse:action.error
    };
}
// priority changes for category 

export const changeCategoryPriorityRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isProjectCategoriesSuccess: false,
        projectCategoryTableResponse: null
    };
};

export const changeCategoryPrioritySuccess = (state: any, action: any) => {
    const response = action.response;
        return {
            ...state,
            isLoading: false,
            isProjectCategoriesSuccess: true,
            projectCategoryTableResponse: action.response
        };
    
};

export const changeCategoryPriorityFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        isProjectCategoriesSuccess: true,
        projectCategoryTableResponse: action.error
    };
};

// bulkdelete project categories

export const bulkDeleteProjectCategoryRequest = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        isLoading: true,
        isProjectCategoriesSuccess: false,
    };
}
export const bulkDeleteProjectCategorySuccess = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        isLoading: false,
        isProjectCategoriesSuccess: true,
        projectCategoryTableResponse: action.response
    };
}
export const bulkDeleteProjectCategoryFailure = (state: any, action: any) => {
    const response = action.response;
    return {
        ...state,
        isLoading: false,
        isProjectCategoriesSuccess: false,
        projectCategoryTableResponse: action.error,
    };
}

//seting upcategory
export const setProjectCategoriesState = (state: any, action: any) => {
    const newState = {
        ...state,
    };
    newState[action.key] = action.value;
    return newState;
};



export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_PROJECT_CATEGORIES_REQUEST]: getProjectCategoriesRequest,
    [Types.GET_PROJECT_CATEGORIES_SUCCESS]: getProjectCategoriesSuccess,
    [Types.GET_PROJECT_CATEGORIES_FAILURE]: getProjectCategoriesFailure,

    [Types.ADD_PROJECT_CATEGORY_REQUEST]: addProjectCategoryRequest,
    [Types.ADD_PROJECT_CATEGORY_SUCCESS]: addProjectCategorySuccess,
    [Types.ADD_PROJECT_CATEGORY_FAILURE]: addProjectCategoryFailure,

    [Types.DELETE_PROJECT_CATEGORY_REQUEST]: deleteProjectCategoryRequest,
    [Types.DELETE_PROJECT_CATEGORY_SUCCESS]: deleteProjectCategorySuccess,
    [Types.DELETE_PROJECT_CATEGORY_FAILURE]: deleteProjectCategoryFailure,


    [Types.PUT_PROJECT_CATEGORY_REQUEST]: putProjectCategoryRequest,
    [Types.PUT_PROJECT_CATEGORY_SUCCESS]: putProjectCategorySuccess,
    [Types.PUT_PROJECT_CATEGORY_FAILURE]: putProjectCategoryFailure,

    [Types.BULK_STATUS_UPDATE_PROJECT_CATEGORY_REQUEST]: bulkStatusUpdateProjectCategoryRequest,
    [Types.BULK_STATUS_UPDATE_PROJECT_CATEGORY_SUCCESS]: bulkStatusUpdateProjectCategorySuccess,
    [Types.BULK_STATUS_UPDATE_PROJECT_CATEGORY_FAILURE]: bulkStatusUpdateProjectCategoryFailure,


    [Types.BULK_DELETE_PROJECT_CATEGORY_REQUEST]: bulkDeleteProjectCategoryRequest,
    [Types.BULK_DELETE_PROJECT_CATEGORY_SUCCESS]: bulkDeleteProjectCategorySuccess,
    [Types.BULK_DELETE_PROJECT_CATEGORY_FAILURE]: bulkDeleteProjectCategoryFailure,


    [Types.CHANGE_CATEGORY_PRIORITY_SUCCESS]: changeCategoryPrioritySuccess,
    [Types.CHANGE_CATEGORY_PRIORITY_FAILURE]: changeCategoryPriorityFailure,
    [Types.CHANGE_CATEGORY_PRIORITY_REQUEST]: changeCategoryPriorityRequest,

    
    [Types.SET_PROJECT_CATEGORIES_STATE]: setProjectCategoriesState,
});
