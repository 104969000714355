import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./ImageUploadMediaDialog.module.css";
import CloseIcon from "@mui/icons-material/Close";
import ImageUploaderDragDrop from "./ImageUploadDragDrop";
import TextFieldComponent from "../../../../components/ui-elements/text-field/TextFieldComponent";
import { IconButton, MenuItem, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { LoadingButtonComponent } from "components/ui-elements";

interface IImageUploadDialog {
  shouldOpen: boolean;
  title: string;
  showWarning?: boolean;
  cancelHandler: () => void;
}

const ImageUploadDialog = ({
  shouldOpen,
  title,
  showWarning = false,
  cancelHandler,
}: IImageUploadDialog) => {
  const dialogTitle = title ? title : "Upload new Image";

  const handleClose = () => {
    cancelHandler();
  };

  const initialState = {
    imageUpload: "",
    name: "",
    category: "select",
  };

  return (
    <Dialog
      data-testid="main-dialog-box"
      maxWidth="lg"
      open={shouldOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <DialogTitle data-testid="dialog-title" color="black">
        <Typography variant="h5" component="h5">
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        className={styles["close-icon-btn-wrapper"]}
        aria-label="close-icon"
      >
        <CloseIcon className={styles["close-icon-btn"]} />
      </IconButton>

      <DialogContent>
        {showWarning ? (
          <Alert severity="warning" className={styles["confirm-dialog-alert"]}>
            <AlertTitle>
              <strong>Warning</strong>
            </AlertTitle>
            This action cannot be undone!
          </Alert>
        ) : null}

        <DialogContentText>
          <Formik
            initialValues={initialState}
            onSubmit={(values) => {
              handleClose();
            }}
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
            }) => {
              return (
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  className={styles["form-section"]}
                >
                  <div className={styles["media-upload-content"]}>
                    <ImageUploaderDragDrop
                      data-testid="media-uploader"
                      onDrop={handleChange}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let imageUrl = URL.createObjectURL(e.target.files[0]);
                        setFieldValue(`imageUpload`, imageUrl);
                      }}
                      previewimage={values.imageUpload}
                      cancelimage={() => {
                        setFieldValue("imageUpload", "");
                      }}
                      fieldhelpertext={
                        touched &&
                        touched.imageUpload &&
                        values.imageUpload.length < 1 &&
                        "Required"
                      }
                    />
                  </div>

                  <div>
                    <div className={styles["text-select-elements-wrapper"]}>
                      <TextFieldComponent
                        inputProps={{ "data-testid": "name-textField" }}
                        className={styles["textfield-section"]}
                        placeholder="Enter"
                        name={"name"}
                        fieldtitle="Name *"
                        onBlur={handleBlur}
                        value={values.name}
                        required
                        onChange={handleChange}
                        fieldhelpertext={
                          touched &&
                          touched.name &&
                          values.name.length < 1 &&
                          "Required"
                        }
                      />

                      <TextFieldComponent
                        inputProps={{ "data-testid": "category-select-field" }}
                        className={styles["textfield-section"]}
                        type="select"
                        placeholder="Enter"
                        name={"category"}
                        fieldtitle="Category *"
                        onBlur={handleBlur}
                        value={values.category}
                        required
                        onChange={handleChange}
                        fieldhelpertext={
                          touched &&
                          touched.category &&
                          values.category.length < 1 &&
                          "Required"
                        }
                        select
                        defaultValue={"select"}
                      >
                        <MenuItem disabled value={"select"}>
                          Select an option or Create one
                        </MenuItem>
                        <MenuItem value={"Plot"}> Plot </MenuItem>
                        <MenuItem value={"Land"}> Land </MenuItem>
                        <MenuItem value={"House"}> House </MenuItem>
                      </TextFieldComponent>
                    </div>

                    <div className={styles["upload-btn-wrapper"]}>
                      <LoadingButtonComponent
                        className="btn btn-dark"
                        type="submit"
                        disabled={
                          !values.name ||
                          values.category === "select" ||
                          (!values.imageUpload && true)
                        }
                        variant="contained"
                        disableElevation
                        data-testid="upload-btn-dialog"
                      >
                        UPLOAD
                      </LoadingButtonComponent>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ImageUploadDialog;
