import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";

import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { projectCategoryForFaqAction } from "redux-container/project-management/ProjectCategoryForFaqCreationRedux";


export function* projectCategoryForFaq(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.getAllCategories, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(projectCategoryForFaqAction.projectCategoryForFaqCreationSuccess(response.data));
        } else {
            yield put(projectCategoryForFaqAction.projectCategoryForFaqCreationFailure(response.data));
        }
    } catch (error) {
        yield put(projectCategoryForFaqAction.projectCategoryForFaqCreationFailure(error));
    }
}

//ADD NEW CATEGORY   

export function* addNewProjectCategoryForFaq(api: any, action: any): any {
    try {
        const response = yield call(api.addNewCategory, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(projectCategoryForFaqAction.addProjectCategoryForFaqCreationSuccess(response.data));
        } else {
            yield put(projectCategoryForFaqAction.addProjectCategoryForFaqCreationFailure(response.data));
        }
    } catch (error) {
        yield put(projectCategoryForFaqAction.addProjectCategoryForFaqCreationFailure(error));
    }
}

//Delete category

export function* deleteProjectCategoryForFaq(api: any, action: any): any {
    try {
        const response = yield call(api.deleteCategory, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {

            yield put(projectCategoryForFaqAction.deleteProjectCategoryForFaqCreationSuccess(response.data));
        } else {
            yield put(projectCategoryForFaqAction.deleteProjectCategoryForFaqCreationFailure(response.data));
        }
    } catch (error) {
        yield put(projectCategoryForFaqAction.deleteProjectCategoryForFaqCreationFailure(error));
    }
}

//put category 

export function* putProjectCategoryForFaq(api: any, action: any): any {

    try {
        const response = yield call(api.putCategory, action.id, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(projectCategoryForFaqAction.putProjectCategoryForFaqCreationSuccess(response.data));
        } else {
            yield put(projectCategoryForFaqAction.putProjectCategoryForFaqCreationFailure(response.data));
        }
    } catch (error) {
        yield put(projectCategoryForFaqAction.putProjectCategoryForFaqCreationFailure(error));
    }
}

//bulk status change 

export function* statusUpdateProjectCategoryForFaq(api: any, action: any): any {

    try {
        const response = yield call(api.bulkStatusProjectCategory, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(projectCategoryForFaqAction.bulkStatusProjectCategoryForFaqCreationSuccess(response.data));
        } else {
            yield put(projectCategoryForFaqAction.bulkStatusProjectCategoryForFaqCreationFailure(response.data));
        }
    } catch (error) {
        yield put(projectCategoryForFaqAction.bulkStatusProjectCategoryForFaqCreationFailure(error));
    }
}
