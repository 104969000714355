import { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import AboutPromisesSection from "./about-promises/AboutPromises";
import DisclaimerSection from "./disclaimer/Disclaimer";
import PromiseSection from "./promise/Promise";
import { RouteConstants } from "routes/RouteConstants";
import PageHeading from "components/page-heading/PageHeading";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { PageTypeEnum } from "enumerations/PageTypeEnum";
import PagePromiseActions from "../../../../redux-container/page-management/Page3PromiseRedux";
import Loader from "components/loader/Loader";
import Retry from "components/retry/Retry";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { isEmpty } from "utils/ValidationUtils";
import LoadingButtonComponent from "components/ui-elements/button/LoadingButtonComponent";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { Stack } from "@mui/material";
import { page3Labels } from "./page3Constants";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { AuditStatus } from "enumerations/AuditStatusTypes";

const actionDispatch = (dispatch: any) => {
  return {
    getPagePromise: (params: any) =>
      dispatch(PagePromiseActions.getPagePromisesRequest(params)),
    addPagePromise: (data: any) =>
      dispatch(PagePromiseActions.addPagePromisesRequest(data)),
    updatePagePromise: (data: any) =>
      dispatch(PagePromiseActions.updatePagePromisesRequest(data)),
    setPagePromiseState: (key: any, data: any) =>
      dispatch(PagePromiseActions.setPagePromisesState(key, data)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const Page3Promises = () => {
  const navigate = useNavigate();

  const {
    getPagePromise,
    addPagePromise,
    updatePagePromise,
    setPagePromiseState,
    postAuditLogRequest,
    rolesAndModulesRequest,
  } = actionDispatch(useDispatch());

  const pageData = useSelector((state: any) => state?.page3Promises?.pageData);

  const isLoading = useSelector(
    (state: any) => state?.page3Promises?.isLoading
  );
  const error = useSelector((state: any) => state?.page3Promises?.error);
  const message = useSelector((state: any) => state?.page3Promises?.message);
  const isSuccess = useSelector(
    (state: any) => state?.page3Promises?.isSuccess
  );

  const [aboutPromiseSectionHeading, setAboutPromiseSectionHeading] =
    useState("");
  const [aboutPromiseDescription, setAboutPromiseDescription] = useState("");
  const [promiseSectionHeading, setPromiseSectionHeading] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [isDisclaimerActive, setIsDisclaimerActive] = useState(false);
  const [page, setPage] = useState<any>(null);

  const [aboutPromiseHeadingError, setAboutPromiseHeadingError] = useState("");
  const [aboutPromiseDescriptionError, setAboutPromiseDescriptionError] =
    useState("");
  const [promiseHeadingError, setPromiseHeadingError] = useState("");
  const [disclaimerError, setDisclaimerError] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const location: any = useLocation();
  const [isViewMode, setIsViewMode] = useState(
    location?.state?.isViewMode ? location?.state?.isViewMode : false
  );
  const [shouldPublishActive, setShouldPublishActive] = useState(false);
  const [pagedataLocalState, setpagedataLocalState] = useState<any>();

  const modules = useSelector((state: any) => state?.rolesAndModules?.module);
  const [previousStates, setPreviousStates] = useState<any>({
    aboutPromiseSectionHeading: "",
    aboutPromiseDescription: "",
    promiseSectionHeading: "",
    disclaimer: "",
    isDisclaimerActive: false,
  });
  const [finalValues, setfinalValues] = useState<any>();

  const [modulesAudited, setModulesAudited] = useState<any>(null);
  const [statusAudited, setStatusAudited] = useState<any>(null);
  const [isItemDeleted, setIsItemDeleted] = useState(false);

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  const breadcrumbRoutes: any = [
    {
      name: "Page Management",
      route: RouteConstants.pageManagementDashboard,
    },
    {
      name: "Page 3 - Promises",
      route: RouteConstants.page3Promises,
    },
  ];

  useEffect(() => {
    const params = {
      pageType: PageTypeEnum.Promises,
    };
    loadData(params);
  }, []);

  useEffect(() => {
    if (pageData) {
      setLoadedData(pageData.page);
      setpagedataLocalState(pageData);
      setPage(pageData.page);
      setPagePromiseState("pageData", null);
    }
  }, [pageData]);

  useEffect(() => {
    if (pageData) {
      setPreviousStates({
        ...previousStates,
        aboutPromiseSectionHeading:
          pageData?.page?.promiseSection?.aboutPromises?.sectionHeading,
        aboutPromiseDescription:
          pageData?.page?.promiseSection?.aboutPromises?.subDescription,
        promiseSectionHeading:
          pageData?.page?.promiseSection?.promiseSectionHeading,
        disclaimer: pageData?.page?.promiseSection?.disclaimer,
        isDisclaimerActive:
          pageData?.page?.promiseSection?.aboutPromises?.isDisclaimerActive,
      });
    }
  }, [pageData]);

  useEffect(() => {
    rolesAndModulesRequest();
    return () => {};
  }, []);

  const loadData = (params: any) => {
    getPagePromise(params);
  };
  const shouldEnablePublishButton = (data: any, stateName: any) => {
    let shouldPublishButtonActive: boolean = false;
    if (stateName === "aboutPromiseSectionHeading") {
      if (
        pagedataLocalState?.page?.promiseSection?.aboutPromises
          ?.sectionHeading !== data
      ) {
        shouldPublishButtonActive = true;
      }
    } else if (
      pagedataLocalState?.page?.promiseSection?.aboutPromises
        ?.sectionHeading !== aboutPromiseSectionHeading
    ) {
      shouldPublishButtonActive = true;
    }

    if (stateName === "aboutPromiseDescription") {
      if (
        pagedataLocalState?.page?.promiseSection?.aboutPromises
          ?.subDescription !== data
      ) {
        shouldPublishButtonActive = true;
      }
    } else if (
      pagedataLocalState?.page?.promiseSection?.aboutPromises
        ?.subDescription !== aboutPromiseDescription
    ) {
      shouldPublishButtonActive = true;
    }
    if (stateName === "promiseSectionHeading") {
      if (
        pagedataLocalState?.page?.promiseSection?.promiseSectionHeading !== data
      ) {
        shouldPublishButtonActive = true;
      }
    } else if (
      pagedataLocalState?.page?.promiseSection?.promiseSectionHeading !==
      promiseSectionHeading
    ) {
      shouldPublishButtonActive = true;
    }
    if (stateName === "disclaimer") {
      if (pagedataLocalState?.page?.promiseSection?.disclaimer !== data) {
        shouldPublishButtonActive = true;
      }
    } else if (
      pagedataLocalState?.page?.promiseSection?.disclaimer !== disclaimer
    ) {
      shouldPublishButtonActive = true;
    }

    setShouldPublishActive(shouldPublishButtonActive);
  };
  const setLoadedData = (page?: any) => {
    if (page && page.promiseSection) {
      setAboutPromiseSectionHeading(
        page?.promiseSection?.aboutPromises?.sectionHeading
      );
      setAboutPromiseDescription(
        page?.promiseSection?.aboutPromises?.subDescription
      );
      setPromiseSectionHeading(page?.promiseSection?.promiseSectionHeading);
      setDisclaimer(page?.promiseSection?.disclaimer);
      setIsDisclaimerActive(
        page?.promiseSection?.aboutPromises?.isDisclaimerActive
      );
    }
  };

  const handleAboutPromiseSectionHeadingChange = (heading: any) => {
    setAboutPromiseSectionHeading(heading?.value);
    // shouldEnablePublishButton(heading?.value,heading?.name);
  };

  const handlePromiseSectionHeadingChange = (heading: any) => {
    // shouldEnablePublishButton(heading?.value,heading?.name);
    setPromiseSectionHeading(heading.value);
  };

  const handleAboutPromiseDescriptionChange = (description: any) => {
    // shouldEnablePublishButton(description?.value,description?.name);
    setAboutPromiseDescription(description.value);
  };

  const handleDisclaimerChange = (disclaimer: any) => {
    // shouldEnablePublishButton(disclaimer?.value,disclaimer?.name);
    setDisclaimer(disclaimer.value);
  };

  const handleCloseFlashMessageDialog = () => {
    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Pages Management";
    });
    if (isSuccess) {
      if (modulesAudited?.length) {
        const postAuditRequestbody = {
          ipAddress: ipAddress, //ipAddress
          moduleId: moduleObject?.id,
          modulesAudited: modulesAudited,
          status: AuditStatus.EDITED,
          auditedObject: finalValues,
          route: location.pathname,
        };
        postAuditLogRequest(postAuditRequestbody);
      }
    }
    setPagePromiseState("message", null);
    setPagePromiseState("error", null);
    navigate(RouteConstants.pageManagementDashboard);
  };

  const handleOnBlurAboutPromiseHeading = () => {
    if (isEmpty(aboutPromiseSectionHeading)) {
      setAboutPromiseHeadingError("Please enter the section heading");
    } else {
      setAboutPromiseHeadingError("");
    }
  };

  const handleOnBlurAboutPromiseDescription = () => {
    if (isEmpty(aboutPromiseDescription)) {
      setAboutPromiseDescriptionError("Please enter the description");
    } else {
      setAboutPromiseDescriptionError("");
    }
  };

  const handleOnBlurPromiseHeading = () => {
    if (isEmpty(promiseSectionHeading)) {
      setPromiseHeadingError("Please enter the section heading");
    } else {
      setPromiseHeadingError("");
    }
  };

  const handleOnBlurDisclaimer = () => {
    if (isEmpty(promiseSectionHeading)) {
      setDisclaimerError("Please enter the disclaimer");
    } else {
      setDisclaimerError("");
    }
  };

  const handlePublish = () => {
    let isValid = true;
    if (isEmpty(aboutPromiseSectionHeading)) {
      setAboutPromiseHeadingError("Please enter the section heading");
      isValid = false;
    }
    if (isEmpty(aboutPromiseDescription)) {
      setAboutPromiseDescriptionError("Please enter the description");
      isValid = false;
    }
    if (isEmpty(promiseSectionHeading)) {
      setPromiseHeadingError("Please enter the section heading");
      isValid = false;
    }
    if (isEmpty(disclaimer)) {
      setDisclaimerError("Please enter the disclaimer");
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    setConfirmDialogOpen(true);
  };

  const handleDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  const handleDeleteOkClick = () => {
    let auditObjectsArray: any[] = [];
    const presentStates: any = {
      aboutPromiseSectionHeading: aboutPromiseSectionHeading,
      aboutPromiseDescription: aboutPromiseDescription,
      promiseSectionHeading: promiseSectionHeading,
      disclaimer: disclaimer,
      isDisclaimerActive: isDisclaimerActive,
    };
    setfinalValues(presentStates);   

    Object?.keys(previousStates)?.map((key: any) => {
      if (presentStates[key] !== previousStates[key]) {
        let auditObject = {
          itemAffected: page3Labels[key],
          newValue:
            key === "isDisclaimerActive"
              ? presentStates[key]
                ? "ON"
                : "Off"
              : presentStates[key],
          oldValue:
            key === "isDisclaimerActive"
              ? previousStates[key]
                ? "ON"
                : "Off"
              : previousStates[key],
        };
        auditObjectsArray.push(auditObject);
      }
    });
    setModulesAudited(auditObjectsArray);

    setConfirmDialogOpen(false);

    const requestBody: any = {
      isPublished: true,
      pageType: PageTypeEnum.Promises,
      pageName: "Promises",
      promiseSection: {
        aboutPromises: {
          sectionHeading: aboutPromiseSectionHeading,
          subDescription: aboutPromiseDescription,
          isDisclaimerActive: isDisclaimerActive,
        },
        promiseSectionHeading: promiseSectionHeading,
        disclaimer: disclaimer,
      },
    };

    if (page?.promiseSection?.aboutPromises?.sectionHeading !== null) {
      requestBody["id"] = page.id;
      updatePagePromise(requestBody);
    } else {
      addPagePromise(requestBody);
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderContent = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">Page 3 - Promises</h4>

          <div>
            {!isViewMode ? (
              <>
                <Stack>
                  <Button
                    variant="contained"
                    onClick={handleCancel}
                    // autoFocus
                    disableElevation
                    className="btn btn-light-outline margin-right-15"
                  >
                    Cancel
                  </Button>
                  <LoadingButtonComponent
                    variant="contained"
                    onClick={handlePublish}
                    // autoFocus
                    disableElevation
                    // className="btn btn-dark"
                  >
                    Publish
                  </LoadingButtonComponent>
                </Stack>
              </>
            ) : (
              <LoadingButtonComponent onClick={() => setIsViewMode(false)}>
                Edit
              </LoadingButtonComponent>
            )}
          </div>
        </div>

        <AboutPromisesSection
          heading={aboutPromiseSectionHeading}
          description={aboutPromiseDescription}
          aboutPromiseHeadingError={aboutPromiseHeadingError}
          aboutPromiseDescriptionError={aboutPromiseDescriptionError}
          handleAboutPromiseDescriptionChange={
            handleAboutPromiseDescriptionChange
          }
          handleAboutPromiseSectionHeadingChange={
            handleAboutPromiseSectionHeadingChange
          }
          handleOnBlurAboutPromiseHeading={handleOnBlurAboutPromiseHeading}
          handleOnBlurAboutPromiseDescription={
            handleOnBlurAboutPromiseDescription
          }
          disabledComponent={isViewMode}
        />

        <PromiseSection
          heading={promiseSectionHeading}
          promiseHeadingError={promiseHeadingError}
          handlePromiseSectionHeadingChange={handlePromiseSectionHeadingChange}
          handleOnBlurPromiseHeading={handleOnBlurPromiseHeading}
          disabledComponent={isViewMode}
        />

        <DisclaimerSection
          disclaimer={disclaimer}
          disclaimerError={disclaimerError}
          handleDisclaimerChange={handleDisclaimerChange}
          handleOnBlurDisclaimer={handleOnBlurDisclaimer}
          disabledComponent={isViewMode}
          isDisclaimerActive={isDisclaimerActive}
          setIsDisclaimerActive={setIsDisclaimerActive}
        />
      </Fragment>
    );
  };

  const render = () => {
    if (isLoading === false) {
      return renderContent();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <PageHeading title="Page Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <Container>{render()}</Container>

      {message ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : "Something Went Wrong!"}
          isSuccess={true}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}

      {error ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
      <ConfirmationDialog
        shouldOpen={confirmDialogOpen}
        content="Are you sure you want to Publish?"
        okText="Publish"
        okHandler={() => handleDeleteOkClick()}
        cancelHandler={handleDialogClose}
      />
    </Fragment>
  );
};

export default Page3Promises;
