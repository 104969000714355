import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CancelIcon from "@mui/icons-material/Cancel";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import IconButton from "@mui/material/IconButton";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";
import { Link } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import Search from "@mui/icons-material/Search";
import styles from "./Breadcrumbs.module.css";

interface IBreadcrumbRouteObject {
  name: string;
  route: string;
}

interface IBreadcrumbsProps {
  routes: IBreadcrumbRouteObject[];
  placeholder?: string;
  handleSearchOnKeyPress?: (param: string) => void;
  shouldClearSearchKey?: boolean;
}

const BreadCrumbs = ({
  routes,
  placeholder,
  handleSearchOnKeyPress,
  shouldClearSearchKey = false,
}: IBreadcrumbsProps) => {
  const [searchKey, setSearchKey] = useState("");
  const [isClosebuttonClicked, setisClosebuttonClicked] = useState(false);

  useEffect(() => {
    if (searchKey === "" && isClosebuttonClicked) {
      search();
    }
  }, [searchKey]);

  const search = () => {
    handleSearchOnKeyPress && handleSearchOnKeyPress(searchKey);
  };

  const searchKeyPress = () => {
    search();
    setisClosebuttonClicked(true);
  };

  const handleOnEnterKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      search();
      setisClosebuttonClicked(true);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event?.target.value.trimStart());
    setisClosebuttonClicked(true);
  };

  const clearSearchKey = () => {
    setSearchKey("");
    setisClosebuttonClicked(true);
  };

  useEffect(() => {
    if (shouldClearSearchKey) {
      clearSearchKey();
    }
  }, [shouldClearSearchKey]);

  return (
    <div
      className={styles["breadcrumbs-container"]}
      style={
        routes.length === 1
          ? { paddingTop: 7, paddingBottom: 7 }
          : { paddingTop: 0 }
      }
    >
      <Breadcrumbs
        separator={
          <ChevronRightRoundedIcon
            className={styles["separator"]}
            fontSize="large"
          />
        }
      >
        {routes &&
          routes.length > 0 &&
          routes.map((item, index) => {
            return index < routes.length - 1 ? (
              <Link
                className={styles["breadcrumb-route"]}
                to={item.route}
                key={item.name}
              >
                {item.name}
              </Link>
            ) : (
              <span className={styles["active-breadcrumb"]} key={item.name}>
                {item.name}
              </span>
            );
          })}
      </Breadcrumbs>

      {placeholder ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <OutlinedInput
            placeholder={placeholder}
            onKeyPress={handleOnEnterKeyPress}
            onChange={handleSearchChange}
            value={searchKey}
            size={"small"}
            sx={{ marginRight: 2 }}
            startAdornment={
              <IconButton
                aria-label="search"
                onClick={searchKeyPress}
                onMouseDown={(e) => e.preventDefault()}
              >
                <Search className={styles["search-icon"]} />
              </IconButton>
            }
            endAdornment={
              searchKey.length > 0 ? (
                <IconButton
                  aria-label="cancel"
                  onClick={clearSearchKey}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <CancelIcon className={styles["search-icon"]} />
                </IconButton>
              ) : null
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default BreadCrumbs;
