export const enum DesignationTypeEnum {
  SalesManager = "Sales Manager",
  LandConsultant = "Land Consultant",
  BrokerageManager = "Brokerage Manager",
}

export class DesignationTypeEnumUtils {
  public static getEnumText(type: DesignationTypeEnum): string {
    switch (type) {
      case DesignationTypeEnum.SalesManager:
        return "Sales Manager";
      case DesignationTypeEnum.LandConsultant:
        return "Land Consultant";
      case DesignationTypeEnum.BrokerageManager:
        return "Brokerage Manager";
    }
  }

  public static getDesignationTypeEnums(): {
    label: string;
    value: DesignationTypeEnum;
  }[] {
    const options: { label: string; value: DesignationTypeEnum }[] = [
      {
        label: this.getEnumText(DesignationTypeEnum.SalesManager),
        value: DesignationTypeEnum.SalesManager,
      },
      {
        label: this.getEnumText(DesignationTypeEnum.LandConsultant),
        value: DesignationTypeEnum.LandConsultant,
      },
      {
        label: this.getEnumText(DesignationTypeEnum.BrokerageManager),
        value: DesignationTypeEnum.BrokerageManager,
      },
    ];
    return options;
  }
}
