import * as React from "react";
import { Modal, DialogTitle, Box, IconButton, Typography } from "@mui/material";
import LoginActions from "redux-container/login/LoginRedux";
import {
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import styles from "./ForgotPasswordEmail.module.css";
import { isEmailValid } from "utils/ValidationUtils";
import { useDispatch, useSelector } from "react-redux";
import { ForgotPasswordAction } from "redux-container/forgot-password/ForgotPasswordRedux";
import CloseIcon from "@mui/icons-material/Close";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";

interface IForgotPassword {
  open: boolean;
  onClose: () => void;
}

interface IEmailSentModel {
  open: boolean;
  onOpenSendEmail: () => void;
}

const EmailSentModel = (props: IEmailSentModel) => {
  const dispatch = useDispatch();
  const { isSucessfulSentEmail } = useSelector(
    (state: any) => state.forgotPassword
  );
  const closeSucessModal = () => {
    dispatch(
      ForgotPasswordAction.setForgotPasswordState("isSucessfulSentEmail", false)
    );
  };

  React.useEffect(() => {
    closeSucessModal();
  }, []);

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        onClose={props.onOpenSendEmail}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className={styles["backgroundblur"]}
      >
        <Box className={styles["dialogBox-outer"]}>
          <DialogTitle className={styles["dialog-title"]}>
            <Typography variant="h5" color={"common.black"}>
              Check your mail
            </Typography>
            <IconButton
              edge={"end"}
              component={"span"}
              onClick={props.onOpenSendEmail}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Typography data-testid="sucessful-mail">
            Please click on the recovery link sent to your registered mail - id
          </Typography>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default function ForgotPassword(props: IForgotPassword) {
  const dispatch = useDispatch();
  const { error, isLoading, enteredEmail, isSucessfulSentEmail } = useSelector(
    (state: any) => state.forgotPassword
  );
  const closeEmailModel = () => {
    if (isSucessfulSentEmail) {
      props.onClose();
      setOpenChildModel(true);
    }
    setTimeout(() => {
      dispatch(
        ForgotPasswordAction.setForgotPasswordState(
          "isSucessfulSentEmail",
          false
        )
      );
      // setEmail("");
      dispatch(ForgotPasswordAction.setForgotPasswordState("enteredEmail", ""));
    }, 5000);
  };

  // const [email, setEmail] = React.useState("");
  const [isOpenChildModel, setOpenChildModel] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setEmail(event.target.value.trim());
    dispatch(
      ForgotPasswordAction.setForgotPasswordState(
        "enteredEmail",
        event.target.value.trim()
      )
    );
    setEmailError("");
  };
  const onTouchEmail = () => {
    if (enteredEmail.length === 0) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  React.useEffect(() => {
    closeEmailModel();
  }, [isSucessfulSentEmail]);

  const handleForgetEmailSubmit = () => {
    if (isEmailValid(enteredEmail)) {
      const reqBody = { email: enteredEmail };
      dispatch(ForgotPasswordAction.forgotPasswordEmailSentRequest(reqBody));
    } else {
      setEmailError("Please enter a valid email address");
    }
  };

  const handleCloseChildModel = () => {
    dispatch(
      ForgotPasswordAction.setForgotPasswordState("isSucessfulSentEmail", false)
    );
  };

  const handleForgotPasswordOnKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      handleForgetEmailSubmit();
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className={styles["backgroundblur"]}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className={styles["dialogBox-outer"]}>
          <DialogTitle className={styles["dialog-title"]}>
            <Typography component={"span"} variant="h5" color={"common.black"}>
              Reset Password
            </Typography>
            <IconButton
              edge={"end"}
              component={"span"}
              onClick={() => {
                props.onClose();
                setEmailError("");
                // setEmail("");
                dispatch(
                  ForgotPasswordAction.setForgotPasswordState(
                    "enteredEmail",
                    ""
                  )
                );
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Typography color="grey.400" mb={3}>
            Enter your registered mail-id to receive the link to reset the
            password
          </Typography>
          <TextFieldComponent
            required
            label="Email Address"
            fieldhelpertext={(error?.length && error) || emailError}
            error={error?.length || emailError?.length > 0}
            placeholder="Enter"
            variant="outlined"
            onChange={handleEmailChange}
            onBlur={onTouchEmail}
            name="resetEmail"
            value={enteredEmail}
            onKeyPress={handleForgotPasswordOnKeyPress}
            autoFocus
            focused
          />
          <Box textAlign="center" mt={2}>
            <LoadingButtonComponent
              onClick={handleForgetEmailSubmit}
              disabled={enteredEmail?.length === 0}
              data-testid="password-recovery-mail"
              loading={isLoading}
            >
              Email me a Recovery Link
            </LoadingButtonComponent>
          </Box>
        </Box>
      </Modal>
      <EmailSentModel
        onOpenSendEmail={handleCloseChildModel}
        open={isSucessfulSentEmail}
      />
    </div>
  );
}
