import {
  Avatar,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import QuillEditor from "components/quill-editor/QuillEditor";
import { FieldArray, Form, Formik, getIn, useFormikContext } from "formik";
import { Fragment, useState } from "react";
import styles from "./ProductCategory.module.css";
import * as Yup from "yup";
import { ProductCategoryConstants } from "../../AboutUsPageConstants";
import React from "react";
import { IPage5ProfileInitialValue } from "../../../page-5-profile-IniticalValue";
import MediaAssets from "assets";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { SwitchComponent, TextFieldComponent } from "components/ui-elements";
import ProductCategoryImageMedia from "./ProductCategoryImageMedia";
import { useDispatch, useSelector } from "react-redux";
import { stripHtml } from "utils/ValidationUtils";
import PageManagementCreationAction from "redux-container/page-management/PageManagementRedux";

const actionDispatch = (dispatch: any) => {
  return {
    setPageManagementCreationProfile: (key: any, value: any) =>
      dispatch(
        PageManagementCreationAction.setUpdatePageManagement(key, value)
      ),
  };
};

const ProductCategory = () => {
  const [currentlyOpenedIndex, setCurrentlyOpenedIndex] = useState(999);

  const { isReduxViewMode, page5Data, modulesAudited, productCategoryLength } =
    useSelector((state: any) => state?.pageManagement);
  const { setPageManagementCreationProfile } = actionDispatch(useDispatch());

  const {
    values,
    handleChange,
    setFieldValue,
    setFieldError,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<IPage5ProfileInitialValue>();

  const handleShowImageMedia = (imageIndex: number) => {
    setCurrentlyOpenedIndex(imageIndex);
  };

  const handleTextEditor = (
    name: string,
    newValue: string,
    options: any,
    { setFieldValue, setFieldError }: any
  ) => {
    // if (options?.isError) {
    //   setFieldError(name, "Maximum character exceed");
    // } else {
    setFieldValue(name, newValue);
    // }
  };

  return (
    <Fragment>
      <div className={styles["accordian-global-padding"]}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={12}
            xl={12}
            className={styles["priority-holder"]}
          >
            <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
              <Typography>Product Category</Typography>

              <FormControlLabel
                className="switch-form-label"
                name={"aboutUs.isProductCategoryActive"}
                disabled={isReduxViewMode || false}
                checked={values?.aboutUs?.isProductCategoryActive}
                onChange={handleChange}
                control={<SwitchComponent />}
                label=""
                labelPlacement="start"
              />
              <IconButton>
                <Tooltip title={"Corporate Philosophy"} placement="top" arrow>
                  <Avatar
                    className={styles["Avator"]}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={5} mt={3}>
            <TextFieldComponent
              required
              label="Section Heading"
              placeholder="Enter"
              name={`aboutUs.productCategory.sectionHeading`}
              disabled={isReduxViewMode || false}
              inputProps={{
                maxLength:
                  ProductCategoryConstants.sectionHeadingCharacterLimit,
              }}
              value={values?.aboutUs?.productCategory?.sectionHeading}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors?.aboutUs?.productCategory?.sectionHeading &&
                touched?.aboutUs?.productCategory?.sectionHeading
                  ? true
                  : false
              }
              fieldhelpertext={
                errors?.aboutUs?.productCategory?.sectionHeading &&
                touched?.aboutUs?.productCategory?.sectionHeading &&
                errors?.aboutUs?.productCategory?.sectionHeading
              }
            />
          </Grid>
        </Grid>
      </div>

      <Divider />
      <FieldArray
        name="aboutUs.productCategory.detailedInformation"
        render={(arrayHelpers) => (
          <div>
            {values?.aboutUs?.productCategory?.detailedInformation?.length >
              0 &&
              values?.aboutUs?.productCategory?.detailedInformation?.map(
                (element, index: any) => (
                  <React.Fragment>
                    <Divider />
                    <div className={styles["accordian-global-padding"]}>
                      <Stack justifyContent={"flex-end"}>
                        {values?.aboutUs?.productCategory?.detailedInformation
                          ?.length > 1 &&
                          !isReduxViewMode && (
                            <IconButton
                              aria-label="delete"
                              data-testid="button-delete"
                              onClick={() => {
                                arrayHelpers.remove(index);

                                let localPage5Data =
                                  page5Data?.aboutUs?.productCategory
                                    ?.detailedInformation;

                                localPage5Data.splice(index);
                                setPageManagementCreationProfile("page5Date", {
                                  ...page5Data,
                                  aboutUs: {
                                    ...page5Data?.aboutUs,
                                    productCategory: {
                                      ...page5Data?.aboutUs?.productCategory,
                                      detailedInformation: localPage5Data,
                                    },
                                  },
                                });

                                if (
                                  productCategoryLength >
                                  values?.aboutUs?.productCategory
                                    ?.detailedInformation?.length -
                                    1
                                ) {
                                  setPageManagementCreationProfile(
                                    "modulesAudited",
                                    [
                                      ...modulesAudited,
                                      {
                                        itemAffected: `Product Category ${
                                          index + 1
                                        } Deleted`,
                                        newValue: "",
                                        oldValue: "",
                                      },
                                    ]
                                  );
                                }
                              }}
                            >
                              <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                            </IconButton>
                          )}
                      </Stack>
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <TextFieldComponent
                            required
                            label="Display Name"
                            placeholder="Enter"
                            name={`aboutUs.productCategory.detailedInformation[${index}].displayName`}
                            disabled={isReduxViewMode || false}
                            inputProps={{
                              maxLength:
                                ProductCategoryConstants.displayNameCharactersLimit,
                            }}
                            value={element?.displayName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(
                                errors,
                                `aboutUs.productCategory.detailedInformation[${index}].displayName`
                              ) &&
                                getIn(
                                  touched,
                                  `aboutUs.productCategory.detailedInformation[${index}].displayName`
                                )
                            )}
                            fieldhelpertext={
                              getIn(
                                touched,
                                `aboutUs.productCategory.detailedInformation[${index}].displayName`
                              ) &&
                              getIn(
                                errors,
                                `aboutUs.productCategory.detailedInformation[${index}].displayName`
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <div className={styles["accordian-global-padding"]}>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3} md={3} lg={4} xl={3.5}>
                          <Typography
                            className={styles["Upload-heading-padding"]}
                          >
                            Upload Icon
                          </Typography>
                          {/* imageMedia */}
                          <ProductCategoryImageMedia
                            index={index}
                            currentlyOpenedIndex={currentlyOpenedIndex}
                            handleShowImageMedia={handleShowImageMedia}
                          />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8.5}>
                          <Typography
                            className={styles["Upload-heading-padding"]}
                          >
                            Description
                          </Typography>
                          <QuillEditor
                            placeholder="Enter description"
                            name={`aboutUs.productCategory.detailedInformation[${index}].description`}
                            className={styles["QuillEditor"]}
                            onChange={(
                              name: string,
                              newValue: string,
                              options: any
                            ) =>
                              handleTextEditor(name, newValue, options, {
                                setFieldValue,
                                setFieldError,
                              })
                            }
                            inputProps={{
                              maxLength: ProductCategoryConstants.description,
                            }}
                            value={element?.description}
                            style={
                             { height: "12.3rem" }
                            }
                            disabled={isReduxViewMode}
                            fieldhelpertext={
                              values?.aboutUs?.isProductCategoryActive ===
                                true &&
                              stripHtml(
                                values?.aboutUs?.productCategory
                                  ?.detailedInformation[index]?.description
                              ).length === 0 &&
                              "Required"
                            }
                            error={Boolean(
                              getIn(
                                errors,
                                "aboutUs.productCategory.detailedInformation[${index}].description"
                              ) &&
                                getIn(
                                  touched,
                                  "aboutUs.productCategory.detailedInformation[${index}].description"
                                )
                            )}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    {index ===
                    values?.aboutUs?.productCategory?.detailedInformation
                      ?.length -
                      1 ? (
                      <div className={styles["accordian-global-padding"]}>
                        <Grid container>
                          <Grid item>
                            {!isReduxViewMode && (
                              <Link
                                underline="always"
                                component="button"
                                onClick={() => {
                                  arrayHelpers.push({
                                    displayName: "",
                                    media: {
                                      key: "",
                                      name: "",
                                      value: {
                                        url: "",
                                        size: 0,
                                        width: 320,
                                        height: 240,
                                        mediaType: "image",
                                      },
                                    },
                                    description: "",
                                  });

                                  let localPage5Data =
                                    page5Data?.aboutUs?.productCategory
                                      ?.detailedInformation;

                                  localPage5Data.push({
                                    displayName: "",
                                    media: {
                                      key: "",
                                      name: "",
                                      value: {
                                        url: "",
                                        size: 0,
                                        width: 320,
                                        height: 240,
                                        mediaType: "image",
                                      },
                                    },
                                    description: "",
                                  });
                                  setPageManagementCreationProfile(
                                    "page5Date",
                                    {
                                      ...page5Data,
                                      aboutUs: {
                                        ...page5Data?.aboutUs,
                                        productCategory: {
                                          ...page5Data?.aboutUs
                                            ?.productCategory,
                                          detailedInformation: localPage5Data,
                                        },
                                      },
                                    }
                                  );

                                  if (
                                    productCategoryLength <
                                    values?.aboutUs?.productCategory
                                      ?.detailedInformation?.length +
                                      1
                                  ) {
                                    setPageManagementCreationProfile(
                                      "modulesAudited",
                                      [
                                        ...modulesAudited,
                                        {
                                          itemAffected: `Product Category ${
                                            index + 1 + 1
                                          } Added`,
                                          newValue: "",
                                          oldValue: "",
                                        },
                                      ]
                                    );
                                  }
                                }}
                              >
                                + Add more
                              </Link>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </React.Fragment>
                )
              )}

            {/* <Divider />

            <div className={styles["accordian-global-padding"]}>
              <Grid className={styles["button-position"]} mb={2}>
                <Button variant="contained" type="submit">
                  Save
                </Button>
              </Grid>
            </div> */}
          </div>
        )}
      />
    </Fragment>
  );
};

export default ProductCategory;
