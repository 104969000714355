import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { AppUpdateActions } from "redux-container/notification-management/AppUpdatesRedux";

export function* getAppUpdates(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAppUpdates, action.params);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(AppUpdateActions.getAppUpdatesSuccess(response.data));
    } else {
      yield put(AppUpdateActions.getAppUpdatesFailure(response.data));
    }
  } catch (error) {
    yield put(AppUpdateActions.getAppUpdatesFailure(error));
  }
}

export function* addAppUpdate(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addAppUpdate, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(AppUpdateActions.addAppUpdateSuccess(response.data));
    } else {
      yield put(AppUpdateActions.addAppUpdateFailure(response.data));
    }
  } catch (error) {
    yield put(AppUpdateActions.addAppUpdateFailure(error));
  }
}
