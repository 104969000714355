import { Box, MenuList, Paper, Popper, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import CategoryBasedFaqList from "./CategoryBasedFaqList";
import ProjectFaqCreation from "./ProjectFaqCreation";
import { IconButton, ClickAwayListener } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Typography } from "@mui/material";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { MenuItem } from "@mui/material";
import { StatusTypeEnum } from "enumerations/StatusTypeEnum";
import { useState } from "react";
import { projectCategoryForFaqAction } from "redux-container/project-management/ProjectCategoryForFaqCreationRedux";
import { useDispatch, useSelector } from "react-redux";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";
import { faqRoutePathConst } from "pages/resource-management/faq/faqRoutePathConst";
import { RouteConstants } from "routes/RouteConstants";
import { Form, Formik, useFormikContext } from "formik";
import { FAQInitialValues } from "pages/resource-management/faq/faq-creation/FAQInitialValues";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { FAQSchemaValidation } from "pages/resource-management/faq/faq-creation/FAQSchemaValidation";
import { FaqsTooltip } from "pages/resource-management/faq/faq-creation/FAQCreation";
import {
  ButtonComponent,
  LoadingButtonComponent,
} from "components/ui-elements";
import { GetProjectCategoriesAction } from "redux-container/project-management/ProjectCategoryRedux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { IInitinalValueProjectContent } from "../../InitinalValuesProjectContent";
import { Constants } from "constants/Constants";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { FaqsTypeEnum } from "enumerations/FaqsTypes";
import { AllProjectFaqsAction } from "redux-container/project-management/ProjectAllFaqsRedux";

const actionDispatch = (dispatch: any) => {
  return {
    projectCategoryforFaq: (param: any) =>
      dispatch(
        projectCategoryForFaqAction.projectCategoryForFaqCreationRequest(param)
      ),
    addProjectCategoryforFaq: (data: any) =>
      dispatch(
        projectCategoryForFaqAction.addProjectCategoryForFaqCreationRequest(
          data
        )
      ),
    setProjectCategoryForFaqCreationState: (key: any, value: any) =>
      dispatch(
        projectCategoryForFaqAction.setProjectCategoryForFaqCreationState(
          key,
          value
        )
      ),
    setProjectFaqState: (key: any, value: any) =>
      dispatch(ProjectFaqActions.setProjectFaqState(key, value)),
    projectFaqCreationRequest: (data: any) =>
      dispatch(ProjectFaqActions.projectFaqCreationRequest(data)),
    setProjectCategoriesState: (key: any, data: any) =>
      dispatch(GetProjectCategoriesAction.setProjectCategoriesState(key, data)),
    bulkStatusChangeCategoryForFaqCreation: (data: any) =>
      dispatch(
        projectCategoryForFaqAction.bulkStatusProjectCategoryForFaqCreationRequest(
          data
        )
      ),
    deleteProjectCategoryforFaq: (data: any) =>
      dispatch(
        projectCategoryForFaqAction.deleteProjectCategoryForFaqCreationRequest(
          data
        )
      ),
    getProjectFaqs: (params: any) =>
      dispatch(ProjectFaqActions.getAllProjectFaqsRequest(params)),

    setALLProjectFaqState: (key: any, value: any) =>
      dispatch(AllProjectFaqsAction.setALLProjectFaqState(key, value)),
  };
};

export default function FaqCreationAndEdit() {
  const {
    projectCategoryforFaq,
    setProjectCategoryForFaqCreationState,
    projectFaqCreationRequest,
    setProjectFaqState,
    setProjectCategoriesState,
    bulkStatusChangeCategoryForFaqCreation,
    deleteProjectCategoryforFaq,
    getProjectFaqs,
    setALLProjectFaqState,
  } = actionDispatch(useDispatch());
  const {
    isSuccessProjectFaqCategories,
    projectFaqCategories,
    responseProjectFaqCategoryData,
    projectRouteToPageCategory,
  } = useSelector((state: any) => state.projectFaqsCategories);
  const { projectFaqResponse, routeProjectToPage } = useSelector(
    (state: any) => state.projectFaqs
  );
  const projectGetFaqs = useSelector(
    (state: any) => state?.projectContentManagement?.projectContentsAndFaqs
  );

  const location: any = useLocation();
  const navigate: any = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const { mappedCategoryArray } = useSelector(
    (state: any) => state?.projectCategories
  );
  const [isEditable, setEditable] = useState(false);
  const [isConfirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
    useState(false);
  const { projectFaqs, mappedFaqs, isLoading } = useSelector(
    (state: any) => state?.projectFaqs
  );
  const faqStatus = projectFaqs?.find(
    (faq: any) => faq?.id === location?.state?.id
  );

  const categoryStatus = projectFaqCategories.find((projectCategory: any) => {
    if (location?.state?.categoryId) {
      return location.state?.categoryId === projectCategory.id;
    } else {
      return location.state?.category?.id === projectCategory.id;
    }
  });

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    projectCategoryforFaq("2002");
  }, []);

  useEffect(() => {
    if (isSuccessProjectFaqCategories) {
      projectCategoryforFaq("2002");
      setProjectCategoryForFaqCreationState(
        "isSuccessProjectFaqCategories",
        false
      );
    }
  }, [isSuccessProjectFaqCategories]);

  const handleEditable = () => {
    setEditable(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const onSubmitHandler = (values: any) => {
    const individualCategoryObject = projectFaqCategories?.find(
      (item: any) => item.name === values.categoryId
    );
    const reqBody = {
      typeOfFAQ: FaqsTypeEnum.ProjectFaq,
      categoryId: individualCategoryObject.id,
      faqs: values.faqs,
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    projectFaqCreationRequest(reqBody);
    const addedNewCategory = mappedCategoryArray;
    addedNewCategory.push(individualCategoryObject.id);
    setProjectCategoriesState("mappedCategoryArray", addedNewCategory);

    const getCategoryParams = {
      typeOfFAQ: FaqsTypeEnum.ProjectFaq,
      shouldDisplayMappedFaqs: false,
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    getProjectFaqs(getCategoryParams);
  };

  const popOverComponent = (event: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
  };
  const handleCloseConfirmDialog = () => {
    setConfirmDeleteDialogOpen(false);
  };
  const handleOpenConfirmDialog = () => {
    setConfirmDeleteDialogOpen(true);
  };

  const deleteCategory = () => {
    deleteProjectCategoryforFaq(categoryStatus.id);

    categoryStatus?.faqs?.map(async (faq: any) => {
      const faqStatus = await projectFaqs?.find(
        (faq: any) => faq?.id === faq?.id
      );
      if (faqStatus.isMappedToggle === true) {
        const removeFalseFaqID = mappedFaqs.filter((id: any) => {
          return id !== faq?.id;
        });
        setALLProjectFaqState("isFaqAddOrRemoved", true);
        setProjectFaqState("mappedFaqs", removeFalseFaqID);
        setALLProjectFaqState("filterAllfaqBasedGivenArray", removeFalseFaqID);
      }
    });

    setConfirmDeleteDialogOpen(false);
  };
  const handleMarkStatus = () => {
    if (categoryStatus.status === StatusTypeEnum.Active) {
      const reqBody = {
        status: StatusTypeEnum.Inactive,
        categoryIds: [categoryStatus.id],
        shouldValidateAdmin:
          loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
      };

      bulkStatusChangeCategoryForFaqCreation(reqBody);
    } else {
      const reqBody = {
        status: StatusTypeEnum.Active,
        categoryIds: [categoryStatus.id],
        shouldValidateAdmin:
          loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
      };

      bulkStatusChangeCategoryForFaqCreation(reqBody);
    }
  };

  // redirecting the pages

  const handleCloseFlashMessageDialog = (routeToPage: any) => {
    setProjectCategoryForFaqCreationState(
      "responseProjectFaqCategoryData",
      null
    );
    setProjectFaqState("projectFaqResponse", null);
    if (routeToPage === faqRoutePathConst.CategoryTablePage) {
      navigate(RouteConstants.projectCreation, {
        state: { ...location.state },
      });
    }
    if (routeToPage === faqRoutePathConst.FaqListTablePage) {
      navigate(
        `${RouteConstants.projectCreation}/${RouteConstants.projectFaqCreation}`,
        {
          state: {
            ...location.state,
            categoryId: categoryStatus.id,
            isFaqCreation: false,
            isFaqTable: true,
          },
        }
      );
    }
    if (
      routeToPage === faqRoutePathConst.CategoryDeleteFormList &&
      location?.state?.isFaqTable
    ) {
      navigate(RouteConstants.projectCreation, {
        state: { ...location.state },
      });
    }
  };

  const editInitialState = () => {
    return {
      categoryId: "",
      isCategoryField: false,
      faqs: [
        {
          faqQuestion: {
            question: location?.state?.faqQuestion?.question,
          },
          faqAnswer: {
            answer: location?.state?.faqAnswer?.answer,
          },
          isEdit: false,
          faqId: location?.state?.id,
        },
      ],
    };
  };

  const { values: formimValues, setFieldValue: formikSetFieldValue } =
    useFormikContext<IInitinalValueProjectContent>();

  const handleNavigateBack = () => {
    const faqCreationPath = `${RouteConstants.projectCreation}/${RouteConstants.projectFaqCreation}`;
    if (location?.state?.categoryId && location?.state?.isFaqCreation) {
      navigate(faqCreationPath, {
        state: {
          ...location.state,
          isFaqTable: true,
          isFaqCreation: false,
          categoryId: location?.state?.category?.id,
          isViewMode: false,
          isOnlyView: false,
        },
      });
    } else {
      navigate(RouteConstants.projectCreation, {
        state: { ...location.state },
      });
    }
  };

  return (
    <Box p={"1rem"}>
      <Formik
        initialValues={
          location?.state?.isFaqTable ? editInitialState() : FAQInitialValues
        }
        onSubmit={onSubmitHandler}
        validationSchema={FAQSchemaValidation}
        enableReinitialize={true}
      >
        {({ values, handleChange, isValid, dirty, resetForm }) => (
          <Form>
            <Stack mt={"1rem"}>
              <Box>
                <Stack>
                  <IconButton onClick={() => handleNavigateBack()}>
                    <ArrowBackIosIcon fontSize="small" />
                  </IconButton>
                  <Typography color={"secondary.dark"} variant="h6">
                    {`${
                      location?.state?.isFaqTable
                        ? ` ${categoryStatus?.name} FAQs`
                        : "Create New"
                    }`}
                  </Typography>
                  {location?.state?.isFaqTable ? (
                    <IconButton
                      disabled={location?.state?.isOnlyView && !isEditable}
                    >
                      <SettingsOutlinedIcon
                        fontSize="medium"
                        onClick={(event) => popOverComponent(event)}
                      />
                    </IconButton>
                  ) : (
                    <Tooltip
                      title={<FaqsTooltip />}
                      arrow
                      placement="top-start"
                    >
                      <IconButton>
                        <InfoOutlinedIcon
                          fontSize="small"
                          className="infooutlined-alignment"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Box>

              {location?.state?.isFaqTable ? null : (
                <Stack direction={"row"} columnGap={2}>
                  <ButtonComponent
                    color="inherit"
                    onClick={() => {
                      resetForm();
                      handleNavigateBack();
                    }}
                  >
                    Cancel
                  </ButtonComponent>
                  {/* <ButtonComponent disabled>
                      Save as Draft
                    </ButtonComponent> */}
                  <LoadingButtonComponent
                    onClick={() => onSubmitHandler(values)}
                    // loading={isLoading}
                    loadingPosition="start"
                    disabled={!isValid || !dirty}
                  >
                    Publish
                  </LoadingButtonComponent>
                </Stack>
              )}
            </Stack>
            {location.state.isFaqCreation ? (
              <ProjectFaqCreation
                isEditable={isEditable}
                handleEditable={handleEditable}
                formikValues={formimValues}
                formikSetFieldValue={formikSetFieldValue}
              />
            ) : null}
            {location.state.isFaqTable ? (
              <CategoryBasedFaqList
                isEditable={isEditable}
                handleEditable={handleEditable}
              />
            ) : null}
          </Form>
        )}
      </Formik>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Popper open={open} placement="right-start" anchorEl={anchor}>
          <Paper elevation={12}>
            <MenuList>
              <MenuItem sx={{ bgcolor: "white" }} onClick={handleMarkStatus}>
                {`${
                  categoryStatus?.status === StatusTypeEnum.Active
                    ? "Mark as Inactive"
                    : "Mark as Active"
                }`}
              </MenuItem>
              <MenuItem
                sx={{ bgcolor: "white" }}
                onClick={handleOpenConfirmDialog}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Paper>
        </Popper>
      </ClickAwayListener>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Category(s)?"
        content={`This action will delete this category and related FAQs permanently from database and cannot be reverted . Are you sure you want to continue?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteCategory}
      />
      {responseProjectFaqCategoryData ? (
        <FlashMessageDialog
          shouldOpen={responseProjectFaqCategoryData ? true : false}
          content={
            responseProjectFaqCategoryData?.code === 200
              ? responseProjectFaqCategoryData?.message
              : responseProjectFaqCategoryData?.message
          }
          isSuccess={
            responseProjectFaqCategoryData?.code === 200 ? true : false
          }
          cancelHandler={() =>
            handleCloseFlashMessageDialog(projectRouteToPageCategory)
          }
        />
      ) : null}

      {projectFaqResponse ? (
        <FlashMessageDialog
          shouldOpen={projectFaqResponse ? true : false}
          content={
            projectFaqResponse?.code === 200
              ? projectFaqResponse?.message
              : projectFaqResponse?.message
          }
          isSuccess={projectFaqResponse?.code === 200 ? true : false}
          cancelHandler={() =>
            handleCloseFlashMessageDialog(routeProjectToPage)
          }
        />
      ) : null}
    </Box>
  );
}
