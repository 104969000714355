import { MediaUploadComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";

interface IProjectAmenitiesMedia {
  index?: any;
  currentlyOpenedIndex?: any;
  handleProjectAmenitiesMedia: (imageIndex: number) => void;
}

function ProjectAmenitiesMedia({
  index,
  currentlyOpenedIndex,
  handleProjectAmenitiesMedia,
}: IProjectAmenitiesMedia) {
  const { values, handleChange, setFieldValue, handleBlur, errors, touched } =
    useFormikContext<IInitinalValueProjectContent>();

  const { imageDetails, isLoading, error, isSuccess, isDelete } = useSelector(
    (state: any) => state.fileUpload
  );
  const dispatch = useDispatch();

  const [projectAmenitiesMediaValue, setProjectAmenitiesMediaValue] = useState<
    any[]
  >([]);

  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));

  //upload Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const projectAmenitiesImageUploadSuccess = `opportunityDoc.projectAminities.${index}.icon.value.url`;
      if (
        projectAmenitiesMediaValue[0] === projectAmenitiesImageUploadSuccess &&
        index === currentlyOpenedIndex
      ) {
        setFieldValue(projectAmenitiesImageUploadSuccess, imageDetails?.url);
        handleProjectAmenitiesMedia(999);
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  //delete Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const projectAmenitiesImageUploadDelete = `opportunityDoc.projectAminities.${index}.icon.value.url`;
      if (
        projectAmenitiesImageUploadDelete === projectAmenitiesMediaValue[0] &&
        index === currentlyOpenedIndex
      ) {
        const empty = "";
        setFieldValue(projectAmenitiesImageUploadDelete, empty);
      }
    }
    getAllInsightsCreationFunction();
  }, [isDelete]);

  const handleuploadImage = (event: any) => {
    const file = event?.target?.files[0];
    const MediaId = event?.target?.id;

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
        setProjectAmenitiesMediaValue((prevState) => [...prevState, MediaId]);
      };
      handleProjectAmenitiesMedia(index);
      reader.readAsArrayBuffer(file);
      const currentInfraStoryMediaName = `opportunityDoc.projectAminities.${index}.icon.name`;
      const currentInfraStoryMediaKey = `opportunityDoc.projectAminities.${index}.icon.key`;
      setFieldValue(currentInfraStoryMediaKey, file?.name);
      setFieldValue(currentInfraStoryMediaName, file?.name);
    }
  };

  const handleConfirmDelete = () => {
    let fileName = values?.opportunityDoc?.projectAminities[index].icon?.name;
    let fileUrl = `opportunityDoc.projectAminities.${index}.icon.value.url`;
    setProjectAmenitiesMediaValue((prevState) => [...prevState, fileUrl]);
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
  };

  return (
    <MediaUploadComponent
      ondrop={handleChange}
      loading={isLoading && index === currentlyOpenedIndex}
      name={`opportunityDoc.projectAminities.${index}.icon.value.url`}
      value={values?.opportunityDoc?.projectAminities[index].icon?.value?.url}
      onChange={(event: any) => {
        handleuploadImage(event);
      }}
      previewimage={
        values?.opportunityDoc?.projectAminities[index].icon?.value?.url
      }
      cancelimage={() => {
        handleProjectAmenitiesMedia(index);
        handleConfirmDelete();
      }}
      onBlur={handleBlur}
      fieldhelpertext={
        getIn(
          touched,
          `opportunityDoc.projectAminities.${index}.icon.value.url`
        ) &&
        getIn(errors, `opportunityDoc.projectAminities.${index}.icon.value.url`)
      }
      disabled={isViewMode}
    />
  );
}

export default ProjectAmenitiesMedia;
