import {
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  TablePagination,
  Grid,
  Typography,
  FormControl,
  Button,
  MenuItem,
  Avatar,
  ClickAwayListener,
  Popper,
  Box,
  styled,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { Fragment, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import { GenealFaqActions } from "redux-container/faq/GeneralFaqRedux";
import { useDispatch, useSelector } from "react-redux";
import { state } from "../../../../utils/States";
import { FaqActions } from "redux-container/faq/FaqRedux";
import Divider from "@mui/material/Divider";
import {
  ActionTypeEnum,
  ActionTypeEnumInsight,
  ActionTypeEnumUtils,
  ActionTypeEnumUtilsInsight,
} from "enumerations/ActionTypeEnum";
import { exportAsExcel } from "utils/ExportFileUtils";
import { FaqStatusTypeEnum } from "../faq-list/FAQListConstant";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import SwitchComponent from "../../../../components/ui-elements/switch/SwitchComponent";
import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import MediaAssets from "../../../../assets/index";
import Loader from "components/loader/Loader";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { format, compareAsc } from "date-fns";
import { useFormikContext } from "formik";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import axios from "axios";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
// import { StatusTypeEnumUtilsFaq, StatusTypeEnumUtilsInsight } from "enumerations/StatusTypeEnum";
export const ReadmoreDialogWrapper = styled(Box)`
  && .MuiPaper-root {
    overflow-wrap: break-word;
    width: 200px;
    /* margin-left: 50px; */
  }
`;
const tableHeadings = [
  { heading: "Question" },
  { heading: `Created On` },
  { heading: "Published On" },
  { heading: "Modified On" },
  { heading: "Last Modified By" },
  { heading: "Priority" },
  { heading: "Status" },
  { heading: "Action" },
];

const actionDispatch = (dispatch: any) => {
  return {
    getAllFaqs: (data: any) =>
      dispatch(GenealFaqActions.getAllFaqsRequest(data)),
    deleteFaqs: (id: any) => dispatch(GenealFaqActions.faqDeleteRequest(id)),
    getFaqs: (params: any) => dispatch(FaqActions.faqRequest(params)),
    setFaqState: (key: any, data: any) =>
      dispatch(GenealFaqActions.setFaqState(key, data)),
    bulkFaqsDelete: (data: any) =>
      dispatch(GenealFaqActions.faqBulkDeleteRequest(data)),
    bulkFaqsUpdate: (data: any) =>
      dispatch(GenealFaqActions.faqBulkUpdateRequest(data)),
    faqPriority: (data: any) =>
      dispatch(GenealFaqActions.faqPriorityUpdateRequest(data)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
  };
};

export default function FAQPublishedList(props: any) {
  const {
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    isValid,
    dirty,
    errors,
    resetForm,
  } = useFormikContext();
  const {
    isEditLocale,
    settingIconDisable,
    searchKey,
    isNavigationFromSidemenu,
  } = props;
  const location: any = useLocation();

  const navigate: any = useNavigate();
  const {
    getAllFaqs,
    getFaqs,
    setFaqState,
    deleteFaqs,
    bulkFaqsDelete,
    bulkFaqsUpdate,
    faqPriority,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());
  const users = useSelector((state: any) => state?.users.usersForFilters);
  const faqData = useSelector((state: any) => state?.faq);
  const categories = useSelector((state: any) => state?.faqList?.faqs);

  const isLoading = useSelector((state: any) => state?.faq.isLoading);
  const { isSuccessfulUpdated } = useSelector(
    (state: any) => state?.generalFaq
  );
  const shouldAudit = useSelector(
    (state: any) => state?.generalFaq?.shouldAudit
  );
  const { page, rowsPerPage, modules } = useSelector((state: any) => {
    return {
      page: state?.faq?.page,
      rowsPerPage: state?.faq?.rowsPerPage,
      modules: state?.rolesAndModules?.module,
    };
  });
  const actions = ActionTypeEnumUtilsInsight.getActionTypeEnumsInsight();
  // const statuses = StatusTypeEnumUtilsFaq.getStatusTypeEnumsCategory();

  //all state
  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [createdBy, setCreatedBy] = useState<any>(null);
  const [publishedBy, setPublishedBy] = useState<any>(null);
  const [modifiedBy, setModifiedBy] = useState<any>(null);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [isSortByPriority, setSortByPriority] = useState(true);
  const [anchor, setAnchor] = useState(null);
  const [question, setQuestion] = useState("");
  const [sortingPriority, setSortingPriority] = useState(0);
  const [sortingKey, setSortingKey] = useState(1);
  const [isCreatedBySort, setCreatedBySort] = useState(true);
  const [isPublishedBySort, setPublishedBySort] = useState(true);
  const [isModifiedBySort, setModifiedBySort] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [ipAddress, setIpAddress] = useState<any>(null);
  const [modulesAudited, setModulesAudited] = useState<any>(null);

  //all useEffect

  useEffect(() => {
    if (location?.state?.statusType?.length > 0) {
      selectedStatusDashBoard();
      const params = {
        index: page + 1,
        size: rowsPerPage,
        createdBy: createdBy?.id || publishedBy?.id,
        categoryId: location?.state?.categoryId || location.state?.category?.id,
        updatedBy: modifiedBy?.id,
        byPrority: isSortByPriority,
        sortingPriority: sortingPriority,
        sortingKey: sortingKey,
        shouldDisplayMappedFaqs: false,
        status:
          location?.state?.statusType?.length > 0
            ? location?.state?.statusType === "Published"
              ? true
              : false
            : selectedStatusForFaq(selectedStatus),
      };
      getFaqs(params);
    }
  }, []);

  const selectedStatusDashBoard = () => {
    if (location?.state?.statusType.length > 0) {
      setSelectedStatus(location?.state?.statusType);
    }
  };

  useEffect(() => {
    if (
      location?.state?.statusType?.length === 0 ||
      location?.state?.statusType === undefined
    ) {
      const params = {
        index: page + 1,
        size: rowsPerPage,
        createdBy: createdBy?.id || publishedBy?.id,
        categoryId: location?.state?.categoryId || location.state?.category?.id,
        updatedBy: modifiedBy?.id,
        byPrority: isSortByPriority,
        sortingPriority: sortingPriority,
        sortingKey: sortingKey,
        shouldDisplayMappedFaqs: false,
        status: selectedStatusForFaq(selectedStatus),
        searchKey: searchKey,
      };
      getFaqs(params);
    }
  }, [
    createdBy,
    publishedBy,
    modifiedBy,
    isSortByPriority,
    rowsPerPage,
    page,
    sortingPriority,
    sortingKey,
    location?.state.categoryId,
    location?.state.categoryId,
    selectedStatus,
    searchKey,
  ]);

  const selectedStatusForFaq = (selectedStatus: any) => {
    if (selectedStatus === "Published") {
      return true;
    }
    if (selectedStatus === "Inactive") {
      return false;
    }
  };

  useEffect(() => {
    if (isSuccessfulUpdated) {
      const params = {
        index: page + 1,
        size: rowsPerPage,
        createdBy: createdBy?.id || publishedBy?.id,
        categoryId: location?.state?.categoryId || location.state?.category?.id,
        updatedBy: modifiedBy?.id,
        byPrority: isSortByPriority,
        sortingPriority: sortingPriority,
        sortingKey: sortingKey,
        shouldDisplayMappedFaqs: false,
      };
      getFaqs(params);
      setSelectedAction("");
      setFaqState("isSuccessfulUpdated", false);
    }
  }, [isSuccessfulUpdated]);

  useEffect(() => {
    if (isNavigationFromSidemenu) {
      setFaqState("page", 0);
      setFaqState("rowsPerPage", 10);
    }

    return () => {};
  }, []);

  useEffect(() => {
    getIpAddress();
    return () => {};
  }, []);

  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIpAddress(res.data.IPv4);
  };

  useEffect(() => {
    if (shouldAudit) {
      const moduleObject: any = modules?.find((element: any) => {
        return element?.name === "Resources Management";
      });
      const postAuditRequestbody = {
        ipAddress: ipAddress, //ipAddress
        moduleId: moduleObject?.id,
        route: null,
        auditedObject: null,
        modulesAudited: modulesAudited,
        status: null,
      };
      postAuditLogRequest(postAuditRequestbody);

      setFaqState("shouldAudit", false);
    }
  }, [shouldAudit]);

  const statusAuditedFaqs = () => {
    let auditedFaqs: any[] = [];
    faqData?.faqs?.map((faq: any) => {
      selected?.map((faqId: any) => {
        if (faqId === faq.id) {
          auditedFaqs.push(faq);
        }
      });
    });
    return auditedFaqs;
  };

  //handleChanges for filters
  const handleActionChange = (event: SelectChangeEvent) => {
    // setSelectedAction(event.target.value as string);
    const actionSelected = event.target.value;
    switch (Number(actionSelected)) {
      case ActionTypeEnumInsight.Export:
        handleExportAsExcel();
        break;
      case ActionTypeEnumInsight.Delete:
        deleteSelectedFaqs();
        break;
      case ActionTypeEnumInsight.MarkAsPublished:
        changeFaqStatus("true");
        break;
      case ActionTypeEnumInsight.MarkAsInactive:
        changeFaqStatus("false");
        break;
    }
  };
  //clearning filter

  const clearFilters = () => {
    setPublishedBy(null);
    setCreatedBy(null);
    setModifiedBy(null);
    setSelectedStatus("");
    navigate(location.pathname, {
      state: {
        isEditField: true,
        categoryId: location?.state?.categoryId
          ? location?.state?.categoryId
          : location.state?.category?.id,
      },
    });
  };
  // excel extraction
  const handleExportAsExcel = () => {
    if (selected.length === 0) {
      alert("You have not selected any FAQ");
      return;
    }
    let exportingFaqs: any = [];
    if (faqData?.faqs.length) {
      selected.map((selectedFaq: any) => {
        let localSelectedItem = faqData?.faqs.find(
          (item: any) => item.id == selectedFaq
        );
        exportingFaqs.push({
          Question: localSelectedItem?.faqQuestion?.question,
          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Published_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Modified_On: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Last_Modified_by: localSelectedItem?.faqsUpdatedAdmin?.firstName
            ? localSelectedItem?.faqsUpdatedAdmin?.firstName
            : "----",
          Priority: localSelectedItem?.priority,
          Status: localSelectedItem?.status === true ? `Published` : "Inactive",
        });
      });
    }

    exportAsExcel(exportingFaqs, "Faqs", "Sheet1");
  };
  // selected Deleted
  const deleteSelectedFaqs = () => {
    const selectedFaqs = {
      selectedFaqs: selected,
    };
    if (selected.length === 0) {
      alert("You have not selected any Faqs");
      return;
    }
    handleCloseLogoutDialog();
  };
  // delete dialog openFaqEditDialog
  const handleCloseLogoutDialog = () => {
    setIsConfirmDeleteDialogOpen(!isConfirmDeleteDialogOpen);
  };
  const deleteFaqsOnSelect = () => {
    const reqBody = {
      faqIds: selected,
    };

    let auditedFaqs = statusAuditedFaqs();
    let auditObjectsArray: any[] = [];
    auditedFaqs?.map((faq: any) => {
      let auditObject = {
        itemAffected: `Faq '${faq?.faqQuestion?.question}' Of Category '${
          categories?.find((category: any) => category?.id === faq?.categoryId)
            .name
        }' Deleted.(ID ${faq?.id})`,
        newValue: "",
        oldValue: "",
        auditedObject: null,
        route: null,
        status: AuditStatus.DELETED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    bulkFaqsDelete(reqBody);
    setIsConfirmDeleteDialogOpen(false);
    setSelected([]);
  };
  //serach keypress

  // changeStatus
  const changeFaqStatus = (status: any) => {
    if (selected.length === 0) {
      alert("You have not selected any Faqs");
      return;
    }
    let auditedFaqs = statusAuditedFaqs();
    let auditObjectsArray: any[] = [];
    auditedFaqs?.map((faq: any) => {
      let auditObject = {
        itemAffected: `Faq '${faq?.faqQuestion?.question}' Of Category '${
          categories?.find((category: any) => category?.id === faq?.categoryId)
            .name
        }' Status`,
        newValue: status === true ? "Published" : "Inactive",
        oldValue: status === false ? "Inactive" : "Published",
        auditedObject: {
          ...faq,
          status: status,
          isEditField: true,
          isCreatfield: true,
        },
        route: RouteConstants.faqCreation,
        status: AuditStatus.EDITED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    if (status === "true") {
      const reqBody = {
        faqIds: selected,
        status: true,
        categoryId: location?.state?.categoryId
          ? location?.state?.categoryId
          : location.state?.category?.id,
      };
      bulkFaqsUpdate(reqBody);
    } else {
      const reqBody = {
        faqIds: selected,
        status: false,
        categoryId: location?.state?.categoryId
          ? location?.state?.categoryId
          : location.state?.category?.id,
      };
      bulkFaqsUpdate(reqBody);
    }

    clearSelected();
  };

  const clearSelected = () => {
    setSelected([]);
    setSelectedAction("");
  };

  const handleCloseConfirmDialog = (id: any) => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFaqState("rowsPerPage", Number(event.target.value));
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setFaqState("page", newPage);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = faqData?.faqs.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //select all
  const handleClick = (event: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const editFAQ = (faq: any) => {
    navigate(RouteConstants.faqCreation, {
      state: { ...faq, isEditField: true, isCreatfield: true },
    });
  };

  const handlePriorityChange = () => {
    setSortByPriority(!isSortByPriority);
  };
  const viewFAQ = (faq: any) => {
    navigate(RouteConstants.faqCreation, {
      state: {
        ...faq,
        isEditField: true,
        isCreatfield: true,
        isViewField: true,
      },
    });
    settingIconDisable();
  };
  //handleClickAway
  const handleClickAway = () => {
    setOpen(false);
  };
  // tooltip
  const popOverComponent = (event: any, question: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
    setQuestion(question);
  };
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      <div
        className="heading-action-holder"
        style={{ marginTop: "20px", zIndex: "4" }}
      >
        <h4 className="heading">
          Published
          {`(${
            isLoading
              ? ""
              : faqData?.totalCount
              ? faqData?.totalCount
              : faqData?.faqs?.length
          })`}
          {selected.length > 0 ? (
            <Fragment>
              <span className="selected-items-count-text">
                {`${selected.length} faqs(s) Selected`}
              </span>
              <a className="clear-selected-text" onClick={clearSelected}>
                Clear
              </a>
            </Fragment>
          ) : null}
        </h4>

        <div>
          <FormControl className="action-dropdown">
            <TextFieldComponent
              select
              value={selectedAction}
              label="Action"
              onChange={handleActionChange}
            >
              {actions.map((action: any) => {
                return (
                  <MenuItem
                    value={action.value}
                    disabled={
                      action.value !== 1 &&
                      location?.state &&
                      !isEditLocale &&
                      location?.state.isViewField
                    }
                  >
                    {action.label}
                  </MenuItem>
                );
              })}
            </TextFieldComponent>
          </FormControl>

          <Button
            variant="contained"
            // autoFocus
            onClick={() => {
              resetForm();
              navigate(RouteConstants.faqCreation, {
                state: {
                  isCreatfield: true,
                  category: categories?.find(
                    (category: any) =>
                      category?.id === location?.state?.categoryId ||
                      location?.state?.category?.id
                  ),
                  isNewFaqForm: true,
                },
              });
            }}
            disableElevation
            className="btn btn-dark"
          >
            Add More FAQs
          </Button>
        </div>
      </div>

      <Grid
        container
        spacing={1}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ zIndex: "4" }}
      >
        <Grid container spacing={1} xs={10}>
          <Grid item className="margin-left-10 filter-heading-holder">
            <span className="filter-heading" style={{ marginBottom: "15px" }}>
              Filters:
            </span>
          </Grid>
          <Grid item xs={2}>
            <FormControl size="small" className="filter-dropdown">
              <AutocompleteComponent
                options={users}
                label="Created by"
                onChange={(
                  event: React.SyntheticEvent,
                  newValue: any | null
                ) => {
                  setCreatedBy(newValue);
                  if (page !== 0) {
                    setFaqState("page", 0);
                  }
                }}
                value={createdBy}
                getOptionLabel={(option: any) =>
                  option?.isDuplicated
                    ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                    : `${option?.firstName} ${option?.lastName}`
                }
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl size="small" className="filter-dropdown">
              <AutocompleteComponent
                options={users}
                label="Modified by"
                onChange={(
                  event: React.SyntheticEvent,
                  newValue: any | null
                ) => {
                  setModifiedBy(newValue);
                  if (page !== 0) {
                    setFaqState("page", 0);
                  }
                }}
                value={modifiedBy}
                getOptionLabel={(option: any) =>
                  option?.isDuplicated
                    ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                    : `${option?.firstName} ${option?.lastName}`
                }
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl size="small" className="filter-dropdown">
              <AutocompleteComponent
                options={users}
                label="Published By"
                onChange={(
                  event: React.SyntheticEvent,
                  newValue: any | null
                ) => {
                  setPublishedBy(newValue);
                  if (page !== 0) {
                    setFaqState("page", 0);
                  }
                }}
                value={publishedBy}
                getOptionLabel={(option: any) =>
                  option?.isDuplicated
                    ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                    : `${option?.firstName} ${option?.lastName}`
                }
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl className="filter-dropdown">
              <TextFieldComponent
                select
                value={selectedStatus}
                label="Status"
                onChange={(event: any) => {
                  setSelectedStatus(event.target.value);
                  if (page !== 0) {
                    setFaqState("page", 0);
                  }
                  navigate(location.pathname, {
                    state: {
                      isEditField: true,
                      categoryId: location?.state?.categoryId
                        ? location?.state?.categoryId
                        : location.state?.category?.id,
                    },
                  });
                }}
              >
                {/* {statuses.map((status) => {
                  return (
                    <MenuItem value={status.value} key={status.value}>
                      {status.label}
                    </MenuItem>
                  );
                })} */}
                <MenuItem value={"Published"}>Published </MenuItem>
                <MenuItem value={"Inactive"}>Inactive </MenuItem>
              </TextFieldComponent>
            </FormControl>
          </Grid>
          {publishedBy !== null ||
          modifiedBy !== null ||
          selectedStatus != "" ||
          createdBy !== null ? (
            <Grid item xs={1} className="filter-heading-holder">
              <span
                onClick={clearFilters}
                className="clear-filter"
                style={{ marginBottom: "15px" }}
              >
                Clear
              </span>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={2}>
          <Stack spacing={1} justifyContent={"flex-end"}>
            <Typography>Sort by Priority</Typography>
            <SwitchComponent
              checked={isSortByPriority}
              onChange={handlePriorityChange}
            />
            <Tooltip
              title={"Toggle is to show/hide this section on the Customer App"}
              placement="top-start"
            >
              <Avatar
                sx={{
                  width: "0.9rem",
                  height: "0.9rem",
                  cursor: "pointer",
                  marginTop: "0.10rem",
                  marginBottom: "0.10rem",
                }}
                src={MediaAssets.ic_info}
                variant="rounded"
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>

      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell className="table-header-text" padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    data-testid="table-head-checkbox"
                    color="primary"
                    indeterminate={
                      selected.length > 0 &&
                      selected.length < faqData?.faqs.length
                    }
                    checked={
                      faqData?.faqs?.length > 0 &&
                      selected?.length === faqData?.faqs?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    <Stack justifyContent="flex-start">
                      <div>{item.heading}</div>
                      {/* {index === 1 && (
                        <IconButton
                          onClick={() => {
                            setCreatedBySort(!isCreatedBySort);
                            if (sortingPriority === 0) {
                              setSortingKey(0);
                              setSortingPriority(1);
                              setSortByPriority(false);
                            } else {
                              setSortingPriority(0);
                              setSortingKey(0);
                              setSortByPriority(false);
                            }
                          }}
                        >
                          {isCreatedBySort ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )}
                        </IconButton>
                      )} */}
                      {/* {index === 2 && (
                        <IconButton
                          onClick={() => {
                            setPublishedBySort(!isPublishedBySort);
                            if (sortingPriority === 0) {
                              setSortingKey(0);
                              setSortingPriority(1);
                              setSortByPriority(false);
                            } else {
                              setSortingPriority(0);
                              setSortingKey(0);
                              setSortByPriority(false);
                            }
                          }}
                        >
                          {isPublishedBySort ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )}
                        </IconButton>
                      )} */}
                      {/* {index === 3 && (
                        <IconButton
                          onClick={() => {
                            setModifiedBySort(!isModifiedBySort);
                            if (sortingPriority === 0) {
                              setSortingKey(1);
                              setSortingPriority(1);
                              setSortByPriority(false);
                            } else {
                              setSortingPriority(0);
                              setSortingKey(1);
                              setSortByPriority(false);
                            }
                          }}
                        >
                          {isModifiedBySort ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )}
                        </IconButton>
                      )} */}
                      {/* {index == 4 && (
                        <Tooltip
                          title={"Toggle is to show/hide"}
                          placement="top-start"
                        >
                          <Avatar
                            sx={{
                              width: "0.9rem",
                              height: "0.9rem",
                              cursor: "pointer",
                              marginTop: "0.10rem",
                              marginBottom: "0.10rem",
                              marginLeft: "0.3rem",
                            }}
                            src={MediaAssets.ic_info}
                            variant="rounded"
                          />
                        </Tooltip>
                      )} */}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {faqData.totalCount !== 0 ? (
              <TableBody>
                {faqData?.faqs?.map((faq: any, index: any) => {
                  const isItemSelected = isSelected(faq.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={faq.id}
                      className="table-data-row"
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ minWidth: 50 }} padding="checkbox">
                        <Checkbox
                          className="active-checkbox"
                          onClick={(event) => handleClick(event, faq.id)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 200 }}
                      >
                        {faq?.faqQuestion?.question.length > 0 ? (
                          <div>
                            {faq?.faqQuestion?.question.length > 18 ? (
                              <Stack justifyContent={"flex-start"}>
                                <span>
                                  {`${faq?.faqQuestion?.question.slice(
                                    0,
                                    18
                                  )}...`}
                                </span>
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="#2d2d2d"
                                  sx={{
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    wordBreak: "keep-all",
                                    textAlign: "start",
                                    width: "150px",
                                  }}
                                  onClick={(event: any) =>
                                    popOverComponent(
                                      event,
                                      faq?.faqQuestion?.question
                                    )
                                  }
                                >
                                  READ MORE
                                </Typography>
                              </Stack>
                            ) : (
                              faq?.faqQuestion?.question
                            )}
                          </div>
                        ) : (
                          "-"
                        )}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>
                          {format(new Date(faq?.createdAt), "dd/MM/yyyy")}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>
                          {format(new Date(faq?.createdAt), "dd/MM/yyyy")}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        {faq?.updatedAt ? (
                          <div>
                            {format(new Date(faq?.updatedAt), "dd/MM/yyyy")}
                          </div>
                        ) : (
                          "-"
                        )}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        {faq?.faqsUpdatedAdmin?.firstName
                          ? faq?.faqsUpdatedAdmin?.firstName
                          : "-"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 140 }}
                      >
                        {faq?.status === true ? (
                          <PrioritySelector
                            priority={faq?.priority}
                            incrementpriority={() => {
                              if (faq.priority >= 1) {
                                const reqBody = {
                                  id: faq.id,
                                  priority: faq.priority + 1,
                                };

                                faqPriority(reqBody);
                              }
                            }}
                            decrementpriority={() => {
                              const reqBody = {
                                id: faq.id,
                                priority: faq.priority - 1,
                              };
                              faqPriority(reqBody);
                            }}
                            disableDecrement={faq.priority === 1 ? true : false}
                            // disableIncrement={
                            //   faq.priority === faqData.totalCount ? true : false
                            // }
                            isPriorityRequired={
                              !isSortByPriority || faq.status === false
                            }
                          />
                        ) : (
                          <PrioritySelector
                            disableIncrement={true}
                            disableDecrement={true}
                            isPriorityRequired={false}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 150 }}
                      >
                        {faq?.status === true ? `Published` : "Inactive"}
                      </TableCell>
                      <TableCell style={{ minWidth: 150 }}>
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              editFAQ(faq);
                            }}
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewFAQ(faq)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No FAQs To Display</div>
            )}
          </Table>
        </TableContainer>
      )}

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Popper open={open} placement="bottom-start" anchorEl={anchor}>
          <ReadmoreDialogWrapper>
            <Paper
              elevation={3}
              sx={{ p: "0.5rem", width: "350px", zIndex: "1" }}
            >
              {question}
            </Paper>
          </ReadmoreDialogWrapper>
        </Popper>
      </ClickAwayListener>

      {!searchKey && faqData?.faqs?.length > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 30]}
          component="div"
          count={
            faqData.totalCount ? faqData.totalCount : faqData?.faqs?.length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        content={`Are you sure that you want to delete the ${selected?.length} selected Faq(s)?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteFaqsOnSelect}
      />
    </Fragment>
  );
}
