import {
  Breadcrumbs,
  Grid,
  IconButton,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useLocation, Link as RouterLink, LinkProps } from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";
import { useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Search from "@mui/icons-material/Search";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

interface IBreadcrumbsProps {
  searchPlaceholder?: string;
  handleSearchOnKeyPress?: (param: string) => void;
  shouldClearSearchKey?: boolean;
  breadcrumbNameMap: { [key: string]: string };
  shouldSearchBarAppear?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

const BreadCrumbSection = ({
  breadcrumbNameMap,
  handleSearchOnKeyPress,
  searchPlaceholder = "Search",
  shouldClearSearchKey,
  shouldSearchBarAppear = false,
}: IBreadcrumbsProps) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [searchKey, setSearchKey] = useState("");
  const [isClosebuttonClicked, setisClosebuttonClicked] = useState(false);

  useEffect(() => {
    if (searchKey === "" && isClosebuttonClicked) {
      search();
    }
  }, [searchKey]);

  const search = () => {
    handleSearchOnKeyPress && handleSearchOnKeyPress(searchKey);
  };

  const searchKeyPress = () => {
    search();
    setisClosebuttonClicked(true);
  };

  const handleOnEnterKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      search();
      setisClosebuttonClicked(true);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event?.target.value.trimStart());
    setisClosebuttonClicked(true);
  };

  const clearSearchKey = () => {
    setSearchKey("");
    setisClosebuttonClicked(true);
  };

  useEffect(() => {
    if (shouldClearSearchKey) {
      clearSearchKey();
    }
  }, [shouldClearSearchKey]);

  return (
    <Grid
      container
      columnSpacing={2}
      alignItems={"center"}
      justifyContent={"space-between"}
      bgcolor={"grey.50"}
      minHeight={"7vh"}
    >
      <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <ChevronRightRoundedIcon className={"separator"} fontSize="large" />
          }
        >
          {pathnames.map((_value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join("/")}`;
            return last ? (
              <Typography color="action.active" key={to}>
                {breadcrumbNameMap[to]}
              </Typography>
            ) : (
              <LinkRouter color="grey.900" to={to} key={to}>
                {breadcrumbNameMap[to]}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      </Grid>
      {shouldSearchBarAppear ? (
        <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5}>
          <OutlinedInput
            placeholder={searchPlaceholder}
            onKeyPress={handleOnEnterKeyPress}
            onChange={handleSearchChange}
            value={searchKey}
            size={"small"}
            sx={{ marginRight: 2, backgroundColor: "common.white" }}
            startAdornment={
              <IconButton
                aria-label="search"
                onClick={searchKeyPress}
                onMouseDown={(e) => e.preventDefault()}
              >
                <Search className={"search-icon"} />
              </IconButton>
            }
            endAdornment={
              <IconButton
                aria-label="cancel"
                onClick={clearSearchKey}
                onMouseDown={(e) => e.preventDefault()}
                sx={{
                  visibility: searchKey.length > 0 ? "visible" : "hidden",
                }}
              >
                <CancelIcon className={"search-icon"} />
              </IconButton>
            }
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default BreadCrumbSection;
