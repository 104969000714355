import { Fragment, useEffect, useState } from "react";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Avatar,
  Box,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { CustomerViewStyleWrapper } from "./CustomerView.style";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerOverview from "./customer-view-components/CustomerOverview";
import PageHeading from "components/page-heading/PageHeading";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { customerBreadcrumbRoutes } from "./CustomerViewInitialValues";
import { CustomerPortfolio } from "./customer-view-components/CustomerPortfolio";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomerActions,
  setCustomersState,
} from "redux-container/customer-management/CustomersListAndDetailsRedux";
import { RouteConstants } from "routes/RouteConstants";

const actionDispatch = (dispatch: any) => {
  return {
    setCustomersState: async (key: any, value: any) => {
      dispatch(CustomerActions.setCustomersState(key, value));
    },
  };
};
const CustomerView = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const tabValue = useSelector(
    (state: any) => state?.customerListAndDetails?.tabValue
  );
  const { setCustomersState } = actionDispatch(useDispatch());

  const tabOne = "1";
  const tabTwo = "2";

  //function to handle Tab Panel.
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCustomersState("tabValue", newValue);
  };

  //setting tabValue to initial value if user Navigated to the Customer View from Customer List.
  useEffect(() => {
    if (
      location.state?.isNavigationFromSidemenu ||
      location?.state?.isNavigatedFromList
    ) {
      setCustomersState("tabValue", tabOne);
    }
  }, []);

  return (
    <CustomerViewStyleWrapper>
      <PageHeading title="Customer Management" />
      <BreadCrumbs
        routes={customerBreadcrumbRoutes(
          `${location?.state?.customerData?.firstName}${" "}${
            location?.state?.customerData?.lastName
          }`
        )}
      />
      <Container>
        <Stack justifyContent={"space-between"}>
          <Box>
            <Stack>
              <IconButton
                edge={"start"}
                onClick={() => navigate(RouteConstants.customerList)}
              >
                <ChevronLeftIcon color="inherit" />
              </IconButton>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar
                      alt="Customer profile pic"
                      src={location?.state?.customerData?.profilePictureUrl}
                      variant="rounded"
                      sx={{ width: 70, height: 60, marginRight: "15px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${
                      location?.state?.customerData?.firstName
                    }${" "}${location?.state?.customerData?.lastName}`}
                    secondary={
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="caption"
                        color="text.primary"
                      >
                        {`Registered on : ${
                          location?.state?.customerData?.firstLoginDate
                            ? formatDateTime(
                                location?.state?.customerData?.firstLoginDate,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "--"
                        }`}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Stack>
          </Box>
          <Box>
            <Paper
              elevation={4}
              sx={{ padding: 1.5, backgroundColor: "#f1f1f1" }}
            >
              <ListItemText
                primary="CRM ID"
                secondary={location.state.customerData.crmId}
              />
              <Divider />
              <ListItemText
                primary="CMS ID"
                secondary={location.state.customerData.id}
              />
            </Paper>
          </Box>
        </Stack>
        <TabContext value={tabValue}>
          <Box
            sx={{
              display: "inline-block",
              borderBottom: 1,
              borderColor: "divider",
              marginX: "2.5rem",
            }}
          >
            <TabList onChange={handleChange} aria-label="customer API tabs">
              <Tab label="Customer Overview" value={tabOne} />
              <Tab label="Customer Portfolio" value={tabTwo} />
            </TabList>
          </Box>

          <TabPanel value={tabOne}>
            {/* when tabPanal Value is equal to "tabOne" than Customer Over View will be rendered. */}
            <CustomerOverview />
          </TabPanel>
          <TabPanel value={tabTwo}>
            {/* when tabPanal Value is equal to "tabTwo" than Customer Over View will be rendered. */}
            <CustomerPortfolio />
          </TabPanel>
        </TabContext>
      </Container>
    </CustomerViewStyleWrapper>
  );
};

export default CustomerView;
