import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Avatar, TextField } from "@mui/material";
import MediaAssets from "assets";
import moment from "moment";

const CalenderIcon = () => (
  <Avatar
    sx={{ width: 20, height: 20 }}
    src={MediaAssets.ic_calendar_icon}
    alt="calender-icon"
    variant="rounded"
  />
);

const CustomDatePicker = (props: any) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        closeOnSelect
        disableMaskedInput
        value={moment(props.value || "Select a Date", "YYYY-MMMM-DD")}
        components={{ OpenPickerIcon: CalenderIcon }}
        inputFormat="yyyy/MM/dd"
        renderInput={(params) => (
          <TextField
            fullWidth={props.fullWidth}
            placeholder={props.placeholder}
            {...params}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
