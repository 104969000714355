import {
  Stack,
  Typography,
  Container,
  Box,
  IconButton,
  Tooltip,
  Popper,
  MenuList,
  ClickAwayListener,
  MenuItem,
  Paper,
} from "@mui/material";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import {
  ButtonComponent,
  FormFieldContainer,
  LoadingButtonComponent,
} from "components/ui-elements";
import { Form, Formik } from "formik";
import { IObjectProps } from "pages/user-management/user-creation/UserCreationInitialValues";
import { RouteConstants } from "routes/RouteConstants";
import PageHeading from "../../../../components/page-heading/PageHeading";
import GeneralFAQ from "./faq-types/GeneralFAQ";
import ProjectFAQ from "./faq-types/ProjectFAQ";
import {
  CategoryList,
  FAQcreationLabels,
  FAQInitialValues,
} from "./FAQInitialValues";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { FAQSchemaValidation } from "./FAQSchemaValidation";
import { GenealFaqActions } from "redux-container/faq/GeneralFaqRedux";
import { useDispatch, useSelector } from "react-redux";
import CategoryActions from "redux-container/resource-management/CategoryRedux";
import { useEffect, useState } from "react";
import CustomTabs from "../FaqTabs";
import {
  GetAllCategoriesAction,
  setCategoriesState,
} from "redux-container/faq/GetAllCategoriesRedux";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { FaqActions } from "redux-container/faq/FaqRedux";
import FAQPublishedList from "../faq-published-list/FAQPublishedList";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { StatusTypeEnum } from "enumerations/StatusTypeEnum";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { faqRoutePathConst } from "../faqRoutePathConst";
import FaqListActions from "../../../../redux-container/resource-management/FaqListRedux";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import axios from "axios";
import { AuditStatus } from "enumerations/AuditStatusTypes";

export const FaqsTooltip = () => {
  return (
    <Box>
      <Typography color={"inherit"}>
        FAQs will appear on the app in the following sequence
      </Typography>
      <Box component={"ol"} ml={"1rem"}>
        <li>
          <Typography color={"inherit"}>Category Name</Typography>
        </li>
        <li>
          <Typography color={"inherit"}>Question</Typography>
        </li>
        <li>
          <Typography color={"inherit"}>Answer</Typography>
        </li>
      </Box>
    </Box>
  );
};
const actionDispatch = (dispatch: any) => {
  return {
    addNewFaq: (data: any) =>
      dispatch(GenealFaqActions.faqCreationRequest(data)),
    getAllCategories: () => dispatch(CategoryActions.getCategoriesRequest()),
    setCategoryReduxState: (key: any, data: any) =>
      dispatch(CategoryActions.setCategoriesState(key, data)),
    getAllFaqs: (data: any) =>
      dispatch(GenealFaqActions.getAllFaqsRequest(data)),
    setFaqState: (key: any, data: any) =>
      dispatch(GenealFaqActions.setFaqState(key, data)),
    getFaqs: (params: any) => dispatch(FaqActions.faqRequest(params)),
    deleteCategory: (data: any) =>
      dispatch(GetAllCategoriesAction.deleteCategoryRequest(data)),
    updateCategory: (id: any, data: any) =>
      dispatch(GetAllCategoriesAction.putCategoryRequest(id, data)),
    bulkStatusCategory: (data: any) =>
      dispatch(GetAllCategoriesAction.bulkStatusCategoryRequest(data)),
    getCategories: (param: any) =>
      dispatch(GetAllCategoriesAction.getAllCategoriesRequest(param)),
    setCategoriesState: (key: any, data: any) =>
      dispatch(GetAllCategoriesAction.setCategoriesState(key, data)),
    faqPriority: (data: any) =>
      dispatch(GenealFaqActions.faqPriorityUpdateRequest(data)),
    changeFaqStatus: (data: any) =>
      dispatch(FaqListActions.changeFaqsStatusRequest(data)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
  };
};

const FAQCreation = () => {
  const {
    addNewFaq,
    getAllCategories,
    getAllFaqs,
    setFaqState,
    getFaqs,
    deleteCategory,
    getCategories,
    setCategoriesState,
    updateCategory,
    faqPriority,
    changeFaqStatus,
    bulkStatusCategory,
    postAuditLogRequest,
    setCategoryReduxState,
  } = actionDispatch(useDispatch());
  const navigate = useNavigate();
  const location: any = useLocation();

  // all useSelector
  const {
    categories,
    isLoading,
    allFaqs,
    data,
    isSuccessfulUpdated,
    responseData,
    isSuccessFulGeneralFaq,
    routeToPage,
    shouldAudit,
    reduxAuditedObject,
  } = useSelector((state: any) => state?.generalFaq);
  const { isSuccess, responseCategoryData, routeToPageCategory } = useSelector(
    (state: any) => state?.allCategoriesForFaq
  );
  const response = useSelector(
    (state: any) => state?.allCategoriesForFaq.responseData
  );
  const allCategories = useSelector(
    (state: any) => state?.categories?.categories
  );
  const modules = useSelector((state: any) => state?.rolesAndModules?.module);
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [isEditLocale, setIsEditLocale] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [ipAddress, setIpAddress] = useState<any>(null);
  const [previousValues, setPreviousValues] = useState<any>();
  useEffect(() => {
    setPreviousValues(
      location?.state?.isEditField
        ? {
            //same as initial state fuction editInitialState().
            categoryId: "",
            isCategoryField: false,
            faqs: [
              {
                faqQuestion: {
                  question: location?.state?.faqQuestion?.question,
                },
                faqAnswer: {
                  answer: location?.state?.faqAnswer?.answer,
                },
                // priority: location?.state?.priority,
                isEdit: false,
                faqId: location?.state?.id,
              },
            ],
          }
        : FAQInitialValues
    );
  }, [location?.state]);
  const [modulesAuditedForNewCreation, setModulesAuditedForNewCreation] =
    useState<any>(null);

  useEffect(() => {
    getIpAddress();
    return () => {};
  }, []);
  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIpAddress(res.data.IPv4);
  };

  const settingIconAble = () => {
    setIsEditLocale(true);
  };
  const settingIconDisable = () => {
    setIsEditLocale(false);
  };
  const popOverComponent = (event: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  const categoryStatus = allCategories?.find((item: any) => {
    if (location?.state?.categoryId) {
      return location.state?.categoryId === item.id;
    } else {
      return location.state?.category?.id === item.id;
    }
  });

  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
  };

  useEffect(() => {
    getAllCategories();
    faqsListBasedOnID();
    getCategories("2001");
  }, [location?.state]);

  useEffect(() => {
    if (isSuccess) {
      getAllCategories();
      getCategories("2001");
      setCategoriesState("isSuccess", false);
      setCategoryReduxState("shouldAudit", false);
    }
  }, [isSuccess]);

  // useEffect(() => {
  //   if (shouldAudit) {
  //     getAllCategories();
  //     setCategoryReduxState("shouldAudit", false);
  //     setCategoriesState("isSuccess", false);
  //   }
  // }, [shouldAudit]);

  useEffect(() => {
    if (isSuccessFulGeneralFaq) {
      getCategories("2001");
      setFaqState("isSuccessFulGeneralFaq", false);
    }
  }, [isSuccessFulGeneralFaq]);

  useEffect(() => {
    if (isSuccessfulUpdated) {
      faqsListBasedOnID();
      setFaqState("isSuccessfulUpdated", false);
    }
  }, [isSuccessfulUpdated]);

  // confirm dialog for category delete
  const handleOpenConfirmDialog = (e: any) => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const deletedCategory = (e: any) => {
    if (location.state.category?.id !== 1) {
      deleteCategory(categoryStatus?.id);
      setIsConfirmDeleteDialogOpen(false);
    } else {
      alert("cant delete General Category");
    }
  };

  // update category active and inactive
  const handleMarkStatus = async () => {
    let auditObject = {
      itemAffected: `Category '${categoryStatus?.name}' Status`,
      newValue:
        StatusTypeEnum.Active === categoryStatus.status ? "Inactive" : "Active",
      oldValue:
        StatusTypeEnum.Active === categoryStatus.status ? "Active" : "Inactive",
    };

    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Resources Management";
    });

    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: RouteConstants?.faqCreation,
      auditedObject: {
        // data: { ...categoryStatus, status: status },
        categoryId: categoryStatus?.id,
        isEditField: true,
      },
      modulesAudited: [auditObject],
      status: AuditStatus.EDITED,
    };
    postAuditLogRequest(postAuditRequestbody);
    if (categoryStatus.status === StatusTypeEnum.Active) {
      const reqBody1 = {
        status: StatusTypeEnum.Inactive,
        categoryIds: [categoryStatus.id],
      };

      bulkStatusCategory(reqBody1);
    } else {
      const reqBody1 = {
        status: StatusTypeEnum.Active,
        categoryIds: [categoryStatus.id],
      };

      bulkStatusCategory(reqBody1);
    }
  };
  //handle submit
  //here u need to use values as auditObject even though we dont have all the data in values .
  const onSubmitHandler = async (values: IObjectProps, { resetForm }: any) => {
    auditLogHandler(values);

    const foundCategoryId = allCategories?.find(
      (item: any) => values?.categoryId == item.name
    );
    const reqBody = {
      typeOfFAQ: "3001",
      categoryId: foundCategoryId.id,
      faqs: values.faqs,
    };
    await addNewFaq(reqBody);
  };

  const auditLogHandler = (values: any) => {
    let auditObjectsArray: any[] = [];
    const foundCategoryId = allCategories?.find(
      (item: any) => values?.categoryId == item.name
    );

    // values?.faqs?.map((faq: any) => {
    Object.keys(values)?.map((valuesKeys: any) => {
      //Values objectkeys getting
      if (typeof values[valuesKeys] == "object") {
        values[valuesKeys]?.map(
          (faqsArrayObject: any, faqsArrayObjectIndex: number) => {
            let auditObject = {
              itemAffected: `FAQ '${faqsArrayObject?.faqQuestion?.question}' added to Category '${foundCategoryId?.name}'`,
              newValue: "",
              oldValue: "",
              auditedObject: {
                ...reduxAuditedObject?.bulkFaqs[faqsArrayObjectIndex],
                category: reduxAuditedObject?.category,
                isEditField: true,
                isCreatfield: true,
              },
              route: location.pathname,
              // auditedObject: {
              //   ...values,
              //   faqs: [faqsArrayObject],
              //   // ...location?.state,
              //   isEditField: true,
              //   isCreatfield: true,
              // },
              // route: location.pathname,
              status: AuditStatus.CREATED,
            };
            auditObjectsArray.push(auditObject);
          }
        );
      }
    });
    // });

    setModulesAuditedForNewCreation(auditObjectsArray);
  };
  useEffect(() => {
    if (shouldAudit && modulesAuditedForNewCreation?.length > 0) {
      const moduleObject: any = modules?.find((element: any) => {
        return element?.name === "Resources Management";
      });
      const postAuditRequestbody = {
        ipAddress: ipAddress, //ipAddress
        moduleId: moduleObject?.id,
        route: null,
        auditedObject: null,
        modulesAudited: modulesAuditedForNewCreation,
        status: null,
      };
      postAuditLogRequest(postAuditRequestbody);
      setFaqState("shouldAudit", false);
    }
  }, [shouldAudit]);

  const breadcrumbRoutes: any = [
    {
      name: "Resource Management",
      route: RouteConstants.resourceManagementDashboard,
    },
    {
      name: "FAQs",
      route: RouteConstants.faqList,
    },
    {
      name: location?.state?.isEditField
        ? location.state && location.state?.isViewField
          ? `View :${categoryStatus?.name ? categoryStatus?.name : ""}`
          : `Edit :${categoryStatus?.name ? categoryStatus?.name : ""}`
        : "Create New",
      route: RouteConstants.faqCreation,
    },
  ];

  const faqsListBasedOnID = () => {
    if (location?.state?.isCreatfield) {
      getAllFaqs(location?.state?.categoryId);
    }
  };

  // re routing the component based on image selection
  const handleCloseFlashMessageDialog = (routeTo: any) => {
    setFaqState("responseData", null);
    setCategoriesState("responseCategoryData", null);

    if (routeTo === faqRoutePathConst.CategoryTablePage) {
      navigate(RouteConstants.faqList);
      setCategoriesState("routeToPageCategory", null);
      setFaqState("routeToPage", null);
    }
    if (
      routeTo === faqRoutePathConst.CategoryDeleteFormList &&
      !location?.state?.isCreatfield
    ) {
      navigate(RouteConstants.faqList);
      setCategoriesState("routeToPageCategory", null);
      setFaqState("routeToPage", null);
    }
    if (
      routeTo === faqRoutePathConst.CategoryDeleteFormList &&
      location?.state?.isCreatfield &&
      location?.state?.isEditField
    ) {
      navigate(RouteConstants.faqList);
      setCategoriesState("routeToPageCategory", null);
      setFaqState("routeToPage", null);
    }

    if (routeTo === faqRoutePathConst.FaqListTablePage) {
      navigate(RouteConstants.faqCreation, {
        state: {
          category: location.state.category,
          isEditField: true,
          isCreatfield: false,
        },
      });
      setCategoriesState("routeToPageCategory", null);
      setFaqState("routeToPage", null);
    }
  };

  const editInitialState = () => {
    return {
      categoryId: "",
      isCategoryField: false,
      faqs: [
        {
          faqQuestion: {
            question: location?.state?.faqQuestion?.question,
          },
          faqAnswer: {
            answer: location?.state?.faqAnswer?.answer,
          },
          // priority: location?.state?.priority,
          isEdit: false,
          faqId: location?.state?.id,
        },
      ],
    };
  };

  return (
    <>
      <Formik
        initialValues={
          location?.state?.isEditField ? editInitialState() : FAQInitialValues
        }
        onSubmit={onSubmitHandler}
        validationSchema={FAQSchemaValidation}
        enableReinitialize={true}
      >
        {({ values, handleChange, isValid, dirty, resetForm }) => (
          <Form>
            <PageHeading title="Resource Management" />
            {location?.state?.isEditField ? (
              <BreadCrumbs
                routes={breadcrumbRoutes}
                handleSearchOnKeyPress={handleSearchChange}
                placeholder="Search FAQs"
              ></BreadCrumbs>
            ) : (
              <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
            )}
            <Container>
              <Stack mt={"2rem"} style={{ zIndex: "4" }}>
                <Box>
                  <Stack>
                    <IconButton
                      onClick={() => {
                        if (
                          location?.state?.isNavigationFromSidemenu ||
                          !location?.state?.isCreatfield ||
                          (!location?.state?.isNewFaqForm &&
                            !location?.state?.category)
                        ) {
                          navigate(RouteConstants.faqList);
                        } else {
                          navigate(-1);
                        }
                      }}
                    >
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                    <Typography color={"secondary.dark"} variant="h6">
                      {`${
                        location?.state?.isEditField
                          ? ` ${
                              categoryStatus?.name ? categoryStatus?.name : ""
                            } FAQs`
                          : "Create New"
                      }`}
                    </Typography>
                    {location?.state?.isEditField ? (
                      <IconButton
                        disabled={
                          location?.state &&
                          !isEditLocale &&
                          location?.state.isViewField
                        }
                      >
                        <SettingsOutlinedIcon
                          fontSize="medium"
                          onClick={(event) => popOverComponent(event)}
                        />
                      </IconButton>
                    ) : (
                      <Tooltip
                        title={<FaqsTooltip />}
                        arrow
                        placement="top-start"
                      >
                        <IconButton>
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Box>

                {location?.state?.isEditField ? null : (
                  <Stack direction={"row"} columnGap={2}>
                    <ButtonComponent
                      color="inherit"
                      onClick={() => {
                        resetForm();
                        // navigate(RouteConstants.faqList);
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </ButtonComponent>
                    {/* <ButtonComponent disabled>
                      Save as Draft
                    </ButtonComponent> */}
                    <LoadingButtonComponent
                      type="submit"
                      loading={isLoading}
                      loadingPosition="start"
                      disabled={!isValid || !dirty}
                    >
                      Publish
                    </LoadingButtonComponent>
                  </Stack>
                )}
              </Stack>
              {location?.state?.isCreatfield && (
                <FormFieldContainer>
                  <GeneralFAQ
                    isEditLocale={isEditLocale}
                    settingIconAble={settingIconAble}
                    previousValues={previousValues}
                    setPreviousValues={setPreviousValues}
                  />
                </FormFieldContainer>
              )}

              {location?.state?.isEditField && (
                <FAQPublishedList
                  isEditLocale={isEditLocale}
                  settingIconDisable={settingIconDisable}
                  searchKey={searchKey}
                  isNavigationFromSidemenu={
                    location?.state?.isNavigationFromSidemenu
                  }
                />
              )}
            </Container>
          </Form>
        )}
      </Formik>

      {responseData ? (
        <FlashMessageDialog
          shouldOpen={responseData ? true : false}
          content={
            responseData?.code === 200
              ? responseData?.message
              : responseData?.message
          }
          isSuccess={responseData?.code === 200 ? true : false}
          cancelHandler={() => handleCloseFlashMessageDialog(routeToPage)}
        />
      ) : null}

      {responseCategoryData ? (
        <FlashMessageDialog
          shouldOpen={responseCategoryData ? true : false}
          content={
            responseCategoryData?.code === 200
              ? responseCategoryData?.message
              : responseCategoryData?.message
          }
          isSuccess={responseCategoryData?.code === 200 ? true : false}
          cancelHandler={() => {
            handleCloseFlashMessageDialog(routeToPageCategory);
            setFaqState("shouldAudit", false);
          }}
        />
      ) : null}

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Catagory?"
        content="This action will delete the Category and related FAQs. Are you sure you want to continue?"
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deletedCategory}
      />
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Popper open={open} placement="right-start" anchorEl={anchor}>
          <Paper elevation={12}>
            <MenuList>
              <MenuItem sx={{ bgcolor: "white" }} onClick={handleMarkStatus}>
                {`${
                  categoryStatus?.status == StatusTypeEnum.Active
                    ? "Mark as Inactive"
                    : "Mark as Active"
                }`}
              </MenuItem>
              <MenuItem
                sx={{ bgcolor: "white" }}
                onClick={handleOpenConfirmDialog}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default FAQCreation;
