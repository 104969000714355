import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";
import styles from "./ProjectCloneConfirmDialog.module.css";
import { Box, Typography } from "@mui/material";

interface IProjectCloneConfirmDialogProps {
  shouldOpen: boolean;
  title?: string;
  content: string;
  actionContent: string;
  actionSubContent: string;
  okText?: string;
  showWarning?: boolean;
  cancelHandler: (e?: any) => any;
  okHandler?: (e?: any) => any;
  cancelBtnText?: string;
  notifycheckoptions?: ReactNode;
  isOkDisabled?: boolean;
}

const ProjectCloneConfirmDialog = ({
  shouldOpen,
  title,
  content,
  okText,
  showWarning = false,
  cancelHandler,
  okHandler,
  cancelBtnText = "Cancel",
  notifycheckoptions,
  isOkDisabled,
  actionContent,
  actionSubContent,
}: IProjectCloneConfirmDialogProps) => {
  const dialogTitle = title;
  const dialogContent = content ? content : "";
  const okBtnText = okText ? okText : "Okay";

  const handleClose = () => {
    cancelHandler();
  };

  const handleOkay = () => {
    okHandler ? okHandler() : cancelHandler();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={shouldOpen}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>

      <DialogContent>
        <Box textAlign={"center"} p={3}>
          <Typography className={styles["typography-medium"]}>
            {dialogContent}
          </Typography>
          <Typography mt={2} className={styles["typography-semiBold"]}>
            {actionContent}
          </Typography>
          <Typography mt={1} className={styles["typography-Light"]}>
            {actionSubContent}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions className={styles["confirm-dialog-actions"]}>
        <Button
          color="inherit"
          variant="contained"
          onClick={handleClose}
          disableElevation
          className={`${styles["action-btn"]} btn btn-light-outline`}
        >
          {cancelBtnText}
        </Button>
        <Button
          variant="contained"
          onClick={handleOkay}
          // autoFocus
          disableElevation
          className={`${styles["action-btn"]} btn btn-dark`}
          disabled={isOkDisabled}
        >
          {okBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectCloneConfirmDialog;
