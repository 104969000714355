import { Grid, Stack, Typography } from "@mui/material";
import QuillEditor from "components/quill-editor/QuillEditor";
import { MediaUploadComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { stripHtml } from "utils/ValidationUtils";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";
import { useLocation } from "react-router-dom";

const actionDispatch = (dispatch: any) => {
  return {
    setUploadFileState: (key: any, data: any) => {
      dispatch(FileUploadActions.setUploadFileState(key, data));
    },
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
    fileDeleteRequest: (data: any) => {
      dispatch(FileUploadActions.fileDeleteRequest(data));
    },
  };
};

function PaymentBannerImageSection() {
  const location: any = useLocation();
  console.log("location", location);

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<any>();

  const {
    imageDetails,
    isLoading: mediaUploading,
    isSuccess,
    shouldFlashMessageOpen,
    data,
    error,
  } = useSelector((state: any) => state?.fileUpload);

  const { setUploadFileState, fileUploadRequest, fileDeleteRequest } =
    actionDispatch(useDispatch());
  const [mediaName, setMediaName] = useState("");
  const [mediaErrors, setMediaErrors] = useState({
    mediaWeb: "",
    mediaMobile: "",
    mediaTab: "",
  });
  console.log("mediaErrors", mediaErrors);

  const handleMediaUpload = async (event: any) => {
    const file = event.target.files[0];
    setMediaName(event?.target?.name);
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader: any = new FileReader();
      reader.onload = (e: any) => {
        const image: any = new Image();
        const dataUrl = arrayBufferToDataUrl(e.target.result);
        image.src = dataUrl;
        image.onload = async () => {
          switch (event?.target?.name) {
            case "mediaWeb":
              if (
                image?.width >= 680 &&
                image?.width <= 720 &&
                image?.height >= 570 &&
                image?.height < 620
              ) {
                data.binary = e.target.result;
                fileUploadRequest(data);

                setMediaErrors({
                  ...mediaErrors,
                  mediaWeb: "",
                });
              } else {
                setMediaErrors({
                  ...mediaErrors,
                  mediaWeb: "Uplaod Image with mentioned dimensions!",
                });
                setFieldTouched(
                  `paymentFormBanner.${event?.target?.name}.value.url`,
                  true
                );
              }
              break;
            case "mediaMobile":
              if (
                image?.width >= 370 &&
                image?.width <= 410 &&
                image?.height >= 180 &&
                image?.height < 220
              ) {
                data.binary = e.target.result;
                fileUploadRequest(data);
                setMediaErrors({
                  ...mediaErrors,
                  mediaMobile: "",
                });
              } else {
                setMediaErrors({
                  ...mediaErrors,
                  mediaMobile: "Uplaod Image with mentioned dimensions!",
                });
                setFieldTouched(
                  `paymentFormBanner.${event?.target?.name}.value.url`,
                  true
                );
              }
              break;
            case "mediaTab":
              if (
                image?.width >= 740 &&
                image?.width <= 790 &&
                image?.height >= 380 &&
                image?.height < 420
              ) {
                data.binary = e.target.result;
                fileUploadRequest(data);
                setMediaErrors({
                  ...mediaErrors,
                  mediaTab: "",
                });
              } else {
                setMediaErrors({
                  ...mediaErrors,
                  mediaTab: "Uplaod Image with mentioned dimensions!",
                });
                setFieldTouched(
                  `paymentFormBanner.${event?.target?.name}.value.url`,
                  true
                );
              }
              break;
          }
        };
      };

      await reader.readAsArrayBuffer(file);

      setFieldValue(
        `paymentFormBanner.${event?.target?.name}.value.size`,
        file.size
      );
      setFieldValue(`paymentFormBanner.${event?.target?.name}.name`, file.name);
      setFieldValue(`paymentFormBanner.${event?.target?.name}.key`, file.name);
    } else {
      return;
    }
  };
  const arrayBufferToDataUrl = (arrayBuffer: any) => {
    const blob = new Blob([arrayBuffer]);
    const dataUrl = URL.createObjectURL(blob);
    return dataUrl;
  };
  console.log("values", values);
  console.log("values errors after upload", errors);
  console.log("values touched", touched);

  useEffect(() => {
    if (imageDetails) {
      setFieldTouched(`paymentFormBanner.${mediaName}.value.url`, true);
      const imageRes: any = imageDetails;
      console.log("imageRes", imageRes);
      setFieldValue(`paymentFormBanner.${mediaName}.value.url`, imageRes?.url);
      setUploadFileState("imageDetails", null);
    }
  }, [imageDetails]);

  const handleDeleteMedia = (name: any) => {
    setFieldValue(`paymentFormBanner.${name}.value.url`, "");
    setFieldValue(`paymentFormBanner.${name}.value.value.size`, "");
    setFieldValue(`paymentFormBanner.${name}.value.key`, "");
    setFieldValue(`paymentFormBanner.${name}.value.name`, "");
  };
  const handleTextEditor = (name: string, newValue: string, options: any) => {
    setFieldValue(name, newValue);
    setFieldTouched(name, true);

    console.log("inside outer strip html,", name, stripHtml(newValue));
    if (!stripHtml(newValue)) {
      console.log("inside strip html,", stripHtml(newValue));
      setFieldError(name, "This field is mandatory");
    }
  };

  return (
    <>
      <Grid container padding={4} columnSpacing={6}>
        <Grid item xl={8} sm={8} lg={8} md={8}>
          <Typography>Description </Typography>
          <QuillEditor
            // readonly={mediaAndPrState.isViewMode}
            value={values?.paymentFormBanner?.description}
            onChange={(name: string, newValue: string, options: any) =>
              handleTextEditor(name, newValue, options)
            }
            name={"paymentFormBanner.description"}
            placeholder={"Enter Description"}
            inputProps={{
              maxLength: 250,
            }}
            style={{ height: "14rem" }}
            disabled={location?.state?.isViewMode}
          />
        </Grid>
        <Grid item xl={4} sm={4} lg={4} md={4}>
          <Typography>Web Banner Image *</Typography>
          <MediaUploadComponent
            name={`mediaWeb`}
            accept=".jpg,.png,.jpeg,.gif"
            value={values?.paymentFormBanner?.mediaWeb?.value?.url}
            loading={mediaName === "mediaWeb" ? mediaUploading : false}
            onChange={(event: any) => {
              handleMediaUpload(event);
            }}
            cancelimage={handleDeleteMedia}
            previewimage={values?.paymentFormBanner?.mediaWeb?.value?.url}
            fieldhelpertext={
              !mediaUploading &&
              getIn(touched, `paymentFormBanner.mediaWeb.value.url`) &&
              // getIn(errors, `paymentFormBanner.mediaWeb.value.url`)
              mediaErrors?.mediaWeb
            }
            mediaHeight="680-720"
            mediaWidth="570-620"
            // isAspectRatio={true}
            disabled={location?.state?.isViewMode}
          />
          {values?.paymentFormBanner?.mediaWeb?.value?.url &&
          !mediaUploading &&
          mediaErrors?.mediaWeb ? (
            <>
              <Stack justifyContent={"center"} alignItems={"flex-start"}>
                <ErrorOutlineSharpIcon
                  fontSize="small"
                  sx={{
                    // marginTop: "10px",
                    color: "red",
                  }}
                />
                <Typography sx={{ color: "red", textAlign: "left" }}>
                  {mediaErrors?.mediaWeb}{" "}
                </Typography>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xl={4} sm={4} lg={4} md={4}>
          <Typography>Mobile Banner Image*</Typography>
          <MediaUploadComponent
            name={`mediaMobile`}
            accept=".jpg,.png,.jpeg,.gif"
            value={values?.paymentFormBanner?.mediaMobile?.value?.url}
            loading={mediaName === "mediaMobile" ? mediaUploading : false}
            onChange={(event: any) => {
              handleMediaUpload(event);
            }}
            cancelimage={handleDeleteMedia}
            previewimage={values?.paymentFormBanner?.mediaMobile?.value?.url}
            fieldhelpertext={
              !mediaUploading &&
              getIn(touched, `paymentFormBanner.mediaMobile.value.url`) &&
              // getIn(errors, `paymentFormBanner.mediaMobile.value.url`)
              mediaErrors?.mediaMobile
            }
            mediaHeight="370-410"
            mediaWidth="180-220"
            // isAspectRatio={true}
            disabled={location?.state?.isViewMode}
          />
          {values?.paymentFormBanner?.mediaMobile?.value?.url &&
          !mediaUploading &&
          mediaErrors?.mediaMobile ? (
            <>
              <Stack justifyContent={"center"} alignItems={"flex-start"}>
                <ErrorOutlineSharpIcon
                  fontSize="small"
                  sx={{
                    // marginTop: "10px",
                    color: "red",
                  }}
                />
                <Typography sx={{ color: "red", textAlign: "left" }}>
                  {mediaErrors?.mediaMobile}{" "}
                </Typography>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xl={4} sm={4} lg={4} md={4}>
          <Typography>Tab Banner Image*</Typography>
          <MediaUploadComponent
            name={`mediaTab`}
            accept=".jpg,.png,.jpeg,.gif"
            value={values?.paymentFormBanner?.mediaTab?.value?.url}
            loading={mediaName === "mediaTab" ? mediaUploading : false}
            onChange={(event: any) => {
              handleMediaUpload(event);
            }}
            cancelimage={handleDeleteMedia}
            previewimage={values?.paymentFormBanner?.mediaTab?.value?.url}
            fieldhelpertext={
              !mediaUploading &&
              getIn(touched, `paymentFormBanner.mediaTab.value.url`) &&
              // getIn(errors, `paymentFormBanner.mediaTab.value.url`)
              mediaErrors?.mediaTab
            }
            mediaHeight="740-790"
            mediaWidth="380-420"
            // isAspectRatio={true}
            disabled={location?.state?.isViewMode}
          />
          {values?.paymentFormBanner?.mediaTab?.value?.url &&
          !mediaUploading &&
          mediaErrors?.mediaTab ? (
            <>
              <Stack justifyContent={"center"} alignItems={"flex-start"}>
                <ErrorOutlineSharpIcon
                  fontSize="small"
                  sx={{
                    // marginTop: "10px",
                    color: "red",
                  }}
                />
                <Typography sx={{ color: "red", textAlign: "left" }}>
                  {mediaErrors?.mediaTab}{" "}
                </Typography>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default PaymentBannerImageSection;
