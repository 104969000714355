import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

const { Types, Creators } = createActions({
  addPageManagementDataSuccess: ["response"],
  addPageManagementDataFailure: ["error"],
  addPageManagementDataRequest: ["data"],

  getPageManagementDataSuccess: ["response"],
  getPageManagementDataFailure: ["error"],
  getPageManagementDataRequest: ["data"],

  updatePageManagementDataSuccess: ["response"],
  updatePageManagementDataFailure: ["error"],
  updatePageManagementDataRequest: ["data"],

  setUpdatePageManagement: ["key", "value"],
  setPageManagementState: ["key", "value"],
});

export const PageManagementCreationTypes = Types;

export default Creators;

export const INITIAL_STATE: any = {
  pageManagementData: null,
  isReduxViewMode: null,
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  isViewMode: false,
  page5Data: null,

  modulesAudited: [],
  corporatePhilosophyLength: 0,
  productCategoryLength: 0,
  statsOverviewLength: 0,
};

export const addPageManagementDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const addPageManagementDataSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Page Management Data added successfully!",
      isLoading: false,
    };
  }
};

export const addPageManagementDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// get pagemanagement data
export const getPageManagementDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPageManagementDataSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      pageManagementData: response.data,
      page5Data: response.data.page,
      corporatePhilosophyLength:
        response?.data?.page?.aboutUs?.corporatePhilosophy?.detailedInformation
          ?.length,
      productCategoryLength:
        response?.data?.page?.aboutUs?.productCategory?.detailedInformation
          ?.length,
      statsOverviewLength:
        response?.data?.page?.aboutUs?.statsOverview?.detailedInformation
          ?.length,
      message: response.message,
      isLoading: false,
    };
  }
};

export const getPageManagementDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const updatePageManagementDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updatePageManagementDataSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Page Management Data Updates successfully!",
      isLoading: false,
    };
  }
};

export const updatePageManagementDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setUpdatePageManagement = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const setPageManagementState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_PAGE_MANAGEMENT_DATA_SUCCESS]: addPageManagementDataSuccess,
  [Types.ADD_PAGE_MANAGEMENT_DATA_FAILURE]: addPageManagementDataFailure,
  [Types.ADD_PAGE_MANAGEMENT_DATA_REQUEST]: addPageManagementDataRequest,

  [Types.GET_PAGE_MANAGEMENT_DATA_SUCCESS]: getPageManagementDataSuccess,
  [Types.GET_PAGE_MANAGEMENT_DATA_FAILURE]: getPageManagementDataFailure,
  [Types.GET_PAGE_MANAGEMENT_DATA_REQUEST]: getPageManagementDataRequest,

  [Types.UPDATE_PAGE_MANAGEMENT_DATA_SUCCESS]: updatePageManagementDataSuccess,
  [Types.UPDATE_PAGE_MANAGEMENT_DATA_FAILURE]: updatePageManagementDataFailure,
  [Types.UPDATE_PAGE_MANAGEMENT_DATA_REQUEST]: updatePageManagementDataRequest,

  [Types.SET_UPDATE_PAGE_MANAGEMENT]: setUpdatePageManagement,
  [Types.SET_PAGE_MANAGEMENT_STATE]: setPageManagementState,
});
