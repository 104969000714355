import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { projectListHoablPage1Action } from "redux-container/page-management/hoabl-page-1/ProjectListInHoablPage1Redux";
import { call, put } from "redux-saga/effects";

export function* getProjectContentList(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getProjectContentList, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        projectListHoablPage1Action.projectListHoablPage1Success(response.data)
      );
    } else {
      yield put(
        projectListHoablPage1Action.projectListHoablPage1Failure(response.data)
      );
    }
  } catch (error) {
    yield put(projectListHoablPage1Action.projectListHoablPage1Failure(error));
  }
}
