import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  createPaymentFormSuccess: ["response"],
  createPaymentFormFailure: ["error"],
  createPaymentFormRequest: ["data"],

  getPaymentFormSuccess: ["response"],
  getPaymentFormFailure: ["error"],
  getPaymentFormRequest: ["data"],

  getPaymentInventoriesSuccess: ["response"],
  getPaymentInventoriesFailure: ["error"],
  getPaymentInventoriesRequest: ["data"],

  updatePaymentFormSuccess: ["response"],
  updatePaymentFormFailure: ["error"],
  updatePaymentFormRequest: ["id", "data"],

  setPaymentFormState: ["key", "value"],
});

export const PaymentFormTypes = Types;

export const PaymentFormActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  paymentForm: [],
  paymentInventories: null,
  formId: null,
  shouldDialogOpen: false,
  responseMessage: null,
  isSuccess: false,
  shouldNavigateBackOnError: false,
};

//* Getting all added users
export const getPaymentFormRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
    paymentForm: [],
  };
};

export const getPaymentFormSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      paymentForm: response.data,
      isSuccess: true,
      isLoading: false,
      shouldDialogOpen: false,
      // responseMessage: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getPaymentFormFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: false,
    error: action.error.message,
  };
};

//* Get inventory based on launch phase ID
export const getPaymentInventoriesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
    shouldNavigateBackOnError: false,
  };
};

export const getPaymentInventoriesSuccess = (state: any, action: any) => {
  const response = action.response;

  const constructSkuNameAndRange = (skuName: string, range: any) => {
    let areaRange = "";
    if (range?.from && range?.to) {
      areaRange = `(${range?.from} - ${range?.to} Sqft)`;
    }
    return skuName + areaRange;
  };

  if (response) {
    return {
      ...state,
      error: null,
      paymentInventories: {
        bookingAmount: response?.data?.bookingAmount,
        choiceOfPlots: response?.data?.crmInventoryBuckets?.map((plot: any) =>
          // `${plot?.name}(${plot?.areaRange?.from} - ${plot?.areaRange?.to} Sqft)`
          constructSkuNameAndRange(
            plot?.displayName || plot?.name,
            plot?.areaRange
          )
        ),
      },
      isSuccess: true,
      isLoading: false,
      shouldDialogOpen: false,
      // responseMessage: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getPaymentInventoriesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: true,
    error: action.error.message,
    paymentInventories: null,
    shouldNavigateBackOnError: true,
  };
};

//* Adding a new payment details

export const createPaymentFormRequest = (state: any) => {
  return {
    ...state,
    // isLoading: true,
    isSuccess: false,
  };
};

export const createPaymentFormSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      paymentForm: response.data,
      isSuccess: true,
      isLoading: false,
      shouldDialogOpen: true,
      responseMessage: response.message,
      shouldNavigateBackOnError: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const createPaymentFormFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: true,
    error: action.error.message,
  };
};

//* Update existing payment details

export const updatePaymentFormRequest = (state: any) => {
  return {
    ...state,
    // isLoading: true,
    isSuccess: false,
  };
};

export const updatePaymentFormSuccess = (state: any, action: any) => {
  const { response } = action;

  if (response) {
    return {
      ...state,
      error: null,
      paymentForm: response.data,
      isLoading: false,
      isSuccess: true,
      shouldDialogOpen: true,
      responseMessage: response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const updatePaymentFormFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    shouldDialogOpen: true,
    error: action.error.message,
  };
};

export const setPaymentFormState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_PAYMENT_FORM_FAILURE]: createPaymentFormFailure,
  [Types.CREATE_PAYMENT_FORM_SUCCESS]: createPaymentFormSuccess,
  [Types.CREATE_PAYMENT_FORM_REQUEST]: createPaymentFormRequest, //post payment detail(s)

  [Types.GET_PAYMENT_FORM_SUCCESS]: getPaymentFormSuccess,
  [Types.GET_PAYMENT_FORM_FAILURE]: getPaymentFormFailure,
  [Types.GET_PAYMENT_FORM_REQUEST]: getPaymentFormRequest, //get payment details

  [Types.GET_PAYMENT_INVENTORIES_SUCCESS]: getPaymentInventoriesSuccess,
  [Types.GET_PAYMENT_INVENTORIES_FAILURE]: getPaymentInventoriesFailure,
  [Types.GET_PAYMENT_INVENTORIES_REQUEST]: getPaymentInventoriesRequest, //get inventory details

  [Types.UPDATE_PAYMENT_FORM_SUCCESS]: updatePaymentFormSuccess,
  [Types.UPDATE_PAYMENT_FORM_FAILURE]: updatePaymentFormFailure,
  [Types.UPDATE_PAYMENT_FORM_REQUEST]: updatePaymentFormRequest, //update payment details

  [Types.SET_PAYMENT_FORM_STATE]: setPaymentFormState,
});
