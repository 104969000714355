import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/ui-elements";
import React, { useEffect, useState } from "react";
import { CustomerPortpolioSections } from "./customer-portfolio-components/CustomerPortpolioSections";
import { InvestmentDetailsCard } from "./customer-portfolio-components/InvestmentDetailsCard";
import { createBrowserHistory } from "history";
import { CustomerActions } from "redux-container/customer-management/CustomersListAndDetailsRedux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerInvestmentSummary: async (customerId: any) => {
      dispatch(CustomerActions.getCustomerInvestmentSummaryRequest(customerId));
    },
  };
};

export const CustomerPortfolio = () => {
  const location: any = useLocation();
  const [selectedProject, setSelectedProject] = useState<any>(null);

  const { customerInvestmentSummary  } = useSelector((state: any) => {
    return {
      customerInvestmentSummary:
        state?.customerListAndDetails?.customerSummary?.projects,
    };
  });
  const [showAccordionId, setShowAccordionId] = useState<number | null>(0);

  const { getCustomerInvestmentSummary } = actionDispatch(useDispatch());

  useEffect(() => {
    const params = {
      cmsId: location?.state?.customerData?.id,
      projectName: selectedProject?.launchName,
    };
    getCustomerInvestmentSummary(params);
    return () => {};
  }, []);

  return (
    <>
      <InvestmentDetailsCard />

      {CustomerPortpolioSections()?.map(
        (CustomerPortpolioSection: any, index: number) => {
          const NewComponent = CustomerPortpolioSection
            ? CustomerPortpolioSection.component
            : null;
          if (index === 0 && !(customerInvestmentSummary?.length > 0)) {
            return null;
          }
          return (
            <Accordion
              key={index}
              expanded={showAccordionId === CustomerPortpolioSection?.id}
              onChange={(event: any, newExpanded: boolean) => {
                setShowAccordionId(
                  newExpanded ? CustomerPortpolioSection.id : null
                );
              }}
            >
              <AccordionSummary
                aria-controls={`panel${CustomerPortpolioSection?.id}-content`}
                id={`panel${CustomerPortpolioSection?.id}-header`}
              >
                <Typography variant="body1">
                  {CustomerPortpolioSection.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {showAccordionId === CustomerPortpolioSection?.id ? (
                  <NewComponent />
                ) : null}
              </AccordionDetails>
            </Accordion>
          );
        }
      )}
    </>
  );
};
