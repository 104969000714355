import { styled } from "@mui/material";

const ProjectTimelineStyleWrapper = styled("section")`
  .section-gutter {
    padding: 1.5rem;
    & .no-gutter {
      padding: 0;
    }
  }
  .button-section {
    justify-content: flex-end;
  }
  .field-container-box {
    padding: 1.5rem 1.5rem 0.5rem;
    justify-content: flex-start;
    column-gap: 1.2rem;
  }
  .field-container-bottom {
    padding: 0rem 1.5rem 1.5rem;
    justify-content: flex-start;
    column-gap: 1.2rem;
  }
  .section-heading {
    margin: 0.5rem 0 2rem;
  }
  .picker-bottom-spacing {
    margin-bottom: 0.8rem;
  }
`;

export { ProjectTimelineStyleWrapper };
