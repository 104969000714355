export interface IFaqProps {
  categoryId?: string;
  isCategoryField?: boolean;
  faqs: {
    faqQuestion: {
      question: String;
    };
    faqAnswer: {
      answer: string;
    };
    priority?: number | string;
    isEdit?: boolean;
    faqId?: number | string;
  }[];
}

const FAQInitialValues: IFaqProps = {
  categoryId: "",
  isCategoryField: true,
  faqs: [
    {
      faqQuestion: {
        question: "",
      },
      faqAnswer: {
        answer: "",
      },
      // priority: 0,
      isEdit: false,
    },
  ],
};

export const FAQcreationLabels: any = {
  categoryId: "Category",
  isCategoryField: true,
  faqs: [
    {
      faqQuestion: {
        question: "Question",
      },
      faqAnswer: {
        answer: "Answer",
      },
      // priority: 0,
      isEdit: false,
      faqId: 0,
    },
  ],
};

const faqType = [
  { id: 1, label: "General FAQ", value: "General" },
  { id: 2, label: "Project FAQ", value: "Project" },
];

const CategoryList: Array<{ id: number; title: string }> = [
  { id: 1, title: "Land SKUs" },
  { id: 2, title: "Payment" },
  { id: 3, title: "Account" },
  { id: 4, title: "KYC" },
];

const ProjectList: Array<{ id: number; title: string }> = [
  { id: 1, title: "Dapoli" },
  { id: 2, title: "Anjarle" },
  { id: 3, title: "Goa" },
  { id: 4, title: "KYC" },
];

export { faqType, CategoryList, ProjectList, FAQInitialValues };
