import * as React from "react";
import { Fragment, useState, useEffect } from "react";

import {
  Stack,
  Typography,
  Container,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonComponent,
  LoadingButtonComponent,
} from "components/ui-elements";
import PageHeading from "components/page-heading/PageHeading";
import { RouteConstants } from "routes/RouteConstants";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { ProjectContentSections } from "./ProjectContentSections";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { InitinalValueProjectContent } from "./InitinalValuesProjectContent";
import { useDispatch, useSelector } from "react-redux";
import ProjectContentCreationAction from "redux-container/project-management/project-content/ProjectContentRedux";
import { launchPhasesAction } from "redux-container/project-management/launch-phases/LaunchPhasesRedux";
import { useLocation, useNavigate } from "react-router-dom";
import { Delayed } from "utils/DelayRender";
import { validationSchemaforProjectContentCreation } from "./ProjectContentCreationValidation";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import projectListActions from "redux-container/project-management/ProjectListRedux";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Loader from "components/loader/Loader";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import ProjectAllFaqsListAction from "redux-container/project-management/project-content/ProjectAllFaqsListRedux";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import ProjectDraftsList from "redux-container/project-management/project-drafts/ProjectDraftsRedux";
import ProjectCloneList from "./project-clone-list/ProjectCloneList";
import ProjectCloneConfirmDialog from "./project-clone-confim-dialog/ProjectCloneConfirmDialog";
import MediaAssets from "assets";
import styles from "./ProjectContentCreation.module.css";
import { FaqsTypeEnum } from "enumerations/FaqsTypes";

const actionDispatch = (dispatch: any) => {
  return {
    getLaunchPhasesRequest: (id: any) =>
      dispatch(launchPhasesAction.getLaunchPhasesRequest(id)),
    getProjectContentCreationDataRequest: (id: any) =>
      dispatch(
        ProjectContentCreationAction.getProjectContentCreationDataRequest(id)
      ),
    addProjectContentCreationDataRequest: (data: any) =>
      dispatch(
        ProjectContentCreationAction.addProjectContentCreationDataRequest(data)
      ),
    updateProjectContentCreationDataRequest: (data: any) =>
      dispatch(
        ProjectContentCreationAction.updateProjectContentCreationDataRequest(
          data
        )
      ),
    setUpdateProjectContentCreation: (key: any, value: any) =>
      dispatch(
        ProjectContentCreationAction.setUpdateProjectContentCreation(key, value)
      ),
    getProjectList: (params: any) =>
      dispatch(projectListActions.projectListRequest(params)),

    updateProjectsStatus: (params: any) =>
      dispatch(projectListActions.updateProjectsStatusRequest(params)),

    deleteProjects: (id: any) =>
      dispatch(projectListActions.deleteProjectsRequest(id)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    getProjectFaqs: (params: any) =>
      dispatch(
        ProjectAllFaqsListAction.getProjectAllFaqsListDataRequest(params)
      ),
    addProjectSaveAsDraft: (data: any) =>
      dispatch(ProjectDraftsList.addProjectDraftRequest(data)),
    updateProjectSaveAsDraft: (data: any) =>
      dispatch(ProjectDraftsList.updateProjectDraftRequest(data)),
    setProjectDraftState: (key: string, value: any) =>
      dispatch(ProjectDraftsList.setProjectDraftState(key, value)),
    setProjectListState: (key: any, data: any) => {
      dispatch(projectListActions.setProjectState(key, data));
    },
    getProjectContentToCloneRequest: (id: any) =>
      dispatch(
        ProjectContentCreationAction.getProjectContentToCloneRequest(id)
      ),
    setProjectContentCreationState: (key: any, value: any) =>
      dispatch(
        ProjectContentCreationAction.setUpdateProjectContentCreation(key, value)
      ),
  };
};

const ProjectContentCreation = () => {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const navigate = useNavigate();
  const location: any = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);
  const [projectCategoriesIds, setProjectCategoriesId] = useState<any>([]);

  const {
    getProjectContentCreationDataRequest,
    getLaunchPhasesRequest,
    addProjectContentCreationDataRequest,
    updateProjectContentCreationDataRequest,
    setUpdateProjectContentCreation,
    getProjectList,
    updateProjectsStatus,
    deleteProjects,
    postAuditLogRequest,
    getProjectFaqs,
    addProjectSaveAsDraft,
    updateProjectSaveAsDraft,
    setProjectDraftState,
    setProjectListState,
    getProjectContentToCloneRequest,
    setProjectContentCreationState,
  } = actionDispatch(useDispatch());
  const {
    projectContentManagement,
    fetchedLaunchPhasesData,
    isConfirmDialogOpen,
    isViewMode,
    modules,
    saveDraftMessage,
    openDraftFlashMessage,
    saveDraftError,
    isDraftSuccess,
    newLaunchDetailsForProjectClone,
  } = useSelector((state: any) => ({
    projectContentManagement:
      state?.projectContentManagement?.projectCreationData,
    prevProjectValues: state?.projectContentManagement?.prevProjectValues,
    isConfirmDialogOpen: state?.projectContentManagement?.isConfirmDialogOpen,
    isViewMode: state?.projectContentManagement?.isViewMode,
    fetchedLaunchPhasesData:
      state?.projectLaunchPhases?.fetchedLaunchPhasesData,
    modules: state?.rolesAndModules?.module,
    openDraftFlashMessage: state?.projectDrafts?.openFlashMessage,
    saveDraftMessage: state?.projectDrafts?.message,
    saveDraftError: state?.projectDrafts?.error,
    isDraftSuccess: state?.projectDrafts?.isSuccess,
    projectCloneStoreData:
      state?.projectContentManagement?.projectCloneData?.projectContent,
    newLaunchDetailsForProjectClone:
      state?.projectContentManagement?.newLaunchDetailsForProjectClone,
  }));

  const openFlashMessage = useSelector(
    (state: any) => state?.projectContentManagement?.openFlashMessage
  );

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  const projectContentDetails = location?.state?.projectContent
    ? location?.state?.projectContent
    : projectContentManagement?.projectContent;

  const breadcrumbRoutes: any = [
    {
      name: "Project Management",
      route: RouteConstants.projectManagementDashboard,
    },
    {
      name: "Projects",
      route: RouteConstants.projectList,
    },
    (location?.state?.isNew || location?.state?.isProjectClone) &&
    !location?.state?.isDraft
      ? {
          name: "Create Content",
          route: RouteConstants.projectCreation,
        }
      : isViewMode
      ? {
          name: `View - ${projectContentDetails?.launchName?.slice(
            0,
            30
          )} : #ID-${
            location?.state?.projectContent
              ? location?.state?.projectContent?.id
              : location?.state?.ProjectListCreationId
          }`,
          route: RouteConstants.projectCreation,
        }
      : {
          name: `${
            location?.state?.isDraft ? "Draft" : "Edit"
          } - ${projectContentDetails?.launchName?.slice(0, 30)} : #ID-${
            location?.state?.projectContent
              ? location?.state?.projectContent?.id
              : location?.state?.id
              ? location?.state?.id
              : location?.state?.ProjectListCreationId
          }`,
          route: RouteConstants.projectCreation,
        },
  ];

  const message = useSelector(
    (state: any) => state?.projectContentManagement?.message
  );
  const error = useSelector(
    (state: any) => state?.projectContentManagement?.error
  );
  const [showFlashMessage, setShowFlashMessage] = React.useState(false);

  const isLoading = useSelector(
    (state: any) => state?.projectContentManagement?.isGetProjectDetailsLoading
  );

  const mappedFaqs = useSelector(
    (state: any) => state?.projectAllfaqs?.allProjectFaqs
  );

  const projectAllFaqsDetails = useSelector(
    (state: any) => state?.projectAllFaqsList?.projectAllFaqsListData
  );

  const handleSubmitProjectContent = (formValues: FormikValues) => {
    setShowFlashMessage(true);
    let getAllProjectFaqIds = projectAllFaqsDetails?.map((faq: any) => faq?.id);
    let removeEmpty = formValues?.reraDetails?.reraNumbers?.filter(
      (item: any) => item !== ""
    );

    let removeQrcode: any = [];
    if (formValues?.reraDetails?.reraCodes?.length > 0) {
      removeQrcode = formValues?.reraDetails?.reraCodes?.filter(
        (item: any) =>
          item?.reraNumber && item?.reraNumber !== "" && item?.qrCode
      );
    }
    let qrcodes: any = [];
    if (
      formValues?.reraDetails?.reraCodes &&
      formValues?.reraDetails?.reraCodes?.length > 0
    ) {
      removeQrcode = [...formValues?.reraDetails?.reraCodes];
      removeQrcode = formValues?.reraDetails?.reraCodes?.filter(
        (item: any) => item !== undefined
      );
    }

    qrcodes = [...removeQrcode];
    removeEmpty.map((item: any, i: number) => {
      if (removeQrcode?.length > 0) {
        let filterr = removeQrcode.filter(
          (itemChange: any) => itemChange.reraNumber == item
        );
        if (filterr.length > 0) {
          if (filterr[0]?.qrCode == undefined) {
            qrcodes[i].qrCode = "false";
          } else if (filterr[0]?.qrCode == "") {
            qrcodes[i].qrCode = "false";
          }
        } else {
          qrcodes.push({ reraNumber: item, qrCode: "false" });
        }
      } else {
        qrcodes.push({ reraNumber: item, qrCode: "false" });
      }
    });
    qrcodes.sort((a: any, b: any) => {
      const indexA = removeEmpty.indexOf(a.reraNumber);
      const indexB = removeEmpty.indexOf(b.reraNumber);
      return indexA - indexB;
    });

    let values = { ...formValues };
    values.reraDetails = {
      ...values.reraDetails,
      reraNumbers: removeEmpty,
      reraCodes: qrcodes,
    };

    if (
      (location.state.isNew && !location?.state?.isDraft) ||
      location?.state?.isProjectClone
    ) {
      let { frequentlyAskedQuestions, ...restProjectContent } = values;
      let faqsValues: any = [];

      const configureFrequentlyAskedQuestions = () => {
        const elements =
          frequentlyAskedQuestions?.length > 0 &&
          frequentlyAskedQuestions?.map((item: any) => {
            if (item) {
              if (item?.category?.projectContentId) {
                delete item?.category?.projectContentId;
              }

              if (item?.projectContentId) {
                item.projectContentId = null;
              }

              faqsValues?.push(item);

              return item;
            }
          });

        return elements;
      };

      configureFrequentlyAskedQuestions();

      let reqBody = {
        ...restProjectContent,
        frequentlyAskedQuestions: faqsValues,
      };

      let { faqsToDisplayInDetails, ...restValues } = restProjectContent;
      let faqsToDisplayInDetailsValues = faqsToDisplayInDetails?.filter(
        (item: any) => getAllProjectFaqIds?.includes(item?.faqId)
      );
      addProjectContentCreationDataRequest({
        ...restValues,
        faqsToDisplayInDetails: faqsToDisplayInDetailsValues,
      });
    } else if (location.state.isNew && location?.state?.isDraft) {
      let { id, faqsToDisplayInDetails, ...restValues } = values;
      let faqsToDisplayInDetailsValues = faqsToDisplayInDetails?.filter(
        (item: any) => getAllProjectFaqIds?.includes(item?.faqId)
      );
      let params = {
        draftId: id,
        faqsToDisplayInDetails: faqsToDisplayInDetailsValues,
        ...restValues,
      };

      addProjectContentCreationDataRequest(params);
    } else {
      let { faqsToDisplayInDetails, ...restValues } = values;
      let faqsToDisplayInDetailsValues = faqsToDisplayInDetails?.filter(
        (item: any) => getAllProjectFaqIds?.includes(item?.faqId)
      );
      updateProjectContentCreationDataRequest({
        ...restValues,
        faqsToDisplayInDetails: faqsToDisplayInDetailsValues,
      });
    }
    setUpdateProjectContentCreation("isConfirmDialogOpen", false);
  };

  const handleDialogClose = () => {
    setUpdateProjectContentCreation("isConfirmDialogOpen", false);
    setUpdateProjectContentCreation("error", null);
  };

  const [oldProjectValueForAudit, setOldProjectValueForAudit] = useState<any>();
  const [shouldSetPreviousValue, setShouldSetPreviousValue] = useState(false);
  const [openProjectDialogToClone, setOpenProjectDialogToClone] =
    useState(false);

  const [projectForClone, setProjectForClone] = useState<any>([]);
  const [isProjectClone, setIsProjectClone] = useState<boolean>(false);
  const [valuesForProjectClone, setValuesForProjectClone] = useState<any>({});
  const [openProjectCloneConfirmation, setOpenProjectCloneConfirmation] =
    useState<boolean>(false);

  // calling CRM lauch phase by id to get inventory details while creating new project
  React.useEffect(() => {
    setUpdateProjectContentCreation("isViewMode", location.state?.isViewMode);
    if (
      location?.state?.isNew &&
      !location?.state?.isDraft &&
      !location?.state?.isProjectClone
    ) {
      getLaunchPhasesRequest(location?.state?.ProjectListCreationId);
    } else if (!location?.state?.projectContent) {
      // getLaunchPhasesRequest(location?.state?.crmLaunchPhaseId);
      getProjectContentCreationDataRequest(
        location?.state?.ProjectListCreationId
      );
    }
  }, [location?.state?.isProjectClone]);

  useEffect(() => {
    setUpdateProjectContentCreation("openFlashMessage", false);
    setProjectListState("openFlashMessage", false);
    setProjectDraftState("openFlashMessage", false);
    getProjectList({});
  }, []);

  const handleChange =
    (panel: string, setState: any, id: any) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      setState(id);
    };

  const handleOnGlobalSave = (values: any) => {
    console.log("submit executed");

    if (values.opportunityDoc.brochure == null) {
      delete values.opportunityDoc.brochure;
    }
    if (values.projectIcon == null) {
      delete values.projectIcon;
    }
    if (values.projectPhoneNumber == null) {
      delete values.projectPhoneNumber;
    }

    const getCategoryParams = {
      typeOfFAQ: FaqsTypeEnum.ProjectFaq,
      shouldDisplayMappedFaqs: false,
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    getProjectFaqs(getCategoryParams);
    setShouldSetPreviousValue(true);
    setUpdateProjectContentCreation("isConfirmDialogOpen", true);
  };

  const handleEditClick = () => {
    setUpdateProjectContentCreation("isViewMode", false);
  };

  const handleOpenSettingMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSettingMenu = () => {
    setAnchorEl(null);
  };

  const mappedFaqsWithPriority =
    mappedFaqs?.length > 0 &&
    mappedFaqs?.map((item: any) => ({
      faqId: item?.id,
      priority: item?.priority,
    }));

  const mappedFaqsCategories = mappedFaqs?.map((item: any) => item?.id);

  const handleCloseFlashMessageDialog = async (values: any) => {
    setValuesForProjectClone(null);
    setProjectForClone(null);
    setIsProjectClone(false);
    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Project Management";
    });
    if (location?.state?.isNew) {
      const postAuditRequestbody = {
        ipAddress: ipAddress, //ipAddress
        moduleId: moduleObject?.id,
        route: location.pathname,
        auditedObject: values,
        modulesAudited: [
          {
            itemAffected: `Project ${values?.launchName} Created.`,
            newValue: "",
            oldValue: "",
          },
        ],
        status: AuditStatus.CREATED,
      };
      postAuditLogRequest(postAuditRequestbody);
    } else {
      let removeEmpty = await values?.reraDetails?.reraNumbers?.filter(
        (item: any) => item !== ""
      );

      let removeEmptyRera = /// remove empty strings from reranumbers.
        values?.reraDetails?.reraNumbers[0] === ""
          ? []
          : values?.reraDetails?.reraNumbers;

      //get project mapped faqs category id
      let projectCategoryIds = await values?.frequentlyAskedQuestions?.map(
        (item: any) => item?.categoryId
      );

      // remove duplicate categories
      let uniqueCategories = await projectCategoryIds?.filter(
        (item: any, pos: any) => {
          return projectCategoryIds?.indexOf(item) == pos;
        }
      );

      let newProjectContentValues = { ...values };
      newProjectContentValues.reraDetails = {
        ...newProjectContentValues.reraDetails,
        reraNumbers: removeEmptyRera,
      };

      let newProjectcontentFaqs = await projectAllFaqsDetails?.filter(
        (faqs: any) => uniqueCategories.includes(faqs?.categoryId)
      );

      let oldValueForProjectContent = {
        ...oldProjectValueForAudit,
        frequentlyAskedQuestions:
          oldProjectValueForAudit?.frequentlyAskedQuestions?.sort(function (
            prev: any,
            current: any
          ) {
            return prev?.id - current?.id;
          }),
      };

      let projectOldValues = location?.state?.projectContent // grouping and checking for present operation(audit, draft or edit project )
        ? location?.state?.projectContent
        : oldValueForProjectContent;

      let removeEmptyFromOldValues =
        projectOldValues?.reraDetails?.reraNumbers?.filter(
          (item: any) => item !== ""
        );
      projectOldValues.reraDetails = {
        ...projectOldValues.reraDetails,
        reraNumbers: removeEmpty,
      };
      projectOldValues.frequentlyAskedQuestions =
        projectOldValues?.frequentlyAskedQuestions?.sort(
          (currentFaq: any, previousFaq: any) =>
            currentFaq?.id - previousFaq?.id
        );

      const postAuditRequestbody = {
        ipAddress: ipAddress, //ipAddress
        moduleId: moduleObject?.id,
        route: location.pathname,
        auditedObject: values,
        // modulesAudited: modulesAudited,
        status: AuditStatus.EDITED,
        projectContentModel: {
          ...newProjectContentValues,
          frequentlyAskedQuestions: newProjectcontentFaqs?.sort(function (
            prev: any,
            current: any
          ) {
            return prev?.id - current?.id;
          }),
        },
        oldProjectContentModel: projectOldValues,
        isUpdate: false,
      };
      postAuditLogRequest(postAuditRequestbody);
    }
    setUpdateProjectContentCreation("openFlashMessage", false);
    if (!error) {
      if (location?.state?.isNavigatedFromAudit) {
        navigate(RouteConstants.auditLog);
      } else {
        navigate(RouteConstants.projectList);
      }
    }
  };

  const handleProjectDelete = async (id: any, values: any) => {
    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Project Management";
    });
    const params = {
      contentIds: id,
    };
    await deleteProjects(params);

    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: null,
      auditedObject: null,
      modulesAudited: [
        {
          itemAffected: `Project ${values?.launchName} - id: ${values?.id} Deleted.`,
          newValue: "",
          oldValue: "",
        },
      ],
      status: AuditStatus.DELETED,
    };
    !error && (await postAuditLogRequest(postAuditRequestbody));

    !error && navigate(RouteConstants.projectList);
  };

  const changeUpdateStatus = async (status: any, id: any, values?: any) => {
    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Project Management";
    });
    const params = {
      contentIds: id,
      status: status,
    };
    await updateProjectsStatus(params);

    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: null,
      auditedObject: null,
      modulesAudited: [
        {
          itemAffected: `Status of Project ${values?.launchName} - id: ${values?.id}.`,
          newValue: status === "1001" ? "1001" : "1002",
          oldValue: status === "1001" ? "1002" : "1001",
        },
      ],
      status: AuditStatus.EDITED,
    };
    !error && (await postAuditLogRequest(postAuditRequestbody));

    !error && navigate(RouteConstants.projectList);
  };

  const projectContentAndFaqs = useSelector(
    (state: any) =>
      state?.projectContentManagement?.projectCreationData
        ?.projectContentsAndFaqs
  );
  const projectContentAndFaqsIds = projectContentAndFaqs?.filter(
    (item: any) =>
      item && {
        faqId: item?.faqId,
        priority: item?.priority,
      }
  );
  const getFrequentlyAskedQuestions = useSelector(
    (state: any) =>
      state?.projectContentManagement?.projectCreationData?.projectContent
        ?.frequentlyAskedQuestions
  );
  const getFrequentlyAskedQuestionsIds = getFrequentlyAskedQuestions?.map(
    (item: any) => item?.id
  );

  let finalReqBodyForUpdate: any = {};
  if (projectContentManagement?.projectContent) {
    const {
      mediaGalleryOrProjectContent,
      latestMediaGalleryOrProjectContent,
      similarInvestments,
      ...projectContentRest
    } = projectContentManagement?.projectContent;
    finalReqBodyForUpdate = {
      ...projectContentRest,
      mediaGallery: mediaGalleryOrProjectContent[0],
      latestMediaGallery: latestMediaGalleryOrProjectContent[0],
    };
  }

  // construting request body for api calls
  const updatedValues: any = {
    ...finalReqBodyForUpdate,
    faqsToDisplayInDetails: projectContentAndFaqsIds,
    mappedFaqs: getFrequentlyAskedQuestionsIds,
    mappedFaqSectionHeader: "faqs",
  };

  useEffect(() => {
    if (shouldSetPreviousValue) {
      let removeEmpty = updatedValues?.reraDetails?.reraNumbers?.filter(
        (item: any) => item !== ""
      );
      let values = { ...updatedValues };
      values.reraDetails = { ...values.reraDetails, reraNumbers: removeEmpty };
      values.frequentlyAskedQuestions = values?.frequentlyAskedQuestions?.sort(
        (currentFaq: any, previousFaq: any) => currentFaq?.id - previousFaq?.id
      );

      setOldProjectValueForAudit(values);
      setShouldSetPreviousValue(false);
    }
  }, [shouldSetPreviousValue]);

  const projectFormikValues = () => {
    return isProjectClone && updatedValues
      ? projectCloneValues()
      : location?.state?.isDraft
      ? location?.state?.projectContent
      : location?.state?.isNavigatedFromAudit
      ? location?.state?.projectContent
      : fetchedLaunchPhasesData !== null && location?.state?.isNew
      ? { ...InitinalValueProjectContent }
      : projectContentManagement !== null && !location?.state?.isNew
      ? updatedValues
      : location?.state !== undefined
      ? location?.state
      : InitinalValueProjectContent;
  };

  const handleClickSaveAsDraft = (formValues: any) => {
    let removeEmpty = formValues?.reraDetails?.reraNumbers?.filter(
      (item: any) => item !== ""
    );
    let values = { ...formValues };
    values.reraDetails = { ...values.reraDetails, reraNumbers: removeEmpty };
    if (location?.state?.projectContent?.id) {
      updateProjectSaveAsDraft(values);
    } else {
      addProjectSaveAsDraft(values);
      setValuesForProjectClone(null);
      setProjectForClone(null);
      setIsProjectClone(false);
    }
  };

  const handleCloseSaveDraftFlassMessage = () => {
    setProjectDraftState("error", null);
    setProjectDraftState("message", null);
    setProjectDraftState("openFlashMessage", false);
    if (!saveDraftError) {
      navigate(RouteConstants.projectList);
    }
  };

  const handleSetValuesForProjectClone = async (newProjectValues: any) => {
    setIsProjectClone(true);
    setValuesForProjectClone(newProjectValues);
    setProjectContentCreationState(
      "newLaunchDetailsForProjectClone",
      newProjectValues
    );
    // await getProjectContentToCloneRequest(projectForClone[0]?.id);
    navigate(RouteConstants.projectCreation, {
      state: {
        isNew: false,
        ProjectListCreationId: projectForClone[0]?.id,
        isViewMode: false,
        isEditMode: false,
        isProjectClone: true,
      },
    });
    setOpenProjectCloneConfirmation(false);
  };

  const projectCloneValues = () => {
    // removing unwanted keys from copy of a existing project for clone
    delete updatedValues?.id;
    delete updatedValues?.crmProjectId;
    delete updatedValues?.crmLaunchPhaseId;
    delete updatedValues?.createdBy;
    delete updatedValues?.updatedBy;
    delete updatedValues?.updatedAt;
    delete updatedValues?.createdAt;
    delete updatedValues?.inventoryBucketContents;
    delete updatedValues?.crmProject;
    delete updatedValues?.crmLaunchPhase;
    delete updatedValues?.priority;
    delete updatedValues?.status;
    delete updatedValues?.launchName;
    // assigning new values launch details
    // updatedValues.generalInfoEscalationGraph.crmLaunchPhaseName =
    //   newLaunchDetailsForProjectClone?.generalInfoEscalationGraph?.crmLaunchPhaseName;
    // updatedValues.generalInfoEscalationGraph.crmProjectName =
    //   newLaunchDetailsForProjectClone?.generalInfoEscalationGraph?.crmProjectName;
    // updatedValues.generalInfoEscalationGraph.estimatedAppreciation = 1000;

    let {
      areaStartingFrom,
      priceStartingFrom,
      generalInfoEscalationGraph,
      address,
      inventoryBucketContents,
      mediaGallery,
      latestMediaGallery,
      projectTimelines,
      opportunityDoc,
      ...restProjectCloneStoreData
    } = updatedValues;

    const newProjectTimelines = projectTimelines?.map((timeline: any) => {
      delete timeline?.id;
      delete timeline?.projectContentId;
      delete timeline?.createdAt;
      delete timeline?.updatedAt;

      return timeline;
    });

    if (
      address?.mapMedia?.value?.url &&
      !valuesForProjectClone?.address?.mapMedia?.value?.url
    ) {
      valuesForProjectClone.address.mapMedia = address.mapMedia;
    }

    // removing unwanted ids and object structuring
    const removeIdsFromMediaGallery = (content: any) => {
      if (content?.id) {
        delete content?.id;
      }
      if (content?.mediaGalleryProjectContentId) {
        delete content?.mediaGalleryProjectContentId;
      }
      if (content?.latestMediaGalleryProjectContentId) {
        delete content?.latestMediaGalleryProjectContentId;
      }
      if (content?.latestMediaGalleryProjectCon) {
        delete content?.latestMediaGalleryProjectCon;
      }
      if (content?.projectContentId) {
        delete content?.projectContentId;
      }
      return content;
    };

    const removeIdAndProjectContentId = (content: any) => {
      delete content?.id;
      delete content?.projectContentId;
      return content;
    };

    let projectCloneValues = {
      ...newLaunchDetailsForProjectClone,
      ...restProjectCloneStoreData,
      projectTimelines: newProjectTimelines,
      mediaGallery: removeIdsFromMediaGallery(mediaGallery),
      latestMediaGallery: removeIdsFromMediaGallery(latestMediaGallery),
      opportunityDoc: removeIdAndProjectContentId(opportunityDoc),
      generalInfoEscalationGraph: {
        ...generalInfoEscalationGraph,
        crmLaunchPhaseName:
          newLaunchDetailsForProjectClone?.generalInfoEscalationGraph
            ?.crmLaunchPhaseName,
        crmProjectName:
          newLaunchDetailsForProjectClone?.generalInfoEscalationGraph
            ?.crmProjectName,
      },
      isCloned: true,
    };

    return projectCloneValues;
  };

  const isAccordionValidator = (
    name: string,
    errors: any,
    touched: any,
    values: any
  ) => {
    switch (name) {
      case "generalInformation":
        if (
          (errors?.launchName && touched?.launchName) ||
          (errors?.shortDescription && touched?.shortDescription) ||
          (errors?.fullDescription && touched?.fullDescription) ||
          (errors?.generalInfoEscalationGraph &&
            touched?.generalInfoEscalationGraph) ||
          (errors?.address?.mapMedia?.value?.url &&
            touched?.address?.mapMedia?.value?.url)
        ) {
          return true;
        }
        return false;

      case "faqSection":
        if (
          errors?.otherSectionHeadings?.faqSection &&
          touched?.otherSectionHeadings?.faqSection
        ) {
          return true;
        }
        return false;

      default:
        return errors.hasOwnProperty(name) && touched.hasOwnProperty(name);
    }
  };

  return (
    <React.Fragment>
      <PageHeading title="Project Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      {isLoading ? (
        <Loader />
      ) : (
        <Delayed>
          <Formik
            initialValues={projectFormikValues()}
            validationSchema={validationSchemaforProjectContentCreation}
            onSubmit={handleOnGlobalSave}
          >
            {({ values, isValid, errors, touched }) => (
              <Form noValidate>
                {openProjectDialogToClone && (
                  <ProjectCloneList
                    shouldOpen={openProjectDialogToClone}
                    cancelHandler={() => setOpenProjectDialogToClone(false)}
                    mapProjectList={setProjectForClone}
                    initialMapList={projectForClone}
                    onCloneProjectClick={() =>
                      setOpenProjectCloneConfirmation(true)
                    }
                  />
                )}
                {projectForClone?.length > 0 &&
                  openProjectCloneConfirmation && (
                    <ProjectCloneConfirmDialog
                      content={"Are you sure you want to Clone?"}
                      actionContent={`#ID${projectForClone[0]?.id} ${projectForClone[0]?.launchName}`}
                      actionSubContent={
                        projectForClone[0]?.address?.city
                          ? `${projectForClone[0]?.address?.city}${
                              projectForClone[0]?.address?.state
                                ? ", " + projectForClone[0]?.address?.state
                                : ""
                            }`
                          : projectForClone[0]?.address?.state
                      }
                      shouldOpen={openProjectCloneConfirmation}
                      okText={"Yes"}
                      cancelBtnText={"No"}
                      cancelHandler={() =>
                        setOpenProjectCloneConfirmation(false)
                      }
                      okHandler={() => handleSetValuesForProjectClone(values)}
                    />
                  )}

                <Container className="main-container">
                  <Stack>
                    <Stack textAlign={"start"}>
                      <IconButton
                        edge={"start"}
                        onClick={() => navigate(RouteConstants.projectList)}
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                      <Typography variant="h5">
                        {location?.state?.isProjectClone
                          ? `Project ID : #ID-${newLaunchDetailsForProjectClone?.crmLaunchPhaseId}`
                          : location?.state?.isNew
                          ? `Project ID : #ID-${
                              location?.state?.projectContent &&
                              !location?.state?.isProjectClone
                                ? location?.state?.projectContent?.id
                                : location?.state?.ProjectListCreationId
                            }`
                          : `${projectContentDetails?.launchName?.slice(
                              0,
                              30
                            )} :#ID-${
                              location?.state?.projectContent
                                ? location?.state?.projectContent?.id
                                : location?.state?.id
                                ? location?.state?.id
                                : location?.state?.ProjectListCreationId
                            }`}
                      </Typography>
                      {location?.state?.isEditMode &&
                        !location?.state?.isProjectClone && (
                          <>
                            <span
                              onClick={
                                !isViewMode
                                  ? handleOpenSettingMenuClick
                                  : () => {}
                              }
                              className={"margin-left-15px align-center"}
                              aria-controls={open ? "account-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                            >
                              {isViewMode ? (
                                <SettingsOutlinedIcon color={"disabled"} />
                              ) : (
                                <SettingsOutlinedIcon />
                              )}
                            </span>

                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleCloseSettingMenu}
                              onClick={handleCloseSettingMenu}
                              hideBackdrop
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              {projectContentManagement?.projectContent
                                ?.status === "1001" ? (
                                <MenuItem
                                  onClick={() =>
                                    changeUpdateStatus(
                                      "1002",
                                      [location?.state?.ProjectListCreationId],
                                      values
                                    )
                                  }
                                >
                                  Mark As Inactive
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  onClick={() =>
                                    changeUpdateStatus(
                                      "1001",
                                      [location?.state?.ProjectListCreationId],
                                      values
                                    )
                                  }
                                >
                                  Mark As Published
                                </MenuItem>
                              )}
                              <MenuItem
                                onClick={() =>
                                  handleProjectDelete(
                                    [location?.state?.ProjectListCreationId],
                                    values
                                  )
                                }
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </>
                        )}

                      {(location?.state?.isNew ||
                        location?.state?.isProjectClone) &&
                        !location?.state?.isDraft && (
                          <Tooltip
                            title={"Clone Project"}
                            placement={"top-start"}
                            arrow
                          >
                            <IconButton
                              onClick={() => {
                                setProjectForClone([]);
                                setOpenProjectDialogToClone(true);
                              }}
                            >
                              <img
                                className={styles["clone-icon"]}
                                src={MediaAssets.ic_clone}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Stack>
                    <Stack columnGap={2}>
                      <Collapse in={!isViewMode} orientation={"horizontal"}>
                        <ButtonComponent
                          onClick={() => navigate(RouteConstants.projectList)}
                        >
                          Cancel
                        </ButtonComponent>
                      </Collapse>
                      {(location?.state?.isEditMode === false ||
                        location?.state?.isNavigatedFromAudit === false ||
                        location?.state?.isNew === true ||
                        location?.state?.isDraft === true) && (
                        <ButtonComponent
                          onClick={() => handleClickSaveAsDraft(values)}
                        >
                          Save as Drafts
                        </ButtonComponent>
                      )}
                      <Collapse in={!isViewMode} orientation={"horizontal"}>
                        <LoadingButtonComponent
                          type="submit"
                          // disabled={!isValid}
                        >
                          Publish
                        </LoadingButtonComponent>
                      </Collapse>
                      <Collapse in={isViewMode} orientation={"horizontal"}>
                        <LoadingButtonComponent
                          type="button"
                          onClick={handleEditClick}
                        >
                          Edit
                        </LoadingButtonComponent>
                      </Collapse>
                    </Stack>
                  </Stack>
                  {ProjectContentSections()?.map(
                    (projectManagementInfo: any, index: any) => (
                      <Accordion
                        key={index}
                        expanded={
                          expanded === `panel${projectManagementInfo?.id}`
                        }
                        onChange={handleChange(
                          `panel${projectManagementInfo.id}`,
                          projectManagementInfo.setStatus,
                          projectManagementInfo.id
                        )}
                        sx={
                          // errors.hasOwnProperty(projectManagementInfo.name) &&
                          // touched.hasOwnProperty(projectManagementInfo.name)
                          isAccordionValidator(
                            projectManagementInfo.name,
                            errors,
                            touched,
                            values
                          )
                            ? {
                                border: "1px solid red",
                              }
                            : {}
                        }
                      >
                        <AccordionSummary
                          aria-controls={`panel${projectManagementInfo?.id}-content`}
                          id={`panel${projectManagementInfo?.id}-header`}
                        >
                          <Typography variant="body1">
                            {projectManagementInfo.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {projectManagementInfo?.component}
                        </AccordionDetails>
                      </Accordion>
                    )
                  )}
                </Container>
                <ConfirmationDialog
                  shouldOpen={isConfirmDialogOpen}
                  content="Are you sure you want to Publish?"
                  okText="Publish"
                  okHandler={() => handleSubmitProjectContent(values)}
                  cancelHandler={handleDialogClose}
                />

                {openDraftFlashMessage ? (
                  <FlashMessageDialog
                    shouldOpen={openDraftFlashMessage}
                    content={isDraftSuccess ? saveDraftMessage : saveDraftError}
                    isSuccess={isDraftSuccess ? true : false}
                    cancelHandler={handleCloseSaveDraftFlassMessage}
                  />
                ) : null}
                {message && openFlashMessage ? (
                  <FlashMessageDialog
                    shouldOpen={true}
                    content={message ? message : "Something Went Wrong!"}
                    isSuccess={true}
                    cancelHandler={() => handleCloseFlashMessageDialog(values)}
                  />
                ) : null}

                {error && openFlashMessage ? (
                  <FlashMessageDialog
                    shouldOpen={true}
                    content={error}
                    isSuccess={false}
                    cancelHandler={() => handleCloseFlashMessageDialog(values)}
                  />
                ) : null}
              </Form>
            )}
          </Formik>
        </Delayed>
      )}
    </React.Fragment>
  );
};

export default ProjectContentCreation;
