import { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { menus } from "./SidemenuConfiguration";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./Sidemenu.module.css";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import UserActions from "redux-container/users/UsersRedux";
import { useDispatch, useSelector } from "react-redux";
import { RouteConstants } from "routes/RouteConstants";

const actionDispatch = (dispatch: any) => {
  return {
    setUsersState: (key: any, data: any) =>
      dispatch(UserActions.setUsersState(key, data)),
  };
};

const Sidemenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUsersState } = actionDispatch(useDispatch());

  const activeSideMenu = useSelector(
    (state: any) => state?.users?.activeSideMenu
  );
  const isCustomerSidemenu = useSelector(
    (state: any) => state?.users?.isCustomerSidemenu
  );
  const isRouteUnderActiveSideMenu = useSelector(
    (state: any) => state?.users?.isRouteUnderActiveSideMenu
  );
  const defaultMenuToBeExpanded = "CMS User Management";
  const [expanded, setExpanded] = useState<string | false>(activeSideMenu);
  const [menus, setMenus] = useState<any>([]);
  const [leadMenuActive, setLeadMenuActive] = useState<any>(false);
  useEffect(() => {
    const admin = getItemFromLocalStorage(Constants.loggedInUser);
    console.log("type", admin);
    // if (admin?.admin?.roleId != 2) {
    //   navigate("/");
    //   window.scrollTo(0, 0);
    // }
  }, []);

  useEffect(() => {
    setExpanded(activeSideMenu);
  }, [activeSideMenu]);

  useEffect(() => {
    configureSidemenu();
  }, []);

  const configureSidemenu = () => {
    const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);
    const sidemenu = loggedInUser?.sideMenuList
      ? loggedInUser?.sideMenuList
      : [];
    sidemenu?.push({
      id: 13,
      name: "Payment Form Management",
      route: RouteConstants.paymentFormManagement,
      children: [
        {
          name: "Projects",
          route: RouteConstants.formLists,
        },
      ],
    });
    setMenus(sidemenu);
  };

  const navigateToDashboard = (route: string) => {
    navigate(route, { state: { isNavigationFromSidemenu: true } });
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      setLeadMenuActive(false);
      setUsersState("activeSideMenu", panel);
      if (
        panel === "Customer Management" ||
        panel === "Payment Form Management"
      ) {
        setUsersState("isCustomerSidemenu", true);
      } else {
        if (isCustomerSidemenu) {
          setUsersState("isCustomerSidemenu", false);
        }
      }
    };

  return (
    <div className={styles["sidemenu"]}>
      <div>
        {menus.map((menu: any, key: any) => {
          return menu.children.length === 0 ? (
            <AccordionDetails key={key} className={styles["menu-details"]}>
              {
                <Fragment>
                  <NavLink
                    key={key}
                    to={menu.route}
                    state={{ isNavigationFromSidemenu: true }}
                    className={({ isActive }) =>
                      isActive
                        ? styles["menu-without-children-active"]
                        : styles["menu-without-children"]
                    }
                  >
                    {menu.name}
                  </NavLink>
                </Fragment>
              }
            </AccordionDetails>
          ) : (
            <Accordion
              key={key}
              disableGutters
              square
              expanded={expanded === menu.name}
              className={styles["menu-header"]}
              // onClick={navigateToDashboard}
              onChange={handleChange(menu.name)}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon className={styles["expand-collapse-icon"]} />
                }
                className={styles["accordion-dimension"]}
                onClick={() => {
                  navigateToDashboard(menu.route);
                  setUsersState("isRouteUnderActiveSideMenu", menu.route);
                }}
              >
                <span className={styles["menu-header-text"]}>{menu.name}</span>
                {/* <NavLink
                  key={key}
                  to={menu.route}
                  className={styles["menu-header-text"]}
                  style={{ textDecoration: "none", color: "#ffffff" }}
                  className={({ isActive }) =>
                    isActive === true
                      ? styles["menu-item-active"]
                      : styles["menu-item"]
                  }
                >
                  {menu.name}
                </NavLink> */}
              </AccordionSummary>
              <AccordionDetails className={styles["menu-details"]}>
                {
                  <Fragment>
                    {menu?.children?.map((submenu: any, key: number) => {
                      return (
                        <NavLink
                          key={key}
                          to={submenu.route}
                          state={{ isNavigationFromSidemenu: true }}
                          className={({ isActive }) =>
                            isActive ||
                            (isCustomerSidemenu && key === 0) ||
                            (isRouteUnderActiveSideMenu === submenu.route &&
                              isRouteUnderActiveSideMenu !== location.pathname)
                              ? styles["menu-item-active"]
                              : styles["menu-item"]
                          }
                          onClick={(event: any) => {
                            setUsersState(
                              "isRouteUnderActiveSideMenu",
                              submenu.route
                            );
                            if (isCustomerSidemenu) {
                              setUsersState("isCustomerSidemenu", false);
                            }
                          }}
                        >
                          {submenu.name}
                        </NavLink>
                      );
                    })}
                  </Fragment>
                }
              </AccordionDetails>
            </Accordion>
          );
        })}

        {/* {getItemFromLocalStorage(Constants.loggedInUser)?.admin?.roleId ==
          2 && (
          <NavLink
            to="/leads"
            style={{
              paddingLeft: "16px",
              fontSize: "1.125rem",
              color: "white",
              textDecoration: "none",
            }}
            className={
              leadMenuActive === true
                ? styles.leadsMenuActive
                : styles.leadsMenu
            }
            onClick={() => {
              setLeadMenuActive(true);
            }}
          >
            <span
              className={
                leadMenuActive === true
                  ? styles.leadsTextActive
                  : styles.leadsText
              }
            >
              Leads
            </span>
          </NavLink>
        )} */}
      </div>
    </div>
  );
};

export default Sidemenu;
