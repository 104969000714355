import {
  Avatar,
  Box,
  Typography,
  Link,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material";
import MediaAssets from "assets";
import AlertHelperText from "../../../../components/ui-elements/alert-helper-text/AlertHelperText";
import styles from "./ImageUploader.module.css";
import CachedIcon from "@mui/icons-material/Cached";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSelector } from "react-redux";
import { useState } from "react";

interface IImageUploadDragDrop {
  fieldhelpertext?: any;
  onDrop: (event: any) => void;
  onChange: (event: any) => void;
  previewimage?: any;
  name?: string;
  value?: string;
  cancelimage: () => void;
  onBlur: (event: any) => void;
  isDisabled?: any;
}

export const DragAndDropStyle = styled(Box)({
  border: "2px dashed #9795af",
  borderRadius: "2px",
  width: "20rem",
  height: "15rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  "& .innerButton": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});


const ImageUploadDragDrop = (props: IImageUploadDragDrop) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <>
      <div data-testid="media-upload-drag-drop">
        {props.previewimage?.length > 0 ? (
          <Box className={styles["image-dropdown-section"]}>
            <Avatar
              className={styles["preview-image-section"]}
              src={`${props.previewimage}`}
              alt={props.name}
            />
            <Button className={styles["replace-button-wrapper"]}>
              <label
                className={styles["display-flex-space-btw"]}
                htmlFor={props.name}
              >
                <input
                  accept=".jpg,.png,.jpeg, .gif"
                  id={props.name}
                  name={props.name}
                  disabled={props.isDisabled}
                  type="file"
                  // value={props.value}
                  onBlur={props.onBlur}
                  className={styles["input-display-none"]}
                  onChange={(event: any) => {
                    // props.onChange(event);
                    props.onChange(event.target.files[0]);
                  }}
                  onDrop={(event: any) => {
                    props.onDrop(event);
                  }}
                />
                <CachedIcon className={styles["cached-icon"]} />
                <Typography
                  className={styles["replace-text"]}
                  variant="body1"
                  component="h5"
                >
                  Replace
                </Typography>
              </label>
            </Button>

            <Stack
              justifyContent={"space-around"}
              className={`${styles["right-side-button"]} edit-icon-wrapper`}
            >
              <div style={{ height: "30px", maxWidth: "30px" }}>
                <label
                  className={styles["display-flex-space-btw"]}
                  htmlFor={props.name}
                >
                  <input
                    accept=".jpg,.png,.jpeg,.gif"
                    id={props.name}
                    name={props.name}
                    disabled={props.isDisabled}
                    type="file"
                    onBlur={props.onBlur}
                    className={styles["input-display-none"]}
                    onChange={(event: any) => {
                      props.onChange(event.target.files[0]);
                    }}
                    onDrop={(event: any) => {
                      props.onDrop(event);
                    }}
                  />
                  <Typography
                    className={`${styles["right-side-icon"]} edit-icon`}
                    variant="body1"
                    component="h5"
                  >
                    <ModeEditOutlineOutlinedIcon />
                  </Typography>
                </label>
              </div>

              <div
                onClick={props.cancelimage}
                className={`${styles["right-side-icon"]} delete-icon`}
              >
                <DeleteOutlineOutlinedIcon />
              </div>
            </Stack>
          </Box>
        ) : (
          <DragAndDropStyle>
            <Box
              component="label"
              htmlFor={props.name}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <input
                disabled={props.isDisabled}
                data-testid="media-input-field"
                accept=".jpg,.png,.jpeg,.gif"
                id={props.name}
                name={props.name}
                type="file"
                value={props.value}
                onBlur={props.onBlur}
                className={styles["image-upload-input"]}
                onChange={(event: any) => {
                  // props.onChange(event);
                  props.onChange(event.target.files[0]);
                }}
                onDrop={(event: any) => {
                  props.onDrop(event);
                }}
                style={{ cursor: "pointer" }}
                // disabled={true}
              />

              <div>
                {isHover ? (
                  <Stack className={styles["upload-btn-input-hover"]}>
                    <FileUploadOutlinedIcon sx={{ color: "white" }} />
                    <span style={{ color: "white", fontSize: "1rem" }}>
                      Upload Media
                    </span>
                  </Stack>
                ) : (
                  <Stack className={styles["upload-btn-input"]}>
                    <FileUploadOutlinedIcon sx={{ color: "white" }} />
                    <span style={{ color: "white", fontSize: "1rem" }}>
                      Upload Media
                    </span>
                  </Stack>
                )}
              </div>

              <Box className="innerButton">
                {props.fieldhelpertext ? (
                  <AlertHelperText
                    sx={{ marginTop: "5px" }}
                    color="error"
                    severity="error"
                  >
                    {props.fieldhelpertext || ""}
                  </AlertHelperText>
                ) : (
                  <Typography
                    variant="body2"
                    visibility={"visible"}
                    lineHeight={1.5}
                  ></Typography>
                )}
              </Box>
            </Box>
          </DragAndDropStyle>
        )}
      </div>
    </>
  );
};
export default ImageUploadDragDrop;

// export default ImageUploadDragDrop;
