import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getFaqListSuccess: ["response"],
  getFaqListFailure: ["error"],
  getFaqListRequest: ["params"],

  deleteFaqsSuccess: ["response"],
  deleteFaqsFailure: ["error"],
  deleteFaqsRequest: ["data"],

  changeFaqsStatusSuccess: ["response"],
  changeFaqsStatusFailure: ["error"],
  changeFaqsStatusRequest: ["data"],

  changeFaqPrioritySuccess: ["response"],
  changeFaqPriorityFailure: ["error"],
  changeFaqPriorityRequest: ["data"],

  setFaqListState: ["key", "value"],
});

export const FaqListTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  faqs: [],
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  page:1,
  rowsPerPage:20,
  shouldAudit:false
};

/* Get FAQs */
export const getFaqListRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getFaqListSuccess = (state: any, action: any) => {
  const response = action.response;


  if (response) {
    return {
      ...state,
      error: null,
      faqs: response.data,
      isLoading: false,
      totalCount: response.totalCount,
    };
  }
};

export const getFaqListFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Delete FAQs */
export const deleteFaqsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const deleteFaqsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "FAQ deleted successfully!",
      isLoading: false,
      shouldAudit:true
    };
  }
};

export const deleteFaqsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

/* Update FAQs status */
export const changeFaqsStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const changeFaqsStatusSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "FAQ status updated successfully",
      isLoading: false,
      shouldAudit:true
    };
  }
};

export const changeFaqsStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Update FAQ Priority status */
export const changeFaqPriorityRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const changeFaqPrioritySuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      isLoading: false,
    };
  }
};

export const changeFaqPriorityFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setFaqListState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_FAQ_LIST_SUCCESS]: getFaqListSuccess,
  [Types.GET_FAQ_LIST_FAILURE]: getFaqListFailure,
  [Types.GET_FAQ_LIST_REQUEST]: getFaqListRequest,

  [Types.DELETE_FAQS_SUCCESS]: deleteFaqsSuccess,
  [Types.DELETE_FAQS_FAILURE]: deleteFaqsFailure,
  [Types.DELETE_FAQS_REQUEST]: deleteFaqsRequest,

  [Types.CHANGE_FAQS_STATUS_REQUEST]: changeFaqsStatusRequest,
  [Types.CHANGE_FAQS_STATUS_SUCCESS]: changeFaqsStatusSuccess,
  [Types.CHANGE_FAQS_STATUS_FAILURE]: changeFaqsStatusFailure,

  [Types.CHANGE_FAQ_PRIORITY_SUCCESS]: changeFaqPrioritySuccess,
  [Types.CHANGE_FAQ_PRIORITY_FAILURE]: changeFaqPriorityFailure,
  [Types.CHANGE_FAQ_PRIORITY_REQUEST]: changeFaqPriorityRequest,

  [Types.SET_FAQ_LIST_STATE]: setFaqListState,
});
