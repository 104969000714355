import { RouteConstants } from "routes/RouteConstants";
import BasicDetailsForm from "./basic-details-form/BasicDetailsForm";
import EssentialDetailsForm from "./essential-details-form/EssentialDetailsForm";
import GeneralInformation from "./general-information/GeneralInformation";
import { Fragment, useState, useEffect } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  ButtonComponent,
  LoadingButtonComponent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/ui-elements";
import { Form, Formik } from "formik";
import { ProjectFormInitialValues } from "./ProjectFormInitialValues";
import { ProjectFormValidationSchema } from "./ProjectFormValidations";
import { PaymentFormActions } from "redux-container/payment-forms/paymentFormRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { Delayed } from "utils/DelayRender";
import PaymentBannerImageSection from "./payment-banner-image/PaymentBannerImageSection";

const FormDetails = () => {
  const [showAccordionId, setShowAccordionId] = useState(0);

  return [
    {
      id: 1,
      title: "General Information",
      setStatus: setShowAccordionId,
      component: <GeneralInformation />,
    },
    {
      id: 2,
      title: "Basic Details Form",
      setStatus: setShowAccordionId,
      component: showAccordionId === 2 ? <BasicDetailsForm /> : <></>,
    },
    {
      id: 3,
      title: "Essential Details Form",
      setStatus: setShowAccordionId,
      component: showAccordionId === 3 ? <EssentialDetailsForm /> : <></>,
    },
    {
      id: 4,
      title: "Payment App Banner Image Section ",
      setStatus: setShowAccordionId,
      component: showAccordionId === 4 ? <PaymentBannerImageSection /> : <></>,
    },
  ];
};

const actionDispatch = (dispatch: any) => {
  return {
    createPaymentForm: (data: any) => {
      dispatch(PaymentFormActions.createPaymentFormRequest(data));
    },

    getPaymentFormById: (id: any) => {
      dispatch(PaymentFormActions.getPaymentFormRequest(id));
    },

    updatePaymentForm: (data: any, id: any) => {
      dispatch(PaymentFormActions.updatePaymentFormRequest(data, id));
    },

    setPaymentFormState: (key: any, value: any) => {
      dispatch(PaymentFormActions.setPaymentFormState(key, value));
    },
    getInventories: (id: number) =>
      dispatch(PaymentFormActions.getPaymentInventoriesRequest(id)),
  };
};

const Projects = () => {
  const [expanded, setExpanded] = useState<string | false>("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const {
    createPaymentForm,
    getPaymentFormById,
    updatePaymentForm,
    setPaymentFormState,
    getInventories,
  } = actionDispatch(useDispatch());

  const navigate = useNavigate();

  const handleChange =
    (panel: string, setState: any, id: any) =>
    (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      setState(id);
    };

  const handleOnGlobalSave = (values: any) => {
    if (location?.state?.isEditMode) {
      updatePaymentForm(
        {
          ...values,
          PaymentFormBanner: values?.paymentFormBanner,
        },
        values?.id
      );
    } else {
      createPaymentForm({
        ...values,
        PaymentFormBanner: values?.paymentFormBanner,
      });
    }
    setIsConfirmDialogOpen(false);
  };

  const location: any = useLocation();
  const {
    paymentFormById,
    isLoading,
    error,
    openFlashMessageDialog,
    responseMessage,
    inventoriesData,
    shouldNavigateBackOnError,
  } = useSelector((state: any) => ({
    paymentFormById: state?.paymentForm?.paymentForm,
    inventoriesData: state?.paymentForm?.paymentInventories,
    isLoading: state?.paymentForm?.isLoading,
    error: state?.paymentForm?.error,
    openFlashMessageDialog: state?.paymentForm?.shouldDialogOpen,
    responseMessage: state?.paymentForm?.responseMessage,
    shouldNavigateBackOnError: state?.paymentForm?.shouldNavigateBackOnError,
  }));

  useEffect(() => {
    setPaymentFormState("formId", location?.state?.form?.id);
    if (location?.state?.isEditMode) {
      getPaymentFormById(location?.state?.form?.id);
    }
    getInventories(
      location?.state?.form?.crmLaunchPhase?.id ||
        location?.state?.form?.crmLaunchPhaseId
    );
  }, []);

  const handleChangeToEditMode = () => {
    navigate(RouteConstants.projects, {
      state: { ...location?.state, isViewMode: false, isEditMode: true },
    });
  };

  const clearFlashMessageState = () => {
    setPaymentFormState("error", null);
    setPaymentFormState("responseMessage", null);
    setPaymentFormState("shouldDialogOpen", null);
  };

  const handleCloseFlashMessageDialog = () => {
    if (error && !shouldNavigateBackOnError) {
      clearFlashMessageState();
    } else {
      clearFlashMessageState();
      navigate(RouteConstants.paymentFormManagement);
    }
  };

  const handlePublishForm = (values: any) => {
    setIsConfirmDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <Delayed waitBeforeShow={2000}>
          {openFlashMessageDialog && (
            <FlashMessageDialog
              shouldOpen={openFlashMessageDialog}
              content={error ? error : responseMessage}
              isSuccess={error ? false : true}
              cancelHandler={handleCloseFlashMessageDialog}
            />
          )}
          <Formik
            initialValues={
              location?.state?.isEditMode
                ? {
                    ...paymentFormById,
                    bookingAmount: inventoriesData?.bookingAmount,
                    basicDetailsForm: {
                      ...paymentFormById.basicDetailsForm,
                      ...inventoriesData,
                    },
                  }
                : {
                    ...ProjectFormInitialValues,
                    bookingAmount: inventoriesData?.bookingAmount,
                    basicDetailsForm: {
                      ...ProjectFormInitialValues.basicDetailsForm,
                      ...inventoriesData,
                    },
                  }
            }
            validationSchema={ProjectFormValidationSchema}
            onSubmit={handlePublishForm}
          >
            {({ isValid, values }) => (
              <Form noValidate>
                <ConfirmationDialog
                  shouldOpen={isConfirmDialogOpen}
                  content="Are you sure you want to Publish?"
                  okText="Publish"
                  okHandler={() => handleOnGlobalSave(values)}
                  cancelHandler={handleDialogClose}
                />
                <Stack my={"1rem"}>
                  <Stack textAlign={"start"}>
                    <IconButton
                      edge={"start"}
                      onClick={() =>
                        navigate(RouteConstants.paymentFormManagement)
                      }
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <Typography variant="h5">
                      {location?.state?.isNew
                        ? `Create Form - Project ID: #ID-${location?.state?.form?.id}`
                        : `${location?.state?.form?.crmLaunchPhase?.launchName} : #ID-${location?.state?.form?.id} `}
                    </Typography>
                  </Stack>

                  {location?.state?.isViewMode ? (
                    <LoadingButtonComponent onClick={handleChangeToEditMode}>
                      Edit
                    </LoadingButtonComponent>
                  ) : (
                    <Stack columnGap={2}>
                      <ButtonComponent
                        onClick={() => navigate(RouteConstants.formLists)}
                      >
                        Cancel
                      </ButtonComponent>

                      <LoadingButtonComponent
                        type={"submit"}
                        disabled={
                          !isValid ||
                          !inventoriesData?.bookingAmount ||
                          !inventoriesData?.choiceOfPlots?.length
                        }
                      >
                        Publish
                      </LoadingButtonComponent>
                    </Stack>
                  )}
                </Stack>
                {FormDetails().map((form: any) => (
                  <Accordion
                    key={form.id}
                    expanded={expanded === `panel${form?.id}`}
                    onChange={handleChange(
                      `panel${form.id}`,
                      form?.setStatus,
                      form?.id
                    )}
                  >
                    <AccordionSummary
                      aria-controls={`panel${form?.id}-content`}
                      id={`panel${form?.id}-header`}
                    >
                      <Typography variant="body1">{form.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{form?.component}</AccordionDetails>
                  </Accordion>
                ))}
              </Form>
            )}
          </Formik>
        </Delayed>
      )}
    </Fragment>
  );
};

export default Projects;
