import { MediaUploadComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
const actionDispatch = (dispatch: any) => {
  return {
    setUploadFileState: (key: any, data: any) => {
      dispatch(FileUploadActions.setUploadFileState(key, data));
    },
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
    fileDeleteRequest: (data: any) => {
      dispatch(FileUploadActions.fileDeleteRequest(data));
    },
  };
};

function LogoUploader({ mediaAndPrState }: any) {
  const { setUploadFileState, fileUploadRequest, fileDeleteRequest } =
    actionDispatch(useDispatch());

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<any>();
  const {
    imageDetails,
    isLoading: mediaUploading,
    isSuccess,
    shouldFlashMessageOpen,
    data,
    error,
  } = useSelector((state: any) => state?.fileUpload);

  console.log("values", values);
  console.log("values errors", errors);
  console.log("values touched", touched);

  const handleMediaUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (e: any) => {
        data.binary = e.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);
      setFieldValue(`logo.media.value.size`, file.size);
      setFieldValue(`logo.media.name`, file.name);
      setFieldValue(`logo.media.key`, file.name);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (imageDetails) {
      const imageRes: any = imageDetails;
      console.log("imageRes", imageRes);
      setFieldValue(`logo.media.value.url`, imageRes?.url);
      setUploadFileState("imageDetails", null);
    }
  }, [imageDetails]);

  return (
    <>
      <MediaUploadComponent
        name={`logo.media.value.url`}
        accept=".jpg,.png,.jpeg,.gif"
        value={values?.logo?.media?.value?.url}
        loading={mediaUploading}
        onChange={(event: any) => {
          handleMediaUpload(event);
        }}
        previewimage={values?.logo?.media?.value?.url}
        fieldhelpertext={
          getIn(touched, `logo.media.value.url`) &&
          getIn(errors, `logo.media.value.url`)
        }
        disabled={mediaAndPrState.isViewMode}
      />
    </>
  );
}

export default LogoUploader;
