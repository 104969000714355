import {
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
  ButtonComponent,
  FileUploadComponent,
  MediaUploadComponent,
} from "components/ui-elements";
import {
  Avatar,
  Box,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  MenuItem,
  Divider,
  styled,
  Tooltip,
  IconButton,
} from "@mui/material";
import MediaAssets from "assets";
import { ChangeEvent, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { getIn } from "formik";
import { useLocation } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const actionDispatch = (dispatch: any) => {
  return {
    fileUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    setUploadFileState: (key: string, value: any) =>
      dispatch(FileUploadActions.setUploadFileState(key, value)),
  };
};

const PromotionAndOffer = ({ isEditMode }: any) => {
  const [open, setOpen] = useState(false);
  const { fileUpload, setUploadFileState } = actionDispatch(useDispatch());
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  const {
    imageDetails,
    isLoading,
    isSuccess,
    shouldFlashMessageOpen,
    data,
    error,
  } = useSelector((state: any) => state?.fileUpload);
  const { projectContentList } = useSelector(
    (state: any) => state.projectContentListHoablPage1
  );
  const {
    hoablPage1,
    projectListWithPriorityReduxState,
    projectListHoablPage1,
  } = useSelector((state: any) => state?.hoablPage1Data);
  const [promotionAndOffer, setPromotionAndOffer] = useState(0);
  const location: any = useLocation();

  const handleTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      `${event.target.name}`,
      event.target.value.replace(/[^A-Za-z ]/gi, "")
    );
  };
  useEffect(() => {
    if (imageDetails) {
      if (promotionAndOffer === 2) {
        setFieldValue(`promotionAndOffersMedia.value.url`, imageDetails?.url);
        setUploadFileState("imageDetails", null);
        setPromotionAndOffer(0);
      }
    }
  }, [imageDetails]);

  const handleNudgeCardOpen = () => {
    setOpen(true);
  };

  const handleNudgeCardModalClose = () => {
    setOpen(false);
  };
  const PromotionContainer = styled(FormFieldContainer)`
    padding: 0;
  `;
  const handleImageUpload = (event: any) => {
    setPromotionAndOffer(2);
    const file = event.target.files[0];
    let imageUrl;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUpload(data);
        setFieldValue(`promotionAndOffersMedia.value.url`, imageDetails?.url);
      };
      reader.readAsArrayBuffer(file);
    } else {
      imageUrl = null;
    }
  };
  return (
    <PromotionContainer>
      <Grid container p={"1rem"}>
        <Grid item xs={6}>
          <Stack columnGap={2} justifyContent={"flex-start"}>
            <Typography className="accordion-heading" margin={"0.5rem 0"}>
              Promotion and Offer Card
            </Typography>
            <SwitchComponent
              name="isPromotionAndOfferActive"
              checked={values?.isPromotionAndOfferActive}
              onChange={handleChange}
              disabled={location?.state?.isViewMode && isEditMode}
            />
            <Tooltip
              title={"This represent the ticker on Customer App"}
              arrow
              placement="top-start"
            >
              <InfoOutlinedIcon
                fontSize="small"
                className="infooutlined-alignment"
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Grid container columnSpacing={4} rowSpacing={2} p={"1rem"}>
        <Grid item xs={4}>
          <Box width={"335px"}>
            <MediaUploadComponent
              inputId={2}
              loading={promotionAndOffer === 2 && isLoading}
              fieldhelpertext={
                getIn(touched, "promotionAndOffersMedia.value.url") &&
                getIn(errors, "promotionAndOffersMedia.value.url")
              }
              disabled={location?.state?.isViewMode && isEditMode}
              name={"promotionAndOffersMedia.value.url"}
              previewimage={values?.promotionAndOffersMedia?.value?.url}
              onChange={handleImageUpload}
              cancelimage={() =>
                setFieldValue("promotionAndOffersMedia.value.url", "")
              }
            />
          </Box>
        </Grid>
        <Grid item xs={5} lg={4}>
          <TextFieldComponent
            select
            label="Map project"
            name="promotionAndOffersProjectContentId"
            required
            value={values?.promotionAndOffersProjectContentId}
            disabled={location?.state?.isViewMode && isEditMode}
            helpericon={MediaAssets.ic_info}
            fieldhelpertext={
              getIn(touched, "promotionAndOffersProjectContentId") &&
              getIn(errors, "promotionAndOffersProjectContentId")
            }
            error={Boolean(
              getIn(errors, "promotionAndOffersProjectContentId") &&
                getIn(touched, "promotionAndOffersProjectContentId")
            )}
            onChange={(e: any) => {
              setFieldValue(
                "promotionAndOffersProjectContentId",
                e.target.value
              );
            }}
            helpericoncontent={
              "User will be redirected to the selected project"
            }
          >
            {projectListHoablPage1?.map((project: any, index: number) => (
              <MenuItem value={project.id} key={index}>
                {project?.launchName}
              </MenuItem>
            ))}
          </TextFieldComponent>
        </Grid>
      </Grid>
      <Divider />

      {/* <NudgeCardListing open={open} handleClose={handleNudgeCardModalClose} /> */}
      <Box p={"1rem"} textAlign={"right"}>
        {/* <ButtonComponent variant="contained" onClick={handleNudgeCardOpen}>
          Save
        </ButtonComponent> */}
      </Box>
    </PromotionContainer>
  );
};

export default PromotionAndOffer;
