import {
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import PageHeading from "components/page-heading/PageHeading";
import { Fragment, useEffect, useState } from "react";
import { RouteConstants } from "../../routes/RouteConstants";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import chatManagementListActions from "redux-container/chat-management/ChatManagementListRedux";
import { useDispatch, useSelector } from "react-redux";
import NoDataRecord from "components/no-data-record/NoDataRecord";
import MediaAssets from "assets";
import Retry from "components/retry/Retry";
import Loader from "components/loader/Loader";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import UserActions from "redux-container/users/UsersRedux";
import { AutocompleteComponent } from "components/ui-elements";
import { useLocation, useNavigate } from "react-router-dom";

const actionDispatch = (dispatch: any) => {
  return {
    getChatManagementListData: (params: any) =>
      dispatch(chatManagementListActions.chatManagementListRequest(params)),
    setChatManagementListState: (key: any, data: any) =>
      dispatch(chatManagementListActions.setChatManagementListState(key, data)),
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),
  };
};

const ChatSupportManagement = () => {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const { getChatManagementListData, setChatManagementListState, getUsers } =
    actionDispatch(useDispatch());
  const chatManagementListData = useSelector(
    (state: any) => state?.chatManagementList?.chatMangementListData?.rows
  );
  const isSuccess = useSelector(
    (state: any) => state?.chatManagementList?.isSuccess
  );
  const users = useSelector((state: any) => state?.users?.usersForFilters);
  const isLoading = useSelector(
    (state: any) => state?.chatManagementList?.isLoading
  );
  const error = useSelector((state: any) => state?.chatManagementList?.error);
  const [selectedCreatedOrPublished, setSelectedCreatedOrPublished] =
    useState<any>(null);
  const [selectedModifiedUser, setSelectedModified] = useState<any>(null);
  const [selectedCreated, setSelectedCreated] = useState<any>(null);
  const [selectedPublished, setSelectedPublished] = useState<any>(null);

  const breadcrumbRoutes: any = [
    {
      name: "Chat Support Management",
      route: RouteConstants.chatSupportManagementDashboard,
    },
  ];
  useEffect(() => {
    const params = {
      createdBy: selectedCreatedOrPublished?.id,
      updatedBy: selectedModifiedUser?.id,
    };
    loadData(params);
  }, [dispatch, selectedCreatedOrPublished, selectedModifiedUser]);

  const loadData = (params: any) => {
    getChatManagementListData(params);
    // getUsers({});
  };

  const chatTableHeading = [
    { heading: "Chat Category" },
    { heading: "Created on" },
    { heading: "Published on" },
    { heading: "Modified on" },
    { heading: "Last Modified By" },
    { heading: "Status" },
    { heading: "Action" },
  ];

  const handleClearButton = (): boolean => {
    if (
      selectedModifiedUser === null &&
      selectedPublished === null &&
      selectedCreated === null
    ) {
      return false;
    }
    return true;
  };

  const handleResetFilter = () => {
    setSelectedCreated(null);
    setSelectedModified(null);
    setSelectedPublished(null);
    setSelectedCreatedOrPublished(null);
  };

  const navigate = useNavigate();

  const editChatSupport = (data: any) => {
    switch (data?.smartKey) {
      case "NEW_PROJECT":
        return navigate(RouteConstants.projectsNewUser, {
          state: { isEditMode: true, isViewMode: false },
        });

      case "NEW_HOABL":
        return navigate(RouteConstants.hoablNewUser, {
          state: { isEditMode: true, isViewMode: false },
        });

      case "INVESTED_PROJECT":
        return navigate(RouteConstants.projectsInvestedUser, {
          state: { isEditMode: true, isViewMode: false },
        });

      case "INVESTED_HOABL":
        return navigate(RouteConstants.hoablInvestedUser, {
          state: { isEditMode: true, isViewMode: false },
        });

      default:
        break;
    }
  };

  const viewChatSupport = (data: any) => {
    switch (data?.smartKey) {
      case "NEW_PROJECT":
        return navigate(RouteConstants.projectsNewUser, {
          state: { isEditMode: false, isViewMode: true },
        });

      case "NEW_HOABL":
        return navigate(RouteConstants.hoablNewUser, {
          state: { isEditMode: false, isViewMode: true },
        });

      case "INVESTED_PROJECT":
        return navigate(RouteConstants.projectsInvestedUser, {
          state: { isEditMode: false, isViewMode: true },
        });

      case "INVESTED_HOABL":
        return navigate(RouteConstants.hoablInvestedUser, {
          state: { isEditMode: false, isViewMode: true },
        });

      default:
        break;
    }
  };
  const createChatNewUser = () => {
    navigate(RouteConstants.hoablNewUser);
  };
  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_combined_shape}
        altText=""
        message="You haven’t created any message yet, create your first message.."
        btnTitle="Go to  HoABL - New User"
        btnAction={createChatNewUser}
      />
    );
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Published {`(${chatManagementListData?.length})`}
          </h4>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={1} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) => option?.firstName}
                    label="Created By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedCreated(newValue);
                      setSelectedCreatedOrPublished(newValue);
                    }}
                    value={selectedCreated}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) => option?.firstName}
                    label="Modified By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedModified(newValue);
                    }}
                    value={selectedModifiedUser}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>
              {handleClearButton() ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={handleResetFilter}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Stack>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                {chatTableHeading.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {chatManagementListData && chatManagementListData?.length > 0 ? (
              <TableBody>
                {chatManagementListData?.map((item: any) => {
                  return (
                    <TableRow key={item.id} className="table-data-row">
                      <TableCell className="table-data-text">
                        {item.smartKey}
                      </TableCell>

                      <TableCell className="table-data-text">
                        <div>
                          {item.createdAt
                            ? formatDateTime(
                                item.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell className="table-data-text">
                        <div>
                          {item.createdAt
                            ? formatDateTime(
                                item.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell className="table-data-text">
                        <div>
                          {item.updatedAt
                            ? formatDateTime(
                                item.updatedAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell className="table-data-text">
                        {item &&
                        item.chatUpdatedBy &&
                        item.chatUpdatedBy?.firstName
                          ? item?.chatUpdatedBy?.firstName
                          : "-"}
                      </TableCell>

                      <TableCell className="table-data-text">
                        {/* {item.status} */}
                        Published
                      </TableCell>

                      <TableCell className="table-data-text">
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editChatSupport(item)}
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewChatSupport(item)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record"> No Chat Record</div>
            )}
          </Table>
        </TableContainer>
      </Fragment>
    );
  };

  const render = () => {
    if (
      (chatManagementListData?.length > 0 || handleClearButton()) &&
      isLoading === false
    ) {
      return renderTable();
    } else if (
      (chatManagementListData?.length === 0 ||
        chatManagementListData?.length === undefined) &&
      !handleClearButton() &&
      isLoading === false
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <PageHeading title="Chat Support Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

      <Container className="main-container">{render()}</Container>
    </Fragment>
  );
};

export default ChatSupportManagement;
