interface IMediaDetails {
  imageUpload: string;
  imageTitle: string;
  videoTitle: string;
  videoUrl: string;
  category: string;
  pageWidthImage: boolean;
}

interface IMediaListDetails {
  upload: string;
  name: string;
  type: boolean | string;
  status: boolean | string;
  category?: string;
  url?: string;
  mediaUploadType?: string;
}

export interface IMediaUploadInitialState {
  isImagesVisible: boolean;
  isVideosVisible: boolean;
  isDroneShootsVisible: boolean;
  isThreeSixtyImagesVisible: boolean;
  sectionHeading: string;
  subHeading: string;
  imageUploadListDetails: IMediaListDetails[];
  videoUploadListDetails: IMediaListDetails[];
  droneShootsUploadListDetails: IMediaListDetails[];
  threeSixtyUploadListDetails: IMediaListDetails[];
  mediaUploadFor?: string;
  openMediaUploadDialog?: boolean;
  singleMediaUpdateData?: any;
  isMediaDataUpdating?: boolean;
  isViewMode?: boolean;
}

export const mediaUploadInitialState: IMediaUploadInitialState = {
  isImagesVisible: true,
  isVideosVisible: true,
  isDroneShootsVisible: true,
  isThreeSixtyImagesVisible: true,
  sectionHeading: "",
  subHeading: "",
  imageUploadListDetails: [],
  videoUploadListDetails: [],
  droneShootsUploadListDetails: [],
  threeSixtyUploadListDetails: [],
};

export enum TypeOfUploadListDetails {
  imageUploadListDetails = "imageUploadListDetails",
  videoUploadListDetails = "videoUploadListDetails",
  droneShootsUploadListDetails = "droneShootsUploadListDetails",
  threeSixtyUploadListDetails = "threeSixtyUploadListDetails",
  virtualVideoUploadListDetails = "virtualVideoUploadListDetails",
}
