import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { call, put } from "redux-saga/effects";
import ProjectCollectionActions from "redux-container/project-management/project-collection/ProjectCollectionCreationRedux";

export function* projectCollectionCreation(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.postProjectCollectionCreation, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectCollectionActions.addProjectCollectionSuccess(response.data)
      );
    } else {
      yield put(
        ProjectCollectionActions.addProjectCollectionFailure(response.data)
      );
    }
  } catch (error) {
    yield put(ProjectCollectionActions.addProjectCollectionFailure(error));
  }
}

//Get By Id Project collections

export function* getProjectCollectionById(api: any, action: any): any {
  try {
    const response = yield call(api.getProjectCollectionById, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectCollectionActions.getProjectCollectionByIdSuccess(response.data)
      );
    } else {
      yield put(
        ProjectCollectionActions.getProjectCollectionByIdFailure(response.data)
      );
    }
  } catch (error) {
    yield put(ProjectCollectionActions.getProjectCollectionByIdFailure(error));
  }
}

// delete Project collection by Id

export function* deleteProjectCollectionById(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.deleteProjectCollectionById, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectCollectionActions.deleteProjectCollectionByIdSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProjectCollectionActions.deleteProjectCollectionByIdFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      ProjectCollectionActions.deleteProjectCollectionByIdFailure(error)
    );
  }
}

//update project collection by Id

export function* updateProjectCollectionById(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(
      api.updateProjectCollectionById,
      action.id,
      action.data
    );
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectCollectionActions.updateProjectCollectionByIdSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProjectCollectionActions.updateProjectCollectionByIdFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      ProjectCollectionActions.updateProjectCollectionByIdFailure(error)
    );
  }
}
//status
export function* changeProjectCollectionListStatus(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(
      api.changeProjectCollectionListStatus,
      action.data
    );
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectCollectionActions.changeProjectCollectionListStatusSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProjectCollectionActions.changeProjectCollectionListStatusFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      ProjectCollectionActions.changeProjectCollectionListStatusFailure(error)
    );
  }
}
