import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SelectDropdown from "components/ui-elements/select/SelectDropdown";
import {
  ButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { ChangeEvent, MouseEvent, useState } from "react";
import {
  referralCards,
  tableHeadings,
} from "pages/marketing-management/referral-cards/referral-card-list/ReferralCardList";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

interface IReferralListingProps {
  open: boolean;
  handleClose: () => void;
}

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function ReferralTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {tableHeadings.map((headCell) => (
          <TableCell padding={"normal"}>{headCell.heading}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ReferralListingDialog = (props: IReferralListingProps) => {
  const { open, handleClose } = props;
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [selectReferralTitle, setSelectReferralTitle] = useState("");
  const [selectReferralType, setSelectReferralType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = referralCards.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleReferralTitleChange = (event: any) => {
    setSelectReferralTitle(event.target.value as string);
  };

  const handleReferralTypeChange = (event: any) => {
    setSelectReferralType(event.target.value as string);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  return (
    <Dialog
      fullWidth
      sx={{ padding: "6rem" }}
      maxWidth={"xl"}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ padding: "1rem 2rem" }}>
        <Stack>
          <DialogTitle sx={{ padding: 0 }}>
            <Typography
              component={"span"}
              variant="h6"
              color={"secondary.dark"}
            >
              Referral listings
            </Typography>
          </DialogTitle>
          <DialogActions>
            <ButtonComponent color="inherit" onClick={handleClose}>
              Cancel
            </ButtonComponent>
            <ButtonComponent variant="contained">Map Selection</ButtonComponent>
          </DialogActions>
        </Stack>
        <Grid
          container
          alignItems={"flex-end"}
          justifyContent={"space-between"}
        >
          <Grid item xs={3}>
            <Stack columnGap={2}>
              <Typography variant="body1">Filters:</Typography>
              <SelectDropdown
                value={selectReferralTitle}
                placeholder={"Title"}
                onChange={handleReferralTitleChange}
              >
                <MenuItem value={"update kyc"}>update kyc</MenuItem>
                <MenuItem value={"update profile"}>update profile</MenuItem>
                <MenuItem value={"update site details"}>
                  update site details
                </MenuItem>
              </SelectDropdown>
              <SelectDropdown
                value={selectReferralType}
                placeholder={"Type"}
                onChange={handleReferralTypeChange}
              >
                <MenuItem value={"Tomorrow Land"}>Tomorrow Land</MenuItem>
                <MenuItem value={"The Rush"}>The Rush</MenuItem>
              </SelectDropdown>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <TextFieldComponent
              fullWidth
              placeholder="Search"
              autoComplete="on"
              sx={{ backgroundColor: "grey.A400" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: "0.8rem" }} />
        <TableContainer>
          <Table>
            <ReferralTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={referralCards.length}
            />
            <TableBody>
              {referralCards.map((row, index) => {
                const isItemSelected = isSelected(row.title);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    onClick={(event) => handleClick(event, row.title)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.title}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell id={labelId} scope="row" padding="none">
                      {row.title}
                    </TableCell>
                    {/* <TableCell>{row.title}</TableCell> */}
                    <TableCell>{row.subtitle}</TableCell>
                    <TableCell>{row.createdBy}</TableCell>
                    <TableCell>{row.createdOn}</TableCell>
                    <TableCell>{row.modifiedBy}</TableCell>
                    <TableCell>{row.modifiedOn}</TableCell>
                    <TableCell>
                      <SwitchComponent checked={row.status} />
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="view">
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider sx={{ marginX: "2rem" }} />

      <DialogActions sx={{ justifyContent: "end" }}>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={referralCards.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ReferralListingDialog;
