import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { ChangePasswordActions } from "redux-container/change-password/ChangePasswordRedux";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";

export function* changePassword(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.changePassword, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ChangePasswordActions.changePasswordSuccess(response.data));
    } else {
      yield put(ChangePasswordActions.changePasswordFailure(response.data));
    }
  } catch (error) {
    yield put(ChangePasswordActions.changePasswordFailure(error));
  }
}
