export const enum UpdateTypeEnum {
  HoABL = "HoABL",
  Project = "Project",
  Factual = "Factual",
}

export const enum UpdateTypeValueEnum {
  HoABL = "2001",
  Project = "2002",
  Factual = "2003",
}
