import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, styled } from "@mui/material";

const LoadingButtonWrapper = styled(Box)(
  ({ theme }) => `
    && .MuiLoadingButton-root.Mui-disabled {
      color: ${theme.palette.common.white}
    }
`
);

export default function LoadingButtonComponent(props: LoadingButtonProps) {
  const { children } = props;
  return (
    <LoadingButtonWrapper>
      <LoadingButton {...props}>{children}</LoadingButton>
    </LoadingButtonWrapper>
  );
}
