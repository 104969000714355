import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import { FormControl, Grid, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import projectListActions from "redux-container/project-management/ProjectListRedux";
import formListActions from "../../../redux-container/form-list/FormListRedux";
import { RouteConstants } from "routes/RouteConstants";
import { AutocompleteComponent } from "components/ui-elements";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "components/loader/Loader";
import MediaAssets from "assets";
import NoDataRecord from "components/no-data-record/NoDataRecord";
import ApiService from "services/ApiService";
import { AvOptions, milestoneFlagEnumReversed } from "./data";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    getProjectList: async (params: any) => {
      dispatch(projectListActions.projectListRequest(params));
    },
    setFormListState: async (key: any, value: any) => {
      dispatch(formListActions.setFormListState(key, value));
    },
    getForms: (params: any) =>
      dispatch(formListActions.formsListRequest(params)),
  };
};

const NewForm = ({ listHeading, searchKey }: any) => {
  const navigate = useNavigate();

  const { getProjectList } = actionDispatch(useDispatch());

  const formList = useSelector((state: any) => state?.projectList?.data?.data);

  const isLoading = useSelector((state: any) => state?.projectList?.isLoading);

  const [selectedLaunch, setSelectedLaunch] = useState<any>(null);
  const [selectedProject, setSelectedProject] = useState<any>(null);


  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    let projectParam = {
      shouldValidateAdmin:
        loggedInUser?.admin?.roleId !== RoleTypeEnum.Admin ? true : false,
    };
    getProjectList(projectParam);
    return () => {};
  }, []);

  const publishedProjects = useSelector(
    (state: any) => state?.projectList?.data?.data
  );

  const handleResetFilter = () => {
    setFilters({ ...filters, index: 1, size: 10 });
    setSelectedLaunch(null)
    setSelectedProject(null)
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilters({ ...filters, index: newPage + 1 });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilters({ ...filters, size: Number(event.target.value) });
  };

  const [milestones, setMilestones] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const api = ApiService.create();
  const [filters, setFilters] = useState<any>({ index: 1, size: 10 });

  useEffect(() => {
    setLoading(true);
    api
      .getPaymentHistory({ ...filters, search: searchKey })
      .then((res: any) => {
        setMilestones(res.data);
      })
      .catch((e: any) => {
        console.log(e, "some error");
      })
      .finally(() => setLoading(false));
  }, [filters]);

  useEffect(() => {
    setFilters({ index: 1, size: 10, searchKey: searchKey });
  }, [searchKey]);

  const filterDropdwonChange = (key: any, value: any) => {
    setFilters({ ...filters, index: 1, size: 10, [key]: value });
  };


  const renderTable = () => {
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Stack>
              <Grid container spacing={2} md={10} lg={10} xl={10}>
                <Grid
                  item
                  sx={{ marginRight: -"40px" }}
                  md={1}
                  lg={1}
                  xl={1}
                  className="margin-left-10 filter-heading-holder"
                >
                  <span className="filter-heading">Filters:</span>
                </Grid>

                <Grid item md={2} lg={2} xl={2}>
                  <FormControl className="filter-dropdown">
                    <AutocompleteComponent
                      options={publishedProjects}
                      getOptionLabel={(option: any) =>
                        option?.generalInfoEscalationGraph?.crmProjectName
                          ? option?.launchName +
                            ` (${option?.generalInfoEscalationGraph?.crmProjectName})`
                          : option?.launchName
                      }
                      label="Project Name (CRM Project Name)"
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: any | null
                      ) => {
                        setSelectedProject(newValue);
                        filterDropdwonChange(
                          "projectName",
                          newValue?.launchName
                        );
                      }}
                      value={selectedProject}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option?.id === value?.id
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item md={2} lg={2} xl={2}>
                  <FormControl className="filter-dropdown">
                    <AutocompleteComponent
                      options={AvOptions}
                      getOptionLabel={(option: any) => option?.name}
                      label="Av includes"
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: any | null
                      ) => {
                        setSelectedLaunch(newValue);
                        if(!newValue){
                          filterDropdwonChange(
                            "doesAgreementValueIncludeInfraValue",
                             null
                          );
                          return
                        }
                        filterDropdwonChange(
                          "doesAgreementValueIncludeInfraValue",
                          newValue?.value
                            ? "true"
                            : "false"
                        );
                      }}
                      value={selectedLaunch}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option?.id === value?.id
                      }
                    />
                  </FormControl>
                </Grid>

                { selectedLaunch || selectedProject ? (
                  <Grid
                    item
                    md={0.5}
                    lg={0.5}
                    xl={0.5}
                    className="filter-heading-holder"
                    style={{ position: "relative" }}
                  >
                    <span
                      onClick={handleResetFilter}
                      className="clear-filter"
                      style={{ position: "absolute", top: 32 }}
                    >
                      Clear
                    </span>
                  </Grid>
                ) : null}
              </Grid>
            </Stack>
            <TableContainer
              component={Paper}
              className="table-container"
              style={{ height: window.innerHeight - 475 }}
            >
              <Table style={{ position: "relative" }}>
                <TableHead>
                  <TableRow className="table-header-row">
                    {listHeading.map(
                      (item: (typeof listHeading)[0], index: number) => (
                        <TableCell key={index} className="table-header-text">
                          {item.heading}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                {milestones?.data?.length > 0 ? (
                  <TableBody>
                    {milestones?.data?.map((form: any) => {
                      return (
                        <TableRow
                          key={form.id}
                          className="table-data-row"
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {`${form?.firstName || " "} ${
                              form?.lastName || ""
                            }` || ""}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {form.launchName || ""}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {form.email || ""}
                          </TableCell>

                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 200 }}
                          >
                            {form.phoneNumber || ""}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 200 }}
                          >
                            {form.paidAmount || ""}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 200 }}
                          >
                            {form.pendingAmount || ""}
                          </TableCell>

                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 200 }}
                          >
                            {form.expectedAmount || ""}
                          </TableCell>

                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 150 }}
                          >
                            {form.doesAgreementValueIncludeInfraValue
                              ? "Yes"
                              : "No"}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 170 }}
                          >
                            {form.paymentMilestone || ""}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 170 }}
                          >
                            {milestoneFlagEnumReversed[form.milestoneFlag] ||
                              ""}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <div className="no-record">No Milestones Found</div>
                )}
              </Table>
            </TableContainer>
            {milestones?.data?.length > 0 ? (
              <TablePagination
                // className={styles["table-pagination"]}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={milestones?.totalCount || 0}
                rowsPerPage={filters.size}
                page={filters.index - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </>
        )}
      </>
    );
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_upload_black}
        altText=""
        message="You haven't created any Projects yet. Create your first Project."
        btnTitle="CREATE Project"
        btnAction={createProject}
      />
    );
  };

  const createProject = () => {
    navigate(RouteConstants.projectList);
  };

  const render = () => {
    if (
      isLoading === false &&
      (formList?.length > 0 ||
        selectedProject ||
        selectedLaunch ||
        searchKey)
    ) {
      return renderTable();
    } else if (
      isLoading === false &&
      !formList?.length &&
      !selectedLaunch &&
      !selectedProject &&
      !selectedLaunch &&
      !searchKey
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    }
  };

  return (
    <>
      <>{render()}</>
    </>
  );
};

export default NewForm;
