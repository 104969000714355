import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import Container from "@mui/material/Container";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import FormFieldContainer from "../../../../components/ui-elements/form-field-container/FormFieldContainer";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PageHeading from "../../../../components/page-heading/PageHeading";
import { RouteConstants } from "routes/RouteConstants";
import TextFieldComponent from "components/ui-elements/text-field/TextFieldComponent";
import { isEmpty } from "utils/ValidationUtils";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  StatusTypeEnum,
  TestimonialStatusTypeEnum,
  TestimonialStatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import TestimonialsActions from "../../../../redux-container/marketing-management/testimonials/TestimonialsRedux";
import MediaAssets from "assets";
import styles from "./TestimonialCreation.module.css";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import Tooltip from "@mui/material/Tooltip";
import { InfoOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { FormControlLabel, IconButton } from "@mui/material";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { testimonialsLabels } from "./TestimonialCreationConstants";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { RadioButtonGroupComponent, StyledRadio } from "components/ui-elements";
import {
  TestimonailTypeEnum,
  TestimonialTypeEnumUtils,
} from "enumerations/TestimonialTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    addTestimonial: (data: any) =>
      dispatch(TestimonialsActions.addTestimonialRequest(data)),
    updateTestimonial: (data: any) =>
      dispatch(TestimonialsActions.updateTestimonialRequest(data)),
    setTestimonialsState: (key: any, data: any) =>
      dispatch(TestimonialsActions.setTestimonialsState(key, data)),
    deleteTestimonial: (data: any) =>
      dispatch(TestimonialsActions.deleteTestimonialRequest(data)),
    // setTestimonialsState: (key: any, data: any) =>
    //   dispatch(TestimonialActions.setTestimonialsState(key, data)),
    changeTestimonialStatus: (data: any) =>
      dispatch(TestimonialsActions.changeTestimonialStatusRequest(data)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const CreateTestimonialTooltipInfo = () => {
  return (
    <div className="heading-tooltip-text">
      <p>Testimonial will appear on the app in the following sequence:</p>
      <ol>
        <li>Customer Name</li>
        <li>Designation & Company Name</li>
        <li>Testimonial</li>
      </ol>
    </div>
  );
};

const TestimonialCreation = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const isLoading = useSelector((state: any) => state.testimonials?.isLoading);
  const error = useSelector((state: any) => state.testimonials?.error);
  const isSuccess = useSelector((state: any) => state.testimonials?.isSuccess);
  const message = useSelector((state: any) => state.testimonials?.message);
  const auditedObject = useSelector(
    (state: any) => state.testimonials?.auditedObject
  );
  const modules = useSelector((state: any) => state?.rolesAndModules?.module);
  const testmonialTypes = TestimonialTypeEnumUtils.getTestimonailTypeEnums();
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [designation, setDesignation] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [testimonialsMedia, setTestimonialsMedia] = useState("");
  const [testimonialType, setTestimonialType] = useState<any>(
    TestimonailTypeEnum.TextTestimonial
  );
  const [testimonialError, setTestimonialError] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const [isCreateMode, setCreateMode] = useState(true);
  const [isViewMode, setViewMode] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [testimonialCopy, setTestimonialCopy] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const open = Boolean(anchorEl);
  const [previousStates, setPreviousStates] = useState<any>({
    firstName: "",
    lastName: "",
    designation: "",
    companyName: "",
    testimonial: "",
  });
  const [finalValues, setfinalValues] = useState<any>();

  const [modulesAudited, setModulesAudited] = useState<any>(null);
  const [statusAudited, setStatusAudited] = useState<any>(null);
  const [isItemDeleted, setIsItemDeleted] = useState(false);

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  const [openPublishConfirmationDialog, setOpenPublishConfirmationDialog] =
    useState<boolean>(false);

  const {
    addTestimonial,
    updateTestimonial,
    deleteTestimonial,
    setTestimonialsState,
    changeTestimonialStatus,
    rolesAndModulesRequest,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());

  const breadcrumbRoutes: any = [
    {
      name: "Marketing Management",
      route: RouteConstants.marketingManagementDashboard,
    },
    {
      name: "Testimonials",
      route: RouteConstants.testimonialsList,
    },
    {
      name: location.state
        ? `${(isEditMode == true && "Edit : ") || "View : "} 
        ${firstName} ${lastName}`
        : "Create New",
      route: RouteConstants.testimonialCreation,
    },
  ];

  useEffect(() => {
    rolesAndModulesRequest();

    return () => {};
  }, []);

  useEffect(() => {
    resetState();
    getDataForEditOrViewMode();
  }, []);

  const getDataForEditOrViewMode = () => {
    if (location && location.state && location.state.isViewMode === true) {
      setDisabled(true);
      setData(location.state.testimonial);
      setCreateMode(false);
      setViewMode(true);
    } else if (
      location &&
      location.state &&
      location.state.isEditMode === true
    ) {
      setDisabled(false);
      setData(location.state.testimonial);
      setCreateMode(false);
      setEditMode(true);
    }
  };

  const setData = (testimonial: any) => {
    setTestimonialCopy(testimonial);
    setFirstName(testimonial?.firstName);
    setLastName(testimonial?.lastName);
    setDesignation(testimonial?.designation);
    setCompanyName(testimonial?.companyName);
    setTestimonial(
      testimonial.testimonialType === TestimonailTypeEnum.VideoTestimonial
        ? testimonial?.testimonialsMedia
        : testimonial?.testimonialContent
    );
    setTestimonialType(testimonial?.testimonialType);
    setPreviousStates({
      ...previousStates,
      firstName: testimonial?.firstName,
      lastName: testimonial?.lastName,
      designation: testimonial?.designation,
      companyName: testimonial?.companyName,

      ...(testimonial.testimonialType === TestimonailTypeEnum.VideoTestimonial
        ? { testimonial: testimonial?.testimonialsMedia }
        : { testimonial: testimonial?.testimonialContent }),
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  // const handleSaveAsDraft = () => {};

  const handlePublishChanges = () => {
    let isValid = true;
    if (isEmpty(firstName)) {
      setFirstNameError("Please enter the first name");
      isValid = false;
    }
    if (isEmpty(testimonial)) {
      if (testimonialType === TestimonailTypeEnum.TextTestimonial) {
        setTestimonialError("Please enter the testimonial");
      } else if (testimonialType === TestimonailTypeEnum.VideoTestimonial) {
        setTestimonialError("Please enter the testimonial video URL");
      }
      isValid = false;
    }
    if (isEmpty(lastName)) {
      setLastNameError("Please enter the last name");
      isValid = false;
    }
    if (isEmpty(companyName)) {
      setCompanyNameError("Please enter the company name");
      isValid = false;
    }
    if (isEmpty(designation)) {
      setDesignationError("Please enter the designation");
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    let auditObjectsArray: any[] = [];
    const presentStates: any = {
      firstName: firstName,
      lastName: lastName,
      designation: designation,
      companyName: companyName,
      testimonial: testimonial,
    };
    setfinalValues({
      ...testimonialCopy,
      testimonialContent: testimonial,
      firstName: firstName,
      lastName: lastName,
      designation: designation,
      companyName: companyName,
      testimonial: testimonial,
    });
    Object?.keys(previousStates)?.map((key: any) => {
      if (presentStates[key] !== previousStates[key]) {
        let auditObject = {
          itemAffected: testimonialsLabels[key],
          newValue: presentStates[key],
          oldValue: previousStates[key],
        };
        auditObjectsArray.push(auditObject);
      }
    });
    setModulesAudited(auditObjectsArray);
    const data = {
      firstName: firstName,
      lastName: lastName,
      designation: designation,
      companyName: companyName,
      testimonialType: testimonialType,

      ...(testimonialType === TestimonailTypeEnum.VideoTestimonial
        ? { testimonialsMedia: testimonial, testimonialContent: "" }
        : { testimonialContent: testimonial, testimonialsMedia: "" }),
    };

    if (isEditMode === true) {
      const editedData: any = {
        ...data,
      };
      editedData["id"] = testimonialCopy?.id;
      updateTestimonial(editedData);
    } else {
      addTestimonial(data);
    }
    resetState();
  };

  const resetState = () => {
    setTestimonialsState("message", null);
    setTestimonialsState("error", null);
    setTestimonialsState("isSuccess", false);
    setFirstName("");
    setLastName("");
    setDesignation("");
    setCompanyName("");
    setTestimonial("");
    setFirstNameError("");
    setLastNameError("");
    setCompanyNameError("");
    setDesignationError("");
    setTestimonialError("");
  };

  const handleCloseFlashMessageDialog = () => {
    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Marketing Management";
    });
    if (isSuccess) {
      if (modulesAudited?.length) {
        if (location?.state) {
          const postAuditRequestbody = {
            ipAddress: ipAddress, //ipAddress
            moduleId: moduleObject?.id,
            modulesAudited: modulesAudited,
            status: AuditStatus.EDITED,
            auditedObject: {
              isEditMode: true,
              testimonial: finalValues,
            },
            route: RouteConstants.testimonialCreation,
          };
          postAuditLogRequest(postAuditRequestbody);
        } else {
          const postAuditRequestbody = {
            ipAddress: ipAddress, //ipAddress
            moduleId: moduleObject?.id,
            // modulesAudited: modulesAudited,
            modulesAudited: [
              {
                itemAffected: `Testimonial ${finalValues?.firstName} ${finalValues?.lastName} Created.`,
                newValue: "",
                oldValue: "",
              },
            ],
            status: AuditStatus.CREATED,
            auditedObject: {
              isEditMode: true,
              testimonial: auditedObject,
            },
            route: RouteConstants.testimonialCreation,
          };
          postAuditLogRequest(postAuditRequestbody);
        }
      }
      if (statusAudited) {
        const postAuditRequestbody = {
          ipAddress: ipAddress, //ipAddress
          moduleId: moduleObject?.id,
          route: location.pathname,
          auditedObject: {
            isEditMode: true,
            testimonial: {
              ...testimonialCopy,
              status:
                statusAudited === "Published"
                  ? TestimonialStatusTypeEnum.Published
                  : TestimonialStatusTypeEnum.Inactive,
            },
          },
          modulesAudited: [
            {
              itemAffected: `Testimonial Status`,
              newValue: statusAudited,
              oldValue:
                statusAudited === "Published" ? "Inactive" : "Published",
            },
          ],
          status: AuditStatus.EDITED,
        };
        postAuditLogRequest(postAuditRequestbody);
      } else if (isItemDeleted) {
        const postAuditRequestbody = {
          ipAddress: ipAddress, //ipAddress
          moduleId: moduleObject?.id,
          route: null,
          auditedObject: null,
          modulesAudited: [
            {
              itemAffected: `Testimonial==>${location?.state?.testimonial?.firstName} Deleted. (ID ${location?.state?.testimonial?.id})`,
              newValue: "",
              oldValue: "",
            },
          ],
          status: null,
        };
        postAuditLogRequest(postAuditRequestbody);
      }

      setTestimonialsState("isSuccess", false);
    }
    setfinalValues(null);

    resetState();
    navigate(RouteConstants.testimonialsList);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event?.target.value.replace(/[^A-Za-z ]/gi, ""));
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event?.target.value.replace(/[^A-Za-z ]/gi, ""));
  };

  const handleDesignationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDesignation(event?.target.value);
  };

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyName(event?.target.value);
  };

  const handleTestimonialChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTestimonial(event?.target.value);
  };

  const handleEdit = () => {
    setDisabled(false);
    setViewMode(false);
    setEditMode(true);
  };

  const handleDelete = () => {
    setIsConfirmDeleteDialogOpen(true);
    setIsItemDeleted(true);
  };

  const handleDeleteTestimonial = (id: any) => {
    setIsConfirmDeleteDialogOpen(false);
    const testimonial = {
      testimonialIds: [id],
    };
    deleteTestimonial(testimonial);
  };

  const changeTestimonialStatuses = (
    id: any,
    status: TestimonialStatusTypeEnum
  ) => {
    const data = {
      status: status,
      testimonialIds: [id],
    };
    changeTestimonialStatus(data);
  };

  const handleOnBlurTestimonial = () => {
    if (isEmpty(testimonial)) {
      if (testimonialType === TestimonailTypeEnum.TextTestimonial) {
        setTestimonialError("Please enter the testimonial");
      } else if (testimonialType === TestimonailTypeEnum.VideoTestimonial) {
        setTestimonialError("Please enter the testimonial video URL");
      }
    } else {
      setTestimonialError("");
    }
  };

  const handleOnBlurFirstName = () => {
    if (isEmpty(firstName)) {
      setFirstNameError("Please enter the first name");
    } else {
      setFirstNameError("");
    }
  };

  const handleOnBlurLastName = () => {
    if (isEmpty(lastName)) {
      setLastNameError("Please enter the last name");
    } else {
      setLastNameError("");
    }
  };

  const handleOnBlurCompanyName = () => {
    if (isEmpty(companyName)) {
      setCompanyNameError("Please enter the company name");
    } else {
      setCompanyNameError("");
    }
  };

  const handleOnBlurDesignation = () => {
    if (isEmpty(designation)) {
      setDesignationError("Please enter the designation");
    } else {
      setDesignationError("");
    }
  };

  const isValid = () => {
    if (
      firstName &&
      lastName &&
      designation &&
      companyName &&
      testimonial &&
      testimonialType
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderForm = () => {
    return (
      <Fragment>
        {/* Confirmation Dialog for Publish */}
        <ConfirmationDialog
          shouldOpen={openPublishConfirmationDialog}
          title="Publish Testimonial"
          content="This action will Publish Testimonial. Are you sure you want to continue?"
          okText="Publish"
          cancelHandler={() => setOpenPublishConfirmationDialog(false)}
          okHandler={() => {
            handlePublishChanges();
            setOpenPublishConfirmationDialog(false);
          }}
        />
        <div className="heading-action-holder">
          {isCreateMode ? (
            <div className="heading align-center">
              <ChevronLeftRoundedIcon
                onClick={() => navigate(-1)}
                className="margin-right-15px"
              />
              <h4 className="heading">Create Testimonial</h4>
              <Tooltip
                arrow
                title={<CreateTestimonialTooltipInfo />}
                placement="top"
              >
                <InfoOutlined className={styles["infooutlined-alignment"]} />
              </Tooltip>
            </div>
          ) : (
            <h4 className="heading align-center">
              <ChevronLeftRoundedIcon
                onClick={() => navigate(-1)}
                className="margin-right-15px"
              />
              {`${firstName} ${lastName}`}
              <span
                onClick={
                  // typeof location?.state?.isAuditedWhileCreating == "boolean"
                  //   ? isViewMode || location?.state?.isAuditedWhileCreating
                  //     ? () => {}
                  //     : handleClick
                  //   :
                  isViewMode ? () => {} : handleClick
                }
                className="margin-left-15px align-center"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <IconButton
                  disabled={
                    isViewMode ? true : false
                    // || location?.state?.isAuditedWhileCreating
                  }
                >
                  <SettingsOutlinedIcon />
                </IconButton>
              </span>
            </h4>
          )}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            hideBackdrop
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {testimonialCopy?.status === TestimonialStatusTypeEnum.Published ? (
              <MenuItem
                onClick={() => {
                  changeTestimonialStatuses(
                    testimonialCopy?.id,
                    TestimonialStatusTypeEnum.Inactive
                  );
                  setStatusAudited(
                    TestimonialStatusTypeEnumUtils.getEnumText(
                      TestimonialStatusTypeEnum.Inactive
                    )
                  );
                }}
              >
                Mark As Inactive
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  changeTestimonialStatuses(
                    testimonialCopy?.id,
                    TestimonialStatusTypeEnum.Published
                  );
                  setStatusAudited(
                    TestimonialStatusTypeEnumUtils.getEnumText(
                      TestimonialStatusTypeEnum.Published
                    )
                  );
                }}
              >
                Mark As Published
              </MenuItem>
            )}
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>

          {isViewMode ? (
            <div>
              <Button
                variant="contained"
                onClick={handleEdit}
                // autoFocus
                disableElevation
                className="btn btn-dark"
              >
                Edit
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="contained"
                onClick={handleCancel}
                // autoFocus
                disableElevation
                className="btn btn-light-outline margin-right-15"
              >
                Cancel
              </Button>

              {/* <Button
                variant="contained"
                onClick={handleSaveAsDraft}
                // autoFocus
                disableElevation
                className="btn btn-dark-outline margin-right-15"
              >
                Save as Draft
              </Button> */}

              <Button
                disabled={isValid() ? false : true}
                variant="contained"
                onClick={() => setOpenPublishConfirmationDialog(true)}
                // autoFocus
                disableElevation
                // disabled={location?.state?.isAuditedWhileCreating}
                className="btn btn-dark"
              >
                {isEditMode ? "Publish Changes" : "Publish"}
              </Button>
            </div>
          )}
        </div>

        {isViewMode && (
          <Stack columnGap={"1rem"} justifyContent={"flex-start"}>
            <Typography>
              <Typography component={"i"}> Created on:&nbsp;</Typography>
              {`${formatDateTime(
                testimonialCopy.createdAt,
                Constants.dateFormatDDmmYYYY
              )} by `}
              <Typography component={"i"}>
                {`${testimonialCopy?.testimonialCreatedBy?.firstName}`}
              </Typography>
            </Typography>
          </Stack>
        )}
        <FormFieldContainer>
          {!isDisabled ? (
            <RadioButtonGroupComponent
              onChange={(event) => {
                setTestimonialType(event.target.value);
                setTestimonial("");
                setTestimonialError("");
              }}
              className={styles["descrition-radio-btn"]}
              value={testimonialType}
            >
              {testmonialTypes.map((item: any) => {
                return (
                  <FormControlLabel
                    key={item.value}
                    name={item.value}
                    value={item.value}
                    control={<StyledRadio />}
                    label={item.label}
                    checked={testimonialType === item.value}
                  />
                );
              })}
            </RadioButtonGroupComponent>
          ) : (
            ""
          )}

          <Grid container spacing={3} className="margin-bottom-30px">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextFieldComponent
                required
                disabled={isDisabled}
                type="text"
                label="First Name"
                placeholder="Enter"
                name="firstName"
                value={firstName}
                inputProps={{ maxLength: 15 }}
                onBlur={handleOnBlurFirstName}
                onChange={handleFirstNameChange}
                error={firstNameError !== "" ? true : false}
                fieldhelpertext={firstNameError}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextFieldComponent
                required
                disabled={isDisabled}
                type="text"
                label="Last Name"
                placeholder="Enter"
                name="lastName"
                value={lastName}
                inputProps={{ maxLength: 15 }}
                onBlur={handleOnBlurLastName}
                onChange={handleLastNameChange}
                error={lastNameError !== "" ? true : false}
                fieldhelpertext={lastNameError}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="margin-bottom-30px">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextFieldComponent
                required
                disabled={isDisabled}
                type="text"
                label="Designation"
                placeholder="Enter"
                name="designation"
                value={designation}
                inputProps={{ maxLength: 25 }}
                onBlur={handleOnBlurDesignation}
                onChange={handleDesignationChange}
                error={designationError !== "" ? true : false}
                fieldhelpertext={designationError}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextFieldComponent
                required
                disabled={isDisabled}
                type="text"
                label="Company Name"
                placeholder="Enter"
                name="companyName"
                value={companyName}
                inputProps={{ maxLength: 60 }}
                onBlur={handleOnBlurCompanyName}
                onChange={handleCompanyNameChange}
                error={companyNameError !== "" ? true : false}
                fieldhelpertext={companyNameError}
              />
            </Grid>
          </Grid>

          {testimonialType === TestimonailTypeEnum.VideoTestimonial ? (
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <TextFieldComponent
                  required
                  disabled={isDisabled}
                  type="text"
                  label="Video URL"
                  placeholder="Enter"
                  name="testimonial"
                  value={testimonial}
                  // inputProps={{ maxLength: 250 }}
                  onBlur={handleOnBlurTestimonial}
                  onChange={handleTestimonialChange}
                  error={testimonialError !== "" ? true : false}
                  fieldhelpertext={testimonialError}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextFieldComponent
                  required
                  disabled={isDisabled}
                  type="text"
                  label="Testimonial"
                  placeholder="Enter"
                  name="testimonial"
                  value={testimonial}
                  multiline
                  minRows={4}
                  inputProps={{ maxLength: 250 }}
                  onBlur={handleOnBlurTestimonial}
                  onChange={handleTestimonialChange}
                  error={testimonialError !== "" ? true : false}
                  fieldhelpertext={testimonialError}
                />
              </Grid>
            </Grid>
          )}
        </FormFieldContainer>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <PageHeading title="Marketing Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

      <Container className="main-container">{renderForm()}</Container>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Testimonial?"
        content={`This action will delete the testimonial. Are you sure you want to continue?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={() => handleDeleteTestimonial(testimonialCopy.id)}
      />

      {isSuccess ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message}
          isSuccess={true}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}

      {error ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
    </Fragment>
  );
};

export default TestimonialCreation;
