import Container from "@mui/material/Container";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeading from "../../../../components/page-heading/PageHeading";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import styles from "./FooterTabs.module.css";
import { Avatar, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { RouteConstants } from "routes/RouteConstants";
import { validationSchema } from "./FooterTabsValidations";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import {
  ButtonComponent,
  LoadingButtonComponent,
  TextFieldComponent,
  FormFieldContainer,
  SwitchComponent,
  MediaUploadComponent,
} from "../../../../components/ui-elements";
import { useDispatch, useSelector } from "react-redux";
import PageManagentAction from "redux-container/page-management/PageManagementRedux";
import MediaAssets from "assets";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { footerTabConstants } from "./FooterTabsConstants";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import Loader from "components/loader/Loader";
import DelayComponent from "./DelayComponent";

const actionDispatch = (dispatch: any) => {
  return {
    addPageManagementData: (params: any) =>
      dispatch(PageManagentAction.addPageManagementDataRequest(params)),
    updatePageManagentData: (params: any) =>
      dispatch(PageManagentAction.updatePageManagementDataRequest(params)),
    getPageManagementData: (params: any) =>
      dispatch(PageManagentAction.getPageManagementDataRequest(params)),
    fileUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    deleteFile: (data: any) =>
      dispatch(FileUploadActions.fileDeleteRequest(data)),
    setPageManagementState: (key: any, data: any) =>
      dispatch(PageManagentAction.setPageManagementState(key, data)),
  };
};

const breadcrumbRoutes: any = [
  {
    name: "Page Management",
    route: RouteConstants.pageManagementDashboard,
  },
  {
    name: "Page 6 - Footer Tabs",
    route: RouteConstants.page6FooterTabs,
  },
];

const FooterTabs = () => {
  const handleCancel = () => {
    const goBack = -1;
    navigate(goBack);
  };
  let navigate = useNavigate();
  const location: any = useLocation();

  const [isViewMode, setIsViewMode] = useState(
    location?.state?.isViewMode ? location?.state?.isViewMode : false
  );

  const fileUploadDetails = useSelector(
    (state: any) => state.fileUpload.imageDetails
  );
  const {
    addPageManagementData,
    updatePageManagentData,
    getPageManagementData,
    fileUpload,
    deleteFile,
  } = actionDispatch(useDispatch());
  const footerPageData: any = useSelector(
    (state: any) => state?.pageManagement?.pageManagementData?.page
  );
  const [homePageMedia, setHomePageMedia] = useState<any>(
    footerPageData?.footerTabs?.homePage?.media?.value?.url
      ? footerPageData?.footerTabs?.homePage?.media?.value?.url
      : ""
  );
  const [investmentMedia, setInvestmentMedia] = useState<any>(
    footerPageData?.footerTabs?.investmentPage?.media?.value?.url
      ? footerPageData?.footerTabs?.investmentPage?.media?.value?.url
      : ""
  );
  const [promiseMedia, setPromiseMedia] = useState<any>(
    footerPageData?.footerTabs?.promisePage?.media?.value?.url
      ? footerPageData?.footerTabs?.promisePage?.media?.value?.url
      : ""
  );
  const [portfolioMedia, setPortfolioMedia] = useState<any>(
    footerPageData?.footerTabs?.portfolioPage?.media?.value?.url
      ? footerPageData?.footerTabs?.portfolioPage?.media?.value?.url
      : ""
  );
  const [profileMediaFiles, setProfileMediaFiles] = useState<any>(
    footerPageData?.footerTabs?.profilePage?.media?.value?.url
      ? footerPageData?.footerTabs?.profilePage?.media?.value?.url
      : ""
  );
  const [mediaIndexOfUpload, setMediaIndexOfUpload] = useState(0);
  const isLoading = useSelector(
    (state: any) => state?.pageManagement?.isLoading
  );
  const message = useSelector((state: any) => state?.pageManagement?.message);
  const error = useSelector((state: any) => state?.pageManagement?.error);
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [isHomePageMediaLoading, setIsHomeMediaLoading] =
    useState<boolean>(false);
  const [isInvestmentMediaLoading, setIsInvestmentMediaLoading] =
    useState<boolean>(false);
  const [isPromiseMediaLoading, setIsPromiseMediaLoading] =
    useState<boolean>(false);
  const [isPortfolioMedia, setIsPortfolioMedia] = useState<boolean>(false);
  const [isProfileMediaFilesLoading, setIsProfileMediaFilesLoading] =
    useState<boolean>(false);
  const mediaLoader = useSelector((state: any) => state?.fileUpload?.isLoading);
  const isSuccess = useSelector((state: any) => state.pageManagement.isSuccess);

  const [clear, setClear] = useState(false);
  const [homePageName, setHomePageName] = useState("");

  useEffect(() => {
    const data = {
      pageType: "5006",
    };
    getPageManagementData(data);
  }, [clear]);

  const defaultValues: any = {
    footerName: footerPageData?.footerTabs?.homePage?.footerName
      ? footerPageData?.footerTabs?.homePage?.footerName
      : "",
    mediaFiles: homePageMedia,
    investmentFooterName: footerPageData?.footerTabs?.investmentPage?.footerName
      ? footerPageData?.footerTabs?.investmentPage?.footerName
      : "",
    investmentMediaFiles: investmentMedia,
    promiseFooterName: footerPageData?.footerTabs?.promisePage?.footerName
      ? footerPageData?.footerTabs?.promisePage?.footerName
      : "",
    promiseMediaFiles: promiseMedia,
    portfolioFooterName: footerPageData?.footerTabs?.portfolioPage?.footerName
      ? footerPageData?.footerTabs?.portfolioPage?.footerName
      : "",
    portfolioMediaFiles: portfolioMedia,
    profileFooterName: footerPageData?.footerTabs?.profilePage?.footerName
      ? footerPageData?.footerTabs?.profilePage?.footerName
      : "",
    profileMediaFiles: profileMediaFiles,
    isPromisePageActive: footerPageData?.footerTabs?.promisePage?.isActive
      ? footerPageData?.footerTabs?.promisePage?.isActive
      : false,
  };

  useEffect(() => {
    setClear(true);
    switch (mediaIndexOfUpload) {
      case 1:
        return setHomePageMedia(fileUploadDetails?.url);

      case 2:
        return setInvestmentMedia(fileUploadDetails?.url);

      case 3:
        return setPromiseMedia(fileUploadDetails?.url);

      case 4:
        return setPortfolioMedia(fileUploadDetails?.url);

      case 5:
        return setProfileMediaFiles(fileUploadDetails?.url);

      default:
        break;
    }
  }, [mediaIndexOfUpload, fileUploadDetails?.url]);

  useEffect(() => {
    if (footerPageData?.footerTabs?.homePage?.media?.value?.url?.length > 0) {
      setHomePageMedia(footerPageData?.footerTabs?.homePage?.media?.value?.url);
    }
    if (
      footerPageData?.footerTabs?.investmentPage?.media?.value?.url?.length > 0
    ) {
      setInvestmentMedia(
        footerPageData?.footerTabs?.investmentPage?.media?.value?.url
      );
    }
    if (
      footerPageData?.footerTabs?.promisePage?.media?.value?.url?.length > 0
    ) {
      setPromiseMedia(
        footerPageData?.footerTabs?.promisePage?.media?.value?.url
      );
    }
    if (
      footerPageData?.footerTabs?.portfolioPage?.media?.value?.url?.length > 0
    ) {
      setPortfolioMedia(
        footerPageData?.footerTabs?.portfolioPage?.media?.value?.url
      );
    }
    if (
      footerPageData?.footerTabs?.profilePage?.media?.value?.url?.length > 0
    ) {
      setProfileMediaFiles(
        footerPageData?.footerTabs?.profilePage?.media?.value?.url
      );
    }
  }, [
    footerPageData?.footerTabs?.homePage?.media?.value?.url,
    footerPageData?.footerTabs?.homePage?.footerName,
  ]);

  useEffect(() => {
    if (footerPageData?.footerTabs?.homePage?.footerName) {
      setHomePageName(footerPageData?.footerTabs?.homePage?.footerName);
    }
  }, [
    footerPageData?.footerTabs?.homePage?.footerName,
    homePageName,
    footerPageData?.footerTabs?.homePage?.media?.value?.url,
  ]);

  const handleuploadImage = (file: any, indexCount: any) => {
    setIsInvestmentMediaLoading(false);
    setIsHomeMediaLoading(false);
    setIsPromiseMediaLoading(false);
    setIsPortfolioMedia(false);
    setIsProfileMediaFilesLoading(false);

    setMediaIndexOfUpload(indexCount);

    let imageUrl;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
      };
      reader.readAsArrayBuffer(file);
      fileUpload(data);
    } else {
      imageUrl = null;
    }

    switch (indexCount) {
      case 1:
        return setIsHomeMediaLoading(true);
      case 2:
        return setIsInvestmentMediaLoading(true);
      case 3:
        return setIsPromiseMediaLoading(true);
      case 4:
        return setIsPortfolioMedia(true);
      case 5:
        return setIsProfileMediaFilesLoading(true);

      default:
        break;
    }
  };

  const handleOnGlobalSave = (value: any) => {
    const data = {
      id: footerTabConstants.id,
      isPublished: true,
      pageType: footerTabConstants.pageType,
      pageName: footerTabConstants.pageName,
      footerTabs: {
        homePage: {
          footerName: value.footerName,
          media: {
            name: homePageMedia.split("/")[3],
            key: homePageMedia.split("/")[3],
            value: {
              mediaType: "image",
              size: 10,
              width: 10,
              height: 10,
              url: homePageMedia,
              isActive: true,
            },
          },
        },
        investmentPage: {
          footerName: value.investmentFooterName,
          media: {
            name: investmentMedia.split("/")[3],
            key: investmentMedia.split("/")[3],
            value: {
              mediaType: "image",
              size: 10,
              width: 10,
              height: 10,
              url: investmentMedia,
              isActive: true,
            },
          },
        },
        promisePage: {
          footerName: value.promiseFooterName,
          isActive: value.isPromisePageActive,
          media: {
            name: promiseMedia.split("/")[3],
            key: promiseMedia.split("/")[3],
            value: {
              mediaType: "image",
              size: 10,
              width: 10,
              height: 10,
              url: promiseMedia,
              isActive: true,
            },
          },
        },
        portfolioPage: {
          footerName: value.portfolioFooterName,
          media: {
            name: portfolioMedia.split("/")[3],
            key: portfolioMedia.split("/")[3],
            value: {
              mediaType: "image",
              size: 10,
              width: 10,
              height: 10,
              url: portfolioMedia,
              isActive: true,
            },
          },
        },
        profilePage: {
          footerName: value.profileFooterName,
          media: {
            name: profileMediaFiles.split("/")[3],
            key: profileMediaFiles.split("/")[3],
            value: {
              mediaType: "image",
              size: 10,
              width: 10,
              height: 10,
              url: profileMediaFiles,
              isActive: true,
            },
          },
        },
      },
    };

    if (
      !footerPageData?.footerTabs?.homePage?.footerName &&
      !footerPageData?.footerTabs?.homePage?.media.value.url
    ) {
      addPageManagementData(data);
    } else {
      updatePageManagentData(data);
    }
    setShowFlashMessage(true);
  };

  const handleCloseFlashMessageDialog = () => {
    setShowFlashMessage(false);
    const data = {
      pageType: "5006",
    };
    getPageManagementData(data);
    navigate(RouteConstants.pageManagementDashboard);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleOkConfirmationDialog = () => {
    let fileName =
      deleteIndex === 1
        ? homePageMedia
        : deleteIndex === 2
        ? investmentMedia
        : deleteIndex === 3
        ? promiseMedia
        : deleteIndex === 4
        ? portfolioMedia
        : profileMediaFiles;
    if (fileName) {
      fileName = fileName.split("/")[3];
    }
    // deleteFile(fileName);
    setOpenConfirmationDialog(false);
    switch (deleteIndex) {
      case 1:
        return setHomePageMedia("");
      case 2:
        return setInvestmentMedia("");
      case 3:
        return setPromiseMedia("");
      case 4:
        return setPortfolioMedia("");
      case 5:
        return setProfileMediaFiles("");
      default:
        break;
    }
  };

  const handleFileDelete = (deleteIndex: any) => {
    setDeleteIndex(deleteIndex);
    setOpenConfirmationDialog(true);
  };
  return (
    <Fragment>
      <PageHeading title="Page Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      {isLoading ? (
        <Loader />
      ) : (
        <DelayComponent>
          <Formik
            initialValues={defaultValues}
            onSubmit={handleOnGlobalSave}
            validationSchema={validationSchema}
          >
            {({
              values,
              handleSubmit,
              isValid,
              handleChange,
              errors,
              setFieldValue,
              handleBlur,
              touched,
              dirty
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                {showFlashMessage && (
                  <FlashMessageDialog
                    shouldOpen={showFlashMessage}
                    content={error ? error : message}
                    isSuccess={error ? false : true}
                    cancelHandler={handleCloseFlashMessageDialog}
                  />
                )}
                {openConfirmationDialog && (
                  <ConfirmationDialog
                    shouldOpen={openConfirmationDialog}
                    title="Delete Media Image?"
                    content="This action will Delete Media Image data. Are you sure you want to continue?"
                    okText="Delete"
                    cancelHandler={handleCloseConfirmationDialog}
                    okHandler={handleOkConfirmationDialog}
                  />
                )}
                <Container className="main-container">
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography className="heading">
                      Page 6 - Footer Tabs
                    </Typography>

                    <Stack direction={"row"} columnGap={2}>
                      {isViewMode ? (
                        <LoadingButtonComponent
                          onClick={() => setIsViewMode(false)}
                        >
                          Edit
                        </LoadingButtonComponent>
                      ) : (
                        <>
                          <ButtonComponent
                            color={"inherit"}
                            onClick={handleCancel}
                          >
                            Cancel
                          </ButtonComponent>
                          {/* 
                    <ButtonComponent
                      type="reset"
                      disabled={!isValid}
                    onClick={() => handleSaveAndNew(values, resetForm)}
                    >
                      Save As Draft
                    </ButtonComponent> */}

                          <LoadingButtonComponent
                            type="submit"
                            disabled={
                              !profileMediaFiles ||
                              !portfolioMedia ||
                              !promiseMedia ||
                              !investmentMedia ||
                              !homePageMedia 
                            }
                            onSubmit={() => handleOnGlobalSave(values)}
                          >
                            {footerPageData?.id !== undefined
                              ? "Publish Changes"
                              : "Publish"}
                          </LoadingButtonComponent>
                        </>
                      )}
                    </Stack>
                  </Stack>

                  <FormFieldContainer>
                    <Typography className={styles["home-page-heading"]}>
                      <h3 className="accordion-heading">Home Page</h3>
                    </Typography>
                    <Divider className={styles.Divider} />

                    <Stack
                      justifyContent={"space-between"}
                      className={styles["heading-text-alignmnet"]}
                      alignItems={"top"}
                    >
                      <Stack alignItems={"top"}>
                        <label className={styles["input-label-alignmnet"]}>
                          This Tab Name (Appears in footer)*
                        </label>
                        <TextFieldComponent
                          required
                          disabled={isViewMode}
                          type="text"
                          label="Footer Name"
                          placeholder="Enter"
                          name="footerName"
                          value={values?.footerName}
                          inputProps={{ maxLength: 20 }}
                          fieldhelpertext={
                            errors?.footerName &&
                            touched?.footerName &&
                            errors.footerName
                          }
                          onBlur={handleBlur}
                          onChange={(event: any) => {
                            setFieldValue(
                              `footerName`,
                              event.target.value.replace(/[^A-Za-z ]/gi, "")
                            );
                          }}
                          error={
                            touched?.footerName &&
                            (values?.footerName.length > 0 ? false : true)
                          }
                        />
                      </Stack>
                      <div style={{ width: "340px", marginLeft: "20px" }}>
                        <MediaUploadComponent
                          disabled={isViewMode}
                          loading={isHomePageMediaLoading && mediaLoader}
                          ondrop={handleChange}
                          onBlur={handleBlur}
                          name={"mediaFiles"}
                          inputId={1}
                          onChange={(event: any) => {
                            const fileDetails = event?.target.files[0];
                            setIsHomeMediaLoading(false);
                            handleuploadImage(fileDetails, 1);
                          }}
                          previewimage={homePageMedia}
                          cancelimage={() => {
                            handleFileDelete(1);
                          }}
                          fieldhelpertext={
                            errors?.mediaFiles &&
                            touched?.mediaFiles &&
                            !values?.mediaFiles &&
                            "Required"
                          }
                        />
                      </div>
                    </Stack>
                    {/* <Divider /> */}

                    {/* <div className={styles["save-btn"]}>
                    <LoadingButtonComponent >
                      Save
                    </LoadingButtonComponent>
                  </div> */}
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <Typography className={styles["home-page-heading"]}>
                      <h3 className="accordion-heading">Investment Page</h3>
                    </Typography>
                    <Divider className={styles.Divider} />

                    <Stack
                      justifyContent={"space-between"}
                      className={styles["heading-text-alignmnet"]}
                      alignItems={"top"}
                    >
                      <Stack alignItems={"top"}>
                        <label className={styles["input-label-alignmnet"]}>
                          This Tab Name (Appears in footer)*
                        </label>

                        <TextFieldComponent
                          required
                          disabled={isViewMode}
                          type="text"
                          label="Footer Name"
                          placeholder="Enter"
                          name="investmentFooterName"
                          value={values?.investmentFooterName}
                          inputProps={{ maxLength: 20 }}
                          fieldhelpertext={
                            values?.investmentFooterName?.length < 1 &&
                            touched.investmentFooterName &&
                            "Required"
                          }
                          onBlur={handleBlur}
                          onChange={(event: any) => {
                            setFieldValue(
                              `investmentFooterName`,
                              event.target.value.replace(/[^A-Za-z ]/gi, "")
                            );
                          }}
                          error={
                            touched?.investmentFooterName &&
                            (values?.investmentFooterName?.length > 0
                              ? false
                              : true)
                          }
                        />
                      </Stack>
                      <div style={{ width: "340px", marginLeft: "20px" }}>
                        <MediaUploadComponent
                          disabled={isViewMode}
                          loading={isInvestmentMediaLoading && mediaLoader}
                          ondrop={handleChange}
                          onBlur={handleBlur}
                          name={"investmentMediaFiles"}
                          inputId={2}
                          onChange={(event: any) => {
                            const fileDetails = event?.target.files[0];
                            setIsInvestmentMediaLoading(false);
                            handleuploadImage(fileDetails, 2);
                          }}
                          previewimage={investmentMedia}
                          cancelimage={() => {
                            handleFileDelete(2);
                          }}
                          fieldhelpertext={
                            errors?.mediaFiles &&
                            touched?.mediaFiles &&
                            !values?.mediaFiles &&
                            "Required"
                          }
                        />
                      </div>
                    </Stack>
                    {/* <Divider /> */}

                    {/* <div className={styles["save-btn"]}>
                    <LoadingButtonComponent >
                      Save
                    </LoadingButtonComponent>
                  </div> */}
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <Stack justifyContent="flex-start" spacing={2} mb={3}>
                      <Typography>
                        <h3 className="accordion-heading">Promise Page</h3>
                      </Typography>
                      <SwitchComponent
                        disabled={isViewMode}
                        name={"isPromisePageActive"}
                        checked={values?.isPromisePageActive}
                        onClick={() =>
                          setFieldValue(
                            "isPromisePageActive",
                            !values.isPromisePageActive
                          )
                        }
                      />
                      <Tooltip
                        title={
                          "Toggle is to show/hide this section on the Customer App"
                        }
                        placement="top"
                        arrow
                      >
                        <Avatar
                          className={styles["info-icon"]}
                          src={MediaAssets.ic_info}
                          variant="rounded"
                        />
                      </Tooltip>
                    </Stack>
                    <Divider className={styles.Divider} />

                    <Stack
                      justifyContent={"space-between"}
                      className={styles["heading-text-alignmnet"]}
                      alignItems={"top"}
                    >
                      <Stack alignItems={"top"}>
                        <label className={styles["input-label-alignmnet"]}>
                          This Tab Name (Appears in footer)*
                        </label>
                        <TextFieldComponent
                          required
                          disabled={isViewMode}
                          type="text"
                          label="Footer Name"
                          placeholder="Enter"
                          name="promiseFooterName"
                          value={values?.promiseFooterName}
                          inputProps={{ maxLength: 20 }}
                          fieldhelpertext={
                            values?.promiseFooterName?.length < 1 &&
                            touched.promiseFooterName &&
                            "Required"
                          }
                          onBlur={handleBlur}
                          onChange={(event: any) => {
                            setFieldValue(
                              `promiseFooterName`,
                              event.target.value.replace(/[^A-Za-z ]/gi, "")
                            );
                          }}
                          error={
                            touched?.promiseFooterName &&
                            (values?.promiseFooterName?.length > 0
                              ? false
                              : true)
                          }
                        />
                      </Stack>
                      <div style={{ width: "340px", marginLeft: "20px" }}>
                        <MediaUploadComponent
                          name={"promiseMediaFiles"}
                          inputId={3}
                          disabled={isViewMode}
                          loading={isPromiseMediaLoading && mediaLoader}
                          ondrop={handleChange}
                          onBlur={handleBlur}
                          onChange={(event: any) => {
                            const fileDetails = event?.target.files[0];
                            setIsPromiseMediaLoading(false);
                            handleuploadImage(fileDetails, 3);
                          }}
                          previewimage={promiseMedia}
                          cancelimage={() => {
                            handleFileDelete(3);
                          }}
                          fieldhelpertext={
                            errors?.promiseMediaFiles &&
                            touched?.promiseMediaFiles &&
                            !values?.promiseMediaFiles &&
                            "Required"
                          }
                        />
                      </div>
                    </Stack>
                    {/* <Divider /> */}

                    {/* <div className={styles["save-btn"]}>
                    <LoadingButtonComponent>
                      Save
                    </LoadingButtonComponent>
                  </div> */}
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <Typography className={styles["home-page-heading"]}>
                      <h3 className="accordion-heading">Portfolio Page</h3>
                    </Typography>
                    <Divider className={styles.Divider} />

                    <Stack
                      justifyContent={"space-between"}
                      className={styles["heading-text-alignmnet"]}
                      alignItems={"top"}
                    >
                      <Stack alignItems={"top"}>
                        <label className={styles["input-label-alignmnet"]}>
                          This Tab Name (Appears in footer)*
                        </label>
                        <TextFieldComponent
                          required
                          disabled={isViewMode}
                          type="text"
                          label="Footer Name"
                          placeholder="Enter"
                          name="portfolioFooterName"
                          value={values?.portfolioFooterName}
                          inputProps={{ maxLength: 20 }}
                          fieldhelpertext={
                            values?.portfolioFooterName?.length < 1 &&
                            touched.portfolioFooterName &&
                            "Required"
                          }
                          onBlur={handleBlur}
                          onChange={(event: any) => {
                            setFieldValue(
                              `portfolioFooterName`,
                              event.target.value.replace(/[^A-Za-z ]/gi, "")
                            );
                          }}
                          error={
                            touched?.portfolioFooterName &&
                            (values?.portfolioFooterName?.length > 0
                              ? false
                              : true)
                          }
                        />
                      </Stack>
                      <div style={{ width: "340px", marginLeft: "20px" }}>
                        <MediaUploadComponent
                          name={"portfolioMediaFiles"}
                          inputId={4}
                          disabled={isViewMode}
                          loading={isPortfolioMedia && mediaLoader}
                          ondrop={handleChange}
                          onBlur={handleBlur}
                          onChange={(event: any) => {
                            const fileDetails = event?.target.files[0];
                            setIsPortfolioMedia(false);
                            handleuploadImage(fileDetails, 4);
                          }}
                          previewimage={portfolioMedia}
                          cancelimage={() => {
                            handleFileDelete(4);
                          }}
                          fieldhelpertext={
                            errors?.portfolioMediaFiles &&
                            touched?.portfolioMediaFiles &&
                            !values?.portfolioMediaFiles &&
                            "Required"
                          }
                        />
                      </div>
                    </Stack>
                    {/* <Divider /> */}

                    {/* <div className={styles["save-btn"]}>
                    <LoadingButtonComponent >
                      Save
                    </LoadingButtonComponent>
                  </div> */}
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <Typography>
                      <h3 className="accordion-heading">Profile Page</h3>
                    </Typography>
                    <Divider className={styles.Divider} />

                    <Stack
                      justifyContent={"space-between"}
                      className={styles["heading-text-alignmnet"]}
                      alignItems={"top"}
                    >
                      <Stack alignItems={"top"}>
                        <label className={styles["input-label-alignmnet"]}>
                          This Tab Name (Appears in footer)*
                        </label>
                        <TextFieldComponent
                          required
                          disabled={isViewMode}
                          type="text"
                          label="Footer Name"
                          placeholder="Enter"
                          name="profileFooterName"
                          value={values?.profileFooterName}
                          inputProps={{ maxLength: 20 }}
                          fieldhelpertext={
                            values?.profileFooterName?.length < 1 &&
                            touched.profileFooterName &&
                            "Required"
                          }
                          onBlur={handleBlur}
                          onChange={(event: any) => {
                            setFieldValue(
                              `profileFooterName`,
                              event.target.value.replace(/[^A-Za-z ]/gi, "")
                            );
                          }}
                          error={
                            touched?.profileFooterName &&
                            (values?.profileFooterName?.length > 0
                              ? false
                              : true)
                          }
                        />
                      </Stack>
                      <div style={{ width: "340px", marginLeft: "20px" }}>
                        <MediaUploadComponent
                          name={"profileMediaFiles"}
                          inputId={5}
                          disabled={isViewMode}
                          loading={isProfileMediaFilesLoading && mediaLoader}
                          ondrop={handleChange}
                          onBlur={handleBlur}
                          value={values?.profileMediaFiles}
                          onChange={(event: any) => {
                            const fileDetails = event?.target.files[0];
                            setIsProfileMediaFilesLoading(false);
                            handleuploadImage(fileDetails, 5);
                          }}
                          previewimage={profileMediaFiles}
                          cancelimage={() => {
                            handleFileDelete(5);
                          }}
                          fieldhelpertext={
                            errors?.profileMediaFiles &&
                            touched?.profileMediaFiles &&
                            !values?.profileMediaFiles &&
                            "Required"
                          }
                        />
                      </div>
                    </Stack>
                    {/* <Divider /> */}

                    {/* <div className={styles["save-btn"]}>
                    <LoadingButtonComponent>
                      Save
                    </LoadingButtonComponent>
                  </div> */}
                  </FormFieldContainer>
                </Container>
              </Form>
            )}
          </Formik>
        </DelayComponent>
      )}
    </Fragment>
  );
};

export default FooterTabs;
