import { FormControlLabel, Grid, Stack, Typography } from "@mui/material";
import {
  InfoComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { textFieldMaxCharLimitFor } from "./PromiseCreationConstants";
import QuillEditor from "components/quill-editor/QuillEditor";
import { stripHtml } from "utils/ValidationUtils";

interface ITermsAndConditions {
  isViewMode: boolean;
  fieldTitle: any;
  handleTextEditor: any;
}

const TermsAndConditions = (props: ITermsAndConditions) => {
  const { isViewMode, fieldTitle, handleTextEditor } = props;
  const {
    isValid,
    dirty,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldError,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext<any>();

  useEffect(() => {
    if (
      values?.isTermsAndConditionsActive &&
      !stripHtml(values?.termsAndConditions?.description)?.length
    ) {
      setFieldError(
        "termsAndConditions.description",
        "description is required"
      );
    }
  }, [
    values?.isTermsAndConditionsActive,
    values?.termsAndConditions?.description,
  ]);
  return (
    <div>
      <Stack mt={"1.5rem"} justifyContent={"flex-start"}>
        <FormControlLabel
          className="switch-form-label"
          name={"isTermsAndConditionsActive"}
          checked={values.isTermsAndConditionsActive}
          onChange={(e, newValue) =>
            setFieldValue("isTermsAndConditionsActive", newValue)
          }
          control={<SwitchComponent />}
          label="Terms & Conditions"
          labelPlacement="start"
          disabled={isViewMode}
        />
        <InfoComponent infoContent="Toggle is to show/hide this section on the Customer App" />
      </Stack>
      <Grid container rowSpacing={2} className={"box"}>
        <Grid item xs={3}>
          <TextFieldComponent
            label={fieldTitle.displayName}
            disabled={isViewMode}
            fieldhelpertext={
              getIn(touched, "termsAndConditions.displayName") &&
              getIn(errors, "termsAndConditions.displayName")
            }
            error={Boolean(
              getIn(touched, "termsAndConditions.displayName") &&
                getIn(errors, "termsAndConditions.displayName")
            )}
            inputProps={{
              "data-testid": "title",
              maxLength: textFieldMaxCharLimitFor.displayName,
            }}
            InputProps={{
              readOnly: isViewMode,
            }}
            name={"termsAndConditions.displayName"}
            value={values.termsAndConditions?.displayName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Description</Typography>
          <QuillEditor
            readonly={isViewMode}
            value={values.termsAndConditions?.description}
            onChange={(name: string, newValue: string, options: any) =>
              handleTextEditor(name, newValue, options, {
                setFieldValue,
                setFieldError,
                setFieldTouched,
              })
            }
            name={"termsAndConditions.description"}
            placeholder={"Enter Description"}
            fieldhelpertext={
              getIn(errors, "termsAndConditions.description") ||
              (values?.isTermsAndConditionsActive &&
              !stripHtml(values?.termsAndConditions?.description)?.length
                ? "description is Required"
                : "")
            }
            error={Boolean(
              // getIn(touched, "termsAndConditions.description") &&
              getIn(errors, "termsAndConditions.description")
            )}
            inputProps={{
              maxLength:
                textFieldMaxCharLimitFor.discriptionForTermsAndConditions,
            }}
            style={{ height: "20rem" }}
            disabled={isViewMode}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TermsAndConditions;
