import { Constants } from "constants/Constants";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  resetPasswordSuccess: ["response"],
  resetPasswordFailure: ["error"],
  resetPasswordRequest: ["data"],

  setResetPasswordState: ["key", "value"],
});

export const ResetPasswordTypes = Types;

export const ResetPasswordAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  data: null,
  code: null,
  isSuccessfulResetPassword: false,
};

export const resetPasswordRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccessfulResetPassword: false,
  };
};
export const resetPasswordSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    data: action.response,
    error: null,
    isSuccessfulResetPassword: true,
  };
};

export const resetPasswordFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    code: action.error.code,
    error: action.error.message,
    isSuccessfulResetPassword: true,
  };
};

export const setResetPasswordState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};
export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,
  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.SET_RESET_PASSWORD_STATE]: setResetPasswordState,
});
