import * as Yup from "yup";

export const landingPageValidationSchema = Yup.object({
  urlDescription: Yup.object().shape({
    media: Yup.object().shape({
      value: Yup.object().shape({
        url: Yup.mixed().required("Required"),
      }),
    }),
  }),
});
