import Button from "@mui/material/Button";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./FlashMessageDialog.module.css";
import MediaAssets from "../../assets";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

interface IFlashMessageDialogProps {
  shouldOpen: boolean;
  content: string;
  isSuccess: boolean;
  showWarning?: boolean;
  cancelHandler: (e?: any) => void;
  cancel?: boolean;
}

const FlashMessageDialog = ({
  shouldOpen,
  content,
  isSuccess,
  showWarning,
  cancelHandler,
  cancel,
}: IFlashMessageDialogProps) => {
  const dialogContent = content ? content : "";
  const okBtnText = "Okay";
  const cancelBtnText = "Cancel";

  const handleClose = () => {
    cancelHandler();
  };

  return (
    <Dialog
      open={shouldOpen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xs"}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ justifyContent: "end" }}>
        <CloseRoundedIcon
          className={styles["close-icon"]}
          onClick={handleClose}
          titleAccess="Close"
        />
      </DialogTitle>

      <DialogContent className={styles["content-alignment"]}>
        <img
          src={
            isSuccess === true
              ? `${MediaAssets.ic_success_tick}`
              : showWarning ? `${MediaAssets.ic_warning}`
              : `${MediaAssets.ic_error_cross}`
          }
          alt="White tick inside green circle"
          className={styles["success-error-image"]}
        />
        <h2
          className={
            isSuccess ? styles["success-heading"] : showWarning ? styles["warning-heading"] : styles["error-heading"]
          }
        >
          {isSuccess ? "SUCCESS!" : showWarning ? "WARNING!" : "ERROR!"}
        </h2>
        <DialogContentText className={styles.content}>
          {dialogContent}
        </DialogContentText>
      </DialogContent>

      <DialogActions className={styles["flash-message-dialog-actions"]}>
        {cancel && (
          <Button
            color="inherit"
            variant="contained"
            onClick={handleClose}
            disableElevation
            className="btn btn-light-outline"
          >
            {cancelBtnText}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleClose}
          // autoFocus
          disableElevation
          className="btn btn-dark"
        >
          {okBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FlashMessageDialog;
