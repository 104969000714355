import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import ProjectAllFaqsListAction from "redux-container/project-management/project-content/ProjectAllFaqsListRedux"

export function* getProjectAllFaqsListDataSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.getProjectFaqs, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        ProjectAllFaqsListAction.getProjectAllFaqsListDataSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProjectAllFaqsListAction.getProjectAllFaqsListDataFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      ProjectAllFaqsListAction.getProjectAllFaqsListDataFailure(error)
    );
  }
}
