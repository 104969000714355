import { styled } from "@mui/material";

export const BasicDetailStyleWrapper = styled("div")`
  .section-gutter {
    padding: 1.5rem 3rem;
    ${({ theme }) => theme.breakpoints.down("xl")} {
      padding: 1.5rem;
    }
  }
  .grid-container{
    justify-content: space-between;
  }
  .section-heading {
    font-family: "Jost-Medium";
    margin: 0.5rem 0 1rem;
  }
  .bottom-spacing {
    margin-bottom: 0.5rem;
    font-family: "Jost-Medium";
  }
  .switch-form-label {
    column-gap: 0.5rem;
    & .MuiFormControlLabel-label {
      font-family: "Jost-Medium";
    }
  }
`;
