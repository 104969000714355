import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import {
  AutocompleteComponent,
  RadioButtonGroupComponent,
  StyledRadio,
  TextFieldComponent,
} from "components/ui-elements";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";

import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { RouteConstants } from "routes/RouteConstants";
import { useNavigate } from "react-router-dom";
import CarouselMediaUploader from "../carousels/CarouselMediaUploader";
const DialogWrapper = styled(Box)`
  /* .MuiPaper-root {
    max-width: 800;
    width: 50rem;

    background-color: red;
  } */
  .dialogResize {
    width: 50rem !important;
  }
`;
const initialValues = {
  webMedia: {
    key: "",
    name: "",
    value: {
      url: "",
      size: 1,
      width: 1,
      height: 1,
      mediaType: "VIDEO",
    },
    isActive: false,
  },
  mobileMedia: {
    key: "",
    name: "",
    value: {
      url: "",
      size: 1,
      width: 1,
      height: 1,
      mediaType: "VIDEO",
    },
    isActive: false,
  },
  pageManagementId: "",

  priority: "",
};

const actionDispatch = (dispatch: any) => {
  return {
    setUploadFileState: (key: any, data: any) => {
      dispatch(FileUploadActions.setUploadFileState(key, data));
    },
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
    fileDeleteRequest: (data: any) => {
      dispatch(FileUploadActions.fileDeleteRequest(data));
    },
  };
};
function BrandMediaUploadDialog(props: any) {
  const navigate = useNavigate();

  console.log("props", props);
  const { setUploadFileState, fileUploadRequest, fileDeleteRequest } =
    actionDispatch(useDispatch());

  const [brandMediaData, setBrandMediaData] = useState<any>(initialValues);
  console.log("brandMediaData", brandMediaData);
  const [mediaUploaderName, setMediaUploaderName] = useState("");

  const { imageDetails, isLoading } = useSelector(
    (state: any) => state.fileUpload
  );

  const [mediaType, setMediaType] = useState("VIDEO");
  const [mediaUploadHelperText, setMediaUploadHelperText] = useState<any>(null);
  const [mediaMobileUploadHelperText, setMediaMobileUploadHelperText] =
    useState<any>(null);

  useEffect(() => {
    if (typeof props?.MediaObject == "object") {
      console.log("inside useEffect");
      setBrandMediaData({
        ...props?.MediaObject,
      });
      setMediaType("VIDEO");
    }
  }, []);

  const handleWebMediaUpload = (event: any) => {
    console.log("handleWebMediaUpload", event);
    setMediaUploaderName("webMedia");
    const file = event.target.files[0];
    console.log("file", file);
    let isUploadError: boolean = false;

    if (mediaType === "IMAGE" || mediaType === "VIDEO") {
      if (mediaType === "IMAGE" && file?.size > 1024 * 1024 * 10) {
        setMediaUploadHelperText("Please upload file size below 10 MB.");
        isUploadError = true;
        return;
      }

      // if (mediaType === "VIDEO" && file?.size > 1024 * 1024 * 30) {
      //   setMediaUploadHelperText("Please upload video size of below 30 MB.");
      //   isUploadError = true;
      //   return;
      // }

      if (mediaType === "IMAGE") {
        switch (file?.type) {
          case "image/svg+xml":
            setMediaUploadHelperText(null);
            isUploadError = false;
            break;
          case "image/jpeg":
            setMediaUploadHelperText(null);
            isUploadError = false;
            break;
          case "image/png":
            setMediaUploadHelperText(null);
            isUploadError = false;
            break;
          case "image/gif":
            setMediaUploadHelperText(null);
            isUploadError = false;
            break;

          default:
            isUploadError = true;
            setMediaUploadHelperText(
              "Unsupported format. Use JPEG, SVG, PNG or GIF image uploads."
            );
            break;
        }
      } else if (mediaType === "VIDEO") {
        switch (file?.type) {
          case "video/mp4":
            isUploadError = false;
            setMediaUploadHelperText(null);
            break;
          case "video/mov":
            isUploadError = false;
            setMediaUploadHelperText(null);
            break;
          case "video/webm":
            isUploadError = false;
            setMediaUploadHelperText(null);
            break;

          default:
            isUploadError = true;
            setMediaUploadHelperText(
              "Unsupported format. Use MP4,MOV or WEBM for Video uploads."
            );

            break;
        }
      }
    }

    if (file && !isUploadError) {
      setBrandMediaData({
        ...brandMediaData,
        webMedia: {
          ...brandMediaData?.webMedia,
          name: file?.name,
          key: file?.name,
          value: {
            ...brandMediaData?.webMedia?.value,
            size: file?.size,
            height: file?.height,
            width: file?.width,
          },
        },
      });

      if (file) {
        const data = {
          name: file?.name,
          file,
          urlType: "upload",
          binary: "",
        };
        const reader = new FileReader();
        reader.onload = (event: any) => {
          data.binary = event.target.result;
          fileUploadRequest(data);
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };
  const handleMobileMediaUpload = (event: any) => {
    console.log("handleMobileMediaUpload", event);
    setMediaUploaderName("mobileMedia");
    const file = event.target.files[0];
    console.log("file", file);
    let isUploadError: boolean = false;

    if (mediaType === "IMAGE" || mediaType === "VIDEO") {
      if (mediaType === "IMAGE" && file?.size > 1024 * 1024 * 10) {
        setMediaMobileUploadHelperText("Please upload file size below 10 MB.");
        isUploadError = true;
        return;
      }

      // if (mediaType === "VIDEO" && file?.size > 1024 * 1024 * 30) {
      //   setMediaMobileUploadHelperText("Please upload video size of below 30 MB.");
      //   isUploadError = true;
      //   return;
      // }

      if (mediaType === "IMAGE") {
        switch (file?.type) {
          case "image/svg+xml":
            setMediaMobileUploadHelperText(null);
            isUploadError = false;
            break;
          case "image/jpeg":
            setMediaMobileUploadHelperText(null);
            isUploadError = false;
            break;
          case "image/png":
            setMediaMobileUploadHelperText(null);
            isUploadError = false;
            break;
          case "image/gif":
            setMediaMobileUploadHelperText(null);
            isUploadError = false;
            break;

          default:
            isUploadError = true;
            setMediaMobileUploadHelperText(
              "Unsupported format. Use JPEG, SVG, PNG or GIF image uploads."
            );
            break;
        }
      } else if (mediaType === "VIDEO") {
        switch (file?.type) {
          case "video/mp4":
            isUploadError = false;
            setMediaMobileUploadHelperText(null);
            break;
          case "video/mov":
            isUploadError = false;
            setMediaMobileUploadHelperText(null);
            break;
          case "video/webm":
            isUploadError = false;
            setMediaMobileUploadHelperText(null);
            break;

          default:
            isUploadError = true;
            setMediaMobileUploadHelperText(
              "Unsupported format. Use MP4,MOV or WEBM for Video uploads."
            );

            break;
        }
      }
    }

    if (file && !isUploadError) {
      setBrandMediaData({
        ...brandMediaData,
        mobileMedia: {
          ...brandMediaData?.mobileMedia,
          name: file?.name,
          key: file?.name,
          value: {
            ...brandMediaData?.mobileMedia?.value,
            size: file?.size,
            height: file?.height,
            width: file?.width,
          },
        },
      });

      if (file) {
        const data = {
          name: file?.name,
          file,
          urlType: "upload",
          binary: "",
        };
        const reader = new FileReader();
        reader.onload = (event: any) => {
          data.binary = event.target.result;
          fileUploadRequest(data);
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  useEffect(() => {
    if (imageDetails) {
      const imageRes: any = imageDetails;
      console.log("imageRes", imageRes);

      setBrandMediaData({
        ...brandMediaData,
        [mediaUploaderName]: {
          ...brandMediaData?.[mediaUploaderName],
          value: {
            ...brandMediaData?.[mediaUploaderName]?.value,
            url: imageRes?.url,
          },
        },
      });
      setUploadFileState("imageDetails", null);
    }
  }, [imageDetails]);

  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  console.log("BrandMediaUploadDialog values", values);

  const handleSubmit = (event: any) => {
    const brandMediaLocalArray: any[] = values?.brandMedia;

    if (brandMediaData?.priority) {
      brandMediaLocalArray[brandMediaData?.priority - 1] = brandMediaData;
    } else {
      brandMediaLocalArray?.push({
        ...brandMediaData,
        priority: values?.brandMedia?.length + 1,
      });
    }

    setFieldValue("brandMedia", brandMediaLocalArray);
    if (!brandMediaLocalArray?.length) {
      setFieldValue("isBrandMediaPresent", false);
    } else if (
      brandMediaLocalArray?.length > 0 &&
      !values?.isBrandMediaPresent
    ) {
      setFieldValue("isBrandMediaPresent", true);
    }

    props.closeMediaUploadDialog();
  };

  console.log("brandMediaData", brandMediaData);

  return (
    <>
      <Box>
        <Dialog
          open={props?.open}
          maxWidth="md"
          PaperProps={{ sx: { padding: "0px 5px 5px 5px", width: "50rem" } }}
        >
          <DialogTitle>
            <Typography sx={{ fontWeight: "550" }}>
              Upload Brand Video Media
            </Typography>
            <IconButton
              onClick={() => {
                props.closeMediaUploadDialog();
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <RadioButtonGroupComponent
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              // value={brandMediaData?.media?.value?.mediaType}
              value={mediaType}
              //   onChange={MediaTypeHandleChange}
            >
              <FormControlLabel
                value="VIDEO"
                control={<StyledRadio />}
                label="Video"
              />
            </RadioButtonGroupComponent>
          </DialogContent>
          <DialogActions
            sx={{ padding: "40px", flexDirection: "column", rowGap: "40px" }}
          >
            <>
              <Grid container columnSpacing={2} sx={{ width: "100%" }}>
                <Grid item xs={6} md={6} sm={12} xl={6}>
                  <Typography>Desktop View</Typography>
                  <CarouselMediaUploader
                    onChange={handleWebMediaUpload}
                    name="webMedia"
                    fieldhelpertext={mediaUploadHelperText}
                    accept={
                      brandMediaData?.webMedia?.value?.mediaType == "IMAGE"
                        ? ".JPG,.PNG,.JPEG,.SVG+XML,.GIF"
                        : ".MP4,.MOV,.WEBM"
                    }
                    loading={
                      mediaUploaderName === "webMedia" ? isLoading : false
                    }
                    componentType={
                      brandMediaData?.webMedia?.value?.mediaType == "IMAGE"
                        ? "img"
                        : "VIDEO"
                    }
                    // componentType="img"
                    previewimage={brandMediaData?.webMedia?.value?.url}
                  />
                  {mediaUploadHelperText && (
                    <>
                      <Stack
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                      >
                        <ErrorOutlineSharpIcon
                          fontSize="small"
                          sx={{
                            // marginTop: "10px",
                            color: "red",
                          }}
                        />
                        <Typography sx={{ color: "red", textAlign: "left" }}>
                          {mediaUploadHelperText}
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Grid>
                <Grid item xs={6} sm={12} md={6} xl={6}>
                  <Typography>Mobile View</Typography>
                  <CarouselMediaUploader
                    onChange={handleMobileMediaUpload}
                    name="mobileMedia"
                    fieldhelpertext={mediaMobileUploadHelperText}
                    accept={
                      brandMediaData?.mobileMedia?.value?.mediaType == "IMAGE"
                        ? ".JPG,.PNG,.JPEG,.SVG+XML,.GIF"
                        : ".MP4,.MOV,.WEBM"
                    }
                    loading={
                      mediaUploaderName === "mobileMedia" ? isLoading : false
                    }
                    componentType={
                      brandMediaData?.mobileMedia?.value?.mediaType == "IMAGE"
                        ? "img"
                        : "VIDEO"
                    }
                    // componentType="img"
                    previewimage={brandMediaData?.mobileMedia?.value?.url}
                  />
                  {mediaMobileUploadHelperText && (
                    <>
                      <Stack
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                      >
                        <ErrorOutlineSharpIcon
                          fontSize="small"
                          sx={{
                            // marginTop: "10px",
                            color: "red",
                          }}
                        />
                        <Typography sx={{ color: "red", textAlign: "left" }}>
                          {mediaMobileUploadHelperText}
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Grid>
              </Grid>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  !(
                    brandMediaData?.webMedia?.value?.url &&
                    brandMediaData?.mobileMedia?.value?.url
                  )
                }
              >
                Upload
              </Button>
            </>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default BrandMediaUploadDialog;
