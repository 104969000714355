import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import ConfirmationDialog from "../../../../components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MediaAssets from "../../../../assets";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NoDataRecord from "../../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Avatar,
  Checkbox,
  ClickAwayListener,
  Stack,
  Typography,
  Popper,
} from "@mui/material";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import {
  AutocompleteComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { InfoOutlined } from "@mui/icons-material";
import styles from "./UpdateList.module.css";
import {
  ActionTypeEnumUpdates,
  ActionTypeEnumUtilsUpdate,
} from "enumerations/ActionTypeEnum";
import UpdateActions from "../../../../redux-container/marketing-management/updates/UpdatesRedux";
import UpdateCreationActions from "../../../../redux-container/marketing-management/updates/UpdateCreationRedux";
import { useDispatch, useSelector } from "react-redux";
import { exportAsExcel } from "utils/ExportFileUtils";
import {
  StatusTypeEnumInsight,
  UpdatesStatusTypeEnum,
  UpdatesStatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import Loader from "components/loader/Loader";
import Retry from "components/retry/Retry";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { UpdateTypeEnum, UpdateTypeValueEnum } from "./UpdateListConstants";
import UserActions from "redux-container/users/UsersRedux";
import { ReadmoreDialogWrapper } from "pages/promise-management/promise-list/PromiseList";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { format } from "date-fns";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";

const actionDispatch = (dispatch: any) => {
  return {
    getUpdates: (params: any) => dispatch(UpdateActions.updateRequest(params)),
    deleteUpdate: (data: any) =>
      dispatch(UpdateActions.deleteUpdateRequest(data)),
    bulkUpdateDelete: (data: any) =>
      dispatch(UpdateActions.deleteBulkUpdateRequest(data)),
    updateStatus: (data: any) =>
      dispatch(UpdateActions.updateStatusRequest(data)),
    updatePriority: (data: any) =>
      dispatch(UpdateActions.updatePriorityRequest(data)),
    setUpdateState: (key: any, data: any) => {
      dispatch(UpdateActions.setUpdateState(key, data));
    },
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const UpdateList = () => {
  const isLoading = useSelector((state: any) => state.update?.isLoading);
  const isPriorityUpdated = useSelector(
    (state: any) => state.update?.isPriorityUpdated
  );
  const error = useSelector((state: any) => state.update?.error);
  const isSuccess: any = useSelector((state: any) => state?.update?.isDeleted);
  const message = useSelector((state: any) => state.update?.message);
  const updateData = useSelector((state: any) => state?.update);
  const updatesListData = useSelector((state: any) => state?.update.updates);
  const totalCount = useSelector((state: any) => state?.update.totalCount);
  const users = useSelector((state: any) => state?.users.usersForFilters);
  const shouldOpenFlashDialog: any = useSelector(
    (state: any) => state?.update?.shouldOpenFlashDialog
  );
  const { page, rowsPerPage, modules } = useSelector((state: any) => {
    return {
      page: state?.update?.page,
      rowsPerPage: state?.update?.rowsPerPage,
      modules: state?.rolesAndModules?.module,
    };
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();

  const actionsData = ActionTypeEnumUtilsUpdate.getActionTypeEnumsUpdates();
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedFormType, setSelectedFormType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedUpdate, setSelectedUpdate] = useState<any>([]);
  const [selectedModifiedUser, setSelectedModifiedUser] = useState("");

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [priority, setPriority] = useState(1);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState<any>(null);
  const [selectedUpdatedBy, setSelectedUpdatedBy] = useState<any>(null);
  const [selectPublishedBy, setSelectPublishedBy] = useState("");
  const [createdBy, setcreatedBy] = useState(1);
  const [isSortByPriority, setSortByPriority] = useState(true);
  const [anchor, setAnchor] = useState(null);
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [sortingPriority, setSortingPriority] = useState(0);
  const [sortingKey, setSortingKey] = useState(1);
  const [isCreatedBySort, setCreatedBySort] = useState(true);
  const [isPublishedBySort, setPublishedBySort] = useState(true);
  const [isModifiedBySort, setModifiedBySort] = useState(true);
  const [isStatusUpdate, setIsStatusUpdate] = useState<any>();

  const [modulesAudited, setModulesAudited] = useState<any>(null);
  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  const statuses = UpdatesStatusTypeEnumUtils.getUpdatesStatusTypeEnums();
  const breadcrumbRoutes: any = [
    {
      name: "Marketing Management",
      route: RouteConstants.marketingManagementDashboard,
    },
    {
      name: "Updates",
      route: RouteConstants.updateList,
    },
  ];

  const {
    getUpdates,
    deleteUpdate,
    setUpdateState,
    updateStatus,
    updatePriority,
    getUsers,
    rolesAndModulesRequest,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());

  //Loading Data (Updates data) while mounting and
  // every time after User applies any filters (Status,Created by,Modified by,Search key,Sorting key,page and number of rows per page) .
  useEffect(() => {
    getFilterDataFromDashboard();
    const params = {
      index: page + 1,
      size: rowsPerPage,
      type: selectedType,
      action: selectedAction,
      status: location?.state?.statusType
        ? location?.state?.statusType
        : selectedStatus,
      updateType: selectedFormType,
      createdBy:
        selectedCreatedBy?.id !== undefined ? selectedCreatedBy?.id : "",
      updatedBy:
        selectedUpdatedBy?.id !== undefined ? selectedUpdatedBy?.id : "",
      publishedBy: selectPublishedBy,
      byPrority: isSortByPriority,
      searchKey: searchKey,
      sortingKey: sortingKey,
      sortingPriority: sortingPriority,
    };
    getUpdates(params);
    setSelectedAction("");
    setSelectedUpdate([]);
    // getUsers({});
  }, [
    dispatch,
    rowsPerPage,
    // selectedAction,
    // isLoading,
    message,

    selectedFormType,
    page,
    selectedCreatedBy,
    selectedUpdatedBy,
    selectPublishedBy,
    // priority,
    createdBy,
    selectedType,
    selectedStatus,
    isSortByPriority,
    searchKey,
    sortingKey,
    sortingPriority,
    isStatusUpdate,
    // isConfirmDeleteDialogOpen,
  ]);

  //fetching updates after changing the Priority of an update.
  useEffect(() => {
    if (isPriorityUpdated) {
      setUpdateState("isPriorityUpdated", false);
      const params = {
        index: page + 1,
        size: rowsPerPage,
        type: selectedType,
        action: selectedAction,
        status: location?.state?.statusType
          ? location?.state?.statusType
          : selectedStatus,
        updateType: selectedFormType,
        createdBy:
          selectedCreatedBy?.id !== undefined ? selectedCreatedBy?.id : "",
        updatedBy:
          selectedUpdatedBy?.id !== undefined ? selectedUpdatedBy?.id : "",
        publishedBy: selectPublishedBy,
        byPrority: isSortByPriority,
        searchKey: searchKey,
        sortingKey: sortingKey,
        sortingPriority: sortingPriority,
      };
      getUpdates(params);
      setSelectedAction("");
      setSelectedUpdate([]);
    }
  }, [isPriorityUpdated]);

  //setting page and rowsPerPage to initial value if user Navigated to the list from Side menu.
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setUpdateState("page", 0);
      setUpdateState("rowsPerPage", 10);
    }

    return () => {};
  }, []);

  //API call to get role,modules.
  useEffect(() => {
    rolesAndModulesRequest();
    return () => {};
  }, []);

  //table headings (heading objects with name,boolean key for whether it is sorting column or not,and sorting key for an API call )
  const tableHeadings = [
    { heading: "Update" },
    { heading: "Type" },
    { heading: "Created On" },
    { heading: "Published On" },
    { heading: "Modified On" },
    { heading: "Last Modified By" },
    { heading: "Priority" },
    { heading: "Status" },
    { heading: "Actions" },
  ];

  const getFilterDataFromDashboard = () => {
    if (location && location.state && location.state.statusType) {
      setSelectedStatus(location.state.statusType as string);
    }
    navigate(location.pathname, { replace: true });
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  //handle change function for page in Pagination.
  const handleChangePage = (event: unknown, newPage: number) => {
    setUpdateState("page", newPage);
  };

  //handle change function for number of elements per page in Pagination.
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUpdateState("rowsPerPage", Number(event.target.value));
    setUpdateState("page", 0);
  };

  const renderLoader = () => {
    return <Loader />;
  };
  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };
  const loadData = (params: any) => {
    getUpdates(params);
  };

  //function to handle Action change.
  const handleActionChange = (event: SelectChangeEvent) => {
    // setSelectedAction(event.target.value as string);
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case ActionTypeEnumUpdates.Export:
        handleExportAsExcel();
        break;
      case ActionTypeEnumUpdates.Delete:
        handleDelete();
        break;
      case ActionTypeEnumUpdates.MarkAsPublished:
        changeUpdateStatus(String(UpdatesStatusTypeEnum.Published));
        break;
      case ActionTypeEnumUpdates.MarkAsInactive:
        changeUpdateStatus(String(UpdatesStatusTypeEnum.Inactive));
        break;
    }
  };

  //function to make an Audit log API call .
  const handleCloseFlashMessageDialog = () => {
    setUpdateState("error", null);
    setUpdateState("message", null);

    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Marketing Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: null,
      auditedObject: null,
      modulesAudited: modulesAudited,
      status: null,
    };
    postAuditLogRequest(postAuditRequestbody);
  };

  //handle change function for status filter.
  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
    if (page !== 0) {
      setUpdateState("page", 0);
    }
  };

  //function to make and API call to change Status.
  const changeUpdateStatus = (status: any) => {
    const data = {
      status: status,
      marketingUpdateIds: selectedUpdate,
    };

    if (selectedUpdate.length === 0) {
      alert("You have not selected any updates");
      return;
    }

    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Update Status`,
        newValue:
          UpdatesStatusTypeEnum.Published === status ? "Published" : "Inactive",
        oldValue:
          UpdatesStatusTypeEnum.Published === status ? "Inactive" : "Published",
        auditedObject: { data: { ...item, status: status }, isEditMode: true },
        route: RouteConstants?.updateCreation,
        status: AuditStatus.EDITED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    // clearSelected();
    updateStatus(data);
    setIsStatusUpdate(data);
  };

  const handleDelete = () => {
    if (selectedUpdate.length === 0) {
      alert("You have not selected any updates");
      return;
    }
    setIsConfirmDeleteDialogOpen(true);
  };

  //function to identify only selected items.
  const statusAuditedItems = () => {
    let auditedItems: any[] = [];
    updatesListData?.map((item: any) => {
      selectedUpdate?.map((itemId: number) => {
        if (itemId === item.id) {
          auditedItems.push(item);
        }
      });
    });
    return auditedItems;
  };

  //function to make an API call to selected delete Updates.
  const deleteSelectedUpdates = () => {
    const updates = {
      marketingUpdateIds: [...selectedUpdate],
    };

    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Update ${item?.displayTitle} Deleted.(ID${item?.id})`,
        newValue: "",
        oldValue: "",
        auditedObject: null,
        route: null,
        status: AuditStatus.DELETED,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    deleteUpdate(updates);
    setIsConfirmDeleteDialogOpen(false);
  };

  //function to Export selected Updates.
  const handleExportAsExcel = () => {
    if (selectedUpdate.length === 0) {
      alert("You have not selected any Update");
      return;
    }
    let exportingUpdates: any = [];
    if (updatesListData.length) {
      selectedUpdate.map((selectedUpdateItem: any) => {
        let localSelectedItem = updatesListData.find(
          (item: any) => item.id == selectedUpdateItem
        );
        exportingUpdates.push({
          Update: localSelectedItem.displayTitle,
          Type:
            localSelectedItem.updateType === UpdateTypeValueEnum.HoABL
              ? UpdateTypeEnum.HoABL
              : localSelectedItem.updateType === UpdateTypeValueEnum.Project
              ? UpdateTypeEnum.Project
              : UpdateTypeEnum.Factual,
          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Published_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Modified_On: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Last_Modified_by: localSelectedItem?.marketingUpdateUpdatedBy
            ?.firstName
            ? localSelectedItem?.marketingUpdateUpdatedBy?.firstName
            : "----",
          Priority: localSelectedItem?.priority,
          Status: localSelectedItem.status
            ? UpdatesStatusTypeEnumUtils.getEnumText(localSelectedItem.status)
            : "----",
        });
      });
    }

    exportAsExcel(exportingUpdates, "Updates", "Sheet1");
  };

  //handle change function for Form type filter.
  const handleFormTypeChange = (event: SelectChangeEvent) => {
    setSelectedFormType(event.target.value as string);
    if (page !== 0) {
      setUpdateState("page", 0);
    }
  };

  const createUpdate = () => {
    navigate(RouteConstants.updateCreation);
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedUpdate.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUpdate, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUpdate.slice(1));
    } else if (selectedIndex === selectedUpdate.length - 1) {
      newSelected = newSelected.concat(selectedUpdate.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUpdate.slice(0, selectedIndex),
        selectedUpdate.slice(selectedIndex + 1)
      );
    }
    setSelectedUpdate(newSelected);
  };

  const isSelected = (id: any) => selectedUpdate.indexOf(id) !== -1;

  const clearSelected = () => {
    setSelectedUpdate([]);
  };

  //handle change function for select all elements.
  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = updatesListData.map((n: any) => n.id);
      setSelectedUpdate(newSelecteds);
      return;
    }
    setSelectedUpdate([]);
  };

  const handleChangePrioritySwitchButton = () => {
    setSortByPriority(!isSortByPriority);
  };

  const editUpdate = (data: any) => {
    navigate(RouteConstants.updateCreation, {
      state: { data: data, isEditMode: true },
    });
  };

  const viewUpdate = (data: any) => {
    navigate(RouteConstants.updateCreation, {
      state: { data: data, isDisabled: true },
    });
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  //function to show Description after clicking on READ MORE.
  const popOverComponent = (event: any, description: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
    setDescription(description);
  };

  //Reset all the filters .
  const clearFilters = () => {
    setSelectedStatus("");
    setSelectedCreatedBy(null);
    setSelectedFormType("");
    setSelectPublishedBy("");
    setSelectedUpdatedBy(null);
  };

  //handle change function for Search key change.
  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 0) {
      setUpdateState("page", 0);
    }
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_newspaper}
        altText=""
        message="You haven't created any Updates yet. Create your first Update."
        btnTitle="CREATE UPDATES"
        btnAction={createUpdate}
      />
    );
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Updates List
            {`(${
              totalCount
                ? totalCount
                : updatesListData?.length
                ? updatesListData?.length
                : 0
            })`}
            {selectedUpdate?.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedUpdate.length} update(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Action"
                onChange={handleActionChange}
              >
                {actionsData.map((action: any) => {
                  return (
                    <MenuItem value={action.value}>{action.label}</MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>

            <Button
              variant="contained"
              onClick={createUpdate}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New
            </Button>
          </div>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={1} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedStatus}
                    label="Status"
                    onChange={handleStatusChange}
                  >
                    {statuses.map((status: any) => {
                      return (
                        <MenuItem value={status.value} key={status.value}>
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedFormType}
                    label="Type"
                    onChange={handleFormTypeChange}
                  >
                    <MenuItem value={UpdateTypeValueEnum.HoABL}>HoABL</MenuItem>
                    <MenuItem value={UpdateTypeValueEnum.Project}>
                      Project
                    </MenuItem>
                    <MenuItem value={UpdateTypeValueEnum.Factual}>
                      Factual
                    </MenuItem>
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    label="Created By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedCreatedBy(newValue);
                      if (page !== 0) {
                        setUpdateState("page", 0);
                      }
                    }}
                    value={selectedCreatedBy}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    label="Modified By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedUpdatedBy(newValue);
                      if (page !== 0) {
                        setUpdateState("page", 0);
                      }
                    }}
                    value={selectedUpdatedBy}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              {/* <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectPublishedBy}
                    label="Published By"
                    onChange={handlePublishedByChange}
                  >
                    {users.map((user: any) => {
                      return (
                        <MenuItem value={user.id} key={user.value}>
                          {user.firstName}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid> */}
              {selectedStatus !== "" ||
              selectedUpdatedBy !== null ||
              selectedCreatedBy !== null ||
              selectPublishedBy != "" ||
              selectedFormType != "" ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <div className="time-sync-holder margin-right-15">
            <p className={styles["priority-switch-btn-alignment"]}>
              Sort by Priority
            </p>
            <SwitchComponent
              checked={isSortByPriority}
              onChange={handleChangePrioritySwitchButton}
            />
            <Tooltip
              arrow
              placement="top-start"
              title="Disabling the toggle will switch to show items on the Customer App based on Date & Time."
            >
              <InfoOutlined className={styles["infooutlined-alignment"]} />
            </Tooltip>
          </div>
        </Stack>
        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell
                  style={{ minWidth: 50 }}
                  className="table-header-text"
                  padding="checkbox"
                >
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedUpdate?.length > 0 &&
                      selectedUpdate?.length < updatesListData?.length
                    }
                    checked={
                      updatesListData?.length > 0 &&
                      selectedUpdate?.length === updatesListData?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all promotions and offers",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}

                    {/* {index === 2 && (
                      <IconButton
                        onClick={() => {
                          setCreatedBySort(!isCreatedBySort);
                          if (sortingPriority === 0) {
                            setSortingKey(0);
                            setSortingPriority(1);
                            setSortByPriority(false);
                          } else {
                            setSortingPriority(0);
                            setSortingKey(0);
                            setSortByPriority(false);
                          }
                        }}
                      >
                        {isCreatedBySort ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropUpIcon />
                        )}
                      </IconButton>
                    )} */}
                    {/* {index === 3 && (
                      <IconButton
                        onClick={() => {
                          setPublishedBySort(!isPublishedBySort);
                          if (sortingPriority === 0) {
                            setSortingKey(0);
                            setSortingPriority(1);
                            setSortByPriority(false);
                          } else {
                            setSortingPriority(0);
                            setSortingKey(0);
                            setSortByPriority(false);
                          }
                        }}
                      >
                        {isPublishedBySort ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropUpIcon />
                        )}
                      </IconButton>
                    )}
                    {index === 4 && (
                      <IconButton
                        onClick={() => {
                          setModifiedBySort(!isModifiedBySort);
                          if (sortingPriority === 0) {
                            setSortingKey(1);
                            setSortingPriority(1);
                            setSortByPriority(false);
                          } else {
                            setSortingPriority(0);
                            setSortingKey(1);
                            setSortByPriority(false);
                          }
                        }}
                      >
                        {isModifiedBySort ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropUpIcon />
                        )}
                      </IconButton>
                    )} */}
                    {index == 6 && (
                      <IconButton>
                        <Tooltip
                          title={
                            "The items will be shown in ascending order of the priority number on the Customer App."
                          }
                          placement="top-start"
                        >
                          <Avatar
                            sx={{
                              width: "0.9rem",
                              height: "0.9rem",
                              cursor: "pointer",
                              marginTop: "0.10rem",
                              marginBottom: "0.10rem",
                            }}
                            src={MediaAssets.ic_info}
                            variant="rounded"
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {updatesListData && updatesListData.length > 0 ? (
              <TableBody>
                {updatesListData?.map((update: any) => {
                  const isItemSelected = isSelected(update.id);
                  return (
                    <TableRow
                      className="table-data-row"
                      hover
                      role="checkbox"
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" style={{ minWidth: 50 }}>
                        <Checkbox
                          onClick={(event) => handleClick(event, update.id)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={update.id}
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 200 }}
                      >
                        <Stack justifyContent="flex-start">
                          {" "}
                          {update.detailedInfo[0].media?.value &&
                          update.detailedInfo[0].media.value ? (
                            <Avatar
                              className={styles["update-image-size-fix"]}
                              src={update.detailedInfo[0].media?.value?.url}
                            ></Avatar>
                          ) : (
                            ""
                          )}
                          {update?.displayTitle.length > 18 ? (
                            <Stack
                              justifyContent="flex-start"
                              sx={{ width: "100%" }}
                            >
                              <span>{`${update?.displayTitle.slice(
                                0,
                                18
                              )}...`}</span>
                              <Typography
                                component="span"
                                variant="body1"
                                color="#2d2d2d"
                                sx={{
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  wordBreak: "keep-all",
                                  textAlign: "start",
                                  width: "130px",
                                }}
                                onClick={(event: any) =>
                                  popOverComponent(event, update?.displayTitle)
                                }
                              >
                                READ MORE
                              </Typography>
                            </Stack>
                          ) : (
                            update?.displayTitle
                          )}
                        </Stack>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {update.updateType === UpdateTypeValueEnum.HoABL
                          ? UpdateTypeEnum.HoABL
                          : update.updateType === UpdateTypeValueEnum.Project
                          ? UpdateTypeEnum.Project
                          : UpdateTypeEnum.Factual}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>
                          {update.createdAt
                            ? format(new Date(update.createdAt), "dd/MM/yyyy")
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>
                          {update.createdAt
                            ? format(new Date(update.createdAt), "dd/MM/yyyy")
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        <div>
                          {update.updatedAt
                            ? format(new Date(update?.updatedAt), "dd/MM/yyyy")
                            : "-"}
                        </div>
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 170 }}
                      >
                        {update?.marketingUpdateUpdatedBy?.firstName
                          ? update?.marketingUpdateUpdatedBy?.firstName
                          : "----"}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        <PrioritySelector
                          incrementpriority={() => {
                            const priorityRequestBody = {
                              id: update?.id,
                              priority: update?.priority + 1,
                            };
                            updatePriority(priorityRequestBody);
                          }}
                          decrementpriority={() => {
                            const priorityRequestBody = {
                              id: update?.id,
                              priority: update?.priority - 1,
                            };
                            updatePriority(priorityRequestBody);
                          }}
                          priority={update?.priority}
                          disableDecrement={
                            update?.priority == 1 ? true : false
                          }
                          isPriorityRequired={
                            !isSortByPriority ||
                            update?.status == StatusTypeEnumInsight.Inactive
                          }
                        />
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {update.status
                          ? UpdatesStatusTypeEnumUtils.getEnumText(
                              update.status
                            )
                          : "-"}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editUpdate(update)}
                          >
                            <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() => viewUpdate(update)}
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Update Records</div>
            )}
          </Table>
        </TableContainer>

        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleClickAway}
        >
          <Popper open={open} placement="bottom-start" anchorEl={anchor}>
            <ReadmoreDialogWrapper>
              <Paper elevation={3} sx={{ p: "0.5rem", width: "350px" }}>
                {description}
              </Paper>
            </ReadmoreDialogWrapper>
          </Popper>
        </ClickAwayListener>
        {!searchKey && updatesListData && updatesListData.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={totalCount ? totalCount : updatesListData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };
  const isFilterApplied = () => {
    if (
      selectedStatus === "" &&
      selectedUpdatedBy === null &&
      selectedCreatedBy === null &&
      selectPublishedBy === "" &&
      selectedFormType === ""
    ) {
      return false;
    }
    return true;
  };
  const render = () => {
    if (
      (updatesListData?.length > 0 || isFilterApplied() || searchKey !== "") &&
      isLoading === false
    ) {
      return renderTable();
    } else if (
      (updatesListData?.length === 0 ||
        updatesListData?.length === undefined) &&
      !isFilterApplied() &&
      searchKey === "" &&
      isLoading === false
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };
  const shouldProvidePlaceholder = () => {
    if (
      (updatesListData?.length === 0 ||
        updatesListData?.length === undefined) &&
      !isFilterApplied() &&
      searchKey === "" &&
      isLoading === false
    ) {
      return false;
    }
    return true;
  };
  return (
    <Fragment>
      <PageHeading title="Marketing Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder={shouldProvidePlaceholder() ? "Search Update" : ""}
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Update?"
        content={`Are you sure that you want to delete the ${selectedUpdate.length} selected update?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteSelectedUpdates}
      />

      {/* <FlashMessageDialog
        shouldOpen={shouldOpenFlashDialog}
        content={isSuccess ? message : error}
        isSuccess={isSuccess}
        cancelHandler={handleCloseFlashMessageDialog}
      /> */}

      {message ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : "Something Went Wrong!"}
          isSuccess={true}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}

      {error ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
    </Fragment>
  );
};

export default UpdateList;
