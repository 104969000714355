import { RouteConstants } from "routes/RouteConstants";
import { DescriptionTypeEnum, TypeOfUpdate } from "../UpdateCreationConstants";

export const breadcrumbRoutes: any = (
  displayTitle: string,
  locationStateId: any,
  isEditMode: boolean,
  isDisabled: boolean
) => [
  {
    name: "Marketing Management",
    route: RouteConstants.marketingManagementDashboard,
  },
  {
    name: "Updates",
    route: RouteConstants.updateList,
  },
  locationStateId && isEditMode
    ? {
        name: `Edit - ${displayTitle}`,
        route: RouteConstants.updateCreation,
      }
    : isDisabled
    ? {
        name: `View - ${displayTitle}`,
        route: RouteConstants.updateCreation,
      }
    : {
        name: `Create New`,
        route: RouteConstants.updateCreation,
      },
];

export const descriptionSelection = [
  {
    id: 1,
    label: "Brief Description",
    value: DescriptionTypeEnum.BriefDescription,
  },
  {
    id: 2,
    label: "Detailed Description",
    value: DescriptionTypeEnum.DetailedDescription,
  },
];

export const getUpdateTypeLabels = (value: string) => {
  switch (value) {
    case TypeOfUpdate.hoablType:
      return "HoABL";
    case TypeOfUpdate.projectType:
      return "Project";
    case TypeOfUpdate.factualType:
      return "Factual";
    default:
      break;
  }
};
