import {
  ButtonComponent,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import React, { ChangeEvent, Fragment, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import { FieldArray, Formik, getIn, useFormikContext } from "formik";
import { minAndSecHourSet, shouldNumberOnly } from "utils/ValidationUtils";
import LocationInfrastrutureImageMedia from "./LocationInfrastrutureImageMedia";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSelector } from "react-redux";
import styles from "./LocationInfrastructure.module.css";

const LocationInfrastruture = () => {
  const [currentlyOpenedIndex, setCurrentlyOpenedIndex] = useState(999);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<IInitinalValueProjectContent>();
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );
  const handleLocationInfrastrutureImageMedia = (imageIndex: number) => {
    setCurrentlyOpenedIndex(imageIndex);
  };

  const handleOnGlobalSave = (values: any) => {};

  const handleTooleTipForTime = () => (
    <p>
      This field will indicate the time required to <br />
      cover the distance between the selected <br />
      Location Infrastructure & the Project.
    </p>
  );

  return (
    <div>
      <Fragment>
        <Grid container p={3}>
          <Grid style={{marginBottom: '20px'}} item xs={12}>
            <Stack justifyContent={"flex-start"} paddingBottom={2} spacing={2}>
              <Typography>Location Infrastructure</Typography>

              <SwitchComponent
                name="isLocationInfrastructureActive"
                checked={values?.isLocationInfrastructureActive}
                onChange={handleChange}
                disabled={isViewMode}
              />

              <IconButton>
                <Tooltip
                  title={
                    "Toggle is to show/hide this section on the Customer App"
                  }
                  placement="top"
                >
                  <Avatar
                    className={styles["info-icon"]}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>

              {/* Location Infrastructure toggle button for web */}
              <Typography>Location Infrastructure Web</Typography>
              <SwitchComponent
                name="isLocationInfrastructureWebActive"
                checked={values?.isLocationInfrastructureWebActive}
                onChange={handleChange}
                disabled={isViewMode}
              />

              <IconButton>
                <Tooltip
                  title={
                    "Toggle is to show/hide this section on the Website"
                  }
                  placement="top"
                >
                  <Avatar
                    className={styles["info-icon"]}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <TextFieldComponent
              required
              type="text"
              label="Section Heading"
              placeholder="Enter"
              name="locationInfrastructure.heading"
              value={values?.locationInfrastructure?.heading}
              onChange={handleChange}
              inputProps={{ maxLength: 40 }}
              onBlur={handleBlur}
              fieldhelpertext={
                getIn(touched, `locationInfrastructure.heading`) &&
                getIn(errors, `locationInfrastructure.heading`)
              }
              error={Boolean(
                getIn(errors, `locationInfrastructure.heading`) &&
                  getIn(touched, `locationInfrastructure.heading`)
              )}
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
        <Divider />

        <FieldArray
          name="locationInfrastructure.values"
          render={(arrayHelpers) => (
            <div>
              {values?.locationInfrastructure?.values?.length > 0 &&
                values?.locationInfrastructure?.values?.map(
                  (element, index: any) => (
                    <Fragment>
                      <Stack justifyContent={"flex-end"}>
                        {values?.locationInfrastructure?.values?.length > 1 && (
                          <ButtonComponent
                            sx={{
                              textDecoration: "underline",
                              marginTop: "0.5rem",
                            }}
                            variant="text"
                            color="inherit"
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                            disabled={isViewMode}
                          >
                            <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                          </ButtonComponent>
                        )}
                      </Stack>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        p={3}
                        spacing={3}
                        mt={0.1}
                      >
                        <Grid item xs={6}>
                          <Box>
                            <Grid
                              container
                              spacing={1}
                              align-items={"baseline"}
                            >
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                spacing={2}
                                mx={1}
                              >
                                <TextFieldComponent
                                  required
                                  type="text"
                                  label="Infrastructure Name"
                                  placeholder="Enter"
                                  name={`locationInfrastructure.values.${index}.name`}
                                  value={
                                    values?.locationInfrastructure?.values[
                                      index
                                    ]?.name
                                  }
                                  onChange={handleChange}
                                  inputProps={{
                                    maxLength: 15,
                                  }}
                                  onBlur={handleBlur}
                                  fieldhelpertext={
                                    getIn(
                                      touched,
                                      `locationInfrastructure.values.${index}.name`
                                    ) &&
                                    getIn(
                                      errors,
                                      `locationInfrastructure.values.${index}.name`
                                    )
                                  }
                                  error={Boolean(
                                    getIn(
                                      errors,
                                      `locationInfrastructure.values.${index}.name`
                                    ) &&
                                      getIn(
                                        touched,
                                        `locationInfrastructure.values.${index}.name`
                                      )
                                  )}
                                  disabled={isViewMode}
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={5} xl={4}>
                                <Typography mt={"-25px"}>
                                  Time (HH:MM) *
                                </Typography>
                                <Stack spacing={2}>
                                  <Box minWidth={"30px"}>
                                    <TextFieldComponent
                                      required
                                      type="text"
                                      name={`locationInfrastructure.values.${index}.hours`}
                                      onBlur={handleBlur}
                                      value={
                                        values?.locationInfrastructure?.values[
                                          index
                                        ].hours
                                      }
                                      onChange={(event) => {
                                        if (
                                          shouldNumberOnly(event.target.value)
                                        ) {
                                          if (
                                            minAndSecHourSet(event.target.value)
                                          ) {
                                            setFieldValue(
                                              `${event.target.name}`,
                                              event.target.value
                                            );
                                          }
                                        }
                                      }}
                                      placeholder="HH"
                                      inputProps={{
                                        maxLength: 2,
                                      }}
                                      fieldhelpertext={
                                        getIn(
                                          touched,
                                          `locationInfrastructure.values.${index}.hours`
                                        ) &&
                                        getIn(
                                          errors,
                                          `locationInfrastructure.values.${index}.hours`
                                        )
                                      }
                                      error={Boolean(
                                        getIn(
                                          errors,
                                          `locationInfrastructure.values.${index}.hours`
                                        ) &&
                                          getIn(
                                            touched,
                                            `locationInfrastructure.values.${index}.hours`
                                          )
                                      )}
                                      disabled={isViewMode}
                                    />
                                  </Box>
                                  <div style={{ marginTop: "-10px" }}>: </div>
                                  <div style={{ marginTop: "-17.5px" }}>
                                    <TextFieldComponent
                                      required
                                      type="text"
                                      placeholder="MM"
                                      name={`locationInfrastructure.values[${index}].minutes`}
                                      onBlur={handleBlur}
                                      value={
                                        values?.locationInfrastructure?.values[
                                          index
                                        ].minutes
                                      }
                                      onChange={(event) => {
                                        if (
                                          shouldNumberOnly(event.target.value)
                                        ) {
                                          if (
                                            minAndSecHourSet(event.target.value)
                                          ) {
                                            setFieldValue(
                                              `${event.target.name}`,
                                              event.target.value
                                            );
                                          }
                                        }
                                      }}
                                      inputProps={{
                                        maxLength: 2,
                                      }}
                                      helpericon={MediaAssets.ic_info}
                                      placement="top"
                                      helpericoncontent={handleTooleTipForTime()}
                                      fieldhelpertext={
                                        getIn(
                                          touched,
                                          `locationInfrastructure.values[${index}].minutes`
                                        ) &&
                                        getIn(
                                          errors,
                                          `locationInfrastructure.values[${index}].minutes`
                                        )
                                      }
                                      error={Boolean(
                                        getIn(
                                          errors,
                                          `locationInfrastructure.values[${index}].minutes`
                                        ) &&
                                          getIn(
                                            touched,
                                            `locationInfrastructure.values[${index}].minutes`
                                          )
                                      )}
                                      disabled={isViewMode}
                                    />
                                  </div>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Box>
                          <Grid
                            container
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Grid
                              mt={5.8}
                              xs={5.8}
                              sm={5.8}
                              md={5.8}
                              lg={5.8}
                              xl={5.8}
                            >
                              <TextFieldComponent
                                required
                                type="text"
                                label="longitude"
                                placeholder="Enter"
                                name={`locationInfrastructure.values.${index}.longitude`}
                                value={
                                  values?.locationInfrastructure?.values[index]
                                    ?.longitude
                                }
                                onChange={(event) => {
                                  if (shouldNumberOnly(event.target.value)) {
                                    setFieldValue(
                                      `${event.target.name}`,
                                      event.target.value
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                                helpericon={MediaAssets.ic_info}
                                placement="top"
                                helpericoncontent={`longitude`}
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `locationInfrastructure.values.${index}.longitude`
                                  ) &&
                                  getIn(
                                    errors,
                                    `locationInfrastructure.values.${index}.longitude`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    errors,
                                    `locationInfrastructure.values.${index}.longitude`
                                  ) &&
                                    getIn(
                                      touched,
                                      `locationInfrastructure.values.${index}.longitude`
                                    )
                                )}
                                disabled={isViewMode}
                              />
                            </Grid>
                            <Grid
                              mt={5.8}
                              xs={5.8}
                              sm={5.8}
                              md={5.8}
                              lg={5.8}
                              xl={5.8}
                            >
                              <TextFieldComponent
                                required
                                type="text"
                                label="latitude "
                                placeholder="Enter"
                                name={`locationInfrastructure.values.${index}.latitude`}
                                value={
                                  values?.locationInfrastructure?.values[index]
                                    ?.latitude
                                }
                                onChange={(event) => {
                                  if (shouldNumberOnly(event.target.value)) {
                                    setFieldValue(
                                      `${event.target.name}`,
                                      event.target.value
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                                helpericon={MediaAssets.ic_info}
                                placement="top"
                                helpericoncontent={`latitude`}
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `locationInfrastructure.values.${index}.latitude`
                                  ) &&
                                  getIn(
                                    errors,
                                    `locationInfrastructure.values.${index}.latitude`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    errors,
                                    `locationInfrastructure.values.${index}.latitude`
                                  ) &&
                                    getIn(
                                      touched,
                                      `locationInfrastructure.values.${index}.latitude`
                                    )
                                )}
                                disabled={isViewMode}
                              />
                            </Grid>
                          </Grid>

                          {values?.locationInfrastructure?.values?.length -
                            1 ===
                            index && (
                            <ButtonComponent
                              sx={{
                                textDecoration: "underline",
                                marginTop: "0.5rem",
                              }}
                              variant="text"
                              color="inherit"
                              onClick={() => {
                                arrayHelpers.push({
                                  name: "",
                                  hours: "",
                                  minutes: "",
                                  latitude: null,
                                  longitude: null,
                                  icon: {
                                    name: "",
                                    key: "",
                                    value: {
                                      url: "",
                                      size: 0,
                                      width: 1024,
                                      height: 1024,
                                      mediaType: "image",
                                    },
                                  },
                                });
                              }}
                              disabled={isViewMode}
                            >
                              + Add more Values
                            </ButtonComponent>
                          )}
                        </Grid>
                        {/* <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={2}
                          xl={2}
                          spacing={2}
                        >
                          {values?.locationInfrastructure?.values?.length >
                            1 && (
                            <ButtonComponent
                              sx={{
                                textDecoration: "underline",
                                marginTop: "0.5rem",
                              }}
                              variant="text"
                              color="inherit"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            >
                              <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                            </ButtonComponent>
                          )}
                        </Grid> */}
                        <Grid item xs={3} sm={3} md={3} lg={4} xl={3.5}>
                          <LocationInfrastrutureImageMedia
                            index={index}
                            currentlyOpenedIndex={currentlyOpenedIndex}
                            handleLocationInfrastrutureImageMedia={
                              handleLocationInfrastrutureImageMedia
                            }
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                    </Fragment>
                  )
                )}
              {/* <Grid container padding={2} justify-content={"flex-end"}>
                <Grid item xs={12} textAlign="right">
                  <LoadingButtonComponent
                    type="submit"
                    // disabled={
                    //   values.offersAndPromotion.length === 0 ||
                    //   (!values.offersAndPromotionMedia && true)
                    // }
                  >
                    Save
                  </LoadingButtonComponent>
                </Grid>
              </Grid> */}
            </div>
          )}
        />
      </Fragment>
    </div>
  );
};

export default LocationInfrastruture;
