import CustomAutoComplete from "components/ui-elements/custom-autocomplete/CustomAutoComplete";
import { useFormikContext } from "formik";
import {
  IProjectFormInitialValues,
  ProjectFormInitialValues,
} from "../ProjectFormInitialValues";
import { TextFieldComponent } from "components/ui-elements";
import { Box, Grid, Typography } from "@mui/material";
import styles from "./EssentialDetailsForm.module.css";
import FieldHeadingToggle from "./FieldHeadingToggle";
import { useLocation } from "react-router-dom";

const EssentialDetailsForm = () => {
  const { values, handleChange } =
    useFormikContext<IProjectFormInitialValues>();

  const location: any = useLocation();

  return (
    <div>
      <Box p={5}>
        <Grid container spacing={3}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography className={styles["typography-heading"]}>
              Address Line 1
            </Typography>
            <TextFieldComponent
              isPaymentDisabledField={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm?.addressLineOne
              }
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"Address Line 2"}
              toggleName={"essentialDetailsForm.isAddressLineTwoActive"}
              handleToggleChange={handleChange}
              value={values?.essentialDetailsForm?.isAddressLineTwoActive}
            />
            <TextFieldComponent
              isPaymentDisabledField={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm?.addressLineTwo
              }
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography className={styles["typography-heading"]}>
              Contact Person 1 Name
            </Typography>
            <TextFieldComponent
              isPaymentDisabledField={true}
              value={`Contact Person 1 Name (Input field) *`}
            />
          </Grid>
        </Grid>

        <Grid mt={2} container spacing={3} rowGap={5}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography className={styles["typography-heading"]}>
              Country
            </Typography>
            <TextFieldComponent
              isPaymentDisabledField={true}
              value={"Country (Select Field) *"}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography className={styles["typography-heading"]}>
              State
            </Typography>
            <TextFieldComponent
              isPaymentDisabledField={true}
              value={"State (Select Field) *"}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography className={styles["typography-heading"]}>
              City
            </Typography>
            <TextFieldComponent
              isPaymentDisabledField={true}
              value={"City (Input field) *"}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography className={styles["typography-heading"]}>
              Pincode
            </Typography>
            <TextFieldComponent
              isPaymentDisabledField={true}
              value={ProjectFormInitialValues?.essentialDetailsForm?.pincode}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography className={styles["typography-heading"]}>
              Contact Person 1 Number
            </Typography>
            <TextFieldComponent
              isPaymentDisabledField={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm
                  ?.contactPersonOneNumber
              }
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography className={styles["typography-heading"]}>
              Occupation
            </Typography>

            <CustomAutoComplete
              disabled={location?.state?.isViewMode ? true : false}
              validationPattern={2}
              label={"Occupation"}
              required={true}
              name={`essentialDetailsForm.occupation`}
              fieldValue={values?.essentialDetailsForm?.occupation}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"Designation"}
              toggleName={"essentialDetailsForm.isDesignationActive"}
              handleToggleChange={handleChange}
              value={values?.essentialDetailsForm?.isDesignationActive}
            />
            <TextFieldComponent
              isPaymentDisabledField={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm?.designation
              }
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"Applicant Employer / Company Name"}
              toggleName={
                "essentialDetailsForm.isApplicantEmployerOrCompanyActive"
              }
              handleToggleChange={handleChange}
              value={
                values?.essentialDetailsForm?.isApplicantEmployerOrCompanyActive
              }
            />
            <TextFieldComponent
              isPaymentDisabledField={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm
                  ?.applicantEmployerOrCompany
              }
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography className={styles["typography-heading"]}>
              Marital Status
            </Typography>

            <CustomAutoComplete
              validationPattern={2}
              disabled={location?.state?.isViewMode ? true : false}
              label={"Marital Status"}
              name={"essentialDetailsForm.maritalStatuses"}
              required={true}
              fieldValue={values?.essentialDetailsForm?.maritalStatuses}
            />
          </Grid>
        </Grid>

        <Grid mt={2} container spacing={8}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography className={styles["typography-heading"]}>
              Source Of Funding
            </Typography>

            <CustomAutoComplete
              validationPattern={2}
              disabled={location?.state?.isViewMode ? true : false}
              name={"essentialDetailsForm.sourceOfFunding"}
              label={"Source of Funding"}
              required={true}
              fieldValue={values?.essentialDetailsForm?.sourceOfFunding}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography className={styles["typography-heading"]}>
              Purpose of Purchase
            </Typography>

            <CustomAutoComplete
              validationPattern={2}
              disabled={location?.state?.isViewMode ? true : false}
              name={"essentialDetailsForm.puposeOfPurchase"}
              label={"Purpose of Purchase"}
              required={true}
              fieldValue={values?.essentialDetailsForm?.puposeOfPurchase}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"PAN Card Number"}
              toggleName={"essentialDetailsForm.isPanCardNumberActive"}
              handleToggleChange={handleChange}
              value={values?.essentialDetailsForm?.isPanCardNumberActive}
            />

            <TextFieldComponent
              isPaymentDisabledField={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm?.panCardNumber
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"PAN Upload"}
              toggleName={"essentialDetailsForm.isPanCardUploadActive"}
              handleToggleChange={handleChange}
              value={values?.essentialDetailsForm?.isPanCardUploadActive}
            />

            <TextFieldComponent
              isPaymentDisabledField={true}
              showUploadIcon={true}
              value={ProjectFormInitialValues?.essentialDetailsForm?.panUpload}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"Company/HUF PAN Card"}
              toggleName={"essentialDetailsForm.isCompanyOrHUFPanNumberActive"}
              handleToggleChange={handleChange}
              value={
                values?.essentialDetailsForm?.isCompanyOrHUFPanNumberActive
              }
            />

            <TextFieldComponent
              isPaymentDisabledField={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm
                  ?.companyOrHUFPanNumber
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"Company/HUF PAN Card Upload"}
              toggleName={"essentialDetailsForm.isCompanyOrHUFPanUploadActive"}
              handleToggleChange={handleChange}
              value={
                values?.essentialDetailsForm?.isCompanyOrHUFPanUploadActive
              }
            />

            <TextFieldComponent
              isPaymentDisabledField={true}
              showUploadIcon={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm
                  ?.companyOrHUFPanUpload
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"Passport Number"}
              toggleName={"essentialDetailsForm.isPassportNumberActive"}
              handleToggleChange={handleChange}
              value={values?.essentialDetailsForm?.isPassportNumberActive}
            />

            <TextFieldComponent
              isPaymentDisabledField={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm?.passportNumber
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"Passport Upload"}
              toggleName={"essentialDetailsForm.isPassportUploadActive"}
              handleToggleChange={handleChange}
              value={values?.essentialDetailsForm?.isPassportUploadActive}
            />

            <TextFieldComponent
              isPaymentDisabledField={true}
              showUploadIcon={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm?.passportUpload
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"Aadhar Card Number"}
              toggleName={"essentialDetailsForm.isAadharCardNumberActive"}
              handleToggleChange={handleChange}
              value={values?.essentialDetailsForm?.isAadharCardNumberActive}
            />

            <TextFieldComponent
              isPaymentDisabledField={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm?.adhaarCardNumber
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FieldHeadingToggle
              disableToggle={location?.state?.isViewMode}
              fieldName={"Aadhar Upload"}
              toggleName={"essentialDetailsForm.isAadharCardUploadActive"}
              handleToggleChange={handleChange}
              value={values?.essentialDetailsForm?.isAadharCardUploadActive}
            />

            <TextFieldComponent
              isPaymentDisabledField={true}
              showUploadIcon={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm
                  ?.adhaarCardNumberUpload
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography className={styles["typography-heading"]}>
              HUF Letter
            </Typography>

            <TextFieldComponent
              isPaymentDisabledField={true}
              showUploadIcon={true}
              value={ProjectFormInitialValues?.essentialDetailsForm?.hufLetter}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography mt={"-28px"} className={styles["typography-heading"]}>
              Authorization letter (by Company Secretary that mentions you as
              the Authorised Signatory)
            </Typography>

            <TextFieldComponent
              isPaymentDisabledField={true}
              showUploadIcon={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm
                  ?.authorizationLetter
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography className={styles["typography-heading"]}>
              Board Resolution that mentions you as the Authorised Signatory.
            </Typography>

            <TextFieldComponent
              isPaymentDisabledField={true}
              showUploadIcon={true}
              value={
                ProjectFormInitialValues?.essentialDetailsForm
                  ?.boardResolutionLetter
              }
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default EssentialDetailsForm;
