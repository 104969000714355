// import { useState } from "react";
// import {
//   FormFieldContainer,
//   LoadingButtonComponent,
//   SwitchComponent,
//   TextFieldComponent,
//   ButtonComponent,
// } from "components/ui-elements";
// import {
//   Avatar,
//   Box,
//   Divider,
//   Grid,
//   InputAdornment,
//   Stack,
//   styled,
//   Typography,
// } from "@mui/material";
// import MediaAssets from "assets";
// import PromiseCardListings from "./PromiseCardListings";
// import styles from "./Promise.module.css";
// import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
// import { useFormikContext } from "formik";
// import { getIn } from "formik";

// const Promises = (props: any) => {
//   const { values, handleChange, touched, errors, handleBlur, setFieldValue } = useFormikContext<any>()

//   const PromisesContainer = styled(FormFieldContainer)`
//   padding: 0;
// `;

//   return (
//     <PromisesContainer>
//       <Grid container p={"1rem"}>
//         <Grid item xs={6}>
//           <Stack columnGap={2} justifyContent={"flex-start"}>
//             <Typography
//               variant="h5"
//               color={"text.secondary"}
//               margin={"0.5rem 0"}
//             >
//               Promises
//             </Typography>
//             <SwitchComponent checked={values?.isPromisesActive} name="isPromisesActive" onChange={handleChange} />
//           </Stack>
//         </Grid>
//         <Grid item xs={6} textAlign={"right"}>
//           {/* <LoadingButtonComponent type="submit">Save</LoadingButtonComponent> */}
//         </Grid>
//       </Grid>
//       <Divider />
//       <Grid container columnSpacing={4} rowSpacing={2} p={"1rem"}>
//         <Grid item xs={5}>
//           <TextFieldComponent
//             inputProps={{ maxLength: 100 }}
//             label="Section Heading"
//             name={"promisesHeading"}
//             value={values?.promisesHeading}
//             onChange={(e) => {
//               handleChange(e)
//             }}
//             // onBlur={handleBlur}
//             fieldhelpertext={getIn(touched, "promisesHeading") && getIn(errors, "promisesHeading")}
//             error={Boolean(getIn(errors, "promisesHeading") && getIn(touched, "promisesHeading"))}
//           />
//         </Grid>
//       </Grid>
//       <Divider />
//       <Stack justifyContent="flex-start" spacing={2} p={"1rem"}>
//         <Typography mb={"15px"}> Total Promises  on the home screen?</Typography>
//         <PrioritySelector
//           priority={values?.totalPromisesOnHomeScreen}
//           decrementpriority={() => { values?.totalPromisesOnHomeScreen > 1 ? setFieldValue("totalPromisesOnHomeScreen", values?.totalPromisesOnHomeScreen - 1) : alert("no less then 0 ") }}
//           incrementpriority={() => { setFieldValue("totalPromisesOnHomeScreen", values?.totalPromisesOnHomeScreen + 1) }}
//         />
//       </Stack>
//       <Divider />
//     </PromisesContainer>
//   );
// };

// export default Promises;

import {
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
  ButtonComponent,
} from "components/ui-elements";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { useFormikContext } from "formik";
import { getIn } from "formik";
import { ChangeEvent, useEffect } from "react";
import { useLocation } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";

const LastestUpdateContainer = styled(FormFieldContainer)`
  padding: 0;
`;
const Promises = ({ isEditMode }: any) => {
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  const location: any = useLocation();
  const promises = useSelector((state: any) => state?.promisesList?.promises);
  const handleTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${event.target.name}`, event.target.value);
  };

  useEffect(() => {
    if (values?.totalPromisesOnHomeScreen >= promises?.length) {
      setFieldValue("totalPromisesOnHomeScreen", promises?.length);
    }
  }, [promises?.length]);

  return (
    <LastestUpdateContainer>
      <Grid container p={"1rem"}>
        <Grid item xs={6}>
          <Stack columnGap={2} justifyContent={"flex-start"}>
            <Typography className="accordion-heading" margin={"0.5rem 0"}>
              Promises
            </Typography>
            <SwitchComponent
              name="isPromisesActive"
              checked={values?.isPromisesActive}
              onChange={handleChange}
              disabled={location?.state?.isViewMode && isEditMode}
            />
            <Tooltip
              title={"This represent the ticker on Customer App"}
              arrow
              placement="top-start"
            >
              <InfoOutlinedIcon
                fontSize="small"
                className="infooutlined-alignment"
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Grid container columnSpacing={4} rowSpacing={2} p={"1rem"}>
        <Grid item xs={5} marginRight={"1rem"}>
          <TextFieldComponent
            inputProps={{ maxLength: 100 }}
            label="Section Heading"
            name={"promisesHeading"}
            value={values?.promisesHeading}
            disabled={location?.state?.isViewMode && isEditMode}
            required
            onChange={handleTextInput}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, "promisesHeading") &&
              getIn(errors, "promisesHeading")
            }
            error={Boolean(
              getIn(errors, "promisesHeading") &&
                getIn(touched, "promisesHeading")
            )}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container rowSpacing={1} p={"1rem"}>
        <Grid item lg={5} md={5} xs={5}>
          <Stack justifyContent="flex-start" spacing={1}>
            <Typography mb={"1.2rem"}>
              {" "}
              Total Promises on the home screen?
            </Typography>
            <PrioritySelector
              priority={values?.totalPromisesOnHomeScreen}
              disableDecrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalPromisesOnHomeScreen <= 1
              }
              disableIncrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalPromisesOnHomeScreen === promises?.length
              }
              decrementpriority={() => {
                values?.totalPromisesOnHomeScreen > 0 &&
                  setFieldValue(
                    "totalPromisesOnHomeScreen",
                    values?.totalPromisesOnHomeScreen - 1
                  );
              }}
              incrementpriority={() => {
                values?.totalPromisesOnHomeScreen <=
                (promises?.length >= 20 ? 20 : promises?.length - 1)
                  ? setFieldValue(
                      "totalPromisesOnHomeScreen",
                      values?.totalPromisesOnHomeScreen + 1
                    )
                  : alert(
                      `no more then ${
                        promises?.length >= 20 ? 20 : promises?.length
                      }`
                    );
              }}
              isCounter={true}
            />
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Box p="1rem" textAlign="right">
        {/* <ButtonComponent variant="contained">Save</ButtonComponent> */}
      </Box>
    </LastestUpdateContainer>
  );
};

export default Promises;
