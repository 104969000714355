import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  CustomDatePicker,
  InfoComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import { Fragment } from "react";
import { useSelector } from "react-redux";

const ProjectRegistration = () => {
  const { values, touched, errors, handleChange, setFieldValue, handleBlur } =
    useFormikContext<any>();
  const handleDatePickerChange = (name: string) => (newValue: string) => {
    setFieldValue(name, newValue);
  };
  const projectRegIndex = 0;
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  return (
    <Fragment>
      {values?.projectTimelines[projectRegIndex]?.timeLines?.map(
        (timeline: any, timelineIndex: number) => (
          <Fragment>
            <Box className="section-gutter">
              <Grid container margin={"1rem 0"}>
                <Grid item lg={3} xl={3}>
                  <TextFieldComponent
                    required
                    label="Section Heading"
                    inputProps={{ maxLength: 40 }}
                    name={`projectTimelines[${projectRegIndex}].timeLineSectionHeading`}
                    value={
                      values?.projectTimelines[projectRegIndex]
                        ?.timeLineSectionHeading
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      getIn(
                        touched,
                        `projectTimelines[${projectRegIndex}].timeLineSectionHeading`
                      ) &&
                        getIn(
                          errors,
                          `projectTimelines[${projectRegIndex}].timeLineSectionHeading`
                        )
                    )}
                    fieldhelpertext={
                      getIn(
                        touched,
                        `projectTimelines[${projectRegIndex}].timeLineSectionHeading`
                      ) &&
                      getIn(
                        errors,
                        `projectTimelines[${projectRegIndex}].timeLineSectionHeading`
                      )
                    }
                    disabled={isViewMode}
                  />
                </Grid>
              </Grid>
              <Stack justifyContent={"start"} spacing={2} mb={2}>
                <Typography color={"common.black"}>1.1 Rera</Typography>
                <SwitchComponent
                  name={`projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].isSectionActive`}
                  onChange={handleChange}
                  checked={
                    values?.projectTimelines[projectRegIndex]?.timeLines[
                      timelineIndex
                    ]?.isSectionActive
                  }
                  disabled={isViewMode}
                />
                <InfoComponent />
              </Stack>
              <Grid container>
                <Grid item lg={6} xl={6}>
                  <Grid container columnSpacing={5} rowSpacing={3}>
                    <Grid item lg={6} xl={6}>
                      <TextFieldComponent
                        label="Display Name*"
                        inputProps={{ maxLength: 30 }}
                        onChange={handleChange}
                        value={
                          values?.projectTimelines[projectRegIndex]?.timeLines[
                            timelineIndex
                          ]?.values?.displayName
                        }
                        onBlur={handleBlur}
                        name={`projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].values.displayName`}
                        error={Boolean(
                          getIn(
                            touched,
                            `projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].values.displayName`
                          ) &&
                            getIn(
                              errors,
                              `projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].values.displayName`
                            )
                        )}
                        fieldhelpertext={
                          getIn(
                            touched,
                            `projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].values.displayName`
                          ) &&
                          getIn(
                            errors,
                            `projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].values.displayName`
                          )
                        }
                        disabled={isViewMode}
                      />
                    </Grid>
                    <Grid item lg={6} xl={6}>
                      <CustomDatePicker
                        label={"Date of Completion"}
                        placeholder={"Select Date"}
                        name={`projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].values.dateOfCompletion`}
                        value={
                          values?.projectTimelines[projectRegIndex]?.timeLines[
                            timelineIndex
                          ]?.values?.dateOfCompletion
                        }
                        onChange={handleDatePickerChange(
                          `projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].values.dateOfCompletion`
                        )}
                        disabled={isViewMode}
                      />
                    </Grid>
                    <Grid item lg={12} xl={12}>
                      <TextFieldComponent
                        required
                        label="Rera Link"
                        name={`projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].values.reraLink`}
                        onChange={handleChange}
                        value={
                          values?.projectTimelines[projectRegIndex]?.timeLines[
                            timelineIndex
                          ]?.values?.reraLink
                        }
                        onBlur={handleBlur}
                        // error={
                        //   touched &&
                        //   // touched?.projectTimelines[0]
                        //   // ?.timeLines[0]?.values?.reraLink &&
                        //   !values?.projectTimelines[0]
                        //   ?.timeLines[0]?.values?.reraLink ? true : false
                        // }
                        fieldhelpertext={
                          getIn(
                            touched,
                            `projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].values.reraLink`
                          ) &&
                          getIn(
                            errors,
                            `projectTimelines[${projectRegIndex}].timeLines[${timelineIndex}].values.reraLink`
                          )
                        }
                        disabled={isViewMode}
                      />
                    </Grid>

                    {values?.reraDetails?.reraNumbers?.map(
                      (item: any, index: any) =>
                        item?.length > 0 && (
                          <Grid item lg={12} xl={12} key={index}>
                            <TextFieldComponent
                              disabled
                              label="Rera Number*"
                              value={item}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        )
      )}
    </Fragment>
  );
};

export default ProjectRegistration;
