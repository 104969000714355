export const enum StatusTypeEnum {
  NOT_STARTED_YET = "6000",
  // IN_PROGRESS = "6001",
  IN_PROGRESS_PERCENTAGE = "6002",
  COMPLETED = "6003",
}

export class TimeLineStatusTypeEnumUtils {
  public static getEnumText(type: StatusTypeEnum): string {
    switch (type) {
      case StatusTypeEnum.NOT_STARTED_YET:
        return "Not Started Yet";
      // case StatusTypeEnum.IN_PROGRESS:
      //   return "In Progress";
      case StatusTypeEnum.IN_PROGRESS_PERCENTAGE:
        return "In Progress (In %)";
      case StatusTypeEnum.COMPLETED:
        return "Completed";
    }
  }

  public static getTimeLineStatusTypeEnums(): {
    label: string;
    value: StatusTypeEnum;
  }[] {
    const options: { label: string; value: StatusTypeEnum }[] = [
      {
        label: this.getEnumText(StatusTypeEnum.NOT_STARTED_YET),
        value: StatusTypeEnum.NOT_STARTED_YET,
      },
      // {
      //   label: this.getEnumText(StatusTypeEnum.IN_PROGRESS),
      //   value: StatusTypeEnum.IN_PROGRESS,
      // },
      {
        label: this.getEnumText(StatusTypeEnum.IN_PROGRESS_PERCENTAGE),
        value: StatusTypeEnum.IN_PROGRESS_PERCENTAGE,
      },
      {
        label: this.getEnumText(StatusTypeEnum.COMPLETED),
        value: StatusTypeEnum.COMPLETED,
      },
    ];
    return options;
  }
}
