import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

type UpdateStateType = typeof INITIAL_STATE;

export interface IReduxSuccessActionProps {
  response?: {
    code: number;
    data: [];
    message: string;
  };
}

export interface IReduxFailureActionProps {
  error: {
    message: string;
  };
}

const { Types, Creators } = createActions({
  updateSuccess: ["response"],
  updateFailure: ["error"],
  updateRequest: ["data"],

  deleteUpdateSuccess: ["response"],
  deleteUpdateFailure: ["error"],
  deleteUpdateRequest: ["data"],

  updateStatusSuccess: ["response"],
  updateStatusFailure: ["error"],
  updateStatusRequest: ["data"],

  updatePrioritySuccess: ["response"],
  updatePriorityFailure: ["error"],
  updatePriorityRequest: ["data"],

  setUpdateState: ["key", "value"],
});

export const UpdateTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  updates: [],
  drafts: [],
  dashboardData: null,
  pageSize: Constants.defaultPageSize,
  pageIndex: Constants.defaultPageIndex,
  totalPages: Constants.defaultTotalPages,
  totalCount: Constants.defaultTotalCount,
  shouldOpenFlashDialog: false,
  error: null,
  isLoading: false,
  isPriorityUpdated: false,
  snackBarError: "",
  message: "",
  isUpdated: false,
  page: 0,
  rowsPerPage: 10,
};

/* Get update */ export const updateRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      updates: response.data,
      isLoading: false,
      // isUpdated: false,
      totalCount: response.totalCount,
      // message : response.message
    };
  }

  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const updateFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    // isUpdated: false,
  };
};

// update delete
export const deleteUpdateRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    message: "",
  };
};

export const deleteUpdateSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      isDeleted: true,
      totalCount: response.totalCount,
      message: action.response.message,
    };
  }
  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const deleteUpdateFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Updating Admin update status
export const updateStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateStatusSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      message: response.message,
    };
  }

  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const updateStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Update Admin updates status
export const updatePriorityRequest = (state: any) => {
  return {
    ...state,
    // isLoading: true,
    isUpdated: false,
  };
};

export const updatePrioritySuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      // isUpdated: true,
      isPriorityUpdated: true,
    };
  }
};

export const updatePriorityFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    // isUpdated: true,
  };
};

export const setUpdateState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_SUCCESS]: updateSuccess,
  [Types.UPDATE_FAILURE]: updateFailure,
  [Types.UPDATE_REQUEST]: updateRequest,

  [Types.DELETE_UPDATE_SUCCESS]: deleteUpdateSuccess,
  [Types.DELETE_UPDATE_FAILURE]: deleteUpdateFailure,
  [Types.DELETE_UPDATE_REQUEST]: deleteUpdateRequest,

  [Types.UPDATE_STATUS_REQUEST]: updateStatusRequest,
  [Types.UPDATE_STATUS_SUCCESS]: updateStatusSuccess,
  [Types.UPDATE_STATUS_FAILURE]: updateStatusFailure,

  [Types.UPDATE_PRIORITY_REQUEST]: updatePriorityRequest,
  [Types.UPDATE_PRIORITY_SUCCESS]: updatePrioritySuccess,
  [Types.UPDATE_PRIORITY_FAILURE]: updatePriorityFailure,

  [Types.SET_UPDATE_STATE]: setUpdateState,
});
