import {
  Avatar,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Grid,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  InputAdornment,
} from "@mui/material";
import {
  AutocompleteComponent,
  ButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectListHoablPage1Action } from "redux-container/page-management/hoabl-page-1/ProjectListInHoablPage1Redux";
import { format, compareAsc } from "date-fns";
import styles from "./Breadcrumbs.module.css";
import Search from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";
import Loader from "components/loader/Loader";
import { hoablPage1Action } from "redux-container/page-management/hoabl-page-1/HoablPage1Redux";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";

const tableHeadings = [
  { heading: "Project ID" },
  { heading: "Thumbnail" },
  { heading: "Name" },
  { heading: "Location" },
  { heading: "Starting Price" },
  { heading: "Starting Area" },
  { heading: "Created on" },
  { heading: "Modified on" },
];

const actionDispatch = (dispatch: any) => {
  return {
    getProjectContentList: (data: any) =>
      dispatch(projectListHoablPage1Action.projectListHoablPage1Request(data)),
    reduxProjectListWithPriority: (key: any, data: any) =>
      dispatch(hoablPage1Action.setHoablPage1State(key, data)),
  };
};

export default function NewInvestmentProjectTable(props: any) {
  const { getProjectContentList, reduxProjectListWithPriority } =
    actionDispatch(useDispatch());
  const {
    open,
    handleClose,
    onselectedProject,
    setAllProjectList,
    isAllProjectList,
    setMappedNewInvestment,
    mappedNewInvestment,
    totalProjectsOnHomeScreen,
    setFieldValue,
  } = props;
  const users = useSelector((state: any) => state?.users.users);
  const { totalCount, projectContentList, isLoading } = useSelector(
    (state: any) => state.projectContentListHoablPage1
  );
  const { hoablPage1 } = useSelector((state: any) => state?.hoablPage1Data);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [onCilckSearch, setOnClickSearch] = useState("");
  const [selected, setSelected] = useState<any>([]);
  const [createdBy, setCreatedBy] = useState<any>(null);
  const [modifiedBy, setModifiedBy] = useState<any>(null);
  const allUsers: any = useSelector(
    (state: any) => state?.users?.usersForFilters
  );
  const [selectedCreatedUser, setSelectedCreatedUser] = useState<any>(null);
  const [selectedModifiedUser, setSelectedModifiedUser] = useState<any>(null);
  const mappedNewInvestmentIds = mappedNewInvestment?.map(
    (item: any) => item?.id
  );

  useEffect(() => {
    setSelected(mappedNewInvestmentIds);
  }, []);

  useEffect(() => {
    if (isAllProjectList) {
      const param = {
        searchKey: onCilckSearch,
        index: page + 1,
        size: rowsPerPage,
        createdBy: selectedCreatedUser?.id,
        updatedBy: selectedModifiedUser?.id,
        status: "1001",
      };

      getProjectContentList(param);
      setAllProjectList(false);
    }
  }, [
    onCilckSearch,
    rowsPerPage,
    page,
    selectedCreatedUser,
    selectedModifiedUser,
  ]);

  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  //search functions

  const handleOnEnterKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      search();
    }
  };
  const search = () => {
    // handleSearchOnKeyPress && handleSearchOnKeyPress(searchKey);
    setOnClickSearch(searchKey);
    setAllProjectList(true);
  };
  const searchKeyPress = () => {
    search();
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event?.target.value);
  };
  const clearSearchKey = () => {
    setOnClickSearch("");
    setSearchKey("");
    setAllProjectList(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setAllProjectList(true);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setAllProjectList(true);
  };

  //select all
  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = projectContentList?.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (e: any, id: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleResetFilter = () => {
    setSelected([]);
  };

  const handleMappedProject = () => {
    const filterSelected = projectContentList.filter((project: any) => {
      // selected array of object from project
      return selected.find((id: any) => {
        return id === project.id;
      });
    });

    const duplicateProject = [...filterSelected]; // concating both array
    const uniqueProject = new Set();
    const uniqueProjects = duplicateProject?.filter((project: any) => {
      // removing duplicate from array.
      const isDuplicate = uniqueProject?.has(project.id);
      uniqueProject.add(project.id);
      if (!isDuplicate) {
        return true;
      } else {
        return false;
      }
    });

    const finalProjectList = uniqueProjects?.map((project: any, index: any) => {
      return {
        ...project,
        priority: index + 1,
      };
    });
    setMappedNewInvestment(finalProjectList);
    reduxProjectListWithPriority(
      "projectListWithPriorityReduxState",
      finalProjectList
    );
    if (totalProjectsOnHomeScreen > finalProjectList?.length) {
      setFieldValue("totalProjectsOnHomeScreen", finalProjectList?.length);
    }
    handleClose();
  };

  const clearFilters = () => {
    setSelectedModifiedUser(null);
    setSelectedCreatedUser(null);
    setAllProjectList(true);
  };

  return (
    <Dialog fullWidth maxWidth={"xl"} open={open} onClose={handleClose}>
      <DialogContent sx={{ padding: "1rem 2rem" }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Stack justifyContent={"flex-start"} spacing={2}>
              <Typography
                variant="h6"
                fontWeight={"Bold"}
                color={"secondary.dark"}
              >
                Project({projectContentList?.length})
              </Typography>
              {selected.length > 0 ? (
                <Typography>
                  {" "}
                  {selected.length} Project selected{" "}
                  <span onClick={handleResetFilter} className="clear-filter">
                    Clear
                  </span>{" "}
                </Typography>
              ) : null}
            </Stack>
          </Grid>
          <Grid item>
            <Stack justifyContent="flex-end" spacing={2}>
              <ButtonComponent onClick={handleClose}>Cancel</ButtonComponent>
              <ButtonComponent
                variant="contained"
                disabled={selected.length === 0}
                onClick={handleMappedProject}
              >
                Map Selection
              </ButtonComponent>
            </Stack>
          </Grid>
        </Grid>
        <Stack mt={"0.5rem"}>
          <Grid container spacing={1}>
            <Grid item className="margin-left-10 filter-heading-holder">
              <span className="filter-heading" style={{ marginBottom: "15px" }}>
                Filters:
              </span>
            </Grid>
            <Grid item xs={2}>
              <FormControl className="filter-dropdown">
                <AutocompleteComponent
                  options={allUsers}
                  getOptionLabel={(option: any) => option?.firstName}
                  label="Created By"
                  onChange={(
                    event: React.SyntheticEvent,
                    newValue: any | null
                  ) => {
                    setSelectedCreatedUser(newValue);
                    setAllProjectList(true);
                  }}
                  value={selectedCreatedUser}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl className="filter-dropdown">
                <AutocompleteComponent
                  options={allUsers}
                  getOptionLabel={(option: any) => option?.firstName}
                  label="Modified By"
                  onChange={(
                    event: React.SyntheticEvent,
                    newValue: any | null
                  ) => {
                    setSelectedModifiedUser(newValue);
                    setAllProjectList(true);
                  }}
                  value={selectedModifiedUser}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              {selectedModifiedUser !== null || selectedCreatedUser !== null ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Grid>
            <TextFieldComponent
              placeholder="Search"
              value={searchKey}
              fullWidth={false}
              sx={{ width: "300px" }}
              size="medium"
              onChange={handleSearchChange}
              onKeyPress={handleOnEnterKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="search"
                      onClick={searchKeyPress}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    {searchKey.length > 0 ? (
                      <IconButton
                        aria-label="cancel"
                        onClick={clearSearchKey}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <div></div>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Stack>
        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Table>
              <TableHead>
                <TableRow className="table-header-row">
                  <TableCell className="table-header-text" padding="checkbox">
                    <Checkbox
                      className="active-checkbox"
                      data-testid="table-head-checkbox"
                      color="primary"
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < projectContentList?.length
                      }
                      checked={
                        projectContentList?.length > 0 &&
                        selected?.length === projectContentList?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  {tableHeadings.map((item, index) => (
                    <TableCell key={index} className="table-header-text">
                      {item.heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {projectContentList?.map((project: any, index: any) => {
                  const isItemSelected = isSelected(project?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={project?.id}
                      className="table-data-row"
                      hover
                      // onClick={(event) => handleClick(event.target)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ minWidth: 50 }} padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, project?.id)}
                        />
                      </TableCell>
                      <TableCell className="table-data-text">
                        {project?.id}
                      </TableCell>
                      <TableCell className="table-data-text">
                        {project.projectCoverImages?.homePageMedia?.value?.url
                          .length > 0 && (
                          <Avatar
                            style={{
                              height: "70px",
                              width: "110px",
                            }}
                            // className={styles["Media-box"]}
                            src={
                              project.projectCoverImages?.homePageMedia?.value
                                ?.url
                            }
                          ></Avatar>
                        )}
                      </TableCell>
                      <TableCell className="table-data-text">
                        {project?.launchName}
                      </TableCell>
                      <TableCell className="table-data-text">
                        {`${project?.address?.city}, ${project?.address?.state} `}
                      </TableCell>
                      <TableCell className="table-data-text">
                        {project?.priceStartingFrom}
                      </TableCell>
                      <TableCell className="table-data-text">
                        {project?.areaStartingFrom}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        <div>{project?.projectContentCreatedBy?.firstName}</div>
                        {project?.createdAt
                          ? formatDateTime(
                              project?.createdAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "-"}
                      </TableCell>

                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {
                          <div>
                            {project?.projectContentUpdatedBy?.firstName}
                          </div>
                        }
                        {project?.updatedAt
                          ? formatDateTime(
                              project?.updatedAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>

        {!searchKey && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 30]}
            component="div"
            count={
              totalCount
                ? totalCount
                : projectContentList?.length
                ? projectContentList?.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
