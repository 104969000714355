import {
  Avatar,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import {
  ButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { FieldArray, getIn, useFormikContext } from "formik";
import styles from "./Opportunity.module.css";
import { useState } from "react";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import ProjectAmenitiesMedia from "../opportunity-doc/ProjectAmenitiesMedia";
import { useSelector } from "react-redux";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

function ProjectAmenities() {
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<IInitinalValueProjectContent>();

  const [currentlyOpenedIndex, setCurrentlyOpenedIndex] = useState(999);
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const handleProjectAmenitiesMedia = (imageIndex: number) => {
    setCurrentlyOpenedIndex(imageIndex);
  };

  return (
    <div>
      <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
        <Typography>Project Amenities</Typography>

        <FormControlLabel
          className="switch-form-label"
          name={"opportunityDoc.isProjectAminitiesActive"}
          checked={values?.opportunityDoc?.isProjectAminitiesActive}
          onChange={handleChange}
          control={<SwitchComponent />}
          label=""
          labelPlacement="start"
          disabled={isViewMode}
        />

        <IconButton>
          <Tooltip title={"Project Amenities"} placement="top">
            <Avatar
              className={styles["Avator"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
      </Stack>
      <Grid item xs={3} sm={3} md={3} lg={4} xl={3.2} p={3}>
        <TextFieldComponent
          required
          type="text"
          placeholder="Enter"
          label="section Heading"
          name={`opportunityDoc.projectAminities.${0}.projectAminitiesSectionHeading`}
          value={
            values?.opportunityDoc?.projectAminities[0]
              ?.projectAminitiesSectionHeading
          }
          onChange={handleChange}
          inputProps={{ maxLength: 40 }}
          onBlur={handleBlur}
          fieldhelpertext={
            getIn(
              touched,
              `opportunityDoc.projectAminities.${0}.projectAminitiesSectionHeading`
            ) &&
            getIn(
              errors,
              `opportunityDoc.projectAminities.${0}.projectAminitiesSectionHeading`
            )
          }
          error={Boolean(
            getIn(
              errors,
              `opportunityDoc.projectAminities.${0}.projectAminitiesSectionHeading`
            ) &&
              getIn(
                touched,
                `opportunityDoc.projectAminities.${0}.projectAminitiesSectionHeading`
              )
          )}
          disabled={isViewMode}
        />
      </Grid>
      <FieldArray
        name="opportunityDoc.projectAminities"
        render={(arrayHelpers) => (
          <div>
            {values?.opportunityDoc?.projectAminities?.length > 0 &&
              values?.opportunityDoc?.projectAminities?.map(
                (element: any, index: number) => {
                  return (
                    <div>
                      <Grid container>
                        <Grid item xs={3} sm={3} md={3} lg={4} xl={3.2} p={3}>
                          <ProjectAmenitiesMedia
                            index={index}
                            currentlyOpenedIndex={currentlyOpenedIndex}
                            handleProjectAmenitiesMedia={
                              handleProjectAmenitiesMedia
                            }
                          />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={4} xl={3.3} p={3}>
                          <TextFieldComponent
                            required
                            type="text"
                            placeholder="Enter"
                            label="Amenity Name"
                            name={`opportunityDoc.projectAminities.${index}.name`}
                            value={
                              values?.opportunityDoc?.projectAminities[index]
                                .name
                            }
                            onChange={handleChange}
                            inputProps={{ maxLength: 15 }}
                            onBlur={handleBlur}
                            fieldhelpertext={
                              getIn(
                                touched,
                                `opportunityDoc.projectAminities.${index}.name`
                              ) &&
                              getIn(
                                errors,
                                `opportunityDoc.projectAminities.${index}.name`
                              )
                            }
                            error={Boolean(
                              getIn(
                                errors,
                                `opportunityDoc.projectAminities.${index}.name`
                              ) &&
                                getIn(
                                  touched,
                                  `opportunityDoc.projectAminities.${index}.name`
                                )
                            )}
                            disabled={isViewMode}
                          />

                          {values?.opportunityDoc?.projectAminities?.length >
                            1 && (
                            <IconButton
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                              disabled={isViewMode}
                            >
                              <DeleteOutlineOutlinedIcon color="error" />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>

                      {!(
                        index ==
                        values?.opportunityDoc?.projectAminities?.length - 1
                      ) && <Divider sx={{ mt: "0.5rem" }} />}

                      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} ml={2}>
                        {values?.opportunityDoc?.projectAminities?.length -
                          1 ===
                          index && (
                          <ButtonComponent
                            sx={{
                              textDecoration: "underline",
                              marginTop: "0.5rem",
                            }}
                            variant="text"
                            color="inherit"
                            onClick={() => {
                              arrayHelpers.push({
                                name: "",
                                icon: {
                                  name: "",
                                  key: "",
                                  value: {
                                    url: "",
                                    size: 0,
                                    width: 1024,
                                    height: 1024,
                                    mediaType: "image",
                                  },
                                },
                              });
                            }}
                            disabled={isViewMode}
                          >
                            + Add more Values
                          </ButtonComponent>
                        )}
                      </Grid>
                    </div>
                  );
                }
              )}
          </div>
        )}
      />
      <Divider sx={{ mt: "0.5rem" }} />
    </div>
  );
}

export default ProjectAmenities;
