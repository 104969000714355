import { Grid, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { getIn } from "formik";
import MediaUploadComponent from "components/ui-elements/media-upload-component/MediaUploadComponent";
import { InfoComponent } from "components/ui-elements";
import { useState } from "react";

const actionDispatch = (dispatch: any) => {
  return {
    fileUploadRequest: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    fileDeleteRequest: (data: any) =>
      dispatch(FileUploadActions.fileDeleteRequest(data)),
    setUploadFileState: (key: string, value: any) =>
      dispatch(FileUploadActions.setUploadFileState(key, value)),
  };
};

const PromiseIconTypes = ({ isViewMode }: Record<string, boolean>) => {
  const {
    imageDetails,
    isLoading,
    isSuccess,
    shouldFlashMessageOpen,
    data,
    error,
  } = useSelector((state: any) => state?.fileUpload);
  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<any>();
  const { fileUploadRequest, setUploadFileState } = actionDispatch(
    useDispatch()
  );

  const [mediaUploadIndex, setMediaUploadIndex] = useState<number | null>(null);

  useEffect(() => {
    if (imageDetails && mediaUploadIndex === 1) {
      setFieldTouched(`displayMedia.value.url`, true); // after setting formik value, we should not touch that field manually.
      setFieldValue(`displayMedia.value.url`, imageDetails?.url);
      setUploadFileState("imageDetails", null);
    }
  }, [imageDetails]);

  const handlePromiseIconUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (e: any) => {
        data.binary = e.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);
      setFieldValue(`displayMedia.value.url`, imageDetails?.url);
      setFieldValue(`displayMedia.value.mediaType`, file.type);
      setFieldValue(`displayMedia.value.size`, file.size);
      setFieldValue(`displayMedia.name`, file.name);
      setFieldValue(`displayMedia.key`, file.name);
      setUploadFileState("shouldFlashMessageOpen", true);
    } else {
      return;
    }
  };

  const handleImageDelete = () => {
    setFieldValue("displayMedia.value.url", "");
    setFieldTouched("displayMedia.value.url", true);
  };

  return (
    <Fragment>
      <Grid container columnGap={4} spacing={4} className={"box"}>
        <Grid item xs={6} md={3.5} sm={3.5} lg={3.5} xl={3.5}>
          <MediaUploadComponent
            loading={mediaUploadIndex === 1 && isLoading}
            disabled={isViewMode}
            ondrop={handleChange}
            name={"displayMedia"}
            onChange={(event: any) => {
              setMediaUploadIndex(1);
              handlePromiseIconUpload(event);
            }}
            previewimage={values.displayMedia?.value?.url}
            cancelimage={handleImageDelete}
            fieldhelpertext={
              getIn(touched, "displayMedia.value.url") &&
              getIn(errors, "displayMedia.value.url")
            }
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PromiseIconTypes;
