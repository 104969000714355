import { Avatar, Box, Typography, Link, IconButton } from "@mui/material";
import { styled } from "@mui/material";
import MediaAssets from "../../../assets";
import CloseIcon from "@mui/icons-material/Close";
import AlertHelperText from "../alert-helper-text/AlertHelperText";
import { useSelector } from "react-redux";

interface IImageuploaderdropbox {
  fieldhelpertext: any;
  onDrop: (event: any) => void;
  onChange: (event: any) => void;
  previewimage?: any;
  name?: string;
  value?: string;
  cancelimage: () => void;
  onBlur: (event: any) => void;
}

const DragAndDropStyle = styled(Box)({
  border: "3px dotted #9795af",
  borderRadius: "2px",
  width: "20rem",
  height: "15rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  "& .innerButton": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const Imageuploaderdropbox = (props: IImageuploaderdropbox) => {
  const { isLoading } = useSelector((state: any) => state.fileUpload);
  return (
    <DragAndDropStyle data-testid="drag-and-drop">
      {props.previewimage?.length > 0 ? (
        <Box>
          <IconButton
            onClick={props.cancelimage}
            sx={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
          >
            {" "}
            <CloseIcon />
          </IconButton>
          <Avatar
            sx={{ height: "100%", width: "100%", objectFit: "cover" }}
            src={`${props.previewimage}`}
            alt="image"
          />
        </Box>
      ) : (
        <Box
          component="label"
          htmlFor={`contained-button-file`}
          style={{ padding: "10%" }}
        >
          <input
            // accept=".jpg,.png,.jpeg,.webp,.pdf"
            accept=".jpg,.png,.jpeg"
            id={`contained-button-file`}
            name={props.name}
            type="file"
            value={props.value}
            onBlur={props.onBlur}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              opacity: 0,
            }}
            onChange={(event: any) => {
              props.onChange(event.target.files[0]);
            }}
            onDrop={(event: any) => {
              props.onDrop(event);
            }}
          />
          <Box className="innerButton">
            <Avatar
              sx={{ height: "30px", width: "30px" }}
              src={MediaAssets.ic_upload}
            />
            <Box sx={{ color: "#6e6b8f", fontSize: "16px" }}>
              Drag & Drop here
            </Box>
            <Box sx={{ color: "#9b9aa8", fontSize: "16px" }}>Or</Box>
            <Link>
              {" "}
              <Typography
                sx={{
                  color: "#4a39ff",
                  fontSize: "16px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                data-testid="browser-files"
              >
                {isLoading ? "Loading..." : "Browser files"}
              </Typography>
            </Link>
            <Box sx={{ color: "#9795af", fontSize: "12px" }}>
              Max file size : 2 MB
            </Box>
            {props.fieldhelpertext ? (
              <AlertHelperText color="error" severity="error">
                {props.fieldhelpertext || ""}
              </AlertHelperText>
            ) : (
              <Typography
                variant="body2"
                visibility={"visible"}
                lineHeight={1.5}
              ></Typography>
            )}
          </Box>
        </Box>
      )}
    </DragAndDropStyle>
  );
};
export default Imageuploaderdropbox;
