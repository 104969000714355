import { ChangeEvent, Fragment, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import styles from "./UpcommingProjectListing.module.css";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import PanoramaIcon from "@mui/icons-material/Panorama";
// import { Divider } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import UpCommingProjectContentCreationTypes from "redux-container/project-management/upcomming-project/UpcommingProjectRedux";
import {
  AutocompleteComponent,
  ButtonComponent,
  InfoComponent,
  SwitchComponent,
  TextFieldComponent,
} from "../../../../components/ui-elements/";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SearchIcon from "@mui/icons-material/Search";
import {
  Divider,
  IconButton,
  Stack,
  Tab,
  Tooltip,
  Typography,
  Checkbox,
  CardMedia,
  Card,
  Box,
  Paper,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import { ProjectStatusTypeEnumUtils } from "enumerations/ProjectStatusTypeEnum";
import { log } from "console";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import Loader from "components/loader/Loader";

const actionDispatch = (dispatch: any) => {
  return {
    // filterUpCommingProjectContentCreationDataRequest,
    filterUpCommingProjectContentCreationDataRequest: (data: any) =>
      dispatch(
        UpCommingProjectContentCreationTypes.filterUpCommingProjectContentCreationDataRequest(
          data
        )
      ),
    getUpCommingProjectContentCreationDataRequest: () =>
      dispatch(
        UpCommingProjectContentCreationTypes.getUpCommingProjectContentCreationDataRequest()
      ),
    getSingleUpCommingProjectContentCreationDataRequest: (data: any) =>
      dispatch(
        UpCommingProjectContentCreationTypes.getSingleUpCommingProjectContentCreationDataRequest(
          data
        )
      ),
    setUpcomingProjectState: (key: any, data: any) => {
      dispatch(
        UpCommingProjectContentCreationTypes.setUpcomingProjectState(key, data)
      );
    },
  };
};
const UpcommingProjectListing = (props: any) => {
  const statuses = ProjectStatusTypeEnumUtils.getProjectStatusTypeEnums();
  const [checkedValues, setcheCkedValues] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [startIndex, setStartIndex] = useState(0);
  const [totalProject, setTotalProject] = useState<any>();
  const [selectedCreatedUser, setSelectedCreatedUser] = useState<any>(null);
  const [selectedModifiedUser, setSelectedModifiedUser] = useState<any>(null);
  const [filterText, setFilterText] = useState<any>(``);
  const [checkIds, setCheckIds] = useState<any>([
    {
      id: "",
      checked: "",
    },
  ]);
  // const [upcommingselectedPublish, setUpcommingselectedPublish] = useState<any>([]);
  const [inc, setinc] = useState<any>(5);
  // const { selectedPublish, setSelectedPublish } = props;
  const {
    upcommingselectedPublish,
    setUpcommingselectedPublish,
    searchKeyforUpcoming,
  } = props;
  const publishedHeadings = [
    { heading: "Project ID" },
    { heading: "Banner Image" },
    { heading: "Title" },
    { heading: "Short Description" },
    { heading: "Project Number" },
    { heading: "Project Icon" },
    { heading: "State" },
    { heading: "Action" },
  ];
  const {
    filterUpCommingProjectContentCreationDataRequest,
    getUpCommingProjectContentCreationDataRequest,
    getSingleUpCommingProjectContentCreationDataRequest,
    setUpcomingProjectState,
  } = actionDispatch(useDispatch());
  const commingSoonProjects = useSelector((state: any) => {
    return state?.upCommingProjects?.upCommingprojectCreationData;
  });

  const navigate = useNavigate();
  const location: any = useLocation();
  console.log("projects", commingSoonProjects);
  const handleSeeIconClick = (published: any) => {
    getSingleUpCommingProjectContentCreationDataRequest(published.id);
    navigate(RouteConstants.singleUpcommingProject, {
      state: { project: published },
    });
  };
  const handleUpdateIconClick = (data: any, project: any) => {
    getSingleUpCommingProjectContentCreationDataRequest(data);
    // navigate(RouteConstants.upCommingProjectUpdate, {
    //   state: { data: data, isEditMode: true },
    // });
    navigate(RouteConstants.upCommingProjectUpdate, {
      state: { project },
    });
  };
  const {
    page,
    rowsPerPage,
    isDeleted,
    isUpdated,
    isLoading,
    allUsers,
    tabValue,
    totalCount,
    publishedProjects,
    fetchedAllLaunchPhasesData,
    shouldOpenFlashDialog,
    error,
    message,
    isSuccess,
  } = useSelector((state: any) => {
    return {
      page: state?.upCommingProjects?.pageOfUpcomingProject,
      rowsPerPage: state?.upCommingProjects?.rowsPerPage,
      isDeleted: state?.projectList?.isDeleted,
      isUpdated: state?.projectList?.isUpdated,
      isLoading: state?.upCommingProjects?.isLoading,
      allUsers: state?.users?.usersForFilters,
      tabValue: state?.projectList?.tabValue,
      totalCount: state?.upCommingProjects?.totalCount,
      publishedProjects: state?.projectList?.data?.data,
      fetchedAllLaunchPhasesData:
        state.projectLaunchPhases?.fetchedAllLaunchPhasesData,
      shouldOpenFlashDialog: state?.upCommingProjects?.shouldOpenFlashDialog,
      error: state?.upCommingProjects?.error,
      isSuccess: state?.upCommingProjects?.isSuccess,
      message: state?.upCommingProjects?.message,
    };
  });

  const handleChangePageOfNewTable = (event: unknown, newPage: number) => {
    setUpcomingProjectState("pageOfUpcomingProject", newPage);
  };

  const handleChangeRowsPerPageOfNewTable = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUpcomingProjectState("rowsPerPage", Number(event.target.value));
    setUpcomingProjectState("pageOfUpcomingProject", 0);
  };

  useEffect(() => {
    console.log("if", rowsPerPage, page);
    filterUpCommingProjectContentCreationDataRequest({
      searchKey: searchKeyforUpcoming,
      index: page + 1,
      size: rowsPerPage,
      status: location?.state?.statusType
        ? location?.state?.statusType
        : selectedStatus,
      createdBy:
        selectedCreatedUser?.id !== undefined ? selectedCreatedUser?.id : "",
      updatedBy:
        selectedModifiedUser?.id !== undefined ? selectedModifiedUser?.id : "",
    });
  }, [
    searchKeyforUpcoming,
    page,
    rowsPerPage,
    selectedStatus,
    selectedCreatedUser,
    selectedModifiedUser,
  ]);

  useEffect(() => {
    if (location?.state?.isNavigationFromSidemenu) {
      setUpcomingProjectState("pageOfUpcomingProject", 0);
      setUpcomingProjectState("rowsPerPage", 5);
    }

    return () => {};
  }, []);

  let text = ``;
  // filterUpCommingProjectContentCreationDataRequest
  const handleStatusOnchange = (from: any, event: any) => {
    console.log("eventtt", from);

    if (from == "status") {
      let x = `status=${event.target.value}&`;
      text = text + x;
    }
    if (from == "users") {
      let txt = `createdBy=${event.id}`;
      text = text + txt;
    }
    if (from == "modified") {
      let txt = `updatedBy=${event.id}`;
      text = text + txt;
    }
    console.log("text", text);

    filterUpCommingProjectContentCreationDataRequest(text);
  };

  console.log("allusers", allUsers);

  const handleSearchOnChnage = (e: any) => {
    let searchText = `searchKey=${e.target.value}`;
    filterUpCommingProjectContentCreationDataRequest(searchText);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = upcommingselectedPublish.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(upcommingselectedPublish, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(upcommingselectedPublish.slice(1));
    } else if (selectedIndex === upcommingselectedPublish.length - 1) {
      newSelected = newSelected.concat(upcommingselectedPublish.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        upcommingselectedPublish.slice(0, selectedIndex),
        upcommingselectedPublish.slice(selectedIndex + 1)
      );
    }
    setUpcommingselectedPublish(newSelected);
  };

  const isSelected = (id: any) => upcommingselectedPublish.indexOf(id) !== -1;

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = commingSoonProjects.map((n: any) => n.id);
      setUpcommingselectedPublish(newSelecteds);
      return;
    }
    setUpcommingselectedPublish([]);
  };

  const handleCloseFlashMessageDialog = () => {
    setUpcomingProjectState("shouldOpenFlashDialog", false);
    setUpcomingProjectState("error", false);
    setUpcomingProjectState("message", false);
    setUpcomingProjectState("isSuccess", false);
  };

  const handleResetFilter = () => {
    setSelectedStatus(null);
    setSelectedCreatedUser(null);
    setSelectedModifiedUser(null);
  };

  const isFilterApplied = () => {
    if (
      selectedStatus === null &&
      selectedCreatedUser === null &&
      selectedModifiedUser === null
    ) {
      return false;
    }
    console.log("FFFFFFFFFF");
    return true;
  };

  console.log(
    "status",
    selectedStatus,
    selectedCreatedUser,
    selectedModifiedUser
  );

  return (
    <Fragment>
      {shouldOpenFlashDialog && (
        <FlashMessageDialog
          shouldOpen={shouldOpenFlashDialog}
          content={message}
          isSuccess={isSuccess}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={8} lg={12} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>
              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedStatus}
                    label="Status"
                    onChange={(event: any) => {
                      setSelectedStatus(event.target.value);
                      handleStatusOnchange("status", event);

                      // if (page !== 0) {
                      //   setProjectState("page", 0);
                      // }
                    }}
                  >
                    {statuses.map((status: any) => {
                      return (
                        <MenuItem value={status.value} key={status.value}>
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={allUsers}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    label="Created By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedCreatedUser(newValue);
                      handleStatusOnchange("users", newValue);
                      // if (page !== 0) {
                      //   setProjectState("page", 0);
                      // }
                    }}
                    value={selectedCreatedUser}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={2.5} lg={2.5} xl={2.5}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={allUsers}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    label="Modified By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedModifiedUser(newValue);
                      handleStatusOnchange("modified", newValue);
                      // if (page !== 0) {
                      //   setProjectState("page", 0);
                      // }
                    }}
                    value={selectedModifiedUser}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>
              {selectedStatus !== null ||
              selectedModifiedUser !== null ||
              selectedCreatedUser !== null ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={handleResetFilter}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            className="table-container"
            style={{ height: window.innerHeight - 200 }}
          >
            <Table style={{ position: "relative" }}>
              <TableHead>
                <TableRow className="table-header-row">
                  <TableCell className="table-header-text" padding="checkbox">
                    <Checkbox
                      className="active-checkbox"
                      data-testid="table-head-checkbox"
                      color="primary"
                      indeterminate={
                        upcommingselectedPublish?.length > 0 &&
                        upcommingselectedPublish?.length <
                          commingSoonProjects?.length
                      }
                      checked={
                        commingSoonProjects?.length > 0 &&
                        upcommingselectedPublish?.length ===
                          commingSoonProjects?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  {publishedHeadings.map((item, index) => (
                    <TableCell key={index} className="table-header-text">
                      {item.heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {commingSoonProjects?.length > 0 ? (
                <TableBody>
                  {commingSoonProjects?.map((published: any) => {
                    const isItemSelected: any = isSelected(published?.id);

                    return (
                      <TableRow
                        key={published.id}
                        className="table-data-row"
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event: any) =>
                              handleClick(event, published?.id)
                            }
                            className="active-checkbox"
                            checked={isItemSelected}
                          />
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 150 }}
                        >
                          {published?.id}
                        </TableCell>

                        <TableCell
                          style={{
                            minWidth: 150,
                            justifyContent: "center",
                          }}
                        >
                          {published?.bannerImage?.value?.url ? (
                            <Card
                              elevation={0}
                              className={styles["project-image"]}
                              style={{
                                height: "80px",
                                width: "100%",
                                objectFit: "fill",
                              }}
                            >
                              <CardMedia
                                className={styles["media-area"]}
                                component={"img"}
                                alt="Image"
                                src={published?.bannerImage?.value?.url}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "fill",
                                }}
                              />
                            </Card>
                          ) : (
                            <PanoramaIcon
                              style={{ height: "3rem", width: "3rem" }}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 200 }}
                        >
                          {published?.launchName}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 200 }}
                        >
                          {published?.shortDescription}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 200 }}
                        >
                          {published?.projectPhoneNumber}
                        </TableCell>
                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 200 }}
                        >
                          {published?.projectIcon?.value?.url ? (
                            <Card
                              elevation={0}
                              className={styles["project-image"]}
                              style={{
                                height: "80px",
                                width: "100%",
                                objectFit: "fill",
                              }}
                            >
                              <CardMedia
                                className={styles["media-area"]}
                                component={"img"}
                                alt="Image"
                                src={published?.projectIcon?.value?.url}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "fill",
                                }}
                              />
                            </Card>
                          ) : (
                            <PanoramaIcon
                              style={{ height: "3rem", width: "3rem" }}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 150 }}
                        >
                          {published?.address?.state}
                        </TableCell>
                        <TableCell
                          className="table-data-text"
                          style={{ minWidth: 150 }}
                        >
                          <Tooltip title="View" placement="top">
                            <IconButton
                              aria-label="view"
                              onClick={() => handleSeeIconClick(published)}
                            >
                              <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit" placement="top">
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                handleUpdateIconClick(published?.id, published)
                              }
                            >
                              <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <Box mt={-5} className="no-record">
                  {/* {renderNoData()} */}
                  No Data Found
                </Box>
              )}
            </Table>
          </TableContainer>
          {!searchKeyforUpcoming || isFilterApplied() ? (
            <TablePagination
              className={styles["table-pagination"]}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount ? totalCount : commingSoonProjects?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePageOfNewTable}
              onRowsPerPageChange={handleChangeRowsPerPageOfNewTable}
            />
          ) : null}
        </Box>
      )}
    </Fragment>
  );
};

export default UpcommingProjectListing;
