import ic_add_user from "./images/ic_add_user.png";
import ic_duplicate from "./images/ic_duplicate.png";
import ic_error_cross from "./images/ic_error_cross.png";
import ic_faq from "./images/ic_faq.png";
import ic_fetch_white from "./images/ic_fetch_white.png";
import ic_logo from "./images/ic_logo.png";
import ic_logo_black from "./images/ic_logo_black.png";
import ic_insight from "./images/ic_insight.png";
import ic_nudge from "./images/ic_nudge.png";
import ic_projects from "./images/ic_projects.png";
import ic_project_collections from "./images/ic_project_collections.png";
import ic_promise from "./images/ic_promise.png";
import ic_promotion_offer from "./images/ic_promotion_offer.png";
import ic_referral from "./images/ic_referral.png";
import ic_retry_error from "./images/ic_retry_error.png";
import ic_success_tick from "./images/ic_success_tick.png";
import ic_testimonial from "./images/ic_testimonial.png";
import ic_update from "./images/ic_update.png";
import ic_upload from "./images/ic_upload.png";
import ic_info from "./images/ic_info.png";
import ic_close from "./images/ic_close.png";
import ic_edit from "./images/ic_edit.png";
import ic_upload_white from "./images/ic_upload_white.png";
import ic_file_upload from "./images/ic_fiLe_upload.png";
import ic_search from "./images/ic_search.png";
import ic_upload_black from "./images/ic_upload_black.png";
import ic_preview_lock_icon from "./images/ic_preview_lock_icon.png";
import ic_radio_checked_icon from "./images/ic_radio_checked_icon.png";
import ic_radio_icon from "./images/ic_radio_icon.png";
import ic_media_gallery_tooltip from "./images/ic_media_gallery_tooltip.png";
import ic_calendar_icon from "./images/ic_calendar_icon.png";
import ic_newspaper from "./images/ic_newspaper.png";
import ic_google_docs from "./images/ic_google_docs.png";
import ic_combined_shape from "./images/ic_combined_shape.png";
import ic_warning from "./images/ic_warning.png";
import ic_rupee_coin from "./images/ic_rupee_coin.png";
import ic_rupee_disabled from "./images/ic_rupee_disabled.png";
import ic_docs_icon from "./images/ic_docs_icon.png";
import ic_clone from "./images/ic_clone.png";

const MediaAssets = {
  ic_add_user,
  ic_duplicate,
  ic_error_cross,
  ic_faq,
  ic_fetch_white,
  ic_logo,
  ic_logo_black,
  ic_insight,
  ic_nudge,
  ic_projects,
  ic_project_collections,
  ic_promise,
  ic_promotion_offer,
  ic_referral,
  ic_retry_error,
  ic_success_tick,
  ic_testimonial,
  ic_update,
  ic_upload,
  ic_upload_white,
  ic_info,
  ic_close,
  ic_edit,
  ic_search,
  ic_upload_black,
  ic_file_upload,
  ic_preview_lock_icon,
  ic_radio_checked_icon,
  ic_radio_icon,
  ic_media_gallery_tooltip,
  ic_calendar_icon,
  ic_newspaper,
  ic_rupee_coin,
  ic_rupee_disabled,
  ic_google_docs,
  ic_combined_shape,
  ic_warning,
  ic_docs_icon,
  ic_clone,
};

export default MediaAssets;
