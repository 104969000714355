import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  referralsRequest: ["data"],
  referralsSuccess: ["response"],
  referralsFailure: ["error"],
});

export const ReferralsTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  referrals: [],
};

export const referralsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const referralsSuccess = (state: any, action: any) => {
  return {
    ...state,
    error: null,
    referrals: action.response,
    isLoading: false,
  };
};

export const referralsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.message,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REFERRALS_REQUEST]: referralsRequest,
  [Types.REFERRALS_SUCCESS]: referralsSuccess,
  [Types.REFERRALS_FAILURE]: referralsFailure,
});
