import * as Yup from "yup";

export const FAQSchemaValidation = Yup.object().shape({
  categoryId: Yup.string().when('isCategoryField', {
    is: true,
    then: Yup.string().required('Please select category.'),
  }),
  faqs: Yup.array().of(
    Yup.object().shape({
      faqQuestion: Yup.object().shape({
        question: Yup.string().required("Please Enter question"),
      }),
      faqAnswer: Yup.object().shape({
        answer: Yup.string().required("Please Enter Answer"),
      }),
    })
  ),
});
