import { Fragment, useState } from "react";
import { Container } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import PageHeading from "components/page-heading/PageHeading";
import BreadCrumbSection from "components/custom-breadcrumbs/BreadCrumbSection";

const PaymentHistory = () => {
  const location: any = useLocation();
  const [searchKey, setSearchKey] = useState<string>("");
  const [shouldClearSearchKey, setShouldClearSearchKey] = useState(false);
  const breadcrumbRoutes = {
    [RouteConstants.paymentHistory]: `Milestone History`,
  };

  const handleSearchChange = (searchKey: string) => {
    setShouldClearSearchKey(false);
    setSearchKey(searchKey);
  };

  return (
    <Fragment>
      <PageHeading title="Payment Form Management" />
      <BreadCrumbSection
        breadcrumbNameMap={breadcrumbRoutes}
        shouldSearchBarAppear={
          RouteConstants.paymentHistory === location.pathname 
        }
        shouldClearSearchKey={shouldClearSearchKey}
        searchPlaceholder="Search History"
        handleSearchOnKeyPress={handleSearchChange}
      />
      <Container>
        <Outlet
          context={{ searchKey, setSearchKey, setShouldClearSearchKey }}
        />
      </Container>
    </Fragment>
  );
};

export default PaymentHistory;
