export interface IPage5ProfileInitialValue {
  isPublished: boolean;
  pageType: string;
  pageName: string;
  isTermsActive: boolean;
  isAboutUsActive: boolean;
  isSecurityTipsActive: boolean;

  securityTips: {
    sectionHeading: string;
    detailedInformation: {
      media: {
        key: string;
        name: string;
        value: {
          url: string;
          width: number;
          height: number;
          mediaType: string;
          size: number;
        };
        isActive: boolean;
        mediaDescription: string;
      };
      description: string;
    }[];
  };
  // faqSectionHeading: string;
  termsAndConditions: {
    displayName: string;
    description: string;
  };
  aboutUs: {
    isAboutHoablActive: boolean;
    isFoundersVisionActive: boolean;
    isProductCategoryActive: boolean;
    isCorporatePhilosophyActive: boolean;
    isStatsOverviewActive: boolean;
    // ------------------------------------"Founder's vision"------------------------------------------
    foundersVision: {
      sectionHeading: string;
      founderName: string;
      media: {
        key: string;
        name: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      description: string;
    };
    // -----------------------------------aboutHoabl-------------------------------------------------------
    aboutHoabl: {
      sectionHeading: string;
      description: string;
    };
    // -----------------------------------------"Corporate Philosophy"--------------------------------------
    corporatePhilosophy: {
      sectionHeading: string;
      detailedInformation: [
        {
          displayName: string;
          media: {
            key: string;
            name: string;
            value: {
              url: string;
              size: number;
              width: number;
              height: number;
              mediaType: string;
            };
          };
          description: string;
        },
        {
          displayName: string;
          media: {
            key: string;
            name: string;
            value: {
              url: string;
              size: number;
              width: number;
              height: number;
              mediaType: string;
            };
          };
          description: string;
        }
      ];
    };
    // -----------------------------------------------"Product Category"-----------------------------------
    productCategory: {
      sectionHeading: string;
      detailedInformation: [
        {
          displayName: string;
          media: {
            key: string;
            name: string;
            value: {
              url: string;
              size: number;
              width: number;
              height: number;
              mediaType: string;
            };
          };
          description: string;
        },
        {
          displayName: string;
          media: {
            key: string;
            name: string;
            value: {
              url: string;
              size: number;
              width: number;
              height: number;
              mediaType: string;
            };
          };
          description: string;
        }
      ];
    };
    // ------------------------------"Stats Overview"-----------------------------------------
    statsOverview: {
      sectionHeading: string;
      detailedInformation: [
        {
          title: string;
          value: string;
          description: string;
        },
        {
          title: string;
          value: string;
          description: string;
        }
      ];
    };
  };
}

const page5ProfileInitialValue: IPage5ProfileInitialValue = {
  isPublished: true,
  pageType: "5005",
  pageName: "Profile",
  isTermsActive: true,
  isAboutUsActive: true,
  isSecurityTipsActive: true,
  securityTips: {
    sectionHeading: "",
    detailedInformation: [
      {
        media: {
          key: "",
          name: "",
          value: {
            url: "",
            size: 0,
            width: 320,
            height: 240,
            mediaType: "image",
          },
          isActive: true,
          mediaDescription: "",
        },
        description: "",
      },
      {
        media: {
          key: "",
          name: "",
          value: {
            url: "",
            size: 0,
            width: 320,
            height: 240,
            mediaType: "image",
          },
          isActive: true,
          mediaDescription: "",
        },
        description: "",
      },
      {
        media: {
          key: "",
          name: "",
          value: {
            url: "",
            size: 0,
            width: 320,
            height: 240,
            mediaType: "image",
          },
          isActive: true,
          mediaDescription: "",
        },
        description: "",
      },
    ],
  },
  //----------------------------------------------FAQ---------------------------------
  // faqSectionHeading: "",
  // -----------------------------------------termsAndConditions----------------------------------
  termsAndConditions: {
    displayName: "",
    description: "",
  },
  //--------------------------------------------aboutHoabl---------------------------------------
  aboutUs: {
    isAboutHoablActive: true,
    isFoundersVisionActive: true,
    isProductCategoryActive: true,
    isCorporatePhilosophyActive: true,
    isStatsOverviewActive: true,
    foundersVision: {
      sectionHeading: "",
      founderName: "",
      media: {
        key: "",
        name: "",
        value: {
          url: "",
          size: 0,
          width: 320,
          height: 240,
          mediaType: "image",
        },
      },
      description: "",
    },
    aboutHoabl: {
      sectionHeading: "",
      description: "",
    },
    corporatePhilosophy: {
      sectionHeading: "",
      detailedInformation: [
        {
          displayName: "",
          media: {
            key: "",
            name: "",
            value: {
              url: "",
              size: 0,
              width: 320,
              height: 240,
              mediaType: "image",
            },
          },
          description: "",
        },
        {
          displayName: "",
          media: {
            key: "",
            name: "",
            value: {
              url: "",
              size: 0,
              width: 320,
              height: 240,
              mediaType: "image",
            },
          },
          description: "",
        },
      ],
    },
    productCategory: {
      sectionHeading: "",
      detailedInformation: [
        {
          displayName: "",
          media: {
            key: "",
            name: "",
            value: {
              url: "",
              size: 0,
              width: 320,
              height: 240,
              mediaType: "image",
            },
          },
          description: "",
        },
        {
          displayName: "",
          media: {
            key: "",
            name: "",
            value: {
              url: "",
              size: 0,
              width: 320,
              height: 240,
              mediaType: "image",
            },
          },
          description: "",
        },
      ],
    },
    //---------------------------------Stats Overview-----------------------
    statsOverview: {
      sectionHeading: "",
      detailedInformation: [
        {
          title: "",
          value: "",
          description: "",
        },
        {
          title: "",
          value: "",
          description: "",
        },
      ],
    },
  },
};
export const page5ProfileLabels: any = {
  //--------------------------------------------------------------------------
  isSecurityTipsActive: "Security Tips Toggle",
  securityTips: {
    sectionHeading: "Security Tips Section Heading ",
    detailedInformation: [
      {
        media: {
          value: {
            url: "Detailed Information Upload Media",
          },
        },
        description: "Detailed Information Description",
      },
    ],
  },
  //----------------------------------------------FAQ---------------------------------
  // faqSectionHeading: "",
  // -----------------------------------------termsAndConditions----------------------------------
  isTermsActive: "Terms & Conditions Toggle",
  termsAndConditions: {
    displayName: "Terms & Conditions Section Heading*",
    description: "Terms & Conditions Description",
  },
  //--------------------------------------------aboutHoabl---------------------------------------
  aboutUs: {
    isAboutHoablActive: "About Hoabl Toggle",
    aboutHoabl: {
      sectionHeading: "About Us Section Heading",
      description: "About Us Description *",
    },

    isFoundersVisionActive: "Founder's Vision Toggle",
    foundersVision: {
      sectionHeading: "Founder's Vision Section Heading",
      founderName: "Founder's Vision Founder Name*",
      media: {
        value: {
          url: "Founder's Vision Upload Image",
        },
      },
      description: "Founder's Vision Description",
    },

    isCorporatePhilosophyActive: "Corporate Philosophy Toggle",
    corporatePhilosophy: {
      sectionHeading: "Corporate Philosophy Section Heading",
      detailedInformation: [
        {
          displayName: "Corporate Philosophy DisplayName",
          media: {
            value: {
              url: "Corporate Philosophy Upload Icon",
            },
          },
          description: "Corporate Philosophy Description",
        },
      ],
    },

    isProductCategoryActive: "Product Category Toggle",
    productCategory: {
      sectionHeading: "Product Category Section Heading",
      detailedInformation: [
        {
          displayName: "Product Category Display Name",
          media: {
            value: {
              url: "Product Category Upload Icon",
            },
          },
          description: "Product Category Description",
        },
      ],
    },
    //---------------------------------Stats Overview-----------------------
    isStatsOverviewActive: "Stats Overview Toggle",
    statsOverview: {
      sectionHeading: "Stats Overview Section Heading",
      detailedInformation: [
        {
          title: "Stats Overview Title",
          value: "Stats Overview Value",
          description: "Stats Overview Description",
        },
      ],
    },
  },
};

export { page5ProfileInitialValue };
