import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { FormFieldContainer } from "components/ui-elements";
import Grid from "@mui/material/Grid";
import PageHeading from "../../../components/page-heading/PageHeading";
import { RouteConstants } from "../../../routes/RouteConstants";
import styles from "./PromiseManagement.module.css";
import { Typography } from "@mui/material";
import PromiseActions from "../../../redux-container/promises/PromiseRedux";
import { useNavigate } from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { StatusTypeEnum } from "enumerations/StatusTypeEnum";

var Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

const actionDispatch = (dispatch: any) => {
  return {
    getPromiseDashboard: () =>
      dispatch(PromiseActions.getPromiseDashboardRequest()),
    setPromiseDashboardState: (key: any, data: any) =>
      dispatch(PromiseActions.setPromiseDashboardState(key, data)),
  };
};

const PromiseManagementDashboard = () => {
  const { getPromiseDashboard, setPromiseDashboardState } = actionDispatch(
    useDispatch()
  );

  const navigate = useNavigate();

  const promiseDashboard = useSelector(
    (state: any) => state?.promiseDashboard.promiseDashboardData
  );
  const isGraphDataFetched = useSelector(
    (state: any) => state?.promiseDashboard?.isGraphDataFetched
  );

  const breadcrumbRoutes: any = [
    {
      name: "Promise Management",
      route: RouteConstants.promiseManagementDashboard,
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (isGraphDataFetched) {
      setPromiseDashboardState("isGraphDataFetched", false);
      configurePromiseChart();
    }
  }, [isGraphDataFetched]);

  const loadData = () => {
    getPromiseDashboard();
  };

  const navigateToPromises = () => {
    navigate(RouteConstants.promiseList ,{ state: { isNavigationFromSidemenu: true } });
  };

  const filterByPromiseStatus = (statusType: StatusTypeEnum) => {
    const filterData = {
      statusType: statusType,
      isNavigationFromSidemenu: true
    };
    navigate(`${RouteConstants.promiseList}`, {
      state: filterData,
    });
  };

  const configurePromiseChart = () => {
    Highcharts.chart("promise-container", {
      chart: {
        backgroundColor: "#f7f6ff",
        plotBackgroundColor: "#f7f6ff",
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          slicedOffset: 0,
          dataLabels: {
            enabled: true,
            style: {
              fontWeight: "bold",
              color: "black",
              fontSize: "13px",
              align: "left",
            },
            filter: {
              property: "name",
              operator: "===",
              value: "Promises",
            },
          },
          borderWidth: 0,
        },
        series: {
          animation: true,
        },
      },
      series: [
        {
          type: "pie",
          name: "Total",
          innerSize: "80%",
          data: [
            {
              name: "Active Promises",
              y: promiseDashboard?.activePromisesCount,
              color: "#54b652",
              sliced: true,
            },
            {
              name: "InActive Promises",
              y: promiseDashboard?.inactivePromisesCount,
              color: "#f37c7c",
              sliced: true,
            },
          ],
        },
      ],
    });
  };

  return (
    <Fragment>
      <PageHeading title="Promise Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

      <Container>
        <Grid className="heading-action-holder">
          <Typography className="heading">Dashboard</Typography>
          <Button
            variant="contained"
            onClick={navigateToPromises}
            // autoFocus
            endIcon={<ChevronRightRoundedIcon />}
            disableElevation
            className="btn btn-dark"
          >
            Go To Promise
          </Button>
        </Grid>

        <FormFieldContainer>
          <Grid className="graph-data-container">
            <Typography className="graph-heading">Promise Overview</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Grid id="promise-container" className="graph-holder" />
              </Grid>

              <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    className={styles["total-stats-holder"]}
                    onClick={navigateToPromises}
                  >
                    <span className="stat-card-total-value">
                      {promiseDashboard?.totalPromisesCount}
                    </span>
                    <span className="stat-card-total-heading">
                      Total Published Promises
                    </span>
                    <span className={styles["stat-card-total-icon"]}>
                      <ChevronRightRoundedIcon />
                    </span>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      className={styles["individual-stats-holder"]}
                      onClick={() =>
                        filterByPromiseStatus(StatusTypeEnum.Inactive)
                      }
                    >
                      <span className="individual-stat-value-text red-stat-value">
                        {promiseDashboard?.inactivePromisesCount}
                      </span>
                      <span className="individual-stat-card-heading">
                        Inactive Promises
                      </span>
                      <span className={styles["individual-stat-card-icon"]}>
                        <ChevronRightRoundedIcon />
                      </span>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      className={styles["individual-stats-holder"]}
                      onClick={() =>
                        filterByPromiseStatus(StatusTypeEnum.Active)
                      }
                    >
                      <span className="individual-stat-value-text green-stat-value">
                        {promiseDashboard?.activePromisesCount}
                      </span>
                      <span className="individual-stat-card-heading">
                        Active Promises
                      </span>
                      <span className={styles["individual-stat-card-icon"]}>
                        <ChevronRightRoundedIcon />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormFieldContainer>
      </Container>
    </Fragment>
  );
};

export default PromiseManagementDashboard;
