import {
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
  ButtonComponent,
} from "components/ui-elements";
import {
  Avatar,
  Box,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  styled,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import MediaAssets from "assets";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { useFormikContext, getIn } from "formik";
import { ChangeEvent, useEffect } from "react";
import { useLocation } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
const TestimonialsContainer = styled(FormFieldContainer)`
  padding: 0;
`;

const Testimonials = ({ isEditMode }: any) => {
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  const location: any = useLocation();
  const testimonials = useSelector(
    (state: any) => state?.testimonials.testimonials
  );

  const handleTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${event.target.name}`, event.target.value);
  };

  useEffect(() => {
    if (values?.totalTestimonialsOnListView >= testimonials?.length) {
      setFieldValue("totalTestimonialsOnListView", testimonials?.length);
    }

    if (values?.totalTestimonialsOnHomeScreen >= testimonials?.length) {
      setFieldValue("totalTestimonialsOnHomeScreen", testimonials?.length);
    }
  }, [testimonials?.length]);

  useEffect(() => {
    if (
      values?.totalTestimonialsOnHomeScreen >
      values?.totalTestimonialsOnListView
    ) {
      setFieldValue(
        "totalTestimonialsOnListView",
        values?.totalTestimonialsOnHomeScreen
      );
    }
  }, [
    values?.totalTestimonialsOnListView,
    values?.totalTestimonialsOnHomeScreen,
  ]);
  
  return (
    <TestimonialsContainer>
      <Grid container p={"1rem"}>
        <Grid item xs={6}>
          <Stack columnGap={2} justifyContent={"flex-start"}>
            <Typography className="accordion-heading" margin={"0.5rem 0"}>
              Testimonial
            </Typography>
            <SwitchComponent
              name="isTestimonialsActive"
              checked={values?.isTestimonialsActive}
              onChange={handleChange}
              disabled={location?.state?.isViewMode && isEditMode}
            />
            <Tooltip
              title={"This represent the ticker on Customer App"}
              arrow
              placement="top-start"
            >
              <InfoOutlinedIcon
                fontSize="small"
                className="infooutlined-alignment"
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Grid container columnSpacing={4} rowSpacing={2} p={"1rem"}>
        <Grid item xs={5} marginRight={"1rem"}>
          <TextFieldComponent
            inputProps={{ maxLength: 100 }}
            label="Section Heading"
            name={"testimonialsHeading"}
            value={values?.testimonialsHeading}
            onChange={handleTextInput}
            onBlur={handleBlur}
            required
            fieldhelpertext={
              getIn(touched, "testimonialsHeading") &&
              getIn(errors, "testimonialsHeading")
            }
            error={Boolean(
              getIn(errors, "testimonialsHeading") &&
                getIn(touched, "testimonialsHeading")
            )}
            disabled={location?.state?.isViewMode && isEditMode}
          />
        </Grid>
        <Grid item xs={5} marginRight={"1rem"}>
          <TextFieldComponent
            inputProps={{ maxLength: 100 }}
            label="Sub Heading"
            required
            name={"testimonialsSubHeading"}
            value={values?.testimonialsSubHeading}
            onBlur={handleBlur}
            onChange={handleTextInput}
            fieldhelpertext={
              getIn(touched, "testimonialsSubHeading") &&
              getIn(errors, "testimonialsSubHeading")
            }
            error={Boolean(
              getIn(errors, "testimonialsSubHeading") &&
                getIn(touched, "testimonialsSubHeading")
            )}
            disabled={location?.state?.isViewMode && isEditMode}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container rowSpacing={2} p={"1rem"}>
        <Grid item lg={4} xs={4}>
          <Stack justifyContent="flex-start" spacing={1}>
            <Typography mb={"15px"}>Total Testmonial on List View</Typography>
            <PrioritySelector
              priority={values?.totalTestimonialsOnListView}
              disableDecrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalTestimonialsOnListView <= 1 ||
                values?.totalTestimonialsOnListView <=
                  values?.totalTestimonialsOnHomeScreen
              }
              disableIncrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalTestimonialsOnListView === testimonials?.length
              }
              decrementpriority={() => {
                values?.totalTestimonialsOnListView > 0
                  ? setFieldValue(
                      "totalTestimonialsOnListView",
                      values?.totalTestimonialsOnListView - 1
                    )
                  : alert("no less then 0 ");
              }}
              incrementpriority={() => {
                values?.totalTestimonialsOnListView <=
                (testimonials?.length - 1 >= 20 ? 20 : testimonials?.length - 1)
                  ? setFieldValue(
                      "totalTestimonialsOnListView",
                      values?.totalTestimonialsOnListView + 1
                    )
                  : alert(
                      `no more then ${
                        testimonials?.length - 1 >= 20
                          ? 20
                          : testimonials?.length
                      }`
                    );
              }}
              isCounter={true}
            />
          </Stack>
        </Grid>
        <Grid item lg={4} xs={5}>
          <Stack justifyContent="flex-start" spacing={1}>
            <Typography mb={"15px"}>
              Total Testmonial on the home screen?
            </Typography>
            <PrioritySelector
              priority={values?.totalTestimonialsOnHomeScreen}
              disableDecrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalTestimonialsOnHomeScreen <= 1
              }
              disableIncrement={
                (location?.state?.isViewMode && isEditMode) ||
                values?.totalTestimonialsOnHomeScreen === testimonials?.length
              }
              decrementpriority={() => {
                values?.totalTestimonialsOnHomeScreen > 0
                  ? setFieldValue(
                      "totalTestimonialsOnHomeScreen",
                      values?.totalTestimonialsOnHomeScreen - 1
                    )
                  : alert("no less then 0 ");
              }}
              incrementpriority={() => {
                values?.totalTestimonialsOnHomeScreen <=
                (testimonials?.length - 1 >= 20 ? 20 : testimonials?.length - 1)
                  ? setFieldValue(
                      "totalTestimonialsOnHomeScreen",
                      values?.totalTestimonialsOnHomeScreen + 1
                    )
                  : alert(
                      `no more then ${
                        testimonials?.length - 1 >= 20
                          ? 20
                          : testimonials?.length
                      }`
                    );
              }}
              isCounter={true}
            />
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Box p="1rem" textAlign="right">
        {/* <ButtonComponent variant="contained">Save</ButtonComponent> */}
      </Box>
    </TestimonialsContainer>
  );
};

export default Testimonials;
