import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  ButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import {
  IProjectsInvestedUser,
  projectInvestedUserInitialValues,
} from "./ProjectsInvestedUserInitialValues";

import styles from "./ProjectsInvestedUser.module.css";
import { Fragment, Key } from "react";
import { FieldArray, getIn, useFormikContext } from "formik";
import {
  ActionEnumsUtils,
  ActionsEnums,
} from "enumerations/ActionsTypesForInvestedNewUser";
import {
  TopicEnumsUtils,
  TopicEnums,
} from "enumerations/TopicEnumsForInvestedNewUser";

function Categories(props: any) {
  const {
    values,
    setFieldValue,
    errors,
    handleChange,
    handleBlur,
    touched,
    isValid,
    dirty,
  } = useFormikContext<IProjectsInvestedUser>();

  const actions = ActionEnumsUtils.getActionsEnums();
  const topics = TopicEnumsUtils.getTopicEnums();
  const {
    isViewMode,
    setPreviousValues,
    previousValues,
    setIsCategoryDeleted,
    setModulesAudited,
    modulesAudited,
    categoryCount,
  } = props;

  const actionTypesForTopic = 1;
  const actionTypesForNavigation = 2;
  const actionTypesForOthers = 3;
  const handleCategories = () => {
    let categoryArray = previousValues?.categories;
    // array.splice(array.length, 0, "🐴");
    categoryArray.splice(categoryArray.length, 0, {
      text: "",
      action: null,
      actionType: 0,
      optionNumber: 0,
      message: "",
      options: [
        {
          text: "",
          action: null,
          actionType: 0,
          optionNumber: 0,
        },
        {
          text: "",
          action: null,
          actionType: 0,
          optionNumber: 0,
        },
      ],
      linkedOption: 0,
    });
    setPreviousValues({ ...previousValues, categories: categoryArray });
  };
  return (
    <>
      <Box padding={"1.5rem"}>
        <Typography variant="body1">{`Categories`}</Typography>
      </Box>
      <Divider />
      <FieldArray
        name="categories"
        render={(arrayHelpers) => {
          const { push: formikPush, remove: formikRemove, form } = arrayHelpers;
          const { values, errors, touched } = form;

          return (
            <>
              {values?.categories.map((category: any, catergoryIndex: any) => (
                <Fragment key={category.id}>
                  <Stack className={styles["field-box"]}>
                    <Typography>Category {catergoryIndex + 1}</Typography>
                    {values?.categories?.length > 1 ? (
                      <IconButton
                        aria-label="delete"
                        data-testid="button-delete"
                        onClick={async () => {
                          setIsCategoryDeleted(true);
                          formikRemove(catergoryIndex);
                          if (categoryCount > values?.categories?.length) {
                            setModulesAudited((prev: any) => [
                              ...prev,
                              {
                                itemAffected: `Category ${
                                  catergoryIndex + 1
                                } Deleted`,
                                newValue: "",
                                oldValue: "",
                              },
                            ]);
                          }

                          let categoryArray = previousValues?.categories;
                          categoryArray.splice(catergoryIndex);

                          setPreviousValues({
                            ...previousValues,
                            categories: categoryArray,
                          });
                        }}
                      >
                        <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                      </IconButton>
                    ) : null}
                  </Stack>
                  <Grid
                    container
                    spacing={3}
                    className={styles["section-gutter"]}
                  >
                    <Grid item lg={3} xl={3}>
                      <TextFieldComponent
                        label={"Display Name"}
                        name={`categories[${catergoryIndex}].text`}
                        value={values?.categories[catergoryIndex]?.text}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 100,
                        }}
                        disabled={isViewMode}
                        required
                        fieldhelpertext={
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].text`
                          ) &&
                          getIn(errors, `categories[${catergoryIndex}].text`)
                        }
                        error={Boolean(
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].text`
                          ) &&
                            getIn(errors, `categories[${catergoryIndex}].text`)
                        )}
                      />
                    </Grid>
                    <Grid item lg={3} xl={3}>
                      <TextFieldComponent
                        select
                        label={"Topics"}
                        name={`categories[${catergoryIndex}].action`}
                        value={values?.categories[catergoryIndex]?.action}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(
                            `categories.${catergoryIndex}.actionType`,
                            actionTypesForTopic
                          );
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        disabled={isViewMode}
                        required
                        onBlur={handleBlur}
                        fieldhelpertext={
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].action`
                          ) &&
                          getIn(errors, `categories[${catergoryIndex}].action`)
                        }
                        error={Boolean(
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].action`
                          ) &&
                            getIn(
                              errors,
                              `categories[${catergoryIndex}].action`
                            )
                        )}
                      >
                        {topics?.map((topic: any) => {
                          return (
                            <MenuItem value={topic?.value} key={topic?.value}>
                              {topic.label}
                            </MenuItem>
                          );
                        })}
                      </TextFieldComponent>
                    </Grid>
                    <Grid item lg={11} xl={11}>
                      <TextFieldComponent
                        label={"Message body"}
                        name={`categories[${catergoryIndex}].message`}
                        value={values?.categories[catergoryIndex]?.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 100,
                        }}
                        disabled={isViewMode}
                        required
                        fieldhelpertext={
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].message`
                          ) &&
                          getIn(errors, `categories[${catergoryIndex}].message`)
                        }
                        error={Boolean(
                          getIn(
                            touched,
                            `categories[${catergoryIndex}].message`
                          ) &&
                            getIn(
                              errors,
                              `categories[${catergoryIndex}].message`
                            )
                        )}
                      />
                    </Grid>
                    <Grid item lg={6} xl={6}>
                      <Grid container spacing={4}>
                        {category?.options.map((action: any, index: any) => (
                          <Fragment key={action.id}>
                            <Grid item lg={6} xl={6}>
                              <TextFieldComponent
                                label={`Option ${index + 1}`}
                                name={`categories[${catergoryIndex}].options[${index}].text`}
                                value={
                                  values?.categories[catergoryIndex]?.options[
                                    index
                                  ]?.text
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{
                                  maxLength: 100,
                                }}
                                disabled={isViewMode}
                                required
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `categories[${catergoryIndex}].options[${index}].text`
                                  ) &&
                                  getIn(
                                    errors,
                                    `categories[${catergoryIndex}].options[${index}].text`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    `categories[${catergoryIndex}].options[${index}].text`
                                  ) &&
                                    getIn(
                                      errors,
                                      `categories[${catergoryIndex}].options[${index}].text`
                                    )
                                )}
                              />
                            </Grid>
                            <Grid item lg={6} xl={6}>
                              <TextFieldComponent
                                select
                                label={`Actions `}
                                name={`categories[${catergoryIndex}].options[${index}].action`}
                                value={
                                  values?.categories[catergoryIndex]?.options[
                                    index
                                  ]?.action
                                }
                                // Generally we are useing Formik's "handleChange","handleBlur" functions to all the text-fields But for all Select-Fields
                                // we use our manual handle change.
                                //since this is Select-field so we manually setting value using formik's function "setFieldValue" .
                                onChange={(e: any) => {
                                  handleChange(e);
                                  setFieldValue(
                                    `categories.${catergoryIndex}.options.${index}.optionNumber`,
                                    0
                                  );
                                  if (
                                    e.target.value === ActionsEnums.AllowTyping
                                  ) {
                                    setFieldValue(
                                      `categories.${catergoryIndex}.options.${index}.actionType`,
                                      actionTypesForOthers
                                    );
                                    return;
                                  }
                                  setFieldValue(
                                    `categories.${catergoryIndex}.options.${index}.actionType`,
                                    actionTypesForNavigation
                                  );
                                }}
                                disabled={isViewMode}
                                required
                                onBlur={handleBlur}
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `categories[${catergoryIndex}].options[${index}].action`
                                  ) &&
                                  getIn(
                                    errors,
                                    `categories[${catergoryIndex}].options[${index}].action`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    `categories[${catergoryIndex}].options[${index}].action`
                                  ) &&
                                    getIn(
                                      errors,
                                      `categories[${catergoryIndex}].options[${index}].action`
                                    )
                                )}
                              >
                                {actions.map((action: any) => (
                                  <MenuItem key={action.c} value={action.value}>
                                    {action.label}
                                  </MenuItem>
                                ))}
                              </TextFieldComponent>
                            </Grid>
                          </Fragment>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* {catergoryIndex !== categories.length - 1 && <Divider />} */}
                  <Divider />
                  <Stack padding={"10px"}>
                    {catergoryIndex === values?.categories?.length - 1 &&
                    values?.categories?.length < 6 ? (
                      <ButtonComponent
                        variant="text"
                        onClick={() => {
                          formikPush(
                            projectInvestedUserInitialValues.categories[0]
                          );
                          handleCategories();
                          if (categoryCount < values?.categories?.length + 1) {
                            setModulesAudited((prev: any) => [
                              ...prev,
                              {
                                itemAffected: `Category ${
                                  catergoryIndex + 1 + 1
                                } Added`,
                                newValue: "",
                                oldValue: "",
                              },
                            ]);
                          }
                        }}
                      >
                        +add more
                      </ButtonComponent>
                    ) : null}
                  </Stack>
                </Fragment>
              ))}
            </>
          );
        }}
      />
    </>
  );
}

export default Categories;
