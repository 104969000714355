import React, { useEffect, useState } from "react";
import PdfUpload from "components/ui-elements/pdf-upload/PdfUpload";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { useDispatch, useSelector } from "react-redux";
import MediaAssets from "assets";
import styles from "./CustomerGuidelines.module.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const actionDispatch = (dispatch: any) => {
  return {
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
  };
};

const CustomerGuidelines = () => {
  const { values, setFieldValue } =
    useFormikContext<IInitinalValueProjectContent>();

  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const { fileUploadRequest } = actionDispatch(useDispatch());
  const [uploadUrl, setUploadUrl] = useState<boolean>(false);

  const handleImageUpload = (event: any) => {
    setUploadUrl(true);
    const file = event.target.files[0];

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);

      setFieldValue(`customerGuideLines.key`, file?.name);
      setFieldValue(`customerGuideLines.name`, file?.name);
    }
  };

  const { imageDetails, isLoading } = useSelector(
    (state: any) => state.fileUpload
  );

  useEffect(() => {
    if (imageDetails && uploadUrl) {
      setFieldValue(`customerGuideLines.value.url`, imageDetails.url);
      setUploadUrl(false);
    }
  }, [imageDetails]); 

  const handleDeletePdfData = () => {
    setFieldValue(`customerGuideLines.value.url`, "");
    setFieldValue(`customerGuideLines.key`, "");
    setFieldValue(`customerGuideLines.name`, "");
  };

  return (
    <Box mt={3} sx={{ width: "350px", margin: "30px 30px" }}>
      {values?.customerGuideLines?.value?.url ? (
        <Stack spacing={2}>
          <Box className={styles["view-replace-wrapper"]}>
            {isLoading ? (
              <Box className={styles["loader-content"]}>
                <CircularProgress />
              </Box>
            ) : (
              <Stack>
                <Avatar
                  alt="Document"
                  src={MediaAssets.ic_google_docs}
                  variant="rounded"
                />
                <Typography>
                  {values?.customerGuideLines?.name?.length > 20
                    ? values?.customerGuideLines?.name?.slice(0, 17) + "..."
                    : values?.customerGuideLines?.name}
                </Typography>
                {!isViewMode && (
                  <Box component={"label"} htmlFor={"customerGuidelines"}>
                    <input
                      disabled={isViewMode}
                      accept={".pdf"}
                      id={"customerGuidelines"}
                      type="file"
                      name={"customerGuidelines"}
                      hidden
                      onChange={handleImageUpload}
                    />
                    <span className={styles["replace-button"]}>Replace</span>
                  </Box>
                )}
                <a
                  target="_blank"
                  href={`${values?.customerGuideLines?.value?.url}`}
                  className={styles["link-a-tag"]}
                >
                  View
                </a>
              </Stack>
            )}
          </Box>
          {!isViewMode && (
            <DeleteOutlineOutlinedIcon
              onClick={handleDeletePdfData}
              className="table-action-icon delete-icon"
            />
          )}
        </Stack>
      ) : (
        <PdfUpload
          onChange={handleImageUpload}
          disabled={isViewMode}
          name={"customer guidelines"}
          loading={isLoading}
        />
      )}
    </Box>
  );
};

export default CustomerGuidelines;
