import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import ConfirmationDialog from "../../../../components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MediaAssets from "../../../../assets";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NoDataRecord from "../../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import { RouteConstants } from "../../../../routes/RouteConstants";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SwitchComponent } from "../../../../components/ui-elements";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const NudgeCardList = () => {
  const navigate = useNavigate();

  const [selectedAction, setSelectedAction] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const breadcrumbRoutes: any = [
    {
      name: "Marketing Management",
      route: RouteConstants.marketingManagementDashboard,
    },
    {
      name: "Nudge Cards",
      route: RouteConstants.nudgeCardList,
    },
  ];

  const tableHeadings = [
    { heading: "Title" },
    { heading: "Sub Title" },
    { heading: "Created By" },
    { heading: "Created On" },
    { heading: "Modified By" },
    { heading: "Modified On" },
    { heading: "Status" },
    { heading: "Actions" },
  ];

  const nudgeCards = [
    {
      id: 1,
      title: "Update KYC",
      subtitle: "For easy access",
      createdBy: "User C",
      createdOn: "02-10-2022",
      modifiedBy: "User A",
      modifiedOn: "03-10-2022",
      status: true,
    },
  ];

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleActionChange = (event: SelectChangeEvent) => {
    setSelectedAction(event.target.value as string);
  };

  const handleTitleChange = (event: SelectChangeEvent) => {
    setSelectedTitle(event.target.value as string);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value as string);
  };

  const createNudgeCard = () => {
    navigate(RouteConstants.nudgeCardCreation);
  };

  const deleteNudgeCard = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const editNudgeCard = () => {};

  const viewNudgeCard = () => {};

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_nudge}
        altText=""
        message="You haven't created any Nudge cards yet. Create your first Nudge Card."
        btnTitle="CREATE NUDGE CARD"
        btnAction={createNudgeCard}
      />
    );
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">Nudge Cards {`(${nudgeCards.length})`}</h4>

          <div>
            <FormControl className="action-dropdown">
              <InputLabel>Actions</InputLabel>
              <Select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
                IconComponent={() => (
                  <ExpandMoreRoundedIcon className="dropdown-icon" />
                )}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={createNudgeCard}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New
            </Button>
          </div>
        </div>

        <Grid container spacing={1}>
          <Grid container spacing={2} md={8} lg={8} xl={8}>
            <Grid
              item
              md={1}
              lg={1}
              xl={1}
              className="margin-left-10 filter-heading-holder"
            >
              <span className="filter-heading">Filters:</span>
            </Grid>
            <Grid item md={2} lg={2} xl={2}>
              <FormControl className="filter-dropdown">
                <InputLabel>Title</InputLabel>
                <Select
                  value={selectedTitle}
                  label="Title"
                  onChange={handleTitleChange}
                  IconComponent={() => (
                    <ExpandMoreRoundedIcon className="dropdown-icon" />
                  )}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={2} lg={2} xl={2}>
              <FormControl className="filter-dropdown">
                <InputLabel>Type</InputLabel>
                <Select
                  value={selectedType}
                  label="Type"
                  onChange={handleTypeChange}
                  IconComponent={() => (
                    <ExpandMoreRoundedIcon className="dropdown-icon" />
                  )}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer component={Paper} className="table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header-row">
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {nudgeCards.map((card) => (
                <TableRow key={card.id} className="table-data-row">
                  <TableCell className="table-data-text">
                    {card.title}
                  </TableCell>

                  <TableCell className="table-data-text">
                    {card.subtitle}
                  </TableCell>

                  <TableCell className="table-data-text">
                    {card.createdBy}
                  </TableCell>

                  <TableCell className="table-data-text">
                    {card.createdOn}
                  </TableCell>

                  <TableCell className="table-data-text">
                    {card.modifiedBy}
                  </TableCell>

                  <TableCell className="table-data-text">
                    {card.modifiedOn}
                  </TableCell>

                  <TableCell className="table-data-text">
                    <SwitchComponent />
                  </TableCell>

                  <TableCell>
                    <Tooltip title="Edit" placement="top">
                      <IconButton aria-label="edit" onClick={editNudgeCard}>
                        <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete" placement="top">
                      <IconButton aria-label="delete" onClick={deleteNudgeCard}>
                        <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="View" placement="top">
                      <IconButton aria-label="view" onClick={viewNudgeCard}>
                        <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={nudgeCards.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <PageHeading title="Marketing Management" />

      <Container className="main-container">
        {nudgeCards.length > 0 ? renderTable() : renderNoData()}
      </Container>

      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Nudge Card?"
        content="This action will delete the Nudge Card. Are you sure you want to continue?"
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
      />
    </Fragment>
  );
};

export default NudgeCardList;
