import {
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import PageHeading from "components/page-heading/PageHeading";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  ButtonComponent,
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { FieldArray, Form, Formik } from "formik";
import styles from "./HoablInvestedUser.module.css";
import { HoablInvestedUserConstants } from "./HoablInvestedUserConstants";
import { ActionsEnums, ActionEnumsUtils } from "./ActionsEnums";
import { TopicEnumsUtils } from "./TopicEnums";
import {
  HoablInvestedInitialState,
  hoablInvestedUserLabels,
} from "./HoablInvestedInitialValues";
import chatManagementAction from "redux-container/chat-management/ChatManagementRedux";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/loader/Loader";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import HoablInvestedUserFields from "./HoablInvestedUserFields";
import { validationSchemaChatInvestedHoablUser } from "./ValidationSchemaHoablInvested";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import { stripHtml } from "utils/ValidationUtils";
import { AuditStatus } from "enumerations/AuditStatusTypes";

const breadcrumbRoutes: any = [
  {
    name: "Chat Support Management",
    route: RouteConstants.chatSupportManagementDashboard,
  },
  {
    name: "HoABL - Invested User",
    route: RouteConstants.hoablInvestedUser,
  },
];

const actionDispatch = (dispatch: any) => {
  return {
    getChatManagementData: (id: any) =>
      dispatch(chatManagementAction.getChatManagementDataRequest(id)),
    addChatManagementData: (data: any) =>
      dispatch(chatManagementAction.addChatManagementDataRequest(data)),
    updateChatManagement: (data: any) =>
      dispatch(chatManagementAction.updateChatManagementDataRequest(data)),
    setChatManagementState: (key: any, value: any) =>
      dispatch(chatManagementAction.setChatManagementState(key, value)),
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
  };
};

const HoablInvestedUser = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const topics = TopicEnumsUtils.getTopicEnums();
  const actions = ActionEnumsUtils.getActionsEnums();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const {
    getChatManagementData,
    addChatManagementData,
    updateChatManagement,
    setChatManagementState,
    rolesAndModulesRequest,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());
  const getInvestedHoablChatData = useSelector(
    (state: any) => state?.chatManagement?.chatManagementData
  );
  const error = useSelector((state: any) => state?.chatManagement?.error);
  const message = useSelector((state: any) => state?.chatManagement?.message);
  const isLoading = useSelector(
    (state: any) => state?.chatManagement?.isLoading
  );
  const isDataPresent = useSelector(
    (state: any) => state?.chatManagement?.isDataPresent
  );
  const openFlashmessage = useSelector(
    (state: any) => state?.chatManagement?.openFlashmessage
  );
  const modules = useSelector((state: any) => state?.rolesAndModules?.module);

  const [isViewMode, setIsViewMode] = useState(
    isDataPresent ? location?.state?.isViewMode : false
  );
  const [isEditMode, setIsEditMode] = useState(
    isDataPresent ? location?.state?.isEditMode : false
  );

  const [previousValues, setPreviousValues] = useState<any>();
  const [modulesAudited, setModulesAudited] = useState<any>([]);
  const [isCategoryDeleted, setIsCategoryDeleted] = useState<boolean>(false);

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);
  const [categoryCount, setCategoryCount] = useState(
    previousValues?.categories?.length
  );
  useEffect(() => {
    getChatManagementData("INVESTED_HOABL");
  }, []);
  useEffect(() => {
    setCategoryCount(getInvestedHoablChatData?.categories?.length);
  }, [getInvestedHoablChatData]);

  //set API Data in Previous Values state after getting it from "getChatManagementData" API.
  useEffect(() => {
    setPreviousValues(getInvestedHoablChatData);
  }, [getInvestedHoablChatData]);

  //API call to get roles and modules which are used in POST API call.
  useEffect(() => {
    rolesAndModulesRequest();
    return () => {};
  }, []);

  //function to publish changes made to  Hoabl Invested User's chat (Post API call) and Audit Log API call.
  const handleOnSaveHandler = (formikObjects: any) => {
    let formValues = formikObjects?.values;
    let touchedObject = formikObjects?.touched;

    let localPreviousValues: any = previousValues;
    let localCategories: any = localPreviousValues?.categories;

    if (categoryCount < formValues?.categories?.length) {
      for (
        let index = categoryCount;
        index < formValues?.categories?.length;
        index++
      ) {
        localCategories[index] = formValues?.categories[index];
      }
      localPreviousValues = {
        ...localPreviousValues,
        categories: localCategories,
      };
    }

    //Post API call
    updateChatManagement(formValues);
    setIsConfirmDialogOpen(false);
    setChatManagementState("openFlashmessage", true);

    //Audit Log Functionality.

    // if (location?.state !== null) {
    let auditObjectsArray: any[] = [];
    Object?.keys(touchedObject)?.map((key: any) => {
      //values objects
      if (typeof touchedObject[key] == "object") {
        //categories as object checking
        if (Array.isArray(touchedObject[key])) {
          //categories as array checking
          //if key is array
          touchedObject[key]?.map((element: any, index: number) => {
            //categories array looping
            if (typeof touchedObject[key][index] == "object") {
              //categories array's obejct checking
              Object?.keys(touchedObject[key][index])?.map(
                (categoryKey: any, categoryKeyIndex: number) => {
                  //categories array's object key looping
                  if (Array.isArray(touchedObject[key][index][categoryKey])) {
                    //categories array's object's(options) key is array checking
                    touchedObject[key][index][categoryKey].map(
                      (optionsObject: any, optionsKeyIndex: number) => {
                        //options array looping
                        if (
                          typeof touchedObject[key][index][categoryKey][
                            optionsKeyIndex
                          ] == "object" //1st object of options is object checking
                        ) {
                          Object?.keys(
                            touchedObject[key][index][categoryKey][
                              optionsKeyIndex //keys of options object mapping
                            ]
                          )?.map((optionsKeys: any) => {
                            //options array's object's key looping
                            if (
                              hoablInvestedUserLabels[key][0][categoryKey][0][
                                optionsKeys
                              ] !== undefined &&
                              formValues[key][index][categoryKey][
                                optionsKeyIndex
                              ][optionsKeys] !==
                                localPreviousValues[key][index][categoryKey][
                                  optionsKeyIndex
                                ][optionsKeys]
                            ) {
                              let auditObject = {
                                itemAffected: ` ${
                                  hoablInvestedUserLabels[key][0][
                                    categoryKey
                                  ][0][optionsKeys]
                                } ${optionsKeyIndex + 1} of Category ${
                                  index + 1
                                } `,
                                newValue:
                                  optionsKeys === "action"
                                    ? ActionEnumsUtils.getEnumText(
                                        formValues[key][index][categoryKey][
                                          optionsKeyIndex
                                        ][optionsKeys]
                                      )
                                    : formValues[key][index][categoryKey][
                                        optionsKeyIndex
                                      ][optionsKeys],
                                oldValue:
                                  optionsKeys === "action"
                                    ? ActionEnumsUtils.getEnumText(
                                        localPreviousValues[key][index][
                                          categoryKey
                                        ][optionsKeyIndex][optionsKeys]
                                      )
                                    : localPreviousValues[key][index][
                                        categoryKey
                                      ][optionsKeyIndex][optionsKeys],
                              };
                              auditObjectsArray.push(auditObject);
                            }
                          });
                        }
                      }
                    );
                  } else {
                    if (
                      hoablInvestedUserLabels[key][0][categoryKey] !==
                        undefined &&
                      formValues[key][index][categoryKey] !==
                        localPreviousValues[key][index][categoryKey]
                    ) {
                      let auditObject = {
                        itemAffected: `${
                          hoablInvestedUserLabels[key][0][categoryKey]
                        }  of Category ${index + 1} `,
                        newValue:
                          categoryKey === "action"
                            ? TopicEnumsUtils.getEnumText(
                                formValues[key][index][categoryKey]
                              )
                            : formValues[key][index][categoryKey],
                        oldValue:
                          categoryKey === "action"
                            ? TopicEnumsUtils.getEnumText(
                                localPreviousValues[key][index][categoryKey]
                              )
                            : localPreviousValues[key][index][categoryKey],
                      };
                      auditObjectsArray.push(auditObject);
                    }
                  }
                }
              );
            } else if (
              hoablInvestedUserLabels[key][0] !== undefined &&
              formValues[key][index] !== localPreviousValues[key][index]
            ) {
              let auditObject = {
                itemAffected: `${hoablInvestedUserLabels[key]} of Category ${
                  index + 1
                }`,
                newValue: formValues[key][index],
                oldValue: localPreviousValues[key][index],
              };
              auditObjectsArray.push(auditObject);
            }
          });
        }
      } else {
        // if key is just variable
        if (
          hoablInvestedUserLabels[key] !== undefined &&
          formValues[key] !== localPreviousValues[key]
        ) {
          let auditObject = {
            itemAffected: hoablInvestedUserLabels[key],
            newValue: stripHtml(formValues[key]),
            oldValue: stripHtml(localPreviousValues[key]),
          };
          auditObjectsArray.push(auditObject);
        }
      }
    });
    //set auditObjectsArray to a state to use it for api call ..
    // setModulesAudited(auditObjectsArray);
    // setModulesAudited([...modulesAudited, ...auditObjectsArray]);
    setModulesAudited((prev: any) => [...prev, ...auditObjectsArray]);
    // }
  };

  const handleDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleOpenSubmitConfirm = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleCloseFlashMessageDialog = (values: any) => {
    setChatManagementState("openFlashmessage", false);
    !error && navigate(RouteConstants.chatSupportManagementDashboard);
    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Chat Support Management";
    });

    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: location.pathname,
      auditedObject: values,
      // auditedObject:chatManagementData,
      modulesAudited: modulesAudited,
      status: AuditStatus.EDITED,
    };
    !error && postAuditLogRequest(postAuditRequestbody);
  };

  const checkDisableState = (dirty: any) => {
    if (isCategoryDeleted) {
      return !isCategoryDeleted;
    } else {
      return !dirty;
    }
  };

  return (
    <Fragment>
      <PageHeading title="Chat Support Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      {isLoading ? (
        <Loader />
      ) : (
        <Container className="main-container">
          <Formik
            initialValues={
              isDataPresent
                ? getInvestedHoablChatData
                : HoablInvestedInitialState
            }
            onSubmit={handleOpenSubmitConfirm}
            validationSchema={validationSchemaChatInvestedHoablUser}
          >
            {({ values, isValid, dirty, errors, handleChange, touched }) => (
              <Form noValidate>
                <Stack>
                  <Stack textAlign={"start"}>
                    <IconButton edge={"start"} onClick={() => navigate(-1)}>
                      <ChevronLeftIcon />
                    </IconButton>
                    <Typography variant="h5">HoABL - Invested User</Typography>
                  </Stack>
                  <Stack columnGap={2}>
                    {!isViewMode ? (
                      <>
                        <ButtonComponent onClick={() => navigate(-1)}>
                          Cancel
                        </ButtonComponent>
                        {/* <ButtonComponent>Save as Drafts</ButtonComponent> */}
                        <LoadingButtonComponent
                          type="submit"
                          disabled={!isValid || checkDisableState(dirty)}
                        >
                          Publish
                        </LoadingButtonComponent>
                      </>
                    ) : (
                      <LoadingButtonComponent
                        type="button"
                        onClick={() => {
                          setIsViewMode(false);
                          setIsEditMode(true);
                        }}
                      >
                        Edit
                      </LoadingButtonComponent>
                    )}
                  </Stack>
                </Stack>

                <HoablInvestedUserFields
                  isViewMode={isViewMode}
                  setPreviousValues={setPreviousValues}
                  previousValues={previousValues}
                  modulesAudited={modulesAudited}
                  setModulesAudited={setModulesAudited}
                  categoryCount={categoryCount}
                  setIsCategoryDeleted={setIsCategoryDeleted}
                />

                <ConfirmationDialog
                  shouldOpen={isConfirmDialogOpen}
                  content="Are you sure you want to Publish?"
                  okText="Publish"
                  okHandler={() => handleOnSaveHandler({ values, touched })}
                  cancelHandler={handleDialogClose}
                />
                {openFlashmessage && (
                  <FlashMessageDialog
                    shouldOpen={openFlashmessage}
                    content={error ? error : message}
                    isSuccess={error ? false : true}
                    cancelHandler={() => handleCloseFlashMessageDialog(values)}
                  />
                )}
              </Form>
            )}
          </Formik>
        </Container>
      )}
    </Fragment>
  );
};

export default HoablInvestedUser;
