import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getAppUpdatesSuccess: ["response"],
  getAppUpdatesFailure: ["error"],
  getAppUpdatesRequest: ["params"],

  addAppUpdateSuccess: ["response"],
  addAppUpdateFailure: ["error"],
  addAppUpdateRequest: ["data"],

  setAppUpdatesState: ["key", "value"],
});

export const AppUpdateTypes = Types;
export const AppUpdateActions = Creators;

export const INITIAL_STATE = {
  appUpdatesList: [],
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  shouldOpenFlashDialog: false,
  isDialogOpen: false,
  isGraphDataFetched: false,
  page: 1,
  rowsPerPage: 20,
};

/* Get Notifications */
export const getAppUpdatesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getAppUpdatesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      appUpdatesList: response.data,
      isLoading: false,
      totalCount: response.totalCount,
      isGraphDataFetched: true,
    };
  }
};

export const getAppUpdatesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Add App updates */
export const addAppUpdateRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const addAppUpdateSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "Notification added successfully!",
      isLoading: false,
      isDialogOpen: true,
    };
  }
};

export const addAppUpdateFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    message: action.error.message,
    isSuccess: false,
    isDialogOpen: true,
  };
};

export const setAppUpdatesState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_APP_UPDATES_SUCCESS]: getAppUpdatesSuccess,
  [Types.GET_APP_UPDATES_FAILURE]: getAppUpdatesFailure,
  [Types.GET_APP_UPDATES_REQUEST]: getAppUpdatesRequest,

  [Types.ADD_APP_UPDATE_SUCCESS]: addAppUpdateSuccess,
  [Types.ADD_APP_UPDATE_FAILURE]: addAppUpdateFailure,
  [Types.ADD_APP_UPDATE_REQUEST]: addAppUpdateRequest,

  [Types.SET_APP_UPDATES_STATE]: setAppUpdatesState,
});
