export const enum RoleTypeEnum {
  // All = -1,
  RootAdmin = 1,
  SuperAdmin = 2,
  Admin = 3,
}

export class RoleTypeEnumUtils {
  public static getEnumText(type: RoleTypeEnum): string {
    switch (type) {
      case RoleTypeEnum.RootAdmin:
        return "Root Admin";
      case RoleTypeEnum.SuperAdmin:
        return "Super Admin";
      case RoleTypeEnum.Admin:
        return "Admin";
    }
  }

  public static getRoleTypeEnums(): {
    label: string;
    value: RoleTypeEnum;
  }[] {
    const options: { label: string; value: RoleTypeEnum }[] = [
      {
        label: this.getEnumText(RoleTypeEnum.RootAdmin),
        value: RoleTypeEnum.RootAdmin,
      },
      {
        label: this.getEnumText(RoleTypeEnum.SuperAdmin),
        value: RoleTypeEnum.SuperAdmin,
      },
      {
        label: this.getEnumText(RoleTypeEnum.Admin),
        value: RoleTypeEnum.Admin,
      },
    ];
    return options;
  }

  public static getRoleTypeEnumsForSuperAdmin(): {
    label: string;
    value: RoleTypeEnum;
  }[] {
    const options: { label: string; value: RoleTypeEnum }[] = [
      {
        label: this.getEnumText(RoleTypeEnum.SuperAdmin),
        value: RoleTypeEnum.SuperAdmin,
      },
      {
        label: this.getEnumText(RoleTypeEnum.Admin),
        value: RoleTypeEnum.Admin,
      },
    ];
    return options;
  }
}
