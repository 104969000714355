import { useEffect, useState, useRef } from "react";
import styles from "./Header.module.css";
import { useDispatch } from "react-redux";
import MediaAssets from "../../assets";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import KeyboardArrowDownRounded from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRounded from "@mui/icons-material/KeyboardArrowUpRounded";

import ConfirmationDialog from "../confirmation-dialog/ConfirmationDialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  getItemFromLocalStorage,
  getItemFromSessionStorage,
  removeItemFromSessionStorage,
  setItemToLocalStorage,
} from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import { RoleTypeEnumUtils } from "enumerations/RoleTypeEnum";
import LoginActions from "../../redux-container/login/LoginRedux";
import UserActions from "../../redux-container/users/UsersRedux";
import MyProfileDialog from "pages/user-management/user-profile/MyProfileDialog";
import UpdateNudgeCardListingDialog from "pages/page-management/page-management-dashboard/page-1-hoabl/sections/latest-updates/update-nudge-card-listing-Dialog/UpdateNudgeCardListingDialog";
import ChangePasswordComponent from "pages/user-management/change-password/ChangePassword";
import Divider from "@mui/material/Divider";
import { useIdleTimer } from "react-idle-timer";

const Header = () => {
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState<{
    confirmationDialog: boolean;
    resetPasswordDialog: boolean;
    userProfileDialog: boolean;
  }>({
    confirmationDialog: false,
    resetPasswordDialog: false,
    userProfileDialog: false,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loggedInUser, setLoggedInUser] = useState<any>({});

  const open = Boolean(anchorEl);
  const logoutTimerIdRef = useRef<any>(null);

  useEffect(() => {
    getUserDetails();
    const user = getItemFromLocalStorage(Constants.loggedInUser);
    if (user && user.admin && user.admin.isResetPasswordCompleted === false) {
      setIsDialogOpen((prev) => ({ ...prev, resetPasswordDialog: true }));
    }
  }, []);

  // auto logout after one hour of window close.
  useEffect(() => {
    const loginTime = new Date().getTime();
    const windowCloseTime = getItemFromLocalStorage(Constants.windowCloseTime);

    if (loginTime - windowCloseTime > 60 * 60 * 1000) {
      logout();
    }

    window.addEventListener("unload", handleRefresh);

    return () => {
      window.removeEventListener("unload", handleRefresh);
    };
  }, []);

  const logout = () => {
    dispatch(UserActions.logoutRequest());
    localStorage.clear();
    dispatch(LoginActions.setLoginState("isLoggedIn", false));
  };

  const handleRefresh = () => {
    setItemToLocalStorage(Constants.windowCloseTime, new Date().getTime());
  };
  useIdleTimer({
    onIdle: logout,
    timeout: 60 * 60 * 1000,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });
  // auto logout after 1 hour implemented
  // useEffect(() => {
  //   const autoLogout = () => {
  //     if (document.visibilityState === "hidden") {
  //       const timeOutId = window.setTimeout(logout, 60 * 60 * 1000);
  //       logoutTimerIdRef.current = timeOutId;
  //     } else {
  //       window.clearTimeout(logoutTimerIdRef.current);
  //     }
  //   };

  //   document.addEventListener("visibilitychange", autoLogout);

  //   return () => {
  //     document.removeEventListener("visibilitychange", autoLogout);
  //   };
  // }, []);

  const getUserDetails = () => {
    setLoggedInUser(getItemFromLocalStorage(Constants.loggedInUser));
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setIsDialogOpen((prev) => ({ ...prev, confirmationDialog: true }));
  };

  const handleResetDialogOpen = () => {
    setIsDialogOpen((prev) => ({ ...prev, resetPasswordDialog: true }));
  };

  const handleResetDialogClose = () => {
    setIsDialogOpen((prev) => ({ ...prev, resetPasswordDialog: false }));
  };

  const handleCloseLogoutDialog = () => {
    setIsDialogOpen((prev) => ({ ...prev, confirmationDialog: false }));
  };

  const handleUserProfileDialogOpen = () => {
    setIsDialogOpen({ ...isDialogOpen, userProfileDialog: true });
  };
  const handleUserProfileDialogClose = () => {
    setIsDialogOpen({ ...isDialogOpen, userProfileDialog: false });
  };

  return (
    <div>
      <nav className={styles["navbar"]}>
        <div className={styles["logo-heading-holder"]}>
          <img
            src={MediaAssets.ic_logo}
            alt="The House of Abhinandan Lodha - New Generation Land"
            className={styles.logo}
            data-testid="side-menu"
          />
          <div>
            <p>HoABL</p>
            <p>Content Management</p>
          </div>
        </div>

        <div
          onClick={handleClick}
          className={styles["userinfo-holder"]}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <ManageAccountsOutlinedIcon className={styles["userinfo-icon"]} />

          <div className={styles["name-role-holder"]}>
            <p className={styles["name-text"]}>
              {`${loggedInUser?.admin?.firstName} ${
                loggedInUser &&
                loggedInUser.admin &&
                loggedInUser.admin.lastName
                  ? loggedInUser.admin.lastName
                  : ""
              }`}
            </p>
            <p className={styles["role-text"]}>
              {RoleTypeEnumUtils.getEnumText(loggedInUser?.admin?.roleId)}
            </p>
          </div>
          {open ? (
            <KeyboardArrowUpRounded className={styles["up-down-arrow-icon"]} />
          ) : (
            <KeyboardArrowDownRounded
              className={styles["up-down-arrow-icon"]}
            />
          )}
        </div>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          hideBackdrop
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className={styles["menu-dropdown"]}
        >
          <MenuItem onClick={handleUserProfileDialogOpen}>My Profile</MenuItem>
          <MenuItem onClick={handleResetDialogOpen}>Change Password</MenuItem>
          <Divider></Divider>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </nav>

      <ConfirmationDialog
        shouldOpen={isDialogOpen.confirmationDialog}
        title="Logout of HoABL?"
        content="This action will log you out of HoABL. Are you sure you want to continue?"
        okText="Logout"
        cancelHandler={handleCloseLogoutDialog}
        okHandler={logout}
      />
      <ChangePasswordComponent
        shouldOpen={isDialogOpen.resetPasswordDialog}
        handleClose={handleResetDialogClose}
      />
      <MyProfileDialog
        shouldOpen={isDialogOpen.userProfileDialog}
        handleClose={handleUserProfileDialogClose}
      />
    </div>
  );
};

export default Header;
