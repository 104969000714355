export const enum TopicEnums {
    AboutHoabl = 102,
    Investments = 104,
    Promises = 103,
    Others = 105
  }
 
  export class TopicEnumsUtils {
    public static getEnumText(type: TopicEnums): string {
      switch (type) {
        case TopicEnums.AboutHoabl:
          return "About HoABL Enquiry";
        case TopicEnums.Investments:
          return "Investments Enquiry";
        case TopicEnums.Promises:
          return "Promises Enquiry";
        case TopicEnums.Others:
          return "Others";
      }
    }
  
    public static getTopicEnums(): {
      label: string;
      value: TopicEnums;
    }[] {
      const options: { label: string; value: TopicEnums }[] = [
        {
          label: this.getEnumText(TopicEnums.AboutHoabl),
          value: TopicEnums.AboutHoabl,
        },
        {
          label: this.getEnumText(TopicEnums.Investments),
          value: TopicEnums.Investments,
        },
        {
          label: this.getEnumText(TopicEnums.Promises),
          value: TopicEnums.Promises,
        },
        {
          label: this.getEnumText(TopicEnums.Others),
          value: TopicEnums.Others,
        },
      ];
      return options;
    }
  }
  