import { FormFieldContainer, TextFieldComponent } from "components/ui-elements";
import React, { useState } from "react";
import { Divider, styled } from "@mui/material";
import styles from "../Page3promises.module.css";

// Overring the padding for formFieldContainer
const PromiseContainer = styled(FormFieldContainer)`
  padding: 30px 0px 0px 0px;
`;

interface IPromiseProps {
  heading: string;
  promiseHeadingError: string;
  handlePromiseSectionHeadingChange: (e?: any) => any;
  handleOnBlurPromiseHeading: (e?: any) => any;
  disabledComponent: boolean;
}

const PromiseSection = ({
  heading,
  promiseHeadingError,
  handlePromiseSectionHeadingChange,
  handleOnBlurPromiseHeading,
  disabledComponent,
}: IPromiseProps) => {
  const handleSectionHeadingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handlePromiseSectionHeadingChange(event?.target);
  };

  return (
    <div>
      <PromiseContainer>
        <h4 className={styles["section-heading"]}>Promise</h4>

        <Divider></Divider>

        <div className={styles["section-content"]}>
          <TextFieldComponent
            disabled={disabledComponent}
            className=""
            fullWidth
            required
            type="text"
            label="Section Heading"
            placeholder="Enter"
            name="promiseSectionHeading"
            value={heading}
            inputProps={{ maxLength: 100 }}
            onChange={handleSectionHeadingChange}
            onBlur={handleOnBlurPromiseHeading}
            error={promiseHeadingError !== "" ? true : false}
            fieldhelpertext={promiseHeadingError}
          />
        </div>
      </PromiseContainer>
    </div>
  );
};

export default PromiseSection;
