import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";
import { state } from "utils/States";

const { Types, Creators } = createActions({//Notification
  getNotificationDashboardSuccess: ["response"],
  getNotificationDashboardFailure: ["error"],
  getNotificationDashboardRequest: ["data"],

  setNotificationDashboardState: ["key", "value"],
});

export const NotificationTypes = Types;

export default Creators;

export const INITIAL_STATE = {
    notificationDashboardData: null,
  error: null,
  isLoading: false,
  snackBarError: "",
  isGraphDataFetched: false,
};

/*  get Promise Dashboard  */
export const getNotificationDashboardRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getNotificationDashboardSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      promiseDashboardData: response.data,
      isLoading: false,
      isGraphDataFetched: true,
    };
  }

  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const getNotificationDashboardFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setNotificationDashboardState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATION_DASHBOARD_SUCCESS]: getNotificationDashboardSuccess,
  [Types.GET_NOTIFICATION_DASHBOARD_FAILURE]: getNotificationDashboardFailure,
  [Types.GET_NOTIFICATION_DASHBOARD_REQUEST]: getNotificationDashboardRequest,

  [Types.SET_NOTIFICATION_DASHBOARD_STATE]: setNotificationDashboardState,
});
//NOTIFICATION