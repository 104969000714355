import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import Page3PromiseAction from "redux-container/page-management/Page3PromiseRedux";

export function* getPage3Promise(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPageManagementData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(Page3PromiseAction.getPagePromisesSuccess(response.data));
    } else {
      yield put(Page3PromiseAction.getPagePromisesFailure(response.data));
    }
  } catch (error) {
    yield put(Page3PromiseAction.getPagePromisesFailure(error));
  }
}

export function* addPage3Promise(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addPageManagementData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(Page3PromiseAction.addPagePromisesSuccess(response.data));
    } else {
      yield put(Page3PromiseAction.addPagePromisesFailure(response.data));
    }
  } catch (error) {
    yield put(Page3PromiseAction.addPagePromisesFailure(error));
  }
}

export function* updatePage3Promise(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updatePageManagementData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(Page3PromiseAction.updatePagePromisesSuccess(response.data));
    } else {
      yield put(Page3PromiseAction.updatePagePromisesFailure(response.data));
    }
  } catch (error) {
    yield put(Page3PromiseAction.updatePagePromisesFailure(error));
  }
}
