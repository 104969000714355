import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  // getAll insightsCreation
  getAllInsightsCreationSuccess: ["response"],
  getAllInsightsCreationFailure: ["error"],
  getAllInsightsCreationRequest: ["data"],

  //get ID By Insights
  getInsightsByIdSuccess: ["response"],
  getInsightsByIdFailure: ["error"],
  getInsightsByIdRequest: ["data"],

  // create insightsCreation
  insightsCreationSuccess: ["response"],
  insightsCreationFailure: ["error"],
  insightsCreationRequest: ["data"],

  // update insightsCreation
  insightsCreationUpdateSuccess: ["response"],
  insightsCreationUpdateFailure: ["error"],
  insightsCreationUpdateRequest: ["data"],

  // delete insightsCreation
  insightsCreationDeleteSuccess: ["response"],
  insightsCreationDeleteFailure: ["error"],
  insightsCreationDeleteRequest: ["data"],

  //update status insightsCreation
  insightsCreationUpdateStatusSuccess: ["response"],
  insightsCreationUpdateStatusFailure: ["error"],
  insightsCreationUpdateStatusRequest: ["data"],

  setInsightsCreationState: ["key", "value"],
});

export const InsightsCreationTypes = Types;

export const InsightsCreationActions = Creators;

export const INITIAL_STATE = {
  data: null,
  isLoading: false,
  dataRecived: false,
  error: null,
  code: null,
  singleInsights: null,
  isVideoDelete: null,
  responseForNewAudit: null,
};

//getAllInsightsCreations
export const getAllInsightsCreationRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getAllInsightsCreationSuccess = (state: any, action: any) => {
  const response = action?.response;
  return {
    ...state,
    isLoading: false,
    data: action.response,
    error: null,
    isSuccessfulInsightsCreationCreation: true,
  };
};

export const getAllInsightsCreationFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    code: action.error.code,
    error: action.error.message,
  };
};

// single getId Insights
export const getInsightsByIdRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getInsightsByIdSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      singleInsights: response.data,
      isLoading: false,
      // totalCount: response.totalCount,
    };
  }
};

export const getInsightsByIdFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

//create Insights Creation event
export const insightsCreationRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const insightsCreationSuccess = (state: any, action: any) => {
  const response = action.response;

  return {
    ...state,
    data: action.response?.message,
    isLoading: false,
    dataRecived: true,
    error: null,
    responseForNewAudit: response?.data,
  };
};

export const insightsCreationFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    dataRecived: true,
    data: action.error?.message,
    error: action.error?.message,
  };
};

// Update Insights Creation event
export const insightsCreationUpdateRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const insightsCreationUpdateSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    dataRecived: true,
    data: action.response?.message,
    error: null,
  };
};

export const insightsCreationUpdateFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    dataRecived: true,
    data: action?.error?.message,
    error: action?.response?.message,
  };
};

//InsightsCreation Delete event

export const insightsCreationDeleteRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const insightsCreationDeleteSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    dataRecived: true,
    data: action.response?.message,
    error: null,
  };
};

export const insightsCreationDeleteFailure = (state: any, action: any) => {
  const message = action?.error?.message;
  return {
    ...state,
    isLoading: false,
    dataRecived: true,
    data: message,
    error: message,
  };
};

//update status for Insights Creation event
export const insightsCreationUpdateStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const insightsCreationUpdateStatusSuccess = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    dataRecived: true,
    data: action.response?.message,
    error: null,
  };
};

export const insightsCreationUpdateStatusFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    dataRecived: true,
    data: action?.error?.message,
    error: action?.response?.message,
  };
};

export const setInsightsCreationState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_INSIGHTS_CREATION_SUCCESS]: getAllInsightsCreationSuccess,
  [Types.GET_ALL_INSIGHTS_CREATION_FAILURE]: getAllInsightsCreationFailure,
  [Types.GET_ALL_INSIGHTS_CREATION_REQUEST]: getAllInsightsCreationRequest,

  [Types.GET_INSIGHTS_BY_ID_SUCCESS]: getInsightsByIdSuccess,
  [Types.GET_INSIGHTS_BY_ID_FAILURE]: getInsightsByIdFailure,
  [Types.GET_INSIGHTS_BY_ID_REQUEST]: getInsightsByIdRequest,

  [Types.INSIGHTS_CREATION_SUCCESS]: insightsCreationSuccess,
  [Types.INSIGHTS_CREATION_FAILURE]: insightsCreationFailure,
  [Types.INSIGHTS_CREATION_REQUEST]: insightsCreationRequest,

  [Types.INSIGHTS_CREATION_UPDATE_SUCCESS]: insightsCreationUpdateSuccess,
  [Types.INSIGHTS_CREATION_UPDATE_FAILURE]: insightsCreationUpdateFailure,
  [Types.INSIGHTS_CREATION_UPDATE_REQUEST]: insightsCreationUpdateRequest,

  [Types.INSIGHTS_CREATION_DELETE_SUCCESS]: insightsCreationDeleteSuccess,
  [Types.INSIGHTS_CREATION_DELETE_FAILURE]: insightsCreationDeleteFailure,
  [Types.INSIGHTS_CREATION_DELETE_REQUEST]: insightsCreationDeleteRequest,

  [Types.INSIGHTS_CREATION_UPDATE_STATUS_SUCCESS]:
    insightsCreationUpdateStatusSuccess,
  [Types.INSIGHTS_CREATION_UPDATE_STATUS_FAILURE]:
    insightsCreationUpdateStatusFailure,
  [Types.INSIGHTS_CREATION_UPDATE_STATUS_REQUEST]:
    insightsCreationUpdateStatusRequest,

  [Types.SET_INSIGHTS_CREATION_STATE]: setInsightsCreationState,
});
