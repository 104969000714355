import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";


// all Faqs
export function* getProjectFaqs(api: any, action: any): any {
    try {
        const response = yield call(api.getProjectFaqs, action.data);

        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ProjectFaqActions.getAllProjectFaqsSuccess(response.data));
        } else {
            yield put(ProjectFaqActions.getAllProjectFaqsFailure(response.data));
        }
    } catch (error) {
        yield put(ProjectFaqActions.getAllProjectFaqsFailure(error));
    }
}




//creation 
export function* projectFaqCreation(api: any, action: any): any {
    try {
        const response = yield call(api.createFaq, action.data);

        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ProjectFaqActions.projectFaqCreationSuccess(response.data));
        } else {
            yield put(ProjectFaqActions.projectFaqCreationFailure(response.data));
        }
    } catch (error) {
        yield put(ProjectFaqActions.projectFaqCreationFailure(error));
    }
}

//updated
export function* ProjectFaqUpdate(api: any, action: any): any {

    try {
    const response = yield call(api.updateGeneralFaq, action.id, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ProjectFaqActions.projectFaqUpdateSuccess(response.data));
        } else {
            yield put(ProjectFaqActions.projectFaqUpdateFailure(response.data));
        }
    } catch (error) {
        yield put(ProjectFaqActions.projectFaqUpdateFailure(error));
    }
}

//bulk updated
export function* projectFaqBulkUpdate(api: any, action: any): any {

    try {
        const response = yield call(api.bulkUpdateGeneralFaq, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ProjectFaqActions.projectFaqBulkUpdateSuccess(response.data));
        } else {
            yield put(ProjectFaqActions.projectFaqBulkUpdateFailure(response.data));
        }
    } catch (error) {
        yield put(ProjectFaqActions.projectFaqBulkUpdateFailure(error));
    }
}


//delete a geneal
export function* projectFaqsDelete(api: any, action: any): any {
    try {
        const response = yield call(api.deleteGeneralFaq, action.data);

        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ProjectFaqActions.projectFaqDeleteSuccess(response.data));
        } else {
            yield put(ProjectFaqActions.projectFaqDeleteFailure(response.data));
        }
    } catch (error) {
        yield put(ProjectFaqActions.projectFaqDeleteFailure(error));
    }
}


//bulk delete
export function* ProjectFaqBulkDelete(api: any, action: any): any {

    try {
        const response = yield call(api.bulkDeleteGeneralFaq, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ProjectFaqActions.projectFaqBulkDeleteSuccess(response.data));
        } else {
            yield put(ProjectFaqActions.projectFaqBulkDeleteFailure(response.data));
        }
    } catch (error) {
        yield put(ProjectFaqActions.projectFaqBulkDeleteFailure(error));
    }
}

// faq update priority

export function* projectFaqUpdatePriority(api: any, action: any): any {
    try {
        const response = yield call(api.faqPriorityUpdate, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(ProjectFaqActions.projectFaqPriorityUpdateSuccess(response.data));
        } else {
            yield put(ProjectFaqActions.projectFaqPriorityUpdateFailure(response.data));
        }
    } catch (error) {
        yield put(ProjectFaqActions.projectFaqPriorityUpdateFailure(error));
    }

}
