export const enum notificationTypeEnum {
  APP_NOTIFICATION = 5001,
  PUSH_NOTIFICATION = 5002,
  APP_NOTIFICATION_PUSH_NOTIFICATION = 5003,
}

export class PageTypeEnumUtils {
  public static getEnumText(type: notificationTypeEnum): string {
    switch (type) {
      case notificationTypeEnum.APP_NOTIFICATION:
        return "App Notification";
      case notificationTypeEnum.PUSH_NOTIFICATION:
        return "Push Notification";
      case notificationTypeEnum.APP_NOTIFICATION_PUSH_NOTIFICATION:
        return "App and Push Notification";
    }
  }

  public static getPageTypeEnums(): {
    label: string;
    value: notificationTypeEnum;
  }[] {
    const options: { label: string; value: notificationTypeEnum }[] = [
      {
        label: this.getEnumText(notificationTypeEnum.APP_NOTIFICATION),
        value: notificationTypeEnum.APP_NOTIFICATION,
      },
      {
        label: this.getEnumText(notificationTypeEnum.PUSH_NOTIFICATION),
        value: notificationTypeEnum.PUSH_NOTIFICATION,
      },
      {
        label: this.getEnumText(
          notificationTypeEnum.APP_NOTIFICATION_PUSH_NOTIFICATION
        ),
        value: notificationTypeEnum.APP_NOTIFICATION_PUSH_NOTIFICATION,
      },
    ];
    return options;
  }
}
