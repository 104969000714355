export const enum ProjectTypeEnum {
    Ongoing = "ongoing",
    Completed ="completed" ,
  }
  
  export class CustomerEnumsUtils {
    public static getEnumText(type: ProjectTypeEnum): string {
      switch (type) {
        case ProjectTypeEnum.Ongoing:
          return "Ongoing";
        case ProjectTypeEnum.Completed:
          return "Completed";
        
      }
    }
    public static getActionsEnums(): {
      label: string;
      value: ProjectTypeEnum;
    }[] {
      const options: { label: string; value: ProjectTypeEnum }[] = [
        {
          label: this.getEnumText(ProjectTypeEnum.Ongoing),
          value: ProjectTypeEnum.Ongoing,
        },
        {
          label: this.getEnumText(ProjectTypeEnum.Completed),
          value: ProjectTypeEnum.Completed,
        },
       
        
      ];
      return options;
    }
  }
  