import { Typography } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/ui-elements";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CustomerActions } from "redux-container/customer-management/CustomersListAndDetailsRedux";
import { CustomerOverviewSections } from "../CustomerViewInitialValues";


const actionDispatch = (dispatch: any) => {
  return {
    getCustomerOverview: async (customerId: any) => {
      dispatch(CustomerActions.getCustomerOverviewRequest(customerId));
    },
  };
};


const CustomerOverview = () => {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const location: any = useLocation();

  // const [customerId, setCustomerId] = React.useState(location?.state?.id);

  const { getCustomerOverview } = actionDispatch(useDispatch());

  // const { CustomerOverview } = useSelector((state: any) => {
  //   return {
  //     CustomerOverview: state?.customerListAndDetails?.customerOverview,
  //   };
  // });

  React.useEffect(() => {
    getCustomerOverview({cmsId:location?.state?.customerData?.id});
    return () => {};
  }, []);


  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <React.Fragment>
      {CustomerOverviewSections.map((content: any, index: any) => (
        <Accordion
          key={index}
          expanded={expanded === `panel-${content.id}`}
          onChange={handleAccordionChange(`panel-${content.id}`)}
        >
          <AccordionSummary
            aria-controls={`panel-${content.id}-content`}
            id={`panel-${content.id}-header`}
          >
            <Typography variant="body1">{content.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>{content.component}</AccordionDetails>
        </Accordion>
      ))}
    </React.Fragment>
  );
};

export default CustomerOverview;
