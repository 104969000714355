import { createReducer, createActions } from "reduxsauce";
import { Constants } from "constants/Constants";

type FaqStateType = typeof INITIAL_STATE;

export interface IReduxSuccessActionProps {
  response?: {
    code: number;
    data: [];
    message: string;
  };
}

export interface IReduxFailureActionProps {
  error: {
    message: string;
  };
}

const { Types, Creators } = createActions({
  faqSuccess: ["response"],
  faqFailure: ["error"],
  faqRequest: ["data"],

  deleteFaqSuccess: ["response"],
  deleteFaqFailure: ["error"],
  deleteFaqRequest: ["data"],

  updateFaqStatusSuccess: ["response"],
  updateFaqStatusFailure: ["error"],
  updateFaqStatusRequest: ["data"],

  updateFaqPrioritySuccess: ["response"],
  updateFaqPriorityFailure: ["error"],
  updateFaqPriorityRequest: ["data"],


  setFaqState: ["key", "value"],
});

export const faqTypes = Types;

export const FaqActions = Creators;


export const INITIAL_STATE = {
  faqs: [],
  dashboardData: null,
  pageSize: Constants.defaultPageSize,
  pageIndex: Constants.defaultPageIndex,
  totalPages: Constants.defaultTotalPages,
  totalCount: Constants.defaultTotalCount,
  error: null,
  isLoading: false,
  snackBarError: "",
  shouldAudit:false
};

/* Get faq */
export const faqRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const faqSuccess = (state: any, action: any) => {
  const response = action.response;
  const editedFaq = action.response.data.map((item: any) => ({
    ...item, isReadMore: false
  }
  ))


  if (response) {
    return {
      ...state,
      error: null,
      faqs: editedFaq,
      isLoading: false,
      totalCount: response.totalCount,
    };
  }
  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const faqFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};


// faq delete
export const deleteFaqRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const deleteFaqSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      faqs: response.data,
      isLoading: false,
      totalCount: response.totalCount,
      shouldAudit:true
    };
  }
  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const deleteFaqFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Update Admin Faqs status */
export const updateFaqStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateFaqStatusSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      faqs: response.data,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const updateFaqStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};




/* Update Admin Faqs status */
export const updateFaqPriorityRequest = (state: any) => {
  return {
    ...state,
    // isLoading: true,
  };
};

export const updateFaqPrioritySuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      faqs: response.data,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
    snackBarError: action.response ? action.response.message : "Failed to load",
  };
};

export const updateFaqPriorityFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};




export const setFaqState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FAQ_SUCCESS]: faqSuccess,
  [Types.FAQ_FAILURE]: faqFailure,
  [Types.FAQ_REQUEST]: faqRequest,

  [Types.DELETE_FAQ_SUCCESS]: deleteFaqSuccess,
  [Types.DELETE_FAQ_FAILURE]: deleteFaqFailure,
  [Types.DELETE_FAQ_REQUEST]: deleteFaqRequest,

  [Types.UPDATE_FAQ_STATUS_REQUEST]: updateFaqStatusRequest,
  [Types.UPDATE_FAQ_STATUS_SUCCESS]: updateFaqStatusSuccess,
  [Types.UPDATE_FAQ_STATUS_FAILURE]: updateFaqStatusFailure,

  [Types.UPDATE_FAQ_PRIORITY_REQUEST]: updateFaqPriorityRequest,
  [Types.UPDATE_FAQ_PRIORITY_SUCCESS]: updateFaqPrioritySuccess,
  [Types.UPDATE_FAQ_PRIORITY_FAILURE]: updateFaqPriorityFailure,


  [Types.SET_FAQ_STATE]: setFaqState,
});
