import GeneralInformation from "pages/project-management/project-creation/general-information/GeneralInformation";
import LandSKU from "pages/project-management/project-creation/land-sku/LandSKU";
import ProjectCoverImages from "pages/project-management/project-creation/project-cover-images/ProjectCoverImages";
import MediaGallery from "pages/project-management/project-creation/media-gallery/MediaGallery";
import OffersAndPromtions from "pages/project-management/project-creation/offers-and-promotions/OffersAndPromotions";
import OpportunityDoc from "pages/project-management/project-creation/opportunity-doc/OpportunityDoc";
import ProjectTimeline from "pages/project-management/project-creation/project-timeline/ProjectTimeline";
import SimilarInvestments from "pages/project-management/project-creation/similar-investments/SimilarInvestments";
import PromiseComponent from "pages/project-management/project-creation/promises/Promises";
import InventoryBucket from "./inventory-bucket/InventoryBucket";
import LocationInfrastruture from "./location-infrastructure/LocationInfrastructure";
import Faqs from "./faqs/Faqs";
import LatestMediaGallery from "./latest-media-gallery/LatestMediaGallery";
import OtherSectionHeadings from "./other-section-headings/OtherSectionHeadings";
import KeyPillars from "./key-pillars/KeyPillars";
import { useState } from "react";
import CustomerGuidelines from "./customer-guidelines/CustomerGuidelines";
import ReraDetails from "./rera-details/ReraDetails";
import PriceTransparency from "./price-transparency/PriceTransparency";
import ProjectNumber from "./project-number/ProjectNumber";
import ProjectIcons from "./project-icons/ProjectIcons";

interface IProjectContentSections {
  id?: number;
  title?: string;
  name?: string;
  setStatus?: any;
  component?: React.ReactNode;
}

export const ProjectContentSections = (): IProjectContentSections[] => {
  const [showAccordionId, setShowAccordionId] = useState(0);

  return [
    {
      id: 1,
      title: "General Information",
      name: "generalInformation",
      setStatus: setShowAccordionId,
      component: <GeneralInformation />,
    },
    {
      id: 15,
      title: "RERA Details",
      name: "reraDetails",
      setStatus: setShowAccordionId,
      component: showAccordionId === 15 ? <ReraDetails /> : <></>,
    },
    {
      id: 16,
      title: "Price Transparency",
      name: "priceTransparency",
      setStatus: setShowAccordionId,
      component: showAccordionId === 16 ? <PriceTransparency /> : <></>,
    },
    {
      id: 2,
      title: "Why to Invest - Opportunity Doc",
      name: "opportunityDoc",
      setStatus: setShowAccordionId,
      component: showAccordionId === 2 ? <OpportunityDoc /> : <></>,
    },
    {
      id: 3,
      title: "Inventory Bucket",
      name: "inventoryBucketContents",
      setStatus: setShowAccordionId,
      component: <InventoryBucket />,
    },
    {
      id: 4,
      title: "Project Cover images",
      setStatus: setShowAccordionId,
      component: showAccordionId === 4 ? <ProjectCoverImages /> : <></>,
    },
    {
      id: 5,
      title: "Media Gallery",
      name: "mediaGallerySectionHeading",
      setStatus: setShowAccordionId,
      component: showAccordionId === 5 ? <MediaGallery /> : <></>,
    },
    {
      id: 6,
      title: "Latest Media Gallery",
      name: "latestMediaGallerySectionHeading",
      setStatus: setShowAccordionId,
      component: showAccordionId === 6 ? <LatestMediaGallery /> : <></>,
    },
    {
      id: 7,
      title: "Project Timeline",
      name: "projectTimelines",
      setStatus: setShowAccordionId,
      component: showAccordionId === 7 ? <ProjectTimeline /> : <></>,
    },
    {
      id: 8,
      title: "Key Pillars",
      name: "keyPillars",
      setStatus: setShowAccordionId,
      component: showAccordionId === 8 ? <KeyPillars /> : <></>,
    },

    {
      id: 9,
      title: "Location Infrastructure",
      name: "locationInfrastructure",
      setStatus: setShowAccordionId,
      component: showAccordionId === 9 ? <LocationInfrastruture /> : <></>,
    },
    {
      id: 10,
      title: "Offers and Promotion",
      name: "offersAndPromotions",
      setStatus: setShowAccordionId,
      component: showAccordionId === 10 ? <OffersAndPromtions /> : <></>,
    },
    {
      id: 11,
      title: "Similar Investments",
      setStatus: setShowAccordionId,
      name: "similarInvestmentSectionHeading",
      component: <SimilarInvestments />,
    },
    {
      id: 12,
      title: "FAQs",
      setStatus: setShowAccordionId,
      name: "faqSection",
      component: <Faqs />,
    },
    {
      id: 13,
      title: "Other Section Headings",
      name: "otherSectionHeadings",
      setStatus: setShowAccordionId,
      component: showAccordionId === 13 ? <OtherSectionHeadings /> : <></>,
    },
    {
      id: 14,
      title: "Customer Guidelines",
      setStatus: setShowAccordionId,
      component: showAccordionId === 14 ? <CustomerGuidelines /> : <></>,
    },
    {
      id: 17,
      title: "Project Icons",
      setStatus: setShowAccordionId,
      component: showAccordionId === 17 ? <ProjectIcons /> : <></>,
    },
    {
      id: 18,
      title: "Project Number",
      setStatus: setShowAccordionId,
      component: showAccordionId === 18 ? <ProjectNumber /> : <></>,
    },
  ];
};
