import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import PageHeading from "components/page-heading/PageHeading";
import {
  DepartmentsList,
  DesignationsList,
  inviteUserBreadcrumbRoutes,
  IObjectProps,
  userCreate,
} from "./UserCreationInitialValues";
import {
  AutocompleteComponent,
  ButtonComponent,
  FormFieldContainer,
  LoadingButtonComponent,
  RadioButtonGroupComponent,
  TextFieldComponent,
  StyledRadio,
} from "components/ui-elements";
import {
  Container,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  InputAdornment,
  List,
  ListProps,
  Stack,
  Typography,
  Box,
  IconButton,
  MenuItem,
  Link,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { validationSchema } from "./UserCreationValidation";
import {
  handleNumberFieldChange,
  isEmailValid,
  isMobileNumberValid,
} from "utils/ValidationUtils";
import { useFormik } from "formik";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InviteActions } from "redux-container/users/InviteUserRedux";
import { RoleAndModuleActions } from "redux-container/users/RolesAndModulesRedux";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { StatusTypeEnum } from "enumerations/StatusTypeEnum";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import moment from "moment";
import SettingMenuDropdown from "components/ui-elements/setting-menu-dropdown/SettingMenuDropdown";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import { RouteConstants } from "routes/RouteConstants";
import { Constants } from "constants/Constants";
import { DepartmentTypeEnumUtils } from "enumerations/DepartmentTypeEnum";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const actionDispatch = (dispatch: any) => {
  return {
    rolesAndModulesRequest: () =>
      dispatch(RoleAndModuleActions.rolesAndModulesRequest()),
    updateUsersRequest: (id: number, data: any) =>
      dispatch(InviteActions.updateUsersRequest(id, data)),
    getAllUsersRequest: () => dispatch(InviteActions.getUsersRequest()),
    inviteUsersRequest: (data: any) =>
      dispatch(InviteActions.inviteUsersRequest(data)),
    deleteUserRequest: (data: any) =>
      dispatch(InviteActions.deleteUserRequest(data)),
    setInviteUsersReduxState: (key: string, value: any) =>
      dispatch(InviteActions.setInviteUsersState(key, value)),
    setRoleOrModulesState: (key: string, value: any) =>
      dispatch(RoleAndModuleActions.setRoleOrModulesState(key, value)),
    updateUserStatus: (data: any) =>
      dispatch(InviteActions.updateUserStatusRequest(data)),
  };
};

const UserCreation = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const {
    errorMessage,
    successMessage,
    isSuccess,
    isLoading,
    roles,
    modules,
    rolesAndModules,
    isDialogOpen,
    userData,
  } = useSelector((state: any) => ({
    successMessage: state?.inviteUsers?.responseMessage,
    errorMessage: state?.inviteUsers?.error,
    userData: state?.inviteUsers?.inviteUsers,
    isDialogOpen: state?.inviteUsers?.shouldDialogOpen,
    isSuccess: state?.inviteUsers?.isSuccess,
    isLoading: state?.inviteUsers?.isLoading,
    roles: state?.rolesAndModules?.role,
    modules: state?.rolesAndModules?.module,
    rolesAndModules: state?.rolesAndModules,
  }));

  const {
    rolesAndModulesRequest,
    updateUsersRequest,
    inviteUsersRequest,
    setInviteUsersReduxState,
    setRoleOrModulesState,
    deleteUserRequest,
    updateUserStatus,
    getAllUsersRequest,
  } = actionDispatch(useDispatch());

  const [inviteUserState, setInviteuserState] = useState({
    isFetchDetails: false,
    isConfirmDialogOpen: false,
    formValues: userCreate,
    isViewMode: location?.state?.isView,
    saveButtonText: "Invite",
    newButtonText: "Invite & New",
    shouldRedirect: false,
    isAdminView: false,
    isEditMode: location?.state ? location?.state?.isEditMode : false,
  });

  const departmentTypes = DepartmentTypeEnumUtils.getDepartmentTypeEnums();
  const handleTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      `${event.target.name}`,
      event.target.value.trimStart().replace(/[^A-Za-z ]/gi, "")
    );
  };

  const getModuleHandler = async () => {
    setInviteuserState((prev) => ({
      ...prev,
      saveButtonText: location?.state ? "Save Changes" : "Invite",
      newButtonText: location?.state ? "Save & New" : "Invite & New",
    }));
    await rolesAndModulesRequest();
  };

  const handleEditClick = () => {
    setInviteuserState((prev) => ({
      ...prev,
      isViewMode: false,
      isEditMode: true,
    }));
  };

  const handleClose = () => {
    setInviteuserState((prev) => ({
      ...prev,
      isConfirmDialogOpen: false,
    }));
  };

  const onDeleteClick = () => {
    setInviteuserState((prev) => ({
      ...prev,
      isConfirmDialogOpen: true,
    }));
  };

  const handleDelete = () => {
    deleteUserRequest(values.id);
    handleClose();
    // setInviteUsersReduxState("isDialogOpen", true);
  };

  const setModuleHandler = () => {
    const admod = location?.state?.adminsOrModules?.map((item: any) => {
      if (item?.name !== "CMS User Management") {
        return item?.id;
      }
    });
    let resArr: any = [];
    modules.forEach((mod: any) => {
      if (admod?.includes(mod.id)) resArr.push(mod);
    });

    return resArr;
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleFetchClick = async () => {
    setInviteuserState((prev) => ({
      ...prev,
      isFetchDetails: true,
    }));
  };

  const handleCloseFlashMessageDialog = () => {
    setInviteUsersReduxState("error", null);
    setInviteUsersReduxState("shouldDialogOpen", false);
    setInviteUsersReduxState("responseMessage", null);

    if (inviteUserState.shouldRedirect !== true && isSuccess) {
      setInviteuserState((prev) => ({
        ...prev,
        isFetchDetails: false,
      }));
      navigate(-1);
    } else {
      setInviteuserState((prev) => ({
        ...prev,
        isFetchDetails: false,
      }));
      resetForm({ values: { ...userCreate } });
    }
  };

  const handleStatusChange = () => {
    let currentStatus: string;
    if (values.status === StatusTypeEnum.Suspended) {
      currentStatus = StatusTypeEnum.Invited;
    } else {
      currentStatus = StatusTypeEnum.Suspended;
    }
    const adminStatusPayload = {
      status: currentStatus,
      admins: [values.id],
    };
    updateUserStatus(adminStatusPayload);
  };

  const handleAdminView = (type: string) => () => {
    setInviteUsersReduxState("shouldDialogOpen", false);
    const createdAdminId = userData?.find(
      (userId: any) => userId.id === location?.state?.createdBy
    );

    const updatedAdminId = userData?.find(
      (userId: any) => userId.id === location?.state?.updatedBy
    );
    let findUser: any;
    if (type === "createdBy") {
      findUser = createdAdminId;
    } else {
      findUser = updatedAdminId;
    }
    setInviteuserState((prev) => ({
      ...prev,
      formValues: {
        ...prev.formValues,
        lastName: values.lastName ? values.lastName : "",
        ...findUser,
        isViewMode: true,
      },
      isAdminView: true,
    }));
  };

  const onSubmitHandler = async (formValuesparam: IObjectProps) => {
    let formValues: any;
    if (formValuesparam?.event?.target?.name) {
      formValues = formValuesparam?.values;
      setInviteuserState((prev) => ({
        ...prev,
        shouldRedirect: true,
      }));
    } else {
      formValues = formValuesparam;
      setInviteuserState((prev) => ({
        ...prev,
        shouldRedirect: false,
      }));
    }

    const Ids = formValues.moduleIds
      ? formValues.moduleIds.map((modules: any) => modules.id)
      : [];

    const InviteUserPayload = {
      ...formValues,
      moduleIds: +formValues.roleId === RoleTypeEnum.SuperAdmin ? [] : Ids,
      roleId: +formValues.roleId,
    };

    //Todo: check if user exist on fetch click and add "|| inviteUserState.isUserExist" in below condition to hit update API call if user is already exist

    if (location.state && location.state.id === formValues.id) {
      await updateUsersRequest(
        location.state?.id || formValues.id,
        InviteUserPayload
      );
    } else {
      await inviteUsersRequest(InviteUserPayload);
    }
  };

  useEffect(() => {
    getModuleHandler();
    getAllUsersRequest();
    if (location?.state) {
      handleFetchClick();
    }
    return () => {
      resetForm({ values: { ...userCreate } });
      setInviteUsersReduxState("error", null);
      setInviteUsersReduxState("isSuccess", false);
      setInviteUsersReduxState("isLoading", false);
      setInviteUsersReduxState("responseMessage", null);
      setRoleOrModulesState("module", []);
    };
  }, [location?.state, inviteUserState.formValues]);

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    initialValues:
      location.state !== null && !inviteUserState.isAdminView
        ? {
            ...location.state,
            moduleIds: setModuleHandler(),
          }
        : inviteUserState.formValues,
    validationSchema,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
  });

  return (
    <Fragment>
      <BreadCrumbs
        routes={inviteUserBreadcrumbRoutes(
          location.state
            ? `${values.firstName} ${values.lastName || ""}: #ID-${
                values.id || ""
              }`
            : "Invite New User",
          inviteUserState.isViewMode
        )}
      />
      <PageHeading title="CMS User Management" />
      <Container>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Stack mt={"1rem"}>
            <Stack>
              <IconButton edge={"start"} onClick={() => navigate(-1)}>
                <ChevronLeftIcon />
              </IconButton>
              <Typography color={"secondary.dark"} variant="h5">
                {location.state
                  ? `${values.firstName} ${values.lastName || ""}: #ID-${
                      values.id || ""
                    }`
                  : "Invite New User"}
              </Typography>
              <Collapse in={location.state} orientation={"horizontal"}>
                <SettingMenuDropdown
                  disabled={inviteUserState.isViewMode}
                  onstatuschange={handleStatusChange}
                  ondelete={onDeleteClick}
                  statusmenutext={
                    values.status === StatusTypeEnum.Suspended
                      ? "Invite"
                      : "Suspended"
                  }
                />
              </Collapse>
            </Stack>
            <Stack direction={"row"} columnGap={2}>
              <Collapse in={!inviteUserState.isViewMode}>
                <ButtonComponent
                  type="reset"
                  color="inherit"
                  onClick={handleCancelClick}
                >
                  Cancel
                </ButtonComponent>
              </Collapse>
              {inviteUserState.isViewMode === undefined && (
                <ButtonComponent
                  disabled={!isValid || !dirty}
                  name="inviteAndNew"
                  onClick={(event: any) => onSubmitHandler({ event, values })}
                >
                  {inviteUserState.newButtonText}
                </ButtonComponent>
              )}
              <Collapse
                in={!inviteUserState.isViewMode}
                orientation={"horizontal"}
              >
                <LoadingButtonComponent
                  type="submit"
                  disabled={
                    (values.roleId == 3 && !values?.moduleIds?.length) ||
                    !isValid ||
                    !dirty
                  }
                >
                  {inviteUserState.saveButtonText}
                </LoadingButtonComponent>
              </Collapse>
              <Collapse
                in={inviteUserState.isViewMode}
                orientation={"horizontal"}
              >
                <LoadingButtonComponent type="button" onClick={handleEditClick}>
                  {"Edit"}
                </LoadingButtonComponent>
              </Collapse>
            </Stack>
          </Stack>
          {inviteUserState.isViewMode && (
            <Stack columnGap={"1rem"} justifyContent={"flex-start"}>
              <Typography>
                <Typography component={"i"}> Created on:&nbsp;</Typography>
                {`${moment(values.createdAt).format("L") || ""} by `}
                <Link
                  type="button"
                  component="button"
                  onClick={handleAdminView("createdBy")}
                >
                  {values.adminCreatedBy?.firstName || "User"}
                </Link>
              </Typography>
              <Typography>
                <Typography component={"i"}>Modified on:&nbsp;</Typography>
                {`${moment(values.updatedAt).format("L") || ""} by `}
                <Link
                  type="button"
                  component="button"
                  onClick={handleAdminView("updatedBy")}
                >
                  {values.adminUpdatedBy?.firstName || "User"}
                </Link>
              </Typography>
            </Stack>
          )}
          <FormFieldContainer>
            <Typography
              variant="h5"
              color={"text.secondary"}
              margin={"0.5rem 0 3rem"}
            >
              User Details
            </Typography>
            <Grid container rowSpacing={inviteUserState.isFetchDetails ? 3 : 0}>
              <Grid item xs={2.7}>
                <TextFieldComponent
                  disabled={
                    inviteUserState.isViewMode || inviteUserState.isFetchDetails
                  }
                  // disabled={
                  //   inviteUserState.isViewMode || inviteUserState.isEditMode
                  // }
                  type={"email"}
                  label="Email Address *"
                  name="email"
                  onBlur={handleBlur}
                  value={values.email}
                  onChange={handleChange}
                  error={Boolean(errors?.email && touched.email)}
                  fieldhelpertext={
                    errors.email && touched.email && errors.email
                  }
                />
              </Grid>
              <Grid item xs={2.7} paddingLeft={"1.5rem"}>
                <Collapse in={!inviteUserState.isFetchDetails || !values.email}>
                  <ButtonComponent
                    variant="text"
                    color="inherit"
                    disabled={!isEmailValid(values.email)}
                    onClick={handleFetchClick}
                  >
                    Fetch Details
                  </ButtonComponent>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  columnGap={inviteUserState.isFetchDetails ? 4 : 0}
                >
                  <Grid item xs={2.7}>
                    <Collapse in={inviteUserState.isFetchDetails}>
                      <TextFieldComponent
                        disabled={inviteUserState.isViewMode || false}
                        label="First Name *"
                        name="firstName"
                        onBlur={handleBlur}
                        value={values.firstName}
                        onChange={handleTextInput}
                        error={Boolean(errors?.firstName && touched.firstName)}
                        fieldhelpertext={
                          errors.firstName &&
                          touched.firstName &&
                          errors.firstName
                        }
                      />
                    </Collapse>
                  </Grid>
                  <Grid item xs={2.7}>
                    <Collapse in={inviteUserState.isFetchDetails}>
                      <TextFieldComponent
                        disabled={inviteUserState.isViewMode || false}
                        label="Last Name *"
                        name="lastName"
                        onBlur={handleBlur}
                        value={values.lastName}
                        onChange={handleTextInput}
                        error={Boolean(errors?.lastName && touched.lastName)}
                        fieldhelpertext={
                          errors.lastName && touched.lastName && errors.lastName
                        }
                      />
                    </Collapse>
                  </Grid>
                  <Grid item xs={2.7}>
                    <Collapse in={inviteUserState.isFetchDetails}>
                      <TextFieldComponent
                        select
                        disabled={inviteUserState.isViewMode || false}
                        label="Department *"
                        name="department"
                        onBlur={handleBlur}
                        value={values.department}
                        onChange={handleChange}
                        inputProps={{ "data-testid": "department" }}
                        error={Boolean(
                          errors?.department && touched.department
                        )}
                        fieldhelpertext={
                          errors.department &&
                          touched.department &&
                          errors.department
                        }
                      >
                        {departmentTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextFieldComponent>
                    </Collapse>
                  </Grid>
                  {/* <Grid item xs={2.7}>
                    <Collapse in={inviteUserState.isFetchDetails}>
                      <TextFieldComponent
                        select
                        disabled={inviteUserState.isViewMode || false}
                        label="Designation*"
                        name="designation"
                        value={values.designation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ "data-testid": "designation" }}
                        error={Boolean(
                          errors?.designation && touched.designation
                        )}
                        fieldhelpertext={
                          errors.designation &&
                          touched.designation &&
                          errors.designation
                        }
                      >
                        {DesignationsList.map((option) => (
                          <MenuItem key={option.id} value={option.title}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </TextFieldComponent>
                    </Collapse>
                  </Grid> */}
                  <Grid item xs={2.7}>
                    <Collapse in={inviteUserState.isFetchDetails}>
                      <TextFieldComponent
                        // disabled={
                        //   inviteUserState.isViewMode ||
                        //   inviteUserState.isFetchDetails
                        // }
                        label="Phone Number *"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        inputProps={{ maxLength: 10 }}
                        onChange={handleNumberFieldChange(setFieldValue)}
                        error={Boolean(
                          errors.phoneNumber && touched.phoneNumber
                        )}
                        fieldhelpertext={
                          errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                variant="body1"
                                color={"text.primary"}
                              >
                                {`+${values.countryCode}`}
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormFieldContainer>
          <Collapse in={inviteUserState.isFetchDetails}>
            <FormFieldContainer>
              <Typography variant="h5" color={"text.secondary"} mb={"1rem"}>
                Role
              </Typography>
              <RadioButtonGroupComponent
                name="roleId"
                value={values.roleId}
                onChange={handleChange}
              >
                {roles?.map((role: typeof roles) => {
                  return (
                    <FormControlLabel
                      disabled={inviteUserState.isViewMode || false}
                      key={role?.id}
                      value={role?.id}
                      control={<StyledRadio />}
                      label={role.name}
                      checked={`${values.roleId}` === `${role?.id}`}
                    />
                  );
                })}
              </RadioButtonGroupComponent>
              <Collapse in={`${values.roleId}` === "3"}>
                <Typography
                  my={"1rem"}
                  variant="body1"
                  color={"secondary.dark"}
                >
                  Assign Module
                </Typography>
                <AutocompleteComponent
                  disableCloseOnSelect
                  disabled={inviteUserState.isViewMode || false}
                  label="Module"
                  multiple
                  name="moduleIds"
                  onBlur={handleBlur}
                  options={modules?.filter(
                    (item: any) =>
                      item?.name !== Constants.blockedModulesForAdmin[0]
                  )}
                  loading={rolesAndModules?.isLoading}
                  isOptionEqualToValue={(
                    option: IObjectProps,
                    value: IObjectProps
                  ) => option?.name === value?.name}
                  getOptionLabel={(option: IObjectProps) => option?.name}
                  value={values.moduleIds}
                  error={errors.moduleIds && touched.moduleIds ? true : false}
                  helpertext={
                    errors.moduleIds && touched.moduleIds && errors.moduleIds
                  }
                  getOptionDisabled={(option: any) => option.disabled}
                  onChange={(event: React.SyntheticEvent, newValue: []) => {
                    setFieldValue("moduleIds", newValue);
                  }}
                  renderOption={(
                    props: ListProps,
                    option: any,
                    { selected }: any
                  ) => (
                    <List {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: "#000" }}
                        checked={selected}
                      />
                      {option?.name}
                    </List>
                  )}
                  style={{ width: 600 }}
                />
              </Collapse>
            </FormFieldContainer>
          </Collapse>
        </Box>
        <FlashMessageDialog
          shouldOpen={isDialogOpen}
          content={successMessage ? successMessage : errorMessage}
          isSuccess={isSuccess}
          cancelHandler={handleCloseFlashMessageDialog}
        />
        <ConfirmationDialog
          shouldOpen={inviteUserState.isConfirmDialogOpen}
          content={`Are you sure want to delete this users?`}
          title="Deleting User"
          okText="Delete"
          cancelHandler={handleClose}
          okHandler={handleDelete}
        />
      </Container>
    </Fragment>
  );
};

export default UserCreation;
