import {
  Box,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ButtonComponent, FormFieldContainer } from "components/ui-elements";
import React, { Fragment } from "react";
import styles from "./BookingJourneySection.module.css";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";

const BookingJourneySection = (props: any) => {
  const {
    children,
    isInvestmentCompleted,
    isAnyPaymentCompleted,
    isSectionCompleted,
    isDisabled,
    onClickViewAllReceipts,
    isViewReceiptDisabled,
    shouldHideDivider,
  } = props;

  return (
    <Fragment>
      <div style={{ marginLeft: "25px", marginRight: "25px" }}>
        <Box
          boxShadow={isSectionCompleted ? 0 : 1}
          className={
            isSectionCompleted
              ? styles["journey-completed-section-container"]
              : styles["journey-section-container"]
          }
        >
          <Stack mt={-1} mb={1}>
            <Stack justifyContent={"start"} spacing={2}>
              <IconButton>
                {isSectionCompleted ? (
                  <CheckCircleRoundedIcon fontSize={"medium"} color="success" />
                ) : (
                  <CircleTwoToneIcon fontSize={"medium"} />
                )}
              </IconButton>
              <Typography
                className={isDisabled && styles["disabled-text"]}
                variant={"h6"}
                component={"h6"}
              >
                {props.titleText}
              </Typography>
            </Stack>

            {isAnyPaymentCompleted && (
              <ButtonComponent
                sx={{ textDecoration: "underline", marginTop: "0.5rem" }}
                variant="text"
                color="inherit"
                onClick={onClickViewAllReceipts}
                disabled={isViewReceiptDisabled}
              >
                View Receipts
              </ButtonComponent>
            )}
          </Stack>

          {!shouldHideDivider && (
            <Divider className={styles["divider-main-container"]} />
          )}

          {children}
        </Box>
      </div>
    </Fragment>
  );
};

export default BookingJourneySection;
