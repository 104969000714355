import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import NotificationActions from "redux-container/notification-management/NotificationsRedux";

export function* getNotifications(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getNotifications, action.params);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(NotificationActions.getNotificationsSuccess(response.data));
    } else {
      yield put(NotificationActions.getNotificationsFailure(response.data));
    }
  } catch (error) {
    yield put(NotificationActions.getNotificationsFailure(error));
  }
}

export function* addNotification(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addNotification, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(NotificationActions.addNotificationSuccess(response.data));
    } else {
      yield put(NotificationActions.addNotificationFailure(response.data));
    }
  } catch (error) {
    yield put(NotificationActions.addNotificationFailure(error));
  }
}

export function* updateNotification(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateNotification, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(NotificationActions.updateNotificationSuccess(response.data));
    } else {
      yield put(NotificationActions.updateNotificationFailure(response.data));
    }
  } catch (error) {
    yield put(NotificationActions.updateNotificationFailure(error));
  }
}

export function* changeNotificationStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.changeTestimonialStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        NotificationActions.changeNotificationStatusSuccess(response.data)
      );
    } else {
      yield put(
        NotificationActions.changeNotificationStatusFailure(response.data)
      );
    }
  } catch (error) {
    yield put(NotificationActions.changeNotificationStatusFailure(error));
  }
}
