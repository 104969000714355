import { Autocomplete, styled } from "@mui/material";
import TextFieldComponent from "../text-field/TextFieldComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledAutoComplete = styled(Autocomplete)`
  .MuiChip-root {
    border-radius: 5px;
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

const AutocompleteComponent = (props: any) => {
  const { error, helpertext, label, name } = props;
  return (
    <StyledAutoComplete
      size="medium"
      popupIcon={<ExpandMoreIcon />}
      {...props}
      renderInput={(params) => (
        <TextFieldComponent
          name={name}
          label={label}
          error={error}
          fieldhelpertext={helpertext}
          {...params}
        />
      )}
    />
  );
};

export default AutocompleteComponent;
