import * as Yup from "yup";

export const notificationValidationSchema = Yup.object({
  targetPage: Yup.string().required("This field is mandatory").nullable(),
  notificationDescription: Yup.object().shape({
    title: Yup.string().required("This field is mandatory").nullable(),
    body: Yup.string().required("This field is mandatory").nullable(),
    media: Yup.object().when("shouldDisplay", {
      is: true,
      then: Yup.object().shape({
        value: Yup.object().shape({
          url: Yup.mixed().required("Required"),
        }),
      }),
    }),
  }),
});
