export const enum ActionTypeEnum {
  Export = 1,
  Delete = 2,
  MarkAsActive = 3,
  MarkAsInactive = 4,
  MarkAsInvited = 5,
  MarkAsSuspended = 6,
}

export class ActionTypeEnumUtils {
  public static getEnumText(type: ActionTypeEnum): string {
    switch (type) {
      case ActionTypeEnum.Export:
        return "Export";
      case ActionTypeEnum.Delete:
        return "Delete";
      case ActionTypeEnum.MarkAsActive:
        return "Mark As Active";
      case ActionTypeEnum.MarkAsInactive:
        return "Mark As Inactive";
      case ActionTypeEnum.MarkAsInvited:
        return "Mark As Invited";
      case ActionTypeEnum.MarkAsSuspended:
        return "Mark As Suspended";
    }
  }

  public static getActionTypeEnums(): {
    label: string;
    value: ActionTypeEnum;
  }[] {
    const options: { label: string; value: ActionTypeEnum }[] = [
      {
        label: this.getEnumText(ActionTypeEnum.Export),
        value: ActionTypeEnum.Export,
      },
      {
        label: this.getEnumText(ActionTypeEnum.Delete),
        value: ActionTypeEnum.Delete,
      },
      {
        label: this.getEnumText(ActionTypeEnum.MarkAsActive),
        value: ActionTypeEnum.MarkAsActive,
      },
      {
        label: this.getEnumText(ActionTypeEnum.MarkAsInactive),
        value: ActionTypeEnum.MarkAsInactive,
      },
    ];
    return options;
  }

  public static getUserActionTypeEnums(): {
    label: string;
    value: ActionTypeEnum;
  }[] {
    const options: { label: string; value: ActionTypeEnum }[] = [
      {
        label: this.getEnumText(ActionTypeEnum.Export),
        value: ActionTypeEnum.Export,
      },
      {
        label: this.getEnumText(ActionTypeEnum.Delete),
        value: ActionTypeEnum.Delete,
      },
      {
        label: this.getEnumText(ActionTypeEnum.MarkAsInvited),
        value: ActionTypeEnum.MarkAsInvited,
      },
      {
        label: this.getEnumText(ActionTypeEnum.MarkAsSuspended),
        value: ActionTypeEnum.MarkAsSuspended,
      },
    ];
    return options;
  }
}

// --------------------------------------

export const enum ActionTypeEnumPromise {
  Export = 1,
  Delete = 2,
  MarkAsPublished = 3,
  MarkAsInactive = 4,
}

export class ActionTypeEnumUtilsPromise {
  public static getEnumText(type: ActionTypeEnumPromise): any {
    switch (type) {
      case ActionTypeEnumPromise.Export:
        return "Export";
      case ActionTypeEnumPromise.Delete:
        return "Delete";
      case ActionTypeEnumPromise.MarkAsPublished:
        return "Mark As Published";
      case ActionTypeEnumPromise.MarkAsInactive:
        return "Mark As Inactive";
    }
  }

  public static getActionTypeEnumsPromise(): {
    label: string;
    value: ActionTypeEnumPromise;
  }[] {
    const options: { label: string; value: ActionTypeEnumPromise }[] = [
      {
        label: this.getEnumText(ActionTypeEnumPromise.Export),
        value: ActionTypeEnumPromise.Export,
      },
      {
        label: this.getEnumText(ActionTypeEnumPromise.Delete),
        value: ActionTypeEnumPromise.Delete,
      },
      {
        label: this.getEnumText(ActionTypeEnumPromise.MarkAsPublished),
        value: ActionTypeEnumPromise.MarkAsPublished,
      },
      {
        label: this.getEnumText(ActionTypeEnumPromise.MarkAsInactive),
        value: ActionTypeEnumPromise.MarkAsInactive,
      },
    ];
    return options;
  }
}

// --------------------------------------

export const enum ActionTypeEnumUpdates {
  Export = 1,
  Delete = 2,
  MarkAsPublished = 3,
  MarkAsInactive = 4,
}

export class ActionTypeEnumUtilsUpdate {
  public static getEnumText(type: ActionTypeEnumUpdates): any {
    switch (type) {
      case ActionTypeEnumUpdates.Export:
        return "Export";
      case ActionTypeEnumUpdates.Delete:
        return "Delete";
      case ActionTypeEnumUpdates.MarkAsPublished:
        return "Mark As Published";
      case ActionTypeEnumUpdates.MarkAsInactive:
        return "Mark As Inactive";
    }
  }
  public static getActionTypeEnumsUpdates(): {
    label: string;
    value: ActionTypeEnumUpdates;
  }[] {
    const options: { label: string; value: ActionTypeEnumUpdates }[] = [
      {
        label: this.getEnumText(ActionTypeEnumUpdates.Export),
        value: ActionTypeEnumUpdates.Export,
      },
      {
        label: this.getEnumText(ActionTypeEnumUpdates.Delete),
        value: ActionTypeEnumUpdates.Delete,
      },

      {
        label: this.getEnumText(ActionTypeEnumUpdates.MarkAsPublished),
        value: ActionTypeEnumUpdates.MarkAsPublished,
      },
      {
        label: this.getEnumText(ActionTypeEnumUpdates.MarkAsInactive),
        value: ActionTypeEnumUpdates.MarkAsInactive,
      },
    ];
    return options;
  }
}

// --------------------------------------

export const enum ActionTypeEnumInsight {
  Export = 1,
  Delete = 2,
  MarkAsPublished = 3,
  MarkAsInactive = 4,
}

export class ActionTypeEnumUtilsInsight {
  public static getEnumText(type: ActionTypeEnumInsight): string {
    switch (type) {
      case ActionTypeEnumInsight.Export:
        return "Export";
      case ActionTypeEnumInsight.Delete:
        return "Delete";
      case ActionTypeEnumInsight.MarkAsPublished:
        return "Mark As Published";
      case ActionTypeEnumInsight.MarkAsInactive:
        return "Mark As Inactive";
    }
  }
  public static getActionTypeEnumsInsight(): {
    label: string;
    value: ActionTypeEnumInsight;
  }[] {
    const options: { label: string; value: ActionTypeEnumInsight }[] = [
      {
        label: this.getEnumText(ActionTypeEnumInsight.Export),
        value: ActionTypeEnumInsight.Export,
      },
      {
        label: this.getEnumText(ActionTypeEnumInsight.Delete),
        value: ActionTypeEnumInsight.Delete,
      },
      {
        label: this.getEnumText(ActionTypeEnumInsight.MarkAsPublished),
        value: ActionTypeEnumInsight.MarkAsPublished,
      },
      {
        label: this.getEnumText(ActionTypeEnumInsight.MarkAsInactive),
        value: ActionTypeEnumInsight.MarkAsInactive,
      },
    ];
    return options;
  }
}

// --------------------------------------

export const enum MediaAndPrActionTypeEnum {
  Export = 1,
  Delete = 2,
  MarkAsPublished = 3,
  MarkAsInactive = 4,
}

export class MediaAndPrActionTypeEnumUtils {
  public static getEnumText(type: MediaAndPrActionTypeEnum): any {
    switch (type) {
      case MediaAndPrActionTypeEnum.Export:
        return "Export";
      case MediaAndPrActionTypeEnum.Delete:
        return "Delete";
      case MediaAndPrActionTypeEnum.MarkAsPublished:
        return "Mark As Published";
      case MediaAndPrActionTypeEnum.MarkAsInactive:
        return "Mark As Inactive";
    }
  }

  public static getMediaAndPrActionTypeEnum(): {
    label: string;
    value: MediaAndPrActionTypeEnum;
  }[] {
    const options: { label: string; value: MediaAndPrActionTypeEnum }[] = [
      {
        label: this.getEnumText(MediaAndPrActionTypeEnum.Export),
        value: MediaAndPrActionTypeEnum.Export,
      },
      {
        label: this.getEnumText(MediaAndPrActionTypeEnum.Delete),
        value: MediaAndPrActionTypeEnum.Delete,
      },

      {
        label: this.getEnumText(MediaAndPrActionTypeEnum.MarkAsPublished),
        value: MediaAndPrActionTypeEnum.MarkAsPublished,
      },
      {
        label: this.getEnumText(MediaAndPrActionTypeEnum.MarkAsInactive),
        value: MediaAndPrActionTypeEnum.MarkAsInactive,
      },
    ];
    return options;
  }
}
// --------------------------------------

export const enum TestimonialActionTypeEnum {
  Export = 1,
  Delete = 2,
  MarkAsPublished = 3,
  MarkAsInactive = 4,
}

export class TestimonialActionTypeEnumUtils {
  public static getEnumText(type: TestimonialActionTypeEnum): any {
    switch (type) {
      case TestimonialActionTypeEnum.Export:
        return "Export";
      case TestimonialActionTypeEnum.Delete:
        return "Delete";
      case TestimonialActionTypeEnum.MarkAsPublished:
        return "Mark As Published";
      case TestimonialActionTypeEnum.MarkAsInactive:
        return "Mark As Inactive";
    }
  }

  public static getTestimonialActionTypeEnum(): {
    label: string;
    value: TestimonialActionTypeEnum;
  }[] {
    const options: { label: string; value: TestimonialActionTypeEnum }[] = [
      {
        label: this.getEnumText(TestimonialActionTypeEnum.Export),
        value: TestimonialActionTypeEnum.Export,
      },
      {
        label: this.getEnumText(TestimonialActionTypeEnum.Delete),
        value: TestimonialActionTypeEnum.Delete,
      },

      {
        label: this.getEnumText(TestimonialActionTypeEnum.MarkAsPublished),
        value: TestimonialActionTypeEnum.MarkAsPublished,
      },
      {
        label: this.getEnumText(TestimonialActionTypeEnum.MarkAsInactive),
        value: TestimonialActionTypeEnum.MarkAsInactive,
      },
    ];
    return options;
  }
}

export const enum BlogActionTypeEnum {
  Export = 1,
  Delete = 2,
  MarkAsPublished = 3,
  MarkAsInactive = 4,
}

export class BlogActionTypeEnumUtils {
  public static getEnumText(type: BlogActionTypeEnum): any {
    switch (type) {
      case BlogActionTypeEnum.Export:
        return "Export";
      case BlogActionTypeEnum.Delete:
        return "Delete";
      case BlogActionTypeEnum.MarkAsPublished:
        return "Mark As Published";
      case BlogActionTypeEnum.MarkAsInactive:
        return "Mark As Inactive";
    }
  }

  public static getBlogActionTypeEnum(): {
    label: string;
    value: BlogActionTypeEnum;
  }[] {
    const options: { label: string; value: BlogActionTypeEnum }[] = [
      {
        label: this.getEnumText(BlogActionTypeEnum.Export),
        value: BlogActionTypeEnum.Export,
      },
      {
        label: this.getEnumText(BlogActionTypeEnum.Delete),
        value: BlogActionTypeEnum.Delete,
      },

      {
        label: this.getEnumText(BlogActionTypeEnum.MarkAsPublished),
        value: BlogActionTypeEnum.MarkAsPublished,
      },
      {
        label: this.getEnumText(BlogActionTypeEnum.MarkAsInactive),
        value: BlogActionTypeEnum.MarkAsInactive,
      },
    ];
    return options;
  }
}

export const enum ProjectCollectionActionTypeEnum {
  Export = 1,
  Delete = 2,
  MarkAsPublished = 3,
  MarkAsInactive = 4,
}
export class ProjectCollectionActionTypeEnumUtils {
  public static getEnumText(type: ProjectCollectionActionTypeEnum): any {
    switch (type) {
      case ProjectCollectionActionTypeEnum.Export:
        return "Export";
      case ProjectCollectionActionTypeEnum.Delete:
        return "Delete";
      case ProjectCollectionActionTypeEnum.MarkAsPublished:
        return "Mark As Published";
      case ProjectCollectionActionTypeEnum.MarkAsInactive:
        return "Mark As Inactive";
    }
  }

  public static getProjectCollectionActionTypeEnum(): {
    label: string;
    value: ProjectCollectionActionTypeEnum;
  }[] {
    const options: { label: string; value: ProjectCollectionActionTypeEnum }[] =
      [
        {
          label: this.getEnumText(ProjectCollectionActionTypeEnum.Export),
          value: ProjectCollectionActionTypeEnum.Export,
        },
        {
          label: this.getEnumText(ProjectCollectionActionTypeEnum.Delete),
          value: ProjectCollectionActionTypeEnum.Delete,
        },

        {
          label: this.getEnumText(
            ProjectCollectionActionTypeEnum.MarkAsPublished
          ),
          value: ProjectCollectionActionTypeEnum.MarkAsPublished,
        },
        {
          label: this.getEnumText(
            ProjectCollectionActionTypeEnum.MarkAsInactive
          ),
          value: ProjectCollectionActionTypeEnum.MarkAsInactive,
        },
      ];
    return options;
  }
}

// --------------------------------------

export const enum NotificationActionTypeEnum {
  Export = 1,
}

export class NotificationActionTypeEnumUtils {
  public static getEnumText(type: NotificationActionTypeEnum): any {
    switch (type) {
      case NotificationActionTypeEnum.Export:
        return "Export";
    }
  }

  public static getNotificationActionTypeEnum(): {
    label: string;
    value: NotificationActionTypeEnum;
  }[] {
    const options: { label: string; value: NotificationActionTypeEnum }[] = [
      {
        label: this.getEnumText(NotificationActionTypeEnum.Export),
        value: NotificationActionTypeEnum.Export,
      },
    ];
    return options;
  }
}

//---------------------------------------
export const enum UrlActionTypeEnum {
  Export = 1,
  Delete = 2,
  MarkAsPublished = 3,
  // MarkAsInactive = 4,
}

export class UrlActionTypeEnumUtils {
  public static getEnumText(type: UrlActionTypeEnum): any {
    switch (type) {
      case UrlActionTypeEnum.Export:
        return "Export";
      case UrlActionTypeEnum.Delete:
        return "Delete";
      case UrlActionTypeEnum.MarkAsPublished:
        return "Mark As Published";
      // case UrlActionTypeEnum.MarkAsInactive:
      //   return "Mark As Inactive";
    }
  }

  public static getUrlActionTypeEnum(): {
    label: string;
    value: UrlActionTypeEnum;
  }[] {
    const options: { label: string; value: UrlActionTypeEnum }[] = [
      {
        label: this.getEnumText(UrlActionTypeEnum.Export),
        value: UrlActionTypeEnum.Export,
      },
      {
        label: this.getEnumText(UrlActionTypeEnum.Delete),
        value: UrlActionTypeEnum.Delete,
      },

      {
        label: this.getEnumText(UrlActionTypeEnum.MarkAsPublished),
        value: UrlActionTypeEnum.MarkAsPublished,
      },
      // {
      //   label: this.getEnumText(UrlActionTypeEnum.MarkAsInactive),
      //   value: UrlActionTypeEnum.MarkAsInactive,
      // },
    ];
    return options;
  }
}

// --------------------------------------

export const enum LandingPageActionTypeEnum {
  Export = 1,
  Active = 2,
  Inactive = 3,
}

export class LandingPageActionTypeEnumUtils {
  public static getEnumText(type: LandingPageActionTypeEnum): any {
    switch (type) {
      case LandingPageActionTypeEnum.Export:
        return "Export";
      case LandingPageActionTypeEnum.Active:
        return "Active";
      case LandingPageActionTypeEnum.Inactive:
        return "Inactive";
    }
  }

  public static getLandingPageActionTypeEnum(): {
    label: string;
    value: LandingPageActionTypeEnum;
  }[] {
    const options: { label: string; value: LandingPageActionTypeEnum }[] = [
      {
        label: this.getEnumText(LandingPageActionTypeEnum.Export),
        value: LandingPageActionTypeEnum.Export,
      },
      {
        label: this.getEnumText(LandingPageActionTypeEnum.Active),
        value: LandingPageActionTypeEnum.Active,
      },
      {
        label: this.getEnumText(LandingPageActionTypeEnum.Inactive),
        value: LandingPageActionTypeEnum.Inactive,
      },
    ];
    return options;
  }
}

// ------------------blocked customer list Action-------------
export const enum BlockedCustomerActionTypeEnum {
  MarkAsUnblocked = 1,
  Export = 2,
}

export class BlockedCustomerActionTypeEnumUtils {
  public static getEnumText(type: BlockedCustomerActionTypeEnum): any {
    switch (type) {
      case BlockedCustomerActionTypeEnum.MarkAsUnblocked:
        return "Mark as Unblocked";
      case BlockedCustomerActionTypeEnum.Export:
        return "Export";
    }
  }

  public static getBlockedCustomerActionTypeEnum(): {
    label: string;
    value: BlockedCustomerActionTypeEnum;
  }[] {
    const options: { label: string; value: BlockedCustomerActionTypeEnum }[] = [
      {
        label: this.getEnumText(BlockedCustomerActionTypeEnum.MarkAsUnblocked),
        value: BlockedCustomerActionTypeEnum.MarkAsUnblocked,
      },
      {
        label: this.getEnumText(BlockedCustomerActionTypeEnum.Export),
        value: BlockedCustomerActionTypeEnum.Export,
      },
    ];
    return options;
  }
}

// Published Project Actions

export class ProjectActionTypeEnumUtils {
  public static getEnumText(type: ActionTypeEnumInsight): string {
    switch (type) {
      case ActionTypeEnumInsight.Export:
        return "Export";
      case ActionTypeEnumInsight.Delete:
        return "Delete";
      case ActionTypeEnumInsight.MarkAsPublished:
        return "Mark As Live";
      case ActionTypeEnumInsight.MarkAsInactive:
        return "Mark As Hidden";
    }
  }
  public static getProjectsActionTypeEnums(): {
    label: string;
    value: ActionTypeEnumInsight;
  }[] {
    const options: { label: string; value: ActionTypeEnumInsight }[] = [
      {
        label: this.getEnumText(ActionTypeEnumInsight.Export),
        value: ActionTypeEnumInsight.Export,
      },
      {
        label: this.getEnumText(ActionTypeEnumInsight.Delete),
        value: ActionTypeEnumInsight.Delete,
      },
      {
        label: this.getEnumText(ActionTypeEnumInsight.MarkAsPublished),
        value: ActionTypeEnumInsight.MarkAsPublished,
      },
      {
        label: this.getEnumText(ActionTypeEnumInsight.MarkAsInactive),
        value: ActionTypeEnumInsight.MarkAsInactive,
      },
    ];
    return options;
  }
}
