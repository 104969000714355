import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  UrlActionTypeEnum,
  UrlActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import Button from "@mui/material/Button";
import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Checkbox from "@mui/material/Checkbox";
import ConfirmationDialog from "../../components/confirmation-dialog/ConfirmationDialog";
import Container from "@mui/material/Container";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Loader from "../../components/loader/Loader";
import MediaAssets from "../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../components/no-data-record/NoDataRecord";
import PageHeading from "../../components/page-heading/PageHeading";
import Paper from "@mui/material/Paper";
import Retry from "../../components/retry/Retry";
import { RouteConstants } from "../../routes/RouteConstants";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  UrlStatusTypeEnum,
  UrlStatusTypeEnumUtils,
} from "enumerations/StatusTypeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import LandingPageActions from "../../redux-container/landing-page-management/LandingPageUrlCreationRedux";
import { exportAsExcel } from "utils/ExportFileUtils";
import UserActions from "../../redux-container/users/UsersRedux";
import { ClickAwayListener, Popper, Stack, Typography } from "@mui/material";
import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import { ReadmoreDialogWrapper } from "pages/promise-management/promise-list/PromiseList";

const actionDispatch = (dispatch: any) => {
  return {
    getUrls: (params: any) =>
      dispatch(LandingPageActions.getUrlsRequest(params)),
    deleteUrl: (data: any) =>
      dispatch(LandingPageActions.deleteUrlRequest(data)),
    changeUrlStatus: (data: any) =>
      dispatch(LandingPageActions.changeUrlStatusRequest(data)),
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),
    setLandingPageUrlCreationState: (key: string, value: any) =>
      dispatch(LandingPageActions.setLandingPageUrlCreationState(key, value)),
  };
};

const UrlList = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const actions = UrlActionTypeEnumUtils.getUrlActionTypeEnum();
  const statuses = UrlStatusTypeEnumUtils.getUrlStatusTypeEnums();

  const {
    getUrls,
    changeUrlStatus,
    deleteUrl,
    getUsers,
    setLandingPageUrlCreationState,
  } = actionDispatch(useDispatch());

  const checkingStateValues = useSelector((state: any) => {
    return state;
  });

  const landingPageUrls = useSelector(
    (state: any) => state?.landingPageUrlCreation.landingPageUrls
  );
  const totalCount = useSelector(
    (state: any) => state?.landingPageUrlCreation.totalCount
  );
  const users = useSelector((state: any) => state?.users?.usersForFilters);
  const isLoading = useSelector(
    (state: any) => state?.landingPageUrlCreation?.isLoading
  );
  const error = useSelector(
    (state: any) => state?.landingPageUrlCreation?.error
  );
  const isSuccess = useSelector(
    (state: any) => state?.landingPageUrlCreation?.isSuccess
  );
  const message = useSelector(
    (state: any) => state?.landingPageUrlCreation?.message
  );
  const shouldOpenFlashDialog = useSelector(
    (state: any) => state?.landingPageUrlCreation?.shouldOpenFlashDialog
  );
  const { page, rowsPerPage } = useSelector((state: any) => {
    return {
      page: state?.landingPageUrlCreation?.page,
      rowsPerPage: state?.landingPageUrlCreation?.rowsPerPage,
    };
  });
  const [selectedUrls, setSelectedUrls] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCreatedBy, setCreatedBy] = useState<any>(null);
  const [selectedModifiedBy, setModifiedBy] = useState<any>(null);
  const [searchKey, setSearchKey] = useState("");
  const [anchor, setAnchor] = useState(null);
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [modulesAudited, setModulesAudited] = useState<any>(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const breadcrumbRoutes: any = [
    {
      name: "Landing Page Management",
      route: RouteConstants.landingPageManagementDashboard,
    },
    {
      name: "Urls",
      route: RouteConstants.urlList,
    },
  ];

  const tableHeadings = [
    { heading: "Id" },
    { heading: "Url" },
    { heading: "Name" },
    { heading: "Created On" },
    { heading: "Published On" },
    { heading: "Last Modified By" },
    { heading: "Status" },
    { heading: "Action" },
  ];

  useEffect(() => {
    const params = {};
    // loadData(params);
    getFilterDataFromDashboard();
  }, []);

  useEffect(() => {
    const params = {
      index: page,
      size: rowsPerPage,
      status: location?.state?.statusType
        ? location?.state?.statusType
        : selectedStatus,
      updatedBy: selectedModifiedBy?.id,
      createdBy: selectedCreatedBy?.id,
      searchKey: searchKey,
    };
    if (
      searchKey ||
      selectedStatus ||
      selectedCreatedBy ||
      rowsPerPage ||
      page ||
      selectedModifiedBy
    ) {
      loadData(params);
      clearSelected();
    }
  }, [
    page,
    rowsPerPage,
    searchKey,
    selectedStatus,
    selectedCreatedBy,
    selectedModifiedBy,
    isSuccess,
  ]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     setLandingPageUrlCreationState("isSuccess", false);
  //     const params = {
  //       index: page,
  //       size: rowsPerPage,
  //       updatedBy: selectedModifiedBy,
  //       status: selectedStatus,
  //       createdBy: selectedCreatedBy,
  //       searchKey: searchKey,
  //     };
  //     loadData(params);
  //     clearSelected();
  //   }
  // }, [isSuccess]);
  const loadData = (params: any) => {
    getUrls(params);
    // getUsers({});
  };
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setLandingPageUrlCreationState("page", 1);
      setLandingPageUrlCreationState("rowsPerPage", 20);
    }

    return () => {};
  }, []);
  const getFilterDataFromDashboard = () => {
    if (location && location.state && location.state.statusType) {
      setSelectedStatus(location.state.statusType as string);
    }
    navigate(location.pathname, { replace: true });
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };
  const handleClickAway = () => {
    setOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setLandingPageUrlCreationState("page", newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLandingPageUrlCreationState("rowsPerPage", Number(event.target.value));
    setLandingPageUrlCreationState("page", 1);
  };

  const handleExportAsExcel = () => {
    let data = JSON.parse(JSON.stringify(landingPageUrls));
    for (let i = 0; i <= data.length - 1; i++) {
      if (data[i].urlDescription) {
        data[i]["urlDescription"] = JSON.stringify(data[i].urlDescription);
      }
    }
    exportAsExcel(data, "Urls", "Sheet1");
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
    if (page !== 1) {
      setLandingPageUrlCreationState("page", 1);
    }
  };

  // const handleCreatedByChange = (event: SelectChangeEvent) => {
  //   setCreatedBy(event.target.value as string);
  // };

  const handleModifiedByChange = (event: SelectChangeEvent) => {
    setModifiedBy(event.target.value as string);
  };

  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
    if (page !== 1) {
      setLandingPageUrlCreationState("page", 1);
    }
  };

  const resetState = () => {
    setLandingPageUrlCreationState("message", null);
    setLandingPageUrlCreationState("error", null);
    setLandingPageUrlCreationState("isSuccess", false);
  };

  const handleCloseFlashMessageDialog = () => {
    resetState();
    clearSelected();
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = landingPageUrls.map((data: any) => data.id);
      setSelectedUrls(newSelecteds);
      return;
    }
    setSelectedUrls([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedUrls.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUrls, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUrls.slice(1));
    } else if (selectedIndex === selectedUrls?.length - 1) {
      newSelected = newSelected.concat(selectedUrls.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUrls.slice(0, selectedIndex),
        selectedUrls.slice(selectedIndex + 1)
      );
    }

    setSelectedUrls(newSelected);
  };
  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case UrlActionTypeEnum.Export:
        handleExportAsExcel();
        break;
      case UrlActionTypeEnum.Delete:
        handleDeleteUrl();
        break;
      case UrlActionTypeEnum.MarkAsPublished:
        changeUrlStatuses(String(UrlStatusTypeEnum.Published));
        break;
      // case UrlActionTypeEnum.MarkAsInactive:
      //   changeUrlStatuses(String(UrlStatusTypeEnum.Inactive));
      //   break;
    }
  };

  const statusAuditedItems = () => {
    let auditedItems: any[] = [];
    landingPageUrls?.map((item: any) => {
      selectedUrls?.map((itemId: number) => {
        if (itemId === item.id) {
          auditedItems.push(item);
        }
      });
    });
    return auditedItems;
  };
  const handleDeleteUrl = () => {

    if (selectedUrls.length === 0) {
      alert("You have not selected any urls");
      return;
    }
    for (let i = 0; i <= selectedUrls.length - 1; i++) {
      let objectValue = landingPageUrls.find(
        (x: { id: number }) => x.id === selectedUrls[i]
      );
      if (objectValue.status == "1001") {
        alert("Cannot delete active url");
        return;
      }
    }
    setIsConfirmDeleteDialogOpen(true);
  };

  const deleteSelectedUrls = () => {
    setIsConfirmDeleteDialogOpen(false);
    const urls = {
      urlIds: selectedUrls,
    };

    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Url ${item?.firstName} ${item?.lastName} Deleted.(ID${item?.id})`,
        newValue: "",
        oldValue: "",
        auditedObject: null,
        route: null,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);

    deleteUrl(urls);
    clearSelected();
  };

  const changeUrlStatuses = (status: string) => {
    const data = {
      status: status,
      urlIds: selectedUrls,
    };

    if (selectedUrls.length === 0) {
      alert("You have not selected any urls");
      return;
    }
    if (status == "1001" && selectedUrls.length > 1) {
      alert("Only one url can be active at a time.");
      return;
    }

    let auditedItems = statusAuditedItems();
    let auditObjectsArray: any[] = [];
    auditedItems?.map((item: any) => {
      let auditObject = {
        itemAffected: `Url Status`,
        newValue:
          UrlStatusTypeEnum.Published === status ? "Published" : "Inactive",
        oldValue:
          UrlStatusTypeEnum.Published === status ? "Inactive" : "Published",
        auditedObject: {
          url: { ...item, status: status },
          isEditMode: true,
        },
        route: RouteConstants?.landingPageUrlCreation,
      };
      auditObjectsArray.push(auditObject);
    });
    setModulesAudited(auditObjectsArray);
    changeUrlStatus(data);
  };

  const clearSelected = () => {
    setSelectedUrls([]);
  };
  const popOverComponent = (event: any, description: any) => {
    setAnchor(event.currentTarget);
    setOpen(!open);
    setDescription(description);
  };
  const clearFilters = () => {
    setSelectedStatus("");
    setCreatedBy(null);
    setModifiedBy(null);
    setSearchKey("");
  };

  const isSelected = (id: any) => selectedUrls.indexOf(id) !== -1;

  const createUrl = () => {
    navigate(RouteConstants.landingPageUrlCreation, {
      state: { isViewMode: false, landingUrl: null },
    });
  };

  const viewVideo = (url: string) => {
    window.open(url, "_blank");
  };

  const renderNoData = () => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_testimonial}
        altText=""
        message="You haven't created any Url yet. Create your first Url."
        btnTitle="CREATE Urls"
        btnAction={createUrl}
      />
    );
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Urls
            {`(${
              totalCount
                ? totalCount
                : landingPageUrls?.length
                ? landingPageUrls?.length
                : 0
            })`}
            {selectedUrls.length > 0 ? (
              <Fragment>
                <span className="selected-items-count-text">
                  {`${selectedUrls?.length} Url(s) Selected`}
                </span>
                <a className="clear-selected-text" onClick={clearSelected}>
                  Clear
                </a>
              </Fragment>
            ) : null}
          </h4>

          <div>
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Actions"
                onChange={handleActionChange}
              >
                {actions.map((action: any) => {
                  return (
                    <MenuItem value={action.value} key={action.value}>
                      {action.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>

            <Button
              variant="contained"
              onClick={createUrl}
              // autoFocus
              disableElevation
              className="btn btn-dark"
            >
              Create New
            </Button>
          </div>
        </div>

        <Stack>
          <Grid container spacing={1}>
            <Grid container spacing={2} md={8} lg={8} xl={8}>
              <Grid
                item
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedCreatedBy}
                    label="Created By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setCreatedBy(newValue);
                      if (page !== 0) {
                        setLandingPageUrlCreationState("page", 1);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} lg={3} xl={3}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedModifiedBy}
                    label="Modified By"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setModifiedBy(newValue);
                      if (page !== 0) {
                        setLandingPageUrlCreationState("page", 1);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedStatus}
                    label="Status"
                    onChange={handleStatusChange}
                  >
                    {statuses.map((status: any) => {
                      return (
                        <MenuItem value={status.value} key={status.value}>
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>

              {selectedCreatedBy !== null ||
              selectedStatus !== "" ||
              selectedModifiedBy !== null ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={clearFilters}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Stack>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell style={{ minWidth: 50 }} padding="checkbox">
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedUrls?.length > 0 &&
                      selectedUrls?.length < landingPageUrls?.length
                    }
                    checked={
                      landingPageUrls?.length > 0 &&
                      selectedUrls?.length === landingPageUrls?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all urls",
                    }}
                  />
                </TableCell>
                {tableHeadings.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {landingPageUrls && landingPageUrls.length > 0 ? (
              <TableBody>
                {landingPageUrls.map((url: any) => {
                  const isItemSelected = isSelected(url.id);
                  return (
                    <TableRow
                      key={url.id}
                      className="table-data-row"
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ minWidth: 50 }} padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, url.id)}
                          className="active-checkbox"
                          checked={isItemSelected}
                        />
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 150, maxWidth: 220 }}
                        className="table-data-text"
                      >
                        {url?.id ? url?.id : "-"}
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 200 }}
                      >
                        {url?.urlDescription.media.value.url.length > 18 ? (
                          <Stack
                            justifyContent="flex-start"
                            sx={{ width: "100%" }}
                          >
                            <span>{`${url.urlDescription.media.value.url.slice(
                              0,
                              18
                            )}...`}</span>
                            <Typography
                              component="span"
                              variant="body1"
                              color="#2d2d2d"
                              sx={{
                                fontWeight: "bold",
                                cursor: "pointer",
                                wordBreak: "keep-all",
                                textAlign: "start",
                                width: "130px",
                              }}
                              onClick={(event: any) =>
                                popOverComponent(
                                  event,
                                  url?.urlDescription?.media?.value?.url
                                )
                              }
                            >
                              READ MORE
                            </Typography>
                          </Stack>
                        ) : (
                          url?.urlDescription?.media?.value?.url
                        )}
                      </TableCell>

                      <TableCell
                        style={{
                          minWidth: 150,
                          maxWidth: 300,
                          wordBreak: "break-all",
                        }}
                        className="table-data-text"
                        align={"left"}
                      >
                        {url.urlName ? url.urlName : "-"}
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 100 }}
                        className="table-data-text"
                      >
                        <div>
                          {url.createdAt
                            ? formatDateTime(
                                url.createdAt,
                                Constants.dateFormatDDmmYYYY
                              )
                            : "-"}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 140 }}
                        className="table-data-text"
                      >
                        {url.updatedAt
                          ? formatDateTime(
                              url.updatedAt,
                              Constants.dateFormatDDmmYYYY
                            )
                          : "-"}
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 150 }}
                        className="table-data-text"
                      >
                        <div>
                          {url && url.urlUpdatedBy && url.urlUpdatedBy.firstName
                            ? url?.urlUpdatedBy?.firstName
                            : "----"}
                        </div>
                      </TableCell>
                      <TableCell
                        className="table-data-text"
                        style={{ minWidth: 120 }}
                      >
                        {url.status
                          ? UrlStatusTypeEnumUtils.getEnumText(url.status)
                          : "-"}
                      </TableCell>
                      <TableCell style={{ minWidth: 120 }}>
                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="view"
                            onClick={() =>
                              viewVideo(url.urlDescription.media.value.url)
                            }
                          >
                            <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <div className="no-record">No Urls</div>
            )}
          </Table>
        </TableContainer>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleClickAway}
        >
          <Popper open={open} placement="bottom-start" anchorEl={anchor}>
            <ReadmoreDialogWrapper>
              <Paper elevation={3} sx={{ p: "0.5rem", width: "350px" }}>
                {description}
              </Paper>
            </ReadmoreDialogWrapper>
          </Popper>
        </ClickAwayListener>
        {!searchKey && landingPageUrls && landingPageUrls.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[20, 40, 50]}
            component="div"
            count={
              totalCount
                ? totalCount
                : landingPageUrls?.length
                ? landingPageUrls?.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Fragment>
    );
  };

  const render = () => {
    if (
      (landingPageUrls?.length > 0 ||
        selectedCreatedBy !== null ||
        selectedModifiedBy != null) &&
      isLoading === false
    ) {
      return renderTable();
    } else if (
      (landingPageUrls?.length === 0 ||
        landingPageUrls?.length === undefined) &&
      selectedCreatedBy === null &&
      selectedModifiedBy === null &&
      isLoading === false
    ) {
      return renderNoData();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };
  const shouldProvidePlaceholder = () => {
    if (
      (landingPageUrls?.length > 0 || selectedCreatedBy !== null) &&
      isLoading === false
    ) {
      return true;
    }
    return false;
  };
  return (
    <Fragment>
      <PageHeading title="Url Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder={shouldProvidePlaceholder() ? "Search Urls" : ""}
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-container">{render()}</Container>
      <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Url(s)?"
        content={`This action will delete ${selectedUrls.length} selected url(s). Are you sure you want to continue?`}
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={deleteSelectedUrls}
      />

      {message ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : "Something Went Wrong!"}
          isSuccess={true}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null} 

      {error ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
    </Fragment>
  );
};

export default UrlList;
