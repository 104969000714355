import React, { Fragment, useEffect } from "react";
import {
  FormFieldContainer,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./InventoryBucket.module.css";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { getIn, useFormikContext } from "formik";
import { FieldArray } from "formik";
import { shouldNumberOnly } from "utils/ValidationUtils";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const InventorySection = () => {
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    errors,
    touched,
    isValid,
  } = useFormikContext<IInitinalValueProjectContent>();

  const location: any = useLocation();
  const fetchLaunchPhases = useSelector(
    (state: any) => state?.projectLaunchPhases?.fetchedLaunchPhasesData
  );
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  useEffect(() => {
    if (location?.state?.isNew && !location?.state?.isDraft) {
      const removeLaunchPhaseInvetoryId = // constructing inventory bucket payload structure to reach requirment
        fetchLaunchPhases?.crmInventoryBuckets?.map((item: any, index: any) => {
          const { id, ...restContent } = item; // removing CRM ID and adding shortDescription key
          return {
            ...restContent,
            shortDescription:
              values?.inventoryBucketContents[index]?.shortDescription,
          };
        });
      setFieldValue("inventoryBucketContents", removeLaunchPhaseInvetoryId);

      // configure area and price range for inventory buckets
      fetchLaunchPhases?.crmInventoryBuckets?.map((item: any, index: any) => {
        setFieldValue(
          `inventoryBucketContents[${index}].crmInventoryBucketId`,
          item?.id
        );
        setFieldValue(
          `inventoryBucketContents[${index}].areaRange.from`,
          item?.areaRange?.from ? item?.areaRange?.from?.toString() : "0"
        );
        setFieldValue(
          `inventoryBucketContents[${index}].areaRange.to`,
          item?.areaRange?.to ? item?.areaRange?.to?.toString() : "0"
        );
        setFieldValue(
          `inventoryBucketContents[${index}].priceRange.from`,
          item?.priceRange?.from ? item?.priceRange?.from?.toString() : "0"
        );
        setFieldValue(
          `inventoryBucketContents[${index}].priceRange.to`,
          item?.priceRange?.to ? item?.priceRange?.to?.toString() : "0"
        );
        if (item?.areaRange && item?.areaRange?.from === undefined) {
          setFieldValue(
            `inventoryBucketContents[${index}].areaRange.from`,
            // item?.areaRange?.split("-")[0].toString()
            "0"
          );
        }
        if (item?.areaRange && item?.areaRange?.to === undefined) {
          setFieldValue(
            `inventoryBucketContents[${index}].areaRange.to`,
            // item?.areaRange?.split("-")[1].toString()
            "0"
          );
        }
        if (item?.priceRange && item?.priceRange?.from === undefined) {
          setFieldValue(
            `inventoryBucketContents[${index}].priceRange.from`,
            // item?.priceRange?.split("-")[0].toString()
            "0"
          );
        }
        if (item?.priceRange && item?.priceRange?.to === undefined) {
          setFieldValue(
            `inventoryBucketContents[${index}].priceRange.to`,
            // item?.priceRange?.split("-")[1].toString()
            "0"
          );
        }
      });
    }
  }, []);

  return (
    <Fragment>
      <FieldArray
        name="inventoryBucketContents"
        render={(arrayHelper) => (
          <>
            {values?.inventoryBucketContents?.map(
              (fieldValue: any, index: any) => {
                return (
                  <div className="margin-30">
                    <Divider sx={{ margin: "0 -30px" }} />
                    <Grid container rowSpacing={2} spacing={2}>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <TextFieldComponent
                          className="margin-top-20"
                          type="text"
                          label="Inventory Bucket Name"
                          placeholder="Enter"
                          name={`inventoryBucketContents[${index}].name`}
                          disabled={true}
                          value={
                            values?.inventoryBucketContents[index]
                              ?.displayName ||
                            values?.inventoryBucketContents[index]?.name
                          }
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Stack
                          mt={4.5}
                          spacing={2}
                          alignItems={"center"}
                          justifyContent={"start"}
                        >
                          <Typography>Is Inventory Bucket Sold Out?</Typography>
                          <SwitchComponent
                            name={`inventoryBucketContents[${index}].isSoldOut`}
                            onChange={handleChange}
                            value={
                              values?.inventoryBucketContents[index]?.isSoldOut
                            }
                            checked={
                              values?.inventoryBucketContents[index]?.isSoldOut
                            }
                            disabled={isViewMode}
                          />
                        </Stack>
                      </Grid>
                    </Grid>

                    <Grid container spacing={0} className="margin-top-20">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextFieldComponent
                          fullWidth
                          required
                          type="text"
                          label="Short Description"
                          placeholder="Enter"
                          name={`inventoryBucketContents[${index}].shortDescription`}
                          inputProps={{ maxLength: 240 }}
                          value={
                            values?.inventoryBucketContents[index]
                              ?.shortDescription
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fieldhelpertext={
                            getIn(
                              touched,
                              `inventoryBucketContents[${index}].shortDescription`
                            ) &&
                            getIn(
                              errors,
                              `inventoryBucketContents[${index}].shortDescription`
                            )
                          }
                          error={Boolean(
                            getIn(
                              errors,
                              `inventoryBucketContents[${index}].shortDescription`
                            ) &&
                              getIn(
                                touched,
                                `inventoryBucketContents[${index}].shortDescription`
                              )
                          )}
                          disabled={isViewMode}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} className="margin-top-20">
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
                        <Stack direction={"column"}>
                          <Typography color={"#000"} width={"100%"} mb={1}>
                            Area Range (sq ft) *
                          </Typography>
                          <Stack spacing={1}>
                            <TextFieldComponent
                              fullWidth
                              required
                              type="text"
                              label="From"
                              placeholder="Enter"
                              name={`inventoryBucketContents[${index}].areaRange.from`}
                              value={
                                values?.inventoryBucketContents[index]
                                  ?.areaRange?.from
                              }
                              onBlur={handleBlur}
                              onChange={(event: any) => {
                                if (shouldNumberOnly(event.target.value)) {
                                  setFieldValue(
                                    `${event.target.name}`,
                                    event.target.value.toString()
                                  );
                                }
                              }}
                              fieldhelpertext={
                                getIn(
                                  touched,
                                  `inventoryBucketContents.${index}.areaRange.from`
                                ) &&
                                getIn(
                                  errors,
                                  `inventoryBucketContents.${index}.areaRange.from`
                                )
                              }
                              error={Boolean(
                                getIn(
                                  errors,
                                  `inventoryBucketContents.${index}.areaRange.from`
                                ) &&
                                  getIn(
                                    touched,
                                    `inventoryBucketContents.${index}.areaRange.from`
                                  )
                              )}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    sq ft
                                  </InputAdornment>
                                ),
                              }}
                              disabled={true}
                            />
                            <div style={{ marginBottom: "10px" }}>-</div>
                            <TextFieldComponent
                              fullWidth
                              required
                              type="text"
                              label="To"
                              placeholder="Enter"
                              name={`inventoryBucketContents[${index}].areaRange.to`}
                              value={
                                values?.inventoryBucketContents[index]
                                  ?.areaRange?.to
                              }
                              onBlur={handleBlur}
                              onChange={(event: any) => {
                                if (shouldNumberOnly(event.target.value)) {
                                  setFieldValue(
                                    `${event.target.name}`,
                                    event.target.value.toString()
                                  );
                                }
                              }}
                              fieldhelpertext={
                                getIn(
                                  touched,
                                  `inventoryBucketContents[${index}].areaRange.to`
                                ) &&
                                getIn(
                                  errors,
                                  `inventoryBucketContents[${index}].areaRange.to`
                                )
                              }
                              error={Boolean(
                                getIn(
                                  errors,
                                  `inventoryBucketContents[${index}].areaRange.to`
                                ) &&
                                  getIn(
                                    touched,
                                    `inventoryBucketContents[${index}].areaRange.to`
                                  )
                              )}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    sq ft
                                  </InputAdornment>
                                ),
                              }}
                              disabled={true}
                            />
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
                        <Stack direction={"column"}>
                          <Typography color={"#000"} width={"100%"} mb={1}>
                            Price Range (₹) *
                          </Typography>
                          <Stack spacing={1}>
                            <TextFieldComponent
                              fullWidth
                              required
                              type="text"
                              label="From"
                              placeholder="Enter"
                              name={`inventoryBucketContents[${index}].priceRange.from`}
                              value={
                                values?.inventoryBucketContents[index]
                                  ?.priceRange?.from
                              }
                              onBlur={handleBlur}
                              onChange={(event: any) => {
                                if (shouldNumberOnly(event.target.value)) {
                                  setFieldValue(
                                    `${event.target.name}`,
                                    event.target.value.toString()
                                  );
                                }
                              }}
                              fieldhelpertext={
                                getIn(
                                  touched,
                                  `inventoryBucketContents[${index}].priceRange.from`
                                ) &&
                                getIn(
                                  errors,
                                  `inventoryBucketContents[${index}].priceRange.from`
                                )
                              }
                              error={Boolean(
                                getIn(
                                  errors,
                                  `inventoryBucketContents[${index}].priceRange.from`
                                ) &&
                                  getIn(
                                    touched,
                                    `inventoryBucketContents[${index}].priceRange.from`
                                  )
                              )}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CurrencyRupeeIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              }}
                              disabled={true}
                            />
                            <div style={{ marginBottom: "10px" }}>-</div>
                            <TextFieldComponent
                              fullWidth
                              required
                              type="text"
                              label="To"
                              placeholder="Enter"
                              name={`inventoryBucketContents[${index}].priceRange.to`}
                              value={
                                values?.inventoryBucketContents[index]
                                  ?.priceRange?.to
                              }
                              onBlur={handleBlur}
                              onChange={(event: any) => {
                                if (shouldNumberOnly(event.target.value)) {
                                  setFieldValue(
                                    `${event.target.name}`,
                                    event.target.value.toString()
                                  );
                                }
                              }}
                              fieldhelpertext={
                                getIn(
                                  touched,
                                  `inventoryBucketContents[${index}].priceRange.to`
                                ) &&
                                getIn(
                                  errors,
                                  `inventoryBucketContents[${index}].priceRange.to`
                                )
                              }
                              error={Boolean(
                                getIn(
                                  errors,
                                  `inventoryBucketContents[${index}].priceRange.to`
                                ) &&
                                  getIn(
                                    touched,
                                    `inventoryBucketContents[${index}].priceRange.to`
                                  )
                              )}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CurrencyRupeeIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              }}
                              disabled={true}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>
                );
              }
            )}
          </>
        )}
      />
    </Fragment>
  );
};

export default InventorySection;
