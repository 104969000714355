export const Constants = {
  developmentBaseUrl: "https://cms-api-dev.hoabl.in/hoabl-admin/",

  // localBaseUrl: "http://192.168.68.112:8002/hoabl-admin/",
  // localBaseUrl: "http://192.168.1.17:8002/hoabl-admin/",
  localBaseUrl: "http://192.168.1.24:8002/hoabl-admin/",
  qaBaseUrl: "https://cms-api-stg.hoabl.in/hoabl-admin/",
  preProductionBaseUrl: "https://cms-api-uat.hoabl.in/hoabl-admin/",
  productionBaseUrl: "https://cms-api.hoabl.in/hoabl-admin/",

  paymentsFrontEndDevBaseUrl: "https://payments-dev.hoabl.in/",
  paymentsFrontEndStgBaseUrl: "https://payments-stg.hoabl.in/",
  paymentsFrontEndUatBaseUrl: "https://payments-uat.hoabl.in/",
  paymentsFrontEndProdBaseUrl: "https://payments.hoabl.in/",
  
  authorizationToken: "AuthorizationToken",
  loggedInUser: "LoggedInUser",
  windowCloseTime: "windowCloseTime",

  dateFormatMMDDYY: "MM-DD-YY",
  dateFormatDDMMMYY: "DD MMM YYYY",
  dateFormatYYYYMMDD: "YYYY-MM-DD",
  dateFormatDDmmYYYY: "DD/MM/YYYY",
  timeFormathhmmssA: "hh:mm:ss A",
  dateAndTimeFormatDDMMYYYYhhmmss: "DD/MM/YYYY hh:mm:ss A",
  defaultPageSize: 20,
  defaultPageIndex: 1,
  defaultTotalPages: 0,
  defaultTotalCount: 0,
  userData: "Myprofile",
  blockedModulesForAdmin: ["CMS User Management"],
};
