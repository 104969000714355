import {
  Avatar,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import QuillEditor from "components/quill-editor/QuillEditor";
import { FieldArray, Form, Formik, getIn, useFormikContext } from "formik";
import { Fragment, useState } from "react";
import styles from "./CorporatePhilosophy.module.css";
import * as Yup from "yup";
import {
  CorporatePhilosophyConstants,
  FoundersConstants,
} from "../../AboutUsPageConstants";
import React from "react";
import { IPage5ProfileInitialValue } from "../../../page-5-profile-IniticalValue";
import MediaAssets from "assets";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { SwitchComponent, TextFieldComponent } from "components/ui-elements";
import CorporatePhilosphyImageMedia from "./CorporatePhilosphyImageMedia";
import { useDispatch, useSelector } from "react-redux";
import { stripHtml } from "utils/ValidationUtils";
import PageManagementCreationAction from "redux-container/page-management/PageManagementRedux";

const actionDispatch = (dispatch: any) => {
  return {
    setPageManagementCreationProfile: (key: any, value: any) =>
      dispatch(
        PageManagementCreationAction.setUpdatePageManagement(key, value)
      ),
  };
};

const CorporatePhilosphy = () => {
  const [currentlyOpenedIndex, setCurrentlyOpenedIndex] = useState(999);
  const {
    isReduxViewMode,
    page5Data,
    corporatePhilosophyLength,
    modulesAudited,
  } = useSelector((state: any) => state?.pageManagement);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<IPage5ProfileInitialValue>();

  const { setPageManagementCreationProfile } = actionDispatch(useDispatch());

  const handleShowImageMedia = (imageIndex: number) => {
    setCurrentlyOpenedIndex(imageIndex);
  };

  const handleTextEditor = (
    name: string,
    newValue: string,
    options: any,
    { setFieldValue, setFieldError }: any
  ) => {
    // if (options?.isError) {
    //   setFieldError(name, "Maximum character exceed");
    // } else {
    setFieldValue(name, newValue);
    // }
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <div className={styles["accordian-global-padding"]}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              lg={12}
              xl={12}
              className={styles["priority-holder"]}
            >
              <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
                <Typography>Corporate Philosophy</Typography>

                <FormControlLabel
                  className="switch-form-label"
                  name={"aboutUs.isCorporatePhilosophyActive"}
                  disabled={isReduxViewMode || false}
                  checked={values?.aboutUs?.isCorporatePhilosophyActive}
                  onChange={handleChange}
                  control={<SwitchComponent />}
                  label=""
                  labelPlacement="start"
                />
                <IconButton>
                  <Tooltip title={"Corporate Philosophy"} placement="top" arrow>
                    <Avatar
                      className={styles["Avator"]}
                      src={MediaAssets.ic_info}
                      variant="rounded"
                    />
                  </Tooltip>
                </IconButton>
              </Stack>
            </Grid>

            <Grid item xs={5} mt={3}>
              <TextFieldComponent
                required
                label="Section Heading"
                placeholder="Enter"
                name={`aboutUs.corporatePhilosophy.sectionHeading`}
                disabled={isReduxViewMode || false}
                inputProps={{
                  maxLength:
                    CorporatePhilosophyConstants.sectionHeadingCharacterLimit,
                }}
                value={values?.aboutUs?.corporatePhilosophy?.sectionHeading}
                onChange={handleChange}
                onBlur={handleBlur}
                fieldhelpertext={
                  getIn(
                    touched,
                    "aboutUs.corporatePhilosophy.sectionHeading"
                  ) &&
                  getIn(errors, "aboutUs.corporatePhilosophy.sectionHeading")
                }
                error={Boolean(
                  getIn(errors, "aboutUs.corporatePhilosophy.sectionHeading") &&
                    getIn(touched, "aboutUs.corporatePhilosophy.sectionHeading")
                )}
              />
            </Grid>
          </Grid>
        </div>

        <Divider />
        <FieldArray
          name="aboutUs.corporatePhilosophy.detailedInformation"
          render={(arrayHelpers) => (
            <div>
              {values?.aboutUs?.corporatePhilosophy?.detailedInformation
                ?.length > 0 &&
                values?.aboutUs?.corporatePhilosophy?.detailedInformation?.map(
                  (element, index: any) => (
                    <React.Fragment>
                      <Divider />
                      <div className={styles["accordian-global-padding"]}>
                        <Stack justifyContent={"flex-end"}>
                          {values?.aboutUs?.corporatePhilosophy
                            ?.detailedInformation?.length > 1 &&
                            !isReduxViewMode && (
                              <IconButton
                                aria-label="delete"
                                data-testid="button-delete"
                                onClick={() => {
                                  arrayHelpers.remove(index);

                                  let localPage5Data =
                                    page5Data?.aboutUs?.corporatePhilosophy
                                      ?.detailedInformation;

                                  localPage5Data.splice(index);
                                  setPageManagementCreationProfile(
                                    "page5Date",
                                    {
                                      ...page5Data,
                                      aboutUs: {
                                        ...page5Data?.aboutUs,
                                        corporatePhilosophy: {
                                          ...page5Data?.aboutUs
                                            ?.corporatePhilosophy,
                                          detailedInformation: localPage5Data,
                                        },
                                      },
                                    }
                                  );

                                  if (
                                    corporatePhilosophyLength >
                                    values?.aboutUs?.corporatePhilosophy
                                      ?.detailedInformation?.length -
                                      1
                                  ) {
                                    setPageManagementCreationProfile(
                                      "modulesAudited",
                                      [
                                        ...modulesAudited,
                                        {
                                          itemAffected: `Corporate Philosophy ${
                                            index + 1
                                          } Deleted`,
                                          newValue: "",
                                          oldValue: "",
                                        },
                                      ]
                                    );
                                  }
                                }}
                              >
                                <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                              </IconButton>
                            )}
                        </Stack>
                        <Grid container spacing={2}>
                          <Grid item xs={5}>
                            <TextFieldComponent
                              required
                              label="Display Name"
                              placeholder="Enter"
                              onBlur={handleBlur}
                              name={`aboutUs.corporatePhilosophy.detailedInformation[${index}].displayName`}
                              disabled={isReduxViewMode || false}
                              inputProps={{
                                maxLength:
                                  CorporatePhilosophyConstants.displayNameCharactersLimit,
                              }}
                              value={element?.displayName}
                              onChange={handleChange}
                              error={Boolean(
                                getIn(
                                  errors,
                                  `aboutUs.corporatePhilosophy.detailedInformation[${index}].displayName`
                                ) &&
                                  getIn(
                                    touched,
                                    `aboutUs.corporatePhilosophy.detailedInformation[${index}].displayName`
                                  )
                              )}
                              fieldhelpertext={
                                getIn(
                                  touched,
                                  `aboutUs.corporatePhilosophy.detailedInformation[${index}].displayName`
                                ) &&
                                getIn(
                                  errors,
                                  `aboutUs.corporatePhilosophy.detailedInformation[${index}].displayName`
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>

                      <div className={styles["accordian-global-padding"]}>
                        <Grid container spacing={2}>
                          <Grid item xs={3} sm={3} md={3} lg={4} xl={3.5}>
                            <Typography
                              className={styles["Upload-heading-padding"]}
                            >
                              Upload Icon
                            </Typography>
                            {/* imageMedia */}
                            <CorporatePhilosphyImageMedia
                              index={index}
                              currentlyOpenedIndex={currentlyOpenedIndex}
                              handleShowImageMedia={handleShowImageMedia}
                            />
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8} xl={8.5}>
                            <Typography
                              className={styles["Upload-heading-padding"]}
                            >
                              Description
                            </Typography>
                            <QuillEditor
                              placeholder="Enter description"
                              name={`aboutUs.corporatePhilosophy.detailedInformation[${index}].description`}
                              className={styles["QuillEditor"]}
                              onChange={(
                                name: string,
                                newValue: string,
                                options: any
                              ) =>
                                handleTextEditor(name, newValue, options, {
                                  setFieldValue,
                                  setFieldError,
                                })
                              }
                              inputProps={{
                                maxLength: FoundersConstants.description,
                              }}
                              value={element?.description}
                              style={
                               { height: "12.3rem" }
                              }
                              disabled={isReduxViewMode}
                              fieldhelpertext={
                                values?.aboutUs?.isCorporatePhilosophyActive ===
                                  true &&
                                stripHtml(
                                  values?.aboutUs.corporatePhilosophy
                                    .detailedInformation[index].description
                                ).length === 0 &&
                                "Required"
                              }
                              error={Boolean(
                                getIn(
                                  errors,
                                  "aboutUs.corporatePhilosophy.detailedInformation[${index}].description"
                                ) &&
                                  getIn(
                                    touched,
                                    "aboutUs.corporatePhilosophy.detailedInformation[${index}].description"
                                  )
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      {index ===
                      values?.aboutUs?.corporatePhilosophy?.detailedInformation
                        ?.length -
                        1 ? (
                        <div className={styles["accordian-global-padding"]}>
                          <Grid container>
                            <Grid item>
                              {!isReduxViewMode && (
                                <Link
                                  underline="always"
                                  component="button"
                                  onClick={() => {
                                    arrayHelpers.push({
                                      displayName: "",
                                      media: {
                                        key: "",
                                        name: "",
                                        value: {
                                          url: "",
                                          size: 0,
                                          width: 320,
                                          height: 240,
                                          mediaType: "image",
                                        },
                                      },
                                      description: "",
                                    });

                                    let localPage5Data =
                                      page5Data?.aboutUs?.corporatePhilosophy
                                        ?.detailedInformation;

                                    localPage5Data.push({
                                      displayName: "",
                                      media: {
                                        key: "",
                                        name: "",
                                        value: {
                                          url: "",
                                          size: 0,
                                          width: 320,
                                          height: 240,
                                          mediaType: "image",
                                        },
                                      },
                                      description: "",
                                    });
                                    setPageManagementCreationProfile(
                                      "page5Date",
                                      {
                                        ...page5Data,
                                        aboutUs: {
                                          ...page5Data?.aboutUs,
                                          corporatePhilosophy: {
                                            ...page5Data?.aboutUs
                                              ?.corporatePhilosophy,
                                            detailedInformation: localPage5Data,
                                          },
                                        },
                                      }
                                    );

                                    if (
                                      corporatePhilosophyLength <
                                      values?.aboutUs?.corporatePhilosophy
                                        ?.detailedInformation?.length +
                                        1
                                    ) {
                                      setPageManagementCreationProfile(
                                        "modulesAudited",
                                        [
                                          ...modulesAudited,
                                          {
                                            itemAffected: `Corporate Philosophy ${
                                              index + 1 + 1
                                            } Added`,
                                            newValue: "",
                                            oldValue: "",
                                          },
                                        ]
                                      );
                                    }
                                  }}
                                >
                                  + Add more
                                </Link>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      ) : null}
                    </React.Fragment>
                  )
                )}

              {/* <Divider />

              <div className={styles["accordian-global-padding"]}>
                <Grid className={styles["button-position"]} mb={2}>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </Grid>
              </div> */}
            </div>
          )}
        />
      </Form>
    </Fragment>
  );
};

export default CorporatePhilosphy;
