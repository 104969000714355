import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getMediaAndPrsSuccess: ["response"],
  getMediaAndPrsFailure: ["error"],
  getMediaAndPrsRequest: ["params"],

  addMediaAndPrSuccess: ["response"],
  addMediaAndPrFailure: ["error"],
  addMediaAndPrRequest: ["data"],

  updateMediaAndPrSuccess: ["response"],
  updateMediaAndPrFailure: ["error"],
  updateMediaAndPrRequest: ["data"],

  deleteMediaAndPrSuccess: ["response"],
  deleteMediaAndPrFailure: ["error"],
  deleteMediaAndPrRequest: ["data"],

  changeMediaAndPrStatusSuccess: ["response"],
  changeMediaAndPrStatusFailure: ["error"],
  changeMediaAndPrStatusRequest: ["data"],

  updateMediaAndPrPrioritySuccess: ["response"],
  updateMediaAndPrPriorityFailure: ["error"],
  updateMediaAndPrPriorityRequest: ["data"],

  setMediaAndPrsState: ["key", "value"],
});

export const MediaAndPrsTypes = Types;

export const MediaAndPrsActions = Creators;

export const INITIAL_STATE = {
  MediaAndPrList: [],
  error: null,
  isLoading: false,
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  page: 1,
  rowsPerPage: 20,
};

/* Get MediaAndPrs */
export const getMediaAndPrsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getMediaAndPrsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      MediaAndPrList: response.data,
      isLoading: false,
      totalCount: response.totalCount,
    };
  }
};

export const getMediaAndPrsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    // error: action.error.message,
  };
};

/* Add MediaAndPrs */
export const addMediaAndPrRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const addMediaAndPrSuccess = (state: any, action: any) => {
  const response = action.response;
  console.log("addMediaAndPrSuccess", action);

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "MediaAndPr added successfully!",
      isLoading: false,
    };
  }
};

export const addMediaAndPrFailure = (state: any, action: any) => {
  console.log("addMediaAndPrFailure", action);

  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

/* Update MediaAndPrs */
export const updateMediaAndPrRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const updateMediaAndPrSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "MediaAndPr updated successfully!",
      isLoading: false,
    };
  }
};

export const updateMediaAndPrFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

/* Delete MediaAndPrs */
export const deleteMediaAndPrRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const deleteupdateMediaAndPrSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "MediaAndPrs deleted successfully!",
      isLoading: false,
    };
  }
};

export const deleteMediaAndPrFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

/* Update MediaAndPr(s) status */
export const changeMediaAndPrStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const changeMediaAndPrStatusSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message
        ? response.message
        : "MediaAndPrs status updated successfully",
      isLoading: false,
    };
  }
};

export const changeMediaAndPrStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

/* Update MediaAndPr Priority status */
export const updateMediaAndPrPriorityRequest = (state: any) => {
  return {
    ...state,
    // isLoading: true,
  };
};

export const updateMediaAndPrPrioritySuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isPriorityUpdated: true,
      isLoading: false,
    };
  }
};

export const updateMediaAndPrPriorityFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setMediaAndPrsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MEDIA_AND_PRS_SUCCESS]: getMediaAndPrsSuccess,
  [Types.GET_MEDIA_AND_PRS_FAILURE]: getMediaAndPrsFailure,
  [Types.GET_MEDIA_AND_PRS_REQUEST]: getMediaAndPrsRequest,

  [Types.ADD_MEDIA_AND_PR_SUCCESS]: addMediaAndPrSuccess,
  [Types.ADD_MEDIA_AND_PR_FAILURE]: addMediaAndPrFailure,
  [Types.ADD_MEDIA_AND_PR_REQUEST]: addMediaAndPrRequest,

  [Types.UPDATE_MEDIA_AND_PR_SUCCESS]: updateMediaAndPrSuccess,
  [Types.UPDATE_MEDIA_AND_PR_FAILURE]: updateMediaAndPrFailure,
  [Types.UPDATE_MEDIA_AND_PR_REQUEST]: updateMediaAndPrRequest,

  [Types.DELETE_MEDIA_AND_PR_SUCCESS]: deleteupdateMediaAndPrSuccess,
  [Types.DELETE_MEDIA_AND_PR_FAILURE]: deleteMediaAndPrFailure,
  [Types.DELETE_MEDIA_AND_PR_REQUEST]: deleteMediaAndPrRequest,

  [Types.CHANGE_MEDIA_AND_PR_STATUS_REQUEST]: changeMediaAndPrStatusRequest,
  [Types.CHANGE_MEDIA_AND_PR_STATUS_SUCCESS]: changeMediaAndPrStatusSuccess,
  [Types.CHANGE_MEDIA_AND_PR_STATUS_FAILURE]: changeMediaAndPrStatusFailure,

  [Types.UPDATE_MEDIA_AND_PR_PRIORITY_SUCCESS]: updateMediaAndPrPrioritySuccess,
  [Types.UPDATE_MEDIA_AND_PR_PRIORITY_FAILURE]: updateMediaAndPrPriorityFailure,
  [Types.UPDATE_MEDIA_AND_PR_PRIORITY_REQUEST]: updateMediaAndPrPriorityRequest,

  [Types.SET_MEDIA_AND_PRS_STATE]: setMediaAndPrsState,
});
