import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getUsersListSuccess: ["response"],
  getUsersListFailure: ["error"],
  getUsersListRequest: ["params"],

  getUserDashboardSuccess: ["response"],
  getUserDashboardFailure: ["error"],
  getUserDashboardRequest: ["data"],

  getAdminListForFiltersRequest: [""],
  getAdminListForFiltersSuccess: ["response"],
  getAdminListForFiltersFailure: ["error"],

  deleteUsersSuccess: ["response"],
  deleteUsersFailure: ["error"],
  deleteUsersRequest: ["data"],

  changeUserStatusSuccess: ["response"],
  changeUserStatusFailure: ["error"],
  changeUserStatusRequest: ["data"],

  logoutSuccess: ["response"],
  logoutFailure: ["error"],
  logoutRequest: ["data"],

  setUsersState: ["key", "value"],
});

export const UserTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  users: [],
  usersForFilters: [],
  dashboardData: null,
  pageSize: Constants.defaultPageSize,
  pageIndex: Constants.defaultPageIndex,
  totalPages: Constants.defaultTotalPages,
  totalCount: Constants.defaultTotalCount,
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  isGraphDataFetched: false,
  page: 1,
  rowsPerPage: 5,
  activeSideMenu: "",
  isCustomerSidemenu: false,
  isRouteUnderActiveSideMenu: "",
  shouldOpenFlashDialog: false,
};

// Get Admin Users
export const getUsersListRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const getUsersListSuccess = (state: any, action: any) => {
  const response = action.response;

  // if (response) {
  return {
    ...state,
    error: null,
    users: response.data,
    isLoading: false,
    totalCount: response.totalCount,
    isSuccess: true,
  };
};

export const getUsersListFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    // error: action.error.message,
  };
};

// Get admins for filter dropdown
export const getAdminListForFiltersRequest = (state: any) => {
  return {
    ...state,
  };
};

export const getAdminListForFiltersSuccess = (state: any, action: any) => {
  const response = action.response;

  // if (response) {
  return {
    ...state,
    error: null,
    usersForFilters: response.data,
    isLoading: false,
    isSuccess: true,
  };
};

export const getAdminListForFiltersFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    // error: action.error.message,
  };
};

// Get Admin Users Dashboard
export const getUserDashboardRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getUserDashboardSuccess = (state: any, action: any) => {
  const response = action.response;

  return {
    ...state,
    error: null,
    dashboardData: response.data,
    isLoading: false,
    isGraphDataFetched: true,
    isSuccess: true,
  };
};

export const getUserDashboardFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

// Delete Admin User(s)
export const deleteUsersRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const deleteUsersSuccess = (state: any, action: any) => {
  const response = action.response;

  // if (response) {
  return {
    ...state,
    error: null,
    isLoading: false,
    message: response.message
      ? response.message
      : "Admins deleted successfully!",
    totalCount: response.totalCount,
    shouldOpenFlashDialog: true,
    isSuccess: true,
  };
  // }
  // return {
  //   ...state,
  //   isLoading: false,
  //   error: action.response ? action.response.message : "Failed to load",
  // };
};

export const deleteUsersFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    message: action.error.message,
    shouldOpenFlashDialog: true,
    isSuccess: false,
  };
};

/* Update Admin User(s) status */
export const changeUserStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const changeUserStatusSuccess = (state: any, action: any) => {
  const response = action.response;

  // if (response) {
  return {
    ...state,
    error: null,
    isSuccess: true,
    message: response.message
      ? response.message
      : "Admins status updated successfully!",
    isLoading: false,
    shouldOpenFlashDialog: true,
  };
  // }
  // return {
  //   ...state,
  //   isLoading: false,
  //   error: action.response ? action.response.message : "Failed to load",
  // };
};

export const changeUserStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    message: action.error.message,
    shouldOpenFlashDialog: true,
    isSuccess: false,
  };
};

// Logout User
export const logoutRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const logoutSuccess = (state: any, action: any) => {
  const response = action.response;

  // if (response) {
  return {
    ...state,
    error: null,
    users: response.data,
    isLoading: false,
    totalCount: response.totalCount,
  };
  // }
  // return {
  //   ...state,
  //   isLoading: false,
  //   error: action.response ? action.response.message : "Failed to load",
  // };
};

export const logoutFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setUsersState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USERS_LIST_SUCCESS]: getUsersListSuccess,
  [Types.GET_USERS_LIST_FAILURE]: getUsersListFailure,
  [Types.GET_USERS_LIST_REQUEST]: getUsersListRequest,

  [Types.GET_USER_DASHBOARD_SUCCESS]: getUserDashboardSuccess,
  [Types.GET_USER_DASHBOARD_FAILURE]: getUserDashboardFailure,
  [Types.GET_USER_DASHBOARD_REQUEST]: getUserDashboardRequest,

  [Types.GET_ADMIN_LIST_FOR_FILTERS_SUCCESS]: getAdminListForFiltersSuccess,
  [Types.GET_ADMIN_LIST_FOR_FILTERS_FAILURE]: getAdminListForFiltersFailure,
  [Types.GET_ADMIN_LIST_FOR_FILTERS_REQUEST]: getAdminListForFiltersRequest,

  [Types.DELETE_USERS_SUCCESS]: deleteUsersSuccess,
  [Types.DELETE_USERS_FAILURE]: deleteUsersFailure,
  [Types.DELETE_USERS_REQUEST]: deleteUsersRequest,

  [Types.CHANGE_USER_STATUS_REQUEST]: changeUserStatusRequest,
  [Types.CHANGE_USER_STATUS_SUCCESS]: changeUserStatusSuccess,
  [Types.CHANGE_USER_STATUS_FAILURE]: changeUserStatusFailure,

  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,

  [Types.SET_USERS_STATE]: setUsersState,
});
