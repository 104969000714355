export interface IProjectsNewUser {
  smartKey: string;
  welcomeMessage: string;
  // allowTypingMessage: string;
  // inactiveMessage: string;
  firstMessage: string;
  finalMessage: string;
  categories: {
    text: string;
    action:null| number;
    actionType: number;
    optionNumber: number;
    message: string;

    options: {
      text: string;
      action: null|number;
      actionType: number;
      optionNumber: number;
    }[];
    linkedOption: number;
  }[];
}

export const projectNewUserInitialValues: IProjectsNewUser = {
  smartKey: "NEW_PROJECT",
  welcomeMessage:"",
  // allowTypingMessage: "",
  // inactiveMessage:"",
  firstMessage:"",
  finalMessage: "Thank you for reaching out to us. Our sales executive will get in touch you soon to address all your questions.",
  categories: [
    {
      text: "",
      action: null,
      actionType: 0,
      optionNumber: 0,
      message:"",
      options: [
        {
          text: "",
          action: null,
          actionType: 0,
          optionNumber: 0,
        },
        {
          text: "",
          action: null,
          actionType: 0,
          optionNumber: 0,
        },
      ],
      linkedOption: 1,
    },
  ],
};

export const projectNewUserLabels:any = {
  // smartKey: "NEW_PROJECT",
  welcomeMessage:"Welcome Message *",
  firstMessage:"Message *",
  finalMessage: "Thank you for reaching out to us. Our sales executive will get in touch you soon to address all your questions.",
  categories: [
    {
      text: "Display Name",
      action: "Topics",
      message:"Message body",
      options: [
        {
          text: "Option",
          action: "Actions",
        },
        {
          text: "Option",
          action: "Actions",
        },
      ],
    },
  ],
};