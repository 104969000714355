import { useEffect, useState } from "react";
import { getIn, useFormikContext } from "formik";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { useDispatch, useSelector } from "react-redux";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { MediaUploadComponent } from "components/ui-elements";

interface ILocationInfrastrutureImageMedia {
  index?: any;
  currentlyOpenedIndex?: any;
  handleLocationInfrastrutureImageMedia: (imageIndex: number) => void;
}

function LocationInfrastrutureImageMedia({
  index,
  currentlyOpenedIndex,
  handleLocationInfrastrutureImageMedia,
}: ILocationInfrastrutureImageMedia) {
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    handleBlur,
  } = useFormikContext<IInitinalValueProjectContent>();

  const {
    imageDetails,
    isLoading,
    error,
    isSuccess,
    fileName,
    isDelete,
    data,
  } = useSelector((state: any) => state.fileUpload);
  const dispatch = useDispatch();
  const [locationInfrastrutureImageValue, setLocationInfrastrutureImageValue] =
    useState<any[]>([]);
    const isViewMode = useSelector((state: any) => (
      state?.projectContentManagement?.isViewMode
   ));
  //upload Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const locationInfrastrutureImageUpload = `locationInfrastructure.values.${index}.icon.value.url`;
      if (
        locationInfrastrutureImageValue[0] ===
          locationInfrastrutureImageUpload &&
        currentlyOpenedIndex == index
      ) {
        setFieldValue(locationInfrastrutureImageUpload, imageDetails?.url);
        handleLocationInfrastrutureImageMedia(999);
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  //delete Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const insightMediaImageDelete = `locationInfrastructure.values.${index}.icon.value.url`;
      if (
        locationInfrastrutureImageValue[0] === insightMediaImageDelete &&
        currentlyOpenedIndex == index
      ) {
        const empty = "";
        setFieldValue(insightMediaImageDelete, empty);
      }
    }
    getAllInsightsCreationFunction();
  }, [isDelete]);

  const handleuploadImage = (event: any) => {
    const file = event?.target?.files[0];
    const MediaId = event?.target?.id;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
      };
      setLocationInfrastrutureImageValue((prevState) => [
        ...prevState,
        MediaId,
      ]);

      reader.readAsArrayBuffer(file);
      dispatch(FileUploadActions.setUploadFileState("fileName", file?.name));
      const loactionImageFilename = `locationInfrastructure.values.${index}.icon.name`;
      const loactionImageFileKey = `locationInfrastructure.values.${index}.icon.key`;
      setFieldValue(loactionImageFilename, file?.name);
      setFieldValue(loactionImageFileKey, file?.name);
    }
  };

  const handleCancelImageUpload = async () => {
    const loactionImageFilename =
      values?.locationInfrastructure?.values[index]?.icon?.name;
    let fileUrl = `locationInfrastructure.values.${index}.icon.value.url`;
    setLocationInfrastrutureImageValue((prevState) => [...prevState, fileUrl]);
    dispatch(FileUploadActions.fileDeleteRequest(loactionImageFilename));
  };

  const handleCloseFlashMessage = () => {
    dispatch(FileUploadActions.setUploadFileState("isDelete", false));
    // dispatch(FileUploadActions.setUploadFileState("error", null));
  };

  return (
    <div>
      <MediaUploadComponent
        loading={isLoading && currentlyOpenedIndex == index}
        ondrop={handleChange}
        name={`locationInfrastructure.values.${index}.icon.value.url`}
        value={values?.locationInfrastructure?.values[index]?.icon?.value?.url}
        // onBlur={handleBlur}
        onChange={(event: any) => {
          handleuploadImage(event);
          handleLocationInfrastrutureImageMedia(index); //here i m setting the particular Index
        }}
        previewimage={
          values?.locationInfrastructure?.values[index]?.icon?.value?.url
        }
        cancelimage={() => {
          handleCancelImageUpload();
          handleLocationInfrastrutureImageMedia(index); //here i m setting the particular Index
        }}
        onBlur={handleBlur}
        fieldhelpertext={
          getIn(
            touched,
            `locationInfrastructure.values.${index}.icon.value.url`
          ) &&
          getIn(errors, `locationInfrastructure.values.${index}.icon.value.url`)
        }
        disabled={isViewMode}
      />
      {/* <FlashMessageDialog
        cancel={false}
        shouldOpen={isDelete}
        content={data}
        isSuccess={error === null ? true : false}
        cancelHandler={handleCloseFlashMessage}
      /> */}
    </div>
  );
}

export default LocationInfrastrutureImageMedia;
