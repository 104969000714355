import { Constants } from "constants/Constants";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { faqRoutePathConst } from "pages/resource-management/faq/faqRoutePathConst";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    //get all faqs 
    allFaqsProjectSuccess: ["response"],
    allFaqsProjectFailure: ["error"],
    allFaqsProjectRequest: ["data"],

    setALLProjectFaqState: ["key", "value"]
}
);

export const AllProjectFaqsType =Types
export const AllProjectFaqsAction = Creators

export const INITIAL_STATE = {
    error: null,
    isLoading: false,
    allProjectFaqs: null,
    showInMobileFaq:[],
    filterAllfaqBasedGivenArray:[],
    isFaqAddOrRemoved:false
}



//getAllFaqs
export const allFaqsProjectRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isFaqAddOrRemoved:false

    }
}
export const allFaqsProjectSuccess = (state: any, action: any) => {
    const response = action.response;
    const filteredData = response?.data?.filter((faq:any)=>(
     state?.filterAllfaqBasedGivenArray?.indexOf(faq.id)!==-1
    )).map((faqList:any,index:number)=>({
        ...faqList,
        priority:index+1
    }))
    
    return {
        ...state,
        isLoading: false,
        allProjectFaqs: filteredData,
        
    }
}

export const allFaqsProjectFailure = (state: any, action: any) => {

    return {
        ...state,
        isLoading: false,
        isFaqAddOrRemoved: false,
    }
}

export const setALLProjectFaqState = (state: any, action: any) => {
    const newState = {
        ...state,
    };
    newState[action.key] = action.value;
    return newState;
};


export const reducer = createReducer(INITIAL_STATE, {

    [Types.ALL_FAQS_PROJECT_REQUEST]: allFaqsProjectRequest,
    [Types.ALL_FAQS_PROJECT_SUCCESS]: allFaqsProjectSuccess,
    [Types.ALL_FAQS_PROJECT_FAILURE]: allFaqsProjectFailure,

    [Types.SET_ALL_PROJECT_FAQ_STATE]: setALLProjectFaqState
}) 