import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { GenealFaqActions } from "redux-container/faq/GeneralFaqRedux";

// all Faqs
export function* allFaqs(api: any, action: any): any {
    try {
        const response = yield call(api.allFaqs, action.data);
  
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GenealFaqActions.getAllFaqsSuccess(response.data));
        } else {
            yield put(GenealFaqActions.getAllFaqsFailure(response.data));
        }
    } catch (error) {
        yield put(GenealFaqActions.getAllFaqsFailure(error));
    }
}




//creation 
export function* faqCreation(api: any, action: any): any {
    try {
        const response = yield call(api.createFaq, action.data);
     
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GenealFaqActions.faqCreationSuccess(response.data));
        } else {
            yield put(GenealFaqActions.faqCreationFailure(response.data));
        }
    } catch (error) {
        yield put(GenealFaqActions.faqCreationFailure(error));
    }
}

//updated
export function* faqUpdate(api: any, action: any): any {
   
    try {
        const response = yield call(api.updateGeneralFaq, action.id, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GenealFaqActions.faqUpdateSuccess(response.data));
        } else {
            yield put(GenealFaqActions.faqUpdateFailure(response.data));
        }
    } catch (error) {
        yield put(GenealFaqActions.faqUpdateFailure(error));
    }
}

//bulk updated
export function* faqBulkUpdate(api: any, action: any): any {
   
    try {
        const response = yield call(api.bulkUpdateGeneralFaq, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GenealFaqActions.faqBulkUpdateSuccess(response.data));
        } else {
            yield put(GenealFaqActions.faqBulkUpdateFailure(response.data));
        }
    } catch (error) {
        yield put(GenealFaqActions.faqBulkUpdateFailure(error));
    }
}


//delete a geneal
export function* faqDelete(api: any, action: any): any {
    try {
        const response = yield call(api.deleteGeneralFaq, action.data);
       
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GenealFaqActions.faqDeleteSuccess(response.data));
        } else {
            yield put(GenealFaqActions.faqDeleteFailure(response.data));
        }
    } catch (error) {
        yield put(GenealFaqActions.faqDeleteFailure(error));
    }
}


//bulk delete
export function* faqBulkDelete(api: any, action: any): any {

    try {
        const response = yield call(api.bulkDeleteGeneralFaq, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GenealFaqActions.faqBulkDeleteSuccess(response.data));
        } else {
            yield put(GenealFaqActions.faqBulkDeleteFailure(response.data));
        }
    } catch (error) {
        yield put(GenealFaqActions.faqBulkDeleteFailure(error));
    }
}

// faq update priority

export function* faqUpdatePriority(api:any,action:any):any{
    try {
        const response = yield call(api.faqPriorityUpdate, action.data);
        if (response.status === HttpResponseTypeEnum.Success) {
            yield put(GenealFaqActions.faqPriorityUpdateSuccess(response.data));
        } else {
            yield put(GenealFaqActions.faqPriorityUpdateFailure(response.data));
        }
    } catch (error) {
        yield put(GenealFaqActions.faqPriorityUpdateFailure(error));
    }

}
