import { call, put } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";

export function* fileUpload(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.fileUploadToS3, action.data);

    if (response.data.code === 200) {
      const uploadResponse = yield call(api.uploadeFileToS3, {
        url: response.data.preSignedUrl,
        blob: action.data.binary,
        contentType: action.data.file.type,
      });

      if (uploadResponse?.status === 200) {
        yield put(
          FileUploadActions.fileUploadSuccess({
            data: response.data,
            file: action.data.file,
          })
        );
      } else {
        yield put(FileUploadActions.fileUploadFailure(uploadResponse.data));
      }
    } else {
      yield put(FileUploadActions.fileUploadFailure(response.data));
    }
  } catch (error) {
    yield put(FileUploadActions.fileUploadFailure(error));
  }
}

export function* fileDelete(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.fileDeleteFromS3, action.data);

    if (response.data.code === 200) {
      yield put(FileUploadActions.fileDeleteSuccess(response.data));
    } else {
      yield put(FileUploadActions.fileDeleteFailure(response.data));
    }
  } catch (error) {
    yield put(FileUploadActions.fileDeleteFailure(error));
  }
}
