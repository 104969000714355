import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    getProjectCollectionListSuccess: ["response"],
    getProjectCollectionListFailure: ["error"],
    getProjectCollectionListRequest: ["data"],

    deleteProjectCollectionListSuccess: ["response"],
    deleteProjectCollectionListFailure: ["error"],
    deleteProjectCollectionListRequest: ["data"],

    changeProjectCollectionListStatusSuccess: ["response"],
    changeProjectCollectionListStatusFailure: ["error"],
    changeProjectCollectionListStatusRequest: ["data"],

    setProjectCollectionListState: ["key", "value"],
});

export const ProjectCollectionListTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  projectCollection: [],
  error: null,
  isLoading: false,
  snackBarError: "",
  isSuccess: false,
  message: null,
  totalCount: Constants.defaultTotalCount,
  dataRecievedProjectCollectionList: false,
  page: 0,
  rowsPerPage:10,
};

/* Get Project Collections */
export const getProjectCollectionListRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
    };
};

export const getProjectCollectionListSuccess = (state: any, action: any) => {
    const response = action.response;
    if (response) {
        return {
            ...state,
            error: null,
            projectCollection: response.data,
            isLoading: false,
            totalCount: response.totalCount
        };
    }
};

export const getProjectCollectionListFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
    };
};

/* delete Project Collections */
export const deleteProjectCollectionListRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccess: false,
    };
};

export const deleteProjectCollectionListSuccess = (state: any, action: any) => {
    const response = action.response;

    if (response) {
        return {
            ...state,
            error: null,
            isSuccess: true,
            message: response.message
                ? response.message
                : "Project Collection deleted Successfully!",
            isLoading: false,
            dataRecievedProjectCollectionList: true,
        };
    }
};

export const deleteProjectCollectionListFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
        isSuccess: false,
        dataRecievedProjectCollectionList: true,
    };
};

/*Update Project Collection Status */
export const changeProjectCollectionListStatusRequest = (state: any) => {
    return {
        ...state,
        isLoading: true,
        isSuccess: false,
    };
};

export const changeProjectCollectionListStatusSuccess = (state: any, action: any) => {
    const response = action.response;

    if (response) {
        return {
            ...state,
            error: null,
            isSuccess: true,
            message: response.message
                ? response.message
                : "Project Collection Status Updated Successfully",
            isLoading: false,
            dataRecievedProjectCollectionList: true,
        };
    }
};

export const changeProjectCollectionListStatusFailure = (state: any, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: action.error.message,
        dataRecievedProjectCollectionList: true,
    };
};

export const setProjectCollectionListState = (state: any, action: any) => {
    const newState = {
        ...state,
    };
    newState[action.key] = action.value;
    return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_PROJECT_COLLECTION_LIST_SUCCESS]: getProjectCollectionListSuccess,
    [Types.GET_PROJECT_COLLECTION_LIST_FAILURE]: getProjectCollectionListFailure,
    [Types.GET_PROJECT_COLLECTION_LIST_REQUEST]: getProjectCollectionListRequest,

    [Types.DELETE_PROJECT_COLLECTION_LIST_SUCCESS]: deleteProjectCollectionListSuccess,
    [Types.DELETE_PROJECT_COLLECTION_LIST_FAILURE]: deleteProjectCollectionListFailure,
    [Types.DELETE_PROJECT_COLLECTION_LIST_REQUEST]: deleteProjectCollectionListRequest,

    [Types.CHANGE_PROJECT_COLLECTION_LIST_STATUS_SUCCESS]: changeProjectCollectionListStatusSuccess,
    [Types.CHANGE_PROJECT_COLLECTION_LIST_STATUS_FAILURE]: changeProjectCollectionListStatusFailure,
    [Types.CHANGE_PROJECT_COLLECTION_LIST_STATUS_REQUEST]: changeProjectCollectionListStatusRequest,

    [Types.SET_PROJECT_COLLECTION_LIST_STATE]: setProjectCollectionListState,
});