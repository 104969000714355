import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import {
  AutocompleteComponent,
  RadioButtonGroupComponent,
  StyledRadio,
  TextFieldComponent,
} from "components/ui-elements";
import CarouselMediaUploader from "./CarouselMediaUploader";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";

import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { RouteConstants } from "routes/RouteConstants";
import { useNavigate } from "react-router-dom";
const DialogWrapper = styled(Dialog)`
  /* .MuiPaper-root {
    max-width: 800;
    width: 50rem;

    background-color: red;
  } */
  width: "50rem";
`;
const initialValues = {
  media: {
    key: "",
    name: "",
    value: {
      url: "",
      size: 1,
      width: 1,
      height: 1,
      mediaType: "IMAGE",
    },
    isActive: false,
  },
  pageManagementId: "",
  redirectTo: null,
  redirectToPage: null,
  priority: "",

  projectContent: {
    id: 0,
    launchName: "None",
  },
  redirectProject: {
    id: 0,
    launchName: "None",
  },
};

const actionDispatch = (dispatch: any) => {
  return {
    setUploadFileState: (key: any, data: any) => {
      dispatch(FileUploadActions.setUploadFileState(key, data));
    },
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
    fileDeleteRequest: (data: any) => {
      dispatch(FileUploadActions.fileDeleteRequest(data));
    },
  };
};
function CarouselMediaUploadDialog(props: any) {
  const navigate = useNavigate();

  console.log("props", props);
  const { setUploadFileState, fileUploadRequest, fileDeleteRequest } =
    actionDispatch(useDispatch());

  const [carouselData, setCarouselData] = useState<any>(initialValues);
  console.log("carouselData", carouselData);

  const { imageDetails, isLoading } = useSelector(
    (state: any) => state.fileUpload
  );

  const [mediaType, setMediaType] = useState("IMAGE");
  const [mediaUploadHelperText, setMediaUploadHelperText] = useState<any>(null);

  useEffect(() => {
    if (typeof props?.MediaObject == "object") {
      console.log("inside useEffect");
      setCarouselData({
        ...props?.MediaObject,
        redirectProject: props?.MediaObject?.projectContent
          ? props?.MediaObject?.projectContent
          : props?.MediaObject?.redirectToPage == 7000
          ? {
              id: 7000,
              launchName: "Refer Now",
            }
          : {
              id: 0,
              launchName: "None",
            },
      });
      setMediaType(props?.MediaObject?.media?.value?.mediaType);
    }
    // projectContentList.unshift({
    //   id: "0",
    //   launchName: "None",
    // });
  }, []);

  const handleMediaUpload = (event: any) => {
    console.log("handleMediaUpload", event);
    const file = event.target.files[0];
    console.log("file", file);
    let isUploadError: boolean = false;

    if (mediaType === "IMAGE" || mediaType === "VIDEO") {
      if (mediaType === "IMAGE" && file?.size > 1024 * 1024 * 10) {
        setMediaUploadHelperText("Please upload file size below 10 MB.");
        isUploadError = true;
        return;
      }

      if (mediaType === "VIDEO" && file?.size > 1024 * 1024 * 30) {
        setMediaUploadHelperText("Please upload video size of below 30 MB.");
        isUploadError = true;
        return;
      }

      if (mediaType === "IMAGE") {
        switch (file?.type) {
          case "image/svg+xml":
            setMediaUploadHelperText(null);
            isUploadError = false;
            break;
          case "image/jpeg":
            setMediaUploadHelperText(null);
            isUploadError = false;
            break;
          case "image/png":
            setMediaUploadHelperText(null);
            isUploadError = false;
            break;
          case "image/gif":
            setMediaUploadHelperText(null);
            isUploadError = false;
            break;

          default:
            isUploadError = true;
            setMediaUploadHelperText(
              "Unsupported format. Use JPEG, SVG, PNG or GIF image uploads."
            );
            break;
        }
      } else if (mediaType === "VIDEO") {
        switch (file?.type) {
          case "video/mp4":
            isUploadError = false;
            setMediaUploadHelperText(null);
            break;
          case "video/mov":
            isUploadError = false;
            setMediaUploadHelperText(null);
            break;
          case "video/webm":
            isUploadError = false;
            setMediaUploadHelperText(null);
            break;

          default:
            isUploadError = true;
            setMediaUploadHelperText(
              "Unsupported format. Use MP4,MOV or WEBM for Video uploads."
            );

            break;
        }
      }
    }

    if (file && !isUploadError) {
      setCarouselData({
        ...carouselData,
        media: {
          ...carouselData?.media,
          name: file?.name,
          key: file?.name,
          value: {
            ...carouselData?.media?.value,
            size: file?.size,
            height: file?.height,
            width: file?.width,
          },
        },
      });

      if (file) {
        const data = {
          name: file?.name,
          file,
          urlType: "upload",
          binary: "",
        };
        const reader = new FileReader();
        reader.onload = (event: any) => {
          data.binary = event.target.result;
          fileUploadRequest(data);
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  useEffect(() => {
    if (imageDetails) {
      const imageRes: any = imageDetails;
      console.log("imageRes", imageRes);

      setCarouselData({
        ...carouselData,
        media: {
          ...carouselData?.media,
          value: {
            ...carouselData?.media?.value,
            url: imageRes?.url,
          },
        },
      });
      setUploadFileState("imageDetails", null);
    }
  }, [imageDetails]);

  const { projectContentList } = useSelector((state: any) => {
    return {
      projectContentList: [
        {
          id: 0,
          launchName: "None",
        },
        {
          id: 7000,
          launchName: "Refer Now",
        },
        ...state.projectList?.data?.data,
      ],
    };
  });
  console.log("projectContentList", projectContentList);

  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  console.log("CarouselMediaUploadDialog values", values);

  const MediaTypeHandleChange = (event: any) => {
    setMediaUploadHelperText(null);
    setMediaType(event.target.value);
    setCarouselData({
      ...initialValues,
      media: {
        ...initialValues?.media,
        value: {
          ...initialValues?.media?.value,
          mediaType: event?.target.value,
        },
      },
      projectContent: {
        id: 0,
        launchName: "None",
      },
      redirectProject: {
        id: 0,
        launchName: "None",
      },
      priority: carouselData?.priority,
    });
  };

  const handleRedirectProjectChange = (event: any) => {
    console.log("handleRedirectProjectChange event", event);
    let value: any;
    if (event) {
      value = event;
    }
    // else {
    //   value = {
    //     id: 0,
    //     launchName: "None",
    //   };
    // }

    setCarouselData({
      ...carouselData,
      redirectTo: value?.id && value?.id !== 7000 ? value?.id : null,
      redirectToPage: value?.id == 7000 ? value?.id : null,
      projectContent: {
        ...carouselData?.projectContent,
        ...value,
        launchName: value?.launchName,
        id: value?.id,
      },
      redirectProject: value,
    });
  };

  const handleYoutubeLinkChange = (event: any) => {
    setCarouselData({
      ...carouselData,
      media: {
        ...carouselData?.media,
        value: {
          ...carouselData?.media?.value,
          url: event?.target?.value,
        },
      },
    });
  };

  const handleSubmit = (event: any) => {
    const pageManagementOrCarouselsLocalArray: any[] =
      values?.pageManagementOrCarousels;

    if (carouselData?.priority) {
      pageManagementOrCarouselsLocalArray[carouselData?.priority - 1] =
        carouselData;
    } else {
      pageManagementOrCarouselsLocalArray?.push({
        ...carouselData,
        priority: values?.pageManagementOrCarousels?.length + 1,
      });
    }

    setFieldValue(
      "pageManagementOrCarousels",
      pageManagementOrCarouselsLocalArray
    );
    if (!pageManagementOrCarouselsLocalArray?.length) {
      setFieldValue("isCarouselMediaPresent", false);
    } else if (
      pageManagementOrCarouselsLocalArray?.length > 0 &&
      !values?.isCarouselMediaPresent
    ) {
      setFieldValue("isCarouselMediaPresent", true);
    }

    props.closeCarouselDialog();
  };

  console.log("carouselData", carouselData);

  const handleViewIconClick = (publishedId: number, crmLaunchPhaseId: any) => {
    navigate(RouteConstants.projectCreation, {
      state: {
        isNew: false,
        ProjectListCreationId: publishedId,
        isViewMode: true,
        crmLaunchPhaseId: crmLaunchPhaseId,
      },
    });
  };

  return (
    <>
      <Box>
        <Dialog
          open={props?.open}
          maxWidth={"md"}
          PaperProps={{ sx: { padding: "0px 5px 60px 5px", width: "50rem" } }}
        >
          <DialogTitle>
            <Typography sx={{ fontWeight: "550" }}>
              Upload Carousel Media
            </Typography>
            <IconButton
              onClick={() => {
                props.closeCarouselDialog();
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid xs={6}>
                <RadioButtonGroupComponent
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={carouselData?.media?.value?.mediaType}
                  onChange={MediaTypeHandleChange}
                >
                  <FormControlLabel
                    value="IMAGE"
                    control={<StyledRadio />}
                    label="Image"
                  />
                  <FormControlLabel
                    value="VIDEO"
                    control={<StyledRadio />}
                    label="Video"
                  />
                  <FormControlLabel
                    value="YOUTUBE"
                    control={<StyledRadio />}
                    label="YouTube Link"
                  />
                </RadioButtonGroupComponent>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: "20px" }}>
            <Grid container columnSpacing={8}>
              {carouselData?.media?.value?.mediaType !== "YOUTUBE" ? (
                <>
                  <Grid item md={6} xs={6}>
                    <CarouselMediaUploader
                      onChange={handleMediaUpload}
                      fieldhelpertext={mediaUploadHelperText}
                      accept={
                        carouselData?.media?.value?.mediaType == "IMAGE"
                          ? ".JPG,.PNG,.JPEG,.SVG+XML,.GIF"
                          : ".MP4,.MOV,.WEBM"
                      }
                      loading={isLoading}
                      componentType={
                        carouselData?.media?.value?.mediaType == "IMAGE"
                          ? "img"
                          : "VIDEO"
                      }
                      // componentType="img"
                      previewimage={carouselData?.media?.value?.url}
                    />
                    {mediaUploadHelperText && (
                      <>
                        <Stack
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                        >
                          <ErrorOutlineSharpIcon
                            fontSize="small"
                            sx={{
                              // marginTop: "10px",
                              color: "red",
                            }}
                          />
                          <Typography sx={{ color: "red", textAlign: "left" }}>
                            {mediaUploadHelperText}
                          </Typography>
                        </Stack>
                      </>
                    )}
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Stack
                      mb={2}
                      direction={"column"}
                      sx={{ height: "100%", alignItems: "flex-end" }}
                    >
                      {/* <TextFieldComponent
                        select
                        label="Redirect to"
                        value={carouselData?.redirectProject}
                        onChange={handleRedirectProjectChange}
                      >
                        <MenuItem value={"none"} key={0}>
                          {"None"}
                        </MenuItem>
                        {projectContentList.map((action: any) => {
                          return (
                            <MenuItem value={action?.id} key={action.id}>
                              {action.launchName}
                            </MenuItem>
                          );
                        })}
                      </TextFieldComponent>
                    </Stack>

                    <Stack justifyContent={"flex-end"}>
                      </TextFieldComponent> */}

                      <FormControl className="filter-dropdown">
                        <AutocompleteComponent
                          options={projectContentList}
                          getOptionLabel={(option: any) => option?.launchName}
                          label="Redirect to"
                          onChange={(
                            event: React.SyntheticEvent,
                            newValue: any | null
                          ) => {
                            console.log("newValue", newValue);
                            handleRedirectProjectChange(newValue);
                          }}
                          value={carouselData?.redirectProject}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option?.id === value?.id
                          }
                          key={(values: any) => values.id}
                          // renderOption={(props: any, option: any) => {
                          //   <>
                          //     <Stack>
                          //       <li
                          //         {...props}
                          //         sx={{
                          //           width: `${
                          //             option == projectContentList[0]
                          //               ? "100%"
                          //               : "60%"
                          //           }`,
                          //         }}
                          //       >
                          //         {option.launchName}
                          //       </li>
                          //       {option !== projectContentList[0] &&
                          //         option !== projectContentList[0] && (
                          //           <IconButton
                          //             size="small"
                          //             onClick={(e: any) => {}}
                          //           >
                          //             <VisibilityOutlinedIcon />
                          //           </IconButton>
                          //         )}
                          //     </Stack>
                          //   </>;
                          // }}

                          renderOption={(props: any, option: any) => (
                            <>
                              <Stack
                                direction="row"
                                justifyContent={"space-between"}
                              >
                                <div
                                  {...props}
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div>{option?.launchName}</div>
                                </div>
                                <div>
                                  {option !== projectContentList[0] &&
                                  option !== projectContentList[1] ? (
                                    <IconButton
                                      onClick={() =>
                                        handleViewIconClick(
                                          option?.id,
                                          option?.crmLaunchPhaseId
                                        )
                                      }
                                    >
                                      <VisibilityOutlinedIcon />
                                    </IconButton>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Stack>
                            </>
                          )}
                          renderInput={(params: any) => (
                            <TextField {...params} />
                          )}
                        />
                      </FormControl>

                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={!carouselData?.media?.value?.url}
                      >
                        Upload
                      </Button>
                    </Stack>
                  </Grid>
                </>
              ) : (
                <Grid item md={12} xs={12}>
                  <Stack
                    direction={"column"}
                    sx={{ height: "100%", alignItems: "flex-end" }}
                  >
                    <TextFieldComponent
                      label="Youtube Link"
                      value={carouselData?.media?.value?.url}
                      onChange={handleYoutubeLinkChange}
                    />
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={!carouselData?.media?.value?.url}
                    >
                      Upload
                    </Button>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default CarouselMediaUploadDialog;
