import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import PageActions from "redux-container/landing-page-management/LandingPageDashboardRedux";

export function* getLandingPageDashboard(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getLandingPageDashboard, action.data);
    yield put(PageActions.getLandingPageDashboardSuccess(response.data));
  } catch (error) {
    yield put(PageActions.getLandingPageDashboardFailure(error));
  }
}
