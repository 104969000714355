export const GeneralInfoConst = {
  projectNameCharacterLimit: 20,
  launchNameCharacterLimit: 50,
  shortDescriptionCharacterLimit: 75,
  fullDescriptionCharacterLimit: 240,
  fomoContentCharacterLimit: 50,
  sectionHeadingCharacterLimit: 40,
  reraAddressCharacterLimit: 240,
};

export const QuarterlyMonth: any = {
  "Q1 (Jan,Feb,Mar)": [
    { month: "January", monthBasedValues: "" },
    { month: "February", monthBasedValues: "" },
    { month: "March", monthBasedValues: "" },
  ],
  "Q2 (Apr,May,Jun)": [
    { month: "April", monthBasedValues: "" },
    { month: "May", monthBasedValues: "" },
    { month: "June", monthBasedValues: "" },
  ],
  "Q3 (Jul,Aug,Sep)": [
    { month: "July", monthBasedValues: "" },
    { month: "August", monthBasedValues: "" },
    { month: "September", monthBasedValues: "" },
  ],
  "Q4 (Oct,Nov,Dec)": [
    { month: "October", monthBasedValues: "" },
    { month: "November", monthBasedValues: "" },
    { month: "December", monthBasedValues: "" },
  ],
};

export const HalfYearMonths: any = {
  "HY1 (Jan,Feb,Mar,Apr,May,Jun)": [
    { month: "January", monthBasedValues: "" },
    { month: "February", monthBasedValues: "" },
    { month: "March", monthBasedValues: "" },
    { month: "April", monthBasedValues: "" },
    { month: "May", monthBasedValues: "" },
    { month: "June", monthBasedValues: "" },
  ],
  "HY2 (Jul,Aug,Sep,Oct,Nov,Dec)": [
    { month: "July", monthBasedValues: "" },
    { month: "August", monthBasedValues: "" },
    { month: "September", monthBasedValues: "" },
    { month: "October", monthBasedValues: "" },
    { month: "November", monthBasedValues: "" },
    { month: "December", monthBasedValues: "" },
  ],
};

export const QuarterlyMonthList = [
  {
    label: "Quater 1 (Jan,Feb,Mar)",
    value: "Q1 (Jan,Feb,Mar)",
  },
  {
    label: "Quater 2 (Apr,May,Jun)",
    value: "Q2 (Apr,May,Jun)",
  },
  {
    label: "Quater 3 (Jul,Aug,Sep)",
    value: "Q3 (Jul,Aug,Sep)",
  },
  {
    label: "Quater 4 (Oct,Nov,Dec)",
    value: "Q4 (Oct,Nov,Dec)",
  },
];

export const HalfYearMonthsList = [
  {
    label: "Half Year 1 (Jan,Feb,Mar,Apr,May,Jun)",
    value: "HY1 (Jan,Feb,Mar,Apr,Mar,Jun)",
  },
  {
    label: "Half Year 2 (Jul,Aug,Sep,Oct,Nov,Dec)",
    value: "HY2 (Jul,Aug,Sep,Oct,Nov,Dec)",
  },
];

export const monthsConstants: any = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
