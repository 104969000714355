import { Avatar, Box, Dialog, styled } from "@mui/material";

export const MyProfileStyleWrapper = styled(Box)`
  & .MuiDialogActions-root {
    padding: 1rem;
    padding-bottom: 2rem;
  }
  & .MuiDialogContent-root {
    padding: 0.5rem 1.5rem;
  }
  && .kye-typography {
    font-size: 1.125rem;
    color: #707070;
    margin-bottom: 25px;
    font-family: "Jost-Light";
  }
  && .value-typography {
    font-size: 1.125rem;
    color: #141615;
  }
  && .title {
    color: #141615;
    font-size: 1.5rem;
    margin-bottom: 25px;
  }
  && .contact-details {
    color: #141615;
    font-size: 1rem;
    margin-bottom: 25px;
    /* margin-bottom: 20px; */
  }
`;

export const FullWidthDialogBox = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 650px;
  }
  && .MuiDialogContent-root {
    padding: 1.5rem;
  }
  && .MuiDialogTitle-root {
    padding-bottom: 0px;
  }
`;

export const TextFieldSection = styled(Box)`
  margin: 1rem 0;
`;

export const CloseIcon = styled(Avatar)`
  width: 1rem;
  height: 1rem;
`;
