import * as Yup from "yup";

export const newHoablValidationSchema = Yup.object().shape({
  firstMessage: Yup.string().required("This field is mandatory"),
  welcomeMessage: Yup.string().required("This field is madatory"),
  finalMessage: Yup.string().required("This field is madatory"),
  // inactiveMessage: Yup.string().required("This field is madatory"),
  categories: Yup.array().of(
    Yup.object().shape({
      text: Yup.string().required("This field is mandatory"),
      action: Yup.string().required("This field is madatory").nullable(),
      message: Yup.string().required("This field is madatory"),
      options: Yup.array().of(
        Yup.object().shape({
          text: Yup.string().required("This field is mandatory"),
          action: Yup.string().required("This field is madatory").nullable(),
        })
      ),
    })
  ),
});
