import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { Fragment, useEffect, useMemo, useState } from "react";
import PageHeading from "components/page-heading/PageHeading";
import {
  ButtonComponent,
  LoadingButtonComponent,
  TextFieldComponent,
  FormFieldContainer,
} from "components/ui-elements";
import MediaAssets from "assets";
import { Form, Formik, FormikValues, getIn } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { InsightsCreationConstants } from "./InsightsCreationConstants";
import { RouteConstants } from "routes/RouteConstants";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import styles from "./InsightsCreation.module.css";
import QuillEditor from "components/quill-editor/QuillEditor";
import {
  insightCreationDefaultValue,
  InsightsCreationLabels,
  insightMediaObj,
} from "./InsightsCreationInitialValue";
import { useDispatch, useSelector } from "react-redux";
import { InsightsCreationActions } from "redux-container/insights-creation/InsightsCreationRedux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { StatusTypeEnumInsight } from "enumerations/StatusTypeEnum";
import InsightsCreationMediaDescription from "./InsightsCreationMediaDescription";
import moment from "moment";
import SettingMenuDropdown from "components/ui-elements/setting-menu-dropdown/SettingMenuDropdown";
import { stripHtml } from "utils/ValidationUtils";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";

const InsightsCreationContainer = styled(FormFieldContainer)`
  padding: 0;
`;

const actionDispatch = (dispatch: any) => {
  return {
    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
  };
};

const InsightsCreation = () => {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const { postAuditLogRequest } = actionDispatch(useDispatch());

  const { dataRecived, data, error, responseForNewAudit } = useSelector(
    (state: any) => state.insightsCreation
  );

  const { modules } = useSelector((state: any) => {
    return {
      modules: state?.rolesAndModules?.module,
    };
  });
  const [getInsightState, setGetInsightState] = useState({
    isViewMode: location?.state?.isView,
  });
  const isEditMode = location?.state ? true : false; // only to handle audit logs.
  const [showImageUploadDialog, setShowImageUploadDialog] = useState(false);
  const [ImageUploadedInDialog, setImageUploadInDialog] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [isConfirmDeleteInsight, setIsConfirmDeleteInsight] = useState(false);

  const [modulesAudited, setModulesAudited] = useState<any>([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [shouldChangeStatus, setShouldChangeStatus] = useState<boolean>(false);
  const [openPublishConfirmationDialog, setOpenPublishConfirmationDialog] =
    useState<boolean>(false);
  const [isStatusUpdated, setIsStautsUpdated] = useState<boolean>(false);
  const [isInsightDeleted, setInsightDeleted] = useState<boolean>(false);
  const [insightStatusValue, setInsightStatusValue] = useState<string>(
    StatusTypeEnumInsight.Published
  );
  const [captureMediaAudit, setCaptureMediaAudit] = useState<any>();

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);

  const deleteInsightsCreationImageOrVideo = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const deleteInsightsCreations = () => {
    setIsConfirmDeleteInsight(true);
  };

  const handleCloseDeleteInsight = () => {
    setIsConfirmDeleteInsight(false);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const [currentlyOpenedIndex, setCurrentlyOpenedIndex] = useState(999);

  const handleShowInsightsCreationMediaDialog = (imageIndex: number) => {
    setCurrentlyOpenedIndex(imageIndex);
    setShowImageUploadDialog(true);
  };
  const handleShowInsightsCreationMedia = (imageIndex: number) => {
    setCurrentlyOpenedIndex(imageIndex);
    setShowImageUploadDialog(true);
  };
  const handleShowInsightsCreationCancel = () => {
    setShowImageUploadDialog(true);
  };
  //title

  const handleImageSuccesFullyUploaded = () => {
    setImageUploadInDialog(true);
  };

  //Get Insights By Id
  useEffect(() => {
    if (location?.state !== null) {
      dispatch(
        InsightsCreationActions.getInsightsByIdRequest(location?.state?.id)
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (dataRecived) {
      setOpenPublishConfirmationDialog(false);
    }
  }, [dataRecived]);

  const navigate = useNavigate();
  const displayTitleForBreadCrumb =
    location?.state?.displayTitle?.length > 50
      ? location?.state?.displayTitle.slice(0, 40) +
        "...." +
        location?.state?.displayTitle.slice(-5)
      : location?.state?.displayTitle;

  const breadcrumbRoutes: any = [
    {
      name: "Resource Management",
      route: RouteConstants.resourceManagementDashboard,
    },
    {
      name: "Insight List",
      route: RouteConstants.insightList,
    },
    location?.state?.displayTitle && getInsightState.isViewMode
      ? {
          name: `View - ${displayTitleForBreadCrumb}`,
          route: RouteConstants.insightCreation,
        }
      : location?.state?.displayTitle && getInsightState.isViewMode !== true
      ? {
          name: `Edit - ${displayTitleForBreadCrumb}`,
          route: RouteConstants.insightCreation,
        }
      : {
          name: `Create New`,
          route: RouteConstants.insightCreation,
        },
  ];

  const updatedValues = useMemo(() => {
    if (location.state) {
      return {
        ...insightCreationDefaultValue,
        displayTitle: location.state?.displayTitle,
        status: location.state?.status,
        insightsMedia: insightCreationDefaultValue.insightsMedia.map(
          (mediaItems, mediaIndex) =>
            location.state?.insightsMedia?.find(
              (
                _seletedItems: typeof location.state.insightsMedia[0],
                selectedItemIndex: number
              ) => selectedItemIndex === mediaIndex
            ) || mediaItems
        ),
      };
    } else {
      return { ...insightCreationDefaultValue };
    }
  }, [location.state]);

  const validationSchema = Yup.object().shape({
    displayTitle: Yup.string().required("Requried"),
  });

  const createInsightsCreation = () => {
    // -1 indicates go one step back
    const goBack = -1;
    navigate(goBack);
  };

  const handleOnGlobalSave = (values: FormikValues) => {
    let previousValues: any = location?.state;
    for (
      let index = previousValues?.insightsMedia.length;
      index < values?.insightsMedia.length;
      index++
    ) {
      previousValues.insightsMedia[index] = insightMediaObj;
    }
    const insightPayload = {
      ...values,
      insightsMedia: values.insightsMedia.filter(
        (insight: typeof values.insightMedia[0]) =>
          insight?.media?.value?.url != "" &&
          stripHtml(insight?.description)?.length > 0
      ),
    };
    if (location.state) {
      dispatch(
        InsightsCreationActions.insightsCreationUpdateRequest({
          data: insightPayload,
          id: location?.state?.id,
        })
      );
    } else {
      dispatch(InsightsCreationActions.insightsCreationRequest(insightPayload));
    }

    // audit logs for insights
    let auditedLogArray: any = [];
    if (isEditMode) {
      Object?.keys(values)?.map((key: any) => {
        if (typeof values[key] === "object") {
          if (Array.isArray(values[key])) {
            // inside detailed infor array
            values[key]?.map((elementKey: any, elementKeyIndex: number) => {
              if (typeof values[key][elementKeyIndex] === "object") {
                // detailed info media
                Object?.keys(values[key][elementKeyIndex])?.map(
                  (mediaKey: any, mediaKeyIndex: number) => {
                    // inside media object
                    if (
                      typeof values[key][elementKeyIndex][mediaKey] === "object"
                    ) {
                      Object?.keys(values[key][elementKeyIndex][mediaKey])?.map(
                        (mediaElements: any, mediaElementsIndex: number) => {
                          if (
                            typeof values[key][elementKeyIndex][mediaKey][
                              mediaElements
                            ] === "object" &&
                            values[key][elementKeyIndex][mediaKey][
                              mediaElements
                            ].url
                          ) {
                            Object.keys(
                              values[key][elementKeyIndex][mediaKey][
                                mediaElements
                              ]
                            )?.map((mediaUrlKey: any, mediaUrlIndex) => {
                              if (
                                // checking for media object.....
                                InsightsCreationLabels[key][elementKeyIndex][
                                  mediaKey
                                ][mediaElements][mediaUrlKey] !== undefined &&
                                (values[key][elementKeyIndex][mediaKey][
                                  mediaElements
                                ][mediaUrlKey] !== undefined ||
                                  previousValues[key][elementKeyIndex][
                                    mediaKey
                                  ][mediaElements][mediaUrlKey] !==
                                    undefined) &&
                                values[key][elementKeyIndex][mediaKey][
                                  mediaElements
                                ][mediaUrlKey] !==
                                  previousValues[key][elementKeyIndex][
                                    mediaKey
                                  ][mediaElements][mediaUrlKey]
                              ) {
                                let auditObject = {
                                  itemAffected: `${InsightsCreationLabels[key][elementKeyIndex][mediaKey][mediaElements][mediaUrlKey]} of "${previousValues?.displayTitle}" insight`,
                                  newValue: values[key][elementKeyIndex][
                                    mediaKey
                                  ][mediaElements][mediaUrlKey]
                                    ? values[key][elementKeyIndex][mediaKey][
                                        mediaElements
                                      ][mediaUrlKey]
                                    : "",
                                  oldValue: previousValues[key][
                                    elementKeyIndex
                                  ][mediaKey][mediaElements][mediaUrlKey]
                                    ? previousValues[key][elementKeyIndex][
                                        mediaKey
                                      ][mediaElements][mediaUrlKey]
                                    : "",
                                };
                                auditedLogArray.push(auditObject); // pushing insights media url
                              }
                            });
                          } else {
                            if (
                              typeof values[key][elementKeyIndex][mediaKey][
                                mediaElements
                              ] !== "object" &&
                              InsightsCreationLabels[key][elementKeyIndex][
                                mediaKey
                              ][mediaElements] !== undefined &&
                              values[key][elementKeyIndex][mediaKey][
                                mediaElements
                              ] !== "" &&
                              values[key][elementKeyIndex][mediaKey][
                                mediaElements
                              ] !==
                                previousValues[key][elementKeyIndex][mediaKey][
                                  mediaElements
                                ]
                            ) {
                              let auditObject = {
                                itemAffected: `${InsightsCreationLabels[key][elementKeyIndex][mediaKey][mediaElements]} of "${previousValues?.displayTitle}" insight`,
                                newValue:
                                  values[key][elementKeyIndex][mediaKey][
                                    mediaElements
                                  ],
                                oldValue:
                                  previousValues[key][elementKeyIndex][
                                    mediaKey
                                  ][mediaElements],
                              };
                              auditedLogArray.push(auditObject); // pushing insights Media description
                            }
                          }
                        }
                      );
                    } else {
                      if (
                        InsightsCreationLabels[key][elementKeyIndex][
                          mediaKey
                        ] !== undefined &&
                        values[key][elementKeyIndex][mediaKey] &&
                        values[key][elementKeyIndex][mediaKey] !==
                          previousValues[key][elementKeyIndex][mediaKey]
                      ) {
                        let auditObject = {
                          itemAffected: `${InsightsCreationLabels[key][elementKeyIndex][mediaKey]} of "${previousValues?.displayTitle}" Insight`,
                          newValue: stripHtml(
                            values[key][elementKeyIndex][mediaKey]
                          ),
                          oldValue: stripHtml(
                            previousValues[key][elementKeyIndex][mediaKey]
                          ),
                        };
                        if (!auditObject.newValue && auditObject.oldValue) {
                          let mediaAuditObj = {
                            itemAffected: `Deleted Insight Media and Description at position ${
                              Number(elementKeyIndex) + 1
                            }`,
                            newValue: "",
                            oldValue: "",
                          };
                          auditedLogArray.push(mediaAuditObj);
                        } else {
                          auditedLogArray.push(auditObject); // pushing insights description.
                        }
                      }
                    }
                  }
                );
              }
            });
          }
        } else {
          if (
            InsightsCreationLabels[key] !== undefined &&
            values[key] !== previousValues[key]
          ) {
            let auditObject = {
              itemAffected: `${InsightsCreationLabels[key]} of a Insight - "${values?.displayTitle}"`,
              newValue: values[key],
              oldValue: previousValues[key],
            };
            auditedLogArray.push(auditObject); // pushing all upper level keys of the main object
          }
        }
      });
      setModulesAudited(auditedLogArray);
    } else {
      // else block will execute for newly created insight
      setModulesAudited([
        {
          itemAffected: `Created New Insight - "${values?.displayTitle}"`,
          newValue: "",
          oldValue: "",
        },
      ]);
    }
  };

  //delete the insigth by id
  const handleConfirmDelete = () => {
    if (location.state !== null) {
      dispatch(
        InsightsCreationActions.insightsCreationDeleteRequest(
          location?.state?.id
        )
      );
      setInsightDeleted(true);
    }
    handleCloseDeleteInsight();
  };

  //mark as Published Or Inactive
  const handleStatusChange = () => {
    let currentStatus: string;
    if (location?.state?.status === StatusTypeEnumInsight.Published) {
      currentStatus = StatusTypeEnumInsight.Inactive;
    } else {
      currentStatus = StatusTypeEnumInsight.Published;
    }
    const insightStatusPayload = {
      insightIds: [location?.state?.id],
      status: currentStatus,
    };
    setInsightStatusValue(currentStatus);
    setIsStautsUpdated(true);
    dispatch(
      InsightsCreationActions.insightsCreationUpdateStatusRequest(
        insightStatusPayload
      )
    );
  };

  const handleEditClick = () => {
    setGetInsightState((prev) => ({
      ...prev,
      isViewMode: false,
    }));
  };

  //success Close of Flash Message
  const handleCloseFlashMessage = (values: any) => {
    dispatch(
      InsightsCreationActions.setInsightsCreationState("dataRecived", false)
    );
    dispatch(FileUploadActions.setUploadFileState("isDeleteConfirm", false));

    const moduleObject = modules?.find((element: any) => {
      return element?.name === "Resources Management";
    });

    if (!error) {
      if (isInsightDeleted) {
        const postAuditRequestbodyForInternalDelete = {
          ipAddress: ipAddress,
          moduleId: moduleObject?.id,
          route: null,
          auditedObject: null,
          modulesAudited: [
            {
              itemAffected: `Insight - "${location?.state?.displayTitle}" is Deleted. (ID ${location?.state?.id})`,
              newValue: "",
              oldValue: "",
            },
          ],
          status: "2003",
        };
        postAuditLogRequest(postAuditRequestbodyForInternalDelete);
      } else if (isStatusUpdated) {
        const { status, ...restValues } = values;
        const postAuditRequestbodyForInternalStatusChange = {
          ipAddress: ipAddress,
          moduleId: moduleObject?.id,
          route: location.pathname,
          auditedObject: {
            ...restValues,
            status: insightStatusValue,
          },
          modulesAudited: [
            {
              itemAffected: `Insight - status of "${location?.state?.displayTitle}"`,
              newValue:
                insightStatusValue === "1001" ? "Published" : "InActive",
              oldValue:
                location?.state?.status === "1001" ? "Published" : "InActive",
            },
          ],
          status: "2001",
        };
        postAuditLogRequest(postAuditRequestbodyForInternalStatusChange);
      } else {
        const postAuditRequestbody = {
          ipAddress: ipAddress,
          moduleId: moduleObject?.id,
          route: location.pathname,
          auditedObject: location?.state?.id ? values : responseForNewAudit,
          modulesAudited: modulesAudited,
          status: location?.state?.id ? "2001" : "2002",
        };
        postAuditLogRequest(postAuditRequestbody);
      }
      navigate(-1);
    }
  };

  //Tool Tip
  const headingToolTipText = () => {
    return (
      <div className={styles["heading-tooltip-text"]}>
        <p>Insights will appear on the app in the following sequence:</p>
        <ol>
          <li>Display Title </li>
          <li>Detailed Info as per the sequence below</li>
        </ol>
      </div>
    );
  };

  const handleTextEditor = (
    name: string,
    newValue: string,
    { setFieldValue }: any
  ) => {
    setFieldValue(name, newValue);
  };

  // status and delete confirmation dialog handlers
  const handleOpenStatusConfirmationDialog = (isChangingStatus: boolean) => {
    if (isChangingStatus) {
      setShouldChangeStatus(true);
    }
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setShouldChangeStatus(false);
  };

  const handleOkConfirmationDialog = () => {
    if (shouldChangeStatus) {
      handleStatusChange();
    } else {
      handleConfirmDelete();
    }
    handleCloseConfirmationDialog();
  };

  // publish confirmation dialog handlers
  const handleClosePublishConfirmationDialog = () => {
    setOpenPublishConfirmationDialog(false);
  };

  const handleOkPublishConfirmationDialog = (values: FormikValues) => {
    handleCloseConfirmationDialog();
    handleOnGlobalSave(values);
  };

  return (
    <Fragment>
      <Formik
        initialValues={updatedValues}
        validationSchema={validationSchema}
        onSubmit={handleOnGlobalSave}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          isValid,
          errors,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <PageHeading title="Resource Management" />
            <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
            <ConfirmationDialog
              shouldOpen={openPublishConfirmationDialog}
              title={"Publish Insight."}
              content={
                "This Action will Publish Insights. Are you sure you want to continue?"
              }
              okText={"Publish"}
              cancelHandler={handleClosePublishConfirmationDialog}
              okHandler={() => handleOkPublishConfirmationDialog(values)}
            />

            {/* confirmationDialog for status edit and delete */}
            <ConfirmationDialog
              shouldOpen={openConfirmationDialog}
              title={
                shouldChangeStatus
                  ? "Change Status of Insight?"
                  : "Delete Insight?"
              }
              content={
                shouldChangeStatus
                  ? "This Action will change the status of Insight. Are you sure you want to continue?"
                  : "This action will Delete Insight. Are you sure you want to continue?"
              }
              okText={shouldChangeStatus ? "Change Status" : "Delete"}
              cancelHandler={handleCloseConfirmationDialog}
              okHandler={handleOkConfirmationDialog}
            />
            <Container className="main-container">
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack spacing={2}>
                  <IconButton edge={"start"} onClick={() => navigate(-1)}>
                    <ChevronLeftIcon />
                  </IconButton>
                  {/* Display Title Field*/}
                  <Typography color={"palette.secondary.dark"} variant="h6">
                    {(location?.state?.displayTitle &&
                      getInsightState.isViewMode !== true) ||
                    getInsightState.isViewMode
                      ? location.state?.id &&
                        location?.state?.displayTitle.length > 50
                        ? location?.state?.displayTitle.slice(0, 40) + "...."
                        : location?.state?.displayTitle
                      : "Create Insights"}
                  </Typography>
                  {(location?.state?.displayTitle &&
                    getInsightState.isViewMode !== true) ||
                  getInsightState.isViewMode ? (
                    <SettingMenuDropdown
                      disabled={getInsightState.isViewMode || false}
                      onstatuschange={() =>
                        handleOpenStatusConfirmationDialog(true)
                      }
                      ondelete={() => handleOpenStatusConfirmationDialog(false)} // setting params as false to identify this is delete action.
                      statusmenutext={
                        values.status === StatusTypeEnumInsight.Published
                          ? "Inactive"
                          : "Published"
                      }
                    />
                  ) : (
                    <IconButton>
                      <Tooltip title={headingToolTipText()} placement="top">
                        <Avatar
                          className={styles["Avator"]}
                          src={MediaAssets.ic_info}
                          variant="rounded"
                        />
                      </Tooltip>
                    </IconButton>
                  )}
                </Stack>
                {location?.state?.displayTitle && getInsightState.isViewMode ? (
                  <LoadingButtonComponent
                    onClick={handleEditClick}
                    color={"inherit"}
                  >
                    Edit
                  </LoadingButtonComponent>
                ) : (
                  <Stack direction={"row"} columnGap={2}>
                    <ButtonComponent
                      onClick={createInsightsCreation}
                      color={"inherit"}
                    >
                      Cancel
                    </ButtonComponent>
                    {/* <ButtonComponent
                      type="reset"
                      disabled={!isValid}
                      data-testid="save-and-new-button"
                      onClick={() => handleSaveAndNew(values)}
                    >
                      Save as Drafts
                    </ButtonComponent> */}
                    <LoadingButtonComponent
                      // type="submit"
                      data-testid="form-save-button"
                      disabled={
                        (stripHtml(values?.insightsMedia[0]?.description) &&
                          !values?.insightsMedia[0]?.media?.value?.url) ||
                        (!stripHtml(values?.insightsMedia[0]?.description) &&
                          values?.insightsMedia[0]?.media?.value?.url) ||
                        (stripHtml(values?.insightsMedia[1]?.description) &&
                          !values?.insightsMedia[1]?.media?.value?.url) ||
                        (!stripHtml(values?.insightsMedia[1]?.description) &&
                          values?.insightsMedia[1]?.media?.value?.url) ||
                        (stripHtml(values?.insightsMedia[2]?.description) &&
                          !values?.insightsMedia[2]?.media?.value?.url) ||
                        (!stripHtml(values?.insightsMedia[2]?.description) &&
                          values?.insightsMedia[2]?.media?.value?.url) ||
                        !isValid ||
                        !dirty
                      }
                      onClick={() => setOpenPublishConfirmationDialog(true)}
                    >
                      {location.state !== null ? "Publish Changes" : "Publish"}
                    </LoadingButtonComponent>
                  </Stack>
                )}
              </Stack>
              {getInsightState.isViewMode && (
                <Stack columnGap={"1rem"} justifyContent={"flex-start"}>
                  <Typography>
                    <Typography component={"i"}>Created on:&nbsp;</Typography>
                    {`${
                      // format(new Date(values.createdAt), "dd/MM/yyyy") || ""
                      moment(location?.state?.createdAt).format("L") || ""
                    } by `}
                    <Link>
                      {location?.state?.insightsCreatedAdmin?.firstName ||
                        "User"}
                    </Link>
                  </Typography>
                  <Typography>
                    <Typography component={"i"}>Modified on:&nbsp;</Typography>
                    {`${
                      // format(new Date(values.updatedAt), "dd/MM/yyyy") || ""
                      moment(location?.state?.createdAt).format("L") || ""
                    } by `}
                    <Link>
                      {location?.state?.insightsModifiedAdmin?.firstName ||
                        "User"}
                    </Link>
                  </Typography>
                </Stack>
              )}
              <InsightsCreationContainer>
                <Grid container className={styles["box"]}>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <TextFieldComponent
                      placeholder="Enter"
                      label="Display Title"
                      disabled={getInsightState.isViewMode || false}
                      name={`displayTitle`}
                      value={values.displayTitle}
                      onChange={handleChange}
                      inputProps={{
                        maxLength:
                          InsightsCreationConstants.titleCharacterLimit,
                      }}
                      onBlur={handleBlur}
                      error={
                        touched?.displayTitle && values.displayTitle.length < 1
                      }
                      fieldhelpertext={
                        getIn(touched, "displayTitle") &&
                        getIn(errors, "displayTitle")
                      }
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mt: "0.5rem" }} />
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={styles["box"]}
                  >
                    <Typography
                      variant="body1"
                      color={"palette.secondary.dark"}
                    >
                      Detailed Information
                    </Typography>
                  </Grid>
                  {values?.insightsMedia?.length > 0 &&
                    values?.insightsMedia?.map(
                      (_element: any, index: number) => {
                        return (
                          <Grid
                            key={index + 1}
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Grid
                              container
                              key={index}
                              justifyContent={"space-between"}
                            >
                              <Grid
                                item
                                xs={3}
                                md={3}
                                lg={4}
                                xl={3}
                                className={styles["box"]}
                              >
                                <Typography>Upload Media</Typography>
                                <InsightsCreationMediaDescription
                                  index={index}
                                  isViewMode={getInsightState.isViewMode}
                                  handleShowInsightsCreationMediaDialog={
                                    handleShowInsightsCreationMediaDialog
                                  }
                                  deleteInsightsCreationImageOrVideo={
                                    deleteInsightsCreationImageOrVideo
                                  }
                                  showImageUploadDialog={showImageUploadDialog}
                                  currentlyOpenedIndex={currentlyOpenedIndex}
                                  handleImageSuccesFullyUploaded={
                                    handleImageSuccesFullyUploaded
                                  }
                                  isConfirmDeleteDialogOpen={
                                    isConfirmDeleteDialogOpen
                                  }
                                  handleCloseConfirmDialog={
                                    handleCloseConfirmDialog
                                  }
                                  handleShowInsightsCreationMedia={
                                    handleShowInsightsCreationMedia
                                  }
                                  handleShowInsightsCreationCancel={
                                    handleShowInsightsCreationCancel
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                xs={9}
                                md={9}
                                lg={8}
                                xl={9}
                                className={styles["box"]}
                              >
                                <Stack justifyContent={"flex-start"}>
                                  <Typography>Description</Typography>
                                  <IconButton>
                                    <Tooltip
                                      title={
                                        "Description can be a combination of more than 1 Paragraph. Maximum Character limit for the complete insight is 3,000."
                                      }
                                      placement="top"
                                    >
                                      <Avatar
                                        className={styles["Avator"]}
                                        src={MediaAssets.ic_info}
                                        variant="rounded"
                                      />
                                    </Tooltip>
                                  </IconButton>
                                </Stack>
                                <QuillEditor
                                  placeholder="Enter description"
                                  className={styles["QuillEditor"]}
                                  name={`insightsMedia[${index}].description`}
                                  value={
                                    values?.insightsMedia[index]?.description
                                  }
                                  onChange={(
                                    name: string,
                                    newValue: string,
                                    options: any
                                  ) => {
                                    handleTextEditor(name, newValue, {
                                      setFieldValue,
                                    });
                                  }}
                                  inputProps={{
                                    maxLength:
                                      InsightsCreationConstants.description,
                                  }}
                                  style={{ height: "195px" }}
                                  disabled={getInsightState.isViewMode}
                                  fieldhelpertext={
                                    !stripHtml(
                                      values?.insightsMedia[index]?.description
                                    ) &&
                                    values?.insightsMedia[index]?.media?.value
                                      ?.url &&
                                    "Required"
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Divider sx={{ mt: "0.5rem" }} key={index} />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    )}
                </Grid>
              </InsightsCreationContainer>
            </Container>
            <ConfirmationDialog
              shouldOpen={isConfirmDeleteInsight}
              title="Delete Insights Creation Media?"
              content="This action will delete the Insights Creation Media. Are you sure you want to continue?"
              okText="Delete"
              cancelHandler={handleCloseDeleteInsight}
              okHandler={() => handleConfirmDelete()}
              // okHandler={() => handleCloseConfirmDialog()}
            />
            <FlashMessageDialog
              cancel={false}
              shouldOpen={dataRecived}
              content={data}
              isSuccess={error === null ? true : false}
              cancelHandler={() => handleCloseFlashMessage(values)}
            />
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default InsightsCreation;
