import { createTheme, responsiveFontSizes } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

let HoABLTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 690,
      md: 890,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    primary: {
      main: "#676ac0",
      light: "#5348d0",
      dark: "#494a67",
    },
    secondary: {
      dark: "#242e4c",
      main: "#f2f0ff",
    },
    text: {
      primary: "#6e6b8f",
      secondary: "#9b9aa8",
    },
    grey: {
      50: "#f8f8f8",
      100: "#f7f6f8",
      200: "#eeeeee",
      300: "#c4c4ce",
      400: "#707070",
      500: "#f7f7f7",
      600: "#979797",
      700: "#a9a9a9",
      800: "#808080",
      900: "#686680",
      A100: "linear-gradient(to left, rgba(246, 246, 249, 0), #eaeaf1)",
      A200: "#9795af",
      A400: "#f9f9fa",
      A700: "#5a5f62",
    },
    info: {
      main: "#4a39ff",
      light: "#c0bcef",
    },
    success: {
      main: "#3cc012",
    },
    error: {
      main: "#ff485a",
      dark: "#ff3939",
    },
    background: {
      paper: "#fff",
      default: "transparent",
    },
    action: {
      disabled: "#d3d3d9",
      active: "#17171c",
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: "Jost-Regular",
    fontSize: 10,
    h5: {
      fontFamily: "Jost-Regular",
    },
    h6: {
      fontFamily: "Jost-Regular",
    },
    body1: {
      fontFamily: "Jost-Regular",
    },
    body2: {
      fontFamily: "Jost-Regular",
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "large",
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
        disableTouchRipple: true,
        variant: "outlined",
      },
    },
    MuiTypography: {
      defaultProps: {
        color: "#000",
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        disableTouchRipple: true,
        disableElevation: true,
        loadingPosition: "start",
        disableFocusRipple: true,
        variant: "contained",
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "default",
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: "outlined",
        margin: "none",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        margin: "none",
        size: "medium",
        IconComponent: ExpandMoreIcon,
        MenuProps: {
          PaperProps: { sx: { maxHeight: 250 } },
          MenuListProps: { sx: { ".MuiMenuItem-root": { minHeight: 35 } } },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },
    MuiLink: {
      defaultProps: {
        color: "inherit",
        underline: "none",
      },
    },
    MuiStack: {
      defaultProps: {
        direction: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    MuiDrawer: {
      defaultProps: {
        color: "inherit",
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: "inherit",
        position: "fixed",
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: "square",
        sizes: "small",
        alt: "icons",
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
        fixed: false,
        disableGutters: true,
      },
    },
    MuiInputAdornment: {
      defaultProps: {
        position: "start",
      },
    },
  },
});

export default HoABLTheme = responsiveFontSizes(HoABLTheme);
