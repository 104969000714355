export interface HoablInvestedInitialStateInterFace {
  smartKey: string;
  firstMessage: string;
  welcomeMessage: string;
  // inactiveMessage: string;
  // allowTypingMessage: string;
  finalMessage: string;
  categories: {
      text: string;
      action: null | number;
      actionType: null | number;
      message: string;
      options: {
          text: string;
          action: null | number;
          actionType: null | number;
      }[];
  }[];
}

export const HoablInvestedInitialState : HoablInvestedInitialStateInterFace = {
  smartKey: "INVESTED_HOABL",
  firstMessage: "",
  welcomeMessage: "",
  // inactiveMessage: "",
  // allowTypingMessage: "",
  finalMessage: "Thank you for reaching out to us. Our sales executive will get in touch you soon to address all your questions.",
  categories: [
    {
      text: "",
      action: null,
      actionType: 1,
      message: "",
      options: [
        {
          text: "",
          action: null,
          actionType: 3
        },
        {
          text: "",
          action: null,
          actionType: 3
        }
      ]
    }
  ]
}

export const hoablInvestedUserLabels:any = {
  welcomeMessage:"Welcome Message *",
  firstMessage:"Message *",
  categories: [
    {
      text: "Display Name",
      action: "Topics",
      message:"Message body",
      options: [
        {
          text: "Option",
          action: "Actions",
        },
        {
          text: "Option",
          action: "Actions",
        },
      ],
    },
  ],
};