import {
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import React, { Fragment, useEffect } from "react";
import { Avatar, Button, Divider, Grid, Stack, styled, Tooltip, Typography } from "@mui/material";
import styles from "./InventoryBucket.module.css";
import InventorySection from "./InventorySection";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { getIn, useFormikContext } from "formik";
import MediaAssets from "assets";
import { launchPhasesAction } from "redux-container/project-management/launch-phases/LaunchPhasesRedux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "components/loader/Loader";

const actionDispatch = (dispatch : any) => {
  return {
    getLaunchPhasesRequest: (id: any) =>
      dispatch(launchPhasesAction.getLaunchPhasesRequest(id))
  }
}

const InventoryBucket = () => {

  const location : any = useLocation()
  const isLoading = useSelector((state : any) => state?.projectLaunchPhases?.isLoading)
  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    errors,
    touched,
    isValid
  } = useFormikContext<IInitinalValueProjectContent>();

  const { getLaunchPhasesRequest } = actionDispatch(useDispatch());

  const handleClickFetchUpdates = () => {
      getLaunchPhasesRequest(location?.state?.id)
  }

  

  return (
    <Fragment>
      {isLoading ? (<Loader /> ) : (
        <>
      <div className="margin-30">
        <Stack justifyContent={'space-between'}>
          <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Inventory Bucket
          </Typography>
        </Stack>
          
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
            <TextFieldComponent
              required
              type="text"
              label="Section Heading"
              placeholder="Enter"
              onChange={handleChange}
              onBlur={handleBlur}
              name={'otherSectionHeadings.inventoryBucketContents.sectionHeading'}
              value={values?.otherSectionHeadings?.inventoryBucketContents?.sectionHeading}
              fieldhelpertext={
                getIn(touched, `otherSectionHeadings.inventoryBucketContents.sectionHeading`) &&
                getIn(errors, `otherSectionHeadings.inventoryBucketContents.sectionHeading`)
              }
              error={Boolean(
                getIn(errors, `otherSectionHeadings.inventoryBucketContents.sectionHeading`) &&
                  getIn(touched, `otherSectionHeadings.inventoryBucketContents.sectionHeading`)
              )}
              inputProps={{ maxLength: 24 }}
              disabled={isViewMode}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <TextFieldComponent
              required
              type="text"
              label="Sub Heading"
              placeholder="Enter"
              name={'otherSectionHeadings.inventoryBucketContents.subHeading'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.otherSectionHeadings?.inventoryBucketContents?.subHeading}
              fieldhelpertext={
                getIn(touched, `otherSectionHeadings.inventoryBucketContents.subHeading`) &&
                getIn(errors, `otherSectionHeadings.inventoryBucketContents.subHeading`)
              }
              error={Boolean(
                getIn(errors, `otherSectionHeadings.inventoryBucketContents.subHeading`) &&
                  getIn(touched, `otherSectionHeadings.inventoryBucketContents.subHeading`)
              )}
              inputProps={{ maxLength: 50 }}
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
      </div>

      <InventorySection />
      </>
      )}
    </Fragment>
  );
};

export default InventoryBucket;
