import * as Yup from "yup";

export const upCommingValidationSchema = Yup.object().shape({
  //   ---------------Gereral Information-------------
  launchName: Yup.string().required("Required").nullable(),
  shortDescription: Yup.string().required("Required").nullable(),
  address: Yup.object({
    city: Yup.string().required("Required").nullable(),
    state: Yup.string().required("Required").nullable(),
    country: Yup.string().required("Required").nullable(),
  }),
  bannerImage: Yup.object({
    value: Yup.object({
      url: Yup.string().required("Required").nullable(),
    }),
  }),

  isCurrentInfraStoryActive: Yup.boolean(),
  isCurrentInfraStoryActiveForWeb: Yup.boolean(),
  isUpcomingInfraStoryActive: Yup.boolean(),
  isUpcomingInfraStoryActiveForWeb: Yup.boolean(),
  isTourismAroundActive: Yup.boolean(),
  isTourismAroundActiveForWeb: Yup.boolean(),

  opportunityDoc: Yup.object({
    currentInfraStory: Yup.object().when(
      ["isCurrentInfraStoryActive", "isCurrentInfraStoryActiveForWeb"],
      {
        // is: true,
        is: (
          isCurrentInfraStoryActive: boolean,
          isCurrentInfraStoryActiveForWeb: boolean
        ) => isCurrentInfraStoryActive || isCurrentInfraStoryActiveForWeb,

        then: Yup.object({
          heading: Yup.string().required("Required").nullable(),
          stories: Yup.array().of(
            Yup.object({
              title: Yup.string().required("Required").nullable(),
              description: Yup.string().required("Required").nullable(),
              media: Yup.object({
                value: Yup.object({
                  url: Yup.string().required("Required").nullable(),
                }),
              }),
            })
          ),
        }),
      }
    ),

    upcomingInfraStory: Yup.object().when(
      ["isUpcomingInfraStoryActive", "isUpcomingInfraStoryActiveForWeb"],
      {
        // is: true,
        is: (
          isUpcomingInfraStoryActive: boolean,
          isUpcomingInfraStoryActiveForWeb: boolean
        ) => isUpcomingInfraStoryActive || isUpcomingInfraStoryActiveForWeb,
        then: Yup.object({
          heading: Yup.string().required("Required").nullable(),
          stories: Yup.array().of(
            Yup.object({
              title: Yup.string().required("Required").nullable(),
              media: Yup.object({
                value: Yup.object({
                  url: Yup.string().required("Required").nullable(),
                }),
              }),
            })
          ),
        }),
      }
    ),

    tourismAround: Yup.object().when(
      ["isTourismAroundActive", "isTourismAroundActiveForWeb"],
      {
        // is: true,
        is: (
          isTourismAroundActive: boolean,
          isTourismAroundActiveForWeb: boolean
        ) => isTourismAroundActive || isTourismAroundActiveForWeb,
        then: Yup.object({
          heading: Yup.string().required("Required").nullable(),
          stories: Yup.array().of(
            Yup.object({
              title: Yup.string().required("Required").nullable(),
              media: Yup.object({
                value: Yup.object({
                  url: Yup.string().required("Required").nullable(),
                }),
              }),
            })
          ),
        }),
      }
    ),
  }),

  isKeyPillarsActive: Yup.boolean(),
  isKeyPillarsActiveForWeb: Yup.boolean(),
  keyPillars: Yup.object().when(
    ["isKeyPillarsActive", "isKeyPillarsActiveForWeb"],
    {
      // is: true,
      is: (isKeyPillarsActive: boolean, isKeyPillarsActiveForWeb: boolean) =>
        isKeyPillarsActive || isKeyPillarsActiveForWeb,
      then: Yup.object({
        heading: Yup.string().required("Required").nullable(),
        values: Yup.array().of(
          Yup.object({
            name: Yup.string().required("Required").nullable(),
            icon: Yup.object({
              value: Yup.object({
                url: Yup.string().required("Required").nullable(),
              }),
            }),
          })
        ),
      }),
    }
  ),
});
export const upCommingUpdateValidationSchema = Yup.object().shape({
  //   ---------------Gereral Information-------------
  // launchName: Yup.string().required("Required").nullable(),
  // shortDescription: Yup.string().required("Required").nullable(),
  // address: Yup.object({
  //   city: Yup.string().required("Required").nullable(),
  //   state: Yup.string().required("Required").nullable(),
  //   country: Yup.string().required("Required").nullable(),
  // }),
  // bannerImage: Yup.object({
  //   value: Yup.object({
  //     url: Yup.string().required("Required").nullable(),
  //   }),
  // }),

  isCurrentInfraStoryActive: Yup.boolean(),
  isCurrentInfraStoryActiveForWeb: Yup.boolean(),
  isUpcomingInfraStoryActive: Yup.boolean(),
  isUpcomingInfraStoryActiveForWeb: Yup.boolean(),
  isTourismAroundActive: Yup.boolean(),
  isTourismAroundActiveForWeb: Yup.boolean(),

  opportunityDoc: Yup.object({
    currentInfraStory: Yup.object().when(
      ["isCurrentInfraStoryActive", "isCurrentInfraStoryActiveForWeb"],
      {
        is: (
          isCurrentInfraStoryActive: boolean,
          isCurrentInfraStoryActiveForWeb: boolean
        ) => isCurrentInfraStoryActive || isCurrentInfraStoryActiveForWeb,
        then: Yup.object({
          heading: Yup.string().required("Required").nullable(),
          stories: Yup.array().of(
            Yup.object({
              title: Yup.string().required("Required").nullable(),
              description: Yup.string().required("Required").nullable(),
              media: Yup.object({
                value: Yup.object({
                  url: Yup.string().required("Required").nullable(),
                }),
              }),
            })
          ),
        }),
      }
    ),

    upcomingInfraStory: Yup.object().when(
      ["isUpcomingInfraStoryActive", "isUpcomingInfraStoryActiveForWeb"],
      {
        is: (
          isUpcomingInfraStoryActive: boolean,
          isUpcomingInfraStoryActiveForWeb: boolean
        ) => isUpcomingInfraStoryActive || isUpcomingInfraStoryActiveForWeb,
        then: Yup.object({
          heading: Yup.string().required("Required").nullable(),
          stories: Yup.array().of(
            Yup.object({
              title: Yup.string().required("Required").nullable(),
              media: Yup.object({
                value: Yup.object({
                  url: Yup.string().required("Required").nullable(),
                }),
              }),
            })
          ),
        }),
      }
    ),

    tourismAround: Yup.object().when(
      ["isTourismAroundActive", "isTourismAroundActiveForWeb"],
      {
        is: (
          isTourismAroundActive: boolean,
          isTourismAroundActiveForWeb: boolean
        ) => isTourismAroundActive || isTourismAroundActiveForWeb,
        then: Yup.object({
          heading: Yup.string().required("Required").nullable(),
          stories: Yup.array().of(
            Yup.object({
              title: Yup.string().required("Required").nullable(),
              media: Yup.object({
                value: Yup.object({
                  url: Yup.string().required("Required").nullable(),
                }),
              }),
            })
          ),
        }),
      }
    ),
  }),

  isKeyPillarsActive: Yup.boolean(),
  keyPillars: Yup.object().when(
    ["isKeyPillarsActive", "isKeyPillarsActiveForWeb"],
    {
      is: (isKeyPillarsActive: boolean, isKeyPillarsActiveForWeb: boolean) =>
        isKeyPillarsActive || isKeyPillarsActiveForWeb,
      then: Yup.object({
        heading: Yup.string().required("Required").nullable(),
        values: Yup.array().of(
          Yup.object({
            name: Yup.string().required("Required").nullable(),
            icon: Yup.object({
              value: Yup.object({
                url: Yup.string().required("Required").nullable(),
              }),
            }),
          })
        ),
      }),
    }
  ),
});
