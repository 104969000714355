import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardMedia,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ElementType, FocusEventHandler, Fragment } from "react";
import AlertHelperText from "../alert-helper-text/AlertHelperText";
import MediaAssets from "assets";
import ProgressLoader from "../progress-loader/ProgressLoader";
import CachedIcon from "@mui/icons-material/Cached";
interface Ivideouploaderdropbox {
  inputId?: number;
  fieldhelpertext?: any;
  ondrop?: (event: any) => void;
  onChange: (event: any) => void;
  previewimage?: any;
  name?: string;
  value?: string;
  cancelimage?: () => void;
  disabled?: boolean;
  loading?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  componentType?: ElementType<HTMLElementTagNameMap>;
  mediaHeight?: any;
  mediaWidth?: any;
}

const VideoUploadComponentStyleWrapper = styled<any>(Box)`
  ${(props) =>
    props.isError
      ? props.theme.palette.error.main
      : props.theme.palette.grey.A200};
  border-radius: 4px;
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};

  height: 17rem;
  position: relative;
  & .MuiCard-root {
    height: 100%;
    width: 100%;
  }
  & .replace-button {
    font-family: "Jost-Regular";
    cursor: pointer;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    position: absolute;
    column-gap: 0.3rem;
    bottom: 0.5rem;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.common.black};
  }
  & .trash-icon {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    z-index: 1;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    padding: 0.1rem;
    background-color: ${({ theme }) => theme.palette.common.white};
  }
  & .to-right {
    right: 4rem;
  }
  & .caption-detail-text {
    line-height: 2;
    color: ${({ theme }) => theme.palette.grey.A200};
  }
`;
const VideoUploadLoader = () => {
  return (
    <Stack
      position={"relative"}
      zIndex={5}
      justifyContent={"center"}
      height={"inherit"}
    >
      <ProgressLoader />
      <Typography
        color={"text.primary"}
        fontFamily={"Jost-Regular"}
        variant={"body1"}
        ml={"0.5rem"}
        lineHeight={2}
      >
        Loading...
      </Typography>
    </Stack>
  );
};

const VideoUploadComponent = (props: Ivideouploaderdropbox) => {
  const {
    inputId = 1,
    cancelimage,
    ondrop,
    disabled,
    previewimage,
    loading = false,
    componentType = "video",
    onBlur,
    name,
    fieldhelpertext,
    onChange,
    mediaWidth,
    mediaHeight,
  } = props;
  return (
    <VideoUploadComponentStyleWrapper
      isError={fieldhelpertext}
      isDisabled={disabled}
    >
      {loading ? (
        <VideoUploadLoader />
      ) : (
        <Fragment>
          <Box component={"label"} htmlFor={name}>
            <input
              disabled={disabled}
              accept=".mp4"
              id={name}
              type="file"
              name={name}
              hidden
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e);
              }}
              onDrop={(e: any) => {
                if (ondrop) {
                  ondrop(e);
                }
              }}
            />
            {!!previewimage ? (
              <Box
                component={"span"}
                className="replace-button"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                {!disabled && (
                  <>
                    <video width="400" height="200" controls>
                      <source src={previewimage} />
                    </video>
                    <CachedIcon fontSize="small" />
                    Replace
                  </>
                )}
              </Box>
            ) : (
              <Stack
                direction={"column"}
                p={"2.5rem"}
                sx={{
                  cursor: disabled ? "not-allowed" : "pointer",
                  alignSelf: "center",
                }}
              >
                <Avatar
                  sx={{ height: "30px", width: "30px" }}
                  src={MediaAssets.ic_file_upload}
                />
                <Typography
                  color={"text.primary"}
                  variant={"body1"}
                  lineHeight={2}
                >
                  Drag & Drop here
                </Typography>
                <Typography variant={"caption"} className="caption-detail-text">
                  or
                </Typography>
                <Typography
                  variant={"body1"}
                  color={"common.black"}
                  sx={{
                    textDecoration: "underline",
                  }}
                >
                  Browser files
                </Typography>
                <Typography className="caption-detail-text" variant="caption">
                  Max file size : 10 MB
                </Typography>
                {/* <Typography variant="caption" className="caption-detail-text">
                  Dimension(W*H): {mediaHeight ? mediaHeight : "198"}*
                  {mediaWidth ? mediaWidth : "194"}
                </Typography> */}
                <Typography variant="caption" className="caption-detail-text">
                  File Type:mp4
                </Typography>
                {fieldhelpertext ? (
                  <AlertHelperText color="error" severity="error">
                    {fieldhelpertext || ""}
                  </AlertHelperText>
                ) : (
                  <Typography variant="body2" lineHeight={1.5}></Typography>
                )}
              </Stack>
            )}
          </Box>
        </Fragment>
      )}
    </VideoUploadComponentStyleWrapper>
  );
};

export default VideoUploadComponent;
