import * as yup from "yup";
export const validationSchema = yup.object({
    footerName: yup.string().required("Required"),
    mediaFiles: yup.string().required("Required"),
    investmentFooterName: yup.string().required("Required"),
    investmentMediaFiles: yup.string().required("Required"),
    promiseFooterName: yup.string().required("Required"),
    promiseMediaFiles: yup.string().required("Required"),
    portfolioFooterName: yup.string().required("Required"),
    portfolioMediaFiles: yup.string().required("Required"),
    profileFooterName: yup.string().required("Required"),
    profileMediaFiles: yup.string().required("Required"),
  });

