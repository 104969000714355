import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import BlogActions from "redux-container/blog-management/BlogRedux";
import BlogCreationAction from "redux-container/blog-management/BlogCreationRedux";

export function* getBlogs(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAllBlogs, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(BlogActions.blogSuccess(response.data));
    } else {
      yield put(BlogActions.blogFailure(response.data));
    }
  } catch (error) {
    yield put(BlogActions.blogFailure(error));
  }
}

export function* deleteBlog(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteBlog, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(BlogActions.deleteBlogSuccess(response.data));
    } else {
      yield put(BlogActions.deleteBlogFailure(response.data));
    }
  } catch (error) {
    yield put(BlogActions.deleteBlogFailure(error));
  }
}

export function* blogStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.blogStatus, action.data);
    yield put(BlogActions.blogStatusSuccess(response.data));
  } catch (error) {
    yield put(BlogActions.blogStatusFailure(error));
  }
}

export function* blogPriority(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.blogPriority, action.data);
    yield put(BlogActions.blogPrioritySuccess(response.data));
  } catch (error) {
    yield put(BlogActions.blogPriorityFailure(error));
  }
}

export function* addBlogs(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addBlogs, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(BlogCreationAction.addBlogsSuccess(response.data));
    } else {
      yield put(BlogCreationAction.addBlogsFailure(response.data));
    }
  } catch (error) {
    yield put(BlogCreationAction.addBlogsFailure(error));
  }
}

export function* updateBlogs(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateBlogs, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(BlogCreationAction.updateBlogsSuccess(response.data));
    } else {
      yield put(BlogCreationAction.updateBlogsFailure(response.data));
    }
  } catch (error) {
    yield put(BlogCreationAction.updateBlogsFailure(error));
  }
}

export function* addDraftsBlogs(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addDraftsBlogs, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(BlogCreationAction.addDraftsBlogsSuccess(response.data));
    } else {
      yield put(BlogCreationAction.addDraftsBlogsFailure(response.data));
    }
  } catch (error) {
    yield put(BlogCreationAction.addDraftsBlogsFailure(error));
  }
}

export function* getSingleBlog(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getSingleBlog, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(BlogCreationAction.getSingleBlogsSuccess(response.data));
    } else {
      yield put(BlogCreationAction.getSingleBlogsFailure(response.data));
    }
  } catch (error) {
    yield put(BlogCreationAction.getSingleBlogsFailure(error));
  }
}

export function* deleteSingleBlog(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteSingleBlog, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(BlogCreationAction.deleteBlogsSuccess(response.data));
    } else {
      yield put(BlogCreationAction.deleteBlogsFailure(response.data));
    }
  } catch (error) {
    yield put(BlogCreationAction.deleteBlogsFailure(error));
  }
}

export function* blogCreationStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.blogStatus, action.data);

    yield put(BlogCreationAction.blogCreationStatusSuccess(response.data));
  } catch (error) {
    yield put(BlogCreationAction.blogCreationStatusFailure(error));
  }
}
