import { MediaUploadComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";

const actionDispatch = (dispatch: any) => {
  return {
    setUploadFileState: (key: any, data: any) => {
      dispatch(FileUploadActions.setUploadFileState(key, data));
    },
    fileUploadRequest: (data: any) => {
      dispatch(FileUploadActions.fileUploadRequest(data));
    },
    fileDeleteRequest: (data: any) => {
      dispatch(FileUploadActions.fileDeleteRequest(data));
    },
  };
};
function ImageUploaderForInvestments() {
  const { values, setFieldValue, errors, touched } =
    useFormikContext<any>();

  const { imageDetails, isLoading } = useSelector(
    (state: any) => state.fileUpload
  );

  const pageData: any = useSelector(
    (state: any) => state?.pageManagement?.pageManagementData?.page
  );

  const { setUploadFileState, fileUploadRequest, fileDeleteRequest } =
    actionDispatch(useDispatch());

    useEffect(() => {
      setFieldValue('promotionAndOffersMedia.value.url', pageData?.promotionAndOffersMedia?.value?.url)
    }, [])

  const [componentName, setComponentName] = useState<any>(null);
  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);

      setFieldValue(`promotionAndOffersMedia.key`, file?.name);
      setFieldValue(`promotionAndOffersMedia.name`, file?.name);
      setFieldValue(`promotionAndOffersMedia.value.size`, file?.size);
      setFieldValue(`promotionAndOffersMedia.value.type`, file?.type);
    }
  };

  const cancelImageHandler = () => {
    fileDeleteRequest(values?.address?.mapMedia?.name);

    setFieldValue(`promotionAndOffersMedia.value.url`, "");
    setFieldValue(`promotionAndOffersMedia.value.size`, "");
    setFieldValue(`promotionAndOffersMedia.name`, "");
    setFieldValue(`promotionAndOffersMedia.key`, "");
  };

  const onChangeHandler = (event: any) => {
    handleImageUpload(event);
  };
  useEffect(() => {
    if (imageDetails) {
      const imageRes = imageDetails;
      setUploadFileState("imageDetails", null);
      if (componentName === "ImageUploaderForInvestments") {
        const promotionAndOffersMedia = { ...values.promotionAndOffersMedia };

        promotionAndOffersMedia.value.url = imageRes?.url;

        setFieldValue(`promotionAndOffersMedia`, promotionAndOffersMedia);
      }
    }
  }, [imageDetails]);
  return (
    <>
      <MediaUploadComponent
        name="ImageUploaderForInvestments"
        onChange={(event: any) => {
          onChangeHandler(event);
          setComponentName(event.target.name);
        }}
        cancelimage={cancelImageHandler}
        previewimage={values?.promotionAndOffersMedia?.value?.url}
        loading={isLoading}
        fieldhelpertext={
          getIn(touched, `promotionAndOffersMedia.value.url`) &&
          getIn(errors, `promotionAndOffersMedia.value.url`)
        }
      />
    </>
  );
}

export default ImageUploaderForInvestments;
