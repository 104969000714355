export const enum ActionsEnums {
    NavigateToProjectDetails = 111,
    ShowFinalResponse = 100,
    
  }
 
  export class ActionEnumsUtils {
    public static getEnumText(type: ActionsEnums): string {
      switch (type) {
        case ActionsEnums.NavigateToProjectDetails:
          return "Navigate To Project Details";
        case ActionsEnums.ShowFinalResponse:
          return "Show Final Response";
       
      }
    }
  
    public static getActionsEnums(): {
      label: string;
      value: ActionsEnums;
    }[] {
      const options: { label: string; value: ActionsEnums }[] = [
        {
          label: this.getEnumText(ActionsEnums.NavigateToProjectDetails),
          value: ActionsEnums.NavigateToProjectDetails,
        },
        {
          label: this.getEnumText(ActionsEnums.ShowFinalResponse),
          value: ActionsEnums.ShowFinalResponse,
        },
        
      ];
      return options;
    }
  }
  