import {
  Avatar,
  Box,
  Typography,
  Link,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material";
import styles from "./ProfileImageUploadDragDrop.module.css";
import CachedIcon from "@mui/icons-material/Cached";
import LoadingButtonComponent from "components/ui-elements/button/LoadingButtonComponent";
import MediaAssets from "assets";
import { AlertHelperText } from "components/ui-elements";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import { Fragment } from "react";

interface IImageUploadReplaceDragDrop {
  fieldhelpertext: any;
  onDrop: (event: any) => void;
  onChange: (event: any) => void;
  previewImage?: any;
  name?: string;
  value?: string;
  cancelImage: () => void;
  uploadMediaButton: boolean;
  dragAndDropButton: boolean;
  onClick: (event: any) => void;
  isDisabled?: any;
  onBlur?: (event: any) => void;
  index?: number;
  currentlyOpenedIndex?: number;
}

const DragAndDropStyle = styled(Box)({
  borderRadius: "2px",
  height: "15rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  "& .innerButton": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const FileUploadComponentStyleWrapper = styled(Box)`
  border: 2px dotted ${({ theme }) => theme.palette.grey.A200};
  border-radius: 4px;
  height: 15rem;
  position: relative;
  & .replace-button {
    font-family: "Jost-Regular";
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    position: absolute;
    column-gap: 0.3rem;
    bottom: 0.5rem;
    right: 3rem;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.common.black};
  }
  & .trash-icon {
    position: absolute;
    bottom: 0.4rem;
    right: 1rem;
    z-index: 1;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    padding: 0.2rem;
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

const ImageUploadReplaceDragDrop = (props: IImageUploadReplaceDragDrop) => {
  const { isLoading } = useSelector((state: any) => state.fileUpload);
  const { isReduxViewMode } = useSelector(
    (state: any) => state?.pageManagement
  );
  return (
    <FileUploadComponentStyleWrapper>
      {props.previewImage?.length > 0 ? (
        <Box>
          <Avatar
            className={styles["preview-image-section"]}
            src={`${props.previewImage}`}
            alt={props.name}
          />
          {!isReduxViewMode && (
            <Fragment>
              <Stack
                justifyContent={"space-around"}
                className={`${styles["right-side-button"]} edit-icon-wrapper`}
              >
                <IconButton
                  onClick={props.cancelImage}
                  className={"trash-icon"}
                >
                  <DeleteOutlineOutlinedIcon
                    className={`${styles["delete-icon"]} table-action-icon delete-icon`}
                  />
                </IconButton>
              </Stack>
              <Button className={"replace-button"}>
                <label
                  className={styles["display-flex-space-btw"]}
                  htmlFor={props.name}
                >
                  <input
                    accept=".jpg,.png,.jpeg"
                    id={props.name}
                    name={props.name}
                    type="file"
                    value={props.value}
                    className={styles["input-display-none"]}
                    onChange={(event: any) => {
                      props.onChange(event.target.files[0]);
                    }}
                    onDrop={(event: any) => {
                      props.onDrop(event);
                    }}
                  />
                  <CachedIcon className={styles["cached-icon"]} />
                  <Typography
                    className={styles["replace-text"]}
                    variant="body1"
                    component="h5"
                  >
                    {isLoading && props.index === props.currentlyOpenedIndex
                      ? "Loading"
                      : "Replace"}
                  </Typography>
                </label>
              </Button>
            </Fragment>
          )}
        </Box>
      ) : (
        <DragAndDropStyle>
          {props.dragAndDropButton && (
            <Box component="label" htmlFor={`contained-button-file`}>
              <input
                data-testid="media-input-field"
                accept=".jpg,.png,.jpeg"
                id={`contained-button-file`}
                name={props.name}
                type="file"
                value={props.value}
                className={styles["image-upload-input"]}
                onChange={(event: any) => {
                  props.onChange(event.target.files[0]);
                }}
                onDrop={(event: any) => {
                  props.onDrop(event);
                }}
              />
              {isLoading && props.index === props.currentlyOpenedIndex ? (
                <Loader />
              ) : (
                <Box className="innerButton">
                  <Avatar
                    className={styles["upload-icon"]}
                    src={MediaAssets.ic_upload_black}
                  />
                  <Box color="#6e6b8f" fontSize="16px">
                    Drag & Drop here
                  </Box>
                  <Box color="#9b9aa8" fontSize="16px">
                    Or
                  </Box>
                  <Link>
                    <Typography className={styles["browse-files-link"]}>
                      Browser file
                    </Typography>
                  </Link>
                  <Box color="#9795af" fontSize="12px">
                    Max file size : 100 KB
                  </Box>
                  <Box color="#9795af" fontSize="12px" mt="0.2rem">
                    Dimension(W*H): 34*34
                  </Box>
                  <Box color="#9795af" fontSize="12px" mt="0.5rem">
                    File Type: jpeg,svg,png,webp
                  </Box>
                  {props.fieldhelpertext ? (
                    <AlertHelperText color="error" severity="error">
                      {props.fieldhelpertext || ""}
                    </AlertHelperText>
                  ) : (
                    <Typography
                      variant="body2"
                      visibility={"visible"}
                      lineHeight={1.5}
                    ></Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </DragAndDropStyle>
      )}
    </FileUploadComponentStyleWrapper>
  );
};
export default ImageUploadReplaceDragDrop;
