import { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import MediaAssets from "../../../assets";
import MenuItem from "@mui/material/MenuItem";
import NoDataRecord from "../../../components/no-data-record/NoDataRecord";
import PageHeading from "../../../components/page-heading/PageHeading";
import { RouteConstants } from "../../../routes/RouteConstants";
import { SelectChangeEvent } from "@mui/material/Select";
import { TextFieldComponent } from "../../../components/ui-elements";
import styles from "./ProjectList.module.css";

import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ProjectListStyleWrapper } from "./ProjectListStyleWrapper.style";
import { launchPhasesAction } from "redux-container/project-management/launch-phases/LaunchPhasesRedux";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionTypeEnumInsight,
  ActionTypeEnumUtilsInsight,
  ProjectActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import { StatusTypeEnumInsight } from "enumerations/StatusTypeEnum";
import projectListActions from "redux-container/project-management/ProjectListRedux";
import { exportAsExcel } from "utils/ExportFileUtils";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import Retry from "components/retry/Retry";
import { AllProjectFaqsAction } from "redux-container/project-management/ProjectAllFaqsRedux";
import { GetProjectCategoriesAction } from "redux-container/project-management/ProjectCategoryRedux";
import { ProjectFaqActions } from "redux-container/project-management/ProjectFaqsRedux";
import UserActions from "redux-container/users/UsersRedux";
import { ProjectListConstants } from "./ProjectListConstants";
import ProjectDraftsList from "redux-container/project-management/project-drafts/ProjectDraftsRedux";
import ProjectNewLaunchList from "./ProjectNewLaunchList";
import ProjectPublishedList from "./ProjectPublishedList";
import ProjectDraftList from "./ProjectDraftList";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";
import { ProjectStatusTypeEnumUtils } from "enumerations/ProjectStatusTypeEnum";
import UpcommingProject from "../project-creation/upcomming-projects/UpcommingProject";
// import UpCommingProjectContentCreationTypes from "redux-container/project-management/upcomming-project/UpcommingProjectRedux";
import UpCommingProjectContentCreationTypes, {
  filterUpCommingProjectContentCreationDataRequest,
  filterUpCommingProjectContentCreationDataSuccess,
  upCommingProjectContentCreationDataFailure,
} from "redux-container/project-management/upcomming-project/UpcommingProjectRedux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonComponent,
  LoadingButtonComponent,
  MediaUploadComponent,
  SwitchComponent,
} from "components/ui-elements";
import UpcommingProjectListing from "./upcomming-project/UpcommingProjectListing";
const actionDispatch = (dispatch: any) => {
  return {
    getUpCommingProjectContentCreationDataRequest: () =>
      dispatch(
        UpCommingProjectContentCreationTypes.getUpCommingProjectContentCreationDataRequest()
      ),
    updateStatusUpCommingProjectContentCreationDataRequest: (data: any) =>
      dispatch(
        UpCommingProjectContentCreationTypes.updateStatusUpCommingProjectContentCreationDataRequest(
          data
        )
      ),

    setLaunchPhasesState: (key: any, data: any) =>
      dispatch(launchPhasesAction.setLaunchPhasesState(key, data)),
    getProjectList: async (params: any) => {
      dispatch(projectListActions.projectListRequest(params));
    },
    deleteSelectedProjects: (newtoBeDeletingIds: any) => {
      dispatch(projectListActions.deleteProjectsRequest(newtoBeDeletingIds));
    },
    updateProjectsStatus: (toBeUpdatingIdies: any) => {
      dispatch(
        projectListActions.updateProjectsStatusRequest(toBeUpdatingIdies)
      );
    },
    setProjectState: (key: any, data: any) => {
      dispatch(projectListActions.setProjectState(key, data));
    },
    setUpcomingProjectState: (key: any, data: any) => {
      dispatch(
        UpCommingProjectContentCreationTypes.setUpcomingProjectState(key, data)
      );
    },
    setProjectCategoriesState: (key: any, data: any) =>
      dispatch(GetProjectCategoriesAction.setProjectCategoriesState(key, data)),
    setProjectFaqState: (key: any, value: any) =>
      dispatch(ProjectFaqActions.setProjectFaqState(key, value)),
    setALLProjectFaqState: (key: any, value: any) =>
      dispatch(AllProjectFaqsAction.setALLProjectFaqState(key, value)),
    verifyLaunchPhase: (params: any) =>
      dispatch(launchPhasesAction.verifyLaunchPhaseDataRequest(params)),
    getUsers: (params: any) =>
      dispatch(UserActions.getUsersListRequest(params)),
    getProjectDraftsList: (data: any) =>
      dispatch(ProjectDraftsList.getProjectDraftRequest(data)),
    bulkDeleteDrafts: (ids: any) =>
      dispatch(ProjectDraftsList.deleteProjectDraftRequest(ids)),
    filterUpCommingProjectContentCreationDataRequest: (data: any) => { 
      dispatch(UpCommingProjectContentCreationTypes.filterUpCommingProjectContentCreationDataRequest(data))
    }
  };
};

const ProjectList = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useDispatch();

  const {
    updateStatusUpCommingProjectContentCreationDataRequest,
    setLaunchPhasesState,
    setProjectFaqState,
    setProjectCategoriesState,
    setALLProjectFaqState,
    verifyLaunchPhase,
    getProjectDraftsList,
    bulkDeleteDrafts,
    getProjectList,
    setProjectState,
    deleteSelectedProjects,
    updateProjectsStatus,
    getUpCommingProjectContentCreationDataRequest,
    setUpcomingProjectState,
    filterUpCommingProjectContentCreationDataRequest,
  } = actionDispatch(dispatch);

  const actions = ActionTypeEnumUtilsInsight.getActionTypeEnumsInsight();
  const projectActionTypes =
    ProjectActionTypeEnumUtils.getProjectsActionTypeEnums();

  const breadcrumbRoutes: any = [
    {
      name: "Project Management",
      route: RouteConstants.projectManagementDashboard,
    },
    {
      name: "Projects",
      route: RouteConstants.projectList,
    },
  ];

  const [selectedPublish, setSelectedPublish] = useState<any>([]);
  const [upcommingselectedPublish, setUpcommingSelectedPublish] = useState<any>(
    []
  );
  const [selectedDraft, setSelectedDraft] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [selectedCreatedUser, setSelectedCreatedUser] = useState<any>(null);
  const [selectedModifiedUser, setSelectedModifiedUser] = useState<any>(null);
  const [selectedCRMProject, setSelectedCRMProject] = useState<any>(null);

  const [searchKeyOfNewTable, setSearchKeyOfNewTable] = useState("");
  const [searchKeyforUpcoming, setSearchKeyforUpcoming] = useState("");

  const [searchKey, setSearchKey] = useState("");
  const [shouldClearSearchKey, setShouldClearSearchKey] = useState(false);

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [isConfirmDraftDeleteDialogOpen, setIsConfirmDraftDeleteDialogOpen] =
    useState(false);

  const tabOne = "1";
  const tabTwo = "2";
  const tabThree = "3";
  const tabFour = "4";

  const searchKeyInitialValue = "";
  const {
    page,
    projectDraftList,
    draftsTotalCount,
    verifyLaunchPhaseError,
    totalCountOfNewProjects,
    isLoading,
    tabValue,
    totalCount,
    publishedProjects,
    fetchedAllLaunchPhasesData,
    launchPhasesLoader,
    pageOfNewTable,
    verifyLauchPhaseInventoryCount,
    isVerifyLoading,
    pageOfUpcomingProject,
  } = useSelector((state: any) => {
    return {
      page: state?.projectList?.page,
      projectDraftList: state?.projectDrafts?.data,
      draftsTotalCount: state?.projectDrafts?.totalCount,
      verifyLaunchPhaseError: state?.projectLaunchPhases.error,
      totalCountOfNewProjects:
        state?.projectLaunchPhases?.totalCountOfNewProjects,
      isLoading: state?.projectList?.isLoading,
      tabValue: state?.projectList?.tabValue,
      totalCount: state?.projectList?.totalCount,
      publishedProjects: state?.projectList?.data?.data,
      launchPhasesLoader: state.projectLaunchPhases?.isLoading,
      fetchedAllLaunchPhasesData:
        state.projectLaunchPhases?.fetchedAllLaunchPhasesData,
      pageOfNewTable: state?.projectLaunchPhases?.pageOfNewTable,
      verifyLauchPhaseInventoryCount:
        state?.projectLaunchPhases?.verifiedResponse?.inventoryBucketsCount,
      isVerifyLoading: state?.projectLaunchPhases.isLoading,
      pageOfUpcomingProject:
        state?.UpCommingProjectContentCreationTypes?.pageOfUpcomingProject,
    };
  });

  const handleChange = (event: any, newValue: string) => {
    setShouldClearSearchKey(true);
    setProjectState("tabValue", newValue);
    setSearchKey(searchKeyInitialValue);
    setSearchKeyOfNewTable(searchKeyInitialValue);
    setSearchKeyforUpcoming(searchKeyInitialValue);
  };

  useEffect(() => {
    setALLProjectFaqState("showInMobileFaq", []);
    setALLProjectFaqState("filterAllfaqBasedGivenArray", []);
    setProjectCategoriesState("mappedCategoryArray", []);
    setProjectCategoriesState("projectCategories", []);
    setProjectFaqState("mappedFaqs", []);
    setALLProjectFaqState("allProjectFaqs", []);
  }, []);

  useEffect(() => {
    setProjectState("page", 0);
    setProjectState("rowsPerPage", 5);
  }, [tabValue]);

  useEffect(() => {
    filterUpCommingProjectContentCreationDataRequest({size:5,index:1});
  }, []);

  const handleExportAsExcelForPublishedProjects = () => {
    if (selectedPublish.length === 0) {
      alert("You have not selected any Project");
      return;
    }
    let exportingPublishedProjects: any = [];
    if (publishedProjects.length) {
      selectedPublish.map((selectedProject: any) => {
        let localSelectedItem = publishedProjects.find(
          (item: any) => item.id == selectedProject
        );
        exportingPublishedProjects.push({
          Project_ID: localSelectedItem.id,
          Display_Name: localSelectedItem?.launchName
            ? localSelectedItem.launchName
            : "----",
          CRM_Lanuch_Name: localSelectedItem?.generalInfoEscalationGraph
            ?.crmLaunchPhaseName
            ? localSelectedItem?.generalInfoEscalationGraph?.crmLaunchPhaseName
            : "----",
          CRM_Project_Name: localSelectedItem?.generalInfoEscalationGraph
            ?.crmProjectName
            ? localSelectedItem?.generalInfoEscalationGraph?.crmProjectName
            : "----",
          Location: `${localSelectedItem?.address?.city} ${localSelectedItem.address?.state}`,
          Starting_Price: `(₹)${localSelectedItem.priceStartingFrom || "----"}`,
          Starting_Area: localSelectedItem?.areaStartingFrom
            ? localSelectedItem.areaStartingFrom
            : "----",
          Views: localSelectedItem?.fomoContent?.noOfViews
            ? localSelectedItem.fomoContent?.noOfViews
            : "----",
          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Published_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Modified_On: localSelectedItem.updatedAt
            ? formatDateTime(
                localSelectedItem.updatedAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
          Last_Modified_by:
            localSelectedItem?.projectContentUpdatedBy?.firstName,
          Status: localSelectedItem?.status
            ? ProjectStatusTypeEnumUtils.getEnumText(localSelectedItem?.status)
            : "----",
          Availability: localSelectedItem?.isSoldOut ? "Sold out" : "Available",
          Priority: localSelectedItem?.priority,
        });
      });
    }

    exportAsExcel(
      exportingPublishedProjects,
      "publishedProject",
      "publishedProject"
    );
  };
  const commingSoonProjects = useSelector((state: any) => {
    return state?.upCommingProjects?.upCommingprojectCreationData;
  });
  const totalCountCommingSoon=useSelector((state: any) => {
    return state?.upCommingProjects?.totalCount;
  });
  const handleActionChange = (event: SelectChangeEvent) => {
    const selectedActionOption = event.target.value;
    switch (Number(selectedActionOption)) {
      case ActionTypeEnumInsight.Export:
        handleExportAsExcelForPublishedProjects();
        setSelectedPublish([]);
        break;

      case ActionTypeEnumInsight.Delete:
        if (selectedPublish.length === 0) {
          alert("You have not selected any Projects");
          return;
        }
        setIsConfirmDeleteDialogOpen(true);
        break;

      case ActionTypeEnumInsight.MarkAsPublished:
        if (selectedPublish.length === 0) {
          alert("You have not selected any Project");
          return;
        }
        updateSelectedProjectsStatus(String(StatusTypeEnumInsight.Published));
        break;

      case ActionTypeEnumInsight.MarkAsInactive:
        if (selectedPublish.length === 0) {
          alert("You have not selected any Project");
          return;
        }
        updateSelectedProjectsStatus(String(StatusTypeEnumInsight.Inactive));
        break;
    }
  };

  const handleUpCommingActionChange = (event: SelectChangeEvent) => {
    const selectedActionOption = event.target.value;
    switch (Number(selectedActionOption)) {
      case ActionTypeEnumInsight.MarkAsPublished:
        if (upcommingselectedPublish.length === 0) {
          alert("You have not selected any Project");
          return;
        }
        updateUpcommingProjectStatus(String(StatusTypeEnumInsight.Published));

        break;

      case ActionTypeEnumInsight.MarkAsInactive:
        if (upcommingselectedPublish.length === 0) {
          alert("You have not selected any Project");
          return;
        }
        updateUpcommingProjectStatus(String(StatusTypeEnumInsight.Inactive));

        break;
    }
  };

  const handleExportAsExcelForDrafts = () => {
    if (selectedDraft.length === 0) {
      alert("You have not selected any Draft");
      return;
    }
    let exportingDrafts: any = [];
    if (projectDraftList.length) {
      selectedDraft.map((selectedDraftItem: any) => {
        let localSelectedItem = projectDraftList.find(
          (item: any) => item.id == selectedDraftItem
        );
        exportingDrafts.push({
          Project_ID: localSelectedItem?.id,
          Display_Name: localSelectedItem?.launchName
            ? localSelectedItem.launchName
            : "----",

          Location: `${localSelectedItem?.address?.city} ${localSelectedItem.address?.state}`,
          Starting_Price: `(₹)${localSelectedItem.priceStartingFrom || "----"}`,
          Starting_Area: localSelectedItem?.areaStartingFrom
            ? localSelectedItem.areaStartingFrom
            : "----",

          Created_On: localSelectedItem.createdAt
            ? formatDateTime(
                localSelectedItem.createdAt,
                Constants.dateFormatDDmmYYYY
              )
            : "----",
        });
      });
    }

    exportAsExcel(exportingDrafts, "projectDraftList", "projectDraftList");
  };

  const handleDraftActionChange = (event: any) => {
    const selectedActionOption = event.target.value;
    switch (Number(selectedActionOption)) {
      case ActionTypeEnumInsight.Export:
        handleExportAsExcelForDrafts();
        setSelectedDraft([]);
        break;

      case ActionTypeEnumInsight.Delete:
        if (selectedDraft.length === 0) {
          alert("You have not selected any Draft");
          return;
        }
        setIsConfirmDeleteDialogOpen(true);
        setIsConfirmDraftDeleteDialogOpen(true);
        break;
    }
  };

  const updateSelectedProjectsStatus = async (status: string) => {
    const projectsToBeUpdated = {
      contentIds: selectedPublish,
      status: status,
    };

    updateProjectsStatus(projectsToBeUpdated);
  };

  const updateUpcommingProjectStatus = async (status: any) => {
    const projectsToBeUpdated = {
      projectIds: upcommingselectedPublish,
      status: status,
    };

    updateStatusUpCommingProjectContentCreationDataRequest(projectsToBeUpdated);
    getUpCommingProjectContentCreationDataRequest();
    window.location.reload();
  };

  const handleDeleteSelectedDraft = () => {
    const draftsIds = {
      contentIds: selectedDraft,
    };
    bulkDeleteDrafts(draftsIds);
  };

  const handleCloseConfirmDialog = () => {
    setSelectedPublish([]);
    setSelectedDraft([]);
    setIsConfirmDeleteDialogOpen(false);
    setIsConfirmDraftDeleteDialogOpen(false);
    setTimeout(() => {
      getProjectDraftsList({});
    }, 500);
  };
  const okHandler = async () => {
    deleteProjects();
    handleCloseConfirmDialog();
  };

  const handleOkConfirmDeleteDraft = () => {
    handleDeleteSelectedDraft();
    handleCloseConfirmDialog();
  };
  const deleteProjects = () => {
    const projectsToBeDeleted = {
      contentIds: selectedPublish,
    };

    deleteSelectedProjects(projectsToBeDeleted);
  };

  const handleClearButton = (): boolean => {
    if (
      selectedStatus === null &&
      selectedModifiedUser === null &&
      selectedCreatedUser === null &&
      selectedCRMProject === null &&
      searchKey === ""
    ) {
      return false;
    }
    return true;
  };

  const handleSearchChange = (searchKey: string) => {
    setShouldClearSearchKey(false);
    setSearchKey(searchKey);
    if (page !== 0) {
      setProjectState("page", 0);
    }
  };

  const handleDraftSearch = (searchKey: any) => {
    setShouldClearSearchKey(false);
    setSearchKey(searchKey);
    if (page !== 0) {
      setProjectState("page", 0);
    }
  };

  const handleSearchChangeOfNewTable = (searchKey: string) => {
    setShouldClearSearchKey(false);
    setSearchKeyOfNewTable(searchKey);
    if (pageOfNewTable) {
      setLaunchPhasesState("pageOfNewTable", 0);
    }
  };

  const handleSearchChangeforUpcoming = (searchKey: string) => {
    setShouldClearSearchKey(false);
    setSearchKeyforUpcoming(searchKey);
    if (pageOfUpcomingProject !== 0) {
      setUpcomingProjectState("pageOfUpcomingProject", 0);
    }
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => getProjectList} />;
  };

  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setProjectState("tabValue", tabOne);
      setProjectState("page", 0);
      setProjectState("rowsPerPage", 5);
      setLaunchPhasesState("pageOfNewTable", 0);
      setLaunchPhasesState("rowsPerPageOfNewTable", 5);
    }
  }, []);

  const renderNoData = (message?: string) => {
    return (
      <NoDataRecord
        image={MediaAssets.ic_projects}
        altText=""
        message={
          message
            ? message
            : "You have no projects yet. Fetch your first project."
        }
      />
    );
  };

  const shouldProvidePlaceHolder = () => {
    if (tabValue === tabFour) {
      if (
        commingSoonProjects?.length > 0 ||
        !(searchKeyforUpcoming === searchKeyInitialValue) ||
        (handleClearButton() && isLoading === false)
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (tabValue === tabOne) {
      if (
        (fetchedAllLaunchPhasesData?.length > 0 ||
          !(searchKeyOfNewTable === searchKeyInitialValue)) &&
        launchPhasesLoader === false
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        publishedProjects?.length > 0 ||
        !(searchKey === searchKeyInitialValue) ||
        (handleClearButton() && isLoading === false)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <ProjectListStyleWrapper>
      <PageHeading title="Project Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder={shouldProvidePlaceHolder() ? "Search Projects" : ""}
        shouldClearSearchKey={shouldClearSearchKey}
        handleSearchOnKeyPress={
          tabValue === tabThree
            ? handleSearchChange
            : tabValue === tabTwo
            ? handleDraftSearch
            : tabValue === tabFour
            ? handleSearchChangeforUpcoming
            : handleSearchChangeOfNewTable
        }
      ></BreadCrumbs>
      <TabContext value={tabValue}>
        <Grid container justifyContent="end" padding={"2rem 1.2rem 0 1rem"}>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <Grid
              className={styles["tab-heading"]}
              border-bottom="1"
              borderColor="divider"
            >
              <TabList onChange={handleChange} aria-label="">
                <Tab
                  className={styles["tab-heading-label"]}
                  label={
                    <h1>
                      Total Launches
                      {`(${
                        totalCountOfNewProjects
                          ? totalCountOfNewProjects
                          : fetchedAllLaunchPhasesData?.length
                          ? fetchedAllLaunchPhasesData?.length
                          : 0
                      })`}
                    </h1>
                  }
                  value={tabOne}
                />
                <Tab
                  className={styles["tab-heading-label"]}
                  label={
                    <h1>
                      Drafts
                      {`(${
                        draftsTotalCount
                          ? draftsTotalCount
                          : projectDraftList?.length
                          ? projectDraftList?.length
                          : 0
                      })`}
                    </h1>
                  }
                  value={tabTwo}
                />
                <Tab
                  className={styles["tab-heading-label"]}
                  label={
                    <h1>
                      Live Launches
                      {`(${
                        totalCount
                          ? totalCount
                          : publishedProjects?.length
                          ? publishedProjects?.length
                          : 0
                      })`}
                    </h1>
                  }
                  value={tabThree}
                />
                <Tab
                  className={styles["tab-heading-label"]}
                  label={<h1>Upcoming Launches({totalCountCommingSoon})</h1>}
                  value={tabFour}
                />
              </TabList>
            </Grid>
          </Grid>
          <Grid item sm={4} md={4} lg={4} xl={4}>
            <TabPanel value={tabTwo} sx={{ textAlign: "end", padding: 0 }}>
              <FormControl className="action-dropdown">
                {(publishedProjects?.length > 0 || handleClearButton()) &&
                isLoading === false ? (
                  <TextFieldComponent
                    select
                    value={selectedAction}
                    label="Actions"
                    onChange={handleDraftActionChange}
                  >
                    {actions?.slice(0, 2).map((action) => {
                      return (
                        <MenuItem value={action.value} key={action.value}>
                          {action.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                ) : null}
              </FormControl>
            </TabPanel>
            <TabPanel value={tabThree} sx={{ textAlign: "end", padding: 0 }}>
              <FormControl className="action-dropdown">
                {(publishedProjects?.length > 0 || handleClearButton()) &&
                isLoading === false ? (
                  <TextFieldComponent
                    select
                    value={selectedAction}
                    label="Actions"
                    onChange={handleActionChange}
                  >
                    {projectActionTypes.map((action) => {
                      return (
                        <MenuItem value={action.value} key={action.value}>
                          {action.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                ) : null}
              </FormControl>
            </TabPanel>
            <TabPanel value={tabFour} sx={{ textAlign: "end", padding: 0 }}>
              <FormControl className="action-dropdown">
                {(publishedProjects?.length > 0 || handleClearButton()) &&
                isLoading === false ? (
                  <TextFieldComponent
                    select
                    value={selectedAction}
                    label="Actions"
                    onChange={handleUpCommingActionChange}
                  >
                    {projectActionTypes.slice(2, 4).map((action) => {
                      return (
                        <MenuItem value={action.value} key={action.value}>
                          {action.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                ) : null}
              </FormControl>
            </TabPanel>
          </Grid>
        </Grid>

        {selectedPublish.length > 0 && (
          <ConfirmationDialog
            shouldOpen={isConfirmDeleteDialogOpen}
            title="Delete Project?"
            content={`This action will delete the  ${selectedPublish.length} Projects. Are you sure you want to continue?`}
            okText="Delete"
            cancelHandler={handleCloseConfirmDialog}
            okHandler={okHandler}
          />
        )}

        {selectedDraft.length > 0 && (
          <ConfirmationDialog
            shouldOpen={isConfirmDraftDeleteDialogOpen}
            title="Delete Project?"
            content={`This action will delete the  ${selectedDraft.length} Projects drafts. Are you sure you want to continue?`}
            okText="Delete"
            cancelHandler={handleCloseConfirmDialog}
            okHandler={handleOkConfirmDeleteDraft}
          />
        )}

        <TabPanel value={tabOne}>
          <Container style={{ padding: "0 1.2rem" }}>
            <ProjectNewLaunchList searchKeyOfNewTable={searchKeyOfNewTable} />
          </Container>
        </TabPanel>
        <TabPanel value={tabTwo}>
          <Container style={{ padding: "0 1.2rem" }}>
            <ProjectDraftList
              selectedDraft={selectedDraft}
              setSelectedDraft={setSelectedDraft}
              searchKey={searchKey}
              renderNoData={renderNoData}
            />
          </Container>
        </TabPanel>
        <TabPanel value={tabThree}>
          <Container style={{ padding: "0 1.2rem" }}>
            <ProjectPublishedList
              selectedPublish={selectedPublish}
              setSelectedPublish={setSelectedPublish}
              searchKey={searchKey}
              renderNoData={renderNoData}
            />
          </Container>
        </TabPanel>
        <TabPanel value={tabFour}>
          <Container style={{ padding: "0 1.2rem" }}>
            <div>
              <LoadingButtonComponent
                onClick={() => {
                  navigate("/ucomming-project");
                }}
                style={{marginBottom: "1rem" }}
              >
                Create
              </LoadingButtonComponent>
              <UpcommingProjectListing
                searchKeyforUpcoming={searchKeyforUpcoming}
                upcommingselectedPublish={upcommingselectedPublish}
                setUpcommingselectedPublish={setUpcommingSelectedPublish}
              />
            </div>
            {/* <UpcommingProject /> */}
          </Container>
        </TabPanel>
      </TabContext>
    </ProjectListStyleWrapper>
  );
};

export default ProjectList;
