import React, { useState, useEffect } from "react";
import { Container, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { RouteConstants } from "routes/RouteConstants";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import PageHeading from "components/page-heading/PageHeading";
import { Fragment } from "react";
import { InvestmentsContentSections } from "./InvestmentsSection";
import { LoadingButtonComponent } from "components/ui-elements";
import { useSelector, useDispatch } from 'react-redux';
import PageManagentAction from "redux-container/page-management/PageManagementRedux";
import { useLocation } from "react-router-dom";
import DelayedComponent from "./new-investment-page/DelayedComponent";  

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/ui-elements";
import NewInvestmentPage from "./new-investment-page/NewInvestmentPage";
import Loader from "components/loader/Loader";

const Investment = () => {
  const [expanded, setExpanded] = useState<string | false>("");
  const dispatch = useDispatch();
  const location : any = useLocation()
  const isViewMode = useSelector((state : any) => state?.pageManagement?.isViewMode)
  const pageLoading = useSelector(
    (state: any) => state?.pageManagement?.isLoading
  );

  const isLoading = useSelector((state: any) => state?.pagemanagement?.isLoading)
  useEffect(() => {
    if(location?.state?.isViewMode === true){
      dispatch(PageManagentAction.setPageManagementState("isViewMode", location?.state?.isViewMode))
    }else {
      dispatch(PageManagentAction.setPageManagementState("isViewMode", false))
    }
  }, [location?.state?.isViewMode, dispatch])

    const breadcrumbRoutes: any = [
        {
          name: "Page Management",
          route: RouteConstants.pageManagementDashboard,
        },
        {
          name: "Page 2 - Investments",
          route: RouteConstants.investments,
        },
      ];
      const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <Fragment>
      <PageHeading title="Page Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
      <Container className="main-container">
        {isLoading ? <Loader /> : (

      <NewInvestmentPage />
      )}
      </Container>
    </Fragment>
  );
}

export default Investment