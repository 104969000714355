export const CorporatePhilosophyConstants = {
  sectionHeadingCharacterLimit: 30,
  displayNameCharactersLimit: 30,
  minimumLength: 0,
};

export const AboutUsContants = {
  sectionHeadingCharacterLimit: 16,
  mediaDescriptionCharacterLimit: 3000,
  minimumLength: 0,
};

export const FoundersConstants = {
  sectionHeadingCharacterLimit: 20,
  foundersNAmeCharacterLimit: 40,
  description: 3000,
  minimumLength: 0,
};

export const ProductCategoryConstants = {
  sectionHeadingCharacterLimit: 30,
  displayNameCharactersLimit: 30,
  minimumLength: 0,
  description: 3000,
};

export const StatsOverviewConstants = {
  sectionHeadingCharacterLimit: 30,
  titleCharactersLimit: 30,
  valueCharacterLimit: 30,
  minimumLength: 0,
  description: 150,
};
