import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  mediaAndPrCreationInitialValues,
  mediaAndPrCreationStateType,
  validationSchema,
} from "./MediaAndPrCreationInitialValues";
import PageHeading from "components/page-heading/PageHeading";
import {
  Collapse,
  Container,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SettingMenuDropdown from "components/ui-elements/setting-menu-dropdown/SettingMenuDropdown";
import { UpdatesStatusTypeEnum } from "enumerations/StatusTypeEnum";
import {
  ButtonComponent,
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import moment from "moment";
import { PromiseCreationContainer } from "pages/promise-management/promise-creation/PromiseCreation.style";
import LogoUploader from "./LogoUploader";
import { MediaAndPrsActions } from "redux-container/marketing-management/media-and-prs/MediaAndPrRedux";
import { useDispatch, useSelector } from "react-redux";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { RouteConstants } from "routes/RouteConstants";
import MediaQuillEditor from "./MediaQuillEditor";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { stripHtml } from "utils/ValidationUtils";

const actionDispatch = (dispatch: any) => {
  return {
    deleteMediaAndPr: (data: any) =>
      dispatch(MediaAndPrsActions.deleteMediaAndPrRequest(data)),
    updateMediaAndPrRequest: (data: any) =>
      dispatch(MediaAndPrsActions.updateMediaAndPrRequest(data)),
    addMediaAndPrRequest: (data: any) =>
      dispatch(MediaAndPrsActions.addMediaAndPrRequest(data)),
    changeMediaAndPrStatus: (data: any) =>
      dispatch(MediaAndPrsActions.changeMediaAndPrStatusRequest(data)),

    setMediaAndPrsState: (key: any, data: any) =>
      dispatch(MediaAndPrsActions.setMediaAndPrsState(key, data)),
  };
};

function MediaAndPrCreation() {
  const location: any = useLocation();
  const navigate: any = useNavigate();
  const {
    updateMediaAndPrRequest,
    deleteMediaAndPr,
    changeMediaAndPrStatus,
    addMediaAndPrRequest,
    setMediaAndPrsState,
  } = actionDispatch(useDispatch());

  const message = useSelector((state: any) => state.mediaAndPrs?.message);
  const isSuccess = useSelector((state: any) => state.mediaAndPrs?.isSuccess);
  const error = useSelector((state: any) => state.mediaAndPrs?.error);
  const userData = useSelector((state: any) => state?.users?.usersForFilters);

  const initialFormValues = () => {
    if (location.state !== null) {
      return location?.state?.mediaAndPR;
    } else {
      return mediaAndPrCreationInitialValues;
    }
  };
  const [mediaAndPrState, setMediaAndPrState] =
    useState<mediaAndPrCreationStateType>({
      isEditMode: location?.state?.isEdit || false,
      isViewMode: location?.state?.isView || false,
      formValues: initialFormValues(),
      buttonText: location?.state ? "Publish Changes" : "Publish",
      deleteConfirm: false,
    });
  console.log("mediaAndPrState", mediaAndPrState);

  const createdAdminId = userData?.find(
    (userId: any) => userId.id === mediaAndPrState.formValues.createdBy
  );

  const updatedAdminId = userData?.find(
    (userId: any) => userId.id === mediaAndPrState.formValues.modifiedBy
  );

  const handleSubmit = (values: any) => {
    if (values?.id) {
      updateMediaAndPrRequest({
        ...values,
        description: stripHtml(values?.description),
      });
    } else {
      addMediaAndPrRequest({
        ...values,
        description: stripHtml(values?.description),
      });
    }
  };

  const handleStatusChange = () => {
    let currentStatus: string;
    if (mediaAndPrState.formValues.status === UpdatesStatusTypeEnum.Published) {
      currentStatus = UpdatesStatusTypeEnum.Inactive;
    } else {
      currentStatus = UpdatesStatusTypeEnum.Published;
    }
    const adminStatusPayload = {
      status: currentStatus,
      mediaAndPrIds: [mediaAndPrState.formValues.id],
    };
    changeMediaAndPrStatus(adminStatusPayload);
  };

  const onDeleteClick = () => {
    setMediaAndPrState((prevState) => ({
      ...prevState,
      deleteConfirm: true,
    }));
  };

  const handleDeleteOkClick = () => {
    deleteMediaAndPr({ mediaAndPrIds: [mediaAndPrState.formValues.id] });
    setMediaAndPrState((prevState) => ({
      ...prevState,
      deleteConfirm: false,
    }));
  };

  const handleDialogClose = () => {
    setMediaAndPrState((prevState) => ({
      ...prevState,
      deleteConfirm: false,
    }));
  };

  const handleCloseFlashMessageDialog = () => {
    if (isSuccess) {
      navigate(-1);
    }
    setMediaAndPrsState("message", null);
    setMediaAndPrsState("error", null);
    setMediaAndPrsState("isSuccess", false);
  };

  const handleAdminView = (type: string) => {
    if (mediaAndPrState.isViewMode) {
      if (type === "createdBy") {
        navigate(`${RouteConstants.userCreation}`, {
          state: { ...createdAdminId, isView: true },
        });
      } else {
        navigate(`${RouteConstants.userCreation}`, {
          state: { ...updatedAdminId, isView: true },
        });
      }
    }
  };

  // put this in page constant
  const mediaAndPrCreationBreadcrumbRoues = (
    routeName?: any,
    isViewMode?: any
  ) => {
    return [
      {
        name: "Marketing Management",
        route: RouteConstants.mediaAndPrCreation,
      },
      {
        name: "Media and PR",
        route: RouteConstants.mediaAndPrList,
      },
      {
        name:
          routeName === "Create Media And PR"
            ? routeName
            : `${isViewMode ? "View" : "Edit"} - ${routeName}`,
        route: RouteConstants.mediaAndPrCreation,
      },
    ];
  };

  return (
    <>
      <PageHeading title="Marketing Management" />
      <BreadCrumbs
        routes={mediaAndPrCreationBreadcrumbRoues(
          mediaAndPrState?.formValues?.displayTitle || "Create Media And PR",
          mediaAndPrState?.isViewMode
        )}
      ></BreadCrumbs>

      <Container className="main-container">
        <Formik
          initialValues={mediaAndPrState.formValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            isValid,
            dirty,
            values,
            setFieldValue,
            handleChange,
            handleBlur,
            setFieldError,
            setFieldTouched,
            errors,
            touched,
          }) => {
            return (
              <Form>
                <Stack>
                  <Stack>
                    <IconButton
                      color="inherit"
                      edge={"start"}
                      onClick={() => navigate(-1)}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <Typography color={"secondary.dark"} variant="h5">
                      {mediaAndPrState.formValues.displayTitle?.length > 50
                        ? `${mediaAndPrState.formValues.displayTitle.slice(
                            0,
                            50
                          )}...`
                        : mediaAndPrState.formValues.displayTitle ||
                          "Create Media and PR"}
                    </Typography>
                    <Collapse in={mediaAndPrState.formValues.id}>
                      <SettingMenuDropdown
                        disabled={mediaAndPrState.isViewMode}
                        onstatuschange={handleStatusChange}
                        ondelete={onDeleteClick}
                        statusmenutext={
                          mediaAndPrState.formValues.status ===
                          UpdatesStatusTypeEnum.Published
                            ? "Inactive"
                            : "Published"
                        }
                      />
                    </Collapse>
                  </Stack>
                  <Stack direction={"row"} columnGap={2}>
                    <Collapse in={!mediaAndPrState.isViewMode}>
                      <ButtonComponent
                        type="reset"
                        variant="outlined"
                        onClick={() => navigate(-1)}
                        disabled={mediaAndPrState.isViewMode}
                      >
                        Cancel
                      </ButtonComponent>
                    </Collapse>
                    <Collapse
                      in={!mediaAndPrState.isViewMode}
                      orientation={"horizontal"}
                    >
                      <LoadingButtonComponent
                        type="submit"
                        disabled={
                          !isValid ||
                          !dirty ||
                          !(stripHtml(values?.description)?.length > 0)
                        }
                      >
                        {mediaAndPrState.buttonText}
                      </LoadingButtonComponent>
                    </Collapse>
                    <Collapse
                      in={mediaAndPrState.isViewMode}
                      orientation={"horizontal"}
                    >
                      <ButtonComponent
                        variant="contained"
                        type="button"
                        onClick={() => {
                          setMediaAndPrState((previousState) => ({
                            ...previousState,
                            isViewMode: false,
                          }));
                        }}
                      >
                        {"Edit"}
                      </ButtonComponent>
                    </Collapse>
                  </Stack>
                </Stack>
                <Collapse in={mediaAndPrState.isViewMode}>
                  <Stack columnGap={"1rem"} justifyContent={"flex-start"}>
                    <Typography>
                      <Typography component={"i"}>
                        {" "}
                        Created on:&nbsp;
                      </Typography>
                      {`${moment(values.createdAt).format("L") || ""} by `}
                      <Link
                      // component="button"
                      // onClick={() => handleAdminView("createdBy")}
                      >
                        {values?.mediaAndPrsCreatedAdmin?.firstName || "User"}
                      </Link>
                    </Typography>
                    <Typography>
                      <Typography component={"i"}>
                        Modified on:&nbsp;
                      </Typography>
                      {`${moment(values.updatedAt).format("L") || ""} by `}
                      <Link
                      // component="button"
                      // onClick={() => handleAdminView("updatedBy")}
                      >
                        {values?.mediaAndPrModifiedAdmin?.firstName || "User"}
                      </Link>
                    </Typography>
                    {/* <ButtonComponent
                      sx={{ textDecoration: "underline" }}
                      variant="text"
                      color="inherit"
                    >
                      View Modified Content
                    </ButtonComponent> */}
                  </Stack>
                </Collapse>
                <PromiseCreationContainer>
                  <Grid
                    container
                    mt={"0.5rem"}
                    spacing={3}
                    className={"box"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                      <TextFieldComponent
                        label={"Title"}
                        required
                        disabled={mediaAndPrState.isViewMode}
                        name="displayTitle"
                        value={values.displayTitle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          "data-testid": "MediaAndPr-displayTitle",
                          maxLength: 100,
                        }}
                        InputProps={{
                          readOnly: mediaAndPrState.isViewMode,
                        }}
                        error={Boolean(
                          errors.displayTitle && touched.displayTitle
                        )}
                        fieldhelpertext={
                          errors.displayTitle &&
                          touched.displayTitle &&
                          errors.displayTitle
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                      <TextFieldComponent
                        label={"URL"}
                        required
                        disabled={mediaAndPrState.isViewMode}
                        name="url"
                        value={values.url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          "data-testid": "MediaAndPr-url",
                        }}
                        InputProps={{
                          readOnly: mediaAndPrState.isViewMode,
                        }}
                        error={Boolean(errors.url && touched.url)}
                        fieldhelpertext={
                          errors.url && touched.url && errors.url
                        }
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <MediaQuillEditor mediaAndPrState={mediaAndPrState} />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>Logo*</Typography>
                      <LogoUploader mediaAndPrState={mediaAndPrState} />
                    </Grid>
                  </Grid>
                </PromiseCreationContainer>
              </Form>
            );
          }}
        </Formik>
      </Container>

      <ConfirmationDialog
        shouldOpen={!!mediaAndPrState.deleteConfirm}
        content="Are you sure you want to delete this Media PR?"
        okText="Yes"
        cancelBtnText={"No"}
        okHandler={handleDeleteOkClick}
        cancelHandler={handleDialogClose}
      />

      {message ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : "Something Went Wrong!"}
          isSuccess={isSuccess}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
      {error ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error ? error : "Something Went Wrong!"}
          isSuccess={isSuccess}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      ) : null}
    </>
  );
}

export default MediaAndPrCreation;
