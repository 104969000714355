import HoABLTheme from "./HoablTheme";
import type {} from "@mui/lab/themeAugmentation";

const { palette, shape } = HoABLTheme;

const OverridedThemes = {
  ...HoABLTheme,
  components: {
    ...HoABLTheme.components,
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: palette.common.black,
          padding: "1rem 0.2rem",
          fontSize: "0.7rem",
        },
        label: {
          color: palette.common.white,
        },
        deleteIcon: {
          color: palette.common.white,
          fontSize: "0.8rem",
        },
      },
    },
    MuiAppBar: {
      ...HoABLTheme.components?.MuiAppBar,
      styleOverrides: {
        root: {
          background: palette.common.white,
          padding: "0.5rem 0",
        },
      },
    },
    MuiBreadcrumbs: {
      ...HoABLTheme.components?.MuiBreadcrumbs,
      styleOverrides: {
        root: {
          padding: "0.5rem 2rem",
        },
      },
    },
    MuiButton: {
      ...HoABLTheme.components?.MuiButton,
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontFamily: "Jost-Regular",
          textTransform: "capitalize",
          borderRadius: shape.borderRadius,
        },
        outlinedInherit: {
          color: palette.grey.A700,
          borderColor: palette.grey[700],
        },
        outlined: {
          color: palette.primary.dark,
          borderColor: palette.primary.dark,
        },
        contained: {
          color: palette.common.white,
          backgroundColor: palette.common.black,
        },
        textInherit: {
          color: palette.common.black,
          textDecoration: "underline",
          marginTop: "0.5rem",
          fontFamily: "Jost-SemiBold",
          "&:hover": {
            textDecoration: "underline",
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiLoadingButton: {
      ...HoABLTheme.components?.MuiLoadingButton,
      styleOverrides: {
        root: {
          fontFamily: "Jost-Regular",
          textTransform: "capitalize",
          color: palette.common.white,
        },
      },
    },
    MuiTextField: {
      ...HoABLTheme.components?.MuiTextField,
      styleOverrides: {
        root: {
          borderRadius: shape.borderRadius,
        },
      },
    },
    MuiInputBase: {
      ...HoABLTheme.components?.MuiInputBase,
      styleOverrides: {
        root: {
          color: palette.primary.main,
          borderRadius: shape.borderRadius,
        },
        input: {
          color: palette.common.black,
          fontFamily: "Jost-Medium",
          fontWeight: 500, // To make the text entered as Bold
        },
        disabled: {
          color: palette.grey[300],
        },
      },
    },
    MuiCheckbox: {
      ...HoABLTheme.components?.MuiCheckbox,
      styleOverrides: {
        root: {
          color: palette.common.black,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "0.2rem 0 0",
          lineHeight: 1,
          color: palette.info.main,
        },
      },
    },
    MuiSelect: {
      ...HoABLTheme.components?.MuiSelect,
      styleOverrides: {
        select: {
          borderRadius: shape.borderRadius,
          fontFamily: "Jost-Regular",
          color: palette.common.black,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: "2rem",
          fontWeight: 500,
        },
      },
    },
    MuiAvatar: {
      ...HoABLTheme.components?.MuiAvatar,
      styleOverrides: {
        img: {
          objectFit: "contain",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // backgroundColor: palette.background.paper,
          backgroundColor: palette.common.black,
          border: `1px solid ${palette.grey[400]}`,
          maxWidth: 500,
        },
        arrow: {
          "&::before": {
            border: `1px solid ${palette.grey[400]}`,
            // backgroundColor: palette.background.paper,
            backgroundColor: palette.common.black,
          },
        },
      },
    },
    MuiAlert: {
      ...HoABLTheme.components?.MuiAlert,
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
        },
        standard: {
          padding: 0,
        },
        standardError: {
          color: palette.error.main,
        },
        standardInfo: {
          color: palette.primary.light,
        },
        filled: {
          color: palette.common.white,
        },
        filledSuccess: {
          backgroundColor: palette.primary.main,
        },
        filledError: {
          backgroundColor: palette.error.main,
        },
      },
    },
    MuiListItemButton: {
      ...HoABLTheme.components?.MuiListItemButton,
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.dark,
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: palette.primary.light,
          },
        },
      },
    },
    MuiContainer: {
      ...HoABLTheme.components?.MuiContainer,
      styleOverrides: {
        root: {
          padding: "0 3.5rem",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 25,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          columnGap: "3rem",
          justifyContent: "center",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          color: palette.common.black,
          border: "none",
          backgroundColor: "transparent",
        },
        head: {
          backgroundColor: palette.common.white,
          color: palette.primary.main,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderRadius: shape.borderRadius,
          backgroundColor: palette.grey[100],
          borderBottom: `8px solid ${palette.common.white}`,
        },
        head: {
          border: "none",
        },
      },
    },
  },
};

export default OverridedThemes;
