import { Box, styled } from "@mui/material";

export const PortfolioPageStyleWrapper = styled(Box)`
  .divider-alignment {
    margin: 1rem 0;
  }
  .field-container-box {
    padding: 1.5rem 1.5rem 0.5rem;
  }
  .button-container {
    padding: 0 1rem 1rem;
    column-gap: 0.5rem;
    justify-content: flex-end;
  }
`;
