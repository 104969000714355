import { MediaUploadComponent } from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";

interface ICurrentInfraStoryMedia {
  index?: any;
  isViewModeProp?: boolean;
  currentlyOpenedIndex?: any;
  handleCurrentInfraStoryMedia: (imageIndex: number) => void;
}

function CurrentInfraStroyMedia({
  index,
  isViewModeProp,
  currentlyOpenedIndex,
  handleCurrentInfraStoryMedia,
}: ICurrentInfraStoryMedia) {
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<IInitinalValueProjectContent>();

  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));

  const { imageDetails, isLoading, error, isSuccess, isDelete } = useSelector(
    (state: any) => state.fileUpload
  );
  const dispatch = useDispatch();

  const [currentInfraStoryMedia, setCurrentInfraStoryMedia] = useState<any[]>(
    []
  );

  //upload Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const insightMediaImageUploadSuccess = `opportunityDoc.currentInfraStory.stories.${index}.media.value.url`;
      if (
        currentInfraStoryMedia[0] === insightMediaImageUploadSuccess &&
        index === currentlyOpenedIndex
      ) {
        setFieldValue(insightMediaImageUploadSuccess, imageDetails?.url);
        handleCurrentInfraStoryMedia(999);
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  //delete Image
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      const currentInfraMediaImageDelete = `opportunityDoc.currentInfraStory.stories.${index}.media.value.url`;
      if (
        currentInfraMediaImageDelete === currentInfraStoryMedia[0] &&
        index === currentlyOpenedIndex
      ) {
        const empty = "";
        setFieldValue(currentInfraMediaImageDelete, empty);
        handleCurrentInfraStoryMedia(999);
      }
    }
    getAllInsightsCreationFunction();
  }, [isDelete]);

  const handleuploadImage = (event: any) => {
    const file = event?.target?.files[0];
    const MediaId = event?.target?.id;

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
        setCurrentInfraStoryMedia((prevState) => [...prevState, MediaId]);
      };
      handleCurrentInfraStoryMedia(index);
      reader.readAsArrayBuffer(file);
      const currentInfraStoryMediaName = `opportunityDoc.currentInfraStory.stories.${index}.media.name`;
      const currentInfraStoryMediaKey = `opportunityDoc.currentInfraStory.stories.${index}.media.key`;
      setFieldValue(currentInfraStoryMediaName, file?.name);
      setFieldValue(currentInfraStoryMediaKey, file?.name);
    }
  };

  const handleConfirmDelete = () => {
    let fileName =
      values?.opportunityDoc?.currentInfraStory?.stories[index]?.media?.name;
    let fileUrl = `opportunityDoc.currentInfraStory.stories.${index}.media.value.url`;
    setCurrentInfraStoryMedia((prevState) => [...prevState, fileUrl]);
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
  };

  return (
    <MediaUploadComponent
      ondrop={handleChange}
      loading={isLoading && index === currentlyOpenedIndex}
      name={`opportunityDoc.currentInfraStory.stories.${index}.media.value.url`}
      value={
        values?.opportunityDoc?.currentInfraStory?.stories[index]?.media?.value
          ?.url
      }
      onChange={(event: any) => {
        handleuploadImage(event);
      }}
      previewimage={
        values?.opportunityDoc?.currentInfraStory?.stories[index]?.media?.value
          ?.url
      }
      cancelimage={() => {
        handleCurrentInfraStoryMedia(index);
        handleConfirmDelete();
      }}
      onBlur={handleBlur}
      fieldhelpertext={
        getIn(
          touched,
          `opportunityDoc.currentInfraStory.stories.${index}.media.value.url`
        ) &&
        getIn(
          errors,
          `opportunityDoc.currentInfraStory.stories.${index}.media.value.url`
        )
      }
      disabled={isViewMode || isViewModeProp}
    />
  );
}

export default CurrentInfraStroyMedia;
