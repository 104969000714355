import {
  Avatar,
  Container,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ButtonComponent,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Fragment, useState } from "react";
import { getIn } from "formik";
import MediaAssets from "assets";
import styles from "./MediaGallery.module.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MediaUploadDialog from "./image-upload-dialog/ImageUploadDialog";
import MediaGalleryTable from "./MediaGalleryTable";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

export default function MediaGallery() {
  const [mediaUploadContentType, setMediaUploadContentType] = useState("");
  const [mediaUploadFor, setMediaUploadFor] = useState<any>();
  const [openMediaUploadDialog, setOpenMediaUploadDialog] = useState(false);
  const [singleMediaUpdateData, setSingleMediaUpdateData] = useState<any>();
  const [isViewMode, setIsViewMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    errors,
    touched,
    isValid,
  } = useFormikContext<IInitinalValueProjectContent>();
  const isProjectViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const handleOpenMediaUploadDialog = (
    setFieldValue: any,
    mediaUploadFor: any,
    mediaUploadContentType?: any
  ) => {
    setFieldValue("mediaUploadFor", mediaUploadFor);
    setMediaUploadFor(mediaUploadFor);
    setMediaUploadContentType(mediaUploadContentType); //image or video
    setOpenMediaUploadDialog(true);
    setFieldValue("openMediaUploadDialog", true);
  };

  return (
    <Fragment>
      <Container>
        {openMediaUploadDialog === true && (
          <MediaUploadDialog
            shouldOpen={openMediaUploadDialog}
            title="Upload New Image"
            cancelHandler={() => setOpenMediaUploadDialog(false)}
            uploadContentType={mediaUploadContentType}
            mediaUploadFor={mediaUploadFor}
            setMediaUploadFor={setMediaUploadFor}
            singleMediaUpdateData={singleMediaUpdateData}
            setSingleMediaUpdateData={setSingleMediaUpdateData}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            isViewMode={isViewMode}
            setIsViewMode={setIsViewMode}
          />
        )}

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h6"} component={"h6"}>
            Media Gallery
          </Typography>
          <SwitchComponent
            name={"isMediaGalleryActive"}
            checked={values?.isMediaGalleryActive}
            onChange={handleChange}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <Grid container spacing={2} mt={3} mb={3}>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <TextFieldComponent
              required
              label={"Section Heading"}
              type="text"
              placeholder="Enter"
              name={"mediaGallerySectionHeading"}
              inputProps={{
                maxLength: 25,
              }}
              onBlur={handleBlur}
              value={values?.mediaGallerySectionHeading}
              onChange={handleChange}
              fieldhelpertext={
                getIn(touched, `mediaGallerySectionHeading`) &&
                getIn(errors, `mediaGallerySectionHeading`)
              }
              error={Boolean(
                getIn(errors, `mediaGallerySectionHeading`) &&
                  getIn(touched, `mediaGallerySectionHeading`)
              )}
              disabled={isProjectViewMode}
            />
          </Grid>

          {/* <Grid item xs={3} sm={3} md={3} lg={3}>
            <TextFieldComponent
              required
              label={"Sub Heading"}
              type="text"
              placeholder="Enter"
              name={"mediaGallerySubHeading"}
              inputProps={{
                maxLength: 25,
              }}
              onBlur={handleBlur}
              value={values?.mediaGallerySubHeading}
              onChange={handleChange}
              fieldhelpertext={P
                getIn(touched, `mediaGallerySubHeading`) &&
                getIn(errors, `mediaGallerySubHeading`)
              }
              error={Boolean(
                getIn(errors, `mediaGallerySubHeading`) &&
                  getIn(touched, `mediaGallerySubHeading`)
              )}
              disabled={isProjectViewMode}
            />
          </Grid> */}
        </Grid>

        <Divider className={styles["content-divider"]} />

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Image
          </Typography>
          <SwitchComponent
            name={`mediaGallery.isImagesActive`}
            checked={values?.mediaGallery?.isImagesActive}
            value={values?.mediaGallery?.isImagesActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <div className={styles["top-upload-btn"]}>
          <LoadingButtonComponent
            onClick={() => {
              handleOpenMediaUploadDialog(
                setFieldValue,
                "imageUploadListDetails",
                "image"
              );
              setOpenMediaUploadDialog(true);
              setSingleMediaUpdateData({});
              setIsViewMode(false);
              setIsEditMode(false);
            }}
            className={"btn btn-dark"}
            disabled={isProjectViewMode}
          >
            <Stack spacing={1} justifyContent={"space-evenly"}>
              <FileUploadOutlinedIcon />
              <Typography color={"#fff"}>
                {values?.mediaGallery?.images?.length > 0
                  ? "Upload More Images"
                  : "Upload Image"}
              </Typography>
            </Stack>
          </LoadingButtonComponent>
        </div>

        {values?.mediaGallery?.images?.length > 0 && (
          <div>
            <MediaGalleryTable
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setSingleMediaUpdateData={setSingleMediaUpdateData}
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
              dataMap={values?.mediaGallery?.images}
              name={"mediaGallery.images"}
              setMediaUploadFor={setMediaUploadFor}
              mediaUploadFor={"imageUploadListDetails"}
            />
          </div>
        )}

        {/* <Divider className={styles["content-divider"]} />

              <Stack justifyContent={"end"} margin={"20px 0"}>
                <LoadingButtonComponent
                  disabled={
                    values?.mediaGallery?.images?.length === 0 ? true : false
                  }
                  onClick={() => {console.log(values?.mediaGallery?.images); setCount(count + 1)}}
                >
                  Save
                </LoadingButtonComponent>
              </Stack> */}

        {/* video upload section */}
        <Divider className={styles["content-divider"]} />

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Video
          </Typography>
          <SwitchComponent
            name={`mediaGallery.isVideosActive`}
            checked={values?.mediaGallery?.isVideosActive}
            value={values?.mediaGallery?.isVideosActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <div className={styles["top-upload-btn"]}>
          <LoadingButtonComponent
            onClick={() => {
              handleOpenMediaUploadDialog(
                setFieldValue,
                "videoUploadListDetails",
                "video"
              );
              setOpenMediaUploadDialog(true);
              setSingleMediaUpdateData({});
              setIsViewMode(false);
              setIsEditMode(false);
            }}
            className={"btn btn-dark"}
            disabled={isProjectViewMode}
          >
            <Stack spacing={1} justifyContent={"space-evenly"}>
              <FileUploadOutlinedIcon />
              <Typography color={"#fff"}>
                {values?.mediaGallery?.videos?.length > 0
                  ? "Upload More Videos"
                  : "Upload Video"}
              </Typography>
            </Stack>
          </LoadingButtonComponent>
        </div>

        {values?.mediaGallery?.videos?.length > 0 && (
          <div>
            <MediaGalleryTable
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
              setSingleMediaUpdateData={setSingleMediaUpdateData}
              dataMap={values?.mediaGallery?.videos}
              name={"mediaGallery.videos"}
              setMediaUploadFor={setMediaUploadFor}
              mediaUploadFor={"videoUploadListDetails"}
            />
          </div>
        )}

        {/* <Divider className={styles["content-divider"]} />

              <Stack justifyContent={"end"} margin={"20px 0"}>
                <LoadingButtonComponent
                  disabled={
                    values?.mediaGallery?.videos.length === 0 ? true : false
                  }
                  onClick={() => console.log(values?.mediaGallery?.videos)}
                >
                  Save
                </LoadingButtonComponent>
              </Stack> */}

        {/* drone shoots section  */}

        <Divider className={styles["content-divider"]} />

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Drone Shoots
          </Typography>
          <SwitchComponent
            name={`mediaGallery.isDroneShootsActive`}
            checked={values?.mediaGallery?.isDroneShootsActive}
            value={values?.mediaGallery?.isDroneShootsActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <div className={styles["top-upload-btn"]}>
          <LoadingButtonComponent
            onClick={() => {
              handleOpenMediaUploadDialog(
                setFieldValue,
                "droneShootsUploadListDetails",
                "video"
              );
              setOpenMediaUploadDialog(true);
              setSingleMediaUpdateData({});
              setIsViewMode(false);
              setIsEditMode(false);
            }}
            className={"btn btn-dark"}
            disabled={isProjectViewMode}
          >
            <Stack spacing={1} justifyContent={"space-evenly"}>
              <FileUploadOutlinedIcon />
              <Typography color={"#fff"}>
                {values?.mediaGallery?.droneShoots?.length > 0
                  ? "Upload More Videos"
                  : "Upload Video"}
              </Typography>
            </Stack>
          </LoadingButtonComponent>
        </div>

        {values?.mediaGallery?.droneShoots?.length > 0 && (
          <div>
            <MediaGalleryTable
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
              setSingleMediaUpdateData={setSingleMediaUpdateData}
              dataMap={values?.mediaGallery?.droneShoots}
              name={"mediaGallery.droneShoots"}
              setMediaUploadFor={setMediaUploadFor}
              mediaUploadFor={"droneShootsUploadListDetails"}
            />
          </div>
        )}

        {/* <Divider className={styles["content-divider"]} />

              <Stack justifyContent={"end"} margin={"20px 0"}>
                <LoadingButtonComponent
                  disabled={
                    values?.mediaGallery?.droneShoots?.length === 0
                      ? true
                      : false
                  }
                  onClick={() => console.log(values?.mediaGallery?.droneShoots)}
                >
                  Save
                </LoadingButtonComponent>
              </Stack> */}

        {/* 360 images */}
        <Divider className={styles["content-divider"]} />

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            360&deg; Images
          </Typography>
          <SwitchComponent
            name={`mediaGallery.isThreeSixtyImagesActive`}
            checked={values?.mediaGallery?.isThreeSixtyImagesActive}
            value={values?.mediaGallery?.isThreeSixtyImagesActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <div className={styles["top-upload-btn"]}>
          <LoadingButtonComponent
            onClick={() => {
              handleOpenMediaUploadDialog(
                setFieldValue,
                "threeSixtyUploadListDetails",
                "image"
              );
              setOpenMediaUploadDialog(true);
              setSingleMediaUpdateData({});
              setIsViewMode(false);
              setIsEditMode(false);
            }}
            className={"btn btn-dark"}
            disabled={isProjectViewMode}
          >
            <Stack spacing={1} justifyContent={"space-evenly"}>
              <FileUploadOutlinedIcon />
              <Typography color={"#fff"}>
                {values?.mediaGallery?.threeSixtyImages?.length > 0
                  ? "Upload More Images"
                  : "Upload Image"}
              </Typography>
            </Stack>
          </LoadingButtonComponent>
        </div>

        {values?.mediaGallery?.threeSixtyImages?.length > 0 && (
          <div>
            <MediaGalleryTable
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
              setSingleMediaUpdateData={setSingleMediaUpdateData}
              dataMap={values?.mediaGallery?.threeSixtyImages}
              name={"mediaGallery.threeSixtyImages"}
              setMediaUploadFor={setMediaUploadFor}
              mediaUploadFor={"threeSixtyUploadListDetails"}
            />
          </div>
        )}

        {/* <Divider className={styles["content-divider"]} />

              <Stack justifyContent={"end"} margin={"20px 0"}>
                <LoadingButtonComponent
                  disabled={
                    values?.mediaGallery?.threeSixtyImages?.length === 0
                      ? true
                      : false
                  }
                  onClick={() => console.log(values?.mediaGallery?.threeSixtyImages)}
                >
                  Save
                </LoadingButtonComponent>
              </Stack> */}

        {/* Media Gallery Virtual Video Walk Through */}
        <Divider className={styles["content-divider"]} />

        <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
          <Typography color={"#000"} variant={"h5"} component={"h5"}>
            Virtual Video
          </Typography>
          <SwitchComponent
            name={`mediaGallery.isVirtualWalkthroughActive`}
            checked={values?.mediaGallery?.isVirtualWalkthroughActive}
            value={values?.mediaGallery?.isVirtualWalkthroughActive}
            onChange={handleChange}
            disabled={isProjectViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>

        <div className={styles["top-upload-btn"]}>
          <LoadingButtonComponent
            onClick={() => {
              handleOpenMediaUploadDialog(
                setFieldValue,
                "virtualVideoUploadListDetails",
                "video"
              );
              setOpenMediaUploadDialog(true);
              setSingleMediaUpdateData({});
              setIsViewMode(false);
              setIsEditMode(false);
            }}
            className={"btn btn-dark"}
            disabled={isProjectViewMode}
          >
            <Stack spacing={1} justifyContent={"space-evenly"}>
              <FileUploadOutlinedIcon />
              <Typography color={"#fff"}>
                {values?.mediaGallery?.virtualWalkthrough?.length > 0
                  ? "Upload More Videos"
                  : "Upload Video"}
              </Typography>
            </Stack>
          </LoadingButtonComponent>
        </div>

        {values?.mediaGallery?.virtualWalkthrough?.length > 0 && (
          <div>
            <MediaGalleryTable
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setOpenMediaUploadDialog={setOpenMediaUploadDialog}
              setSingleMediaUpdateData={setSingleMediaUpdateData}
              dataMap={values?.mediaGallery?.virtualWalkthrough}
              name={"mediaGallery.virtualWalkthrough"}
              setMediaUploadFor={setMediaUploadFor}
              mediaUploadFor={"virtualVideoUploadListDetails"}
            />
          </div>
        )}
      </Container>
    </Fragment>
  );
}
