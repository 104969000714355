import {
  Avatar,
  Box,
  Typography,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import { styled } from "@mui/material";
// import MediaAssets from "../../../assets";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutline";
import MediaAssets from "assets";
import AlertHelperText from "../alert-helper-text/AlertHelperText";
// import AlertHelperText from "../alert-helper-text/AlertHelperText";
import { Fragment } from "react";
import ProgressLoader from "../progress-loader/ProgressLoader";

interface Iimageuploaderdropbox {
  fieldhelpertext?: any;
  ondrop?: (event: any) => void;
  onChange: (event: any) => void;
  previewimage?: any;
  name?: string;
  value?: string;
  cancelimage?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const FileUploadStyleWrapper = styled(Stack)`
  border: 2px dotted ${({ theme }) => theme.palette.grey.A200};
  border-radius: 4px;
  /* width: 20rem; */
  height: 17rem;
  position: relative;
  justify-content: center;
  & .trash-icon {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    z-index: 1;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    padding: 0.1rem;
    background-color: ${({ theme }) => theme.palette.common.white};
  }
  & .to-right {
    right: 4rem;
  }
  & .caption-detail-text {
    line-height: 2;
    color: ${({ theme }) => theme.palette.grey.A200};
  }
`;

const FileUploadComponent = (props: Iimageuploaderdropbox) => {
  const {
    cancelimage,
    ondrop,
    disabled,
    previewimage,
    loading,
    name,
    fieldhelpertext,
    onChange,
  } = props;
  return (
    <FileUploadStyleWrapper>
      {!!previewimage ? (
        <Fragment>
          {cancelimage && (
            <Fragment>
              <IconButton
                disabled={disabled}
                onClick={cancelimage}
                className={"trash-icon"}
              >
                <DeleteOutlineOutlinedIcon color="error" />
              </IconButton>
              {/* <Box
                className="trash-icon to-right"
                component={"label"}
                htmlFor={`contained-button-file`}
              >
                Replace
              </Box> */}
            </Fragment>
          )}
          {loading ? (
            <Fragment>
              <ProgressLoader />
              <Typography
                color={"text.primary"}
                variant={"body1"}
                ml={"0.5rem"}
                lineHeight={2}
              >
                Uploading...
              </Typography>
            </Fragment>
          ) : (
            <Avatar
              sx={{ height: "100%", width: "100%" }}
              src={previewimage}
              alt="image"
            />
          )}
        </Fragment>
      ) : (
        <Box
          component="label"
          htmlFor={name}
          padding={"0.5rem 2rem"}
          sx={{ cursor: "pointer" }}
        >
          <input
            accept=".jpg,.png,.jpeg,.svg,.svg+xml"
            id={name}
            name={name}
            type="file"
            hidden
            onDrop={(e: any) => {
              if (ondrop) {
                ondrop(e);
              }
            }}
            onChange={(e: any) => onChange(e.target.files[0])}
          />
          <Stack direction={"column"} p={"0.5rem"}>
            <Avatar
              sx={{ height: "30px", width: "30px" }}
              src={MediaAssets.ic_file_upload}
            />
            <Typography color={"text.primary"} variant={"body1"} lineHeight={2}>
              Drag & Drop here
            </Typography>
            <Typography variant={"caption"} className="caption-detail-text">
              or
            </Typography>
            <Typography
              variant={"body1"}
              color={"common.black"}
              sx={{
                textDecoration: "underline",
              }}
            >
              Browser files
            </Typography>
            <Typography className="caption-detail-text" variant="caption">
              Max file size : 2 MB
            </Typography>
            <Typography variant="caption" className="caption-detail-text">
              Dimension(W*H): 30*30
            </Typography>
            <Typography variant="caption" className="caption-detail-text">
              File Type: jpeg,svg,png,webP{" "}
            </Typography>
            {fieldhelpertext ? (
              <AlertHelperText color="error" severity="error">
                {fieldhelpertext || ""}
              </AlertHelperText>
            ) : (
              <Typography
                variant="body2"
                visibility={"visible"}
                lineHeight={1.5}
              ></Typography>
            )}
          </Stack>
        </Box>
      )}
    </FileUploadStyleWrapper>
  );
};
export default FileUploadComponent;
