import {
  FormFieldContainer,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
  ButtonComponent,
} from "components/ui-elements";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import PrioritySelector from "components/ui-elements/priority-selector/PrioritySelector";
import { useFormikContext } from "formik";
import { getIn } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import MappedTable from "./MappedTable";
import { hoablPage1Action } from "redux-container/page-management/hoabl-page-1/HoablPage1Redux";
import { useDispatch, useSelector } from "react-redux";
import { setItemToLocalStorage } from "utils/LocalStorageUtils";
import NewInvestmentProjectTable from "./NewInvestmentProjectTable";
import { shouldNumberOnly } from "utils/ValidationUtils";
import { useLocation } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";

const LastestUpdateContainer = styled(FormFieldContainer)`
  padding: 0;
`;

const actionDispatch = (dispatch: any) => {
  return {
    reduxProjectListWithPriority: (key: any, data: any) =>
      dispatch(hoablPage1Action.setHoablPage1State(key, data)),
  };
};

const LatestUpdates = ({ isEditMode }: any) => {
  const { values, handleChange, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<any>();
  const {
    hoablPage1,
    projectListWithPriorityReduxState,
    projectListHoablPage1,
  } = useSelector((state: any) => state?.hoablPage1Data);
  const { totalCount, projectContentList, isLoading } = useSelector(
    (state: any) => state.projectContentListHoablPage1
  );
  const location: any = useLocation();
  const { reduxProjectListWithPriority } = actionDispatch(useDispatch());
  const [isProjectNewInvestment, setProjectNewInvestment] = useState(false);
  const [isAllProjectList, setAllProjectList] = useState(false);
  const [projectListFromProjectTable, setProjectListFromProjectTable] =
    useState([]);
  const [mappedNewInvestment, setMappedNewInvestment] = useState<any>([]);
  const [showHomeScreenLimitFlashMessage, setShowHomeScreenLimitFlashMessage] =
    useState(false);

  useEffect(() => {
    const filterProject = projectListHoablPage1?.filter((project: any) => {
      return hoablPage1?.page?.newInvestmentsWithPriority.find((item: any) => {
        return project.id === item.id;
      });
    });

    const initialPrioritySetting = new Map();

    hoablPage1?.page?.newInvestmentsWithPriority?.forEach((item: any) => {
      initialPrioritySetting.set(item.id, { ...item });
    }); //merging two array in to one with priority

    filterProject?.forEach((item: any) => {
      initialPrioritySetting.set(item.id, {
        ...initialPrioritySetting.get(item.id),
        ...item,
      });
    });
    const initialProjectListWithPriority = Array.from(
      initialPrioritySetting.values()
    ); // creating array

    const overRidingProjectPriority = initialProjectListWithPriority.map(
      (project: any, index: any) => {
        return {
          ...project,
          priority: index + 1,
        };
      }
    );

    setMappedNewInvestment(overRidingProjectPriority);

    reduxProjectListWithPriority(
      "projectListWithPriorityReduxState",
      overRidingProjectPriority
    );
    setItemToLocalStorage("localStorgeProjectList", overRidingProjectPriority);

    // if (values?.totalProjectsOnHomeScreen > mappedNewInvestment?.length) {
    //   setFieldValue("totalProjectsOnHomeScreen", 1);
    // }
  }, [hoablPage1, projectListHoablPage1]);

  const handleTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${event.target.name}`, event.target.value);
  };

  const handleOpenMapProject = () => {
    setProjectNewInvestment(true);
    setAllProjectList(true);
  };
  const handCloseMapProject = () => {
    setProjectNewInvestment(false);
  };

  const handleCloseFlashMessageDialog = () => {
    setShowHomeScreenLimitFlashMessage(false);
  };

  return (
    <LastestUpdateContainer>
      {showHomeScreenLimitFlashMessage && (
        <FlashMessageDialog
          shouldOpen={true}
          content={"Total Project On The Home Screen Cannot Be Less Than 1"}
          isSuccess={false}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      )}
      <Grid container p={"1rem"}>
        <Grid item xs={6}>
          <Stack columnGap={2} justifyContent={"flex-start"}>
            <Typography className="accordion-heading" margin={"0.5rem 0"}>
              New Investments
            </Typography>
            <SwitchComponent
              name="isNewInvestmentsActive"
              checked={values?.isNewInvestmentsActive}
              onChange={handleChange}
            />
            <Tooltip
              title={"This represent the ticker on Customer App"}
              arrow
              placement="top-start"
            >
              <InfoOutlinedIcon
                fontSize="small"
                className="infooutlined-alignment"
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Grid container columnSpacing={4} rowSpacing={2} p={"1rem"}>
        {/* <Grid item xs={5} marginRight={"1rem"}>
          <TextFieldComponent
            inputProps={{ maxLength: 100 }}
            label="Section Heading"
            name={"newInvestments.heading"}
            value={values?.newInvestments?.heading}
            disabled={location?.state?.isViewMode && isEditMode}
            onChange={handleTextInput}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, "newInvestments.heading") &&
              getIn(errors, "newInvestments.heading")
            }
            error={Boolean(
              getIn(errors, "newInvestments.heading") &&
                getIn(touched, "newInvestments.heading")
            )}
          />
        </Grid>
        <Grid item xs={5} marginRight={"1rem"}>
          <TextFieldComponent
            inputProps={{ maxLength: 100 }}
            label="Sub Heading"
            required
            name={"newInvestments.subHeading"}
            value={values?.newInvestments?.subHeading}
            disabled={location?.state?.isViewMode && isEditMode}
            onChange={handleTextInput}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(touched, "newInvestments.subHeading") &&
              getIn(errors, "newInvestments.subHeading")
            }
            error={Boolean(
              getIn(errors, "newInvestments.subHeading") &&
                getIn(touched, "newInvestments.subHeading")
            )}
          />
        </Grid> */}
        <Grid item xs={3}>
          <Stack flexDirection={"column"} rowGap={1} alignItems={"flex-start"}>
            <ButtonComponent
              variant="contained"
              onClick={handleOpenMapProject}
              disabled={location?.state?.isViewMode && isEditMode}
            >
              {mappedNewInvestment?.length > 0
                ? "Map More Projects to New Investment"
                : "Map Projects to New Investment"}
            </ButtonComponent>
            {values?.isNewInvestmentsActive && !mappedNewInvestment.length && (
              <Typography sx={{ color: "var(--primary-red-color)" }}>
                Please map atleast one investment
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Grid container rowSpacing={1} p={"1rem"}>
        <Grid item lg={5} md={5} xs={5}>
          <Stack justifyContent="flex-start" spacing={1}>
            {mappedNewInvestment.length > 0 && (
              <Typography mb={"1.2rem"}>
                Total Project on the home screen?
              </Typography>
            )}
            {mappedNewInvestment.length > 0 && (
              <PrioritySelector
                priority={values?.totalProjectsOnHomeScreen}
                disableDecrement={
                  (location?.state?.isViewMode && isEditMode) ||
                  values?.totalProjectsOnHomeScreen === 1
                }
                disableIncrement={
                  (location?.state?.isViewMode && isEditMode) ||
                  values?.totalProjectsOnHomeScreen ===
                    mappedNewInvestment?.length
                }
                decrementpriority={() => {
                  values?.totalProjectsOnHomeScreen > 1
                    ? setFieldValue(
                        "totalProjectsOnHomeScreen",
                        values?.totalProjectsOnHomeScreen - 1
                      )
                    : // : alert("no less then 0 ");
                      setShowHomeScreenLimitFlashMessage(true);
                }}
                incrementpriority={() => {
                  values?.totalProjectsOnHomeScreen <=
                  mappedNewInvestment?.length - 1
                    ? setFieldValue(
                        "totalProjectsOnHomeScreen",
                        values?.totalProjectsOnHomeScreen + 1
                      )
                    : alert("Must be less the selected project Length ");
                }}
                isCounter={true}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Box p={"1rem"}>
        {mappedNewInvestment.length > 0 && (
          <MappedTable
            projectListFromProjectTable={projectListFromProjectTable}
            mappedNewInvestment={mappedNewInvestment}
            setMappedNewInvestment={setMappedNewInvestment}
            isEditMode={isEditMode}
          />
        )}
      </Box>
      <Box p="1rem" textAlign="right">
        {/* <ButtonComponent variant="contained">Save</ButtonComponent> */}
      </Box>
      {isProjectNewInvestment === true ? (
        <NewInvestmentProjectTable
          open={isProjectNewInvestment}
          mappedNewInvestment={mappedNewInvestment}
          handleClose={handCloseMapProject}
          onselectedProject={setProjectListFromProjectTable}
          setAllProjectList={setAllProjectList}
          isAllProjectList={isAllProjectList}
          setMappedNewInvestment={setMappedNewInvestment}
          setFieldValue={setFieldValue}
          totalProjectsOnHomeScreen={values?.totalProjectsOnHomeScreen}
        />
      ) : null}
    </LastestUpdateContainer>
  );
};

export default LatestUpdates;
