import * as Yup from "yup";

export const validationSchemaforProjectContentCreation = Yup.object().shape({
  //   ---------------Gereral Information-------------
  fullDescription: Yup.string().required("Required").nullable(),
  shortDescription: Yup.string().required("Required").nullable(),
  areaStartingFrom: Yup.string().required("Required").nullable(),
  priceStartingFrom: Yup.string().required("Required").nullable(),
  isEscalationGraphActive: Yup.boolean(),
  generalInfoEscalationGraph: Yup.object().when("isEscalationGraphActive", {
    is: true,
    then: Yup.object({
      title: Yup.string().required("Required").nullable(),
      xAxisDisplayName: Yup.string().required("Required").nullable(),
      yAxisDisplayName: Yup.string().required("Required").nullable(),
      dataPoints: Yup.object({
        dataPointType: Yup.string().required("Required").nullable(),
        points: Yup.array().of(
          Yup.object({
            year: Yup.string().required("Required").nullable(),
            value: Yup.string().required("Required").nullable(),
          })
        ),
      }),
    }),
  }),

  reraDetails: Yup.object({
    isReraDetailsActive: Yup.boolean(),
    isReraDetailsActiveForWeb: Yup.boolean(),
    isReraDocumentsActive: Yup.boolean(),
    companyNameAndAddress: Yup.string().when(
      ["isReraDetailsActive", "isReraDetailsActiveForWeb"],
      {
        is: (isReraDetailsActive: any, isReraDetailsActiveForWeb: any) =>
          isReraDetailsActive || isReraDetailsActiveForWeb,
        then: Yup.string().required("Required"),
      }
    ),
    reraNumbers: Yup.array().when(
      ["isReraDetailsActive", "isReraDetailsActiveForWeb"],
      {
        is: (isReraDetailsActive: any, isReraDetailsActiveForWeb: any) =>
          isReraDetailsActive || isReraDetailsActiveForWeb,
        then: Yup.array().of(Yup.string().required("Required").nullable()),
      }
    ),
    sectionHeading: Yup.string().when("isReraDocumentsActive", {
      is: true,
      then: Yup.string().required("Required"),
    }),
    reraDocuments: Yup.array().when("isReraDocumentsActive", {
      is: true,
      then: Yup.array().of(
        Yup.object({
          phaseName: Yup.string().required("Required").nullable(),
          documents: Yup.array().of(
            Yup.object({
              documentName: Yup.string().required("Required").nullable(),
              documentMedia: Yup.object({
                value: Yup.object({
                  url: Yup.string().required("Required").nullable(),
                }),
              }),
            })
          ),
        })
      ),
    }),
  }),

  // address: Yup.object({
  //   isMapDetailsActive: Yup.boolean(),
  //   mapMedia: Yup.object({
  //     value: Yup.object({
  //       url: Yup.string().when("isMapDetailsActive", {
  //         is: true,
  //         then: Yup.string().required("Required").nullable(),
  //       }),
  //     }),
  //   }),
  // }),

  address: Yup.object().shape({
    isMapDetailsActive: Yup.boolean().required(),
    mapMedia: Yup.object().when("isMapDetailsActive", {
      is: true,
      then: Yup.object().shape({
        value: Yup.object().shape({
          url: Yup.string().required("required"),
        }),
      }),
      otherwise: Yup.object().nullable(),
    }),
  }),

  //   ---------------Opportunity Doc----------------------
  opportunityDoc: Yup.object({
    //-------------section headings ----------------//
    sectionHeading: Yup.object({
      heading: Yup.string().required("Required").nullable(),
      subHeading: Yup.string().required("Required").nullable(),
    }),
    //--------------Why to Invest Card-------------------------
    whyToInvestMedia: Yup.object({
      value: Yup.object({
        url: Yup.string().required("Required").nullable(),
      }),
    }),

    //----------------Page Banner Image---------------------
    bannerImage: Yup.object({
      value: Yup.object({
        url: Yup.string().required("Required").nullable(),
      }),
    }),

    //----------------Expected Growth----------------------
    isEscalationGraphActive: Yup.boolean(),
    isCurrentInfraStoryActive: Yup.boolean(),
    isUpcomingInfraStoryActive: Yup.boolean(),
    isTourismAroundActive: Yup.boolean(),
    isAboutProjectsActive: Yup.boolean(),
    isProjectAminitiesActive: Yup.boolean(),
    isPageFooterActive: Yup.boolean(),
    escalationGraph: Yup.object().when("isEscalationGraphActive", {
      is: true,
      then: Yup.object({
        title: Yup.string().required("Required").nullable(),
        xAxisDisplayName: Yup.string().required("Required").nullable(),
        yAxisDisplayName: Yup.string().required("Required").nullable(),
        // projectEstimatedAppreciation: Yup.string()
        //   .required("Required")
        //   .nullable(),
        dataPoints: Yup.object({
          dataPointType: Yup.string().required("Required").nullable(),
          points: Yup.array().of(
            Yup.object({
              year: Yup.string().required("Required").nullable(),
              value: Yup.string().required("Required").nullable(),
            })
          ),
        }),
      }),
    }),

    //--------------------currentInfraStory-------------------------
    currentInfraStory: Yup.object().when("isCurrentInfraStoryActive", {
      is: true,
      then: Yup.object({
        heading: Yup.string().required("Required").nullable(),
        stories: Yup.array().of(
          Yup.object({
            title: Yup.string().required("Required").nullable(),
            description: Yup.string().required("Required").nullable(),
            media: Yup.object({
              value: Yup.object({
                url: Yup.string().required("Required").nullable(),
              }),
            }),
          })
        ),
      }),
    }),

    //--------------------upcomingInfraStory-------------------------
    upcomingInfraStory: Yup.object().when("isUpcomingInfraStoryActive", {
      is: true,
      then: Yup.object({
        heading: Yup.string().required("Required").nullable(),
        stories: Yup.array().of(
          Yup.object({
            title: Yup.string().required("Required").nullable(),
            media: Yup.object({
              value: Yup.object({
                url: Yup.string().required("Required").nullable(),
              }),
            }),
          })
        ),
      }),
    }),

    //--------------------tourismAround-------------------------
    tourismAround: Yup.object().when("isTourismAroundActive", {
      is: true,
      then: Yup.object({
        heading: Yup.string().required("Required").nullable(),
        stories: Yup.array().of(
          Yup.object({
            title: Yup.string().required("Required").nullable(),
            media: Yup.object({
              value: Yup.object({
                url: Yup.string().required("Required").nullable(),
              }),
            }),
          })
        ),
      }),
    }),

    //--------------------aboutProjects-------------------------
    aboutProjects: Yup.object().when("isAboutProjectsActive", {
      is: true,
      then: Yup.object({
        heading: Yup.string().required("Required").nullable(),
        media: Yup.object({
          value: Yup.object({
            url: Yup.string().required("Required").nullable(),
          }),
        }),
      }),
    }),

    //--------------------------Project Amenities-----------------------
    projectAminities: Yup.array().when("isProjectAminitiesActive", {
      is: true,
      then: Yup.array().of(
        Yup.object({
          // sectionHeading: Yup.string().required("Required").nullable(),
          name: Yup.string().required("Required").nullable(),
          icon: Yup.object({
            value: Yup.object({
              url: Yup.string().required("Required").nullable(),
            }),
          }),
        })
      ),
    }),
    //-----------------Page Footer----------------------------------
    pageFooter: Yup.string().when("isPageFooterActive", {
      is: true,
      then: Yup.string().required("Required").nullable(),
    }),
  }),

  //-------------------------inventoryBucketContents-------------------------
  inventoryBucketContents: Yup.array().of(
    Yup.object({
      // name: Yup.string().required("Required").nullable(),
      shortDescription: Yup.string().required("Required").nullable(),
      areaRange: Yup.object({
        from: Yup.string().required("Required").nullable(),
        to: Yup.string().required("Required").nullable(),
      }),
      priceRange: Yup.object({
        from: Yup.number().required("Required").nullable(),
        to: Yup.string().required("Required").nullable(),
      }),
    })
  ),

  // ------------------------------mediaGallery-----------------------------
  isMediaGalleryActive: Yup.boolean(),
  mediaGallerySectionHeading: Yup.string().when("isMediaGalleryActive", {
    is: true,
    then: Yup.string().required("Required").nullable(),
  }),

  // --------------------------------latestMediaGallery------------------------------------------------
  isLatestMediaGalleryActive: Yup.boolean(),
  latestMediaGallerySectionHeading: Yup.string().when(
    "isLatestMediaGalleryActive",
    {
      is: true,
      then: Yup.string().required("Required").nullable(),
    }
  ),

  // ------------------------------similar investments ----------------------------------------------------------------
  isSimilarInvestmentActive: Yup.boolean(),
  similarInvestmentSectionHeading: Yup.string().when(
    "isSimilarInvestmentActive",
    {
      is: true,
      then: Yup.string().required("Required").nullable(),
    }
  ),

  //--------------------------------other section heading ----------------------------------------------------------------
  isMappedFaqSectionHeaderActive: Yup.boolean(),
  otherSectionHeadings: Yup.object().when("isMappedFaqSectionHeaderActive", {
    is: true,
    then: Yup.object({
      faqSection: Yup.object({
        sectionHeading: Yup.string().required("Required").nullable(),
      }),
    }),
    otherwise: Yup.object({
      promises: Yup.object({
        sectionHeading: Yup.string().required("Required").nullable(),
      }),
      testimonials: Yup.object({
        sectionHeading: Yup.string().required("Required").nullable(),
      }),
      inventoryBucketContents: Yup.object({
        sectionHeading: Yup.string().required("Required").nullable(),
        subHeading: Yup.string().required("Required").nullable(),
      }),
    }),
  }),

  // ------------------------------keyPillars--------------------------
  isKeyPillarsActive: Yup.boolean(),
  keyPillars: Yup.object().when("isKeyPillarsActive", {
    is: true,
    then: Yup.object({
      heading: Yup.string().required("Required").nullable(),
      values: Yup.array().of(
        Yup.object({
          name: Yup.string().required("Required").nullable(),
          icon: Yup.object({
            value: Yup.object({
              url: Yup.string().required("Required").nullable(),
            }),
          }),
        })
      ),
    }),
  }),
  // ------------------------------keyPillars--------------------------

  priceTransparency: Yup.object().shape({
    isPriceTransparencyActive: Yup.boolean(),
    sectionHeading: Yup.string().when("isPriceTransparencyActive", {
      is: true,
      then: Yup.string().required("Required"),
    }),
    projectName: Yup.string().when("isPriceTransparencyActive", {
      is: true,
      then: Yup.string().required("Required"),
    }),
    priceTransparencyCTAName: Yup.string().when("isPriceTransparencyActive", {
      is: true,
      then: Yup.string().required("Required"),
    }),
    shortDescription: Yup.string().when("isPriceTransparencyActive", {
      is: true,
      then: Yup.string().required("Required"),
    }),
    projects: Yup.array().when("isPriceTransparencyActive", {
      is: true,
      then: Yup.array().of(
        Yup.object().shape({
          skuName: Yup.string().required("Required"),
          areaStartingFrom: Yup.string().required("Required"),
          priceStartingFrom: Yup.string().required("Required"),
        })
      ),
    }),
    termsAndConditions: Yup.object().shape({
      isTermsAndConditionsActive: Yup.boolean(),
      sectionHeading: Yup.string().when("isTermsAndConditionsActive", {
        is: true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
      }),
      description: Yup.string().when("isTermsAndConditionsActive", {
        is: true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
      }),
    }),
  }),

  // ----------------------------------isLocationInfrastructure-----------------------
  isLocationInfrastructureActive: Yup.boolean(),
  locationInfrastructure: Yup.object().when("isLocationInfrastructureActive", {
    is: true,
    then: Yup.object({
      heading: Yup.string().required("Required").nullable(),
      values: Yup.array().of(
        Yup.object({
          name: Yup.string().required("Required").nullable(),
          longitude: Yup.string().required("Required").nullable(),
          latitude: Yup.string().required("Required").nullable(),
          minutes: Yup.string().required("Required").nullable(),
          hours: Yup.string().required("Required").nullable(),
          icon: Yup.object({
            value: Yup.object({
              url: Yup.string().required("Required").nullable(),
            }),
          }),
        })
      ),
    }),
  }),

  // ------------------------------isOffersAndPromotions------------------------
  isOffersAndPromotionsActive: Yup.boolean(),
  offersAndPromotions: Yup.object().when("isOffersAndPromotionsActive", {
    is: true,
    then: Yup.object({
      value: Yup.object({
        url: Yup.string().required("Required").nullable(),
      }),
    }),
  }),

  // ------------------------------projectTimeLines------------------------
  projectCompletionDate: Yup.string().required("Required").nullable(),
  projectTimelines: Yup.array().of(
    Yup.object({
      timeLineSectionHeading: Yup.string().required("Required").nullable(),
    })
  ),
});
