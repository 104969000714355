import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import PageHeading from "components/page-heading/PageHeading";
import { RouteConstants } from "routes/RouteConstants";
import CachedIcon from "@mui/icons-material/Cached";
import TableContainer from "@mui/material/TableContainer";
import styles from "./BlockedCustomerList.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import blockedCustomerListActions from "redux-container/customer-management/BlockedCustomerListRedux";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import Retry from "components/retry/Retry";
import Loader from "components/loader/Loader";
import { BlockedCustomerTypeEnumUtils } from "enumerations/CustomerManagementEnums";
import {
  AutocompleteComponent,
  TextFieldComponent,
} from "components/ui-elements";
import {
  BlockedCustomerActionTypeEnum,
  BlockedCustomerActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { AuditActions } from "redux-container/audit-log/auditLogRedux";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { AuditStatus } from "enumerations/AuditStatusTypes";
import { exportAsExcel } from "utils/ExportFileUtils";
import { DeviceEnumsUtils } from "enumerations/DeviceTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    getBlockedCustomerListData: (params: any) =>
      dispatch(blockedCustomerListActions.blockedCustomerListRequest(params)),
    unblockedCustomersAction: (data: any) =>
      dispatch(blockedCustomerListActions.unblockedCustomerRequest(data)),
    setBlockedCustomerListState: (key: any, data: any) =>
      dispatch(
        blockedCustomerListActions.setBlockedCustomerListState(key, data)
      ),

    postAuditLogRequest: (data: any) =>
      dispatch(AuditActions.postAuditLogRequest(data)),
  };
};

const BlockedCustomerList = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    getBlockedCustomerListData,
    unblockedCustomersAction,
    setBlockedCustomerListState,
    postAuditLogRequest,
  } = actionDispatch(useDispatch());
  const blockedCustomerListData = useSelector(
    (state: any) => state?.blockedCustomerList?.blockedCustomerList
  );
  const isLoading = useSelector(
    (state: any) => state?.blockedCustomerList?.isLoading
  );
  const error = useSelector((state: any) => state?.blockedCustomerList?.error);
  const message = useSelector(
    (state: any) => state?.blockedCustomerList?.message
  );
  const shouldOpenFlashDialog = useSelector(
    (state: any) => state?.blockedCustomerList?.shouldOpenFlashDialog
  );
  const isSuccess = useSelector(
    (state: any) => state?.blockedCustomerList?.isSuccess
  );

  const ipAddress = useSelector((state: any) => state?.auditLog?.ipAddress);
  const modules = useSelector((state: any) => state?.rolesAndModules?.module);
  const [modulesAudited, setModulesAudited] = useState<any>(null);

  const customerType =
    BlockedCustomerTypeEnumUtils.getBlockedCustomersTypeEnums();
  const action =
    BlockedCustomerActionTypeEnumUtils.getBlockedCustomerActionTypeEnum();

  const [selectedAction, setSelectedAction] = useState<any>(null);
  const [selectedType, setSelectedType] = useState("");
  const [selectedCustId, setSelectedCustId] = useState<any>(null);
  const [selectedCustomers, setSelectedCustomers] = useState<any>([]);
  const [customerId, setCustomerId] = useState<number>(0);
  const [searchKey, setSearchKey] = useState("");
  const [isConfirmationBlockDialogOpen, setIsConfirmationBlockDialogOpen] =
    useState(false);
  const [updatedTime, setUpdatedTime] = useState(new Date());

  const breadcrumbRoutes: any = [
    {
      name: "Customer Management",
      route: RouteConstants.customerManagementDashboard,
    },
    {
      name: "Blocked Customer List",
      route: RouteConstants.blockedCustomersList,
    },
  ];

  useEffect(() => {
    const params = {};
    getBlockedCustomerListData(params);
  }, []);

  //Loading Data (Customers data) while mounting and
  // every time after User applies any filters (Contact type,Search key,page and number of rows per page) .
  useEffect(() => {
    const params = {
      contactType: location?.state?.contactType
        ? location?.state?.contactType
        : selectedType,
      searchKey: encodeURIComponent(searchKey),
    };
    loadData(params);
    setSelectedAction("");
    setSelectedCustomers([]);
    clearSelected();
  }, [dispatch, selectedType, searchKey]);

  useEffect(() => {
    if (isSuccess) {
      // setBlockedCustomerListState("isSuccess", false);
      const params = {
        contactType: location?.state?.contactType
          ? location?.state?.contactType
          : selectedType,
        searchKey: searchKey,
      };
      loadData(params);
      clearSelected();
    }
  }, [isSuccess]);

  const loadData = (params: any) => {
    getBlockedCustomerListData(params);
  };

  //table headings (heading objects with name,boolean key for whether it is sorting column or not,and sorting key for an API call )
  const tableHeadings = [
    { heading: "Image" },
    // { heading: "CRM ID" },
    // { heading: "CMS ID" },
    { heading: "Customer Name" },
    { heading: "Customer Type" },
    { heading: "Email Address" },
    { heading: "Phone Number" },
    { heading: "Device Type" },
    { heading: "IP Address" },
    { heading: "Blocked Time" },
    { heading: "Actions" },
  ];

  //handle change function for select all checkbox.
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = blockedCustomerListData.map(
        (data: any) => data?.id
      );
      setSelectedCustomers(newSelecteds);
      return;
    }
    setSelectedCustomers([]);
  };

  //handle change function for a selecting single element in a table.
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedCustomers.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCustomers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCustomers.slice(1));
    } else if (selectedIndex === selectedCustomers?.length - 1) {
      newSelected = newSelected.concat(selectedCustomers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCustomers.slice(0, selectedIndex),
        selectedCustomers.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomers(newSelected);
  };

  const isSelected = (id: any) => selectedCustomers?.indexOf(id) !== -1;

  const clearSelected = () => {
    setSelectedCustomers([]);
  };

  //handle change for Action Selection.
  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case BlockedCustomerActionTypeEnum.Export:
        handleExportAsExcel();
        break;
      case BlockedCustomerActionTypeEnum.MarkAsUnblocked:
        setIsConfirmationBlockDialogOpen(true);
        break;
    }
  };

  //handle change for "Type of Customer" filter.
  const handleTypeChange = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value as string);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmationBlockDialogOpen(false);
  };
  const handleExportAsExcel = () => {
    if (blockedCustomerListData.length > 0) {
      exportAsExcel(blockedCustomerListData, "Blocked Customers", "Sheet1");
      return;
    }
    alert("There are no Blocked Customers");
  };
  const syncUsers = () => {
    setUpdatedTime(new Date());
    const params = {
      contactType: selectedType,
      searchKey: searchKey,
    };
    loadData(params);
  };

  //function to block Selected Customers.
  const blockSelectedCustomer = (id?: any) => {
    setIsConfirmationBlockDialogOpen(false);
    if (selectedCustomers.length === 0) {
      alert("You have not selected any customer");
      return;
    }
    if (customerId === 0) {
      let auditedItems = statusAuditedItems();
      let auditObjectsArray: any[] = [];
      auditedItems?.map((item: any) => {
        let auditObject = {
          itemAffected: `Customer '${
            item?.firstName ? item?.firstName : null
          } ${item?.firstName ? item.lastName : "----"}' Unblocked.(ID ${
            item?.id
          })`,
          newValue: "",
          oldValue: "",
          auditedObject: null,
          route: null,
        };
        auditObjectsArray.push(auditObject);
      });
      setModulesAudited(auditObjectsArray);

      const data = {
        cmsIds: selectedCustomers,
      };
      unblockedCustomersAction(data);
      // setBlockedCustomerListState("isSuccess", true)
      clearSelected();
      setCustomerId(0);
      return;
    }
    // const data = {
    //   cmsIds: [customerId]
    // };
    // unblockedCustomersAction(data);
    // setCustomerId(0);
    // setBlockedCustomerListState("isSuccess", true)
  };

  //function to block single Customer.
  const singleBlockCustomer = (id: any) => {
    let auditedItem: any = statusAuditedItem();
    let auditObject = {
      itemAffected: `Customer '${
        auditedItem?.firstName ? auditedItem?.firstName : null
      } ${
        auditedItem?.firstName ? auditedItem.lastName : "----"
      }' Unblocked.(ID ${auditedItem?.id})`,
      newValue: "",
      oldValue: "",
      auditedObject: null,
      route: null,
      status: AuditStatus.EDITED,
    };
    setModulesAudited(auditObject);

    setIsConfirmationBlockDialogOpen(false);
    const data = {
      cmsIds: [customerId],
    };
    unblockedCustomersAction(data);
    clearSelected();
    setCustomerId(0);
  };

  const handleBlockedCustomer = (id: any) => {
    setIsConfirmationBlockDialogOpen(true);
    setCustomerId(id);
  };

  //handle change for search key filter.
  const handleSearchChange = (searchKey: string) => {
    setSearchKey(searchKey);
  };

  const handleClearButton = (): boolean => {
    if (selectedCustId === null && selectedType === "") {
      return false;
    }
    return true;
  };

  //function to Reset the filters applied by User.
  const handleResetFilter = () => {
    setSelectedCustId(null);
    setSelectedType("");
  };

  const renderRetry = () => {
    return <Retry message={"Error"} retryHandler={() => loadData({})} />;
  };

  const renderLoader = () => {
    return <Loader />;
  };

  const statusAuditedItems = () => {
    let auditedItems: any[] = [];
    blockedCustomerListData?.map((item: any) => {
      selectedCustomers?.map((itemId: number) => {
        if (itemId === item.id) {
          auditedItems.push(item);
        }
      });
    });
    return auditedItems;
  };
  const statusAuditedItem = () => {
    let auditedItems: any[] = [];
    blockedCustomerListData?.map((item: any) => {
      if (customerId === item.id) {
        auditedItems.push(item);
      }
    });
    return auditedItems;
  };

  const handleCloseFlashMessageDialog = () => {
    setBlockedCustomerListState("shouldOpenFlashDialog", false);
    const moduleObject: any = modules?.find((element: any) => {
      return element?.name === "Customer Management";
    });
    const postAuditRequestbody = {
      ipAddress: ipAddress, //ipAddress
      moduleId: moduleObject?.id,
      route: null,
      auditedObject: null,
      modulesAudited: modulesAudited,
      status: null,
    };
    postAuditLogRequest(postAuditRequestbody);
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">
            Blocked Customer List
            {/* {`(${
              totalCount
                ? totalCount
                : blockedCustomerListData?.length
                ? blockedCustomerListData?.length
                : 0
            })`} */}
          </h4>
          <div>
            <FormControl size="small" className="action-dropdown">
              <TextFieldComponent
                select
                value={selectedAction}
                label="Action"
                onChange={handleActionChange}
              >
                {action.map((actions: any) => {
                  return (
                    <MenuItem key={actions?.value} value={actions?.value}>
                      {actions?.label}
                    </MenuItem>
                  );
                })}
              </TextFieldComponent>
            </FormControl>
          </div>
        </div>
        <Grid container spacing={1}>
          <Grid container spacing={1} md={8} lg={8} xl={8}>
            <Grid
              item
              md={1}
              lg={1}
              xl={1}
              className="margin-left-10 filter-heading-holder"
            >
              <span className="filter-heading">filters:</span>
            </Grid>
            <Grid item md={2} lg={2} xl={2}>
              <FormControl className="filter-dropdown">
                <TextFieldComponent
                  select
                  value={selectedType}
                  label="Type"
                  onChange={handleTypeChange}
                >
                  {customerType.map((type: any) => {
                    return (
                      <MenuItem value={type?.value} key={type?.value}>
                        {type?.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </Grid>

            {/* <Grid item md={2} lg={2} xl={2}>
              <FormControl className="filter-dropdown">
                <AutocompleteComponent
                  options={blockedCustomerListData}
                  getOptionLabel={(option: any) =>
                    <div>{option?.id}, {option?.firstName}</div>
                  }
                  label="CustId, Name"
                  onChange={(
                    event: React.SyntheticEvent,
                    newValue: any | null
                  ) => {
                    setSelectedCustId(newValue);
                  }}
                  value={selectedCustId}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                />
              </FormControl>
            </Grid> */}

            {handleClearButton() ? (
              <Grid
                item
                md={0.5}
                lg={0.5}
                xl={0.5}
                className="filter-heading-holder"
                style={{ position: "relative" }}
              >
                <span
                  onClick={handleResetFilter}
                  className="clear-filter"
                  style={{ position: "absolute", top: 32 }}
                >
                  clear
                </span>
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={0} md={4} lg={4} xl={4}>
            <Grid item md={12} lg={12} xl={12}>
              <div className="time-sync-holder">
                <div className="time-sync-holder margin-right-15">
                  <span className="margin-right-5 updated-on-heading">
                    Updated on:
                  </span>
                  <span>
                    {formatDateTime(updatedTime, Constants.timeFormathhmmssA)}
                  </span>
                </div>
                <Button
                  variant="contained"
                  onClick={syncUsers}
                  autoFocus
                  disableElevation
                  className="btn btn-sync"
                >
                  <CachedIcon />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: window.innerHeight - 475 }}
        >
          <Table style={{ position: "relative" }}>
            <TableHead>
              <TableRow className="table-header-row">
                <TableCell padding="checkbox" style={{ minWidth: 50 }}>
                  <Checkbox
                    className="active-checkbox"
                    indeterminate={
                      selectedCustomers?.length > 0 &&
                      selectedCustomers?.length <
                        blockedCustomerListData?.length
                    }
                    checked={
                      blockedCustomerListData?.length > 0 &&
                      selectedCustomers?.length ===
                        blockedCustomerListData?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all customers",
                    }}
                  />
                </TableCell>
                {tableHeadings?.map((item, index) => (
                  <TableCell key={index} className="table-header-text">
                    {item?.heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {blockedCustomerListData && blockedCustomerListData?.length > 0 ? (
              <TableBody>
                {blockedCustomerListData &&
                  blockedCustomerListData?.map((customer: any, index: any) => {
                    const isItemSelected = isSelected(customer?.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <>
                        <TableRow
                          key={customer?.id}
                          className="table-data-row"
                          hover
                          // onClick={(event) => handleClick(event, customer.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          // selected={isItemSelected}
                        >
                          <TableCell
                            padding="checkbox"
                            style={{ minWidth: 80 }}
                          >
                            <Checkbox
                              className="active-checkbox"
                              onClick={(event) =>
                                handleClick(event, customer.id)
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {customer?.profilePictureUrl ? (
                              <img
                                src={customer?.profilePictureUrl}
                                alt=""
                                className={styles["customer-image"]}
                              />
                            ) : (
                              <AccountBoxIcon
                                style={{ height: "3rem", width: "3rem" }}
                              />
                            )}
                          </TableCell>
                          {/* <TableCell
                            className="table-data-text"
                            style={{ width: 200 }}
                          >
                            {customer?.crmId}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {`#ID-${customer?.id}`}
                          </TableCell> */}
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {customer?.firstName ? customer?.firstName : null}{" "}
                            {customer?.firstName ? customer.lastName : "----"}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {customer?.contactType
                              ? BlockedCustomerTypeEnumUtils.getEnumText(
                                  customer?.contactType
                                )
                              : "----"}
                          </TableCell>
                          <TableCell className="table-data-text">
                            {customer?.email ? customer?.email : "----"}
                          </TableCell>
                          <TableCell className="table-data-text">
                            {customer?.phoneNumber
                              ? customer?.countryCode
                              : null}{" "}
                            {customer?.phoneNumber
                              ? customer?.phoneNumber
                              : "----"}
                          </TableCell>
                          <TableCell>
                            {customer?.deviceToken?.deviceType
                              ? DeviceEnumsUtils.getEnumText(
                                  customer?.deviceToken?.deviceType
                                )
                              : "----"}
                          </TableCell>
                          <TableCell>
                            {customer?.deviceToken?.ipAddress
                              ? customer?.deviceToken?.ipAddress
                              : "----"}
                          </TableCell>
                          <TableCell className="table-data-text">
                            {customer?.lastBlockedAt ? (
                              <>
                                <div>
                                  {formatDateTime(
                                    customer?.lastBlockedAt,
                                    Constants.dateFormatDDmmYYYY
                                  )}
                                </div>
                                <div>
                                  {formatDateTime(
                                    customer?.lastBlockedAt,
                                    Constants.timeFormathhmmssA
                                  )}
                                </div>
                              </>
                            ) : (
                              "----"
                            )}
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                color:
                                  selectedCustomers?.length > 0
                                    ? "action.disabled"
                                    : null,
                              }}
                              onClick={() =>
                                selectedCustomers?.length === 0
                                  ? handleBlockedCustomer(customer?.id)
                                  : null
                              }
                            >
                              Unblock
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <ConfirmationDialog
                          shouldOpen={
                            customerId === customer.id
                              ? isConfirmationBlockDialogOpen
                              : false
                          }
                          title="Are you sure you want to Unblock ?"
                          content={
                            selectedCustomers?.length == 0
                              ? `Phone Number ${customer?.countryCode} ${customer?.phoneNumber}`
                              : ""
                          }
                          okText="Yes"
                          cancelHandler={handleCloseConfirmDialog}
                          okHandler={singleBlockCustomer}
                        />
                      </>
                    );
                  })}
              </TableBody>
            ) : (
              <div className="no-record">No Blocked Customer Records</div>
            )}
          </Table>
          {selectedCustomers?.length > 0 && (
            <ConfirmationDialog
              shouldOpen={isConfirmationBlockDialogOpen}
              title="Are you sure you want to Unblock ?"
              content={`This action will unblock ${selectedCustomers?.length} customers. Are you sure to continue?`}
              okText="Yes"
              cancelHandler={handleCloseConfirmDialog}
              okHandler={blockSelectedCustomer}
            />
          )}
        </TableContainer>
        <FlashMessageDialog
          shouldOpen={shouldOpenFlashDialog}
          content={message ? message : error}
          isSuccess={isSuccess}
          cancelHandler={handleCloseFlashMessageDialog}
        />
      </Fragment>
    );
  };

  const render = () => {
    if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    } else if (error) {
      return renderRetry();
    }
  };

  return (
    <Fragment>
      <PageHeading title="Customer Management" />
      <BreadCrumbs
        routes={breadcrumbRoutes}
        placeholder={"Search"}
        handleSearchOnKeyPress={handleSearchChange}
      ></BreadCrumbs>

      <Container className="main-conatiner">{render()}</Container>
    </Fragment>
  );
};

export default BlockedCustomerList;
