import { InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import QuillEditor from "components/quill-editor/QuillEditor";
import {
  ButtonComponent,
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Form, Formik, getIn, useFormikContext } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import styles from "./SecurityTips.module.css";
import { SecurityTipsConstants } from "./SecurityTipsConstants";
import { useDispatch, useSelector } from "react-redux";
import SecurityTipsImageDescription from "./SecurityTipsImageDescription";
import { IPage5ProfileInitialValue } from "../page-5-profile-IniticalValue";

const SecurityTips = () => {
  const {
    values,
    handleChange,
    setFieldValue,
    setFieldError,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<IPage5ProfileInitialValue>();
  const { isReduxViewMode } = useSelector(
    (state: any) => state?.pageManagement
  );
  const [currentlyOpenedIndex, setCurrentlyOpenedIndex] = useState(999);

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };
  const handleOpenConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(true);
  };
  const handleShowInsightsCreationMediaDialog = (imageIndex: number) => {
    setCurrentlyOpenedIndex(imageIndex);
    // setShowImageUploadDialog(true);
  };

  const handleTextEditor = (
    name: string,
    newValue: string,
    setFieldValue: any
  ) => {
    setFieldValue(name, newValue);
  };

  return (
    <Fragment>
      <div className={styles["accordian-global-padding"]}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className={styles["priority-holder"]}
          >
            <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
              <Typography>Security Tips</Typography>

              <FormControlLabel
                className="switch-form-label"
                name={"isSecurityTipsActive"}
                disabled={isReduxViewMode || false}
                checked={values?.isSecurityTipsActive}
                onChange={handleChange}
                control={<SwitchComponent />}
                label=""
                labelPlacement="start"
              />
              <IconButton>
                <Tooltip title={"Security Tips"} placement="top" arrow>
                  <Avatar
                    className={styles["Avator"]}
                    src={MediaAssets.ic_info}
                    variant="rounded"
                  />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} mt={3}>
            <TextFieldComponent
              required
              label="Section Heading"
              placeholder="Enter"
              name={`securityTips.sectionHeading`}
              disabled={isReduxViewMode || false}
              inputProps={{
                maxLength: SecurityTipsConstants.sectionHeading,
              }}
              value={values?.securityTips?.sectionHeading}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors?.securityTips?.sectionHeading &&
                touched.securityTips?.sectionHeading
                  ? true
                  : false
              }
              fieldhelpertext={
                errors.securityTips?.sectionHeading &&
                touched.securityTips?.sectionHeading &&
                errors.securityTips?.sectionHeading
              }
            />
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div className={styles["accordian-global-padding"]}>
        <Typography variant="h6">Detailed Information</Typography>
      </div>

      <Divider />

      {values?.securityTips?.detailedInformation?.length > 0 &&
        values?.securityTips?.detailedInformation?.map(
          (element: any, index: number) => (
            <React.Fragment>
              <div className={styles["accordian-global-padding"]}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={3} md={3} lg={3.5} xl={3}>
                    <Typography className={styles["Upload-heading"]}>
                      Upload Media
                    </Typography>
                    <SecurityTipsImageDescription
                      index={index}
                      currentlyOpenedIndex={currentlyOpenedIndex}
                      isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
                      handleShowInsightsCreationMediaDialog={
                        handleShowInsightsCreationMediaDialog
                      }
                      handleCloseConfirmDialog={handleCloseConfirmDialog}
                      handleOpenConfirmDialog={handleOpenConfirmDialog}
                    />
                  </Grid>
                  <Grid item xs={10} sm={10} md={10} lg={8.5} xl={9}>
                    <Stack
                      justifyContent={"flex-start"}
                      className={styles["Upload-heading"]}
                    >
                      <Typography>Description</Typography>
                      <IconButton>
                        <Tooltip
                          arrow
                          title={
                            "Description can be a combination of more than 1 Paragraph. Maximum Character limit for the complete Security Tips is 5,000."
                          }
                          placement="top"
                        >
                          <Avatar
                            className={styles["Avator"]}
                            src={MediaAssets.ic_info}
                            variant="rounded"
                          />
                        </Tooltip>
                      </IconButton>
                    </Stack>

                    <QuillEditor
                      placeholder="Enter description"
                      name={`securityTips.detailedInformation.${index}.description`}
                      className={styles["QuillEditor"]}
                      onChange={(name: string, newValue: string) => {
                        handleTextEditor(name, newValue, setFieldValue);
                      }}
                      inputProps={{
                        maxLength: SecurityTipsConstants.description,
                      }}
                      value={
                        values?.securityTips?.detailedInformation[index]
                          ?.description
                      }
                      style={{ height: "12.3rem" }}
                      disabled={isReduxViewMode}
                      onBlur={handleBlur}
                      fieldhelpertext={
                        getIn(
                          touched,
                          `securityTips.detailedInformation.${index}.description`
                        ) &&
                        getIn(
                          errors,
                          `securityTips.detailedInformation.${index}.description`
                        )
                      }
                      error={Boolean(
                        getIn(
                          errors,
                          `securityTips.detailedInformation.${index}.description`
                        ) &&
                          getIn(
                            touched,
                            `securityTips.detailedInformation.${index}.description`
                          )
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              {/* <Divider /> */}
            </React.Fragment>
          )
        )}

      {/* <div className={styles["accordian-global-padding"]}>
        <Grid className={styles["button-position"]}>
          <Stack
            direction={"row"}
            columnGap={2}
            justifyContent={"space-around"}
            mb={2}
          >
            <ButtonComponent color={"inherit"}>Cancel</ButtonComponent>
            <ButtonComponent type="reset" disabled={!isValid}>
              Save as Drafts
            </ButtonComponent>
            <LoadingButtonComponent type="submit">
              Publish
            </LoadingButtonComponent>
          </Stack>
        </Grid>
      </div> */}
    </Fragment>
  );
};

export default SecurityTips;
