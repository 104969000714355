export const enum ApplicantTypeEnum {
  PrimaryApplicant = 0,
  Co_Applicant = 1,
}

export class ApplicantTypeEnumUtils {
  public static getEnumText(type: ApplicantTypeEnum): string {
    switch (type) {
      case ApplicantTypeEnum.PrimaryApplicant:
        return "Primary Applicant";
      case ApplicantTypeEnum.Co_Applicant:
        return "Co-Applicant";
    }
  }
}
