import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import styles from "./BookingJourneySection.module.css";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import MediaAssets from "assets";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import { ButtonComponent } from "components/ui-elements";

interface IJourneyBlocksInterface {
  isMilestoneSuccess?: boolean;
  milestoneName?: string;
  applicationDate?: string;
  milestoneSubName?: string;
  showPaymentDetails?: boolean;
  showPaymentDetailsText?: string;
  viewText?: any;
  onViewClick?: any;
  isDividerRequired?: boolean;
  children?: any;
  childrenDivider?: boolean;
  isDisabled?: any;
  isViewReceiptDisabled?: boolean;
}

const JourneyBlocks = (props: IJourneyBlocksInterface) => {
  const {
    isMilestoneSuccess,
    milestoneName,
    applicationDate,
    milestoneSubName,
    showPaymentDetails,
    showPaymentDetailsText,
    viewText,
    onViewClick,
    isDividerRequired,
    children,
    childrenDivider,
    isDisabled,
    isViewReceiptDisabled,
  } = props;
  return (
    <Fragment>
      <Stack spacing={2} ml={0.2} mt={1.5} mb={1.5}>
        <Stack justifyContent={"start"} spacing={2}>
          <IconButton>
            {isMilestoneSuccess ? (
              <CheckCircleRoundedIcon fontSize="small" color="success" />
            ) : (
              <CircleTwoToneIcon fontSize="small" />
            )}
          </IconButton>

          <Typography className={isDisabled && styles["disabled-text"]}>
            {milestoneName}
          </Typography>

          {applicationDate && (
            <Typography className={styles["application-date"]}>
              {applicationDate}
            </Typography>
          )}

          <Typography>{milestoneSubName}</Typography>

          {showPaymentDetails && (
            <Stack spacing={1}>
              <img
                src={
                  isMilestoneSuccess
                    ? MediaAssets.ic_rupee_coin
                    : MediaAssets.ic_rupee_disabled
                }
                className={styles["rupee-coin"]}
              />
              <Typography>{showPaymentDetailsText}</Typography>
            </Stack>
          )}
        </Stack>

        {viewText && (
          <ButtonComponent
            onClick={!isDisabled && !isViewReceiptDisabled && onViewClick}
            disabled={isDisabled || isViewReceiptDisabled}
            sx={{ textDecoration: "underline", marginTop: "0.5rem" }}
            variant="text"
            color="inherit"
          >
            {viewText}
          </ButtonComponent>
        )}
      </Stack>

      {/* <Divider className={isDividerRequired ? styles["divider-line"] : styles["divider-line-grey"]}  /> */}
      {isDividerRequired && (
        <Divider
          className={
            childrenDivider
              ? styles["divider-line-children"]
              : styles["divider-line"]
          }
        />
      )}

      <Box style={{ marginLeft: "30px" }}>{children}</Box>
    </Fragment>
  );
};

export default JourneyBlocks;
