import React, { useEffect, useState } from "react";
import styles from "./ReraDetails.module.css";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SwitchComponent, TextFieldComponent } from "components/ui-elements";
import { FieldArray, getIn, useFormikContext } from "formik";
import {
  IInitinalValueProjectContent,
  InitinalValueProjectContent,
} from "../InitinalValuesProjectContent";
import MediaAssets from "assets";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@mui/icons-material";
import PdfDocumentUploader from "components/ui-elements/pdf-document-uploader/PdfDocumetUploader";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";

const actionDispatch = (dispatch: any) => {
  return {
    fileUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
  };
};

const ReraDocumentDetails = () => {
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    errors,
    touched,
    isValid,
  } = useFormikContext<IInitinalValueProjectContent>();

  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );
  const { fileUpload } = actionDispatch(useDispatch());

  const { isMediaLoading, fileUploadDetails } = useSelector((state: any) => ({
    isMediaLoading: state?.fileUpload?.isLoading,
    fileUploadDetails: state.fileUpload.imageDetails,
  }));

  const [shouldAssignMediaValue, setShouldAssignMediaValue] =
    useState<boolean>(false);
  const [assignMediaFor, setAssignMediaFor] = useState("");

  useEffect(() => {
    if (shouldAssignMediaValue) {
      setFieldValue(assignMediaFor, fileUploadDetails?.url);
      setShouldAssignMediaValue(false);
      setAssignMediaFor("");
    }
  }, [fileUploadDetails?.url]);

  useEffect(() => {
    if (values?.reraDetails?.reraDocuments === undefined) {
      setFieldValue(
        "reraDetails.reraDocuments",
        InitinalValueProjectContent?.reraDetails?.reraDocuments
      );
    }

    if (
      values?.reraDetails?.isReraDocumentsActive === null ||
      values?.reraDetails?.isReraDocumentsActive === undefined
    ) {
      setFieldValue("reraDetails.isReraDocumentsActive", false);
    }
  }, []);

  const handleuploadImage = (event: any) => {
    const file = event.target.files[0];
    const getFileExtension = file?.name?.toLowerCase()?.split(".").pop();

    if (file && getFileExtension === "pdf") {
      const data = {
        name: `${Math.floor(Math.random() * 20).toString()}/` + file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        fileUpload(data);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <Box>
      <Box className={styles["accordion-global-padding"]}>
        <Stack
          alignItems="center"
          justifyContent="flexStart"
          spacing={2}
          mb={1}
        >
          <Typography variant="h6" color="#000">
            Rera Documents
          </Typography>

          <SwitchComponent
            name="reraDetails.isReraDocumentsActive"
            onChange={handleChange}
            value={values?.reraDetails?.isReraDocumentsActive}
            checked={values?.reraDetails?.isReraDocumentsActive}
            disabled={isViewMode}
          />
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top"
          >
            <Avatar
              className={styles["info-icon"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </Stack>
      </Box>

      <Box mt={3.5}>
        <Grid container spacing={2}>
          <Grid item xs={5} sm={5} md={3} lg={3} xl={3}>
            <TextFieldComponent
              required
              fullWidth
              placeholder="Enter"
              name="reraDetails.sectionHeading"
              label={"Section Heading"}
              value={values?.reraDetails?.sectionHeading}
              onChange={handleChange}
              onBlur={handleBlur}
              fieldhelpertext={
                getIn(touched, `reraDetails.sectionHeading`) &&
                getIn(errors, `reraDetails.sectionHeading`)
              }
              error={Boolean(
                getIn(errors, `reraDetails.sectionHeading`) &&
                  getIn(touched, `reraDetails.sectionHeading`)
              )}
              inputProps={{
                maxLength: 40,
              }}
              disabled={isViewMode}
            />
          </Grid>
        </Grid>
      </Box>

      <FieldArray
        name="reraDetails.reraDocuments"
        render={(arrayHelper) => (
          <div>
            {values?.reraDetails?.reraDocuments?.length > 0 &&
              values?.reraDetails.reraDocuments?.map(
                (reraDocument: any, parentIndex: number) => (
                  <div>
                    <Box m={2}>
                      <Grid
                        container
                        spacing={2}
                        className={styles["rera-document-container"]}
                      >
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          component={Stack}
                          p={3}
                        >
                          <Grid item xs={5} sm={5} md={3.5} lg={3.5} xl={3.5}>
                            <TextFieldComponent
                              required
                              fullWidth
                              placeholder="Enter"
                              name={`reraDetails.reraDocuments[${parentIndex}].phaseName`}
                              label={"Phase Name"}
                              value={
                                values?.reraDetails?.reraDocuments[parentIndex]
                                  .phaseName
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fieldhelpertext={
                                getIn(
                                  touched,
                                  `reraDetails.reraDocuments[${parentIndex}].phaseName`
                                ) &&
                                getIn(
                                  errors,
                                  `reraDetails.reraDocuments[${parentIndex}].phaseName`
                                )
                              }
                              error={Boolean(
                                getIn(
                                  errors,
                                  `reraDetails.reraDocuments[${parentIndex}].phaseName`
                                ) &&
                                  getIn(
                                    touched,
                                    `reraDetails.reraDocuments[${parentIndex}].phaseName`
                                  )
                              )}
                              inputProps={{
                                maxLength: 40,
                              }}
                              disabled={isViewMode}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={5}
                            sm={5}
                            md={3.5}
                            lg={3.5}
                            xl={3.5}
                            textAlign={"end"}
                          >
                            {values?.reraDetails?.reraDocuments?.length > 1 && (
                              <DeleteOutlined
                                onClick={() => arrayHelper.remove(parentIndex)}
                                className={styles["delete-icon"]}
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Divider className={styles["divider-with-color"]} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FieldArray
                            name={`reraDetails.reraDocuments[${parentIndex}].documents`}
                            render={(childHepler: any) => (
                              <div>
                                {values?.reraDetails?.reraDocuments[parentIndex]
                                  ?.documents?.length > 0 &&
                                  values?.reraDetails?.reraDocuments[
                                    parentIndex
                                  ]?.documents?.map(
                                    (documents: any, childIndex: number) => (
                                      <>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={12}
                                          xl={12}
                                          component={Stack}
                                          className={
                                            styles["rera-phase-section"]
                                          }
                                        >
                                          <Grid
                                            item
                                            xs={5}
                                            sm={5}
                                            md={3.5}
                                            lg={3.5}
                                            xl={3.5}
                                          >
                                            <TextFieldComponent
                                              required
                                              fullWidth
                                              placeholder="Enter"
                                              name={`reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentName`}
                                              label={"Document Name"}
                                              value={
                                                values?.reraDetails
                                                  ?.reraDocuments[parentIndex]
                                                  ?.documents[childIndex]
                                                  ?.documentName
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              fieldhelpertext={
                                                getIn(
                                                  touched,
                                                  `reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentName`
                                                ) &&
                                                getIn(
                                                  errors,
                                                  `reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentName`
                                                )
                                              }
                                              error={Boolean(
                                                getIn(
                                                  errors,
                                                  `reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentName`
                                                ) &&
                                                  getIn(
                                                    touched,
                                                    `reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentName`
                                                  )
                                              )}
                                              inputProps={{
                                                maxLength: 40,
                                              }}
                                              disabled={isViewMode}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            xs={5}
                                            sm={5}
                                            md={7.5}
                                            lg={7.5}
                                            xl={7.5}
                                            className={
                                              styles["pdf-upload-section"]
                                            }
                                            alignItems={"flex-start"}
                                          >
                                            <PdfDocumentUploader
                                              uploadName={`reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentMedia.value.url`}
                                              value={
                                                values?.reraDetails
                                                  ?.reraDocuments[parentIndex]
                                                  ?.documents[childIndex]
                                                  ?.documentMedia?.value?.url
                                              }
                                              isLoading={
                                                assignMediaFor ===
                                                  `reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentMedia.value.url` &&
                                                isMediaLoading
                                                  ? true
                                                  : false
                                              }
                                              isViewMode={false}
                                              handleImageUpload={(
                                                event: any
                                              ) => {
                                                setAssignMediaFor(
                                                  `reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentMedia.value.url`
                                                );
                                                setShouldAssignMediaValue(true);
                                                handleuploadImage(event);
                                              }}
                                              error={Boolean(
                                                getIn(
                                                  errors,
                                                  `reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentMedia.value.url`
                                                ) &&
                                                  getIn(
                                                    touched,
                                                    `reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentMedia.value.url`
                                                  )
                                              )}
                                              handleDeletePdf={() => {
                                                if (
                                                  values?.reraDetails
                                                    ?.reraDocuments[parentIndex]
                                                    ?.documents?.length > 1
                                                ) {
                                                  childHepler.remove(
                                                    childIndex
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    `reraDetails.reraDocuments[${parentIndex}].documents[${childIndex}].documentMedia.value.url`,
                                                    ""
                                                  );
                                                }
                                              }}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            xs={5}
                                            sm={5}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                          >
                                            {values?.reraDetails?.reraDocuments[
                                              parentIndex
                                            ]?.documents?.length > 1 &&
                                              !values?.reraDetails
                                                .reraDocuments[parentIndex]
                                                .documents[childIndex]
                                                .documentMedia.value.url && (
                                                <DeleteOutlined
                                                  onClick={() => {
                                                    !isViewMode &&
                                                      childHepler.remove(
                                                        childIndex
                                                      );
                                                  }}
                                                  className={
                                                    styles["delete-icon"]
                                                  }
                                                />
                                              )}
                                          </Grid>
                                        </Grid>

                                        {values?.reraDetails?.reraDocuments[
                                          parentIndex
                                        ]?.documents?.length > 0 && (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            mt={2}
                                            mb={2}
                                          >
                                            <Divider
                                              className={
                                                values?.reraDetails
                                                  ?.reraDocuments[parentIndex]
                                                  ?.documents?.length ===
                                                childIndex + 1
                                                  ? styles["divider-with-color"]
                                                  : styles.divider
                                              }
                                            />
                                          </Grid>
                                        )}
                                      </>
                                    )
                                  )}

                                {values?.reraDetails?.reraDocuments[parentIndex]
                                  ?.documents?.length === 0 && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <Divider className={styles.divider} />
                                  </Grid>
                                )}

                                <Grid
                                  ml={3}
                                  mb={2}
                                  item
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                >
                                  <Link
                                    underline="always"
                                    component="button"
                                    type={"button"}
                                    className={styles["Mui-link-btn"]}
                                    onClick={() =>
                                      childHepler.push({
                                        documentName: "",
                                        documentMedia: {
                                          name: "",
                                          key: "",
                                          value: {
                                            mediaType: "PDF",
                                            url: "",
                                          },
                                        },
                                      })
                                    }
                                    disabled={isViewMode}
                                  >
                                    + Add Documents
                                  </Link>
                                </Grid>
                              </div>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {values?.reraDetails?.reraDocuments?.length ===
                      parentIndex + 1 && (
                      <Link
                        underline="always"
                        component="button"
                        className={styles["Mui-link-btn"]}
                        onClick={() =>
                          arrayHelper.push({
                            phaseName: "",
                            documents: [
                              {
                                documentName: "",
                                documentMedia: {
                                  name: "",
                                  key: "",
                                  value: {
                                    mediaType: "PDF",
                                    url: "",
                                  },
                                },
                              },
                            ],
                          })
                        }
                        disabled={isViewMode}
                      >
                        + Add Phases
                      </Link>
                    )}
                  </div>
                )
              )}
          </div>
        )}
      />
    </Box>
  );
};

export default ReraDocumentDetails;
