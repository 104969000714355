import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { useFormik } from "formik";
import { FC, Fragment, useEffect, useState } from "react";
import { ResetPasswordValidationSchema } from "./ChangePasswordValidation";
import MediaAssets from "assets";
import {
  AvatarWrapper,
  CloseIcon,
  DialogBoxOuter,
  ImageWrapper,
  ResetPasswordStyleWrapper,
  TextFieldSection,
  TypographyWrapper,
} from "./ChangePassword.style";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordActions } from "../../../redux-container/change-password/ChangePasswordRedux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";

interface IChangePasswordDialogProps {
  shouldOpen: boolean;
  handleClose: () => void;
}

interface IChangePasswordInitialValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface IRequestBody {
  oldPassword: string;
  newPassword: string;
}

export const ChangePasswordInitialValues: IChangePasswordInitialValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const PasswordTooltipInfo = () => {
  return (
    <Box width={"16rem"}>
      <Typography color={"common.white"} variant="body2" gutterBottom>
        New Password requirements:
      </Typography>
      <Typography
        component={"li"}
        sx={{ listStyle: "none", lineHeight: 1 }}
        color={"common.white"}
        variant="caption"
      >
        A minimum of 8 characters
      </Typography>
      <Typography
        component={"li"}
        sx={{ listStyle: "none", lineHeight: 1 }}
        color={"common.white"}
        variant="caption"
      >
        Include at least one lowercase letter (a-z)
      </Typography>
      <Typography
        component={"li"}
        sx={{ listStyle: "none", lineHeight: 1 }}
        color={"common.white"}
        variant="caption"
      >
        Include at least one uppercase letter (A-Z)
      </Typography>
      <Typography
        component={"li"}
        sx={{ listStyle: "none", lineHeight: 1 }}
        color={"common.white"}
        variant="caption"
      >
        Include at least one number (0-9)
      </Typography>
      <Typography
        component={"li"}
        sx={{ listStyle: "none", lineHeight: 1 }}
        color={"common.white"}
        variant="caption"
      >
        Include a Special Character
      </Typography>
      <Typography
        component={"li"}
        sx={{ listStyle: "none", lineHeight: 1 }}
        color={"common.white"}
        variant="caption"
        gutterBottom
      >
        Cannot use last 3 old passwords.
      </Typography>
    </Box>
  );
};

const ChangePasswordComponent: FC<IChangePasswordDialogProps> = (props) => {
  const { shouldOpen, handleClose } = props;
  const [showPassword, setShowPassword] = useState({
    currentPasswordShow: false,
    newPasswordShow: false,
    confirmNewPasswordShow: false,
  });
  const [user, setUser] = useState<any>(null);

  const dispatch = useDispatch();
  const { dataRecived, data, isLoading, error, isPasswordChangedSuccess } =
    useSelector((state: any) => state.changePassword);

  const onSubmit = (values: IChangePasswordInitialValues) => {
    const requestBody: IRequestBody = {
      oldPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    dispatch(ChangePasswordActions.changePasswordRequest(requestBody));
    handleClose();
    resetForm();
    handleShowPassword();
  };

  const {
    resetForm,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: ChangePasswordInitialValues,
    validationSchema: ResetPasswordValidationSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    const user = getItemFromLocalStorage(Constants.loggedInUser);
    setUser(user);
    if (isPasswordChangedSuccess) {
      const user = getItemFromLocalStorage(Constants.loggedInUser);
      setUser(user);
      dispatch(
        ChangePasswordActions.setChangePasswordState(
          "isPasswordChangedSuccess",
          false
        )
      );
    }
  }, [isPasswordChangedSuccess]);

  const handleDialogClose = () => {
    dispatch(
      ChangePasswordActions.setChangePasswordState("dataRecived", false)
    );
  };

  const handleClickCurrentPassword = () => {
    setShowPassword((prev) => ({
      ...prev,
      currentPasswordShow: !prev.currentPasswordShow,
    }));
  };

  const handleShowPassword = () => {
    setShowPassword({
      currentPasswordShow: false,
      newPasswordShow: false,
      confirmNewPasswordShow: false,
    });
  };

  const handleClickNewPassword = () => {
    setShowPassword((prev) => ({
      ...prev,
      newPasswordShow: !prev.newPasswordShow,
    }));
  };

  const handleClickConfirmNewPassword = () => {
    setShowPassword((prev) => ({
      ...prev,
      confirmNewPasswordShow: !prev.confirmNewPasswordShow,
    }));
  };

  return (
    <Fragment>
      <Dialog fullWidth maxWidth={"xs"} open={shouldOpen}>
        <DialogTitle>
          <Typography component={"span"} variant="h6" color={"common.black"}>
            Change Password
          </Typography>

          <IconButton
            edge={"end"}
            component={"span"}
            onClick={() => {
              handleClose();
              resetForm();
              handleShowPassword();
            }}
          >
            <CloseIcon src={MediaAssets.ic_close} />
          </IconButton>
        </DialogTitle>
        <ResetPasswordStyleWrapper>
          <Box component={"form"} onSubmit={handleSubmit}>
            <DialogContent>
              <TextFieldSection>
                <TextFieldComponent
                  type={showPassword.currentPasswordShow ? "text" : "password"}
                  label="Current Password"
                  placeholder="Enter"
                  name="currentPassword"
                  onBlur={handleBlur}
                  value={values.currentPassword}
                  onChange={handleChange}
                  error={
                    errors.currentPassword && touched.currentPassword
                      ? true
                      : false
                  }
                  fieldhelpertext={
                    errors.currentPassword &&
                    touched.currentPassword &&
                    errors.currentPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickCurrentPassword}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword.currentPasswordShow ? (
                          <Visibility className="password-eye-icon" />
                        ) : (
                          <VisibilityOff className="password-eye-icon" />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </TextFieldSection>
              <TextFieldSection>
                <TextFieldComponent
                  type={showPassword.newPasswordShow ? "text" : "password"}
                  label="New Password"
                  placeholder="Enter"
                  name="newPassword"
                  onBlur={handleBlur}
                  value={values.newPassword}
                  onChange={handleChange}
                  error={
                    errors.newPassword && touched.newPassword ? true : false
                  }
                  fieldhelpertext={
                    errors.newPassword &&
                    touched.newPassword &&
                    errors.newPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <Fragment>
                        <IconButton>
                          <Tooltip
                            title={<PasswordTooltipInfo />}
                            placement="top"
                          >
                            <AvatarWrapper
                              src={MediaAssets.ic_info}
                              variant="rounded"
                            />
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickNewPassword}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword.newPasswordShow ? (
                            <Visibility className="password-eye-icon" />
                          ) : (
                            <VisibilityOff className="password-eye-icon" />
                          )}
                        </IconButton>
                      </Fragment>
                    ),
                  }}
                />
              </TextFieldSection>
              <TextFieldSection>
                <TextFieldComponent
                  type={
                    showPassword.confirmNewPasswordShow ? "text" : "password"
                  }
                  label="Confirm New Password"
                  placeholder="Enter"
                  name="confirmNewPassword"
                  onBlur={handleBlur}
                  value={values.confirmNewPassword}
                  onChange={handleChange}
                  error={
                    errors.confirmNewPassword && touched.confirmNewPassword
                      ? true
                      : false
                  }
                  fieldhelpertext={
                    errors.confirmNewPassword &&
                    touched.confirmNewPassword &&
                    errors.confirmNewPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickConfirmNewPassword}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword.confirmNewPasswordShow ? (
                          <Visibility className="password-eye-icon" />
                        ) : (
                          <VisibilityOff className="password-eye-icon" />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </TextFieldSection>
            </DialogContent>
            <DialogActions>
              <LoadingButtonComponent
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                Confirm Change Password
              </LoadingButtonComponent>
            </DialogActions>
          </Box>
        </ResetPasswordStyleWrapper>
      </Dialog>

      {/* <Dialog open={dataRecived} onClose={handleDialogClose}>
        <DialogBoxOuter>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent="center"
          >
            <ImageWrapper>
              <img
                src={
                  error === null
                    ? `${MediaAssets.ic_success_tick}`
                    : `${MediaAssets.ic_error_cross}`
                }
                alt="White tick inside green circle"
              />
            </ImageWrapper>
            <TypographyWrapper
              variant="h5"
              color="primary.dark"
              data-testid="password-changed-sucessful"
            >
              {data}
            </TypographyWrapper>
          </Stack>
        </DialogBoxOuter>
      </Dialog> */}
      <FlashMessageDialog
        cancel={false}
        shouldOpen={dataRecived}
        content={data}
        isSuccess={error === null ? true : false}
        cancelHandler={handleDialogClose}
      />
    </Fragment>
  );
};

export default ChangePasswordComponent;
