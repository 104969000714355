export interface IBlogsInitialValues {
  formType: string;
  displayTitle: string;
  subTitle: string;
  updateType: string;
  shouldDisplayDate: boolean;
  detailedInfo: {
    media: {
      key: string;
      name: string;
      value: {
        url: string;
        mediaType: string;
      };
      isActive: boolean;
    };
    description: string;
  }[];
}

export const BlogsInitialValues: IBlogsInitialValues = {
  formType: "1001",
  displayTitle: "",
  subTitle: "",
  updateType: "",
  shouldDisplayDate: false,
  detailedInfo: [
    {
      media: {
        key: "",
        name: "",
        value: {
          url: "",
          mediaType: "IMAGE",
        },
        isActive: true,
      },
      description: "",
    },
    {
      media: {
        key: "",
        name: "",
        value: {
          url: "",
          mediaType: "IMAGE",
        },
        isActive: true,
      },
      description: "",
    },
    {
      media: {
        key: "",
        name: "",
        value: {
          url: "",
          mediaType: "IMAGE",
        },
        isActive: true,
      },
      description: "",
    },
  ],
};
