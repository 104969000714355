import Header from "../header/Header";
import Sidemenu from "../sidemenu/Sidemenu";
import styles from "./Layout.module.css";

const Layout = ({ children }: any) => {
  return (
    <div>
      <Header />
      <Sidemenu />
      <div className={styles["page"]}>
        <div className={styles["main-container"]}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
