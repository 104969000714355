import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { InviteActions } from "redux-container/users/InviteUserRedux";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { PaymentFormActions } from "redux-container/payment-forms/paymentFormRedux";

export function* getPaymentDetailSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPaymentDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PaymentFormActions.getPaymentFormSuccess(response.data));
    } else {
      yield put(PaymentFormActions.getPaymentFormFailure(response.data));
    }
  } catch (error) {
    yield put(PaymentFormActions.getPaymentFormFailure(error));
  }
}

export function* getPaymentInventoriesSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPaymentInvetories, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PaymentFormActions.getPaymentInventoriesSuccess(response.data));
    } else {
      yield put(PaymentFormActions.getPaymentInventoriesFailure(response.data));
    }
  } catch (error) {
    yield put(PaymentFormActions.getPaymentInventoriesFailure(error));
  }
}

export function* updatePaymentDetailSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(
      api.updatePaymentDetails,
      action.id,
      action.data
    );
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PaymentFormActions.updatePaymentFormSuccess(response.data));
    } else {
      yield put(PaymentFormActions.updatePaymentFormFailure(response.data));
    }
  } catch (error) {
    yield put(PaymentFormActions.updatePaymentFormFailure(error));
  }
}

export function* createPaymentDetailSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.createPaymentDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PaymentFormActions.createPaymentFormSuccess(response.data));
    } else {
      yield put(PaymentFormActions.createPaymentFormFailure(response.data));
    }
  } catch (error) {
    yield put(PaymentFormActions.createPaymentFormFailure(error));
  }
}
