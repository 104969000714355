import { PopperPlacementType } from "@mui/material";

export interface IInsightsCreation {
  displayTitle: string;
  status: string;
  insightsMedia: {
    media: {
      key: string;
      name: string;
      value: {
        url: string;
        imageDialogUrl: string;
        videoDialogUrl: string;
        successFullyUploadedType: string;
        width: number;
        height: number;
        mediaType: string;
        isPreview: boolean;
        isCancel: boolean;
        size: number;
      };
      isActive: boolean;
      mediaDescription: string;
    };
    description: string;
  }[];
}

export type PropperStateType = {
  anchorEl: null;
  isSettingOpen: boolean;
  placement: PopperPlacementType;
};

export const insightMediaObj = {
  media: {
    key: "upload",
    name: "file",
    value: {
      url: "",
      imageDialogUrl: "",
      videoDialogUrl: "",
      successFullyUploadedType: "IMAGE",
      width: 320,
      height: 240,
      mediaType: "IMAGE",
      isPreview: false,
      isCancel: false,
      size: 0,
    },
    isActive: true,
    mediaDescription: "",
  },
  description: "",
};

const insightCreationDefaultValue: IInsightsCreation = {
  displayTitle: "",
  status: "",
  insightsMedia: [
    { ...insightMediaObj },
    { ...insightMediaObj },
    { ...insightMediaObj },
  ],
};

const mediaType = [
  { id: 1, label: "Image", value: "IMAGE" },
  { id: 2, label: "Video", value: "VIDEO" },
];

const insightMediaLabels = (index: string) => {
  return {
    media: {
      value: {
        url: `Media ${index}`,
      },
      mediaDescription: `Media ${index} Description`,
    },
    description: `Description ${index}`,
  };
};

const InsightsCreationLabels: any = {
  displayTitle: "display Title",
  status: "Status",
  insightsMedia: [
    { ...insightMediaLabels("One") },
    { ...insightMediaLabels("Two") },
    { ...insightMediaLabels("Three") },
  ],
};

export { mediaType, insightCreationDefaultValue, InsightsCreationLabels };
